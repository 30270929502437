import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//Constants
import { sections } from "src/constants";

// Components
import { RevisionsComparisonComponent } from "src/components/revisions_comparison_component";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

// Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { createAllPageRevisionComparisonContextSelector } from "src/selectors/api_selectors/patients/revision_comparison";
import { createAllPageRevisionComparisonQuerySelector } from "src/selectors/api_selectors/patients/revision_comparison";
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";
import { createLatestAssessmentAnswerForRevisionSelector } from "src/selectors/data_selectors";
// Actions
import { loadWoundSeriesRevisionsAction } from "src/actions/data_actions";
import {
  selectRevisionAction,
  addRevisionItemAction,
  removeRevisionItemAction
} from "src/ui_processors/patients/actions";

import SingleValueReduxUtil from "src/ui_processors/single_value";
import { healingIndexDashboardEnabledSelector } from "src/selectors/ui_config_selectors/global";

const revisionComparisonTabSelectedActions = SingleValueReduxUtil.createActionCreators(
  "revisionComparisonTabSelected"
);

function mapStateToProps(state, ownProps) {
  const { seriesId } = ownProps;
  const sectionSelector = createSectionSelectors(sections.patients);
  const revisions = revisionsResourceSelectors.createDataForContextSelector(
    createAllPageRevisionComparisonContextSelector(ownProps)
  )(state);
  const assessmentAnswer = createLatestAssessmentAnswerForRevisionSelector(
    revisions[0]
  )(state);
  return {
    revisions,
    assessmentAnswer,
    // TODO: this shouldn't use the generic selector if it needs a custom default other than null
    revisionsToCompareForSeries:
      sectionSelector.createSectionItemStateSelector(
        `revisionsToCompare.${seriesId}`
      )(state) || [],
    healingIndexDashboardEnabled: healingIndexDashboardEnabledSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSelectRevisionAction: revisionObject => {
      dispatch(selectRevisionAction(revisionObject));
    },
    sendAddRevisionItemAction: seriesId => {
      dispatch(addRevisionItemAction(seriesId));
    },
    sendRemoveRevisionItemAction: revisionItemSeriesIdAndIndex => {
      dispatch(removeRevisionItemAction(revisionItemSeriesIdAndIndex));
    },
    sendRevisionComparisonTabSelectedAction(enabled) {
      dispatch(revisionComparisonTabSelectedActions.setValue(enabled));
    }
  };
}

export const ComparisonViewRevisionsComparisonContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadWoundSeriesRevisionsAction,
    resourceSelectors: revisionsResourceSelectors,
    createContextSelector: createAllPageRevisionComparisonContextSelector,
    createQuerySelector: createAllPageRevisionComparisonQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: `RENDER_REVISION_COMPARISON_CONTAINER`
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RevisionsComparisonComponent);
