// @flow
import React, { PureComponent } from "react";
import { Chart } from "@sw/ui-kit";
import DateValueModel from "@sw/models/TrendModel/DateValueModel";

type $props = {
  data: DateValueModel
};

export default class HealingPerformanceChart extends PureComponent {
  props: $props;
  render() {
    return <Chart data={this.props.data} beginAnywhere noMax />;
  }
}
