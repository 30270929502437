import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash.get";

// Components
import { StudyBadgeComponent } from "../study_badge_component";

// Model
import { getScoreValue } from "src/models/assessment_answers";

export class NonWoundAssessmentListItemComponent extends React.PureComponent {
  static propTypes = {
    assessmentAnswer: PropTypes.object.isRequired,
    assessmentSchema: PropTypes.object.isRequired
  };

  render() {
    const { assessmentAnswer, assessmentSchema } = this.props;

    const title = get(
        { assessmentSchema },
        "assessmentSchema.attributes.schemaJson.localizationDictionary.base.tileName",
        ""
      ),
      createdAt = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.createdAt",
        null
      );

    let bottomText = "";
    if (createdAt) {
      bottomText = moment(assessmentAnswer.attributes.createdAt).fromNow();
    }

    return (
      <StudyBadgeComponent
        title={title}
        value={getScoreValue(assessmentAnswer, assessmentSchema)}
        bottomText={bottomText}
      />
    );
  }
}
