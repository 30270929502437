import { adminActionTypes } from "src/action_types/section_action_types";

/**
 * Action creator for setting the admission status filter
 * @param {string} filterOption the filter option
 * @return {Object} Action
 */
export function setAdmissionStatusFilterAction(filterOption) {
  return {
    type: adminActionTypes.setAdmissionStatus,
    payload: filterOption
  };
}

export function deselectAdmissionStatusFilterAction(filterOption) {
  return {
    type: adminActionTypes.deselectAdmissionStatus,
    payload: filterOption
  };
}
