import { createSelector } from "reselect";
import { studiesResourceSelectors } from "../studies_resource_selectors";

/**
 * Creates a selector that gets the study id for a patient id
 * @param {string} patientId
 * @return {Function} selector
 */
export function createStudyIdForPatientIdSelector(patientId) {
  return createSelector(
    studiesResourceSelectors.dataSelector,
    studies => {
      const study = Object.values(studies).find(study => {
        return study.attributes.patientId == patientId;
      });

      return study ? study.id : undefined;
    }
  );
}
