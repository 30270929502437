import { createSelector } from "reselect";
import get from "lodash.get";
import {
  hasAllowEditOtherUsersWorkPermissionSelector,
  hasUnlockWoundRevisionsPermissionSelector
} from "src/selectors/ui_config_selectors/patients";
import { assessmentAnswersResourceSelectors } from "src/selectors/data_selectors";
import { currentUserSelector } from "../../../config_selectors/current_user_selector";

export function createUnlockIsDisabledSelector({ assessmentAnswer, revision }) {
  return createSelector(
    hasAllowEditOtherUsersWorkPermissionSelector,
    hasUnlockWoundRevisionsPermissionSelector,
    assessmentAnswersResourceSelectors.deleteLoadStateWithStatusByIdSelector,
    currentUserSelector,
    (
      canEditOtherUsersWork,
      canUnlockWoundRevisions,
      deleteLoadStateWithStatusById,
      currentUser
    ) => {
      const revisionAuthorId = get(revision, "attributes.userId", null);
      const isRevisionAuthor =
        revisionAuthorId && revisionAuthorId === currentUser.id;
      return (
        (!canEditOtherUsersWork && !isRevisionAuthor) ||
        !canUnlockWoundRevisions ||
        deleteLoadStateWithStatusById[assessmentAnswer.id] === "loading" ||
        deleteLoadStateWithStatusById[assessmentAnswer.id] === "loaded"
      );
    }
  );
}
