import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { sections } from "src/constants";

// Components
import {
  locationsFilterConfigSelector,
  patientUserFilterConfigSelector,
  payersFilterConfigSelector
} from "src/selectors/ui_config_selectors/global";
import { SavedFilterVisualizationComponent } from "src/components/saved_filter_visualization_component";

// Selectors
import {
  clinicianFilterConfigSelector,
  evaluationsFilterConfigSelector,
  visitSequenceFilterConfigSelector
} from "src/selectors/ui_config_selectors/compliance";

// UI Processors
import { createSectionSelectors } from "src/ui_processors";

// HOC
import { withSchemaLocalizationDictionaryWrapper } from "src/hoc/with_schema_localization_dictionary_wrapper";

const sectionSelectors = createSectionSelectors(sections.compliance);

function mapStateToProps(state) {
  // Evaluations
  const evaluationsFilterConfig = evaluationsFilterConfigSelector(state);
  const evaluationsCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    evaluationsFilterConfig
  )(state);

  // Locations
  const locationConfig = locationsFilterConfigSelector(state);
  const locationCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    locationConfig
  )(state);

  // Clinicians
  const cliniciansConfig = clinicianFilterConfigSelector(state);
  const cliniciansCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    cliniciansConfig
  )(state);

  // Patient User
  const patientUserConfig = patientUserFilterConfigSelector(state);
  const patientUserCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    patientUserConfig
  )(state);

  // Payers
  const payersConfig = payersFilterConfigSelector(state);
  const payersCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    payersConfig
  )(state);

  // Payers
  const visitSequenceConfig = visitSequenceFilterConfigSelector(state);
  const visitSequenceCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    visitSequenceConfig
  )(state);

  return {
    filterUiConfigs: [
      evaluationsFilterConfig,
      locationConfig,
      cliniciansConfig,
      patientUserConfig,
      payersConfig,
      visitSequenceConfig
    ],
    filterCheckedStates: [
      evaluationsCheckedState,
      locationCheckedState,
      cliniciansCheckedState,
      patientUserCheckedState,
      payersCheckedState,
      visitSequenceCheckedState
    ]
  };
}

export const ComplianceSavedFilterVisualizationContainer = flowRight(
  withSchemaLocalizationDictionaryWrapper,
  connect(mapStateToProps)
)(SavedFilterVisualizationComponent);
