import { trackError } from "src/error_tracking";

// Api
import { uploadCsv } from "src/api/csv_upload";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";
import { create } from "src/actions/flashMessageActions";

/**
 * Action creator for setting loading state of csv upload to "loading"
 * @return {Object} action
 */
function csvImportLoadingAction() {
  return {
    type: dataActionTypes.csvImportLoading
  };
}

/**
 * Action creator for setting ready state of csv upload to "ready"
 * @return {Object} action
 */
function csvImportReadyAction() {
  return {
    type: dataActionTypes.csvImportReady
  };
}

/**
 * Send api call to import the csv.
 * @param {Object} payload The payload
 * @param {string} partnerId
 * @param {string} csvType ex. user/pateint
 */
export function importCsvAction(payload, partnerId, csvType) {
  return dispatch => {
    // loading state
    dispatch(csvImportLoadingAction());
    return uploadCsv(payload, partnerId, csvType)
      .then(() => {
        // ready state
        dispatch(csvImportReadyAction());

        dispatch(create("File uploaded sucessfully", "success"));
      })
      .catch(err => {
        if (err.message == "cancel") {
          return;
        }
        if (err.response && (err.response.status = 422))
          dispatch(create("Upload failed: csv format invalid", "error"));
        else
          dispatch(
            create("Sorry, there was an error importing the file", "error")
          );
        dispatch(csvImportReadyAction());
        trackError(err);
      });
  };
}
