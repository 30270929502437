import { createSelector } from "reselect";

// Selectors
import { assessmentSchemasResourceSelectors } from "src/selectors/data_selectors";
import { createLatestAssessmentForRevisionsSelector } from "../../assessments/create_latest_assessment_for_revisions_selector";

/**
 * Create a selector that gets the assessment schema for the
 * latest assessment answer for each revision passed in.
 * @param {Object} props
 * @param {array} props.revisions
 * @returns {Function} selector
 */
export function createLatestAssessmentSchemasForRevisionsSelector({
  revisions
}) {
  return createSelector(
    createLatestAssessmentForRevisionsSelector({ revisions }),
    assessmentSchemasResourceSelectors.dataSelector,
    (assessmentsByRevisionId, assessmentSchemas) => {
      const schemaArray = Object.values(assessmentSchemas);
      return Object.keys(assessmentsByRevisionId).reduce((agg, revId) => {
        const assessment = assessmentsByRevisionId[revId];
        if (assessment) {
          const schema = schemaArray.find(
            s => s.attributes.assessmentId == assessment.id
          );
          agg[revId] = schema;
        } else {
          agg[revId] = undefined;
        }

        return agg;
      }, {});
    }
  );
}
