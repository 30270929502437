import axios from "axios";

/**
 * create patient adt from the admin section
 * @param {Object} payload - responsible for sending the data to api.
 * @return {Promise}
 */
export function createPatientADT(payload) {
  let config = {
    headers: {
      "Content-Type": "application/vnd.api+json"
    }
  };
  return axios.post("/api/v4/adt-events", payload, config);
}
