// @flow
import { Map } from "immutable";
import { handleActions } from "redux-actions";
import mergeDeepWithLists from "./mergeDeepWithLists";
import { entityActionNames as actionNames } from "../actionUtils";
import { generateDefaultState } from "./misc";

type $props = {
  name: string,
  defaultStateConfig?: Object,
  options?: Object,
  otherActions?: Object,
  modelGenerator?: (entity: Object) => Class<any>,
  Model?: Class<any>
};

const create = function(modelGenerator) {
  return function(state, { payload, error }) {
    if (error) {
      return state;
    }
    const Model = modelGenerator(payload.entity);
    // $FlowFixMe
    const entity = new Model(payload.entity);
    return state.setIn(["data", `${payload.entity.id}`], entity);
  };
};

const remove = function() {
  return function(state, { payload, error }) {
    if (error) {
      return state;
    }
    return state.deleteIn(["data", `${payload.entityId}`]);
  };
};

const deleteAtPath = function() {
  return function(state, { payload, error }) {
    if (error) {
      return state;
    }
    let finalPath = ["data"].concat(payload.path);
    state = state.deleteIn(finalPath);
    return state;
  };
};

const update = function() {
  return function(state, { payload, error }) {
    if (error) {
      return state;
    }
    const { id, ...otherProps } = payload.entity;
    return state.updateIn(["data", `${id}`], entity => {
      return entity.mergeWith(mergeDeepWithLists, otherProps);
    });
  };
};

const get = function(modelGenerator) {
  return function(state, { payload, error }) {
    if (error) {
      return state;
    }
    return state.updateIn(["data", `${payload.entity.id}`], previousEntity => {
      if (previousEntity) {
        return previousEntity.mergeWith(mergeDeepWithLists, payload.entity);
      }
      // $FlowFixMe
      return new (modelGenerator(payload.entity))(payload.entity);
    });
  };
};

const index = function(modelGenerator) {
  return function(state, { payload, error }) {
    if (error) {
      return state;
    }
    return payload.entities.reduce((finalResult, entity) => {
      return finalResult.updateIn(["data", `${entity.id}`], previousEntity => {
        if (previousEntity) {
          return previousEntity.mergeWith(mergeDeepWithLists, entity);
        }
        const Model = modelGenerator(entity);
        // $FlowFixMe
        return new Model(entity);
      });
    }, state);
  };
};

export const entityReducer = function({
  name,
  Model,
  modelGenerator,
  defaultStateConfig = {},
  otherActions = {}
}: $props) {
  let finalModelGenerator = () => class {};
  if (Model) {
    finalModelGenerator = () => Model;
  } else if (modelGenerator) {
    finalModelGenerator = modelGenerator;
  } else {
    throw new TypeError("please include Model name");
  }

  return handleActions(
    {
      [actionNames.create(name)]: create(finalModelGenerator),
      [actionNames.update(name)]: update(),
      [actionNames.deleteAtPath(name)]: deleteAtPath(),
      [actionNames.remove(name)]: remove(),
      [actionNames.get(name)]: get(finalModelGenerator),
      [actionNames.index(name)]: index(finalModelGenerator),
      ...otherActions
    },
    generateDefaultState({ data: new Map(), ...defaultStateConfig })
  );
};
