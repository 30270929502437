import { createSelector } from "reselect";
import get from "lodash.get";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

//Constants
import { policyInternalNames } from "src/constants";

/**
 * Memoized selector to determine if evaluation list is enabled for dashboard
 */

export const evaluationListEnabledSelector = createSelector(
  createPolicyForInternalNameSelector(
    policyInternalNames.dashboardEvaluationListEnabled
  ),
  policy => {
    return get(policy, "attributes.policyValue", false);
  }
);
