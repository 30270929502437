import i18n from "i18next";

// Translations per language
import { en_ca_hh } from "./en_ca_hh";
import { en_ca_hosp } from "./en_ca_hosp";
import { en_ca_snf } from "./en_ca_snf";
import { en_us_hh } from "./en_us_hh";
import { en_us_hosp } from "./en_us_hosp";
import { en_us_snf } from "./en_us_snf";

/** Translation format/organization ------------------------------
 * Default name space (translation)
 * actions      - Button labels, calls to action (ex. select a role)
 * data         - translations for key's and values and inputs to api resources/models
 * headers      - all header text
 * interaction  - I/O, api operations user feedback (ex. api error)
 * text         - paragraph text
 * validations  - front end validation text (ex. input needs to be greater than #)
 *
 * glossary name space
 * - single words reused in other texts
 */

i18n.init({
  compatibilityJSON: "v2",
  lng: "en_us_snf",
  resources: {
    en_ca_hh,
    en_ca_hosp,
    en_ca_snf,
    en_us_hh,
    en_us_hosp,
    en_us_snf
  }
});

i18n.setLocale = locale => {
  const langDetails = i18n.language.split("_");
  const language = langDetails[0];
  const context = langDetails[2];
  i18n.changeLanguage(`${language}_${locale}_${context}`);
};

i18n.setCareContext = careContext => {
  const langDetails = i18n.language.split("_");
  const language = langDetails[0];
  const locale = langDetails[1];
  i18n.changeLanguage(`${language}_${locale}_${careContext}`);
};

export default i18n;
