import { createLoadDataAction } from "src/actions/data_actions/create_load_data_action";
import { resourceNames } from "src/constants";
import { healingIndexApi, averageHealingIndexApi } from "src/api/healing_index";

export const loadHealingIndexAction = createLoadDataAction({
  resourceName: resourceNames.healingIndex,
  api: healingIndexApi
});

export const loadAverageHealingIndexAction = createLoadDataAction({
  resourceName: resourceNames.averageHealingIndex,
  api: averageHealingIndexApi
});
