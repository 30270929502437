import get from "lodash.get";

export function createLocalizationDictionary({ assessmentSchemas = {} }) {
  const assessmentSchemasArray = Object.values(assessmentSchemas);
  const woundSchemas = assessmentSchemasArray.filter(schema => {
    return get(schema, "attributes.schemaJson.name", "") === "wound";
  });
  const localizationDictionaryByWoundAssessment = woundSchemas.reduce(
    (map, woundSchema) => {
      const childSchemas = getAllChildSchemasForSchema(
        woundSchema,
        assessmentSchemasArray
      );
      const localizedWoundSchemaTranslations = createFlattenedLocalizationDictionaryForSchemas(
        childSchemas
      );
      map[
        woundSchema.attributes.assessmentId
      ] = localizedWoundSchemaTranslations;
      return map;
    },
    {}
  );

  return localizationDictionaryByWoundAssessment;
}

export const getAllChildSchemasForSchema = (
  woundSchema,
  assessmentSchemasArray
) => {
  const childrenObjects = get(
    woundSchema,
    "attributes.schemaJson.components",
    null
  );
  const componentIdsArray = childrenObjects
    ? Object.values(childrenObjects)
    : [];
  const componentsArray = componentIdsArray.map(id => {
    return assessmentSchemasArray.find(schema => {
      return schema.attributes.assessmentId === id;
    });
  });
  return componentsArray;
};

export const createFlattenedLocalizationDictionaryForSchemas = schemas => {
  if (!schemas || !Object.keys(schemas).length) return {};

  const flattenedDictionary = schemas.reduce((acc, elem) => {
    //Deep clone each schena elem so we can do delete operations on keys.
    const dictionary = JSON.parse(
      JSON.stringify(
        get(elem, "attributes.schemaJson.localizationDictionary.base", {})
      )
    );
    const schemaName = get(elem, "attributes.schemaJson.name", {});
    const title = dictionary.title;
    delete dictionary.title;
    dictionary[`${schemaName}.title`] = title;
    return {
      ...acc,
      ...dictionary
    };
  }, {});
  return flattenedDictionary;
};
