import axios from "axios";

/**
 * change password for users
 * @param {Object} payload - responsible for sending the data to api.
 * @return {Promise} Promise with the response
 */
export function changePassword(payload) {
  const authHost = window.AUTH_HOST || process.env.REACT_APP_AUTH_HOST;
  return axios.put(`${authHost}/users/update-password`, payload);
}
