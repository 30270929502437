import { createSelector } from "reselect";
import { decamelize } from "humps";

// Constants
import { resourceNames } from "src/constants";

// Selectors
import {
  selectedFacilityIdsSelector,
  admissionStatusFilterSelector,
  monitorAtHomeStatusFilterSelector,
  patientNameOrMRNOrCodeSearchStringSelector
} from "src/selectors/section_selectors/admin";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";

// Api
import { createPostPayload } from "src/api/csv_exports/payload_generators";

// Utils
import { getUTCOffsetString } from "src/utils/get_UTC_offset_string/index.js";

export const patientCsvExportPostPayloadSelector = createSelector(
  currentUserSelector,
  selectedFacilityIdsSelector,
  admissionStatusFilterSelector,
  monitorAtHomeStatusFilterSelector,
  patientNameOrMRNOrCodeSearchStringSelector,
  (
    currentUser,
    facilityIds,
    admissionState,
    monitorAtHomeState,
    patientSearch
  ) => {
    return createPostPayload({
      type: decamelize(resourceNames.csvExports),
      workerType: decamelize(resourceNames.patients),
      userId: currentUser ? currentUser.id : "",
      userOffset: getUTCOffsetString(),
      parameters: {
        filter: {
          facility_id: facilityIds ? facilityIds.join() : undefined,
          state:
            admissionState.length > 0
              ? admissionState.join()
              : "new,admitted,discharged",
          patient_user_status:
            monitorAtHomeState.length > 0
              ? monitorAtHomeState.join()
              : "not_invited,invited,locked,accepted",
          name_or_mrn_or_code: patientSearch ? patientSearch : undefined
        }
      }
    });
  }
);
