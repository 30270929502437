import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Components
import { SelectComponent } from "src/components/ui_kit";
import { CloseIconComponent } from "src/components/icon_components";

// Container
import { RevisionAssessmentAnswersContainer } from "src/containers/revision_assessment_answers_container";
import { RevisionSummaryContainer } from "src/containers/revision_summary_container";

// Styles
import styles from "./style.module.less";

export class RevisionsComparisonItemComponent extends React.PureComponent {
  static propTypes = {
    seriesId: PropTypes.string.isRequired,
    revisions: PropTypes.array,
    selectedRevisionId: PropTypes.string,
    index: PropTypes.number.isRequired,
    highlightColor: PropTypes.string,
    revisionSelectionEnabled: PropTypes.bool,

    sendSelectRevisionAction: PropTypes.func.isRequired,
    sendRemoveRevisionItemAction: PropTypes.func.isRequired,

    currentFrameTab: PropTypes.string.isRequired,
    setCurrentFrameTab: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showIndexColor: false,
    revisionSelectionEnabled: true
  };

  onSelectRevision = revisionId => {
    const { seriesId, index, sendSelectRevisionAction } = this.props;
    sendSelectRevisionAction({ seriesId, revisionId, index });
  };

  onRemoveItemClicked = () => {
    const { seriesId, index, sendRemoveRevisionItemAction } = this.props;
    sendRemoveRevisionItemAction({ seriesId, index });
  };

  render() {
    const {
      revisions,
      selectedRevisionId,
      highlightColor,
      revisionSelectionEnabled
    } = this.props;
    const selectedRevision = revisions.find(r => r.id == selectedRevisionId);
    const selectOptions = this.props.revisions.map(rev => {
      return {
        key: rev.id,
        value: rev.id,
        label: moment(rev.attributes.createdAt).format("MMM Do YYYY - kk:mm")
      };
    });
    selectOptions.unshift({
      key: "undefined",
      value: "",
      label: "Select Evaluation Date"
    });

    let borderStyle = {
      borderColor: highlightColor || undefined,
      borderWidth: highlightColor ? "2px" : undefined
    };

    return (
      <div className={styles["comparison-item-container"]}>
        <div
          style={borderStyle}
          className={styles["comparison-item-top-container"]}
        >
          <div
            className={styles["x-button"]}
            onClick={this.onRemoveItemClicked}
          >
            <CloseIconComponent />
          </div>
          <div className={styles["revision-selector-container"]}>
            <SelectComponent
              disabled={!revisionSelectionEnabled}
              value={selectedRevisionId}
              options={selectOptions}
              onChange={this.onSelectRevision}
            />
          </div>
          <div>
            {selectedRevisionId && (
              <RevisionSummaryContainer 
                revisionId={selectedRevisionId} 
                currentFrameTab={this.props.currentFrameTab} 
                setCurrentFrameTab={this.props.setCurrentFrameTab}
              />
            )}
          </div>
        </div>
        <div className={styles["revision-assessment-answer-container"]}>
          {selectedRevision && (
            <RevisionAssessmentAnswersContainer revision={selectedRevision} />
          )}
        </div>
      </div>
    );
  }
}
