import { getEventStringsObject } from "src/models/events";

export function getESignature(latestSignedEvent, assessmentAnswerSignState) {
  let signatureText =
    "Signature:____________________________________________________________   Date:_____________________________";

  const signedObject = latestSignedEvent
    ? getEventStringsObject(latestSignedEvent)
    : undefined;

  if (assessmentAnswerSignState === "signed" && signedObject) {
    signatureText = getEventString(signedObject);
  }

  return signatureText;
}

function getEventString(eventObject) {
  let {
    eventType,
    date,
    userFullName,
    userDesignation,
    metadata
  } = eventObject;
  let revised = "";
  if (eventType === "Signed") {
    eventType = "eSigned";
  }

  if (metadata.resigned) revised = " - Revised";

  const dateAndUserString = `${date} ${userFullName ? userFullName : ""} ${
    userDesignation ? userDesignation : ""
  }`.toUpperCase();

  return `${dateAndUserString} (${eventType}${revised})`;
}
