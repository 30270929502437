import QRCode from "qrcode";

import { swiftQrCodeStringFromPatient } from "../swift_qr_code_string_from_patient";

/**
 * Generate DataURL with patient qr code
 * @param {Object} patient
 * @returns {Promise}
 */
export function getPatientQrCodeDataURL(patient) {
  return QRCode.toDataURL(swiftQrCodeStringFromPatient(patient));
}
