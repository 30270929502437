import { trackError } from "src/error_tracking";

import Moment from "moment";

// API
import { fetchIncidencePrevalenceChartData } from "src/api/population";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

/**
 * Action creator for setting the population load state to loading
 * @return {Object} redux action
 */
function populationIncidencePrevalenceChartDataLoadingAction() {
  return {
    type: dataActionTypes.populationIncidencePrevalenceChartDataLoading
  };
}

/**
 * Action creator for setting the population load state to error
 * @return {Object} redux action
 */
function populationIncidencePrevalenceChartDataLoadErrorAction() {
  return {
    type: dataActionTypes.populationIncidencePrevalenceChartDataLoadError
  };
}

/**
 * Action creator for populating the population chart data.
 * @param {Object} chartData the formatted chart data
 * @return {Object} redux action
 */
function populatePopulationIncidencePrevalenceChartDataAction(chartData) {
  return {
    type: dataActionTypes.populatePopulationIncidencePrevalenceChartData,
    payload: chartData
  };
}

/**
 * Action creator to fetch population chart data - new api
 */
export function loadPopulationIncidencePrevalenceAction(payload) {
  return dispatch => {
    dispatch(populationIncidencePrevalenceChartDataLoadingAction());
    startTimeTrack("API_GET_POPULATION_INCIDENCE_PREVALENCE_CHART");
    return fetchIncidencePrevalenceChartData(payload)
      .then(response => {
        const chartData = calculateChartData(response.data);
        dispatch(
          populatePopulationIncidencePrevalenceChartDataAction(chartData)
        );
        finishTimeTrack("API_GET_POPULATION_INCIDENCE_PREVALENCE_CHART");
      })
      .catch(error => {
        // If the request was cancelled it means a new request started
        // don't show the loading view.
        if (error.message == "cancel") {
          return;
        }
        trackError(error);
        dispatch(populationIncidencePrevalenceChartDataLoadErrorAction());
        finishTimeTrack("API_GET_POPULATION_INCIDENCE_PREVALENCE_CHART", {
          error: true,
          errorCode: error.response ? error.response.status : undefined
        });
      });
  };
}

/**
 * Action creator for calculating the population chart data
 * @param {Object} result, result from api call and manipulate the data for calcualtion
 */
function calculateChartData(result) {
  const labels = result.map(point =>
    new Moment(point.timeIntervalStart).format("MMM Do")
  );
  const incidenceData = result.map(point => point.incidence);
  const prevalenceData = result.map(point => point.prevalence);
  return {
    labels,
    incidenceData,
    prevalenceData
  };
}
