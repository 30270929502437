import moment from "moment";
import i18n from "src/translations";

/**
 * get event string for an event
 * @param {Object} event
 * @returns {string} the string describing the event
 */
export function getEventStringsObject(event) {
  const {
    createdAt,
    resourceCreatedAt,
    eventType,
    userFullName,
    userDesignation,
    metadata
  } = event.attributes;

  const dateCreatedAt = resourceCreatedAt || createdAt;

  const dateString = moment(dateCreatedAt)
    .format("MMM-DD-YYYY HH:mm")
    .toUpperCase();

  const eventTypeString = i18n.t(`data.eventTypes.${eventType}`);

  const userFullNameAndDesignation =
    userFullName + (userDesignation ? ` - ${userDesignation}` : "");

  return {
    eventType: eventTypeString,
    date: dateString,
    userNameAndDesignation: userFullNameAndDesignation,
    userFullName,
    userDesignation,
    metadata
  };
}
