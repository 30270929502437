/**,
 * The names of the json-api resource,
 */
export const resourceNames = {
  assessmentAnswers: "assessmentAnswers",
  assessmentSchemas: "assessmentSchemas",
  assessmentSummaries: "assessmentSummaries",
  assessments: "assessments",
  averageHealingIndex: "averageProfile",
  csvExports: "csvExports",
  customRoles: "customRoles",
  encounters: "encounters",
  events: "events",
  facilities: "facilities",
  floors: "facilityFloors",
  frames: "frames",
  healingIndex: "healingIndex",
  localExports: "localExports",
  organizations: "organizations",
  otpCode: "otpCode",
  patients: "patients",
  payers: "payers",
  payerPatients: "payerPatients",
  payerRevisions: "payerRevisions",
  permissionTypes: "permissionTypes",
  regions: "regions",
  revisions: "revisions",
  roles: "roles",
  ruleTemplates: "ruleTemplates",
  rules: "rules",
  savedFilters: "savedFilters",
  series: "series",
  seriesTrends: "seriesTrends",
  studies: "studies",
  tallies: "tallies",
  units: "facilityUnits",
  userAlerts: "userAlerts",
  users: "users",
  woundClinicalCharts: "woundClinicalCharts",
  woundRevisions: "woundRevisions",
  woundSeries: "woundSeries",
  woundTallies: "woundTallies"
};
