import React from "react";
import PropTypes from "prop-types";

// Translations
import i18n from "src/translations";

// Components
import { CheckboxList, CheckboxListItem } from "../checkbox_list";
import { CollapsibleContainerComponent } from "../collapsible_container_component";

// Analytics
import { track } from "src/analytics";
export class SideBarMonitorAtHomeStatusFilterComponent extends React.Component {
  static propTypes = {
    monitorAtHomeStatusFilter: PropTypes.array.isRequired,
    sendSetMonitorAtHomeStatusFilterAction: PropTypes.func.isRequired,
    sendDeselectMonitorAtHomeStatusFilterAction: PropTypes.func.isRequired
  };

  /**
   * Handler for checkboxes - sets the Monitor at home status filter
   * @param {string} value value for the filter
   */

  onFilterChanged = value => {
    if (!this.isFilterChecked(value)) {
      this.props.sendSetMonitorAtHomeStatusFilterAction(value);
      track("SELECT_MONITOR_AT_HOME_STATUS_FILTER", {
        monitorAtHomeStatusFilter: value
      });
    } else {
      this.props.sendDeselectMonitorAtHomeStatusFilterAction(value);
      track("DESELECT_MONITOR_AT_HOME_STATUS_FILTER", {
        monitorAtHomeStatusFilter: value
      });
    }
  };

  /**
   * Checks if a filter for a given filter value is checked
   */
  isFilterChecked(value) {
    return (
      this.props.monitorAtHomeStatusFilter.find(filter => filter == value) !=
      undefined
    );
  }

  /**
   * Render the checkboxes
   */
  renderCheckboxes() {
    return (
      <CheckboxList>
        <CheckboxListItem
          label={i18n.t("data.monitorAtHomeStatus.not_invited")}
          value="not_invited"
          checked={this.isFilterChecked("not_invited")}
          onChange={this.onFilterChanged}
        />
        <CheckboxListItem
          label={i18n.t("data.monitorAtHomeStatus.invited")}
          value="invited"
          checked={this.isFilterChecked("invited")}
          onChange={this.onFilterChanged}
        />
        <CheckboxListItem
          label={i18n.t("data.monitorAtHomeStatus.locked")}
          value="locked"
          checked={this.isFilterChecked("locked")}
          onChange={this.onFilterChanged}
        />
        <CheckboxListItem
          label={i18n.t("data.monitorAtHomeStatus.accepted")}
          value="accepted"
          checked={this.isFilterChecked("accepted")}
          onChange={this.onFilterChanged}
        />
      </CheckboxList>
    );
  }

  render() {
    return (
      <CollapsibleContainerComponent
        label={i18n.t("data.monitorAtHomeStatus.title")}
      >
        {this.renderCheckboxes()}
      </CollapsibleContainerComponent>
    );
  }
}
