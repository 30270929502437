// React
import React, { Component } from "react";
import i18n from "src/translations";
import classnames from "classnames";
// Css
import styles from "../style.module.less";

export class PopulationTallyTableRowByEtiologyComponent extends Component {
  // ------------------- Get Row Context Data ------------------- //

  renderWoundTypeContextData() {
    const { tally, schemaLocalizationDictionary } = this.props;
    if (tally.tallyType == "etiology" && tally.woundSubtype == null) {
      return (
        <td className={`${styles["left-align"]} ${styles["right-border"]}`}>
          {schemaLocalizationDictionary[`woundType.${tally.woundType}`] ||
            "Undiagnosed"}
        </td>
      );
    } else if (tally.tallyType == "total") {
      return (
        <td className={`${styles["left-align"]} ${styles["right-border"]}`}>
          All Selected Types
        </td>
      );
    } else if (tally.woundSubtype != null && tally.woundType != null) {
      return (
        <td
          className={`${styles["left-align-indent"]} ${styles["right-border"]}`}
        >
          {schemaLocalizationDictionary[
            `${tally.woundType}.${tally.woundSubtype}`
          ] || i18n.t("text.notSet")}
        </td>
      );
    }
  }

  renderNewWoundPatientsContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.newWoundPatients}</td>;
  }

  renderWoundPatientsContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.woundPatients}</td>;
  }

  renderIncidenceContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.incidence}</td>;
  }

  renderPrevalenceContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.prevalence}</td>;
  }

  // ------------------- Render ------------------- //

  render() {
    const { tally } = this.props;
    let rowClassName =
      styles[
        classnames({
          "total-row": tally.tallyType == "total",
          "wound-type-row": tally.tallyType == "etiology"
        })
      ];

    return (
      <tr className={rowClassName}>
        {this.renderWoundTypeContextData()}
        {this.renderNewWoundPatientsContextData()}
        {this.renderWoundPatientsContextData()}
        {this.renderIncidenceContextData()}
        {this.renderPrevalenceContextData()}
      </tr>
    );
  }
}
