import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Component
import { WoundAssessmentProgressTabViewComponent } from "src/components/wound_assessment_progress_tab_view_component";

// HOCs
import { withWoundAssessmentsLoaderFactory } from "src/hoc";

//Selectors
import { woundAssessmentsResourceSelectors } from "src/selectors/data_selectors";
import { selectedProgressFilterSelector } from "src/selectors/section_selectors/wounds";
import { progressValuesWithoutHealedSelector } from "src/selectors/constant_selectors";
//Context Selector
import { assessmentsFirstPageContextAllProgressValuesSelector } from "src/selectors/context_selectors/wounds";

// Actions
import { setSelectedProgressFilterAction } from "src/actions/section_actions/wounds";

//Query Payload and Context Selectors
import { createAssessmentsFirstPageContextSelectorWithProgress } from "src/selectors/context_selectors/wounds";
import { createAssessmentsFirstPagePayloadSelectorWithProgress } from "src/selectors/query_selectors/wounds";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

function mapStateToProps(state) {
  const progressValues = progressValuesWithoutHealedSelector(state);
  const firstPageContextsLoadStates = {};
  progressValues.forEach(progress => {
    const context = createAssessmentsFirstPageContextSelectorWithProgress(
      progress
    )(state);
    firstPageContextsLoadStates[
      progress
    ] = woundAssessmentsResourceSelectors.createLoadStateForContextSelector(
      context
    )(state);
  });
  return {
    woundOverallProgressCounts: woundAssessmentsResourceSelectors.createWoundOverallProgressCountSelector(
      assessmentsFirstPageContextAllProgressValuesSelector
    )(state),
    progressValues,
    assessmentVersion: assessmentVersionSelector(state),
    selectedProgressFilter: selectedProgressFilterSelector(state),
    firstPageContextsLoadStates
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetSelectedProgressFilterAction(value) {
      dispatch(setSelectedProgressFilterAction(value));
    }
  };
}

export const WoundAssessmentProgressTabViewContainer = flowRight(
  withWoundAssessmentsLoaderFactory(
    createAssessmentsFirstPageContextSelectorWithProgress,
    createAssessmentsFirstPagePayloadSelectorWithProgress
  ),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WoundAssessmentProgressTabViewComponent);
