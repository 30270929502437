import { trackError } from "src/error_tracking";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";

// Api
import { fetchWoundTotalsTimeSeriesByLocation } from "src/api/wound_assessments";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

/**
 * Action creator for setting the load state of the wound time series to "loading"
 * @return {Object} redux action
 */
function woundAssessmentTotalsTimeSeriesByLocationLoadingAction() {
  return {
    type: dataActionTypes.woundAssessmentTotalsTimeSeriesByLocationLoading
  };
}

/**
 * Action creator for setting the load state of the wound time series to "loaded"
 * @return {Object} redux action
 */
function woundAssessmentTotalsTimeSeriesByLocationLoadedAction() {
  return {
    type: dataActionTypes.woundAssessmentTotalsTimeSeriesByLocationLoaded
  };
}

/**
 * Action creator for setting the load state of the wound time series to "load error"
 * @return {Object} redux action
 */
function woundAssessmentTotalsTimeSeriesByLocationLoadErrorAction() {
  return {
    type: dataActionTypes.woundAssessmentTotalsTimeSeriesByLocationLoadError
  };
}

/**
 * Action creator for populating the wound assessment time series by location data
 * structure.
 * @param {Object} woundCountTimeSeries time series arrays broken down by locationId
 * @return {Object} redux action
 */
function populateWoundAssessmentTotalsTimeSeriesByLocationAction(
  woundCountTimeSeries
) {
  return {
    type: dataActionTypes.populateWoundAssessmentTotalsTimeSeriesByLocation,
    payload: woundCountTimeSeries
  };
}

/**
 * Action creator for loading a time series of total wounds in each location.
 * @param {Object} queryParams the query params for filtering wounds
 * @param {Array} locationIds the locations to get chart data for
 */
export function loadWoundAssessmentTotalsTimeSeriesByLocationAction(
  queryParams,
  locationIds
) {
  return dispatch => {
    startTimeTrack("API_GET_WOUNDS_DASHBOARD_ADMINISTRATIVE_CHART");
    dispatch(woundAssessmentTotalsTimeSeriesByLocationLoadingAction());

    const loadPromises = locationIds.map(id => {
      return fetchWoundTotalsTimeSeriesByLocation(queryParams, id).then(
        response => {
          const chartData = {};

          // Put chart data for this location into normalized structure
          chartData[id] = {
            id,
            time: response.data.chart.map(data => data.time_interval_start),
            woundTotals: response.data.chart.map(data => data.total_wound_count)
          };

          dispatch(
            populateWoundAssessmentTotalsTimeSeriesByLocationAction(chartData)
          );
        }
      );
    });

    return Promise.all(loadPromises)
      .then(() => {
        finishTimeTrack("API_GET_WOUNDS_DASHBOARD_ADMINISTRATIVE_CHART");
        dispatch(woundAssessmentTotalsTimeSeriesByLocationLoadedAction());
      })
      .catch(err => {
        if (err.message == "cancel") {
          return;
        }
        finishTimeTrack("API_GET_WOUNDS_DASHBOARD_ADMINISTRATIVE_CHART", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
        trackError(err);
        dispatch(woundAssessmentTotalsTimeSeriesByLocationLoadErrorAction());
      });
  };
}
