import { createResourceActions } from "src/redux_resources_v1";

export function createRemoveResourceByIdAction({ resourceName }) {
  return ({ id }) => {
    return dispatch => {
      const resourceActions = createResourceActions(resourceName);
      dispatch(resourceActions.removeResourceByIdAction(id));
    };
  };
}
