import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid/v1";

// Api
import { configureApi } from "src/api/config";

// Analtyics
import { track, registerParamsForAllEvents, userInit } from "src/analytics";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

export class AppConfigurationComponent extends React.PureComponent {
  static propTypes = {
    query: PropTypes.object,
    sendSetModeAction: PropTypes.func.isRequired,
    sendSetThemeAction: PropTypes.func.isRequired,
    sendSetPartnerNameAction: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.configureApp();
  }

  componentDidUpdate(prevProps) {
    const { isIntegration } = this.props;
    if (isIntegration && propsAreDifferent(prevProps, this.props, "user")) {
      userInit(this.props.user);
      track("NEW_DASHBOARD_SESSION");
    }
  }

  configureApp() {
    const {
      query,
      sendSetModeAction,
      sendSetThemeAction,
      sendSetPartnerNameAction,
      sendSetSessionId,
      sessionId,
      user,
      accessToken
    } = this.props;
    if (!query) return;

    const { mode, theme, partner } = query;

    const userId = user ? user.id : null;

    pendo.initialize({
      visitor: { id: userId || "no_user_id" },
      account: { id: "Swift Dashboard Test" }
    });

    if (accessToken) {
      configureApi(accessToken, userId);

      let newSessionId = uuid();

      sendSetSessionId(newSessionId);

      registerParamsForAllEvents({
        session_id: newSessionId
      });

      if (user) {
        userInit(user);
        track("NEW_DASHBOARD_SESSION");
      }
    } else if (sessionId) {
      // Register if session id exists
      registerParamsForAllEvents({
        session_id: sessionId
      });
    }

    if (mode) {
      sendSetModeAction(mode);
    }

    if (theme) {
      sendSetThemeAction(theme);
    }

    if (partner) {
      // Register partner name to be sent with all mixpanel events
      registerParamsForAllEvents({
        partner: query.partner
      });

      sendSetPartnerNameAction(query.partner);
    }
  }

  // TODO: move the rest of configuration here
  // configureErrorTracking() {}
  // configureAnalytics() {}

  render() {
    return this.props.children;
  }
}
