import { addReducer } from "incremental-redux-reducers";

export default addReducer(
  "locationSparkAggregateRisk",
  function locationSparkAggregateRiskReducer(state = {}, action) {
    switch (action.type) {
      case "LOCATION_AGGREGATE_SPARK_RISK_LOAD":
        let { locationId, chart } = action;

        return {
          ...state,
          [locationId]: chart
        };
      default:
        return state;
    }
  }
);
