import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { withRouter } from "react-router-dom";

//loaders
import { withPartnersLoader } from "src/hoc";

//selectors
import { partnersArraySortSelector } from "src/selectors/data_selectors";
import { adminPartnerDropDownEnabledSelector } from "src/selectors/config_selectors";
import { adminUsersSectionEnabledSelector } from "src/selectors/ui_config_selectors/admin";

//Action
import { selectedPartnerIdAction } from "src/actions/section_actions/admin";

//components
import { AdminSectionComponent } from "src/components/admin_section_component";

function mapStateToProps(state) {
  return {
    partners: partnersArraySortSelector(state),
    partnerDropDownEnabled: adminPartnerDropDownEnabledSelector(state),
    usersSectionEnabled: adminUsersSectionEnabledSelector(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sendSelectPartnerAction: id => {
      dispatch(selectedPartnerIdAction(id));
    }
  };
}
export const AdminSectionContainer = flowRight(
  withPartnersLoader,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AdminSectionComponent);
