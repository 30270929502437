import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "react-bootstrap";

// Constants
import { loadStates } from "src/constants/load_states";
import { modalModes } from "src/constants";

// Containers
import {
  AdminUsersTableContainer,
  AdminRolesTableContainer
} from "src/containers/tables";
import {
  CreateUserFormContainer,
  CreateRoleFormContainer
} from "src/containers/forms";

// Styles
import styles from "./style.module.less";

// Components
import { LoadingComponent } from "src/components/loading_component";
import {
  PanelHeaderRightComponent,
  PanelBodyComponent,
  PanelComponent
} from "src/components/panel";
import { ImportUserCsvFormContainer } from "src/containers/import_csv_form_container";
import {
  AdminUserNameSearchBarContainer,
  AdminUserRoleSelectContainer,
  AdminUserStateSelectContainer,
  AdminUserTableResetFiltersContainer
} from "src/containers/admin_users_filter_containers";
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";
import { ButtonComponent } from "src/components/button_component";
import { FlexBox, FlexItem } from "src/components/ui_kit";

// Translations
import i18n from "src/translations";

/**
 * Following component is used for load the users and roles table
 * data.
 * usersLoadState: Check the users table load state
 * customRolesLoadState: check roles table load state
 */
export class AdminUsersComponent extends React.PureComponent {
  static propTypes = {
    usersLoadState: PropTypes.string,
    rolesLoadState: PropTypes.string.isRequired,
    customRolesLoadState: PropTypes.string.isRequired,
    permissionLoadState: PropTypes.string.isRequired,
    usersCsvExportEnabled: PropTypes.bool.isRequired,
    usersCsvPostPayload: PropTypes.object,
    sendPostUsersCsvExportAction: PropTypes.func.isRequired,
    userCreateEnabled: PropTypes.bool.isRequired,
    roleCreateEnabled: PropTypes.bool.isRequired
  };

  state = {
    initialValues: {},
    showUserCreateModal: false,
    showImportUserModal: false,
    showRoleCreateModal: false
  };

  /**
   * Event handler for the create admin users csv
   */

  onClickCreateUsersCsv = () => {
    const { sendPostUsersCsvExportAction, usersCsvPostPayload } = this.props;
    sendPostUsersCsvExportAction(usersCsvPostPayload);
  };

  onClickUserCreate = () => {
    this.setState({ ...this.state, showUserCreateModal: true });
  };

  onClickRoleCreate = () => {
    this.setState({ ...this.state, showRoleCreateModal: true });
  };

  onClickImport = () => {
    this.setState({ ...this.state, showImportUserModal: true });
  };

  hideUserCreateModal = () => {
    this.setState({ ...this.state, showUserCreateModal: false });
  };

  hideImportUserModal = () => {
    this.setState({ ...this.state, showImportUserModal: false });
  };

  hideRoleCreateModal = () => {
    this.setState({ ...this.state, showRoleCreateModal: false });
  };

  isLoading = loadState => {
    return !loadState || loadState === loadStates.loading;
  };

  hasLoadError = loadState => {
    return loadState === loadStates.error;
  };

  render() {
    const {
      usersLoadState,
      rolesLoadState,
      customRolesLoadState,
      bulkImportUsersEnabled,
      permissionLoadState,
      usersCsvExportEnabled,
      userCreateEnabled,
      roleCreateEnabled
    } = this.props;

    const { isLoading, hasLoadError } = this;

    let usersComponent, roleComponent;
    if (
      isLoading(usersLoadState) ||
      isLoading(rolesLoadState) ||
      isLoading(customRolesLoadState)
    ) {
      usersComponent = (
        <StartRenderTimeTrackComponent eventName="RENDER_ADMIN_USER_TABLE">
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else if (
      hasLoadError(usersLoadState) ||
      hasLoadError(rolesLoadState) ||
      hasLoadError(customRolesLoadState)
    ) {
      usersComponent = (
        <FinishRenderTimeTrackComponent
          eventName="RENDER_ADMIN_USER_TABLE"
          error={true}
        >
          <LoadingComponent />
        </FinishRenderTimeTrackComponent>
      );
    } else
      usersComponent = (
        <FinishRenderTimeTrackComponent eventName="RENDER_ADMIN_USER_TABLE">
          <AdminUsersTableContainer />
        </FinishRenderTimeTrackComponent>
      );

    if (
      isLoading(rolesLoadState) ||
      isLoading(customRolesLoadState) ||
      isLoading(permissionLoadState)
    ) {
      roleComponent = (
        <StartRenderTimeTrackComponent eventName="RENDER_ADMIN_ROLE_TABLE">
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else if (
      hasLoadError(rolesLoadState) ||
      hasLoadError(customRolesLoadState) ||
      hasLoadError(permissionLoadState)
    ) {
      roleComponent = (
        <FinishRenderTimeTrackComponent
          eventName="RENDER_ADMIN_ROLE_TABLE"
          error={true}
        >
          <LoadingComponent />
        </FinishRenderTimeTrackComponent>
      );
    } else
      roleComponent = (
        <FinishRenderTimeTrackComponent eventName="RENDER_ADMIN_ROLE_TABLE">
          <AdminRolesTableContainer />
        </FinishRenderTimeTrackComponent>
      );

    return (
      <div>
        <PanelComponent labelShow="Users" enableCollapsible={true}>
          <PanelHeaderRightComponent>
            {userCreateEnabled && (
              <ButtonComponent onClick={this.onClickUserCreate}>
                {i18n.t("actions.addUser")}
              </ButtonComponent>
            )}

            {bulkImportUsersEnabled && (
              <ButtonComponent onClick={this.onClickImport}>
                {i18n.t("actions.importUsers")}
              </ButtonComponent>
            )}

            {usersCsvExportEnabled && (
              <ButtonComponent onClick={this.onClickCreateUsersCsv}>
                {i18n.t("actions.createCSV")}
              </ButtonComponent>
            )}
          </PanelHeaderRightComponent>
          <PanelBodyComponent>
            <FlexBox vAlign="center" spacing="small">
              <FlexItem grow>
                <AdminUserNameSearchBarContainer />
              </FlexItem>
              <FlexItem grow>
                <AdminUserRoleSelectContainer />
              </FlexItem>
              <FlexItem grow>
                <AdminUserStateSelectContainer />
              </FlexItem>
              <FlexItem className={styles["admin-users-reset-filters"]}>
                <AdminUserTableResetFiltersContainer />
              </FlexItem>
            </FlexBox>
            {usersComponent}
          </PanelBodyComponent>
        </PanelComponent>

        <PanelComponent labelShow="Roles" enableCollapsible={true}>
          <PanelHeaderRightComponent>
            {roleCreateEnabled && (
              <ButtonComponent onClick={this.onClickRoleCreate}>
                {i18n.t("actions.createRole")}
              </ButtonComponent>
            )}
          </PanelHeaderRightComponent>
          <PanelBodyComponent>{roleComponent}</PanelBodyComponent>
        </PanelComponent>
        <Modal
          show={this.state.showUserCreateModal}
          onHide={this.hideUserCreateModal}
          bsSize={"lg"}
        >
          <ModalBody>
            <CreateUserFormContainer cancel={this.hideUserCreateModal} />
          </ModalBody>
        </Modal>
        <Modal
          show={this.state.showRoleCreateModal}
          onHide={this.hideRoleCreateModal}
          bsSize={"lg"}
        >
          <ModalBody>
            <CreateRoleFormContainer
              cancel={this.hideRoleCreateModal}
              mode={modalModes.create}
            />
          </ModalBody>
        </Modal>

        <Modal
          show={this.state.showImportUserModal}
          onHide={this.hideImportUserModal}
          bsSize={"small"}
        >
          <ModalBody>
            <ImportUserCsvFormContainer
              initialValuesObject={this.state.initialValues}
              cancel={this.hideImportUserModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
