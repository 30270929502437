import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Selectors
import { patientsResourceSelectors } from "src/selectors/data_selectors";

// Actions
import { loadPatientAction } from "src/actions/data_actions";
import { createPatientForIdSelector } from "src/selectors/data_selectors/patients/create_patient_for_id_selector";

// Subscriptions
// import Subscriptions from "src/subscriptions";

/**
 * HOC to load a single patient
 * @param {Class} WrappedComponent
 * @returns {Class}
 */
export function withPatientLoader(WrappedComponent) {
  class PatientLoader extends React.Component {
    static propTypes = {
      patientLoadState: PropTypes.string,
      patient: PropTypes.object,
      match: PropTypes.object.isRequired,
      sendLoadPatientForPatientIdAction: PropTypes.func.isRequired
    };

    componentDidMount() {
      if (!this.props.patient && !this.props.patientLoadState) {
        const patientId = this.props.match.params.patientId;
        this.props.sendLoadPatientForPatientIdAction(patientId);
      }

      // this.subscribeToPatientChanges(this.props.match.params.patientId);
    }

    // componentWillUnmount() {
    //   this.unsubscribeFromPatientChanges(this.props.series);
    // }

    /**
     * Subscribe to changes for each series
     * @param {array} series
     */
    // subscribeToPatientChanges(patientId) {
    //   Subscriptions.subscribeToResourceChannelEvent({
    //     channelName: Subscriptions.resourceIdChannelNames.patient,
    //     resourceId: patientId,
    //     eventName: Subscriptions.channelEvents.change,
    //     callback: this.onPatientChange,
    //     callbackContext: {
    //       resourceId: patientId,
    //       loadResource: this.props.sendLoadPatientForPatientIdAction
    //     }
    //   });
    // }

    /**
     * Unsubscribe from changes to individual series
     * @param {array} series
     */
    // unsubscribeFromPatientChanges(series) {
    //   series.forEach(s => {
    //     Subscriptions.unsubscribeFromResourceChannelEvent({
    //       channelName:
    //         Subscriptions.resourceIdChannelNames.seriesAssessmentAnswer,
    //       resourceId: s.id,
    //       eventName: Subscriptions.channelEvents.change,
    //       callback: this.onSeriesChange
    //     });
    //   });
    // }

    /**
     * Callback for a series subscription
     */
    // onPatientChange() {
    //   this.loadResource(this.resourceId);
    // }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  /**
   * Map the props needed for loading data
   */
  function mapStateToProps(state, props) {
    const patientId = props.match.params.patientId;

    return {
      patientLoadState: patientsResourceSelectors.createContextLoadStateSelectorWithContextString(
        patientId
      )(state),
      patient: createPatientForIdSelector(patientId)(state)
    };
  }

  /**
   * Map dispatch to a props for loading data
   */
  function mapDispatchToProps(dispatch) {
    return {
      sendLoadPatientForPatientIdAction(patientId) {
        dispatch(loadPatientAction(patientId));
      }
    };
  }

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(PatientLoader)
  );
}
