import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";

export const hasReopenWoundRevisionsPermissionSelector = createSelector(
  createPolicyForInternalNameSelector(
    policyInternalNames.canReopenFromDashboard
  ),
  canReopenFromDashboard => {
    return (
      !!canReopenFromDashboard && canReopenFromDashboard.attributes.policyValue
    );
  }
);
