// React
import { connect } from "react-redux";

// selectors
import { populationTalliesByLocationDataSelector } from "src/selectors/data_selectors";

// Components
import { PopulationTallyTableByLocationComponent } from "src/components/tables";

// -------------- Prop & Component Connection -------------- //
function mapStateToProps(state) {
  return {
    talliesByLocation: populationTalliesByLocationDataSelector(state)
  };
}

export const PopulationTallyTableByLocationContainer = connect(mapStateToProps)(
  PopulationTallyTableByLocationComponent
);
