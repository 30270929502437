// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Translations
import i18n from "src/translations";

// Constants
import { resourceNames } from "src/constants";

// API
import { userAlertsApi } from "src/api/user_alerts";

// Actions
import { createLoadDataAction } from "../create_load_data_action";
import { createPutDataAction } from "../create_put_data_action";

import { create } from "src/actions/flashMessageActions";

export const userAlertsResourceActions = createResourceActions(
  resourceNames.userAlerts
);

export const loadUserAlertsAction = createLoadDataAction({
  resourceName: resourceNames.userAlerts,
  api: userAlertsApi,
  includedResourceNames: [resourceNames.patients, resourceNames.users]
});

export const resolveUserAlertAction = createPutDataAction({
  resourceName: resourceNames.userAlerts,
  api: userAlertsApi,
  onSuccess: dispatch => {
    dispatch(
      create(
        "This alert has been resolved successfully. Please refresh for an updated list of alerts.",
        "success"
      )
    );
  },
  onError: dispatch => {
    let errorCode = "default";
    const errorMessage = i18n.t(
      `interaction.errorMessages.userAlerts.resolve.${errorCode}`
    );
    dispatch(create(errorMessage, "error"));
  }
});
