export const styles = {
  // TEXT
  h1: {
    margin: [0, 10, 0, 10],
    fontSize: 16,
    bold: true
  },
  h2: {
    margin: [0, 0, 0, 5],
    fontSize: 14,
    bold: true
  },
  paragraph: {
    margin: [0, 0, 0, 3],
    fontSize: 12
  },

  // BODY
  columnBody: {
    marginBottom: 15
  },

  subSectionHeader: {
    marginTop: 20
  },

  // TABLE
  tallyTable: {
    hLineWidth: function() {
      return 0.1;
    },
    vLineWidth: function() {
      return 0.1;
    },
    hLineColor: function() {
      return "#ddd";
    },
    vLineColor: function() {
      return "#ddd";
    },
    paddingLeft: function() {
      return 0;
    },
    paddingRight: function() {
      return 0;
    }
  },
  tallyTableHeaderCell: {
    fontSize: 7,
    color: "#ffffff",
    bold: true,
    fillColor: "#a79f9e",
    alignment: "left",
    border: [true, true, true, true]
  },
  tallyTableHeaderCellBlackAndWhite: {
    fontSize: 7,
    color: "#000000",
    bold: true,
    fillColor: "#ffffff ",
    alignment: "left",
    border: [true, true, true, true]
  },
  tallyTableContentCell: {
    fontSize: 7,
    color: "#848484",
    fillColor: "#f6f6f6",
    border: [true, true, false, true]
  },
  tallyTableContentCellBlackAndWhite: {
    fontSize: 7,
    color: "#000000",
    fillColor: "#ffffff",
    border: [true, true, false, true]
  },
  tableLabelCell: {
    fontSize: 10,
    marginLeft: 5
  },
  tableDataCell: {
    fontSize: 10
  },
  tableFootnote: {
    alignment: "right",
    fontSize: 8
  },
  greenFont: {
    color: "#5cb85c"
  },
  redFont: {
    color: "#d9534f"
  }
};

export const defaultBrandingStyles = {
  // TABLE
  tableHeaderCell: {
    fontSize: 12,
    color: "white",
    fillColor: "#0057b8",
    alignment: "left",
    margin: [5, 2, 0, 2]
  }
};

export const pccBrandingStyles = {
  // TABLE
  tableHeaderCell: {
    fontSize: 12,
    color: "white",
    fillColor: "#353531",
    alignment: "left",
    margin: [5, 2, 0, 2]
  }
};

export const defaultStyle = {
  columnGap: 20
};
