import get from "lodash.get";

// Models
import { evaluatePredicate } from "src/models/assessment_answers";

/**
 * Evaluates if the assessment answer has missing required fields
 *
 * @param {Array} schemas
 * @param {Object} assessmentAnswer,
 *
 * @returns {boolean}
 */

export function isMissingRequiredFields(
  schemas,
  assessmentAnswer,
  revisionIsFirstInSeries
) {
  let hasAllRequiredFields = true;

  schemas.forEach(schema => {
    const questions = get(schema, "attributes.schemaJson.questions", []);
    const requiredQuestions = questions.filter(question => {
      if (question.visiblePredicateJson) {
        return (
          !!question.required &&
          !!evaluatePredicate({
            assessmentAnswer,
            revisionIsFirstInSeries,
            predicate: question.visiblePredicateJson
          })
        );
      } else {
        return !!question.required;
      }
    });
    requiredQuestions.forEach(question => {
      const hasQuestionValue = get(
        assessmentAnswer,
        `attributes.answersJson.${question.name}`,
        false
      );
      hasAllRequiredFields = hasAllRequiredFields && hasQuestionValue;
    });
  });

  return !hasAllRequiredFields;
}
