import React from "react";
import { Link } from "react-router-dom";
import get from "lodash.get";
import { assessmentTypes } from "src/constants";
import { woundEvaluationHyperlinkFormatter } from "../wound_evaluation_hyperlink_formatter";

import { formatBodyLocationString } from "src/utils/format_location_string";

//Translations
import i18n from "src/translations";

/**
 * Formatter that translates the body location
 * It expects resource attributes with an assessment type and answersJson
 * @param {object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.type the assessment type
 * @param {Object} row.attributes.answersJson the answers json
 * @param {Object} formatExtraData.localizationDictionary
 */
export function bodyLocationFormatter(cell, row, rowIndex, formatExtraData) {
  if (get(row, "attributes.type") == assessmentTypes.wound) {
    const woundLocation = get(row, "attributes.answersJson.location");
    const woundSublocation = get(row, "attributes.answersJson.sublocation");
    const woundLateralities = get(row, "attributes.answersJson.laterality");

    const assessmentId = get(row, "attributes.assessmentId", "");
    const localizationDictionary =
      formatExtraData.localizationDictionary[assessmentId] || {};

    let locationString = woundLocation
      ? formatBodyLocationString(
          {
            location: woundLocation,
            sublocation: woundSublocation,
            lateralities: woundLateralities
          },
          localizationDictionary
        )
      : i18n.t("text.notSet");

    locationString = locationString || i18n.t(`text.notSet`);

    return (
      <div onClick={e => formatExtraData.eventHandler(e, row)}>
        <Link to={location.pathname}>{locationString}</Link>
      </div>
    );
  }

  //User Alerts Body Location
  if (
    get(row, "attributes.resource.answersJson.header.type") ==
    assessmentTypes.wound
  ) {
    let bodyLocation = get(
      row,
      "attributes.resource.answersJson.location",
      "none"
    );
    return localizationDictionary
      ? localizationDictionary[`location.${bodyLocation}`]
      : i18n.t(`text.notSet`);
  }

  return woundEvaluationHyperlinkFormatter("N/A", row, formatExtraData);
}
