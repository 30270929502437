import {
	acquiredDefault
} from "./acquired_default";
import {
	acquiredV5Ashn
} from "./acquired_v5_ashn";

export const acquired = {
	default: acquiredDefault,
	ML1: acquiredDefault,
	v2: acquiredDefault,
	v3: acquiredDefault,
	v4: acquiredDefault,
	v4Revera: acquiredDefault,
	v5: acquiredDefault,
	v5Bwat: acquiredDefault,
	v5Ashn: acquiredV5Ashn,
	v5LHC: acquiredDefault,
	v6: acquiredDefault,
	v7: acquiredDefault,
	v8: acquiredDefault,
	v6LifeCare: acquiredDefault,
	v6MayoClinic: acquiredDefault
};
