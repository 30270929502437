import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { FixedAspectRatioComponent } from "src/components/fixed_aspect_ratio_component";
import { ImageComponent } from "src/components/image_component";

// Styles
import styles from "./style.module.less";

export class ThermalImageComponent extends React.PureComponent {
  static propTypes = {
    thermalImageLink: PropTypes.string.isRequired
  };

  state = {
    showImageModal: false
  };

  onClickThermalImage = () => {
    this.setState({
      ...this.state,
      showImageModal: true
    });
  };

  hideImageModal = () => {
    this.setState({
      ...this.state,
      showImageModal: false
    });
  };

  render() {
    const { thermalImageLink } = this.props;

    let aspectRatio = 1151 / 808;

    return (
      <FixedAspectRatioComponent maxWidth={1000} aspectRatio={aspectRatio}>
        <div className={styles["revision-visuals-container"]}>
          <div
            className={styles["image-container"]}
            onClick={() => this.onClickThermalImage()}
          >
            {thermalImageLink === "" ? (
              <div className={styles["imageText"]}>Photo Unavailable</div>
            ) : (
              <ImageComponent
                contentType="image"
                patientType="Patient"
                imageUrl={thermalImageLink}
                haveHealX={true}
              />
            )}
          </div>
          {thermalImageLink !== "" && (
            <Modal
              show={this.state.showImageModal}
              onHide={this.hideImageModal}
              className={styles["modal-container"]}
            >
              <div className={styles["image-modal-body"]}>
                <ImageComponent
                  contentType="image"
                  patientType="Patient"
                  imageUrl={thermalImageLink}
                  haveHealX={true}
                />
              </div>
            </Modal>
          )}
        </div>
      </FixedAspectRatioComponent>
    );
  }
}
