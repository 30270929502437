import { createSelector } from "reselect";

//Selectors
import { createLatestSignedEventForRevisionIdQuerySelector } from "src/selectors/query_selectors/events_for_revision_id";

/**
 * create a selector to get the context for the latest signed event with a revisionId
 * @param {Object} props
 * @param {string} props.revisionId
 * @returns {function} selector
 */

export function createLatestSignedEventForRevisionIdContextSelector({
  revisionId
}) {
  return createSelector(
    createLatestSignedEventForRevisionIdQuerySelector({ revisionId }),
    query => {
      return query;
    }
  );
}
