import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//Components
import { EvaluationListBradenTableComponent } from "src/components/tables/evaluation_list_braden_table_component";

//HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

//Actions
import {
  loadAssessmentSummariesAction,
  assessmentSummariesResourceActions
} from "src/actions/data_actions";

//Constants
import { analyticsEventNames } from "src/constants";

//Selectors
import { assessmentSummariesResourceSelectors } from "src/selectors/data_selectors";
import { createFirstPageContextSelector } from "src/selectors/api_selectors/evaluation_list/braden/create_first_page_context_selector";
import { createCurrentPageContextSelector } from "src/selectors/api_selectors/evaluation_list/braden/create_current_page_context_selector";
import { createCurrentPagePayloadSelector } from "src/selectors/api_selectors/evaluation_list/braden/create_current_page_payload_selector";
import { evaluationListBradenSectionSelector } from "src/selectors/section_selectors/evaluation_list/evaluation_list_braden_selector";
import { patientLinksEnabledSelector } from "src/selectors/config_selectors/patient_links_enabled_selector";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const bradenEvaluationPageLimitSelector = SingleValueReduxUtil.createSelectors(
  "bradenEvaluationPageLimit",
  evaluationListBradenSectionSelector
);

const setBradenEvaluationPageLimitAction = SingleValueReduxUtil.createActionCreators(
  "bradenEvaluationPageLimit"
);

function mapStateToProps(state) {
  const firstPageContextSelector = createFirstPageContextSelector();
  return {
    assessmentSummaries: assessmentSummariesResourceSelectors.createDataForContextSelector(
      createCurrentPageContextSelector()
    )(state),
    totalCount: assessmentSummariesResourceSelectors.createRecordCountSelector(
      createFirstPageContextSelector()
    )(state),
    pageLimit: bradenEvaluationPageLimitSelector.fieldSelector(state),
    pageCount:
      assessmentSummariesResourceSelectors.createPageCountSelector(
        firstPageContextSelector,
        bradenEvaluationPageLimitSelector.fieldSelector
      )(state) || 0,
    currentPage: assessmentSummariesResourceSelectors.createCurrentPageForContextSelector(
      firstPageContextSelector
    )(state),
    firstPageContext: firstPageContextSelector(state),
    patientLinksEnabled: patientLinksEnabledSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetPageLimitAction: limit => {
      dispatch(setBradenEvaluationPageLimitAction.setValue(limit));
    },
    sendSetPageAction: (context, pageNumber) => {
      dispatch(
        assessmentSummariesResourceActions.setPageForContextAction(
          context,
          pageNumber
        )
      );
    }
  };
}

export const EvaluationListBradenTableContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentSummariesAction,
    resourceSelectors: assessmentSummariesResourceSelectors,
    createContextSelector: createCurrentPageContextSelector,
    createPayloadSelector: createCurrentPagePayloadSelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: analyticsEventNames.renderUserEvaluationListBradenTable
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EvaluationListBradenTableComponent);
