// @flow
import { Set, List } from "immutable";
import Moment from "moment";
export default function mergeWithoutList(prev: any, next: any) {
  if (typeof next === "object") {
    if (List.isList(prev)) {
      return new List(next);
    }
    if (Set.isSet(prev)) {
      return new Set(next);
    }
    if (Moment.isMoment(next)) {
      return next;
    } else {
      if (prev && typeof prev === "object" && prev.mergeWith) {
        return prev.mergeWith(mergeWithoutList, next);
      }
      return next;
    }
  }
  return next;
}
