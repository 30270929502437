import { createSelector } from "reselect";

// Api
import { createGetPayload } from "src/api/assessment_summaries/payload_generators";
import {
  answersFilterSelector,
  selectedLocationIdsSelector,
  evaluationListBradenSectionSelector
} from "src/selectors/section_selectors/evaluation_list/evaluation_list_braden_selector";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const bradenEvaluationPageLimitSelector = SingleValueReduxUtil.createSelectors(
  "bradenEvaluationPageLimit",
  evaluationListBradenSectionSelector
);

export function createFirstPagePayloadSelector() {
  return createSelector(
    bradenEvaluationPageLimitSelector.fieldSelector,
    selectedLocationIdsSelector,
    answersFilterSelector,
    (pageLimit, selectedLocationIds, answers) => {
      if (!selectedLocationIds.length) return {};
      return createGetPayload({
        pageLimit,
        answers,
        patient_state: "admitted,new",
        selectedLocationIds,
        pageNumber: 0,
        sortEnabled: "created_at"
      });
    }
  );
}
