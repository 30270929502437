import uuidv1 from "uuid/v1";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { talliesApi } from "src/api/tallies";

export const talliesResourceActions = createResourceActions(
  resourceNames.tallies
);

export const loadTalliesAction = createLoadDataAction({
  resourceName: resourceNames.tallies,
  api: talliesApi,
  responseNormalizer: data => {
    return {
      [resourceNames.tallies]: data.reduce((agg, tally) => {
        const id = uuidv1();
        tally.id = id;
        agg[id] = tally;
        return agg;
      }, {})
    };
  }
});
