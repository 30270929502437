import { createSelector } from "reselect";

// Selectors
import { createFirstPageContextSelector } from "../create_first_page_context_selector";
import { userAlertsResourceSelectors } from "src/selectors/data_selectors";
import {
  selectedLocationIdsSelector,
  selectedEvaluationTypeSelector,
  alertsSectionSelector
} from "src/selectors/section_selectors/alerts";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const alertsTablePageLimitSelector = SingleValueReduxUtil.createSelectors(
  "alertsTablePageLimit",
  alertsSectionSelector
);

export function createCurrentPagePayloadSelector() {
  return createSelector(
    userAlertsResourceSelectors.createCurrentPageForContextSelector(
      createFirstPageContextSelector()
    ),
    alertsTablePageLimitSelector.fieldSelector,
    selectedLocationIdsSelector,
    selectedEvaluationTypeSelector,
    (currentPage, pageLimit, selectedLocationIds, selectedEvaluationType) => {
      return {
        filter: {
          ...(selectedEvaluationType && {
            evaluation_type: selectedEvaluationType
          }),
          non_resolved_only: "true",
          ...(selectedLocationIds &&
            selectedLocationIds.length && {
              latest_patient_location_id: selectedLocationIds.join(",")
            })
        },
        include: ["patient", "tripped_by_user"].join(","),
        page:
          currentPage >= 0 && pageLimit
            ? {
                offset: currentPage * pageLimit,
                limit: pageLimit
              }
            : undefined,
        sort: "-created_at"
      };
    }
  );
}
