import { createSelector } from "reselect";
import { usersResourceSelectors } from "src/selectors/data_selectors";
import { routeQueryParamsUserIdSelector } from "src/selectors/router_selectors";
import { userIdSelector } from "src/selectors/session_selectors";

export const currentUserSelector = createSelector(
  usersResourceSelectors.dataSelector,
  routeQueryParamsUserIdSelector,
  userIdSelector,
  (users, routeQueryParamsUserId, sessionUserId) => {
    let userId;
    if (!routeQueryParamsUserId && !sessionUserId) return null;

    if (routeQueryParamsUserId) {
      userId = routeQueryParamsUserId;
    } else if (sessionUserId) {
      userId = sessionUserId;
    }

    const usersDataArray = Object.values(users);
    const currentUser = usersDataArray.find(user => user.id === userId);
    return currentUser ? currentUser : null;
  }
);

import { currentUserDataSelector } from "src/selectors/data_selectors";

export const currentUserSelectorLegacy = createSelector(
  currentUserDataSelector,
  currentUser => {
    return currentUser ? currentUser : null;
  }
);
