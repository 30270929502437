import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

// Components
import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormInputOptionComponent } from "src/components/forms/form_input_option_component";
import { FormComponent } from "src/components/forms/form_component_legacy";
import { FormTitleComponent } from "src/components/forms/form_title_component";
import { GridRow, GridColumn } from "src/components/grid";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

// Styles
import styles from "./style.module.less";

export class PatientAdtFormComponent extends React.Component {
  static propTypes = {
    formName: PropTypes.string.isRequired,
    patient: PropTypes.object,
    facilities: PropTypes.object.isRequired,
    units: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    patientsAdtLoadState: PropTypes.string,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    changeField: PropTypes.func.isRequired,
    clearFields: PropTypes.func.isRequired
  };

  state = {
    initialValues: {}
  };

  constructor(props) {
    super(props);
    const { patient } = props;

    let initialValues = {};

    if (patient) {
      initialValues = this.getInitialValuesForPatient(patient);
    }

    this.state = {
      initialValues
    };
  }

  componentWillReceiveProps(nextProps) {
    const { changeField, clearFields } = this.props;

    if (propsAreDifferent(nextProps, this.props, "facilities")) {
      const facilities = Object.values(nextProps.facilities);
      if (facilities.length) {
        changeField("facility", nextProps.patient.attributes.facilityId);
      } else {
        clearFields("facility");
      }
    }

    if (propsAreDifferent(nextProps, this.props, "units")) {
      if (nextProps.units.length) {
        changeField("unit", nextProps.patient.attributes.facilityUnitId);
      } else {
        changeField("unit", null);
      }
    }

    if (propsAreDifferent(nextProps, this.props, "patient")) {
      this.setState({
        initialValues: this.getInitialValuesForPatient(nextProps.patient)
      });
    }
  }

  /**
   * Get initial form values for a patient adt form
   * @param {Object} patient
   * @return {Object} initial values
   */
  getInitialValuesForPatient(patient) {
    return {
      facility: patient.attributes.facilityId,
      unit: patient.attributes.facilityUnitId,
      firstName: patient.attributes.firstName,
      lastName: patient.attributes.lastName,
      mrn: patient.attributes.mrn,
      userId: patient.attributes.userId,
      shareToken: patient.attributes.shareToken,
      id: patient.id,
      gender: patient.attributes.gender,
      birthDate: patient.attributes.birthAt
        ? moment(patient.attributes.birthAt)
            .utcOffset(0)
            .format("YYYY-MM-DD")
        : null,
      clientId: patient.attributes.admissionId
    };
  }

  /**
   * Event handler for the create ADT for patient
   */
  createAdtPatient = formValues => {
    const payload = {
      patient: this.props.patient,
      adt_event: {
        event_type: formValues.action,
        facility_id: formValues.facility,
        organization_id: this.props.patient.attributes.organizationId,
        facility_unit_id: formValues.unit,
        client_id: formValues.clientId
      }
    };
    this.props.createPatientADT(payload).then(() => {
      this.props.cancel();
    });
  };
  renderActionTypes = patient => {
    const defultEmptyOption = <FormInputOptionComponent text="" value={null} />;
    if (
      !patient.attributes.state ||
      patient.attributes.state == "new" ||
      patient.attributes.state == "discharged"
    )
      return (
        <FormInputComponent
          name="action"
          component="input"
          componentType="select"
          labelText="Action"
        >
          {defultEmptyOption}
          <FormInputOptionComponent
            text={
              patient.attributes.state === "discharged" ? "Readmit" : "Admit"
            }
            value="A01"
          />
        </FormInputComponent>
      );
    else if (patient.attributes.state == "admitted") {
      return (
        <FormInputComponent
          name="action"
          component="input"
          componentType="select"
          labelText="Action"
        >
          {defultEmptyOption}
          <FormInputOptionComponent text="Discharge" value="A03" />
          <FormInputOptionComponent text="Transfer" value="A02" />
        </FormInputComponent>
      );
    }
  };

  render() {
    const {
      formName,
      units,
      facilities,
      pristine,
      submitting,
      patient,
      patientsAdtLocationEnabled
    } = this.props;

    return (
      <div className={styles["style-buttons"]}>
        <FormTitleComponent>
          <h4>Patient ADT</h4>
        </FormTitleComponent>
        <FormComponent
          initialValues={this.state.initialValues}
          form={formName}
          onSubmit={this.createAdtPatient}
        >
          {this.renderActionTypes(patient)}
          <GridRow className="row">
            <GridColumn sm="2">
              <label className={styles["label-text"]}>Name</label>
            </GridColumn>
            <GridColumn sm="10">
              <div className={`input-group ${styles["group-textbox"]}`}>
                <FormInputComponent
                  name="firstName"
                  component="input"
                  componentType="text"
                  placeholder="First Name"
                  className="form-control"
                  disabled={true}
                />

                <FormInputComponent
                  name="lastName"
                  component="input"
                  componentType="text"
                  placeholder="Last Name"
                  className="form-control"
                  disabled={true}
                />
              </div>
            </GridColumn>
          </GridRow>
          <FormInputComponent
            name="birthDate"
            component="input"
            componentType="text"
            placeholder="Birth Date"
            labelText="Birth Date"
            disabled={true}
          />
          <FormInputComponent
            name="gender"
            component="input"
            componentType="select"
            labelText="Gender"
            disabled={true}
          >
            <FormInputOptionComponent text="" value="" />
            <FormInputOptionComponent text="Male" value="male" />
            <FormInputOptionComponent text="Female" value="female" />
          </FormInputComponent>

          <FormInputComponent
            name="mrn"
            component="input"
            componentType="text"
            placeholder="MRN"
            labelText="MRN"
            disabled={true}
          />
          <FormInputComponent
            name="shareToken"
            component="input"
            componentType="text"
            placeholder="Swift Code"
            labelText="Swift Code"
            disabled={true}
          />
          <GridRow className="row">
            <GridColumn sm="2">
              <label className={styles["label-text"]}>Location</label>
            </GridColumn>
            <GridColumn sm="10">
              <div className={`input-group ${styles["group-textbox"]}`}>
                <FormInputComponent
                  name="facility"
                  component="input"
                  componentType="select"
                  className="form-control"
                  disabled={patientsAdtLocationEnabled}
                >
                  <FormInputOptionComponent
                    key="none"
                    text="Facility/Agency"
                    value={null}
                  />
                  {Object.values(facilities).map(facility => {
                    return (
                      <FormInputOptionComponent
                        key={facility.id}
                        text={facility.attributes.name}
                        value={facility.id}
                      />
                    );
                  })}
                </FormInputComponent>

                <FormInputComponent
                  name="unit"
                  component="input"
                  componentType="select"
                  className="form-control"
                  disabled={patientsAdtLocationEnabled}
                >
                  <FormInputOptionComponent
                    key="none"
                    text="Unit/Zone"
                    value={null}
                  />
                  {units.map(unit => {
                    return (
                      <FormInputOptionComponent
                        key={unit.id}
                        text={unit.attributes.name}
                        value={unit.id}
                      />
                    );
                  })}
                </FormInputComponent>
              </div>
            </GridColumn>
          </GridRow>

          <div className="pull-right">
            <button
              type="submit"
              disabled={pristine || submitting}
              className="btn dashboard-btn"
            >
              Save
            </button>
            <button
              disabled={submitting}
              type="button"
              onClick={this.props.cancel}
              className="btn btn-default"
            >
              Cancel
            </button>
          </div>
        </FormComponent>
      </div>
    );
  }
}
