import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { track } from "src/analytics";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

export class ButtonComponent extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    isVisible: PropTypes.bool
  };

  static defaultProps = {
    type: "button"
  };

  // TODO: rework this to make more sense
  handleClick = (...args) => {
    if (this.props.analytics) {
      track(this.props.analytics.type, this.props.analytics.payload);
    }
    if (!this.props.onClick) return;

    return this.props.onClick.apply(this, args);
  };

  render() {
    const {
      className,
      type,
      disabled,
      children,
      tooltip,
      isVisible
    } = this.props;
    if (isVisible === false) {
      return null;
    }
    // Get class names
    const klass = classnames("btn dashboard-btn", className);
    let buttonContent = (
      <button
        type={type}
        disabled={disabled}
        onClick={this.handleClick}
        className={klass}
      >
        {children}
      </button>
    );

    if (!tooltip) return buttonContent;

    if (disabled) {
      buttonContent = (
        <span className="d-inline-block">
          <button
            type={type}
            disabled={disabled}
            onClick={this.handleClick}
            className={klass}
            style={{ pointerEvents: "none" }}
          >
            {children}
          </button>
        </span>
      );
    }
    return (
      <OverlayTrigger
        key="some-key"
        placement="top"
        overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}
      >
        {buttonContent}
      </OverlayTrigger>
    );
  }
}
