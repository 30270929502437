import { track } from "src/analytics";
import get from "lodash.get";

/**
 * Listen for router actions and track the next urls and query strings
 */
export function analyticsRouterTracking() {
  return next => action => {
    if (/\@\@router\/LOCATION_CHANGE/.test(action.type)) {
      let payload = get(action, "payload", {});
      track("CHANGE_PAGE", payload);
    }
    return next(action);
  };
}
