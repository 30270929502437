import apiFetch from "app/util/apiFetch";
import uuid from "uuid/v1";
import { configureApi } from "src/api/config";

import createFlashMessage from "app/flash_messages/create";
import removeFlashMessage from "app/flash_messages/remove";

// new actions
import { loggedIn } from "src/actions/session_actions";

import {
  loadUserAction,
  loadOrganizationsActionLegacy
} from "src/actions/data_actions";

// Analytics
import { track, registerParamsForAllEvents } from "src/analytics";

let previousFlashError;

const signIn = formData => {
  return async dispatch => {
    try {
      const authHost = window.AUTH_HOST || process.env.REACT_APP_AUTH_HOST;
      const authPath = window.AUTH_PATH || process.env.REACT_APP_AUTH_PATH;
      const authUrl = `${authHost}${authPath}`;

      const options = {
        method: "POST",
        body: formData,
        noToken: true
      };

      const asyncSessionResponse = apiFetch(authUrl, options);
      const user = await dispatch(asyncSessionResponse);

      if (user.errors) {
        throw user.errors;
      }

      const {
        config: { api_host },
        access_token,
        id
      } = user;

      const sessionId = uuid();

      registerParamsForAllEvents({
        session_id: sessionId
      });

      track("NEW_DASHBOARD_SESSION");

      dispatch(removeFlashMessage(previousFlashError, "error"));
      dispatch(
        removeFlashMessage(
          "Your session has expired. Please sign in again.",
          "error"
        )
      );
      dispatch(createFlashMessage("Signing in...", "info"));
      dispatch(
        loggedIn({
          apiHost: api_host,
          accessToken: access_token,
          sessionId: sessionId
        })
      );
      dispatch(loadUserAction(id));

      configureApi(access_token, id);

      return dispatch(loadOrganizationsActionLegacy(["organizations"]));
    } catch (err) {
      if (err.length > 0) {
        const msg = err.map(e => e.message).join(", ");
        previousFlashError = msg;
        dispatch(createFlashMessage(msg, "error"));
      } else {
        console.error({ err });
      }
    }
  };
};

export default signIn;
