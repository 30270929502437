import { combineReducers } from "redux";

//Section Reducer Factory
import { createSectionFiltersReducer } from "src/ui_processors/global/reducer_creators";

// Constants
import { sections } from "src/constants";

export const myDashboardSectionReducers = combineReducers({
  filters: createSectionFiltersReducer(sections.myDashboard)
});
