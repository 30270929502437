// React
import { connect } from "react-redux";
import { push } from "react-router-redux";

// selectors
import { createShowAcquiredUnknownSelector } from "src/selectors/section_selectors/survey";
import { localizationDictionaryForAllSchemasSelector } from "src/selectors/section_selectors/global";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

// Components
import { SurveyTallyTableRowComponent } from "src/components/tables";

// query string
import { stringify } from "qs";

function mapStateToProps(state, ownProps) {
  return {
    showAcquiredUnknown: createShowAcquiredUnknownSelector(ownProps.tallyType)(
      state
    ),
    schemaLocalizationDictionary: localizationDictionaryForAllSchemasSelector(
      state
    ),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    /**
     * Dispatch action to load the details view
     * @param {Object} detailsOptions
     */
    goToDetails(options) {
      const query = stringify(options);
      dispatch(push(`/etiology/details?${query}`));
    }
  };
}

export const SurveyTallyTableRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyTallyTableRowComponent);
