import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { propsAreDifferent } from "src/utils/props_are_different";
// Selectors
import {
  selectedWoundTypesSelector,
  selectedLocationIdsSelector,
  dateSelectionSelector
} from "src/selectors/section_selectors/population";

// Actions
import {
  loadPopulationIncidenceChartDataAction,
  loadPopulationPrevalenceChartDataAction
} from "src/actions/data_actions";

/*
 * HOC that is responsible for loading population chart data
 * @param {Class} WrappedComponent the component dependent on this data
 * @return {Class} Component wrapped by the loader component
 */
export function withLegacyPopulationChartDataLoader(WrappedComponent) {
  class legacyPopulationChartDataLoader extends React.Component {
    static propTypes = {
      selectedWoundTypes: PropTypes.object.isRequired,
      selectedLocationIds: PropTypes.array.isRequired,
      sendLoadChartDataAction: PropTypes.func.isRequired,
      dateSelection: PropTypes.object.isRequired
    };

    componentWillMount() {
      // TODO: Keep track of location loading state and use that
      if (this.props.selectedLocationIds.length) {
        this.loadChartData(this.props);
      }
    }

    componentWillReceiveProps(nextProps) {
      if (
        propsAreDifferent(
          this.props,
          nextProps,
          "selectedLocationIds",
          "selectedWoundTypes.woundTypes",
          "dateSelection.startDate",
          "dateSelection.endDate"
        )
      ) {
        this.loadChartData(nextProps);
      }
    }

    loadChartData(props) {
      const { sendLoadChartDataAction } = this.props;

      const queryParams = constructQueryParams(props);

      // Load the chart data
      sendLoadChartDataAction(queryParams);
    }

    render() {
      return <WrappedComponent />;
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(legacyPopulationChartDataLoader);
}

/**
 * Map the props needed for loading data
 */
function mapStateToProps(state) {
  return {
    selectedWoundTypes: selectedWoundTypesSelector(state),
    selectedLocationIds: selectedLocationIdsSelector(state),
    dateSelection: dateSelectionSelector(state)
  };
}

/**
 * Map dispatch to a props for loading data
 */
function mapDispatchToProps(dispatch) {
  return {
    sendLoadChartDataAction(queryParams) {
      dispatch(loadPopulationIncidenceChartDataAction(queryParams));
      dispatch(loadPopulationPrevalenceChartDataAction(queryParams));
    }
  };
}

/**
 * Function that constructs the query string for loading data using the required props
 * @param {Object} props the loader components props
 * @return {Object} the query params
 */
function constructQueryParams(props) {
  const { selectedLocationIds, selectedWoundTypes, dateSelection } = props;

  // Build the query params
  return {
    wound_types: selectedWoundTypes.woundTypes,
    location_ids: selectedLocationIds,
    start_time: dateSelection.startDate.toISOString(),
    end_time: dateSelection.endDate.toISOString(),
    time_step: "week"
  };
}
