/**
 * Get a filter value or undefined for a key
 * @param {Array} filters - filters array
 * @param {string} key - filter key
 * @returns {any} - filter value or undefined
 */
export function filterValueForKeyFromFilters(filters, key) {
  const filterObject = filters.find(f => f.key == key);
  return filterObject ? filterObject.value : undefined;
}
