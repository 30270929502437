import get from "lodash.get";

//Constants
import {
  apiRuleTypes,
  allFacilitiesOptionId,
  locationTypes
} from "src/constants";

export function createRulePostPayload(ruleFormValues) {
  const {
    id,
    facilityId,
    organizationId,
    roleIds,
    ruleTemplateId,
    template
  } = ruleFormValues;

  //Location Type and ID
  let locationType, locationId;
  if (facilityId === allFacilitiesOptionId) {
    locationType = locationTypes.organization;
    locationId = organizationId;
  } else {
    locationType = locationTypes.facility;
    locationId = facilityId;
  }

  //Rule Parameters
  const ruleParams = get(template, "attributes.ruleParams", []);
  const hiddenRuleParams = ruleParams.filter(field => {
    return field.hidden === true;
  });
  const visibleRuleParams = ruleParams.filter(field => {
    return field.hidden === false;
  });
  const formRuleParams = visibleRuleParams.map(ruleParam => {
    const key = `${ruleParam.name}-${ruleParam.operator}`;
    const value = ruleFormValues[key];
    return { ...ruleParam, value };
  });

  //Template Description Name
  const name = get(template, "attributes.description", "");

  return {
    data: {
      ...(id && { id }),
      type: apiRuleTypes.rules,
      attributes: {
        location_type: locationType,
        location_id: locationId,
        name,
        rule_template_id: ruleTemplateId,
        role_ids: roleIds,
        rule_params: [...hiddenRuleParams, ...formRuleParams]
      }
    }
  };
}
