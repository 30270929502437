import { createSelector } from "reselect";

/**
 * create a selector to get the api context for admin permission types for users table and permissions table
 * @returns {function} selector
 */
export function createAdminPermissionTypesContextSelector() {
  return createSelector(
    () => {
      return "adminPermissionTypes";
    },
    context => {
      return context;
    }
  );
}
