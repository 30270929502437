import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

//HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

//Component
import { AdminRiskAlertRulesTableComponent } from "src/components/tables";

//Selectors
import { rulesResourceSelectors } from "src/selectors/data_selectors";
import { createRiskAlertRulesQuerySelector } from "src/selectors/api_selectors/admin/rules";
import { adminSectionSelector } from "src/selectors/section_selectors/admin";
const selectedRiskAlertRulesTablePageLimitSelector = SingleValueReduxUtil.createSelectors(
  "selectedRiskAlertRulesTablePageLimit",
  adminSectionSelector
);
const selectedRiskAlertRulesTablePageNumberSelector = SingleValueReduxUtil.createSelectors(
  "selectedRiskAlertRulesTablePageNumber",
  adminSectionSelector
);
//Actions
import { loadRulesAction } from "src/actions/data_actions";
import {
  selectRiskAlertRulesTablePageLimitAction,
  selectRiskAlertRulesTablePageNumberAction
} from "src/actions/section_actions/admin";

//Constants
import { analyticsEventNames } from "src/constants";

function mapStateToProps(state) {
  const context = createRiskAlertRulesQuerySelector()(state);

  return {
    context,
    rules: rulesResourceSelectors.createDataForContextSelector(
      createRiskAlertRulesQuerySelector()
    )(state),
    pageLimit: selectedRiskAlertRulesTablePageLimitSelector.fieldSelector(
      state
    ),
    currentPage: selectedRiskAlertRulesTablePageNumberSelector.fieldSelector(
      state
    ),
    pageCount:
      rulesResourceSelectors.createPageCountSelector(
        context,
        selectedRiskAlertRulesTablePageLimitSelector.fieldSelector
      )(state) || 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetPageLimitAction: limit => {
      dispatch(selectRiskAlertRulesTablePageLimitAction(limit));
    },
    sendSetPageAction: pageNumber => {
      dispatch(selectRiskAlertRulesTablePageNumberAction(pageNumber));
    }
  };
}

export const AdminRiskAlertRulesTableContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadRulesAction,
    resourceSelectors: rulesResourceSelectors,
    createContextSelector: createRiskAlertRulesQuerySelector,
    createQuerySelector: createRiskAlertRulesQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: analyticsEventNames.renderAdminRiskAlertRulesTable
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AdminRiskAlertRulesTableComponent);
