import React from "react";

export class ComplianceIconComponent extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <path d="M35,7c0-1.6499634-1.3499756-3-3-3h-2.8399658c-0.2800293,0-0.4500122-0.289978-0.5-0.3800049 C28.1500244,2.6199951,27.1300049,2,26,2s-2.1499634,0.6199951-2.6599731,1.6199951C23.2900391,3.710022,23.1199951,4,22.8400269,4 H20c-1.6499634,0-3,1.3500366-3,3v5h18V7z" />
        <path d="M41,7h-4.000061L37,7.0004272v7H15v-7L15.000061,7H11c-1.6499634,0-3,1.3500366-3,3v37c0,1.6500244,1.3500366,3,3,3h30 c1.6500244,0,3-1.3499756,3-3V10C44,8.3500366,42.6500244,7,41,7z M21.6401367,37.7680054l-6,5 C15.4541016,42.9232788,15.2265625,42.9994507,15,42.9994507c-0.2568359,0-0.5126953-0.0986328-0.7070313-0.2929688l-2-2 c-0.390625-0.390625-0.390625-1.0234375,0-1.4140625s1.0234375-0.390625,1.4140625,0l1.3544922,1.3544922l5.2983398-4.4160156 c0.4238281-0.3535156,1.0541992-0.296875,1.4082031,0.1279297C22.121582,36.7836304,22.0644531,37.4144897,21.6401367,37.7680054z M21.6401367,27.7680054l-6,5C15.4541016,32.9232788,15.2265625,32.9994507,15,32.9994507 c-0.2568359,0-0.5126953-0.0986328-0.7070313-0.2929688l-2-2c-0.390625-0.390625-0.390625-1.0234375,0-1.4140625 s1.0234375-0.390625,1.4140625,0l1.3544922,1.3544922l5.2983398-4.4160156 c0.4238281-0.3525391,1.0541992-0.2958984,1.4082031,0.1279297C22.121582,26.7836304,22.0644531,27.4144897,21.6401367,27.7680054z M21.6401367,17.7680054l-6,5C15.4541016,22.9232788,15.2265625,22.9994507,15,22.9994507 c-0.2568359,0-0.5126953-0.0986328-0.7070313-0.2929688l-2-2c-0.390625-0.390625-0.390625-1.0234375,0-1.4140625 s1.0234375-0.390625,1.4140625,0l1.3544922,1.3544922l5.2983398-4.4160156 c0.4238281-0.3525391,1.0541992-0.2958984,1.4082031,0.1279297C22.121582,16.7836304,22.0644531,17.4144897,21.6401367,17.7680054z M39,42.9994507H26v-2h13V42.9994507z M39,38.9994507H26v-2h13V38.9994507z M39,32.9994507H26v-2h13V32.9994507z M39,28.9994507H26 v-2h13V28.9994507z M39,22.9994507H26v-2h13V22.9994507z M39,18.9994507H26v-2h13V18.9994507z" />
      </svg>
    );
  }
}
