import React from "react";
export class SurveyIconComponent extends React.Component {
  render() {
    return (
      <svg
        width="300"
        height="289"
        viewBox="0 0 300 289"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Page-1" fill="none" fillRule="evenodd">
          <g id="Custom-Preset-3" fillRule="nonzero" fill="#000">
            <g id="Survey-Dashboard-Icon">
              <path
                d="M277.954748,289 C273.535678,288.65493 269.116608,288.65493 264.784187,288.051056 C260.278468,287.360915 256.639234,284.772887 253,281.753521 C265.997265,267.778169 278.821232,253.889085 291.731849,240 C301.176528,248.022887 303.77598,262.774648 297.797239,273.816901 C295.111137,278.734155 291.471903,282.702465 286.619591,285.463028 C286.013052,285.808099 285.493162,286.411972 284.886623,286.929577 C282.633763,287.533451 280.294256,288.223592 277.954748,289 Z"
                id="Shape"
              />
              <path
                d="M152.930969,226.927237 C89.8396157,247.783284 25.5316248,212.877765 6.06542209,153.45112 C-14.7043209,89.835812 19.8829499,25.7841853 79.0636821,6.14983103 C142.589549,-14.880744 206.115416,20.1120385 225.842326,79.3641564 C246.872777,142.717673 211.937896,207.205619 152.930969,226.927237 Z M23.9673763,147.168126 C40.3050821,196.123116 93.1419179,226.141862 146.239462,209.038158 C195.078774,193.243411 225.32091,139.925231 208.114177,86.3452602 C192.210985,36.9539513 138.939636,7.1097328 85.8420919,24.2134369 C37.0027799,40.0081841 6.93444899,93.1518363 23.9673763,147.168126 Z"
                id="Shape"
              />
              <path
                d="M283,230.933702 C270.021858,245.042357 257.130237,258.891344 244.152095,273 C225.46357,255.688766 206.861566,238.550645 188,221.152855 C200.978142,207.044199 213.869763,193.108656 226.847905,179 C245.622951,196.39779 264.224954,213.535912 283,230.933702 Z"
                id="Shape"
              />
              <path
                d="M142,66 C149.291621,66 156.316475,66 163.430251,66.0871479 C163.963784,66.0871479 164.58624,66.8714789 164.853006,67.4815141 C165.119773,68.0044014 164.941928,68.7015845 164.941928,69.3116197 C164.941928,100.24912 164.941928,131.18662 164.941928,162.036972 C164.941928,162.995599 164.853006,163.954225 164.764084,165 C157.116774,165 149.647309,165 142,165 C142,131.970951 142,99.2033451 142,66 Z"
                id="Shape"
              />
              <path
                d="M102,163.739156 C102,136.350501 102,109.396587 102,82.0079318 C104.653846,82.0079318 107.219231,82.0079318 109.784615,82.0079318 C113.853846,82.0079318 118.011538,82.0948799 122.080769,82.0079318 C124.292308,81.9209836 125,82.5296204 125,84.7902713 C124.911538,92.5286531 124.911538,100.353983 124.911538,108.179313 C124.911538,125.742831 124.911538,143.30635 125,160.956816 C125,163.130519 124.557692,164 122.080769,164 C115.446154,163.565259 108.9,163.739156 102,163.739156 Z"
                id="Shape"
              />
              <path
                d="M63,100 C70.6964981,100 78.3035019,100 86,100 C86,121.695946 86,143.216216 86,165 C78.3929961,165 70.7859922,165 63,165 C63,143.304054 63,121.695946 63,100 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
