import { apiFactory } from "src/api/api_factory";

export const healingIndexApi = apiFactory({
  path: "api/v4/healing_index/prediction",
  useGetOverride: false
});

export const averageHealingIndexApi = apiFactory({
  path: "api/v4/healing_index/average_profile",
  useGetOverride: false
});
