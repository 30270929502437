import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

// Component based off from react-horizontal-stacked-bar-chart
export class HorizontalStackedBar extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    id: PropTypes.string,
    height: PropTypes.number,
    showTextIn: PropTypes.bool,
    showTextWithValue: PropTypes.bool,
    showValueIn: PropTypes.bool,
    fontColor: PropTypes.string
  };

  static defaultProps = {
    height: 30,
    showTextIn: false,
    showTextWithValue: true,
    showValueIn: false,
    fontColor: "white",
    id: "hsbar"
  };

  constructor(props) {
    super(props);
    this.state = {
      listBars: [],
      data: this.props.data
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      this.setState(state => ({
        ...state,
        listBars: this.getListBarWithOtherParameters(),
        data: this.props.data
      }));
    }
  }

  componentDidMount() {
    this.setState(state => ({
      ...state,
      listBars: this.getListBarWithOtherParameters(),
      data: this.props.data
    }));
  }

  /**
   * Make the calculus of total width
   */
  calcWidthTotal() {
    let widthTotal = 0;
    this.props.data.forEach(bar => {
      widthTotal = widthTotal + bar.value;
    });
    return widthTotal;
  }

  /**
   * Returns the same list of bars with position and barWidth
   */
  getListBarWithOtherParameters() {
    const widthTotal = this.calcWidthTotal();
    let position = 0;
    let barWidth = 0;
    const listBars = this.props.data.map(bar => {
      position = position + barWidth;
      barWidth = (bar.value * 100) / widthTotal;
      bar = Object.assign({ position: position, barWidth: barWidth }, bar);
      return bar;
    });
    return listBars;
  }

  renderBars() {
    const listBars = [];
    const { showTextWithValue, showTextIn, showValueIn } = this.props;

    listBars.push(
      this.state.listBars.map((bar, index) => {
        if (bar.value < 1) return;
        return (
          <g key={index}>
            <rect
              width={`${bar.barWidth + 0.1}%`}
              height={this.props.height}
              style={{
                fill: bar.color
              }}
              x={`${bar.position}%`}
            />
            {(this.props.showTextIn || this.props.showValueIn) && (
              <text
                style={{
                  fill: bar.fontColor || this.props.fontColor,
                  fontSize: "90%"
                }}
                x={`${bar.position + (bar.barWidth + 0.1) / 2}%`}
                y="50%"
                dy="0.35em"
                textAnchor="middle"
              >
                {showTextIn && bar.name}
                {bar.name && showTextIn ? ": " : ""}
                {(showValueIn || showTextWithValue) &&
                  (bar.description || bar.value || "1")}
              </text>
            )}
            <title>{`${bar.name || ""}${
              bar.name ? ": " : ""
            }${bar.description || bar.value || "1"}`}</title>
          </g>
        );
      })
    );
    return listBars;
  }

  render() {
    return (
      <React.Fragment>
        <svg id={this.props.id} width="100%" height={this.props.height}>
          {this.renderBars()}
        </svg>
      </React.Fragment>
    );
  }
}
