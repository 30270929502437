import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// HOC
import { withResourceLoaderFactory } from "src/hoc/factories";
import { HealingIndexChartComponent } from "src/components/healing_index_chart_component";

// Actions
import {
  healingIndexResourceSelector,
  averageHealingIndexResourceSelector
} from "src/selectors/data_selectors";
import {
  loadHealingIndexAction,
  loadAverageHealingIndexAction
} from "src/actions/data_actions/healing_index";

import { healingRiskChartDefinitionSelector } from "src/selectors/chart_definition_selectors";

import { createHealingIndexContextSelector } from "src/selectors/api_selectors/global/healing_index/create_healing_index_context_selector";
import { createAverageHealingIndexContextSelector } from "src/selectors/api_selectors/global/healing_index/create_average_healing_index_context_selector";
import { createAverageHealingIndexQueryForRiskCategorySelector } from "src/selectors/api_selectors/global/healing_index/create_average_healing_index_query_for_risk_category_selector";
import { createHealingIndexQueryForSeriesIdSelector } from "src/selectors/api_selectors/global/healing_index/create_healing_index_query_for_series_id_selector";
import { healingIndexDashboardEnabledSelector } from "src/selectors/ui_config_selectors/global";

function mapStateToProps(state, ownProps) {
  return {
    healingIndexDashboardEnabled: healingIndexDashboardEnabledSelector(state),
    chartDefinition: healingRiskChartDefinitionSelector(ownProps)(state)
  };
}

export const HealingIndexChartContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadHealingIndexAction,
    resourceSelectors: healingIndexResourceSelector,
    createContextSelector: createHealingIndexContextSelector,
    createQuerySelector: createHealingIndexQueryForSeriesIdSelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadAverageHealingIndexAction,
    resourceSelectors: averageHealingIndexResourceSelector,
    createContextSelector: createAverageHealingIndexContextSelector,
    createQuerySelector: createAverageHealingIndexQueryForRiskCategorySelector,
    waitForParentLoaderToComplete: true
  }),
  connect(mapStateToProps)
)(HealingIndexChartComponent);
