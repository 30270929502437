import React from "react";
import PropTypes from "prop-types";

// router
import { NavLink } from "react-router-dom";

import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormComponent } from "src/components/forms/form_component_legacy";
import { Button } from "src/ui-kit";
import { loadStates } from "src/constants/load_states";
// style
import styles from "./style.module.less";

import { reduxFormNames } from "src/constants";

import { validatePassword, required, passwordMatch } from "src/validations";

import { apiHostSelector } from "src/selectors/session_selectors";
import store from "src/store";

export class ChangePasswordFormComponent extends React.Component {
  static propTypes = {
    changePasswordLoadState: PropTypes.string.isRequired
  };

  /**
   * Event handler for the change password
   */
  onChangePassword = values => {
    let {
      changePasswordLoadState,
      sendChangePasswordAction,
      sendPasswordErrorAction
    } = this.props;

    const passwordStrength = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );

    if (!values.oldPassword) {
      sendPasswordErrorAction("Please enter your current password");
    } else if (!values.newPassword) {
      sendPasswordErrorAction("Please enter your new password");
    } else if (!passwordStrength.test(values.newPassword)) {
      sendPasswordErrorAction(
        "Password must be 8 or more characters long, contain at least one capital, one lowercase, one number and one non-alphanumeric symbol (eg. Sw1ftmedic@l)"
      );
    } else if (!values.confirmPassword) {
      sendPasswordErrorAction("Please confirm your new password");
    } else if (values.newPassword !== values.confirmPassword) {
      sendPasswordErrorAction(
        "New password does not match the confirm password"
      );
    } else {
      if (changePasswordLoadState == loadStates.ready) {
        const payload = {
          current_password: values.oldPassword,
          password: values.newPassword,
          password_confirmation: values.confirmPassword,
          host: apiHostSelector(store.getState())
        };
        sendChangePasswordAction(payload);
      }
    }
  };

  render() {
    return (
      <FormComponent
        form={reduxFormNames.changePasswordForm}
        onSubmit={this.onChangePassword}
      >
        <FormInputComponent
          name="oldPassword"
          component="input"
          componentType="password"
          placeholder="Current Password"
          labelText="Current Password"
          validate={[required]}
        />
        <FormInputComponent
          name="newPassword"
          component="input"
          componentType="password"
          placeholder="New Password"
          labelText="New Password"
          validate={[required, validatePassword]}
        />
        <FormInputComponent
          name="confirmPassword"
          component="input"
          componentType="password"
          placeholder="Confirm Password"
          labelText="Confirm Password"
          validate={[required, validatePassword, passwordMatch]}
        />
        <div className={`text-center ${styles["margin-top10px"]}`}>
          <Button className="btn-default" subtype="submit">
            Submit
          </Button>

          <NavLink className="btn btn-default" to="/account">
            Back
          </NavLink>
        </div>
      </FormComponent>
    );
  }
}
