import { createSelector } from "reselect";
import moment from "moment";
import get from "lodash.get";

import { csvExportsResourceSelectors } from "../csv_exports";
import { localExportsResourceSelectors } from "../local_exports";

export const fileExportsDataSelector = createSelector(
  csvExportsResourceSelectors.dataSelector,
  localExportsResourceSelectors.dataSelector,
  (csvExports, localExports) => {
    let allFileExports = Object.values(csvExports).concat(
      Object.values(localExports)
    );

    return allFileExports.sort((exportA, exportB) => {
      const timestampA = get(exportA, "attributes.createdAt", ""),
        timestampB = get(exportB, "attributes.createdAt", "");
      return moment(timestampB).isAfter(timestampA) ? 1 : -1;
    });
  }
);
