import normalize from "json-api-normalizer";

// Translations
import i18n from "src/translations";

// Error Tracking
import { trackError } from "src/error_tracking";

// API
import { fetchOrganizations, organizationsApi } from "src/api/organizations";

// Actions
import { populatePolicies } from "src/actions/data_actions/policies";
import { setLanguage } from "src/actions/config_actions";

// Constants
import { resourceNames } from "src/constants";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

//Actions
import { createLoadDataAction } from "../create_load_data_action";

import { payersResourceActions } from "src/actions/data_actions";

const organizationsResourceActions = createResourceActions("organizations");

export const loadOrganizationsAction = createLoadDataAction({
  resourceName: resourceNames.organizations,
  api: organizationsApi,
  includedResourceNames: ["policies", resourceNames.payers]
});

/**
 * Action creator to fetch organizations data
 * @param {Object} contexts
 * @return {Object} redux action
 */
export function loadOrganizationsActionLegacy(contexts) {
  return dispatch => {
    dispatch(organizationsResourceActions.loadingForContextAction(contexts));

    return fetchOrganizations()
      .then(response => {
        const normalizedData = normalize(response.data);
        const organizations = normalizedData.organizations;
        const policies = normalizedData.policies;
        const payers = normalizedData.payers;

        // Update the namespace for the translations for this organization
        const firstOrg = Object.values(organizations)[0];
        if (firstOrg) {
          const locale = firstOrg.attributes.countryCode == "CA" ? "ca" : "us";
          i18n.setLocale(locale);
          dispatch(setLanguage(`en_${locale}`));
        }

        dispatch(
          organizationsResourceActions.replaceAction({
            contexts,
            data: organizations,
            ids: organizations ? Object.keys(organizations) : [],
            links: response.data.links,
            meta: response.data.meta
          })
        );
        if (policies) dispatch(populatePolicies(contexts, response));
        if (payers) {
          dispatch(
            payersResourceActions.populatePostAction({
              data: payers
            })
          );
        }
      })
      .catch(error => {
        trackError(error);
        dispatch(
          organizationsResourceActions.loadErrorForContextAction(contexts)
        );
      });
  };
}
