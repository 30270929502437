import axios from "axios";
import qs from "qs";
import get from "lodash.get";

/**
 * Create an object for querying an api resource
 * @param {Object} options
 * @param {string} options.baseURL the base url
 * @param {boolean} options.useGetOverride true if should use an http override for GET requests
 * @param {boolean} options.usePostForGet true if fetching data is done with a POST request
 * @returns {Object} api object with query functions as parameters
 */
export function apiFactory() {
  return {
    /**
     * GET function for redux resource v2 integration
     * @param {Object} queryObject
     * @param {string} queryObject.path - path to append to api request before query
     * @param {Object} queryObject.params - query parameters
     * @param {Object} queryObject.options
     * @param {boolean} queryObject.options.useGetOverride - will send GET request as a POST req if true - with Method-Override header
     * @param {boolean} queryObject.options.usePostForGet - will send POST request if true
     * @param {Object} page - the page object to use, produced by pagination strategy
     */
    get(queryObject, page) {
      // resource config used to pass custom configuration here
      const path = queryObject.path || "";

      if (get(queryObject, "options.useGetOverride")) {
        // Flow for http override
        const postOptions = {
          headers: {
            "X-HTTP-Method-Override": "GET"
          }
        };

        return axios.post(path, { ...queryObject.params, page }, postOptions);
      } else if (get(queryObject, "options.usePostForGet")) {
        // Flow for POST requests on server side used to fetch data
        return axios.post(path, { ...queryObject.params, page });
      } else {
        const params = {
          ...queryObject.params,
          page
        };
        let queryString = path ? `${path}` : "";
        queryString = `${queryString}?${qs.stringify(params)}`;

        return axios.get(queryString);
      }
    }
    // TODO support for other methods
    // patch(queryObject, resourceConfig) {
    //   let config = {
    //     headers: {
    //       "Content-Type": "application/vnd.api+json"
    //     }
    //   };

    //   return axiosInstance.patch("/" + payload.data.id, payload, config);
    // },
    // post({ payload }) {
    //   let config = {
    //     headers: {
    //       "Content-Type": "application/vnd.api+json"
    //     }
    //   };

    //   return axiosInstance.post("", payload, config);
    // },
    // delete({ id }) {
    //   let config = {
    //     headers: {
    //       "Content-Type": "application/vnd.api+json"
    //     }
    //   };

    //   return axiosInstance.delete(`/${id}`, config);
    // }
  };
}
