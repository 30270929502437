import { frameActionTypes } from "src/action_types/frame_action_types";

const defaultState = {
  showAnnotation: true,
  isDeltaFrameActive: true,
  fluoroFrameType: "bacterial"
};

export function framesReducer(state = defaultState, action) {
  switch (action.type) {
    case frameActionTypes.setShowAnnotation:
      return {
        ...state,
        showAnnotation: action.payload
      };
    case frameActionTypes.setIsDeltaFrameActive:
      return {
        ...state,
        isDeltaFrameActive: action.payload
      };
    case frameActionTypes.setFluoroFrameType:
      return {
        ...state,
        fluoroFrameType: action.payload
      };
    default:
      return state;
  }
}
