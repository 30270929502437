export const permissionOptions = {
  none: "None",
  read: "Read",
  "read,edit,add": "Read, Edit, Create",
  "read,edit,add,delete": "All (Read, Edit, Create, Delete)"
};

export const permissionOptionsAssessmentState = {
  "unlocked,locked,signed,unsigned,open,stricken":
    "All (Unlocked, Locked, Signed, Unsigned, Open, Stricken)",
  unlocked: "Unlocked",
  locked: "Locked",
  signed: "Signed",
  unsigned: "Unsigned",
  open: "Open",
  stricken: "Stricken"
};
