import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import moment from "moment";
// Translations
import i18n from "src/translations";

import { displayValueForUnitless } from "src/models/revisions";

import {
  incompletePushScoreValues,
  incompleteBwatScoreValues
} from "src/constants";

// Components
import { GridRow, GridColumn } from "src/components/grid";
import { StudyBadgeComponent } from "src/components/study_badge_component";
import {
  LockedIconComponent,
  UnlockedIconComponent,
  SignedIconComponent
} from "src/components/icon_components";
import EncounterDisplayValueComponent from "src/components/encounters/encounter_display_value_component.js";

// Mappings
import { woundProgress } from "src/mappings";

// Legacy utils
import { ageThisMoment } from "src/utils/timeUtils";
import { createListStringFromArray } from "src/utils/format_string_list_from_array";

// Style
import styles from "./style.module.less";

export class RevisionWoundImageHeaderComponent extends React.PureComponent {
  static propTypes = {
    revision: PropTypes.object.isRequired,
    putLoadState: PropTypes.object.isRequired,
    deleteLoadStateWithStatusById: PropTypes.object.isRequired,
    bwatScoreEnabled: PropTypes.bool.isRequired,
    payerDashboardEnabled: PropTypes.bool.isRequired,
    payersForRevision: PropTypes.array.isRequired,
    visitSequenceEnabled: PropTypes.array.isRequired,
    assessmentVersion: PropTypes.string.isRequired
  };

  render() {
    const {
      series,
      revision,
      frame,
      assessmentAnswer,
      bwatScoreEnabled,
      payerDashboardEnabled,
      payersForRevision,
      visitSequenceEnabled,
      assessmentVersion,
      etiologyVerifiedQuestionEnabled
    } = this.props;

    const woundAge = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.answersJson.woundAge",
        "unknown"
      ),
      overallProgress = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.answersJson.overallProgress",
        null
      )
        ? i18n.t(
            `data.overallProgress.${assessmentVersion}.${assessmentAnswer.attributes.answersJson.overallProgress}`
          )
        : woundProgress.unknown,
      seriesOriginAt = get(series, "attributes.originAt", null),
      contentType = get({ frame }, "frame.attributes.contentType", ""),
      closure = get({ revision }, "revision.attributes.closure", null),
      revisionCreatedAt = get(revision, "attributes.createdAt", null),
      encounterNumber = get(
        assessmentAnswer,
        "attributes.encounterNumber",
        null
      ),
      encounterStartAt = get(
        assessmentAnswer,
        "attributes.encounterStartAt",
        null
      ),
      lockState = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.lockState",
        ""
      ),
      signState = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.signState",
        ""
      ),
      woundType = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.answersJson.woundType",
        ""
      ),
      pushScore = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.answersJson.pushScore",
        null
      ),
      bwatScore = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.answersJson.bwatScore",
        null
      );

    let woundAgeFromOrigin = ageThisMoment(revisionCreatedAt, seriesOriginAt);
    let visitFrequency = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.visitFrequency",
      "N/A"
    );

    let physicianVerified = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.physicianVerified",
      "N/A"
    );

    let encounterStartDateString = encounterStartAt
      ? moment(encounterStartAt).format("MMM DD, YYYY")
      : "N/A";

    if (["chronic", "unknown"].includes(woundAge)) {
      woundAgeFromOrigin = i18n.t(`data.woundAge.${woundAge}`);
    }

    return (
      <GridRow>
        <GridColumn lg="6">
          <div className={`${styles["wound-details-heading"]}`}>
            <h2>{moment(revisionCreatedAt).format("MMM Do YYYY")}</h2>
            <h3>
              {overallProgress + " - "}
              <span className={styles["sub-title"]}>{woundAgeFromOrigin}</span>
              {etiologyVerifiedQuestionEnabled && (
                <h4>
                  {"Etiology Verified by Physician: " + physicianVerified}
                </h4>
              )}
              {payerDashboardEnabled && (
                <h4>
                  {"Payer(s): " +
                    createListStringFromArray(payersForRevision[revision.id])}
                </h4>
              )}
              {visitSequenceEnabled && (
                <h4>{"Current Wk Visit Freq: " + visitFrequency}</h4>
              )}
              <EncounterDisplayValueComponent
                tagName="h4"
                tagStyle={null}
                encounterNumber={encounterNumber}
                encounterStartDateString={encounterStartDateString}
              />
            </h3>
          </div>
        </GridColumn>
        <GridColumn lg="6">
          <div className={styles["badge-container"]}>
            <div className={styles["badge"]}>
              <StudyBadgeComponent
                title="% Changed"
                value={
                  contentType == "declined"
                    ? "--"
                    : displayValueForUnitless(closure)
                }
                width="60px"
                height="60px"
              />
            </div>
            {(woundType == "pressureUlcer" ||
              woundType == "pressureKennedy" ||
              woundType == "pressureDeviceRelated") &&
              !bwatScoreEnabled && (
                <div className={styles["badge"]}>
                  <StudyBadgeComponent
                    title="PUSH"
                    value={
                      incompletePushScoreValues.includes(pushScore)
                        ? "INC"
                        : pushScore
                    }
                    width="60px"
                    height="60px"
                  />
                </div>
              )}
            {bwatScoreEnabled && (
              <div className={styles["badge"]}>
                <StudyBadgeComponent
                  title="BWAT"
                  value={
                    incompleteBwatScoreValues.includes(bwatScore)
                      ? "INC"
                      : bwatScore
                  }
                  width="60px"
                  height="60px"
                />
              </div>
            )}
            <div className={`${styles["icon-small"]} pull-right`}>
              {lockState == "locked" && <LockedIconComponent />}
              {lockState != "locked" && <UnlockedIconComponent />}
            </div>
            {signState == "signed" && (
              <div className={`${styles["icon-small"]} pull-right`}>
                <SignedIconComponent />
              </div>
            )}
          </div>
        </GridColumn>
      </GridRow>
    );
  }
}
