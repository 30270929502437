// @flow
import { Action } from "src/utils/actionUtils";
import services from "src/services/riskServices";
import { calculateNortonAggregates } from "../propertyActions/risks/calculate";
import { camelizeKeys } from "humps";
class LocationRiskActions extends Action {
  loadNortonPlusAggregates = (locationIds, startTime, endTime) => dispatch => {
    return Promise.all(
      locationIds.map(id =>
        services
          .loadNortonAggregates(id, startTime, endTime)
          .then(({ aggregates }) => ({
            id,
            nortonPlus: {
              aggregates: calculateNortonAggregates(aggregates)
            }
          }))
      )
    ).then(aggregatesIndex => {
      // Base action replaced with manual one to fix unknown bug
      dispatch({
        type: "INDEX_LOCATION_RISKS",
        payload: {
          entities: camelizeKeys(aggregatesIndex)
        }
      });
      // dispatch(this.baseActions.index(aggregatesIndex));
    });
  };

  loadNortonPlusCharts = (locationIds, startTime, endTime) => dispatch => {
    return Promise.all(
      locationIds.map(id =>
        services
          .loadNortonAdminCharts(id, startTime, endTime)
          .then(({ chart }) => ({
            id,
            nortonPlus: {
              chart
            }
          }))
      )
    ).then(aggregatesChart => {
      // Base action replaced with manual one to fix unknown bug
      dispatch({
        type: "INDEX_LOCATION_RISKS",
        payload: {
          entities: camelizeKeys(aggregatesChart)
        }
      });
      // dispatch(this.baseActions.index(aggregatesChart));
    });
  };
}

export default new LocationRiskActions("locationRisks");
