import { createSelector } from "reselect";

// Selectors
import { createCurrentPageEventsForAssessmentAnswerGroupIdQuerySelector } from "src/selectors/query_selectors/events_for_assessment_answer_group_id";

/**
 * create a selector to get the context for the current page - loading events with a assessmentAnswerId
 * @param {Object} props
 * @param {string} props.assessmentAnswerId
 * @returns {function} selector
 */
export function createCurrentPageEventsForAssessmentAnswerGroupIdContextSelector({
  assessmentAnswerGroupId
}) {
  return createSelector(
    createCurrentPageEventsForAssessmentAnswerGroupIdQuerySelector({
      assessmentAnswerGroupId
    }),
    query => {
      return query;
    }
  );
}
