import { dataActionTypes } from "src/action_types";
import { loadStates } from "src/constants/load_states";

const defaultState = {
  loadState: loadStates.ready
};

export function surveyWoundDetailsExportReducer(state = defaultState, action) {
  switch (action.type) {
    case dataActionTypes.surveyWoundDetailsExportLoading:
      return { loadState: loadStates.loading };
    case dataActionTypes.surveyWoundDetailsExportLoadReady:
      return { loadState: loadStates.ready };
    case dataActionTypes.surveyWoundDetailsExportError:
      return { loadState: loadStates.error };
    default:
      return state;
  }
}
