import { createSelector } from "reselect";

//First Page Context Selector
import { firstPageContextForSelectedProgressSelector } from "src/selectors/context_selectors/wounds/first_page_context_for_selected_progress_selector";

//Resource Selector
import { woundAssessmentsResourceSelectors } from "src/selectors/data_selectors";

//Section Selectors
import {
  selectedWoundOptionsSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector,
  selectedDateSelector,
  selectedProgressFilterSelector,
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  selectedPayersIdsFilterSelector
} from "src/selectors/section_selectors/wounds";

//Payload Generator
import { apiV2AssessmentsPayloadGenerator } from "src/api/wound_assessments";

export const allPagesPayloadsForSelectedProgressSelector = createSelector(
  selectedWoundOptionsSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector,
  selectedDateSelector,
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  selectedProgressFilterSelector,
  woundAssessmentsResourceSelectors.createRecordCountSelector(
    firstPageContextForSelectedProgressSelector
  ),
  selectedPayersIdsFilterSelector,
  (
    selectedWoundOptions,
    selectedLocationIDs,
    acquiredFilter,
    selectedEndDate,
    hprScoreMinRangeFilter,
    hprScoreMaxRangeFilter,
    bwatScoreMinRangeFilter,
    bwatScoreMaxRangeFilter,
    selectedProgressFilter,
    recordCountForSelectedProgress,
    selectedPayersIds
  ) => {
    let allPagesPayloadsForSelectedProgressFilter = [];
    let pageLimit = 10;
    let pageCount = Math.ceil(recordCountForSelectedProgress / pageLimit);

    for (let pageNumber = 0; pageNumber < pageCount; pageNumber++) {
      allPagesPayloadsForSelectedProgressFilter.push(
        apiV2AssessmentsPayloadGenerator(
          selectedWoundOptions,
          selectedLocationIDs,
          acquiredFilter,
          selectedEndDate,
          hprScoreMinRangeFilter,
          hprScoreMaxRangeFilter,
          bwatScoreMinRangeFilter,
          bwatScoreMaxRangeFilter,
          selectedProgressFilter,
          pageNumber,
          pageLimit,
          selectedPayersIds
        )
      );
    }

    return allPagesPayloadsForSelectedProgressFilter;
  }
);
