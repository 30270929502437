import React from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";
import get from "lodash.get";

// utils
import { propsAreDifferent } from "src/utils/props_are_different";

/**
 * React component for rendering a chart.js canvas
 */
export class ChartComponent extends React.Component {
  static propTypes = {
    chartDefinition: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    animationEnabled: PropTypes.bool,
    onClick: PropTypes.func
  };

  static defaultProps = {
    animationEnabled: false
  };

  // the reference to the canvas rendering the chart
  static chartCanvasRef = null;

  // the chart.js chart object
  static chart = null;

  componentDidMount() {
    this.initializeChart(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (propsAreDifferent(nextProps, this.props, "chartDefinition.data")) {
      this.updateChart(nextProps);
    }
  }

  /**
   * Initialize the chart.js chart using the ref to the canvas set in the render
   * function and the chart definition provided in the props.
   * @param {Object} props the current or next props
   */
  initializeChart(props) {
    const ctx = this.chartCanvasRef.getContext("2d");

    // If the chart exists then destroy it - we will re-render it
    // TODO: add update functionality so this isn't necessary
    if (this.chart) {
      this.chart.destroy();
    }

    const chartDef = {
      ...props.chartDefinition,
      options: this.getOptionsForChart(props)
    };

    // Build the chart
    this.chart = new Chart(ctx, chartDef);
  }

  updateChart(props) {
    const { chartDefinition } = props;
    this.chart.data = chartDefinition.data;
    this.chart.options = this.getOptionsForChart(props);
    this.chart.update(0);
  }

  getOptionsForChart(props) {
    const { chartDefinition, animationEnabled, onClick } = props;

    const animationOptions = get(chartDefinition, "options.animation", false);
    if (animationOptions) {
      return {
        ...chartDefinition.options,
        onClick
      };
    }

    return {
      ...chartDefinition.options,
      animation: animationEnabled,
      onClick
    };
  }

  setChartRef = element => {
    // TODO: Update this to use React.createRef when we upgrade to react v16
    this.chartCanvasRef = element;
  };

  onClick = (e, elements) => {
    this.props.onClick(e, elements, this.chart);
  };

  render() {
    return (
      <canvas
        ref={this.setChartRef}
        width={this.props.width}
        height={this.props.height}
        className={this.props.className}
      />
    );
  }
}
