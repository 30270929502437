import PropTypes from "prop-types";
import React from "react";
import "font-awesome/css/font-awesome.css";
import classnames from "classnames";

/**
 * React component used to render an icon from Font Awesome given an icons name
 */
export class FontAwesomeIconComponent extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
    className: PropTypes.string
  };

  render() {
    const { name, size, className } = this.props;
    const klass = classnames(
      "fa",
      `fa-${name}`,
      size && `fa-${size}`,
      className
    );
    return <i className={klass} />;
  }
}
