import { createSelector } from "reselect";
import get from "lodash.get";

import {
  assessmentSchemasResourceSelectors,
  createAssessmentSchemaForAssessmentAnswerSelector
} from "src/selectors/data_selectors";

/**
 * Create a selector that gets nested assessment schemas given an assessmentAnswer
 * @param {string} assessmentAnswer
 * @returns {Function} selector
 */
export function createNestedAssessmentSchemasForAssessmentAnswerSelector(
  assessmentAnswer
) {
  return createSelector(
    assessmentSchemasResourceSelectors.dataSelector,
    createAssessmentSchemaForAssessmentAnswerSelector(assessmentAnswer),
    (assessmentSchemas, rootAssessmentSchema) => {
      const components = get(
        rootAssessmentSchema,
        "attributes.schemaJson.components"
      );

      if (components) {
        const schemas = Object.values(assessmentSchemas);
        let compArray = Array.isArray(components)
          ? components
          : Object.values(components);

        return compArray.reduce((agg, id) => {
          const schema = schemas.find(
            schema => schema.attributes.assessmentId == id
          );

          if (schema) {
            agg.push(schema);
          }

          return agg;
        }, []);
      }

      return [];
    }
  );
}
