import { apiPageLimits } from "src/constants";

/**
 * Create the payload for GETing revision comparison
 * @param {Object} options
 * @param {string} options.seriesId
 * @param {number} options.pageNumber
 */
export function createGetPayload({
  pageNumber,
  pageLimit,
  seriesId,
  strikeout_state,
  fields,
  lockState,
  deleted,
  sort
}) {
  return {
    filter: {
      series_id: seriesId,
      strikeout_state,
      lock_state: lockState,
      deleted: deleted
    },
    ...(fields && {
      fields: {
        revisions: fields
      }
    }),
    ...(sort && { sort }),
    page: {
      offset: pageNumber * (pageLimit || apiPageLimits.revisions),
      limit: pageLimit || apiPageLimits.revisions
    }
  };
}
