// @flow
import numeral from "numeral";

export default (number: number | string, type?: string) => {
  if (number === "INC") {
    return "INC";
  }
  switch (type) {
    case "Skin":
      return "S";
    case "Psychosocial":
      return "P";
    case "Orders":
      return "O";
    case "Care Plan":
      return "C";
    case "Supplies":
      return "S";
    default:
      return numeral(number).format("0.[00]");
  }
};
