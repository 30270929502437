import { createSelector } from "reselect";
import { surveyTallyExportSelector } from "../survey_tally_export_selector";

/**
 * Load state selector for the survey tally export functionality
 */
export const surveyTallyExportLoadStateSelector = createSelector(
  surveyTallyExportSelector,
  surveyTallyExport => surveyTallyExport.loadState
);
