import { normalize } from "normalizr";
import { camelizeKeys } from "humps";
import { trackError } from "src/error_tracking";

// Schema
import { locationSchema } from "src/schema/location";

// Api
import { fetchLocationsForOrganization } from "src/api/organizations_v2";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";

// Analytics
import {
  startTimeTrack,
  finishTimeTrack,
  registerParamsForAllEvents
} from "src/analytics";

/**
 * Action creator for populating location in the data tree of the store
 * @param {Object} locations locations by id
 * @return {Object} redux action
 */
function populateLocations(locations) {
  return {
    type: dataActionTypes.populateLocations,
    payload: locations
  };
}

/**
 * Action creator for loading all locations for an organization from the api
 */
export function loadLocationsForOrganization(organizationId) {
  // TODO:
  // Refactor services
  // Add actions to keep track of loading state
  // Handle error loading locations

  return dispatch => {
    startTimeTrack("API_GET_ORGANIZATION_LOCATIONS");

    return fetchLocationsForOrganization(organizationId)
      .then(result => {
        finishTimeTrack("API_GET_ORGANIZATION_LOCATIONS");

        // camelize keys
        let locations = result.data.locations.map(loc => camelizeKeys(loc));

        // Get all facility IDs
        // TODO: once we are pulling api_v2_locations.v1_model_id from API, use that instead of id
        let facilityIDList = locations
          .filter(loc => loc.tags.includes("Facility"))
          .map(loc => loc.id);

        // If the user only has access to one facility, register it to Mixpanel
        if (facilityIDList.length === 1) {
          registerParamsForAllEvents({
            userFacilityID: facilityIDList[0]
          });
        }

        // Normalize locations
        locations = normalize(locations, [locationSchema]).entities.locations;

        dispatch(populateLocations(locations));
      })
      .catch(err => {
        finishTimeTrack("API_GET_ORGANIZATION_LOCATIONS", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
        trackError(err);
      });
  };
}
