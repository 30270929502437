import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { withRouter } from "react-router-dom";

//components
import { MonitoringSectionComponent } from "src/components/monitoring_section_component";

// function mapStateToProps(state) {
//   return {};
// }
// function mapDispatchToProps(dispatch) {
//   return {};
// }

// TODO: pass required data
export const MonitoringSectionContainer = flowRight(
  withRouter,
  connect()
)(MonitoringSectionComponent);
