import { createListStringFromArray } from "src/utils/format_string_list_from_array";

export const formatBodyLocationString = (
  { location, sublocation, lateralities } = {},
  localizationDictionary
) => {
  if (!location && !sublocation && !lateralities) return "";

  const locationString =
    localizationDictionary[`location.${location}`] || location;
  const sublocationString =
    localizationDictionary[`sublocation.${sublocation}`] || sublocation;
  let lateralitiesString = "";

  let lateralitiesTranslatedArray;
  if (lateralities) {
    lateralitiesTranslatedArray = lateralities.map(value => {
      return localizationDictionary[`laterality.${value}`] || value;
    });
    lateralitiesString = lateralitiesTranslatedArray.length
      ? ` - ${createListStringFromArray(lateralitiesTranslatedArray)}`
      : "";
  }

  return sublocationString
    ? `${sublocationString}${lateralitiesString}`
    : `${locationString}${lateralitiesString}`;
};
