import { patientsActionTypes } from "src/action_types/section_action_types";

/**
 * Reducer for the patient search string entered by users
 */
export function patientSearchStringReducer(state = "", action) {
  switch (action.type) {
    case patientsActionTypes.setPatientSearchString:
      return action.payload;
    default:
      return state;
  }
}
