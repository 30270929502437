import { createSelector } from "reselect";
import { routePathnameSelector } from "src/selectors/router_selectors";

/**
 * Memoized selector, returns true if organizatino status filter is enabled, false otherwise
 */

export const organizationFilterEnabledSelector = createSelector(
  routePathnameSelector,
  pathname => {
    return pathname && pathname !== "/admin/alerts" ? true : false;
  }
);
