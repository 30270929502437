// Redux & router
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { signOut } from "src/actions/session_actions";

// Selectors
import {
  sideBarNavigationItemsSelector,
  adminDefaultViewSelector,
  adminTabEnabledSelector
} from "src/selectors/config_selectors";
import { authWorkflowSelector } from "src/selectors/session_selectors";

import { routeLocationSelector } from "src/selectors/router_selectors";
import {
  outcomesTabEnabledSelector,
  exportsTabEnabledSelector,
  monitoringTabEnabledSelector,
  myDashboardTabEnabledSelector,
  logoutTabEnabledSelector,
  alertsEnabledSelector,
  evaluationListEnabledSelector,
  healingIndexDashboardEnabledSelector,
  searchPatientDashboardEnabledSelector,
  reportingPermissionEnabledSelector
} from "src/selectors/ui_config_selectors/global";
import { isMedlineSelector } from "src/selectors/config_selectors/is_medline_selector/index.js";

// Component
import { SideBarTabNavigationComponent } from "src/components/side_bar_tab_navigation_component";

function mapStateToProps(state) {
  return {
    myDashboardTabEnabled: myDashboardTabEnabledSelector(state),
    alertsTabEnabled: alertsEnabledSelector(state),
    evaluationListEnabled: evaluationListEnabledSelector(state),
    sideBarNavigationItems: sideBarNavigationItemsSelector(state),
    outcomesTabEnabled: outcomesTabEnabledSelector(state),
    location: routeLocationSelector(state),
    adminDefaultView: adminDefaultViewSelector(state),
    adminTabEnabled: adminTabEnabledSelector(state),
    exportsTabEnabled: exportsTabEnabledSelector(state),
    isMedline: isMedlineSelector(state),
    monitoringTabEnabled: monitoringTabEnabledSelector(state),
    searchPatientDashboardEnabled: searchPatientDashboardEnabledSelector(state),
    logoutTabEnabled: logoutTabEnabledSelector(state),
    healingIndexDashboardEnabled: healingIndexDashboardEnabledSelector(state),
    isReportingEnabled: reportingPermissionEnabledSelector(state),
    authWorkflow: authWorkflowSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => {
      dispatch(signOut());
    }
  };
}

export const SideBarTabNavigationContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SideBarTabNavigationComponent)
);
