import { createSelector } from "reselect";
import { partnerNameSelector } from "src/selectors/config_selectors";

export const logoutTabEnabledSelector = createSelector(
  partnerNameSelector,
  partnerName => {
    if (partnerName) {
      return partnerName;
    }
    return partnerName == "pcc" ? false : true;
  }
);
