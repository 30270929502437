import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { assessmentsResourceSelectors } from "../assessments_resource_selectors";
import { createLatestAssessmentAnswerForRevisionsSelector } from "../../assessment_answers/create_latest_assessment_answer_for_revisions_selector";

/**
 * Create a selector that gets the assessment for the latest assessmentAnswer for a set of revisions.
 * Returns object broken down by revision id.
 * @param {Object} revision
 * @returns {Function} selector
 */
export function createLatestAssessmentForRevisionsSelector({ revisions }) {
  return createSelector(
    createLatestAssessmentAnswerForRevisionsSelector({ revisions }),
    assessmentsResourceSelectors.dataSelector,
    (assessmentAnswersByRevisionId, assessments) => {
      return Object.keys(assessmentAnswersByRevisionId).reduce((agg, revId) => {
        const assessmentAnswerId = get(
          assessmentAnswersByRevisionId[revId],
          "attributes.assessmentId"
        );
        agg[revId] = assessments[assessmentAnswerId];
        return agg;
      }, {});
    }
  );
}
