import { connect } from "react-redux";
import flowRight from "lodash.flowright";

import { sections } from "src/constants";
import { withResourceLoaderFactory } from "src/hoc/factories";

import { CheckboxFilterComponent } from "src/components/filters";

// Selectors
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";
import { usersResourceSelectors } from "src/selectors/data_selectors";
import { clinicianFilterConfigSelector } from "src/selectors/ui_config_selectors/compliance/clinician_filter_config_selector";
import {
  createAllPagesCliniciansContextSelector,
  createAllPagesCliniciansPayloadSelector
} from "src/selectors/api_selectors/global/users";

// Actions
import { loadUsersAction } from "src/actions/data_actions";
import { createSectionActions } from "src/ui_processors/global/action_creators";
const complianceSectionActions = createSectionActions(sections.compliance);

function mapStateToProps(state) {
  return {
    filterConfig: clinicianFilterConfigSelector(state),
    filterCheckedState: createSectionSelectors(
      sections.compliance
    ).createFilterCheckedStateSelector(clinicianFilterConfigSelector(state))(
      state
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectCheckbox: filterObject => {
      dispatch(complianceSectionActions.selectCheckbox(filterObject));
    },
    deselectCheckbox: filterObject => {
      dispatch(complianceSectionActions.deselectCheckbox(filterObject));
    }
  };
}

export const SideBarClinicianOptionsFilterContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadUsersAction,
    resourceSelectors: usersResourceSelectors,
    createContextSelector: createAllPagesCliniciansContextSelector,
    createPayloadSelector: createAllPagesCliniciansPayloadSelector
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckboxFilterComponent);
