import { createSelector } from "reselect";
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";
import { checkedFacilityIdsSelector } from "../checked_facility_ids_selector";
import { organizationsForSelectedPartnerIdSelector } from "../organizations_for_selected_partnerid_selector";
/**
 * Facility ids selector for the admin section
 * used to get the facility ids that should be passed to the server to load data
 */
export const selectedFacilityIdsSelector = createSelector(
  facilitiesResourceSelectors.createDataForContextSelectorWithString(
    "adminFacilities"
  ),
  checkedFacilityIdsSelector,
  organizationsForSelectedPartnerIdSelector,
  (facilities, checkedFacilitiesIds, organizations) => {
    if (checkedFacilitiesIds && checkedFacilitiesIds.length) {
      return checkedFacilitiesIds;
    } else {
      const organizationIds = Object.values(organizations).map(org => org.id);
      return Object.values(facilities)
        .filter(fac => {
          return organizationIds.includes(fac.attributes.organizationId);
        })
        .map(fac => fac.id);
    }
  }
);
