/**
 * Create a normalizer for a min value in a range
 * @param {number} minValueAllowed - the min value allowed
 * @returns {number|string}
 */
export function createMinValueOfRangeNormalizer(minValueAllowed) {
  return function(currentMinValue, currentMaxValue) {
    const currentMinValueFloat = parseFloat(currentMinValue);
    const currentMaxValueFloat = parseFloat(currentMaxValue);

    if (!currentMinValueFloat && currentMinValueFloat !== 0) {
      return "";
    } else if (
      currentMinValueFloat < minValueAllowed ||
      currentMinValueFloat > currentMaxValueFloat
    ) {
      return minValueAllowed;
    } else if (currentMinValueFloat > currentMaxValueFloat) {
      return minValueAllowed;
    } else {
      return currentMinValueFloat;
    }
  };
}
