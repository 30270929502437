import { createSelector } from "reselect";

/**
 * create a selector to get the api context for admin  facilities
 * @returns {function} selector
 */
export function createAdminFacilitiesContextSelector() {
  return createSelector(
    () => {
      return "adminFacilities";
    },
    context => {
      return context;
    }
  );
}
