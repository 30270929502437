import { createSelector } from "reselect";

// Selectors
import {
  selectedWoundOptionsSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector,
  selectedDateSelector,
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  selectedPayersIdsFilterSelector
} from "src/selectors/section_selectors/wounds";

import { woundsDashboardPayloadGenerator } from "src/api/wound_assessments";

export const administrativeChartPayloadSelector = createSelector(
  selectedWoundOptionsSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector,
  selectedDateSelector,
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  selectedPayersIdsFilterSelector,
  (
    selectedWoundOptions,
    selectedLocationIds,
    acquiredFilter,
    selectedEndDate,
    hprScoreMinRangeFilter,
    hprScoreMaxRangeFilter,
    bwatScoreMinRangeFilter,
    bwatScoreMaxRangeFilter,
    selectedPayersIds
  ) => {
    return woundsDashboardPayloadGenerator(
      selectedWoundOptions,
      selectedLocationIds,
      acquiredFilter,
      selectedEndDate,
      hprScoreMinRangeFilter,
      hprScoreMaxRangeFilter,
      bwatScoreMinRangeFilter,
      bwatScoreMaxRangeFilter,
      selectedPayersIds
    );
  }
);
