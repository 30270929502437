import React from "react";
import PropTypes from "prop-types";
import { FormComponent } from "src/components/forms/form_component_legacy";
import { FormFileUploadComponent } from "src/components/forms/form_file_upload_component";
import { loadStates } from "src/constants/load_states";
import { Button } from "src/ui-kit";
import moment from "moment";

import { reduxFormNames } from "src/constants";

//Styles
import styles from "./style.module.less";

/**
 * Component used to import the CSV files
 */
export class ImportCsvFormComponent extends React.Component {
  static propTypes = {
    csvImportsLoadState: PropTypes.string.isRequired
  };
  state = { key: "file" };

  /**
   * Event handler for the upload CSV
   */
  onUploadUsersCsvClicked = values => {
    const { csvImportsLoadState, partnerId } = this.props;
    if (csvImportsLoadState == loadStates.ready) {
      const formData = new FormData();
      formData.set("csv", values.file[0]);
      this.props.resetForm();
      this.setState({
        key: moment()
          .unix()
          .toString()
      });
      this.props.sendImportCsvAction(formData, partnerId);
    }
  };

  onCancelClick = () => {
    this.props.resetForm();
    this.props.cancel();
  };

  render() {
    const { submitting, pristine } = this.props;

    return (
      <div className={styles["style-buttons"]}>
        <FormComponent
          form={reduxFormNames.importCsvForm}
          initialValues={this.props.initialValuesObject}
          onSubmit={this.onUploadUsersCsvClicked}
        >
          <FormFileUploadComponent
            name="file"
            labelText="Select File"
            keyName={this.state.key}
          />
          <div className="pull-right">
            <Button
              className="dashboard-btn"
              disabled={pristine || submitting}
              subtype="submit"
            >
              Save
            </Button>
            <Button
              className="btn-default"
              data-dismiss="modal"
              disabled={submitting}
              onClick={this.onCancelClick}
              subtype="button"
            >
              Cancel
            </Button>
          </div>
        </FormComponent>
      </div>
    );
  }
}
