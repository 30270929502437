import React from "react";
import { Link } from "react-router-dom";
import get from "lodash.get";

export function patientLastNameFormatter(cell, row) {
  let lastName, patientId;
  if (row.type === "patients") {
    lastName = get(row, "attributes.lastName");
    patientId = row.id;
  } else {
    lastName = get(row, "attributes.patientLastName");
    patientId = get(row, "attributes.patientId");
  }

  if (!lastName) return "";

  if (patientId) {
    return <Link to={`/patients/${patientId}`}>{lastName}</Link>;
  } else {
    return lastName;
  }
}
