// @flow
import { Record } from "immutable";

export const generateDefaultState = (defaultPropsConfig: Object = {}) => {
  const DefaultState = Record({
    ...defaultPropsConfig
  });
  const defaultState = new DefaultState();
  return defaultState;
};
