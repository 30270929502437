import { createSelector } from "reselect";
import { partnerNameSelector } from "../partner_name_selector";

/**
 * Memoized selector for the partner name (part of the config state tree)
 */
export const isPccSelector = createSelector(
  partnerNameSelector,
  partnerName => {
    return partnerName === "pcc" || partnerName === "pcc_wound";
  }
);
