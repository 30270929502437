import { createSelector } from "reselect";

export function createSelectors(fieldName, rootSelector) {
  let fieldSelector;
  if (rootSelector) {
    fieldSelector = createSelector(
      rootSelector,
      root => root[fieldName]
    );
  } else {
    fieldSelector = state => state[fieldName];
  }

  function createValuesForKeySelector(key) {
    return createSelector(
      fieldSelector,
      field => {
        return field[key] || [];
      }
    );
  }

  return {
    fieldSelector,
    createValuesForKeySelector
  };
}
