// React
import { connect } from "react-redux";

// selectors
import { createShowAcquiredUnknownSelector } from "src/selectors/section_selectors/survey";

// Components
import { SurveyTallyTableHeaderComponent } from "src/components/tables";

// -------------- Prop & Component Connection -------------- //
function mapStateToProps(state, ownProps) {
  return {
    showAcquiredUnknown: createShowAcquiredUnknownSelector(ownProps.tallyType)(
      state
    )
  };
}

export const SurveyTallyTableHeaderContainer = connect(mapStateToProps)(
  SurveyTallyTableHeaderComponent
);
