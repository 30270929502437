import { createSelector } from "reselect";
import { createRevisionsForSeriesIdSelector } from "../create_revisions_for_series_id_selector";

/**
 * Create a selector that returns the first/most recent revision in a series.
 * @param {string} seriesId
 * @return {Function} selector
 */
export function createLatestRevisionForSeriesIdSelector(seriesId) {
  return createSelector(
    createRevisionsForSeriesIdSelector(seriesId),
    revisions => {
      if (revisions.length) {
        return revisions[0];
      }

      return undefined;
    }
  );
}
