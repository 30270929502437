import normalize from "json-api-normalizer";
import { trackError } from "src/error_tracking";

import { dataActionTypes } from "src/action_types/data_action_types";
import { fetchPartners } from "src/api/partners";

/**
 * Action creator for populating partners in the data tree of the store
 * @param {Object} partners partners by id
 * @return {Object} redux action
 */

export function populatePartnersAction(partners) {
  return {
    type: dataActionTypes.populatePartners,
    payload: partners
  };
}

/**
 * Action creator for setting the partners load state to error
 * @return {Object} redux action
 */
function partnersLoadErrorAction() {
  return {
    type: dataActionTypes.partnersLoadError
  };
}

/**
 * Action creator to fetch the partners
 */

export function loadPartnersAction() {
  return dispatch => {
    return fetchPartners()
      .then(response => {
        const partners = normalize(response.data).partners;
        dispatch(
          populatePartnersAction({
            ...response.data,
            data: partners
          })
        );
      })
      .catch(error => {
        // If the request was cancelled it means a new request started
        // don't show the loading view.
        if (error.message == "cancel") {
          return;
        }
        trackError(error);
        dispatch(partnersLoadErrorAction());
      });
  };
}
