import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import { ComplianceContext } from "./compliance_provider_component";
import { TableComponent } from "src/components/tables/table_component";
import { track } from "src/analytics";
//import { LoadingComponent } from "src/components/loading_component";

// Translations
import i18n from "src/translations";

// //CSS
import styles from "./style.module.less";

// Formatters
import { cellFormatters } from "src/components/tables/cell_formatters/index.js";

export function ComplianceBulkLockModalComponent(props) {
  const { subtypeKeysForSchema, hide, patientLinksEnabled } = props;
  const { state } = useContext(ComplianceContext);
  const [modalState, setModalState] = useState("initial");
  const [failedLocks, setFailedLocks] = useState(0);
  const [assessmentSummaries, setAssessmentSummaries] = useState(
    state.checkedAssessmentSummaries
  );

  const totalLocks = assessmentSummaries.length;

  const lockAssessmentSummary = async assessmentAnswerId => {
    const url = `/api/v4/assessment-answers/${assessmentAnswerId}/lock`;
    const payload = {
      id: assessmentAnswerId,
      data: {
        id: assessmentAnswerId
      }
    };
    try {
      const result = await axios.put(url, payload);
      return result;
    } catch (error) {
      console.log("errorResponse", error.response);
      return error.response;
    }
  };

  const setAssessmentsToLoading = () => {
    setAssessmentSummaries(previousList => {
      return previousList.map(summary => ({ ...summary, status: "loading" }));
    });
  };

  const replaceAssessmentSummary = updatedSummary => {
    setAssessmentSummaries(previousList => {
      return previousList.map(summary =>
        summary.id === updatedSummary.id ? updatedSummary : summary
      );
    });
  };

  const submitLockRequests = () =>
    assessmentSummaries.map(summary => {
      lockAssessmentSummary(summary.attributes.assessmentAnswerId).then(
        ({ status, data }) => {
          const updatedSummary = {
            ...summary,
            status,
            message: data.message || "Evaluation Locked Successfully"
          };
          replaceAssessmentSummary(updatedSummary);
        }
      );
    });

  const isLockComplete = ({ status = "" }) => !["", "loading"].includes(status);

  const countFailedLocks = () =>
    assessmentSummaries.filter(({ status = "" }) => status !== 200).length;

  useEffect(() => {
    if (modalState === "loading") {
      setAssessmentsToLoading();
      submitLockRequests();
    }
  }, [modalState]);

  useEffect(() => {
    const allLocksComplete = assessmentSummaries.filter(isLockComplete).length;

    if (allLocksComplete) {
      setFailedLocks(countFailedLocks());
      setModalState("done");
    }
  }, [assessmentSummaries]);

  const columns = [
    {
      text: i18n.t("headers.patientName"),
      dataField: "firstName",
      formatter: cellFormatters.patientFirstNameLastNameFormatter,
      formatExtraData: {
        patientLinksEnabled: patientLinksEnabled
      }
    },
    {
      text: i18n.t("headers.dateCreated"),
      dataField: "attributes.createdAt",
      formatter: cellFormatters.dateAndTimeFormatter
    },
    {
      text: i18n.t("headers.evaluationType"),
      dataField: "evaluationType",
      formatter: cellFormatters.assessmentTypeFormatter,
      formatExtraData: {
        patientLinksEnabled: patientLinksEnabled
      }
    },
    {
      text: i18n.t("headers.woundNumberTypeSubtype"),
      dataField: "attributes.patientWoundNumber",
      formatter: cellFormatters.woundInfoFormatter,
      formatExtraData: {
        localizationDictionary: props.dictionary,
        subtypeKeysForSchema,
        patientLinksEnabled: patientLinksEnabled
      },
      hidden: !props.woundNumbersEnabled
    },
    {
      text: i18n.t("headers.status"),
      dataField: "status",
      formatter: cellFormatters.assessmentLockStatusFormatter
    },
    {
      text: i18n.t("headers.message"),
      dataField: "message"
    }
  ];

  const initialFooter = () => (
    <>
      <p>
        The evaluations above will be locked. Please click confirm to continue
      </p>
      <div>
        <button
          onClick={() => {
            setModalState("loading");
            track("DASHBOARD_BULK_LOCK_CLICKED");
          }}
          className={"btn dashboard-btn"}
        >
          Confirm
        </button>
        <button
          type="button"
          data-dismiss="modal"
          className={"btn btn-default"}
          onClick={hide}
        >
          Cancel
        </button>
      </div>
    </>
  );

  const loadingFooter = () => (
    <>
      <p>Please wait while the evaluations are being locked</p>
      <FontAwesome name="spinner" size="2x" spin />
    </>
  );

  const completeFooter = () => (
    <>
      {completeText()}
      <button onClick={hide} className={"btn dashboard-btn"}>
        Done
      </button>
    </>
  );

  const completeText = () => {
    if (failedLocks !== 0) {
      return (
        <>
          <p>
            Lock Completed.{" "}
            <b>
              {failedLocks}/{totalLocks}
            </b>
            {" Failed - please review the above list"}
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>Lock Completed. Please Review the above list.</p>
        </>
      );
    }
  };

  const footerComponents = () => {
    if (modalState === "initial") {
      return initialFooter();
    } else if (modalState === "loading") {
      return loadingFooter();
    } else if (modalState === "done") {
      return completeFooter();
    }
  };

  return (
    <div className={styles["lock-modal"]}>
      <TableComponent
        data={assessmentSummaries}
        keyField="id"
        columns={columns}
      />
      <div className="text-center">{footerComponents()}</div>
    </div>
  );
}
