import get from "lodash.get";
import i18n from "src/translations";

const encounterDisciplineFormatter = (encounter, encounterFieldsShown) => {
  if (encounterFieldsShown.discipline) {
    const encounterDiscipline =
      get(encounter, "attributes.discipline", null) || "N/A";
    return `${i18n.t(
      "data.encounters.disciplineString"
    )}: ${encounterDiscipline}`;
  }
  return null;
};

export default encounterDisciplineFormatter;
