// @flow
import { Map, OrderedSet, List } from "immutable";
import { createSelector } from "reselect";
import { bindMethods } from "src/utils/classUtils";
const defaultMap = new Map();
const defaultOrderedSet = new OrderedSet();

const defaultIdSelector = function(state, props) {
  if (props && props.id) {
    return props.id;
  }
  return "";
};

export class Selector<X> {
  name: string;
  defaultModel: X;
  constructor(name: string, model: X) {
    this.name = name;
    this.defaultModel = model;
    bindMethods(
      this,
      "getEntityData",
      "getEntities",
      "findEntity",
      "getRelatedEntityIds",
      "findRelatedEntityId"
    );
  }
  getEntityData(): $$selector<Map<$$id, X>> {
    return state => state.entities[this.name].data;
  }
  getMonoRelationshipData(
    relationshipName: string
  ): $$selector<Map<$$id, $$id>> {
    return createSelector(
      [state => state.relationships[this.name]],
      (relationships: Map<$$id, Map<$$id, $$id>>) =>
        relationships.get(relationshipName, defaultMap)
    );
  }
  getManyRelationshipData(
    relationshipName: string
  ): $$selector<Map<$$id, OrderedSet<$$id>>> {
    return createSelector(
      [state => state.relationships[this.name]],
      (relationships: Map<$$id, Map<$$id, OrderedSet<$$id>>>) =>
        relationships.get(relationshipName, defaultMap)
    );
  }
  getEntities(idsSelector: $$selector<List<$$id>>): $$selector<List<X>> {
    return createSelector(
      [idsSelector, this.getEntityData()],
      (ids: List<$$id>, entities: Map<$$id, X>) => {
        return ids.map(id => entities.get(`${id}`) || this.defaultModel);
      }
    );
  }

  findEntity(idSelector?: $$idSelector = defaultIdSelector): $$selector<X> {
    return createSelector(
      [idSelector, this.getEntityData()],
      (id: $$id, entities: Map<$$id, X>) => {
        return entities.get(`${id}`) || this.defaultModel;
      }
    );
  }

  /**
   * This function accepts an id and returns a selector that retreives the
   * corresponding entry in the store
   */
  findEntityById(id) {
    return createSelector(
      [this.getEntityData()],
      entities => {
        return entities.get(`${id}`) || this.defaultModel;
      }
    );
  }

  getRawRelatedEntityIds(
    relationshipName: string,
    idSelector?: $$idSelector = defaultIdSelector
  ): $$selector<OrderedSet<$$id>> {
    return createSelector(
      [idSelector, this.getManyRelationshipData(relationshipName)],
      (id: $$id, relationships: Map<$$id, OrderedSet<$$id>>) =>
        relationships.get(`${id}`) || defaultOrderedSet
    );
  }
  getRelatedEntityIds(
    relationshipName: string,
    idSelector?: $$idSelector = defaultIdSelector
  ): $$selector<List<$$id>> {
    return createSelector(
      [this.getRawRelatedEntityIds(relationshipName, idSelector)],
      (ids: OrderedSet<$$id>) => ids.toList()
    );
  }
  findRelatedEntityId(
    relationshipName: string,
    idSelector?: $$idSelector = defaultIdSelector
  ): $$selector<$$id> {
    return createSelector(
      [idSelector, this.getMonoRelationshipData(relationshipName)],
      (id: $$id, relationships: Map<$$id, $$id>) =>
        relationships.get(`${id}`, 0)
    );
  }
}

export class PropertySelector<X> {
  propertyName: string;
  baseSelector: Selector<X>;
  model: X;
  constructor(propertyName: string, model: X) {
    this.baseSelector = new Selector("properties", model);
    this.propertyName = propertyName;
  }
  findProperty = function(): $$selector<any> {
    return createSelector(
      [this.baseSelector.findEntity(() => this.propertyName)],
      (entity: any) => {
        return entity;
      }
    );
  };
  getRelatedEntityIds = function(
    relationshipName: string
  ): $$selector<List<$$id>> {
    return createSelector(
      [
        this.baseSelector.getRelatedEntityIds(
          relationshipName,
          () => this.propertyName
        )
      ],
      (entityIds: List<$$id>) => entityIds
    );
  };
  getRawRelatedEntityIds = function(
    relationshipName: string
  ): $$selector<OrderedSet<$$id>> {
    return createSelector(
      [
        this.baseSelector.getRawRelatedEntityIds(
          relationshipName,
          () => this.propertyName
        )
      ],
      (entityIds: OrderedSet<$$id>) => entityIds
    );
  };
}
