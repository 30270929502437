export const reduxFormNames = {
  changePasswordForm: "changePasswordForm",
  importCsvForm: "importCsvForm",
  patientForm: "patientForm",
  roleViewForm: "roleViewForm",
  roleCreateForm: "roleCreateForm",
  roleEditForm: "roleEditForm",
  userCreateForm: "userCreateForm",
  userEditForm: "userEditForm",
  resetPasswordForm: "resetPasswordForm",
  schemaAnswersForm: "schemaAnswersForm",
  patientAdtForm: "patientAdtForm",
  savedFilterForm: "savedFilterForm",
  ruleCreateForm: "ruleCreateForm",
  signAssessmentAnswerForm: "signAssessmentAnswerForm",
  woundAlertRuleForm: "woundAlertRuleForm",
  riskAlertRuleForm: "riksAlertRuleForm"
};
