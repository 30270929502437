import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { flowRight } from "lodash";
import { push } from "react-router-redux";

//Components
import { ThermalDetailsComponent } from "src/components/thermal_details_component";

//Selector
import { patientBreadcrumbsEnabledSelector } from "src/selectors/config_selectors";

// Hoc
import { withPatientLoader } from "src/hoc";

function mapStateToProps(state, props) {
  const patientId = props.match.params.patientId;
  return {
    breadcrumbsEnabled: patientBreadcrumbsEnabledSelector(state),
    patientId
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    goBack() {
      dispatch(push(`/patients/${ownProps.match.params.patientId}`));
    }
  };
}

export const ThermalDetailsContainer = flowRight(
  withRouter,
  withPatientLoader,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ThermalDetailsComponent);
