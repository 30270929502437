import React from "react";
import PropTypes from "prop-types";

import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormComponent } from "src/components/forms/form_component_legacy";

// Components
// TODO: refactor this
import { Row, Column } from "@sw/ui-kit";

// Validations
import { required, dateFormatMatch } from "src/validations";

// Normalizers
import { dateNormalizer } from "src/input_normalizers";

export class PatientSearchHomeComponent extends React.PureComponent {
  static propTypes = {
    searchPatientByShareToken: PropTypes.func.isRequired,
    isRoleProvider: PropTypes.bool.isRequired,
    isRoleSupplier: PropTypes.bool.isRequired
  };

  onSubmit = values => {
    const {
      searchPatientByShareToken,
      navigateToSearchedPatient,
      isRoleProvider
    } = this.props;
    const payload = isRoleProvider
      ? {
          filter: {
            share_token: values.shareToken.toUpperCase(),
            birth_at: values.birthAt
          }
        }
      : {
          filter: {
            mrn: values.mrn,
            birth_at: values.birthAt
          }
        };
    searchPatientByShareToken(payload, isRoleProvider).then(patient => {
      navigateToSearchedPatient(patient, isRoleProvider);
    });
  };

  render() {
    const { isRoleProvider, isRoleSupplier } = this.props;
    return (
      <div className="patients-view">
        <Row>
          <Column>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "500px",
                paddingTop: "200px"
              }}
            >
              <p
                style={{ width: "30%", margin: "0 auto", fontSize: "18px" }}
                className="text-center"
              >
                Search Patient:
              </p>
              <div style={{ width: "30%", margin: "0 auto", padding: "10px" }}>
                <FormComponent
                  form="searchPatientForm"
                  onSubmit={this.onSubmit}
                >
                  {isRoleProvider && (
                    <FormInputComponent
                      name="shareToken"
                      component="input"
                      placeholder="Swift Code"
                      className="upper-case"
                      validate={[required]}
                      labelText="Swift Code:"
                      alignmentPatientSearch={true}
                    />
                  )}
                  {isRoleSupplier && (
                    <FormInputComponent
                      name="mrn"
                      component="input"
                      placeholder="MRN"
                      className=""
                      validate={[required]}
                      labelText="MRN:"
                      alignmentPatientSearch={true}
                    />
                  )}
                  <div className="margin-top-10px"></div>
                  <FormInputComponent
                    name="birthAt"
                    component="input"
                    placeholder="YYYY-MM-DD"
                    className="upper-case"
                    validate={[required, dateFormatMatch]}
                    labelText="Date of Birth:"
                    normalize={dateNormalizer}
                    alignmentPatientSearch={true}
                  />
                  <div className="text-center margin-top-10px">
                    <button className="btn btn-default" type="submit">
                      Search
                    </button>
                  </div>
                </FormComponent>
              </div>
            </div>
          </Column>
        </Row>
      </div>
    );
  }
}
