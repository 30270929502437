import axios from "axios";

/**
 * Fetch series
 * @return {Promise}
 */
export function fetchSeries(seriesId) {
  const url = `/api/v4/series/${seriesId}`;
  return axios.get(url);
}
