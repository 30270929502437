import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

export class InputComponent extends React.PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setValue: PropTypes.func.isRequired,
    normalizer: PropTypes.func
  };

  state = { value: "" };

  componentDidUpdate(oldProps) {
    if (propsAreDifferent(oldProps, this.props, "value")) {
      this.setState({
        value: this.props.value
      });
    }
  }

  /**
   * Update search string in the redux store
   */
  handleSearchValueChange = event => {
    // Update the display value immediately
    this.setState({
      value: event.target.value
    });

    // debounce change to the handler
    event.persist();
    this.handleSearchChangeDebounce(event);
  };

  /**
   * Debounced handler for the search string change
   */
  handleSearchChangeDebounce = debounce(event => {
    let value = event.target.value;

    // If we have a normalization function - run the value through it
    if (this.props.normalizer) {
      value = this.props.normalizer(value);

      this.setState({
        value
      });
    }

    this.props.setValue(value);
  }, 500);

  render() {
    const { placeholder } = this.props;
    return (
      <div className="form-group">
        <input
          type="search"
          className="form-control"
          placeholder={placeholder}
          onChange={this.handleSearchValueChange}
          value={this.state.value}
        />
      </div>
    );
  }
}
