import { createSelector } from "reselect";

//Constants
import { sections } from "src/constants";

// Selectors
import { createSectionSelectors } from "src/ui_processors";
const sectionSelectors = createSectionSelectors(sections.alerts);

/**
 * Selected evaluation type filter selector
 */
export const selectedEvaluationTypeSelector = createSelector(
  sectionSelectors.filtersSelector,
  filters =>
    filters.find(filter => filter.key == "evaluationType")
      ? filters.find(filter => filter.key == "evaluationType").value
      : undefined
);
