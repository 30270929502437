import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

// Selectors
import { unitsResourceSelectors } from "src/selectors/data_selectors";

// Constants
import { reduxFormFieldNames } from "src/constants";

// Utils
import { sortByName } from "src/utils/sort_by_name";

export function createUnitOptionsSelector(formName) {
  return createSelector(
    getFormValues(formName),
    unitsResourceSelectors.dataSelector,
    (values, units) => {
      if (values && values[reduxFormFieldNames.facilityId]) {
        const filteredUnits = Object.values(units).filter(
          o => o.attributes.facilityId == values[reduxFormFieldNames.facilityId]
        );

        return sortByName(filteredUnits);
      }

      return [];
    }
  );
}
