export const headers = {
  acknowledge: "Acknowledge",
  acquired: "Acquired",
  acquiredNotSet: "Acquired Not Set",
  action: "Action",
  addRiskAlert: "Add Risk Evaluation Alert",
  addWoundAlert: "Add Wound Evaluation Alert",
  alertDate: "Alert Date",
  alert: "Alert",
  alerts: "Alerts",
  alertType: "Alert Type",
  allergies: "Allergies",
  appliesTo: "Applies To",
  characteristics: "Characteristics",
  cormorbidities: "Cormorbidities",
  admissionStatus: "Admission Status",
  bodyLocation: "Body Location",
  bwatScore: "BWAT Score",
  clinicianOptions: "Clinicians",
  comparison: "Comparison",
  compliance: "Compliance",
  confirm: "Confirm",
  context: "Context",
  create: "Create",
  createdBy: "Created By",
  dashboardSessionTimeout: "Dashboard Session Timeout",
  date: "Date",
  dateCreated: "Creation Date",
  dateRange: "Date Range",
  describe: "Describe",
  description: "Description",
  details: "Details",
  evaluationList: "Evaluation List",
  view: "Details", // TODO - fix this for modalModes
  edit: "Edit",
  editRiskAlert: "Edit Risk Evaluation Alert",
  editWoundAlert: "Edit Wound Evaluation Alert",
  education: "Education",
  eSignature: "E-Signature",
  etiologyReport: "Etiology Report",
  etiologyReport_uppercase: "ETIOLOGY REPORT",
  evaluatedBy: "Evaluated By",
  evaluations: "Evaluations",
  evaluationDate: "Evaluation Date",
  evaluationStatus: "Evaluation Status",
  evaluationType: "Evaluation Type",
  filterName: "Filter Name",
  filterUsers: "Filter Users",
  firstName: "First Name",
  history: "History",
  hprScore: "HPR Score",
  inProgressEvaluations: "In Progress Evaluations",
  completedEvaluations: "Completed Evaluations",
  signedEvaluations: "Signed Evaluations",
  inProgress: "In Progress",
  signed: "Signed",
  completed: "Completed",
  lastName: "Last Name",
  lineList: "Line List",
  lineList_uppercase: "LINE LIST",
  list: "List",
  locationReport: "Location Report",
  location: "Location",
  locations: "Locations",
  locked: "Locked",
  measurement: "Mx",
  medlinePageTitle: "NE1 Wound Management System - Medline Industries, Inc.",
  medication: "Medication",
  message: "Message",
  mrn: "MRN",
  myDashboard: "My Dashboard",
  mySavedFilters: "My Saved Filters",
  notes: "Notes",
  numberOfDaysInProgress: "Number Of Days In Progress",
  orders: "Orders",
  pain: "Pain",
  patientEvaluations: "Patient Evaluations",
  patientName: "Patient Name",
  pathways: "Pathways",
  patientName: "Patient Name",
  payer: "Payer(s)",
  payers: "Payers",
  progress: "Progress",
  revisedBy: "Revised By",
  revisedDate: "Revision Date",
  range: "Range",
  risk: "Risk",
  riskAlerts: "Risk Alerts",
  role: "Role",
  selectedFilters: "Selected Filters",
  signed: "Signed",
  skin: "Skin",
  standard: "Standard",
  state: "State",
  status: "Status",
  supplies: "Supplies",
  surfaceArea: "Surface Area",
  surveyOptions: "Survey Options",
  swiftMedical: "Swift Medical",
  table: "Table",
  thermal: "Thermal",
  total: "Total",
  totalActiveWounds: "Total Active Wounds",
  totalResolved: "Total Resolved",
  treatment: "Tx",
  treatmentNavigator: "Treatment Navigator",
  type: "Type",
  visitFrequency: "Visit Freq",
  wound: "Wound",
  woundNumberTypeSubtype: "Wound#, Wound Type, Subtype",
  woundAlerts: "Wound Alerts",
  woundBedDelta: "Wound Bed Delta",
  woundNumber: "Wound #",
  woundNumberTypeAndSubtype: "Wound #, Type, Subtype",
  woundNumbersGraph: "Wound Numbers Graph",
  woundNumbersTable: "Wound Numbers Table",
  woundResidents: "Wound Residents",
  woundRisk: "Healing Risk",
  woundType: "Wound Type",
  woundTypes: "Wound Types",
  overallProgress: "Wound Progress",
  bwatScore: "BWAT Score",
  compliance: "Compliance",
  myDashboard: "My Dashboard",
  clinicianOptions: "Clinicians",
  eSignature: "E-Signature",
  clinicians: "Evaluations by Clinician",
  controlView: "Control View",
  differenceView: "Difference View",
  visible: "Visible",
  fluoroVisible: "Fluoro Visible",
  fluoroEnhanced: "Fluoro Enhanced",
  fluoro: "Fluoro"
};
