import React from "react";

export class UpArrowIconComponent extends React.Component {
  render() {
    return (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.53033 0.518954C6.23744 0.226061 5.76256 0.226061 5.46967 0.518954L0.6967 5.29192C0.403806 5.58482 0.403806 6.05969 0.696699 6.35258C0.989593 6.64548 1.46447 6.64548 1.75736 6.35258L6 2.10994L10.2426 6.35259C10.5355 6.64548 11.0104 6.64548 11.3033 6.35259C11.5962 6.05969 11.5962 5.58482 11.3033 5.29192L6.53033 0.518954ZM6.75 12.6826L6.75 1.04928L5.25 1.04928L5.25 12.6826L6.75 12.6826Z"
          fill="#7B7D87"
        />
      </svg>
    );
  }
}
