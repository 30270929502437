import moment from "moment";

import { getHeaderV2 } from "../get_header_v2";
import { getPdfOptionsV2 } from "../get_pdf_options_v2";
import { getBody } from "./get_body";

export function createWoundsPdf(globalContent, options, images) {
  const contextText = moment(globalContent.selectedDate).format("MMMM Do YYYY");
  return {
    header: getHeaderV2(
      { ...globalContent, contextText },
      { ...options, landscape: true }
    ),
    content: getBody(globalContent, images),
    ...getPdfOptionsV2({ landscape: true })
  };
}
