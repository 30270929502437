import { connect } from "react-redux";
import { OutcomesSectionComponent } from "src/components/outcomes_section_component";

// Selectors
import { orderedSelectedLocationsSelector } from "src/selectors/section_selectors/outcomes/ordered_selected_locations_selector";

function mapStateToProps(state) {
  return {
    selectedLocations: orderedSelectedLocationsSelector(state)
  };
}

export const OutcomesSectionContainer = connect(mapStateToProps)(
  OutcomesSectionComponent
);
