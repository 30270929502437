import { createSelector } from "reselect";
import {
  viewModeSelector,
  disablePatientLinksAccessTokenValueSelector,
  authWorkflowSelector
} from "src/selectors/session_selectors";

export const patientLinksEnabledSelector = createSelector(
  viewModeSelector,
  disablePatientLinksAccessTokenValueSelector,
  authWorkflowSelector,
  (viewMode, disablePatientLinks, authWorkflow) => {
    // disabled for admins or if specified
    if (
      viewMode === "emc" ||
      disablePatientLinks ||
      authWorkflow === "embedded"
    ) {
      return false;
    } else {
      return true;
    }
  }
);
