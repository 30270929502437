import React from "react";
import { connect } from "react-redux";
import i18n from "src/translations";
import moment from "moment";

import { saveQaNote } from "src/actions/data_actions/qa_notes";

// Selectors
import { qaNoteEnabledRoleSelector } from "src/selectors/ui_config_selectors/global";

// Styles
import styles from "./style.module.less";

export const QA_NOTE_MAX_LENGTH = 250;

export const QaNoteInput = ({
  dispatch,
  assessmentAnswer,
  writePermission
}) => {
  const [note, setNote] = React.useState(assessmentAnswer.qaNoteValue || "");
  if (!writePermission && note == "") {
    return null;
  }

  return (
    <div className={styles["qa-note-input-container"]}>
      <p id="qaNoteTitle" className={styles["qa-note-title"]}>
        {!!assessmentAnswer.qaNoteId
          ? i18n.t("data.qaNotes.updatedBy", {
              user: assessmentAnswer.qaNoteAuthor,
              date: moment(assessmentAnswer.qaNoteUpdatedAt).format("LL - LT")
            })
          : i18n.t("data.qaNotes.createNewNote")}
        <span className={styles["qa-note-hint"]}>
          ({i18n.t("data.qaNotes.notPartOfEval")})
        </span>
      </p>

      <textarea
        id="qaNoteTextArea"
        className={styles["qa-note-input-field"]}
        rows="3"
        placeholder={i18n.t("data.qaNotes.qaNotePlaceHolder")}
        maxLength={QA_NOTE_MAX_LENGTH}
        value={note}
        onChange={e => setNote(e.target.value)}
        disabled={!writePermission}
      />

      {writePermission && (
        <div
          id="qaFooter"
          className={`row text-right ${styles["qa-note-footer"]}`}
        >
          <span id="qaNoteCharsLeft" className={styles["qa-note-hint"]}>
            {QA_NOTE_MAX_LENGTH - note.length}/{QA_NOTE_MAX_LENGTH}{" "}
            {i18n.t("data.qaNotes.charLeft")}
          </span>
          <button
            className={styles["qa-note-button-primary"]}
            onClick={() => dispatch(saveQaNote(note, assessmentAnswer))}
            disabled={
              (note.length < 1 && !assessmentAnswer.qaNoteId) ||
              assessmentAnswer.qaNoteValue === note
            }
          >
            {i18n.t("actions.save")}
          </button>
          <button
            className={styles["qa-note-button-secondary"]}
            onClick={() => setNote(assessmentAnswer.qaNoteValue || "")}
            disabled={
              (note.length < 1 && !assessmentAnswer.qaNoteId) ||
              assessmentAnswer.qaNoteValue === note
            }
          >
            {i18n.t("actions.cancel")}
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    writePermission: qaNoteEnabledRoleSelector(state)
  };
};

export default connect(mapStateToProps)(QaNoteInput);
