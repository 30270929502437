import { connect } from "react-redux";
import moment from "moment";

// Constants
import { sections } from "src/constants";

// Components
import { DatePickerComponent } from "src/components/ui_kit";

// Selectors
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";

// Actions
import { createSectionActions } from "src/ui_processors/global/action_creators";

const selectors = createSectionSelectors(sections.exports);
const actions = createSectionActions(sections.exports);

// State
function mapStateToProps(state) {
  let date = selectors.dateFilterSelector(state);
  date = date ? moment(date) : date;
  return {
    selected: date
  };
}

// Dispatch Actions
function mapDispatchToProps(dispatch) {
  return {
    onChange: dateString => {
      dispatch(actions.selectDate({ key: "date", value: dateString }));
    }
  };
}

export const WoundsEvaluationsDateFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DatePickerComponent);
