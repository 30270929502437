import { createSelector } from "reselect";

// Api
import { createGetPayload } from "src/api/revisions/payload_generators";

// Selectors
import { createFirstPageContextSelector } from "../create_first_page_context_selector";
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { patientsSectionSelector } from "src/selectors/section_selectors/patients";
import { visibleEvaluationStatesPermissionSelector } from "src/selectors/config_selectors/visible_evaluation_states_permission";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const revisionsTablePageLimitSelector = SingleValueReduxUtil.createSelectors(
  "revisionsTablePageLimit",
  patientsSectionSelector
);

// Utils
import { buildQueryString } from "src/utils/build_query_string";

export function createCurrentPageQuerySelector({ seriesId }) {
  return createSelector(
    revisionsResourceSelectors.createCurrentPageForContextSelector(
      createFirstPageContextSelector({ seriesId })
    ),
    revisionsTablePageLimitSelector.fieldSelector,
    visibleEvaluationStatesPermissionSelector,
    (currentPage, pageLimit, assessmentStateFilters) => {
      const { lockState } = assessmentStateFilters;
      return `${seriesId}/revisions${buildQueryString(
        createGetPayload({
          pageNumber: currentPage,
          pageLimit,
          lockState,
          strikeout_state: "open",
          deleted: "false",
          sort: "-created_at"
        })
      )}&include=user`;
    }
  );
}
