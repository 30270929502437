import { createSelector } from "reselect";
import {
  populationIncidenceChartDataDataSelector,
  populationPrevalenceChartDataDataSelector
} from "src/selectors/data_selectors";
import { legacyCreatePopulationLineChart } from "src/charts";
import { brandingSelector } from "src/selectors/ui_config_selectors/global";

export const legacyPopulationIncidencePrevalenceChartDefinitionSelector = createSelector(
  populationIncidenceChartDataDataSelector,
  populationPrevalenceChartDataDataSelector,
  brandingSelector,
  (incidenceChartData, prevalenceChartData, branding) => {
    return legacyCreatePopulationLineChart(
      incidenceChartData,
      prevalenceChartData,
      branding
    );
  }
);
