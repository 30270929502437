import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

//Constants
import { reduxFormNames } from "src/constants";
//Payload Generator
import { createRulePostPayload } from "src/api/rules/payload_generators";

//Translations
import i18n from "src/translations";

//Validations
import { required } from "src/validations";

//Components
import { FormComponent } from "src/components/forms/form_component_legacy";
import { FormTitleComponent } from "src/components/forms/form_title_component";
import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormInputOptionComponent } from "src/components/forms/form_input_option_component";
import { FormMultipleOptionsSelectionComponent } from "src/components/forms/form_multiple_options_selection_component";
import { FormRangeDropdownsComponent } from "src/components/forms/form_range_dropdowns_component";
import { FormSubmitComponent } from "../form_submit_component";
import { ruleParameterOperators } from "../../../constants";

export class RiskAlertRuleFormComponent extends React.Component {
  static propTypes = {
    initialValues: PropTypes.object.isRequired,
    organizations: PropTypes.array.isRequired,
    facilities: PropTypes.array.isRequired
  };

  onSubmit = formValues => {
    const {
      templates,
      sendPatchAlertAction,
      sendPostAlertAction,
      cancel
    } = this.props;
    formValues.template = templates.find(
      template => template.id === formValues.ruleTemplateId
    );

    if (formValues.id) {
      sendPatchAlertAction(createRulePostPayload(formValues)).then(() =>
        cancel()
      );
    } else {
      sendPostAlertAction(createRulePostPayload(formValues)).then(() =>
        cancel()
      );
    }
  };

  shouldRefreshRuleParams = () => {
    const { formValues, createdAlertRuleParams } = this.props;
    let refreshParam = false;
    let { visibleRuleParams } = formValues;
    if (visibleRuleParams && createdAlertRuleParams.visibleRuleParams) {
      const minValueParam = visibleRuleParams.find(
        param => param.operator === ruleParameterOperators.greaterOrEqual
      );

      const createdMinValueParam = createdAlertRuleParams.visibleRuleParams.find(
        param => param.operator === ruleParameterOperators.greaterOrEqual
      );

      if (minValueParam.name !== createdMinValueParam.name) {
        refreshParam = true;
      }
    }

    return refreshParam;
  };

  renderRangeField = () => {
    const { formValues, createdAlertRuleParams } = this.props;
    if (formValues && createdAlertRuleParams.visibleRuleParams.length) {
      let { visibleRuleParams } = formValues;
      let refreshRuleParams = this.shouldRefreshRuleParams();
      if (!visibleRuleParams || refreshRuleParams) {
        visibleRuleParams = createdAlertRuleParams.visibleRuleParams;
      }
      const minValueParam = visibleRuleParams.find(
        param => param.operator === ruleParameterOperators.greaterOrEqual
      );
      const maxValueParam = visibleRuleParams.find(
        param => param.operator === ruleParameterOperators.lessOrEqual
      );
      const name = minValueParam.name;
      const domain = minValueParam.domain;

      if (minValueParam && maxValueParam) {
        return (
          <FormRangeDropdownsComponent
            name={name}
            labelText={i18n.t(`text.alertRuleFormLabels.${name}`)}
            minLabelText={"from"}
            maxLabelText={"to"}
            minOperator={ruleParameterOperators.greaterOrEqual}
            maxOperator={ruleParameterOperators.lessOrEqual}
            domain={domain}
            min={minValueParam.value}
            max={maxValueParam.value}
            validate={[required]}
          />
        );
      }
    }
  };

  render() {
    const {
      initialValues,
      organizations,
      facilities,
      templates,
      customRoles
    } = this.props;

    const formHeader = initialValues.id ? "editRiskAlert" : "addRiskAlert";
    const formAction = initialValues.id ? "saveChanges" : "addAlert";

    return (
      <div>
        <FormTitleComponent>
          <h4>{i18n.t(`headers.${formHeader}`)}</h4>
        </FormTitleComponent>

        <FormComponent
          form={reduxFormNames.riskAlertRuleForm}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
        >
          <FormInputComponent
            name="organizationId"
            component="input"
            componentType="select"
            labelText={i18n.t(`entities.organization`)}
          >
            {organizations &&
              organizations.map(organization => {
                return (
                  <FormInputOptionComponent
                    key={organization.id}
                    text={get(organization, "attributes.name", "")}
                    value={organization.id}
                  />
                );
              })}
          </FormInputComponent>

          <FormInputComponent
            name="facilityId"
            component="input"
            componentType="select"
            labelText={i18n.t(`entities.facility`)}
          >
            {facilities &&
              facilities.map(facility => {
                return (
                  <FormInputOptionComponent
                    key={facility.id}
                    text={facility.text}
                    value={facility.id}
                  />
                );
              })}
          </FormInputComponent>

          <FormInputComponent
            name="ruleTemplateId"
            component="input"
            componentType="select"
            labelText={i18n.t(`text.alertRuleFormLabels.alert`)}
            emptyDefault={true}
            validate={[required]}
          >
            {templates &&
              templates.map(template => {
                let templateName = get(template, "attributes.description", "");
                return (
                  <FormInputOptionComponent
                    key={template.id}
                    text={
                      i18n.exists(
                        `data.ruleTemplateNamesByName.${templateName}`
                      )
                        ? i18n.t(`data.ruleTemplateNamesByName.${templateName}`)
                        : templateName
                    }
                    value={template.id.toString()}
                  />
                );
              })}
          </FormInputComponent>

          {this.renderRangeField()}

          <hr />
          <h4>Roles</h4>
          <FormMultipleOptionsSelectionComponent
            options={customRoles}
            name="roleIds"
            emptyDefault={true}
            addActionKey="addRole"
            removeActionKey="removeRole"
          />
          <br />
          <FormSubmitComponent
            saveLabel={i18n.t(`actions.${formAction}`)}
            cancelLabel="Cancel"
            cancel={this.props.cancel}
          />
        </FormComponent>
      </div>
    );
  }
}
