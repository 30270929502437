import { adminActionTypes } from "src/action_types/section_action_types";

export function selectedRiskAlertRulesTablePageNumberReducer(
  state = 0,
  action
) {
  switch (action.type) {
    case adminActionTypes.selectRiskAlertRulesTablePageNumber:
      return action.payload;
    case adminActionTypes.selectRulesFacility:
      return 0;
    case adminActionTypes.selectRulesOrganization:
      return 0;
    default:
      return state;
  }
}
