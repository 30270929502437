import React from "react";

// TODO: This is a temp SVG. need to replace with official SVG.
export class AlertsIconComponent extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <path d="M24 48C27.1058 48 29.647 45.84 29.647 43.2H18.3529C18.3529 45.84 20.8941 48 24 48V48ZM42.3529 33.6V20.4C42.3529 12.96 36.4235 6.96 28.2353 5.28V3.6C28.2353 1.68 26.2588 0 24 0C21.7412 0 19.7647 1.68 19.7647 3.6V5.28C11.5765 6.96 5.64706 12.96 5.64706 20.4V33.6L0 38.4V40.8H48V38.4L42.3529 33.6V33.6Z" />
      </svg>
    );
  }
}
