import { createSelector } from "reselect";
import { assessmentsResourceSelectors } from "../assessments_resource_selectors";

/**
 * Create a selector that gets the assessment for an assessmentAnswer
 * @param {Object} assessmentAnswer
 * @returns {Function} selector
 */
export function createAssessmentForAssessmentAnswerSelector(assessmentAnswer) {
  return createSelector(
    assessmentsResourceSelectors.dataSelector,
    assessments => {
      return assessments[assessmentAnswer.attributes.assessmentId];
    }
  );
}
