import Moment from "moment";

/**
 * Return the footer object for the pdf
 * @return {object} footer pdf object
 */
export function getFooter(branding) {
  const currentYear = new Moment().format("YYYY");
  const currentDate = new Moment().format("MMM D, YYYY");

  return function(currentPage, pageCount) {
    let credits =
      branding === "pcc" || branding === "pcc_wound"
        ? "Powered by Swift Medical Inc"
        : `\u00A9 Swift Medical, ${currentYear}`;
    return {
      columns: [
        {
          text: credits,
          alignment: "left"
        },
        {
          text: "Confidential Information",
          alignment: "center"
        },
        {
          text: `Date Printed: ${currentDate}`,
          alignment: "right"
        },
        {
          text: `${currentPage.toString()}/${pageCount}`,
          alignment: "right",
          width: "10%"
        }
      ],
      margin: [10, 20, 10, 0]
    };
  };
}
