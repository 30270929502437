import merge from "lodash.merge";
import { en } from "../en";

import { data } from "./data";
import { text } from "./text";

export const en_ca = merge({}, en, {
  translation: {
    data,
    text
  }
});
