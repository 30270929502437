import { createSelector } from "reselect";
import { routeQueryParamsUserIdSelector } from "src/selectors/router_selectors";
import { userIdSelector } from "src/selectors/session_selectors";

/**
 * create a selector to get the api query for the first page for revision comparison
 * @param {Object} props
 * @param {string} props.revision
 * @returns {function} selector
 */
export function createCurrentUserQuerySelector() {
  return createSelector(
    routeQueryParamsUserIdSelector,
    userIdSelector,
    (routeQueryParamsUserId, sessionUserId) => {
      let userId;
      if (!routeQueryParamsUserId && !sessionUserId) return "";

      if (routeQueryParamsUserId) {
        userId = routeQueryParamsUserId;
      } else if (sessionUserId) {
        userId = sessionUserId;
      }

      return `${userId}?include=policies,roles`;
    }
  );
}
