import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

// Constants
import { loadStates } from "src/constants/load_states";

// Containers
import { RevisionsListItemContainer } from "src/containers/revisions_list_item_container";

// Components
import { LoadingComponent } from "src/components/loading_component";
import { Button } from "src/ui-kit";

// Styles
import styles from "./style.module.less";

//Analytics
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";

/**
 * Render a list of Revision Details Components
 */
export class RevisionsListComponent extends React.PureComponent {
  static propTypes = {
    revisions: PropTypes.array.isRequired,
    revisionsMeta: PropTypes.object,
    revisionsLoadState: PropTypes.string,
    seriesLoadState: PropTypes.string,
    loadMoreRevisions: PropTypes.func.isRequired
  };

  render() {
    const {
      revisions,
      revisionsMeta,
      revisionsLoadState,
      seriesLoadState,
      loadMoreRevisions
    } = this.props;

    if (
      !revisionsLoadState ||
      (revisionsLoadState == loadStates.loading && !revisions.length) ||
      seriesLoadState == loadStates.loading
    ) {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_PATIENTS_REVISIONS_LIST">
          <LoadingComponent height="50px" />
        </StartRenderTimeTrackComponent>
      );
    } else if (
      revisionsLoadState == loadStates.error ||
      seriesLoadState == loadStates.error
    ) {
      // TODO load error view
      return (
        <FinishRenderTimeTrackComponent
          eventName="RENDER_PATIENTS_REVISIONS_LIST"
          error={true}
        >
          <LoadingComponent height="50px" />
        </FinishRenderTimeTrackComponent>
      );
    }

    const revisionList = revisions.map(r => {
      return <RevisionsListItemContainer key={r.id} revision={r} />;
    });

    const recordCount = get(
      { revisionsMeta },
      "revisionsMeta.meta.recordCount",
      0
    );

    const showLoadMore =
      recordCount > revisions.length &&
      revisionsLoadState != loadStates.loadingMore;

    return (
      <div>
        {revisions.length > 0 && (
          <FinishRenderTimeTrackComponent eventName="RENDER_PATIENTS_REVISIONS_LIST">
            <ul className={styles["no-style-list"]}>{revisionList}</ul>
          </FinishRenderTimeTrackComponent>
        )}
        {showLoadMore && (
          <div className={styles["load-more-container"]}>
            <Button
              className={`dashboard-btn ${styles["load-more-button"]}`}
              onClick={loadMoreRevisions}
            >
              Load More...
            </Button>
          </div>
        )}
        {revisionsLoadState == loadStates.loadingMore && (
          <LoadingComponent height="50px" />
        )}
      </div>
    );
  }
}
