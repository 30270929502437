/**
 * Convert revision percentage measurement to a display value
 * @param {number} value
 * @returns {string} the display value
 */
export function displayValueForPercentage(value) {
  const sign = value && value > 0 ? "+" : "";

  return value || value == 0 ? `${sign}${value} %` : "--";
}
