// @flow
import { Api } from "src/utils/serviceUtils";
import { nortonScores } from "src/actions/propertyActions/risks/scores";

class RiskServices extends Api {
  loadNortonPatients = (
    riskLevel: string,
    locationIds: string,
    startTime: string,
    endTime: string,
    nextCursor: any
  ) => {
    let nextCursorObject = {};
    if (nextCursor) {
      nextCursorObject.nextCursor = nextCursor;
    }
    const query = {
      locationIds,
      startTime,
      endTime,
      ...nortonScores[riskLevel],
      ...nextCursorObject
    };
    return this.get("norton_plus", { query });
  };
  loadNortonAggregates = (locationIds, startTime, endTime) => {
    return this.get("norton_plus/aggregates", {
      query: {
        locationIds,
        startTime,
        endTime
      }
    });
  };
  loadNortonCharts = (locationIds, startTime, endTime) => {
    return this.get("norton_plus/clinical_chart", {
      query: {
        locationIds,
        startTime,
        endTime,
        timeStep: "week"
      }
    });
  };
  loadNortonAdminCharts = (locationIds, startTime, endTime) => {
    return this.get("norton_plus/administrative_chart", {
      query: {
        locationIds,
        startTime,
        endTime,
        timeStep: "day"
      }
    });
  };
}

export default new RiskServices("risk_dashboard", "/api/v2");
