// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";

type $props = {
  striped?: boolean,
  className?: string,
  children?: any
};

export default class Table extends PureComponent {
  props: $props;
  render() {
    const { className, striped, children } = this.props;
    const klass = classnames(
      "table",
      {
        ["table-striped"]: striped
      },
      className
    );
    return <table className={klass}>{children}</table>;
  }
}
