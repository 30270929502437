import { createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { unitsResourceSelectors } from "src/selectors/data_selectors";
import { sortByName } from "src/utils/sort_by_name";
import { createUserFormFacilityOptionsSelector } from "src/selectors/section_selectors/admin";
import pickby from "lodash.pickby";

export function createUserFormFacilityUnitOptionsSelector(formName) {
  return createSelector(
    getFormValues(formName),
    unitsResourceSelectors.dataSelector,
    createUserFormFacilityOptionsSelector(formName),
    (form, units, facilities) => {
      let filteredUnits;

      if (form && form["facility"]) {
        filteredUnits = Object.values(units).filter(function(itm) {
          return (
            Object.keys(pickby(form["facility"])).indexOf(
              itm.attributes.facilityId
            ) > -1
          );
        });
      } else {
        // this case will selects the units for first facilty selected
        facilities.length
          ? (filteredUnits = Object.values(units).filter(
              o => o.attributes.facilityId == Object.values(facilities)[0].id
            ))
          : [];
      }

      return filteredUnits ? sortByName(filteredUnits) : [];
    }
  );
}
