// Load states
import { loadStates } from "../../load_states";

// Action Types
import { createActionTypes } from "../../create_action_types";

// Context
import { createContext } from "../../create_context";

/**
 * Create a reducer for the data piece of this resource
 * @param {string} name - the resource name
 */
export function createLoadStateReducer(name) {
  const actionTypes = createActionTypes(name);

  return (state = {}, action) => {
    let loadState, method, queryObject, page, context;

    switch (action.type) {
      case actionTypes.populate:
        method = action.payload.method;
        queryObject = action.payload.queryObject;
        page = action.payload.page;
        context = createContext(method, queryObject, page);
        return {
          ...state,
          [context]: loadStates.loaded
        };

      case actionTypes.setLoadState:
        loadState = action.payload.loadState;
        method = action.payload.method;
        queryObject = action.payload.queryObject;
        page = action.payload.page;
        context = createContext(method, queryObject, page);
        return {
          ...state,
          [context]: loadState
        };

      case actionTypes.invalidate:
        method = action.payload.method;
        queryObject = action.payload.queryObject;
        page = action.payload.page;
        context = createContext(method, queryObject, page);
        return {
          ...state,
          [context]: loadStates.invalidated
        };

      case actionTypes.invalidateAll:
        return Object.keys(state).reduce((agg, key) => {
          agg[key] = loadStates.invalidated;
          return agg;
        }, {});

      default:
        return state;
    }
  };
}
