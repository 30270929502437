import { createSelector } from "reselect";
import moment from "moment";
import { dateSelectionSelector } from "../date_selection_selector";

export const timeStepFilterSelector = createSelector(
  dateSelectionSelector,
  dateSelector => {
    const startDate = moment(dateSelector.startDate);
    const endDate = moment(dateSelector.endDate);
    let monthDiffrence = endDate.diff(startDate, "months", true);
    if (monthDiffrence > 3) return "month";
    else if (monthDiffrence > 1 && monthDiffrence <= 3) return "week";
    else if (monthDiffrence <= 1) return "day";
  }
);
