import { createSelector } from "reselect";
import { woundSeriesResourceSelector } from "../wound_series_resource_selector";

export const createArrayOfSeriesIdSelector = createSelector(
  woundSeriesResourceSelector.dataSelector,
  woundSeries => {
    const woundSeriesArray = Object.values(woundSeries);
    return woundSeriesArray.map(woundSeriesObj => woundSeriesObj.id);
  }
);
