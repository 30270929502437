import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import flowRight from "lodash.flowright";

import { RoleFormComponent } from "src/components/forms/role_form_component";

import {
  createCustomRoleAction,
  updateCustomRoleAction
} from "src/actions/data_actions/custom_roles";

//Selectors
import {
  permissionTypesResourceSelectors,
  rolesResourceSelectors
} from "src/selectors/data_selectors";

import {
  createRoleFormFacilityOptionsSelector,
  organizationsForSelectedPartnerIdSelector,
  selectFacilitiesByAllOrganizationsSelector
} from "src/selectors/section_selectors/admin";

import { reduxFormNames } from "src/constants";

function mapStateToProps(state) {
  return {
    formName: reduxFormNames.roleEditForm,
    permissionTypes: permissionTypesResourceSelectors.dataSelector(state),
    organizations: organizationsForSelectedPartnerIdSelector(state),
    facilities: createRoleFormFacilityOptionsSelector(
      reduxFormNames.roleEditForm
    )(state),
    formValues: getFormValues(reduxFormNames.roleEditForm)(state),
    createLoadState: rolesResourceSelectors.postLoadStateSelector(state),
    allFacilities: selectFacilitiesByAllOrganizationsSelector(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sendCreateRoleAction(payload) {
      return dispatch(createCustomRoleAction(payload));
    },
    sendUpdateRoleAction(payload) {
      return dispatch(updateCustomRoleAction(payload));
    },
    resetForm() {
      return dispatch(reset(reduxFormNames.roleEditForm));
    },
    changeField(fieldName, fieldValue) {
      dispatch(change(reduxFormNames.roleEditForm, fieldName, fieldValue));
    },
    clearFields(...fieldNames) {
      dispatch(
        clearFields(reduxFormNames.roleEditForm, false, false, ...fieldNames)
      );
    }
  };
}
export const EditRoleFormContainer = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: reduxFormNames.roleEditForm
  })
)(RoleFormComponent);
