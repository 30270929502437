import { createSelector } from "reselect";
import { exportsSelector } from "../../exports_selector";

/**
 * Selector for the surveyWoundDetails section of the export state tree
 */
export const surveyWoundDetailsExportSelector = createSelector(
  exportsSelector,
  exports => exports.surveyWoundDetailsExport
);
