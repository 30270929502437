import get from "lodash.get";

import { styles } from "../../pdf_styles";
import { margins } from "../../margins";

// Translations
import i18n from "src/translations";

/**
 * Get table for pdf
 * @param {Object} tallies
 * @return {Object} returns null if table is empty
 */
export function getLocationTable(tallies) {
  let body = [];
  const talliesData = Object.values(tallies);

  // Left, top, right, bottom
  // page margins built into the table (no margin applied to the page)
  const cellMargin = [4, 4, 4, 4];
  const leftCellMargin = [margins.defaultPageMargin, 4, 4, 4];
  const rightCellMargin = [4, 4, margins.defaultPageMargin, 4];

  for (let i = 0; i < talliesData.length; i++) {
    let name;
    if (talliesData[i].attributes.type == "total") {
      name = "Total";
    } else {
      name = get(talliesData[i], "attributes.location.name", "") || "";
    }

    const cellValues = (textValue, alignment, margin) => {
      return {
        text: textValue,
        style: styles.tallyTableContentCell,
        alignment: alignment,
        margin: margin || cellMargin
      };
    };
    body.push([
      cellValues(name, "left", leftCellMargin),
      cellValues(talliesData[i].attributes.woundResidentCount, "center"),
      cellValues(talliesData[i].attributes.newPoaCount, "center"),
      cellValues(talliesData[i].attributes.newIhaCount, "center"),
      cellValues(talliesData[i].attributes.newIhawCount, "center"),
      cellValues(talliesData[i].attributes.newPihawCount, "center"),
      cellValues(talliesData[i].attributes.newTotal, "center"),
      cellValues(talliesData[i].attributes.existingPoaCount, "center"),
      cellValues(talliesData[i].attributes.existingIhaCount, "center"),
      cellValues(talliesData[i].attributes.existingIhawCount, "center"),
      cellValues(talliesData[i].attributes.existingPihawCount, "center"),
      cellValues(talliesData[i].attributes.existingTotal, "center"),
      cellValues(
        talliesData[i].attributes.newAcquiredUnknownCount +
          talliesData[i].attributes.existingAcquiredUnknownCount,
        "center"
      ),
      cellValues(talliesData[i].attributes.totalExcludingResolved, "center"),
      cellValues(
        talliesData[i].attributes.totalResolved,
        "center",
        rightCellMargin
      )
    ]);
  }

  const headerCells = [
    {
      text: "Location",
      style: styles.tallyTableHeaderCell,
      margin: leftCellMargin
    },
    {
      text: "Wound\nResidents",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `New\n${i18n.t("data.acquired.default.POA")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `New\n${i18n.t("data.acquired.default.IHA")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `New\n${i18n.t("data.acquired.default.IHAw")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `New\n${i18n.t("data.acquired.default.PIHAw")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Total\nNew",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `Existing\n${i18n.t("data.acquired.default.POA")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `Existing\n${i18n.t("data.acquired.default.IHA")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `Existing\n${i18n.t("data.acquired.default.IHAw")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `Existing\n${i18n.t("data.acquired.default.PIHAw")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Total\nExisting",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Acquired\nNot Set",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Total Active\nWounds",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Total\nResolved",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    }
  ];

  // don't show empty table
  if (!body.length) return null;

  // Construct table
  return {
    table: {
      headerRows: 1,
      // Total width should add up to page size (792)
      // Note - this ignores table border width (negligible)
      widths: [84, 52, 49, 49, 49, 49, 49, 49, 49, 49, 49, 49, 51, 49, 66],
      body: [headerCells, ...body]
    },
    layout: styles.tallyTable
  };
}
