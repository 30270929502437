import { connect } from "react-redux";

import {
  adminRulesSelectedOrganizationIdSelector,
  adminRulesSelectedFacilityIdSelector
} from "src/selectors/section_selectors/admin";

import {
  organizationsForSelectedPartnerIdSelector,
  facilitiesForSelectedOrganizationSelector
} from "src/selectors/section_selectors/admin";

import {
  selectOrganizationIdAction,
  selectFacilityIdAction
} from "src/actions/section_actions/admin";

import { AdminAlertRulesFiltersComponent } from "src/components/admin_alert_rules_filters_component";

function mapStateToProps(state) {
  return {
    facilities: facilitiesForSelectedOrganizationSelector(state),
    organizations: organizationsForSelectedPartnerIdSelector(state),
    selectedOrganizationId: adminRulesSelectedOrganizationIdSelector(state),
    selectedFacilityId: adminRulesSelectedFacilityIdSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSelectOrganizationAction: id => {
      dispatch(selectOrganizationIdAction(id));
    },
    sendSelectFacilityAction: id => {
      dispatch(selectFacilityIdAction(id));
    }
  };
}

export const AdminAlertRulesFiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAlertRulesFiltersComponent);
