import { createSelector } from "reselect";
import { partnersDataSelector } from "../partners_data_selector";
import { sortByName } from "src/utils/sort_by_name";

export const partnersArraySortSelector = createSelector(
  partnersDataSelector,
  partners => {
    let partnersArray = partners ? sortByName(Object.values(partners)) : [];
    return partnersArray;
  }
);
