import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { propsAreDifferent } from "src/utils/props_are_different";
// Selectors
import {
  selectedWoundTypesSelector,
  selectedLocationIdsSelector,
  dateSelectionSelector,
  acquiredFilterSelector,
  timeStepFilterSelector
} from "src/selectors/section_selectors/population";

// Actions
import { loadPopulationIncidencePrevalenceAction } from "src/actions/data_actions";

/*
 * HOC that is responsible for loading population chart data
 * @param {Class} WrappedComponent the component dependent on this data
 * @return {Class} Component wrapped by the loader component
 */
export function withPopulationChartDataLoader(WrappedComponent) {
  class PopulationChartDataLoader extends React.Component {
    static propTypes = {
      selectedWoundTypes: PropTypes.object.isRequired,
      selectedLocationIds: PropTypes.array.isRequired,
      sendLoadChartDataAction: PropTypes.func.isRequired,
      dateSelection: PropTypes.object.isRequired
    };

    componentWillMount() {
      // TODO: Keep track of location loading state and use that
      if (this.props.selectedLocationIds.length) {
        this.loadChartData(this.props);
      }
    }

    componentWillReceiveProps(nextProps) {
      if (
        propsAreDifferent(
          this.props,
          nextProps,
          "selectedLocationIds",
          "selectedWoundTypes.woundTypes",
          "dateSelection.startDate",
          "dateSelection.endDate",
          "acquiredFilter",
          "selectedWoundTypes.subtypes"
        )
      ) {
        this.loadChartData(nextProps);
      }
    }

    loadChartData(props) {
      const { sendLoadChartDataAction } = this.props;

      const queryParams = constructQueryParams(props);

      // Load the chart data
      sendLoadChartDataAction(queryParams);
    }

    render() {
      return <WrappedComponent />;
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(PopulationChartDataLoader);
}

/**
 * Map the props needed for loading data
 */
function mapStateToProps(state) {
  return {
    selectedWoundTypes: selectedWoundTypesSelector(state),
    selectedLocationIds: selectedLocationIdsSelector(state),
    dateSelection: dateSelectionSelector(state),
    acquiredFilter: acquiredFilterSelector(state),
    timeStep: timeStepFilterSelector(state)
  };
}

/**
 * Map dispatch to a props for loading data
 */
function mapDispatchToProps(dispatch) {
  return {
    sendLoadChartDataAction(queryParams) {
      dispatch(loadPopulationIncidencePrevalenceAction(queryParams));
    }
  };
}

/**
 * Function that constructs the query string for loading data using the required props
 * @param {Object} props the loader components props
 * @return {Object} the query params
 */
function constructQueryParams(props) {
  const {
    selectedLocationIds,
    selectedWoundTypes,
    dateSelection,
    acquiredFilter,
    timeStep
  } = props;

  let acquired;
  if (acquiredFilter) acquired = acquiredFilter == "externally" ? "POA" : "IHA";
  // Build the query params
  return {
    timeStep: timeStep,
    woundTypes: selectedWoundTypes.woundTypes,
    locationIds: selectedLocationIds,
    startDate: dateSelection.startDate.toISOString(),
    endDate: dateSelection.endDate.toISOString(),
    acquired: acquired,
    woundSubtypes: selectedWoundTypes.subtypes
  };
}
