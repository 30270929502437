import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { track } from "src/analytics";
import Select from "../../Select";
import "./style.less";

export default class DatePickerRange extends PureComponent {
  handleAnalytics = (name, value) => {
    track(this.props.analytics || "DATE_RANGE_PICKER", {
      name,
      value
    });
  };
  handleDateRangeChangeUnit = unitRange => {
    this.handleAnalytics("changeUnit", unitRange);
    switch (unitRange) {
      // Month presets
      case "monthToDate":
        this.props.update({
          startDate: Moment().startOf("month"),
          endDate: Moment().endOf("day"),
          unitRange
        });
        break;
      case "pastMonth":
        this.props.update({
          startDate: Moment()
            .subtract(1, "months")
            .startOf("month"),
          endDate: Moment()
            .subtract(1, "months")
            .endOf("month"),
          unitRange
        });
        break;
      case "past90Days":
        // NOTE: This is inclusive of the current day
        this.props.update({
          startDate: Moment()
            .subtract(89, "days")
            .startOf("day"),
          endDate: Moment().endOf("day"),
          unitRange
        });
        break;

      // Week presets
      case "weekToDate":
        // NOTE: Start of week considered to be Mon at 12am
        this.props.update({
          startDate: Moment().startOf("isoWeek"),
          endDate: Moment().endOf("day"),
          unitRange
        });
        break;
      case "pastWeek":
        // NOTE: Start of week considered to be Mon at 12am
        this.props.update({
          startDate: Moment()
            .startOf("isoWeek")
            .subtract(1, "week"),
          endDate: Moment()
            .endOf("isoWeek")
            .subtract(1, "week"),
          unitRange
        });
        break;
      case "past7Days":
        // NOTE: This is inclusive of the current day
        this.props.update({
          startDate: Moment()
            .subtract(6, "days")
            .startOf("day"),
          endDate: Moment().endOf("day"),
          unitRange
        });
        break;

      case "pastQuarter":
        this.props.update({
          startDate: Moment()
            .subtract(1, "quarter")
            .startOf("quarter"),
          endDate: Moment()
            .subtract(1, "quarter")
            .endOf("quarter"),
          unitRange
        });
        break;
      case "quarterToDate":
        this.props.update({
          startDate: Moment().startOf("quarter"),
          endDate: Moment().endOf("day"),
          unitRange
        });
        break;

      default:
        this.props.update({
          unitRange
        });
    }
  };

  calculateEndDate = () => {
    return Moment(this.props.startDate).add();
  };

  handleStartDateRangeChange = startDate => {
    if (startDate) {
      this.handleAnalytics("changeStartDate", startDate.toISOString());
      this.props.update({
        startDate,
        unitRange: "custom"
      });
    }
  };

  handleEndDateRangeChange = endDate => {
    if (endDate) {
      this.handleAnalytics("changeEndDate", endDate.toISOString());
      this.props.update({
        endDate: endDate.endOf("day"),
        unitRange: "custom"
      });
    }
  };
  isBeforeEndDate = date => {
    const { allowSameDayRange } = this.props;
    if (allowSameDayRange) {
      return new Moment(date).isSameOrBefore(this.props.endDate);
    } else {
      return new Moment(date).isBefore(this.props.endDate);
    }
  };

  isAfterStartDate = date => {
    const { allowSameDayRange } = this.props;
    if (allowSameDayRange) {
      return new Moment(date).isSameOrAfter(this.props.startDate);
    } else {
      return new Moment(date).isAfter(this.props.startDate);
    }
  };
  render() {
    const {
      startDate,
      endDate,
      format = "MMMM D, YYYY",
      unitRange
    } = this.props;

    return (
      <span>
        <span className="date-picker-range-first-element">
          <Select
            onChange={this.handleDateRangeChangeUnit}
            value={unitRange}
            options={[
              { value: "monthToDate", label: "Month to Date" },
              { value: "pastMonth", label: "Past Month" },
              { value: "past90Days", label: "Past 90 Days" },

              { value: "weekToDate", label: "Week to Date" },
              { value: "pastWeek", label: "Past Week" },
              { value: "past7Days", label: "Past 7 Days" },

              { value: "pastQuarter", label: "Past Quarter" },
              { value: "quarterToDate", label: "Quarter to Date" },

              { value: "custom", label: "Custom" }
            ]}
          />
        </span>

        <span className="date-picker-range-element">
          <DatePicker
            filterDate={this.isBeforeEndDate}
            dateFormat={format}
            selected={startDate}
            onChange={this.handleStartDateRangeChange}
            className="form-control"
          />
        </span>

        <span className="date-picker-range-element">to</span>

        <span className="date-picker-range-last-element">
          <DatePicker
            filterDate={this.isAfterStartDate}
            dateFormat={format}
            selected={endDate}
            onChange={this.handleEndDateRangeChange}
            className="form-control"
          />
        </span>
      </span>
    );
  }
}
