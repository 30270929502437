import * as React from "react";
import PropTypes from "prop-types";

/**
 * Wrapper around flexbox css api
 * defaults:
 * flex-grow - 0
 * flex-shrink - 1
 * align-self - auto
 */
export class FlexItem extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    grow: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    shrink: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    basis: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  getGrow() {
    const { grow } = this.props;
    if (typeof grow === "number") {
      return grow;
    } else if (grow) {
      return 1;
    }

    return 0;
  }

  getShrink() {
    const { shrink, basis } = this.props;
    if (typeof shrink === "number") {
      return shrink;
    } else if (shrink) {
      return 1;
    } else if (shrink === false) {
      return 0;
    }

    if (basis && basis !== "auto") {
      return 0;
    }

    return 1; // default
  }

  getBasis() {
    const { basis } = this.props;
    if (basis) {
      const suffix =
        typeof basis === "number" || String(parseInt(basis, 10)) === basis
          ? "px"
          : "";
      return basis + suffix;
    }

    return "auto"; // default
  }

  render() {
    const styles = {
      flex: `${this.getGrow()} ${this.getShrink()} ${this.getBasis()}`
    };

    return (
      <div className={this.props.className} style={styles}>
        {this.props.children}
      </div>
    );
  }
}
