import { connect } from "react-redux";

// Constants
import { sections } from "src/constants";

// Components
import { DateRangeFilterComponent } from "src/components/filters";

// Selectors
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";

// Actions
import { createSectionActions } from "src/ui_processors/global/action_creators";

// State
function mapStateToProps(state) {
  return {
    analytics: { type: "WOUND_EVALUATIONS_EXPORTS_DATE_RANGE" },
    startDate: createSectionSelectors(
      sections.exports
    ).selectedStartDateFilterSelector(state),
    endDate: createSectionSelectors(
      sections.exports
    ).selectedEndDateFilterSelector(state)
  };
}

// Dispatch Actions
function mapDispatchToProps(dispatch) {
  return {
    setDate: dateFilterObject => {
      dispatch(
        createSectionActions(sections.exports).selectDate(dateFilterObject)
      );
    }
  };
}

export const WoundsEvaluationsDateRangeFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateRangeFilterComponent);
