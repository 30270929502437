import { createSelector } from "reselect";
import { configSelector } from "../config_selector";

/**
 * Memoized selector for the partner name (part of the config state tree)
 */
export const modeSelector = createSelector(
  configSelector,
  config => {
    return config.mode;
  }
);
