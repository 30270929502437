import axios from "axios";
import get from "lodash.get";
import uuid from "uuid/v1";
import qs from "qs";
import { push } from "react-router-redux";
import { configureApi } from "src/api/config";
import { sessionActionTypes } from "src/action_types";
import { expire, set } from "src/utils/clientStorageUtils";
import { camelCaseObjects } from "src/utils/camel_case_objects";
import {
  track,
  registerParamsForAllEvents,
  startTimeTrack,
  finishTimeTrack
} from "src/analytics";
import {
  expireCookieValueForKey,
  setCookieValueForKey
} from "src/client_storage";
import { locationReload } from "src/utils/windowUtils";
import * as flashMessages from "src/actions/flashMessageActions";
import { setApiAuthorization } from "src/api/config";
import apiFetch from "app/util/apiFetch";
import { resetMixpanel } from "../../analytics";
import { updateLaunchContext } from "src/actions/config_actions";
import { launchIntents } from "src/constants";
import { getQueryParams } from "src/utils/queryUtils";
import {
  responseErrorInterceptor,
  responseInterceptor
} from "src/api/interceptors/index.js";

/**
 * Action creator to set the access token in the store
 * @param {string} token the access token
 */
export function setAccessToken(token) {
  // Configure api with token for authorization
  setApiAuthorization(token);
  setCookieValueForKey("accessToken", token);
  axios.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor
  );

  return {
    type: sessionActionTypes.setAccessToken,
    payload: token
  };
}

export function loggedIn(loginPayload) {
  setApiAuthorization(loginPayload.accessToken);
  setCookieValueForKey("accessToken", loginPayload.accessToken);
  setCookieValueForKey("apiHost", loginPayload.apiHost);
  axios.defaults.baseURL = loginPayload.apiHost;
  axios.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor
  );

  return {
    type: sessionActionTypes.loggedIn,
    payload: loginPayload
  };
}

/**
 * Action creator to clear the access token
 */
export function clearAccessToken() {
  axios.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor
  );

  return {
    type: sessionActionTypes.clearAccessToken
  };
}

export function clearApiHost() {
  expireCookieValueForKey("apiHost");
  axios.defaults.baseURL = "";
  axios.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor
  );

  return {
    type: sessionActionTypes.clearApiHost
  };
}

export function invalidateJwt() {
  return apiFetch(`/debug/dashboard/invalidate_jwt`, {
    method: "GET"
  });
}

export function launchFailed(errorMessage) {
  return {
    type: sessionActionTypes.launchFailed,
    payload: errorMessage
  };
}

/**
 * Sign out
 * legacy - this should be replaced by a single action ex. SESSION_SIGN_OUT that
 * any relevant reducers are aware of to clear their own state
 */
export const signOut = function signOut(displayFlashMessages = true) {
  return async dispatch => {
    try {
      await dispatch(invalidateJwt());
    } catch (e) {}

    dispatch(clearAccessToken());
    resetMixpanel();
    dispatch(clearApiHost());
    dispatch(clearSessionId());

    dispatch({
      type: "CURRENT_USER_UNLOAD"
    });

    set("currentUser", "");
    set("currentOrganization", "");

    expireCookieValueForKey("accessToken");
    expireCookieValueForKey("apiHost");
    expire("currentUser");
    expire("currentOrganization");

    if (displayFlashMessages) {
      flashMessages.create("Successfully signed out.", "success");
    }
    dispatch(push("/sign_in"));
    locationReload();
  };
};

/**
 * Action creator to set the workflow in the store
 */
export function setAuthWorkflow(authWorkflow) {
  return {
    type: sessionActionTypes.setAuthWorkflow,
    payload: authWorkflow
  };
}

/**
 * Action creator to load the selected organizationId
 */
export function populateSelectedOrganizationId(organizationId) {
  return {
    type: sessionActionTypes.selectOrganizationId,
    payload: organizationId
  };
}

/**
 * Action creator to set the session id
 */
export function setSessionId(session_id) {
  return {
    type: sessionActionTypes.setSessionId,
    payload: session_id
  };
}

/**
 * Action creator to clear the session id
 */
export function clearSessionId() {
  return {
    type: sessionActionTypes.clearSessionId
  };
}

export function pingApiAction(lastActivityTimestamp) {
  let qs = `?last_activity_timestamp=${lastActivityTimestamp}`;
  return apiFetch(`/debug/dashboard/ping${qs}`, {
    method: "GET"
  });
}

export function setUserAuthConfig(authConfig) {
  return {
    type: sessionActionTypes.setUserAuthConfig,
    payload: authConfig
  };
}

export const smartOnFhirLogin = () => async dispatch => {
  startTimeTrack("SMART_FHIR_LOGIN_PROCESS");
  const queryParams = getQueryParams();
  const requestQueryParams = {
    iss: queryParams.iss,
    launchToken: queryParams.launch
  };
  const smartOnFhirUrl = `${window.AUTH_HOST ||
    process.env.REACT_APP_AUTH_HOST}/oauth/launch-token-exchange?${qs.stringify(
    requestQueryParams
  )}`;
  try {
    startTimeTrack("SMART_FHIR_LOGIN_REQUEST");
    const response = await axios.get(smartOnFhirUrl);
    finishTimeTrack("SMART_FHIR_LOGIN_REQUEST");
    const formattedResponse = camelCaseObjects(response.data);
    const id = get(formattedResponse, "id", "");
    const apiHost = "https://" + get(formattedResponse, "apiHost", "");
    const sessionId = uuid();
    const accessToken = get(formattedResponse, "accessToken", null);

    registerParamsForAllEvents({ session_id: sessionId });
    track("NEW_DASHBOARD_SESSION");

    dispatch(
      updateLaunchContext({
        intent: queryParams.intent,
        patient: queryParams.patient
      })
    );

    dispatch(setAuthWorkflow("embedded"));
    dispatch(
      loggedIn({
        apiHost: apiHost,
        accessToken: accessToken,
        sessionId: sessionId
      })
    );

    configureApi(accessToken, id);

    if (queryParams.intent != launchIntents.chart) {
      dispatch(push("/compliance"));
    }
    finishTimeTrack("SMART_FHIR_LOGIN_PROCESS", { error: false });
  } catch (error) {
    console.error("smartOnFhirLogin", error);
    dispatch(launchFailed(`Authentication failed: ${error.message}`));
    finishTimeTrack("SMART_FHIR_LOGIN_PROCESS", {
      error: true,
      errorCode: error.response ? error.response.status : undefined
    });
  }
};
