import { createSelector } from "reselect";
import { csvExportsResourceSelectors } from "src/selectors/data_selectors";
import get from "lodash.get";

/**
 * create a selector to get the download link query for the csv_export
 * @param {Object} props
 * @param {string} props.csvExportId
 * @returns {function} selector
 */
export function createCsvExportForIdDownloadQuerySelector({ csvExportId }) {
  return createSelector(
    csvExportsResourceSelectors.createDataForIdSelector(csvExportId),
    csvExport => {
      const csvState = get(csvExport, "attributes.state", "");
      if (csvState === "completed") {
        return `${csvExportId}/download`;
      }
    }
  );
}
