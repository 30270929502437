import { createSelector } from "reselect";

// Constants
import { apiPageLimits } from "src/constants";

// Selectors
import {
  patientSearchStringSelector,
  selectedLocationIdsSelector,
  patientsSectionSelector
} from "src/selectors/section_selectors/patients";

import SingleValueReduxUtil from "src/ui_processors/single_value";

const dischargedOptionSelectedSelectors = SingleValueReduxUtil.createSelectors(
  "dischargedOptionSelected",
  patientsSectionSelector
);

export function createFirstPagePayloadSelector() {
  return createSelector(
    patientSearchStringSelector,
    selectedLocationIdsSelector,
    dischargedOptionSelectedSelectors.fieldSelector,
    (searchString, locationIds, dischargedOptionSelected) => {
      return {
        filter: {
          name_or_mrn_or_code: searchString ? searchString : undefined,
          location_id: locationIds ? locationIds.join() : undefined,
          state: dischargedOptionSelected
            ? "admitted,new,discharged"
            : "admitted,new"
        },
        page: {
          offset: 0,
          limit: apiPageLimits.patients
        },
        include: "studies"
      };
    }
  );
}
