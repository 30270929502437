import { createSelector } from "reselect";

//Util
import { buildQueryString } from "src/utils/build_query_string";

//Selectors
import { createUsersTablePayloadSelector } from "../create_users_table_payload_selector";

/**
 * Selector to return the query string to query users for selected facilities
 */

export const createUsersTableContextSelector = () =>
  createSelector(
    createUsersTablePayloadSelector(),
    usersTablePayloadSelector => {
      return buildQueryString(usersTablePayloadSelector);
    }
  );
