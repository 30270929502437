import { createSelector } from "reselect";
import { parse } from "qs";
import jwtDecode from "jwt-decode";

const locationSelector = state => state.routing.location;
const pathnameSelector = state => state.routing.location.pathname;
const searchSelector = state => state.routing.location.search;

export const routeLocationSelector = createSelector(
  locationSelector,
  location => {
    return location;
  }
);

export const routeQuerySelector = createSelector(
  searchSelector,
  search => search
);

export const routeQueryParamsSelector = createSelector(
  searchSelector,
  search => {
    return parse(search, { ignoreQueryPrefix: true, arrayLimit: 5000 });
  }
);

export const routeQueryParamsUserIdSelector = createSelector(
  routeQueryParamsSelector,
  query => {
    let userId;
    if (query && query.access_token) {
      userId = jwtDecode(query.access_token).user_id;
    }
    return userId;
  }
);

export const routePathnameSelector = createSelector(
  pathnameSelector,
  pathname => {
    return pathname;
  }
);

// Not actually a selector - returns a selector
// legacy code used to extract the access token from the routing state
// NOTE: This really shouldn't be necessary, the token should be stored to state before
// you try to access it. Don't use this in any more places
export const getQuery = name => state => {
  return parse(state.routing.location.search, { ignoreQueryPrefix: true })[
    name
  ];
};
