// @flow
import React, { PureComponent } from "react";
import { Chart } from "@sw/ui-kit";
import DateValueModel from "@sw/models/TrendModel/DateValueModel";
type $props = {
  data: DateValueModel
};

const userCallback = function(tick) {
  if ([-3, 0, 5, 10, 15, 20].indexOf(tick) === -1) {
    return null;
  }
  return tick;
};

export default class NortonHistoryChart extends PureComponent {
  props: $props;
  render() {
    return (
      <Chart
        data={this.props.data}
        userCallback={userCallback}
        min={-8}
        max={20}
        fixedStepSize={1}
      />
    );
  }
}
