import { createSelector } from "reselect";

import get from "lodash.get";

// Constants
import { pressureWoundTypes } from "src/constants";

// Selectors
import { createSeriesForRevisionsSelector } from "src/selectors/data_selectors/series/create_series_for_revisions_selector";
import { createPatientsForRevisionsSelector } from "src/selectors/data_selectors/patients/create_patients_for_revisions_selector";
import { createLatestAssessmentAnswerForRevisionsSelector } from "src/selectors/data_selectors/assessment_answers/create_latest_assessment_answer_for_revisions_selector";
import { createDefaultFramesForRevisionsSelector } from "src/selectors/data_selectors/frames/create_default_frames_for_revisions_selector";
import { createLatestRegionsForRevisionsSelector } from "src/selectors/data_selectors/regions/create_latest_regions_for_revisions_selector";
import { createSeriesTrendsForRevisionsSelector } from "src/selectors/data_selectors/series_trends/create_series_trends_for_revisions_selector";
import { createSchemaByTabForRevisionsSelector } from "src/selectors/data_selectors/assessment_schemas/create_schema_by_tab_for_revisions_selector";
import { createLatestSignedEventForRevisionsSelector } from "src/selectors/data_selectors/events/create_latest_signed_event_for_revisions_selector";
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";
import { createPayersForRevisionsSelector } from "src/selectors/data_selectors/payers/create_payers_for_revisions_selector";
import { createLocalizationDictionaryForRevisionsSelector } from "src/selectors/section_selectors/global";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

import {
  brandingSelector,
  brandedLogoPngSelector,
  woundNumbersEnabledSelector,
  hprScoreEnabledSelector,
  bwatScoreEnabledSelector,
  encounterNamingConventionSelector,
  payerDashboardEnabledSelector,
  visitSequenceReportingEnabledSelector,
  etiologyVerifiedQuestionEnabledSelector
} from "src/selectors/ui_config_selectors/global";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

// Model
import { isAutodepth } from "src/models/regions";
import { getPatientQrCodeDataURL } from "src/models/patients";

// Utils
import {
  getImageDataUrlFromPath,
  getImageDataUrlFromLocalPath
} from "src/utils/image_data_url";
import { getChartUrlForPdf } from "src/utils/get_chart_url_for_pdf";

// PDF generation
import { createRevisionPdf } from "src/pdfs/create_revision_pdf";

/**
 * create a selector that returns a function that is used to asynchronously
 * generate a pdf file definition for use with pdfmake
 * @param {Object} props
 * @param {Object} props.revision - revisions to print
 * @returns {Function} selector
 */

export function createRevisionPdfDefinitionSelector({
  revisions,
  patientLastFacilityId,
  landscape
}) {
  return createSelector(
    createSeriesForRevisionsSelector({ revisions }),
    createPatientsForRevisionsSelector({ revisions }),
    createLatestAssessmentAnswerForRevisionsSelector({ revisions }),
    createDefaultFramesForRevisionsSelector({ revisions }),
    createLatestRegionsForRevisionsSelector({ revisions }),
    createSchemaByTabForRevisionsSelector({ revisions }),
    createSeriesTrendsForRevisionsSelector({ revisions }),
    createLatestSignedEventForRevisionsSelector({ revisions }),
    createPayersForRevisionsSelector({ revisions }),
    facilitiesResourceSelectors.createDataForIdSelector(patientLastFacilityId),
    brandingSelector,
    brandedLogoPngSelector,
    woundNumbersEnabledSelector,
    hprScoreEnabledSelector,
    bwatScoreEnabledSelector,
    encounterNamingConventionSelector,
    payerDashboardEnabledSelector,
    visitSequenceReportingEnabledSelector,
    createLocalizationDictionaryForRevisionsSelector({ revisions }),
    assessmentVersionSelector,
    etiologyVerifiedQuestionEnabledSelector,
    subtypeKeysForAllSchemasSelector,
    (
      seriesByRevision,
      patientsByRevision,
      assessmentAnswersByRevision,
      framesByRevision,
      regionsByRevision,
      schemasByRevision,
      seriesTrendsByRevision,
      latestSignedEventByRevision,
      payersByRevision,
      patientLastFacility,
      branding,
      brandedLogoPng,
      woundNumbersEnabled,
      hprScoreEnabled,
      bwatScoreEnabled,
      encounterNamingConvention,
      payerDashboardEnabled,
      visitSequenceEnabled,
      schemaLocalizationDictionaryByAssessmentIds,
      assessmentVersion,
      etiologyVerifiedQuestionEnabled,
      subtypeKeysForSchema
    ) => {
      // Get branded logo png image
      const logo = brandedLogoPng;

      let reportName =
        revisions.length > 0 && landscape
          ? "WOUND SUMMARY"
          : "WOUND EVALUATION";

      let globalContent = {
        reportName: reportName
      };

      let pdfOptions = {
        branding,
        woundNumbersEnabled,
        bwatScoreEnabled,
        encounterNamingConvention,
        payerDashboardEnabled,
        visitSequenceEnabled,
        schemaLocalizationDictionary: schemaLocalizationDictionaryByAssessmentIds,
        assessmentVersion,
        etiologyVerifiedQuestionEnabled,
        subtypeKeysForSchema
      };

      return async () => {
        //Images
        const logoImage = await getImageDataUrlFromLocalPath(logo);
        globalContent.logo = logoImage;

        let pdfContents = [];
        for (const revision of revisions) {
          const latestAssessmentAnswer =
            assessmentAnswersByRevision[revision.id];

          let pdfContent = {
            revision: revision,
            payersForRevision: payersByRevision[revision.id],
            patient: patientsByRevision[revision.id],
            patientLastFacility,
            series: seriesByRevision[revision.id],
            assessmentAnswer: latestAssessmentAnswer,
            frame: framesByRevision[revision.id],
            region: regionsByRevision[revision.id],
            isAutodepth: isAutodepth(regionsByRevision[revision.id]),
            schemas: schemasByRevision[revision.id],
            seriesTrends: seriesTrendsByRevision[revision.id],
            latestSignedEvent: latestSignedEventByRevision[revision.id]
          };

          const hasImage = get(pdfContent, "frame.attributes.hasImage", false);

          const pdfUrl = get(pdfContent, "frame.attributes.pdfUrl", "");

          let woundImageUrl = "";

          if (hasImage) {
            woundImageUrl = await getImageDataUrlFromPath(pdfUrl);
          }

          const frameContentType = get(
            pdfContent,
            "frame.attributes.contentType",
            ""
          );

          //Wound Type
          const woundType = get(
            pdfContent,
            "assessmentAnswer.attributes.answersJson.woundType",
            ""
          );

          let qrCodeUrl = await getPatientQrCodeDataURL(pdfContent.patient);

          pdfContent.images = {
            wound: woundImageUrl,
            qrCode: qrCodeUrl,
            closureChart:
              frameContentType == "declined"
                ? null
                : getChartUrlForPdf(pdfContent.seriesTrends, "closureTrend", {
                    max: 100
                  }),
            pushChart: pressureWoundTypes.includes(woundType)
              ? getChartUrlForPdf(pdfContent.seriesTrends, "pushTrend", {
                  min: 0,
                  max: 17
                })
              : null,
            hprChart: hprScoreEnabled
              ? getChartUrlForPdf(pdfContent.seriesTrends, "hprTrend", {
                  max: 30
                })
              : null,
            bwatChart: bwatScoreEnabled
              ? getChartUrlForPdf(pdfContent.seriesTrends, "bwatTrend", {
                  max: 70
                })
              : null,
            imageText:
              frameContentType === "declined"
                ? "Patient Declined Photo"
                : "No Image"
          };

          pdfContents.push(pdfContent);
        }

        return {
          pdfContents,
          pdfDefinition: await createRevisionPdf(
            pdfContents,
            globalContent,
            pdfOptions,
            landscape
          )
        };
      };
    }
  );
}
