import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import flowRight from "lodash.flowright";

// Components
import { SectionContentComponent } from "src/components/section_content_component";

// Selectors
import {
  alertsEnabledSelector,
  evaluationListEnabledSelector,
  myDashboardTabEnabledSelector,
  exportsTabEnabledSelector,
  outcomesTabEnabledSelector,
  monitoringTabEnabledSelector,
  janssenDashboardEnabledSelector,
  searchPatientDashboardEnabledSelector,
  woundNumbersEnabledSelector,
  visitSequenceReportingEnabledSelector,
  payerDashboardEnabledSelector,
  reportingPermissionEnabledSelector,
  qaNoteEnabledPolicySelector
} from "src/selectors/ui_config_selectors/global";
import { adminTabEnabledSelector } from "../../selectors/config_selectors/admin_tab_enabled_selector";
import { schemaLocalizationDictionarySelector } from "src/selectors/section_selectors/global";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";
import {
  assessmentVersionSelector,
  patientLinksEnabledSelector
} from "src/selectors/config_selectors";
import { patientsSectionSelector } from "src/selectors/section_selectors/patients";
import { inProgressEvaluationsCsvExportEnabledSelector } from "src/selectors/ui_config_selectors/compliance";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";
import {
  hasAllowEditOtherUsersWorkPermissionSelector,
  hasLockWoundRevisionsPermissionSelector
} from "src/selectors/ui_config_selectors/patients";

// Actions
import { postCsvExportAction } from "src/actions/data_actions";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const enableRevisionToBeEditedSelector = SingleValueReduxUtil.createSelectors(
  "revisionEnabledToBeEdited",
  patientsSectionSelector
);

function mapStateToProps(state) {
  return {
    csvExportEnabled: inProgressEvaluationsCsvExportEnabledSelector(state),
    myDashboardTabEnabled: myDashboardTabEnabledSelector(state),
    alertsTabEnabled: alertsEnabledSelector(state),
    evaluationListEnabled: evaluationListEnabledSelector(state),
    exportsTabEnabled: exportsTabEnabledSelector(state),
    adminTabEnabled: adminTabEnabledSelector(state),
    outcomesTabEnabled: outcomesTabEnabledSelector(state),
    monitoringTabEnabled: monitoringTabEnabledSelector(state),
    searchPatientDashboardEnabled: searchPatientDashboardEnabledSelector(state),
    janssenDashboardEnabled: janssenDashboardEnabledSelector(state),
    woundNumbersEnabled: woundNumbersEnabledSelector(state),
    schemaLocalizationDictionary: schemaLocalizationDictionarySelector(state),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state),
    visitSequenceEnabled: visitSequenceReportingEnabledSelector(state),
    payerDashboardEnabled: payerDashboardEnabledSelector(state),
    assessmentVersion: assessmentVersionSelector(state),
    revisionBeingEdited: enableRevisionToBeEditedSelector.fieldSelector(state),
    currentUser: currentUserSelector(state),
    canLockFromDashboard: hasLockWoundRevisionsPermissionSelector(state),
    canEditOtherUsersWork: hasAllowEditOtherUsersWorkPermissionSelector(state),
    isReportingEnabled: reportingPermissionEnabledSelector(state),
    qaNoteAllowed: qaNoteEnabledPolicySelector(state),
    patientLinksEnabled: patientLinksEnabledSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendPostCsvExportAction(payload) {
      dispatch(postCsvExportAction({ payload }));
    }
  };
}

export const SectionContentContainer = flowRight(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SectionContentComponent);
