import { connect } from "react-redux";
import { reset, isSubmitting, isPristine } from "redux-form";

import { ImportCsvFormComponent } from "src/components/forms/import_csv_form_component";

import { csvImportsLoadStateSelector } from "src/selectors/data_selectors";
import { selectedPartnerIdSelector } from "src/selectors/section_selectors/admin";

// Actions
import { importCsvAction } from "src/actions/data_actions/import";

function mapStateToProps(state) {
  return {
    csvImportsLoadState: csvImportsLoadStateSelector(state),
    partnerId: selectedPartnerIdSelector(state),
    submitting: isSubmitting("importCsvForm")(state),
    pristine: isPristine("importCsvForm")(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sendImportCsvAction(payload, partnerId) {
      dispatch(importCsvAction(payload, partnerId, "user"));
    },
    resetForm: () => {
      dispatch(reset("importCsvForm"));
    }
  };
}
export const ImportUserCsvFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportCsvFormComponent);
