import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Components
import { SeriesDetailsHeaderComponent } from "src/components/series_details_header_component";

// Selectors
import {
  woundNumbersEnabledSelector,
  historyCauseEnabledSelector,
  visitSequenceReportingEnabledSelector
} from "src/selectors/ui_config_selectors/global";
import {
  createSeriesForIdSelector,
  createDefaultFrameForSeriesIdSelector,
  createLatestAssessmentAnswerForSeriesIdSelector
} from "src/selectors/data_selectors";

import { assessmentVersionSelector } from "src/selectors/config_selectors";
import { createLatestRevisionForSeriesIdSelector } from "src/selectors/data_selectors/revisions/create_latest_revision_for_series_id_selector";

import { localizationDictionaryForSeriesIdSelector } from "src/selectors/section_selectors/global";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

function mapStateToProps(state, ownProps) {
  const series = createSeriesForIdSelector(ownProps.match.params.seriesId)(
    state
  );
  return {
    series,
    frame: createDefaultFrameForSeriesIdSelector(ownProps.match.params.seriesId)(
      state
    ),
    assessmentAnswer: createLatestAssessmentAnswerForSeriesIdSelector(
      ownProps.match.params.seriesId
    )(state),
    assessmentVersion: assessmentVersionSelector(state),
    woundNumbersEnabled: woundNumbersEnabledSelector(state),
    revision: createLatestRevisionForSeriesIdSelector(
      ownProps.match.params.seriesId
    )(state),
    historyCauseEnabled: historyCauseEnabledSelector(state),
    visitSequenceEnabled: visitSequenceReportingEnabledSelector(state),
    schemaLocalizationDictionary: localizationDictionaryForSeriesIdSelector(
      series.id
    )(state),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state)
  };
}

export const SeriesDetailsHeaderContainer = withRouter(
  connect(mapStateToProps)(SeriesDetailsHeaderComponent)
);
