export const policyInternalNames = {
  userAccess: "user_access",
  patientAccess: "patient_access",
  allowRevisionCreate: "allow_revision_create",
  woundAssessmentAccess: "wound_assessment_access",
  canLockFromDashboard: "can_lock_from_dashboard",
  canUnlockFromDashboard: "can_unlock_from_dashboard",
  myDashboardEnabled: "my_dashboard_enabled",
  dashboardActivityTimeoutSeconds: "dashboard_activity_timeout_seconds",
  eSignatureMode: "esignature_mode",
  canSignAssessments: "can_sign_assessments",
  canSignFromDashboard: "can_sign_from_dashboard",
  allowEditOtherUsersWork: "allow_edit_other_users_work",
  dashboardAlertsEnabled: "dashboard_alerts_enabled",
  dashboardEvaluationListEnabled: "dashboard_evaluation_list_enabled",
  assessmentSummaryOptimizationsEnabled:
    "assessment_summary_optimizations_enabled",
  canReopenFromDashboard: "can_reopen_from_dashboard",
  nonWoundSchemas: "non_wound_schemas",
  patientAppDashboardEnabled: "patient_app_dashboard_enabled",
  userPasswordMinLength: "user_password_min_length",
  payerDashboardEnabled: "payer_dashboard_enabled",
  visitSequenceReportingEnabled: "visit_sequence_reporting_enabled",
  healingIndexDashboardEnabled: "healing_index_dashboard_enabled",
  janssenDashboardEnabled: "janssen_dashboard_enabled",
  encounterNamingConvention: "encounter_naming_convention",
  qaNotesEnabled: "qa_notes_allowed",
  encounterSelectionMandatory: "encounter_selection_mandatory",
  encounterFieldsShownInSelectionUI: "encounter_fields_shown_in_selection_ui",
};
