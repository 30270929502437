import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { sections } from "src/constants";

// Components
import {
  locationsFilterConfigSelector,
  payersFilterConfigSelector
} from "src/selectors/ui_config_selectors/global";
import { SavedFilterVisualizationComponent } from "src/components/saved_filter_visualization_component";

// Selectors
import { woundTypesConfigurationFilterForSchemaPolicySelector } from "src/selectors/ui_config_selectors/global/wound_type_filter_configuration_selector";

// UI Processors
import { createSectionSelectors } from "src/ui_processors";

// HOC
import { withSchemaLocalizationDictionaryWrapper } from "src/hoc/with_schema_localization_dictionary_wrapper";

const sectionSelectors = createSectionSelectors(sections.evaluationListWound);

function mapStateToProps(state) {
  // Locations
  const locationConfig = locationsFilterConfigSelector(state);
  const locationCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    locationConfig
  )(state);

  // wound types
  const woundTypesFilterConfig = woundTypesConfigurationFilterForSchemaPolicySelector(
    state
  );
  const woundTypesFilterCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    woundTypesFilterConfig
  )(state);

  // payers
  const payersFilterConfig = payersFilterConfigSelector(state);
  const payersFilterCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    payersFilterConfig
  )(state);

  return {
    filterUiConfigs: [
      locationConfig,
      woundTypesFilterConfig,
      payersFilterConfig
    ],
    filterCheckedStates: [
      locationCheckedState,
      woundTypesFilterCheckedState,
      payersFilterCheckedState
    ]
  };
}

export const EvaluationListWoundSavedFilterVisualizationContainer = flowRight(
  withSchemaLocalizationDictionaryWrapper,
  connect(mapStateToProps)
)(SavedFilterVisualizationComponent);
