export const woundTypeKeyForSubtype = {
  skinTearCategory: "skinTear",
  burnDegree: "burn",
  bruiseType: "bruise",
  colostomyType: "colostomy",
  illeostomyType: "illeostomy",
  urostomyType: "urostomy",
  fistulaType: "fistula",
  stomaType: "stoma",						
  pressureUlcerStage: "pressureUlcer",
  pressureKennedyStage: "pressureKennedy",
  pressureDeviceRelatedStage: "pressureDeviceRelated",
  masdType: "masd",
  grade: "diabetic",
  dermConditionType: "dermCondition", 	
  bruiseType: "bruise",
  hSuppurtivaStage: "hSuppurtiva",
  skinGraftSite: "skinGraft",
  softTissueNecrosisType: "softTissueNecrosis"
};
