import { createSelector } from "reselect";
import { woundRevisionsResourceSelector } from "../wound_revisions_resource_selector";

export const createArrayOfRevisionIdsSelector = createSelector(
  woundRevisionsResourceSelector.dataSelector,
  woundRevisions => {
    const woundRevisionsArray = Object.values(woundRevisions);
    return woundRevisionsArray.map(woundRevisionsObj => woundRevisionsObj.id);
  }
);
