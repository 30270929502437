import React from "react";
export class MedlineIconComponent extends React.Component {
  render() {
    return (
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="182 150 500 500"
      >
        <g id="inner_circle">
          <path
            style={{ fill: "#F7CFD8" }}
            d="M404.25,581.12c-0.47,0-0.95-0.04-1.44-0.12l-0.25-0.04c-36.69-6.2-70.07-25.33-93.95-53.85 c-23.61-28.2-36.61-63.88-36.61-100.46c0-8.75,0.75-17.6,2.22-26.3c2.63-15.53,7.51-30.46,14.53-44.4 c2.09-4.16,7.16-5.83,11.32-3.74c4.16,2.09,5.83,7.16,3.74,11.32c-6.26,12.43-10.62,25.76-12.96,39.62 c-1.31,7.77-1.98,15.68-1.98,23.49c0,32.63,11.61,64.46,32.68,89.63c21.3,25.45,51.09,42.52,83.86,48.06l0.26,0.04 c4.59,0.79,7.67,5.15,6.89,9.74C411.85,578.22,408.28,581.12,404.25,581.12z"
          />
          <path
            style={{ fill: "#F7CFD8" }}
            d="M437.31,582.94c-4.44,0-8.15-3.46-8.41-7.95c-0.27-4.65,3.29-8.63,7.94-8.9 c31.08-1.78,61.13-14.19,84.59-34.95c23.81-21.06,39.84-49.96,45.15-81.36c1.31-7.77,1.98-15.67,1.98-23.49 c0-16.68-2.97-33.07-8.82-48.71c-1.63-4.36,0.58-9.22,4.94-10.85c4.36-1.64,9.22,0.58,10.85,4.94c6.56,17.54,9.89,35.91,9.89,54.62 c0,8.76-0.75,17.61-2.22,26.3c-5.95,35.2-23.92,67.58-50.61,91.18c-26.29,23.25-59.96,37.16-94.8,39.15 C437.64,582.93,437.47,582.94,437.31,582.94z"
          />
          <path
            style={{ fill: "#F7CFD8" }}
            d="M552.66,353.52c-2.74,0-5.44-1.34-7.06-3.8c-21.28-32.39-55.39-54.67-93.57-61.12 c-7.77-1.31-15.67-1.98-23.49-1.98c-41.69,0-80.92,18.51-107.63,50.77c-2.97,3.59-8.28,4.09-11.87,1.12 c-3.59-2.97-4.09-8.29-1.12-11.87c29.93-36.15,73.89-56.88,120.62-56.88c8.75,0,17.6,0.75,26.3,2.22 c42.79,7.24,81.01,32.2,104.86,68.49c2.56,3.89,1.48,9.12-2.42,11.68C555.85,353.08,554.25,353.52,552.66,353.52z"
          />
        </g>
        <path
          style={{ fill: "#F491A3" }}
          d="M428.34,615.27c-42.19,0-82.89-14.05-116.52-40.58c-39.59-31.22-64.66-76-70.57-126.07	c-12.22-103.37,61.95-197.41,165.32-209.63c50.08-5.92,99.46,8.02,139.05,39.24c39.59,31.22,64.66,76,70.57,126.08	c5.92,50.08-8.02,99.46-39.25,139.05c-31.22,39.59-76,64.66-126.07,70.57l0,0C443.34,614.83,435.82,615.27,428.34,615.27z M429.06,254.53c-6.81,0-13.65,0.4-20.52,1.21c-45.6,5.39-86.38,28.21-114.81,64.27c-28.43,36.06-41.13,81.03-35.74,126.63	c5.39,45.6,28.21,86.38,64.27,114.81c36.06,28.44,81.03,41.13,126.63,35.74l0,0c45.6-5.39,86.38-28.21,114.81-64.27	c28.43-36.06,41.13-81.03,35.74-126.63c-5.39-45.6-28.21-86.38-64.27-114.81C504.55,267.32,467.48,254.53,429.06,254.53z"
        />
        <g id="outer_circle">
          <path
            style={{ fill: "#F56076" }}
            d="M239.22,502.11c-9.63-24.19-14.51-49.69-14.51-75.78c0-46.19,15.16-89.83,43.83-126.18 c36.69-46.52,91.7-74.75,150.93-77.47c4.49-0.21,7.99-3.89,8.04-8.34c0.01-0.14,0-0.28,0-0.43c0-0.02,0-0.03,0-0.04 c0-0.07-0.01-0.13-0.02-0.19c-0.08-2-0.83-3.97-2.32-5.52l-22.3-23.12c-3.23-3.35-8.57-3.45-11.92-0.21 c-3.35,3.23-3.45,8.57-0.21,11.92l10.24,10.62c-57.29,7.26-109.64,36.66-145.67,82.34c-31.04,39.36-47.45,86.61-47.45,136.63 c0,28.24,5.28,55.84,15.71,82.02c1.32,3.3,4.48,5.32,7.84,5.32c1.04,0,2.09-0.19,3.12-0.6 C238.84,511.34,240.95,506.43,239.22,502.11z"
          />
          <path
            style={{ fill: "#F56076" }}
            d="M600.85,551.2c-3.67-2.87-8.97-2.22-11.84,1.45c-0.04,0.05-0.08,0.09-0.11,0.14 c-38.93,49.37-97.37,77.68-160.32,77.68c-46.19,0-89.83-15.15-126.18-43.83c-18.03-14.22-33.38-31.14-45.62-50.3 c-0.49-0.77-1.11-1.4-1.78-1.95c-0.94-0.89-2.1-1.58-3.43-1.97c-4.47-1.29-9.15,1.27-10.45,5.75l-8.96,30.85 c-1.3,4.47,1.27,9.15,5.75,10.45c0.79,0.23,1.58,0.34,2.36,0.34c3.66,0,7.02-2.4,8.09-6.08l4.1-14.12 c11.34,14.99,24.56,28.51,39.5,40.29c39.36,31.04,86.61,47.45,136.63,47.45c68.15,0,131.41-30.65,173.56-84.1l0,0l0.16-0.2 C605.17,559.36,604.52,554.06,600.85,551.2z"
          />
          <path
            style={{ fill: "#F56076" }}
            d="M656.81,514.77c-1.12-4.52-5.7-7.27-10.21-6.16l-14.29,3.54c11.33-26.95,17.29-56.19,17.29-85.55 c0-68.15-30.65-131.41-84.1-173.57c-30.53-24.08-66.9-39.71-105.18-45.21c-4.61-0.65-8.88,2.54-9.55,7.15 c-0.66,4.61,2.54,8.88,7.15,9.55c35.34,5.07,68.93,19.51,97.13,41.75c49.37,38.93,77.68,97.37,77.68,160.32 c0,32.54-7.89,64.93-22.82,93.68c-0.85,1.63-1.09,3.41-0.84,5.1c0.04,0.39,0.1,0.78,0.19,1.17c0.95,3.84,4.39,6.4,8.18,6.4 c0.67,0,1.35-0.08,2.04-0.25l31.18-7.73C655.17,523.86,657.93,519.29,656.81,514.77z"
          />
        </g>
      </svg>
    );
  }
}
