import Pusher from "pusher-js";

// Instantiate Pusher instance
// TODO: the pusher key should not be hard coded - make this an env variable
const pusher = new Pusher("29f43a96ab212a403a39", {
  encrypted: true,
  disableStats: true
});

// The pusher channels (less the resource ids)
export const resourceIdChannelNames = {
  patient: "patient--",
  studyAssessmentAnswer: "study_assessment_answer--",
  seriesAssessmentAnswer: "series_assessment_answer--",
  csv: "csv--"
};

export const channelEvents = {
  change: "change"
};

/**
 * Subscribe to a pusher channel
 * @param {Object} payload
 * @param {string} payload.channelName
 * @param {string} payload.resourceId
 * @param {Function} payload.callback
 * @param {Object} payload.callbackContext - used to give a 'this' context to the callback
 */
export function subscribeToResourceChannelEvent({
  channelName,
  resourceId,
  eventName,
  callback,
  callbackContext
}) {
  const fullChannelName = channelName + resourceId;
  let channel =
    pusher.channel(fullChannelName) || pusher.subscribe(fullChannelName);

  channel.bind(eventName, callback, callbackContext);
}

export function unsubscribeFromResourceChannelEvent({
  channelName,
  resourceId,
  eventName,
  callback
}) {
  const fullChannelName = channelName + resourceId;
  const channel = pusher.channels.find(fullChannelName);
  if (channel) {
    channel.unbind(eventName, callback);

    // TODO: conditionally unsubscribe if there are no more bindings
    // no clear way to check if there are bindings from the pusher docs.
    pusher.unsubscribe(channelName);
  }
}

export default {
  resourceIdChannelNames,
  channelEvents,
  subscribeToResourceChannelEvent,
  unsubscribeFromResourceChannelEvent
};
