import { createSelector } from "reselect";
import get from "lodash.get";

import { createQuestionHierarchyFromMultipleSchemas } from "src/utils/downloadable_schemas";

import { localizationDictionaryForAllSchemasSelector } from "src/selectors/section_selectors/global";
import { assessmentSchemasResourceSelectors } from "src/selectors/data_selectors";
import { woundSchemaPolicySelector } from "src/selectors/config_selectors";

export const woundTypesConfigurationFilterForSchemaPolicySelector = createSelector(
  assessmentSchemasResourceSelectors.dataSelector,
  woundSchemaPolicySelector,
  localizationDictionaryForAllSchemasSelector,
  (assessmentSchemas, woundSchemaPolicy, schemaLocalizationDictionary) => {
    if (
      !woundSchemaPolicy ||
      !(assessmentSchemas && Object.values(assessmentSchemas).length)
    ) {
      return {
        filterName: "woundTypes",
        filterValues: []
      };
    }

    const policyAssessmentSchema = Object.values(assessmentSchemas).find(
      schema => schema.attributes.assessmentId === woundSchemaPolicy
    );
    const mainDescriptionAssessmentId = get(
      policyAssessmentSchema,
      "attributes.schemaJson.components.description"
    );
    const mainDescriptionSchema = Object.values(assessmentSchemas).find(
      schema => schema.attributes.assessmentId === mainDescriptionAssessmentId
    );

    const allOtherDescriptionSchemas = Object.values(assessmentSchemas).filter(
      schema => {
        const schemaName = get(schema, "attributes.schemaJson.name", false);
        const assessmentId = get(schema, "attributes.assessmentId", false);
        return (
          schemaName &&
          schemaName == "description" &&
          assessmentId != mainDescriptionAssessmentId
        );
      }
    );

    const valuesFromMultipleSchemas = createQuestionHierarchyFromMultipleSchemas(
      mainDescriptionSchema,
      allOtherDescriptionSchemas
    );

    let filterValues = valuesFromMultipleSchemas;
    if (schemaLocalizationDictionary) {
      filterValues = sortHierarchyByValuesAlphabetically(
        valuesFromMultipleSchemas,
        schemaLocalizationDictionary
      );
    }

    return {
      filterName: "woundTypes",
      filterValues
    };
  }
);

const sortHierarchyByValuesAlphabetically = (
  hierarchy,
  localizationDictionary
) => {
  const sortedHierarchy = hierarchy.sort((a, b) => {
    return localizationDictionary[`woundType.${a.value}`] <
      localizationDictionary[`woundType.${b.value}`]
      ? -1
      : 1;
  });
  return sortedHierarchy; //we may want to make this recursive in the future (so that subtypes are also sorted alphabetically)
};
