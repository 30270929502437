import { createSelector } from "reselect";
import { dataSelector } from "../../data_selector";

/**
 * Selector for an array of all locations in the store
 */

export const locationsDataSelector = createSelector(
  dataSelector,
  data => {
    return data.locations;
  }
);
