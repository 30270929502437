import { createSelector } from "reselect";

// Payload generator
import { createGetPayload } from "src/api/single_revision/payload_generators";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api query for a single revision
 * @param {Object} props
 * @param {string} props.revisionId
 * @returns {function} selector
 */
export function createSingleRevisionQuerySelector({ revisionId }) {
  return createSelector(() => {
    return buildQueryString(
      createGetPayload({
        revisionId
      })
    );
  });
}
