import React from "react";
import get from "lodash.get";
import { Link } from "react-router-dom";
import { woundEvaluationHyperlinkFormatter } from "../wound_evaluation_hyperlink_formatter";
import { getWoundNumberTypeSubtypeString } from "src/utils/get_wound_number_type_subtype_string";
// Constants
import { assessmentTypes } from "src/constants";

/**
 * cell formatter to translate wound type and subtype
 * @param {object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.type the assessment type
 * @param {Object} row.attributes.answersJson the answers json
 * @param {number} rowIndex
 * @param {string} localizationDictionary
 */
export function woundInfoFormatter(cell, row, rowIndex, formatExtraData) {
  const { localizationDictionary, subtypeKeysForSchema } = formatExtraData;
  const assessmentId = get(row, "attributes.assessmentId");
  const localizationDictionaryForRow = localizationDictionary[assessmentId];

  const type = get(row, "attributes.type");
  if (type !== assessmentTypes.wound) {
    return woundEvaluationHyperlinkFormatter("N/A", row, formatExtraData);
  }

  const woundNumber = get(row, "attributes.patientWoundNumber");

  const value = getWoundNumberTypeSubtypeString({
    localizationDictionary: localizationDictionaryForRow,
    answersJson: get(row, "attributes.answersJson"),
    patientWoundNumber: woundNumber,
    subtypeKeysForSchema
  });
  if (!formatExtraData.eventHandler) {
    return <div>{value}</div>;
  }
  return (
    <div
      onClick={e =>
        formatExtraData.eventHandler(e, row, formatExtraData.columnName)
      }
    >
      <Link to={location.pathname}>{value}</Link>
    </div>
  );
}
