import { createSelector } from "reselect";
import { createContext } from "../../create_context";
import { requestMethods } from "../../request_methods";
import { wrapSelector } from "../../utils";

/**
 * Create selector creator that takes a query object and returns the current page for it
 * @param {Function} pagesRootSelector
 */
export function createCurrentPageSelectorCreator(pagesRootSelector) {
  return function createCurrentPageSelector(queryObject) {
    const queryObjectSelector = wrapSelector(queryObject);

    return createSelector(
      queryObjectSelector,
      pagesRootSelector,
      (queryObjectValue, pages) => {
        const context = createContext(requestMethods.get, queryObjectValue);
        return pages[context] || 0;
      }
    );
  };
}
