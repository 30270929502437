import get from "lodash.get";

const encounterIDFormatter = (
  encounter,
  encounterNamingConvention,
  encounterFieldsShown
) => {
  if (encounterFieldsShown.externalIdentifier) {
    const encounterNumber = get(
      encounter,
      "attributes.externalIdentifier",
      "N/A"
    );
    return `${encounterNamingConvention}: ${encounterNumber}`;
  }
  return null;
};

export default encounterIDFormatter;
