// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";

type $size = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto";

type $props = {
  centre?: boolean,
  className?: string,
  children?: any,
  size?: $size,
  xs?: $size,
  sm?: $size,
  md?: $size,
  lg?: $size,
  xl?: $size,
  dashboard?: string
};
export default class GridRow extends PureComponent {
  props: $props;
  render() {
    const {
      children,
      className,
      size,
      sm,
      md,
      lg,
      xl,
      xs,
      centre,
      dashboard,
      ...otherProps
    } = this.props;
    const klass = classnames(className, "col", `col-xs-${size || "12"}`, {
      ["text-center"]: centre,
      [`col-xs-${xs || ""}`]: xs,
      [`col-xs-${sm || ""}`]: sm,
      [`col-md-${md || ""}`]: md,
      [`col-lg-${lg || ""}`]: lg,
      [`col-xl-${xl || ""}`]: xl,
      ["dashboard-"]: !!dashboard
    });
    return (
      <div {...otherProps} className={klass}>
        {children}
      </div>
    );
  }
}
