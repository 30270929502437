import PropTypes from "prop-types";
import React from "react";
import PatientColumnComponent from "app/patients/columnComponent";

export default class RiskDashboardPatientListComponent extends React.Component {
  static propTypes = {
    patients: PropTypes.arrayOf(PropTypes.object),
    aggregateTotal: PropTypes.number,
    handleLoadMore: PropTypes.func
  };

  render = () => {
    let { patients, aggregateTotal, handleLoadMore } = this.props;

    if (patients.length === 0) {
      return (
        <p className="empty-patients-list">
          There are no residents in this category.
        </p>
      );
    }

    let formattedPatients = patients.map(patient => {
      return (
        <li key={patient.id}>
          <PatientColumnComponent patient={patient} startDate={new Date()} />
        </li>
      );
    });

    if (patients.length < aggregateTotal) {
      formattedPatients.push(
        <li key="loadMore">
          <button
            type="button"
            onClick={handleLoadMore}
            className="btn btn-block btn-link view-more-button"
          >
            <span className="view-more-plus">
              +{aggregateTotal - patients.length}
            </span>
            View more
          </button>
        </li>
      );
    }

    return <ul className="patient-list">{formattedPatients}</ul>;
  };
}
