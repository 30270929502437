// React
import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

// css
import styles from "./style.module.less";

// Containers
import { SideBarTabNavigationContainer } from "src/containers/side_bar_tab_navigation_container";
import { SideBarFiltersContainer } from "src/containers/side_bar_filters_container";

// Components
import { Glyphicon } from "src/components/ui_kit";

export class SideBarComponent extends React.Component {
  static propTypes = {
    showSidebar: PropTypes.bool.isRequired,
    toggleExpandSidebar: PropTypes.func.isRequired,
    searchPatientDashboardEnabled: PropTypes.bool.isRequired
  };

  toggleSideBar = () => {
    const { toggleExpandSidebar } = this.props;
    toggleExpandSidebar();
  };

  render() {
    const {
      showSidebar,
      monitoringTabEnabled,
      partnerName,
      searchPatientDashboardEnabled
    } = this.props;

    const classnamesObject = {
      [styles["sidebar-pcc"]]:
        partnerName == "pcc" || partnerName == "pcc_wound"
    };

    const sideBarToggleClassNames = classnames(
      styles["sidebar-toggle"],
      classnamesObject
    );

    return (
      <div className={styles["sidebar-container"]}>
        <SideBarTabNavigationContainer
          className={styles["sidebar-navigation"]}
        />
        {showSidebar && !searchPatientDashboardEnabled && (
          <SideBarFiltersContainer className={styles["sidebar-filter"]} />
        )}
        {monitoringTabEnabled && ( // Temporarily only enable this feature for VCS:QA
          <div onClick={this.toggleSideBar} className={sideBarToggleClassNames}>
            <div className={styles["sidebar-toggle-icon"]}>
              <Glyphicon glyph={showSidebar ? "menu-left" : "menu-right"} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
