import React from "react";

export class SignedIconComponent extends React.Component {
  render() {
    return (
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 108 108"
      >
        <g id="Row_5">
          <path
            className="st0"
            d="M75.4,62.9c0.1-0.4,0.4-0.6,0.8-0.8L97.3,54l-21.1-8.1c-0.7-0.3-1.1-1.1-0.8-1.8c0,0,0,0,0-0.1l9.2-20.6 L64,32.5c-0.4,0.2-0.8,0.2-1.1,0c-0.4-0.1-0.6-0.4-0.8-0.8L54,10.7l-8.1,21.1c-0.1,0.4-0.4,0.7-0.8,0.8c-0.4,0.2-0.8,0.2-1.1,0 l-20.6-9.2L32.5,44c0.3,0.7,0,1.6-0.7,1.9c0,0,0,0-0.1,0L10.7,54l21.1,8.1c0.7,0.3,1.1,1.1,0.8,1.8c0,0,0,0,0,0.1l-9.2,20.6 L44,75.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5,0.1c0.4,0.1,0.6,0.4,0.8,0.8L54,97.3l8.1-21.1c0.1-0.4,0.4-0.7,0.8-0.8 c0.4-0.2,0.8-0.2,1.1,0l20.7,9.2L75.4,64C75.3,63.6,75.3,63.2,75.4,62.9z M70.3,46.5L50.9,65.4c-0.5,0.5-1.2,0.8-1.9,0.8 c-0.7,0-1.4-0.3-1.9-0.8l-9.4-9.4c-1-1.1-1-2.8,0.1-3.9c1.1-1,2.7-1,3.8,0l7.5,7.5l17.4-17c1.1-1.1,2.8-1.1,3.9,0 C71.4,43.7,71.4,45.4,70.3,46.5C70.3,46.5,70.3,46.5,70.3,46.5z"
            id="Signed"
          />
        </g>
      </svg>
    );
  }
}
