import React from "react";
import PropTypes from "prop-types";

import { AssessmentPatientCardComponent } from "src/components/cards";
import { LoadMoreCardComponent } from "src/components/cards";

import styles from "./style.module.less";

/**
 * Component to display a list of wound assessments filtered by the selected progress
 * filter.
 */
export class WoundAssessmentProgressListComponent extends React.PureComponent {
  static propTypes = {
    counts: PropTypes.object.isRequired,
    assessments: PropTypes.array.isRequired,
    selectedProgressFilter: PropTypes.string.isRequired,
    loadMoreAssessmentsForProgress: PropTypes.func.isRequired,
    nextPagePayload: PropTypes.object,
    nextPageContext: PropTypes.string
  };

  handleLoadMore = () => {
    this.props.loadMoreAssessmentsForProgress(
      this.props.nextPagePayload,
      this.props.nextPageContext
    );
  };

  render() {
    const { assessments, counts, selectedProgressFilter } = this.props;
    let assessmentCards = [];

    if (!assessments.length)
      assessmentCards.push(
        <li key="0" className={styles["width-align-center"]}>
          There are no wounds in this status category
        </li>
      );
    else {
      assessmentCards = assessments.map((assessment, index) => {
        const { attributes } = assessment;
        // build patient from the assessment information
        const patient = {
          id: attributes.patientId,
          firstName: attributes.patientFirstName,
          lastName: attributes.patientLastName,
          facilityRoom: attributes.patientFacilityRoom,
          facilityRoomBed: attributes.patientFacilityBed,
          avatarImageUrl: attributes.patientAvatarImageUrls
            ? attributes.patientAvatarImageUrls.small
            : null,
          admissionId: attributes.patientAdmissionId,
          mrn: attributes.patientMrn
        };

        return (
          <li key={index}>
            <AssessmentPatientCardComponent
              assessment={assessment}
              patient={patient}
            />
          </li>
        );
      });

      if (counts[selectedProgressFilter] > assessments.length) {
        const remainingCount =
          counts[selectedProgressFilter] - assessments.length;

        assessmentCards.push(
          <li key="loadMore">
            <LoadMoreCardComponent
              remainingCount={remainingCount}
              handleLoadMore={this.handleLoadMore}
            />
          </li>
        );
      }
    }

    return (
      <div className={styles["wound-card-list-container"]}>
        <ul className={styles["wound-card-list"]}>{assessmentCards}</ul>
      </div>
    );
  }
}
