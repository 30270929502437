import PropTypes from "prop-types";
import React from "react";
// Css
import styles from "./style.module.less";

//bootstrap table component
import BootstrapTable from "react-bootstrap-table-next";

/**
 * React Component that will surround its children with a table container
 */

export function TableComponent(props) {
  TableComponent.propTypes = {
    keyField: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired
  };

  const { rowClasses, keyField, data, columns } = props;
  return (
    <div className={styles["bootstrap-table"]}>
      <BootstrapTable
        keyField={keyField}
        data={data}
        columns={columns}
        striped
        hover
        bordered={false}
        rowClasses={rowClasses}
      />
    </div>
  );
}
