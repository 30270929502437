import { createSelector } from "reselect";
import moment from "moment";
import { framesResourceSelectors } from "../frames_resource_selectors";
import { STANDARD_FRAME_TYPES } from "src/selectors/data_selectors/frames/constants.js";

/**
 * Create a selector to get the most recent frames for a set of revisions
 * @param {Object} props
 * @param {array} props.revisions
 * @return {Function} selector
 */
export function createDefaultFramesForRevisionsSelector({ revisions }) {
  return createSelector(
    framesResourceSelectors.dataSelector,
    frames => {
      const sortedFrames = Object.values(frames).sort((f1, f2) => {
        return moment(f1.attributes.createdAt).isAfter(f2.attributes.createdAt)
          ? -1
          : 1;
      });

      return revisions.reduce((agg, r) => {
        const filteredFrame = sortedFrames.find(
          frame =>
            frame.attributes.revisionId == r.id &&
            STANDARD_FRAME_TYPES.includes(frame.attributes.frameType)
        );
        agg[r.id] = filteredFrame || undefined;
        return agg;
      }, {});
    }
  );
}
