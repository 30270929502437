import { connect } from "react-redux";

import {
  brandingSelector,
  defaultNavigationPageSelector
} from "src/selectors/ui_config_selectors/global";
// import { accessTokenSelector } from "src/selectors/session_selectors";

import {
  policiesResourceSelectors,
  organizationsResourceSelectors
} from "src/selectors/data_selectors";

import { currentUserSelectorLegacy } from "src/selectors/config_selectors/current_user_selector";

// @flow
import { SignInComponent } from "app/sign_in";

function mapStateToProps(state) {
  return {
    branding: brandingSelector(state),
    brandingStyle: brandingSelector(state),
    currentUser: currentUserSelectorLegacy(state), //TODO: need to use legacy user selector.
    organization: organizationsResourceSelectors.dataSelector(state),
    policies: policiesResourceSelectors.dataSelector(state),
    // accessToken: accessTokenSelector(state), //TODO: remove this?
    //SignInComponent should be aware of organization and policies once they are loaded to check
    //for which page to land on upon sign in, and what tabs to show on the sidebar navigation list
    defaultNavigationPage: defaultNavigationPageSelector(state)
  };
}

export const SignInContainer = connect(mapStateToProps)(SignInComponent);
