import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";

// Middleware
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import { analyticsRouterTracking } from "src/redux_middleware/analytics_router_tracking";

// Reducers
import reducer from "./reducer";
import { setupIncrementalReduxReducers } from "incremental-redux-reducers";

// History
import history from "./history";

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(thunk, analyticsRouterTracking, routerMiddleware(history))
  // applyMiddleware(thunk, routerMiddleware(history))
)(createStore);

let store = createStoreWithMiddleware(reducer);

setupIncrementalReduxReducers(store);

export default store;
