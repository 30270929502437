import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import get from "lodash.get";

// Constants
import { loadStates } from "src/constants/load_states";

// Components
import { LoadingComponent } from "src/components/loading_component";
import { StudyBadgeComponent } from "../study_badge_component";

// Containers
import { NonWoundAssessmentListItemContainer } from "src/containers/non_wound_assessment_list_item_container";

// Styles
import styles from "./style.module.less";

//Analytics
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";

export class NonWoundAssessmentListComponent extends React.PureComponent {
  static propTypes = {
    assessmentAnswers: PropTypes.array.isRequired,
    assessmentAnswersLoadState: PropTypes.string,
    assessmentsContextLoadState: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    janssenDashboardEnabled: PropTypes.boolean
  };

  render() {
    const {
      assessmentAnswers,
      assessmentAnswersLoadState,
      assessmentsContextLoadState,
      match,
      janssenDashboardEnabled
    } = this.props;

    const assessmentsLoaded = assessmentAnswers
      .map(aa => aa.attributes.assessmentId)
      .every(id => {
        return assessmentsContextLoadState[id] == loadStates.loaded;
      });

    if (
      !assessmentAnswersLoadState ||
      assessmentAnswersLoadState == loadStates.loading ||
      !assessmentsLoaded
    ) {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_PATIENTS_NON_WOUND_ASSESSMENT_LIST">
          <LoadingComponent height="50px" />
        </StartRenderTimeTrackComponent>
      );
    }

    if (assessmentAnswersLoadState == loadStates.error) {
      return (
        <FinishRenderTimeTrackComponent
          eventName="RENDER_PATIENTS_NON_WOUND_ASSESSMENT_LIST"
          error={true}
        >
          <LoadingComponent height="50px" />
        </FinishRenderTimeTrackComponent>
      );
    }

    const list =
      assessmentAnswers &&
      assessmentAnswers.map(aa => {
        const assessmentId = get({ aa }, "aa.attributes.assessmentId", "");
        return (
          <li className={styles["non-wound-study-badge-container"]} key={aa.id}>
            <Link to={`${match.url}/assessments/${assessmentId}`}>
              <NonWoundAssessmentListItemContainer assessmentAnswer={aa} />
            </Link>
          </li>
        );
      });

    const thermalBadge = (
      <li
        className={styles["non-wound-study-badge-container"]}
        key="thermal_badge"
      >
        <Link to={`${match.url}/thermal`}>
          <StudyBadgeComponent title="Thermal" value="T" bottomText="" />
        </Link>
      </li>
    );

    return (
      <FinishRenderTimeTrackComponent eventName="RENDER_PATIENTS_NON_WOUND_ASSESSMENT_LIST">
        <ul className="list-inline">
          {list}
          {janssenDashboardEnabled && thermalBadge}
        </ul>
      </FinishRenderTimeTrackComponent>
    );
  }
}
