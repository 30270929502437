export const woundEvaluations = {
  lock: {
    403: "You do not have permission to lock the evaluation. If this is an error, please try again and If the issue persists, contact your organization’s IT support staff",
    409: "The evaluation cannot be locked as there is no signature . If this is an error, please try again and If the issue persists, contact your organization’s IT support staff",
    423: "The evaluation has already been locked. If this is an error, please try again and If the issue persists, contact your organization’s IT support staff",
    default: "Sorry, there was an error locking the evaluation"
  },
  sign: {
    401: "Password is incorrect. Please try again. If issue persists, contact your organization’s IT support staff",
    403: "You do not have permission to sign the evaluation. If this is an error, please try again and If the issue persists, contact your organization’s IT support staff",
    423: "The evaluation has already been locked. If this is an error, please try again and If the issue persists, contact your organization’s IT support staff",
    default: "Sorry, there was an error signing the evaluation"
  }
};
