import { createSelector } from "reselect";

/**
 * Memoized selector for the vertical selector to be used for vertical based feature enabling/disabling
 */
export const verticalSelector = createSelector(
  () => process.env.VERTICAL,
  vertical => {
    return vertical || undefined;
  }
);
