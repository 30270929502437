import { createSelector } from "reselect";

//<-------- Note: Code commented out to enable revisions comparisons for all users------------->//

// import { verticalSelector } from "src/config_selectors";

export const revisionsTableEnabledSelector = createSelector(
  // verticalSelector,
  () => {
    return true;
  },
  enabled => enabled
);
