import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash.get";

// Components
import { RevisionsComparisonItemContainer } from "src/containers/revisions_comparison_item_container";
import { PlusIconComponent } from "src/components/plus_icon_component";
import { ChartComponent } from "src/components/chart_component";
import { HealingIndexChartContainer } from "src/containers/healing_index_chart_container";

// Styles
import styles from "./style.module.less";

const colors = [
  "#FF3B30",
  "#FF9500",
  "#FFCC00",
  "#4CD964",
  "#5AC8FA",
  "#007AFF",
  "#5856D6",
  "#FF2D55"
];

export class RevisionsComparisonComponent extends React.PureComponent {
  static propTypes = {
    navigationType: PropTypes.oneOf(["plusButton", "closureChart"]).isRequired,
    seriesId: PropTypes.string.isRequired,
    revisions: PropTypes.array.isRequired,
    revisionsToCompareForSeries: PropTypes.array.isRequired,
    sendSelectRevisionAction: PropTypes.func.isRequired,
    sendInsertRevisionAction: PropTypes.func,
    sendAddRevisionItemAction: PropTypes.func.isRequired,
    sendRemoveRevisionItemAction: PropTypes.func.isRequired,
    sendRevisionComparisonTabSelectedAction: PropTypes.func.isRequired,
    healingIndexDashboardEnabled: PropTypes.bool.isRequired
  };

  //Empty state to follow getDerivedStateFromProps pattern
  state = {
    colors: {},
    currentFrameTab: ""
  };

  constructor(props) {
    super(props);

    const {
      navigationType,
      revisions,
      revisionsToCompareForSeries,
      seriesId,
      sendAddRevisionItemAction,
      sendSelectRevisionAction
    } = props;

    // Set up initial revisions
    if (!revisionsToCompareForSeries.length && revisions.length) {
      let defaultRevisions = [revisions[0]];
      if (revisions.length > 1) {
        defaultRevisions.push(revisions[revisions.length - 1]);
      }

      switch (navigationType) {
        case "plusButton":
          defaultRevisions.forEach((rev, index) => {
            sendAddRevisionItemAction(seriesId);
            sendSelectRevisionAction({ seriesId, revisionId: rev.id, index });
          });
          break;
        case "closureChart":
          let colorState = {};
          defaultRevisions.forEach((rev, index) => {
            colorState[rev.id] = colors[index];
            sendAddRevisionItemAction({ revisionId: rev.id, seriesId });
          });

          this.state = {
            colors: colorState
          };

          break;
      }
    }

    this.setCurrentFrameTab = this.setCurrentFrameTab.bind(this);
  }

  componentDidMount() {
    this.props.sendRevisionComparisonTabSelectedAction(true);
  }

  componentWillUnmount() {
    this.props.sendRevisionComparisonTabSelectedAction(false);
  }

  setCurrentFrameTab(newFrameTab) {
    this.setState({ currentFrameTab: newFrameTab });
  }

  generateClosureChart(revisions, selectedRevisionIds) {
    const data = {
      labels: revisions.map(r =>
        moment(r.attributes.createdAt).format("MM-DD-YYYY")
      ),
      datasets: [
        {
          data: revisions.map(r => r.attributes.closure),
          fill: false,
          pointRadius: revisions.map(r =>
            selectedRevisionIds.includes(r.id) ? 9 : 5
          ),
          pointBorderColor: revisions.map(r => {
            return selectedRevisionIds.includes(r.id)
              ? this.state.colors[r.id]
              : "grey";
          }),
          pointBorderWidth: 3
        }
      ]
    };

    return {
      type: "line",
      data,
      options: {
        events: ["click"],
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "% Changed",
                fontSize: 14
              }
            }
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Date of Evaluation",
                fontSize: 14
              }
            }
          ]
        }
      }
    };
  }

  renderRevisionComparisonItems() {
    const {
      navigationType,
      revisionsToCompareForSeries,
      seriesId
    } = this.props;

    const { currentFrameTab } = this.state;

    if (
      Array.isArray(revisionsToCompareForSeries) &&
      revisionsToCompareForSeries.length
    ) {
      return revisionsToCompareForSeries.map((revisionId, index) => {
        return (
          <RevisionsComparisonItemContainer
            highlightColor={this.state.colors[revisionId]}
            revisionSelectionEnabled={navigationType == "plusButton"}
            key={`${seriesId} - ${index}`}
            seriesId={seriesId}
            selectedRevisionId={revisionId}
            index={index}
            currentFrameTab={currentFrameTab}
            setCurrentFrameTab={this.setCurrentFrameTab}
          />
        );
      });
    }
  }

  renderAddRevisionComparisonItemButton = () => {
    const { sendAddRevisionItemAction, seriesId } = this.props;
    return (
      <div
        className={styles["plus-icon"]}
        onClick={() => sendAddRevisionItemAction(seriesId)}
      >
        <PlusIconComponent />
      </div>
    );
  };

  onChartClicked = (e, chartElements) => {
    const {
      seriesId,
      revisions,
      revisionsToCompareForSeries,
      sendInsertRevisionAction,
      sendRemoveRevisionItemAction
    } = this.props;
    if (chartElements.length) {
      // Get index of revision clicked in chart
      const element = chartElements[0];
      const index = element._index;

      // Check if clicked revision is already selected
      const clickedRevision = revisions[index];
      const indexOfClickedRevision = revisionsToCompareForSeries.indexOf(
        clickedRevision.id
      );

      // Get list of all selected revisions and find the one that was clicked
      const selectedRevisions = revisionsToCompareForSeries.map(id =>
        revisions.find(r => r.id == id)
      );
      const revisionBeingAdded = revisions[index];

      // Check if revision is already selected - remove it
      // Otherwise, insert it at correct sorted position
      if (indexOfClickedRevision >= 0) {
        // Clear color
        this.clearColorForRevisionId(revisionBeingAdded.id);

        sendRemoveRevisionItemAction({
          seriesId,
          index: indexOfClickedRevision
        });
      } else {
        if (revisionsToCompareForSeries >= 8) {
          return;
        }
        const revisionBeingAddedCreatedMoment = moment(
          revisionBeingAdded.attributes.createdAt
        );

        let indexToInsert = selectedRevisions.length;
        for (let i = 0; i < selectedRevisions.length; i++) {
          const revAtIndexCreatedMoment = moment(
            selectedRevisions[i].attributes.createdAt
          );
          if (
            revisionBeingAddedCreatedMoment.isBefore(revAtIndexCreatedMoment)
          ) {
            indexToInsert = i;
            break;
          }
        }

        // set the color
        this.setColorForRevisionId(revisionBeingAdded.id);

        // insert revision in list
        sendInsertRevisionAction({
          seriesId,
          revisionId: revisionBeingAdded.id,
          index: indexToInsert
        });
      }
    }
  };

  getNextColor() {
    return colors.find(c => {
      return !Object.values(this.state.colors).includes(c);
    });
  }

  setColorForRevisionId(revId) {
    const color = this.getNextColor();

    this.setState({
      colors: {
        ...this.state.colors,
        [revId]: color
      }
    });
  }

  clearColorForRevisionId(revId) {
    this.setState({
      colors: {
        ...this.state.colors,
        [revId]: undefined
      }
    });
  }

  render() {
    const {
      navigationType,
      revisionsToCompareForSeries,
      revisions,
      seriesId,
      healingIndexDashboardEnabled,
      assessmentAnswer
    } = this.props;

    // show plus icon
    let showPlusIcon = false;
    if (
      navigationType == "plusButton" &&
      revisionsToCompareForSeries &&
      revisions
    ) {
      showPlusIcon = revisionsToCompareForSeries.length < revisions.length;
    }
    const woundType = get(
      assessmentAnswer,
      "attributes.answersJson.woundType",
      ""
    );

    return (
      <div>
        {healingIndexDashboardEnabled && woundType == "pressureUlcer" && (
          <HealingIndexChartContainer seriesId={seriesId} />
        )}

        {navigationType == "closureChart" && (
          <ChartComponent
            chartDefinition={this.generateClosureChart(
              revisions,
              revisionsToCompareForSeries
            )}
            width={300}
            height={50}
            onClick={this.onChartClicked}
          />
        )}
        <div className={styles["comparison-view-container"]}>
          {this.renderRevisionComparisonItems()}
          {showPlusIcon ? this.renderAddRevisionComparisonItemButton() : null}
        </div>
      </div>
    );
  }
}
