import { connect } from "react-redux";

// Constants
import { sections, hprFilterRange } from "src/constants";

// Component
import { SideBarRangeFilterComponent } from "src/components/side_bar_range_filter_component";

// Selectors
import { hprScoreMaxRangeFilterSelector } from "src/selectors/section_selectors/wounds/hpr_score_max_range_filter_selector";
import { hprScoreMinRangeFilterSelector } from "src/selectors/section_selectors/wounds/hpr_score_min_range_filter_selector";

// Actions
import { createSectionActions } from "src/ui_processors/global/action_creators";

// normalizers
import {
  createMinValueOfRangeNormalizer,
  createMaxValueOfRangeNormalizer
} from "src/input_normalizers";
const minValueOfRangeNormalizer = createMinValueOfRangeNormalizer(
  hprFilterRange.minValue
);
const maxValueOfRangeNormalizer = createMaxValueOfRangeNormalizer(
  hprFilterRange.maxValue
);

function mapStateToProps(state) {
  return {
    minValue: hprScoreMinRangeFilterSelector(state),
    maxValue: hprScoreMaxRangeFilterSelector(state),
    minRangeAllowed: hprFilterRange.minValue,
    maxRangeAllowed: hprFilterRange.maxValue,
    minValueOfRangeNormalizer,
    maxValueOfRangeNormalizer
  };
}
const sectionActions = createSectionActions(sections.wounds);

// Dispatch Actions
function mapDispatchToProps(dispatch) {
  return {
    minChanged: minValue => {
      dispatch(
        sectionActions.setSingleValue({
          key: "minHprScore",
          value: minValue
        })
      );
    },
    maxChanged: maxValue => {
      dispatch(
        sectionActions.setSingleValue({
          key: "maxHprScore",
          value: maxValue
        })
      );
    },
    resetRange: () => {
      dispatch(
        sectionActions.setSingleValue({
          key: "minHprScore",
          value: ""
        })
      );
      dispatch(
        sectionActions.setSingleValue({
          key: "maxHprScore",
          value: ""
        })
      );
    }
  };
}

export const SideBarHprScoreFilterContainerWounds = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBarRangeFilterComponent);
