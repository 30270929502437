// @flow
import get from "lodash.get";
import { batchActions } from "redux-batched-actions";
import {
  indexEntity,
  createRelationship,
  addRelationship
} from "../actionUtils/actions";

export const sendNormalizedToStore = function(
  { entities, relationships }: Object,
  options?: Object = {}
): Object {
  const entityActions = Object.keys(entities).map(entityName => {
    const entityIndex = entities[entityName];
    const entitiesArray = Object.keys(entityIndex).map(id => entityIndex[id]);
    return indexEntity(entityName, entitiesArray);
  });
  const relationshipActions = Object.keys(relationships).reduce(
    (finalResult, entityName) => {
      const relationshipsByRelationshipName = relationships[entityName];
      Object.keys(relationshipsByRelationshipName).forEach(relationshipName => {
        const relationshipsById =
          relationshipsByRelationshipName[relationshipName];
        Object.keys(relationshipsById).forEach(id => {
          if (
            get(options, `relationships.${entityName}.${relationshipName}.add`)
          ) {
            finalResult.push(
              addRelationship(
                entityName,
                relationshipName,
                id,
                relationshipsById[id]
              )
            );
          } else {
            finalResult.push(
              createRelationship(
                entityName,
                relationshipName,
                id,
                relationshipsById[id]
              )
            );
          }
        });
      });
      return finalResult;
    },
    []
  );
  return batchActions([...entityActions, ...relationshipActions]);
};
