import { createSelector } from "reselect";

// Selectors
import { createFirstPageEventsForAssessmentAnswerGroupIdQuerySelector } from "src/selectors/query_selectors/events_for_assessment_answer_group_id";

/**
 * create a selector to get the context for the first page - loading events with a assessmentAnswerGroupId
 * @param {Object} props
 * @param {string} props.assessmentAnswerGroupId
 * @returns {function} selector
 */
export function createFirstPageEventsForAssessmentAnswerGroupIdContextSelector({
  assessmentAnswerGroupId
}) {
  return createSelector(
    createFirstPageEventsForAssessmentAnswerGroupIdQuerySelector({
      assessmentAnswerGroupId
    }),
    query => {
      return query;
    }
  );
}
