import get from "lodash.get";
import moment from "moment";

export function woundHeaderClosureChart(seriesTrend) {
  const closure = get(seriesTrend, "attributes.closure_trend", {});
  const { values, dates } = closure;

  const data = values.map((val, index) => {
    return {
      x: dates[index],
      y: val
    };
  });

  return {
    type: "line",
    data: {
      datasets: [
        {
          label: "Treatments",
          fill: true,
          backgroundColor: "#e6efd5",
          strokeColor: "rgba(124,174,43,1)",
          pointColor: "rgba(124,174,43,1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(124,174,43,1)",
          data: data,
          pointRadius: 6,
          pointHoverRadius: 10,
          pointHitRadius: 30,
          pointBorderWidth: 2,
          borderColor: "rgba(124,174,43,1)"
        }
      ]
    },
    options: {
      animation: false,
      responsive: false,
      title: {
        display: true,
        text: "Percent Changed",
        fontSize: 40,
        padding: 10,
        fontColor: "#000000"
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              callback(label, index, labelsArray) {
                if (index === 0 || index === labelsArray.length - 1) {
                  return moment(label).format("MM/DD/YYYY");
                }
              },
              fontSize: 22,
              fontColor: "#000000",
              maxRotation: 0,
              padding: 20
            },
            gridLines: {
              color: "#000000"
            },
            time: {
              displayFormats: "MM/DD/YYYY",
              unit: "day",
              round: "day"
            },
            type: "time",
            display: true,
            scaleLabel: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              callback(label) {
                if (label.toFixed(0) == label) {
                  return label;
                }
              },
              beginAtZero: true,
              max: 100,
              fontSize: 22,
              fontColor: "#000000"
            },
            gridLines: {
              color: "#000000"
            },
            display: true,
            scaleLabel: {
              display: false
            }
          }
        ]
      }
    }
  };
}
