import { combineReducers } from "redux";
import { complianceSectionReducers } from "./compliance_section_reducers";
import { patientsSectionReducers } from "./patients_section_reducers";
import { woundsSectionReducers } from "./wounds_section_reducers";
import { riskSectionReducers } from "./risk_section_reducers";
import { surveySectionReducers } from "./survey_section_reducers";
import { populationSectionReducers } from "./population_section_reducers";
import { adminSectionReducers } from "./admin_section_reducers";
import { globalSectionReducers } from "./global_section_reducers";
import { outcomesSectionReducers } from "./outcomes_section_reducers";
import { exportsSectionReducers } from "./exports_section_reducers";
import { monitoringSectionReducers } from "./monitoring_section_reducers";
import { myDashboardSectionReducers } from "./my_dashboard_section_reducers";
import { alertsSectionReducers } from "./alerts_section_reducers";
import { otpVerificationSectionReducers } from "./otp_verification_section_reducers";
import { evaluationListWoundReducers } from "./evaluation_list_reducers/evaluation_list_wound_reducers";
import { evaluationListBradenReducers } from "./evaluation_list_reducers/evaluation_list_braden_reducers";
import { evaluationListPURSReducers } from "./evaluation_list_reducers/evaluation_list_purs_reducers";
import { evaluationListTotalSkinReducers } from "./evaluation_list_reducers/evaluation_list_total_skin_reducers";
import { woundRiskReducers } from "./wound_risk_reducers";

export const sectionReducers = combineReducers({
  compliance: complianceSectionReducers,
  patients: patientsSectionReducers,
  wounds: woundsSectionReducers,
  risk: riskSectionReducers,
  survey: surveySectionReducers,
  population: populationSectionReducers,
  admin: adminSectionReducers,
  global: globalSectionReducers,
  outcomes: outcomesSectionReducers,
  exports: exportsSectionReducers,
  monitoring: monitoringSectionReducers,
  myDashboard: myDashboardSectionReducers,
  alerts: alertsSectionReducers,
  otpVerification: otpVerificationSectionReducers,
  evaluationListWound: evaluationListWoundReducers,
  evaluationListBraden: evaluationListBradenReducers,
  evaluationListPURS: evaluationListPURSReducers,
  evaluationListTotalSkin: evaluationListTotalSkinReducers,
  woundRisk: woundRiskReducers
});
