import get from "lodash.get";
import i18n from "src/translations";

export function getHPRTable(revision) {
  const { assessmentAnswers } = revision.attributes;
  const worstTissueType = get(
    assessmentAnswers[0],
    "answersJson.worstTissueTypeScore",
    null
  );
  const surfaceAreaValue = get(
    assessmentAnswers[0],
    "answersJson.surfaceAreaValueScore",
    null
  );
  const intactValue = get(
    assessmentAnswers[0],
    "answersJson.intactValueScore",
    null
  );
  const totalHPRScore = get(assessmentAnswers[0], "answersJson.hprScore", null);

  let body = [];

  // WTT row
  body.push([
    {
      text: i18n.t(`data.hprScoreLabels.worstTissueTypeScore`),
      style: "tableLabelCell"
    },
    {
      text: worstTissueType || "INC",
      style: "tableDataCell"
    }
  ]);

  // SAV row
  body.push([
    {
      text: i18n.t(`data.hprScoreLabels.surfaceAreaValueScore`),
      style: "tableLabelCell"
    },
    {
      text: surfaceAreaValue || "INC",
      style: "tableDataCell"
    }
  ]);

  // SAV row
  body.push([
    {
      text: i18n.t(`data.hprScoreLabels.intactValueScore`),
      style: "tableLabelCell"
    },
    {
      text: intactValue || "INC",
      style: "tableDataCell"
    }
  ]);

  // SAV row
  body.push([
    {
      text: i18n.t(`data.hprScoreLabels.hprScore`),
      style: "tableLabelCell"
    },
    {
      text: totalHPRScore || "INC",
      style: "tableDataCell"
    }
  ]);

  // add header
  body.unshift([
    {
      text: "HPR SCORE BREAKDOWN",
      colSpan: 2,
      style: "tableHeaderCell"
    },
    {}
  ]);

  // Construct table
  return [
    {
      table: {
        widths: ["60%", "40%"],
        headerRows: 1,
        body
      },
      layout: "pdfTableStyle"
    }
  ];
}
