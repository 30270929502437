import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// selectors
import {
  populationTalliesDataSelector,
  populationTalliesLoadStateSelector
} from "src/selectors/data_selectors";

// HOC
import { withSchemaLocalizationDictionaryWrapper } from "src/hoc/with_schema_localization_dictionary_wrapper";

// Components
import { PopulationTallyTableByEtiologyComponent } from "src/components/tables";

// -------------- Prop & Component Connection -------------- //
function mapStateToProps(state) {
  return {
    tallies: populationTalliesDataSelector(state),
    populationTalliesLoadState: populationTalliesLoadStateSelector(state)
  };
}

export const PopulationTallyTableByEtiologyContainer = flowRight(
  withSchemaLocalizationDictionaryWrapper,
  connect(mapStateToProps)
)(PopulationTallyTableByEtiologyComponent);
