import get from "lodash.get";
import { styles } from "../pdf_styles";
import { margins } from "../margins";
import { phoneNumberFormatter } from "../formatters";

/**
 * Get the header portion of the Non Wound Assessment PDF, Formatted for PDF Make
 * @param {Object} globalContent - PDF content
 * @param {string} globalContent.logo - data url for swift logo
 * @param {string} globalContent.reportName - top left text for the header
 * @param {string} globalContent.contextText - context text to add to the header
 * @param {Object} globalContent.organization - Organization
 *
 * @param {Object} options
 * @param {bool} options.landscape - true if landscape pdf
 * @param {string} options.branding - branding string
 * @param {bool} options.showPageNumber - false to hide page number
 * @param {bool} options.showOrganizationDetails - to show an organization's address and contact info
 * @return {Object} JSON representation of the pdf header
 */
export function getHeaderV2(globalContent, options = {}) {
  const { logo, reportName, contextText, organization } = globalContent;
  const {
    landscape,
    branding,
    showPageNumber,
    showOrganizationDetails,
    fillWhite
  } = options;

  let logoWidth, logoHeight;
  let logoTopMargin = margins.defaultPageMargin + 2;
  switch (branding) {
    case "pcc":
    case "pcc_wound":
      logoWidth = 163;
      logoHeight = 20;
      break;
    case "medline":
      logoWidth = 65;
      logoHeight = 25;
      logoTopMargin = margins.defaultPageMargin - 4;
      break;
    default:
      logoWidth = 250;
      logoHeight = 14;
  }

  return function(currentPage, pageCount) {
    let body = [[]];
    // Total width should add up to page width (792 - landscape, 612 - portrait)
    let widths = [];

    // Report Name
    widths.push(158);
    body[0].push({
      text: `${reportName}`,
      bold: true,
      fontSize: 13,
      color: fillWhite ? "#000000" : "#848484",
      fillColor: fillWhite ? "#ffffff" : "#f6f6f6",
      alignment: "center",
      // left, top, right, bottom
      // The top and bottom margins here determine the pdf header height
      // height = top + bottom + space taken up by header text.
      // NOTE: the header does not handle text wrapping very well.
      margin: [
        margins.defaultPageMargin,
        margins.defaultPageMargin,
        5,
        margins.defaultPageMargin
      ],
      border: [false, false, true, true]
    });

    if (organization && showOrganizationDetails) {
      const address = {
        stack: [
          {
            text: `${get(organization, "attributes.name", "") || ""}`
          },
          {
            text: `${get(organization, "attributes.address", "") || ""}`
          },
          {
            text: `${get(organization, "attributes.city", "") || ""}, ${get(
              organization,
              "attributes.provinceCode",
              ""
            ) || ""} ${get(organization, "attributes.postalCode", "") || ""}`
          }
        ],
        fontSize: 8,
        color: fillWhite ? "#000000" : "#848484",
        fillColor: fillWhite ? "#ffffff" : "#f6f6f6",
        alignment: "left",
        margin: [8, margins.defaultPageMargin - 6, 0, 0],
        border: [false, false, false, true]
      };

      let fax = phoneNumberFormatter(get(organization, "attributes.fax", ""));
      let phone = phoneNumberFormatter(
        get(organization, "attributes.phone", "")
      );

      const contactInfo = {
        stack: [
          {
            text: `Fax: ${fax}`
          },
          {
            text: `Phone: ${phone}`
          },
          {
            text: `Email: ${get(organization, "attributes.email", "") || ""}`
          }
        ],
        fontSize: 8,
        color: fillWhite ? "#000000" : "#848484",
        fillColor: fillWhite ? "#ffffff" : "#f6f6f6",
        alignment: "left",
        margin: [0, margins.defaultPageMargin - 6, 4, 0],
        border: [false, false, true, true]
      };

      body[0].push(address);
      body[0].push(contactInfo);

      if (landscape) {
        widths.push(100);
        widths.push(100);
      }
    }

    // Organization name
    if (organization && !showOrganizationDetails) {
      body[0].push({
        text: get(organization, "attributes.name", ""),
        fontSize: 10,
        color: fillWhite ? "#000000" : "#848484",
        fillColor: fillWhite ? "#ffffff" : "#f6f6f6",
        alignment: "center",
        margin: [4, margins.defaultPageMargin + 2, 4, 0],
        border: [false, false, true, true]
      });

      if (landscape) {
        widths.push(156);
      }
      // TODO: else case for portrait with organization name
    }

    // Context text (ex. report date range)
    if (contextText) {
      body[0].push({
        text: `${contextText}`,
        fontSize: 10,
        color: fillWhite ? "#000000" : "#848484",
        fillColor: fillWhite ? "#ffffff" : "#f6f6f6",
        alignment: "center",
        margin: [2, margins.defaultPageMargin + 2, 2, 0],
        border: [false, false, true, true]
      });

      if (landscape) {
        widths.push(150);
      }
      // TODO: else case for portrait
    }

    // Page number
    if (!(showPageNumber === false)) {
      widths.push(40);
      body[0].push({
        text: `${currentPage.toString()}/${pageCount}`,
        fontSize: 10,
        color: fillWhite ? "#000000" : "#848484",
        fillColor: fillWhite ? "#ffffff" : "#f6f6f6",
        alignment: "center",
        margin: [2, margins.defaultPageMargin + 2, 2, 0],
        border: [false, false, true, true]
      });
    }

    // Logo (or empty remaining space if no logo provided)
    body[0].push({
      stack: logo
        ? [
            {
              image: logo,
              alignment: "right",
              width: logoWidth,
              height: logoHeight
            }
          ]
        : [],
      background: "#f6f6f6",
      border: [false, false, false, true],
      fillColor: fillWhite ? "#ffffff" : "#f6f6f6",
      //margin: [ -, top, right, -]
      margin: [18, logoTopMargin, margins.defaultPageMargin, 0]
    });

    const totalWidth = widths.reduce((sum, value) => {
      sum = sum + value;
      return sum;
    }, 0);

    if (landscape) {
      widths.push(792 - totalWidth);
    } else {
      widths.push(612 - totalWidth);
    }

    return {
      table: {
        headerRows: 1,
        widths,
        body
      },
      layout: styles.tallyTable
    };
  };
}
