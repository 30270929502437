import { sortQueryObject } from "src/utils/sort_query_object";
import moment from "moment";

//Acquired Filter Values
import { acquiredPayloadFilters } from "src/constants";

export const apiV2AssessmentsPayloadGenerator = (
  selectedWoundOptions,
  selectedLocationIDs,
  acquiredFilter,
  selectedEndDate,
  hprScoreMinRangeFilter,
  hprScoreMaxRangeFilter,
  bwatScoreMinRangeFilter,
  bwatScoreMaxRangeFilter,
  selectedProgressFilter,
  pageNumber,
  pageLimit,
  selectedPayersIds
) => {
  const payload = {};
  payload.filter = {
    wounds: selectedWoundOptions,
    location_id: selectedLocationIDs,
    ...(selectedPayersIds &&
      selectedPayersIds.length && { payer_ids: selectedPayersIds.join() }),
    start_time: new moment("1900-01-01").toJSON(),
    end_time: new moment(selectedEndDate).endOf("day").toJSON(),
    wound_overall_progress: selectedProgressFilter
  };

  if (acquiredFilter) {
    payload.filter.acquired = acquiredPayloadFilters[acquiredFilter].join();
  }

  if (
    (hprScoreMinRangeFilter || hprScoreMinRangeFilter === 0) &&
    hprScoreMaxRangeFilter
  ) {
    payload.filter.hpr_score_range = `${hprScoreMinRangeFilter},${hprScoreMaxRangeFilter}`;
  }

  if (
    (bwatScoreMinRangeFilter || bwatScoreMinRangeFilter === 0) &&
    bwatScoreMaxRangeFilter
  ) {
    payload.filter.bwat_score_range = `${bwatScoreMinRangeFilter},${bwatScoreMaxRangeFilter}`;
  }

  payload.page = {
    offset: pageNumber * pageLimit,
    limit: pageLimit
  };

  return sortQueryObject(payload);
};
