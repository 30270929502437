import get from "lodash.get";

import { getWoundNumberTypeSubtypeString } from "src/utils/get_wound_number_type_subtype_string";

/**
 * Get the wound type string
 * @param {Object} obj
 * @param {Object} obj.assessmentAnswer
 * @param {Object} obj.woundTypes
 * @param {Object} obj.woundSubtypes
 * @returns {string}
 */
export function getNumberTypeSubtypeStringForAssessmentAnswer({
  assessmentAnswer,
  localizationDictionary,
  subtypeKeysForSchema,
  patientWoundNumber
}) {
  const woundTypeString = getWoundNumberTypeSubtypeString({
    answersJson: get(assessmentAnswer, "attributes.answersJson", {}),
    localizationDictionary,
    includePressureUnstageable: true,
    subtypeKeysForSchema,
    patientWoundNumber
  });

  return woundTypeString;
}
