// @flow
import { List } from "immutable";
import { convertInput, PropertyRecord } from "src/utils/modelUtils";
import GenericRiskModel from "./GenericRiskModel";

export const recordInput = {
  braden: new GenericRiskModel(),
  nortonPlus: new GenericRiskModel(),
  assessmentTypes: new List()
};

export default class RiskPropertyModel extends PropertyRecord(recordInput) {
  constructor(opts: Object = {}) {
    super(
      convertInput(
        {
          braden: ent => new GenericRiskModel(ent),
          nortonPlus: ent => new GenericRiskModel(ent),
          assessmentTypes: List
        },
        opts
      )
    );
  }
}
