import { createSelector } from "reselect";
import get from "lodash.get";

import { mergeSchemasIntoSingleSchema } from "src/utils/downloadable_schemas/merge_schemas_into_single_schema";

import { assessmentSchemasResourceSelectors } from "src/selectors/data_selectors";
import { woundSchemaPolicySelector } from "src/selectors/config_selectors";

export const mergedDescriptionSchemasSelector = createSelector(
  assessmentSchemasResourceSelectors.dataSelector,
  woundSchemaPolicySelector,
  (assessmentSchemas, woundSchemaPolicy) => {
    if (
      !woundSchemaPolicy ||
      !(assessmentSchemas && Object.values(assessmentSchemas).length)
    ) {
      return {};
    }

    const policyAssessmentSchema = Object.values(assessmentSchemas).find(
      schema => schema.attributes.assessmentId === woundSchemaPolicy
    );
    const mainDescriptionAssessmentId = get(
      policyAssessmentSchema,
      "attributes.schemaJson.components.description"
    );
    const mainDescriptionSchema = Object.values(assessmentSchemas).find(
      schema => schema.attributes.assessmentId === mainDescriptionAssessmentId
    );

    const allOtherDescriptionSchemas = Object.values(assessmentSchemas).filter(
      schema => {
        const schemaName = get(schema, "attributes.schemaJson.name", false);
        const assessmentId = get(schema, "attributes.assessmentId", false);
        return (
          schemaName &&
          schemaName == "description" &&
          assessmentId != mainDescriptionAssessmentId
        );
      }
    );

    const mergedSchemaQuestions = mergeSchemasIntoSingleSchema(
      mainDescriptionSchema,
      allOtherDescriptionSchemas
    );

    return mergedSchemaQuestions;
  }
);
