import { actionTypes } from "../action_types";

/**
 * Action to select a revision
 * @param {Object} revisionObject object with revision data eg. revisionId, seriesId, index
 * @return {Object} Action
 */
export function selectRevisionAction(revisionObject) {
  return {
    type: actionTypes.selectRevision,
    payload: revisionObject
  };
}

/**
 * Action to add a revision item
 * @param {Object} seriesId
 * @return {Object} Action
 */
export function addRevisionItemAction(seriesId) {
  return {
    type: actionTypes.addRevisionItem,
    payload: seriesId
  };
}

/**
 * Action to remove a revision item
 * @param {Object} revisionItemSeriesIdAndIndex
 * @return {Object} Action
 */
export function removeRevisionItemAction(revisionItemSeriesIdAndIndex) {
  return {
    type: actionTypes.removeRevisionItem,
    payload: revisionItemSeriesIdAndIndex
  };
}
