import { createSelector } from "reselect";

// Api
import { createArrayOfSeriesIdSelector } from "src/selectors/data_selectors/wound_series";

export function createAllPagesPayloadSelector() {
  return createSelector(
    createArrayOfSeriesIdSelector,
    woundSeriesIds => {
      return {
        filter: {
          ...(woundSeriesIds &&
            woundSeriesIds.length && { series_id: woundSeriesIds }),
          strikeout_state: "open",
          latest_revision_by_series: "true"
        },
        page: {
          limit: "1000"
        }
      };
    }
  );
}
