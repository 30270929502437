import { createSelector } from "reselect";
import { partnerNameSelector } from "../partner_name_selector";

/**
 * Memoized selector to check if it is an integration
 */
export const isIntegrationSelector = createSelector(
  partnerNameSelector,
  partnerName => {
    return partnerName == "pcc" || partnerName == "pcc_wound";
  }
);
