export const woundPainScores = {
  woundPain00: "0",
  woundPain01: "1",
  woundPain02: "2",
  woundPain03: "3",
  woundPain04: "4",
  woundPain05: "5",
  woundPain06: "6",
  woundPain07: "7",
  woundPain08: "8",
  woundPain09: "9",
  woundPain10: "10"
};
