import { adminActionTypes } from "src/action_types/section_action_types";

export function rulesSelectedOrganizationReducer(state = "", action) {
  switch (action.type) {
    case adminActionTypes.selectRulesOrganization:
      return action.payload;
    default:
      return state;
  }
}
