import { createSelector } from "reselect";
import { organizationsResourceSelectors } from "src/selectors/data_selectors/organizations";
import { selectedPartnerIdSelector } from "../selected_partner_id_selector";

export const organizationsForSelectedPartnerIdSelector = createSelector(
  organizationsResourceSelectors.dataSelector,
  selectedPartnerIdSelector,
  (organizations, selectedPartnerId) => {
    return organizations
      ? Object.values(organizations).filter(
          o => o.attributes.partnerId == selectedPartnerId
        )
      : [];
  }
);
