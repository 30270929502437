import axios from "axios";

/**
 * reset user password
 * @param {Object} payload - responsible for sending the data to api.
 * @return {Promise} Promise with the response
 */
export function resetPassword(payload) {
  const authHost = window.AUTH_HOST || process.env.REACT_APP_AUTH_HOST;

  return axios.post(`${authHost}/users/reset-password`, payload);
}
