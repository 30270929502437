import { createSelector } from "reselect";

//Payload Generator
import { createLatestEventGetPayload } from "src/api/events/payload_generators";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api query for the latest locked event with a assessmentAnswerGroupId
 * @param {Object} props
 * @param {string} props.assessmentAnswerGroupId
 * @returns {function} selector
 */
export function createLatestLockedEventForAssessmentGroupIdQuerySelector({
  assessmentAnswerGroupId
}) {
  return createSelector(() => {
    return buildQueryString(
      createLatestEventGetPayload({
        assessmentAnswerGroupId,
        eventType: "lock"
      })
    );
  });
}
