// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
export default class ButtonGroup extends PureComponent {
  render() {
    return (
      <div
        className={classnames("btn-group", this.props.className)}
        role="group"
      >
        {this.props.children}
      </div>
    );
  }
}
