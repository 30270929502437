// @flow
import React, { PureComponent } from "react";
import {
  Checkbox as RCheckbox,
  CheckboxGroup as RCheckBoxGroup
} from "react-checkbox-group";
import { createSelector } from "reselect";
import { List } from "immutable";
import classnames from "classnames";
import Checkbox from "../";
import "./style.less";
type $option = {
  value: any,
  label: string
};

type $props = {
  all?: boolean,
  name?: string,
  onChange: (any[]) => any,
  options: List<$option>,
  value: List<any>,
  disabled?: boolean
};

export default class CheckboxGroup extends PureComponent {
  getValue: $$selector<Array<any>>;
  props: $props;
  constructor(props: $props) {
    super(props);
    this.getValue = createSelector(
      [(value: List<any>) => value],
      (value: List<any>) => value.toArray()
    );
  }
  areAllChecked = () => this.props.value.size === this.props.options.size;
  checkAll = () => {
    this.props.onChange(this.props.options.map(({ value }) => value).toArray());
  };
  uncheckAll = () => {
    this.props.onChange([]);
  };
  renderCheckAll = () => {
    if (!this.props.all) {
      return null;
    }
    const areAllChecked = this.areAllChecked();
    return (
      <Checkbox
        disabled={this.props.disabled}
        onChange={areAllChecked ? this.uncheckAll : this.checkAll}
        checked={areAllChecked}
        label="All"
      />
    );
  };
  render() {
    return (
      <div>
        {this.renderCheckAll()}
        <RCheckBoxGroup
          name={this.props.name}
          value={this.getValue(this.props.value, {})}
          onChange={this.props.onChange}
        >
          <ul className={classnames({ "ui-checkbox-group": this.props.all })}>
            {this.props.options.map((option, i) => (
              <li key={i}>
                <label>
                  <RCheckbox
                    disabled={this.props.disabled}
                    value={option.value}
                  />
                  {option.label}
                </label>
              </li>
            ))}
          </ul>
        </RCheckBoxGroup>
      </div>
    );
  }
}
