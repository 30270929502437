import { createSelector } from "reselect";
import get from "lodash.get";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

//Constants
import { policyInternalNames } from "src/constants";

/**
 * Memoized selector to determine the min length for user password
 */

export const userPasswordMinLengthSelector = createSelector(
  createPolicyForInternalNameSelector(
    policyInternalNames.userPasswordMinLength
  ),
  policy => {
    return get(policy, "attributes.policyValue", 8);
  }
);
