import { createSelector } from "reselect";
import { isSwiftAdminSelector } from "../is_swift_admin_selector";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

/**
 * Memoized selector to determine if user is allowed to import patients in bulk,
 * returns true if user is a Swift Admin or has the permission, false otherwise
 */
export const adminBulkImportPatientsEnabledSelector = createSelector(
  isSwiftAdminSelector,
  createPolicyForInternalNameSelector("allow_patient_bulk_import"),
  (isSwiftAdmin, allowPatientBulkImportPolicy) => {
    return (
      isSwiftAdmin ||
      (allowPatientBulkImportPolicy &&
        allowPatientBulkImportPolicy.attributes.policyValue)
    );
  }
);
