import { connect } from "react-redux";
import { RoleFormComponent } from "src/components/forms/role_form_component";
import {
  organizationsForSelectedPartnerIdSelector,
  selectFacilitiesByAllOrganizationsSelector
} from "src/selectors/section_selectors/admin";
import { permissionTypesResourceSelectors } from "src/selectors/data_selectors";
import { reduxFormNames } from "src/constants/redux_form_names";

function mapStateToProps(state) {
  return {
    formName: reduxFormNames.roleViewForm,
    allFacilities: selectFacilitiesByAllOrganizationsSelector(state),
    organizations: organizationsForSelectedPartnerIdSelector(state),
    permissionTypes: permissionTypesResourceSelectors.dataSelector(state)
  };
}

export const ViewRoleFormContainer = connect(mapStateToProps)(
  RoleFormComponent
);
