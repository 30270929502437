// @flow
import { camelizeKeys } from "humps";
import { Set, Map, Record } from "immutable";

export const camelize = function(obj: Object = {}) {
  return camelizeKeys(obj);
};

export const convertInput = (modelChanges: Object, input: Object) => {
  return Object.keys(modelChanges).reduce(
    (finalResult, key) => {
      const originalValue = finalResult[key];
      if (originalValue) {
        const fn = modelChanges[key];
        finalResult[key] = fn(originalValue);
      }
      return finalResult;
    },
    { ...input }
  );
};

export const PropertyRecord = (inputs: Object = {}) =>
  Record({
    id: "",
    locations: new Map({
      selected: new Set()
    }),
    ...inputs
  });
