import { createSelector } from "reselect";
import cloneDeep from "lodash.clonedeep";
import { locationsToDisplaySelector } from "src/selectors/data_selectors/locations";

export const locationsFilterConfigSelector = createSelector(
  locationsToDisplaySelector,
  locations => {
    let filterConfig = {
      filterName: "locations",
      filterValues: []
    };

    filterConfig.filterValues = locations.reduce((agg, location) => {
      //Units And Floors
      if (location.depth === 2 && location.parent) {
        //Units or Floors
        const locationType = location.tags[0] + "s";
        //Get Unit or Floor value using Parent ID to differentiate
        const locationValue = location.parent.id + locationType;
        //Set up child object
        const child = {
          key: locationValue + "_location_id",
          value: location.id,
          name: location.name
        };

        //Check if parent already in agg
        let filterValue = agg.find(
          obj => obj.key === "location_id" && obj.value === location.parent.id
        );
        let filterValueIndex = agg.findIndex(
          obj => obj.key === "location_id" && obj.value === location.parent.id
        );
        //Set up children present if filterValue found
        let children =
          filterValue && filterValue.children ? filterValue.children : [];
        //Check if Units/Floors exist in chldren
        let locationTypeIndex = children.findIndex(
          child => child.value === locationValue
        );

        //Case: filterValue not found => Set up new filterValue object;
        if (!filterValue) {
          agg.push({
            key: "location_id",
            value: location.parent.id,
            name: location.parent.name,
            children: [
              {
                value: locationValue,
                name: locationType,
                children: [child]
              }
            ]
          });

          //Case: filterValue found but locationTypeIndex not found => add new locationType child to existing filterValue children
        } else if (filterValue && locationTypeIndex < 0) {
          children.push({
            value: locationValue,
            name: locationType,
            children: [child]
          });
          filterValue.children = children.sort(reverseAlphabeticOrder);
          agg[filterValueIndex] = cloneDeep(filterValue);
          //Case: filterValue found and locationTypeIndex also found => add new child to existing to existing locationTypeIndex children
        } else if (filterValue && locationTypeIndex >= 0) {
          filterValue.children[locationTypeIndex].children.push(child);
          filterValue.children[locationTypeIndex].children.sort(
            alphaticalOrder
          );
          agg[filterValueIndex] = cloneDeep(filterValue);
        }
      }

      //Facilities
      if (location.depth === 1) {
        //Check if parent already in agg
        let filterValue = agg.find(
          obj => obj.key === "location_id" && obj.value === location.id
        );
        if (!filterValue) {
          agg.push({
            key: "location_id",
            value: location.id,
            name: location.name
          });
        }
      }

      return agg.sort(alphaticalOrder);
    }, []);

    return filterConfig;
  }
);

function alphaticalOrder(loc1, loc2) {
  return loc1.name < loc2.name ? -1 : 1;
}

function reverseAlphabeticOrder(loc1, loc2) {
  return loc1.name < loc2.name ? 1 : -1;
}
