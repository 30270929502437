// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { rolesApi } from "src/api/roles";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

export const rolesResourceActions = createResourceActions(resourceNames.roles);

export const loadRolesAction = createLoadDataAction({
  resourceName: resourceNames.roles,
  api: rolesApi
});
