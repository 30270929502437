// @flow
let lastFlashMessageID = 0;

// Types: info, success, error

export const create = function createFlashMessage(
  flashMessage: string,
  messageType: string
) {
  return (dispatch: $$dispatch) => {
    dispatch({
      type: "FLASH_MESSAGE_CREATE",
      id: lastFlashMessageID++,
      flashMessage,
      messageType
    });

    setTimeout(() => {
      dispatch(remove(flashMessage, messageType));
    }, 5000);
  };
};

export const remove = function removeFlashMessage(
  flashMessage: string,
  messageType: string
) {
  return {
    type: "FLASH_MESSAGE_REMOVE",
    flashMessage,
    messageType
  };
};
