import { createSelector } from "reselect";

// Selectors
import { isSwiftAdminSelector } from "src/selectors/config_selectors/is_swift_admin_selector";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

// Constants
import { policyValues, policyInternalNames } from "src/constants";

/**
 * Memoized selector to determine if user is allowed to view users section in admin dashboard,
 * returns true if user is a Swift Admin, false otherwise
 */
export const adminUsersSectionEnabledSelector = createSelector(
  isSwiftAdminSelector,
  createPolicyForInternalNameSelector(policyInternalNames.userAccess),
  (isSwiftAdmin, userAccessPolicy) => {
    return (
      isSwiftAdmin ||
      (userAccessPolicy &&
        userAccessPolicy.attributes.policyValue.some(val => {
          return [
            policyValues.create,
            policyValues.read,
            policyValues.update,
            policyValues.delete
          ].includes(val);
        }))
    );
  }
);
