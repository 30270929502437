import React from "react";
export class SwiftIconComponent extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="554.972"
        height="553.472"
        viewBox="0 0 554.972 553.472"
        {...this.props}
      >
        <path
          fill="#FFF"
          d="M281.902,33.852c-134.294,0-243.161,108.867-243.161,243.161c0,134.294,108.867,243.161,243.161,243.161	c134.293,0,243.161-108.866,243.161-243.161C525.063,142.719,416.195,33.852,281.902,33.852z M245.073,401.508	c0,0-22.198,22.702-40.863,0c0,0-10.09-14.629,1.513-28.25l68.608-71.637l-101.904,0.504l4.415-4.666l148.951-149.203	c0,0,13.112-13.117,30.267,0c0,0,15.134,14.125,6.054,29.26l-73.654,75.169l102.915,1.009L245.073,401.508z"
        />
      </svg>
    );
  }
}
