import { dataActionTypes } from "src/action_types/data_action_types";

const defaultState = {
  loadState: "loading",
  data: []
};

/**
 * Reducer for population data
 */
export function populationIncidenceReducer(state = defaultState, action) {
  switch (action.type) {
    case dataActionTypes.populatePopulationIncidenceChartData:
      return {
        data: action.payload,
        loadState: "loaded"
      };

    case dataActionTypes.populationIncidenceChartDataLoading:
      return { ...state, loadState: "loading" };

    case dataActionTypes.populationIncidenceChartDataLoadError:
      return { ...state, loadState: "error" };

    default:
      return state;
  }
}
