import { createSelector } from "reselect";
import get from "lodash.get";
import uniq from "lodash.uniq";

import { createGetPayload } from "src/redux_resources_v1";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Selectors
import {
  assessmentAnswersResourceSelectors,
  assessmentSchemasResourceSelectors
} from "src/selectors/data_selectors";
import { createLatestAssessmentAnswerQueriesForAllRevisionsForFiltersSelector } from "../../assessment_answers/create_latest_assessment_answer_queries_for_all_revisions_for_filters_selector";

/**
 * Create query selector to get all queries to load all assessments
 * with schema for the latest assessment answer for a revision
 * @param {Object} props
 * @param {Object} props.revision
 * @returns {Function} selector
 */
export function createAllAssessmentsWithSchemaQueriesForRevisionFiltersSelector({
  filters
}) {
  return createSelector(
    assessmentAnswersResourceSelectors.createDataForContextSelector(
      createLatestAssessmentAnswerQueriesForAllRevisionsForFiltersSelector({
        filters
      })
    ),
    assessmentSchemasResourceSelectors.dataSelector,
    (assessmentAnswers, schemasObject) => {
      if (!assessmentAnswers || !assessmentAnswers.length) return [];

      let assessmentIds = uniq(
        assessmentAnswers.map(aa => aa.attributes.assessmentId)
      );

      assessmentIds = assessmentIds.reduce((agg, id) => {
        agg.push(id);

        const schema = Object.values(schemasObject).find(
          schema => schema.attributes.assessmentId == id
        );

        const components = Object.values(
          get(schema, "attributes.schemaJson.components", {})
        );
        if (components.length) {
          agg = agg.concat(components);
        }

        return agg;
      }, []);

      const value = assessmentIds.map(id => {
        return buildQueryString(
          createGetPayload({
            filter: {
              id
            },
            include: ["assessment_schema"]
          })
        );
      });

      return value;
    }
  );
}
