import React from "react";
import get from "lodash.get";
import { Link } from "react-router-dom";

// Constants
import { assessmentTypes } from "src/constants";

/**
 * cell formatter for assessment type
 * @param {Object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.type the assessment type
 * @param {Object} row.attributes.answersJson
 * @param {string} row.attributes.series_id
 * @param {string} row.attributes.revision_id
 */
export const woundEvaluationHyperlinkFormatter = (
  value,
  row,
  formatExtraData
) => {
  const { patientLinksEnabled } = formatExtraData;
  const patientId = get(row, "attributes.patientId");
  const type =
    get(row, "attributes.type") || get(row, "attributes.evaluationType");

  if (!type) return "";

  if (type == assessmentTypes.wound) {
    const seriesId =
      get(row, "attributes.seriesId") ||
      get(row, "attributes.resource.seriesId");
    if (patientId && seriesId && patientLinksEnabled) {
      return (
        <Link to={`/patients/${patientId}/series/${seriesId}`}>{value}</Link>
      );
    }
  } else {
    const assessmentId =
      get(row, "attributes.assessmentId") ||
      get(row, "attributes.resource.assessmentId");

    if (patientId && assessmentId && patientLinksEnabled) {
      return (
        <Link to={`/patients/${patientId}/assessments/${assessmentId}`}>
          {value}
        </Link>
      );
    }
  }

  return value;
};
