import { createSelector } from "reselect";

// Selectors
import { createAllPagesEventsForRevisionIdQuerySelector } from "src/selectors/query_selectors/events_for_revision_id";

/**
 * create a selector to get all the contexts for loading events with revision id
 * @param {Object} props
 * @param {string} props.revisionId
 * @returns {function} selector
 */
export function createAllPagesEventsForRevisionIdContextSelector({
  revisionId
}) {
  return createSelector(
    createAllPagesEventsForRevisionIdQuerySelector({ revisionId }),
    queries => {
      return queries;
    }
  );
}
