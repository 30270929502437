import React, { useState, useEffect } from "react";
import axios from "axios";
import get from "lodash.get";
// Styles
import styles from "./style.module.less";

export function ThermalMetaDataComponent(props) {
  const { thermalMetaData, imageType, userId } = props;
  const control = get(thermalMetaData, "control", "N/A"),
    location = get(thermalMetaData, "location", "N/A"),
    notes = get(thermalMetaData, "notes", "N/A");
  const [user, setUser] = useState([]);
  const url = `/api/v4/users/${userId}`;

  let firstName = get(user, "attributes.profile.first_name", ""),
    lastName = get(user, "attributes.profile.last_name", "");

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(url);
      setUser(result.data.data);
    };
    fetchData();
  }, []);

  return (
    <div className={styles["metadata-container"]}>
      <b>Image Taken By:</b> {firstName + " " + lastName}
      <br />
      <b>Image Type:</b> {imageType}
      <br />
      <b>Location:</b> {location}
      <br />
      <b>Control:</b> {control.toString()}
      <br />
      <b>Notes:</b> {notes}
    </div>
  );
}
