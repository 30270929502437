import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { SavedFilterModalContentComponent } from "src/components/saved_filter_modal_content_component";

function EditSavedFilterModal({ visible, onCancel, onSubmit, savedFilter }) {
  return (
    <Modal show={visible} bsSize={"lg"}>
      <ModalBody>
        <SavedFilterModalContentComponent
          cancel={onCancel}
          onSubmit={onSubmit}
          savedFilter={savedFilter}
        />
      </ModalBody>
    </Modal>
  );
}

export default EditSavedFilterModal;
