import { combineReducers } from "redux";

//Constants
import { alertsSectionTabs } from "src/constants";

//Section Reducer Factory
import {
  createSectionFiltersReducer,
  createSectionSelectedFilterIdReducer
} from "src/ui_processors/global/reducer_creators";

//UI Processors
import SingleValue from "src/ui_processors/single_value";

// Constants
import { sections } from "src/constants";

export const evaluationListPURSReducers = combineReducers({
  filters: createSectionFiltersReducer(sections.evaluationListPURS),
  selectedFilterId: createSectionSelectedFilterIdReducer(
    sections.evaluationListPURS
  ),
  selectedTab: SingleValue.createReducer(
    "selectedTab",
    alertsSectionTabs.evaluationList
  ),
  pursEvaluationPageLimit: SingleValue.createReducer(
    "pursEvaluationPageLimit",
    50
  )
});
