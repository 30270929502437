import axios from "axios";

export const AUDIT_EVENT_TYPES = {
  CREATE: "create",
  UPDATE: "update",
  VIEW: "viewed"
};

export const AUDIT_RESOURCE_TYPES = {
  PATIENT: "patient"
};

export async function sendNewAuditEvent(
  event_type,
  user_id,
  resource_id,
  resource_type
) {
  try {
    const body = {
      events: [
        {
          source: "dashboard",
          user_id,
          event_type,
          resource_id,
          resource_type
        }
      ]
    };

    await axios.post("/api/v1a/events", body);
  } catch (err) {
    console.error(err);
  }
}
