//React
import React from "react";
import FontAwesome from "react-fontawesome";

//Lodash
import get from "lodash.get";

// Components
import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormCheckboxComponent } from "src/components/forms/form_checkbox_component";
import { FormInputOptionComponent } from "src/components/forms/form_input_option_component";
import { FormDatePickerComponent } from "src/components/forms/form_date_picker_component";
import { GridRow, GridColumn } from "src/components/grid";

// Helpers
import { formatValues } from "./format_values";
import { evaluatePredicate } from "src/models/assessment_answers";

//Styling
import styles from "./style.module.less";

//Validations
import { dateFormatMatch } from "src/validations";
import { useEffect, useState } from "react";

const questionsAllowedFutureDates = ["nextEvaluationDate", "stopDate"];
/**
 * Render answers for an assessment schema (static/not editible)
 */

const SchemaAnswersFormComponent = props => {
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const { assessmentSchema, assessmentAnswer, formValues } = props;

  const schemaJson = get(assessmentSchema, "attributes.schemaJson", null);
  const schemaJsonQuestions = get(schemaJson, "questions", []);
  const answersJson = formValues
    ? formValues
    : assessmentAnswer.attributes.answersJson;
  const formattedValues = formatValues(answersJson);
  const hiddenQuestionName = ["location"];
  const multipleEvaluationQuestion = [
    "surgicalSutureCount",
    "surgicalStapleCount"
  ];

  useEffect(() => {
    const functions = {
      setFields,
      setLoading
    };
    const attributes = {
      props,
      schemaJson,
      schemaJsonQuestions,
      formattedValues,
      hiddenQuestionName,
      multipleEvaluationQuestion,
      assessmentSchema
    };
    populateFields(functions, attributes);
  }, []);

  return (
    <div>
      <div className={styles["schema-title"]}>
        {schemaJson.localizationDictionary.base.title}
        {loading && <span className={styles["loading-text"]}> Loading...</span>}
      </div>
      <div className={styles["form-container"]}>
        <div className={styles["form-field-container"]}>
          <div>{fields}</div>
        </div>

        {loading && (
          <div className={styles["loading-overlay"]}>
            <div className={styles["loading-content"]}>
              <p className="text-center">
                <FontAwesome name="spinner" size="3x" spin />
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const populateFields = async (functions, attributes) => {
  const { setFields, setLoading } = functions;
  const {
    props,
    schemaJson,
    schemaJsonQuestions,
    formattedValues,
    hiddenQuestionName,
    multipleEvaluationQuestion,
    assessmentSchema
  } = attributes;

  const formInputs = [];
  if (!schemaJson) {
    return;
  } else {
    for (let i = 0; i < schemaJsonQuestions.length; i++) {
      const question = schemaJsonQuestions[i];
      const visiblePredicate = question.visiblePredicateJson;
      const enabledPredicate = question.enabledPredicateJson;
      const translations = get(
        assessmentSchema,
        "attributes.schemaJson.localizationDictionary.base",
        null
      );
      let visibleQuestion = visiblePredicate
        ? evaluateQuestion(props, formattedValues, visiblePredicate)
        : true;
      let enabledQuestion = enabledPredicate
        ? evaluateQuestion(props, formattedValues, enabledPredicate)
        : true;

      if (multipleEvaluationQuestion.includes(question.name)) {
        visibleQuestion =
          visibleQuestion && formattedValues.woundType == "surgical";
      }

      if (hiddenQuestionName.includes(question.name)) {
        enabledQuestion = false;
      }

      if (visibleQuestion) {
        formInputs.push(
          renderQuestionAndAnswer(
            props,
            enabledQuestion,
            question,
            formattedValues,
            translations
          )
        );
        setFields([...formInputs]);
        // the fields are processed before they have time to render.
        // Adding the await allows us to display them one at a time without needing
        // to wait for all of them to load
        await timeout(1);
      }
    }
    setLoading(false);
  }
};

function timeout(delay) {
  return new Promise(res => setTimeout(res, delay));
}

const evaluateQuestion = (props, formattedValues, predicate) => {
  const { revisionIsFirstInSeries } = props;
  return evaluatePredicate({
    answersJson: formattedValues,
    revisionIsFirstInSeries,
    predicate: predicate
  });
};

const renderQuestionInput = (props, question) => {
  const { assessmentSchema, formValues } = props;
  const { name, type, required } = question;

  const useFormValue = formValues
    ? formValues
    : props.assessmentAnswer.attributes.answersJson;

  const translations = get(
    assessmentSchema,
    "attributes.schemaJson.localizationDictionary.base",
    null
  );

  switch (type) {
    // hidden question
    case 0:
      return;

    // Boolean value
    case 1:
      const opts = [{ key: "Yes", text: "Yes", value: "1" }];
      return (
        <FormInputComponent
          alignmentOverride={true}
          name={name}
          componentType="select"
          emptyDefault={true}
          defaultOption={"Select Option"}
          labelText={translations[`${name}.title`]}
          required={required}
        >
          {opts.map(answer => {
            return (
              <FormInputOptionComponent
                key={answer.key}
                text={answer.text}
                value={answer.value}
              />
            );
          })}
        </FormInputComponent>
      );
      return;

    // Integer
    case 2:
      return (
        <FormInputComponent
          alignmentOverride={true}
          name={name}
          componentType="number"
          required={required}
          labelText={translations[`${name}.title`]}
          step={1}
        />
      );

    //float Values
    case 3:
      return (
        <FormInputComponent
          name={name}
          alignmentOverride={true}
          componentType="number"
          required={required}
          labelText={translations[`${name}.title`]}
          step={0.01}
        />
      );

    // String
    case 4:
      return (
        <FormInputComponent
          alignmentOverride={true}
          name={name}
          componentType="text"
          required={required}
          labelText={translations[`${name}.title`]}
          useMultiLine={true}
        />
      );

    // date value
    case 5:
      return (
        <FormDatePickerComponent
          name={name}
          alignmentOverride={true}
          component="input"
          componentType="date"
          required={required}
          placeholder="YYYY-MM-DD"
          value={useFormValue[name] ? new Date(useFormValue[name]) : null}
          maxDate={
            questionsAllowedFutureDates.includes(name) ? null : new Date()
          }
          validate={dateFormatMatch}
          labelText={translations[`${name}.title`]}
        />
      );

    // single select list (enumeration)
    case 6:
      const opt = question.enumConstants;
      return (
        <FormInputComponent
          name={name}
          alignmentOverride={true}
          componentType="select"
          emptyDefault={true}
          defaultOption={"Select Option"}
          required={required}
          labelText={translations[`${name}.title`]}
        >
          {opt.map(answer => {
            return (
              <FormInputOptionComponent
                key={answer}
                text={translations[`${name}.${answer}`]}
                value={answer}
              />
            );
          })}
        </FormInputComponent>
      );

    // Multi select list (flags)
    case 7:
      return (
        <div>
          <label className={styles["label-text"]}>
            {translations[`${name}.title`]}{" "}
            {required && <span className={styles["required-field"]}>*</span>}
          </label>
          <div className={styles["checkbox-style"]}>
            {question.enumConstants.map(answer => {
              return (
                <FormCheckboxComponent
                  component="input"
                  componentType="checkbox"
                  key={answer}
                  name={`${name}.${answer}`}
                  value={answer}
                  labelText={translations[`${name}.${answer}`]}
                  checked={useFormValue[name] && useFormValue[name][answer]}
                />
              );
            })}
          </div>
        </div>
      );
  }
};

const renderViewOnlyField = (name, answer, required) => {
  return (
    <GridRow className={styles["margin-bottom5px"]}>
      <GridColumn sm="3">
        <label className={styles["label-text"]}>
          {name}{" "}
          {required && <span className={styles["required-field"]}>*</span>}
        </label>
      </GridColumn>
      <GridColumn sm="9">
        <p className={styles["view-only-text"]}>{answer}</p>
      </GridColumn>
    </GridRow>
  );
};

const renderQuestionAndAnswer = (
  props,
  enabledQuestion,
  question,
  formattedValues,
  translations
) => {
  if (enabledQuestion) {
    return renderQuestionInput(props, question);
  } else {
    let yesOrNoAnswer;
    if (question.type == 1) {
      yesOrNoAnswer =
        formattedValues[question.name] === "0" ? "Not Set" : "Yes";
    }
    const name = translations[`${question.name}.title`];
    const answer =
      translations[`${question.name}.${formattedValues[question.name]}`] ||
      yesOrNoAnswer ||
      "Not Set";
    const required = question.required;
    return renderViewOnlyField(name, answer, required);
  }
};

export default SchemaAnswersFormComponent;
