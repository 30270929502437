import moment from "moment";

/**
 * Get graph images
 * @param {string} dataUrl the image url
 * @return {Object}
 */
export function getChartImage(dataUrl, width = 200) {
  return {
    stack: [
      {
        image: dataUrl,
        width: width
      },
      {
        text: `As of ${new moment().format("MMM D, YYYY")}`,
        fontSize: 10,
        alignment: "left",
        margin: [60, 0, 0, 0]
      }
    ]
  };
}
