import { camelizeKeys } from "humps";

// Action creators
import { createPopulateActionCreator } from "../create_populate_action_creator";
import { createSetLoadStateActionCreator } from "../create_set_load_state_action_creator";

// Load states
import { loadStates } from "../../load_states";

// request methods
import { requestMethods } from "../../request_methods";

/**
 *
 * @param {Object} resourceConfig - ResourceManager instance
 * @param {class} resourceConfig.modelClass
 * @param {string} resourceConfig.resourceName
 * @param {function} resourceConfig.normalizer
 * @param {function} resourceConfig.onApiActionError
 *
 * @param {Object} globalConfig - Global Config of Redux Resources
 * @param {function} globalConfig.normalizer
 * @param {function} globalConfig.onApiActionError
 */
export function createApiGetActionCreator(resourceConfig, globalConfig) {
  /**
   * Action creator for making a GET request for this resource
   * @param {Object} queryObject - the GET request query object
   * @param {Object} page        - page object
   */
  return function createApiGetAction(queryObject, page) {
    // TODO: optimization
    // - load and populate actions should be created once for each resource name at setup
    // - currently they'll be recreated every reload
    const setLoadStateAction = createSetLoadStateActionCreator(
      resourceConfig.resourceName
    );

    return dispatch => {
      // Set load state
      dispatch(
        setLoadStateAction(
          loadStates.loading,
          requestMethods.get,
          queryObject,
          page
        )
      );

      // Fetch data
      return resourceConfig.api
        .get(queryObject, page, resourceConfig) // resource config object can be used to control the api wrapper
        .then(response => {
          // Normalize response
          let normalizedData;
          if (resourceConfig.normalizer) {
            normalizedData = resourceConfig.normalizer(response);
          } else if (globalConfig.normalizer) {
            normalizedData = globalConfig.normalizer(response);
          } else {
            throw new Error("response normalizer not defined");
          }

          // TODO - add support for included custom classes
          if (
            resourceConfig.ResourceClass &&
            normalizedData[resourceConfig.resourceName]
          ) {
            normalizedData[resourceConfig.resourceName] = Object.keys(
              normalizedData[resourceConfig.resourceName]
            ).reduce((agg, key) => {
              let resource = new resourceConfig.ResourceClass();
              resource = {
                ...resource,
                ...normalizedData[resourceConfig.resourceName][key]
              };

              agg[key] = resource;
              return agg;
            }, {});
          }

          // Populate "included" resources
          if (resourceConfig.includedResourceNames) {
            resourceConfig.includedResourceNames.forEach(
              includedResourceName => {
                if (normalizedData[includedResourceName]) {
                  const populateIncludedAction = createPopulateActionCreator(
                    includedResourceName
                  );
                  dispatch(
                    populateIncludedAction({
                      data: normalizedData[includedResourceName]
                    })
                  );
                }
              }
            );
          }

          // Populate resource
          const populateAction = createPopulateActionCreator(
            resourceConfig.resourceName
          );
          if (normalizedData[resourceConfig.resourceName]) {
            dispatch(
              populateAction({
                data: normalizedData[resourceConfig.resourceName],
                links: response.data.links,
                meta: camelizeKeys(response.data.meta),
                method: requestMethods.get,
                queryObject,
                page
              })
            );
          } else {
            dispatch(
              populateAction({
                data: {},
                links: response.data.links,
                meta: camelizeKeys(response.data.meta),
                method: requestMethods.get,
                queryObject,
                page
              })
            );
          }

          return response;
        })
        .catch(err => {
          dispatch(
            setLoadStateAction(
              loadStates.error,
              requestMethods.get,
              queryObject,
              page
            )
          );

          // Error hooks
          if (resourceConfig.onApiActionError) {
            resourceConfig.onApiActionError(err, {
              resourceName: resourceConfig.resourceName,
              requestMethod: requestMethods.get,
              queryObject,
              page
            });
          } else if (globalConfig.onApiActionError) {
            globalConfig.onApiActionError(err, {
              resourceName: resourceConfig.resourceName,
              requestMethod: requestMethods.get,
              queryObject,
              page
            });
          }

          throw err;
        });
    };
  };
}
