import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import snakecase from "lodash.snakecase";
import i18n from "src/translations";

// Components
import { TableComponent } from "src/components/tables/table_component";
import { FormTitleComponent } from "src/components/forms/form_title_component";
import { FormComponent } from "src/components/forms/form_component_legacy";
import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormInputOptionComponent } from "src/components/forms/form_input_option_component";
import { Button } from "src/ui-kit";

import {
  permissionSets,
  permissionSetsAssessmentState,
  permissionTypeAccess
} from "src/constants/permissions_type";
import { modalModes } from "src/constants";
import {
  permissionOptions,
  permissionOptionsAssessmentState
} from "src/mappings";

// Validations
import { required } from "src/validations";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

import styles from "./style.module.less";

export class RoleFormComponent extends React.PureComponent {
  static propTypes = {
    role: PropTypes.object,
    sendUpdateRoleAction: PropTypes.func,
    sendCreateRoleAction: PropTypes.func,
    permissionTypes: PropTypes.object.isRequired,
    cancel: PropTypes.func
  };

  state = {
    initialValues: {}
  };

  constructor(props) {
    super(props);
    const { role, mode, permissionTypes } = props;

    let initialRoleObject;

    if (mode === modalModes.edit) {
      initialRoleObject = this.getInitialValuesForRole(role);
    } else if (mode === modalModes.view) {
      initialRoleObject = this.getInitialValuesForRoleViewOnly(role);
    } else if (mode === modalModes.create) {
      const permissions = Object.values(permissionTypes).map(permissionType => {
        return {
          [permissionType.attributes.internalName]:
            permissionType.attributes.defaultValue
        };
      });
      initialRoleObject = { ...permissions };
    }

    this.state = {
      initialValues: initialRoleObject
    };
  }

  getInitialValuesForRoleViewOnly(role) {
    if (!role || !role.id) return;

    const locationInfo = role.attributes.location
      ? this.getLocationDisplayInfo(role.attributes.location)
      : null;
    let initialRoleObject = {
      id: role.id,
      roleName: get(role, "attributes.primaryName"),
      roleSecondaryName: get(role, "attributes.secondaryName"),
      facility: locationInfo ? locationInfo.facility : null,
      organization: locationInfo ? locationInfo.organization : null
    };

    const permissions = this.getInitialRolePermissionValues(role);

    initialRoleObject = Object.assign(initialRoleObject, ...permissions);

    return initialRoleObject;
  }

  getInitialValuesForRole(role) {
    const { allFacilities } = this.props;

    let initialRoleObject = {};
    if (role && role.id) {
      initialRoleObject.roleName = role.attributes.primaryName;
      initialRoleObject.roleSecondaryName = role.attributes.secondaryName;
      initialRoleObject.id = role.id;
      if (role.attributes.location[1] == "Organization") {
        initialRoleObject.organization = role.attributes.location[0];
      } else {
        const facility = allFacilities.find(
          o => o.id == role.attributes.location[0]
        );

        initialRoleObject.organization = facility
          ? facility.attributes.organizationId
          : "";
        initialRoleObject.facility = role.attributes.location[0];
      }

      const permissions = this.getInitialRolePermissionValues(role);

      initialRoleObject = Object.assign(initialRoleObject, ...permissions);
    }

    return initialRoleObject;
  }

  getInitialRolePermissionValues = role => {
    const { permissionTypes, mode } = this.props;

    const permissions = Object.values(permissionTypes).map(permissionType => {
      const rolePermissionType = Object.values(
        role.attributes.rolePermissionTypes
      ).find(o => o.permissionTypeId == permissionType.id);

      let value = rolePermissionType
        ? rolePermissionType.permissionValue
        : get(permissionType, "attributes.defaultValue", "");

      if (mode === modalModes.view) {
        value = this.formatAccessText(value);
      } else {
        if (
          value === permissionTypeAccess.true ||
          value === permissionTypeAccess.false
        ) {
          value = value === permissionTypeAccess.true;
        }
      }

      return {
        [permissionType.attributes.internalName]: value
      };
    });

    return permissions;
  };

  componentWillReceiveProps(nextProps) {
    const { mode, clearFields, changeField } = this.props;

    if (mode === modalModes.view) return;

    if (mode != modalModes.edit) {
      if (propsAreDifferent(nextProps, this.props, "organizations")) {
        if (nextProps.organizations.length) {
          changeField("organization", nextProps.organizations[0].id);
        } else {
          clearFields("organization");
        }
      }
    }
    if (propsAreDifferent(nextProps, this.props, "role")) {
      this.setState({
        initialValues: this.getInitialValuesForRole(nextProps.role)
      });
    }

    if (
      this.props.createLoadState === "loading" &&
      nextProps.createLoadState === "ready"
    ) {
      clearFields("roleName");
    }
  }

  mapPermissions = permissionTypes => {
    const visiblePermissions = Object.values(permissionTypes).filter(
      permissionType => {
        return permissionType.attributes.hidden === false;
      }
    );

    const result = visiblePermissions.map(permissionType => {
      const platform = get(permissionType, "attributes.platforms", []);
      return {
        id: permissionType.id,
        name: get(permissionType, "attributes.name", ""),
        dashboard: platform.includes("dashboard"),
        app: platform.includes("mobile"),
        internalName: get(permissionType, "attributes.internalName", ""),
        valueType: get(permissionType, "attributes.valueType", ""),
        allowedValues: get(permissionType, "attributes.allowedValues", []),
        description: get(permissionType, "attributes.description", null)
      };
    });
    return result;
  };

  roleInfoFormatter = (e, row) => {
    return (
      <p className={styles["no-margin"]}>
        <span className={styles["role-name"]}>{row.name}</span>
        <br />
        {row.description && (
          <span className={styles["role-description"]}>{row.description}</span>
        )}
      </p>
    );
  };

  deviceAvailabilityFormatter = e => {
    return (
      <div className={styles["font-grey"]}>
        {e && <span className="fa fa-circle" />}
        {!e && <span>--</span>}
      </div>
    );
  };

  accessFormatter = (e, row) => {
    const { mode } = this.props;
    const isViewOnly = mode === modalModes.view;

    const permissionType = row;
    if (permissionType.valueType === "Boolean") {
      return (
        // <VerticalCenterComponent>
        <FormInputComponent
          name={permissionType.internalName}
          component="input"
          componentType="toggle"
          key={permissionType.id}
          viewOnly={isViewOnly}
          id={permissionType.id}
        />
        // </VerticalCenterComponent>
      );
    }
    let permissionOptionsView = "";
    if (permissionType.allowedValues.includes("read")) {
      permissionOptionsView = permissionSets.map(permissionSet => {
        return (
          <FormInputOptionComponent
            text={permissionOptions[permissionSet]}
            value={permissionSet}
            key={permissionType.id + permissionSet}
          />
        );
      });
    } else if (permissionType.allowedValues.includes("unlocked")) {
      permissionOptionsView = permissionSetsAssessmentState.map(
        permissionSetAssessmentState => {
          return (
            <FormInputOptionComponent
              text={
                permissionOptionsAssessmentState[permissionSetAssessmentState]
              }
              value={permissionSetAssessmentState}
              key={permissionType.id + permissionSetAssessmentState}
            />
          );
        }
      );
    } else {
      permissionOptionsView = permissionType.allowedValues.map(
        permissionSet => {
          return (
            <FormInputOptionComponent
              text={permissionSet}
              value={permissionSet}
              key={permissionType.id + permissionSet}
            />
          );
        }
      );
    }

    return (
      <FormInputComponent
        name={permissionType.internalName}
        component="input"
        componentType="select"
        key={permissionType.id}
        viewOnly={isViewOnly}
      >
        {permissionOptionsView}
      </FormInputComponent>
    );
  };

  formatAccessText = value => {
    let words = value.split(",");
    words = words.map(word => {
      switch (word) {
        case permissionTypeAccess.true:
          return ["Yes"];
        case permissionTypeAccess.false:
          return ["No"];
        case permissionTypeAccess.add: {
          return ["Create"];
        }
        default: {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
      }
    });

    let text;

    if (words.length > 1) {
      text =
        words.splice(0, words.length - 1).join(", ") +
        " and " +
        words[words.length - 1];
    } else {
      text = words;
    }

    return text;
  };

  getLocationDisplayInfo = location => {
    const { organizations, allFacilities } = this.props;
    let organizationId, facility, organization;
    if (location[1] === "Organization") {
      organizationId = location[0];
    } else {
      facility = allFacilities.find(facility => {
        return facility.id === location[0];
      });
      organizationId = get(facility, "attributes.organizationId", "");
    }

    organization = organizations.find(org => {
      return org.id === organizationId;
    });

    return {
      organization: get(organization, "attributes.name", ""),
      facility: facility
        ? get(facility, "attributes.name", "")
        : "All Facilities"
    };
  };

  renderFormTitle = () => {
    const { mode } = this.props;

    const type = "Role";
    let title = i18n.t(`headers.${mode}`);
    title =
      mode === modalModes.view
        ? type.concat(` ${title}`)
        : title.concat(` ${type}`);

    return (
      <FormTitleComponent>
        <h4>{title}</h4>
      </FormTitleComponent>
    );
  };

  renderGeneralSection = () => {
    const { mode, organizations, facilities, role } = this.props;
    const isViewOnly = mode === modalModes.view;

    let isRole = false;

    if (role) {
      isRole = role.type === "roles";
    }

    return (
      <div>
        <FormInputComponent
          name="roleName"
          component="input"
          componentType="text"
          placeholder="Name"
          labelText="Name"
          viewOnly={isViewOnly}
          validate={[required]}
        />
        <FormInputComponent
          name="roleSecondaryName"
          component="input"
          componentType="text"
          placeholder="optional"
          labelText="Secondary Name"
          viewOnly={isViewOnly}
          validate={[]}
        />
        {!isRole && (
          <div>
            <FormInputComponent
              name="organization"
              component="input"
              componentType="select"
              labelText="Organization"
              viewOnly={isViewOnly}
            >
              {!isViewOnly &&
                Object.values(organizations).map(organization => {
                  return (
                    <FormInputOptionComponent
                      key={organization.id}
                      text={get(organization, "attributes.name", "")}
                      value={organization.id}
                    />
                  );
                })}
            </FormInputComponent>
            <FormInputComponent
              name="facility"
              component="input"
              componentType="select"
              labelText="Facility"
              viewOnly={isViewOnly}
            >
              <FormInputOptionComponent key="all" text="All" value="all" />
              {!isViewOnly &&
                facilities.map(facility => {
                  return (
                    <FormInputOptionComponent
                      key={facility.id}
                      text={get(facility, "attributes.name", "")}
                      value={facility.id}
                    />
                  );
                })}
            </FormInputComponent>
          </div>
        )}
      </div>
    );
  };

  renderPermissionsTable = () => {
    const { permissionTypes } = this.props;
    const permissionsData = this.mapPermissions(permissionTypes);

    const permissionColumns = [
      {
        dataField: "name",
        text: "Name",
        formatter: this.roleInfoFormatter,
        headerStyle: () => {
          return {
            width: "50%"
          };
        }
      },
      {
        dataField: "dashboard",
        text: "Dashboard",
        formatter: this.deviceAvailabilityFormatter,
        align: "center",
        headerAlign: "center"
      },
      {
        dataField: "app",
        text: "App",
        formatter: this.deviceAvailabilityFormatter,
        align: "center",
        headerAlign: "center"
      },
      {
        dataField: "hidden",
        text: "Access",
        formatter: this.accessFormatter,
        align: "right",
        headerAlign: "center",
        headerStyle: () => {
          return {
            width: "30%"
          };
        }
      }
    ];

    return (
      <div className={styles["table-rows-middle-vertical-align"]}>
        <TableComponent
          data={permissionsData}
          keyField="id"
          columns={permissionColumns}
        />
      </div>
    );
  };

  onSubmit = () => {
    const {
      permissionTypes,
      sendCreateRoleAction,
      sendUpdateRoleAction,
      formValues,
      organizations,
      cancel
    } = this.props;

    const values = formValues;

    // this code will get the values from the permissionTypes and create the initialize array for the
    //rolePermissionTypes
    let rolePermissionTypes = Object.values(permissionTypes).map(
      permissionType => {
        return {
          id: permissionType.id,
          value: permissionType.attributes.defaultValue
        };
      }
    );
    // this code block will be used if no organization and faclity selected
    if (!values.organization && !values.facility) {
      values.organization = organizations[0].id;
      values.facility = "all";
    }
    // this code get the form value and update the values in the rolePermissionTypes array
    Object.keys(values).forEach(formObject => {
      const permissionType = Object.values(permissionTypes).find(
        p => p.attributes.internalName == formObject
      );
      if (permissionType) {
        let value;
        if (typeof values[formObject] === "boolean") {
          value =
            values[formObject] === true
              ? permissionTypeAccess.true
              : permissionTypeAccess.false;
        } else {
          value = values[formObject];
        }
        rolePermissionTypes.find(o => o.id === permissionType.id).value = value;
      }
    });

    // created the payload for the create and update actions
    const payload = {
      data: {
        type: "custom_roles",
        attributes: {
          primary_name: values.roleName,
          secondary_name: values.roleSecondaryName || values.roleName,
          name: snakecase(values.roleName),
          role_permission_types: rolePermissionTypes,
          location: {
            type:
              !values.facility || values.facility == "all"
                ? "Organization"
                : "Facility",
            id:
              !values.facility || values.facility == "all"
                ? values.organization
                : values.facility
          }
        },
        id: values.id
      }
    };

    const action = values.id ? sendUpdateRoleAction : sendCreateRoleAction;
    action(payload).then(() => cancel());
  };

  render() {
    const { formName, mode, pristine, submitting } = this.props;
    return (
      <div className={styles["style-buttons"]}>
        {this.renderFormTitle()}
        <FormComponent
          form={formName}
          initialValues={this.state.initialValues}
          onSubmit={this.onSubmit}
        >
          {this.renderGeneralSection()}

          <h3 className={styles["section-name"]}>Permissions</h3>
          <div className={styles["divisor"]} />
          {this.renderPermissionsTable()}
          {mode !== modalModes.view && (
            <div className="pull-right">
              <Button
                type="submit"
                disabled={pristine || submitting}
                className="btn dashboard-btn"
                subtype="submit"
              >
                Save
              </Button>
              <Button
                type="button"
                disabled={submitting}
                className="btn btn-default"
                subtype="button"
                onClick={this.props.cancel}
              >
                Cancel
              </Button>
            </div>
          )}
        </FormComponent>
      </div>
    );
  }
}
