import { adminActionTypes } from "src/action_types/section_action_types";

/**
 * Action to select patient table page limit
 * @param {number} pageLimit selected table page number
 * @return {Object} Action
 */

export function selectPatientTablePageLimitAction(pageLimit) {
  return {
    type: adminActionTypes.selectPatientTablePageLimit,
    payload: pageLimit
  };
}
