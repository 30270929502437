// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";

type $props = {
  className?: string,
  name: string
};

export default class Glyph extends PureComponent {
  props: $props;
  render() {
    const { className, name } = this.props;
    const klass = classnames("glyphicon", `glyphicon-${name}`, {
      [className || ""]: !!className
    });
    return <i className={klass} />;
  }
}
