import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import moment from "moment";

import { GridRow, GridColumn } from "src/components/grid";

import { ThermalImageComponent } from "src/components/thermal_image_component";
import { ThermalMetaDataComponent } from "src/components/thermal_meta_data_component";

// Styles
import styles from "./style.module.less";

export class ThermalAssessmentViewComponent extends React.PureComponent {
  static propTypes = {
    thermalAssessment: PropTypes.object.isRequired
  };

  renderAssessmentCard(image) {
    const { thermalAssessment } = this.props;
    let createdAt = get(
        thermalAssessment,
        "attributes.manifest.header.created_at",
        "N/A"
      ),
      link = get(image, "download_link", ""),
      thermalMetaData = get(thermalAssessment, "attributes.metadata", {}),
      imageType = get(image, "image_type", "N/A"),
      userId = get(
        thermalAssessment,
        "attributes.manifest.identifiers.user_id",
        ""
      );
    return (
      <div className={styles["card-container"]}>
        <div className={styles["header-container"]}>
          <h2>{moment(createdAt).format("MMM D, YYYY HH:mm")}</h2>
        </div>
        <ThermalImageComponent thermalImageLink={link} />
        <ThermalMetaDataComponent
          userId={userId}
          thermalMetaData={thermalMetaData}
          imageType={imageType}
        />
      </div>
    );
  }

  render() {
    const { thermalAssessment } = this.props;

    let images = get(thermalAssessment, "attributes.images", "");

    let originalImage = images.find(image => image.image_type == "original"),
      thermalImage = images.find(image => image.image_type == "thermal"),
      gradientImage = images.find(image => image.image_type == "gradient");

    return (
      <GridRow>
        <GridColumn xs="4">
          {this.renderAssessmentCard(originalImage)}
        </GridColumn>
        <GridColumn xs="4">
          {this.renderAssessmentCard(thermalImage)}
        </GridColumn>
        <GridColumn xs="4">
          {this.renderAssessmentCard(gradientImage)}
        </GridColumn>
      </GridRow>
    );
  }
}
