import React from "react";

/**
 * React Component that will surround its children with a collapsible container
 */
export class PanelBodyComponent extends React.Component {
  static displayName = "PanelBodyComponent";

  render() {
    return <div>{this.props.children}</div>;
  }
}
