import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash.get";
import { Link } from "react-router-dom";
import { Button } from "src/ui-kit";
import { Modal, ModalBody } from "react-bootstrap";
import { Glyphicon } from "src/components/ui_kit";
import { cellFormatters } from "../cell_formatters";

// Containers
import {
  PatientFormContainer,
  PatientAdtFormContainer
} from "src/containers/forms";

// Components
import { TableComponent } from "../table_component";
import { TableNavigatorComponent } from "../table_navigator_component";
import { TablePageLimitDropdownComponent } from "../table_page_limit_dropdown_component";

// Utils
import { capitalizeFirstLetter } from "src/utils/capitalize_first_letter";

export class AdminPatientTableComponent extends React.PureComponent {
  static propTypes = {
    patientsAndUsers: PropTypes.array.isRequired,
    selectedPatientTablePageNumber: PropTypes.number.isRequired,
    selectedPatientTablePageLimit: PropTypes.number.isRequired,
    patientTablePageCount: PropTypes.number.isRequired,
    patientTableRecordCount: PropTypes.number.isRequired,
    selectPatientTablePage: PropTypes.func.isRequired,
    selectPatientTablePageLimit: PropTypes.func.isRequired,
    editPatientEnabled: PropTypes.bool.isRequired,
    adtEnabled: PropTypes.bool.isRequired,
    resendWelcomeMessage: PropTypes.func.isRequired,
    patientAppEnabled: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired
  };

  state = {
    editPatient: undefined,
    showPatientEditModal: false,
    adtPatient: undefined,
    showADTModal: false
  };

  buttonFormatter = (cell, row) => {
    if (this.props.editPatientEnabled) {
      return (
        <Button
          type="button"
          onClick={e => this.onClickEdit(e, row)}
          className="btn-default"
          subtype="button"
        >
          <Glyphicon glyph="pencil" className="margin-right5px" />
          Edit
        </Button>
      );
    } else {
      return <p> -- </p>;
    }
  };

  adtButtonFormatter = (cell, row) => {
    if (this.props.adtEnabled) {
      return (
        <Button
          type="button"
          onClick={e => this.onClickAdt(e, row)}
          className="btn-default"
          subtype="button"
        >
          ADT
        </Button>
      );
    } else {
      return <p> -- </p>;
    }
  };

  birthDateFormatter = (cell, row) => {
    return row.attributes.birthAt
      ? moment(row.attributes.birthAt)
          .utcOffset(0)
          .format("YYYY-MM-DD")
      : null;
  };

  genderStringFormatter = (cell, row) => {
    let genderString = get({ row }, "row.attributes.gender", "");
    if (genderString) {
      genderString = capitalizeFirstLetter(row.attributes.gender);
    }
    return genderString;
  };

  statusFormatter = (cell, row) => {
    let statusString = get({ row }, "row.attributes.state", "");
    if (statusString) {
      statusString = capitalizeFirstLetter(row.attributes.state);
    }
    return statusString;
  };

  atHomeStatusFormatter = (cell, row) => {
    const { currentUser } = this.props;
    let status;
    let patientUserId = get(row, "attributes.patientUserId", "");
    if (patientUserId) {
      if (row.attributes.monitorAtHomeStatus === "locked") {
        status = "Locked";
      } else {
        status =
          row.attributes.monitorAtHomeStatus === "accepted"
            ? "Accepted"
            : "Invited";
      }
    } else {
      status = "Not Invited";
    }
    const patientId = get(row, "id", "");

    return (
      <div>
        <b>
          {status}{" "}
          {patientUserId && (
            <Link
              to={location.pathname}
              onClick={this.props.resendWelcomeMessage.bind(
                this,
                patientUserId,
                patientId,
                currentUser.id
              )}
              className="pull-right"
            >
              (Re-send)
            </Link>
          )}
        </b>
      </div>
    );
  };

  onClickEdit = (e, row) => {
    this.setState({
      ...this.state,
      editPatient: row,
      showPatientEditModal: true
    });
  };

  hidePatientEditModal = () => {
    this.setState({
      ...this.state,
      showPatientEditModal: false
    });
  };

  hideADTModal = () => {
    this.setState({
      ...this.state,
      showADTModal: false
    });
  };

  onClickAdt = (e, row) => {
    this.setState({
      ...this.state,
      adtPatient: row,
      showADTModal: true
    });
  };

  render() {
    const {
      selectedPatientTablePageNumber,
      patientTablePageCount,
      selectPatientTablePage,
      selectPatientTablePageLimit,
      selectedPatientTablePageLimit,
      patientAppEnabled
    } = this.props;
    const patientsData = Object.values(this.props.patientsAndUsers);
    const columns = [
      {
        dataField: "attributes.lastName",
        text: "Last Name",
        formatter: cellFormatters.patientLastNameFormatter
      },
      {
        dataField: "attributes.firstName",
        text: "First Name",
        formatter: cellFormatters.patientFirstNameFormatter
      },
      {
        dataField: "attributes.birthAt",
        text: "DOB",
        formatter: this.birthDateFormatter
      },
      {
        dataField: "attributes.gender",
        text: "Gender",
        formatter: this.genderStringFormatter
      },
      {
        dataField: "attributes.phoneNumber",
        text: "Phone Number"
      },
      {
        dataField: "attributes.mrn",
        text: "MRN"
      },
      {
        dataField: "attributes.shareToken",
        text: "Swift Code"
      },
      {
        dataField: "attributes.state",
        text: "Status",
        formatter: this.statusFormatter
      },
      {
        dataField: "Monitor at Home Status",
        formatter: this.atHomeStatusFormatter,
        text: "Monitor at Home Status",
        hidden: !patientAppEnabled
      },
      {
        dataField: "ADT",
        formatter: this.adtButtonFormatter,
        text: "ADT"
      },
      {
        dataField: "id",
        formatter: this.buttonFormatter,
        text: "Action"
      }
    ];

    const filteredColumns = columns.filter(col => {
      return !col.hidden;
    });

    return (
      <div>
        <TableComponent
          data={patientsData}
          keyField="id"
          columns={filteredColumns}
        />
        <div className="pull-left">
          <TablePageLimitDropdownComponent
            selectPageLimit={selectPatientTablePageLimit}
            selectedPageLimit={selectedPatientTablePageLimit}
          />
        </div>
        <TableNavigatorComponent
          pageNumber={selectedPatientTablePageNumber}
          pageCount={patientTablePageCount}
          selectTablePage={selectPatientTablePage}
        />

        <Modal
          show={this.state.showPatientEditModal}
          onHide={this.hidePatientEditModal}
          bsSize={"lg"}
        >
          <ModalBody>
            <PatientFormContainer
              cancel={this.hidePatientEditModal}
              patient={this.state.editPatient}
            />
          </ModalBody>
        </Modal>

        <Modal
          show={this.state.showADTModal}
          onHide={this.hideADTModal}
          bsSize={"lg"}
        >
          <ModalBody>
            <PatientAdtFormContainer
              patient={this.state.adtPatient}
              cancel={this.hideADTModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
