export const ruleTemplateNamesByName = {
  "Wound acquired in house": "Wound acquired in house",
  "Evaluation saved and acquired not set":
    "Evaluation saved and acquired not set",
  "PURS score is in between": "PURS score range",
  "Evaluation saved and location not set":
    "Evaluation saved and location not set",
  "Evaluation saved and wound type not set":
    "Evaluation saved and wound type not set",
  "Braden score is in between": "Braden score range",
  "Evaluation saved and progress not set":
    "Evaluation saved and progress not set",
  "Evaluation saved and wound age not set":
    "Evaluation saved and wound age not set",
  "Norton score is in between": "Norton score range"
};
