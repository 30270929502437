// React
import PropTypes from "prop-types";

import React, { Component } from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import i18n from "src/translations";
import { trackError } from "src/error_tracking";

// Containers
import { SurveyEtiologyContainer } from "../../containers/survey_etiology_container";
import { SurveyLocationsContainer } from "../../containers/survey_locations_container";

// Components
import { Button, Icon, DatePickerRange, ButtonGroup } from "../../ui-kit";

// Analytics
import { track } from "src/analytics";

// Styles
import styles from "./style.module.less";
import pdf from "./pdf.png"; // TODO: this should be an svg component
import csv from "./csv.png"; // TODO: this should be an svg component

import pdfMake from "pdfmake/build/pdfmake";

import { createSurveyEtiologyPdf, createSurveyLocationPdf } from "src/pdfs";

// Utils
import {
  getImageDataUrlFromLocalPath,
  renderChartToImageDataUrl
} from "src/utils/image_data_url";

export class SurveyComponent extends Component {
  static propTypes = {
    selectedLocationIds: PropTypes.array.isRequired,
    dateSelection: PropTypes.object.isRequired,
    surveyTallyExportLoadState: PropTypes.string.isRequired,
    selectedOrganization: PropTypes.object,
    setDateSelection: PropTypes.func.isRequired,
    createAndDownloadCsv: PropTypes.func.isRequired,
    branding: PropTypes.string,
    brandedLogoPng: PropTypes.object.isRequired,
    subtypeKeysForSchema: PropTypes.object.isRequired
  };

  handleDatePickerChange = dateData => {
    // Using a bulk action to update the entire date state
    // TODO: These should be split up to individual actions,
    // the date range selector shouldn't be responsible for setting the dates
    // when the range filter changes (ex. month to date). Should be handled by
    // the reducer.
    this.props.setDateSelection(dateData);
  };

  /**
   * Event handler for the csv download
   */
  onDownloadCsvButtonClicked = () => {
    const { surveyTallyExportLoadState, selectedLocationIds } = this.props;

    track("SURVEY_EXPORT_CSV_CLICKED");

    // TODO: Make this dependent on load state of locations instead of checking length
    if (surveyTallyExportLoadState == "ready" && selectedLocationIds.length) {
      this.props.createAndDownloadCsv();
    }
  };

  getChartUrl(chartDefinition) {
    return renderChartToImageDataUrl(chartDefinition, 600, 450);
  }

  /**
   * Event handler for the pdf download
   */
  onDownloadPdfButtonClicked = () => {
    const { selectedOrganization, brandedLogoPng } = this.props;
    const chartDefinition = this.props.chartDefinition;
    const { startDate, endDate } = this.props.dateSelection;
    const dateFormat = "MMM DD, YYYY";
    const dateRangeText = `${startDate.format(
      dateFormat
    )} \u2013 ${endDate.format(dateFormat)}`;

    track("SURVEY_EXPORT_PDF_CLICKED");

    if (this.props.location.pathname == "/etiology") {
      Promise.all([getImageDataUrlFromLocalPath(brandedLogoPng)])
        .then(([logoDataUrl]) => {
          const globalContent = {
            logo: logoDataUrl,
            reportName: i18n.t("headers.etiologyReport", {
              context: "uppercase"
            }),
            contextText: dateRangeText,
            organization: selectedOrganization
          };
          const options = {
            branding: this.props.branding
          };

          let images = {};
          images.woundChart = this.getChartUrl(chartDefinition);
          const date = new moment().format("YYYY-MMM-DD--HH-mm").toUpperCase();
          pdfMake
            .createPdf(
              createSurveyEtiologyPdf(
                globalContent,
                options,
                {
                  footerText: i18n.t("headers.etiologyReport"),
                  dateRangeText: dateRangeText
                },
                this.props.tallies,
                images,
                this.props.schemaLocalizationDictionary,
                this.props.subtypeKeysForSchema
              )
            )
            .download(`etiology_report_${date}.pdf`);
        })

        .catch(err => {
          this.props.showPdfErrorView();
          trackError(err, {
            extra: { error_context: "etiology_report_pdf" }
          });
        });
    } else {
      Promise.all([getImageDataUrlFromLocalPath(brandedLogoPng)])
        .then(([logoDataUrl]) => {
          const globalContent = {
            logo: logoDataUrl,
            reportName: i18n.t("headers.etiologyReport", {
              context: "uppercase"
            }),
            contextText: dateRangeText,
            organization: selectedOrganization
          };

          const options = {
            branding: this.props.branding
          };

          const date = new moment().format("YYYY-MMM-DD--HH-mm").toUpperCase();

          pdfMake
            .createPdf(
              createSurveyLocationPdf(
                globalContent,
                options,
                {
                  footerText: i18n.t("headers.locationReport"),
                  dateRangeText: dateRangeText
                },
                this.props.tallies,
                this.props.excludeNewHealed
              )
            )
            .download(`location_report_${date}.pdf`);
        })

        .catch(err => {
          this.props.showPdfErrorView();
          trackError(err, {
            extra: { error_context: "location_report_pdf" }
          });
        });
    }
  };

  getPageTitle() {
    const { startDate, endDate } = this.props.dateSelection;
    const dateFormat = "MMM DD, YYYY";

    return `Wound Survey: ${startDate.format(
      dateFormat
    )} \u2013 ${endDate.format(dateFormat)}`;
  }

  toolTipEntering = e => {
    track("SURVEY_EXPORT_BUTTON_CLICKED");
    e.children[0].style.borderBottomColor = "#f5f5f5";

    e.children[1].style.backgroundColor = "#f5f5f5";
    e.children[1].style.float = "left";
  };

  // TODO: Control bar should be moved to its own component
  // that uses the same pattern as the sidebar to render variations
  // for different routes
  renderControlNavbar() {
    return (
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">Survey</h1>
        </div>

        <form className="navbar-form navbar-right">
          <ButtonGroup className="control-navbar-element">
            <NavLink
              to="/etiology"
              exact={true}
              className="btn btn-default"
              activeClassName="active"
            >
              Etiology
            </NavLink>
            <NavLink
              to="/etiology/locations"
              className="btn btn-default"
              activeClassName="active"
            >
              Locations
            </NavLink>
          </ButtonGroup>

          <span className="control-navbar-element">
            <DatePickerRange
              allowSameDayRange={true}
              startDate={this.props.dateSelection.startDate}
              endDate={this.props.dateSelection.endDate}
              unitRange={this.props.dateSelection.unitRange}
              update={this.handleDatePickerChange}
            />
          </span>
          <OverlayTrigger
            placement="bottom"
            trigger="click"
            onEntering={this.toolTipEntering}
            overlay={
              <Tooltip id="popover-contained">
                <ul className={styles["download-icon-list"]}>
                  <li>
                    <div className={styles["textLeft"]}>
                      <img src={csv} width={32} height={32} />
                      <a onClick={this.onDownloadCsvButtonClicked}>
                        Export to CSV
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className={styles["textLeft"]}>
                      <img src={pdf} width={32} height={32} />
                      <a onClick={this.onDownloadPdfButtonClicked}>
                        Export to PDF
                      </a>
                    </div>
                  </li>
                </ul>
              </Tooltip>
            }
          >
            <Button
              name="primary"
              className="control-navbar-element dashboard-btn"
              subtype="button"
            >
              <Icon name="download" />
            </Button>
          </OverlayTrigger>
        </form>
      </nav>
    );
  }

  render() {
    return (
      <div>
        {this.renderControlNavbar()}
        <div>
          <h3 className={styles["section-title"]}>{this.getPageTitle()}</h3>
          <Switch>
            <Route exact path="/etiology" component={SurveyEtiologyContainer} />
            <Route
              path="/etiology/locations"
              component={SurveyLocationsContainer}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
