import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { reduxForm } from "redux-form";
import { push } from "react-router-redux";
import createFlashMessage from "app/flash_messages/create";

// Translations
import i18n from "src/translations";

//Actions
import { loadPatientsByQueryStringAction } from "src/actions/data_actions";
import { PatientSearchHomeComponent } from "src/components/patient_search_home_component";
import { isRoleSupplierSelector } from "../../selectors/config_selectors/is_role_supplier_selector";
import { isRoleProviderSelector } from "../../selectors/config_selectors/is_role_provider_selector";

function mapStateToProps(state) {
  return {
    isRoleProvider: isRoleProviderSelector(state),
    isRoleSupplier: isRoleSupplierSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchPatientByShareToken: (payload, isProvider) => {
      let context = isProvider
        ? payload.filter.share_token
        : payload.filter.mrn;
      return dispatch(loadPatientsByQueryStringAction(context, payload));
    },
    navigateToSearchedPatient: (patient, isRoleProvider) => {
      if (!patient) {
        return dispatch(
          createFlashMessage(
            i18n.t("interaction.errorMessages.patientSearch.patientSearchFail"),
            "error"
          )
        );
      } else if (patient.length === 0) {
        return isRoleProvider
          ? dispatch(
              createFlashMessage(
                i18n.t(
                  "interaction.errorMessages.patientSearch.patientSearchErrorCode"
                ),
                "error"
              )
            )
          : dispatch(
              createFlashMessage(
                i18n.t(
                  "interaction.errorMessages.patientSearch.patientSearchErrorMrn"
                ),
                "error"
              )
            );
      }

      let id = patient[0].id;
      return dispatch(push(`/patients/${id}`));
    }
  };
}

export const PatientSearchHomeContainer = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "searchPatientForm"
  })
)(PatientSearchHomeComponent);
