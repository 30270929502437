import { createSelector } from "reselect";

const getConfig = state => {
  return state.frames;
};

/**
 * Memoized selector for the frame config state tree
 */
export const frameConfigSelector = createSelector(
  getConfig,
  config => {
    return config;
  }
);
