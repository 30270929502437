export function createPostPayload({
  id,
  filterName,
  filters,
  section,
  appliesToFacilityWithId,
  userId
}) {
  return {
    data: {
      id,
      type: "saved_filters",
      attributes: {
        title: filterName,
        parameters: {
          version: "1",
          context: section,
          ...(appliesToFacilityWithId && { appliesToFacilityWithId }),
          filters
        },
        dashboard: section,
        user_id: userId
      }
    }
  };
}
