import apiFetch from "app/util/apiFetch";
import calculateAggregates from "./calculateAggregates";

export default function loadLocationsAndRiskAggregates(
  locationIds,
  startTime,
  endTime
) {
  return dispatch => {
    let locationRequests = locationIds.map(locationId => {
      const payload = {
        location_ids: locationId,
        start_time: startTime,
        end_time: endTime
      };
      return dispatch(
        apiFetch(`/api/v2/risk_dashboard/braden/aggregates`, {
          method: "POST",
          body: payload,
          headers: {
            Accept: "application/json",
            "X-HTTP-Method-Override": "GET"
          }
        })
      ).then(({ aggregates }) => {
        dispatch({
          type: "LOCATION_AGGREGATE_RISK_LOAD",
          locationId,
          aggregates: calculateAggregates(aggregates)
        });
      });
    });

    return Promise.all(locationRequests);
  };
}
