import Moment from "moment";
import get from "lodash.get";

/**
 * Return pdfmake stack object of patient and wound summary information for
 * display at the top of the pdf body content.
 * @param {Object} obj
 * @param {Object} obj.assessmentAnswer - the assessmentAnswer
 * @param {Object} obj.user - current user
 * @return {object} pdf object
 */
export function getNonWoundAssessmentHeader({ assessmentAnswerGroup, user }) {
  const assessedOn = new Moment(
    assessmentAnswerGroup.attributes.createdAt
  ).format("MMM D, YYYY - HH:mm");

  const firstName = get({ user }, "user.attributes.profile.firstName", null);
  const lastName = get({ user }, "user.attributes.profile.lastName", null);

  let fullName;

  if (!firstName && !lastName) {
    fullName = get({ user }, "user.attributes.username", "");
  } else if (firstName && !lastName) {
    fullName = firstName;
  } else if (lastName && !firstName) {
    fullName = lastName;
  } else {
    fullName = `${firstName} ${lastName}`;
  }

  fullName = fullName.toUpperCase();

  return {
    stack: [
      {
        text: `Completed by: ${fullName}`,
        style: "paragraph"
      },
      {
        text: `Evaluated On: ${assessedOn}`,
        style: "paragraph"
      }
    ],
    alignment: "left",
    margin: [0, 0, 0, 10]
  };
}
