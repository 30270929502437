import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";

// Load states
import { loadStates } from "../load_states";

// Request methods
import { requestMethods } from "../request_methods";

// Utils
// TODO: eliminate dependency on rest of code base
import { propsAreDifferent } from "src/utils/props_are_different";

/**
 * Creates a HOC that fetches the resource associated with the supplied values
 * @param {Object} resourceManager
 * @param {Object} globalConfig
 * @return {function} Higher Order Component function wrapped by the loader component
 */
export function createWithResourceHoc(resourceManager, globalConfig) {
  return function withResource({ mapStateToQueryObject }) {
    return function withResourceLoader(WrappedComponent) {
      class ResourceLoader extends React.PureComponent {
        static propTypes = {
          loadingObjects: PropTypes.array.isRequired,
          resourceLoadingObjects: PropTypes.array.isRequired,
          sendLoadResourceAction: PropTypes.func.isRequired
        };

        constructor(props) {
          super(props);
          this.loadData(props);
        }

        componentDidUpdate(prevProps) {
          if (propsAreDifferent(prevProps, this.props, "loadingObjects")) {
            this.loadData(this.props);
          }
        }

        /**
         * Called from constructor and when loading objects change
         * Sends action to load data
         * @param {Object} props
         */
        loadData(props) {
          const { loadingObjects, sendLoadResourceAction } = props;

          loadingObjects.forEach(lo => {
            if (!lo.loadState || lo.loadState == loadStates.invalidated) {
              sendLoadResourceAction(lo.queryObject, lo.page);
            }
          });
        }

        /**
         * Retry failed queries
         * will retry failed queries from all cascaded loading objects
         */
        retry = () => {
          const { resourceLoadingObjects, sendLoadResourceAction } = this.props;
          Object.values(resourceLoadingObjects).forEach(loList => {
            loList.forEach(lo => {
              if (lo.loadState == loadStates.error) {
                sendLoadResourceAction(lo.queryObject, lo.page);
              }
            });
          });
        };

        /**
         * Refresh all queries
         */
        refresh = () => {
          // TODO: this might be useful and easy to do
        };

        render() {
          return <WrappedComponent {...this.props} retry={this.retry} />;
        }
      }

      function mapStateToProps(state, ownProps) {
        // Client provided map functions
        const queryObject = mapStateToQueryObject(state, ownProps);

        // Page info
        const pageCountSelector = resourceManager.selectors.createPageCountSelector(
          queryObject
        );
        const pageCount = pageCountSelector(state);
        const currentPage = resourceManager.selectors.createCurrentPageSelector(
          queryObject
        )(state);

        // resourcePages prop
        let resourcePages = {
          [resourceManager.resourceName]: {
            pageCount,
            currentPage
          }
        };
        if (ownProps.resourcePages) {
          resourcePages = {
            ...ownProps.resourcePages,
            ...resourcePages
          };
        }

        // pages array
        const pages = globalConfig.paginationStrategy(
          queryObject.pageConfig,
          pageCount,
          currentPage
        );

        // Load states and retry objects
        const loadStates = resourceManager.selectors.createLoadStateSelector(
          requestMethods.get,
          queryObject
        )(state);

        // Loading objects
        const loadingObjects = pages.map((page, index) => {
          return {
            page,
            queryObject,
            loadState: loadStates[index]
          };
        });
        const currentLoadObjectsForResource = get(
          ownProps,
          `resourceLoadingObjects.${resourceManager.resourceName}`,
          []
        );
        let resourceLoadingObjects = {
          [resourceManager.resourceName]: [
            ...currentLoadObjectsForResource,
            ...loadingObjects
          ]
        };
        resourceLoadingObjects = {
          ...ownProps.resourceLoadingObjects,
          ...resourceLoadingObjects
        };

        // resourceData prop
        let resourceData = ownProps.resourceData || {};
        const resourceName = resourceManager.resourceName;
        const propagatedData = get(resourceData, `${resourceName}`, []);
        const currentData = resourceManager.selectors.createDataSelector(
          queryObject
        )(state);
        resourceData = {
          ...resourceData,
          [resourceManager.resourceName]: [...propagatedData, ...currentData]
        };

        return {
          queryObject,
          resourcePages,
          resourceData, // used to pass data down to the wrapped component
          loadingObjects, // used for loading in this loader
          resourceLoadingObjects // used for cascading loaders
        };
      }

      function mapDispatchToProps(dispatch) {
        return {
          dispatch,
          sendLoadResourceAction(queryObject, page) {
            return dispatch(resourceManager.actions.get(queryObject, page));
          }
        };
      }

      function mergeProps(propsFromState, propsFromDispatch, ownProps) {
        const { dispatch } = propsFromDispatch;
        const { queryObject } = propsFromState;
        let resourceActions = {
          [resourceManager.resourceName]: {
            incrementPage: () =>
              dispatch(resourceManager.actions.incrementPage(queryObject)),
            decrementPage: () =>
              dispatch(resourceManager.actions.decrementPage(queryObject)),
            setPage: page =>
              dispatch(resourceManager.actions.setPage(queryObject, page))
          }
        };
        if (ownProps.resourceActions) {
          resourceActions = {
            ...ownProps.resourceActions,
            ...resourceActions
          };
        }

        return {
          ...propsFromState,
          ...propsFromDispatch,
          resourceActions
        };
      }

      return connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
      )(ResourceLoader);
    };
  };
}
