import { createSelector } from "reselect";
import { policiesResourceSelectors } from "src/selectors/data_selectors/policies";
import get from "lodash.get";

export const woundSchemaPolicySelector = createSelector(
  policiesResourceSelectors.dataSelector,
  policies => {
    if (!Object.keys(policies).length) {
      return null;
    }

    // get all policies for this org
    const woundSchemaPolicy = Object.values(policies).find(policy => {
      return policy.attributes.internalName == "wound_schema";
    });

    const policyValue = get(woundSchemaPolicy, "attributes.policyValue", ""); // get wound schema policy

    //Remove all white spaces on the start and end of policy string.
    return policyValue.trim();
  }
);
