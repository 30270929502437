import React from "react";

export class CloseIconComponent extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="13.5" fill="#7B7D87" stroke="#D3D4D7" />
        <g clipPath="url(#clip0)">
          <path
            d="M13.7907 12.6292L17.3981 9.0219C17.7185 8.70116 18.2386 8.70119 18.5592 9.02179C18.88 9.34244 18.88 9.86239 18.5592 10.183L14.9517 13.7904L18.5591 17.3978C18.5592 17.3978 18.5592 17.3979 18.5592 17.3979C18.88 17.7185 18.8799 18.2383 18.5593 18.5589C18.399 18.7193 18.1884 18.7995 17.9787 18.7995C17.769 18.7995 17.5584 18.7193 17.3981 18.559C17.3981 18.559 17.3981 18.5589 17.3981 18.5589L13.7907 14.9515L10.1833 18.559L10.0091 18.3848L10.1833 18.559C10.023 18.7193 9.81246 18.7995 9.60261 18.7995C9.39277 18.7995 9.18226 18.7193 9.02198 18.559H9.02198C8.7013 18.2384 8.70137 17.7185 9.02198 17.3979L12.6295 13.7904L9.02207 10.1829C9.02207 10.1829 9.02207 10.1829 9.02207 10.1829C8.70144 9.86228 8.70143 9.34242 9.02203 9.02177L13.7907 12.6292ZM13.7907 12.6292L10.1833 9.02174L10.1833 9.02173C9.86264 8.70112 9.34265 8.70106 9.02207 9.02173L13.7907 12.6292Z"
            fill="#fff"
            stroke="#fff"
            strokeWidth="0.5"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="10.47"
              height="10.47"
              fill="#fff"
              transform="translate(8.556 8.556)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
