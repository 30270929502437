import React from "react";
import PropTypes from "prop-types";

// Model
import { getEventStringsObject } from "src/models/events";

// Components
import { LoadingComponent } from "src/components/loading_component";
import { Button } from "src/ui-kit";
import { GridRow, GridColumn } from "src/components/grid";

// Constants
import { loadStates } from "src/constants/load_states";

// Styles
import styles from "./style.module.less";

//Analytics
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";

/**
 * React component that renders a list of Events
 */
export class EventHistoryComponent extends React.PureComponent {
  static propTypes = {
    events: PropTypes.array.isRequired,
    loadState: PropTypes.string,
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    firstPageContext: PropTypes.string.isRequired,
    sendIncrementPageAction: PropTypes.func.isRequired
  };

  loadMoreClicked = () => {
    this.props.sendIncrementPageAction(this.props.firstPageContext);
  };

  render() {
    const { events, loadState, currentPage, pageCount } = this.props;
    if (currentPage == 0 && (!loadState || loadState == loadStates.loading)) {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_PATIENTS_EVENT_HISTORY">
          <LoadingComponent height="50px" />
        </StartRenderTimeTrackComponent>
      );
    } else if (loadState == loadStates.error) {
      return (
        <FinishRenderTimeTrackComponent
          eventName="RENDER_PATIENTS_EVENT_HISTORY"
          error={true}
        >
          <LoadingComponent height="50px" />
        </FinishRenderTimeTrackComponent>
      );
    }

    const eventList = events.map(event => {
      const { eventType, date, userNameAndDesignation } = getEventStringsObject(
        event
      );

      return (
        <GridRow key={event.id}>
          <GridColumn sm="7">
            <p>
              <b>{eventType}</b>
              <br />
              {userNameAndDesignation}
            </p>
          </GridColumn>
          <GridColumn sm="5">
            <p>{date}</p>
          </GridColumn>
        </GridRow>
      );
    });

    const showNextPageButton =
      loadState != loadStates.loading &&
      loadState != loadStates.error &&
      currentPage <= pageCount &&
      pageCount > 1;

    const showLoadingMore = loadState == loadStates.loading;

    return (
      <div>
        {events.length > 0 && (
          <FinishRenderTimeTrackComponent eventName="RENDER_PATIENTS_EVENT_HISTORY">
            <div className={styles["event-string-list"]}>{eventList}</div>
          </FinishRenderTimeTrackComponent>
        )}
        {showNextPageButton && (
          <div className={styles["load-more-container"]}>
            <Button className="dashboard-btn" onClick={this.loadMoreClicked}>
              Load More...
            </Button>
          </div>
        )}
        {showLoadingMore && <LoadingComponent height="50px" />}
      </div>
    );
  }
}

// --------- NEW IMPLEMENTATION - redux resources v2 ---------- //

// import React from "react";
// import PropTypes from "prop-types";

// // Model
// import { getEventStringsObject } from "src/models/events";

// // Components
// import { LoadMoreComponent } from "../load_more_component";
// import { GridRow, GridColumn } from "src/components/grid";

// // Styles
// import styles from "./style.module.less";

// /**
//  * React component that renders a list of Events
//  */
// export class EventHistoryComponent extends React.PureComponent {
//   static propTypes = {
//     resourceData: PropTypes.object.isRequired,
//     resourcePages: PropTypes.object.isRequired,
//     resourceLoadingObjects: PropTypes.object.isRequired,
//     resourceActions: PropTypes.object.isRequired,
//     retry: PropTypes.func.isRequired
//   };

//   loadMoreClicked = () => {
//     this.props.resourceActions.events.incrementPage();
//   };

//   render() {
//     const { resourceData, resourcePages, resourceLoadingObjects, resourceActions, retry } = this.props;
//     const eventList = resourceData.events.map(event => {
//       const { eventType, date, userNameAndDesignation } = getEventStringsObject(
//         event
//       );

//       return (
//         <GridRow key={event.id}>
//           <GridColumn sm="7">
//             <p>
//               <b>{eventType}</b>
//               <br />
//               {userNameAndDesignation}
//             </p>
//           </GridColumn>
//           <GridColumn sm="5">
//             <p>{date}</p>
//           </GridColumn>
//         </GridRow>
//       );
//     });

//     return (
//       <div>
//         <div className={styles["event-string-list"]}>{eventList}</div>
//         <LoadMoreComponent
//           pageCount={resourcePages.events.pageCount}
//           currentPage={resourcePages.events.currentPage}
//           loadingObjects={resourceLoadingObjects.events}
//           onLoadMoreClicked={resourceActions.events.incrementPage}
//           onRetryClicked={retry}
//         />
//       </div>
//     );
//   }
// }
