import { connect } from "react-redux";

// Components
import { ButtonComponent } from "src/components/button_component";

// Selectors
import { createSignAndLockButtonVisibleSelector } from "src/selectors/ui_config_selectors/patients/create_sign_and_lock_button_visible_selector";
import { createSignAndLockIsDisabledSelector } from "src/selectors/ui_config_selectors/patients/create_sign_and_lock_is_disabled_selector";
import { createSignAndLockTooltipSelector } from "src/selectors/ui_config_selectors/patients/create_sign_and_lock_tooltip_selector";

import { signAndLockAssessmentAnswersAction } from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  return {
    isVisible: createSignAndLockButtonVisibleSelector(ownProps)(state),
    disabled: createSignAndLockIsDisabledSelector(ownProps)(state),
    tooltip: createSignAndLockTooltipSelector(ownProps)(state)
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const payload = {
    id: ownProps.assessmentAnswer.id,
    data: {
      id: ownProps.assessmentAnswer.id
    }
  };
  if (!ownProps.onClick) {
    return {
      onClick: () => {
        return dispatch(signAndLockAssessmentAnswersAction({ payload }));
      }
    };
  }
};

export const SignAndLockButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonComponent);
