import {
  styles,
  defaultStyle,
  defaultBrandingStyles,
  pccBrandingStyles
} from "../pdf_styles";

/**
 * Get pdf configure options and styles to apply
 * @return {Object}
 */
export function getPdfOptions(brandingStyle) {
  const brandedStyle =
    brandingStyle === "pcc" || brandingStyle === "pcc_wound"
      ? pccBrandingStyles
      : defaultBrandingStyles;

  return {
    pageSize: "LETTER",
    pageMargins: [10, 210, 10, 40],
    styles: { ...styles, ...brandedStyle },
    defaultStyle: defaultStyle
  };
}
