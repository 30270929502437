import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

// Constants
import { tallyTypes } from "src/constants";

// Translations
import i18n from "src/translations";

// Contants
import { acquiredValues, ageValues } from "src/constants";

// Styles
import styles from "../style.module.less";

const toolTipStringPrefix = "Number of";

export class SurveyTallyTableHeaderComponent extends PureComponent {
  static propTypes = {
    tallyType: PropTypes.string.isRequired,
    showAcquiredUnknown: PropTypes.bool
  };

  // ------------------- Wound Type Header ------------------- //

  /**
   * Get the Type Header for wound type table
   */
  getWoundTypeHeader() {
    if (this.props.tallyType == tallyTypes.etiology) {
      return (
        <th className={styles["left-align-header"]}>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">{`${i18n.t(
                "text.surveyTableTooltips.type"
              )}`}</Tooltip>
            }
          >
            <span>{`${i18n.t("headers.type")}`}</span>
          </OverlayTrigger>
        </th>
      );
    }
  }

  // ------------------- Location Headers ------------------- //

  /**
   * Get the Facility Header for location type table
   */
  getLocationHeader() {
    if (this.props.tallyType == tallyTypes.location) return <th>Location</th>;
  }

  // ------------------- Wound Residents Header ------------------- //

  getWoundResidentsHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              {`${i18n.t("text.surveyTableTooltips.woundResidents")}`}
            </Tooltip>
          }
        >
          <span>{`${i18n.t("headers.woundResidents")}`}</span>
        </OverlayTrigger>
      </th>
    );
  }

  // ------------------- Wound Age Section Headers ------------------- //

  /**
   * Get column headers for a specific section type (ex. new/existing wounds).
   * @param {string} age the age of wounds (New or Existing)
   */
  generateAgeColumnHeaders(age) {
    const ageString = i18n.t(`glossary.${age}`, { context: "capitalize" });

    let headers = acquiredValues.map(acquired => {
      const acquiredString = i18n.t(`data.acquired.default.${acquired}`);
      const tooltipString = i18n.t(`text.surveyTableTooltips.${acquired}`, {
        age
      });
      return (
        <th key={`${acquired} - ${ageString}`}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip">{tooltipString}</Tooltip>}
          >
            <span>
              {ageString} {acquiredString}
            </span>
          </OverlayTrigger>
        </th>
      );
    });

    const totalString = i18n.t("headers.total");

    // Add a totals column on the end of each section
    headers.push(
      <th
        key={`${totalString} - ${ageString}`}
        className={styles["right-border"]}
      >
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              {`${toolTipStringPrefix} ${age} active wounds in the selected date range`}
            </Tooltip>
          }
        >
          <span>
            {totalString} {ageString}
          </span>
        </OverlayTrigger>
      </th>
    );

    return headers;
  }

  // ------------------- Unknown Aquired Header ------------------- //

  /**
   * Get the unknown aquired column header if it should be displayed
   */
  getAcquiredUnknownColumnHeader() {
    if (this.props.showAcquiredUnknown) {
      return (
        <th
          className={`${styles["right-border"]} ${
            styles["red-attention-text"]
          }`}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip">
                {`${i18n.t("text.surveyTableTooltips.acquiredNotSet")}`}
              </Tooltip>
            }
          >
            <span>{`${i18n.t("headers.acquiredNotSet")}`}</span>
          </OverlayTrigger>
        </th>
      );
    }
  }

  // ------------------- Total wounds ------------------- //

  /**
   * Get the unknown aquired column header if it should be displayed
   */
  getTotalWoundsColumnHeader = () => {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              {`${i18n.t("text.surveyTableTooltips.total")}`}
            </Tooltip>
          }
        >
          <span>{`${i18n.t("headers.totalActiveWounds")}`}</span>
        </OverlayTrigger>
      </th>
    );
  };

  // ------------------- Resolved wounds ------------------- //

  /**
   * Get the unknown aquired column header if it should be displayed
   */
  getResolvedWoundsColumnHeader = () => {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              {`${i18n.t("text.surveyTableTooltips.totalResolved")}`}
            </Tooltip>
          }
        >
          <span>{`${i18n.t("headers.totalResolved")}`}</span>
        </OverlayTrigger>
      </th>
    );
  };

  // ------------------- Render ------------------- //

  render() {
    return (
      <tr>
        {this.getWoundTypeHeader()}
        {this.getLocationHeader()}
        {this.getWoundResidentsHeader()}
        {ageValues.reduce((agg, age) => {
          return agg.concat(this.generateAgeColumnHeaders(age));
        }, [])}
        {this.getAcquiredUnknownColumnHeader()}
        {this.getTotalWoundsColumnHeader()}
        {this.getResolvedWoundsColumnHeader()}
      </tr>
    );
  }
}
