import { createSelector } from "reselect";

// Selectors
import { createFirstPageEventsForRevisionIdQuerySelector } from "src/selectors/query_selectors/events_for_revision_id";

/**
 * create a selector to get the context for the first page - loading events with a revisionId
 * @param {Object} props
 * @param {string} props.revisionId
 * @returns {function} selector
 */
export function createFirstPageEventsForRevisionIdContextSelector({
  revisionId
}) {
  return createSelector(
    createFirstPageEventsForRevisionIdQuerySelector({ revisionId }),
    query => {
      return query;
    }
  );
}
