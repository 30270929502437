import { populationActionTypes } from "src/action_types/section_action_types";
import Moment from "moment";

// Default date range is 'month to date'
// TODO: Store the dates as strings in the store.
// date range picker must be updated before we can do this.
const defaultState = {
  startDate: new Moment().startOf("month"),
  endDate: new Moment().endOf("day"),
  unitRange: "monthToDate"
};

export function dateSelectionReducer(state = defaultState, action) {
  switch (action.type) {
    case populationActionTypes.setDateSelection:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
