import React from "react";
import { connect } from "react-redux";

import { SchemaAnswersComponent } from "src/components/schema_answers_component";

// Selectors
import {
  createLatestAssessmentAnswerForRevisionSelector,
  createDescriptionAssessmentSchemaForAssessmentAnswerSelector,
} from "src/selectors/data_selectors";

export function DescribeComponent({ assessmentAnswer, descriptionSchemas }) {
  return (
    <div id="describe-component">
        <SchemaAnswersComponent
            assessmentAnswer={assessmentAnswer}
            assessmentSchema={descriptionSchemas[0]}
            revisionIsFirstInSeries={false}
        />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const assessmentAnswer = createLatestAssessmentAnswerForRevisionSelector(
    ownProps.revision
  )(state);

  return {
    assessmentAnswer,
    descriptionSchemas: createDescriptionAssessmentSchemaForAssessmentAnswerSelector(
      assessmentAnswer
    )(state),
  };
}

export default connect(mapStateToProps)(DescribeComponent);
