import { reduce } from "lodash";

export const formatValues = answersJson => {
  return reduce(
    answersJson,
    function(result, value, key) {
      if (key != "header" && typeof value === "object" && value !== null) {
        result[key] = [];
        Object.keys(value).forEach(k => {
          if (answersJson[key][k]) {
            result[key].push(k);
          }
        });
      } else {
        result[key] = value;
      }
      return result;
    },
    {}
  );
};
