import { createSelector } from "reselect";
import get from "lodash.get";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

//Constants
import { policyInternalNames } from "src/constants";

/**
 * Memoized selector to encounter naming convention
 */

export const encounterNamingConventionSelector = createSelector(
  createPolicyForInternalNameSelector(
    policyInternalNames.encounterNamingConvention
  ),
  policy => {
    return get(policy, "attributes.policyValue", "None");
  }
);
