import { createSelector } from "reselect";

const getConfig = state => {
  return state.config;
};

/**
 * Memoized selector for the config state tree
 * this part of the state hosts application wide configuration
 */
export const configSelector = createSelector(
  getConfig,
  config => {
    return config;
  }
);
