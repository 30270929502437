import get from "lodash.get";

/**
 * cell formatter to get patients facility, unit and/or floor
 * @param {object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.evaluationType the assessment type
 * @param {Object} row.attributes.resource.answersJson the answers json
 * @param {Object} row.attributes.resource.patientWoundNumber the patient wound number
 * @param {number} rowIndex
 */
export const patientLocationNamesFormatter = (cell, row) => {
  const locations = get(row, "attributes.latestPatientLocation", []);
  let facility, floor, unit, locationString;
  if (row.attributes.facilityName) {
    facility = get(row, "attributes.facilityName", "");
    floor = get(row, "attributes.facilityFloorName", "");
    unit = get(row, "attributes.facilityUnitName", "");
    locationString = facility ? facility : "";

    if (floor && unit) {
      locationString += ` - ${floor} - ${unit}`;
    } else {
      if (floor) {
        locationString += ` - ${floor}`;
      } else if (unit) {
        locationString += ` - ${unit}`;
      }
    }
  } else {
    facility = locations.find(location => location.tags.includes("Facility"));
    floor = locations.find(location => location.tags.includes("Floor"));
    unit = locations.find(location => location.tags.includes("Unit"));
    locationString = facility ? facility.name : "";

    if (floor && unit) {
      locationString += ` - ${floor.name} - ${unit.name}`;
    } else {
      if (floor) {
        locationString += ` - ${floor.name}`;
      } else if (unit) {
        locationString += ` - ${unit.name}`;
      }
    }
  }

  return locationString;
};
