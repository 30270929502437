// React / router
import React, { Component } from "react";

// Constants
import { tallyTypes } from "src/constants";

// Containers
import { SurveyEtiologyChartContainer } from "src/containers/survey_etiology_chart_container";
import { SurveyTallyTableContainer } from "src/containers/tables";

// Components
import { GridRow, GridColumn } from "src/components/grid";
import { PanelComponent, PanelBodyComponent } from "src/components/panel";

//translation
import i18n from "src/translations";

export class SurveyEtiologyComponent extends Component {
  render() {
    return (
      <div className="etiology-details">
        <GridRow>
          <GridColumn xs="12">
            <PanelComponent
              labelShow={i18n.t("headers.woundNumbersGraph")}
              labelHide={i18n.t("headers.woundNumbersGraph")}
              enableCollapsible={true}
            >
              <PanelBodyComponent>
                <SurveyEtiologyChartContainer />
              </PanelBodyComponent>
            </PanelComponent>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn xs="12">
            <PanelComponent
              labelShow={i18n.t("headers.woundNumbersTable")}
              labelHide={i18n.t("headers.woundNumbersTable")}
              enableCollapsible={true}
            >
              <PanelBodyComponent>
                <SurveyTallyTableContainer tallyType={tallyTypes.etiology} />
              </PanelBodyComponent>
            </PanelComponent>
          </GridColumn>
        </GridRow>
      </div>
    );
  }
}
