import { connect } from "react-redux";

//Translations
import i18n from "src/translations";

//Components
import { SelectComponent } from "src/components/ui_kit";

//Selectors
import {
  adminSectionSelector,
  userRolesSelector
} from "src/selectors/section_selectors/admin";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const selectUsersRoleIdFilterSelector = SingleValueReduxUtil.createSelectors(
  "selectedUserRoleId",
  adminSectionSelector
);

const selectUsersRoleIdFilterActions = SingleValueReduxUtil.createActionCreators(
  "selectedUserRoleId"
);

function mapStateToProps(state) {
  return {
    options: userRolesSelector(state),
    label: i18n.t(`headers.role`),
    value: selectUsersRoleIdFilterSelector.fieldSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange(value) {
      dispatch(selectUsersRoleIdFilterActions.setValue(value));
    }
  };
}

export const AdminUserRoleSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectComponent);
