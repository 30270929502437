export const evaluationTypesConfiguration = {
  filterName: "evaluationType",
  filterValues: [
    {
      key: "evaluationType",
      value: "wound"
    },
    {
      key: "evaluationType",
      value: "risk"
    }
  ]
};
