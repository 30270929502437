import React from "react";
export class RiskIconComponent extends React.Component {
  render() {
    return (
      <svg
        width="38"
        height="33"
        viewBox="0 0 38 33"
        xmlns="http://www.w3.org/2000/svg"
        {...this.props}
      >
        <path
          d="M22.263 10.463c.098-.423-.175-.882-.632-1.237-1.588-1.236-3.404-1.02-4.808.23-.288.256-.467.584-.432.983.14 1.597.282 3.195.424 4.793.183 2.052.372 4.104.55 6.158.083.955.918 1.742 1.904 1.77 1.134.034 2.016-.633 2.126-1.658.118-1.108.192-2.22.278-3.33.08-1.045.148-2.09.228-3.134.113-1.467.233-2.933.363-4.575m-2.84 19.48c1.574-.01 2.856-1.232 2.856-2.722-.002-1.482-1.29-2.745-2.853-2.73-1.637.013-2.865 1.22-2.86 2.744.003 1.512 1.287 2.718 2.857 2.71m-.468 2.956H1.433c-.128 0-.257.003-.385 0-.784-.028-1.222-.588-.983-1.306.108-.325.282-.636.46-.934C6.514 20.584 12.51 10.51 18.5.436c.17-.28.404-.44.73-.436.346.005.56.208.727.495 2.993 5.14 5.99 10.28 8.99 15.416 2.907 4.983 5.818 9.964 8.727 14.945.24.41.388.834.235 1.31-.137.42-.487.698-.942.73-.128.01-.257.005-.385.005H18.956"
          fill="#020302"
        />
      </svg>
    );
  }
}
