import React from "react";
import PropTypes from "prop-types";
import { InputComponent } from "../input_component";

import styles from "./style.module.less";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

export class InputRangeComponent extends React.PureComponent {
  static propTypes = {
    minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minRangeAllowed: PropTypes.number.isRequired,
    maxRangeAllowed: PropTypes.number.isRequired,
    minChanged: PropTypes.func.isRequired,
    maxChanged: PropTypes.func.isRequired,
    minValueOfRangeNormalizer: PropTypes.func,
    maxValueOfRangeNormalizer: PropTypes.func
  };

  state = {
    minValue: undefined,
    maxValue: undefined
  };

  componentDidUpdate(oldProps) {
    if (propsAreDifferent(oldProps, this.props, "minValue")) {
      this.setState({
        minValue: this.props.minValue
      });
    }
    if (propsAreDifferent(oldProps, this.props, "maxValue")) {
      this.setState({
        maxValue: this.props.maxValue
      });
    }
  }

  minValueNormalizer = minValue => {
    return this.props.minValueOfRangeNormalizer(
      minValue,
      this.state.maxValue || this.props.maxRangeAllowed
    );
  };

  maxValueNormalizer = maxValue => {
    return this.props.maxValueOfRangeNormalizer(
      maxValue,
      this.state.minValue || this.props.minRangeAllowed
    );
  };

  handleMinSearchValueChange = value => {
    this.setState({
      minValue: value
    });
    this.props.minChanged(value);
  };

  handleMaxSearchValueChange = value => {
    this.setState({
      maxValue: value
    });
    this.props.maxChanged(value);
  };

  render() {
    return (
      <div className={styles["input-range-container"]}>
        <div className={styles["input-component-wrapper"]}>
          <InputComponent
            placeholder={this.props.minRangeAllowed}
            value={this.state.minValue}
            setValue={this.handleMinSearchValueChange}
            normalizer={this.minValueNormalizer}
          />
        </div>
        <div className={styles["hyphen-wrapper"]}>
          <p className={styles["hyphen"]}> - </p>
        </div>
        <div className={styles["input-component-wrapper"]}>
          <InputComponent
            placeholder={this.props.maxRangeAllowed}
            value={this.state.maxValue}
            setValue={this.handleMaxSearchValueChange}
            normalizer={this.maxValueNormalizer}
          />
        </div>
      </div>
    );
  }
}
