import { connect } from "react-redux";
import { SideBarAdminNavigationComponent } from "src/components/side_bar_admin_navigation_component";

//selectors
import {
  admissionFilterEnabledSelector,
  organizationFilterEnabledSelector,
  monitorAtHomeStatusEnabledSelector
} from "src/selectors/config_selectors";

import {
  adminAlertsSectionEnabledSelector,
  adminUsersSectionEnabledSelector,
  adminPatientsSectionEnabledSelector
} from "src/selectors/ui_config_selectors/admin";

function mapStateToProps(state) {
  return {
    usersSectionEnabled: adminUsersSectionEnabledSelector(state),
    admissionFilterEnabled: admissionFilterEnabledSelector(state),
    organizationFilterEnabled: organizationFilterEnabledSelector(state),
    alertsSectionEnabled: adminAlertsSectionEnabledSelector(state),
    patientsSectionEnabled: adminPatientsSectionEnabledSelector(state),
    monitorAtHomeStatusFilterEnabled: monitorAtHomeStatusEnabledSelector(state)
  };
}

export const SideBarAdminNavigationContainer = connect(mapStateToProps)(
  SideBarAdminNavigationComponent
);
