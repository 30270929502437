/**
 * Create a normalizer for a max value in a range
 * @param {number} maxValueAllowed - the min value allowed
 * @returns {number|string}
 */
export function createMaxValueOfRangeNormalizer(maxValueAllowed) {
  return function(currentMaxValue, currentMinValue) {
    const currentMinValueFloat = parseFloat(currentMinValue);
    const currentMaxValueFloat = parseFloat(currentMaxValue);

    if (!currentMaxValueFloat && currentMaxValueFloat !== 0) {
      return "";
    } else if (
      currentMaxValueFloat > maxValueAllowed ||
      currentMaxValueFloat < currentMinValueFloat
    ) {
      return maxValueAllowed;
    } else {
      return currentMaxValueFloat;
    }
  };
}
