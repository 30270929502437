import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { withRouter } from "react-router-dom";

// Constants
import { apiPageLimits } from "src/constants";

// Components
import { PatientListComponent } from "../../components/patient_list_component";

// HOC
import { withResourceLoaderFactory } from "src/hoc/factories";

// Actions
import {
  patientsResourceActions,
  loadPatientsAction
} from "src/actions/data_actions/patients";

// Selectors
import { patientsResourceSelectors } from "src/selectors/data_selectors/patients";
import { createAllPagesContextSelector } from "src/selectors/api_selectors/patients/patients/create_all_pages_context_selector";
import { createCurrentPageContextSelector } from "src/selectors/api_selectors/patients/patients/create_current_page_context_selector";
import { createCurrentPagePayloadSelector } from "src/selectors/api_selectors/patients/patients/create_current_page_payload_selector";
import { createFirstPageContextSelector } from "../../selectors/api_selectors/patients/patients/create_first_page_context_selector";

function mapStateToProps(state) {
  const firstPageContextSelector = createFirstPageContextSelector();

  return {
    patients: patientsResourceSelectors.createDataForContextSelector(
      createAllPagesContextSelector()
    )(state),
    currentPage: patientsResourceSelectors.createCurrentPageForContextSelector(
      firstPageContextSelector
    )(state),
    pageCount:
      patientsResourceSelectors.createPageCountSelector(
        firstPageContextSelector,
        apiPageLimits.patients
      )(state) || 0,
    firstPageContext: firstPageContextSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendIncrementPageAction: context => {
      dispatch(patientsResourceActions.incrementPageForContextAction(context));
    }
  };
}

export const PatientListContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadPatientsAction,
    resourceSelectors: patientsResourceSelectors,
    createContextSelector: createCurrentPageContextSelector,
    createPayloadSelector: createCurrentPagePayloadSelector
  }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PatientListComponent);
