export const evaluationsConfigurationV7 = {
  filterName: "evaluations",
  filterValues: [
    {
      key: "assessment_type",
      value: "wound"
    },
    {
      key: "assessment_type",
      value: "braden,norton_plus,purs"
    },
    {
      key: "assessment_type",
      value: "full_body_skin"
    },
    {
      key: "assessment_type",
      value: "left_abpi"
    },
    {
      key: "assessment_type",
      value: "right_abpi"
    },
    {
      key: "assessment_type",
      value: "toe_pressure"
    },
    {
      key: "assessment_type",
      value: "hemoglobin"
    }
  ]
};
