import axios from "axios";

const remoteImageToDataURL = async url => {
  let response;

  try {
    response = await fetch(url, {
      method: "GET"
    });
  } catch (err) {
    console.error(err);
    return;
  }

  const base64Image = Buffer.from(
    await response.arrayBuffer(),
    "binary"
  ).toString("base64");
  return `data:image/jpeg;base64,${base64Image}`;
};

export const remoteImageToBuffer = async url => {
  let response;

  try {
    response = await axios.get(url, {
      responseType: "arraybuffer"
    });
  } catch (err) {
    return;
  }

  return Buffer.from(response.data, "binary");
};

export default remoteImageToDataURL;
