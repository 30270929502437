import get from "lodash.get";
import compact from "lodash.compact";
import { createSelector } from "reselect";

//Selectors
import {
  payersResourceSelector,
  payerPatientsResourceSelector
} from "src/selectors/data_selectors";

export function loadPayersFromPatientId(patientId) {
  return createSelector(
    payersResourceSelector.dataSelector,
    payerPatientsResourceSelector.dataSelector,
    (payers, payerPatients) => {
      const payerPatientsArray = Object.values(payerPatients);
      let payerArray = payerPatientsArray.map(payerPatient => {
        let payerPatientId = get(payerPatient, "attributes.patientId", "");
        let isOldPayer = get(payerPatient, "attributes.endDate", "");
        if (patientId === payerPatientId && !isOldPayer) {
          return payers[payerPatient.attributes.payerId].attributes.name;
        }
      });
      if (compact(payerArray).length === 0) payerArray.push("N/A");
      return compact(payerArray);
      //returns array of payers for patient id (check test)
    }
  );
}
