import { createSelector } from "reselect";

//Translations
import i18n from "src/translations";

/**
 * Selector to return an array of options for admin users table states filter
 */

export const userStatesSelector = createSelector(
  () => {},
  () => {
    const userStates = [
      {
        value: "",
        label: "-- Select State --"
      },
      {
        value: "enabled",
        label: i18n.t(`data.userStates.enabled`)
      },
      {
        value: "disabled",
        label: i18n.t(`data.userStates.disabled`)
      }
    ];

    return userStates;
  }
);
