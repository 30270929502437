import { createSelector } from "reselect";
import moment from "moment";
import { createLatestRevisionForSeriesIdSelector } from "src/selectors/data_selectors/revisions/create_latest_revision_for_series_id_selector";
import { assessmentAnswersResourceSelectors } from "../assessment_answers_resource_selectors";

/**
 * For a series id, returns a selector that gets the most recent assessment answer
 * for the most recent revision in the series.
 * @param {String} seriesId
 * @return {Function} the selector
 */
export function createLatestAssessmentAnswerForSeriesIdSelector(seriesId) {
  return createSelector(
    createLatestRevisionForSeriesIdSelector(seriesId),
    assessmentAnswersResourceSelectors.dataSelector,
    (revision, assessmentAnswers) => {
      if (!seriesId || !revision) {
        return null;
      }

      const sortedAssessmentAnswers = Object.values(assessmentAnswers)
        .filter(assessmentAnswer => {
          return (
            assessmentAnswer.attributes.resourceType == "Revision" &&
            assessmentAnswer.attributes.resourceId == revision.id
          );
        })
        .sort((a1, a2) => {
          return moment(a1.attributes.createdAt).isAfter(
            a2.attributes.createdAt
          )
            ? -1
            : 1;
        });

      return sortedAssessmentAnswers.length
        ? sortedAssessmentAnswers[0]
        : undefined;
    }
  );
}
