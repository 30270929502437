import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

// Constants
import { loadStates } from "src/constants/load_states";

// Components
import { LoadingComponent } from "src/components/loading_component";
import { AssessmentAnswerHistoryListItemContainer } from "src/containers/assessment_answer_history_list_item_container";
import { Button } from "src/ui-kit";

//Analytics
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";

// Styles
import styles from "./style.module.less";

export class AssessmentAnswerHistoryListComponent extends React.PureComponent {
  static propTypes = {
    assessmentAnswers: PropTypes.array.isRequired,
    assessmentAnswersLoadState: PropTypes.string,
    assessmentAnswersMeta: PropTypes.object,
    loadMoreAssessmentAnswers: PropTypes.func.isRequired
  };

  render() {
    const {
      assessmentAnswers,
      assessmentAnswersLoadState,
      assessmentAnswersMeta,
      loadMoreAssessmentAnswers
    } = this.props;

    if (
      !assessmentAnswersLoadState ||
      assessmentAnswersLoadState == loadStates.loading
    ) {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_PATIENTS_HISTORY_LIST">
          <LoadingComponent height="50px" />
        </StartRenderTimeTrackComponent>
      );
    } else if (assessmentAnswersLoadState == loadStates.error) {
      <FinishRenderTimeTrackComponent
        eventName="RENDER_PATIENTS_HISTORY_LIST"
        error={true}
      >
        <LoadingComponent height="50px" />
      </FinishRenderTimeTrackComponent>;
    }

    const completedAssessmentAnswers = assessmentAnswers.filter(
      aa => aa.attributes && aa.attributes.completed
    );

    const list =
      completedAssessmentAnswers &&
      completedAssessmentAnswers.map(aa => {
        return (
          <li key={aa.id}>
            <AssessmentAnswerHistoryListItemContainer assessmentAnswer={aa} />
          </li>
        );
      });

    const recordCount = get(
      { assessmentAnswersMeta },
      "assessmentAnswersMeta.meta.recordCount",
      0
    );

    const showLoadMore =
      recordCount > assessmentAnswers.length &&
      assessmentAnswersLoadState != loadStates.loadingMore;

    return (
      <div>
        {completedAssessmentAnswers && completedAssessmentAnswers.length > 0 && (
          <FinishRenderTimeTrackComponent eventName="RENDER_PATIENTS_HISTORY_LIST">
            <ul className="list-unstyled">{list}</ul>
          </FinishRenderTimeTrackComponent>
        )}
        {showLoadMore && (
          <div className={styles["load-more-container"]}>
            <Button
              className="dashboard-btn"
              onClick={loadMoreAssessmentAnswers}
            >
              Load More...
            </Button>
          </div>
        )}
        {assessmentAnswersLoadState == loadStates.loadingMore && (
          <LoadingComponent height="50px" />
        )}
      </div>
    );
  }
}
