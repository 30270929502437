import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import { GridColumn, GridRow } from "src/components/grid";

// Containers
import {
  ComplianceSavedFilterFormContainer,
  OutcomesSavedFilterFormContainer,
  WoundsSavedFilterFormContainer,
  RiskSavedFilterFormContainer,
  SurveySavedFilterFormContainer,
  PopulationSavedFilterFormContainer,
  MonitoringSavedFilterFormContainer,
  AlertsSavedFilterFormContainer,
  EvaluationListWoundSavedFilterFormContainer,
  EvaluationListBradenSavedFilterFormContainer,
  EvaluationListPURSSavedFilterFormContainer,
  EvaluationListTotalSkinSavedFilterFormContainer,
  WoundRiskWoundSavedFilterFormContainer
} from "src/containers/forms/saved_filter_form_container";
import {
  ComplianceSavedFilterVisualizationContainer,
  WoundsSavedFilterVisualizationContainer,
  RiskSavedFilterVisualizationContainer,
  SurveySavedFilterVisualizationContainer,
  PopulationSavedFilterVisualizationContainer,
  MonitoringSavedFilterVisualizationContainer,
  AlertsSavedFilterVisualizationContainer,
  EvaluationListWoundSavedFilterVisualizationContainer,
  EvaluationListBradenSavedFilterVisualizationContainer,
  EvaluationListPURSSavedFilterVisualizationContainer,
  EvaluationListTotalSkinSavedFilterVisualizationContainer,
  WoundRiskWoundSavedFilterVisualizationContainer
} from "src/containers/saved_filter_visualization_container";

// Styles
import styles from "./style.module.less";
export class SavedFilterModalContentComponent extends React.PureComponent {
  static propTypes = {
    cancel: PropTypes.func.isRequired
  };

  render() {
    const { cancel, savedFilter, onSubmit } = this.props;

    return (
      <GridRow className={styles["saved-filter-modal"]}>
        <GridColumn xs="9">
          <Switch>
            <Route
              path="/compliance"
              render={props => (
                <ComplianceSavedFilterFormContainer
                  {...props}
                  cancel={cancel}
                  onSubmit={onSubmit}
                  savedFilter={savedFilter}
                />
              )}
            />
            <Route
              path="/outcomes"
              render={props => (
                <OutcomesSavedFilterFormContainer {...props} cancel={cancel} />
              )}
            />
            <Route
              path="/wounds"
              render={props => (
                <WoundsSavedFilterFormContainer {...props} cancel={cancel} />
              )}
            />
            <Route
              path="/risk"
              render={props => (
                <RiskSavedFilterFormContainer {...props} cancel={cancel} />
              )}
            />
            <Route
              path="/etiology"
              render={props => (
                <SurveySavedFilterFormContainer {...props} cancel={cancel} />
              )}
            />
            <Route
              path="/population"
              render={props => (
                <PopulationSavedFilterFormContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              path="/monitoring"
              render={props => (
                <MonitoringSavedFilterFormContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              exact
              path="/my-dashboard/alerts"
              render={props => (
                <AlertsSavedFilterFormContainer {...props} cancel={cancel} />
              )}
            />
            <Route
              exact
              path="/my-dashboard/evaluation-list/wound"
              render={props => (
                <EvaluationListWoundSavedFilterFormContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              exact
              path="/my-dashboard/evaluation-list/braden"
              render={props => (
                <EvaluationListBradenSavedFilterFormContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              exact
              path="/my-dashboard/evaluation-list/purs"
              render={props => (
                <EvaluationListPURSSavedFilterFormContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              exact
              path="/my-dashboard/evaluation-list/total-skin"
              render={props => (
                <EvaluationListTotalSkinSavedFilterFormContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              exact
              path="/my-dashboard/wound-risk"
              render={props => (
                <WoundRiskWoundSavedFilterFormContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
          </Switch>
        </GridColumn>
        <GridColumn xs="3" className={styles["list-column"]}>
          <Switch>
            <Route
              path="/compliance"
              component={ComplianceSavedFilterVisualizationContainer}
            />
            <Route
              exact
              path="/my-dashboard/alerts"
              render={props => (
                <AlertsSavedFilterVisualizationContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              exact
              path="/my-dashboard/evaluation-list/wound"
              render={props => (
                <EvaluationListWoundSavedFilterVisualizationContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              exact
              path="/my-dashboard/evaluation-list/braden"
              render={props => (
                <EvaluationListBradenSavedFilterVisualizationContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              exact
              path="/my-dashboard/evaluation-list/purs"
              render={props => (
                <EvaluationListPURSSavedFilterVisualizationContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              exact
              path="/my-dashboard/evaluation-list/total-skin"
              render={props => (
                <EvaluationListTotalSkinSavedFilterVisualizationContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              exact
              path="/my-dashboard/wound-risk"
              render={props => (
                <WoundRiskWoundSavedFilterVisualizationContainer
                  {...props}
                  cancel={cancel}
                />
              )}
            />
            <Route
              path="/wounds"
              component={WoundsSavedFilterVisualizationContainer}
            />
            <Route
              path="/risk"
              component={RiskSavedFilterVisualizationContainer}
            />
            <Route
              path="/etiology"
              component={SurveySavedFilterVisualizationContainer}
            />
            <Route
              path="/population"
              component={PopulationSavedFilterVisualizationContainer}
            />
            <Route
              path="/monitoring"
              component={MonitoringSavedFilterVisualizationContainer}
            />
          </Switch>
        </GridColumn>
      </GridRow>
    );
  }
}
