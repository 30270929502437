import { createActionTypes } from "../../create_action_types";

/**
 * Create action creator for marking an entire resource invalid
 * @param {string} resourceName
 * @returns {function}
 */
export function createInvalidateAllActionCreator(resourceName) {
  const actionTypes = createActionTypes(resourceName);
  return () => {
    return {
      type: actionTypes.invalidateAll
    };
  };
}
