// @flow
import React, { PureComponent } from "react";

type $props = {
  children?: any,
  className?: string
};

export default class ULItem extends PureComponent {
  props: $props;
  render() {
    const { children, ...props } = this.props;
    return <li {...props}>{children}</li>;
  }
}
