import { createSelector } from "reselect";
import { patientsResourceSelectors } from "../patients_resource_selectors";

/**
 * Create a selector to find a patient by id
 * @param {string} patientId
 * @returns {Function} selector
 */
export function createPatientForIdSelector(patientId) {
  return createSelector(
    patientsResourceSelectors.dataSelector,
    patients => {
      return patients[patientId];
    }
  );
}
