import { connect } from "react-redux";
import { SideBarAdminssionStatusFilterComponent } from "src/components/side_bar_admission_status_filter_component";

// selectors
import { admissionStatusFilterSelector } from "src/selectors/section_selectors/admin";

// actions
import {
  setAdmissionStatusFilterAction,
  deselectAdmissionStatusFilterAction
} from "src/actions/section_actions/admin";

function mapStateToProps(state) {
  return {
    admissionStatusFilter: admissionStatusFilterSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetAdmissionStatusFilterAction: value => {
      dispatch(setAdmissionStatusFilterAction(value));
    },
    sendDeselectAdmissionStatusFilterAction: value => {
      dispatch(deselectAdmissionStatusFilterAction(value));
    }
  };
}

export const SideBarAdmissionStatusFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBarAdminssionStatusFilterComponent);
