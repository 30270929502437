import { connect } from "react-redux";

// Components
import { WoundProgressTallyByLocationTableHeaderComponent } from "src/components/tables";

// Selectors
import { progressValuesSelector } from "src/selectors/constant_selectors";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

function mapStateToProps(state) {
  return {
    progressValues: progressValuesSelector(state),
    assessmentVersion: assessmentVersionSelector(state)
  };
}

export const WoundProgressTallyByLocationTableHeaderContainer = connect(
  mapStateToProps
)(WoundProgressTallyByLocationTableHeaderComponent);
