import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Selectors
import { partnersDataSelector } from "src/selectors/data_selectors";

// Actions
import { loadPartnersAction } from "src/actions/data_actions";

/**
 * HOC that is responsible for loading a list of Partners
 * @param {Class} WrappedComponent the component dependent on this data
 * @return {Class} Component wrapped by the loader component
 */
export function withPartnersLoader(WrappedComponent) {
  class PartnersLoader extends React.Component {
    static propTypes = {
      partners: PropTypes.object.isRequired
    };

    componentWillMount() {
      // Load partners if we haven't already
      if (!Object.keys(this.props.partners).length) {
        this.props.sendLoadPartnersAction();
      }
    }

    render() {
      return <WrappedComponent />;
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartnersLoader);
}

/**
 * Map the props needed for loading data
 */
function mapStateToProps(state) {
  return {
    partners: partnersDataSelector(state)
  };
}

/**
 * Map dispatch to a props for loading data
 */
function mapDispatchToProps(dispatch) {
  return {
    sendLoadPartnersAction() {
      dispatch(loadPartnersAction());
    }
  };
}
