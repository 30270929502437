import { createSelector } from "reselect";

// Selectors
import { partnerNameSelector } from "../partner_name_selector";

/**
 * Selector that determines if bread crumbs are enabled on the patient view
 */
export const patientBreadcrumbsEnabledSelector = createSelector(
  partnerNameSelector,
  partnerName => {
    switch (partnerName) {
      case "pcc":
      case "pcc_wound":
        return true;
      default:
        return false;
    }
  }
);
