import { createSelector } from "reselect";

//Selectors
import { mergedDescriptionSchemasSelector } from "src/selectors/section_selectors/global/merged_description_schemas_selector";

//Utils
import { extractSubtypeKeysFromSchema } from "src/utils/downloadable_schemas/extract_subtype_keys_for_types_from_schema";

export const subtypeKeysForAllSchemasSelector = createSelector(
  mergedDescriptionSchemasSelector,
  mergedSchemaQuestions => {
    return extractSubtypeKeysFromSchema({
      attributes: {
        schemaJson: {
          questions: mergedSchemaQuestions
        }
      }
    });
  }
);
