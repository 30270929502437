import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonComponent } from "src/components/button_component";

// Translations
import i18n from "src/translations";

// Styles
import styles from "./style.module.less";

export class LoadingErrorComponent extends React.PureComponent {
  static propTypes = {
    message: PropTypes.string,
    retry: PropTypes.func.isRequired
  };

  render() {
    const { message, retry } = this.props;
    return (
      <div className={styles["loading-error-container"]}>
        <p>{message ? message : i18n.t("interaction.apiGeneric.error")}</p>
        <ButtonComponent
          className={styles["loading-error-button"]}
          onClick={retry}
        >
          {i18n.t("actions.retry")}
        </ButtonComponent>
      </div>
    );
  }
}
