import { combineReducers } from "redux";

import { downloadManagerOpenReducer } from "./download_manager_open_reducer";
import { downloadManagerToggleExpandCollapseBodyReducer } from "./download_manager_toggle_expand_collapse_body_reducer";
import { toggleExpandSidebarReducer } from "./toggle_expand_sidebar_reducer";
import { schemaLocalizationDictionaryReducer } from "./schema_localization_dictionary_reducer";

export const globalSectionReducers = combineReducers({
  downloadManagerOpen: downloadManagerOpenReducer,
  showDownloadManagerBody: downloadManagerToggleExpandCollapseBodyReducer,
  showSidebar: toggleExpandSidebarReducer,
  schemaLocalizationDictionary: schemaLocalizationDictionaryReducer
});
