import React, { useContext } from "react";

// Components
import Select from "src/ui-kit/Select";
import { ComplianceContext } from "src/pages/compliance/components/compliance_provider_component.js";

export function TablePageLimitDropdownComponent() {
  const { state, dispatch } = useContext(ComplianceContext);
  const onChangePageLimit = value => {
    dispatch({
      type: "selectPageLimit",
      payload: { pageLimit: parseInt(value) }
    });
  };

  const options = [
    {
      label: "25",
      value: 25
    },
    {
      label: "50",
      value: 50
    },
    {
      label: "100",
      value: 100
    }
  ];
  return (
    <div>
      <Select
        value={state.pageLimit}
        onChange={onChangePageLimit}
        options={options}
      />
    </div>
  );
}
