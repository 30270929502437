import get from "lodash.get";
import moment from "moment";
import i18n from "src/translations";

const encounterEndDateFormatter = (
  encounter,
  encounterFieldsShown,
  isFloatingVisit
) => {
  if (encounterFieldsShown.endDate) {
    const encounterEndDate = get(encounter, "attributes.endAt", null);

    const formatString =
      encounterFieldsShown.endTime && !isFloatingVisit
        ? "DD MMM YYYY, h:mm a"
        : "DD MMM YYYY";
    const formattedEndDate = encounterEndDate
      ? moment(encounterEndDate).format(formatString)
      : "N/A";
    return `${i18n.t("data.encounters.endDateString")}: ${formattedEndDate}`;
  }
  return null;
};

export default encounterEndDateFormatter;
