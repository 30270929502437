import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//Selectors
import { woundsClinicalChartResourceSelector } from "src/selectors/data_selectors";
import {
  createAllWeeksWoundsClinicalChartDataContextSelector,
  createAllWeeksWoundsClinicalChartDataPayloadSelector
} from "src/selectors/api_selectors/wounds/wounds_chart";
import { woundsClinicalChartDefinitionSelector } from "src/selectors/chart_definition_selectors";

import { WoundsOptimizedProgressChartComponent } from "src/components/wounds_optimized_chart_component";

// Actions
import { loadWoundsClinicalChartDataAction } from "src/actions/data_actions";

// HOC
import { withResourceLoaderFactory } from "src/hoc/factories";

function mapStateToProps(state) {
  const allWeeksContextSelector = createAllWeeksWoundsClinicalChartDataContextSelector();
  return {
    chartDefinition: woundsClinicalChartDefinitionSelector(state),
    loadStates: woundsClinicalChartResourceSelector.createLoadStateForContextSelector(
      allWeeksContextSelector
    )(state)
  };
}

export const WoundsOptimizedChartContainer = flowRight(
  // load 16 weeks worth of chart data given the selected date
  withResourceLoaderFactory({
    getResourceAction: loadWoundsClinicalChartDataAction,
    resourceSelectors: woundsClinicalChartResourceSelector,
    createContextSelector: createAllWeeksWoundsClinicalChartDataContextSelector,
    createPayloadSelector: createAllWeeksWoundsClinicalChartDataPayloadSelector
  }),
  connect(mapStateToProps)
)(WoundsOptimizedProgressChartComponent);
