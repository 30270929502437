// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { assessmentSummariesApi } from "src/api/assessment_summaries";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

export const assessmentSummariesResourceActions = createResourceActions(
  resourceNames.assessmentSummaries
);

export const loadAssessmentSummariesAction = createLoadDataAction({
  resourceName: resourceNames.assessmentSummaries,
  api: assessmentSummariesApi
});
