import { connect } from "react-redux";

// Component
import { SurveyEtiologyChartComponent } from "src/components/survey_etiology_chart_component";

// selectors
import { totalTallySelector } from "src/selectors/section_selectors/survey";

import { brandingSelector } from "src/selectors/ui_config_selectors/global";

function mapStateToProps(state) {
  return {
    totalTally: totalTallySelector(state),
    branding: brandingSelector(state)
  };
}

export const SurveyEtiologyChartContainer = connect(mapStateToProps)(
  SurveyEtiologyChartComponent
);
