import { resourceNames } from "src/constants";

export function createPatientQueryForPatientIdSelector({ patientId }) {
  return () => {
    if (patientId) {
      return `/${patientId}?include=${resourceNames.studies},payer_patients`;
    }

    return "";
  };
}
