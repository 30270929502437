import { connect } from "react-redux";
import React from "react";

// Constants
import { sections } from "src/constants";

// Selectors
import { localizationDictionaryForAllSchemasSelector } from "src/selectors/section_selectors/global";
import { woundTypesConfigurationFilterForSchemaPolicySelector } from "src/selectors/ui_config_selectors/global/wound_type_filter_configuration_selector";
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";

export function withSchemaFilterConfigurationWrapper(WrappedComponent) {
  class SchemaLocalizationDictionaryWrapper extends React.PureComponent {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state, ownProps) {
    const filterConfig = woundTypesConfigurationFilterForSchemaPolicySelector(
      state
    );
    return {
      schemaLocalizationDictionary: localizationDictionaryForAllSchemasSelector(
        state
      ),
      filterConfig,
      filterCheckedState: createSectionSelectors(
        sections[ownProps.section]
      ).createFilterCheckedStateSelector(filterConfig)(state)
    };
  }

  return connect(mapStateToProps)(SchemaLocalizationDictionaryWrapper);
}
