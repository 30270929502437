import { createSelector } from "reselect";
import get from "lodash.get";

export function createSignAndLockButtonVisibleSelector({ assessmentAnswer }) {
  return createSelector(() => {
    const lockState = get(assessmentAnswer, "attributes.lockState", null);
    const signState = get(assessmentAnswer, "attributes.signState", null);
    return signState === "unsigned" && lockState === "unlocked";
  });
}
