import React from "react";
import { Route, Switch } from "react-router-dom";
import get from "lodash.get";

// Containers
import { PatientDetailsContainer } from "src/containers/patient_details_container";
import { SeriesDetailsContainer } from "src/containers/series_details_container";
import { NonWoundAssessmentDetailsContainer } from "src/containers/non_wound_assessment_details_container";
import { ThermalDetailsContainer } from "src/containers/thermal_details_container";

// Components
import { NoPatientSelectedComponent } from "src/components/no_patient_selected_component";

// Legacy styles
import "src/containers/patients/style.less";

/**
 * Render the patient section
 */
export class PatientsSectionComponent extends React.PureComponent {
  renderPatientDetailsContainer = ({ match }) => {
    const patientId = get(match, "params.patientId");
    return <PatientDetailsContainer patientId={patientId} />;
  };

  render() {
    return (
      <div className="sm-bootstrap">
        <Switch>
          <Route
            exact
            path="/patients"
            component={NoPatientSelectedComponent}
          />
          <Route
            exact
            path="/patients/:patientId"
            render={this.renderPatientDetailsContainer}
          />
          <Route
            exact
            path="/patients/:patientId/series/:seriesId"
            component={SeriesDetailsContainer}
          />
          <Route
            exact
            path="/patients/:patientId/assessments/:assessmentId"
            component={NonWoundAssessmentDetailsContainer}
          />
          {this.props.janssenDashboardEnabled && (
            <Route
              exact
              path="/patients/:patientId/thermal"
              component={ThermalDetailsContainer}
            />
          )}
        </Switch>
      </div>
    );
  }
}
