/**
 * Create the payload for POSTing csv_exports
 * @param {Object} options
 * @param {string} options.type //type of resouce eg. csv_exports
 * @param {string} options.workerType //worker type to generate resource eg. assessment_summaries
 * @param {string} options.userId // user id of current user
 * @param {array} options.selectedLocationIds // ids of locations selected in sidebar filter
 * @param {array} options.answers // answers to filter with eg certain asssessment type, wound type etc
 * @param {string} options.lockstate // lock state of assessment eg locked/unlocked
 * @param {string} options.nameOrMrnOrCode // patient first name, last name, full name or MRN or code to filter with
 * @param {string} options.sort // field to sort ascending or descending by
 */
export function createPostPayload({
  type,
  workerType,
  userId,
  userOffset,
  parameters
}) {
  return {
    data: {
      type,
      attributes: {
        worker_type: workerType,
        user_id: userId,
        user_offset: userOffset,
        parameters
      }
    }
  };
}
