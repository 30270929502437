import Chart from "chart.js";

/**
 * Helper function to convert an image path to a dataURL (in a Promise).
 * @param {string} imagePath a local or remote path to the image
 * @return {Promise} promise that resolves to dataURL
 */
export function getImageDataUrlFromPath(imagePath) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();

    xhr.onload = function() {
      // Read image to data url
      var reader = new FileReader();
      reader.readAsDataURL(this.response);
      reader.onload = function() {
        resolve(this.result);
      };
    };
    xhr.onerror = function(e) {
      reject(e);
    };

    // Send request
    // NOTE: an XMLHttpRequest is used instead of loading directly to a canvas
    // in order to support IE 10. The canvas on IE 10 has no CORS support
    xhr.open("GET", imagePath, true);
    xhr.responseType = "blob";
    xhr.send();
  });
}

/**
 * Helper function to convert a local image path to a dataURL.
 * @param {string} imagePath the path to the local image
 * @return {Promise} promise that resolves to dataURL
 */
export function getImageDataUrlFromLocalPath(imagePath) {
  return new Promise((resolve, reject) => {
    // create new image
    let img = new Image();
    // onload callback
    img.onload = function() {
      // draw image to a canvas
      const canvas = document.createElement("canvas");
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;
      const context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);

      // convert to data url
      const encodedImg = canvas.toDataURL("image/png");

      // resolve promise
      resolve(encodedImg);
    };
    img.onerror = function(e) {
      reject(e);
    };

    // load image
    img.src = imagePath;
  });
}

/**
 * Helper function to render a 'chart.js' chart to a Image Data Url
 * @param {Object} chartDefinition object with chart data and config options
 * @param {Number} width the width of the desired output chart
 * @param {Number} height the height of the desired output chart
 * @return {String} Image Data Url string
 */
export function renderChartToImageDataUrl(chartDefinition, width, height) {
  // Create a canvas to draw the chart
  const canvas = document.createElement("canvas");

  // Set width and height of the canavas
  // Will be rendered at 96ppi
  canvas.width = width;
  canvas.height = height;

  // Make sure animation and responsivness turned off for chart
  // Otherwise the chart will render async
  chartDefinition.options.animation = false;
  chartDefinition.options.responsive = false;

  // Get context and render chart
  const context = canvas.getContext("2d");
  new Chart(context, chartDefinition);
  return canvas.toDataURL("image/png");
}
