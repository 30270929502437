import { connect } from "react-redux";

import { createSectionActions } from "src/ui_processors";
import { sections } from "src/constants";

import { SearchBarComponent } from "src/components/search_bar_component";
import { userNameSearchStringSelector } from "src/selectors/section_selectors/admin";

//Actions
import { selectUserTablePageNumberAction } from "src/actions/section_actions/admin";

//Translations
import i18n from "src/translations";

function mapStateToProps(state) {
  return {
    searchKey: "name",
    searchBarLabel: i18n.t(`headers.filterUsers`),
    searchString: userNameSearchStringSelector(state),
    placeHolder: i18n.t(`text.searchBarPlaceholders.name`)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSearchString: searchFilterObject => {
      dispatch(
        createSectionActions(sections.admin).setSearchString(searchFilterObject)
      );
    },
    onInputChange: () => {
      dispatch(selectUserTablePageNumberAction(0));
    }
  };
}

export const AdminUserNameSearchBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBarComponent);
