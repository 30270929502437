import { apiFactory } from "../api_factory";

export const usersApi = apiFactory({
  path: "/api/v4/users",
  useGetOverride: true
});

export const singleUserApi = apiFactory({
  path: "/api/v4/users"
});

//TO DO: Take out redundant api methods below when Resource Loader Factory can be used all across
import axios from "axios";
import { registerForCancellation } from "../cancellation";

/**
 * Fetch list of users by query string
 * @param {Object} payload For filter the records
 * @return {Promise}
 */
export function fetchUsersByQueryString(payload) {
  const config = {
    cancelToken: registerForCancellation("users"),
    headers: {
      "X-HTTP-Method-Override": "GET"
    }
  };

  return axios.post("/api/v4/users", payload, config);
}

/**
 * Fetch current user details
 * @return {Promise} Promise with the response
 */
export function fetchUser(userId) {
  let url = "/api/v4/users/" + userId + "?include=policies,roles";
  return axios.get(url, userId);
}

/**
 * Send welcome message for patient user
 * @return {Promise} Promise with the response
 */
export function sendWelcomeMessage(userId) {
  let url = "/api/v4/users/send-welcome-message?id=" + userId;
  return axios.get(url, userId);
}

/**
 * create user from the admin section
 * @param {Object} payload - responsible for sending the data to api.
 * @return {Promise}
 */
export function createUser(payload) {
  let config = {
    headers: {
      "Content-Type": "application/vnd.api+json"
    }
  };
  return axios.post("/api/v4/users", payload, config);
}

/**
 * Update user from the admin section
 * @param {Object} payload - responsible for sending the data to api.
 * @return {Promise}
 */
export function updateUser(payload) {
  let config = {
    headers: {
      "Content-Type": "application/vnd.api+json"
    }
  };
  return axios.patch(
    "/api/v4/users/" + payload.data.id,
    payload,
    config
  );
}
