import React from "react";

// Components
// TODO: refactor this
import { Row, Column } from "@sw/ui-kit";

export class NoPatientSelectedComponent extends React.PureComponent {
  render() {
    return (
      <div className="patients-view">
        <Row>
          <Column>
            <div className="loading-state">
              <p className="text-center">Choose a patient</p>
            </div>
          </Column>
        </Row>
      </div>
    );
  }
}
