import { combineReducers } from "redux";

// Reducers
import { createDataReducer } from "./create_data_reducer";
import { createLoadStateReducer } from "./create_load_state_reducer";
import { createMetaReducer } from "./create_meta_reducer";
import { createPagesReducer } from "./create_pages_reducer";

/**
 * Create a redux reducer for a json api resource
 * @param {string} name the resource name
 * @returns {Function} redux reducer
 */
export function createReducer(name) {
  return combineReducers({
    data: createDataReducer(name),
    loadState: createLoadStateReducer(name),
    meta: createMetaReducer(name),
    pages: createPagesReducer(name)
  });
}
