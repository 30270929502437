import React from "react";
import PropTypes from "prop-types";

import styles from "./style.module.less";

export class TabViewHeaderCountTitleComponent extends React.PureComponent {
  static propTypes = {
    count: PropTypes.number,
    title: PropTypes.string
  };

  render() {
    const { count, title } = this.props;

    return (
      <div className={styles["count-title-tab"]}>
        <h3>{count}</h3>
        <h4>{title}</h4>
      </div>
    );
  }
}
