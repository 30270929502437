// React
import PropTypes from "prop-types";

import React, { Component } from "react";

// Containers
import { PopulationTallyTableRowByLocationComponent } from "../population_tally_table_row_by_location_component";

//Components
import { PopulationTallyTableHeaderByLocationComponent } from "../population_tally_table_header_by_location_component";

// css
import styles from "../style.module.less";

export class PopulationTallyTableByLocationComponent extends Component {
  static propTypes = {
    talliesByLocation: PropTypes.array.isRequired
  };

  /**
   * Get the body for the tally table by location (all tally rows)
   */
  renderBody() {
    const { talliesByLocation } = this.props;
    return talliesByLocation.map((tally, index) => {
      return (
        <PopulationTallyTableRowByLocationComponent key={index} tally={tally} />
      );
    });
  }

  render() {
    return (
      <div>
        <table
          className={`table ${styles["population-tally-by-location-table"]}`}
        >
          <thead>
            <PopulationTallyTableHeaderByLocationComponent />
          </thead>
          <tbody>{this.renderBody()}</tbody>
        </table>
      </div>
    );
  }
}
