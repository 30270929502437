import { connect } from "react-redux";

//Translations
import i18n from "src/translations";

//Components
import { TextLinkComponent } from "src/components/ui_kit";

//Constants
import { sections } from "src/constants";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";
import { createSectionActions } from "src/ui_processors";

//Action
const searchUserNameFilterAction = createSectionActions(sections.admin)
  .setSearchString;

const selectUsersRolesFilterAction = SingleValueReduxUtil.createActionCreators(
  "selectedUserRoleId"
);

const selectUsersStateFilterAction = SingleValueReduxUtil.createActionCreators(
  "selectedUserState"
);

function mapStateToProps() {
  return {
    label: i18n.t(`actions.reset`)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClick() {
      dispatch(
        searchUserNameFilterAction({
          key: "name",
          value: ""
        })
      );
      dispatch(selectUsersRolesFilterAction.setValue(""));
      dispatch(selectUsersStateFilterAction.setValue(""));
    }
  };
}

export const AdminUserTableResetFiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TextLinkComponent);
