import { createSelector } from "reselect";
import { locationsDataSelector } from "../locations_data_selector";

/**
 * Selector for an array of all locations in the store
 */

export const allLocationsSelector = createSelector(
  locationsDataSelector,
  locations => {
    return Object.values(locations);
  }
);
