import React from "react";
import PropTypes from "prop-types";
import { CollapsibleContainerComponent } from "src/components/collapsible_container_component";
import { CheckboxList, CheckboxListItem } from "src/components/checkbox_list";
import { LoadingComponent } from "src/components/loading_component";
import useComplianceFilter from "src/pages/compliance/hooks/useComplianceFilter";
import { ComplianceContext } from "src/pages/compliance/components/compliance_provider_component.js";
import FiltersService from "src/pages/compliance/services/filters_service";

// SOME LEGACY STUFF NEEDED TO MAKE EVALUATIONS AND LOCATIONS WORK
import { connect } from "react-redux";
import { sections } from "src/constants";
import { createSectionActions } from "src/ui_processors/global/action_creators";
import EditSavedFilterModal from "./edit_saved_filter_modal";
const sectionActions = createSectionActions(sections.compliance);

function mapDispatchToProps(dispatch) {
  return {
    legacySelectAction(filterId) {
      dispatch(sectionActions.selectSavedFilter(filterId));
    },
    legacyDeselectAction() {
      dispatch(sectionActions.deselectSavedFilter());
    }
  };
}

function SavedFiltersSection({
  title,
  filterKey,
  legacySelectAction,
  legacyDeselectAction
}) {
  const { enabledFilters, disableFilters } = useComplianceFilter(filterKey);

  const { dispatch } = React.useContext(ComplianceContext);

  const [isLoading, setIsLoading] = React.useState(true);
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [legacyFilters, setLegacyFilters] = React.useState([]);
  const [savedFilterMappings, setSavedFilterMappings] = React.useState([]);
  // [
  //   {
  //     savedFilterId: '123',
  //     savedFilterValues: { evaluations: ['ev1'], clinicians: ['cli1'] }
  //   },
  //   {
  //     savedFilterId: '456',
  //     savedFilterValues: { evaluations: ['ev1'], locations: ['loc1'] }
  //   }
  // ]

  const storeFilters = data => {
    setLegacyFilters(data);

    const filterOptions = data.map(({ attributes, id }) => ({
      label: attributes.title,
      value: id
    }));
    setFilters(filterOptions);
  };

  const oldToNewKeyMappings = {
    clinicianOptions: "clinicians",
    visitFrequency: "visitFrequencies"
  };

  const storeFilterMappings = data => {
    const mappings = data.map(filter => {
      const { parameters } = filter.attributes;

      const valueEntries = parameters.filters.map(filterSection => {
        const key = oldToNewKeyMappings[filterSection.key] || filterSection.key;
        const value = filterSection.value.map(v => v.value);

        return [key, value];
      });

      const savedFilterValues = Object.fromEntries(valueEntries);

      return { savedFilterId: filter.id, savedFilterValues };
    });

    setSavedFilterMappings(mappings);
  };

  const loadFilters = async () => {
    try {
      const data = await FiltersService.saved();
      storeFilters(data);
      storeFilterMappings(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const overrideFilters = (key, values) => {
    dispatch({
      type: "OVERRIDE_FILTERS",
      payload: { filterKey: key, filterIds: values }
    });
  };

  const applySavedFilter = ({ savedFilterValues }) => {
    Object.entries(savedFilterValues).map(([key, value]) => {
      overrideFilters(key, value);
    });
  };

  const removeSavedFilter = ({ savedFilterValues }) => {
    Object.entries(savedFilterValues).map(([key]) => {
      overrideFilters(key, []);
    });
  };

  React.useEffect(() => {
    loadFilters();
  }, []);

  const isFilterEnabled = filterId => enabledFilters.includes(filterId);

  const mappingForFilterId = filterId =>
    savedFilterMappings.find(({ savedFilterId }) => savedFilterId === filterId);

  const selectSavedFilter = filterId => {
    const filterObject = legacyFilters.find(f => f.id === filterId);
    const filterMapping = mappingForFilterId(filterId);

    legacySelectAction(filterObject);
    overrideFilters(filterKey, [filterId]);
    applySavedFilter(filterMapping);
  };

  const deselectSavedFilter = filterId => {
    const filterMapping = mappingForFilterId(filterId);

    legacyDeselectAction();
    disableFilters([filterId]);
    removeSavedFilter(filterMapping);
  };

  const onClickFilter = filterId => {
    if (isFilterEnabled(filterId)) {
      deselectSavedFilter(filterId);
    } else {
      selectSavedFilter(filterId);
    }
  };

  const savedFilterRightIcon = filterId =>
    isFilterEnabled(filterId) ? "pencil" : undefined;

  const showEditModal = () => {
    setEditModalVisible(true);
  };

  const hideEditModal = () => {
    setEditModalVisible(false);
  };

  const onSubmitEditForm = () => {
    hideEditModal();
    loadFilters();
  };

  const selectedSavedFilter = () => {
    if (!legacyFilters.length || !enabledFilters.length) {
      return null;
    }

    const savedFilterId = enabledFilters[0];
    return legacyFilters.find(lf => lf.id === savedFilterId);
  };

  const filterItem = ({ label, value }) => (
    <CheckboxListItem
      key={value}
      label={label}
      value={value}
      checked={isFilterEnabled(value)}
      onChange={() => onClickFilter(value)}
      rightIcon={savedFilterRightIcon(value)}
      onRightIconClicked={() => showEditModal(value)}
    />
  );

  if (isLoading) {
    return <LoadingComponent />;
  } else {
    return (
      <CollapsibleContainerComponent label={title}>
        <CheckboxList>{filters.map(filterItem)}</CheckboxList>
        <EditSavedFilterModal
          visible={editModalVisible}
          onCancel={hideEditModal}
          onSubmit={onSubmitEditForm}
          savedFilter={selectedSavedFilter()}
        />
      </CollapsibleContainerComponent>
    );
  }
}

SavedFiltersSection.propTypes = {
  title: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired
};

const SavedFiltersSectionWrapper = connect(
  () => ({}),
  mapDispatchToProps
)(SavedFiltersSection);

export default SavedFiltersSectionWrapper;
