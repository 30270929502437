import { createSelector } from "reselect";
import { createWoundSeriesQuerySelector } from "src/selectors/api_selectors/evaluation_list/wound_series/create_wound_series_query_selector";

export function createWoundSeriesContextSelector() {
  return createSelector(
    createWoundSeriesQuerySelector(),
    queries => {
      return queries;
    }
  );
}
