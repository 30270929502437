import { createSelector } from "reselect";

// Constants
import { sections } from "src/constants";

// Selectors
import { locationsToDisplaySelector } from "src/selectors/data_selectors/locations";
import { createSectionSelectors } from "src/ui_processors";
const sectionSelectors = createSectionSelectors(sections.woundRisk);

/**
 * Location ids selector for the alerts tab
 * used to get the location ids that should be passed to the server to load data
 */
export const selectedLocationIdsSelector = createSelector(
  locationsToDisplaySelector,
  sectionSelectors.filtersSelector,
  (locationsToDisplay, filters) => {
    const locationFilter = filters.find(filter => filter.key == "locations");

    if (locationFilter && locationFilter.value.length) {
      return locationFilter.value.map(filt => filt.value);
    } else {
      return locationsToDisplay.map(loc => loc.id);
    }
  }
);
