import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { flowRight } from "lodash";
import { createStructuredSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { eventsResourceSelectors } from "src/selectors/data_selectors";
import { withResourceLoaderFactory } from "src/hoc/factories";

import {
  createLatestLockedEventForAssessmentAnswerGroupIdContextSelector,
  createLatestSignedEventForAssessmentAnswerGroupIdContextSelector
} from "src/selectors/context_selectors/events_for_assessment_answer_group_id";

import {
  createLatestLockedEventForAssessmentGroupIdQuerySelector,
  createLatestSignedEventForAssessmentGroupIdQuerySelector
} from "src/selectors/query_selectors/events_for_assessment_answer_group_id";

import {
  loadEventsAction,
  loadFacilitiesAction
} from "src/actions/data_actions";

// Components
import { NonWoundAssessmentSummaryComponent } from "src/components/non_wound_assessment_summary_component";

// Selectors
import {
  createUserForIdSelector,
  createAssessmentAnswerGroupForIdSelector,
  createPatientForIdSelector,
  facilitiesResourceSelectors
} from "src/selectors/data_selectors";
import { nonWoundPdfDownloadEnabledSelector } from "src/selectors/ui_config_selectors/patients";

import { createLastFacilityQueryForPatientIdSelector } from "src/selectors/api_selectors/global/patients";
import {
  brandingSelector,
  brandedLogoPngSelector
} from "src/selectors/ui_config_selectors/global";

// Selectors - legacy
import patientTrendSelectors from "@sw/selectors/patientTrendSelectors";

import { create } from "src/actions/flashMessageActions";
import { withPatientTrendsLoader } from "src/hoc";

function mapStateToProps(state, ownProps) {
  const idSelector = () => ownProps.match.params.patientId;

  // This is legacy code
  const structuredSelectors = createStructuredSelector({
    patientTrend: patientTrendSelectors.findEntity(idSelector)
  });

  const patient = createPatientForIdSelector(ownProps.match.params.patientId)(
    state
  );

  const patientLastFacilityId = get(
    patient,
    "attributes.lastFacilityId",
    undefined
  );

  return {
    assessmentAnswerGroup: createAssessmentAnswerGroupForIdSelector(
      ownProps.assessmentAnswer.attributes.assessmentAnswerGroupId
    )(state),
    user: createUserForIdSelector(ownProps.assessmentAnswer.attributes.userId)(
      state
    ),
    latestSignedEvent: eventsResourceSelectors.createDataForContextSelector(
      createLatestSignedEventForAssessmentAnswerGroupIdContextSelector({
        assessmentAnswerGroupId:
          ownProps.assessmentAnswer.attributes.assessmentAnswerGroupId
      })
    )(state),
    latestLockedEvent: eventsResourceSelectors.createDataForContextSelector(
      createLatestLockedEventForAssessmentAnswerGroupIdContextSelector({
        assessmentAnswerGroupId:
          ownProps.assessmentAnswer.attributes.assessmentAnswerGroupId
      })
    )(state),
    pdfDownloadEnabled: nonWoundPdfDownloadEnabledSelector(state),
    ...structuredSelectors,
    patient,
    patientLastFacility: facilitiesResourceSelectors.createDataForIdSelector(
      patientLastFacilityId
    )(state),
    branding: brandingSelector(state),
    brandedLogoPng: brandedLogoPngSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showPdfErrorView() {
      dispatch(
        create("Sorry, there was an error downloading your export", "error")
      );
    },
    showPdfTimeoutErrorMessage() {
      dispatch(create("PDF download timeout. Please refresh page", "error"));
    }
  };
}

// TO DO: Replace once lastFacilityId implemented and two containers are not needed #lastFacilityIdDelay
export const NonWoundAssessmentSummaryContainer = flowRight(
  withRouter,
  withPatientTrendsLoader,
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestSignedEventForAssessmentAnswerGroupIdContextSelector,
    createQuerySelector: createLatestSignedEventForAssessmentGroupIdQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestLockedEventForAssessmentAnswerGroupIdContextSelector,
    createQuerySelector: createLatestLockedEventForAssessmentGroupIdQuerySelector
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NonWoundAssessmentSummaryComponent);

export const NonWoundAssessmentSummaryWithPatientLastFacilityContainer = flowRight(
  withRouter,
  withPatientTrendsLoader,
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestSignedEventForAssessmentAnswerGroupIdContextSelector,
    createQuerySelector: createLatestSignedEventForAssessmentGroupIdQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestLockedEventForAssessmentAnswerGroupIdContextSelector,
    createQuerySelector: createLatestLockedEventForAssessmentGroupIdQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadFacilitiesAction,
    resourceSelectors: facilitiesResourceSelectors,
    createQuerySelector: createLastFacilityQueryForPatientIdSelector,
    createContextSelector: createLastFacilityQueryForPatientIdSelector
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NonWoundAssessmentSummaryComponent);
