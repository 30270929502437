/**
 * Set the url template that we will use to create links to patient profiles
 *
 * @export
 * @param {String} template
 * @returns {Object}
 */
export function setPatientLinkTemplate(template: String): Object {
  return {
    type: "SET_PATIENT_LINK_TEMPLATE",
    template
  };
}
