import { createSelector } from "reselect";

// Selectors
import { createFirstPageQuerySelector } from "../create_first_page_query_selector";

/**
 * create selector that gets context for the first page of revisions table
 */
export function createFirstPageContextSelector({ seriesId }) {
  return createSelector(
    createFirstPageQuerySelector({ seriesId }),
    firstPageQuery => firstPageQuery
  );
}
