import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { version } from "../package.json";

// Components
import App from "./App";

// Service Worker
import * as serviceWorker from "./serviceWorker";

// Styles
import "./style.less";

const envVarsString = document.getElementById("env-html")
  ? document.getElementById("env-html").textContent
  : "{}";
const envVars = JSON.parse(envVarsString);

const {
  API_HOST,
  AUTH_HOST,
  AUTH_PATH,
  SENTRY_DSN,
  MIXPANEL_TOKEN,
  MIXPANEL_PROXY_HOSTNAME,
  MIXPANEL_ENABLED,
  VERSION
} = envVars;

window.API_HOST = API_HOST;
window.AUTH_HOST = AUTH_HOST;
window.AUTH_PATH = AUTH_PATH;
window.SENTRY_DSN = SENTRY_DSN;
window.MIXPANEL_TOKEN = MIXPANEL_TOKEN;
window.MIXPANEL_PROXY_HOSTNAME = MIXPANEL_PROXY_HOSTNAME;
window.MIXPANEL_ENABLED = MIXPANEL_ENABLED;
window.Swift = {
  version: VERSION
};

// Check if string was replaced
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: version
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
