import { createSelector } from "reselect";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Selectors
import { createWoundTalliesPayloadSelector } from "../create_wound_tallies_payload_selector";

/**
 * Create a selector to get all contexts for location tallies
 * @param {string} tallyType
 * @returns {Function} selector
 */
export function createWoundTalliesContextSelector(tallyType) {
  return createSelector(
    createWoundTalliesPayloadSelector(tallyType),
    payload => {
      if (!payload) return payload;
      return buildQueryString(payload);
    }
  );
}
