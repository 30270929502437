import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { sections } from "src/constants";

// Components
import { SavedFilterVisualizationComponent } from "src/components/saved_filter_visualization_component";

// Selectors
import { woundTypesConfigurationFilterForSchemaPolicySelector } from "src/selectors/ui_config_selectors/global/wound_type_filter_configuration_selector";
import { locationsFilterConfigSelector } from "src/selectors/ui_config_selectors/global";

// UI Processors
import { createSectionSelectors } from "src/ui_processors";

// HOC
import { withSchemaLocalizationDictionaryWrapper } from "src/hoc/with_schema_localization_dictionary_wrapper";

const sectionSelectors = createSectionSelectors(sections.population);

function mapStateToProps(state) {
  // wound types
  const woundTypesFilterConfig = woundTypesConfigurationFilterForSchemaPolicySelector(
    state
  );
  const woundTypesFilterCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    woundTypesFilterConfig
  )(state);

  // Locations
  const locationConfig = locationsFilterConfigSelector(state);
  const locationCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    locationConfig
  )(state);

  return {
    filterUiConfigs: [woundTypesFilterConfig, locationConfig],
    filterCheckedStates: [woundTypesFilterCheckedState, locationCheckedState]
  };
}

export const PopulationSavedFilterVisualizationContainer = flowRight(
  withSchemaLocalizationDictionaryWrapper,
  connect(mapStateToProps)
)(SavedFilterVisualizationComponent);
