import { createSelector } from "reselect";
import get from "lodash.get";

//Constants
import { allFacilitiesOptionId } from "src/constants";

//Selectors
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";
import { createAlertRuleFormSelectedOrganizationSelector } from "../create_alert_rule_form_selected_organization_selector";

export function createAlertRuleFormFacilitiesByOrganizationSelector(formName) {
  return createSelector(
    facilitiesResourceSelectors.dataSelector,
    createAlertRuleFormSelectedOrganizationSelector(formName),
    (facilities, organizationId) => {
      if (!facilities) {
        return [];
      }

      const facilitiesByOrganization = Object.values(facilities)
        .filter(facility => {
          return (
            get(facility, "attributes.organizationId", "") === organizationId
          );
        })
        .map(facility => {
          return {
            id: facility.id,
            text: get(facility, "attributes.name", "")
          };
        });

      return facilitiesByOrganization.length
        ? [
            {
              id: allFacilitiesOptionId,
              text: "All Facilities"
            }
          ].concat(facilitiesByOrganization)
        : facilitiesByOrganization;
    }
  );
}
