import { createSelector } from "reselect";

// Selectors
import { createStudyIdForPatientIdSelector } from "src/selectors/data_selectors";

/**
 * TODO: refactor this in accordance with how we end up providing context to loaders and selectors
 * Create a load context selector for non wound assessments for a patient id
 * @param {patientId} string
 * @returns {string} context
 */
export function createNonWoundAssessmentAnswersLoaderContextSelector(
  patientId
) {
  return createSelector(
    createStudyIdForPatientIdSelector(patientId),
    studyId => {
      return studyId
        ? `studies/${studyId}/assessment-answers?filter[assessment_type]=non_wound`
        : "";
    }
  );
}
