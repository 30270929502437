import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Components
import { SeriesListItemComponent } from "src/components/series_list_item_component";

// Selectors
import {
  createDefaultFrameForSeriesIdSelector,
  createLatestAssessmentAnswerForSeriesIdSelector
} from "src/selectors/data_selectors";
import { createLatestRevisionForSeriesIdSelector } from "src/selectors/data_selectors/revisions/create_latest_revision_for_series_id_selector";

import { localizationDictionaryForSeriesIdSelector } from "src/selectors/section_selectors/global";

import { woundNumbersEnabledSelector } from "src/selectors/ui_config_selectors/global";
import { assessmentVersionSelector } from "src/selectors/config_selectors";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

function mapStateToProps(state, ownProps) {
  return {
    revision: createLatestRevisionForSeriesIdSelector(ownProps.series.id)(
      state
    ),
    assessmentVersion: assessmentVersionSelector(state),
    frame: createDefaultFrameForSeriesIdSelector(ownProps.series.id)(state),
    assessmentAnswer: createLatestAssessmentAnswerForSeriesIdSelector(
      ownProps.series.id
    )(state),
    woundNumbersEnabled: woundNumbersEnabledSelector(state),
    schemaLocalizationDictionary: localizationDictionaryForSeriesIdSelector(
      ownProps.series.id
    )(state),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state)
  };
}

export const SeriesListItemContainer = withRouter(
  connect(mapStateToProps)(SeriesListItemComponent)
);
