import removeFlashMessage from "./remove";

let lastFlashMessageID = 0;

export default function createFlashMessage(flashMessage, messageType) {
  return dispatch => {
    dispatch({
      type: "FLASH_MESSAGE_CREATE",
      id: lastFlashMessageID++,
      flashMessage,
      messageType
    });

    setTimeout(() => {
      dispatch(removeFlashMessage(flashMessage, messageType));
    }, 5000);
  };
}
