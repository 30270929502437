import { createSelector } from "reselect";

// Api
import { createGetPayload } from "src/api/revisions/payload_generators";

//Selectors
import { patientsSectionSelector } from "src/selectors/section_selectors/patients";
import { visibleEvaluationStatesPermissionSelector } from "src/selectors/config_selectors/visible_evaluation_states_permission";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const revisionsTablePageLimitSelector = SingleValueReduxUtil.createSelectors(
  "revisionsTablePageLimit",
  patientsSectionSelector
);

// Utils
import { buildQueryString } from "src/utils/build_query_string";

export function createFirstPageQuerySelector({ seriesId }) {
  return createSelector(
    revisionsTablePageLimitSelector.fieldSelector,
    visibleEvaluationStatesPermissionSelector,
    (pageLimit, assessmentStateFilters) => {
      const { lockState } = assessmentStateFilters;
      return `${seriesId}/revisions${buildQueryString(
        createGetPayload({
          pageNumber: 0,
          pageLimit,
          lockState,
          strikeout_state: "open",
          sort: "-created_at"
        })
      )}`;
    }
  );
}
