import { addReducer } from "incremental-redux-reducers";

const initialState = {
  patients: [],
  meta: {}
};

export default addReducer(
  "locationPatientNoRisk",
  function locationPatientNoRiskReducer(state = initialState, action) {
    switch (action.type) {
      case "LOCATION_NORISK_PATIENT_LOAD":
        let { patients, meta } = action;

        return {
          ...state,
          patients,
          meta
        };
      case "LOCATION_NORISK_PATIENT_LOAD_MORE":
        return {
          patients: [...state.patients, ...action.patients],
          meta: action.meta
        };
      default:
        return state;
    }
  }
);
