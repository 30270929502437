import { connect } from "react-redux";

// Component
import { PopulationIncidencePrevalenceChartComponent } from "src/components/population_incidence_prevalence_chart_component";

// Selectors
import { legacyPopulationIncidencePrevalenceChartDefinitionSelector } from "src/selectors/chart_definition_selectors";

function mapStateToProps(state) {
  return {
    chartDefinition: legacyPopulationIncidencePrevalenceChartDefinitionSelector(
      state
    )
  };
}

export const LegacyPopulationIncidencePrevalenceChartContainer = connect(
  mapStateToProps
)(PopulationIncidencePrevalenceChartComponent);
