// @flow
import { List } from "immutable";
import { retypeAction } from "redux-retype-actions";
import { batchActions } from "redux-batched-actions";
import services from "src/services/riskServices";
import organizationServices from "src/services/organizationServices";
import { RISKS } from "src/reducers/propertyReducers/constants";
import LocationModel from "src/models/LocationModel";
import { PropertyAction } from "src/utils/actionUtils";
import historyActions from "src/actions/historyActions";

import separateLocations from "./separateLocations";
import { calculateNortonAggregates, calculateNortonCharts } from "./calculate";

const getLocationIds = locationIds => locationIds.join(",");

class RiskPropertyActions extends PropertyAction {
  loadNortonPlusPatients = (
    riskName: string,
    locationIds: Array,
    startTime: string,
    endTime: string,
    cursor: any
  ) => {
    return (dispatch: $$dispatch) =>
      this.loadNortonPlusPatientsActions(
        riskName,
        locationIds,
        startTime,
        endTime,
        cursor
      ).then(dispatch);
  };
  loadNortonPlusPatientsActions = (
    riskName: string,
    locationIds: List<LocationModel>,
    startTime: string,
    endTime: string,
    cursor: any
  ) => {
    locationIds = getLocationIds(locationIds);
    return services
      .loadNortonPatients(riskName, locationIds, startTime, endTime, cursor)
      .then(({ patients, meta }) => {
        return retypeAction(
          `LOAD_NORTON_PATIENTS_${riskName}`,
          batchActions([
            this.baseActions[`${cursor ? "add" : "get"}Related`](
              `${riskName}PatientsNorton`,
              patients
            ),
            this.baseActions.update({
              nortonPlus: {
                patients: {
                  [riskName]: meta
                }
              }
            })
          ])
        );
      });
  };
  loadNortonPlusAggregates = (
    locationIds: List<LocationModel>,
    startTime: string,
    endTime: string
  ) => {
    locationIds = getLocationIds(locationIds);
    return dispatch => {
      services
        .loadNortonAggregates(locationIds, startTime, endTime)
        .then(({ aggregates }) => {
          const calculatedAggregates = calculateNortonAggregates(aggregates);
          dispatch(
            this.baseActions.update({
              nortonPlus: {
                aggregates: calculatedAggregates
              }
            })
          );
        });
    };
  };
  loadNortonPlusCharts = (
    locationIds: List<LocationModel>,
    startTime: string,
    endTime: string
  ) => {
    locationIds = getLocationIds(locationIds);
    return dispatch => {
      services
        .loadNortonCharts(locationIds, startTime, endTime)
        .then(({ chart }) => {
          const calculatedCharts = calculateNortonCharts(chart);
          dispatch(
            this.baseActions.update({
              nortonPlus: {
                charts: calculatedCharts
              }
            })
          );
        });
    };
  };
  loadLocations = (organizationId: $$id, currentAssessmentType: string) => {
    return dispatch => {
      return organizationServices
        .loadLocations(organizationId, { includeAll: true })
        .then(({ locations }) => {
          const locationsByAssessmentType = separateLocations(locations);
          const assessmentTypes = Object.keys(locationsByAssessmentType);
          const getRelatedActions = assessmentTypes.reduce(
            (finalResult, assessmentType) => {
              finalResult.push(
                this.baseActions.getRelated(
                  `${assessmentType}Locations`,
                  locationsByAssessmentType[assessmentType]
                )
              );
              return finalResult;
            },
            []
          );
          dispatch(
            retypeAction(
              "LOAD_RISK_LOCATIONS",
              batchActions([
                ...getRelatedActions,
                this.baseActions.get({ assessmentTypes })
              ])
            )
          );
          return locationsByAssessmentType[currentAssessmentType];
        });
    };
  };
  loadNortonPlusLocations = (organizationId: $$id) => {
    return dispatch => {
      return organizationServices
        .loadNortonPlusLocations(organizationId, {
          includeAll: true,
          includeLeaves: true
        })
        .then(({ locations }) => {
          dispatch(
            this.baseActions.getRelated("nortonPlusLocations", locations)
          );
          return locations;
        });
    };
  };
  goTo = riskName => dispatch =>
    dispatch(historyActions.push(`/risk/${riskName}`));
}

export default new RiskPropertyActions(RISKS);
