import { createSelector } from "reselect";
import { dataSelector } from "src/selectors/data_selectors/data_selector";

/**
 * Selector for the partners object in the data tree in the store
 */
export const partnersSelector = createSelector(
  dataSelector,
  data => data.partners
);
