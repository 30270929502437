import { createSelector } from "reselect";
import { checkedLocationIdsSelector } from "../checked_location_ids_selector";
import { locationsToDisplaySelector } from "src/selectors/data_selectors/locations";

export const selectedLocationIdsSelector = createSelector(
  locationsToDisplaySelector,
  checkedLocationIdsSelector,
  (locationsToDisplay, checkedLocationIds) => {
    if (checkedLocationIds.length) return checkedLocationIds;
    else return locationsToDisplay.map(loc => loc.id);
  }
);