import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";

export const hasSignWoundRevisionPermissionSelector = createSelector(
  createPolicyForInternalNameSelector(policyInternalNames.canSignFromDashboard),
  canSignFromDashboardRevision => {
    return (
      canSignFromDashboardRevision &&
      canSignFromDashboardRevision.attributes.policyValue
    );
  }
);
