import { List } from "immutable";
import { PropertyRecord } from "src/utils/modelUtils";

export const recordInput = {
  selectedLocationIds: new List(),
  partnerLocationIds: new List()
};

export default class PropertyLocationModel extends PropertyRecord(
  recordInput
) {}

export const determineLocations = (
  locations,
  { selectedLocationIds, partnerLocationIds }
) => {
  if (selectedLocationIds.size) return selectedLocationIds;
  if (partnerLocationIds.size) return partnerLocationIds;
  if (locations.size) return locations;
  return new List();
};
