import { createSelector } from "reselect";

// Payload generator
import { createGetPayload } from "src/api/saved_filters/payload_generators";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api query for the first page for saved filters
 * @param {Object} props
 * @param {string} props.section
 * @returns {function} selector
 */
export function createFirstPageSavedFiltersForSectionQuerySelector({
  section
}) {
  return createSelector(() => {
    return buildQueryString(
      createGetPayload({
        section,
        pageNumber: 0
      })
    );
  });
}
