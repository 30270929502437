import { createSelector } from "reselect";
import { locationsHierarchySelector } from "src/selectors/data_selectors/locations";
import { selectedLocationIdsSelector } from "../selected_location_ids_selector";

/**
 * Locations selector for ordered locations
 */
export const orderedSelectedLocationsSelector = createSelector(
  locationsHierarchySelector,
  selectedLocationIdsSelector,
  (locationsHeirarchy, selectedLocationIds) => {
    if (selectedLocationIds.length) {
      return locationsHeirarchy.all.filter(location => {
        return selectedLocationIds.includes(location.id);
      });
    } else {
      return locationsHeirarchy.all;
    }
  }
);
