import { adminActionTypes } from "src/action_types/section_action_types";
import { apiPageLimits } from "src/constants";

export function selectedWoundAlertRulesTablePageLimitReducer(
  state = apiPageLimits.woundAlertRules,
  action
) {
  switch (action.type) {
    case adminActionTypes.selectWoundAlertRulesTablePageLimit:
      return action.payload;
    case adminActionTypes.selectRulesFacility:
      return apiPageLimits.woundAlertRules;
    case adminActionTypes.selectRulesOrganization:
      return apiPageLimits.woundAlertRules;
    default:
      return state;
  }
}
