import React from "react";

export class LockedIconComponent extends React.Component {
  render() {
    return (
      <svg id="Text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108">
        <defs />
        <path
          className="cls-1"
          d="M76.47,46.62H75.32V33.49a20.12,20.12,0,0,0-20.1-20.1H52.78a20.12,20.12,0,0,0-20.1,20.1V46.62H31.53a9.88,9.88,0,0,0-9.85,9.85V84.76a9.88,9.88,0,0,0,9.85,9.85H76.47a9.88,9.88,0,0,0,9.85-9.85V56.47A9.88,9.88,0,0,0,76.47,46.62ZM40.89,33.49A11.9,11.9,0,0,1,52.78,21.6h2.44A11.9,11.9,0,0,1,67.11,33.49V46.62H40.89ZM57.21,71.77v6.08a3.21,3.21,0,1,1-6.42,0V71.77a5.34,5.34,0,1,1,6.42,0Z"
        />
      </svg>
    );
  }
}
