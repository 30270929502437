import React, { useState, useEffect } from "react";
import axios from "axios";

import { ThermalAssessmentViewComponent } from "src/components/thermal_assessment_view_component";
import { LoadingComponent } from "src/components/loading_component";

export function ThermalAssessmentListComponent(props) {
  const [thermalAssessments, setThermalAssessments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const url = `/api/v4/thermal-assessments?filter[patient_id]=${props.patientId}&sort=-created_at`;

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(url);
      setThermalAssessments(result.data.data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  } else {
    return (
      <div>
        {thermalAssessments.map(assessment => (
          <ThermalAssessmentViewComponent thermalAssessment={assessment} />
        ))}
      </div>
    );
  }
}
