// Actions
import { createLoadDataAction } from "../create_load_data_action";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { woundTalliesApi } from "src/api/wound_tallies";

export const woundTalliesResourceActions = createResourceActions(
  resourceNames.woundTallies
);

export const loadWoundTalliesAction = createLoadDataAction({
  resourceName: resourceNames.woundTallies,
  api: woundTalliesApi
});
