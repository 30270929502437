import { createSelector } from "reselect";
import moment from "moment";
import { framesResourceSelectors } from "../frames_resource_selectors";

/**
 * Creates a selector using revisionId that returns the most recent frame for the revision
 * @param {Object} revision
 * @return {Function} selector
 */
export function createLatestFrameForRevisionSelector(revision) {
  return createSelector(
    framesResourceSelectors.dataSelector,
    frames => {
      const sortedFrames = Object.values(frames)
        .filter(frame => frame.attributes.revisionId == revision.id)
        .sort((f1, f2) => {
          return moment(f1.attributes.createdAt).isAfter(
            f2.attributes.createdAt
          )
            ? -1
            : 1;
        });

      return sortedFrames.length ? sortedFrames[0] : undefined;
    }
  );
}
