import { createSelector } from "reselect";
import { policiesResourceSelectors } from "src/selectors/data_selectors/policies";
import { policies as policyConstants } from "src/constants";

export const hasManageRulesPermissionSelector = createSelector(
  policiesResourceSelectors.dataSelector,
  policies => {
    if (!Object.keys(policies).length) {
      return false;
    }

    const clinicalRulesEnginePolicy = Object.values(policies).find(policy => {
      return (
        policy.attributes.internalName ==
        policyConstants.enableClinicalRulesEngine
      );
    });

    if (clinicalRulesEnginePolicy) {
      return clinicalRulesEnginePolicy.attributes.policyValue;
    }

    return false;
  }
);
