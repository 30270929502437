import { globalActionTypes } from "src/action_types/section_action_types";

export function toggleExpandSidebarReducer(state = true, action) {
  switch (action.type) {
    case globalActionTypes.toggleExpandSidebar:
      return !state;
    default:
      return state;
  }
}
