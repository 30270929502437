import moment from "moment";

// Model
import { evaluatePredicate } from "src/models/assessment_answers";

// Utils
import { bulletListStringFromStrings } from "src/utils/bullet_list_string_from_strings";

/**
 * Create a pdfmake formated object for an answers/schema table
 * @param {Object} assessmentAnswer the assessment answer
 * @param {Object} schema the assessment schema
 * @returns {Object} the pdf table object
 */
export function tableForAnswersAndSchema({
  assessmentAnswer,
  assessmentSchema,
  revisionIsFirstInSeries
}) {
  const answers = assessmentAnswer.attributes.answersJson;
  const translations =
    assessmentSchema.attributes.schemaJson.localizationDictionary.base;
  const questions = assessmentSchema.attributes.schemaJson.questions;

  let rows = questions.reduce((agg, question) => {
    const { name, type } = question;

    const label = translations[`${name}.title`];
    const answer = answers[name];

    // Don't show empty values
    if (!answer || (type == 1 && answer == "0")) {
      return agg;
    }

    // Check visible predicate
    if (
      question.visiblePredicateJson &&
      !evaluatePredicate({
        assessmentAnswer,
        revisionIsFirstInSeries,
        predicate: question.visiblePredicateJson
      })
    ) {
      return agg;
    }

    const answerDisplayValue = getAnswerDisplayValue(
      name,
      answer,
      type,
      translations
    );

    agg.push([
      {
        text: label,
        style: "tableLabelCell"
      },
      {
        text: answerDisplayValue,
        style: "tableDataCell"
      }
    ]);

    return agg;
  }, []);

  if (!rows.length) {
    return null;
  }

  // Add a title row to the table
  rows.unshift([
    {
      text: translations.title.toUpperCase(),
      colSpan: 2,
      style: "tableHeaderCell"
    },
    {}
  ]);

  return [
    {
      table: {
        widths: ["50%", "*"],
        headerRows: 1,
        body: rows
      },
      layout: "pdfTableStyle"
    }
  ];
}

/**
 * Map an answer to a display string
 * @param {(string|string[])} answer the answer value
 * @param {number} type the type of question (ex. multiselect)
 * @param {Object} translations translations object
 * @returns {string}
 */
function getAnswerDisplayValue(name, answer, type, translations) {
  switch (type) {
    // Boolean value
    case 1:
      return answer == "0" ? null : "Yes";

    // integer
    case 2:
      return answer;

    // float
    case 3:
      return (Math.round(parseFloat(answer) * 100) / 100).toString();

    // string
    case 4:
      return answer;

    // date value
    case 5:
      return moment(answer).format("YYYY MM DD");

    // single select list (enumeration)
    case 6:
      return translations[`${name}.${answer}`];

    // Multi select list (flags)
    case 7:
      // Note - support is here for strings due to an android app error - this can be removed at some point
      const values = typeof answer == "string" ? [answer] : answer;
      const translatedAnswers = values.map(
        val => translations[`${name}.${val}`]
      );
      return bulletListStringFromStrings(
        translatedAnswers.sort().map(val => val)
      );
  }
}
