import get from "lodash.get";

export const extractTypeKeysFromSchema = schema => {
  if (!schema || !Object.keys(schema).length) return {};

  const questions = get(schema, "attributes.schemaJson.questions", []);

  const woundTypeQuestion = questions.find(question => {
    return question.name === "woundType";
  });

  return woundTypeQuestion ? woundTypeQuestion.enumConstants : [];
};
