// Constants
import {
  woundTypeKeyForAttribute,
  woundTypeKeyForSubtype,
  subtypeKeyValueForAttribute
} from "src/constants";

/**
 * Get answers filter from a filters array
 * applicable to: assessment-summaries, revisions
 */
export function answersFilterFromFilters(filters) {
  let answers = [];

  // Evaluations
  const evaluationsFilter = filters.find(
    filter => filter.key == "evaluations" || filter.key == "woundTypes"
  );
  if (evaluationsFilter && evaluationsFilter.value.length) {
    evaluationsFilter.value.forEach(filter => {
      if (filter.key === "assessment_type") {
        answers.push({ [filter.key]: filter.value });
      } else {
        let woundOption = {
          assessment_type: "wound",
          [filter.key]: filter.value
        };

        // add wound type for attributes
        if (woundTypeKeyForAttribute[filter.key]) {
          woundOption.woundType = woundTypeKeyForAttribute[filter.key];
        }

        // add wound type for subtypes
        if (woundTypeKeyForSubtype[filter.key]) {
          woundOption.woundType = woundTypeKeyForSubtype[filter.key];
        }

        // Add "subtypes" for attributes of subtypes
        if (subtypeKeyValueForAttribute[filter.key]) {
          const subtypeKey = subtypeKeyValueForAttribute[filter.key].key;
          const subtypeValue = subtypeKeyValueForAttribute[filter.key].value;
          woundOption[subtypeKey] = subtypeValue;
        }

        answers.push(woundOption);
      }
    });
  }

  return answers;
}
