import { expire } from "cookies-js";

/**
 * Expire cookie value for the key
 * @param {string} key
 * @returns {string} the value
 */
export function expireCookieValueForKey(key) {
  expire(key);
}
