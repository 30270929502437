import React from "react";

// TODO: Implement
export class MonitoringSectionComponent extends React.PureComponent {
  render() {
    return (
      <div>
        <nav className="navbar dashboard-control-navbar">
          <div className="navbar-header">
            <h1 className="navbar-text">Monitoring</h1>
          </div>
        </nav>
      </div>
    );
  }
}
