// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";

type $props = {
  className?: string,
  children?: any,
  fluid?: boolean
};
export default class GridRow extends PureComponent {
  props: $props;
  render() {
    const { children, className, fluid, ...otherProps } = this.props;
    const klass = classnames(fluid ? "row-fluid" : "row", className);
    return (
      <div {...otherProps} className={klass}>
        {children}
      </div>
    );
  }
}
