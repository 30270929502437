import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Translations
import i18n from "src/translations";

// Components
import {
  PanelHeaderRightComponent,
  PanelBodyComponent,
  PanelComponent
} from "src/components/panel";
import { TableComponent } from "src/components/tables/table_component";
import { ButtonComponent } from "src/components/button_component";
import { DatePickerRange } from "src/ui-kit";

const locationData = {
  "3cba5285-4313-4a05-9d8d-7bbe90c8d52e": {
    worsenedEpisodes: 21,
    totalEpisodes: 2057
  },
  "d4faff28-77d3-4ac3-aeb1-b41f9fb8e221": {
    worsenedEpisodes: 2,
    totalEpisodes: 223
  },
  "01763648-b249-4844-8697-26d1fa105c7b": {
    worsenedEpisodes: 7,
    totalEpisodes: 778
  },
  "3bce0e14-74e6-4d94-a6da-e593fdf7742b": {
    worsenedEpisodes: 10,
    totalEpisodes: 770
  },
  "6196a569-20ea-4320-be2a-14597829eea6": {
    worsenedEpisodes: 2,
    totalEpisodes: 286
  },
  "46b249df-9b2e-4a59-8a8c-854b8d736724": {
    worsenedEpisodes: 30,
    totalEpisodes: 5114
  },
  "c7af0bfc-768a-4e5a-ae88-fa0ed8e28b12": {
    worsenedEpisodes: 6,
    totalEpisodes: 750
  },
  "e1fd0490-9259-4749-b081-76a68e3476b5": {
    worsenedEpisodes: 13,
    totalEpisodes: 1182
  },
  "74e78880-34f8-477b-8ab5-455989355c20": {
    worsenedEpisodes: 9,
    totalEpisodes: 3000
  },
  "0e523dec-72f4-4ff4-9053-b1911de75d75": {
    worsenedEpisodes: 2,
    totalEpisodes: 182
  },
  "97912d41-ee4b-4faf-ac0d-f7da67932792": {
    worsenedEpisodes: 18,
    totalEpisodes: 2071
  },
  "e109a6e4-3b97-4582-88ab-045bac6728aa": {
    worsenedEpisodes: 9,
    totalEpisodes: 750
  },
  "c9fb27d9-03b3-4e78-8c21-8813a5d9f254": {
    worsenedEpisodes: 2,
    totalEpisodes: 154
  },
  "bf92f70b-7c2d-49e7-b856-9dde2dcd3453": {
    worsenedEpisodes: 5,
    totalEpisodes: 1000
  },
  "645cb32c-8d22-4617-83e1-07de597c7d89": {
    worsenedEpisodes: 2,
    totalEpisodes: 167
  },
  "62fb9fd3-ca1f-4102-a4d0-00bf682b6c48": {
    worsenedEpisodes: 30,
    totalEpisodes: 5416
  },
  "89f22ad7-4144-4025-8458-955af6e01b26": {
    worsenedEpisodes: 9,
    totalEpisodes: 3000
  },
  "dee04d9a-0f6f-4c74-b6a0-23c4a333a5f8": {
    worsenedEpisodes: 8,
    totalEpisodes: 616
  },
  "16aa4d48-5779-4c7a-946e-1a8e401b3912": {
    worsenedEpisodes: 8,
    totalEpisodes: 800
  },
  "aca43468-531e-4d15-a5a5-59f1b0dc923c": {
    worsenedEpisodes: 5,
    totalEpisodes: 1000
  },
  "5b909560-d74c-4884-a3e9-5270d9ca3014": {
    worsenedEpisodes: 25,
    totalEpisodes: 2468
  },
  "95becb1c-529d-4656-b2a0-75fe30747fb1": {
    worsenedEpisodes: 9,
    totalEpisodes: 819
  },
  "172ae195-3f27-4048-a82e-6781a14c0dd4": {
    worsenedEpisodes: 10,
    totalEpisodes: 834
  },
  "8d2dd560-4a90-4be4-b4bd-b32dc89aa222": {
    worsenedEpisodes: 1,
    totalEpisodes: 100
  },
  "57c6c227-fdf8-404d-8c46-0a5a1732372d": {
    worsenedEpisodes: 5,
    totalEpisodes: 715
  },
  "a5ee64ef-9e01-46ad-95e5-82e04ca0da8a": {
    worsenedEpisodes: 33,
    totalEpisodes: 5934
  },
  "e37c4757-d8a8-4753-94e7-bd530567e4ab": {
    worsenedEpisodes: 12,
    totalEpisodes: 1715
  },
  "f0d173c9-ff84-4f75-ae49-ae1aa3495235": {
    worsenedEpisodes: 10,
    totalEpisodes: 3334
  },
  "aa88de0f-7ca6-4ccf-a2f7-858c2654a7ef": {
    worsenedEpisodes: 5,
    totalEpisodes: 385
  },
  "a7fa8dd6-14cb-4653-9137-00cc76ef5f2d": {
    worsenedEpisodes: 6,
    totalEpisodes: 500
  },
  "30008503-8af1-49ab-9ce8-5cbf03257273": {
    worsenedEpisodes: 20,
    totalEpisodes: 2296
  },
  "06c8f652-1333-4557-8e27-12d5a6c5de94": {
    worsenedEpisodes: 6,
    totalEpisodes: 462
  },
  "108562a3-11e6-4b02-aaea-6fc92760c4e2": {
    worsenedEpisodes: 8,
    totalEpisodes: 1000
  },
  "eb170357-8992-4dae-adb4-cfc2498f1816": {
    worsenedEpisodes: 4,
    totalEpisodes: 667
  },
  "7cf01d66-2940-4edb-a381-f37cf19ae69e": {
    worsenedEpisodes: 2,
    totalEpisodes: 167
  },
  "0045e005-9df8-49d1-b252-238ae7234a41": {
    worsenedEpisodes: 35,
    totalEpisodes: 3214
  },
  "c8dc6e89-8465-4734-8155-837d86466838": {
    worsenedEpisodes: 9,
    totalEpisodes: 750
  },
  "96759d89-19fb-4e94-8ab6-2e679dad8f74": {
    worsenedEpisodes: 11,
    totalEpisodes: 1100
  },
  "e8aea1d4-e967-4509-8639-2aff4c9a1084": {
    worsenedEpisodes: 2,
    totalEpisodes: 182
  },
  "60c94ddb-008c-45e8-adaa-a67ee742676a": {
    worsenedEpisodes: 13,
    totalEpisodes: 1182
  },
  "f5faac7f-a32b-40b6-ac2d-e6b068e3eacf": {
    worsenedEpisodes: 30,
    totalEpisodes: 6367
  },
  "bc6742b9-1e15-4638-a9a9-c257d66f8600": {
    worsenedEpisodes: 12,
    totalEpisodes: 3000
  },
  "a1b4096b-f806-4407-902e-6380f30de88c": {
    worsenedEpisodes: 7,
    totalEpisodes: 1400
  },
  "d1cdb8b3-53eb-4dfb-826c-6891e85e0491": {
    worsenedEpisodes: 4,
    totalEpisodes: 800
  },
  "10ca9985-062c-4b04-841e-af7a6e2acd5c": {
    worsenedEpisodes: 7,
    totalEpisodes: 1167
  },
  "d7f0f8af-7d4d-4d25-92eb-c06738f470e5": {
    worsenedEpisodes: 18,
    totalEpisodes: 2301
  },
  "f25efc82-7069-4b49-a208-703dfd51951d": {
    worsenedEpisodes: 8,
    totalEpisodes: 1000
  },
  "5f959121-1b4e-4ad4-9df8-a9f2043f9582": {
    worsenedEpisodes: 2,
    totalEpisodes: 167
  },
  "48428e93-4522-4893-91a2-bc0ff376209c": {
    worsenedEpisodes: 5,
    totalEpisodes: 834
  },
  "7e73f9a1-71b5-43ed-829c-6d1053abc255": {
    worsenedEpisodes: 3,
    totalEpisodes: 300
  },
  "96d2361d-eac6-4b7d-adbb-080466c38977": {
    worsenedEpisodes: 33,
    totalEpisodes: 2935
  },
  "b5100e9c-83ce-4c6b-b03c-d92c1022f2e6": {
    worsenedEpisodes: 11,
    totalEpisodes: 1223
  },
  "fc48c849-c777-4921-a84a-c1179ab2bcab": {
    worsenedEpisodes: 3,
    totalEpisodes: 250
  },
  "875af650-43ab-42c9-aa53-d507532c100c": {
    worsenedEpisodes: 6,
    totalEpisodes: 462
  },
  "6cf6a273-75e2-4b51-9702-4cfc22efe853": {
    worsenedEpisodes: 13,
    totalEpisodes: 1000
  },
  "2bc67649-0f4f-47d9-bfc7-29114229ad88": {
    worsenedEpisodes: 23,
    totalEpisodes: 3701
  },
  "5dec60f8-13b9-405d-b4ca-2724d988eeac": {
    worsenedEpisodes: 5,
    totalEpisodes: 834
  },
  "4bcff5b9-5def-45d9-beba-05c90ef31ae4": {
    worsenedEpisodes: 10,
    totalEpisodes: 1667
  },
  "23ec5398-fe85-4b31-b4c4-56f706cedfd3": {
    worsenedEpisodes: 6,
    totalEpisodes: 1000
  },
  "614db745-c598-44e5-9700-f9877d70595c": {
    worsenedEpisodes: 2,
    totalEpisodes: 200
  },
  "36ded8b4-df88-4512-8551-0dc0da0740f8": {
    worsenedEpisodes: 28,
    totalEpisodes: 3018
  },
  "70ccfc78-c301-4a8a-a760-f43e6d4b1c60": {
    worsenedEpisodes: 6,
    totalEpisodes: 546
  },
  "37409cd7-21be-49e8-b58b-8c9d86404779": {
    worsenedEpisodes: 7,
    totalEpisodes: 584
  },
  "180b4283-3588-46b7-af28-a4b069dcfb49": {
    worsenedEpisodes: 2,
    totalEpisodes: 667
  },
  "4757f0ad-bc21-4c3e-8b27-6d4d655f601a": {
    worsenedEpisodes: 10,
    totalEpisodes: 1000
  },
  // ZONE 5 missing
  "ff6cf821-c752-45db-b0e0-376967ade56c": {
    worsenedEpisodes: 3,
    totalEpisodes: 221
  },
  "f95029a1-173b-4ce3-b800-ed4a9bdc35a1": {
    worsenedEpisodes: 31,
    totalEpisodes: 3387
  },
  "056053f9-06e1-4ccc-ab93-7bf54772af61": {
    worsenedEpisodes: 6,
    totalEpisodes: 750
  },
  "b6dcbda8-f9e6-4ba4-b81b-4945f62275f7": {
    worsenedEpisodes: 8,
    totalEpisodes: 800
  },
  "19f3eacb-b72a-4977-8060-c1fe1d5e516a": {
    worsenedEpisodes: 6,
    totalEpisodes: 462
  },
  "9ed65168-fb78-49aa-9907-43726e24b6a2": {
    worsenedEpisodes: 11,
    totalEpisodes: 1375
  },
  "4a098d53-f714-4ae3-87e8-2ec7d39eee50": {
    worsenedEpisodes: 22,
    totalEpisodes: 2005
  },
  "21fc714f-f541-4b19-91b5-54dd4bb03418": {
    worsenedEpisodes: 1,
    totalEpisodes: 91
  },
  "47cfa69d-a986-4de8-93d9-aeed65d73747": {
    worsenedEpisodes: 4,
    totalEpisodes: 400
  },
  "a0d8a625-d45b-4591-bce0-b9323dabe7e4": {
    worsenedEpisodes: 11,
    totalEpisodes: 847
  },
  "204ce46d-8d95-4371-a042-4331716f6a3c": {
    worsenedEpisodes: 6,
    totalEpisodes: 667
  }
};

export class OutcomesSectionComponent extends React.Component {
  static propTypes = {
    selectedLocations: PropTypes.array.isRequired
  };

  state = {
    startDate: moment().startOf("month"),
    endDate: moment()
  };

  handleDatePickerChange = dateData => {
    this.setState({
      startDate: dateData.startDate,
      endDate: dateData.endDate
    });
  };

  /**
   * TODO: Move this to a central component that renders bar according to Route Switch
   * Renders the control bar on the top of the page
   */
  renderControlNavbar() {
    return (
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">Outcomes</h1>
        </div>

        <form className="navbar-form navbar-right">
          <span className="control-navbar-element">
            <DatePickerRange
              allowSameDayRange={true}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              // unitRange={this.props.dateSelection.unitRange}
              update={this.handleDatePickerChange}
            />
          </span>
        </form>
      </nav>
    );
  }

  generateTableData() {
    return this.props.selectedLocations.map(loc => {
      const worsenedEpisodes = locationData[loc.id].worsenedEpisodes;
      const totalEpisodes = locationData[loc.id].totalEpisodes;

      return {
        name: loc.name,
        worsenedEpisodes,
        totalEpisodes,
        qualityScore:
          ((worsenedEpisodes / totalEpisodes) * 100).toFixed(2) + " %"
      };
    });
  }

  render() {
    const tableColumns = [
      {
        text: "Location",
        dataField: "name"
      },
      {
        text: "Number of Worsened Episodes",
        dataField: "worsenedEpisodes"
      },
      {
        text: "Total Episodes",
        dataField: "totalEpisodes"
      },
      {
        text: "Quality Score",
        dataField: "qualityScore"
      }
    ];

    return (
      <div>
        {this.renderControlNavbar()}
        <PanelComponent labelShow="OASIS Outcome Measures - Pressure Injuries">
          <PanelHeaderRightComponent>
            <ButtonComponent>{i18n.t("actions.createCSV")}</ButtonComponent>
          </PanelHeaderRightComponent>
          <PanelBodyComponent>
            <TableComponent
              data={this.generateTableData()}
              keyField="id"
              columns={tableColumns}
            />
          </PanelBodyComponent>
        </PanelComponent>
      </div>
    );
  }
}
