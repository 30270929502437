import { connect } from "react-redux";
import flowRight from "lodash.flowright";

import SingleValueReduxUtil from "src/ui_processors/single_value";

// Component
import { SideBarOptionsFilterComponent } from "src/components/side_bar_options_filter_component";

// Selectors
import { patientsSectionSelector } from "src/selectors/section_selectors/patients";

const dischargedOptionSelectedSelectors = SingleValueReduxUtil.createSelectors(
  "dischargedOptionSelected",
  patientsSectionSelector
);

//Actions
const dischargedOptionSelectedActions = SingleValueReduxUtil.createActionCreators(
  "dischargedOptionSelected"
);

function mapStateToProps(state) {
  return {
    dischargedOptionSelected: dischargedOptionSelectedSelectors.fieldSelector(
      state
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendDischargedOptionSelectedAction(enabled) {
      dispatch(dischargedOptionSelectedActions.setValue(enabled));
    }
  };
}

export const SideBarOptionsFilterContainerPatients = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SideBarOptionsFilterComponent);
