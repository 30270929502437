import React from "react";
import PropTypes from "prop-types";

export class PatientOverviewTabContentComponent extends React.Component {
  static propTypes = {
    //TO DO: Set the right proptypes, using tab name as content temporarily #PatientOverviewImplementation
    content: PropTypes.string
  };

  render() {
    const { content } = this.props;
    return <div>{content}</div>;
  }
}
