export const woundAge = {
  chronic: "Over 2 years old",
  new: "New",
  nineMonths: "6 - 9 months old",
  oneMonth: "1 month old",
  oneWeek: "1 week old",
  sixMonths: "3 - 6 months old",
  threeMonths: "1 - 3 months old",
  twelveMonths: "9 - 12 months old",
  twoWeeks: "2 weeks old",
  twoYears: "1 - 2 years old",
  unknown: "Age Unknown"
};
