import { connect } from "react-redux";

// Components
import { AssessmentAnswerHistoryListItemComponent } from "src/components/assessment_answer_history_list_item_component";

// Selectors
import { createUserForIdSelector } from "src/selectors/data_selectors";

function mapStateToProps(state, ownProps) {
  return {
    user: createUserForIdSelector(ownProps.assessmentAnswer.attributes.userId)(
      state
    )
  };
}

export const AssessmentAnswerHistoryListItemContainer = connect(
  mapStateToProps
)(AssessmentAnswerHistoryListItemComponent);
