import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// Constants
import { loadStates } from "src/constants";

// Components
import { SideBarPatientsFilterRowComponent } from "src/components/side_bar_patients_filter_row_component";
import { Button } from "src/ui-kit";

// HOC
import { withLoadingComponentFactory } from "src/hoc/factories";
const LoadingComponent = withLoadingComponentFactory({
  analyticsEventName: "RENDER_PATIENTS_PATIENT_LIST"
})();

// Styles
import styles from "./style.module.less";

export class PatientListComponent extends React.PureComponent {
  static propTypes = {
    patients: PropTypes.array.isRequired,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
    firstPageContext: PropTypes.string,
    match: PropTypes.object.isRequired,
    loadingObjectsByContext: PropTypes.object,

    sendIncrementPageAction: PropTypes.func.isRequired
  };

  onLoadMoreClicked = () => {
    this.props.sendIncrementPageAction(this.props.firstPageContext);
  };

  render() {
    const {
      patients,
      currentPage,
      pageCount,
      loadingObjectsByContext
    } = this.props;

    const showLoading =
      !loadingObjectsByContext ||
      !Object.keys(loadingObjectsByContext).length ||
      Object.values(loadingObjectsByContext).some(
        loadingObject =>
          !loadingObject.loadState ||
          loadingObject.loadState == loadStates.loading
      );

    const showNoResults = !showLoading && !patients.length;

    const showLoadMore = currentPage < pageCount - 1;

    const patientList = patients.map(patient => {
      let classnamesObject = {};
      classnamesObject[styles["active"]] =
        this.props.match.params.patientId === patient.id;
      const classname = classnames(classnamesObject);

      return (
        <li className={classname} key={patient.id}>
          <SideBarPatientsFilterRowComponent patient={patient} />
        </li>
      );
    });

    return (
      <div className={styles["patient-filter-container"]}>
        {showNoResults ? <div>No Results</div> : <ul>{patientList}</ul>}
        <LoadingComponent loadingObjectsByContext={loadingObjectsByContext} />
        {!showLoading && showLoadMore && (
          <div className={styles["load-more-container"]}>
            <Button className="btn-default" onClick={this.onLoadMoreClicked}>
              Load More...
            </Button>
          </div>
        )}
      </div>
    );
  }
}
