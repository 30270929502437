import { combineReducers } from "redux";
import { checkedLocationIdsReducer } from "./checked_location_ids_reducer";
import { patientSearchStringReducer } from "./patient_search_string_reducer";

import ValueArraysByKey from "src/ui_processors/value_arrays_by_key";

//Section Reducer Factory
import {
  createSectionFiltersReducer,
  createSectionSelectedFilterIdReducer
} from "src/ui_processors/global/reducer_creators";

//Section UI Processor Reducers
import { revisionsComparisonReducer } from "src/ui_processors/patients/reducers";
import SingleValue from "src/ui_processors/single_value";

// Constants
import { sections } from "src/constants";

export const patientsSectionReducers = combineReducers({
  checkedLocationIds: checkedLocationIdsReducer,
  patientSearchString: patientSearchStringReducer,
  filters: createSectionFiltersReducer(sections.patients),
  selectedFilterId: createSectionSelectedFilterIdReducer(sections.patients),
  revisionsToCompare: revisionsComparisonReducer,
  treatmentRevisionsToDisplayBySeries: ValueArraysByKey.createReducer(
    "treatmentRevisionsToDisplayBySeries"
  ),
  patientOverviewSelectedTab: SingleValue.createReducer(
    "patientOverviewSelectedTab",
    ""
  ),
  revisionsTablePageLimit: SingleValue.createReducer(
    "revisionsTablePageLimit",
    25
  ),
  dischargedOptionSelected: SingleValue.createReducer(
    "dischargedOptionSelected",
    false
  ),
  revisionComparisonTabSelected: SingleValue.createReducer(
    "revisionComparisonTabSelected",
    false
  ),
  bulkSelectedTab: SingleValue.createReducer("bulkSelectedTab", "measurement"),
  bulkDisplayTrace: SingleValue.createReducer("bulkDisplayTrace", true),
  revisionEnabledToBeEdited: SingleValue.createReducer(
    "revisionEnabledToBeEdited",
    ""
  )
});
