import { createSelector } from "reselect";
import { totalTallySelector } from "src/selectors/section_selectors/survey";
import { createWoundSurveyBarChart } from "src/charts";

export const surveyEtiologyChartDefinitionSelector = createSelector(
  totalTallySelector,
  totalTally => {
    return createWoundSurveyBarChart(totalTally);
  }
);
