import { getEtiologyTable } from "../get_etiology_table";
import { getChartImage } from "../get_chart_image";

// Translations
import i18n from "src/translations";

/**
 * Get the content (body) portion of the Assessment PDF, Formatted for pdfMake
 * @param {Object} data
 * @param {Object} data.talliesData - the talliesData
 * @param {boolean} data.excludeNewHealed - the excludeNewHealed
 * @param {Object} data.assessmentVersion
 
 * @param {Object} images
 * @param {string} images.woundChart - data url of wound
 * @return {Object} JSON representation of the pdf content
 */

export function getBody(data, images) {
  const {
    talliesData,
    schemaLocalizationDictionary,
    subtypeKeysForSchema
  } = data;

  // construct pdf body
  let body = [];

  // Graphs
  body.push({
    columns: [
      [getChartImage(images.woundChart)],
      [
        {
          text: [
            { text: `${i18n.t("data.acquired.default.POA")} - `, bold: true },
            `${i18n.t("text.surveyPDFChartAcquiredTooltips.POA")}`
          ],

          color: "#a79f9e",
          fontSize: 10,
          absolutePosition: { x: 600, y: 200 }
        },
        {
          text: [
            { text: `${i18n.t("data.acquired.default.IHA")} - `, bold: true },
            `${i18n.t("text.surveyPDFChartAcquiredTooltips.IHA")}`
          ],

          color: "#a79f9e",
          fontSize: 10,
          absolutePosition: { x: 600, y: 240 }
        },
        {
          text: [
            { text: `${i18n.t("data.acquired.default.IHAw")} - `, bold: true },
            `${i18n.t("text.surveyPDFChartAcquiredTooltips.IHAw")}`
          ],

          color: "#a79f9e",
          fontSize: 10,
          absolutePosition: { x: 600, y: 280 }
        },
        {
          text: [
            { text: `${i18n.t("data.acquired.default.PIHAw")} - `, bold: true },
            `${i18n.t("text.surveyPDFChartAcquiredTooltips.PIHAw")}`
          ],

          color: "#a79f9e",
          fontSize: 10,
          absolutePosition: { x: 600, y: 320 }
        }
      ]
    ],
    style: "columnBody",
    pageBreak: "after"
  });

  body.push(
    getEtiologyTable(
      talliesData,
      schemaLocalizationDictionary,
      subtypeKeysForSchema
    )
  );

  return [...body];
}
