import { apiFactory } from "../api_factory";

export const customRolesApi = apiFactory({ path: "/api/v4/custom-roles" });

//TO DO: Take out redundant api methods below when Resource Loader Factory can be used all across
import axios from "axios";
import { registerForCancellation } from "../cancellation";
import { apiPageLimits } from "src/constants";

export function createCustomRolesGetPayload() {
  return {
    page: {
      offset: 0,
      limit: apiPageLimits.customRoles
    }
  };
}

/**
 * Fetch roles
 * @return {Promise} Promise with the response
 * @param {Object} filters- filters for facilities,partners and organization
 */
export function fetchCustomRoles(filters) {
  const facility_ids = filters.facility_ids ? filters.facility_ids.join() : "";

  const organization_ids = filters.organization_ids
    ? filters.organization_ids.join()
    : "";

  let locationFilterString = organization_ids + "," + facility_ids;

  const payload = {
    filter: {
      location: locationFilterString ? locationFilterString : ""
    },
    page: { limit: 1000 }
  };

  const config = {
    cancelToken: registerForCancellation("custom-roles"),
    headers: {
      "X-HTTP-Method-Override": "GET"
    }
  };
  return axios.post("/api/v4/custom-roles", payload, config);
}
/**
 * Create role
 * @param {Object} payload - responsible for sending the data to api.
 * @return {Promise} Promise with the response
 */
export function createCustomRole(payload) {
  let config = {
    headers: {
      "Content-Type": "application/vnd.api+json"
    }
  };
  return axios.post("/api/v4/custom-roles", payload, config);
}

/**
 * update patient from the admin section
 * @param {Object} payload - responsible for sending the data to api.
 * @return {Promise}
 */
export function updateRole(payload) {
  let config = {
    headers: {
      "Content-Type": "application/vnd.api+json"
    }
  };
  return axios.patch(
    "/api/v4/custom-roles/" + payload.data.id,
    payload,
    config
  );
}
