import { createSelector } from "reselect";
import { facilitiesResourceSelectors } from "../facilities_resource_selectors";
import { sortByName } from "src/utils/sort_by_name";

export const facilitiesSortedByNameSelector = createSelector(
  facilitiesResourceSelectors.dataSelector,
  facilities => {
    return sortByName(Object.values(facilities));
  }
);
