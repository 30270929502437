export const adminActionTypes = {
  selectOrganizations: "ADMIN_SELECT_ORGANIZATIONS",
  deselectOrganizations: "ADMIN_DESELECT_ORGANIZATIONS",

  selectFacilities: "ADMIN_SELECT_FACILITIES",
  deselectFacilities: "ADMIN_DESELECT_FACILITIES",

  selectedPartner: "SELECTED_PARTNER",

  selectPatientTablePageNumber: "ADMIN_SELECT_PATIENT_TABLE_PAGE_NUMBER",
  selectPatientTablePageLimit: "ADMIN_SELECT_PATIENT_TABLE_PAGE_LIMIT",

  selectUserTablePageNumber: "ADMIN_SELECT_USER_TABLE_PAGE_NUMBER",
  selectUserTablePageLimit: "ADMIN_SELECT_USER_TABLE_PAGE_LIMIT",

  selectWoundAlertRulesTablePageNumber:
    "ADMIN_SELECT_WOUND_ALERT_RULES_TABLE_PAGE_NUMBER",
  selectWoundAlertRulesTablePageLimit:
    "ADMIN_SELECT_WOUND_ALERT_RULES_TABLE_PAGE_LIMIT",

  selectRiskAlertRulesTablePageNumber:
    "ADMIN_SELECT_RISK_ALERT_RULES_TABLE_PAGE_NUMBER",
  selectRiskAlertRulesTablePageLimit:
    "ADMIN_SELECT_RISKfedgfrdc_ALERT_RULES_TABLE_PAGE_LIMIT",

  setAdmissionStatus: "ADMIN_SET_ADMISSION_STATUS",
  deselectAdmissionStatus: "ADMIN_DESELECT_ADMISSION_STATUS",

  setMonitorAtHomeStatus: "ADMIN_SET_MONITOR_AT_HOME_STATUS",
  deselectMonitorAtHomeStatus: "ADMIN_DESELECT_MONITOR_AT_HOME_STATUS",

  selectRulesOrganization: "ADMIN_SELECT_RULES_ORGANIZATION",
  selectRulesFacility: "ADMIN_SELECT_RULES_FACILITY"
};
