import React from "react";
import SwiftLogoComponent from "src/components/logo_component";

export default class DefaultSignInComponent extends React.PureComponent {
  render() {
    return (
      <div className="sign-in-content primary-background">
        <div className="row">
          <div className="swift-logo col-xs-12 col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3 text-center">
            <SwiftLogoComponent />
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}
