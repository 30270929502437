export const permissionSets = [
  "none",
  "read",
  "read,edit,add",
  "read,edit,add,delete"
];

export const permissionSetsAssessmentState = [
  "unlocked,locked,signed,unsigned,open,stricken",
  "unlocked",
  "locked",
  "signed",
  "unsigned",
  "open",
  "stricken"
];

export const permissionTypeAccess = {
  true: "TRUE",
  false: "FALSE",
  add: "add"
};

// in the role's data, we don't ahve the permission type's internal name, only the id
// since those ids come from the seeded data, they will be constant across all evs and deployements
export const permissionTypeIds = {
  user_access: "da10041b-182c-46ad-84d6-aa7fc5834332",
  patient_access: "d091a2f6-0db0-46a2-92a0-32c52f503f9c",
  allow_revision_create: "db138094-38d0-43ca-bc1f-35764a175676",
  region_access: "90ad0d4c-d1b3-4557-9cce-041126bafacf",
  wound_assessment_access: "cf91aad5-d4fb-4311-8010-b881918ca55d",
  patient_assessment_access: "bda80e60-3e7e-485e-9fad-94ac195af7b6",
  order_access: "cc2b2f39-77ac-474a-a028-54f20a7c2c4c",
  care_plan_access: "ff6c2584-93cf-4e4a-afc3-b98922f12992",
  note_access: "079dcf41-ad36-4b2f-9b94-289b6e859800",
  messaging: "7baa6886-b6dd-4ecc-afe3-fbbe9119b238",
  reporting: "7c95a3e1-b92f-4559-81ee-78f47d3157d4",
  allow_edit_other_users_work: "06763f23-c642-4e66-88ad-8ea7bc85b966",
  allow_user_bulk_import: "cb2b2525-5c6b-4dff-9e00-702dc34ca2cc",
  allow_patient_bulk_import: "db950a81-3a27-4ed1-a6c9-def134d2a307",
  allow_admin_dashboard: "ebbf84af-de72-4a6d-864e-0a1283c1b3e2",
  rule_access: "dde0aeb7-b9c0-466f-af6b-6481afb4f16f",
  can_lock_from_dashboard: "a9d579fe-c0ab-42f6-95ad-5505cebe40c3",
  can_sign_from_dashboard: "1709daff-ed27-4c4c-b0d8-c62fc22add03",
  can_unlock_from_dashboard: "d21d198e-3df5-4dbc-9053-98daf0de0414",
  can_reopen_from_dashboard: "a2539cad-3d7e-4320-b5ad-3a32e80fe790",
  assessment_state_viewable: "43c80a5c-e0ec-4c90-8922-cbe96c49876e",
  can_lock_from_mobile: "fff7d408-ac9d-4923-af80-7e832baaed03",
  can_sign_from_mobile: "ce8235d4-e651-4240-be09-b09136e3323e",
  allow_add_edit_qa_notes: "fb956347-80e0-49fe-a2d9-57d851b651c5"
};
