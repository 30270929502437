import React from "react";
import PropTypes from "prop-types";

import { RevisionWoundImageContainer } from "src/containers/revision_wound_image_container";
import { RevisionSummaryFooterContainer } from "src/containers/revision_summary_footer_container";

export class RevisionSummaryComponent extends React.Component {
  static propTypes = {
    revision: PropTypes.object.isRequired
  };

  render() {
    const { revision } = this.props;
    return (
      <div>
        <RevisionWoundImageContainer 
          revision={revision} 
          currentFrameTab={this.props.currentFrameTab} 
          setCurrentFrameTab={this.props.setCurrentFrameTab}
        />
        <RevisionSummaryFooterContainer revision={revision} />
      </div>
    );
  }
}
