import React from "react";
import uuid from "uuid/v1";
import { loggedIn } from "src/actions/session_actions";
import { loadUserAction } from "src/actions/data_actions";
import { track, registerParamsForAllEvents } from "src/analytics";
import { configureApi } from "src/api/config";
import { push } from "react-router-redux";
import { ssoMode } from "src/constants";

export class OauthCallBackComponent extends React.Component {
  async componentDidMount() {
    const queryParams = window.location.search;
    const urlParams = new URLSearchParams(queryParams);
    const access_token = urlParams.get("access_token");
    const { dispatch } = this.props;
    const ssomode = localStorage.getItem("ssomode");
    if (access_token) {
      const id = urlParams.get("id");
      const api_host = "https://" + urlParams.get("api_host");
      const sessionId = uuid();
      registerParamsForAllEvents({
        session_id: sessionId
      });
      track("NEW_DASHBOARD_SESSION");
      await dispatch(
        loggedIn({
          apiHost: api_host,
          accessToken: access_token,
          sessionId: sessionId
        })
      );
      await dispatch(loadUserAction(id));

      configureApi(access_token, id);
    }

    if (ssomode === ssoMode.redirect) {
      dispatch(push("/compliance"));
    } else {
      window.close();
    }
  }

  render() {
    return <h1>Sigin Complete</h1>;
  }
}
