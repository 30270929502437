import { createSelector } from "reselect";

// Constants
import { apiPageLimits } from "src/constants";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Selectors
import { facilitiesResourceSelectors } from "src/selectors/data_selectors/facilities";
import { createFirstPageAllFacilitiesContextSelector } from "../create_first_page_all_facilities_context_selector";

export function createAllPagesAllFacilitiesQuerySelector() {
  return createSelector(
    facilitiesResourceSelectors.createPageCountSelector(
      createFirstPageAllFacilitiesContextSelector(),
      apiPageLimits.facilities
    ),
    pageCount => {
      let queries = [
        buildQueryString({
          page: {
            offset: 0,
            limit: apiPageLimits.facilities
          }
        })
      ];

      if (pageCount > 1) {
        for (let i = 1; i < pageCount; i++) {
          queries.push(
            buildQueryString({
              page: {
                offset: i * apiPageLimits.facilities,
                limit: apiPageLimits.facilities
              }
            })
          );
        }
      }

      return queries;
    }
  );
}
