import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import get from "lodash.get";

//CSS
import styles from "./style.module.less";

//Utils
import { camelCaseObjects } from "src/utils/camel_case_objects";

//Constants
import { uiConstants } from "src/constants";

//Components
import { HorizontalStackedBar } from "src/components/ui_kit";
import { LoadingComponent } from "src/components/loading_component";
import { ComplianceContext } from "./compliance_provider_component";

export function ComplianceCliniciansListComponent(props) {
  const { state } = useContext(ComplianceContext);
  const [cliniciansStats, setCliniciansStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const url = "/api/v4/clinician-stats";

  const cliniciansStatsRowComponent = stats => {
    const data = [
      {
        value: get(stats, "attributes.completedCount"),
        color: uiConstants.horizontalBarChart["default"].dark,
        name: "Completed Evaluations"
      },
      {
        value: get(stats, "attributes.signedCount"),
        color: uiConstants.horizontalBarChart["default"].mid,
        name: "Signed Evaluations"
      },
      {
        value: get(stats, "attributes.inProgressCount"),
        color: uiConstants.horizontalBarChart["default"].light,
        name: "In Progress Evaluations",
        fontColor: "#555"
      }
    ];
    return (
      <div className={styles["container"]}>
        <div
          className={`${styles["reduced-line-height"]} ${
            styles["flex-item-1"]
          }`}
        >
          <p className={styles["clinicians-info"]}>
            {get(stats, "attributes.firstName", "")}{" "}
            {get(stats, "attributes.lastName", "")}{" "}
          </p>
          <p className={styles["total-evaluations"]}>
            {get(stats, "attributes.completedCount", "")} /{" "}
            {get(stats, "attributes.totalCount", "")} Completed Evaluations
          </p>
        </div>
        <HorizontalStackedBar height={40} showValueIn data={data} />
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            "X-HTTP-Method-Override": "GET"
          }
        };
        const payload = {
          filter: {
            ...(state.filters.evaluations.length !== 0 && {
              answers: state.filters.evaluations
            }),
            ...(state.filters.locations.length !== 0 && {
              location_id: state.filters.locations
            }),
            patient_admitted_and_created_between:
              state.startDate.toISOString() +
              ", " +
              state.endDate.toISOString(),
            ...(state.filters.clinicians.length !== 0 && {
              user_id: state.filters.clinicians
            }),
            with_patient_users: "false"
          },
          page: {
            offset: 0,
            limit: 1000
          },
          sort: props.sortValue
        };
        setIsLoading(true);
        const result = await axios.post(url, payload, config);
        setCliniciansStats(camelCaseObjects(result.data.data));
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        props.raiseErrorLoadingEvaluations(err);
      }
    };
    fetchData();
  }, [props.sortValue, state.filters, state.startDate, state.endDate]);
  if (isLoading == true) return <LoadingComponent />;
  else if (cliniciansStats.length !== 0) {
    return (
      <div>
        {cliniciansStats &&
          cliniciansStats.map(obj => {
            return cliniciansStatsRowComponent(obj);
          })}
      </div>
    );
  } else {
    return <p>No evaluations found for selected date range and filters.</p>;
  }
}
