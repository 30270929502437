import PropTypes from "prop-types";
import React from "react";

import removeFlashMessage from "./remove";

export default class FlashMessageComponent extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    messageType: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  handleRemove = event => {
    event.preventDefault();

    this.props.dispatch(
      removeFlashMessage(this.props.message, this.props.messageType)
    );
  };

  render = () => {
    return (
      <div className={`flash-message flash-message-${this.props.messageType}`}>
        <div className="container">
          {this.props.message}

          <span
            className="glyphicon glyphicon-remove close-button"
            onClick={this.handleRemove}
          />
        </div>
      </div>
    );
  };
}
