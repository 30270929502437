// Redux Resources

// Constants
import { resourceNames } from "src/constants";

// Api
import { woundsClinicalChartApi } from "src/api/wounds_chart";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

export const loadWoundsClinicalChartDataAction = createLoadDataAction({
  resourceName: resourceNames.woundClinicalCharts,
  api: woundsClinicalChartApi
});
