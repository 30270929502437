import { sessionActionTypes } from "src/action_types/session_action_types";

import {
  setCookieValueForKey,
  getCookieValueForKey,
  expireCookieValueForKey
} from "src/client_storage";

const defaultState = {
  sessionId: getCookieValueForKey("session_id") || null,
  authProgress: "loading",
  selectedOrganizationId: getCookieValueForKey("orgid") || "",
  authConfig: getCookieValueForKey("userAuthConfig") || "",
  apiHost: getCookieValueForKey("apiHost") || "",
  accessToken: getCookieValueForKey("accessToken") || "",
  authWorkflow: ""
};

export function sessionReducer(state = defaultState, action) {
  switch (action.type) {
    case sessionActionTypes.setSessionId:
      setCookieValueForKey("session_id", action.payload);
      return {
        ...state,
        sessionId: action.payload
      };
    case sessionActionTypes.clearSessionId:
      expireCookieValueForKey("session_id");
      return {
        ...state,
        sessionId: null
      };
    case sessionActionTypes.selectOrganizationId:
      setCookieValueForKey("orgid", action.payload);
      return {
        ...state,
        selectedOrganizationId: action.payload
      };
    case sessionActionTypes.setUserAuthConfig:
      setCookieValueForKey("userAuthConfig", action.payload);
      return {
        ...state,
        authConfig: action.payload
      };
    case sessionActionTypes.clearApiHost:
      expireCookieValueForKey("apiHost");
      return {
        ...state,
        apiHost: null
      };
    case sessionActionTypes.setAccessToken:
      return {
        ...state,
        accessToken: action.payload
      };
    case sessionActionTypes.clearAccessToken:
      expireCookieValueForKey("accessToken");
      return {
        ...state,
        accessToken: null
      };
    case sessionActionTypes.launchFailed:
      return {
        ...state,
        launchError: action.payload
      };
    case sessionActionTypes.setAuthWorkflow:
      return {
        ...state,
        authWorkflow: action.payload
      };
    case sessionActionTypes.loggedIn:
      setCookieValueForKey("session_id", action.payload.sessionId);
      return {
        ...state,
        accessToken: action.payload.accessToken,
        apiHost: action.payload.apiHost,
        sessionId: action.payload.sessionId
      };
    default:
      return state;
  }
}
