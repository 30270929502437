import React, { useContext, useState } from "react";
import debounce from "lodash.debounce";

//CSS
import styles from "./style.module.less";
import { ComplianceContext } from "src/pages/compliance/components/compliance_provider_component.js";

export function CompliancePatientSearchBarComponent() {
  const { dispatch } = useContext(ComplianceContext);
  const [inputText, setInputText] = useState("");

  const handleSearchChange = event => {
    setInputText(event.target.value);
    event.persist();
    handleSearchChangeDebounce(event);
  };

  const handleSearchChangeDebounce = debounce(event => {
    dispatch({
      type: "enterPatientOrMrnValue",
      payload: { nameOrMrnOrCode: event.target.value }
    });
  }, 500);

  return (
    <div className={`${styles["search-bar"]}`}>
      <input
        type="search"
        className="form-control"
        placeholder={"Search by Patient Name or MRN"}
        onChange={handleSearchChange}
        value={inputText}
      />
    </div>
  );
}
