import { createSelector } from "reselect";
import { adminSectionSelector } from "../admin_section_selector";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";
import { isSwiftAdminSelector } from "src/selectors/config_selectors/is_swift_admin_selector";

/**
 * Partners id selector for select the selected the partner id
 */
export const selectedPartnerIdSelector = createSelector(
  adminSectionSelector,
  isSwiftAdminSelector,
  currentUserSelector,
  (adminSection, isSwiftAdmin, currentUser) => {
    if (isSwiftAdmin) return adminSection.selectedPartnerId;
    else if (currentUser) return currentUser.attributes.partnerId;
  }
);
