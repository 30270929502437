import { createSelector } from "reselect";
import moment from "moment";
import { createDefaultFrameForRevisionSelector } from "src/selectors/data_selectors";
import { regionsResourceSelectors } from "../regions_resource_selectors";
import { get } from "lodash";

/**
 * Create a selector to get the most recent region from a given revision
 * @param {Object} revision
 * @returns {Function} selector
 */
export function createLatestRegionForRevisionSelector(revision) {
  return createSelector(
    createDefaultFrameForRevisionSelector(revision),
    regionsResourceSelectors.dataSelector,
    (frame, regions) => {
      if (!revision || !frame) {
        return undefined;
      }

      if (get(frame, "attributes.regions", null)) {
        const foundRegion = frame.attributes.regions.sort((r1, r2) => {
          return moment(r1.attributes.createdAt).isAfter(
            r2.attributes.createdAt
          )
            ? -1
            : 1;
        })[0];

        if (foundRegion) {
          return regions[foundRegion.id];
        }
      }

      const sortedRegions = Object.values(regions)
        .filter(region => {
          return region.attributes.frameId == frame.id;
        })
        .sort((r1, r2) => {
          return moment(r1.attributes.createdAt).isAfter(
            r2.attributes.createdAt
          )
            ? -1
            : 1;
        });

      return sortedRegions.length ? sortedRegions[0] : undefined;
    }
  );
}
