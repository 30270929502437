import React from "react";
import PropTypes from "prop-types";

// Containers
import { AssessmentCardContainer } from "src/containers/cards";
import { PatientCardContainer } from "src/containers/cards";

import assessmentPatientCardStyles from "./style.module.less";
import cardStyles from "../style.module.less";

/**
 * Renders a combination AssessmentCard and PatientCard
 */
export class AssessmentPatientCardComponent extends React.PureComponent {
  static propTypes = {
    assessment: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired
  };

  render() {
    const { assessment, patient } = this.props;

    return (
      <div
        className={`${cardStyles["card"]} ${cardStyles["card-border"]} ${
          assessmentPatientCardStyles["assessment-patient-card"]
        }`}
      >
        <div>
          <AssessmentCardContainer assessment={assessment} />
        </div>
        <div>
          <PatientCardContainer patient={patient} />
        </div>
      </div>
    );
  }
}
