import React, { useContext } from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import { Icon } from "src/ui-kit";

// Components
import { ComplianceContext } from "./compliance_provider_component";
import { FlexBox, FlexItem } from "src/components/ui_kit";
import ComplianceEvaluationsComponent from "src/pages/compliance/components/compliance_evaluations_component.js";
import ComplianceCliniciansComponent from "src/pages/compliance/components/compliance_clinicians_component.js";
import { DateRangeFilterComponent } from "./date_range_filter_component";
import { ButtonComponent } from "src/components/button_component";

// Translations
import i18n from "src/translations";

//CSS
import styles from "./style.module.less";

// Utils
import { getUTCOffsetString } from "src/utils/get_UTC_offset_string/index.js";

export function ComplianceSectionComponent(props) {
  const { currentUser, sendPostCsvExportAction, csvExportEnabled } = props;
  const { state } = useContext(ComplianceContext);

  const onCreateCsvClicked = () => {
    const payload = {
      data: {
        type: "csv_exports",
        attributes: {
          worker_type: "assessment_summaries",
          user_id: currentUser ? currentUser.id : "",
          user_offset: getUTCOffsetString(),
          parameters: {
            filter: {
              ...(state.filters.evaluations.length !== 0 && {
                answers: state.filters.evaluations
              }),
              patient_admitted_and_created_between:
                state.startDate.toISOString() +
                ", " +
                state.endDate.toISOString(),
              ...(state.filters.locations.length !== 0 && {
                location_id: state.filters.locations
              }),
              name_or_mrn_or_code: state.nameOrMrnOrCode,
              ...(state.filters.payers.length !== 0 && {
                payer_ids: state.filters.payers
              }),
              ...(state.filters.clinicians.length !== 0 && {
                user_id: state.filters.clinicians
              }),
              lock_state:
                state.selectedTab == "completedEvaluations"
                  ? "locked"
                  : "unlocked",
              sign_state:
                state.selectedTab == "inProgressEvaluations"
                  ? "unsigned"
                  : "signed",
              name_or_mrn_or_code: state.nameOrMrnOrCode
            }
          }
        }
      }
    };
    sendPostCsvExportAction(payload);
  };
  return (
    <div>
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">{i18n.t(`headers.compliance`)}</h1>
        </div>

        <form className="navbar-form navbar-right">
          <FlexBox>
            <FlexItem>
              <div className="btn-group control-navbar-element" role="group">
                <NavLink
                  to="/compliance"
                  exact={true}
                  className="btn btn-default"
                  activeClassName="active"
                >
                  Evaluations
                </NavLink>
                <NavLink
                  to="/compliance/clinicians"
                  className="btn btn-default"
                  activeClassName="active"
                >
                  Clinicians
                </NavLink>
              </div>
            </FlexItem>
            <FlexItem className={styles["header-date-range"]}>
              <DateRangeFilterComponent />
            </FlexItem>
            {csvExportEnabled && (
              <FlexItem>
                <div className={styles["margin-download-button"]}>
                  <ButtonComponent
                    analytics={{ type: "DOWNLOAD_COMPLIANCE_CSV" }}
                    onClick={onCreateCsvClicked}
                  >
                    <Icon name="download" />
                  </ButtonComponent>
                </div>
              </FlexItem>
            )}
          </FlexBox>
        </form>
      </nav>

      <div>
        <Switch>
          <Route
            exact
            path="/compliance"
            render={() => <ComplianceEvaluationsComponent {...props} />}
          />
          <Route
            path="/compliance/clinicians"
            component={ComplianceCliniciansComponent}
          />
        </Switch>
      </div>
    </div>
  );
}
