import { globalActionTypes } from "src/action_types/section_action_types";

export function downloadManagerToggleExpandCollapseBodyReducer(
  state = true,
  action
) {
  switch (action.type) {
    case globalActionTypes.toggleExpandCollapseDownloadManagerBody:
      return !state;
    default:
      return state;
  }
}
