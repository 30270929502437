import axios from "axios";

/**
 * Create an object for querying an api resource
 * @param {Object} options
 * @param {string} options.path the base path
 * @param {boolean} options.useGetOverride true if should use an http override for GET requests
 * @param {boolean} options.usePostForGet true if fetching data is done with a POST request
 * @returns {Object} api object with query functions as parameters
 */
export function apiFactory({
  path = "",
  useGetOverride = false,
  usePostForGet = false,
  urlExtension,
}) {

  return {
    get({ query, payload }) {
      if (usePostForGet) {
        // Flow for POST requests on server side used to fetch data
        return axios.post(path, payload);
      } else if (useGetOverride || (!query && payload)) {
        // Flow for http override
        const options = {
          headers: {
            "X-HTTP-Method-Override": "GET"
          }
        };

        return axios.post(path, payload, options);
      } else {
        return axios.get(`${path}/${query || ""}`);
      }
    },
    patch({ payload }) {
      let config = {
        headers: {
          "Content-Type": "application/vnd.api+json"
        }
      };

      return axios.patch(`${path}/${payload.data.id}`, payload, config);
    },
    put({ payload }) {
      let config = {
        headers: {
          "Content-Type": "application/vnd.api+json"
        }
      };
      let url = `${path}/${payload.data.id}`;
      if (urlExtension) {
        url += "/" + urlExtension;
      }

      return axios.put(url, payload, config);
    },
    post({ payload }) {
      let config = {
        headers: {
          "Content-Type": "application/vnd.api+json"
        }
      };

      return axios.post(path, payload, config);
    },
    delete({ id }) {
      let config = {
        headers: {
          "Content-Type": "application/vnd.api+json"
        }
      };

      let url = `${path}/${id}`;
      if (urlExtension) {
        url += `/${urlExtension}`;
      }

      return axios.delete(url, config);
    }
  };
}
