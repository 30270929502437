import { createSelector } from "reselect";

import { sections } from "src/constants";

import { createSectionSelectors } from "src/ui_processors";
const sectionSelectors = createSectionSelectors(sections.wounds);

export const hprScoreMinRangeFilterSelector = createSelector(
  sectionSelectors.filtersSelector,
  filters => {
    const filter = filters.find(filter => filter.key == "minHprScore");
    if (!filter) {
      return undefined;
    } else {
      return filter.value;
    }
  }
);
