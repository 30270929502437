/**
 * Transform pageConfig and pageCount into an array of page objects to pass to api
 * @param {Object} pageConfig
 * @param {boolean} pageConfig.all
 * @param {number} pageConfig.upTo
 * @param {number} pageConfig.number
 * @param {number} pageConfig.limit
 * @param {number} pageCount
 * @param {number} currentPage - the current page stored by redux resources
 * @returns {Array}
 */
export function offset(pageConfig, pageCount, currentPage) {
  let pages = [];
  if (pageConfig.all) {
    for (let i = 0; i < pageCount; i++) {
      const page = {
        offset: i * pageConfig.limit,
        limit: pageConfig.limit
      };

      pages.push(page);
    }
  } else if (pageConfig.upTo) {
    for (let i = 0; i < pageConfig.upTo + 1; i++) {
      const page = {
        offset: i * pageConfig.limit,
        limit: pageConfig.limit
      };

      pages.push(page);
    }
  } else if (pageConfig.upToCurrentPage) {
    for (let i = 0; i < currentPage + 1; i++) {
      const page = {
        offset: i * pageConfig.limit,
        limit: pageConfig.limit
      };

      pages.push(page);
    }
  } else if (pageConfig.number) {
    pages.push({
      offset: pageConfig.number * pageConfig.limit,
      limit: pageConfig.limit
    });
  } else if (pageConfig.currentPage) {
    pages.push({
      offset: currentPage * pageConfig.limit,
      limit: pageConfig.limit
    });
  }

  return pages;
}
