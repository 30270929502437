import axios from "axios";
import { push } from "react-router-redux";
import { configActionTypes } from "src/action_types/config_action_types";
import { launchFailed } from "src/actions/session_actions";
import { startTimeTrack, finishTimeTrack } from "src/analytics";

/**
 * Action creator for seting the partner location ids in the store
 * @param {Array} locationIds array of partner location ids
 * @return {Object} action to set the partner loc ids in the store
 */
export function setPartnerLocationIds(locationIds) {
  return {
    type: configActionTypes.setPartnerLocationIds,
    payload: locationIds
  };
}

/**
 * Action creator for setting the partner name
 * @param {string} partnerName name of the partner
 * @return {Object} action
 */
export function setPartnerName(partnerName) {
  return {
    type: configActionTypes.setPartnerName,
    payload: partnerName
  };
}

/**
 * Action creator to set the language
 * @param {string} lang
 */
export function setLanguage(lang) {
  return {
    type: configActionTypes.setLanguage,
    payload: lang
  };
}

/**
 * Action creator to set the app mode
 * @param {string} mode
 */
export function setModeAction(mode) {
  return {
    type: configActionTypes.setMode,
    payload: mode
  };
}

/**
 * Action creator to set the app theme
 * @param {string} theme
 */
export function setThemeAction(theme) {
  return {
    type: configActionTypes.setTheme,
    payload: theme
  };
}

/**
 * Action creator to set the app launch context
 * @param {string} launchContext
 */
export function updateLaunchContext(context) {
  return {
    type: configActionTypes.updateLaunchContext,
    payload: context
  };
}

/**
 * Get the swift patient Id from the launch context's patient MRN
 */
export const getContextPatientId = () => async (dispatch, getState) => {
  try {
    startTimeTrack("FETCH_EMBEDDED_PATIENT");
    const patientMrnOrAdmissionId = getState().config.launchContext.patient;
    if (!patientMrnOrAdmissionId) {
      throw new Error("Patient Parameter is missing for this launch content");
    }
    const patientUrl = `/api/v4/patients?filter[mrn_or_admission_id]="${patientMrnOrAdmissionId}"&filter[state]=new,admitted,discharged,away`;
    const response = await axios.get(patientUrl);
    if (!response.data.data[0]) {
      throw new Error("No patient's were found matching the given MRN");
    }
    const patientId = response.data.data[0].id;
    dispatch(push(`/patients/${patientId}`));
    finishTimeTrack("FETCH_EMBEDDED_PATIENT");
  } catch (error) {
    console.error("getContextPatientId", error);
    dispatch(
      launchFailed(`Error while fetching the patient: ${error.message}`)
    );
    finishTimeTrack("FETCH_EMBEDDED_PATIENT", {
      error: true,
      errorCode: error.response ? error.response.status : undefined
    });
  }
};
