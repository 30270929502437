import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import get from "lodash.get";
// Constants
import { assessmentTypes } from "src/constants";
/**
 * Format date string for table cells
 * @param {string} cell iso format date string
 */
export function lastEvaluationDateFormatter(
  cell,
  row,
  rowIndex,
  formatExtraData
) {
  let text = cell ? moment(cell).format("MMM D, YYYY") : "";
  const patientId = get(row, "attributes.patientId", "");
  const seriesId = get(row, "attributes.seriesId", "");
  let assessmentId = get(row, "attributes.assessmentId", null);

  if (
    row.attributes.type === assessmentTypes.braden ||
    row.attributes.type === assessmentTypes.purs ||
    row.attributes.type === assessmentTypes.full_body_skin
  ) {
    return (
      <Link to={`/patients/${patientId}/assessments/${assessmentId}`}>
        {text}
      </Link>
    );
  } else if (!seriesId && !formatExtraData) {
    return text;
  } else {
    return (
      <div onClick={e => formatExtraData.eventHandler(e, row)}>
        <Link to={location.pathname}>{text}</Link>
      </div>
    );
  }
}
