// @flow
import { Map } from "immutable";
import fetch from "isomorphic-fetch";
import get from "lodash.get";
import historyActions from "src/actions/historyActions";
import { signOut } from "src/actions/session_actions";
import { create as createFlashMessage } from "src/actions/flashMessageActions";
import { getQuery } from "src/selectors/router_selectors";
import constructQS from "./constructQS";
import { accessTokenSelector } from "src/selectors/session_selectors";

import { storeCurrentUserEmailAction } from "src/actions/section_actions/otp_verification";

import store from "src/store";
import { apiHostSelector } from "src/selectors/session_selectors";

import { expireCookieValueForKey } from "src/client_storage";

const DEFAULT_OPTIONS = {
  headers: {
    Accept: "application/json"
  },
  mode: "cors"
};

const getAccessTokenFromState = function(state: $$state) {
  return accessTokenSelector(state) || getQuery("access_token")(state);
};

export default function apiFetch(
  url: string,
  options: Object = DEFAULT_OPTIONS
) {
  const accessToken = getAccessTokenFromState(store.getState());

  if (options !== DEFAULT_OPTIONS) {
    options = {
      ...DEFAULT_OPTIONS,
      ...options
    };
  }

  if (options.body && !(options.body instanceof window.FormData)) {
    if (typeof options.body === "object") {
      options.body = JSON.stringify(options.body);
    }

    // don't mutate the existing headers object
    options.headers = {
      ...options.headers,
      "Content-Type": options.headers["Content-Type"] || "application/json"
    };
  }

  if (accessToken) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`
    };
  }

  options.credentials = "include";

  const urlWithApiHost = `${apiHostSelector(store.getState()) || ""}${url}`;

  return fetch(urlWithApiHost, options).then(response => {
    let responsePromise = response.json();
    if (response.status === 202) {
      store.dispatch(
        storeCurrentUserEmailAction.setValue(
          get(response, "data.user_email", null)
        )
      );
      store.dispatch(historyActions.push("/verification"));
    } else if (response.status >= 400) {
      let errorPromise = responsePromise.then(errorResponse => {
        if (response.status === 401) {
          expireCookieValueForKey("accessToken");
          store.dispatch(
            createFlashMessage(
              "Your session has expired. Please sign in again.",
              "error"
            )
          );
          store.dispatch(signOut());
          store.dispatch(historyActions.push("/sign_in"));
        }

        return Promise.reject(errorResponse);
      });

      return errorPromise;
    } else {
      return responsePromise;
    }
  });
}

export class Api {
  service: string;
  api: string;
  constructor(service: string, api?: string) {
    this.service = service;
    this.api = api || "";
  }
  baseFetch = (
    url: string,
    prefinalOptions: Object = {},
    method: string = "GET",
    serviceName?: string
  ) => {
    const finalService = serviceName || `${this.api}/${this.service}`;
    if (finalService.includes("/pcc/v1")) {
      if (!prefinalOptions.query) {
        prefinalOptions.query = {};
      }
    }
    const finalOptions = { method, ...prefinalOptions };
    const { query = {}, ...finalOptionsWithoutQuery } = finalOptions;
    const qs =
      Object.keys(query).length > 0 ? constructQS(Map(query).toJS()) : "";
    return apiFetch(`${finalService}${url}${qs}`, finalOptionsWithoutQuery);
  };

  get = (url: $$id, options?: Object = {}) => {
    return this.baseFetch(`/${url}`, options, "GET");
  };

  index = (options?: Object = {}) => {
    return this.baseFetch("", options, "GET");
  };

  remove = (id: $$id, options?: Object) => {
    return this.baseFetch(`/${id}`, options, "DELETE");
  };

  update = (id: $$id, options?: Object) => {
    return this.baseFetch(`/${id}`, options, "PATCH");
  };

  post = (url: string, body: Object = {}, options?: Object = {}) => {
    return this.baseFetch(`/${url}`, { ...options, body }, "POST");
  };

  create = (options?: Object = {}) => {
    return this.baseFetch("", options, "POST");
  };

  apiGet = (serviceName: string, url: string, options?: Object = {}) =>
    this.baseFetch(url, options, "GET", serviceName);
  apiIndex = (serviceName: string, url: string, options?: Object = {}) =>
    this.baseFetch(url, options, "GET", serviceName);
  apiPost = (serviceName: string, url: string, options?: Object = {}) =>
    this.baseFetch(url, options, "POST", serviceName);
  apiUpdate = (serviceName: string, url: string, options?: Object = {}) =>
    this.baseFetch(url, options, "PATCH", serviceName);
  apiRemove = (serviceName: string, url: string, options?: Object = {}) =>
    this.baseFetch(url, options, "DELETE", serviceName);
}
