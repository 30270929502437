// Action Types
import { createActionTypes } from "../../create_action_types";

// Context
import { createContext } from "../../create_context";

/**
 * Create a reducer for the data piece of this resource
 * @param {string} name - the resource name
 */
export function createMetaReducer(name) {
  const actionTypes = createActionTypes(name);

  return (state = {}, action) => {
    let method, queryObject, page, context;

    switch (action.type) {
      case actionTypes.populate:
        const { data, meta, links } = action.payload;
        method = action.payload.method;
        queryObject = action.payload.queryObject;
        page = action.payload.page;
        context = createContext(method, queryObject, page);
        const ids = Object.keys(data);
        return {
          ...state,
          [context]: {
            ids,
            meta,
            links
          }
        };

      case actionTypes.invalidate:
        method = action.payload.method;
        queryObject = action.payload.queryObject;
        page = action.payload.page;
        context = createContext(method, queryObject, page);
        return {
          ...state,
          [context]: undefined
        };

      case actionTypes.invalidateAll:
        return {};

      default:
        return state;
    }
  };
}
