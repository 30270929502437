import { createSelector } from "reselect";
import {
  partnerNameSelector,
  modeSelector
} from "src/selectors/config_selectors";
import { appModes } from "src/constants";

/**
 * Memoized selector for the partner name (part of the config state tree)
 */
export const pdfExportEnabledSelector = createSelector(
  partnerNameSelector,
  modeSelector,
  (partnerName, mode) => {
    // only enable for partner pcc or swift clients and standard mode
    return (
      (partnerName === "pcc" ||
        partnerName === "pcc_wound" ||
        !partnerName.length) &&
      mode === appModes.standard
    );
  }
);
