import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./style.module.less";

/**
 * Renders children in a fixed aspect ratio box.
 * How to use:
 * If matchParentWidth is provided the box will be the width of the parent
 * If maxWidth is provided the box will not exceed it (px units)
 * If minWidth is provided the box will not be smaller than it (px units)
 * If aspect ratio (height/width) is provided the box will be fixed to it, otherwise a 1:1 ratio is used
 */
export class FixedAspectRatioComponent extends React.PureComponent {
  static propTypes = {
    matchParentWidth: PropTypes.bool,
    maxWidth: PropTypes.number,
    minWidth: PropTypes.number,
    aspectRatio: PropTypes.number
  };

  render() {
    const containerStyle = {
      width: this.props.matchParentWidth ? "100%" : undefined,
      maxWidth: this.props.maxWidth,
      minWidth: this.props.minWidth
    };

    const fixedRatioStyle = {
      paddingTop: this.props.aspectRatio
        ? `${this.props.aspectRatio * 100}%`
        : "100%"
    };

    return (
      <div className={styles["container"]} style={containerStyle}>
        <div className={styles["fix-aspect-ratio"]} style={fixedRatioStyle}>
          <div className={styles["content"]}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
