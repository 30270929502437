import { createSelector } from "reselect";

import { seriesResourceSelectors } from "src/selectors/data_selectors";

/**
 * Create a selector that gets a series for an array of revisions
 * @param {Object} props
 * @param {array} props.revisions - the revisions to get series for
 * @returns {Function} selector
 */
export function createSeriesForRevisionsSelector({ revisions }) {
  return createSelector(
    seriesResourceSelectors.dataSelector,
    series => {
      return revisions.reduce((agg, r) => {
        agg[r.id] = series[r.attributes.seriesId];
        return agg;
      }, {});
    }
  );
}
