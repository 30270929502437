export const lockStateConfiguration = {
  filterName: "evaluationStatus",
  filterValues: [
    {
      key: "lockState",
      value: "locked"
    },
    {
      key: "lockState",
      value: "unlocked"
    }
  ]
};
