// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { customRolesApi } from "src/api/custom_roles";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

export const customRolesResourceActions = createResourceActions(
  resourceNames.customRoles
);

export const loadCustomRolesAction = createLoadDataAction({
  resourceName: resourceNames.customRoles,
  api: customRolesApi
});

/* ------------ Legacy -------------- */
import normalize from "json-api-normalizer";
import { trackError } from "src/error_tracking";

import {
  //   fetchCustomRoles,
  createCustomRole,
  updateRole
} from "src/api/custom_roles";
import { create } from "src/actions/flashMessageActions";

export const loadCustomRolesActionForRuleForm = createLoadDataAction({
  resourceName: resourceNames.customRoles,
  api: customRolesApi,
  responseNormalizer: response => {
    const normalizedData = normalize(response);
    return {
      [resourceNames.customRoles]: {
        ...normalizedData.roles,
        ...normalizedData.customRoles
      }
    };
  }
});

/**
 * Send api call to create role.
 * @param {Object} payload The payload
 */
export function createCustomRoleAction(payload) {
  return dispatch => {
    // loading state
    dispatch(customRolesResourceActions.loadingPostAction());

    return createCustomRole(payload)
      .then(response => {
        const roles = normalize(response.data).customRoles;

        dispatch(
          customRolesResourceActions.populatePostAction({
            data: roles
          })
        );
        dispatch(create("Role created successfully", "success"));
      })
      .catch(err => {
        dispatch(create("Sorry, there was an error create role", "error"));
        trackError(err);
        dispatch(customRolesResourceActions.loadErrorPostAction());
      });
  };
}

/**
 * Action creator for update roles
 * @param {Object} payload The payload
 * @return {Object} thunk action
 */
export function updateCustomRoleAction(payload) {
  return dispatch => {
    dispatch(customRolesResourceActions.loadingPatchAction([payload.id]));
    return updateRole(payload)
      .then(response => {
        const customRoles = normalize(response.data).customRoles;
        dispatch(
          customRolesResourceActions.populatePatchAction({
            data: customRoles,
            ids: [customRoles.id]
          })
        );
        dispatch(create("Role updated successfully", "success"));
      })
      .catch(err => {
        dispatch(
          create("Sorry, there was an error updating the role", "error")
        );
        trackError(err);
        dispatch(customRolesResourceActions.loadErrorPatchAction([payload.id]));
      });
  };
}
