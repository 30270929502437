import { createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";
import { createUserFormPartnerOrganizationsOptionsSelector } from "src/selectors/section_selectors/admin";
import { sortByName } from "src/utils/sort_by_name";
import pickby from "lodash.pickby";

export function createUserFormFacilityOptionsSelector(formName) {
  return createSelector(
    getFormValues(formName),
    facilitiesResourceSelectors.createDataForContextSelectorWithString(
      "adminFacilities"
    ),
    createUserFormPartnerOrganizationsOptionsSelector(formName),
    (form, facilities, orgs) => {
      let filterFacilities;

      if (form && form["organization"]) {
        filterFacilities = Object.values(facilities).filter(function(itm) {
          return (
            Object.keys(pickby(form["organization"])).indexOf(
              itm.attributes.organizationId
            ) > -1
          );
        });
      } else {
        // this case will selects the facilities for first organization
        orgs.length
          ? (filterFacilities = Object.values(facilities).filter(
              o => o.attributes.organizationId == Object.values(orgs)[0].id
            ))
          : [];
      }

      return filterFacilities ? sortByName(filterFacilities) : [];
    }
  );
}
