// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import { track } from "src/analytics";

// @deprecated
export default class Button extends PureComponent {
  handleClick = (...args) => {
    if (this.props.analytics) {
      track(this.props.analytics.type, this.props.analytics.payload);
    }
    return this.props.onClick.apply(this, args);
  };
  render() {
    const {
      className,
      children,
      name,
      subtype,
      circle,
      active,
      /* eslint-disable */
      analytics,
      /* eslint-enable */
      ...props
    } = this.props;
    const klass = classnames(
      "btn",
      {
        active,
        [`btn-${name || ""}`]: !!name,
        [`btn-${name || ""}--circle`]: !!circle
      },
      className
    );
    switch (subtype) {
      case "link":
        return (
          <a
            href="#"
            className={className}
            {...props}
            onClick={this.handleClick}
          >
            {children}
          </a>
        );
      case "submit":
        return (
          <button type="submit" className={klass} {...props}>
            {children}
          </button>
        );
      case "button":
        return (
          <button type="button" className={klass} {...props}>
            {children}
          </button>
        );
      default:
        return (
          <button className={klass} {...props} onClick={this.handleClick}>
            {children}
          </button>
        );
    }
  }
}
