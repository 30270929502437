import { createSelector } from "reselect";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Selectors
import { createAllPagesPayloadSelector } from "src/selectors/api_selectors/evaluation_list/wound_revisions/create_all_pages_payload_selector";

export function createAllPagesContextSelector() {
  return createSelector(
    createAllPagesPayloadSelector(),
    currentPagePayload => buildQueryString(currentPagePayload)
  );
}
