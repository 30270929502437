import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { reduxForm } from "redux-form";

import { resourceNames } from "src/constants";

import {
  enterOtpCodeAction,
  requestNewOtpCodeAction
} from "src/actions/data_actions";

import { defaultNavigationPageSelector } from "src/selectors/ui_config_selectors/global";
import { otpVerificationSectionSelector } from "src/selectors/section_selectors/otp_verification";
import { otpCodeResourceSelector } from "src/selectors/data_selectors";
import SingleValueReduxUtil from "src/ui_processors/single_value";

import { OtpVerificationComponent } from "src/components/otp_verification_component";

const currentUserEmailSelector = SingleValueReduxUtil.createSelectors(
  "currentUserEmail",
  otpVerificationSectionSelector
);

function mapStateToProps(state) {
  return {
    userEmail: currentUserEmailSelector.fieldSelector(state),
    verifyOtpCodeStatus: otpCodeResourceSelector.postLoadStateSelector(state),
    defaultNavigationPage: defaultNavigationPageSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendRequestToValidateOTPCode: payload => {
      return dispatch(enterOtpCodeAction(payload));
    },
    sendRequestForNewOtpCode: () => {
      return dispatch(
        requestNewOtpCodeAction({ context: resourceNames.otpCode })
      );
    }
  };
}

export const OtpVerificationContainer = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "verifyOtpForm"
  })
)(OtpVerificationComponent);
