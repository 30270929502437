import { createActionTypes } from "../create_action_types";

export function createReducer(fieldName, defaultValue) {
  const actionTypes = createActionTypes(fieldName);

  return (state = defaultValue, action) => {
    switch (action.type) {
      case actionTypes.setValue: {
        return action.payload;
      }
      case actionTypes.toggleValue: {
        return !state;
      }
      default:
        return state;
    }
  };
}
