import React from "react";
import styles from "./style.module.less";
import i18n from "src/translations";

export class RefreshComponent extends React.PureComponent {
  render() {
    return (
      <div className={styles["container"]}>
        <h1>{i18n.t("headers.dashboardSessionTimeout")}</h1>
        <p>{i18n.t("text.refreshPage.description")}</p>
      </div>
    );
  }
}
