import React from "react";
import PropTypes from "prop-types";
import IdleTimer from "react-idle-timer";

// Containers
import { SectionContentContainer } from "src/containers/section_content_container";
import { SideBarContainer } from "src/containers/side_bar_container";
import { DownloadManagerContainer } from "src/containers/download_manager/download_manager_container";

// Components
import FlashMessagesComponent from "../../../flash_messages/component";
import { SessionTimeoutComponent } from "src/components/session_timeout_component";
import { ComplianceProvider } from "src/pages/compliance/components/compliance_provider_component.js";

// Styles
import styles from "./style.module.less";

// Analtyics
import { resetMixpanel } from "src/analytics";
import { launchIntents } from "src/constants";

/**
 * React component that renders the web application proper
 */
export class AppComponent extends React.PureComponent {
  static propTypes = {
    showDownloadManager: PropTypes.bool.isRequired,
    sideBarEnabled: PropTypes.bool.isRequired,
    query: PropTypes.object,
    sendClearAccessTokenAction: PropTypes.func.isRequired,
    sendClearApiHostAction: PropTypes.func.isRequired,
    sendGoToLoginAction: PropTypes.func.isRequired,
    partnerName: PropTypes.string,
    launchIntent: PropTypes.string
  };

  pingCall = () => {
    if (!this.idleTimer) {
      return;
    }
    let lastActivityTimestamp = this.idleTimer.getLastActiveTime() / 1000;
    this.props.sendPingApiAction(lastActivityTimestamp);
  };

  componentDidMount() {
    const { dashboardActivityTimeoutSeconds } = this.props;
    if (dashboardActivityTimeoutSeconds) {
      setInterval(this.pingCall, 60000);
    }
  }

  componentDidUpdate(prevProps) {
    const { dashboardActivityTimeoutSeconds } = this.props;
    const prevDashboardActivityTimeoutSeconds =
      prevProps.dashboardActivityTimeoutSeconds;

    if (
      dashboardActivityTimeoutSeconds &&
      !prevDashboardActivityTimeoutSeconds
    ) {
      setInterval(this.pingCall, 60000);
    }
  }

  /**
   * Following function is called when user is idle for 15 mins
   */
  onTimeout = () => {
    this.props.sendClearAccessTokenAction();
    this.props.sendClearApiHostAction();
    resetMixpanel();
    this.props.sendGoToLoginAction();
    location.reload();
  };

  render() {
    const { showDownloadManager, sideBarEnabled, query } = this.props;

    // Check if sidebar enabled
    // TODO: refactor this display logic to use mode and theme only
    // TODO: add Loading workflow for startup loads
    if (
      !sideBarEnabled ||
      query.partner == "pcc_wound" ||
      this.props.launchIntent === launchIntents.chart
    ) {
      return (
        <div>
          <FlashMessagesComponent />
          <div id="container" className={styles["app-section-container"]}>
            <SectionContentContainer />
          </div>
        </div>
      );
    }

    return (
      <div>
        {showDownloadManager && <DownloadManagerContainer />}
        <div className="app-container">
          <ComplianceProvider>
            <div className="sidebar">
              <SideBarContainer />
            </div>
            <div id="container" className="content">
              <SectionContentContainer />
            </div>
          </ComplianceProvider>
        </div>
        <FlashMessagesComponent />
        {!this.props.partnerName && (
          <SessionTimeoutComponent
            timeOut={3600000}
            onTimeout={this.onTimeout}
          />
        )}
        {!this.props.partnerName && (
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onAction={this.onAction}
            debounce={100}
            timeout={1000}
          />
        )}
      </div>
    );
  }
}
