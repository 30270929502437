import { createSelector } from "reselect";

import { sections } from "src/constants";

import { createSectionSelectors } from "src/ui_processors";
const sectionSelectors = createSectionSelectors(sections.wounds);

export const acquiredFilterSelector = createSelector(
  sectionSelectors.filtersSelector,
  filters =>
    filters.find(filter => filter.key == "acquired")
      ? filters.find(filter => filter.key == "acquired").value
      : undefined
);
