import { adminActionTypes } from "src/action_types/section_action_types";

export function selectedWoundAlertRulesTablePageNumberReducer(
  state = 0,
  action
) {
  switch (action.type) {
    case adminActionTypes.selectWoundAlertRulesTablePageNumber:
      return action.payload;
    case adminActionTypes.selectWoundAlertRulesTablePageLimit:
      return 0;
    case adminActionTypes.selectRulesFacility:
      return 0;
    case adminActionTypes.selectRulesOrganization:
      return 0;
    default:
      return state;
  }
}
