// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";

type $props = {
  children?: any,
  className?: string,
  name: string
};

export default class Alert extends PureComponent {
  props: $props;
  render() {
    const { className, name, children } = this.props;
    const klass = classnames("alert", `alert-${name}`, className);
    return <div className={klass}>{children}</div>;
  }
}
