import { dataActionTypes } from "src/action_types";
import { loadStates } from "src/constants/load_states";

const defaultState = {
  loadState: loadStates.ready
};

export function surveyTallyExportReducer(state = defaultState, action) {
  switch (action.type) {
    case dataActionTypes.tallyExportLoading:
      return { loadState: loadStates.loading };
    case dataActionTypes.tallyExportLoadReady:
      return { loadState: loadStates.ready };
    case dataActionTypes.tallyExportError:
      return { loadState: loadStates.error };
    default:
      return state;
  }
}
