import { createResourceActions } from "src/redux_resources_v1";

// Error tracking
import { trackError } from "src/error_tracking";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

// Constants
import { reduxResourceContexts } from "src/constants";

/**
 * Create a load data action creator
 * @param {Object} options
 * @param {string} options.resourceName resource name
 * @param {Object} options.api api object that responds to "post" call
 * @param {function} options.onSuccess thunk action for success case
 * @param {function} options.onError thunk action for error case
 * @returns {Function} action creator for a thunk action to POST the resource
 */
export function createDeleteDataAction({
  resourceName,
  api,
  onSuccess,
  onError
}) {
  const analyticsEventName = `API_DELETE_${resourceName.toUpperCase()}`;
  const resourceActions = createResourceActions(resourceName);

  return ({ resource }) => {
    return dispatch => {
      dispatch(
        resourceActions.loadingForContextAction(reduxResourceContexts.delete)
      );
      startTimeTrack(analyticsEventName);

      return api
        .delete(resource)
        .then(() => {
          finishTimeTrack(analyticsEventName);

          // Normalize response
          // const normalizedData = normalize(response.data);

          // Update the resource
          dispatch(
            resourceActions.deleteAction({
              context: reduxResourceContexts.delete,
              id: resource.id
            })
          );

          if (onSuccess) {
            onSuccess(dispatch);
          }
        })
        .catch(err => {
          finishTimeTrack(analyticsEventName, {
            error: true,
            errorCode: err.response ? err.response.status : undefined
          });
          trackError(err);
          dispatch(
            resourceActions.loadErrorForContextAction(
              reduxResourceContexts.delete
            )
          );

          if (onError) {
            onError(dispatch);
          }
        });
    };
  };
}
