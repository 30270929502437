import { createSelector } from "reselect";

import { woundAssessmentTotalsTimeSeriesByLocationDataSelector } from "src/selectors/data_selectors";

/**
 * Selector that gets the total series for a location id
 */
export function createWoundAssessmentTotalsSeriesForLocationSelector(location) {
  return createSelector(
    woundAssessmentTotalsTimeSeriesByLocationDataSelector,
    totalSeriesData => {
      return totalSeriesData[location.id];
    }
  );
}
