import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Selectors
import { woundAssessmentsResourceSelectors } from "src/selectors/data_selectors";
import { selectedLocationIdsSelector } from "src/selectors/section_selectors/wounds";
import { selectedProgressFilterSelector } from "src/selectors/section_selectors/wounds";

//Actions
import { loadWoundAssessmentsAction } from "src/actions/data_actions";

/**
 * HOC that is responsible for loading wounds for given query parameters
 * @param {function} payloadSelector Selector that returns the query parameters (payload)
 * @param {function} contextSelector Selector that returns the query string (context)
 * @return {function} Higher Order Component function wrapped by the loader component
 */

export function withWoundAssessmentsLoaderFactory(
  contextCreator,
  payloadCreator
) {
  /**
   * HOC that passes in the loaded assessments to the component dependent on the data
   * @param {Class} WrappedComponent the component dependent on this data
   * @return {Class} Component wrapped by the loader component
   */
  return function withWoundAssessmentsLoader(WrappedComponent) {
    class WoundAssessmentsLoader extends React.Component {
      static propTypes = {
        progressValues: PropTypes.array,
        firstPagePayloadsByProgress: PropTypes.object.isRequired,
        firstPageContextsByProgress: PropTypes.object.isRequired,
        firstPageContextsLoadState: PropTypes.bool.isRequired,
        sendLoadWoundAssessmentsAction: PropTypes.func.isRequired,
        selectedLocationIds: PropTypes.array.isRequired
      };

      componentWillMount() {
        const { selectedLocationIds, firstPageContextsLoadState } = this.props;
        if (selectedLocationIds.length && !firstPageContextsLoadState) {
          this.loadFirstPageAssessments(this.props);
        }
      }

      componentWillReceiveProps(nextProps) {
        if (
          nextProps.selectedLocationIds.length &&
          JSON.stringify(this.props.firstPageContextsByProgress) !==
            JSON.stringify(nextProps.firstPageContextsByProgress)
        ) {
          if (!nextProps.firstPageContextsLoadState) {
            this.loadFirstPageAssessments(nextProps);
          }
        }
      }

      loadFirstPageAssessments(props) {
        const {
          firstPagePayloadsByProgress,
          firstPageContextsByProgress,
          sendLoadWoundAssessmentsAction
        } = props;

        sendLoadWoundAssessmentsAction(
          firstPagePayloadsByProgress,
          firstPageContextsByProgress
        );
      }

      loadMoreAssessmentsForProgress = (nextPagePayload, nextPageContext) => {
        if (nextPagePayload && nextPageContext) {
          this.props.sendLoadWoundAssessmentsAction(
            nextPagePayload,
            nextPageContext
          );
        }
      };

      render() {
        return (
          <WrappedComponent
            loadMoreAssessmentsForProgress={this.loadMoreAssessmentsForProgress}
          />
        );
      }
    }

    function mapStateToProps(state) {
      //First Page Payloads and Contexts By Progress
      const selectedProgressFilter = selectedProgressFilterSelector(state);

      const firstPagePayloadsByProgress = payloadCreator(
        selectedProgressFilter
      )(state);
      const firstPageContextsByProgress = contextCreator(
        selectedProgressFilter
      )(state);
      //First Page Contexts Loaded
      let firstPageContextsLoadState = true;
      if (
        !woundAssessmentsResourceSelectors.createLoadStateForContextSelector(
          firstPageContextsByProgress
        )(state)
      ) {
        firstPageContextsLoadState = false;
      }

      return {
        firstPagePayloadsByProgress,
        firstPageContextsByProgress,
        firstPageContextsLoadState,
        selectedLocationIds: selectedLocationIdsSelector(state)
      };
    }

    function mapDispatchToProps(dispatch) {
      return {
        sendLoadWoundAssessmentsAction(payload, context) {
          dispatch(loadWoundAssessmentsAction(payload, context));
        }
      };
    }
    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(WoundAssessmentsLoader);
  };
}
