// @flow
import { Record } from "immutable";

export default class AnswerModel extends Record({
  burnDegree: "N/A",
  inHouseAcquired: "",
  location: "",
  overallProgress: "",
  pressureUlcerStage: "",
  pressureUlcerUnstageableCause: "N/A",
  skinTearCategory: "N/A",
  masdType: "Not Set",
  woundAge: "",
  woundType: ""
}) {}
