import React from "react";
import PropTypes from "prop-types";

// Translations
import i18n from "src/translations";

// Components
import { CheckboxList, CheckboxListItem } from "../checkbox_list";
import { CollapsibleContainerComponent } from "../collapsible_container_component";

// Analytics
import { track } from "src/analytics";
export class SideBarAdminssionStatusFilterComponent extends React.Component {
  static propTypes = {
    admissionStatusFilter: PropTypes.array.isRequired,
    sendSetAdmissionStatusFilterAction: PropTypes.func.isRequired,
    sendDeselectAdmissionStatusFilterAction: PropTypes.func.isRequired
  };

  /**
   * Handler for checkboxes - sets the Admission filter
   * @param {string} value value for the filter
   */
  onFilterChanged = value => {
    if (!this.isFilterChecked(value)) {
      this.props.sendSetAdmissionStatusFilterAction(value);
      track("SELECT_ADMISSION_STATUS_FILTER", {
        admissionStatusFilter: value
      });
    } else {
      this.props.sendDeselectAdmissionStatusFilterAction(value);
      track("DESELECT_ADMISSION_STATUS_FILTER", {
        admissionStatusFilter: value
      });
    }
  };

  /**
   * Checks if a filter for a given filter value is checked
   */
  isFilterChecked(value) {
    return (
      this.props.admissionStatusFilter.find(filter => filter == value) !=
      undefined
    );
  }

  /**
   * Render the checkboxes
   */
  renderCheckboxes() {
    return (
      <CheckboxList>
        <CheckboxListItem
          label={i18n.t("data.admissionStatus.new")}
          value="new"
          checked={this.isFilterChecked("new")}
          onChange={this.onFilterChanged}
        />
        <CheckboxListItem
          label={i18n.t("data.admissionStatus.admitted")}
          value="admitted"
          checked={this.isFilterChecked("admitted")}
          onChange={this.onFilterChanged}
        />
        <CheckboxListItem
          label={i18n.t("data.admissionStatus.discharged")}
          value="discharged"
          checked={this.isFilterChecked("discharged")}
          onChange={this.onFilterChanged}
        />
      </CheckboxList>
    );
  }

  render() {
    return (
      <CollapsibleContainerComponent label="Admission Status">
        {this.renderCheckboxes()}
      </CollapsibleContainerComponent>
    );
  }
}
