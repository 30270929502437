export const historyOrCause = {
  historyStage4: "History of Stage 4 Pressure Injury",
  historyStage3: "History of Stage 3 Pressure Injury",
  historyDTPI: "History of DTPI and resolving",
  poorMobility: "Poor Mobility",
  poorMobilityShearing: "Poor Mobility with Shearing",
  deviceRelated: "Device Related",
  mucosalMembrane: "Mucosal Membrane",
  tubes: "Tubes",
  drains: "Drains",
  incontinence: "Incontinence of Bowel and/or Bladder",
  circulation: "Circulation",
  friction: "Friction",
  medicalAdhesive: "Medical Adhesive",
  ostomyRelated: "Ostomy Related",
  cancerRelated: "Cancer Related",
  diabetes: "Diabetes",
  neuropathy: "Neuropathy",
  fall: "Fall",
  historyOther: "Other",
  indeterminate: "Unable to Determine",
  title: "History/Cause"
};
