import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// Translations
import i18n from "src/translations";

// Styles
import styles from "./style.module.less";

//Containers
import { EvaluationListWoundTableContainer } from "src/containers/tables/evaluation_list_wound_table_container";
import { EvaluationListBradenTableContainer } from "src/containers/tables/evaluation_list_braden_table_container";
import { EvaluationListPURSTableContainer } from "src/containers/tables/evaluation_list_purs_table_container";
import { EvaluationListTotalSkinTableContainer } from "src/containers/tables/evaluation_list_total_skin_table_container";

//Router
import { NavLink } from "react-router-dom";

export class EvaluationListComponent extends React.PureComponent {
  static propTypes = {
    enabledNonWoundAssessments: PropTypes.array.isRequired
  };

  render() {
    const { enabledNonWoundAssessments } = this.props;

    return (
      <div>
        <div className={styles["buttonBox"]}>
          <NavLink
            to="/my-dashboard/evaluation-list/wound"
            className={`btn btn-default ${styles["default"]}`}
            activeClassName={`btn dashboard-btn ${styles["activeClass"]}`}
          >
            {i18n.t("text.evaluationListLabels.wound")}
          </NavLink>
          {enabledNonWoundAssessments.includes("Braden") && (
            <NavLink
              to="/my-dashboard/evaluation-list/braden"
              className={`btn btn-default ${styles["default"]}`}
              activeClassName={`btn dashboard-btn ${styles["activeClass"]}`}
            >
              {i18n.t("text.evaluationListLabels.braden")}
            </NavLink>
          )}
          {enabledNonWoundAssessments.includes("PURS") && (
            <NavLink
              to="/my-dashboard/evaluation-list/purs"
              className={`btn btn-default ${styles["default"]}`}
              activeClassName={`btn dashboard-btn ${styles["activeClass"]}`}
            >
              {i18n.t("text.evaluationListLabels.purs")}
            </NavLink>
          )}
          {enabledNonWoundAssessments.includes("Total Skin") && (
            <NavLink
              to="/my-dashboard/evaluation-list/total-skin"
              className={`btn btn-default ${styles["default"]}`}
              activeClassName={`btn dashboard-btn ${styles["activeClass"]}`}
            >
              {i18n.t("text.evaluationListLabels.totalSkin")}
            </NavLink>
          )}
        </div>
        <Route
          path="/my-dashboard/evaluation-list/wound"
          component={EvaluationListWoundTableContainer}
        />
        <Route
          path="/my-dashboard/evaluation-list/braden"
          component={EvaluationListBradenTableContainer}
        />
        <Route
          path="/my-dashboard/evaluation-list/purs"
          component={EvaluationListPURSTableContainer}
        />
        <Route
          path="/my-dashboard/evaluation-list/total-skin"
          component={EvaluationListTotalSkinTableContainer}
        />
      </div>
    );
  }
}
