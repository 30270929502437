import { styles } from "../pdf_styles";
import { margins } from "../margins";

/**
 * Get the footer portion of the Non Wound Assessment PDF, Formatted for PDF Make
 * @param {Object} footerData - footer Data for PDF footer
 * @param {string} footerText - top left text for the footer
 * @param {string} dateRangeText - top left date range text for the footer
 * @return {Object} JSON representation of the pdf footer
 */
export function getFooterV2(footerData) {
  const { footerText, dateRangeText, fillWhite } = footerData;
  return function(currentPage, pageCount) {
    return {
      table: {
        headerRows: 1,
        widths: [250, "*"],
        body: [
          [
            {
              text: `${footerText} | ${dateRangeText}`,
              fontSize: 9,
              color: fillWhite ? "#000000" : "#848484",
              fillColor: fillWhite ? "#ffffff" : "#f6f6f6",
              alignment: "left",
              margin: [
                margins.defaultPageMargin,
                13,
                margins.defaultPageMargin,
                margins.defaultPageMargin
              ],
              border: [false, true, false, false]
            },
            {
              text: `${currentPage.toString()}/${pageCount}`,
              fontSize: 9,
              color: fillWhite ? "#000000" : "#848484",
              fillColor: fillWhite ? "#ffffff" : "#f6f6f6",
              alignment: "right",
              margin: [
                margins.defaultPageMargin,
                13,
                margins.defaultPageMargin,
                margins.defaultPageMargin
              ],
              border: [false, true, false, false]
            }
          ]
        ]
      },
      layout: styles.tallyTable
    };
  };
}
