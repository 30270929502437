import { createSelector } from "reselect";
import { decamelize } from "humps";

// Constants
import { resourceNames } from "src/constants";

// Selectors
import {
  selectedFacilityIdsSelector,
  userNameSearchStringSelector
} from "src/selectors/section_selectors/admin";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";

// Api
import { createPostPayload } from "src/api/csv_exports/payload_generators";

// Utils
import { getUTCOffsetString } from "src/utils/get_UTC_offset_string/index.js";

export const usersCsvExportPostPayloadSelector = createSelector(
  currentUserSelector,
  selectedFacilityIdsSelector,
  userNameSearchStringSelector,
  (currentUser, facilityIds, name) => {
    return createPostPayload({
      type: decamelize(resourceNames.csvExports),
      workerType: decamelize(resourceNames.users),
      userId: currentUser ? currentUser.id : "",
      userOffset: getUTCOffsetString(),
      parameters: {
        filter: {
          facility_id: facilityIds ? facilityIds.join() : undefined,
          name
        }
      }
    });
  }
);
