export const assessmentTypes = {
  wound: "wound",
  braden: "braden",
  purs: "purs",
  norton_plus: "norton_plus",
  full_body_skin: "full_body_skin",
  apbi: "abpi",
  toe_pressure: "toe_pressure",
  hemoglobin: "hemoglobin"
};
