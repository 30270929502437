import { createSelector } from "reselect";
import get from "lodash.get";

// Constants
import { pressureWoundTypes } from "src/constants";

// Selectors
import { createSeriesForRevisionsSelector } from "src/selectors/data_selectors/series/create_series_for_revisions_selector";
import { createPatientsForRevisionsSelector } from "src/selectors/data_selectors/patients/create_patients_for_revisions_selector";
import { createLatestAssessmentAnswerForRevisionsSelector } from "src/selectors/data_selectors/assessment_answers/create_latest_assessment_answer_for_revisions_selector";
import { createLatestFramesForRevisionsSelector } from "src/selectors/data_selectors/frames/create_latest_frames_for_revisions_selector";
import { createLatestRegionsForRevisionsSelector } from "src/selectors/data_selectors/regions/create_latest_regions_for_revisions_selector";
import { createSeriesTrendsForRevisionsSelector } from "src/selectors/data_selectors/series_trends/create_series_trends_for_revisions_selector";
import { createSchemaByTabForRevisionsSelector } from "src/selectors/data_selectors/assessment_schemas/create_schema_by_tab_for_revisions_selector";
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";

import { eventsResourceSelectors } from "src/selectors/data_selectors/events";
import { createLatestSignedEventQueryByRevisionIdForRevisionsFilterSelector } from "src/selectors/api_selectors/global/events/create_latest_signed_event_query_by_revision_id_for_revisions_filter_selector";
import { createLatestLockedEventQueryByRevisionIdForRevisionsFilterSelector } from "src/selectors/api_selectors/global/events/create_latest_locked_event_query_by_revision_id_for_revisions_filter_selector";
import { createPayersForRevisionsSelector } from "src/selectors/data_selectors/payers/create_payers_for_revisions_selector";

import {
  brandingSelector,
  brandedLogoPngSelector,
  woundNumbersEnabledSelector,
  bwatScoreEnabledSelector,
  encounterNamingConventionSelector,
  visitSequenceReportingEnabledSelector,
  payerDashboardEnabledSelector,
  etiologyVerifiedQuestionEnabledSelector
} from "src/selectors/ui_config_selectors/global";

import { createLocalizationDictionaryForRevisionsSelector } from "src/selectors/section_selectors/global";
import { assessmentVersionSelector } from "src/selectors/config_selectors";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

// Model
import { isAutodepth } from "src/models/regions";
import { getPatientQrCodeDataURL } from "src/models/patients";

// Utils
import {
  getImageDataUrlFromPath,
  getImageDataUrlFromLocalPath,
  renderChartToImageDataUrl
} from "src/utils/image_data_url";

// Chart generation
import { woundHeaderClosureChart, woundHeaderPushChart } from "src/charts";
import { getChartUrlForPdf } from "src/utils/get_chart_url_for_pdf";

// PDF generation
import { createRevisionPdf } from "src/pdfs/create_revision_pdf";

/**
 * create a selector that returns a function that is used to asynchronously
 * generate a pdf file definition for use with pdfmake
 * @param {Object} props
 * @param {array} props.revisions - revisions to print
 * @param {array} props.filters - filters used to load revisions
 * @returns {Function} selector
 */
export function createBulkRevisionPdfDefinitionForFiltersSelector({
  revisions,
  filters // TODO - remove dependence on this - it's required because 'events' resource doesn't have a revision_id so we can't associate them locally
}) {
  return createSelector(
    createSeriesForRevisionsSelector({ revisions }),
    createPatientsForRevisionsSelector({ revisions }),
    createLatestAssessmentAnswerForRevisionsSelector({ revisions }),
    createLatestFramesForRevisionsSelector({ revisions }),
    createLatestRegionsForRevisionsSelector({ revisions }),
    createSchemaByTabForRevisionsSelector({ revisions }),
    createSeriesTrendsForRevisionsSelector({ revisions }),
    createPayersForRevisionsSelector({ revisions }),
    createLatestSignedEventQueryByRevisionIdForRevisionsFilterSelector({
      filters
    }),
    createLatestLockedEventQueryByRevisionIdForRevisionsFilterSelector({
      filters
    }),
    eventsResourceSelectors.metaSelector,
    eventsResourceSelectors.dataSelector,
    brandingSelector,
    brandedLogoPngSelector,
    woundNumbersEnabledSelector,
    bwatScoreEnabledSelector,
    encounterNamingConventionSelector,
    facilitiesResourceSelectors.dataSelector,
    visitSequenceReportingEnabledSelector,
    payerDashboardEnabledSelector,
    createLocalizationDictionaryForRevisionsSelector({ revisions }),
    assessmentVersionSelector,
    etiologyVerifiedQuestionEnabledSelector,
    subtypeKeysForAllSchemasSelector,
    (
      seriesByRevision,
      patientsByRevision,
      assessmentAnswersByRevision,
      framesByRevision,
      regionsByRevision,
      schemasByRevision,
      seriesTrendsByRevision,
      payersByRevision,
      signedEventContexts,
      lockedEventContexts,
      eventsMeta,
      eventsData,
      branding,
      brandedLogoPng,
      woundNumbersEnabled,
      bwatScoreEnabled,
      encounterNamingConvention,
      facilities,
      visitSequenceEnabled,
      payerDashboardEnabled,
      schemaLocalizationDictionaryByAssessmentIds,
      assessmentVersion,
      etiologyVerifiedQuestionEnabled,
      subtypeKeysForSchema
    ) => {
      // Get latest signed event for each revision
      const lastestSignedEventsByRevisionId = Object.keys(
        signedEventContexts
      ).reduce((agg, revId) => {
        const context = signedEventContexts[revId];
        const meta = eventsMeta[context];
        const eventId = meta && meta.ids.length ? meta.ids[0] : undefined;
        if (eventId) {
          agg[revId] = eventsData[eventId];
        } else {
          agg[revId] = undefined;
        }
        return agg;
      }, {});

      // Get latest locked event for each revision
      const lastestLockedEventsByRevisionId = Object.keys(
        lockedEventContexts
      ).reduce((agg, revId) => {
        const context = lockedEventContexts[revId];
        const meta = eventsMeta[context];
        const eventId = meta && meta.ids.length ? meta.ids[0] : undefined;
        if (eventId) {
          agg[revId] = eventsData[eventId];
        } else {
          agg[revId] = undefined;
        }
        return agg;
      }, {});

      // Get correct logo for branding
      const logo = brandedLogoPng;

      // Prepare pdf content object
      let pdfContentPayload = revisions.map(rev => {
        const patientLastFaciltyId = get(
          patientsByRevision[rev.id],
          "attributes.lastFacilityId",
          undefined
        );
        const patientLastFacility = Object.values(facilities).find(facility => {
          return facility.id === patientLastFaciltyId;
        });
        return {
          revision: rev,
          payersForRevision: payersByRevision[rev.id],
          patient: patientsByRevision[rev.id],
          patientLastFacility,
          series: seriesByRevision[rev.id],
          assessmentAnswer: assessmentAnswersByRevision[rev.id],
          frame: framesByRevision[rev.id],
          region: regionsByRevision[rev.id],
          isAutodepth: isAutodepth(regionsByRevision[rev.id]),
          schemas: schemasByRevision[rev.id],
          seriesTrends: seriesTrendsByRevision[rev.id],
          latestSignedEvent: lastestSignedEventsByRevisionId[rev.id],
          latestLockedEvent: lastestLockedEventsByRevisionId[rev.id]
          // Note: revisionIsFirstInSeries would need a new attribute on /revisions
          // - not needed at the moment - doesn't matter for any visiblePredicates
        };
      });

      let globalContent = {
        reportName: "WOUND EVALUATION"
      };

      const pdfOptions = {
        branding,
        woundNumbersEnabled,
        bwatScoreEnabled,
        encounterNamingConvention,
        visitSequenceEnabled,
        payerDashboardEnabled,
        schemaLocalizationDictionary: schemaLocalizationDictionaryByAssessmentIds,
        assessmentVersion,
        etiologyVerifiedQuestionEnabled,
        subtypeKeysForSchema
      };

      return () => {
        const logoImagePromise = getImageDataUrlFromLocalPath(logo);

        const imagePromises = pdfContentPayload.map(content => {
          const hasImage = get(content.frame, "attributes.hasImage", false);
          const pdfUrl = get(content.frame, "attributes.pdfUrl", "");
          const contentType = get(
            content.frame,
            "frame.attributes.contentType",
            ""
          );
          const woundType = get(
            content.assessmentAnswer,
            "attributes.answersJson.woundType",
            ""
          );

          return Promise.all([
            hasImage ? getImageDataUrlFromPath(pdfUrl) : Promise.resolve(null),
            getPatientQrCodeDataURL(content.patient)
          ]).then(([woundDataUrl, qrcodeUrl]) => {
            content.images = {
              wound: woundDataUrl,
              qrCode: qrcodeUrl,
              closureChart:
                contentType == "declined"
                  ? null
                  : renderChartToImageDataUrl(
                      woundHeaderClosureChart(content.seriesTrends),
                      600,
                      450
                    ),
              pushChart: pressureWoundTypes.includes(woundType)
                ? renderChartToImageDataUrl(
                    woundHeaderPushChart(content.seriesTrends),
                    600,
                    450
                  )
                : null,
              bwatChart: bwatScoreEnabled
                ? getChartUrlForPdf(content.seriesTrends, "bwatTrend", {
                    max: 70
                  })
                : null,
              imageText:
                contentType == "declined"
                  ? "Patient Declined Photo"
                  : "No Image"
            };
          });
        });

        return Promise.all([logoImagePromise, ...imagePromises]).then(
          ([logoImageDataUrl]) => {
            globalContent.logo = logoImageDataUrl;

            return pdfContentPayload.map(async content => {
              return {
                content,
                pdfDefinition: await createRevisionPdf(
                  [content],
                  globalContent,
                  pdfOptions
                )
              };
            });
          }
        );
      };
    }
  );
}
