import { apiGeneric } from "./api_generic";
import { patientADT } from "./patient_adt";
import { errorMessages } from "./error_messages";
import { alerts } from "./alerts";
import { successMessages } from "./success_messages";

export const interaction = {
  alerts,
  apiGeneric,
  patientADT,
  errorMessages,
  successMessages
};
