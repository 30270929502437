import { combineReducers } from "redux";

//Constants
import { alertsSectionTabs } from "src/constants";

//Section Reducer Factory
import {
  createSectionFiltersReducer,
  createSectionSelectedFilterIdReducer
} from "src/ui_processors/global/reducer_creators";

//UI Processors
import SingleValue from "src/ui_processors/single_value";

// Constants
import { sections } from "src/constants";

export const alertsSectionReducers = combineReducers({
  filters: createSectionFiltersReducer(sections.alerts),
  selectedFilterId: createSectionSelectedFilterIdReducer(sections.alerts),
  selectedTab: SingleValue.createReducer(
    "selectedTab",
    alertsSectionTabs.alerts
  ),
  alertsTablePageLimit: SingleValue.createReducer("alertsTablePageLimit", 10)
});
