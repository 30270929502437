/**
 * Create action types for a section
 * @param {string} section the section name eg. wounds, survey
 * @returns {Object} action types object
 */
export function createSectionActionTypes(section) {
  let sectionName = section.toUpperCase() + "_SECTION";

  return {
    selectCheckbox: `${sectionName}_SELECT_CHECKBOX`,
    deselectCheckbox: `${sectionName}_DESELECT_CHECKBOX`,
    selectSavedFilter: `${sectionName}_SELECT_SAVED_FILTER`,
    deselectSavedFilter: `${sectionName}_DESELECT_SAVED_FILTER`,
    setSearchString: `${sectionName}_SET_SEARCH_STRING`,
    selectDate: `${sectionName}_SELECT_DATE`,
    setSingleValue: `${sectionName}_SET_SINGLE_VALUE`
  };
}
