import React from "react";
import PropTypes from "prop-types";

//CSS
import styles from "./style.module.less";

//Containers
import { DownloadManagerHeaderContainer } from "src/containers/download_manager/download_manager_header_container";
import { DownloadManagerBodyContainer } from "src/containers/download_manager/download_manager_body_container";

export class DownloadManagerComponent extends React.Component {
  static propTypes = {
    shouldShowBody: PropTypes.bool.isRequired
  };

  render() {
    const { shouldShowBody } = this.props;
    return (
      <div className={`${styles["download-manager-container"]}`}>
        <DownloadManagerHeaderContainer />
        {shouldShowBody && (
          <div className={styles["download-manager-body-container"]}>
            <DownloadManagerBodyContainer />
          </div>
        )}
      </div>
    );
  }
}
