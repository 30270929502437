import { createSelector } from "reselect";
import { createAssessmentsFirstPagePayloadSelectorWithProgress } from "src/selectors/query_selectors/wounds";

import { buildQueryString } from "src/utils/build_query_string";

export const createAssessmentsFirstPageContextSelectorWithProgress = progressFilter => {
  return createSelector(
    createAssessmentsFirstPagePayloadSelectorWithProgress(progressFilter),
    payload => {
      return buildQueryString(payload);
    }
  );
};
