import { connect } from "react-redux";
// Constants
import { sections } from "src/constants";

// UI Processors
import { createSectionSelectors } from "src/ui_processors";

// Components
import { SideBarHeaderComponent } from "src/components/side_bar_header_component";

function mapStateToProps(state) {
  return {
    saveFilterButtonEnabled: createSectionSelectors(
      sections.woundRisk
    ).saveFilterButtonEnabledSelector(state)
  };
}

export const SideBarWoundRiskHeaderContainer = connect(mapStateToProps)(
  SideBarHeaderComponent
);
