import { createSelector } from "reselect";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Selectors
import { createAllPagesPayloadSelector } from "../create_all_pages_payload_selector";

/**
 * create selector that gets context for all page of patients
 */
export function createAllPagesContextSelector() {
  return createSelector(
    createAllPagesPayloadSelector(),
    allPagesPayloads => allPagesPayloads.map(buildQueryString)
  );
}
