import { combineReducers } from "redux";

//Constants
import { alertsSectionTabs } from "src/constants";

//Section Reducer Factory
import {
  createSectionFiltersReducer,
  createSectionSelectedFilterIdReducer
} from "src/ui_processors/global/reducer_creators";

//UI Processors
import SingleValue from "src/ui_processors/single_value";

// Constants
import { sections } from "src/constants";

export const woundRiskReducers = combineReducers({
  filters: createSectionFiltersReducer(sections.woundRisk),
  selectedFilterId: createSectionSelectedFilterIdReducer(sections.woundRisk),
  selectedTab: SingleValue.createReducer(
    "selectedTab",
    alertsSectionTabs.woundRisk
  ),
  woundRiskPageLimit: SingleValue.createReducer("woundRiskPageLimit", 25)
});
