import React, { useContext } from "react";
import { ComplianceContext } from "./compliance_provider_component";

//CSS
import styles from "./style.module.less";

export function TableNavigatorComponent(props) {
  const { pageCount } = props;
  const { state, dispatch } = useContext(ComplianceContext);
  const selectTablePage = value => {
    dispatch({
      type: "selectPageNumber",
      payload: { pageNumber: parseInt(value) }
    });
  };

  const getPageButtons = (pageNumber, pageCount) => {
    let buttons = [0];
    if (pageCount > 3) {
      let fromPage, toPage;
      if (pageNumber === pageCount - 1) {
        fromPage = pageNumber - 2;
        toPage = pageNumber;
      } else if (pageNumber + 1 === pageCount - 1) {
        fromPage = pageNumber - 1;
        toPage = pageNumber + 1;
      } else {
        fromPage = pageNumber;
        toPage = pageNumber + 2;
      }
      buttons = [fromPage, fromPage + 1, toPage];
    } else if (pageCount === 3) {
      buttons = [0, 1, 2];
    } else if (pageCount === 2) {
      buttons = [0, 1];
    }
    return buttons;
  };

  const pageButtons = getPageButtons(state.pageNumber, pageCount);
  return (
    <div className="pull-right">
      <FirstPage selectTablePage={selectTablePage} />
      <PreviousPage
        pageNumber={state.pageNumber}
        selectTablePage={selectTablePage}
      />
      {pageButtons.length &&
        pageButtons.map((pageNumber, index) => {
          return (
            <PageButton
              key={index}
              pageNumber={pageNumber}
              selectTablePage={selectTablePage}
            />
          );
        })}
      <NextPage
        pageNumber={state.pageNumber}
        pageCount={pageCount}
        selectTablePage={selectTablePage}
      />
      <LastPage pageCount={pageCount} selectTablePage={selectTablePage} />
    </div>
  );
}

const FirstPage = ({ ...props }) => {
  const { selectTablePage } = props;
  const arrows = "<<";
  const page = 0;
  return (
    <button
      className={`btn btn-default ${styles["table-navigator-button"]}`}
      onClick={() => selectTablePage(page)}
    >
      {arrows}
    </button>
  );
};

const PreviousPage = ({ ...props }) => {
  const { pageNumber, selectTablePage } = props;
  const arrow = "<";
  const page = pageNumber > 0 ? pageNumber - 1 : 0;
  return (
    <button
      className={`btn btn-default ${styles["table-navigator-button"]}`}
      onClick={() => selectTablePage(page)}
    >
      {arrow}
    </button>
  );
};

const PageButton = ({ ...props }) => {
  const { pageNumber, selectTablePage } = props;
  const showPage = pageNumber + 1;
  return (
    <button
      className={`btn btn-default ${styles["table-navigator-button"]}`}
      onClick={() => selectTablePage(pageNumber)}
    >
      {showPage}
    </button>
  );
};

const NextPage = ({ ...props }) => {
  const { pageNumber, pageCount, selectTablePage } = props;
  const arrow = ">";
  const page = pageNumber === pageCount - 1 ? pageCount - 1 : pageNumber + 1;
  return (
    <button
      className={`btn btn-default ${styles["table-navigator-button"]}`}
      onClick={() => selectTablePage(page)}
    >
      {arrow}
    </button>
  );
};

const LastPage = ({ ...props }) => {
  const { pageCount, selectTablePage } = props;
  const arrows = ">>";
  return (
    <button
      className={`btn btn-default ${styles["table-navigator-button"]}`}
      onClick={() => selectTablePage(pageCount - 1)}
    >
      {arrows}
    </button>
  );
};
