import { trackError } from "src/error_tracking";

// Api
import { woundTalliesApi } from "src/api/wound_tallies";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";

// Actions
import { create } from "src/actions/flashMessageActions";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

/**
 * Action creator for setting loading state of survey tally export to "loading"
 * @return {Object} action
 */
function surveyTallyExportLoading() {
  return {
    type: dataActionTypes.tallyExportLoading
  };
}

/**
 * Action creator for setting loading state of survey tally export to "ready"
 * @return {Object} action
 */
function surveyTallyExportReady() {
  return {
    type: dataActionTypes.tallyExportLoadReady
  };
}

/**
 * Send api call to generate a survey tally csv, initiate download upon success.
 * @param {Object} payload The payload
 */
export function createAndDownloadSurveyTallyCsv(payload) {
  return dispatch => {
    // loading state
    dispatch(surveyTallyExportLoading());

    // analytics tracking
    startTimeTrack("API_POST_CREATE_SURVEY_TALLY_CSV");

    return woundTalliesApi
      .export({ payload })
      .then(response => {
        // analytics tracking
        finishTimeTrack("API_POST_CREATE_SURVEY_TALLY_CSV");

        // download result
        window.location.assign(response.data.url);
        // ready state
        dispatch(surveyTallyExportReady());
      })
      .catch(err => {
        // tracking
        finishTimeTrack("API_POST_CREATE_SURVEY_TALLY_CSV", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
        trackError(err);

        // TODO: Make error message contextual to what actually happened
        dispatch(
          create("Sorry, there was an error downloading your export", "error")
        );
        dispatch(surveyTallyExportReady());
      });
  };
}
