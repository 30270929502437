import { createSelector } from "reselect";

import { adminSectionSelector } from "../admin_section_selector";

export const userNameSearchStringSelector = createSelector(
  adminSectionSelector,
  section => {
    const { searchFilters } = section;
    if (searchFilters.length) {
      let usernameFilterObject = searchFilters.find(filter => {
        return filter.key === "name";
      });

      if (usernameFilterObject) {
        return usernameFilterObject.value;
      }
    }
  }
);
