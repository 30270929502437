import { connect } from "react-redux";
import { SideBarAcquiredFilterComponent } from "src/components/side_bar_acquired_filter_component";

// selectors
import { acquiredFilterSelector } from "src/selectors/section_selectors/population";

// actions
import {
  setAcquiredFilterAction,
  clearAcquiredFilterAction
} from "src/actions/section_actions/population";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

function mapStateToProps(state) {
  return {
    acquiredFilter: acquiredFilterSelector(state),
    assessmentVersion: assessmentVersionSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetAcquiredFilterAction: value => {
      dispatch(setAcquiredFilterAction(value));
    },
    sendClearAcquiredFilterAction: () => {
      dispatch(clearAcquiredFilterAction());
    }
  };
}

export const PopulationSideBarAcquiredFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBarAcquiredFilterComponent);
