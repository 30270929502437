// @flow
import patients from "./patientSchema";
import locations from "./locationSchema";
import series from "./seriesSchema";
import seriesTrends from "./seriesTrendSchema";
import studies from "./studySchema";
import assessmentAnswers from "./assessmentAnswerSchema";
import users from "./userSchema";
import patientTrends from "./patientTrendSchema";

export default {
  patients,
  locations,
  series,
  studies,
  assessmentAnswers,
  users,
  seriesTrends,
  patientTrends
};
