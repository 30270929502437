/**
 * Create the payload for fetching survey tallies
 * @param {Object} options
 * @param {array} options.woundTypes
 * @param {Object} options.woundSubtyes
 * @param {array} options.locationIds
 * @param {Object} options.dateSelection - has startDate and endDate moment objects
 * @param {bool} options.includeDischarged
 * @param {bool} options.excludeNewHealed
 * @param {string} options.organizationId
 *
 * @returns {Object} payload
 */
export function createWoundTalliesGetPayload({
  answers,
  tallyContexts,
  locationIds,
  dateSelection,
  includeDischarged,
  excludeNewHealed,
  acquired,
  organizationId,
  hprRangeScore,
  bwatRangeScore,
  selectedPayersIds
}) {
  return {
    filter: {
      tallyContexts,
      answers,
      locationIds,
      ...(selectedPayersIds &&
        selectedPayersIds.length && { payerIds: selectedPayersIds.join() }),
      startDate: dateSelection.startDate.toISOString(),
      endDate: dateSelection.endDate.toISOString(),
      includeDischarged,
      excludeNewHealed,
      acquired,
      organizationId,
      hprRangeScore,
      bwatRangeScore
    }
  };
}
