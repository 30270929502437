import { createSelector } from "reselect";

//Selectors
import {
  selectedWoundOptionsSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector,
  selectedDateSelector,
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  selectedPayersIdsFilterSelector
} from "src/selectors/section_selectors/wounds";
import { progressValuesSelector } from "src/selectors/constant_selectors";

//Payload Generator
import { apiV2AssessmentsPayloadGenerator } from "src/api/wound_assessments";

export const assessmentsFirstPagePayloadAllProgressValuesSelector = createSelector(
  selectedWoundOptionsSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector,
  selectedDateSelector,
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  progressValuesSelector,
  selectedPayersIdsFilterSelector,
  (
    selectedWoundOptions,
    selectedLocationIDs,
    acquiredFilter,
    selectedEndDate,
    hprScoreMinRangeFilter,
    hprScoreMaxRangeFilter,
    bwatScoreMinRangeFilter,
    bwatScoreMaxRangeFilter,
    progressValues,
    selectedPayersIds
  ) => {
    return progressValues.reduce((agg, progress) => {
      //First Page
      const pageNumber = 0,
        pageLimit = 10;
      agg[progress] = apiV2AssessmentsPayloadGenerator(
        selectedWoundOptions,
        selectedLocationIDs,
        acquiredFilter,
        selectedEndDate,
        hprScoreMinRangeFilter,
        hprScoreMaxRangeFilter,
        bwatScoreMinRangeFilter,
        bwatScoreMaxRangeFilter,
        progress,
        pageNumber,
        pageLimit,
        selectedPayersIds
      );
      return agg;
    }, {});
  }
);
