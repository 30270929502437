import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// HOC
import { withPatientTrendsLoader } from "src/hoc";

// Components
import { NonWoundAssessmentDetailsHeaderComponent } from "src/components/non_wound_assessment_details_header_component";

// Selectors
import {
  createUserForIdSelector,
  createAssessmentAnswerGroupForIdSelector
} from "src/selectors/data_selectors";

// Selectors - legacy
import patientTrendSelectors from "@sw/selectors/patientTrendSelectors";

function mapStateToProps(state, ownProps) {
  const idSelector = () => ownProps.match.params.patientId;
  const structuredSelectors = createStructuredSelector({
    patientTrend: patientTrendSelectors.findEntity(idSelector)
  });

  return {
    ...structuredSelectors,
    assessmentAnswerGroup: createAssessmentAnswerGroupForIdSelector(
      ownProps.assessmentAnswer.attributes.assessmentAnswerGroupId
    )(state),
    user: createUserForIdSelector(ownProps.assessmentAnswer.attributes.userId)(
      state
    )
  };
}

export const NonWoundAssessmentDetailsHeaderContainer = withPatientTrendsLoader(
  connect(mapStateToProps)(NonWoundAssessmentDetailsHeaderComponent)
);
