import React from "react";
import PropTypes from "prop-types";

import SwiftLogoComponent from "src/components/logo_component";
import MedlineLogoComponent from "src/components/medline_logo_component";
import { MedlineEmblemComponent } from "src/components/icon_components/medline_emblem_component";

import { ResetPasswordFormContainer } from "src/containers/reset_password_form_container";
import styles from "./style.module.less";
import { GridRow, GridColumn } from "src/components/grid";
/**
 * Following component is used for reset form load
 * data.
  
 */
export class ResetPasswordComponent extends React.PureComponent {
  static propTypes = {
    branding: PropTypes.string.isRequired
  };

  render() {
    const { branding } = this.props;

    let logoSection, pageBackground, pageFooter;
    if (branding === "medline") {
      logoSection = (
        <div className="medline-logo">
          <MedlineLogoComponent />
          <p className="caption">NE1&#174; Wound Management System</p>
        </div>
      );
      pageBackground = (
        <div className="background">
          <MedlineEmblemComponent />
        </div>
      );
      pageFooter = (
        <div className="footer">
          <p>Powered by Swift Medical</p>
        </div>
      );
    } else {
      logoSection = (
        <div className="swift-logo">
          <SwiftLogoComponent />
        </div>
      );
    }

    return (
      <div className="reset-password-view">
        {pageBackground && pageBackground}
        <div id="container" className="container">
          <div>
            <div className={`text-center ${styles["margin-bottom10px"]}`}>
              {logoSection}
              <div className="instructions">
                <h3> Reset Password</h3>
                <small>
                  Please enter the email associated with your account
                </small>
              </div>
            </div>
            <GridRow className={styles["margin-bottom10px"]}>
              <GridColumn sm="4" className="col-sm-offset-4">
                <ResetPasswordFormContainer />
              </GridColumn>
            </GridRow>
          </div>
        </div>
        {pageFooter && pageFooter}
      </div>
    );
  }
}
