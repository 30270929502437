import { createSelector } from "reselect";
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";

//Constants
import { sections } from "src/constants";

/**
 * Memoized selector to return if download manager body should be shown or hidden
 */
export const showDownloadManagerBodySelector = createSelector(
  createSectionSelectors(sections.global).sectionSelector,
  section => {
    return section.showDownloadManagerBody;
  }
);
