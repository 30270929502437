// @flow
import React, { PureComponent } from "react";
import "font-awesome/css/font-awesome.css";
import classnames from "classnames";

type $props = {
  className?: string,
  name: string,
  size?: number
};

export default class Icon extends PureComponent {
  props: $props;
  render() {
    const { className, name, size, ...props } = this.props;
    const klass = classnames(
      "fa",
      `fa-${name}`,
      className,
      size && `fa-${size}`
    );
    return <i className={klass} {...props} />;
  }
}
