import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Components
import { SideBarFiltersComponent } from "src/components/side_bar_filters_component";

// Selectors
import { partnerNameSelector } from "src/selectors/config_selectors";
import {
  outcomesTabEnabledSelector,
  monitoringTabEnabledSelector,
  myDashboardTabEnabledSelector,
  alertsEnabledSelector,
  evaluationListEnabledSelector,
  patientAppDashboardEnabledSelector,
  payerDashboardEnabledSelector,
  visitSequenceReportingEnabledSelector,
  healingIndexDashboardEnabledSelector
} from "src/selectors/ui_config_selectors/global";
import { adminTabEnabledSelector } from "../../selectors/config_selectors/admin_tab_enabled_selector";
import {
  hprScoreEnabledSelector,
  bwatScoreEnabledSelector
} from "src/selectors/ui_config_selectors/global";

function mapStateToProps(state) {
  return {
    partnerName: partnerNameSelector(state),
    alertsTabEnabled: alertsEnabledSelector(state),
    myDashboardTabEnabled: myDashboardTabEnabledSelector(state),
    adminTabEnabled: adminTabEnabledSelector(state),
    outcomesTabEnabled: outcomesTabEnabledSelector(state),
    monitoringTabEnabled: monitoringTabEnabledSelector(state),
    hprScoreEnabled: hprScoreEnabledSelector(state),
    bwatScoreEnabled: bwatScoreEnabledSelector(state),
    evaluationListEnabled: evaluationListEnabledSelector(state),
    patientAppEnabled: patientAppDashboardEnabledSelector(state),
    payerDashboardEnabled: payerDashboardEnabledSelector(state),
    visitSequenceReportingEnabled: visitSequenceReportingEnabledSelector(state),
    healingIndexDashboardEnabled: healingIndexDashboardEnabledSelector(state)
  };
}

export const SideBarFiltersContainer = withRouter(
  connect(mapStateToProps)(SideBarFiltersComponent)
);
