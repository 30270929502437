export const loadStates = {
  loading: "loading",
  refreshing: "refreshing",
  loaded: "loaded",
  error: "error",
  ready: "ready",
  reload: "reload",
  // loadError is legacy - use error
  loadError: "loadError",
  loadingMore: "loadingMore"
};
