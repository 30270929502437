import { createSelector } from "reselect";

//Selectors
import {
  selectedWoundOptionsSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector,
  selectedDateSelector,
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  selectedPayersIdsFilterSelector
} from "src/selectors/section_selectors/wounds";

//Payload Generator
import { apiV2AssessmentsPayloadGenerator } from "src/api/wound_assessments";

export const createAssessmentsFirstPagePayloadSelectorWithProgress = progressFilter => {
  return createSelector(
    selectedWoundOptionsSelector,
    selectedLocationIdsSelector,
    acquiredFilterSelector,
    selectedDateSelector,
    hprScoreMinRangeFilterSelector,
    hprScoreMaxRangeFilterSelector,
    bwatScoreMinRangeFilterSelector,
    bwatScoreMaxRangeFilterSelector,
    selectedPayersIdsFilterSelector,
    (
      selectedWoundOptions,
      selectedLocationIDs,
      acquiredFilter,
      selectedEndDate,
      hprScoreMinRangeFilter,
      hprScoreMaxRangeFilter,
      bwatScoreMinRangeFilter,
      bwatScoreMaxRangeFilter,
      selectedPayersIds
    ) => {
      //First Page
      const pageNumber = 0,
        pageLimit = 10;

      return apiV2AssessmentsPayloadGenerator(
        selectedWoundOptions,
        selectedLocationIDs,
        acquiredFilter,
        selectedEndDate,
        hprScoreMinRangeFilter,
        hprScoreMaxRangeFilter,
        bwatScoreMinRangeFilter,
        bwatScoreMaxRangeFilter,
        progressFilter,
        pageNumber,
        pageLimit,
        selectedPayersIds
      );
    }
  );
};
