import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { clearFields, getFormValues } from "redux-form";
import flowRight from "lodash.flowright";

// Components
import { PatientFormComponent } from "src/components/forms/patient_form_component";

// Actions
import {
  createPatientsAction,
  updatePatientAction
} from "src/actions/data_actions";

// Selectors
import { createPatientFormInitialValuesSelector } from "src/selectors/form_selectors/initial_values_selectors";
import {
  createOrganizationOptionsSelector,
  createFacilityOptionsSelector,
  createUnitOptionsSelector
} from "src/selectors/form_selectors/form_option_selectors";
import { partnersArraySortSelector } from "src/selectors/data_selectors";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";
import { adminPartnerDropDownEnabledSelector } from "src/selectors/config_selectors";
import { patientAppDashboardEnabledSelector } from "src/selectors/ui_config_selectors/global";
import { userPasswordMinLengthSelector } from "src/selectors/ui_config_selectors/global";

// Constants
import { reduxFormNames } from "src/constants";

// Form Validation
import { duplicatePatientAsyncValidator } from "./duplicate_patient_async_validator";

function mapStateToProps(state, ownProps) {
  return {
    initialValues: createPatientFormInitialValuesSelector(ownProps.patient)(
      state
    ),
    formValues: getFormValues(reduxFormNames.patientForm)(state),
    partners: partnersArraySortSelector(state),
    organizations: createOrganizationOptionsSelector(
      reduxFormNames.patientForm
    )(state),
    facilities: createFacilityOptionsSelector(reduxFormNames.patientForm)(
      state
    ),
    units: createUnitOptionsSelector(reduxFormNames.patientForm)(state),
    currentUser: currentUserSelector(state),
    partnerDropDownEnabled: adminPartnerDropDownEnabledSelector(state),
    patientAppEnabled: patientAppDashboardEnabledSelector(state),
    userPasswordMinLength: userPasswordMinLengthSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendCreatePatientsAction(
      payload,
      isPatientUser,
      currentUserId,
      userPasswordMinLength
    ) {
      return dispatch(
        createPatientsAction(
          payload,
          isPatientUser,
          currentUserId,
          userPasswordMinLength
        )
      );
    },
    sendUpdatePatientAction(
      payload,
      isPatientUser,
      patientEmailChange,
      currentUserId,
      userPasswordMinLength
    ) {
      return dispatch(
        updatePatientAction(
          payload,
          isPatientUser,
          patientEmailChange,
          currentUserId,
          userPasswordMinLength
        )
      );
    },
    clearFormFields(...fieldNames) {
      dispatch(
        clearFields(reduxFormNames.patientForm, false, false, ...fieldNames)
      );
    }
  };
}

export const PatientFormContainer = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: reduxFormNames.patientForm,
    asyncValidate: duplicatePatientAsyncValidator,
    asyncBlurFields: ["firstName", "lastName", "birthDate"],
    asyncChangeFields: ["firstName", "lastName", "birthDate"],
    enableReinitialize: true
  })
)(PatientFormComponent);
