import { createSelector } from "reselect";
import { woundAssessmentTotalsTimeSeriesByLocationSelector } from "../wound_assessment_totals_time_series_by_location_selector";

/**
 * Selector for the total wound count series broken down by location id.
 */
export const woundAssessmentTotalsTimeSeriesByLocationDataSelector = createSelector(
  woundAssessmentTotalsTimeSeriesByLocationSelector,
  totalsSeries => totalsSeries.data
);
