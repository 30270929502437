import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

// Selectors
import { organizationsResourceSelectors } from "src/selectors/data_selectors/organizations";

// Constants
import { reduxFormFieldNames } from "src/constants";

// Utils
import { sortByName } from "src/utils/sort_by_name";

export function createOrganizationOptionsSelector(formName) {
  return createSelector(
    getFormValues(formName),
    organizationsResourceSelectors.dataSelector,
    (values, organizations) => {
      if (values && values[reduxFormFieldNames.partnerId]) {
        const orgArray = Object.values(organizations).filter(o => {
          return (
            o.attributes.partnerId == values[reduxFormFieldNames.partnerId]
          );
        });

        return sortByName(orgArray);
      }

      return [];
    }
  );
}
