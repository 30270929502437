import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { ProgressBar } from "react-bootstrap";

// Components
import { FixedAspectRatioComponent } from "src/components/fixed_aspect_ratio_component";

// Styles
import styles from "./style.module.less";

/**
 * Component used to display a loading state
 */
export class LoadingComponent extends React.PureComponent {
  static propTypes = {
    showProgress: PropTypes.bool,
    progress: PropTypes.number
  };

  renderSpinner() {
    return (
      <div>
        <FontAwesome name="spinner" size="2x" spin />
        <br />
        <br />
        Loading ...
      </div>
    );
  }

  renderProgressBar() {
    const { progress } = this.props;
    return (
      <div className={styles["progress-bar-container"]}>
        <ProgressBar
          now={progress * 100}
          label={progress ? `${Math.floor(progress * 100)}%` : ""}
        />
        <div>Loading...</div>
      </div>
    );
  }

  // TODO: remove height value passed to LoadingComponent wherever it is used
  render() {
    const { showProgress } = this.props;
    return (
      <FixedAspectRatioComponent matchParentWidth aspectRatio={9 / 16}>
        <div className={styles["content"]}>
          {showProgress ? this.renderProgressBar() : this.renderSpinner()}
        </div>
      </FixedAspectRatioComponent>
    );
  }
}
