import { createSelector } from "reselect";
import get from "lodash.get";

//Selectors
import { singleFaciltyViewModeSelector } from "src/selectors/ui_config_selectors/global";

/**
 * create a selector that takes in all saved filters for a user, and if in a single facility view mode,
 * filter for the filters that apply to that facilty only
 * @param {Array} allSavedFilters
 * @returns {function} selector
 */

export function createFilteredSavedFilterDataForViewModeSelector(
  allSavedFilters
) {
  return createSelector(
    singleFaciltyViewModeSelector,
    facilityId => {
      //If singleFaciltyViewModeSelector does not return a facility ID, we are in PCC EMC View Mode or Standalone View Mode
      if (!facilityId) {
        //Filter for the saved filters for the user that are not facility specific
        return allSavedFilters.filter(savedFilter => {
          const savedFilterParameters = get(
            savedFilter,
            "attributes.parameters",
            {}
          );
          return !savedFilterParameters.appliesToFacilityWithId;
        });
        //If singleFaciltyViewModeSelector returns a facility ID, we are in Single Facility View Mode
      } else {
        //Filter for the saved filters for the user that ARE facility specific indeed
        return allSavedFilters.filter(savedFilter => {
          const savedFilterParameters = get(
            savedFilter,
            "attributes.parameters",
            {}
          );
          return savedFilterParameters.appliesToFacilityWithId === facilityId;
        });
      }
    }
  );
}
