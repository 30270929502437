import { createSelector } from "reselect";
import { locationsToDisplaySelector } from "../locations_to_display_selector";

/**
 * Selector for facility location ids of locations to display
 */
export const facilityLocationIdsToDisplaySelector = createSelector(
  locationsToDisplaySelector,
  locations => {
    return locations
      .filter(loc => loc.tags.includes("Facility"))
      .map(loc => loc.id);
  }
);
