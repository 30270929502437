import get from "lodash.get";

import { getSubtypeStringForAnswersJson } from "src/utils/get_wound_subtype_string";

/**
 * Get the display string for a tally
 * @param {Object} tally
 * @returns {string}
 */
export function getSubtypeStringForTally(
  tally,
  schemaLocalizationDictionary,
  subtypeKeysForSchema
) {
  const woundType = get(tally, "attributes.context.woundType");

  if (!subtypeKeysForSchema[woundType]) return "";

  const subtype = get(
    tally,
    `attributes.context.${subtypeKeysForSchema[woundType]}`
  );
  if (!subtype) return "";

  return getSubtypeStringForAnswersJson({
    woundType,
    answersJson: get(tally, "attributes.context"),
    localizationDictionary: schemaLocalizationDictionary,
    subtypeKeysForSchema
  });
}
