import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uniq from "lodash.uniq";

// Helpers
import { propsAreDifferent } from "src/utils/props_are_different";

// Selectors
import {
  assessmentAnswersResourceSelectors,
  assessmentsResourceSelectors,
  createStudyForPatientIdSelector,
  createNonWoundAssessmentAnswersForPatientIdSelector
} from "src/selectors/data_selectors";

// Actions
import {
  loadNonWoundAssessmentAnswersForStudyAction,
  loadAssessmentWithSchemasAction
} from "src/actions/data_actions";

// Load States
import { loadStates } from "src/constants/load_states";

// Subscriptions
import Subscriptions from "src/subscriptions";

/**
 * HOC that is responsible for loading a list of patients
 * @param {Class} WrappedComponent the component dependent on this data
 * @return {Class} Component wrapped by the loader component
 */
export function withNonWoundAssessmentAnswersAndSchemasForStudyLoader(
  WrappedComponent
) {
  class NonWoundAssessmentAnswersLoader extends React.Component {
    static propTypes = {
      assessmentAnswers: PropTypes.array.isRequired,
      assessmentAnswersContextLoadState: PropTypes.object.isRequired,
      assessmentsContextLoadState: PropTypes.object.isRequired,
      study: PropTypes.object,
      currentUser: PropTypes.object,
      sendLoadNonWoundAssessmentAnswersForStudyAction:
        PropTypes.func.isRequired,
      sendLoadAssessmentWithSchemasAction: PropTypes.func.isRequired
    };

    componentWillMount() {
      this.loadAssessmentAnswers(this.props);
      this.loadAssessmentWithSchema(this.props);

      if (this.props.study) {
        this.subscribeToStudyChanges(this.props.study.id);
      }
    }

    componentWillReceiveProps(nextProps) {
      if (
        propsAreDifferent(nextProps, this.props, "study", "assessmentAnswers")
      ) {
        this.loadAssessmentAnswers(nextProps);
        this.loadAssessmentWithSchema(nextProps);

        if (nextProps.study) {
          this.subscribeToStudyChanges(nextProps.study.id);
        }
      }
    }

    /**
     * Subscribe for changes to study assessment answers
     * @param {string} studyId
     */
    subscribeToStudyChanges(studyId) {
      Subscriptions.subscribeToResourceChannelEvent({
        channelName: Subscriptions.resourceIdChannelNames.studyAssessmentAnswer,
        resourceId: studyId,
        eventName: Subscriptions.channelEvents.change,
        callback: this.onResourceChange,
        callbackContext: {
          resourceId: studyId,
          loadResource: this.props
            .sendLoadNonWoundAssessmentAnswersForStudyAction
        }
      });
    }

    /**
     * Unsubscribe from changes to individual series
     * @param {array} series
     */
    unsubscribeFromStudyChanges(studyId) {
      Subscriptions.unsubscribeFromResourceChannelEvent({
        channelName: Subscriptions.resourceIdChannelNames.studyAssessmentAnswer,
        resourceId: studyId,
        eventName: Subscriptions.channelEvents.change,
        callback: this.onResourceChange
      });
    }

    /**
     * Callback for reloading after pusher update
     */
    onResourceChange() {
      this.loadResource(this.resourceId);
    }

    loadAssessmentAnswers(props) {
      const {
        assessmentAnswersContextLoadState,
        study,
        sendLoadNonWoundAssessmentAnswersForStudyAction
      } = props;

      // TODO: move this context to a selector as a query string
      const context = study
        ? `studies/${study.id}/assessment-answers?filter[assessment_type]=non_wound`
        : "";

      if (!assessmentAnswersContextLoadState[context] && study) {
        sendLoadNonWoundAssessmentAnswersForStudyAction(study.id);
      }
    }

    loadAssessmentWithSchema(props) {
      const {
        assessmentAnswers,
        assessmentAnswersContextLoadState,
        assessmentsContextLoadState,
        study
      } = props;

      const context = study
        ? `studies/${study.id}/assessment-answers?filter[assessment_type]=non_wound`
        : "";

      if (
        (study,
        assessmentAnswersContextLoadState[context] == loadStates.loaded,
        assessmentAnswers.length)
      ) {
        // find the assessment ids that haven't been loaded yet
        const ids = assessmentAnswers
          .map(aa => aa.attributes.assessmentId)
          .filter(id => {
            return !assessmentsContextLoadState[id];
          });

        uniq(ids).forEach(id => {
          this.props.sendLoadAssessmentWithSchemasAction(id);
        });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state, props) {
    return {
      assessmentAnswers: createNonWoundAssessmentAnswersForPatientIdSelector(
        props.match.params.patientId
      )(state),
      assessmentAnswersContextLoadState: assessmentAnswersResourceSelectors.contextLoadStateSelector(
        state
      ),
      assessmentsContextLoadState: assessmentsResourceSelectors.contextLoadStateSelector(
        state
      ),
      study: createStudyForPatientIdSelector(props.match.params.patientId)(
        state
      )
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      sendLoadNonWoundAssessmentAnswersForStudyAction(studyId) {
        dispatch(loadNonWoundAssessmentAnswersForStudyAction(studyId));
      },
      sendLoadAssessmentWithSchemasAction(assessmentId) {
        dispatch(loadAssessmentWithSchemasAction(assessmentId));
      }
    };
  }

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(NonWoundAssessmentAnswersLoader)
  );
}
