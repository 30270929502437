import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import flowRight from "lodash.flowright";

// HOC
import { withAssessmentAnswersForStudyLoader } from "src/hoc";

// Components
import { AssessmentAnswerHistoryListComponent } from "src/components/assessment_answer_history_list_component";

// Selectors
import {
  assessmentAnswersResourceSelectors,
  createStudyIdForPatientIdSelector
} from "src/selectors/data_selectors";

function mapStateToProps(state, ownProps) {
  const patientId = ownProps.match.params.patientId;
  const studyIdSelector = createStudyIdForPatientIdSelector(patientId);

  return {
    assessmentAnswers: assessmentAnswersResourceSelectors.createDataForContextSelectorWithSelector(
      studyIdSelector
    )(state),
    assessmentAnswersLoadState: assessmentAnswersResourceSelectors.createContextLoadStateSelectorWithContextStringSelector(
      studyIdSelector
    )(state),
    assessmentAnswersMeta: assessmentAnswersResourceSelectors.createMetaContextSelectorWithSelector(
      studyIdSelector
    )(state)
  };
}

export const AssessmentAnswerHistoryListContainer = flowRight(
  withRouter,
  withAssessmentAnswersForStudyLoader,
  connect(mapStateToProps)
)(AssessmentAnswerHistoryListComponent);
