import { createSelector } from "reselect";
import { createLatestRegionForRevisionSelector } from "../create_latest_region_for_revision_selector";

/**
 * create a selector to check if the first region in a revision has a drawable measurement.
 * @param {Object} revision
 */
export function createLatestRegionForRevisionHasMeasurementSelector(revision) {
  return createSelector(
    createLatestRegionForRevisionSelector(revision),
    region => {
      if (!revision || !region) {
        return false;
      }

      const measurement = region.attributes.measurement;
      if (Object.keys(measurement).length === 0) return false;

      if (
        measurement.regionAnalyser.region.area &&
        measurement.regionAnalyser.region.polygon &&
        measurement.scaleDeterminator.imageScaleFactorComputed &&
        (measurement.scaleDeterminator.scaleMethod == "BLUE_DOT" ||
          measurement.scaleDeterminator.scaleMethod == "SWIFT_FID")
      ) {
        return true;
      }

      return false;
    }
  );
}
