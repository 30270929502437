import { createSelector } from "reselect";

//Payload Selector
import { firstPagePayloadForSelectedProgressSelector } from "src/selectors/query_selectors/wounds/first_page_payload_for_selected_progress_selector";

//Util
import { buildQueryString } from "src/utils/build_query_string";

export const firstPageContextForSelectedProgressSelector = createSelector(
  firstPagePayloadForSelectedProgressSelector,
  payload => buildQueryString(payload)
);
