import { connect } from "react-redux";

import {
  isPristine,
  isSubmitting,
  getFormValues,
  change,
  clearFields,
  reset
} from "redux-form";
//components
import { UserFormComponent } from "src/components/forms/user_form_component";

//actions
import { createUserAction, updateUserAction } from "src/actions/data_actions";

//selectors
import { partnersArraySortSelector } from "src/selectors/data_selectors";
import {
  createUserFormPartnerOrganizationsOptionsSelector,
  createUserFormFacilityOptionsSelector,
  createUserFormFacilityUnitOptionsSelector,
  createUserFormDefaultFacilityOptionsSelector
} from "src/selectors/section_selectors/admin";
//Selectors
import {
  rolesResourceSelectors,
  customRolesResourceSelectors,
  usersResourceSelectors
} from "src/selectors/data_selectors";
import {
  adminPartnerDropDownEnabledSelector,
  currentUserSelector
} from "src/selectors/config_selectors";
import { userPasswordMinLengthSelector } from "src/selectors/ui_config_selectors/global";
import { reduxFormNames } from "src/constants";

function mapStateToProps(state) {
  return {
    formName: reduxFormNames.userEditForm,
    units: createUserFormFacilityUnitOptionsSelector(
      reduxFormNames.userEditForm
    )(state),
    facilities: createUserFormFacilityOptionsSelector(
      reduxFormNames.userEditForm
    )(state),
    partners: partnersArraySortSelector(state),
    organizations: createUserFormPartnerOrganizationsOptionsSelector(
      reduxFormNames.userEditForm
    )(state),
    pristine: isPristine(reduxFormNames.userEditForm)(state),
    submitting: isSubmitting(reduxFormNames.userEditForm)(state),
    formValues: getFormValues(reduxFormNames.userEditForm)(state),
    roles: rolesResourceSelectors.dataSelector(state),
    customRoles: customRolesResourceSelectors.dataSelector(state),
    partnerDropDownEnabled: adminPartnerDropDownEnabledSelector(state),
    currentUser: currentUserSelector(state),
    createLoadState: usersResourceSelectors.postLoadStateSelector(state),
    defaultFacilities: createUserFormDefaultFacilityOptionsSelector(
      reduxFormNames.userEditForm
    )(state),
    userPasswordMinLength: userPasswordMinLengthSelector(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sendCreateUserAction(payload) {
      return dispatch(createUserAction(payload));
    },
    sendUpdateUserAction(payload) {
      return dispatch(updateUserAction(payload));
    },
    resetForm() {
      return dispatch(reset(reduxFormNames.userEditForm));
    },
    changeField(fieldName, fieldValue) {
      dispatch(change(reduxFormNames.userEditForm, fieldName, fieldValue));
    },
    clearFields(...fieldNames) {
      dispatch(
        clearFields(reduxFormNames.userEditForm, false, false, ...fieldNames)
      );
    }
  };
}
export const EditUserFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFormComponent);
