// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
type $props = {
  open?: boolean,
  onClose?: Function,
  className?: string,
  children?: any
};

export default class Modal extends PureComponent {
  props: $props;
  render() {
    const { className, open, children } = this.props;
    const klass = classnames("modal", {
      ["modal-open"]: !!open,
      [className || ""]: !!className
    });
    return <div className={klass}>{children}</div>;
  }
}
