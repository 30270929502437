import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

/**
 * Selector to determine location dropdown will be enable or not
 */
export function patientsAdtLocationEnabledSelector(formName) {
  return createSelector(
    getFormValues(formName),
    form => {
      if (
        form &&
        ((form["action"] && form["action"] == "A03") || !form["action"])
      )
        return true;
      else return false;
    }
  );
}
