import i18n from "src/translations";
import get from "lodash.get";
/**
 * cell formatter for alert rules description
 * @param {Object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.description the description of the alert rule
 */
export function alertRulesDescriptionFormatter(cell, row) {
  const templateName = get(row, "attributes.name", "");
  return i18n.exists(`data.ruleTemplateNamesByName.${templateName}`)
    ? i18n.t(`data.ruleTemplateNamesByName.${templateName}`)
    : templateName;
}
