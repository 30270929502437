import { createResourceActions } from "src/redux_resources_v1";
import normalize from "json-api-normalizer";

// Error tracking
import { trackError } from "src/error_tracking";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

/**
 * Create a load data action creator
 * @param {Object} options
 * @param {string} options.resourceName resource name
 * @param {Object} options.api api object that responds to "patch" call
 * @param {function} options.onSuccess thunk action for success case
 * @param {function} options.onError thunk action for error case
 * @returns {Function} action creator for a thunk action to Patch the resource
 */
export function createPatchDataAction({
  resourceName,
  api,
  onSuccess,
  onError
}) {
  const analyticsEventName = `API_PATCH_${resourceName.toUpperCase()}`;
  const resourceActions = createResourceActions(resourceName);

  return ({ payload }) => {
    return dispatch => {
      dispatch(resourceActions.loadingPatchAction(payload.id));
      startTimeTrack(analyticsEventName);

      return api
        .patch({ payload })
        .then(response => {
          finishTimeTrack(analyticsEventName);

          // Normalize response
          const normalizedData = normalize(response.data)[resourceName];

          // Update the resource
          dispatch(
            resourceActions.populatePatchAction({
              data: normalizedData,
              ids: [normalizedData.id]
            })
          );

          if (onSuccess) {
            onSuccess(dispatch);
          }
        })
        .catch(err => {
          finishTimeTrack(analyticsEventName, {
            error: true,
            errorCode: err.response ? err.response.status : undefined
          });
          trackError(err);
          dispatch(resourceActions.loadErrorPatchAction());

          if (onError) {
            onError(dispatch);
          }
        });
    };
  };
}
