import { createSelector } from "reselect";

import { sections } from "src/constants";

import { createSectionSelectors } from "src/ui_processors";
const sectionSelectors = createSectionSelectors(sections.survey);

export const excludeNewWoundsSelector = createSelector(
  sectionSelectors.filtersSelector,
  filters => {
    return filters.some(filter => {
      return filter.key == "excludeNewWounds";
    });
  }
);
