import React from "react";
import PropTypes from "prop-types";

import { TabViewHeaderCountTitleComponent } from "./tab_view_header_count_title_component";
import { TabViewHeaderTitleComponent } from "./tab_view_header_title_component";

import styles from "./style.module.less";

/**
 * Generic componenet for displaying tabs with a child container.
 * Requires three props:
 * tabs:
 * - array of tab objects.
 * - must specify a 'value' key for each tab.
 * selectedTab: corresponding to the tabs `value`
 * selectTab: function that accepts tab value for selecting a tab
 */
export class TabViewComponent extends React.Component {
  static propTypes = {
    tabHeaderType: PropTypes.oneOf(["count_title", "title"]).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedTabValue: PropTypes.string.isRequired,
    selectTab: PropTypes.func.isRequired,
    sizeLarge: PropTypes.bool,
    horizontalSpread: PropTypes.bool,
    marginClass: PropTypes.string
  };

  /**
   * Checks if a tab is active and returns the active css class if thats the case
   * @param {String} tabValue the tab value
   * @return {String}
   */
  getActiveClass(tabValue) {
    if (tabValue == this.props.selectedTabValue) {
      return "active";
    }
  }

  /**
   * Handle a tab being clicked - pass event to selectTab prop
   */
  onTabClicked = (event, tabValue) => {
    event.preventDefault();
    this.props.selectTab(tabValue);
  };

  /**
   * Render the tabs defined by the "tabs" prop
   */
  renderTabs() {
    const {
      tabHeaderType,
      tabs,
      sizeLarge,
      horizontalSpread,
      marginClass
    } = this.props;
    const navJustified = horizontalSpread ? "" : "nav-justified";

    // Render a tab label for each tab data and hook selection into the onTabChanged
    // NOTE: we could add support for different types of tab labels here if needed
    const tabsList = tabs.map((tab, index) => {
      let tabHeaderComponent;
      switch (tabHeaderType) {
        case "count_title":
          tabHeaderComponent = (
            <TabViewHeaderCountTitleComponent
              count={tab.count}
              title={tab.title}
            />
          );
          break;

        case "title":
          tabHeaderComponent = (
            <TabViewHeaderTitleComponent
              title={tab.title}
              titleSize={sizeLarge ? "title-tab-large" : ""}
            />
          );
      }

      return (
        <li
          key={index}
          className={this.getActiveClass(tab.value)}
          onClick={event => this.onTabClicked(event, tab.value)}
        >
          <a href="#!">{tabHeaderComponent}</a>
        </li>
      );
    });

    return (
      <ul
        className={`nav nav-tabs ${navJustified} ${styles["tabs"]} ${
          styles[marginClass]
        }`}
      >
        {tabsList}
      </ul>
    );
  }

  render() {
    return (
      <div>
        <div>{this.renderTabs()}</div>
        <div>{this.props.children}</div>
      </div>
    );
  }
}
