import normalize from "json-api-normalizer";

// Constants
import { resourceNames } from "src/constants";

import { woundSeriesApi } from "src/api/wound_series";

import { createLoadDataAction } from "src/actions/data_actions/create_load_data_action";

// Model
import { mapMeasurementsToValues } from "src/models/revisions";

export const loadWoundSeriesAction = createLoadDataAction({
  resourceName: resourceNames.woundSeries,
  api: woundSeriesApi,
  responseNormalizer: response => {
    // Normalize wound series
    const normalized = normalize(response);
    let woundSeries = normalized.woundSeries || {};
    woundSeries = Object.values(woundSeries).reduce((agg, woundSeries) => {
      agg[woundSeries.id] = mapMeasurementsToValues(woundSeries);
      return agg;
    }, {});

    return {
      [resourceNames.woundSeries]: woundSeries
    };
  }
});
