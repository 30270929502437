import { roleValues } from "src/models/roles";

export const createUserPayloadFromPatientPayload = (
  payload,
  patientId,
  userPasswordMinLength,
  patientUserId
) => {
  let stringLegnthPassword = userPasswordMinLength - 3;
  let patientData = payload.data;
  let generatedPassword =
    Math.random()
      .toString(36)
      .slice(-stringLegnthPassword) + "A1!";

  const userPayload = {
    data: {
      attributes: {
        partner_id: patientData.attributes.partner_id || null,
        email: patientData.attributes.patient_email,
        password: generatedPassword,
        own_patient_id: patientId,
        profile: {
          first_name: patientData.attributes.first_name,
          last_name: patientData.attributes.last_name,
          designation: null,
          default_facility_id: patientData.attributes.facility_id,
          phone_number: patientData.attributes.phone_number,
          birth_at: patientData.attributes.birth_at
        }
      },
      relationships: {
        roles: {
          data: [{ type: "roles", id: roleValues.patientUserRole }]
        },
        patients: {
          data: [
            {
              type: "patients",
              id: patientId
            }
          ]
        },
        organizations: {
          data: [
            {
              type: "organizations",
              id: patientData.attributes.organization_id || null
            }
          ]
        },
        facilities: {
          data: [
            {
              type: "facilities",
              id: patientData.attributes.facility_id || null
            }
          ]
        },
        facility_units: {
          data: [
            {
              type: "facility_units",
              id: patientData.attributes.facility_unit_id || null
            }
          ]
        }
      },
      type: "users",
      ...(patientUserId && { id: patientUserId || null })
    }
  };
  return userPayload;
};
