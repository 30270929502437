// @flow
import { Record } from "immutable";
import DateValueModel from "./DateValueModel";
import { convertInput } from "src/utils/modelUtils";

export const recordInput = {
  id: "",
  nortonPlusTrend: new DateValueModel(),
  bradenTrend: new DateValueModel(),
  closureTrend: new DateValueModel(),
  healingPerformance: new DateValueModel(),
  overallProgress: new DateValueModel(),
  pushTrend: new DateValueModel(),
  stageTrend: new DateValueModel(),
  hprTrend: new DateValueModel(),
  bwatTrend: new DateValueModel()
};

export default class TrendModel extends Record(recordInput) {
  constructor(opts: Object = {}) {
    super(
      convertInput(
        {
          nortonPlusTrend: trend => new DateValueModel(trend),
          bradenTrend: trend => new DateValueModel(trend),
          closureTrend: trend => new DateValueModel(trend),
          healingPerformance: trend => new DateValueModel(trend),
          overallProgress: trend => new DateValueModel(trend),
          pushTrend: trend => new DateValueModel(trend),
          stageTrend: trend => new DateValueModel(trend),
          hprTrend: trend => new DateValueModel(trend),
          bwatTrend: trend => new DateValueModel(trend)
        },
        opts
      )
    );
  }
}
