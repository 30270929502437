import { createSelector } from "reselect";
import { createAllWeeksWoundsClinicalChartDataPayloadSelector } from "../create_all_weeks_wounds_chart_payload_selector";
import { buildQueryString } from "src/utils/build_query_string";

export function createAllWeeksWoundsClinicalChartDataContextSelector() {
  return createSelector(
    createAllWeeksWoundsClinicalChartDataPayloadSelector(),
    payloads => {
      return payloads.map(buildQueryString);
    }
  );
}
