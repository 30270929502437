import { createSelector } from "reselect";

/**
 * create a selector to get the api context for admin users roles
 * @returns {function} selector
 */
export function createAdminUsersRolesContextSelector() {
  return createSelector(
    () => {
      return "adminUsersRoles";
    },
    context => {
      return context;
    }
  );
}
