// @flow
import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import moment from "moment";
import zip from "lodash.zip";
import zipObject from "lodash.zipobject";
import Chart2 from "chart.js";
import DateValueModel from "@sw/models/TrendModel/DateValueModel";

type $state = {
  chart: Chart2 | null
};

type $props = {
  data: DateValueModel,
  beginAnywhere?: boolean,
  max?: number,
  noMax?: boolean,
  min?: number,
  userCallback?: Function,
  fixedStepSize?: number,
  type?: string,
  noDecimalYAxis?: Boolean
};

export default class Chart extends PureComponent {
  props: $props;
  state: $state;
  canvas: any;
  state = {
    chart: null
  };
  componentDidMount() {
    this.initChart();
  }

  componentWillUnmount() {
    if (this.state.chart) {
      this.state.chart.destroy();
    }
  }
  componentWillUpdate(nextProps: $props, nextState: $state) {
    const { chart } = nextState;
    const { values, dates } = nextProps.data;
    if (chart) {
      const data = zip(dates.toArray(), values.toArray()).map(
        zipObject.bind(null, ["x", "y"])
      );
      chart.data.datasets[0].data = data;
      chart.update();
    }
  }

  initChart = () => {
    const ctx = findDOMNode(this.canvas).getContext("2d");
    const {
      beginAnywhere,
      noMax,
      max = 100,
      min,
      userCallback,
      fixedStepSize,
      type,
      noDecimalYAxis
    } = this.props;
    const chart = new Chart2(ctx, {
      type: type || "line",
      data: {
        datasets: [
          {
            label: "Treatments",
            fill: true,
            backgroundColor: "rgba(128,180,45,0.2)",
            strokeColor: "rgba(124,174,43,1)",
            pointColor: "rgba(124,174,43,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(124,174,43,1)",
            data: []
          }
        ]
      },
      options: {
        tooltips: {
          callbacks: {
            title: tooltips => {
              return tooltips[0].yLabel;
            },
            label: tooltip => {
              return moment(tooltip.xLabel).format("MMM D, YYYY");
            }
          }
        },
        legend: {
          display: false
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              ticks: {
                callback(label, index, labelsArray) {
                  if (index === 0 || index === labelsArray.length - 1) {
                    return moment(label).format("MM/DD/YYYY");
                  }
                }
              },
              time: {
                displayFormats: "MM/DD/YYYY"
              },
              type: "time",
              display: true,
              scaleLabel: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                callback(label) {
                  if (noDecimalYAxis) {
                    if (label.toFixed(0) == label) {
                      return label;
                    }
                  } else {
                    return label;
                  }
                },
                beginAtZero: !beginAnywhere,
                max: noMax ? undefined : max,
                min,
                userCallback,
                fixedStepSize
              },
              display: true,
              scaleLabel: {
                display: false
              }
            }
          ]
        }
      }
    });

    this.setState({
      chart
    });
  };

  render() {
    return <canvas ref={this.setCanvas} width="300" height="150" />;
  }
  setCanvas = (canvas: any) => {
    this.canvas = canvas;
  };
}
