import get from "lodash.get";

import encounterClinicianNameFormatter from "./encounter_clinician_name_formatter/index";
import encounterDisciplineFormatter from "./encounter_discipline_formatter";
import encounterEndDateFormatter from "./encounter_end_date_formatter/index";
import encounterIDFormatter from "./encounter_id_formatter/index";
import encounterLocationFormatter from "./encounter_location_formatter";
import encounterStartDateFormatter from "./encounter_start_date_formatter/index";

const encounterTextFormatter = (
  encounter,
  encounterNamingConvention,
  encounterFieldsShown
) => {
  const fields = [];

  const encounterStartDate = get(encounter, "attributes.startAt", null);
  const encounterEndDate = get(encounter, "attributes.endAt", null);
  const isFloatingVisit = encounterStartDate === encounterEndDate;

  fields.push(
    encounterClinicianNameFormatter(encounter, encounterFieldsShown),
    encounterDisciplineFormatter(encounter, encounterFieldsShown),
    encounterIDFormatter(
      encounter,
      encounterNamingConvention,
      encounterFieldsShown
    ),
    encounterLocationFormatter(encounter, encounterFieldsShown),
    encounterStartDateFormatter(
      encounter,
      encounterFieldsShown,
      isFloatingVisit
    ),
    encounterEndDateFormatter(encounter, encounterFieldsShown, isFloatingVisit)
  );

  return fields.filter(field => field != null).join(" - ");
};

export default encounterTextFormatter;
