import React from "react";
import PropTypes from "prop-types";

// Containers
import { WoundProgressTallyByLocationTableContainer } from "src/containers/tables";

// Components
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";
import { LoadingComponent } from "src/components/loading_component";

// Styles
import styles from "./style.module.less";

/**
 * Component that renders the Locations tab on the Wounds dashboard
 */
export class WoundsLocationTabComponent extends React.PureComponent {
  static propTypes = {
    selectedDate: PropTypes.any,
    chartLoadState: PropTypes.string.isRequired
  };

  renderTabHeading() {
    const { selectedDate } = this.props;
    return (
      <div className={styles["headline"]}>
        <h3>Wounds - {selectedDate.format("MMMM Do YYYY")}</h3>
      </div>
    );
  }

  render() {
    const { chartLoadState } = this.props;

    // TODO: handle error view
    if (!chartLoadState || chartLoadState == "loading") {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_WOUNDS_LOCATIONS">
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else if (chartLoadState == "error") {
      return (
        <StartRenderTimeTrackComponent
          eventName="RENDER_WOUNDS_LOCATIONS"
          error={true}
        >
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else {
      return (
        <FinishRenderTimeTrackComponent eventName="RENDER_WOUNDS_LOCATIONS">
          <div className={styles["wounds-location-tab-container"]}>
            {this.renderTabHeading()}
            <WoundProgressTallyByLocationTableContainer />
          </div>
        </FinishRenderTimeTrackComponent>
      );
    }
  }
}
