import PropTypes from "prop-types";
import React from "react";
import { track } from "src/analytics";
// Css
import styles from "./style.module.less";
import { Glyphicon } from "src/components/ui_kit";

/**
 * React Component that will surround its children with a collapsible container
 */
export class PanelComponent extends React.Component {
  static propTypes = {
    labelHide: PropTypes.string,
    labelShow: PropTypes.string.isRequired,
    enableCollapsible: PropTypes.bool,
    collapsedByDefault: PropTypes.bool
  };
  static defaultProps = {
    enableCollapsible: false
  };
  state = {
    expanded: true
  };

  constructor(props) {
    super(props);
    if (props.collapsedByDefault) {
      this.state.expanded = false;
    }
  }

  /**
   * Responsible for changing the expansion state of the container
   */
  handleExpand = () => {
    if (!this.props.enableCollapsible) return;
    if (this.props.analytics) {
      const actionType = this.state.expanded ? "COLLAPSE" : "EXPAND";
      track(`${actionType}_${this.props.analytics.type}`);
    }
    this.setState({
      expanded: !this.state.expanded
    });
  };

  render() {
    let PanelHeaderRightComponentChild, PanelBodyComponentChild;
    React.Children.forEach(this.props.children, childComponent => {
      switch (childComponent.type.displayName) {
        case "PanelHeaderRightComponent":
          PanelHeaderRightComponentChild = childComponent;
          break;
        case "PanelBodyComponent":
          PanelBodyComponentChild = childComponent;
          break;
        default:
          break;
      }
    });

    return (
      <div className={styles["bootstrap-panel"]}>
        <div className="panel panel-default">
          <div className="panel-heading clearfix">
            <h2 className="panel-title pull-left" onClick={this.handleExpand}>
              {this.props.enableCollapsible ? (
                <a
                  data-toggle="collapse"
                  className={`${styles["display-flex"]} ${
                    this.state.expanded ? "" : "collapsed"
                  }`}
                >
                  <Glyphicon
                    glyph={this.state.expanded ? "menu-down" : "menu-right"}
                    className="panel-arrow"
                  />
                  <div className={`${styles["link-text"]}`}>
                    {this.state.expanded
                      ? this.props.labelShow
                      : this.props.labelHide || this.props.labelShow}
                  </div>
                </a>
              ) : (
                this.props.labelShow
              )}
            </h2>
            <div className="pull-right">{PanelHeaderRightComponentChild}</div>
          </div>
          <div
            className={
              this.state.expanded
                ? "panel-collapse collapse in"
                : "panel-collapse collapse"
            }
          >
            <div className="panel-body">{PanelBodyComponentChild}</div>
          </div>
        </div>
      </div>
    );
  }
}
