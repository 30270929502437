import { createSelector } from "reselect";
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";
import { adminRulesSelectedOrganizationIdSelector } from "src/selectors/section_selectors/admin";

export const facilitiesForSelectedOrganizationSelector = createSelector(
  facilitiesResourceSelectors.dataSelector,
  adminRulesSelectedOrganizationIdSelector,
  (facilities, organizationId) => {
    let facilitiesArray = [];
    if (facilities) {
      facilitiesArray = Object.values(facilities);
    }
    const byOrganization = facilitiesArray.filter(facility => {
      return facility.attributes.organizationId === organizationId;
    });

    return byOrganization;
  }
);
