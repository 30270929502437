import { connect } from "react-redux";

//Constants
import { sections } from "src/constants";

//Component
import { SearchBarComponent } from "src/components/search_bar_component";

//UI Processors
import { createSectionActions } from "src/ui_processors";

//Actions
import { selectPatientTablePageNumberAction } from "src/actions/section_actions/admin";

import { patientNameOrMRNOrCodeSearchStringSelector } from "src/selectors/section_selectors/admin";

function mapStateToProps(state) {
  return {
    searchKey: "patient_name_or_mrn_or_code",
    searchString: patientNameOrMRNOrCodeSearchStringSelector(state),
    placeHolder: "Search by Patient Name or MRN"
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSearchString: searchFilterObject => {
      dispatch(
        createSectionActions(sections.admin).setSearchString(searchFilterObject)
      );
    },
    onInputChange: () => {
      dispatch(selectPatientTablePageNumberAction(0));
    }
  };
}

export const AdminPatientSearchBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBarComponent);
