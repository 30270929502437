import React from "react";
import PropTypes from "prop-types";
import i18n from "src/translations";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import { signOut } from "src/actions/session_actions";

import SwiftLogoComponent from "src/components/logo_component";
import { GridRow, GridColumn } from "src/components/grid";
import { TextLinkComponent } from "src/components/ui_kit";
import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormComponent } from "src/components/forms/form_component_legacy";

import styles from "./style.module.less";

export class OtpVerificationComponent extends React.Component {
  static propTypes = {
    userEmail: PropTypes.string.isRequired,
    sendRequestToValidateOTPCode: PropTypes.func.isRequired,
    sendRequestForNewOtpCode: PropTypes.func.isRequired,
    verifyOtpCodeStatus: PropTypes.string.isRequired,
    defaultNavigationPage: PropTypes.string.isRequired
  };

  onSubmit = values => {
    const payload = { pin: values.otpCode };
    this.props.sendRequestToValidateOTPCode({ payload });
  };

  handleSignOut = event => {
    event.preventDefault();
    this.props.dispatch(signOut());
  };

  componentDidUpdate = prevProps => {
    const { verifyOtpCodeStatus, dispatch, defaultNavigationPage } = this.props;

    if (
      prevProps.verifyOtpCodeStatus === "loading" &&
      verifyOtpCodeStatus === "ready"
    ) {
      dispatch(push(defaultNavigationPage));
    }
  };
  render() {
    const { userEmail } = this.props;
    const emailText = userEmail || "your email";
    const text = `A 6-digit verification code has been sent to ${emailText}. Please enter the code to continue.`;
    return (
      <div className={styles["min-height"]}>
        <div className={`text-center ${styles["margin-bottom10px"]}`}>
          <div className={styles["logo"]}>
            <SwiftLogoComponent />
          </div>
          <div className={styles["instructions"]}>
            <p>{text}</p>
          </div>
        </div>
        <GridRow className={styles["margin-bottom10px"]}>
          <GridColumn sm="4" className="col-sm-offset-4">
            <FormComponent form="verifyOtpForm" onSubmit={this.onSubmit}>
              <FormInputComponent
                name="otpCode"
                component="input"
                placeholder="Enter 6 digit code here"
              />
              <div className="text-center margin-top-10px">
                <button className="btn btn-default" type="submit">
                  {i18n.t("actions.signIn")}
                </button>
              </div>
            </FormComponent>
          </GridColumn>
        </GridRow>
        <GridRow className="text-center">
          <TextLinkComponent
            label="Resend Code to email"
            onClick={this.props.sendRequestForNewOtpCode}
            theme="light"
          />
        </GridRow>
        <br />
        <GridRow className="text-center">
          <Link
            to={`sign_in`}
            className={styles["login-link-style"]}
            onClick={this.handleSignOut}
          >
            Back to Login
          </Link>
        </GridRow>
      </div>
    );
  }
}
