import React from "react";
import { Link } from "react-router-dom";
import get from "lodash.get";

export function patientFirstNameLastNameFormatter(
  cell,
  row,
  rowIndex,
  formatExtraData
) {
  const { patientLinksEnabled } = formatExtraData;
  let firstName, lastName, patientId;
  if (row.type === "patients") {
    firstName = get(row, "attributes.firstName");
    lastName = get(row, "attributes.lastName");
    patientId = row.id;
  } else {
    firstName = get(row, "attributes.patientFirstName", "");
    lastName = get(row, "attributes.patientLastName", "");
    patientId = get(row, "attributes.patientId");
  }

  let patientName = `${firstName} ${lastName}`;
  patientName = patientName == " " ? "Anonymous" : patientName;

  if (patientId && patientLinksEnabled) {
    return <Link to={`/patients/${patientId}`}>{patientName}</Link>;
  } else {
    return patientName;
  }
}
