import PropTypes from "prop-types";
import React from "react";
import styles from "./style.module.less";

export class CheckboxList extends React.PureComponent {
  static propTypes = {
    checkAllEnabled: PropTypes.bool,
    allChecked: PropTypes.bool,
    checkAllLabel: PropTypes.string,
    disabled: PropTypes.bool,
    onCheckAllChange: PropTypes.func
  };

  static defaultProps = {
    checkAllEnabled: false,
    checkAllLabel: "Select All",
    disabled: false
  };

  renderSelectAllCheckbox() {
    if (this.props.checkAllEnabled) {
      return (
        <li>
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                value={"all"}
                checked={this.props.allChecked}
                onChange={this.props.onCheckAllChange}
                disabled={this.props.disabled}
              />
              {this.props.checkAllLabel}
            </label>
          </div>
        </li>
      );
    }
  }

  render() {
    // cascade disabled state
    let childrenWithProps = this.props.children
      ? React.Children.map(this.props.children, child => {
          if (child)
            return React.cloneElement(child, {
              disabled: this.props.disabled
            });
        })
      : [];

    return (
      <div className={styles["checkbox-list-container"]}>
        <ul>
          {this.renderSelectAllCheckbox()}
          {childrenWithProps}
        </ul>
      </div>
    );
  }
}
