import { connect } from "react-redux";

// Component
import { RevisionsComparisonItemComponent } from "src/components/revisions_comparison_item_component";

// Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { createAllPageRevisionComparisonContextSelector } from "src/selectors/api_selectors/patients/revision_comparison";

// Actions
import {
  selectRevisionAction,
  removeRevisionItemAction
} from "src/ui_processors/patients/actions";

function mapStateToProps(state, ownProps) {
  return {
    revisions: revisionsResourceSelectors.createDataForContextSelector(
      createAllPageRevisionComparisonContextSelector(ownProps)
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSelectRevisionAction: revisionObject => {
      dispatch(selectRevisionAction(revisionObject));
    },
    sendRemoveRevisionItemAction: revisionItemSeriesIdAndIndex => {
      dispatch(removeRevisionItemAction(revisionItemSeriesIdAndIndex));
    }
  };
}

export const RevisionsComparisonItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RevisionsComparisonItemComponent);
