import { createSelector } from "reselect";

// Payload generator
import { createEventsGetPayload } from "src/api/events/payload_generators";

// Constants
import { eventTypeFilters } from "src/constants";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api query for the first page - loading events with a revisionId
 * @param {Object} props
 * @param {string} props.revisionId
 * @returns {function} selector
 */
export function createFirstPageEventsForRevisionIdQuerySelector({
  revisionId
}) {
  return createSelector(() => {
    return buildQueryString(
      createEventsGetPayload({
        revisionId,
        pageNumber: 0,
        eventType: eventTypeFilters.revision
      })
    );
  });
}
