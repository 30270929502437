// @flow
import React, { PureComponent } from "react";
import Column from "../Grid/Column";

export default class Sidebar extends PureComponent {
  render() {
    return (
      <Column>
        <h3>{this.props.title}</h3>
        {this.props.children}
      </Column>
    );
  }
}
