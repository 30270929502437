import { getLocationTable } from "../get_location_table";

/**
 * Get the content (body) portion of the Assessment PDF, Formatted for pdfMake
 * @param {Object} data
 * @param {Object} data.talliesData - the talliesData
 * @param {boolean} data.excludeNewHealed - the excludeNewHealed
 
 * @return {Object} JSON representation of the pdf content
 */

export function getBody(data) {
  const { talliesData, excludeNewHealed } = data;

  // construct pdf body
  let body = [];
  body.push(getLocationTable(talliesData, excludeNewHealed));

  return [...body];
}
