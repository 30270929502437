export const patientEvaluationsConfiguration = {
  filterName: "patientEvaluations",
  filterValues: [
    {
      key: "with_patient_users",
      value: "true",
      name: "Only show patient evaluations",
      filterName: "patientEvaluations"
    },
    {
      key: "hide_patient_users",
      value: "false",
      name: "Hide all patient evaluations",
      filterName: "patientEvaluations"
    }
  ]
};
