// @flow
import { Record } from "immutable";

export const recordInput = {
  id: "",
  userId: 0,
  patientId: "",
  name: "",
  createdAt: "",
  updatedAt: ""
};

export default class StudyModel extends Record(recordInput) {}
