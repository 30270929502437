import PropTypes from "prop-types";
import React from "react";
import Chart from "chart.js";

Chart.defaults.global.defaultFontFamily = "Avenir";

export default class ChartComponent extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    data: PropTypes.object,
    options: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number
  };

  state = {
    chart: null
  };

  componentDidMount = () => {
    if (this._chart) {
      let { type, data, options } = this.props;

      this.setState({
        chart: new Chart(this._chart, {
          type,
          data,
          options
        })
      });
    }
  };

  componentWillReceiveProps = nextProps => {
    let { chart } = this.state;

    if (chart && nextProps.data && this.props.data !== nextProps.data) {
      for (let key in nextProps.data) {
        let value = nextProps.data[key];

        chart.data[key] = value;
      }

      chart.update();
    }
  };

  assignChartRef = chart => {
    this._chart = chart;
  };

  render = () => {
    return (
      <canvas
        width={this.props.width}
        height={this.props.height}
        ref={this.assignChartRef}
      />
    );
  };
}
