import { createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";
import { organizationsForSelectedPartnerIdSelector } from "src/selectors/section_selectors/admin";
import { sortByName } from "src/utils/sort_by_name";

export function createRoleFormFacilityOptionsSelector(formName) {
  return createSelector(
    getFormValues(formName),
    facilitiesResourceSelectors.createDataForContextSelectorWithString(
      "adminFacilities"
    ),
    organizationsForSelectedPartnerIdSelector,
    (form, facilities, orgs) => {
      let filterFacilities;

      if (form && form["organization"]) {
        filterFacilities = Object.values(facilities).filter(
          o => o.attributes.organizationId == form["organization"]
        );
      } else {
        // this case will selects the facilities for first organization
        orgs
          ? (filterFacilities = Object.values(facilities).filter(
              o => o.attributes.organizationId == Object.values(orgs)[0].id
            ))
          : [];
      }

      return filterFacilities ? sortByName(filterFacilities) : [];
    }
  );
}
