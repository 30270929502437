import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Selectors
import {
  seriesResourceSelectors,
  createStudyForPatientIdSelector,
  createStudyIdForPatientIdSelector
} from "src/selectors/data_selectors";
import { visibleEvaluationStatesPermissionSelector } from "src/selectors/config_selectors/visible_evaluation_states_permission";

// Actions
import { loadSeriesForStudyAction } from "src/actions/data_actions";

// Pusher
import Subscriptions from "src/subscriptions";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

/**
 * HOC that is responsible for loading a list of series for a study
 * @param {Class} WrappedComponent the component dependent on this data
 * @return {Class} Component wrapped by the loader component
 */
export function withSeriesForStudyLoader(WrappedComponent) {
  class SeriesLoader extends React.Component {
    static propTypes = {
      seriesLoadState: PropTypes.string,
      study: PropTypes.object.isRequired,
      patientId: PropTypes.string.isRequired,
      series: PropTypes.array,
      sendLoadSeriesForStudyAction: PropTypes.func.isRequired,
      sendLoadMoreSeriesAction: PropTypes.func.isRequired,
      refreshSeries: PropTypes.func.isRequired,
      assessmentStateFilters: PropTypes.object
    };

    componentWillMount() {
      this.loadSeries(this.props);
      this.subscribeToPatientChanges();
    }

    componentWillReceiveProps(nextProps) {
      if (propsAreDifferent(this.props, nextProps, "series")) {
        this.subscribeToPatientChanges();
      }

      this.loadSeries(nextProps);
    }

    componentWillUnmount() {
      this.unsubscribeFromPatientChanges(this.props.series);
    }

    /**
     * Subscribe to changes for each series
     * @param {array} series
     */
    subscribeToPatientChanges() {
      Subscriptions.subscribeToResourceChannelEvent({
        channelName: Subscriptions.resourceIdChannelNames.patient,
        resourceId: this.props.patientId,
        eventName: Subscriptions.channelEvents.change,
        callback: this.onSeriesChange
      });
    }

    /**
     * Unsubscribe from changes to individual series
     * @param {array} series
     */
    unsubscribeFromPatientChanges() {
      Subscriptions.unsubscribeFromResourceChannelEvent({
        channelName: Subscriptions.resourceIdChannelNames.patient,
        resourceId: this.props.seriesId,
        eventName: Subscriptions.channelEvents.change,
        callback: this.onSeriesChange
      });
    }

    /**
     * Callback for a series subscription
     */
    onSeriesChange = () => {
      this.props.refreshSeries(
        this.props.study.id,
        this.props.assessmentStateFilters.lockState
      );
    };

    loadSeries(props) {
      const {
        seriesLoadState,
        study,
        series,
        sendLoadSeriesForStudyAction
      } = props;

      // If series has not loaded, is not loading, and we have the studyId then
      // load the series.
      if (!seriesLoadState && study && !series.length) {
        sendLoadSeriesForStudyAction(
          study.id,
          this.props.assessmentStateFilters.lockState
        );
      }
    }

    loadMoreSeries = () => {
      this.props.sendLoadMoreSeriesAction(
        this.props.study.id,
        this.props.assessmentStateFilters.lockState
      );
    };

    render() {
      return (
        <WrappedComponent
          loadMoreSeries={this.loadMoreSeries}
          {...this.props}
        />
      );
    }
  }

  /**
   * Map the props needed for loading data
   */
  function mapStateToProps(state, props) {
    const patientId = props.match.params.patientId;
    const studyIdSelector = createStudyIdForPatientIdSelector(patientId);

    return {
      series: seriesResourceSelectors.createDataForContextSelectorWithSelector(
        studyIdSelector
      )(state),
      seriesLoadState: seriesResourceSelectors.createContextLoadStateSelectorWithContextStringSelector(
        studyIdSelector
      )(state),
      study: createStudyForPatientIdSelector(patientId)(state),
      patientId,
      assessmentStateFilters: visibleEvaluationStatesPermissionSelector(state)
    };
  }

  /**
   * Map dispatch to a props for loading data
   */
  function mapDispatchToProps(dispatch) {
    return {
      sendLoadSeriesForStudyAction(studyId, lockState) {
        dispatch(loadSeriesForStudyAction(studyId, lockState));
      },
      sendLoadMoreSeriesAction(studyId, lockState) {
        dispatch(
          loadSeriesForStudyAction(studyId, lockState, { loadMore: true })
        );
      },
      refreshSeries(studyId, lockState) {
        dispatch(
          loadSeriesForStudyAction(studyId, lockState, { refresh: true })
        );
      }
    };
  }

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(SeriesLoader));
}
