import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import { Field } from "redux-form";
import DatePicker from "react-datepicker";

//components
import { GridRow, GridColumn } from "src/components/grid";

//css
import styles from "./style.module.less";

export class FormDatePickerComponent extends React.Component {
  static propTypes = {
    component: PropTypes.string.isRequired,
    componentType: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    disabled: PropTypes.bool,
    validate: PropTypes.array,
    maxDate: PropTypes.object,
    required: PropTypes.bool
  };
  static defaultProps = {
    disabled: false
  };

  renderDateTimePicker = ({
    input: { onChange, value },
    meta: { touched, error, warning }
  }) => {
    const { placeholder, maxDate } = this.props;
    const onDatePickerChange = val => {
      if (val) onChange(val.format("YYYY-MM-DD"));
      else onChange(val);
    };

    const onDatePickerChangeRaw = val => {
      const date = moment(val.target.value);
      if (date.isValid()) {
        onChange(date.format("YYYY-MM-DD"));
      } else {
        onChange(null);
      }
    };

    return (
      <div>
        <DatePicker
          onChangeRaw={onDatePickerChangeRaw}
          onChange={onDatePickerChange}
          className="form-control"
          dateFormat="YYYY-MM-DD"
          maxDate={maxDate ? moment(maxDate) : null}
          placeholderText={placeholder}
          selected={value ? moment(value, "YYYY-MM-DD") : null}
        />
        {touched &&
          ((error && <span className={styles["error"]}>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    );
  };

  handleBlur = event => {
    const date = moment(event.target.value, "YYYY-MM-DD");
    if (date.isValid()) this.setState({ selectedDate: moment(date) });
    else this.setState({ selectedDate: null });
  };

  render() {
    return (
      <GridRow className={styles["margin-bottom5px"]}>
        <GridColumn sm="2">
          <label className={styles["label-text"]}>
            {this.props.labelText}{" "}
            {this.props.required && (
              <span className={styles["required-field"]}>*</span>
            )}
          </label>
        </GridColumn>
        <GridColumn sm="10">
          <Field
            className="form-control"
            name={this.props.name}
            component={this.renderDateTimePicker}
            disabled={this.props.disabled}
            validate={this.props.validate}
          />
        </GridColumn>
      </GridRow>
    );
  }
}
