import * as Sentry from "@sentry/browser";

export function trackError(error, params) {
  try {
    Sentry.withScope(scope => {
      if (params) {
        Object.keys(params).forEach(key => {
          scope.setExtra(key, params[key]);
        });
      }
      Sentry.captureException(error);
    });
  } catch (err) {}
}
