import { createSelector } from "reselect";
import get from "lodash.get";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

//Constants
import { policyInternalNames } from "src/constants";

/**
 * Memoized selector to determine if encounter selection is mandatory for dashboard
 */

export const encounterMandatorySelectionSelector = createSelector(
  createPolicyForInternalNameSelector(
    policyInternalNames.encounterSelectionMandatory
  ),
  policy => {
    return get(policy, "attributes.policyValue", false);
  }
);
