import { connect } from "react-redux";
import { PopulationEtiologyComponent } from "src/components/population_tallies_by_etiology_component";
import flowRight from "lodash.flowright";
import {
  withPopulationChartDataLoader,
  withPopulationTalliesByEtiologyDataLoader
} from "src/hoc";
// Selectors
import {
  selectedWoundTypesSelector,
  dateSelectionSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector
} from "src/selectors/section_selectors/population";
import {
  populationTalliesLoadStateSelector,
  populationTallyExportLoadStateSelector,
  populationIncidencePrevalenceChartDataLoadStateSelector
} from "src/selectors/data_selectors";

// Actions
import { createAndDownloadPopulationTallyCsv } from "src/actions/data_actions/export";

function mapStateToProps(state) {
  return {
    selectedLocationIds: selectedLocationIdsSelector(state),
    selectedWoundTypes: selectedWoundTypesSelector(state),
    dateSelection: dateSelectionSelector(state),
    acquiredFilter: acquiredFilterSelector(state),
    populationTalliesLoadState: populationTalliesLoadStateSelector(state),
    populationTallyExportLoadState: populationTallyExportLoadStateSelector(
      state
    ),
    populationIncidencePrevalenceChartLoadState: populationIncidencePrevalenceChartDataLoadStateSelector(
      state
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendCreateAndDownloadCsvAction(payload) {
      dispatch(createAndDownloadPopulationTallyCsv(payload));
    }
  };
}
export const PopulationEtiologyContainer = flowRight(
  withPopulationChartDataLoader,
  withPopulationTalliesByEtiologyDataLoader,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PopulationEtiologyComponent);
