// Constants
import { resourceNames } from "src/constants";

// Api
import { ruleTemplatesApi } from "src/api/rules";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

export const loadRuleTemplatesAction = createLoadDataAction({
  resourceName: resourceNames.ruleTemplates,
  api: ruleTemplatesApi
});

export const ruleTemplatesResourceActions = createResourceActions(
  resourceNames.ruleTemplates,
  ruleTemplatesApi
);
