import { createSelector } from "reselect";

//Selectors
import { createLatestSignedEventForAssessmentGroupIdQuerySelector } from "src/selectors/query_selectors/events_for_assessment_answer_group_id";

/**
 * create a selector to get the context for the latest signed event with a assessmentAnswerGroupId
 * @param {Object} props
 * @param {string} props.assessmentAnswerGroupId
 * @returns {function} selector
 */

export function createLatestSignedEventForAssessmentAnswerGroupIdContextSelector({
  assessmentAnswerGroupId
}) {
  return createSelector(
    createLatestSignedEventForAssessmentGroupIdQuerySelector({
      assessmentAnswerGroupId
    }),
    query => {
      return query;
    }
  );
}
