import React from "react";

//Components
import { LoadingComponent } from "src/components/loading_component";

// Translations
import i18n from "src/translations";

// Styles
import styles from "./style.module.less";

export class MyDashboardSectionComponent extends React.PureComponent {
  state = {
    clickCounter: 0,
    showLoadingScreen: true
  };

  componentDidMount() {
    //Show a loading screen overlay for 3 seconds, to hide design platform loading screen
    setTimeout(() => this.setState({ showLoadingScreen: false }), 3000);
  }

  onIframeClick = () => {
    const { clickCounter } = this.state;
    this.setState({
      clickCounter: clickCounter + 1
    });
  };

  renderControlNavbar() {
    return (
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">{i18n.t(`headers.myDashboard`)}</h1>
        </div>
      </nav>
    );
  }

  renderLoadingOverlay() {
    return (
      this.state.showLoadingScreen && (
        <div className={styles["loading-screen-overlay"]}>
          <LoadingComponent />
        </div>
      )
    );
  }

  renderIFrame() {
    const { iframeUrls } = this.props;
    const { clickCounter } = this.state;
    return (
      <iframe
        name="myDashboardIFrame"
        src={iframeUrls[clickCounter % 2]}
        className={styles["my-dashboard-iframe"]}
      ></iframe>
    );
  }

  render() {
    const { iframeUrls } = this.props;

    return (
      <div>
        {this.renderControlNavbar()}
        {this.renderLoadingOverlay()}
        {iframeUrls.length > 1 && (
          <div
            className={styles["iframe-clicker-overlay"]}
            onClick={this.onIframeClick}
          />
        )}
        {this.renderIFrame()}
      </div>
    );
  }
}
