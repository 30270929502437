import { adminActionTypes } from "src/action_types/section_action_types";

/**
 * Action to select facilities given array of org ids
 * @param {Array} facilityIds array of faclity ids
 * @return {Object} Action
 */
export function selectFacilitiesAction(facilityIds) {
  return {
    type: adminActionTypes.selectFacilities,
    payload: facilityIds
  };
}

/**
 * Action to deselect facilities given array of org ids
 * @param {Array} facilityIds array of faclity ids
 * @return {Object} Action
 */
export function deselectFacilitiesAction(facilityIds) {
  return {
    type: adminActionTypes.deselectFacilities,
    payload: facilityIds
  };
}
