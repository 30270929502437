import { createSelector } from "reselect";

import {
  assessmentSchemasResourceSelectors,
  createLatestAssessmentForRevisionSelector
} from "src/selectors/data_selectors";

/**
 * Create a selector that gets the assessment schema for the latest assessment answer for a revision
 * @param {Object} revision
 * @returns {Function} selector
 */
export function createLatestAssessmentSchemaForRevisionSelector(revision) {
  return createSelector(
    assessmentSchemasResourceSelectors.dataSelector,
    createLatestAssessmentForRevisionSelector(revision),
    (assessmentSchemas, assessment) => {
      if (assessment) {
        return Object.values(assessmentSchemas).find(schema => {
          return schema.attributes.assessmentId == assessment.id;
        });
      }

      return null;
    }
  );
}
