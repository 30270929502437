import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { analyticsEventNames } from "src/constants";

// Components
import { RevisionAssessmentAnswersComponent } from "src/components/revision_assessment_answers_component";

// Selectors
import {
  createProgressTabSchemasForAssessmentAnswerSelector,
  createTreatmentTabSchemasForAssessmentAnswerSelector,
  createAttributesTabSchemasForAssessmentAnswerSelector,
  assessmentsResourceSelectors,
  assessmentAnswersResourceSelectors,
  createLatestAssessmentAnswerForRevisionSelector,
  isLatestRegionForRevisionAutodepth,
  createDescriptionAssessmentSchemaForAssessmentAnswerSelector
} from "src/selectors/data_selectors";
import { createRevisionIsFirstInSeriesSelector } from "src/selectors/data_selectors/revisions/create_revision_is_first_in_series_selector";

import { createLatestAssessmentAnswerForRevisionQuerySelector } from "src/selectors/api_selectors/global/assessment_answers/create_latest_assessment_answer_for_revision_query_selector";
import { createAllAssessmentsWithSchemasForRevisionQuerySelector } from "src/selectors/api_selectors/global/assessments";

import { patientsSectionSelector } from "src/selectors/section_selectors/patients";
import { hprScoreEnabledSelector } from "src/selectors/ui_config_selectors/global";
import { ray1EnabledPolicySelector } from "src/selectors/config_selectors";
import SingleValueReduxUtil from "src/ui_processors/single_value";

// Actions
import {
  loadAssessmentAnswersAction,
  loadAssessmentsAction
} from "src/actions/data_actions";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc";

const revisionComparisonTabSelectedSelectors = SingleValueReduxUtil.createSelectors(
  "revisionComparisonTabSelected",
  patientsSectionSelector
);
const bulkSelectedTabActions = SingleValueReduxUtil.createActionCreators(
  "bulkSelectedTab"
);
const bulkSelectedTabSelectors = SingleValueReduxUtil.createSelectors(
  "bulkSelectedTab",
  patientsSectionSelector
);

function mapStateToProps(state, ownProps) {
  const assessmentAnswer = createLatestAssessmentAnswerForRevisionSelector(
    ownProps.revision
  )(state);

  return {
    assessmentAnswer,
    progressSchemas: createProgressTabSchemasForAssessmentAnswerSelector(
      assessmentAnswer
    )(state),
    treatmentSchemas: createTreatmentTabSchemasForAssessmentAnswerSelector(
      assessmentAnswer
    )(state),
    attributesSchemas: createAttributesTabSchemasForAssessmentAnswerSelector(
      assessmentAnswer
    )(state),
    describeSchemas: createDescriptionAssessmentSchemaForAssessmentAnswerSelector(
      assessmentAnswer
    )(state),
    revisionIsFirstInSeries: createRevisionIsFirstInSeriesSelector(
      ownProps.revision
    )(state),
    isAutodepth: isLatestRegionForRevisionAutodepth(ownProps.revision)(state),
    hprScoreEnabled: hprScoreEnabledSelector(state),
    revisionComparisonTabSelected: revisionComparisonTabSelectedSelectors.fieldSelector(
      state
    ),
    bulkSelectedTab: bulkSelectedTabSelectors.fieldSelector(state),
    ray1Enabled: ray1EnabledPolicySelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSelectBulkTabAction: value => {
      dispatch(bulkSelectedTabActions.setValue(value));
    }
  };
}

export const RevisionAssessmentAnswersContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentAnswersAction,
    resourceSelectors: assessmentAnswersResourceSelectors,
    createQuerySelector: createLatestAssessmentAnswerForRevisionQuerySelector,
    createContextSelector: createLatestAssessmentAnswerForRevisionQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentsAction,
    resourceSelectors: assessmentsResourceSelectors,
    createQuerySelector: createAllAssessmentsWithSchemasForRevisionQuerySelector,
    createContextSelector: createAllAssessmentsWithSchemasForRevisionQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName:
      analyticsEventNames.renderRevisionAssessmentAnswersComponent
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(RevisionAssessmentAnswersComponent);
