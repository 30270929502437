import { createSelector } from "reselect";

//Constants
import { apiPageLimits } from "src/constants";

//Selectors
import { createFirstPageAlertsForPatientIdQuerySelector } from "../create_first_page_alerts_for_patient_id_query_selector";
import { userAlertsResourceSelectors } from "src/selectors/data_selectors";

//Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * Create a selector to get the api query for the current page - loading user alerts with a patientId
 * @param {Object} props
 * @param {string} props.patientId
 * @returns {function} selector
 */
export function createAllPagesAlertsForPatientIdQuerySelector({ patientId }) {
  return createSelector(
    userAlertsResourceSelectors.createPageCountSelector(
      createFirstPageAlertsForPatientIdQuerySelector({
        patientId
      }),
      apiPageLimits.patientAlerts
    ),
    pageCount => {
      let queries = [];
      if (pageCount) {
        for (let i = 0; i < pageCount; i++) {
          queries.push(
            buildQueryString({
              filter: {
                patient_id: patientId,
                non_resolved_only: "true"
              },
              include: "patient,tripped_by_user",
              page: {
                offset: i * apiPageLimits.patientAlerts,
                limit: apiPageLimits.patientAlerts
              },
              sort: "-created_at"
            })
          );
        }
      }

      return queries;
    }
  );
}
