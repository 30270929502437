import { createSelector } from "reselect";
import get from "lodash.get";
import { isPccSelector } from "src/selectors/config_selectors";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";

export function createSignButtonVisibleSelector({ assessmentAnswer }) {
  return createSelector(
    isPccSelector,
    createPolicyForInternalNameSelector(policyInternalNames.canSignAssessments),
    (isPcc, canSignAssessmentsPolicy) => {
      const lockState = get(assessmentAnswer, "attributes.lockState", null);
      const signState = get(assessmentAnswer, "attributes.signState", null);
      return (
        signState === "unsigned" &&
        lockState === "unlocked" &&
        (!isPcc &&
          !!(
            canSignAssessmentsPolicy &&
            canSignAssessmentsPolicy.attributes.policyValue
          ))
      );
    }
  );
}
