import PropTypes from "prop-types";
import React from "react";

// Translations
import i18n from "src/translations";

// Constants
import { acquiredValues } from "src/constants";

// Legacy Chart Component
import { BarChart } from "src/ui-kit";

// Styles
import styles from "./style.module.less";
import { medlineColors, defaultColors } from "src/constants/branding_colors";

export class SurveyEtiologyChartComponent extends React.Component {
  static propTypes = {
    totalTally: PropTypes.object.isRequired,
    branding: PropTypes.string
  };

  getChartData() {
    const { totalTally, branding } = this.props;

    const xLabels = acquiredValues.map(acq =>
      i18n.t(`data.acquired.default.${acq}`)
    );
    const newData = [
      totalTally.attributes.newPoaCount,
      totalTally.attributes.newIhaCount,
      totalTally.attributes.newIhawCount,
      totalTally.attributes.newPihawCount
    ];
    const existingData = [
      totalTally.attributes.existingPoaCount,
      totalTally.attributes.existingIhaCount,
      totalTally.attributes.existingIhawCount,
      totalTally.attributes.existingPihawCount
    ];

    const brandingColors =
      branding === "medline"
        ? medlineColors.surveyChart
        : defaultColors.surveyChart;

    return {
      labels: xLabels,
      datasets: [
        {
          label: "New",
          data: newData,
          backgroundColor: brandingColors.new
        },
        {
          label: "Existing",
          data: existingData,
          backgroundColor: brandingColors.existing
        }
      ]
    };
  }

  getChartOptions() {
    const yAxisTickCallback = value => {
      if (Math.floor(value) === value) {
        return value;
      }
    };

    return {
      legend: {
        position: "left"
      },

      tooltips: {
        callbacks: {
          title: tooltipItem => {
            return i18n.t(
              `text.surveyChartTooltips.${acquiredValues[tooltipItem[0].index]}`
            );
          }
        }
      },

      scales: {
        xAxes: [
          {
            stacked: true
          }
        ],

        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: yAxisTickCallback
            }
          }
        ]
      }
    };
  }

  render() {
    return (
      <BarChart
        className={styles["survey-etiology-chart"]}
        data={this.getChartData()}
        options={this.getChartOptions()}
      />
    );
  }
}
