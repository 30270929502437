export const exportSurveyActions = {
  // Survey tally export
  tallyExportLoading: "DATA_TALLY_EXPORT_LOADING",
  tallyExportLoadReady: "DATA_TALLY_EXPORT_LOAD_READY",

  // Survey wound details export
  surveyWoundDetailsExportLoading: "DATA_SURVEY_WOUND_DETAILS_EXPORT_LOADING",
  surveyWoundDetailsExportLoadReady:
    "DATA_SURVEY_WOUND_DETAILS_EXPORT_LOAD_READY"
};
