// @flow
import { Record, Map, fromJS, List } from "immutable";
import { convertInput } from "src/utils/modelUtils";

const properties = {
  aggregates: new Map(),
  chart: new List()
};

export default class GenericRiskModel extends Record(properties) {
  constructor(opts?: Object = {}) {
    super(
      convertInput(
        {
          aggregates: fromJS,
          chart: fromJS
        },
        opts
      )
    );
  }
}
