import { connect } from "react-redux";

// Components
import { ButtonComponent } from "src/components/button_component";

// Selectors
import { createLockButtonVisibleSelector } from "src/selectors/ui_config_selectors/patients/create_lock_button_visible_selector";
import { createLockIsDisabledSelector } from "src/selectors/ui_config_selectors/patients/create_lock_is_disabled_selector";
import { createLockTooltipSelector } from "src/selectors/ui_config_selectors/patients/create_lock_tooltip_selector";

import { lockAssessmentAnswerAction } from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  return {
    isVisible: createLockButtonVisibleSelector(ownProps)(state),
    disabled: createLockIsDisabledSelector(ownProps)(state),
    tooltip: createLockTooltipSelector()(state)
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const payload = {
    id: ownProps.assessmentAnswer.id,
    data: {
      id: ownProps.assessmentAnswer.id
    }
  };
  return {
    onClick: () => {
      return dispatch(lockAssessmentAnswerAction({ payload }));
    }
  };
};

export const LockButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonComponent);
