import { isArray } from "lodash";

export const formatAsObjectWithBooleanValues = value => {
  if (isArray(value)) {
    let newObject = {};
    value.forEach(val => (newObject[val] = true));
    value = newObject;
  }
  return value;
};
