import { woundTypesDefault } from "./wound_types_default";
import { woundTypesML1 } from "./wound_types_ML1";
import { woundTypesV3 } from "./wound_types_v3";
import { woundTypesV4 } from "./wound_types_v4";
import { woundTypesV4Revera } from "./wound_types_v4_revera";
import { woundTypesV5 } from "./wound_types_v5";
import { woundTypesV5Ashn } from "./wound_types_v5_ashn";
import { woundTypesV6 } from "./wound_types_v6";
import { woundTypesV7 } from "./wound_types_v7";
import { woundTypesV8 } from "./wound_types_v8";
import { woundTypesV6LifeCare } from "./wound_types_v6_lifecare";
import { woundTypesV6MayoClinic } from "./wound_types_v6_mayoclinic";
import { woundTypesV5LHC } from "./wound_types_v5_lhc";

export const woundTypes = {
  default: woundTypesDefault,
  ML1: woundTypesML1,
  v2: woundTypesDefault,
  v3: woundTypesV3,
  v4: woundTypesV4,
  v4Revera: woundTypesV4Revera,
  v5: woundTypesV5,
  v5Bwat: woundTypesV5,
  v5Ashn: woundTypesV5Ashn,
  v5LHC: woundTypesV5LHC,
  v6: woundTypesV6,
  v7: woundTypesV7,
  v8: woundTypesV8,
  v6LifeCare: woundTypesV6LifeCare,
  v6MayoClinic: woundTypesV6MayoClinic,  		
  notSet: "Not Set"
};