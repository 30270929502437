import { isPlainObject } from "lodash";

// maps keys from context filters to legacy redux filters for backwards compatibility
const valueKeyMapping = {
  locations: "location_id",
  clinicians: "user_id",
  visitFrequencies: "visitFrequency",
  patientEvaluations: "only_patient_users",
  payers: "payer_id"
};

const buildValueFromObject = valueObject => {
  const valueKey = Object.keys(valueObject)[0];

  return {
    type: "Literal",
    key: valueKey,
    value: valueObject[valueKey]
  };
};

const buildValue = (key, value) => {
  const valueKey = valueKeyMapping[key];

  return {
    type: "Literal",
    key: valueKey,
    value: value
  };
};

const buildFilterValue = (key, values) =>
  values.map(v =>
    isPlainObject(v) ? buildValueFromObject(v) : buildValue(key, v)
  );

// converts a context filter to a payload object that can be posted to saved filters endpoint
export const buildFilterPayloadObject = ([key, values]) => {
  return {
    type: "Compound",
    operator: "OR",
    key,
    value: buildFilterValue(key, values)
  };
};
