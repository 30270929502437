import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";

// Components
import { PatientsSectionComponent } from "src/components/patients_section_component";
import { ChangePasswordComponent } from "src/components/change_password_component";

// Containers
import { OutcomesSectionContainer } from "src/containers/outcomes_section_container";
import AccountContainer from "src/containers/account";

import { WoundsSectionContainer } from "src/containers/wounds_section_container";

import { RiskDefaultContainer } from "src/containers/risk_default_container";
import BradenRiskContainer from "src/containers/risk/Braden";
import NortonRiskContainer from "src/containers/risk/NortonPlus";

import { SurveyContainer } from "src/containers/survey_container";
import { SurveyDetailsContainer } from "src/containers/survey_details_container";

import { PopulationSectionContainer } from "src/containers/population_section_container";

import { AdminSectionContainer } from "src/containers/admin_section_container";
//import { ComplianceSectionContainer } from "src/containers/compliance_section_container";
import { MyDashboardSectionContainer } from "src/containers/my_dashboard_section_container";
import { AlertsSectionContainer } from "src/containers/alerts_section_container";
import { ExportsSectionContainer } from "src/containers/exports_section_container";
import { MonitoringSectionContainer } from "src/containers/monitoring_section_container";
import { PatientSearchHomeContainer } from "src/containers/patient_search_home_container";
import { ComplianceSectionComponent } from "src/pages/compliance/components/index.js";
import HomeComponent from "../../../homeComponent";
export class SectionContentComponent extends Component {
  static propTypes = {
    alertsTabEnabled: PropTypes.bool.isRequired,
    evaluationListEnabled: PropTypes.bool.isRequired,
    myDashboardTabEnabled: PropTypes.bool.isRequired,
    exportsTabEnabled: PropTypes.bool.isRequired,
    adminTabEnabled: PropTypes.bool.isRequired,
    outcomesTabEnabled: PropTypes.bool.isRequired,
    monitoringTabEnabled: PropTypes.bool.isRequired,
    janssenDashboardEnabled: PropTypes.bool.isRequired,
    woundNumbersEnabled: PropTypes.bool.isRequired,
    searchPatientDashboardEnabled: PropTypes.bool.isRequired
  };

  searchPatientTabRoutes = () => {
    return (
      <Switch>
        <Route
          path="/patients"
          render={props => (
            <PatientsSectionComponent
              {...props}
              janssenDashboardEnabled={this.props.janssenDashboardEnabled}
            />
          )}
        />
        <Route path="/patient-search" component={PatientSearchHomeContainer} />
        <Route path="/" component={PatientSearchHomeContainer} />
      </Switch>
    );
  };

  reportingDisabledRoutes = () => {
    const {
      exportsTabEnabled,
      adminTabEnabled,
      janssenDashboardEnabled
    } = this.props;
    return (
      <Switch>
        <Route
          path="/patients"
          render={props => (
            <PatientsSectionComponent
              {...props}
              janssenDashboardEnabled={janssenDashboardEnabled}
            />
          )}
        />
        <Route exact path="/" component={HomeComponent} />
        {exportsTabEnabled && (
          <Route path="/exports" component={ExportsSectionContainer} />
        )}
        {adminTabEnabled && (
          <Route path="/admin" component={AdminSectionContainer} />
        )}
      </Switch>
    );
  };

  render() {
    const {
      csvExportEnabled,
      myDashboardTabEnabled,
      alertsTabEnabled,
      evaluationListEnabled,
      exportsTabEnabled,
      adminTabEnabled,
      outcomesTabEnabled,
      monitoringTabEnabled,
      searchPatientDashboardEnabled,
      janssenDashboardEnabled,
      woundNumbersEnabled,
      schemaLocalizationDictionary,
      subtypeKeysForSchema,
      assessmentVersion,
      payerDashboardEnabled,
      visitSequenceEnabled,
      revisionBeingEdited,
      sendPostCsvExportAction,
      currentUser,
      canEditOtherUsersWork,
      canLockFromDashboard,
      isReportingEnabled,
      qaNoteAllowed,
      patientLinksEnabled
    } = this.props;

    if (searchPatientDashboardEnabled) return this.searchPatientTabRoutes();
    else if (!isReportingEnabled) return this.reportingDisabledRoutes();
    else {
      return (
        <div>
          <Switch>
            <Route exact path="/" component={HomeComponent} />
            {myDashboardTabEnabled && (
              <Route
                path="/my-dashboard"
                component={MyDashboardSectionContainer}
              />
            )}
            {(alertsTabEnabled || evaluationListEnabled) && (
              <Route path="/my-dashboard" component={AlertsSectionContainer} />
            )}
            {/* <Route path="/compliance" component={ComplianceSectionContainer} /> */}
            <Route
              path="/compliance"
              render={props => (
                <ComplianceSectionComponent
                  {...props}
                  woundNumbersEnabled={woundNumbersEnabled}
                  schemaLocalizationDictionary={schemaLocalizationDictionary}
                  subtypeKeysForSchema={subtypeKeysForSchema}
                  assessmentVersion={assessmentVersion}
                  payerDashboardEnabled={payerDashboardEnabled}
                  visitSequenceEnabled={visitSequenceEnabled}
                  revisionBeingEdited={revisionBeingEdited}
                  csvExportEnabled={csvExportEnabled}
                  sendPostCsvExportAction={sendPostCsvExportAction}
                  currentUser={currentUser}
                  canEditOtherUsersWork={canEditOtherUsersWork}
                  canLockFromDashboard={canLockFromDashboard}
                  qaNoteAllowed={qaNoteAllowed}
                  patientLinksEnabled={patientLinksEnabled}
                />
              )}
            />
            {outcomesTabEnabled && (
              <Route path="/outcomes" component={OutcomesSectionContainer} />
            )}
            <Route exact path="/account" component={AccountContainer} />
            <Route path="/wounds" component={WoundsSectionContainer} />
            <Route exact path="/risk" component={RiskDefaultContainer} />
            <Route exact path="/risk/braden" component={BradenRiskContainer} />
            <Route
              exact
              path="/risk/norton_plus"
              component={NortonRiskContainer}
            />
            <Route path="/population" component={PopulationSectionContainer} />

            <Route
              exact
              path="/etiology/details"
              component={SurveyDetailsContainer}
            />
            <Route path="/etiology" component={SurveyContainer} />

            <Route
              path="/change-password"
              component={ChangePasswordComponent}
            />
            {adminTabEnabled && (
              <Route path="/admin" component={AdminSectionContainer} />
            )}
            <Route
              path="/patients"
              render={props => (
                <PatientsSectionComponent
                  {...props}
                  janssenDashboardEnabled={janssenDashboardEnabled}
                />
              )}
            />
            {exportsTabEnabled && (
              <Route path="/exports" component={ExportsSectionContainer} />
            )}
            {monitoringTabEnabled && (
              <Route
                path="/monitoring"
                component={MonitoringSectionContainer}
              />
            )}
          </Switch>
        </div>
      );
    }
  }
}
