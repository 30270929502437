import get from "lodash.get";
/**
 * Get the score value for an assessment answer
 * @param {Object} assessmentAnswer
 * @returns {string}
 */
export function getScoreValue(assessmentAnswer, assessmentSchema) {
  const completed = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.completed",
      null
    ),
    score = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.score",
      null
    );

  if (
    get(assessmentSchema, "attributes.schemaJson.name", "") === "fullBodySkin"
  ) {
    return "S";
  }

  if (!completed) {
    return "--";
  }
  if (score) {
    return (Math.round(score * 100) / 100).toString();
  } else {
    return "";
  }
}
