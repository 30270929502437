import { dataActionTypes } from "src/action_types";
import { loadStates } from "src/constants/load_states";

const defaultState = {
  loadState: loadStates.ready
};

export function changePasswordReducer(state = defaultState, action) {
  switch (action.type) {
    case dataActionTypes.changePasswordLoading:
      return { loadState: loadStates.loading };
    case dataActionTypes.changePasswordReady:
      return { loadState: loadStates.ready };
    default:
      return state;
  }
}
