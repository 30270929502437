import { createSectionActionTypes } from "../../action_type_creators";

/**
 * Create a set of actions for section eg select and deselect checkbox, date range etc
 * @param {string} section the section name eg. wounds, survey
 * @returns {Object} actions object
 */

export function createSectionActions(section) {
  const actionTypes = createSectionActionTypes(section);

  return {
    selectCheckbox: filterObject => {
      return {
        type: actionTypes.selectCheckbox,
        payload: filterObject
      };
    },
    deselectCheckbox: filterObject => {
      return {
        type: actionTypes.deselectCheckbox,
        payload: filterObject
      };
    },
    selectSavedFilter: filter => {
      return {
        type: actionTypes.selectSavedFilter,
        payload: filter
      };
    },
    deselectSavedFilter: () => {
      return {
        type: actionTypes.deselectSavedFilter
      };
    },
    setSearchString: searchFilterObject => {
      return {
        type: actionTypes.setSearchString,
        payload: searchFilterObject
      };
    },
    selectDate: dateFilterObject => {
      return {
        type: actionTypes.selectDate,
        payload: dateFilterObject
      };
    },
    setSingleValue: setValueFilterObject => {
      return {
        type: actionTypes.setSingleValue,
        payload: setValueFilterObject
      };
    }
  };
}
