// @flow
import { combineReducers } from "redux";
import { enableBatching } from "redux-batched-actions";
import { enableRetyping } from "redux-retype-actions";
import composeHors from "redux-compose-hors";
import reducerStore from "./reducerStore";
import reducers from "@sw/reducers";

const updateStoreWithReducerMapping = store => {
  const { ...reducerMap } = reducerStore.getState();
  store.replaceReducer(
    composeHors(
      combineReducers({
        ...reducerMap,
        ...reducers
      }),
      enableRetyping,
      enableBatching
    )
  );
};

export default function setupIncrementalReduxReducers(reduxStore: Object) {
  // initialize store
  updateStoreWithReducerMapping(reduxStore);

  // respond to async reducers being added
  reducerStore.subscribe(() => {
    updateStoreWithReducerMapping(reduxStore);
  });
}
