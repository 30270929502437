import { camelizeKeys } from "humps";
/**
 * Convert snake case object to camel case
 * @param {object} obj
 */

export function camelCaseObjects(obj) {
  return camelizeKeys(obj, (key, convert, options) => {
    return /-/.test(key) ? key : convert(key, options);
  });
}
