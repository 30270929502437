import { createSelector } from "reselect";
import moment from "moment";

import { assessmentAnswersResourceSelectors } from "../assessment_answers_resource_selectors";

/**
 * Create a selector to get the most recent assessment answers for a set of revisions
 * @param {Object} props
 * @param {array} props.revisions
 * @returns {Function} selector
 */
export function createLatestAssessmentAnswerForRevisionsSelector({
  revisions
}) {
  return createSelector(
    assessmentAnswersResourceSelectors.dataSelector,
    assessmentAnswers => {
      return revisions.reduce((agg, r) => {
        const sortedAssessmentAnswers = Object.values(assessmentAnswers)
          .filter(assessmentAnswer => {
            return (
              assessmentAnswer.attributes.resourceType == "Revision" &&
              assessmentAnswer.attributes.resourceId == r.id
            );
          })
          .sort((a1, a2) => {
            return moment(a1.attributes.createdAt).isAfter(
              a2.attributes.createdAt
            )
              ? -1
              : 1;
          });

        agg[r.id] = sortedAssessmentAnswers.length
          ? sortedAssessmentAnswers[0]
          : undefined;

        return agg;
      }, {});
    }
  );
}
