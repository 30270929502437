import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { mapReducersToProps } from "incremental-redux-reducers";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

import "./style.less";

import flashMessagesReducer from "./reducer";

import FlashMessageComponent from "./flashMessageComponent";

class FlashMessagesComponent extends React.Component {
  static propTypes = {
    flashMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
    dispatch: PropTypes.func.isRequired
  };

  render = () => {
    let { flashMessages, dispatch } = this.props;

    let formattedFlashMessages = flashMessages.map(
      ({ id, flashMessage, messageType }) => {
        return (
          <FlashMessageComponent
            key={id}
            message={flashMessage}
            messageType={messageType}
            dispatch={dispatch}
          />
        );
      }
    );

    return (
      <div className="flash-messages">
        <CSSTransitionGroup
          transitionName="flash-message-transition"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {formattedFlashMessages}
        </CSSTransitionGroup>
      </div>
    );
  };
}

export default connect(
  mapReducersToProps({
    flashMessages: flashMessagesReducer
  })
)(FlashMessagesComponent);
