import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "react-bootstrap";
import { ComplianceContext } from "src/pages/compliance/components/compliance_provider_component.js";
import { SavedFilterModalContentComponent } from "../saved_filter_modal_content_component";
import styles from "./style.module.less";

export function SideBarHeaderComponent({ saveFilterButtonEnabled }) {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    state: { filters }
  } = useContext(ComplianceContext);

  const anyContextFilters = () =>
    Object.values(filters).filter(list => list.length).length > 0;

  const saveFilterEnabled = saveFilterButtonEnabled || anyContextFilters();

  const showCreateFilter = () => {
    setModalOpen(true);
  };

  const hideCreateFilter = () => {
    setModalOpen(false);
  };

  const saveBtnStyle = `btn btn-default btn-sm ${
    styles["secondary-filter-button"]
  }`;

  return (
    <div>
      <h3>Filter</h3>
      <button
        className={saveBtnStyle}
        disabled={!saveFilterEnabled}
        onClick={showCreateFilter}
      >
        Save
      </button>
      <Modal show={modalOpen} onHide={showCreateFilter} bsSize={"lg"}>
        <ModalBody>
          <SavedFilterModalContentComponent cancel={hideCreateFilter} />
        </ModalBody>
      </Modal>
    </div>
  );
}

SideBarHeaderComponent.propTypes = {
  saveFilterButtonEnabled: PropTypes.bool.isRequired
};
