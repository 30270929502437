import { createSideBarSavedFiltersContainer } from "./create_side_bar_saved_filters_container";
import { sections } from "src/constants";

export const SideBarAlertsSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.alerts
);
export const SideBarComplianceSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.compliance
);
export const SideBarOutcomesSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.outcomes
);
export const SideBarPatientsSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.patients
);
export const SideBarPopulationSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.population
);
export const SideBarRiskSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.risk
);
export const SideBarSurveySavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.survey
);
export const SideBarWoundsSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.wounds
);
export const SideBarMonitoringSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.monitoring
);
export const SideBarEvaluationListWoundSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.evaluationListWound
);
export const SideBarEvaluationListBradenSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.evaluationListBraden
);
export const SideBarEvaluationListPURSSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.evaluationListPURS
);
export const SideBarEvaluationListTotalSkinSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.evaluationListTotalSkin
);
export const SideBarWoundRiskSavedFiltersContainer = createSideBarSavedFiltersContainer(
  sections.woundRisk
);
