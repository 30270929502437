import { createSelector } from "reselect";
import get from "lodash.get";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

//Constants
import { policyInternalNames } from "src/constants";

/**
 * Memoized selector to determine if My Dashboard is enabled
 */
export const myDashboardTabEnabledSelector = createSelector(
  createPolicyForInternalNameSelector(policyInternalNames.myDashboardEnabled),
  policy => get(policy, "attributes.policyValue", false)
);
