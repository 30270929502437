import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "../../ui-kit";
import moment from "moment";
import {
  getImageDataUrlFromLocalPath,
  renderChartToImageDataUrl
} from "src/utils/image_data_url";
import { loadStates as _loadStates } from "src/constants/load_states";

import clonedeep from "lodash.clonedeep";

// Pdf
import pdfMake from "pdfmake/build/pdfmake";
import { createWoundsPdf } from "../../pdfs/create_wounds_pdf";

export class WoundsWoundTabPDFButtonComponent extends React.Component {
  static propTypes = {
    selectedDate: PropTypes.object.isRequired,
    brandedLogoPng: PropTypes.string.isRequired,
    branding: PropTypes.string.isRequired
  };

  downloadPdf = () => {
    const {
      selectedOrganization,
      chartDefinition,
      progressValues,
      selectedDate,
      brandedLogoPng,
      branding,
      assessmentVersion
    } = this.props;

    let counts = {};

    chartDefinition.data.datasets.forEach(progressDataSet => {
      counts[progressDataSet.progressKey] =
        progressDataSet.data[progressDataSet.data.length - 1];
    });

    const cloneChartDefinition = clonedeep(chartDefinition);

    getImageDataUrlFromLocalPath(brandedLogoPng).then(logoUrl => {
      cloneChartDefinition.options.legend = {
        display: true,
        position: "right",
        labels: {
          boxWidth: 12
        }
      };
      const chartImage = renderChartToImageDataUrl(
        cloneChartDefinition,
        1000,
        357
      );

      const date = new moment().format("YYYY-MMM-DD--HH-mm").toUpperCase();
      const pdfDefinition = createWoundsPdf(
        {
          logo: logoUrl,
          reportName: "WOUNDS REPORT",
          organization: selectedOrganization,
          counts,
          progressValues,
          assessmentVersion,
          selectedDate
        },
        {
          showPageNumber: false,
          showOrganizationDetails: true,
          branding
        },
        {
          woundsChart: chartImage
        }
      );
      pdfMake.createPdf(pdfDefinition).download(`wounds_report_${date}.pdf`);
    });
  };

  isLoadingChartData = () => {
    const { chartLoadStates } = this.props;
    return this.isLoading(chartLoadStates);
  };

  isLoading = loadStates => {
    if (loadStates.some(val => !val)) {
      return true;
    } else if (loadStates.some(val => val == _loadStates.loading)) {
      return true;
    } else if (loadStates.some(val => val == _loadStates.error)) {
      return true;
    } else {
      return false;
    }
  };

  isDisabled = () => {
    return this.isLoadingChartData();
  };

  render() {
    return (
      <Button
        name="primary"
        className="control-navbar-element dashboard-btn"
        subtype="button"
        onClick={() => this.downloadPdf()}
        disabled={this.isDisabled()}
      >
        <Icon name="download" />
      </Button>
    );
  }
}
