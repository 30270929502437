import { createSelector } from "reselect";

// Utils
import { buildQueryString } from "src/utils/build_query_string"; //TODO: remove if it's not needed

export function createAllOrganizationsQuerySelector() {
  return createSelector(() => {
    return buildQueryString({
      include: "policies,payers",
      page: {
        limit: 1000
      }
    });
  });
}
