// @flow
import { propertyRelationshipReducer } from "src/utils/reducerUtils";
import propertySchema from "src/schemas/propertySchemas";

const mapOfRelationshipSchemas = Object.keys(propertySchema).reduce(
  (finalResult, key) => {
    finalResult[key] = propertySchema[key].relationshipSchemas;
    return finalResult;
  },
  {}
);

export const propertyRelationships = propertyRelationshipReducer({
  name: "properties",
  mapOfRelationshipSchemas
});
