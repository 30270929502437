import { createSelector } from "reselect";

/**
 * create a selector to get the api context for admin floors
 * @returns {function} selector
 */
export function createAdminFloorsContextSelector() {
  return createSelector(
    () => {
      return "adminFloors";
    },
    context => {
      return context;
    }
  );
}
