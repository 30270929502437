// @flow
import { Record, List } from "immutable";
import { convertInput } from "src/utils/modelUtils";

export const recordInput = {
  dates: new List(),
  values: new List()
};

export default class DateValueModel extends Record(recordInput) {
  constructor(opts: Object = {}) {
    super(
      convertInput(
        {
          dates: List,
          values: List
        },
        opts
      )
    );
  }
}
