import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

export function createAlertRuleFormSelectedOrganizationSelector(formName) {
  return createSelector(
    getFormValues(formName),
    form => {
      if (form && form["organizationId"]) {
        return form["organizationId"];
      }
      return null;
    }
  );
}
