// React
import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
//css
import styles from "../style.module.less";
export class PopulationTallyTableHeaderByEtiologyComponent extends Component {
  // ------------------- Wound Type Header ------------------- //

  /**
   * Get the Type Header for wound type table
   */
  getWoundTypeHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip">Wound types/subtypes</Tooltip>}
        >
          <span>Wound Type</span>
        </OverlayTrigger>
      </th>
    );
  }

  // ------------------- New Wound Patients Header ------------------- //

  getNewWoundPatientsHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">Number of patients with new wounds</Tooltip>
          }
        >
          <span>New Wound Patients</span>
        </OverlayTrigger>
      </th>
    );
  }

  // ------------------- Wound Patients Header ------------------- //

  getWoundPatientsHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">Number of patients with wounds</Tooltip>
          }
        >
          <span>Wound Patients</span>
        </OverlayTrigger>
      </th>
    );
  }

  // ------------------- Incidence Header ------------------- //

  getIncidenceHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              Percentage of patients with new wounds in the time period
            </Tooltip>
          }
        >
          <span>Incidence (%)</span>
        </OverlayTrigger>
      </th>
    );
  }

  // ------------------- Prevalence Patients Header ------------------- //

  getPrevalenceHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              Percentage patients with wounds in the time period
            </Tooltip>
          }
        >
          <span>Prevalence (%)</span>
        </OverlayTrigger>
      </th>
    );
  }

  // ------------------- Render ------------------- //

  render() {
    return (
      <tr>
        {this.getWoundTypeHeader()}
        {this.getNewWoundPatientsHeader()}
        {this.getWoundPatientsHeader()}
        {this.getIncidenceHeader()}
        {this.getPrevalenceHeader()}
      </tr>
    );
  }
}
