import axios from "axios";
import { getCookieValueForKey } from "src/client_storage";
import {
  responseInterceptor,
  responseErrorInterceptor
} from "src/api/interceptors";

/**
 * Sets the Authorization header globally for axios requests.
 * This should be called every time a new token is provided.
 * The setAccessToken action is responsible to do this.
 * @param {string} token the access token
 */
export function setApiAuthorization(token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor
  );
}

/**
 * Clears the global authorization header
 * this should be done whenever the user signs out or the token expires
 * The clearAccessToken action is responsible for doing this.
 */
export function clearApiAuthorization() {
  delete axios.defaults.headers.common["Authorization"];
}

/**
 * Sets global configurations for axios.
 * Only needs to be called once on startup.
 * This is called in the RootComponent willMount function.
 * @param {string} accessToken the JWT
 */
export function configureApi(accessToken) {
  // set initial token from client storage if it exists
  if (accessToken) {
    setApiAuthorization(accessToken);
  } else {
    const cachedToken = getCookieValueForKey("accessToken");
    if (cachedToken) {
      setApiAuthorization(cachedToken);
    }
  }

  const cachedApiHost = getCookieValueForKey("apiHost");
  if (cachedApiHost) {
    axios.defaults.baseURL = cachedApiHost;
  }

  axios.defaults.headers.common["Content-Type"] = "application/json";
}
