import get from "lodash.get";

export function getWoundDetailsColumns(woundDetailsData, options = {}) {
  const { woundNumbersEnabled } = options;
  let woundSurfaceArea =
    woundDetailsData.surfaceArea != "--"
      ? `${get(woundDetailsData, "surfaceArea", "--")}cm^2`
      : "--";

  //Wound Number, Type, Subtype
  let woundNumber = get(woundDetailsData, "patientWoundNumber", "") || "N/A";
  let woundType = get(woundDetailsData, "type", "") || "N/A";
  let woundSubtype = get(woundDetailsData, "subtype", "") || "N/A";
  let woundTypeSubtype =
    woundSubtype !== "N/A" ? `${woundType} - ${woundSubtype}` : woundType;
  let woundNumberValue = woundNumber == "N/A" ? woundNumber : `#${woundNumber}`;
  let woundInfo = woundNumbersEnabled
    ? `${woundNumberValue} - ${woundTypeSubtype}`
    : `${woundTypeSubtype}`;

  //Measurements
  let { dimensions } = woundDetailsData;
  let { l, w, d } = dimensions;
  l = l === "--" ? l : l + " cm";
  w = w === "--" ? w : w + " cm";
  d = d === "--" ? d : d + " cm";
  let woundMeasurements = `L: ${l}\nW: ${w}\nD: ${d}\n`;

  return {
    name: get(woundDetailsData, "name", "") || "N/A",
    unit: get(woundDetailsData, "unitName", "") || "N/A",
    floor: get(woundDetailsData, "floorName", "") || "N/A",
    lastEvaluatedTimePeriod: get(woundDetailsData, "lastAssessed", "") || "N/A",
    woundInfo: woundInfo,
    acquired: get(woundDetailsData, "acquired", "") || "N/A",
    progress: get(woundDetailsData, "progress", "") || "N/A",
    bodyLocation: get(woundDetailsData, "bodyLocation", "") || "N/A",
    surfaceArea: woundSurfaceArea,
    measurements: woundMeasurements,
    cleansingSolution: get(woundDetailsData, "cleansingSolution", "") || "--",
    primaryDressing: get(woundDetailsData, "primaryDressing", "") || "--"
  };
}
