import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { analyticsEventNames } from "src/constants";

import { createLatestAssessmentAnswerForRevisionSelector } from "src/selectors/data_selectors";

// Actions
import {
  loadAssessmentAnswersAction,
  loadAssessmentsAction
} from "src/actions/data_actions";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc";

// Components
import { RevisionWoundImageHeaderComponent } from "src/components/revision_wound_image_header_component";

// Selectors
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";
import {
  assessmentAnswersResourceSelectors,
  assessmentsResourceSelectors
} from "src/selectors/data_selectors";
import { createNestedAssessmentSchemasForAssessmentAnswerSelector } from "src/selectors/data_selectors";

import { createLatestAssessmentAnswerForRevisionQuerySelector } from "src/selectors/api_selectors/global/assessment_answers/create_latest_assessment_answer_for_revision_query_selector";
import { createAllAssessmentsWithSchemasForRevisionQuerySelector } from "src/selectors/api_selectors/global/assessments";
import { createRevisionIsFirstInSeriesSelector } from "src/selectors/data_selectors/revisions/create_revision_is_first_in_series_selector";
import {
  bwatScoreEnabledSelector,
  payerDashboardEnabledSelector,
  visitSequenceReportingEnabledSelector,
  etiologyVerifiedQuestionEnabledSelector
} from "src/selectors/ui_config_selectors/global";
import { createPayersForRevisionsSelector } from "src/selectors/data_selectors/payers/create_payers_for_revisions_selector";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

function mapStateToProps(state, ownProps) {
  const assessmentAnswer = createLatestAssessmentAnswerForRevisionSelector(
    ownProps.revision
  )(state);
  const revisions = [ownProps.revision];
  return {
    assessmentAnswer,
    assessmentVersion: assessmentVersionSelector(state),
    payersForRevision: createPayersForRevisionsSelector({ revisions })(state),
    putLoadState: assessmentAnswersResourceSelectors.putLoadStateSelector(
      state
    ),
    deleteLoadStateWithStatusById: assessmentAnswersResourceSelectors.deleteLoadStateWithStatusByIdSelector(
      state
    ),
    currentUser: currentUserSelector(state),
    nestedAssessmentSchemas: createNestedAssessmentSchemasForAssessmentAnswerSelector(
      assessmentAnswer
    )(state),
    revisionIsFirstInSeries: createRevisionIsFirstInSeriesSelector(
      ownProps.revision
    )(state),
    bwatScoreEnabled: bwatScoreEnabledSelector(state),
    payerDashboardEnabled: payerDashboardEnabledSelector(state),
    visitSequenceEnabled: visitSequenceReportingEnabledSelector(state),
    etiologyVerifiedQuestionEnabled: etiologyVerifiedQuestionEnabledSelector(
      state
    )
  };
}

export const RevisionWoundImageHeaderContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentAnswersAction,
    resourceSelectors: assessmentAnswersResourceSelectors,
    createQuerySelector: createLatestAssessmentAnswerForRevisionQuerySelector,
    createContextSelector: createLatestAssessmentAnswerForRevisionQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentsAction,
    resourceSelectors: assessmentsResourceSelectors,
    createQuerySelector: createAllAssessmentsWithSchemasForRevisionQuerySelector,
    createContextSelector: createAllAssessmentsWithSchemasForRevisionQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName:
      analyticsEventNames.renderRevisionAssessmentAnswersComponent
  }),
  connect(mapStateToProps)
)(RevisionWoundImageHeaderComponent);
