import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./style.module.less";

// Constants
import { allFacilitiesOptionId } from "src/constants";

// Components
import { SelectComponent } from "src/components/ui_kit";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

export class AdminAlertRulesFiltersComponent extends React.PureComponent {
  static propTypes = {
    sendSelectOrganizationAction: PropTypes.func.isRequired,
    sendSelectFacilityAction: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const { organizations } = props;
    if (organizations.length) {
      const defaultOrganizationId = this.props.organizations[0].id;
      props.sendSelectOrganizationAction(defaultOrganizationId);
      this.props.sendSelectFacilityAction(allFacilitiesOptionId);
    }
  }

  componentDidUpdate(prevProps) {
    const { organizations } = this.props;

    if (!organizations.length) return;

    if (propsAreDifferent(this.props, prevProps, "organizations")) {
      const defaultOrganizationId = this.props.organizations[0].id;
      this.props.sendSelectOrganizationAction(defaultOrganizationId);
      this.props.sendSelectFacilityAction(allFacilitiesOptionId);
    } else if (propsAreDifferent(this.props, prevProps, "facilities")) {
      this.props.sendSelectFacilityAction(allFacilitiesOptionId);
    }
  }

  onChangeOrganization = organizationId => {
    this.props.sendSelectOrganizationAction(organizationId);
  };

  onChangeFacility = facilityId => {
    this.props.sendSelectFacilityAction(facilityId);
  };

  render() {
    const { organizations, facilities } = this.props;

    const organizationOptions = organizations.map(organization => {
      return {
        key: organization.id,
        value: organization.id,
        label: organization.attributes.name
      };
    });

    let facilitiesOptions = [
      {
        key: allFacilitiesOptionId,
        value: allFacilitiesOptionId,
        label: "All Facilities"
      }
    ].concat(
      facilities.map(facility => {
        return {
          key: facility.id,
          value: facility.id,
          label: facility.attributes.name
        };
      })
    );

    return (
      <div className={styles["selectors-container"]}>
        <div>
          <span>Organization</span>
          <SelectComponent
            onChange={this.onChangeOrganization}
            options={organizationOptions}
          />
        </div>
        <div>
          <span>Facility</span>
          <SelectComponent
            onChange={this.onChangeFacility}
            options={facilitiesOptions}
          />
        </div>
      </div>
    );
  }
}
