import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "react-bootstrap";

//Translations
import i18n from "src/translations";

//Formatters
import { cellFormatters } from "../cell_formatters";

//Containers
import { AcknowledgeAlertContainer } from "src/containers/acknowledge_alert_container";

//Components
import { TableComponent } from "../table_component";
import { TableNavigatorComponent } from "src/components/tables/table_navigator_component";
import { TablePageLimitDropdownComponent } from "../table_page_limit_dropdown_component";

// Styles
import styles from "./style.module.less";

/**
 * Table component for user alerts
 */
export class AlertsTableComponent extends React.PureComponent {
  static propTypes = {
    alerts: PropTypes.array.isRequired,
    pageLimit: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    firstPageContext: PropTypes.string.isRequired,
    sendSetPageLimitAction: PropTypes.func.isRequired,
    sendSetPageAction: PropTypes.func.isRequired
  };

  state = {
    showAcknowledgeAlertModal: false,
    alertInModal: {}
  };

  setPage = pageNumber => {
    const { firstPageContext, sendSetPageAction } = this.props;
    sendSetPageAction(firstPageContext, pageNumber);
  };

  acknowledgeAlert = alertId => {
    this.props.sendResolveAlertAction({ id: alertId, data: { id: alertId } });
  };

  onClickAcknowledgeLink = (e, row) => {
    this.setState({
      ...this.state,
      alertInModal: row,
      showAcknowledgeAlertModal: true
    });
  };

  hideAcknowledgeAlertModal = () => {
    this.setState({
      ...this.state,
      showAcknowledgeAlertModal: false
    });
  };

  acknowledgeFormatter = (cell, row) => {
    return (
      <a
        className={styles["action-link"]}
        onClick={e => this.onClickAcknowledgeLink(e, row)}
      >
        {i18n.t("actions.acknowledge")}
      </a>
    );
  };

  render() {
    const {
      schemaLocalizationDictionary,
      alerts,
      pageLimit,
      pageCount,
      currentPage,
      sendSetPageLimitAction,
      subtypeKeysForSchema
    } = this.props;

    const columns = [
      {
        text: "",
        dataField: "attributes.resource.imageUrlThumb",
        formatter: cellFormatters.woundThumbnailFormatter
      },
      {
        text: i18n.t("headers.patientName"),
        dataField: "attributes.patientName",
        formatter: cellFormatters.patientFullNameFormatter
      },
      {
        text: i18n.t("headers.mrn"),
        dataField: "attributes.patientMrn",
        formatter: cellFormatters.mrnFormatter
      },
      {
        text: i18n.t("headers.location"),
        dataField: "attributes.latestPatientLocation[0].name",
        formatter: cellFormatters.patientLocationNamesFormatter
      },
      {
        text: i18n.t("headers.alertDate"),
        dataField: "attributes.createdAt",
        formatter: cellFormatters.dateFormatter
      },
      //   {
      //     text: i18n.t("headers.evaluationDate"),
      //     dataField: "attributes.resource.createdAt",
      //     formatter: cellFormatters.dateFormatter
      //   },
      {
        text: i18n.t("headers.woundNumberTypeAndSubtype"),
        dataField: "attributes.resource.patientWoundNumber",
        formatter: cellFormatters.woundNumberTypeAndSubtypeFormatter,
        formatExtraData: {
          localizationDictionary: schemaLocalizationDictionary,
          subtypeKeysForSchema
        }
      },
      {
        text: i18n.t("headers.bodyLocation"),
        dataField: "attributes.resource.answersJson.location",
        formatter: cellFormatters.bodyLocationFormatter,
        formatExtraData: {
          localizationDictionary: schemaLocalizationDictionary
        }
      },
      {
        text: i18n.t("headers.alertType"),
        dataField: "attributes.ruleTemplateDescription"
      },
      {
        text: i18n.t("headers.evaluatedBy"),
        dataField: "attributes.trippedByUserName"
      },
      {
        text: i18n.t("headers.action"),
        dataField: "id",
        formatter: this.acknowledgeFormatter
      }
    ];

    return (
      <div>
        <TableComponent data={alerts} keyField="id" columns={columns} />
        <div className="pull-left">
          <TablePageLimitDropdownComponent
            selectPageLimit={sendSetPageLimitAction}
            selectedPageLimit={pageLimit}
          />
        </div>
        <TableNavigatorComponent
          pageNumber={currentPage}
          pageCount={pageCount}
          selectTablePage={this.setPage}
        />
        <Modal
          show={this.state.showAcknowledgeAlertModal}
          onHide={this.hideAcknowledgeAlertModal}
        >
          <ModalBody>
            <AcknowledgeAlertContainer
              alert={this.state.alertInModal}
              cancel={this.hideAcknowledgeAlertModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
