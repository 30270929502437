/**
 * Merge two sets of normalized resources - with deep merging on attributes
 * @param {Object} r1
 * @param {Object} r2
 * @returns {Object} Resources with r2 merged into r1
 */
export function mergeResources(r1, r2) {
  let merged = {
    ...r1
  };

  if (r2) {
    Object.keys(r2).forEach(key => {
      if (r1[key]) {
        merged[key] = {
          ...r2[key],
          attributes: {
            ...r1[key].attributes,
            ...r2[key].attributes
          }
        };
      } else {
        merged[key] = r2[key];
      }
    });
  }

  return merged;
}
