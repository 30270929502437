import React from "react";
import PropTypes from "prop-types";

import { startTimeTrack } from "src/analytics";

/**
 * Will try to track events when all children finish rendering
 */
export class StartRenderTimeTrackComponent extends React.PureComponent {
  static propTypes = {
    eventName: PropTypes.string.isRequired
  };

  componentDidMount() {
    startTimeTrack(this.props.eventName);
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
