import { createSelector } from "reselect";
import { patientOverviewTabs } from "src/constants";

//Selectors
import { patientOverviewAllergiesEnabledSelector } from "../allergies_enabled_selector";
import { patientOverviewCormorbiditiesEnabledSelector } from "../cormorbidities_enabled_selector";
import { patientOverviewEducationEnabledSelector } from "../education_enabled_selector";
import { patientOverviewMedicationEnabledSelector } from "../medication_enabled_selector";
import { patientOverviewNotesEnabledSelector } from "../notes_enabled_selector";
import { patientOverviewOrdersEnabledSelector } from "../orders_enabled_selector";
import { patientOverviewPathwaysEnabledSelector } from "../pathways_enabled_selector";
import { patientOverviewSuppliesEnabledSelector } from "../supplies_enabled_selector";

/**
 * Memoized selector to determine the tabs that will be rendered in the patient overview tab
 */
export const patientOverviewTabsToRenderSelector = createSelector(
  patientOverviewPathwaysEnabledSelector,
  patientOverviewOrdersEnabledSelector,
  patientOverviewCormorbiditiesEnabledSelector,
  patientOverviewAllergiesEnabledSelector,
  patientOverviewMedicationEnabledSelector,
  patientOverviewEducationEnabledSelector,
  patientOverviewSuppliesEnabledSelector,
  patientOverviewNotesEnabledSelector,
  (
    pathwaysEnabled,
    ordersEnabled,
    cormorbiditiesEnabled,
    allergiesEnabled,
    medicationEnabled,
    educationEnabled,
    suppliesEnabled,
    notesEnabled
  ) => {
    const tabs = [];
    if (pathwaysEnabled) {
      tabs.push(patientOverviewTabs.pathways);
    }
    if (ordersEnabled) {
      tabs.push(patientOverviewTabs.orders);
    }
    if (cormorbiditiesEnabled) {
      tabs.push(patientOverviewTabs.cormorbidities);
    }
    if (allergiesEnabled) {
      tabs.push(patientOverviewTabs.allergies);
    }
    if (medicationEnabled) {
      tabs.push(patientOverviewTabs.medication);
    }
    if (educationEnabled) {
      tabs.push(patientOverviewTabs.education);
    }
    if (suppliesEnabled) {
      tabs.push(patientOverviewTabs.supplies);
    }
    if (notesEnabled) {
      tabs.push(patientOverviewTabs.notes);
    }

    return tabs;
  }
);
