import { createSelector } from "reselect";

import {
  assessmentSchemasResourceSelectors,
  createAssessmentForAssessmentAnswerSelector
} from "src/selectors/data_selectors";

/**
 * Create a selector that gets the assessment schema given an assessmentAnswer
 * @param {Object} assessmentAnswer
 * @returns {Function} selector
 */
export function createAssessmentSchemaForAssessmentAnswerSelector(
  assessmentAnswer
) {
  return createSelector(
    assessmentSchemasResourceSelectors.dataSelector,
    createAssessmentForAssessmentAnswerSelector(assessmentAnswer),
    (assessmentSchemas, assessment) => {
      if (assessment) {
        return Object.values(assessmentSchemas).find(schema => {
          return schema.attributes.assessmentId == assessment.id;
        });
      }

      return null;
    }
  );
}
