import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import get from "lodash.get";

//HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc";

//Constants
import { analyticsEventNames } from "src/constants";

//Component
import { DownloadRevisionPDFButtonComponent } from "src/components/buttons/download_revision_pdf_button_component";

//Selector
import {
  assessmentAnswersResourceSelectors,
  assessmentsResourceSelectors,
  eventsResourceSelectors,
  facilitiesResourceSelectors
} from "src/selectors/data_selectors";
import { seriesTrendsResourceSelectors } from "src/selectors/data_selectors/series_trends";
import { createRevisionPdfDefinitionSelector } from "src/selectors/pdf_definition_selectors/create_revision_pdf_definition_selector";
import { createPatientsForRevisionsSelector } from "src/selectors/data_selectors/patients/create_patients_for_revisions_selector";
import { createDefaultFramesForRevisionsSelector } from "src/selectors/data_selectors/frames/create_default_frames_for_revisions_selector";
import { createLastFacilityQueryForPatientIdSelector } from "src/selectors/api_selectors/global/patients";

////API Selectors
import { createLatestAssessmentAnswerForRevisionQuerySelector } from "src/selectors/api_selectors/global/assessment_answers/create_latest_assessment_answer_for_revision_query_selector";
import { createAllAssessmentsWithSchemasForRevisionQuerySelector } from "src/selectors/api_selectors/global/assessments/create_all_assessments_with_schemas_for_revision_query_selector";
import { createLatestSignedEventForRevisionsQuerySelector } from "src/selectors/api_selectors/global/events/create_latest_signed_event_for_revisions_query_selector";
import { createAllSeriesTrendsForRevisionQueryFilterSelector } from "src/selectors/api_selectors/global/series_trends";

//Actions
import { create } from "src/actions/flashMessageActions";
import {
  loadAssessmentAnswersAction,
  loadAssessmentsAction,
  loadSeriesTrendsAction,
  loadEventsAction,
  loadFacilitiesAction
} from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  const { revision, revisions, landscape } = ownProps;

  const patientsByRevision = createPatientsForRevisionsSelector({
    revisions
  })(state);

  const patient = patientsByRevision[revision.id];

  const patientLastFacilityId = get(
    patient,
    "attributes.lastFacilityId",
    undefined
  );

  const framesByRevision = createDefaultFramesForRevisionsSelector({
    revisions
  })(state);

  const generatePDFDefinition = createRevisionPdfDefinitionSelector({
    revisions,
    patientLastFacilityId,
    landscape
  })(state);

  return {
    revisions,
    patient,
    frames: framesByRevision,
    generatePDFDefinition
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showPdfErrorView() {
      dispatch(
        create("Sorry, there was an error downloading your export", "error")
      );
    },
    showPdfTimeoutErrorMessage() {
      dispatch(create("PDF download timeout. Please refresh page", "error"));
    }
  };
}

export const DownloadRevisionPDFButtonContainer = flowRight(
  // First assessment answer for revision
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentAnswersAction,
    resourceSelectors: assessmentAnswersResourceSelectors,
    createContextSelector: createLatestAssessmentAnswerForRevisionQuerySelector,
    createQuerySelector: createLatestAssessmentAnswerForRevisionQuerySelector,
    waitForParentLoaderToComplete: true
  }),
  // Assessment and schemas for all assessment answers
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentsAction,
    resourceSelectors: assessmentsResourceSelectors,
    createContextSelector: createAllAssessmentsWithSchemasForRevisionQuerySelector,
    createQuerySelector: createAllAssessmentsWithSchemasForRevisionQuerySelector,
    waitForParentLoaderToComplete: true
  }),
  // Events - latest signed for each revision
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestSignedEventForRevisionsQuerySelector,
    createQuerySelector: createLatestSignedEventForRevisionsQuerySelector,
    waitForParentLoaderToComplete: true
  }),
  // Series trends - for closure and push graphs
  withResourceLoaderFactory({
    getResourceAction: loadSeriesTrendsAction,
    resourceSelectors: seriesTrendsResourceSelectors,
    createContextSelector: createAllSeriesTrendsForRevisionQueryFilterSelector,
    createQuerySelector: createAllSeriesTrendsForRevisionQueryFilterSelector,
    waitForParentLoaderToComplete: true
  }),
  withResourceLoaderFactory({
    getResourceAction: loadFacilitiesAction,
    resourceSelectors: facilitiesResourceSelectors,
    createQuerySelector: createLastFacilityQueryForPatientIdSelector,
    createContextSelector: createLastFacilityQueryForPatientIdSelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: analyticsEventNames.renderRevisionPDFButton
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DownloadRevisionPDFButtonComponent);
