import React, { useReducer, useEffect } from "react";
import { fetchOrganizations } from "src/api/organizations";
import { reducer } from "src/pages/compliance/reducers/index.js";
import Moment from "moment";

const initialState = {
  filters: {
    saved: [],
    evaluations: [],
    locations: [],
    clinicians: [],
    patientEvaluations: [],
    payers: [],
    visitFrequencies: []
  },
  organizations: [],
  pageLimit: 25,
  pageNumber: 0,
  nameOrMrnOrCode: "",
  selectedTab: "inProgressEvaluations",
  startDate: Moment()
    .subtract(7, "d")
    .startOf("day"),
  endDate: Moment().endOf("day"),
  unitRange: "pastWeek",
  nameOrMrnOrCode: "",
  selectedTab: "inProgressEvaluations",
  checkedAssessmentSummaries: [],
  schemaLocalizationDictionary: null
};

const ComplianceContext = React.createContext(initialState);

function ComplianceProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setOrganizations = ({ data }) => {
    dispatch({
      type: "SET_ORGANIZATIONS",
      payload: { organizations: data.data }
    });
  };

  useEffect(() => {
    // alternatively, connect this component to redux and set org from there
    fetchOrganizations().then(setOrganizations);
  }, []);

  return (
    <ComplianceContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ComplianceContext.Provider>
  );
}
export { ComplianceContext, ComplianceProvider };
