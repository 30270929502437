// @flow
import Cookies from "cookies-js";

const devEnvironment = process.env.NODE_ENV !== "production";

const COOKIE_OPTIONS = {
  expires: 60 * 30, // 30 mins
  path: "/",
  secure: true
};

export const get = function(key: string) {
  return Cookies.get(key) || localStorage.getItem(key) || null;
};

export const set = function(key: string, value: string) {
  Cookies.set(key, value, COOKIE_OPTIONS);
  if (devEnvironment) {
    localStorage.setItem(key, value);
  }
};

export const expire = function(key: string) {
  Cookies.expire(key, COOKIE_OPTIONS);
  if (devEnvironment) {
    localStorage.removeItem(key);
  }
};
