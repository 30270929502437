export const eventTypes = {
  create: "Created",
  update: "Updated",
  delete: "Deleted",
  viewed: "Viewed",
  signed: "Signed",
  lock: "Locked",
  unsigned: "Unsigned",
  unlock: "Unlocked",
  strikeout: "Struck Out",
  archive: "Archived",
  activate: "Activated",
  resolve: "Resolved",
  unresolve: "Unresolved",
  cancel: "Canceled",
  acknowledge: "Acknowledged"
};
