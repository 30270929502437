import normalize from "json-api-normalizer";
import ReduxResources from "src/redux_resources_v2";

// Api
import { apiFactory } from "./api_factory";

// Error handling
import { onApiActionError } from "./on_api_action_error";

// Configure ReduxResources
ReduxResources.configure({
  normalizer: response => {
    return normalize(response.data);
  },
  reduxStoreRootName: "resources",
  onApiActionError
});

import { Events } from "./events";

export default {
  events: new ReduxResources.ResourceManager({
    ResourceClass: Events,
    resourceName: "events",
    api: apiFactory({
      path: "/api/v4/events",
      useGetOverride: false,
      usePostForGet: false
    })
    // normalizer: () => console.log("my custom normalizer")
    // includedResourceNames: "this is needed so we don't couple the 'type' "
    // includedResourceClasses: { revisions: Revisions } TODO
    // onApiActionError - custom handling
  })
};

// Use of Redux Resources:

// imports from this folder - open to renaming this to resource_managers
// import ResourceManagers from "src/resources";

// with resource HOC:
// ResourceManagers.events.withResource({
//   mapStateToQueryObject: (state, ownProps) => {
//     // this could use a selector
//     return {
//       filters: {
//         revision_id: ownProps.revisionId,
//         event_type: eventTypeFilters.revision.join()
//       }
//     };
//   },
//   mapStateToPage: (state, ownProps) => {
//     // this could use a selector if needed
//     return {
//       limit: 2,
//       all: true
//     };
//   }
// })

// Selectors:
// const queryObject = {
//   filters: {
//     revision_id: ownProps.revisionId,
//     event_type: eventTypeFilters.revision.join()
//   }
// };
// const pageObject = {
//   limit: 2,
//   all: true
// };

// return {
//   events: ResourceManagers.events.selectors.createDataSelector(
//     queryObject,
//     pageObject
//   )(state)
// };
