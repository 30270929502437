import { createSelector } from "reselect";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * Create revision GET context with a filter json structure
 * @param {Object} props
 * @param {Object} props.filters - a filter blob applied to the revisions request
 */
import { createFirstPageRevisionPayloadForFiltersSelector } from "../create_first_page_revision_payload_for_filters_selector";

export function createFirstPageRevisionContextForFiltersSelector({ filters }) {
  return createSelector(
    createFirstPageRevisionPayloadForFiltersSelector({ filters }),
    payload => buildQueryString(payload)
  );
}
