import { createSelector } from "reselect";

//Selectors
import { locationsToDisplaySelector } from "src/selectors/data_selectors/locations";
import { partnerLocationIdsSelector } from "src/selectors/config_selectors";
import { isEMCViewModeSelector } from "src/selectors/session_selectors";

//Selector that determines if a PCC user is in single facility view mode
export const singleFaciltyViewModeSelector = createSelector(
  isEMCViewModeSelector,
  locationsToDisplaySelector,
  partnerLocationIdsSelector,
  (isEMCViewMode, locationsToDisplay, partnerLocationIds) => {
    //Return FALSE for PCC Partner Electronic Management Console (EMC) view mode
    //OR for any users without partner location ids
    if (isEMCViewMode || !partnerLocationIds.length) {
      return false;
      //Return single facility ID if single facility view mode
    } else {
      const partnerFacility = locationsToDisplay.filter(loc => {
        return (
          loc.tags.includes("Facility") && partnerLocationIds.includes(loc.id)
        );
      });

      let singleFacility;

      if (partnerFacility.length) {
        singleFacility = partnerFacility[0];
      }

      if (singleFacility) {
        return singleFacility.id;
      }
    }
  }
);
