import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Lodash
import flowRight from "lodash.flowright";
import get from "lodash.get";

// HOC
import { withResourceLoaderFactory } from "src/hoc/factories";

// Components
import RevisionWoundDetailsHeaderComponent from "src/components/revision_wound_details_header_component";

// Selectors
import {
  createSeriesForRevisionSelector,
  createLatestAssessmentAnswerForRevisionSelector,
  createDefaultFrameForRevisionSelector,
  createPatientForIdSelector,
  createProgressTabSchemasForAssessmentAnswerSelector,
  createTreatmentTabSchemasForAssessmentAnswerSelector,
  createAttributesTabSchemasForAssessmentAnswerSelector,
  eventsResourceSelectors,
  isLatestRegionForRevisionAutodepth,
  facilitiesResourceSelectors,
  createDescriptionAssessmentSchemaForAssessmentAnswerSelector
} from "src/selectors/data_selectors";
import { createRevisionIsFirstInSeriesSelector } from "src/selectors/data_selectors/revisions/create_revision_is_first_in_series_selector";

import { createStringifiedAssessmentJSONDataSelector } from "src/selectors/stringified_json_data_selectors";

import {
  brandingSelector,
  brandedLogoPngSelector
} from "src/selectors/ui_config_selectors/global";

import {
  createLatestSignedEventForRevisionIdContextSelector,
  createLatestLockedEventForRevisionIdContextSelector
} from "src/selectors/context_selectors/events_for_revision_id";

import {
  createLatestSignedEventForRevisionIdQuerySelector,
  createLatestLockedEventForRevisionIdQuerySelector
} from "src/selectors/query_selectors/events_for_revision_id";

import {
  woundNumbersEnabledSelector,
  historyCauseEnabledSelector,
  hprScoreEnabledSelector,
  bwatScoreEnabledSelector,
  encounterNamingConventionSelector,
  payerDashboardEnabledSelector,
  visitSequenceReportingEnabledSelector,
  etiologyVerifiedQuestionEnabledSelector
} from "src/selectors/ui_config_selectors/global";

import { createPayersForRevisionsSelector } from "src/selectors/data_selectors/payers/create_payers_for_revisions_selector";

import { assessmentVersionSelector } from "src/selectors/config_selectors";

import { copyAssessmentToClipboardEnabledSelector } from "src/selectors/ui_config_selectors/patients";

import { createLastFacilityQueryForPatientIdSelector } from "src/selectors/api_selectors/global/patients";

import { localizationDictionaryForSeriesIdSelector } from "src/selectors/section_selectors/global";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

// Actions
import { create } from "src/actions/flashMessageActions";
import {
  loadEventsAction,
  loadFacilitiesAction
} from "src/actions/data_actions";

// Legacy Selectors
import seriesTrendSelectors from "src/selectors/seriesTrendSelectors";

import { pdfExportEnabledSelector } from "src/selectors/section_selectors/patients";
import { patientTypeSelector } from "src/selectors/config_selectors";
import { patientsSectionSelector } from "src/selectors/section_selectors/patients";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";

//ui processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const setRevisionToEdit = SingleValueReduxUtil.createActionCreators(
  "revisionEnabledToBeEdited"
);

function mapStateToProps(state, ownProps) {
  // legacy selector
  const getSeriesTrend = seriesTrendSelectors.findEntity(
    () => ownProps.revision.attributes.seriesId
  );

  const patient = createPatientForIdSelector(ownProps.patientId)(state);

  const patientLastFacilityId = get(
    patient,
    "attributes.lastFacilityId",
    undefined
  );

  const enableRevisionToBeEditedSelector = SingleValueReduxUtil.createSelectors(
    "revisionEnabledToBeEdited",
    patientsSectionSelector
  );

  const revisions = [ownProps.revision];
  const series = createSeriesForRevisionSelector(ownProps.revision)(state);

  return {
    series,
    assessmentAnswer: createLatestAssessmentAnswerForRevisionSelector(
      ownProps.revision
    )(state),
    payersForRevision: createPayersForRevisionsSelector({ revisions })(state),
    frame: createDefaultFrameForRevisionSelector(ownProps.revision)(state),
    patient,
    progressSchemas: createProgressTabSchemasForAssessmentAnswerSelector(
      ownProps.assessmentAnswer
    )(state),
    treatmentSchemas: createTreatmentTabSchemasForAssessmentAnswerSelector(
      ownProps.assessmentAnswer
    )(state),
    attributesSchemas: createAttributesTabSchemasForAssessmentAnswerSelector(
      ownProps.assessmentAnswer
    )(state),
    describeSchemas: createDescriptionAssessmentSchemaForAssessmentAnswerSelector(
      ownProps.assessmentAnswer
    )(state),
    revisionIsFirstInSeries: createRevisionIsFirstInSeriesSelector(
      ownProps.revision
    )(state),
    pdfExportEnabled: pdfExportEnabledSelector(state),
    copyAssessmentToClipboardEnabled: copyAssessmentToClipboardEnabledSelector(
      state
    ),
    stringifiedAssessmentJSON: createStringifiedAssessmentJSONDataSelector(
      ownProps.revision
    )(state),
    seriesTrend: getSeriesTrend(state),
    patientType: patientTypeSelector(state),
    user: currentUserSelector(state),
    latestSignedEvent: eventsResourceSelectors.createDataForContextSelector(
      createLatestSignedEventForRevisionIdContextSelector({
        revisionId: ownProps.revision.id
      })
    )(state),
    latestLockedEvent: eventsResourceSelectors.createDataForContextSelector(
      createLatestLockedEventForRevisionIdContextSelector({
        revisionId: ownProps.revision.id
      })
    )(state),
    isAutodepth: isLatestRegionForRevisionAutodepth(ownProps.revision)(state),
    woundNumbersEnabled: woundNumbersEnabledSelector(state),
    branding: brandingSelector(state),
    brandedLogoPng: brandedLogoPngSelector(state),
    patientLastFacility: facilitiesResourceSelectors.createDataForIdSelector(
      patientLastFacilityId
    )(state),
    historyCauseEnabled: historyCauseEnabledSelector(state),
    hprScoreEnabled: hprScoreEnabledSelector(state),
    bwatScoreEnabled: bwatScoreEnabledSelector(state),
    encounterNamingConvention: encounterNamingConventionSelector(state),
    payerDashboardEnabled: payerDashboardEnabledSelector(state),
    visitSequenceEnabled: visitSequenceReportingEnabledSelector(state),
    assessmentVersion: assessmentVersionSelector(state),
    isRevisionBeingEdited:
      enableRevisionToBeEditedSelector.fieldSelector(state) ==
      ownProps.revisionId,
    schemaLocalizationDictionary: localizationDictionaryForSeriesIdSelector(
      series.id
    )(state),
    etiologyVerifiedQuestionEnabled: etiologyVerifiedQuestionEnabledSelector(
      state
    ),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showPdfErrorView() {
      dispatch(
        create("Sorry, there was an error downloading your export", "error")
      );
    },
    showPdfTimeoutErrorMessage() {
      dispatch(create("PDF download timeout. Please refresh page", "error"));
    },
    sendEnableRevisionToBeEditedAction(revisionId) {
      dispatch(setRevisionToEdit.setValue(revisionId));
    }
  };
}

// TO DO: Replace once lastFacilityId implemented and two containers are not needed #lastFacilityIdDelay
export const RevisionWoundDetailsHeaderContainer = flowRight(
  withRouter,
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestSignedEventForRevisionIdContextSelector,
    createQuerySelector: createLatestSignedEventForRevisionIdQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestLockedEventForRevisionIdContextSelector,
    createQuerySelector: createLatestLockedEventForRevisionIdQuerySelector
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(RevisionWoundDetailsHeaderComponent);

export const RevisionWoundDetailsHeaderWithPatientLastFacilityContainer = flowRight(
  withRouter,
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestSignedEventForRevisionIdContextSelector,
    createQuerySelector: createLatestSignedEventForRevisionIdQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestLockedEventForRevisionIdContextSelector,
    createQuerySelector: createLatestLockedEventForRevisionIdQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadFacilitiesAction,
    resourceSelectors: facilitiesResourceSelectors,
    createQuerySelector: createLastFacilityQueryForPatientIdSelector,
    createContextSelector: createLastFacilityQueryForPatientIdSelector
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(RevisionWoundDetailsHeaderComponent);
