import { createSelector } from "reselect";

// Payload generator
import { createGetPayload } from "src/redux_resources_v1";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api query for the first page for revision comparison
 * @param {Object} props
 * @param {string} props.revision
 * @returns {function} selector
 */
export function createLatestAssessmentAnswerForRevisionQuerySelector({
  revision
}) {
  return createSelector(() => {
    return buildQueryString(
      createGetPayload({
        filter: {
          resource_id: revision.id,
          resource_type: "Revision"
        },
        page: {
          pageNumber: 0,
          limit: 1
        }
      })
    );
  });
}
