import React from "react";

export class DownArrowIconComponent extends React.Component {
  render() {
    return (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.99264 12.1636C5.28553 12.4565 5.76041 12.4565 6.0533 12.1636L10.8263 7.39068C11.1192 7.09778 11.1192 6.62291 10.8263 6.33002C10.5334 6.03712 10.0585 6.03712 9.76561 6.33002L5.52297 10.5727L1.28037 6.33001C0.987474 6.03712 0.512574 6.03712 0.219674 6.33001C-0.0732259 6.62291 -0.0732259 7.09778 0.219674 7.39068L4.99264 12.1636ZM4.77297 0L4.77297 11.6333H6.27297L6.27297 0H4.77297Z"
          fill="#7B7D87"
        />
      </svg>
    );
  }
}
