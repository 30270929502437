import { adminActionTypes } from "src/action_types/section_action_types";

/**
 * Action to select user table page limit
 * @param {number} pageLimit selected table page number
 * @return {Object} Action
 */

export function selectUserTablePageLimitAction(pageLimit) {
  return {
    type: adminActionTypes.selectUserTablePageLimit,
    payload: pageLimit
  };
}
