import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export class NoResultsTableRowComponent extends PureComponent {
  static propTypes = {
    numberOfColumns: PropTypes.number.isRequired
  };

  render = () => {
    return (
      <tr>
        <td colSpan={this.props.numberOfColumns}>No Results Found</td>
      </tr>
    );
  };
}
