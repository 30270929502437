import { createSelector } from "reselect";

//Selectors
import {
  selectedFacilityIdsSelector,
  selectedUserTablePageNumberSelector,
  selectedUserTablePageLimitSelector,
  userNameSearchStringSelector,
  adminSectionSelector
} from "src/selectors/section_selectors/admin";

//UI Processors
import SingleValue from "src/ui_processors/single_value";

const selectedUserRoleIdSelector = SingleValue.createSelectors(
  "selectedUserRoleId",
  adminSectionSelector
);

const selectedUserStateSelector = SingleValue.createSelectors(
  "selectedUserState",
  adminSectionSelector
);

/**
 * Selector creator to return the payload selector for selected filters
 */

export const createUsersTablePayloadSelector = () => {
  return createSelector(
    selectedFacilityIdsSelector,
    selectedUserTablePageNumberSelector,
    selectedUserTablePageLimitSelector,
    userNameSearchStringSelector,
    selectedUserRoleIdSelector.fieldSelector,
    selectedUserStateSelector.fieldSelector,
    (
      facility_id,
      page_number,
      page_limit,
      name,
      selectedRoleId,
      selectedState
    ) => {
      const queryParams = {
        filter: {
          patient_users: "false",
          ...(facility_id.length && {
            facility_id: facility_id.join(",")
          }),
          name,
          ...(selectedRoleId && { role_id: selectedRoleId }),
          ...(selectedState && { state: selectedState })
        },
        page: {
          offset: page_number * page_limit,
          limit: page_limit
        },
        include: "organizations,facilities,roles,facility_units"
      };
      return queryParams;
    }
  );
};
