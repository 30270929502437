import moment from "moment";

/**
 * Get graph images
 * @param {string} dataUrl the image url
 * @return {Object}
 */
export function getChartImage(dataUrl) {
  return {
    stack: [
      {
        image: dataUrl,
        width: 200
      },
      {
        text: `As of ${new moment().format("MMM D, YYYY")}`,
        fontSize: 10
      }
    ],
    alignment: "center"
  };
}
