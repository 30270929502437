

export const woundTypesV5LHC = {
  //Wound Types
  abrasion: "Abrasion",
	abscess: "Abscess",
	arterial: "Arterial Ulcer",
	blister: "Blister",
	bruise: "Bruise",
  burn: "Burn",
	cancerLesion: "Cancer Lesion",
	chronicUlcer: "Chronic Ulcer",
	diabetic: "Diabetic Ulcer",
	fistula: "Fistula",
	friction: "Friction",
	hematoma: "Hematoma",
  hSuppurtiva: "Hidradenitis Suppurativa",
	incision: "Incision",
	inflammatory: "Inflammatory",
	laceration: "Laceration",
	malignant: "Malignant",
	masd: "MASD",
	mixedVenousArterial: "Mixed arterial & venous",
  mole: "Mole",
	necrotizingFasciitis: "Necrotizing Fasciitis",
	neuropathic: "Neuropathic",
	lesion: "Open Lesion",
  pending: "Pending wound evaluation by RN or PT",
	pressureUlcer: "Pressure injury",
	pressureDeviceRelated: "Pressure - Medical Device Related Pressure Injury",
	radiation: "Radiation",
	rash: "Rash",
	shearing: "Shearing",
	skinGraft: "Skin graft",
	skinTear: "Skin Tear",
	softTissueNecrosis: "Soft Tissue Necrosis",
	surgical: "Surgical Incision",
	traumatic: "Traumatic",
	vasculitis: "Vasculitis",
	venous: "Venous Ulcer",
	other: "Other",
  
  //Sub Types
  type1None: "No skin loss",
  type2Partial: "Partial flap loss",
  type3Total: "Total flap loss",

  chemical: "Chemical",
  cold: "Cold",
  electrical: "Electrical",
  frication: "Friction",
  radiation: "Radiation",
  thermal: "Thermal",

  one: "Stage 1",
  two: "Stage 2",
  three: "Stage 3",
  four: "Stage 4",
  dti: "Deep Tissue Injury",
  mucosalMembrane: "Mucosal Membrane",
  unstageable: "Unstageable",

  dermatitis: "Dermatitis",
  peristomal: "Peristomal Moisture Associated Skin Damage",
  periwound: "Periwound Moisture Associated Skin Damage",
  iad: "IAD Incontinence Associated Dermatitis",
  itd: "ITD Intertriginous Dermatitis",
  
  ischemic: "Ischemic",
  neuroIschemic: "Neuro-ischemic",
  neuropathic: "Neuropathic",

  colocutaneous: "Colocutaneous",
  enterocutaneous: "Enterocutaneous",

  donorSite: "Donor site",
  graphSite: "Graph site",

  cecostomy: "Cecostomy",
  esophagostomy: "Esophagostomy",
  jejunostomy: "Jejunostomy",
  mucousFistula: "Mucous Fistula",

  ////Applies to all notSet keys
  notSet: "Not Set"
};
