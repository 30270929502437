// @flow
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Map, List } from "immutable";
import DatePicker from "react-datepicker";
import Moment from "moment";

// Styles
import styles from "./style.module.less";

//Selectors
import { riskAssessmentTypesSelector } from "src/selectors/section_selectors/risk";

import { Button } from "@sw/ui-kit";

import riskSelectors from "@sw/selectors/propertySelectors/risks";
import locationRiskSelectors from "@sw/selectors/locationRiskSelectors";
import patientSelectors from "@sw/selectors/patientSelectors";

import {
  selectedLocationIdsSelector,
  selectedLocationsSelector
} from "src/selectors/section_selectors/risk";
import { selectedOrganizationSelector } from "src/selectors/data_selectors/organizations";

import riskActions from "@sw/actions/propertyActions/risks";
import locationRiskActions from "@sw/actions/locationRiskActions";

import AdminComponent from "../Braden/adminComponent";

import ClinicalComponent from "../Braden/clinicalComponent";
import ProgressChartComponent from "../Braden/progressChartComponent";

import isEqual from "lodash.isequal";

import "../Braden/style.less";

type $dispatchProps = {
  loadPatientsForHighRisk: Function,
  loadPatientsForMediumRisk: Function,
  loadPatientsForLowRisk: Function,
  goToBraden: Function,
  dispatch: $$dispatch
};

class RiskComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentOrganization: PropTypes.any,
    selectedLocationIds: PropTypes.array.isRequired,
    selectedLocations: PropTypes.array.isRequired,
    locationAggregateRisk: PropTypes.object,
    locationPatientHighRisk: PropTypes.object,
    locationPatientVeryHighRisk: PropTypes.object,
    locationPatientMediumRisk: PropTypes.object,
    locationPatientLowRisk: PropTypes.object,
    locationPatientNoRisk: PropTypes.object,
    locationPatientNoneRisk: PropTypes.object,
    locationSparkAggregateRisk: PropTypes.object,
    locationChartRisk: PropTypes.object,
    clinicalRiskAggregate: PropTypes.any,
    riskAssessmentTypes: PropTypes.array
  };

  state = {
    view: "clinical",
    clinicalLoaded: false,
    adminLoaded: false,
    startDate: Moment()
  };

  componentDidMount = () => {
    if (this.props.selectedLocationIds.length) {
      this.fetchData(this.props.selectedLocationIds, this.state.startDate);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.currentOrganization && nextProps.currentOrganization) {
      this.loadLocationsForOrganization(nextProps.currentOrganization);
    } else if (
      !isEqual(this.props.selectedLocationIds, nextProps.selectedLocationIds)
    ) {
      this.setState({ clinicalLoaded: false, adminLoaded: false });
      if (nextProps.selectedLocationIds.length) {
        this.fetchData(nextProps.selectedLocationIds, this.state.startDate);
      }
      this.handleView();
    }
  };

  fetchData = (locationIds, startDate) => {
    if (!locationIds.length) {
      return Promise.resolve();
    }
    const {
      loadPatientsForHighRisk,
      loadPatientsForMediumRisk,
      loadPatientsForLowRisk,
      loadLocationRiskAggregates,
      loadAggregates,
      loadLocationRiskCharts,
      loadCharts
    } = this.props;
    let startTime = new Moment("1900-01-01").toJSON();
    let endTime = new Moment(startDate).endOf("day").toJSON();
    let adminChartTime = new Moment(startDate).subtract(4, "w").toJSON();
    let clinicalChartTime = new Moment(startDate)
      .subtract(16, "weeks")
      .toJSON();

    if (this.state.view === "admin") {
      this.setState({ adminLoaded: false });

      Promise.all([
        loadLocationRiskAggregates(locationIds, startTime, endTime),
        loadLocationRiskCharts(locationIds, adminChartTime, endTime)
      ]).then(() => {
        this.setState({ adminLoaded: true });
      });
    } else {
      this.setState({ clinicalLoaded: false });
      Promise.all([
        loadAggregates(locationIds, startTime, endTime),
        loadCharts(locationIds, clinicalChartTime, endTime),
        loadPatientsForHighRisk(locationIds, startTime, endTime),
        loadPatientsForMediumRisk(locationIds, startTime, endTime),
        loadPatientsForLowRisk(locationIds, startTime, endTime)
      ]).then(() => {
        this.setState({ clinicalLoaded: true });
      });
    }
  };

  loadLocationsForOrganization = organization => {
    this.props.loadLocations(organization.id).then(() => {
      this.fetchData(this.props.selectedLocationIds, this.state.startDate);
    });
  };
  convertLocationRiskAggregate = locations => {
    const { locationAggregateRisk } = this.props;
    return locations.reduce((finalResult, { id }) => {
      finalResult[id] = locationAggregateRisk
        .getIn([id, "nortonPlus", "aggregates"], new Map())
        .toJS();
      return finalResult;
    }, {});
  };
  getLocationRiskAggregate = () => {
    const { selectedLocations } = this.props;
    if (selectedLocations && selectedLocations.length) {
      return this.convertLocationRiskAggregate(selectedLocations);
    }
  };
  convertLocationRiskChart = locations => {
    const { locationAggregateRisk } = this.props;
    return locations.reduce((finalResult, { id }) => {
      finalResult[id] = locationAggregateRisk
        .getIn([id, "nortonPlus", "chart"], new List())
        .toJS();
      return finalResult;
    }, {});
  };
  getLocationRiskCharts = () => {
    const { selectedLocations } = this.props;
    if (selectedLocations && selectedLocations.length) {
      return this.convertLocationRiskChart(selectedLocations);
    }
  };

  handleDatePicker = date => {
    this.setState({
      startDate: date
    });
    this.fetchData(this.props.selectedLocationIds, date);
  };

  handleStateView = event => {
    this.setState(
      {
        view: event.target.value
      },
      () => {
        this.fetchData(this.props.selectedLocationIds, this.state.startDate);
      }
    );
  };

  handleView = () => {
    const {
      highRiskPatients,
      mediumRiskPatients,
      lowRiskPatients,
      loadPatientsForHighRisk,
      loadPatientsForMediumRisk,
      loadPatientsForLowRisk,
      risk
    } = this.props;

    const tabs = {
      high: {
        label: "High",
        load: loadPatientsForHighRisk
      },
      medium: {
        label: "Medium",
        load: loadPatientsForMediumRisk
      },
      low: {
        label: "Low",
        load: loadPatientsForLowRisk
      }
    };
    if (this.state.view === "admin") {
      return (
        <AdminComponent
          riskName="Norton Plus"
          buckets={tabs}
          loaded={this.state.adminLoaded}
          locations={this.props.selectedLocations}
          locationAggregateRisk={this.getLocationRiskAggregate()}
          locationSparkAggregateRisk={this.getLocationRiskCharts()}
          startDate={new Moment(this.state.startDate)}
        />
      );
    } else if (this.state.view === "clinical") {
      const highMeta =
        risk.getIn(["nortonPlus", "patients", "high"]) || new Map();
      const mediumMeta =
        risk.getIn(["nortonPlus", "patients", "medium"]) || new Map();
      const lowMeta =
        risk.getIn(["nortonPlus", "patients", "low"]) || new Map();
      const locationPatientHighRisk = {
        patients: highRiskPatients.toJS(),
        meta: highMeta.toJS ? highMeta.toJS() : {}
      };
      const locationPatientMediumRisk = {
        patients: mediumRiskPatients.toJS(),
        meta: mediumMeta.toJS ? mediumMeta.toJS() : {}
      };
      const locationPatientLowRisk = {
        patients: lowRiskPatients.toJS(),
        meta: lowMeta.toJS ? lowMeta.toJS() : {}
      };

      return (
        <ClinicalComponent
          riskName="Norton Plus"
          startingRisk="high"
          locationIds={this.props.selectedLocationIds}
          chartData={risk.nortonPlus.charts.toJS()}
          aggregateData={risk.nortonPlus.aggregates.toJS()}
          loaded={this.state.clinicalLoaded}
          startDate={new Moment(this.state.startDate)}
          tabs={tabs}
          patients={{
            high: locationPatientHighRisk,
            medium: locationPatientMediumRisk,
            low: locationPatientLowRisk
          }}
        />
      );
    } else {
      const highMeta =
        risk.getIn(["nortonPlus", "patients", "high"]) || new Map();
      const mediumMeta =
        risk.getIn(["nortonPlus", "patients", "medium"]) || new Map();
      const lowMeta =
        risk.getIn(["nortonPlus", "patients", "low"]) || new Map();
      const locationPatientHighRisk = {
        patients: highRiskPatients.toJS(),
        meta: highMeta.toJS ? highMeta.toJS() : {}
      };
      const locationPatientMediumRisk = {
        patients: mediumRiskPatients.toJS(),
        meta: mediumMeta.toJS ? mediumMeta.toJS() : {}
      };
      const locationPatientLowRisk = {
        patients: lowRiskPatients.toJS(),
        meta: lowMeta.toJS ? lowMeta.toJS() : {}
      };

      return (
        <ProgressChartComponent
          riskName="Norton Plus"
          startingRisk="high"
          locationIds={this.props.selectedLocationIds}
          chartData={risk.nortonPlus.charts.toJS()}
          aggregateData={risk.nortonPlus.aggregates.toJS()}
          loaded={this.state.clinicalLoaded}
          startDate={new Moment(this.state.startDate)}
          tabs={tabs}
          patients={{
            high: locationPatientHighRisk,
            medium: locationPatientMediumRisk,
            low: locationPatientLowRisk
          }}
        />
      );
    }
  };

  toggleClass = value => {
    if (this.state.view === value) {
      return `active btn btn-default ${
        styles["active-button-default-no-margins"]
      }`;
    } else {
      return `btn btn-default ${styles["button-default-no-margins"]}`;
    }
  };

  renderControlNavbar() {
    const { goToBraden, riskAssessmentTypes } = this.props;
    const assessmentTypeButtons = {
      braden: {
        onClick: goToBraden,
        children: "Braden",
        disabled: false,
        className: `btn btn-default ${styles["button-default-no-margins"]}`
      },
      nortonPlus: {
        className: `active btn btn-default ${
          styles["active-button-default-no-margins"]
        }`,
        children: "Norton Plus",
        disabled: true
      }
    };

    return (
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">Risk Status</h1>
        </div>

        <form className="navbar-form navbar-right">
          <div className="btn-group control-navbar-element" role="group">
            {riskAssessmentTypes.length > 1 &&
              riskAssessmentTypes.map(assessmentType => {
                const buttonConfig = assessmentTypeButtons[assessmentType];
                return (
                  <Button
                    key={assessmentType}
                    name="default"
                    {...buttonConfig}
                  />
                );
              })}
          </div>

          <div className="btn-group control-navbar-element" role="group">
            <button
              type="button"
              value="clinical"
              onClick={this.handleStateView}
              className={this.toggleClass("clinical")}
            >
              Residents
            </button>
            <button
              type="button"
              value="progress"
              onClick={this.handleStateView}
              className={this.toggleClass("progress")}
            >
              Chart
            </button>
            <button
              type="button"
              value="admin"
              onClick={this.handleStateView}
              className={this.toggleClass("admin")}
            >
              Locations
            </button>
          </div>

          <span className="control-navbar-element">
            <DatePicker
              dateFormat="MMMM D, YYYY"
              selected={this.state.startDate}
              onChange={this.handleDatePicker}
              className="form-control"
            />
          </span>
        </form>
      </nav>
    );
  }

  render = () => {
    return (
      <div className="risk-dashboard">
        {this.renderControlNavbar()}
        {this.handleView()}
      </div>
    );
  };
}

export function mapDispatchToProps(dispatch: $$dispatch): $dispatchProps {
  return {
    dispatch,
    loadLocations(organizationId) {
      return dispatch(riskActions.loadLocations(organizationId, "nortonPlus"));
    },
    loadLocationRiskCharts(locations, startTime, endTime) {
      return dispatch(
        locationRiskActions.loadNortonPlusCharts(locations, startTime, endTime)
      );
    },
    loadLocationRiskAggregates(locations, startTime, endTime) {
      return dispatch(
        locationRiskActions.loadNortonPlusAggregates(
          locations,
          startTime,
          endTime
        )
      );
    },
    loadCharts(locations, startTime, endTime) {
      return dispatch(
        riskActions.loadNortonPlusCharts(locations, startTime, endTime)
      );
    },
    loadAggregates(locations, startTime, endTime) {
      return dispatch(
        riskActions.loadNortonPlusAggregates(locations, startTime, endTime)
      );
    },
    loadPatientsForHighRisk(locations, startTime, endTime, cursor) {
      return dispatch(
        riskActions.loadNortonPlusPatients(
          "high",
          locations,
          startTime,
          endTime,
          cursor
        )
      );
    },
    loadPatientsForMediumRisk(locations, startTime, endTime, cursor) {
      return dispatch(
        riskActions.loadNortonPlusPatients(
          "medium",
          locations,
          startTime,
          endTime,
          cursor
        )
      );
    },
    loadPatientsForLowRisk(locations, startTime, endTime, cursor) {
      return dispatch(
        riskActions.loadNortonPlusPatients(
          "low",
          locations,
          startTime,
          endTime,
          cursor
        )
      );
    },
    goToBraden() {
      return dispatch(riskActions.goTo("braden"));
    }
  };
}

function mapStateToProps(state) {
  const lowRiskPatients = patientSelectors.getEntities(
    riskSelectors.getRelatedEntityIds("lowPatientsNorton")
  );
  const mediumRiskPatients = patientSelectors.getEntities(
    riskSelectors.getRelatedEntityIds("mediumPatientsNorton")
  );
  const highRiskPatients = patientSelectors.getEntities(
    riskSelectors.getRelatedEntityIds("highPatientsNorton")
  );
  const locationAggregateRisk = locationRiskSelectors.getEntityData();

  const structuredSelectors = createStructuredSelector({
    risk: riskSelectors.findProperty(),
    lowRiskPatients,
    mediumRiskPatients,
    highRiskPatients,
    locationAggregateRisk
  });

  return {
    ...structuredSelectors(state),
    riskAssessmentTypes: riskAssessmentTypesSelector(state),
    selectedLocationIds: selectedLocationIdsSelector(state),
    selectedLocations: selectedLocationsSelector(state),
    currentOrganization: selectedOrganizationSelector(state)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskComponent);
