// Actions
import { createLoadDataAction } from "../create_load_data_action";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { seriesTrendsApi } from "src/api/series_trends";

export const seriesTrendsResourceActions = createResourceActions(
  resourceNames.seriesTrends
);

export const loadSeriesTrendsAction = createLoadDataAction({
  resourceName: resourceNames.seriesTrends,
  api: seriesTrendsApi,
  responseNormalizer: (data, { query }) => {
    const seriesId = query.substring(0, query.indexOf("/"));
    return {
      [resourceNames.seriesTrends]: {
        [seriesId]: {
          id: seriesId,
          type: resourceNames.seriesTrends,
          attributes: {
            ...data.data
          }
        }
      }
    };
  }
});
