import { createSelector } from "reselect";

// Selectors
import {
  selectedLocationIdsSelector,
  selectedEvaluationTypeSelector,
  alertsSectionSelector
} from "src/selectors/section_selectors/alerts";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const alertsTablePageLimitSelector = SingleValueReduxUtil.createSelectors(
  "alertsTablePageLimit",
  alertsSectionSelector
);

export function createFirstPagePayloadSelector() {
  return createSelector(
    alertsTablePageLimitSelector.fieldSelector,
    selectedLocationIdsSelector,
    selectedEvaluationTypeSelector,
    (pageLimit, selectedLocationIds, selectedEvaluationType) => {
      return {
        filter: {
          ...(selectedEvaluationType && {
            evaluation_type: selectedEvaluationType
          }),
          non_resolved_only: "true",
          ...(selectedLocationIds &&
            selectedLocationIds.length && {
              latest_patient_location_id: selectedLocationIds.join(",")
            })
        },
        include: ["patient", "tripped_by_user"].join(","),
        page: {
          offset: 0,
          limit: pageLimit
        },
        sort: "-created_at"
      };
    }
  );
}
