import { connect } from "react-redux";

// Constants
import { sections } from "src/constants";

// Components
import { locationsFilterConfigSelector } from "src/selectors/ui_config_selectors/global";
import { SavedFilterVisualizationComponent } from "src/components/saved_filter_visualization_component";

// UI Processors
import { createSectionSelectors } from "src/ui_processors";

const sectionSelectors = createSectionSelectors(sections.evaluationListBraden);

function mapStateToProps(state) {
  // Locations
  const locationConfig = locationsFilterConfigSelector(state);
  const locationCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    locationConfig
  )(state);

  return {
    filterUiConfigs: [locationConfig],
    filterCheckedStates: [locationCheckedState]
  };
}

export const EvaluationListBradenSavedFilterVisualizationContainer = connect(
  mapStateToProps
)(SavedFilterVisualizationComponent);
