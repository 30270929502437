import { createSelector } from "reselect";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

//Configuration
import {
  acquiredConfigurationDefault,
  acquiredConfigurationV5Ashn
} from "src/constants/filters_configurations/wounds";

export const acquiredFilterConfigSelector = createSelector(
  assessmentVersionSelector,
  version => {
    if (version == "v5Ashn") return acquiredConfigurationV5Ashn;
    return acquiredConfigurationDefault;
  }
);
