import { createSelector } from "reselect";
import { payersResourceSelector } from "src/selectors/data_selectors";

export const payersFilterConfigSelector = createSelector(
  payersResourceSelector.dataSelector,
  payers => {
    let filterConfig = {
      filterName: "payers",
      filterValues: []
    };
    const payersArray = Object.values(payers);
    filterConfig.filterValues = payersArray.reduce((agg, payer) => {
      agg.push({
        key: "payer_id",
        value: payer.id,
        name: payer.attributes.name
      });
      return agg.sort(alphaticalOrder);
    }, []);
    return filterConfig;
  }
);

function alphaticalOrder(loc1, loc2) {
  return loc1.name < loc2.name ? -1 : 1;
}
