import qs from "qs";

/**
 * Get query parameter object from a url
 * @param {string} url
 * @returns {Object} query parameters
 */
export function getQueryParamsFromUrl(url) {
  if (url && /\?/.test(url)) {
    const queryString = url.split("?")[1];
    return qs.parse(queryString);
  }

  return {};
}
