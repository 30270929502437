import { createSelector } from "reselect";
import get from "lodash.get";

/**
 * Create set of ui selectors for a section eg. filter state
 * @param {string} sectionName the section name eg patients, wounds,survey etc
 * @returns {Object} object with selectors
 */

export function createSectionSelectors(sectionName) {
  /**
   * Selector to get the section by sectionName
   */
  const sectionSelector = createSelector(
    getSections,
    sections => get(sections, sectionName, null)
  );

  /**
   * Selector to get the section filter
   */
  const filtersSelector = createSelector(
    sectionSelector,
    section => get(section, "filters", null)
  );

  /**
   * Selector to get the section filter
   */
  const searchFiltersSelector = createSelector(
    sectionSelector,
    section => get(section, "searchFilters", null)
  );

  /**
   * Selector to get the start date in filters
   */
  const selectedStartDateFilterSelector = createSelector(
    filtersSelector,
    filters => {
      if (filters.length > 0) {
        const startDateFilter = filters.find(item => item.key === "startDate");
        if (startDateFilter) {
          return startDateFilter.value;
        }
      }
    }
  );

  /**
   * Selector to get the end date in filters
   */
  const selectedEndDateFilterSelector = createSelector(
    filtersSelector,
    filters => {
      if (filters.length > 0) {
        const endDateFilter = filters.find(item => item.key === "endDate");
        if (endDateFilter) {
          return endDateFilter.value;
        }
      }
    }
  );

  /**
   * Selector to get the end date in filters
   */
  const dateFilterSelector = createSelector(
    filtersSelector,
    filters => {
      if (filters.length > 0) {
        const endDateFilter = filters.find(item => item.key === "date");
        if (endDateFilter) {
          return endDateFilter.value;
        }
      }
    }
  );

  /**
   * Selector to return the checked state of a filter using a ui config and store filters
   */
  const createFilterCheckedStateSelector = config => {
    return createSelector(
      filtersSelector,
      filters => {
        return getCheckedState(config, filters);
      }
    );
  };

  /**
   * Selector to return the id of the selected saved filter
   */
  const selectedFilterIdSelector = createSelector(
    sectionSelector,
    section => {
      return get(section, "selectedFilterId", null);
    }
  );

  /**
   * Selector to return the state of the save filter button.
   * Returns true if filter is enabled and false if filter is disabled
   */
  const saveFilterButtonEnabledSelector = createSelector(
    selectedFilterIdSelector,
    filtersSelector,
    (selectedFilterId, filters) => {
      return shouldFilterButtonBeEnabled(selectedFilterId, filters);
    }
  );

  /**
   * Selector creator that returns a selector to get the state of an item in the section,
   * item can be a string (eg. "filters" or "revisionsToCompare.UniqueSriesId" for nested item)
   */
  const createSectionItemStateSelector = item => {
    return createSelector(
      sectionSelector,
      section => get(section, item, null)
    );
  };

  return {
    sectionSelector,
    filtersSelector,
    searchFiltersSelector,
    createFilterCheckedStateSelector,
    selectedFilterIdSelector,
    selectedStartDateFilterSelector,
    dateFilterSelector,
    selectedEndDateFilterSelector,
    saveFilterButtonEnabledSelector,
    createSectionItemStateSelector
  };
}

const shouldFilterButtonBeEnabled = (selectedFilterId, filters) => {
  return !selectedFilterId && validateFiltersAreSelected(filters);
};

const validateFiltersAreSelected = filters => {
  return filters && filters.length > 0;
};

// ------ Helper Functions ----- //
/**
 * Function to get "sections" from store
 */
const getSections = state => state.sections;

/**
 * Function to translate store filter to filter state object using a config object
 * @param {object} config config object that is used to generate ui checkboxes
 * @param {object} storeFilters filters object in store
 * @returns {Object} object representing filter state
 */
const getCheckedState = (config, storeFilters) => {
  //Go through each item in the filter values array of config,
  //check if item is present in the store filters and
  //return an object representing the checked state of the filter
  let storeFilterValues;
  if (
    config.filterName == "woundTypes" ||
    config.filterName == "locations" ||
    config.filterName == "evaluations" ||
    config.filterName == "clinicianOptions" ||
    config.filterName == "patientEvaluations" ||
    config.filterName == "payers" ||
    config.filterName == "visitFrequency"
  ) {
    const compoundFilter = storeFilters.find(
      filter => filter.key == config.filterName
    );
    storeFilterValues = get(compoundFilter, "value", []);
  } else {
    storeFilterValues = storeFilters;
  }

  return config.filterValues.reduce((agg, configFilterObject) => {
    const { key, value, children } = configFilterObject;

    //If config filter object has children, configure children object and call this function recursively
    if (children) {
      const childrenConfig = {
        filterValues: children
      };

      const childrenCheckedState = getCheckedState(
        childrenConfig,
        storeFilterValues
      );

      agg = {
        ...agg,
        ...childrenCheckedState
      };
    }

    // Get the relevant filter from store using filter name in config
    let storeFilter = storeFilterValues.find(filter => {
      return filter.key == key && filter.value == value;
    });

    //Find if the config filter object is present in the store filter
    let filterObject;

    if (storeFilter) {
      if (Array.isArray(storeFilter.value)) {
        filterObject = storeFilter.value.find(filterObject => {
          return filterObject.key == key && filterObject.value == value;
        });
      } else {
        filterObject =
          storeFilter.key == key && storeFilter.value == value
            ? storeFilter
            : undefined;
      }
    }

    //Config filter object is present in store filter
    if (filterObject && key) {
      agg[key] = { ...agg[key], [value]: "true" };
      //All children of config object is present in store filter
    } else if (
      children &&
      children.every(
        child =>
          (agg[child.key] && agg[child.key][child.value] === "true") ||
          (agg[child.value] && agg[child.value][child.value] === "true")
      )
    ) {
      if (key) {
        agg[key] = { ...agg[key], [value]: "true" };
      } else if (value) {
        agg[value] = { ...agg[value], [value]: "true" };
      }
      //Some children of config object is present or is partially selected in store filter
    } else if (
      children &&
      children.some(
        child =>
          (agg[child.key] &&
            ["true", "partial"].includes(agg[child.key][child.value])) ||
          (agg[child.value] &&
            ["true", "partial"].includes(agg[child.value][child.value]))
      )
    ) {
      if (key) {
        agg[key] = { ...agg[key], [value]: "partial" };
      } else if (value) {
        agg[value] = { ...agg[value], [value]: "partial" };
      }
      //No key in config, identifies a checkbox that is purely representational eg. surgicalOther and surgicalClosure checkboxes in wound types config
    } else if (!filterObject && !key && value) {
      agg[value] = { ...agg[value], [value]: "false" };
      //Filter object is not present in store filter
    } else if (!filterObject && key) {
      agg[key] = { ...agg[key], [value]: "false" };
    }
    return agg;
  }, {});
};
