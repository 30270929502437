import { combineReducers } from "redux";

//Section Reducer Factory
import {
  createSectionFiltersReducer,
  createSectionSelectedFilterIdReducer,
  createSectionSearchFiltersReducer
} from "src/ui_processors/global/reducer_creators";

import SingleValue from "src/ui_processors/single_value";
import NestedValue from "src/ui_processors/nested_value";

// Constants
import { sections } from "src/constants";
import Moment from "moment";

const defaultValue = {
  startAt: new Moment().subtract(7, "d").startOf("day"),
  endAt: new Moment().endOf("day"),
  unitRange: "pastWeek"
};

export const complianceSectionReducers = combineReducers({
  filters: createSectionFiltersReducer(sections.compliance),
  searchFilters: createSectionSearchFiltersReducer(sections.compliance),
  selectedFilterId: createSectionSelectedFilterIdReducer(sections.compliance),
  selectedTab: SingleValue.createReducer(
    "selectedTab",
    "inProgressEvaluations"
  ),
  dateRangeValue: NestedValue.createReducer("dateRangeValue", defaultValue)
});
