import { createSelector } from "reselect";

//Selectors
import {
  alertsEnabledSelector,
  evaluationListEnabledSelector,
  healingIndexDashboardEnabledSelector
} from "src/selectors/ui_config_selectors/global";

//Constants
import { alertsSectionTabs } from "src/constants";

// Translations
import i18n from "src/translations";

/**
 * Memoized selector to return all the tabs to be rendered in the alerts section
 */
export const alertsSectionTabsSelector = createSelector(
  alertsEnabledSelector,
  evaluationListEnabledSelector,
  healingIndexDashboardEnabledSelector,
  (alertsEnabled, evaluationListEnabled, healingIndexEnabled) => {
    let tabs = [];
    if (alertsEnabled) {
      tabs.push({
        value: alertsSectionTabs.alerts,
        title: `${i18n.t("headers.alerts")}`
      });
    }
    if (evaluationListEnabled) {
      tabs.push({
        value: alertsSectionTabs.evaluationList,
        title: `${i18n.t("headers.evaluationList")}`
      });
    }
    if (healingIndexEnabled) {
      tabs.push({
        value: alertsSectionTabs.woundRisk,
        title: `${i18n.t("headers.woundRisk")}`
      });
    }

    return tabs;
  }
);
