import { createSelector } from "reselect";
import i18n from "src/translations";
import {
  hasSignWoundRevisionPermissionSelector,
  hasLockWoundRevisionsPermissionSelector
} from "src/selectors/ui_config_selectors/patients";
import { createNestedAssessmentSchemasForAssessmentAnswerSelector } from "src/selectors/data_selectors";
import { createRevisionIsFirstInSeriesSelector } from "src/selectors/data_selectors/revisions/create_revision_is_first_in_series_selector";
import { getMissingRequiredFields } from "src/models/assessment_answers/get_missing_required_fields";
import { createListStringFromArray } from "src/utils/format_string_list_from_array";
import { containsRequiredFieldsForAssessmentAnswers } from "src/utils/required_fields";

export function createSignAndLockTooltipSelector({
  assessmentAnswer,
  revision,
  schemas
}) {
  return createSelector(
    hasSignWoundRevisionPermissionSelector,
    hasLockWoundRevisionsPermissionSelector,
    createNestedAssessmentSchemasForAssessmentAnswerSelector(assessmentAnswer),
    createRevisionIsFirstInSeriesSelector(revision),
    (
      canSignWoundRevisions,
      canLockWoundRevisions,
      nestedAssessmentSchemas,
      revisionIsFirstInSeries
    ) => {
      let signAndLockTooltipText = [];
      const assessmentAnswerAnswersJson =
        assessmentAnswer.attributes.answersJson;
      const missingRequiredFields = getMissingRequiredFields(
        nestedAssessmentSchemas,
        assessmentAnswer,
        revisionIsFirstInSeries
      );
      if (
        !containsRequiredFieldsForAssessmentAnswers(
          schemas,
          assessmentAnswerAnswersJson,
          revisionIsFirstInSeries
        )
      ) {
        const requiredPermissionsText = i18n.t(
          "text.revisionButtonTooltips.requiredPermissionsTextToSignAndLock"
        );
        signAndLockTooltipText.push(requiredPermissionsText);
      }
      if (!canSignWoundRevisions || !canLockWoundRevisions) {
        const missingPermissionsText = i18n.t(
          "text.revisionButtonTooltips.missingPermissions"
        );
        signAndLockTooltipText.push(missingPermissionsText);
      }
      if (missingRequiredFields.length > 0) {
        let missingRequiredFieldsText = i18n.t(
          "text.revisionButtonTooltips.missingRequiredInformation"
        );
        missingRequiredFieldsText += createListStringFromArray(
          missingRequiredFields
        );
        signAndLockTooltipText.push(missingRequiredFieldsText);
      }
      return signAndLockTooltipText.length > 0
        ? signAndLockTooltipText.join(" ")
        : "";
    }
  );
}
