import get from "lodash.get";

export const mergeSchemasIntoSingleSchema = (mainSchema, secondarySchemas) => {
  if (!mainSchema || !Object.keys(mainSchema).length) {
    return [];
  } else if (!secondarySchemas || !secondarySchemas.length) {
    return get(mainSchema, "attributes.schemaJson.questions", []);
  }

  const mainQuestions = get(mainSchema, "attributes.schemaJson.questions", []);
  const mainQuestionNames = mainQuestions.map(question => question.name);
  let mainWoundTypeQuestion = mainQuestions.find(
    question => question.name === "woundType"
  );

  if (!mainWoundTypeQuestion) return [];

  const mainWoundTypeQuestionTypes = mainWoundTypeQuestion.enumConstants;

  let secondaryWoundTypes = [];
  let secondaryQuestions = [];

  secondarySchemas.forEach(schema => {
    const questions = get(schema, "attributes.schemaJson.questions", []);
    const internalSecondaryWoundTypeObject = questions.find(
      question => question.name === "woundType"
    );
    const internalSecondaryWoundTypes = internalSecondaryWoundTypeObject
      ? internalSecondaryWoundTypeObject.enumConstants
      : [];
    const otherQuestions = questions.filter(
      question =>
        question.name !== "woundType" &&
        !mainQuestionNames.includes(question.name)
    );
    secondaryWoundTypes = [
      ...internalSecondaryWoundTypes,
      ...secondaryWoundTypes
    ];
    secondaryQuestions = [...secondaryQuestions, ...otherQuestions];
  });

  secondaryWoundTypes = [...new Set(secondaryWoundTypes)];
  secondaryQuestions = createSetOfQuestions(secondaryQuestions);

  let mixedSchemasQuestions = [];
  mainWoundTypeQuestion.enumConstants = [
    ...new Set([...mainWoundTypeQuestionTypes, ...secondaryWoundTypes])
  ];
  mixedSchemasQuestions = [...mainQuestions, ...secondaryQuestions];

  return mixedSchemasQuestions;
};

// ALGORITHM
// Take main policy schema
// For each secondary schema, compare to main schema and add any woundtype objects that don't exist in the main schema
// then create hierarchy from new schema.

// create array of wound types for main schema
// create array of wound types for secondary schemas (use set)
// find all the wound types not included in the main schema
// append them to main schema wound types

// create array of questions for main schema
// create array of unique questions for secondary schemas (use set)
// find all the questions not included in the main schema
// append them to main schema
//

//assumption: the schema policy has all the subtypes (enum constants) needed

/**
Removes any duplicate questions 
*/
export const createSetOfQuestions = questions => {
  let finalArraySet = [];
  questions.forEach(question => {
    if (
      !finalArraySet.find(arrayItem => {
        return arrayItem.name === question.name;
      })
    ) {
      finalArraySet.push(question);
    }
  });
  return finalArraySet;
};
