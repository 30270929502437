import React from "react";

//Styles
import styles from "./style.module.less";

export const woundThumbnailFormatter = cell => {
  return (
    <div
      className={styles["wound-image"]}
      style={{
        backgroundImage: `url('${cell}')`
      }}
    />
  );
};
