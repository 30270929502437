import { createSelector } from "reselect";
import { woundAssessmentProgressChartDataDataSelector } from "src/selectors/data_selectors";
import { createWoundAssessmentProgressLineChart } from "src/charts";
import { brandingSelector } from "src/selectors/ui_config_selectors/global";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

export const woundAssessmentProgressChartDefinitionSelector = createSelector(
  woundAssessmentProgressChartDataDataSelector,
  brandingSelector,
  assessmentVersionSelector,
  (chartData, branding, assessmentVersion) => {
    return createWoundAssessmentProgressLineChart(
      chartData,
      branding,
      assessmentVersion
    );
  }
);
