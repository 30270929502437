import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";

export const hasLockWoundRevisionsPermissionSelector = createSelector(
  createPolicyForInternalNameSelector(policyInternalNames.canLockFromDashboard),
  canLockFromDashboard => {
    return canLockFromDashboard && canLockFromDashboard.attributes.policyValue;
  }
);
