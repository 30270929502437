export const patientOverviewTabs = {
  pathways: "pathways",
  orders: "orders",
  cormorbidities: "cormorbidities",
  allergies: "allergies",
  medication: "medication",
  education: "education",
  supplies: "supplies",
  notes: "notes"
};
