import { createSelector } from "reselect";
import jwtDecode from "jwt-decode";

const getSession = state => state.session;

/**
 * Selector for the session state tree
 */
export const sessionSelector = createSelector(
  getSession,
  session => session
);

/**
 * Selector for the access token in the session state tree
 */
export const accessTokenSelector = createSelector(
  sessionSelector,
  session => {
    return session.accessToken;
  }
);

/**
 * Selector for the api host in the session state tree
 */
export const apiHostSelector = createSelector(
  sessionSelector,
  session => {
    return session.apiHost;
  }
);

/**
 * Selector for the session id in the session state tree
 */
export const sessionIdSelector = createSelector(
  sessionSelector,
  session => {
    return session.sessionId;
  }
);

/**
 * Selector that gets the user_id from the decoded jwt access token
 */
export const userIdSelector = createSelector(
  accessTokenSelector,
  accessToken => {
    if (accessToken) {
      const jwt = jwtDecode(accessToken);
      return jwt.user_id;
    } else {
      return "";
    }
  }
);

/**
 * Selector that gets the view_mode from the decoded jwt access token
 */
export const viewModeSelector = createSelector(
  accessTokenSelector,
  accessToken => {
    if (accessToken) {
      const jwt = jwtDecode(accessToken);
      return jwt.view_mode;
    } else {
      return "";
    }
  }
);

/**
 * Selector that gets the disable_patient_links value from the decoded jwt
 */
export const disablePatientLinksAccessTokenValueSelector = createSelector(
  accessTokenSelector,
  accessToken => {
    if (accessToken) {
      let jwt = jwtDecode(accessToken);
      return jwt.disable_patient_links || false;
    } else {
      return false;
    }
  }
);

/**
 * Selector to determine if the current user is an admin or not
 */
export const isEMCViewModeSelector = createSelector(
  viewModeSelector,
  viewMode => {
    return viewMode == "emc";
  }
);

/**
 * Selector for the access token in the session state tree
 */
export const selectedOrganizationIdSelector = createSelector(
  sessionSelector,
  data => {
    return data.selectedOrganizationId;
  }
);

/**
 * Selector for the auth workflow to determine side bar tab view
 */
export const authWorkflowSelector = createSelector(
  sessionSelector,
  session => {
    return session.authWorkflow;
  }
);
