import { createSelector } from "reselect";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Selectors
import { createFirstPagePayloadSelector } from "../create_first_page_payload_selector";

/**
 * create selector that gets context for the first page of patients
 */
export function createFirstPageContextSelector() {
  return createSelector(
    createFirstPagePayloadSelector(),
    firstPagePayload => buildQueryString(firstPagePayload)
  );
}
