import get from "lodash.get";

function localizationDictionaryForAssessmentSummaries(
  assessmentSummaries,
  localizationDictionary
) {
  let assessmentIds = Object.values(assessmentSummaries).map(
    assessmentSummary => get(assessmentSummary, "attributes.assessmentId")
  );
  assessmentIds = [...new Set(assessmentIds)];
  const dictionaryByAssessmentIds = assessmentIds.reduce(
    (agg, assessmentId) => {
      agg[assessmentId] = localizationDictionary[assessmentId] || {};
      return agg;
    },
    {}
  );
  return dictionaryByAssessmentIds;
}

export default { localizationDictionaryForAssessmentSummaries };
