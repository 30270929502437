import axios from "axios";
import normalize from "json-api-normalizer";
import { camelizeKeys } from "humps";

// Constants
import { resourceNames } from "src/constants";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

import createFlashMessage from "app/flash_messages/create";
import { camelCaseObjects } from "src/utils/camel_case_objects";

export const encountersResourceActions = createResourceActions(
  resourceNames.encounters
);

export const getEncountersForPatientId = (
  encounterNamingConvention,
  patientId,
  revisionCreatedAt
) => async dispatch => {
  const encounterForPatientUrl = `/api/v4/encounters?filter[patient_id]="${patientId}"&filter[active_at]="${revisionCreatedAt}"`;
  try {
    const response = await axios.get(encounterForPatientUrl);
    let encounters =
      camelCaseObjects(normalize(response.data, { camelizeKeys: false }))
        .encounters || {};
    dispatch(
      encountersResourceActions.populateAction({
        contexts: patientId,
        data: encounters,
        ids: encounters ? Object.keys(encounters) : [],
        links: response.data.links || null,
        meta: camelizeKeys(response.data.meta) || null
      })
    );
  } catch (error) {
    dispatch(
      createFlashMessage(
        `Error fetching ${encounterNamingConvention} for this patient`,
        "error"
      )
    );
  }
};
