import React from "react";
import PropTypes from "prop-types";
import { Sparklines, SparklinesLine } from "react-sparklines";

/**
 * Body row component for the location wound tally table
 */
export class WoundProgressTallyByLocationTableRowComponent extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    tally: PropTypes.object.isRequired,
    totalSeries: PropTypes.object,
    progressValues: PropTypes.array.isRequired
  };

  /**
   * Renders a sparkline to be displayed in the table
   */
  renderCountHistorySparkline() {
    const { totalSeries } = this.props;
    if (!totalSeries) {
      return null;
    }

    return (
      <Sparklines
        data={totalSeries.woundTotals}
        width={120}
        height={45}
        margin={0}
      >
        <SparklinesLine color="blue" />
      </Sparklines>
    );
  }

  render() {
    const { location, tally, progressValues } = this.props;

    const locationStyle = {
      paddingLeft: `${25 + (location.depth - 1) * 17}px`
    };

    // TODO: Refactor the location, alert, alert-info, alert-danger and in-house styles to
    // be more clear and localized (not from src css file) if possible.

    const progressCounts = progressValues.map(val => {
      let count;
      switch (val) {
        case "new":
          count = tally.progressNewCount;
          break;
        case "worse":
          count = tally.progressWorseCount;
          break;
        case "stalled":
          count = tally.progressStalledCount;
          break;
        case "stable":
          count = tally.progressStableCount;
          break;
        case "better":
          count = tally.progressBetterCount;
          break;
        case "monitoring":
          count = tally.progressMonitoringCount;
          break;
        case "healed":
          count = tally.progressHealedCount;
          break;
        case "unknown":
          count = tally.progressUnknownCount;
          break;
      }
      return (
        <td className="text-center" key={val}>
          {count}
        </td>
      );
    });

    return (
      <tr>
        <td className="location" style={locationStyle}>
          {location.name}
        </td>
        <td>{this.renderCountHistorySparkline()}</td>
        {progressCounts}
        <td className="alert alert-info in-house">
          {tally.totalIncludingResolved}
        </td>
        <td className="alert alert-danger in-house">{tally.ihaTotal}</td>
      </tr>
    );
  }
}
