import { connect } from "react-redux";

// Components
import { RevisionWoundImageComponent } from "src/components/revision_wound_image_component";

// Selectors
import {
  createDefaultFrameForRevisionSelector,
  createLatestRegionForRevisionSelector,
  createLatestRegionForRevisionHasMeasurementSelector
} from "src/selectors/data_selectors";
import { patientTypeSelector } from "src/selectors/config_selectors";

function mapStateToProps(state, ownProps) {
  return {
    frame: createDefaultFrameForRevisionSelector(ownProps.revision)(state),
    region: createLatestRegionForRevisionSelector(ownProps.revision)(state),
    hasMeasurement: createLatestRegionForRevisionHasMeasurementSelector(
      ownProps.revision
    )(state),
    patientType: patientTypeSelector(state)
  };
}

export const RevisionWoundImageContainer = connect(mapStateToProps)(
  RevisionWoundImageComponent
);
