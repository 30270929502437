import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Components
import { EvaluationListComponent } from "src/components/evaluation_list_component";

//Selector
import { evaluationListNonWoundAssessmentsEnabledSelector } from "src/selectors/ui_config_selectors/global";

function mapStateToProps(state) {
  return {
    enabledNonWoundAssessments: evaluationListNonWoundAssessmentsEnabledSelector(
      state
    )
  };
}
export const EvaluationListContainer = withRouter(
  connect(mapStateToProps)(EvaluationListComponent)
);
