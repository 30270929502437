import React from "react";

export class UnlockedIconComponent extends React.Component {
  render() {
    return (
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 108 108"
      >
        <g id="Row_3">
          <path
            className="st0"
            d="M88.2,46.6H52.6V33.5c0-11.1-9-20.1-20.1-20.1h-2.4c-11.1,0-20,9-20.1,20.1v13.1c0.1,2.3,2,4,4.3,3.9 c2.1-0.1,3.8-1.8,3.9-3.9V33.5c0-6.5,5.3-11.9,11.9-11.9h2.4c6.6,0,11.9,5.3,11.9,11.9v13.1h-1.2c-5.4,0-9.8,4.4-9.8,9.9v28.3 c0,5.4,4.4,9.8,9.8,9.8h44.9c5.4,0,9.8-4.4,9.8-9.8V56.5C98,51.1,93.6,46.7,88.2,46.6z M68.9,71.8v6.1c0,1.8-1.4,3.2-3.2,3.2 s-3.2-1.4-3.2-3.2c0,0,0,0,0,0v-6.1c-2.4-1.8-2.8-5.1-1.1-7.5s5.1-2.8,7.5-1.1s2.8,5.1,1.1,7.5C69.7,71.1,69.3,71.5,68.9,71.8z"
            id="Unlocked"
          />
        </g>
      </svg>
    );
  }
}
