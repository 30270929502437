/**
 * Create JSON api compliant GET payload object that can be stringified
 * @param {Object} options
 * @param {Object} options.filter
 * @param {Object} options.page
 * @param {number} options.page.pageNumber
 * @param {number} options.page.limit
 * @param {array} options.include
 */
export function createGetPayload({ filter, page, include, fields }) {
  let fieldValues;
  if (fields) {
    fieldValues = Object.keys(fields).reduce((agg, key) => {
      const fieldsForResource = fields[key];
      if (Array.isArray(fieldsForResource)) {
        agg[key] = fieldsForResource.join();
      } else {
        agg[key] = fieldsForResource;
      }
      return agg;
    }, {});
  }

  return {
    filter,
    page: page
      ? {
          offset: page.pageNumber * page.limit,
          limit: page.limit
        }
      : undefined,
    include: include ? include.join() : undefined,
    fields: fieldValues
  };
}
