import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { sections } from "src/constants";

// Components
import { locationsFilterConfigSelector } from "src/selectors/ui_config_selectors/global";
import { SavedFilterVisualizationComponent } from "src/components/saved_filter_visualization_component";

// Selectors
import { evaluationTypeFilterConfigSelector } from "src/selectors/ui_config_selectors/alerts";

// UI Processors
import { createSectionSelectors } from "src/ui_processors";

// HOC
import { withSchemaLocalizationDictionaryWrapper } from "src/hoc/with_schema_localization_dictionary_wrapper";

const sectionSelectors = createSectionSelectors(sections.alerts);

function mapStateToProps(state) {
  // Evaluation Type
  const evaluationTypeFilterConfig = evaluationTypeFilterConfigSelector(state);
  const evaluationTypeCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    evaluationTypeFilterConfig
  )(state);

  // Locations
  const locationConfig = locationsFilterConfigSelector(state);
  const locationCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    locationConfig
  )(state);

  return {
    filterUiConfigs: [evaluationTypeFilterConfig, locationConfig],
    filterCheckedStates: [evaluationTypeCheckedState, locationCheckedState]
  };
}

export const AlertsSavedFilterVisualizationContainer = flowRight(
  withSchemaLocalizationDictionaryWrapper,
  connect(mapStateToProps)
)(SavedFilterVisualizationComponent);
