import { createSelector } from "reselect";
import { routePathnameSelector } from "src/selectors/router_selectors";
import { patientAppDashboardEnabledSelector } from "src/selectors/ui_config_selectors/global/patient_app_dashboard_enabled_selector";

/**
 * Memoized selector, returns true if monitor at home status filter is enabled, false otherwise
 */

export const monitorAtHomeStatusEnabledSelector = createSelector(
  routePathnameSelector,
  patientAppDashboardEnabledSelector,
  (pathname, patientAppEnabled) => {
    return pathname === "/admin/patients" && patientAppEnabled;
  }
);
