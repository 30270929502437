import get from "lodash.get";

export function isAutodepth(region) {
  if (!region) {
    return false;
  }

  const points = get(region, "attributes.depth.points", null);
  if (!points || !points.length) {
    return false;
  }

  return (
    points.find(point => {
      return point.isComputed === true;
    }) != undefined
  );
}
