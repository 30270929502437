import { createSelector } from "reselect";

// Selectors
import { createFirstPageSavedFiltersForSectionQuerySelector } from "../../../query_selectors/saved_filters";

/**
 * create a selector to get the api context for the first page for saved filters
 * @param {Object} props
 * @param {string} props.section
 * @returns {function} selector
 */
export function createFirstPageSavedFiltersForSectionContextSelector({
  section
}) {
  return createSelector(
    createFirstPageSavedFiltersForSectionQuerySelector({ section }),
    query => {
      return query;
    }
  );
}
