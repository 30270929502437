import { createSelector } from "reselect";

// Selectors
import { woundTalliesResourceSelectors } from "src/selectors/data_selectors";
import { createWoundTalliesContextSelector } from "src/selectors/api_selectors/survey/wound_tallies/create_wound_tallies_context_selector";
import { orderedSelectedLocationsSelector } from "../ordered_selected_locations_selector";

// Constants
import { tallyTypes } from "src/constants";

/**
 * Selector to provide tally data aggregated by wound type
 */
export const talliesByLocationSelector = createSelector(
  orderedSelectedLocationsSelector,
  woundTalliesResourceSelectors.createDataForContextSelector(
    createWoundTalliesContextSelector(tallyTypes.location)
  ),
  (locations, tallies) => {
    if (!locations.length || !tallies.length) return [];

    const totalTally = tallies.find(t => t.attributes.type == tallyTypes.total);

    const contextTallies = tallies.filter(
      t => t.attributes.type != tallyTypes.total
    );

    // group by id (for performance)
    const talliesByLocId = contextTallies.reduce((agg, t) => {
      agg[t.attributes.context.locationId] = t;
      return agg;
    }, {});

    // add location to tallies
    const talliesWithLoc = locations.map(loc => {
      const tally = talliesByLocId[loc.id];
      return {
        ...tally,
        attributes: {
          ...tally.attributes,
          location: loc
        }
      };
    });

    return [totalTally, ...talliesWithLoc];
  }
);
