import { patientsActionTypes } from "src/action_types/section_action_types";
import union from "lodash.union";
import without from "lodash.without";

export function checkedLocationIdsReducer(state = [], action) {
  switch (action.type) {
    case patientsActionTypes.selectLocations:
      return union(state, action.payload);

    case patientsActionTypes.deselectLocations:
      return without(state, ...action.payload);

    default:
      return state;
  }
}
