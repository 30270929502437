import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.less";

// Components
import { ChartComponent } from "src/components/chart_component";
import { GridRow, GridColumn } from "src/components/grid";
import { PanelBodyComponent, PanelComponent } from "src/components/panel";

export class HealingIndexChartComponent extends React.PureComponent {
  static propTypes = {
    healingIndexDashboardEnabled: PropTypes.bool
  };

  renderLegend() {
    return (
      <ul className="text-right">
        <li className="li-chart-legend">
          <span className={`${styles["badge-line"]} ${styles["data-point"]}`} />{" "}
          {"Healing Risk Indicator (HRI)"}
        </li>
        <li className="li-chart-legend">
          <span className={`${styles["badge-line"]} ${styles["average"]}`} />{" "}
          {"Average Healing Risk Indicator (HRI) For Healing Wounds"}
        </li>
        <li className="li-chart-legend">
          <span
            className={`${styles["badge-box"]} ${styles["error-tolerance"]}`}
          />{" "}
          {"Error Tolerance"}
        </li>
        <li className="li-chart-legend">
          <span
            className={`${styles["badge-box"]} ${styles["overperforming"]}`}
          />{" "}
          {"Overperforming"}
        </li>
        <li className="li-chart-legend">
          <span
            className={`${styles["badge-box"]} ${styles["underperforming"]}`}
          />{" "}
          {"Underperforming"}
        </li>
      </ul>
    );
  }

  render() {
    if (!this.props.healingIndexDashboardEnabled) return <div />;

    return (
      <PanelComponent
        labelShow="Healing Risk Over Time"
        enableCollapsible={true}
        collapsedByDefault={false}
        analytics={{ type: "WOUND_RISK_CHART" }}
      >
        <PanelBodyComponent>
          {this.renderLegend()}
          <GridRow className="chart-container">
            <GridColumn xs="12">
              <div className="line-chart">
                <ChartComponent
                  chartDefinition={this.props.chartDefinition}
                  width={750}
                  height={300}
                />
              </div>
            </GridColumn>
          </GridRow>
        </PanelBodyComponent>
      </PanelComponent>
    );
  }
}
