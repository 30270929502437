// @flow
import { Record } from "immutable";
// import {convertInput} from 'src/utils/modelUtils'

export const recordInput = {
  id: "",
  username: "",
  fullName: "",
  partner: ""
};

export default class UserModel extends Record(recordInput) {
  // constructor (opts: Object = {}) {
  //   super(convertInput({
  //     bradenTrend: (braden) => new BradenModel(braden)
  //   }, opts))
  // }
}
