import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//Constants
import { apiPageLimits } from "src/constants";

// HOC
import { withResourceLoaderFactory } from "src/hoc/factories";

// Components
import { EventHistoryComponent } from "src/components/event_history_component";

// Selectors
import { eventsResourceSelectors } from "src/selectors/data_selectors";
import {
  createFirstPageEventsForAssessmentAnswerGroupIdContextSelector,
  createCurrentPageEventsForAssessmentAnswerGroupIdContextSelector,
  createAllPagesEventsForAssessmentAnswerGroupIdContextSelector
} from "src/selectors/context_selectors/events_for_assessment_answer_group_id";
import { createCurrentPageEventsForAssessmentAnswerGroupIdQuerySelector } from "src/selectors/query_selectors/events_for_assessment_answer_group_id";

// Actions
import {
  eventsResourceActions,
  loadEventsAction
} from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  const firstPageContextSelector = createFirstPageEventsForAssessmentAnswerGroupIdContextSelector(
    ownProps
  );
  const currentPageContextSelector = createCurrentPageEventsForAssessmentAnswerGroupIdContextSelector(
    ownProps
  );
  const allPagesContextSelector = createAllPagesEventsForAssessmentAnswerGroupIdContextSelector(
    ownProps
  );

  return {
    events: eventsResourceSelectors.createDataForContextSelector(
      allPagesContextSelector
    )(state),
    loadState: eventsResourceSelectors.createLoadStateForContextSelector(
      currentPageContextSelector
    )(state),
    currentPage: eventsResourceSelectors.createCurrentPageForContextSelector(
      firstPageContextSelector
    )(state),
    pageCount:
      eventsResourceSelectors.createPageCountSelector(
        firstPageContextSelector,
        apiPageLimits.events
      )(state) || 0,
    firstPageContext: firstPageContextSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendIncrementPageAction: context => {
      dispatch(eventsResourceActions.incrementPageForContextAction(context));
    }
  };
}

export const AssessmentAnswerGroupEventHistoryContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createCurrentPageEventsForAssessmentAnswerGroupIdContextSelector,
    createQuerySelector: createCurrentPageEventsForAssessmentAnswerGroupIdQuerySelector
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EventHistoryComponent);
