import get from "lodash.get";

import { getNonWoundAssessmentHeader } from "../get_non_wound_assessment_header";
import { tableForAnswersAndSchema } from "../../table_for_answers_and_schema";
import { getChartImage } from "../get_chart_image";
import { getESignature } from "../../get_e_signature";
/**
 * Get the content (body) portion of the Assessment PDF, Formatted for pdfMake
 * @param {Object} data
 * @param {Object} data.user - the user
 * @param {Object} data.assessmentAnswer - the most recent aa for the revision
 * @param {Array} data.latestLockedEvent - latest locked event,
 * @param {Array} data.latestSignedEvent - latest signed event
 * @param {Object} data.assessmentSchema - assessment schema object
 *
 * @param {Object} images
 * @param {string} images.bradenChart - data url of braden chart
 * @return {Object} JSON representation of the pdf content
 */
export function getBody(data, images) {
  const {
    user,
    assessmentAnswer,
    assessmentAnswerGroup,
    latestSignedEvent,
    assessmentSchema
  } = data;

  const attributesTable = tableForAnswersAndSchema({
    assessmentAnswer,
    assessmentSchema
  });

  // construct pdf body
  let body = [];
  // top of pdf - wound header, image and dimensions
  body.push({
    columns: [
      getNonWoundAssessmentHeader({
        assessmentAnswer,
        assessmentAnswerGroup,
        assessmentSchema,
        user
      })
    ],
    style: "columnBody"
  });

  // Schema tables
  if (attributesTable) {
    body.push({
      columns: [attributesTable],
      style: "columnBody",
      alignment: "left"
    });
  }

  // Graphs
  body.push({
    columns: [images.bradenChart ? getChartImage(images.bradenChart) : [{}]],
    style: "columnBody",
    alignment: "left"
  });

  //E-Signature
  const signatureText = getESignature(
    latestSignedEvent,
    get(assessmentAnswer, "attributes.signState", "")
  );

  return [
    ...body,
    {
      text: signatureText,
      style: "paragraph",
      absolutePosition: { x: 10, y: 720 }
    }
  ];
}
