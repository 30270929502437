import cloneDeep from "lodash.clonedeep";

import { createActionTypes } from "../create_action_types";

export function createReducer(fieldName) {
  const actionTypes = createActionTypes(fieldName);

  return (state = {}, action) => {
    let newState = cloneDeep(state);

    switch (action.type) {
      case actionTypes.setValueForKeyAtIndex: {
        const { value, key, index } = action.payload;
        newState[key] = newState[key] || [];
        newState[key][index] = value;
        return newState;
      }

      case actionTypes.insertValueForKeyAtIndex: {
        const { value, key, index } = action.payload;
        newState[key] = newState[key] || [];
        newState[key].splice(index, 0, value);
        return newState;
      }

      case actionTypes.appendValueForKey: {
        const { value, key } = action.payload;
        newState[key] = newState[key] || [];
        newState[key].push(value);
        return newState;
      }

      case actionTypes.removeValueForKeyAtIndex: {
        const { key, index } = action.payload;
        newState[key] = newState[key] || [];
        newState[key].splice(index, 1);
        return newState;
      }

      default:
        return state;
    }
  };
}
