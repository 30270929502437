import get from "lodash.get";
import { createSelector } from "reselect";

// Models
import {
  getMaxValueWithDirectionForTunnellingAndUndermining,
  roundMeasurementToTwoDecimalPlaces
} from "src/models/revisions";

//Constants
import { woundPainScores } from "src/constants";

//Selectors
import {
  createSeriesForRevisionSelector,
  createLatestAssessmentAnswerForRevisionSelector
} from "src/selectors/data_selectors";
import { createLocalizationDictionaryForRevisionsSelector } from "src/selectors/section_selectors/global";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

/**
 * Create a selector to return a JSON string for a given revision and assessment answer
 * @param {object} revision
 * @returns {function} selector
 */
export function createStringifiedAssessmentJSONDataSelector(revision) {
  const revisions = [revision];
  return createSelector(
    createSeriesForRevisionSelector(revision),
    createLatestAssessmentAnswerForRevisionSelector(revision),
    createLocalizationDictionaryForRevisionsSelector({ revisions }),
    subtypeKeysForAllSchemasSelector,
    (
      series,
      assessmentAnswer,
      schemaLocalizationDictionary,
      subtypeKeysForSchema
    ) => {
      const answersJson = get(assessmentAnswer, "attributes.answersJson", {});
      const localizationDictionary = Object.values(
        schemaLocalizationDictionary
      )[0];
      let location = answersJson.location || null,
        woundType = answersJson.woundType || null,
        pressureUlcerStage = answersJson.pressureUlcerStage || null,
        pressureKennedyStage = answersJson.pressureKennedyStage || null,
        pressureDeviceRelatedStage =
          answersJson.pressureDeviceRelatedStage || null,
        height = roundMeasurementToTwoDecimalPlaces(
          get(revision, "attributes.heightConverted")
        ),
        firstHeight = roundMeasurementToTwoDecimalPlaces(
          get(revision, "attributes.firstHeight")
        ),
        width = roundMeasurementToTwoDecimalPlaces(
          get(revision, "attributes.widthConverted")
        ),
        firstWidth = roundMeasurementToTwoDecimalPlaces(
          get(revision, "attributes.firstWidth")
        ),
        area = roundMeasurementToTwoDecimalPlaces(
          get(revision, "attributes.areaConverted")
        ),
        depth = roundMeasurementToTwoDecimalPlaces(
          get(revision, "attributes.depthConverted")
        ),
        firstDepth = roundMeasurementToTwoDecimalPlaces(
          get(revision, "attributes.firstDepth")
        ),
        maxTunnelling = roundMeasurementToTwoDecimalPlaces(
          get(revision, "attributes.tunnellingConverted")
        ),
        maxUndermining = roundMeasurementToTwoDecimalPlaces(
          get(revision, "attributes.underminingConverted")
        ),
        tunnellings = get(revision, "attributes.tunnellings", []),
        underminings = get(revision, "attributes.underminings", []),
        maxTunnellingWithDirection = getMaxValueWithDirectionForTunnellingAndUndermining(
          tunnellings,
          maxTunnelling
        ),
        maxUnderminingWithDirection = getMaxValueWithDirectionForTunnellingAndUndermining(
          underminings,
          maxUndermining
        ),
        woundMargins = answersJson.woundMargins || [],
        surroundingSkin = answersJson.surroundingSkin || [],
        edema = answersJson.edema || null,
        woundTemperature = answersJson.woundTemperature || null,
        exudateType = answersJson.exudateType || null,
        exudateAmount = answersJson.exudateAmount || null,
        odour = answersJson.odour || null,
        tissueEpithelialAmount = answersJson.tissueEpithelialAmount || null,
        tissueEpithelialExists = answersJson.tissueEpithelialExists || null,
        tissueEscharAmount = answersJson.tissueEscharAmount || null,
        tissueEscharExists = answersJson.tissueEscharExists || null,
        tissueEvidenceOfInfection = answersJson.tissueEvidenceOfInfection || [],
        tissueGranulationAmount = answersJson.tissueGranulationAmount || null,
        tissueGranulationExists = answersJson.tissueGranulationExists || null,
        tissueOther = answersJson.tissueOther || [],
        tissueSloughAmount = answersJson.tissueSloughAmount || null,
        tissueSloughExists = answersJson.tissueSloughExists || null,
        ciPainTotal = answersJson.ciPainTotal || null,
        woundPain = answersJson.woundPain || null,
        woundPainScore = ciPainTotal || woundPainScores[woundPain],
        induration = answersJson.induration || null,
        inHouseAcquired = answersJson.inHouseAcquired || null,
        seriesCreatedAt = get(series, "attributes.createdAt", null),
        woundAge = answersJson.woundAge || null,
        woundDate = answersJson.woundDate || null,
        burnDegree = answersJson.burnDegree || null,
        overallProgress = answersJson.overallProgress || null,
        painNotes = answersJson.painNotes || null;

      const assessmentJSON = {
        id: "swiftAssessment",
        version: "4",
        location: location || "",
        locationTranslation: location
          ? localizationDictionary[`location.${location}`]
          : "",
        woundTypeKey: woundType || "",
        woundTypeTranslation: woundType
          ? localizationDictionary[`woundType.${woundType}`]
          : "Undiagnosed",
        pressureUlcerStageKey: pressureUlcerStage || "",
        pressureUlcerStageTranslation: pressureUlcerStage
          ? localizationDictionary[
              `${subtypeKeysForSchema[woundType]}.${pressureUlcerStage}`
            ]
          : "",
        pressureKennedyStageKey: pressureKennedyStage || "",
        pressureKennedyStageTranslation: pressureKennedyStage
          ? localizationDictionary[
              `${subtypeKeysForSchema[woundType]}.${pressureKennedyStage}`
            ]
          : "",
        pressureDeviceRelatedStageKey: pressureDeviceRelatedStage || "",
        pressureDeviceRelatedStageTranslation: pressureDeviceRelatedStage
          ? localizationDictionary[
              `${subtypeKeysForSchema[woundType]}.${pressureDeviceRelatedStage}`
            ]
          : "",
        height: height || "",
        firstHeight: firstHeight || "",
        width: width || "",
        firstWidth: firstWidth || "",
        depth: depth || "",
        firstDepth: firstDepth || "",
        area: area || "",
        maxUndermining: maxUndermining || "",
        maxUnderminingWithDirection: maxUnderminingWithDirection || "",
        maxTunnelling: maxTunnelling || "",
        maxTunnellingWithDirection: maxTunnellingWithDirection || "",
        woundMargins,
        surroundingSkin,
        surroundingSkinExtent: answersJson.surroundingSkinExtent
          ? roundMeasurementToTwoDecimalPlaces(
              parseFloat(answersJson.surroundingSkinExtent)
            )
          : "",
        edema: edema || "",
        woundTemperature: woundTemperature || "",
        exudateType: exudateType || "",
        exudateAmount: exudateAmount || "",
        odour: odour || "",
        tissueEpithelialAmount: tissueEpithelialAmount || "",
        tissueEpithelialExists: tissueEpithelialExists || "",
        tissueEscharAmount: tissueEscharAmount || "",
        tissueEscharExists: tissueEscharExists || "",
        tissueEvidenceOfInfection,
        tissueGranulationAmount: tissueGranulationAmount || "",
        tissueGranulationExists: tissueGranulationExists || "",
        tissueOther,
        tissueSloughAmount: tissueSloughAmount || "",
        tissueSloughExists: tissueSloughExists || "",
        pain: woundPainScore || "",
        induration: induration || "",
        inHouseAcquired: inHouseAcquired || "",
        woundAge: woundAge || "",
        woundDate: woundDate || "",
        seriesCreatedAt: seriesCreatedAt || "",
        burnDegree: burnDegree || "",
        overallProgress: overallProgress || "",
        painNotes: painNotes || "",
        goalOfCare: answersJson.goalOfCare || "",
        cleaned: answersJson.cleaned || [],
        dressingAppearance: answersJson.dressingAppearance || [],
        primaryDressing: answersJson.primaryDressing || [],
        secondaryDressing: answersJson.secondaryDressing || [],
        modalities: answersJson.modalities || [],
        additionalCare: answersJson.additionalCare || [],
        infection: answersJson.infection || "",
        education: answersJson.education || "",
        strategy: answersJson.strategy || ""
      };

      return JSON.stringify(assessmentJSON);
    }
  );
}
