export const apiPageLimits = {
  events: 10,
  facilities: 50,
  patients: 25,
  savedFilters: 10,
  rules: 25,
  ruleTemplates: 1000,
  revisions: 10,
  customRoles: 1000,
  adminFacilities: 1000,
  adminFacilityUnits: 1000,
  adminFacilityFloors: 1000,
  revisionsBulk: 100,
  users: 25,
  woundAlertRules: 10,
  riskAlertRules: 10,
  patientAlerts: 5,
  clinicianStats: 25
};
