import { createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { createUserFormFacilityOptionsSelector } from "src/selectors/section_selectors/admin";
import { sortByName } from "src/utils/sort_by_name";
import pickby from "lodash.pickby";

export function createUserFormDefaultFacilityOptionsSelector(formName) {
  return createSelector(
    getFormValues(formName),
    createUserFormFacilityOptionsSelector(formName),
    (form, facilities) => {
      let filterFacilities = [];
      if (form && form["facility"]) {
        const checkedFacilitiesIds = Object.keys(pickby(form["facility"]));
        if (checkedFacilitiesIds) {
          filterFacilities = facilities.filter(fac => {
            return checkedFacilitiesIds.includes(fac.id);
          });
        }
      }
      return filterFacilities ? sortByName(filterFacilities) : [];
    }
  );
}
