// @flow
import { Map, OrderedSet } from "immutable";
import { handleActions } from "redux-actions";
import get from "lodash.get";
import { relationshipActionNames } from "../actionUtils";
import { relationshipTypes } from "../schemaUtils";
import { generateDefaultState } from "./misc";
import type { $relationshipSchema } from "../schemaUtils";

type $coreAttributes = {
  name: string,
  defaultStateConfig?: Object,
  options?: Object,
  otherActions?: Object
};

type $attributes = $coreAttributes & {
  relationshipSchemas: $relationshipSchema[]
};

type $propertyAttributes = $coreAttributes & {
  mapOfRelationshipSchemas: $$mapOf<$relationshipSchema[]>
};

const create = function(mapOfRelationshipSchemas) {
  return function(state, { payload, error }) {
    if (error) {
      return state;
    }
    const { relationshipName, entityId, value } = payload;
    const arrayPath = [relationshipName, `${entityId}`];
    const relationshipSchema = get(mapOfRelationshipSchemas, relationshipName);
    if (!relationshipSchema) {
      throw new Error(
        `relationship reducer missing relationship ${relationshipName}`
      );
    }
    const { type } = relationshipSchema;
    if (type === relationshipTypes.ONE) {
      return state.setIn(arrayPath, value);
    }
    return state.setIn(arrayPath, new OrderedSet(value));
  };
};

const add = function(mapOfRelationshipSchemas) {
  return function(state, { payload, error }) {
    if (error) {
      return state;
    }
    const { relationshipName, entityId, value } = payload;
    const arrayPath = [relationshipName, `${entityId}`];
    const relationshipSchema = get(mapOfRelationshipSchemas, relationshipName);
    if (!relationshipSchema) {
      throw new Error(
        `relationship reducer missing relationship ${relationshipName}`
      );
    }
    const { type } = relationshipSchema;
    if (type === relationshipTypes.ONE) {
      return state.setIn(arrayPath, value);
    }
    return state.updateIn(arrayPath, previousValue => {
      if (previousValue) {
        return previousValue.concat(value);
      }
      return new OrderedSet(value);
    });
  };
};

const createProperty = function(mapOfRelationshipSchemasByProperty) {
  return function(state, { payload, error }) {
    if (error) {
      return state;
    }
    const { relationshipName, entityId, value } = payload;
    const arrayPath = [relationshipName, `${entityId}`];
    const relationshipSchema = get(
      mapOfRelationshipSchemasByProperty,
      `${entityId}.${relationshipName}`
    );
    if (!relationshipSchema) {
      throw new Error(
        `property reducer missing relationship schema for ${entityId} ${relationshipName}`
      );
    }
    const { type } = relationshipSchema;
    if (type === relationshipTypes.ONE) {
      return state.setIn(arrayPath, value);
    }
    return state.setIn(arrayPath, new OrderedSet(value));
  };
};

const addProperty = function(mapOfRelationshipSchemasByProperty) {
  return function(state, { payload, error }) {
    if (error) {
      return state;
    }
    const { relationshipName, entityId, value } = payload;
    const arrayPath = [relationshipName, `${entityId}`];
    const relationshipSchema = get(
      mapOfRelationshipSchemasByProperty,
      `${entityId}.${relationshipName}`
    );
    if (!relationshipSchema) {
      throw new Error(
        `property reducer missing relationship schema for ${entityId} ${relationshipName}`
      );
    }
    const { type } = relationshipSchema;
    if (type === relationshipTypes.ONE) {
      return state.setIn(arrayPath, value);
    }
    return state.updateIn(arrayPath, previousValue => {
      if (previousValue) {
        return previousValue.concat(value);
      }
      return new OrderedSet(value);
    });
  };
};

const getMapOfRelationshipSchemas = function(relationshipSchemas) {
  return relationshipSchemas.reduce((finalResult, relationshipSchema) => {
    const relationshipName =
      relationshipSchema.alias || relationshipSchema.name;
    finalResult[relationshipName] = relationshipSchema;
    return finalResult;
  }, {});
};

const getDefaultState = function(mapOfRelationshipSchemas) {
  return Object.keys(mapOfRelationshipSchemas).reduce(
    (finalResult, relationshipName) => {
      finalResult[relationshipName] = new Map();
      return finalResult;
    },
    {}
  );
};

export const relationshipReducer = function({
  name,
  relationshipSchemas,
  defaultStateConfig = {},
  otherActions = {}
}: $attributes) {
  const mapOfRelationshipSchemas = getMapOfRelationshipSchemas(
    relationshipSchemas
  );
  const defaultState = getDefaultState(mapOfRelationshipSchemas);
  const finalDefaultState = { ...defaultState, ...defaultStateConfig };
  return handleActions(
    {
      [relationshipActionNames.create(name)]: create(mapOfRelationshipSchemas),
      [relationshipActionNames.add(name)]: add(mapOfRelationshipSchemas),
      ...otherActions
    },
    generateDefaultState(finalDefaultState)
  );
};

export const propertyRelationshipReducer = function({
  name,
  mapOfRelationshipSchemas,
  defaultStateConfig = {},
  otherActions = {}
}: $propertyAttributes) {
  const {
    mapOfRelationshipSchemasByPropertyName,
    mapOfAllRelationshipSchemas
  } = Object.keys(mapOfRelationshipSchemas).reduce(
    (finalResult, propertyName) => {
      const relationshipSchemasMap = getMapOfRelationshipSchemas(
        mapOfRelationshipSchemas[propertyName]
      );
      finalResult.mapOfRelationshipSchemasByPropertyName[
        propertyName
      ] = relationshipSchemasMap;
      finalResult.mapOfAllRelationshipSchemas = {
        ...finalResult.mapOfAllRelationshipSchemas,
        ...relationshipSchemasMap
      };
      return finalResult;
    },
    {
      mapOfRelationshipSchemasByPropertyName: {},
      mapOfAllRelationshipSchemas: {}
    }
  );
  const defaultState = getDefaultState(mapOfAllRelationshipSchemas);
  const finalDefaultState = { ...defaultState, ...defaultStateConfig };
  return handleActions(
    {
      [relationshipActionNames.create(name)]: createProperty(
        mapOfRelationshipSchemasByPropertyName
      ),
      [relationshipActionNames.add(name)]: addProperty(
        mapOfRelationshipSchemasByPropertyName
      ),
      ...otherActions
    },
    generateDefaultState(finalDefaultState)
  );
};
