import apiFetch from "app/util/apiFetch";

export default function loadPatientsForMediumRisk(
  locationIds,
  startTime,
  endTime,
  nextCursor
) {
  return dispatch => {
    const payload = {
      location_ids: locationIds.join(),
      start_time: startTime,
      end_time: endTime,
      min_score: 13,
      max_score: 14,
      next_cursor: nextCursor
    };
    if (typeof nextCursor !== "undefined") {
      return dispatch(
        apiFetch(`/api/v2/risk_dashboard/braden`, {
          method: "POST",
          body: payload,
          headers: {
            Accept: "application/json",
            "X-HTTP-Method-Override": "GET"
          }
        })
      ).then(({ patients, meta }) => {
        dispatch({
          type: "LOCATION_MEDIUMRISK_PATIENT_LOAD_MORE",
          patients,
          meta
        });
        return patients;
      });
    } else {
      return dispatch(
        apiFetch(`/api/v2/risk_dashboard/braden`, {
          method: "POST",
          body: payload,
          headers: {
            Accept: "application/json",
            "X-HTTP-Method-Override": "GET"
          }
        })
      ).then(({ patients, meta }) => {
        dispatch({
          type: "LOCATION_MEDIUMRISK_PATIENT_LOAD",
          patients,
          meta
        });
        return patients;
      });
    }
  };
}
