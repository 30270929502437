import { Action } from "src/utils/actionUtils";
import { startTimeTrack, finishTimeTrack } from "src/analytics";
import apiFetch from "app/util/apiFetch";

class SeriesActions extends Action {
  /**
   * Load data for the closure/push score/stage/healing perf graphs on the series view
   */
  loadTrendsBySeries = id => {
    return dispatch => {
      startTimeTrack("API_GET_SERIES_TRENDS");

      return dispatch(
        apiFetch(`/pcc/v1/series/${id}/trends`, {
          method: "GET"
        })
      )
        .then(({ data: trends }) => {
          finishTimeTrack("API_GET_SERIES_TRENDS");

          return dispatch(
            this.baseActions.getAdditionalEntityProperties(
              id,
              "seriesTrends",
              trends
            )
          );
        })
        .catch(err => {
          finishTimeTrack("API_GET_SERIES_TRENDS", {
            error: true,
            errorCode: err.response ? err.response.status : undefined
          });
          return dispatch(
            this.baseActions.getAdditionalEntityProperties(
              id,
              "seriesTrends",
              err
            )
          );
        });
    };
  };
}

export default new SeriesActions("series");
