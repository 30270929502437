import { createSelector } from "reselect";
import { adminSectionSelector } from "../admin_section_selector";

/**
 * Selector to select the selected User Table Page Limit
 */

export const selectedUserTablePageLimitSelector = createSelector(
  adminSectionSelector,
  adminSection => {
    return adminSection.selectedUserTablePageLimit;
  }
);
