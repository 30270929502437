import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";

import styles from "./style.module.less";

export class QrCodeComponent extends PureComponent {
  static propTypes = {
    code: PropTypes.string.isRequired
  };

  render() {
    const { code } = this.props;
    return (
      <div className={styles["qrcode-container"]}>
        <QRCode renderAs="svg" level="H" size={100} value={code} />
      </div>
    );
  }
}
