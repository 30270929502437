import { addReducer } from "incremental-redux-reducers";

const DEFAULT_STATE = {
  veryHighTotal: 0,
  highTotal: 0,
  mediumTotal: 0,
  lowTotal: 0,
  noTotal: 0,
  noneTotal: 0
};

export default addReducer(
  "clinicalRiskAggregate",
  function clinicalRiskChartReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
      case "LOCATION_CLINICAL_RISK_AGGREGATE":
        return action.aggregates;
      default:
        return state;
    }
  }
);
