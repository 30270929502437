import moment from "moment";

/**
 * Get UTC offset string from
 * @returns {String} UTC offset string
 */
export function getUTCOffsetString() {
  const UTCOffsetInMinutes = moment().utcOffset();

  const prefixString = UTCOffsetInMinutes.toString()[0] === "-" ? "-" : "",
    UTCOffsetInMinutesAbsolute = Math.abs(UTCOffsetInMinutes),
    hours = (UTCOffsetInMinutesAbsolute / 60).toFixed(0),
    hoursString = hours < 10 ? `0${hours}` : `${hours}`,
    minutes = UTCOffsetInMinutesAbsolute - hours * 60,
    minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return `${prefixString}${hoursString}:${minutesString}`;
}
