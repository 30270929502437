import React from "react";
import PropTypes from "prop-types";
import { CollapsibleContainerComponent } from "src/components/collapsible_container_component";
import { CheckboxList, CheckboxListItem } from "src/components/checkbox_list";
import { LoadingComponent } from "src/components/loading_component";
import useComplianceFilter from "src/pages/compliance/hooks/useComplianceFilter";
import { ComplianceContext } from "src/pages/compliance/components/compliance_provider_component.js";

function FilterSection({ title, filterKey, fetchData }) {
  const { enabledFilters, enableFilters, disableFilters } = useComplianceFilter(
    filterKey
  );
  const {
    state: { organizations }
  } = React.useContext(ComplianceContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [filters, setFilters] = React.useState([]);

  const loadFilters = async organizationId => {
    try {
      const data = await fetchData({ organizationId });
      setFilters(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const organizationId = organizations.length && organizations[0].id;
    if (!!organizationId) {
      loadFilters(organizationId);
    }
  }, [organizations, fetchData]);

  const isFilterEnabled = filterId => enabledFilters.includes(filterId);

  const onClickFilter = filterId => {
    isFilterEnabled(filterId)
      ? disableFilters([filterId])
      : enableFilters([filterId]);
  };

  const filterItem = ({ label, value }) => (
    <CheckboxListItem
      key={value}
      label={label}
      value={value}
      checked={isFilterEnabled(value)}
      onChange={() => onClickFilter(value)}
    />
  );

  if (isLoading) {
    return <LoadingComponent />;
  } else {
    return (
      <CollapsibleContainerComponent label={title}>
        <CheckboxList disabled={false}>{filters.map(filterItem)}</CheckboxList>
      </CollapsibleContainerComponent>
    );
  }
}

FilterSection.propTypes = {
  title: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  filterKey: PropTypes.string.isRequired
};

export default FilterSection;
