import React from "react";
import PropTypes from "prop-types";
import { loadStates } from "src/constants/load_states";
//Container
import { PopulationIncidencePrevalenceChartContainer } from "src/containers/population_incidence_prevalence_chart_container";
import { PopulationTallyTableByEtiologyContainer } from "src/containers/tables";
import { PanelComponent, PanelBodyComponent } from "src/components/panel";

//components
import { LoadingComponent } from "src/components/loading_component";
import { GridRow, GridColumn } from "src/components/grid";

export class PopulationEtiologyComponent extends React.PureComponent {
  static propTypes = {
    populationIncidencePrevalenceChartLoadState: PropTypes.string.isRequired,
    populationTalliesLoadState: PropTypes.string.isRequired
  };
  state = {
    graphShowHide: true,
    tallyTableShowhide: true
  };

  showHideTallyTable = () => {
    this.setState({ tallyTableShowhide: !this.state.tallyTableShowhide });
  };
  showHideGraph = () => {
    this.setState({ graphShowHide: !this.state.graphShowHide });
  };

  render() {
    const {
      populationTalliesLoadState,
      populationIncidencePrevalenceChartLoadState
    } = this.props;

    if (
      populationIncidencePrevalenceChartLoadState == loadStates.loading ||
      populationTalliesLoadState == loadStates.loading
    ) {
      return <LoadingComponent />;
    } else if (
      populationIncidencePrevalenceChartLoadState == loadStates.error ||
      populationTalliesLoadState == loadStates.error
    ) {
      return <LoadingComponent />;
    } else {
      return (
        <div>
          <PanelComponent
            labelShow="Incidence and Prevalence of Wounds"
            labelHide="Show Incidence and Prevalence graph"
            enableCollapsible={true}
          >
            <PanelBodyComponent>
              <GridRow>
                <GridColumn xs="12">
                  <PopulationIncidencePrevalenceChartContainer />
                </GridColumn>
              </GridRow>
            </PanelBodyComponent>
          </PanelComponent>
          <PanelComponent
            labelShow="Population Table"
            labelHide="Show Population Table"
            enableCollapsible={true}
          >
            <PanelBodyComponent>
              <GridRow>
                <GridColumn xs="12">
                  <PopulationTallyTableByEtiologyContainer />
                </GridColumn>
              </GridRow>
            </PanelBodyComponent>
          </PanelComponent>
        </div>
      );
    }
  }
}
