import { connect } from "react-redux";

// Constants
import { sections } from "src/constants";

// Components
import { ExportsSectionComponent } from "src/components/exports_section_component";

// Actions
import { createLocalExportAction } from "src/actions/data_actions/local_exports";

// Selectors
import { exportsSectionSelector } from "src/selectors/section_selectors/exports";
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";
import { localExportsResourceSelectors } from "src/selectors/data_selectors/local_exports";

// UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";
const exportNameSelector = SingleValueReduxUtil.createSelectors(
  "exportName",
  exportsSectionSelector
);

const exportNameActions = SingleValueReduxUtil.createActionCreators(
  "exportName"
);

function mapStateToProps(state) {
  return {
    exportName: exportNameSelector.fieldSelector(state),
    exportType: "zip",
    filters: createSectionSelectors(sections.exports).filtersSelector(state),
    localExports: localExportsResourceSelectors.dataSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendCreateExportAction(attributes) {
      dispatch(createLocalExportAction(attributes));
    },
    sendSetExportNameAction(value) {
      dispatch(exportNameActions.setValue(value));
    }
  };
}

export const ExportsSectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportsSectionComponent);
