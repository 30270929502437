import { createSelector } from "reselect";
import { organizationsResourceSelectors } from "src/selectors/data_selectors/organizations";
import { checkedOrganizationIdsSelector } from "../checked_organization_ids_selector";
import { selectedPartnerIdSelector } from "../selected_partner_id_selector";

/**
 * Organizaton ids selector for the admin section
 * used to get the Organizatons ids that should be passed to the server to load data
 */
export const selectedOrganizationIdsSelector = createSelector(
  organizationsResourceSelectors.dataSelector,
  checkedOrganizationIdsSelector,
  selectedPartnerIdSelector,
  (organizations, checkedOrganizationIds, selectedPartnerId) => {
    if (checkedOrganizationIds && checkedOrganizationIds.length) {
      return checkedOrganizationIds;
    } else {
      return Object.values(organizations)
        .filter(org => org.attributes.partnerId == selectedPartnerId)
        .map(org => org.id);
    }
  }
);
