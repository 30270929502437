import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//Constants
import { analyticsEventNames } from "src/constants";

// HOC's
import { withWoundAssessmentTotalsTimeSeriesByLocationLoader } from "src/hoc";
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

//Action
import { loadWoundTalliesAction } from "src/actions/data_actions/wound_tallies";

//Resource Selector
import { woundTalliesResourceSelectors } from "src/selectors/data_selectors";

//Selectors
import { woundAssessmentTotalsTimeSeriesByLocationLoadStateSelector } from "src/selectors/data_selectors";
import { selectedDateSelector } from "src/selectors/section_selectors/wounds";
////Create Context and Query Selector
import {
  createWoundTalliesPayloadSelector,
  createWoundTalliesContextSelector
} from "src/selectors/api_selectors/wounds/wound_tallies";

// Components
import { WoundsLocationTabComponent } from "src/components/wounds_location_tab_component";

function mapStateToProps(state) {
  return {
    selectedDate: selectedDateSelector(state),
    chartLoadState: woundAssessmentTotalsTimeSeriesByLocationLoadStateSelector(
      state
    )
  };
}

export const WoundsLocationTabContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadWoundTalliesAction,
    resourceSelectors: woundTalliesResourceSelectors,
    createContextSelector: createWoundTalliesContextSelector,
    createPayloadSelector: createWoundTalliesPayloadSelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: analyticsEventNames.renderWoundsDashboardLocationsTable
  }),
  withWoundAssessmentTotalsTimeSeriesByLocationLoader,
  connect(mapStateToProps)
)(WoundsLocationTabComponent);
