import { createSelector } from "reselect";
import { exportsSelector } from "../../exports_selector";

/**
 * Selector for the surveyTally section of the export state tree
 */
export const surveyTallyExportSelector = createSelector(
  exportsSelector,
  exports => exports.surveyTallyExport
);
