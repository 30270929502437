import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { woundTalliesResourceSelectors } from "src/selectors/data_selectors";

/**
 * Create a selector that returns a wound assessment tally for a location
 */
export function createWoundAssessmentTallyForLocationAndContextSelector(
  location,
  context
) {
  return createSelector(
    woundTalliesResourceSelectors.createDataForContextSelector(context),
    woundTallies => {
      const tally = woundTallies.find(tally => {
        const tallyLocationId = get(tally, "attributes.context.locationId", "");
        return tallyLocationId === location.id;
      });

      if (tally && tally.attributes) {
        return tally.attributes;
      } else {
        return {};
      }
    }
  );
}
