import { createActionTypes } from "../../create_action_types";

/**
 * creates an action creator to set the load state of a resource for a give api query object
 * @param {string} resourceName - the resource name
 * @returns {function}
 */
export function createSetLoadStateActionCreator(resourceName) {
  const actionTypes = createActionTypes(resourceName);

  /**
   * @param {Object|array} payload - the api query object or array of query objects
   */
  return function setLoadStateActionCreator(
    loadState,
    method,
    queryObject,
    page
  ) {
    return {
      type: actionTypes.setLoadState,
      payload: {
        loadState,
        method,
        queryObject,
        page
      }
    };
  };
}
