import React from "react";
//TODO: move all logos under one same logo_components file

export default class MedlineLogoComponent extends React.Component {
  render() {
    return (
      <svg
        width="284"
        height="109"
        viewBox="0 0 284 109"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M103.29 4.95996H4.57999V103.67H103.29V4.95996Z"
          fill="#0052CC"
        />
        <path
          d="M50.87 61.4602L44.45 93.5202L59.02 61.4502L50.87 61.4602Z"
          fill="white"
        />
        <path
          d="M94.86 43.0001L65.78 36.9801L68.96 15.1001L57.63 36.9801L20.13 43.0001H94.86Z"
          fill="white"
        />
        <path
          d="M20.08 46.7898H24.96L23.18 54.3598L28.57 46.7898L33.44 46.7798L28.08 57.9598H25.15L29.8 48.5898L22.65 57.9598H19.87L21.86 48.5098L17.39 57.9598H14.72L20.08 46.7898Z"
          fill="white"
        />
        <path
          d="M35.63 46.8198H44.01L42.99 48.9598H37.67L36.55 51.2798H41.56L40.54 53.4198H35.53L34.38 55.7998H39.82L38.79 57.9598H30.28L35.63 46.8198Z"
          fill="white"
        />
        <path
          d="M44.72 55.81H46.19C48.26 55.79 50.41 54.97 51.7 52.39C53.65 48.5 51.1 48.92 49.41 48.86H48.04L44.72 55.81ZM46.02 46.79H50.74C52.07 46.79 53.76 46.93 54.55 47.72C55.66 48.85 55.22 50.69 54.43 52.34C53.24 54.82 51.57 56.65 48.9 57.52C47.55 57.95 46.18 57.95 45.41 57.95H40.66L46.02 46.79Z"
          fill="white"
        />
        <path
          d="M58.82 46.79H61.96L57.64 55.76H62.11L61.05 57.96H53.45L58.82 46.79Z"
          fill="white"
        />
        <path
          d="M68.16 46.79H71.34L65.98 57.96H62.79L68.16 46.79Z"
          fill="white"
        />
        <path
          d="M73.42 46.79H77.69L77.46 55.12L81.43 46.79H84.13L78.77 57.96H74.59L74.91 49.29L70.77 57.96H68.05L73.42 46.79Z"
          fill="white"
        />
        <path
          d="M86.32 46.8198H94.7L93.67 48.9598H88.36L87.24 51.2798H92.25L91.23 53.4198H86.21L85.07 55.7998H90.52L89.47 57.9598H80.96L86.32 46.8198Z"
          fill="white"
        />
        <path
          d="M137.77 5.43018C145.61 5.43018 150.44 7.48018 150.44 7.48018C150.44 10.0102 149.66 12.7902 148.03 14.6002C148.03 14.6002 144.05 13.3902 139.28 13.3902C134.09 13.3902 131.62 14.9602 131.62 18.0302C131.62 21.3502 136.63 22.1302 141.69 23.9402C147 25.8702 152.31 28.8902 152.31 36.7902C152.31 45.5402 146.94 50.3002 136.02 50.3002C127.15 50.3002 121.6 47.3402 121.6 47.3402C121.6 44.8102 122.51 41.9102 123.89 39.9802C123.89 39.9802 129.32 41.8502 135.35 41.8502C140.11 41.8502 142.29 40.2802 142.29 37.2102C142.29 34.4402 139.58 33.2302 136.08 32.0202C130.05 30.0302 121.84 28.2202 121.84 18.6902C121.85 10.7902 126.86 5.43018 137.77 5.43018Z"
          fill="#F56076"
        />
        <path
          d="M172.34 19.0898C172.34 19.0898 173.91 18.6098 176.86 18.6098C179.64 18.6098 181.63 19.0898 181.63 19.0898L173.79 33.3798L183.02 50.0898C183.02 50.0898 180.97 50.6298 177.83 50.6298C174.81 50.6298 172.88 50.0898 172.88 50.0898L165.82 37.2398H164.37V50.0998C164.37 50.0998 162.74 50.5798 159.79 50.5798C156.83 50.5798 155.08 50.0998 155.08 50.0998V5.69984C155.08 5.69984 156.89 5.33984 159.79 5.33984C162.44 5.33984 164.37 5.69984 164.37 5.69984V30.5498H165.94L172.34 19.0898Z"
          fill="#F56076"
        />
        <path
          d="M195.33 13.0898C195.33 13.0898 193.64 13.5698 190.69 13.5698C187.55 13.5698 186.05 13.0898 186.05 13.0898V5.54982C186.05 5.54982 187.5 5.06982 190.69 5.06982C193.65 5.06982 195.33 5.54982 195.33 5.54982V13.0898ZM195.33 50.4298C195.33 50.4298 193.64 50.9098 190.69 50.9098C187.55 50.9098 186.05 50.4298 186.05 50.4298V19.4298C186.05 19.4298 187.5 18.9498 190.69 18.9498C193.65 18.9498 195.33 19.4298 195.33 19.4298V50.4298Z"
          fill="#F56076"
        />
        <path
          d="M226.98 50.0998C226.98 50.0998 225.41 50.5798 222.34 50.5798C219.2 50.5798 217.7 50.0998 217.7 50.0998V28.9798C217.7 26.7498 216.61 25.3598 213.84 25.3598C212.33 25.3598 210.88 25.9598 209.68 27.0498V50.0998C209.68 50.0998 208.05 50.5798 205.04 50.5798C201.9 50.5798 200.4 50.0998 200.4 50.0998V19.1498C200.4 19.1498 201.91 18.6698 205.04 18.6698C208.06 18.6698 209.68 19.1498 209.68 19.1498V20.9598C211.79 19.0298 214.57 18.0698 217.76 18.0698C223.91 18.0698 226.99 21.7498 226.99 27.1198V50.0998H226.98Z"
          fill="#F56076"
        />
        <path
          d="M145.12 60.7802C145.12 60.7802 146.99 60.2402 149.95 60.2402C152.91 60.2402 154.78 60.7802 154.78 60.7802V103.12C154.78 103.12 152.97 103.66 150.02 103.66C146.76 103.66 145.13 103.12 145.13 103.12V85.3302H131.56V103.12C131.56 103.12 129.63 103.66 126.73 103.66C123.53 103.66 121.9 103.12 121.9 103.12V60.7802C121.9 60.7802 123.71 60.2402 126.67 60.2402C129.69 60.2402 131.56 60.7802 131.56 60.7802V77.4902H145.13V60.7802H145.12Z"
          fill="#F56076"
        />
        <path
          d="M167.41 89.9802C168.01 94.9302 170.31 96.1902 173.92 96.1902C177.66 96.1902 180.31 94.7402 180.31 94.7402C181.76 96.0102 183.08 98.6602 183.14 101.07C183.14 101.07 179.52 103.78 172.4 103.78C162.51 103.78 157.86 98.5902 157.86 87.3702C157.86 75.9102 163.23 70.6602 171.97 70.6602C179.87 70.6602 184.4 74.7002 184.4 83.3302C184.4 87.3102 183.74 89.9602 183.74 89.9602H167.41V89.9802ZM172.17 77.4402C169.7 77.4402 167.83 79.1901 167.34 84.2001H175.96C176.02 83.6001 176.08 82.9902 176.08 82.3302C176.09 79.5502 175.07 77.4402 172.17 77.4402Z"
          fill="#F56076"
        />
        <path
          d="M200.59 70.5498C209.64 70.5498 213.32 74.1098 213.32 81.7098V102.58C213.32 102.58 211.99 103.06 209.46 103.06C207.05 103.06 205.6 102.58 205.6 102.58L204.51 99.6198C203.18 102.27 200.29 103.66 196.07 103.66C190.1 103.66 186.72 100.22 186.72 93.1598C186.72 86.2798 190.88 83.4498 198.78 83.4498C200.59 83.4498 202.46 83.5698 204.03 83.6898V82.7298C204.03 80.0198 202.76 78.1498 199.08 78.1498C195.34 78.1498 191.72 79.5398 191.72 79.5398C191.72 79.5398 190.51 78.6398 189.73 76.7098C188.89 74.7798 189.13 73.0298 189.13 73.0298C189.13 73.0298 193.89 70.5498 200.59 70.5498ZM200.17 89.1298C197.52 89.1298 196.01 90.2798 196.01 93.2298C196.01 96.3698 197.28 97.4498 199.51 97.4498C202.83 97.4498 204.03 94.3098 204.03 91.1198V89.3098C202.82 89.1898 201.49 89.1298 200.17 89.1298Z"
          fill="#F56076"
        />
        <path
          d="M226.98 103.19C226.98 103.19 225.35 103.67 222.34 103.67C219.2 103.67 217.7 103.19 217.7 103.19V58.9102C217.7 58.9102 219.21 58.4302 222.34 58.4302C225.36 58.4302 226.98 58.9102 226.98 58.9102V103.19Z"
          fill="#F56076"
        />
        <path
          d="M244.45 91.4201C244.45 95.5801 246.14 96.5501 249.34 96.5501C250.55 96.5501 251.75 96.3101 251.75 96.3101C251.75 96.3101 252.47 97.7001 252.47 99.9901C252.47 101.86 252.05 103.01 252.05 103.01C250.3 103.49 248.43 103.67 246.62 103.67C238.48 103.67 235.16 100.05 235.16 91.6701V77.9701H230.82C230.82 77.9701 230.4 76.7001 230.4 74.7101C230.4 72.4801 230.82 71.3301 230.82 71.3301H235.16V65.9601C237.51 64.6901 241.19 64.1501 244.45 64.4501V71.3301H250.96C250.96 71.3301 251.38 72.5401 251.38 74.6501C251.38 76.5801 250.96 77.9701 250.96 77.9701H244.45V91.4201V91.4201Z"
          fill="#F56076"
        />
        <path
          d="M280.7 103.19C280.7 103.19 279.13 103.67 276.06 103.67C272.92 103.67 271.42 103.19 271.42 103.19V82.0802C271.42 79.8502 270.33 78.4602 267.56 78.4602C266.05 78.4602 264.6 79.0602 263.4 80.1502V103.19C263.4 103.19 261.77 103.67 258.76 103.67C255.62 103.67 254.12 103.19 254.12 103.19V58.9102C254.12 58.9102 255.63 58.4302 258.76 58.4302C261.78 58.4302 263.4 58.9102 263.4 58.9102V73.9902C265.51 72.0602 268.29 71.1602 271.48 71.1602C277.63 71.1602 280.71 74.7802 280.71 80.1502V103.19H280.7Z"
          fill="#F56076"
        />
      </svg>
    );
  }
}
