import { createSelector } from "reselect";
import { createRiskCategoryForSeriesIdSelector } from "src/selectors/data_selectors/healing_index";

export function createAverageHealingIndexQueryForRiskCategorySelector(
  ownProps
) {
  return createSelector(
    createRiskCategoryForSeriesIdSelector(ownProps),
    riskCategory => {
      return `?risk_category=${riskCategory}&version=0.1`; // 4 or ${riskCategory}
    }
  );
}
