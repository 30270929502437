import axios from "axios";
import { apiFactory } from "../api_factory";

let woundTalliesApi = apiFactory({
  path: "/api/v4/survey/wound-tallies",
  usePostForGet: true
});

// Add export to the api
woundTalliesApi.export = ({ payload }) => {
  return axios.post("/api/v4/survey/wound-tallies/export", payload);
};

export { woundTalliesApi };
