import { connect } from "react-redux";

// Constants
import { sections } from "src/constants";

// Components
import { SavedFilterVisualizationComponent } from "src/components/saved_filter_visualization_component";

// Selectors
import { locationsFilterConfigSelector } from "src/selectors/ui_config_selectors/global";
import { overallProgressConfiguration } from "src/constants/filters_configurations/monitoring";

// UI Processors
import { createSectionSelectors } from "src/ui_processors";

const sectionSelectors = createSectionSelectors(sections.monitoring);

function mapStateToProps(state) {
  // TODO: ADD OTHER FILTERS TO THE UI CONFIG (WOUND TYPE)
  // Locations
  const locationConfig = locationsFilterConfigSelector(state);
  const locationCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    locationConfig
  )(state);

  // Wound Progress
  const woundProgressFilterCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    overallProgressConfiguration
  )(state);

  return {
    filterUiConfigs: [locationConfig, overallProgressConfiguration],
    filterCheckedStates: [locationCheckedState, woundProgressFilterCheckedState]
  };
}

export const MonitoringSavedFilterVisualizationContainer = connect(
  mapStateToProps
)(SavedFilterVisualizationComponent);
