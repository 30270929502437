export function sortByName(arrayObject) {
  return arrayObject.sort(function(valueA, valueB) {
    var sortedValueA = valueA.attributes.name
        ? valueA.attributes.name.toLowerCase()
        : "",
      sortedValueB = valueB.attributes.name
        ? valueB.attributes.name.toLowerCase()
        : "";
    return sortedValueA < sortedValueB
      ? -1
      : sortedValueA > sortedValueB
      ? 1
      : 0;
  });
}

export function sortByNameArray(array) {
  return array.sort(function(valueA, valueB) {
    const sortedValueA = valueA ? valueA.toLowerCase() : "",
      sortedValueB = valueB ? valueB.toLowerCase() : "";
    if (sortedValueA < sortedValueB) return -1;
    else if (sortedValueA > sortedValueB) return 1;
    else return 0;
  });
}
