import { createSelector } from "reselect";
import { dataSelector } from "src/selectors/data_selectors/data_selector";

/**
 * Selector for the change password object in the data state tree
 */
export const changePasswordSelector = createSelector(
  dataSelector,
  data => data.changePassword
);
