export const horizontalBarChart = {
  default: {
    mid: "#aaaaaa",
    light: "#E2E2E2",
    dark: "#202b45"
  },
  medline: {
    mid: "#AAAAAA",
    light: "#E2E2E2",
    dark: "#f16278"
  }
};
