// Constants
import { resourceNames } from "src/constants";

// Api
import { rulesApi } from "src/api/rules";

// Actions
import { createLoadDataAction } from "../create_load_data_action";
import { createPostDataAction } from "../create_post_data_action";
import { createPatchDataAction } from "../create_patch_data_action";
import { create } from "src/actions/flashMessageActions";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

export const loadRulesAction = createLoadDataAction({
  resourceName: resourceNames.rules,
  api: rulesApi
});

export const rulesResourceActions = createResourceActions(
  resourceNames.rules,
  rulesApi
);

export const postAlertAction = createPostDataAction({
  resourceName: resourceNames.rules,
  api: rulesApi,
  onSuccess: dispatch => {
    dispatch(create("Alert Created, Please Refresh to View", "success"));
  },
  onError: dispatch => {
    dispatch(create("Sorry, there was an error creating the alert", "error"));
  }
});

export const patchAlertAction = createPatchDataAction({
  resourceName: resourceNames.rules,
  api: rulesApi,
  onSuccess: dispatch => {
    dispatch(create("Alert Updated", "success"));
  },
  onError: dispatch => {
    dispatch(create("Sorry, there was an error updating the alert", "error"));
  }
});
