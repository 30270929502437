import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//HOC
import { withCsvExportsSubscriber, withResourceLoaderFactory } from "src/hoc";

//Component
import { DownloadManagerListItemComponent } from "src/components/download_manager/download_manager_list_item_component";

//Selectors
import { csvExportsResourceSelectors } from "src/selectors/data_selectors";
import {
  createCsvExportForIdQuerySelector,
  createCsvExportForIdDownloadQuerySelector
} from "src/selectors/query_selectors/csv_exports";
import {
  createCsvExportForIContextSelector,
  createCsvExportForIdDownloadContextSelector
} from "src/selectors/context_selectors/csv_exports";

//Action
import {
  loadCsvExportAction,
  removeCsvExportAction
} from "src/actions/data_actions";

function mapDispatchToProps(dispatch) {
  return {
    removeItem: id => {
      dispatch(removeCsvExportAction({ id }));
    }
  };
}

export const CsvExportDownloadManagerListItemContainer = flowRight(
  withCsvExportsSubscriber,
  withResourceLoaderFactory({
    getResourceAction: loadCsvExportAction,
    resourceSelectors: csvExportsResourceSelectors,
    createContextSelector: createCsvExportForIContextSelector,
    createQuerySelector: createCsvExportForIdQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadCsvExportAction,
    resourceSelectors: csvExportsResourceSelectors,
    createContextSelector: createCsvExportForIdDownloadContextSelector,
    createQuerySelector: createCsvExportForIdDownloadQuerySelector
  }),
  connect(
    undefined,
    mapDispatchToProps
  )
)(DownloadManagerListItemComponent);
