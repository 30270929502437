import { connect } from "react-redux";
import { RevisionSummaryFooterComponent } from "src/components/revision_summary_footer_component";
import {
  createLatestAssessmentAnswerForRevisionSelector,
  createLatestFrameForRevisionSelector,
  createSeriesForRevisionSelector,
  createHealingIndexForRevisionSelector
} from "src/selectors/data_selectors";

import {
  bwatScoreEnabledSelector,
  payerDashboardEnabledSelector,
  visitSequenceReportingEnabledSelector,
  healingIndexDashboardEnabledSelector,
  etiologyVerifiedQuestionEnabledSelector
} from "src/selectors/ui_config_selectors/global";
import { createPayersForRevisionsSelector } from "src/selectors/data_selectors/payers/create_payers_for_revisions_selector";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

function mapStateToProps(state, ownProps) {
  const { revision } = ownProps;
  const revisions = [ownProps.revision];
  return {
    assessmentAnswer: createLatestAssessmentAnswerForRevisionSelector(revision)(
      state
    ),
    assessmentVersion: assessmentVersionSelector(state),
    payersForRevision: createPayersForRevisionsSelector({ revisions })(state),
    healingIndex: createHealingIndexForRevisionSelector(revision)(state),
    frame: createLatestFrameForRevisionSelector(revision)(state),
    series: createSeriesForRevisionSelector(ownProps.revision)(state),
    bwatScoreEnabled: bwatScoreEnabledSelector(state),
    payerDashboardEnabled: payerDashboardEnabledSelector(state),
    visitSequenceEnabled: visitSequenceReportingEnabledSelector(state),
    healingIndexDashboardEnabled: healingIndexDashboardEnabledSelector(state),
    etiologyVerifiedQuestionEnabled: etiologyVerifiedQuestionEnabledSelector(
      state
    )
  };
}

export const RevisionSummaryFooterContainer = connect(mapStateToProps)(
  RevisionSummaryFooterComponent
);
