import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//Constants
import { apiPageLimits } from "src/constants";

// HOC
import { withResourceLoaderFactory } from "src/hoc/factories";

// Components
import { EventHistoryComponent } from "src/components/event_history_component";

// Selectors
import { eventsResourceSelectors } from "src/selectors/data_selectors";
import {
  createFirstPageEventsForRevisionIdContextSelector,
  createCurrentPageEventsForRevisionIdContextSelector,
  createAllPagesEventsForRevisionIdContextSelector
} from "src/selectors/context_selectors/events_for_revision_id";
import { createCurrentPageEventsForRevisionIdQuerySelector } from "src/selectors/query_selectors/events_for_revision_id";

// Actions
import {
  eventsResourceActions,
  loadEventsAction
} from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  const firstPageContextSelector = createFirstPageEventsForRevisionIdContextSelector(
    ownProps
  );
  const currentPageContextSelector = createCurrentPageEventsForRevisionIdContextSelector(
    ownProps
  );
  const allPagesContextSelector = createAllPagesEventsForRevisionIdContextSelector(
    ownProps
  );

  return {
    events: eventsResourceSelectors.createDataForContextSelector(
      allPagesContextSelector
    )(state),
    loadState: eventsResourceSelectors.createLoadStateForContextSelector(
      currentPageContextSelector
    )(state),
    currentPage: eventsResourceSelectors.createCurrentPageForContextSelector(
      firstPageContextSelector
    )(state),
    pageCount:
      eventsResourceSelectors.createPageCountSelector(
        firstPageContextSelector,
        apiPageLimits.events
      )(state) || 0,
    firstPageContext: firstPageContextSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendIncrementPageAction: context => {
      dispatch(eventsResourceActions.incrementPageForContextAction(context));
    }
  };
}

export const RevisionEventHistoryContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createCurrentPageEventsForRevisionIdContextSelector,
    createQuerySelector: createCurrentPageEventsForRevisionIdQuerySelector
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EventHistoryComponent);

// ----------------- NEW IMPLEMENTATION - redux resources v2 -------------------------//

// import flowRight from "lodash.flowright";

// // Constants
// import { apiPageLimits } from "src/constants";

// // Components
// import { EventHistoryComponent } from "src/components/event_history_component";

// // Redux Resources V2 Test
// import ResourceManagers from "src/resources";
// import { eventTypeFilters } from "src/constants";

// export const RevisionEventHistoryContainer = flowRight(
//   ResourceManagers.events.withResource({
//     mapStateToQueryObject: (state, ownProps) => {
//       return {
//         path: "/custom_sub_path",
//         params: {
//           filters: {
//             revision_id: ownProps.revisionId,
//             event_type: eventTypeFilters.revision.join()
//           }
//         },
//         pageConfig: {
//           upToCurrentPage: true,
//           limit: apiPageLimits.events
//         },
//         options: {
//           useGetOverride: true
//         }
//       };
//     }
//   })
// )(EventHistoryComponent);
