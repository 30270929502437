// @flow
import { Record } from "immutable";

export const recordInput = {
  id: "",
  name: "",
  geography: "",
  subject: "",
  createdAt: "",
  updatedAt: ""
};

export default class SeriesModel extends Record(recordInput) {}
