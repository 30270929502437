import { connect } from "react-redux";
import { AdminUsersTableComponent } from "src/components/tables";

//Selectors
import {
  usersResourceSelectors,
  rolesResourceSelectors,
  organizationsResourceSelectors,
  facilitiesResourceSelectors,
  partnersDataSelector,
  customRolesResourceSelectors,
  unitsResourceSelectors
} from "src/selectors/data_selectors";
import {
  selectedUserTablePageNumberSelector,
  selectedUserTablePageLimitSelector
} from "src/selectors/section_selectors/admin";
import { createUsersTableContextSelector } from "src/selectors/api_selectors/admin/users/create_users_table_context_selector";
import { userEditEnabledSelector } from "src/selectors/ui_config_selectors/admin";
import { userDeleteEnabledSelector } from "src/selectors/ui_config_selectors/admin";

//Actions
import {
  selectUserTablePageNumberAction,
  selectUserTablePageLimitAction
} from "src/actions/section_actions/admin";

function mapStateToProps(state) {
  const context = createUsersTableContextSelector()(state);
  return {
    users: usersResourceSelectors.createDataForContextSelectorWithString(
      context
    )(state),
    roles: rolesResourceSelectors.dataSelector(state),
    customRoles: customRolesResourceSelectors.dataSelector(state),
    organizations: organizationsResourceSelectors.dataSelector(state),
    allFacilities: facilitiesResourceSelectors.dataSelector(state),
    partners: partnersDataSelector(state),
    allUnits: unitsResourceSelectors.dataSelector(state),
    selectedUserTablePageNumber: selectedUserTablePageNumberSelector(state),
    selectedUserTablePageLimit: selectedUserTablePageLimitSelector(state),
    userTablePageCount:
      usersResourceSelectors.createPageCountSelector(
        context,
        selectedUserTablePageLimitSelector
      )(state) || 0,
    userEditEnabled: userEditEnabledSelector(state),
    userDeleteEnabled: userDeleteEnabledSelector(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    selectUserTablePage(pageNumber) {
      dispatch(selectUserTablePageNumberAction(pageNumber));
    },
    selectUserTablePageLimit(pageLimit) {
      dispatch(selectUserTablePageLimitAction(pageLimit));
    }
  };
}
export const AdminUsersTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersTableComponent);
