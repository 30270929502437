import { createSelector } from "reselect";

// Selectors
import { createFirstPageSavedFiltersForSectionContextSelector } from "../../../context_selectors/saved_filters";
import { savedFiltersResourceSelectors } from "../../../data_selectors/saved_filters";

// Payload generator
import { createGetPayload } from "src/api/saved_filters/payload_generators";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Constants
import { apiPageLimits } from "src/constants";

/**
 * create a selector to get the api query for the first page for saved filters
 * @param {Object} props
 * @param {string} props.section
 * @returns {function} selector
 */
export function createAllPagesSavedFiltersForSectionQuerySelector({ section }) {
  return createSelector(
    savedFiltersResourceSelectors.createPageCountSelector(
      createFirstPageSavedFiltersForSectionContextSelector({ section }),
      apiPageLimits.savedFilters
    ),
    pageCount => {
      let queries = [
        buildQueryString(
          createGetPayload({
            section,
            pageNumber: 0
          })
        )
      ];

      if (pageCount > 1) {
        for (let i = 1; i < pageCount; i++) {
          queries.push(
            buildQueryString(createGetPayload({ section, pageNumber: i }))
          );
        }
      }

      return queries;
    }
  );
}
