import React from "react";
import PropTypes from "prop-types";

/**
 * Renders a form
 */
export class FormComponent extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  };

  render() {
    return <form onSubmit={this.props.onSubmit}>{this.props.children}</form>;
  }
}
