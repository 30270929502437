import React, { useContext } from "react";
import Moment from "moment";
import DatePicker from "react-datepicker";
import Select from "src/ui-kit/Select";

// Analytics
import { track } from "src/analytics";
import { ComplianceContext } from "./compliance_provider_component";

export function DateRangeFilterComponent() {
  const { state, dispatch } = useContext(ComplianceContext);

  const handleAnalytics = (name, value) => {
    track("DATE_RANGE_FILTER_COMPONENT", {
      name,
      value
    });
  };

  const handleStartDateRangeChange = value => {
    if (value) {
      handleAnalytics("Change Start Date", value.toISOString());
      dispatch({
        type: "selectStartDate",
        payload: { startDate: value }
      });
    }
  };

  const handleEndDateRangeChange = value => {
    if (value) {
      handleAnalytics("Change End Date", value.toISOString());
      dispatch({
        type: "selectEndDate",
        payload: { endDate: value }
      });
    }
  };

  const handleDateRangeChangeUnit = value => {
    handleAnalytics("changeUnit", value);
    let newStartDate = state.startDate;
    let newEndDate = state.endDate;
    let newUnitRange = state.unitRange;
    switch (value) {
      // Month presets
      case "monthToDate":
        newStartDate = Moment().startOf("month");
        newEndDate = Moment().endOf("day");
        newUnitRange = value;
        break;
      case "pastMonth":
        newStartDate = Moment()
          .subtract(1, "months")
          .startOf("month");
        newEndDate = Moment()
          .subtract(1, "months")
          .endOf("month");
        newUnitRange = value;
        break;
      case "past90Days":
        // NOTE: This is inclusive of the current day
        newStartDate = Moment()
          .subtract(89, "days")
          .startOf("day");
        newEndDate = Moment().endOf("day");
        newUnitRange = value;
        break;

      // Week presets
      case "weekToDate":
        // NOTE: Start of week considered to be Mon at 12am
        newStartDate = Moment().startOf("isoWeek");
        newEndDate = Moment().endOf("day");
        newUnitRange = value;
        break;
      case "pastWeek":
        // NOTE: Start of week considered to be Mon at 12am
        newStartDate = Moment()
          .startOf("isoWeek")
          .subtract(1, "week");
        newEndDate = Moment()
          .endOf("isoWeek")
          .subtract(1, "week");
        newUnitRange = value;
        break;
      case "past7Days":
        // NOTE: This is inclusive of the current day
        newStartDate = Moment()
          .subtract(6, "days")
          .startOf("day");
        newEndDate = Moment().endOf("day");
        newUnitRange = value;
        break;

      case "pastQuarter":
        newStartDate = Moment()
          .subtract(1, "quarter")
          .startOf("quarter");
        newEndDate = Moment()
          .subtract(1, "quarter")
          .endOf("quarter");
        newUnitRange = value;
        break;
      case "quarterToDate":
        newStartDate = Moment().startOf("quarter");
        newEndDate = Moment().endOf("day");
        newUnitRange = value;
        break;
      case "custom":
        newUnitRange = value;
        break;
    }
    dispatch({
      type: "selectUnitDateRange",
      payload: {
        startDate: newStartDate,
        endDate: newEndDate,
        unitRange: newUnitRange
      }
    });
  };

  const isBeforeEndDate = date => {
    const allowSameDayRange = true;
    if (allowSameDayRange) {
      return new Moment(date).isSameOrBefore(state.endDate);
    } else {
      return new Moment(date).isBefore(state.endDate);
    }
  };

  const isAfterStartDate = date => {
    const allowSameDayRange = true;
    if (allowSameDayRange) {
      return new Moment(date).isSameOrAfter(state.startDate);
    } else {
      return new Moment(date).isAfter(state.startDate);
    }
  };

  const format = "MMMM D, YYYY";

  return (
    <div>
      <span className="date-picker-range-first-element">
        <Select
          onChange={handleDateRangeChangeUnit}
          value={state.unitRange}
          options={[
            { value: "monthToDate", label: "Month to Date" },
            { value: "pastMonth", label: "Past Month" },
            { value: "past90Days", label: "Past 90 Days" },

            { value: "weekToDate", label: "Week to Date" },
            { value: "pastWeek", label: "Past Week" },
            { value: "past7Days", label: "Past 7 Days" },

            { value: "pastQuarter", label: "Past Quarter" },
            { value: "quarterToDate", label: "Quarter to Date" },

            { value: "custom", label: "Custom" }
          ]}
        />
      </span>
      <span className="date-picker-range-element">
        <DatePicker
          filterDate={isBeforeEndDate}
          dateFormat={format}
          selected={state.startDate}
          onChange={handleStartDateRangeChange}
          className="form-control"
        />
      </span>
      <span className="date-picker-range-element">to</span>
      <span className="date-picker-range-last-element">
        <DatePicker
          filterDate={isAfterStartDate}
          dateFormat={format}
          selected={state.endDate}
          onChange={handleEndDateRangeChange}
          className="form-control"
        />
      </span>
    </div>
  );
}
