import { apiFactory } from "../api_factory";

export * from "./payload_generators";

const rulesEnginePath = "/api/v4/rules-engine";

export const ruleTemplatesApi = apiFactory({
  path: `${rulesEnginePath}/rule-templates/`
});

export const rulesApi = apiFactory({
  path: `${rulesEnginePath}/rules`
});
