import { createSelector } from "reselect";
import { isSwiftAdminSelector } from "src/selectors/config_selectors/is_swift_admin_selector";
import { hasManageRulesPermissionSelector } from "src/selectors/config_selectors/has_manage_rules_permission_selector";

export const adminAlertsSectionEnabledSelector = createSelector(
  isSwiftAdminSelector,
  hasManageRulesPermissionSelector,
  (isSwiftAdmin, hasManageRulesPermission) => {
    return isSwiftAdmin || hasManageRulesPermission;
  }
);
