export function sortQueryObject(queryObject) {
  if (isObject(queryObject)) {
    return sortObject(queryObject);
  } else {
    return {};
  }
}

function isObject(param) {
  if (Array.isArray(param)) {
    return false;
  } else {
    return param === Object(param);
  }
}

export function sortObject(obj) {
  let sortedObj = {},
    keys = Object.keys(obj).sort();
  keys.map(key => {
    if (Array.isArray(obj[key])) {
      sortedObj[key] = sortArray(obj[key]);
    } else if (isObject(obj[key])) {
      sortedObj[key] = sortObject(obj[key]);
    } else {
      sortedObj[key] = obj[key];
    }
  });
  return sortedObj;
}

export function sortArray(arr) {
  let sortedArr = arr.sort();
  sortedArr.forEach((item, index) => {
    if (Array.isArray(item)) {
      sortedArr[index] = sortArray(item);
    } else if (isObject(item)) {
      sortedArr[index] = sortObject(item);
    }
  });
  sortedArr = moveObjectsToBeginningOfArray(sortedArr);
  sortedArr = sortedArr.sort((item1, item2) => {
    if (JSON.stringify(item1) > JSON.stringify(item2)) {
      return 1;
    } else if (JSON.stringify(item1) < JSON.stringify(item2)) {
      return -1;
    }
  });
  return sortedArr;
}

export function moveObjectsToBeginningOfArray(arr) {
  const newArray = [];
  arr.forEach(item => {
    if (isObject(item)) {
      newArray.unshift(item);
    } else {
      newArray.push(item);
    }
  });
  return newArray;
}
