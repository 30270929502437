import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Selectors
import { patientsResourceSelectors } from "src/selectors/data_selectors";

//Actions
import { loadPatientsByQueryStringAction } from "src/actions/data_actions";

/**
 * HOC that is responsible for loading a list of patients for given query parameters
 * @param {function} querySelector Selector that returns the query string (context)
 * @param {function} payloadSelector Selector that returns the params object for records filtering
 * @return {function} Higher Order Component function wrapped by the loader component
 */

export function withPatientsLoaderFactory(querySelector, payloadSelector) {
  /**
   * HOC that passes in the loaded patients to the component dependent on the data
   * @param {Class} WrappedComponent the component dependent on this data
   * @return {Class} Component wrapped by the loader component
   */
  return function withPatientsLoader(WrappedComponent) {
    class PatientsLoader extends React.Component {
      static propTypes = {
        context: PropTypes.string.isRequired,
        sendloadPatientsActionLegacy: PropTypes.func.isRequired
      };

      componentWillMount() {
        //Load patients for the context if not already loaded
        if (!this.props.contextLoadState) {
          this.loadPatients(this.props);
        }
      }

      componentWillReceiveProps(nextProps) {
        if (this.props.context !== nextProps.context) {
          this.loadPatients(nextProps);
        }
      }

      loadPatients(props) {
        const { sendloadPatientsActionLegacy, context, payload } = props;
        sendloadPatientsActionLegacy(context, payload);
      }

      render() {
        return <WrappedComponent />;
      }
    }

    function mapStateToProps(state) {
      return {
        context: querySelector(state),
        contextLoadState: patientsResourceSelectors.createContextLoadStateSelectorWithContextString(
          querySelector(state)
        )(state),
        patients: patientsResourceSelectors.dataSelector(state),
        payload: payloadSelector(state)
      };
    }

    function mapDispatchToProps(dispatch) {
      return {
        sendloadPatientsActionLegacy(context, payload) {
          dispatch(loadPatientsByQueryStringAction(context, payload));
        }
      };
    }
    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(PatientsLoader);
  };
}
