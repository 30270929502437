import moment from "moment";

/**
 * Find time elapsed since date for table cells
 * @param {string} cell days since date
 */
export function daysElapsedFormatter(cell) {
  let timeElapsed = moment(cell).from();
  return cell ? timeElapsed : "";
}
