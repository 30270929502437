import { createSelector } from "reselect";
import i18n from "src/translations";
import { hasLockWoundRevisionsPermissionSelector } from "src/selectors/ui_config_selectors/patients";

export function createLockTooltipSelector() {
  return createSelector(
    hasLockWoundRevisionsPermissionSelector,
    canLockWoundRevisions => {
      const missingPermissionsText = i18n.t(
        "text.revisionButtonTooltips.missingPermissions"
      );
      return !canLockWoundRevisions ? missingPermissionsText : "";
    }
  );
}
