export const acquiredConfigurationDefault = {
  assessmentVersion: "default",
  filterName: "acquired",
  filterValues: [
    {
      key: "acquired",
      value: "inHouse"
    },
    {
      key: "acquired",
      value: "externally"
    }
  ]
};

export const acquiredConfigurationV5Ashn = {
  assessmentVersion: "v5Ashn",
  filterName: "acquired",
  filterValues: [
    {
      key: "acquired",
      value: "inHouse"
    },
    {
      key: "acquired",
      value: "externally"
    }
  ]
};
