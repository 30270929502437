import { createSavedFilterFormContainer } from "./create_saved_filter_form_container";
import { sections } from "src/constants";

export const ComplianceSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.compliance
);

export const OutcomesSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.outcomes
);

export const WoundsSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.wounds
);

export const PopulationSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.population
);

export const RiskSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.risk
);

export const SurveySavedFilterFormContainer = createSavedFilterFormContainer(
  sections.survey
);

export const MonitoringSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.monitoring
);

export const AlertsSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.alerts
);

export const EvaluationListWoundSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.evaluationListWound
);

export const EvaluationListBradenSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.evaluationListBraden
);

export const EvaluationListPURSSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.evaluationListPURS
);

export const EvaluationListTotalSkinSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.evaluationListTotalSkin
);

export const WoundRiskWoundSavedFilterFormContainer = createSavedFilterFormContainer(
  sections.woundRisk
);
