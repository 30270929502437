import React from "react";
import get from "lodash.get";

// Constants
import { assessmentTypes } from "src/constants";
import { Link } from "react-router-dom";

/**
 * cell formatter to translate wound type and subtype
 * @param {object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.type the assessment type
 * @param {Object} row.attributes.answersJson the answers json
 * @param {number} rowIndex
 */
export function pursScoreFormatter(cell, row) {
  const attributes = row.attributes;
  // Return N/A for non purs assessments
  if (attributes.type != assessmentTypes.purs) return "N/A";

  let score = get(row, "attributes.answersJson.pursTotal", null);
  let patientId = get(row, "attributes.patientId", null);
  let assessmentId = get(row, "attributes.assessmentId", null);
  return (
    <Link to={`/patients/${patientId}/assessments/${assessmentId}`}>
      {score}
    </Link>
  );
}
