import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// component
import { NonWoundAssessmentListItemComponent } from "src/components/non_wound_assessment_list_item_component";

// selectors
import { createAssessmentSchemaForAssessmentAnswerSelector } from "src/selectors/data_selectors";

function mapStateToProps(state, ownProps) {
  return {
    assessmentSchema: createAssessmentSchemaForAssessmentAnswerSelector(
      ownProps.assessmentAnswer
    )(state)
  };
}

export const NonWoundAssessmentListItemContainer = withRouter(
  connect(mapStateToProps)(NonWoundAssessmentListItemComponent)
);
