/**
 * Get color for a measurement change (ex. red for bigger wound)
 * @param {number|null} delta
 * @returns {string} color
 */
export function colorForMeasurementChange(delta) {
  if (!delta) {
    return "#777777";
  } else if (delta > 0) {
    return "#d9534f";
  } else {
    return "#5cb85c";
  }
}
