import { apiPageLimits } from "src/constants";

export function createGetCliniciansPayload({ pageNumber }) {
  return {
    filter: {
      has_created_assessment: true,
      patient_users: "false"
    },
    page: {
      offset: pageNumber * apiPageLimits.users,
      limit: apiPageLimits.users
    },
    sort: "first_name,last_name"
  };
}
