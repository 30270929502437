const LINE_TENSION = 0.075;

/**
 * Create a chart.js chart definition object for rendering a line chart showing
 * @param {Object} chartData
 */
export function createPopulationLineChart(
  incidencePrevalenceChartData,
  timeStepFilter
) {
  // Labels
  let labels = incidencePrevalenceChartData.labels;

  //title x-axis
  let xAxisTitle = "Day";

  switch (timeStepFilter) {
    case "week":
      xAxisTitle = "Week (Monday – Sunday)";
      break;
    case "month":
      xAxisTitle = "Month";
      break;
    default:
      xAxisTitle;
      break;
  }

  // datasets
  const datasets = [
    {
      label: "Incidence",
      backgroundColor: "rgba(151,187,205,0)",
      borderColor: "#0700be",
      pointBackgroundColor: "#0700be",
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data: incidencePrevalenceChartData.incidenceData,
      tension: LINE_TENSION
    },
    {
      label: "Prevalence",
      backgroundColor: "rgba(151,187,205,0)",
      borderColor: "#db0a5b",
      pointBackgroundColor: "#db0a5b",
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data: incidencePrevalenceChartData.prevalenceData,
      tension: LINE_TENSION
    }
  ];
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            type: "linear",
            callback: value => {
              if (Number.isInteger(value)) {
                return `${value}%`;
              } else {
                return null;
              }
            }
          }
        }
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: xAxisTitle,
            fontStyle: "500"
          }
        }
      ]
    },
    legend: {
      display: true
    },
    tooltips: {
      mode: "single",
      callbacks: {
        label: (item: Object) => {
          return [`${item.yLabel}% `];
        }
      }
    }
  };

  return {
    type: "line",
    data: {
      labels,
      datasets
    },
    options
  };
}
