import { createSelector } from "reselect";

// Payload generator
import { createGetPayload } from "src/api/revisions/payload_generators";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Constants
import { apiPageLimits } from "src/constants";

// Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { createFirstPageRevisionComparisonContextSelector } from "..";
import { visibleEvaluationStatesPermissionSelector } from "src/selectors/config_selectors/visible_evaluation_states_permission";

/**
 * create a selector to get the api query for all pages for revision comparison
 * @param {Object} props
 * @param {string} props.seriesId
 * @returns {function} selector
 */
export function createAllPageRevisionComparisonQuerySelector({ seriesId }) {
  return createSelector(
    revisionsResourceSelectors.createPageCountSelector(
      createFirstPageRevisionComparisonContextSelector({ seriesId }),
      apiPageLimits.revisions
    ),
    visibleEvaluationStatesPermissionSelector,
    (pageCount, assessmentStateFilters) => {
      let { lockState } = assessmentStateFilters;
      let queries = [
        `${seriesId}/revisions${buildQueryString(
          createGetPayload({
            pageNumber: 0,
            lockState,
            strikeout_state: "open",
            deleted: "false",
            fields: "created_at,closure",
            sort: "created_at"
          })
        )}`
      ];

      if (pageCount > 1) {
        for (let i = 1; i < pageCount; i++) {
          queries.push(
            `${seriesId}/revisions${buildQueryString(
              createGetPayload({
                pageNumber: i,
                lockState,
                strikeout_state: "open",
                deleted: "false",
                fields: "created_at,closure",
                sort: "created_at"
              })
            )}`
          );
        }
      }
      return queries;
    }
  );
}
