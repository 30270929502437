import PropTypes from "prop-types";
import React from "react";

export class FormInputOptionComponent extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    text: PropTypes.string.isRequired
  };

  render() {
    return <option value={this.props.value}>{this.props.text}</option>;
  }
}
