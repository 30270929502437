import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { propsAreDifferent } from "src/utils/props_are_different";
import { withRouter } from "react-router-dom";
// Selectors
import {
  selectedWoundTypesSelector,
  selectedLocationIdsSelector,
  dateSelectionSelector,
  acquiredFilterSelector
} from "src/selectors/section_selectors/population";

// Actions
import { loadPopulationTalliesByLocationAction } from "src/actions/data_actions";
/*
 * HOC that is responsible for loading population tallies data
 * @param {Class} WrappedComponent the component dependent on this data
 * @return {Class} Component wrapped by the loader component
 */
export function withPopulationTalliesByLocationDataLoader(WrappedComponent) {
  class PopulationTalliesByLocationDataLoader extends React.Component {
    static propTypes = {
      selectedWoundTypes: PropTypes.object.isRequired,
      selectedLocationIds: PropTypes.array.isRequired,
      sendLoadTalliesByLocationDataAction: PropTypes.func.isRequired,
      dateSelection: PropTypes.object.isRequired,
      acquiredFilter: PropTypes.string.isRequired
    };

    componentWillMount() {
      // TODO: Keep track of location loading state and use that
      if (this.props.selectedLocationIds.length) {
        this.loadPopulationTalliesByLocationData(this.props);
      }
    }

    componentWillReceiveProps(nextProps) {
      if (
        propsAreDifferent(
          this.props,
          nextProps,
          "selectedLocationIds",
          "selectedWoundTypes.woundTypes",
          "dateSelection.startDate",
          "dateSelection.endDate",
          "acquiredFilter"
        )
      ) {
        this.loadPopulationTalliesByLocationData(nextProps);
      }
    }

    loadPopulationTalliesByLocationData(props) {
      const { sendLoadTalliesByLocationDataAction } = this.props;

      const queryParams = constructQueryParams(props);

      // Load the table data
      sendLoadTalliesByLocationDataAction(queryParams);
    }

    render() {
      return <WrappedComponent />;
    }
  }

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(PopulationTalliesByLocationDataLoader)
  );
}

/**
 * Map the props needed for loading data
 */
function mapStateToProps(state) {
  return {
    selectedWoundTypes: selectedWoundTypesSelector(state),
    selectedLocationIds: selectedLocationIdsSelector(state),
    dateSelection: dateSelectionSelector(state),
    acquiredFilter: acquiredFilterSelector(state)
  };
}

/**
 * Map dispatch to a props for loading data
 */
function mapDispatchToProps(dispatch) {
  return {
    sendLoadTalliesByLocationDataAction(queryParams) {
      dispatch(loadPopulationTalliesByLocationAction(queryParams));
    }
  };
}

/**
 * Function that constructs the query string for loading data using the required props
 * @param {Object} props the loader components props
 * @return {Object} the query params
 */
function constructQueryParams(props) {
  const {
    selectedLocationIds,
    selectedWoundTypes,
    dateSelection,
    acquiredFilter
  } = props;
  let acquired;
  if (acquiredFilter) acquired = acquiredFilter == "externally" ? "POA" : "IHA";

  // Build the query params
  return {
    woundTypes: selectedWoundTypes.woundTypes,
    locationIds: selectedLocationIds,
    startDate: dateSelection.startDate.toISOString(),
    endDate: dateSelection.endDate.toISOString(),
    acquired: acquired,
    tallyType: "location",
    woundSubtypes: selectedWoundTypes.subtypes
  };
}
