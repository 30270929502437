export const attributesByResource = {
  revisions: {
    allAttributesWithoutNestedAssessmentAnswers: [
      "area",
      "area_converted",
      "closure",
      "created_at",
      "delta_area",
      "delta_area_percent",
      "delta_depth",
      "delta_depth_percent",
      "delta_height",
      "delta_height_percent",
      "delta_tunnelling",
      "delta_tunnelling_percent",
      "delta_undermining",
      "delta_undermining_percent",
      "delta_width",
      "delta_width_percent",
      "depth_converted",
      "height",
      "height_converted",
      "is_earliest_revision_prd",
      "is_prd",
      "is_previous_revision_prd",
      "max_depth",
      "max_tunnelling",
      "max_undermining",
      "revision_type",
      "series_id",
      "tunnelling_converted",
      "tunnellings",
      "undermining_converted",
      "underminings",
      "updated_at",
      "user_id",
      "width",
      "width_converted",
      "frames"
    ]
  },
  series: {
    allAttributesWithoutNestedResources: [
      "created_at",
      "geography",
      "name",
      "origin_at",
      "patient_wound_number",
      "study_id",
      "subject",
      "total_revisions",
      "updated_at",
      "user_id",
      "wound_key"
    ]
  }
};
