import { createSelector } from "reselect";

const urlSelector = () => window.location.href;

export const isMedlineSelector = createSelector(
  urlSelector,
  url => {
    const subdomain = url.split(/swiftmedical./)[0];
    return subdomain.includes("medline") || subdomain.includes("ne1");
  }
);
