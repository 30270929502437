import { styles } from "../../pdf_styles";
import { margins } from "../../margins";

// Translations
import i18n from "src/translations";

// Model
import {
  getWoundTypeStringForTally,
  getSubtypeStringForTally
} from "src/models/wound_tallies";

/**
 * Get table for pdf
 * @param {Object} tallies
 * @return {Object} returns null if table is empty
 */
export function getEtiologyTable(
  tallies,
  schemaLocalizationDictionary,
  subtypeKeysForSchema
) {
  // Left, top, right, bottom
  // page margins built into the table (no margin applied to the page)
  const cellMargin = [4, 4, 4, 4];
  const leftCellMargin = [margins.defaultPageMargin, 4, 4, 4];
  const rightCellMargin = [4, 4, margins.defaultPageMargin, 4];

  let body = [];
  const talliesData = Object.values(tallies);

  for (let i = 0; i < talliesData.length; i++) {
    const woundTypeString = getWoundTypeStringForTally(
      talliesData[i],
      schemaLocalizationDictionary
    );
    const woundSubtypeString = getSubtypeStringForTally(
      talliesData[i],
      schemaLocalizationDictionary,
      subtypeKeysForSchema
    );

    const cellValues = (textValue, alignment, margin) => {
      return {
        text: textValue,
        style: styles.tallyTableContentCell,
        alignment: alignment,
        margin: margin || cellMargin
      };
    };
    body.push([
      cellValues(woundTypeString, "left", leftCellMargin),
      cellValues(woundSubtypeString, "left"),
      cellValues(talliesData[i].attributes.woundResidentCount, "center"),
      cellValues(talliesData[i].attributes.newPoaCount, "center"),
      cellValues(talliesData[i].attributes.newIhaCount, "center"),
      cellValues(talliesData[i].attributes.newIhawCount, "center"),
      cellValues(talliesData[i].attributes.newPihawCount, "center"),
      cellValues(talliesData[i].attributes.newTotal, "center"),
      cellValues(talliesData[i].attributes.existingPoaCount, "center"),
      cellValues(talliesData[i].attributes.existingIhaCount, "center"),
      cellValues(talliesData[i].attributes.existingIhawCount, "center"),
      cellValues(talliesData[i].attributes.existingPihawCount, "center"),
      cellValues(talliesData[i].attributes.existingTotal, "center"),
      cellValues(
        talliesData[i].attributes.newAcquiredUnknownCount +
          talliesData[i].attributes.existingAcquiredUnknownCount,
        "center"
      ),
      cellValues(talliesData[i].attributes.totalExcludingResolved, "center"),
      cellValues(
        talliesData[i].attributes.totalResolved,
        "center",
        rightCellMargin
      )
    ]);
  }

  const headerCells = [
    {
      text: "Wound\nType",
      style: styles.tallyTableHeaderCell,
      margin: leftCellMargin
    },
    {
      text: "Wound\nSubtype",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Wound\nResidents",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `New\n${i18n.t("data.acquired.default.POA")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `New\n${i18n.t("data.acquired.default.IHA")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `New\n${i18n.t("data.acquired.default.IHAw")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `New\n${i18n.t("data.acquired.default.PIHAw")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Total\nNew",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `Existing\n${i18n.t("data.acquired.default.POA")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `Existing\n${i18n.t("data.acquired.default.IHA")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `Existing\n${i18n.t("data.acquired.default.IHAw")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: `Existing\n${i18n.t("data.acquired.default.PIHAw")}`,
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Total\nExisting",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Acquired\nNot Set",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Total Active\nWounds",
      style: styles.tallyTableHeaderCell,
      margin: cellMargin
    },
    {
      text: "Total\nResolved",
      style: styles.tallyTableHeaderCell,
      margin: rightCellMargin
    }
  ];

  // don't show empty table
  if (!body.length) return null;

  // Construct table
  return {
    table: {
      headerRows: 1,
      // Total width should add up to page size (792)
      // Note - this ignores table border width (negligible) 6
      widths: [113, 93, 44, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 42, 46, 54],
      body: [headerCells, ...body]
    },
    layout: styles.tallyTable
  };
}
