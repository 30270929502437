import i18n from "src/translations";
import get from "lodash.get";

import { woundEvaluationHyperlinkFormatter } from "../wound_evaluation_hyperlink_formatter";

// Constants

/**
 * cell formatter for assessment type
 * @param {Object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.type the assessment type
 * @param {Object} row.attributes.answersJson
 * @param {string} row.attributes.series_id
 * @param {string} row.attributes.revision_id
 */
export function assessmentTypeFormatter(cell, row, rowIndex, formatExtraData) {
  const type = get(row, "attributes.type");

  if (!type) return "";

  const typeString = i18n.t(`data.evaluations.types.${type}`);
  return woundEvaluationHyperlinkFormatter(typeString, row, formatExtraData);
}
