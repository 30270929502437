import { connect } from "react-redux";

// Constants
import { sections } from "src/constants";

// Component
import { CheckboxFilterComponent } from "src/components/filters";

// Selectors
import { locationsFilterConfigSelector } from "src/selectors/ui_config_selectors/global";
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";

// Actions
import { createSectionActions } from "src/ui_processors/global/action_creators";

// State
function mapStateToProps(state) {
  return {
    filterConfig: locationsFilterConfigSelector(state),
    filterCheckedState: createSectionSelectors(
      sections.evaluationListPURS
    ).createFilterCheckedStateSelector(locationsFilterConfigSelector(state))(
      state
    )
  };
}

// Dispatch Actions
function mapDispatchToProps(dispatch) {
  return {
    selectCheckbox: filterObject => {
      dispatch(
        createSectionActions(sections.evaluationListPURS).selectCheckbox(
          filterObject
        )
      );
    },
    deselectCheckbox: filterObject => {
      dispatch(
        createSectionActions(sections.evaluationListPURS).deselectCheckbox(
          filterObject
        )
      );
    }
  };
}

export const SideBarLocationsFilterContainerEvaluationListPURS = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckboxFilterComponent);
