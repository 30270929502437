import React from "react";
import get from "lodash.get";
import FontAwesome from "react-fontawesome";

export function assessmentLockStatusFormatter(cell, row) {
  let status = get(row, "status", null);
  if (status == "loading") {
    return (
      <div>
        <FontAwesome name="spinner" size="2x" spin />
      </div>
    );
  } else if (status == 200)
    return (
      <div>
        <FontAwesome name="check" size="2x" color="green" />
      </div>
    );
  else if (status !== "" && status)
    return (
      <div>
        <FontAwesome name="times" size="2x" color="red" />
      </div>
    );
  else {
    return <div className="text-center"></div>;
  }
}
