/**
 * Convert a length measurement to display value, for a length between 0 and 0.1, return < 0.1
 * @param {number} value
 * @returns {string} the display value
 */
export function displayValueForLength(value) {
  let displayValue = "--";
  if (value || value == 0) {
    displayValue = `${value} cm`;
  }
  if (value < 0.1 && value > 0) {
    displayValue = "< 0.1 cm";
  }
  return displayValue;
}
