import React from "react";
import moment from "moment";
import get from "lodash.get";
import i18n from "src/translations";

// Constants
import {
  incompletePushScoreValues,
  incompleteBwatScoreValues
} from "src/constants";

// Model
import {
  displayValueForArea,
  displayValueForLength
} from "src/models/revisions";

// Utils
import { createListStringFromArray } from "src/utils/format_string_list_from_array";
import { getSubtypeStringForAnswersJson } from "src/utils/get_wound_subtype_string";

export const cellFormatters = {
  evaluationDateFormatter: (cell, row) => {
    return row.attributes.createdAt
      ? moment(row.attributes.createdAt).format("MMM DD, YYYY")
      : "--";
  },

  progressFormatter: (cell, row, assessmentVersion) => {
    let progressString = "Not Set";
    const progress = get(
      row,
      "attributes.assessmentAnswers[0].answersJson.overallProgress",
      null
    );
    if (progress) {
      progressString = i18n.t(
        `data.overallProgress.${assessmentVersion}.${progress}`
      );
    }
    return progressString;
  },

  woundTypeFormatter: (cell, row, localizationDictionary) => {
    const woundType = get(
      row,
      "attributes.assessmentAnswers[0].answersJson.woundType",
      null
    );

    const woundTypeString = woundType
      ? localizationDictionary[`woundType.${woundType}`]
      : i18n.t("text.notSet");

    return woundTypeString;
  },

  subTypeFormatter: (
    cell,
    row,
    localizationDictionary,
    subtypeKeysForSchema
  ) => {
    const woundType = get(
      row,
      "attributes.assessmentAnswers[0].answersJson.woundType",
      null
    );

    if (!woundType) return "N/A";

    const subtypeString = getSubtypeStringForAnswersJson({
      woundType,
      answersJson: get(row, "attributes.assessmentAnswers[0].answersJson"),
      localizationDictionary,
      subtypeKeysForSchema
    });

    return subtypeString || "N/A";
  },

  areaFormatter: (cell, row) => {
    const area = get(row, "attributes.area", null);
    return displayValueForArea(area);
  },

  lengthFormatter: (cell, row) => {
    return displayValueForLength(get(row, "attributes.height", null));
  },

  widthFormatter: (cell, row) => {
    return displayValueForLength(get(row, "attributes.width", null));
  },

  depthFormatter: (cell, row) => {
    return displayValueForLength(get(row, "attributes.maxDepth", null));
  },

  pushScoreFormatter: (cell, row) => {
    let pushScore = get(
      row,
      `attributes.assessmentAnswers[0].answersJson.pushScore`,
      "INC"
    );

    if (incompletePushScoreValues.includes(pushScore)) {
      pushScore = "INC";
    }

    return pushScore;
  },

  bwatScoreFormatter: (cell, row) => {
    let bwatScore = get(
      row,
      `attributes.assessmentAnswers[0].answersJson.bwatScore`,
      "INC"
    );

    if (incompleteBwatScoreValues.includes(bwatScore)) {
      bwatScore = "INC";
    }

    return bwatScore;
  },

  cleansingSolutionFormatter: (cell, row, localizationDictionary) => {
    let cleansingString = "N/A";
    const cleaned = get(
      row,
      "attributes.assessmentAnswers[0].answersJson.cleaned",
      null
    );

    if (cleaned) {
      const cleanedTranslated = cleaned.map(
        soln => localizationDictionary[`cleaned.${soln}`]
      );
      cleansingString = createListStringFromArray(cleanedTranslated);
    }

    return cleansingString;
  },

  primaryDressingFormatter: (cell, row, localizationDictionary) => {
    let primaryDressingString = "N/A";
    const primaryDressing = get(
      row,
      "attributes.assessmentAnswers[0].answersJson.primaryDressing",
      null
    );

    if (primaryDressing) {
      const primaryDressingTranslated = primaryDressing.map(
        dressn => localizationDictionary[`primaryDressing.${dressn}`]
      );
      primaryDressingString = createListStringFromArray(
        primaryDressingTranslated
      );
    }

    return primaryDressingString;
  },

  encounterFormatter: (cell, row, encounterNamingConvention) => {
    const assessmentAnswer = get(row, "attributes.assessmentAnswers[0]", null);
    const encounterNumber = get(assessmentAnswer, "encounterNumber", null);
    const encounterStartDate = get(assessmentAnswer, "encounterStartAt", null);
    let encounterStartDateString = encounterStartDate
      ? moment(encounterStartDate).format("MMM DD, YYYY")
      : "N/A";
    return encounterNumber ? (
      <>
        {encounterNamingConvention}: {encounterNumber}
        <br />
        <br />
        {i18n.t("data.encounters.startDateString")}: {encounterStartDateString}
      </>
    ) : (
      i18n.t("data.encounters.unknown")
    );
  }
};
