/**
 * Get a bullet list string for an array of strings
 * @param {array} strings
 * @returns {string}
 */
export function bulletListStringFromStrings(strings) {
  return strings.reduce((agg, str) => {
    return `${agg}\u2022 ${str}\n`;
  }, "");
}
