import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Component
import { SideBarPatientsFilterComponent } from "src/components/side_bar_patients_filter_component";

// Selectors
import { patientSearchStringSelector } from "src/selectors/section_selectors/patients";

// Actions
import { setPatientSearchStringAction } from "src/actions/section_actions/patients";

function mapStateToProps(state) {
  return {
    searchString: patientSearchStringSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetPatientSearchStringAction(searchString) {
      dispatch(setPatientSearchStringAction(searchString));
    }
  };
}

export const SideBarPatientsFilterContainer = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SideBarPatientsFilterComponent);
