// @flow
import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import uuid from "uuid/v1";
import Chart from "chart.js";
import { track as pretrack } from "src/analytics";

const track = name => pretrack(`HOVER_CHART:${name}`);

export default class extends PureComponent {
  uuid: string;
  canvas: any;
  chart: object;
  componentWillMount() {
    this.uuid = uuid();
  }
  componentDidMount() {
    this.renderChart(this.props);
  }
  componentWillReceiveProps(nextProps: Object) {
    this.renderChart(nextProps);
  }
  renderChart = (props: Object) => {
    const canvas = findDOMNode(this.canvas);
    const ctx = canvas.getContext("2d");
    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(ctx, {
      type: props.type || "line",
      data: props.data,
      options: props.options
    });
  };
  render() {
    return (
      <canvas
        onMouseOver={() => track(this.props.type || "line")}
        ref={canvas => (this.canvas = canvas)}
        id={this.uuid}
        width={this.props.width}
        height={this.props.height}
        className={this.props.className}
      />
    );
  }
}
