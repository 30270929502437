import axios from "axios";
import { registerForCancellation } from "../../../cancellation";

/**
 * Fetch the wound progress trend data for the wounds dashboard graph
 * @param {Object} payload the filters for loading wound counts
 * wound_type_filter
 * location_ids
 * start_time
 * end_time
 * in_house_acquired_filter
 * pressure_ulcer_stage_filter
 * pressure_ulcer_unstageable_filter
 * skin_tear_filter
 * burn_degree_filter
 * surgical_closure_method
 * other_surgical_method
 * masd_subtype
 * bruise_type
 * @return {Promise}
 */
export function fetchWoundAssessmentProgressChartData(payload) {
  const config = {
    cancelToken: registerForCancellation("wounds_dashboard/clinical_chart"),
    headers: {
      "X-HTTP-Method-Override": "GET"
    }
  };
  return axios.post("/api/v2/wounds_dashboard/clinical_chart", payload, config);
}

/**
 * Fetch the wound progress trend data for the wounds dashboard graph
 * @param {Object} queryParams the filters for loading wound counts
 * wound_type_filter
 * start_time
 * end_time
 * in_house_acquired_filter
 * pressure_ulcer_stage_filter
 * pressure_ulcer_unstageable_filter
 * skin_tear_filter
 * burn_degree_filter
 * surgical_closure_method
 * other_surgical_method
 * masd_subtype
 * bruise_type
 * @return {Promise}
 */
export function fetchWoundTotalsTimeSeriesByLocation(queryParams, locationId) {
  const payload = {
    ...queryParams,
    location_ids: locationId
  };

  const url = "/api/v2/wounds_dashboard/administrative_chart";

  const config = {
    cancelToken: registerForCancellation(
      `wounds_dashboard/administrative_chart/${locationId}`
    ),
    headers: {
      "X-HTTP-Method-Override": "GET"
    }
  };

  return axios.post(url, payload, config);
}
