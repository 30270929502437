import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import i18n from "src/translations";

//Actions
import { getEncountersForPatientId } from "src/actions/data_actions/encounters";

//Components
import { FormTitleComponent } from "../forms/form_title_component";
import { LoadingComponent } from "src/components/loading_component";
import EncounterDropdownComponent from "./encounter_dropdown_component";

//Selectors
import { encounterNamingConventionSelector } from "src/selectors/ui_config_selectors/global/encounter_naming_convention_selector";
import { encountersResourceSelectors } from "../../selectors/data_selectors/encounters/encounters_resource_selector";

//Containers
import { SignAssessmentAnswerFormContainer } from "src/containers/forms/sign_assessment_answer_form_container";

export const EncounterModalComponent = props => {
  const {
    cancel,
    assessmentAnswerId,
    actionType,
    encounterNamingConvention,
    patientId,
    revisionCreatedAt,
    dispatch,
    encounters,
    encountersLoadState
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [showESignatureView, setShowESignatureView] = useState(false);
  const [selectedEncounterId, setSelectedEncounterId] = useState("");

  useEffect(() => {
    dispatch(
      getEncountersForPatientId(
        encounterNamingConvention,
        patientId,
        revisionCreatedAt
      )
    );
  }, []);

  useEffect(() => {
    if (encountersLoadState === "loaded") {
      setIsLoading(false);
      if (Object.keys(encounters).length === 1)
        setSelectedEncounterId(encounters[Object.keys(encounters)[0]].id);
    }
  }, [encountersLoadState]);

  if (isLoading) return <LoadingComponent />;
  else if (showESignatureView)
    return (
      <SignAssessmentAnswerFormContainer
        cancel={cancel}
        assessmentAnswerId={assessmentAnswerId}
        actionType={actionType}
        selectedEncounterId={selectedEncounterId}
      />
    );
  else
    return (
      <div>
        <FormTitleComponent>
          <h4>
            {i18n.t("data.encounters.confirmEncounterOrVisit", {
              encounterNamingConvention: encounterNamingConvention
            })}
          </h4>
        </FormTitleComponent>
        <EncounterDropdownComponent
          {...props}
          showModalForESignature={show => setShowESignatureView(show)}
          selectEncounter={id => setSelectedEncounterId(id)}
          selectedEncounterId={selectedEncounterId}
          dispatch={action => dispatch(action)}
        />
      </div>
    );
};

const mapStateToProps = (state, ownProps) => {
  const patientId = ownProps.patientId;
  return {
    encounters: encountersResourceSelectors.createDataForContextSelector(
      patientId
    )(state),
    encountersLoadState: encountersResourceSelectors.createLoadStateForContextSelector(
      patientId
    )(state),
    encounterNamingConvention: encounterNamingConventionSelector(state)
  };
};

export default connect(mapStateToProps)(EncounterModalComponent);
