import { createSelector } from "reselect";
import { patientsResourceSelectors } from "src/selectors/data_selectors";
import { patientSearchStringSelector } from "../patient_search_string_selector";

/**
 * Selector for the patient search string
 */
export const filteredPatientsSelector = createSelector(
  patientsResourceSelectors.createDataForContextSelectorWithString(
    "patientsTab"
  ),
  patientSearchStringSelector,
  (patients, searchString) => {
    const patientList = Object.values(patients);

    if (!searchString) {
      return patientList;
    } else {
      const modifiedSearchString = searchString
        .replace(/\s+/g, "")
        .toLowerCase();

      return patientList.filter(patient => {
        const name = `${patient.attributes.firstName}${patient.attributes.lastName}`.toLowerCase();
        return name.match(modifiedSearchString);
      });
    }
  }
);
