export const pdfTableStyle = {
  hLineWidth: function(i, node) {
    if (i === 0 || i === node.table.body.length) {
      return 0;
    }
    return i === node.table.headerRows ? 0 : 1;
  },
  vLineWidth: function() {
    return 0;
  },
  hLineColor: function(i) {
    return i === 1 ? "black" : "#aaa";
  },
  paddingLeft: function(i) {
    return i === 0 ? 0 : 8;
  },
  paddingRight: function(i, node) {
    return i === node.table.widths.length - 1 ? 0 : 8;
  }
};
