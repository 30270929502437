import React from "react";

export default class HomeComponent extends React.Component {
  render = () => {
    return (
      <div className="home">
        <div className="row">
          <div className="col-xs-12">
            <h1>Welcome Back</h1>
          </div>
        </div>
      </div>
    );
  };
}
