import { createSelector } from "reselect";
import get from "lodash.get";

import { woundSchemaPolicySelector } from "src/selectors/config_selectors";
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";
// Selectors
import { createLatestAssessmentAnswerForSeriesIdSelector } from "src/selectors/data_selectors";
import { createLatestAssessmentAnswerForRevisionsSelector } from "src/selectors/data_selectors/assessment_answers/create_latest_assessment_answer_for_revisions_selector";

//Constants
import { sections } from "src/constants";

//Utils
import { createDictionaryFromMultipleDictionaries } from "src/utils/downloadable_schemas";

/**
 * Memoized selector to return if download manager body should be shown or hidden
 */
export const schemaLocalizationDictionarySelector = createSelector(
  createSectionSelectors(sections.global).sectionSelector,
  section => {
    return section.schemaLocalizationDictionary;
  }
);

export const localizationDictionaryForWoundSchemaPolicySelector = createSelector(
  woundSchemaPolicySelector,
  schemaLocalizationDictionarySelector,
  (assessmentId, schemaLocalizationDictionary) => {
    return schemaLocalizationDictionary[assessmentId] || {};
  }
);

export const localizationDictionaryForAllSchemasSelector = createSelector(
  woundSchemaPolicySelector,
  schemaLocalizationDictionarySelector,
  (assessmentId, schemaLocalizationDictionary) => {
    const mergedDictionaries = createDictionaryFromMultipleDictionaries(
      assessmentId,
      schemaLocalizationDictionary
    );

    return mergedDictionaries;
  }
);

export function createLocalizationDictionarySelectorForAssessmentSummaries(
  assessmentSumaries
) {
  return createSelector(
    schemaLocalizationDictionarySelector,
    schemaLocalizationDictionary => {
      let assessmentIds = Object.values(assessmentSumaries).map(
        assessmentSummary => get(assessmentSummary, "attributes.assessmentId")
      );
      assessmentIds = [...new Set(assessmentIds)];
      const dictionaryByAssessmentIds = assessmentIds.reduce(
        (agg, assessmentId) => {
          agg[assessmentId] = schemaLocalizationDictionary[assessmentId];
          return agg;
        },
        {}
      );
      return dictionaryByAssessmentIds;
    }
  );
}

export function localizationDictionaryForSeriesIdSelector(seriesId) {
  return createSelector(
    createLatestAssessmentAnswerForSeriesIdSelector(seriesId),
    schemaLocalizationDictionarySelector,
    (assessmentAnswer, schemaLocalizationDictionary) => {
      const assessmentId = get(assessmentAnswer, "attributes.assessmentId");
      return schemaLocalizationDictionary[assessmentId] || {};
    }
  );
}

export function createLocalizationDictionarySelectorForUserAlerts(userAlerts) {
  return createSelector(
    schemaLocalizationDictionarySelector,
    schemaLocalizationDictionary => {
      let assessmentIds = Object.values(userAlerts).map(userAlert =>
        get(userAlert, "attributes.resource.assessmentId")
      );
      assessmentIds = [...new Set(assessmentIds)];
      const dictionariesByAssessmentId = assessmentIds.reduce(
        (agg, assessmentId) => {
          agg[assessmentId] = schemaLocalizationDictionary[assessmentId];
          return agg;
        },
        {}
      );
      return dictionariesByAssessmentId;
    }
  );
}

//Returns an object with schema localization dictionaries indexed by assessment id
export function createLocalizationDictionaryForRevisionsSelector({
  revisions
}) {
  return createSelector(
    createLatestAssessmentAnswerForRevisionsSelector({ revisions }),
    schemaLocalizationDictionarySelector,
    (assessmentAnswersByRevision, schemaLocalizationDictionary) => {
      let assessmentIds = Object.values(assessmentAnswersByRevision).map(
        assessmentAnswer => get(assessmentAnswer, "attributes.assessmentId", "")
      );
      assessmentIds = [...new Set(assessmentIds)];
      const dictionariesByAssessmentId = assessmentIds.reduce(
        (agg, assessmentId) => {
          agg[assessmentId] = schemaLocalizationDictionary[assessmentId];
          return agg;
        },
        {}
      );
      return dictionariesByAssessmentId;
    }
  );
}
