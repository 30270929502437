import { createSelector } from "reselect";
import { configSelector } from "../config_selector";

/**
 * Selector to get the current language
 */
export const languageSelector = createSelector(
  configSelector,
  config => {
    return config.language;
  }
);
