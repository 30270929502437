// object to keep track of pagination
let pagination = {};

/**
 * Get pagination query string for a key
 * @param {string} key - the key to access the pagination for a specific call/context
 * @returns {string|undefined}
 */
export function getNextPageQuery(key) {
  return pagination[key];
}

/**
 * Register pagination query for a key (jsonapi response)
 * @param {string} key - the key to access the pagination for a specific call/context
 * @param {object} response - jsonapi response
 */
export function registerPagination(key, response) {
  const nextLink = response.data.links.next;

  if (nextLink) {
    pagination[key] = `?${nextLink.split("?")[1]}`;
  } else {
    pagination[key] = undefined;
  }
}
