import {
  styles,
  defaultStyle,
  defaultBrandingStyles,
  pccBrandingStyles
} from "../pdf_styles";
import { margins } from "../margins";

/**
 * Get pdf configure options and styles to apply
 * @return {Object}
 */
export function getPdfOptionsV2(options = {}) {
  const { landscape, applySideMargins, branding } = options;

  const brandedStyle =
    branding === "pcc" || branding === "pcc_wound"
      ? pccBrandingStyles
      : defaultBrandingStyles;

  // 8.5x11 inches - 612x792pts - Letter paper size
  const width = landscape ? 792 : 612;
  const height = landscape ? 612 : 792;

  // side margin - some pdf designs require margin to be applied directly in the body and cannot have global margins
  // ex. survey pdf has a table that goes to the very edge of the document.
  const sideMargin = applySideMargins ? margins.defaultPageMargin : 0;

  return {
    pageSize: { width, height },
    styles: { ...styles, ...brandedStyle },
    defaultStyle: defaultStyle,
    // left, top, right, bottom
    // Top margin defines body content adjustment for the header size
    // units in points - 1/72 of an inch
    pageMargins: [sideMargin, 55, sideMargin, 45]
  };
}
