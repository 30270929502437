import React from "react";
import PropTypes from "prop-types";

// Components
import { LoadingComponent } from "src/components/loading_component";
import { Modal, ModalBody } from "react-bootstrap";

// Containers
import { AdminPatientSearchBarContainer } from "src/containers/patient_search_bar_container";
import { AdminPatientTableContainer } from "src/containers/tables";
import { ImportPatientCsvFormContainer } from "src/containers/import_csv_form_container";
import { PatientFormContainer } from "src/containers/forms";

// Components
import {
  PanelHeaderRightComponent,
  PanelBodyComponent,
  PanelComponent
} from "src/components/panel";
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";
import { ButtonComponent } from "src/components/button_component";

// Constants
import { loadStates } from "src/constants/load_states";

// Translations
import i18n from "src/translations";

/**
 * Following component is used to load the patients
 */
export class AdminPatientsComponent extends React.PureComponent {
  static propTypes = {
    patientsLoadState: PropTypes.string.isRequired,
    bulkImportPatientsEnabled: PropTypes.bool.isRequired,
    createPatientsEnabled: PropTypes.bool.isRequired,
    csvExportEnabled: PropTypes.bool.isRequired,
    csvPostPayload: PropTypes.object,
    sendPostCsvExportAction: PropTypes.func.isRequired
  };

  // this state is needed for the set the fileupload control to blank
  state = {
    renderPatientForm: false,
    initialValues: {},
    showPatientCreateModal: false,
    showImportPatientModal: false
  };

  onClickImport = () => {
    this.setState({ ...this.state, showImportPatientModal: true });
  };

  onClickCreatePatient = () => {
    this.setState({
      ...this.state,
      showPatientCreateModal: true
    });
  };

  onClickCreateCsv = () => {
    this.props.sendPostCsvExportAction(this.props.csvPostPayload);
  };

  hidePatientCreateModal = () => {
    this.setState({
      ...this.state,
      showPatientCreateModal: false
    });
  };

  hideImportPatientModal = () => {
    this.setState({
      ...this.state,
      showImportPatientModal: false
    });
  };

  render() {
    const {
      patientsLoadState,
      bulkImportPatientsEnabled,
      createPatientsEnabled,
      csvExportEnabled
    } = this.props;

    let patientTable;
    if (!patientsLoadState || patientsLoadState == loadStates.loading) {
      patientTable = (
        <StartRenderTimeTrackComponent eventName="RENDER_ADMIN_PATIENT_TABLE">
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else if (patientsLoadState == loadStates.error) {
      patientTable = (
        <FinishRenderTimeTrackComponent
          eventName="RENDER_ADMIN_PATIENT_TABLE"
          error={true}
        >
          <LoadingComponent />
        </FinishRenderTimeTrackComponent>
      );
    } else
      patientTable = (
        <FinishRenderTimeTrackComponent eventName="RENDER_ADMIN_PATIENT_TABLE">
          <AdminPatientTableContainer />
        </FinishRenderTimeTrackComponent>
      );

    return (
      <div>
        <PanelComponent labelShow="Patients" enableCollapsible={true}>
          <PanelHeaderRightComponent>
            {createPatientsEnabled && (
              <ButtonComponent onClick={this.onClickCreatePatient}>
                {i18n.t("actions.addPatient")}
              </ButtonComponent>
            )}
            {bulkImportPatientsEnabled && (
              <ButtonComponent onClick={this.onClickImport}>
                {i18n.t("actions.importPatients")}
              </ButtonComponent>
            )}
            {csvExportEnabled && (
              <ButtonComponent onClick={this.onClickCreateCsv}>
                {i18n.t("actions.createCSV")}
              </ButtonComponent>
            )}
          </PanelHeaderRightComponent>
          <PanelBodyComponent>
            <AdminPatientSearchBarContainer />
            {patientTable}
          </PanelBodyComponent>
        </PanelComponent>

        <Modal
          show={this.state.showImportPatientModal}
          onHide={this.hideImportPatientModal}
          bsSize={"sm"}
        >
          <ModalBody>
            <ImportPatientCsvFormContainer
              initialValuesObject={this.state.initialValues}
              cancel={this.hideImportPatientModal}
            />
          </ModalBody>
        </Modal>

        <Modal
          show={this.state.showPatientCreateModal}
          onHide={this.hidePatientCreateModal}
          bsSize={"lg"}
        >
          <ModalBody>
            <PatientFormContainer cancel={this.hidePatientCreateModal} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
