import { createSelector } from "reselect";
import { populationTallyExportSelector } from "../population_tally_export_selector";

/**
 * Load state selector for the population tally export functionality
 */
export const populationTallyExportLoadStateSelector = createSelector(
  populationTallyExportSelector,
  populationTallyExport => populationTallyExport.loadState
);
