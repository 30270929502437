import store from "src/store";

import { create } from "src/actions/flashMessageActions";
import { push } from "react-router-redux";
import get from "lodash.get";

import { expireCookieValueForKey } from "src/client_storage";

// Session
import { clearSessionId } from "src/actions/session_actions";

import { storeCurrentUserEmailAction } from "src/actions/section_actions/otp_verification";

/**
 * Interceptor for api response errors
 * handles redirect to login when token is expired
 */
export function responseErrorInterceptor(error) {
  const response = error.response;

  if (response && response.status == 401) {
    expireCookieValueForKey("accessToken");
    // session expired message
    store.dispatch(
      create("Your session has expired. Please sign in again.", "error")
    );

    store.dispatch(clearSessionId());

    // go to login view
    // TODO: store current state to redirect user back to the same view
    // after they log back in.
    store.dispatch(push("/sign_in"));
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
}

export function responseInterceptor(response) {
  //OTP Verification required
  if (response && response.status === 202) {
    store.dispatch(
      storeCurrentUserEmailAction.setValue(
        get(response, "data.user_email", null)
      )
    );
    store.dispatch(push("/verification"));
  } else {
    return response;
  }
}
