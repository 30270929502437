import PropTypes from "prop-types";
import React from "react";
import i18n from "src/translations";

// Components
import { CheckboxList, CheckboxListItem } from "../../checkbox_list";
import { CollapsibleContainerComponent } from "../../collapsible_container_component";

// Analytics
import { track } from "src/analytics";

export class CheckboxFilterComponent extends React.Component {
  static propTypes = {
    filterConfig: PropTypes.object.isRequired,
    filterCheckedState: PropTypes.object.isRequired,
    selectCheckbox: PropTypes.func.isRequired,
    deselectCheckbox: PropTypes.func.isRequired,
    selectAllEnabled: PropTypes.bool,
    user: PropTypes.object.isRequired,
    schemaLocalizationDictionary: PropTypes.object
  };

  // ----------------------- Selection ----------------------- //

  onCheckBoxClickPatientUsers = filterObject => {
    const {
      selectCheckbox,
      deselectCheckbox,
      filterCheckedState,
      filterConfig,
      user
    } = this.props;
    let withPatientUserObject = filterConfig.filterValues.find(
      filter => filter.key === "with_patient_users"
    );
    let hidePatientUserObject = filterConfig.filterValues.find(
      filter => filter.key === "hide_patient_users"
    );
    const mixpanel_properties = {
      filterName: filterObject.filterName,
      value: true,
      key: filterObject.key,
      userID: user.id
    };
    if (filterCheckedState[filterObject.key][filterObject.value] === "true") {
      deselectCheckbox(filterObject);
    } else if (
      filterCheckedState[filterObject.key][filterObject.value] !== "true"
    ) {
      selectCheckbox(filterObject);
      filterObject.key === "hide_patient_users"
        ? (deselectCheckbox(withPatientUserObject),
          track("COMPLIANCE_HIDE_PATIENT_EVALUATIONS", mixpanel_properties))
        : (deselectCheckbox(hidePatientUserObject),
          track("COMPLIANCE_SHOW_PATIENT_EVALUATIONS", mixpanel_properties));
    }
  };

  onCheckboxClick = filterObject => {
    const { selectCheckbox, deselectCheckbox, filterCheckedState } = this.props;
    const checked = this.isChecked(filterObject, filterCheckedState);

    // Set which properties should be sent to Mixpanel
    // Just use location_id if they are using a location filter as we only care that they are
    // utilizing the location filter
    const mixpanel_properties = {
      filterName: filterObject.filterName,
      value:
        filterObject.filterName === "locations"
          ? "location_id"
          : filterObject.value,
      key: filterObject.key
    };

    if (checked === "partial" || !checked) {
      selectCheckbox(filterObject);
      track("UI_SELECT_FILTER", mixpanel_properties);
    } else {
      deselectCheckbox(filterObject);
      track("UI_DESELECT_FILTER", mixpanel_properties);
    }
  };

  onSelectAllClick = () => {
    const {
      filterConfig,
      filterCheckedState,
      selectCheckbox,
      deselectCheckbox
    } = this.props;

    const filterObject = {
      filterName: filterConfig.filterName,
      key: "selectAll",
      children: filterConfig.filterValues
    };

    // Set which properties should be sent to Mixpanel
    const mixpanel_properties = {
      filterName: filterConfig.filterName,
      value: "selectAll"
    };

    if (this.areAllSelected(filterConfig, filterCheckedState)) {
      deselectCheckbox(filterObject);
      track("UI_DESELECT_FILTER", mixpanel_properties);
    } else {
      selectCheckbox(filterObject);
      track("UI_SELECT_FILTER", mixpanel_properties);
    }
  };

  // ----------------------- Is Checked ----------------------- //
  isChecked = (filterObject, filterCheckedState) => {
    let checked = false;
    let key = filterObject.value;

    if (filterObject.key) key = filterObject.key;

    if (filterCheckedState[key][filterObject.value] === "true") {
      checked = true;
    } else if (filterCheckedState[key][filterObject.value] === "partial") {
      checked = "partial";
    }

    return checked;
  };

  // ----------------------- Are All Selected ----------------------- //
  areAllSelected = (config, checkedState) => {
    let filterValues = config.filterValues || config;
    return filterValues.every(filterValue => {
      if (filterValue.children) {
        return filterValue.children.every(
          child => this.isChecked(child, checkedState) === true
        );
      }
      return this.isChecked(filterValue, checkedState) === true;
    });
  };

  // ----------------------- Render Checkbox List ----------------------- //

  renderCheckboxList() {
    const {
      filterConfig,
      filterCheckedState,
      selectAllEnabled,
      schemaLocalizationDictionary
    } = this.props;
    const { filterName } = filterConfig;

    const getCheckboxes = filterObject => {
      // Get label translation.
      let filterLabel;
      if (schemaLocalizationDictionary && filterObject.key) {
        switch (filterObject.value) {
          case "notSet":
            filterLabel = "Not Set";
            break;
          case "undiagnosed":
            filterLabel = "Undiagnosed";
            break;
          default:
            filterLabel =
              schemaLocalizationDictionary[
                `${filterObject.key}.${filterObject.value}`
              ];
        }
      } else if (schemaLocalizationDictionary && !filterObject.key) {
        filterLabel =
          schemaLocalizationDictionary[`${filterObject.value}.title`];
      }

      if (!schemaLocalizationDictionary || !filterLabel) {
        //TODO: still need assessment version for labels other than wound types. (currently not implemented by localization dictionary)
        filterLabel = filterConfig.assessmentVersion
          ? i18n.t(
              `data.${filterName}.${filterConfig.assessmentVersion}.${filterObject.value}`
            )
          : i18n.t(`data.${filterName}.${filterObject.value}`);
      }

      let checkBoxClickFunction =
        filterConfig.filterName === "patientEvaluations"
          ? this.onCheckBoxClickPatientUsers
          : this.onCheckboxClick;

      return (
        <CheckboxListItem
          key={`${filterObject.key ? filterObject.key : filterObject.value}: ${
            filterObject.value
          }`} //Some filterObjects may not have keys eg. surgicalOther, surgicalClosure
          label={filterObject.name ? filterObject.name : filterLabel}
          value={filterObject}
          checked={this.isChecked(filterObject, filterCheckedState)}
          onChange={checkBoxClickFunction}
        >
          {filterObject.children &&
            filterObject.children.map(child => {
              child.filterName = filterName;
              child.parent = filterObject;
              return getCheckboxes(child);
            })}
        </CheckboxListItem>
      );
    };

    return (
      <CheckboxList disabled={this.props.disabled}>
        {selectAllEnabled && (
          <CheckboxListItem
            key="selectAll"
            label="Select All"
            value="selectAll"
            checked={this.areAllSelected(filterConfig, filterCheckedState)}
            onChange={this.onSelectAllClick}
          />
        )}
        {filterConfig.filterValues.map(filterValue => {
          filterValue.filterName = filterName;
          return getCheckboxes(filterValue);
        })}
      </CheckboxList>
    );
  }

  // ----------------------- Render ----------------------- //
  render() {
    const { filterConfig } = this.props;

    return (
      <CollapsibleContainerComponent
        label={i18n.t(`headers.${filterConfig.filterName}`)}
      >
        {this.renderCheckboxList()}
      </CollapsibleContainerComponent>
    );
  }
}
