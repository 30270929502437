import React from "react";

export class OutcomesIconComponent extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          transform="matrix(.69 0 0 .69 15.5 15.5)"
        >
          <path
            d="m 50,970.36216 -5.5937,3.84375 -6.6563,-1.40625 -3.7187,5.6875 -6.6563,1.25 -1.25,6.6875 -5.6875,3.71875 1.375,6.62499 -3.8125,5.5938 3.8125,5.625 -1.375,6.625 5.6875,3.7187 1.25,6.6875 6.6563,1.25 3.7187,5.6875 6.6563,-1.4062 5.5937,3.8125 5.5937,-3.8125 6.6563,1.4062 3.7187,-5.6875 6.6563,-1.25 1.25,-6.6875 5.6875,-3.7187 -1.375,-6.625 3.8125,-5.625 -3.8125,-5.5938 1.375,-6.62499 -5.6875,-3.71875 -1.25,-6.6875 -6.6563,-1.25 -3.7187,-5.6875 -6.6563,1.40625 L 50,970.36216 z m 0,9.03125 c 12.6908,0 23,10.30929 23,22.99999 0,12.6907 -10.3092,23 -23,23 -12.6907,0 -23,-10.3093 -23,-23 0,-12.6907 10.3093,-22.99999 23,-22.99999 z m 0,2 c -11.6098,0 -21,9.39017 -21,20.99999 0,11.6098 9.3902,21 21,21 11.6099,0 21,-9.3902 21,-21 0,-11.60982 -9.3901,-20.99999 -21,-20.99999 z m 9.7187,11 4.375,4.09379 -15,16 -2,2.125 -2.1874,-1.9375 -9,-8 4,-4.4688 6.8124,6.0625 13,-13.87499 z"
            overflow="visible"
            color="#000"
            transform="translate(0 -952.362)"
          />
        </svg>
      </svg>
    );
  }
}
