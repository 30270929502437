import React from "react";
import PropTypes from "prop-types";
import i18n from "src/translations";
import get from "lodash.get";

import styles from "./style.module.less";

// Components
import { FormComponent } from "../form_component";
import { FormTitleComponent } from "../form_title_component";
import { FormSubmitComponent } from "../form_submit_component";
import { FormInputComponent } from "src/components/forms/form_input_component";

// Validation
import { required } from "src/validations";

export class SignAssessmentAnswerFormComponent extends React.PureComponent {
  static propTypes = {
    sendSignAssessmentAnswerAction: PropTypes.func.isRequired,
    assessmentAnswerId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.wrappedHandleSubmit = props.handleSubmit(this.handleSubmit);
  }

  componentDidMount() {}

  handleSubmit = () => {
    const {
      assessmentAnswerId,
      sendSignAssessmentAnswerAction,
      signAndLockAssessmentAnswersAction,
      currentUser,
      formValues,
      actionType,
      selectedEncounterId
    } = this.props;
    const userEmail = get(currentUser, "attributes.email", "");
    const password = formValues["password"];
    const payload = {
      id: assessmentAnswerId,
      username: userEmail,
      password: password,
      data: {
        id: assessmentAnswerId,
        ...(selectedEncounterId && { encounter_id: selectedEncounterId })
      }
    };

    if (actionType === i18n.t("actions.sign")) {
      sendSignAssessmentAnswerAction(payload).then(response => {
        if (get(response, "response.status") != 401) {
          this.props.cancel();
        }
      });
    } else {
      signAndLockAssessmentAnswersAction(payload).then(response => {
        if (get(response, "response.status") != 401) {
          this.props.cancel();
        }
      });
    }
  };

  render() {
    const { actionType, currentUser, cancel } = this.props;
    const username = get(currentUser, "attributes.email", "");

    return (
      <div>
        <FormTitleComponent>
          <h4>{i18n.t("headers.eSignature")}</h4>
        </FormTitleComponent>
        <FormComponent
          form="signAssessmentAnswerForm"
          onSubmit={this.wrappedHandleSubmit}
        >
          <p>{i18n.t("text.descriptions.signAgreement")}</p>
          <br />
          <p className={styles["margin-bottom"]}>
            <strong>{username}</strong>
          </p>
          <FormInputComponent
            giveFocus
            name="password"
            component="input"
            componentType="password"
            placeholder="Password"
            className="form-control"
            validate={[required]}
          />
          <div className={styles["margin-top20px"]}>
            <FormSubmitComponent
              saveLabel={actionType}
              cancelLabel={i18n.t("actions.cancel")}
              cancel={cancel}
            />
          </div>
        </FormComponent>
      </div>
    );
  }
}
