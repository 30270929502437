import { camelizeKeys } from "humps";
import normalize from "json-api-normalizer";

// Constants
import { resourceNames } from "src/constants";

// Api
import { revisionsApi } from "src/api/revisions";

// Actions
import { createLoadDataAction } from "../../create_load_data_action";

import { mapMeasurementsToValues } from "src/models/revisions";

export const loadRevisionsAction = createLoadDataAction({
  resourceName: resourceNames.revisions,
  api: revisionsApi,
  includedResourceNames: [
    resourceNames.assessmentAnswers,
    resourceNames.frames,
    resourceNames.regions,
    resourceNames.series,
    resourceNames.patients,
    resourceNames.studies,
    resourceNames.payerRevisions
  ],
  responseNormalizer: response => {
    // Normalize revisions
    const normalized = normalize(response);
    let revisions = normalized.revisions || {};
    revisions = Object.values(revisions).reduce((agg, revision) => {
      agg[revision.id] = mapMeasurementsToValues(revision);
      return agg;
    }, {});

    // TODO: These resources are nested in the revision attributes which breaks json api pattern
    // Once API has support for these fields in include - remove this custom normalization.
    // Normalize Assessment answers
    const assessmentAnswers = response.data.reduce((agg, revision) => {
      const revAssessmentAnswers = revision.attributes.assessment_answers;
      if (revAssessmentAnswers) {
        revAssessmentAnswers.forEach(aa => {
          agg[aa.id] = {
            id: aa.id,
            attributes: camelizeKeys({
              ...aa
            })
          };
        });
      }

      return agg;
    }, {});

    // Normalize Frames
    const frames = response.data.reduce((agg, revision) => {
      const revFrames = revision.attributes.frames;
      if (revFrames) {
        revFrames.forEach(frame => {
          agg[frame.id] = {
            id: frame.id,
            attributes: camelizeKeys({
              ...frame
            })
          };
        });
      }

      return agg;
    }, {});

    // Normalize Regions for each frame
    const regions = response.data.reduce((agg, revision) => {
      const revFrames = revision.attributes.frames;
      if (revFrames) {
        revFrames.forEach(frame => {
          const frameRegions = frame.regions;
          if (frameRegions) {
            frameRegions.forEach(region => {
              // Parse JSON strings
              region.measurement = region.measurement
                ? JSON.parse(region.measurement)
                : {};

              region.undermining = region.undermining
                ? JSON.parse(region.undermining)
                : {};

              region.tunneling = region.tunneling
                ? JSON.parse(region.tunneling)
                : {};

              region.polygons = region.polygons
                ? JSON.parse(region.polygons)
                : {};

              region.depth = region.depth ? JSON.parse(region.depth) : {};

              agg[region.id] = {
                id: region.id,
                attributes: camelizeKeys({
                  ...region
                })
              };
            });
          }
        });
      }

      return agg;
    }, {});

    return {
      [resourceNames.revisions]: revisions,
      [resourceNames.assessmentAnswers]: assessmentAnswers,
      [resourceNames.frames]: frames,
      [resourceNames.regions]: regions,
      [resourceNames.series]: normalized[resourceNames.series],
      [resourceNames.patients]: normalized[resourceNames.patients],
      [resourceNames.studies]: normalized[resourceNames.studies],
      [resourceNames.payerRevisions]: normalized[resourceNames.payerRevisions]
    };
  }
});
