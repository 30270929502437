import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";

//css
import styles from "./style.module.less";

export class FormFileUploadComponent extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    keyName: PropTypes.string
  };

  customFileInput = field => {
    delete field.input.value; // this is needed otherwise it shows the warning both cases if you set null in that.
    return (
      <input
        className={styles["file-input"]}
        type="file"
        key={this.props.keyName}
        id={this.props.name}
        {...field.input}
        onBlur={() => {
          return;
        }}
      />
    );
  };

  render() {
    return (
      <div className={styles["margin-bottom15px"]}>
        <label className={styles["label-text"]}>{this.props.labelText}</label>
        <Field
          id={this.props.name}
          name={this.props.name}
          type="file"
          component={this.customFileInput}
        />
      </div>
    );
  }
}
