import { createSelector } from "reselect";
import get from "lodash.get";

import { createGetPayload } from "src/redux_resources_v1";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Selectors
import {
  createLatestAssessmentAnswerForRevisionSelector,
  createLatestAssessmentSchemaForRevisionSelector
} from "src/selectors/data_selectors";
import { isArray } from "util";

/**
 * Create query selector to get all queries to load all assessments
 * with schema for the latest assessment answer for a revision
 * @param {Object} props
 * @param {Object} props.revision
 * @returns {Function} selector
 */
export function createAllAssessmentsWithSchemasForRevisionQuerySelector({
  revision
}) {
  return createSelector(
    createLatestAssessmentAnswerForRevisionSelector(revision),
    createLatestAssessmentSchemaForRevisionSelector(revision),
    (assessmentAnswer, schema) => {
      if (!assessmentAnswer) return "";

      let assessmentIds = [assessmentAnswer.attributes.assessmentId];

      let components = get(schema, "attributes.schemaJson.components", []);
      if (!isArray(components)) {
        components = Object.values(components);
      }

      assessmentIds.push(...components);

      return assessmentIds.map(id => {
        return buildQueryString(
          createGetPayload({
            filter: {
              id
            },
            include: ["assessment_schema"]
          })
        );
      });
    }
  );
}
