import React from "react";
import PropTypes from "prop-types";

// Translations
import i18n from "src/translations";

/**
 * The thead component for the location wound tally table
 */
export class WoundProgressTallyByLocationTableHeaderComponent extends React.PureComponent {
  static propTypes = {
    progressValues: PropTypes.array.isRequired,
    assessmentVersion: PropTypes.string.isRequired
  };

  render() {
    const { progressValues, assessmentVersion } = this.props;
    const acquiredString = i18n.t(`data.acquired.${assessmentVersion}.inHouse`);
    const progressHeadings = progressValues.map(progress => {
      return (
        <th key={progress} className="text-center">
          {i18n.t(`data.overallProgress.${assessmentVersion}.${progress}`)}
        </th>
      );
    });

    return (
      <thead>
        <tr>
          <th className="location">Location</th>
          <th>Four Week Trend</th>
          {progressHeadings}
          <th className="alert alert-info in-house">Total</th>
          <th className="alert alert-danger in-house">{acquiredString}</th>
        </tr>
      </thead>
    );
  }
}
