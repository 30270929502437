import { mergeSchemasIntoSingleSchema } from "../merge_schemas_into_single_schema";
import { createQuestionHierarchyFromSchema } from "../create_question_hierarchy_from_schema";

export const createQuestionHierarchyFromMultipleSchemas = (
  mainSchema,
  secondarySchemas
) => {
  if (!mainSchema) return [];
  if (!secondarySchemas || !Object.values(secondarySchemas).length)
    return createQuestionHierarchyFromSchema(mainSchema);

  const schemaFromMerge = mergeSchemasIntoSingleSchema(
    mainSchema,
    secondarySchemas
  );

  const questionHierarchy = createQuestionHierarchyFromSchema({
    attributes: {
      schemaJson: {
        questions: schemaFromMerge
      }
    }
  });

  return questionHierarchy;
};
