import { createActionTypes } from "../../create_action_types";
import { createContext } from "../../create_context";
import { requestMethods } from "../../request_methods";

export function createPagesReducer(name) {
  const actionTypes = createActionTypes(name);

  return (state = {}, action) => {
    let context;
    switch (action.type) {
      case actionTypes.incrementPage:
        context = createContext(requestMethods.get, action.payload.queryObject);
        return {
          ...state,
          [context]: state[context] ? state[context] + 1 : 1
        };
      case actionTypes.decrementPage:
        context = createContext(requestMethods.get, action.payload.queryObject);
        if (!state[context] || state[context] == 0) return state;
        return {
          ...state,
          [context]: state[context] - 1
        };
      case actionTypes.setPage:
        context = createContext(requestMethods.get, action.payload.queryObject);
        return {
          ...state,
          [context]: action.payload.page
        };
      case actionTypes.invalidate:
        const method = action.payload.method;
        const queryObject = action.payload.queryObject;
        const page = action.payload.page;
        context = createContext(method, queryObject, page);
        return {
          ...state,
          [context]: undefined
        };
      case actionTypes.invalidateAll:
        return {};
      default:
        return state;
    }
  };
}
