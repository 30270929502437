/**
 * Get the wound image with a pdf identifier below it
 * @param {string} dataUrl - the image url
 * @param {string} imageText - text for the imahe in case of image declined
 * @return {Object}
 */
export function getWoundImage(
  dataUrl,
  imageText,
  alignment = "right",
  width = 225
) {
  const displayImageText = imageText || "";

  if (dataUrl) {
    return {
      stack: [
        {
          image: dataUrl,
          width: width
        }
      ],
      alignment: alignment,
      width: width
    };
  } else {
    return {
      stack: [
        {
          table: {
            heights: 260,
            widths: [width],
            body: [
              [
                {
                  text: displayImageText,
                  fontSize: 14,
                  alignment: "center",
                  marginTop: 120
                }
              ]
            ]
          }
        }
      ],
      alignment: alignment,
      width: width
    };
  }
}
