import { exportSurveyActions, exportPopulationActions } from "./exports";
import { locationActions } from "./locations";
import { organizationActions } from "./organizations";
import { patientActions } from "./patients";
import { policyActions } from "./policies";
import { surveyWoundDetailsActions } from "./survey_wound_details";
import { woundAssessmentProgressChartDataActions } from "./wound_assessment_progress_chart_data";
import { woundAssessmentTalliesByLocationActions } from "./wound_assessment_tallies_by_location";
import { woundAssessmentTotalsTimeSeriesByLocationActions } from "./wound_assessment_totals_time_series_by_location";
import { woundAssessmentsByProgressActions } from "./wound_assessments_by_progress";
import { legacyPopulationChartDataActions } from "./legacy_population_chart_data";
import { populationChartDataActions } from "./population_chart_data";
import { populationTallyActions } from "./population_tallies";
import { populationTallyByLocationActions } from "./population_tallies_by_location";
import { usersDataActions } from "./users";
import { rolesDataActions } from "./roles";
import { csvImportActions } from "./imports";
import { facilitiesDataActions } from "./facilities";
import { resetPasswordActions } from "./reset_password";
import { changePasswordActions } from "./change_password";
import { partnersActions } from "./partners";

export const dataActionTypes = {
  ...exportSurveyActions,
  ...locationActions,
  ...organizationActions,
  ...patientActions,
  ...policyActions,
  ...surveyWoundDetailsActions,
  ...woundAssessmentProgressChartDataActions,
  ...woundAssessmentTalliesByLocationActions,
  ...woundAssessmentTotalsTimeSeriesByLocationActions,
  ...woundAssessmentsByProgressActions,
  ...legacyPopulationChartDataActions,
  ...populationChartDataActions,
  ...populationTallyActions,
  ...exportPopulationActions,
  ...populationTallyByLocationActions,
  ...usersDataActions,
  ...rolesDataActions,
  ...csvImportActions,
  ...facilitiesDataActions,
  ...resetPasswordActions,
  ...changePasswordActions,
  ...partnersActions
};
