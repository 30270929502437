import { createSelector } from "reselect";
import get from "lodash.get";
import compact from "lodash.compact";

import { healingIndexResourceSelector } from "src/selectors/data_selectors";
/**
 * Create a selector that gets a series for a revision id
 * @param {string} revisionId
 * @returns {Function} selector
 */
export function createHealingIndexForRevisionSelector(revision) {
  return createSelector(
    healingIndexResourceSelector.dataSelector,
    healingIndices => {
      const healingIndicesArray = Object.values(healingIndices);
      let healingIndexObject = healingIndicesArray.map(healingIndex => {
        let healingIndexRevisionId = get(
          healingIndex,
          "attributes.revisionId",
          ""
        );
        if (revision.id === healingIndexRevisionId) {
          return healingIndex;
        }
      });
      let healingIndex = compact(healingIndexObject);
      return healingIndex ? healingIndex : "N/A";
    }
  );
}
