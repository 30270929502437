import { woundsActionTypes } from "src/action_types/section_action_types";

export function selectedProgressFilterReducer(state = "new", action) {
  switch (action.type) {
    case woundsActionTypes.setSelectedProgressFilter:
      return action.payload;
    default:
      return state;
  }
}
