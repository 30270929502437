import { globalActionTypes } from "src/action_types/section_action_types";

/**
 * Action to toggle expand/collapse Download Manager Body
 */
export function toggleExpandCollapseDownloadManagerBodyAction() {
  return {
    type: globalActionTypes.toggleExpandCollapseDownloadManagerBody
  };
}

export function openDownloadManagerAction() {
  return {
    type: globalActionTypes.openDownloadManager
  };
}

export function closeDownloadManagerAction() {
  return {
    type: globalActionTypes.closeDownloadManager
  };
}

export function toggleExpandSidebarAction() {
  return {
    type: globalActionTypes.toggleExpandSidebar
  };
}

export function createLocalizationDictionaryAction(payload) {
  return {
    type: globalActionTypes.createSchemaLocalizationDictionary,
    payload
  };
}
