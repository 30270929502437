import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./style.module.less";

// Containers
import {
  // WoundEvaluationsWoundTypesFilterContainer,
  WoundsEvaluationsLocationsFilterContainer,
  WoundsEvaluationsLockStateFilterContainer
} from "src/containers/exports_section_filter_containers";
import { WoundsEvaluationsDateFilterContainer } from "src/containers/exports_section_filter_containers/date_filter";

// Components
import { CollapsibleContainerComponent } from "src/components/collapsible_container_component";

// Translations
import i18n from "src/translations";

export class ExportsSectionFiltersComponent extends React.Component {
  static propTypes = {
    exportName: PropTypes.string.isRequired
  };

  // ----------------------- Filters ----------------------- //
  renderWoundEvaluationsFilters = () => {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t(`headers.date`)}
            expanded
          >
            <WoundsEvaluationsDateFilterContainer />
          </CollapsibleContainerComponent>
        </li>
        {/* <li>
          <WoundEvaluationsWoundTypesFilterContainer />
        </li> */}
        <li>
          <WoundsEvaluationsLockStateFilterContainer />
        </li>
        <li>
          <WoundsEvaluationsLocationsFilterContainer />
        </li>
      </ul>
    );
  };

  render() {
    const { exportName } = this.props;

    switch (exportName) {
      case "woundEvaluations":
        return this.renderWoundEvaluationsFilters();
      default:
        return (
          <div className={styles["select-message"]}>
            <i>Please select a report type to load filters.</i>
          </div>
        );
    }
  }
}
