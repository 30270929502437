import React from "react";
import PropTypes from "prop-types";

//Constants
import { alertsSectionTabs, analyticsEventNames } from "src/constants";

//Containers
import { AlertsTableContainer } from "src/containers/tables/alerts_table_container";

//Components
import { TabViewComponent } from "src/components/tab_view_component";
import { EvaluationListContainer } from "src/containers/evaluation_list_container";
import { ButtonComponent } from "src/components/button_component";
import { WoundRiskListTableContainer } from "src/containers/tables/wound_risk_list_table_container";

// // Translations
import i18n from "src/translations";

// Legacy components
import { Icon } from "src/ui-kit";
export class AlertsSectionComponent extends React.PureComponent {
  static propTypes = {
    csvPostPayload: PropTypes.object.isRequired,
    sendPostCsvExportAction: PropTypes.func.isRequired,
    alertsTabEnabled: PropTypes.bool.isRequired,
    evaluationListEnabled: PropTypes.bool.isRequired,
    healingIndexDashboardEnabled: PropTypes.bool.isRequired
  };

  onCreateCsvClicked = () => {
    const { sendPostCsvExportAction, csvPostPayload } = this.props;
    sendPostCsvExportAction(csvPostPayload);
  };

  componentDidMount = () => {
    const {
      history,
      alertsTabEnabled,
      evaluationListEnabled,
      healingIndexDashboardEnabled
    } = this.props;
    //This component will eventually become MyDashboardComponent and the pathname should match "/my-dashboard" and we push "alerts"
    //so the path becomes "/my-dashboard/alerts" #theRealMyDashboard
    if (history.location.pathname === "/my-dashboard" && alertsTabEnabled) {
      this.props.history.push("/my-dashboard/alerts");
    } else if (
      history.location.pathname === "/my-dashboard" &&
      evaluationListEnabled
    ) {
      this.props.history.push("/my-dashboard/evaluation-list/wound");
    } else if (
      history.location.pathname === "/my-dashboard" &&
      healingIndexDashboardEnabled
    ) {
      this.props.history.push("/my-dashboard/wound-risk");
    }
  };

  renderControlNavbar() {
    return (
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">{i18n.t(`headers.myDashboard`)}</h1>
        </div>
        {this.getTabValue() === "evaluation-list" && (
          <form className="navbar-form navbar-right">
            <div className="pull-right">
              <ButtonComponent
                analytics={{
                  type: analyticsEventNames.downloadEvaluationListCSV
                }}
                onClick={this.onCreateCsvClicked}
              >
                <Icon name="download" />
              </ButtonComponent>
            </div>
          </form>
        )}
      </nav>
    );
  }

  renderTabContent = tab => {
    switch (tab) {
      case alertsSectionTabs.alerts:
        return <AlertsTableContainer />;
      case alertsSectionTabs.evaluationList:
        return <EvaluationListContainer />;
      case alertsSectionTabs.woundRisk:
        return <WoundRiskListTableContainer />;
      default:
        return <AlertsTableContainer />;
    }
  };

  onSelectTab = tab => {
    //Navigation required to allow for separate "sub"-sections that can have their own filters, saved filters, etc
    //in Alerts Dashboard (which will eventually become My Dashboard)
    //Repalace with (`/my-dashboard/${tab}`) when #theRealMyDashboard is ready
    if (tab === "evaluation-list") {
      this.props.history.push(`/my-dashboard/${tab}/wound`);
    } else {
      this.props.history.push(`/my-dashboard/${tab}`);
    }
  };

  getTabValue = () => {
    let tabValue = alertsSectionTabs.alerts;
    let { pathname } = this.props.location;
    const indexOfSecondSlash = pathname.indexOf("/", 1);
    const indexOfThirdSlash = pathname.indexOf("/", indexOfSecondSlash + 1);
    if (indexOfThirdSlash !== -1) {
      tabValue = pathname.slice(indexOfSecondSlash + 1, indexOfThirdSlash);
    } else {
      tabValue = pathname.slice(indexOfSecondSlash + 1);
    }
    return tabValue;
  };

  render() {
    const { tabs } = this.props;
    const selectedTab = this.getTabValue();

    return (
      <div>
        {this.renderControlNavbar()}
        <TabViewComponent
          tabHeaderType="title"
          tabs={tabs}
          selectedTabValue={selectedTab}
          selectTab={this.onSelectTab}
          sizeLarge={true}
          marginClass={"marginBottom20px"}
          horizontalSpread={true}
        >
          {this.renderTabContent(selectedTab)}
        </TabViewComponent>
      </div>
    );
  }
}
