import { createSelector } from "reselect";

// Constants
import { sections } from "src/constants";

// Selectors
import { woundTypesConfigurationFilterForSchemaPolicySelector } from "src/selectors/ui_config_selectors/global/wound_type_filter_configuration_selector";
import { createSectionSelectors } from "src/ui_processors";
const sectionSelectors = createSectionSelectors(sections.survey);

/**
 * Get tally contexts for etiology tallies (same as answers filter at the moment)
 */
export const tallyContextsEtiologySelector = createSelector(
  woundTypesConfigurationFilterForSchemaPolicySelector,
  sectionSelectors.filtersSelector,
  (woundTypesConfig, filters) => {
    const woundTypesFilter = filters.find(filter => filter.key == "woundTypes");
    const filterValues = woundTypesFilter ? woundTypesFilter.value : [];

    const contexts = woundTypesConfig.filterValues.reduce(
      (agg, configObject) => {
        let shouldIncludeParent = true;
        if (filterValues.length) {
          // check if the wound type (or one of its subtypes is selected)
          const woundTypeSelected = filterValues.find(filt => {
            return filt.value == configObject.value;
          });
          let subtypeSelected = false;
          if (configObject.children) {
            subtypeSelected = configObject.children.some(child => {
              return filterValues.find(filt => {
                return filt.key == child.key && filt.value == child.value;
              });
            });
          }

          shouldIncludeParent = woundTypeSelected || subtypeSelected;
        }

        if (shouldIncludeParent) {
          agg.push({
            woundType: configObject.value
          });
        }

        // Add children
        if (configObject.children) {
          configObject.children.forEach(child => {
            let subtypeSelected = true;
            if (filterValues.length) {
              subtypeSelected = filterValues.find(filt => {
                return filt.key == child.key && filt.value == child.value;
              });
            }

            if (subtypeSelected && child.key) {
              agg.push({
                woundType: configObject.value,
                [child.key]: child.value
              });
            }
          });
        }

        return agg;
      },
      []
    );
    return contexts;
  }
);
