import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Components
import { RevisionSummaryComponent } from "src/components/revision_summary_component";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

// Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { createSingleRevisionContextSelector } from "src/selectors/api_selectors/global/revisions";
import { createSingleRevisionQuerySelector } from "src/selectors/api_selectors/global/revisions";

// Actions
import { loadRevisionsAction } from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  return {
    revision: revisionsResourceSelectors.createDataForContextSelector(
      ownProps.context
    )(state)[0]
  };
}

export const RevisionSummaryContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadRevisionsAction,
    resourceSelectors: revisionsResourceSelectors,
    createContextSelector: createSingleRevisionContextSelector,
    createQuerySelector: createSingleRevisionQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: `RENDER_REVISION_SUMMARY_CONTAINER`
  }),
  connect(mapStateToProps)
)(RevisionSummaryComponent);
