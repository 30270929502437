import { connect } from "react-redux";
import { ChangePasswordFormComponent } from "src/components/forms/change_password_form_component";

//selectors
import { changePasswordLoadStateSelector } from "src/selectors/data_selectors";
// Actions
import { changePasswordAction } from "src/actions/data_actions";
import { create } from "src/actions/flashMessageActions";

function mapStateToProps(state) {
  return {
    changePasswordLoadState: changePasswordLoadStateSelector(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sendChangePasswordAction(payload) {
      dispatch(changePasswordAction(payload));
    },
    sendPasswordErrorAction(errorMessage) {
      dispatch(create(errorMessage, "error"));
    }
  };
}
export const ChangePasswordFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordFormComponent);
