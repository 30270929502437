import flowRight from "lodash.flowright";

// Component
import { AdminAlertRulesComponent } from "src/components/admin_alert_rules_component";

// Actions
import { loadFacilitiesAction } from "src/actions/data_actions";

// Selectors
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";
import {
  createAllPagesAllFacilitiesContextSelector,
  createAllPagesAllFacilitiesQuerySelector
} from "src/selectors/api_selectors/global/facilities";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

// Constants
import { analyticsEventNames } from "src/constants";

export const AdminAlertRulesContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadFacilitiesAction,
    resourceSelectors: facilitiesResourceSelectors,
    createContextSelector: createAllPagesAllFacilitiesContextSelector,
    createQuerySelector: createAllPagesAllFacilitiesQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: analyticsEventNames.renderAdminAlertsTab
  })
)(AdminAlertRulesComponent);
