import { createSelector } from "reselect";
import get from "lodash.get";

import { assessmentAnswersResourceSelectors } from "src/selectors/data_selectors";
import { createLatestAssessmentAnswerQueriesForAllRevisionsForFiltersSelector } from "../../assessment_answers/create_latest_assessment_answer_queries_for_all_revisions_for_filters_selector";
import { patientsResourceSelectors } from "src/selectors/data_selectors";
// Utils
import { buildQueryString } from "src/utils/build_query_string";
import { createGetPayload } from "src/redux_resources_v1";

export function createAllLastFacilitiesQueryForRevisionsSelector({ filters }) {
  return createSelector(
    assessmentAnswersResourceSelectors.createDataForContextSelector(
      createLatestAssessmentAnswerQueriesForAllRevisionsForFiltersSelector({
        filters
      })
    ),
    patientsResourceSelectors.dataSelector,
    (assessmentAnswers, patients) => {
      let facilityIds = assessmentAnswers.map(assessmentAnswer => {
        const patientId = get(assessmentAnswer, "attributes.patientId");
        const patient = Object.values(patients).find(p => {
          return p.id === patientId;
        });
        const facilityId = get(patient, "attributes.lastFacilityId");
        if (facilityId) {
          return facilityId;
        }
      });

      facilityIds = [...new Set(facilityIds)];

      const value = buildQueryString(
        createGetPayload({
          filter: {
            id: facilityIds
          }
        })
      );

      return value;
    }
  );
}
