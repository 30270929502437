import { createSelector } from "reselect";
import { configSelector } from "../config_selector";

/**
 * Memoized selector for the partner name (part of the config state tree)
 */
export const themeSelector = createSelector(
  configSelector,
  config => {
    return config.theme;
  }
);
