import moment from "moment";
import groupBy from "lodash.groupby";

/* TODO: add description here  */
export function calculateWoundsProgressChartValues(
  data,
  timeIntervalKey,
  progressTypeKey,
  countKey
) {
  let newData = [];
  let worseData = [];
  let stalledData = [];
  let stableData = [];
  let betterData = [];
  let unknownData = [];
  let timeIntervals = [];
  let monitoringData = [];

  if (!data) {
    return {
      timeIntervals,
      newData,
      worseData,
      stalledData,
      stableData,
      betterData,
      unknownData,
      monitoringData
    };
  }

  // Note that data coming from the optimized api/v4/wound-clinical-charts will be grouped by end_time,
  // data coming from api/v2/clinical_chart will be grouped by time_interval_start
  //TODO: need refactoring to do better than taking the keys...
  let dataGroupedByTimeInterval = groupBy(data, timeIntervalKey);

  for (let timeInterval in dataGroupedByTimeInterval) {
    let dataPoints = dataGroupedByTimeInterval[timeInterval];
    let formattedTime = moment(timeInterval).format("MMM D");

    timeIntervals.push(formattedTime);

    let newTotal = 0;
    let worseTotal = 0;
    let stalledTotal = 0;
    let stableTotal = 0;
    let betterTotal = 0;
    let monitoringTotal = 0;
    let unknownTotal = 0;

    //TODO: this is using the old - hyphenethed model...
    dataPoints.forEach(point => {
      switch (point[progressTypeKey]) {
        case "new":
          newTotal += point[countKey];
          break;
        case "worse":
          worseTotal += point[countKey];
          break;
        case "stalled":
          stalledTotal += point[countKey];
          break;
        case "stable":
          stableTotal += point[countKey];
          break;
        case "better":
          betterTotal += point[countKey];
          break;
        case "monitoring":
          monitoringTotal += point[countKey];
          break;
        case null:
          unknownTotal += point[countKey];
      }
    });

    newData.push(newTotal);
    worseData.push(worseTotal);
    stalledData.push(stalledTotal);
    stableData.push(stableTotal);
    betterData.push(betterTotal);
    unknownData.push(unknownTotal);
    monitoringData.push(monitoringTotal);
  }

  return {
    timeIntervals,
    newData,
    worseData,
    stalledData,
    stableData,
    betterData,
    unknownData,
    monitoringData
  };
}
