import apiFetch from "app/util/apiFetch";
// import loadLocations from 'app/locations/loadLocations'

export default function loadLocationsAndRiskAggregates(
  locationIds,
  startTime,
  endTime
) {
  return dispatch => {
    let locationRequests = locationIds.map(locationId => {
      const payload = {
        location_ids: locationId,
        start_time: startTime,
        end_time: endTime,
        time_step: "day"
      };

      return dispatch(
        apiFetch(`/api/v2/risk_dashboard/braden/administrative_chart`, {
          method: "POST",
          body: payload,
          headers: {
            Accept: "application/json",
            "X-HTTP-Method-Override": "GET"
          }
        })
      ).then(({ chart }) => {
        dispatch({
          type: "LOCATION_AGGREGATE_SPARK_RISK_LOAD",
          locationId,
          chart
        });
      });
    });

    return Promise.all(locationRequests);
  };
}
