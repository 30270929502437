// @flow
import { Record, Map, fromJS } from "immutable";
import { convertInput } from "src/utils/modelUtils";

const properties = {
  aggregates: new Map(),
  patients: new Map(),
  charts: new Map()
};

export default class GenericRiskPropertyModel extends Record(properties) {
  constructor(opts?: Object = {}) {
    super(
      convertInput(
        {
          aggregates: fromJS,
          patients: fromJS,
          charts: fromJS
        },
        opts
      )
    );
  }
}
