import { createSelector } from "reselect";
import { dataSelector } from "src/selectors/data_selectors/data_selector";

/**
 * Selector for the reset password object in the data state tree
 */
export const resetPasswordSelector = createSelector(
  dataSelector,
  data => data.resetPassword
);
