// React
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import get from "lodash.get";

// Constants
import { tallyTypes } from "src/constants";

// Css
import styles from "../style.module.less";

// Model
import {
  shouldIndentContextString,
  getContextStringForTally
} from "src/models/wound_tallies";

export class SurveyTallyTableRowComponent extends PureComponent {
  static propTypes = {
    tally: PropTypes.object.isRequired,
    tallyType: PropTypes.string.isRequired,
    excludeNewWounds: PropTypes.bool,
    showAcquiredUnknown: PropTypes.bool.isRequired,
    woundTypes: PropTypes.object,
    woundSubtypes: PropTypes.object,
    goToDetails: PropTypes.func.isRequired,
    schemaLocalizationDictionary: PropTypes.object.isRequired,
    subtypeKeysForSchema: PropTypes.object.isRequired
  };

  // ------------------- Get Row Context Data ------------------- //

  renderTotalContextData() {
    const { tally } = this.props;
    if (tally.attributes.type == tallyTypes.total) {
      return <td className={styles["left-align"]}>Total</td>;
    }
  }

  renderWoundTypeContextData() {
    const {
      tally,
      tallyType,
      schemaLocalizationDictionary,
      subtypeKeysForSchema
    } = this.props;
    if (
      tallyType != tallyTypes.etiology ||
      tally.attributes.type == tallyTypes.total
    ) {
      return;
    }

    const woundContextString = getContextStringForTally(
      tally,
      schemaLocalizationDictionary,
      subtypeKeysForSchema
    );

    const shouldIndent = shouldIndentContextString(tally);

    return (
      <td
        className={
          shouldIndent ? styles["left-align-indent"] : styles["left-align"]
        }
      >
        {woundContextString}
      </td>
    );
  }

  renderLocationContextData() {
    const { tally, tallyType } = this.props;
    if (
      tally.attributes.type != tallyTypes.total &&
      tallyType == tallyTypes.location
    ) {
      const locationContextString = get(tally, "attributes.location.name", "");

      const tags = get(tally, "attributes.location.tags", []);
      let style = styles["left-align"];
      if (tags.includes("Unit") || tags.includes("Floor")) {
        style = styles["left-align-indent-location"];
      }

      return <td className={style}>{locationContextString}</td>;
    }
  }

  // ------------------- Wound Residents ------------------- //

  renderWoundResident() {
    const { tally } = this.props;
    return (
      <td className={styles["right-border"]}>
        {tally.attributes.woundResidentCount}
      </td>
    );
  }

  // ------------------- New Counts ------------------- //

  renderNewPoa() {
    const detailsOptions = this.getDetailsOptions("POA", "new");
    return (
      <td>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.newPoaCount}
        </span>
      </td>
    );
  }

  renderNewPihaw() {
    const detailsOptions = this.getDetailsOptions("PIHAw", "new");
    return (
      <td>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.newPihawCount}
        </span>
      </td>
    );
  }

  renderNewIha() {
    const detailsOptions = this.getDetailsOptions("IHA", "new");
    return (
      <td>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.newIhaCount}
        </span>
      </td>
    );
  }

  renderNewIhaw() {
    const detailsOptions = this.getDetailsOptions("IHAw", "new");
    return (
      <td>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.newIhawCount}
        </span>
      </td>
    );
  }

  renderNewTotal() {
    const detailsOptions = this.getDetailsOptions("allSet", "new");
    return (
      <td className={styles["right-border"]}>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.newTotal}
        </span>
      </td>
    );
  }

  // ------------------- Existing Counts ------------------- //

  renderExistingPoa() {
    const detailsOptions = this.getDetailsOptions("POA", "existing");
    return (
      <td>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.existingPoaCount}
        </span>
      </td>
    );
  }

  renderExistingPihaw() {
    const detailsOptions = this.getDetailsOptions("PIHAw", "existing");
    return (
      <td>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.existingPihawCount}
        </span>
      </td>
    );
  }

  renderExistingIha() {
    const detailsOptions = this.getDetailsOptions("IHA", "existing");
    return (
      <td>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.existingIhaCount}
        </span>
      </td>
    );
  }

  renderExistingIhaw() {
    const detailsOptions = this.getDetailsOptions("IHAw", "existing");
    return (
      <td>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.existingIhawCount}
        </span>
      </td>
    );
  }

  renderExistingTotal() {
    const detailsOptions = this.getDetailsOptions("allSet", "existing");
    return (
      <td className={styles["right-border"]}>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.existingTotal}
        </span>
      </td>
    );
  }

  // ------------------- Total ------------------- //

  renderTotal() {
    const detailsOptions = this.getDetailsOptions("total", "all");

    return (
      <td className={`${styles["right-border"]} ${styles["dark-background"]}`}>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {this.props.tally.attributes.totalExcludingResolved}
        </span>
      </td>
    );
  }

  // ------------------- Resolved ------------------- //

  renderResolved() {
    const { tally } = this.props;

    const detailsOptions = this.getDetailsOptions("total", "all", true);

    return (
      <td className={styles["right-border"]}>
        <span
          className={styles["details-link"]}
          onClick={() => this.props.goToDetails(detailsOptions)}
        >
          {tally.attributes.totalResolved}
        </span>
      </td>
    );
  }

  // ------------------- Unknown Aquired ------------------- //

  renderAcquiredUnknown() {
    if (this.props.showAcquiredUnknown) {
      const { tally } = this.props;
      const detailsOptions = this.getDetailsOptions("unknown", "all");
      return (
        <td className={styles["right-border"]}>
          <span
            className={styles["details-link"]}
            onClick={() => this.props.goToDetails(detailsOptions)}
          >
            {tally.attributes.newAcquiredUnknownCount +
              tally.attributes.existingAcquiredUnknownCount}
          </span>
        </td>
      );
    }
  }

  // ------------------- Details options ------------------- //

  /**
   * Get Options for navigating to details view
   * @return {Object} details options
   */
  getDetailsOptions(acquired, age, resolved = false) {
    // TODO: This workflow using a url query to sepecify options for the details
    // view should be replaced with one that pre-sets relevant filters on the
    // details view that can then be edited by the user.
    // For this to happen, new filters have to be added to the
    const { tally, subtypeKeysForSchema } = this.props;

    const locationId = get(tally, "attributes.context.locationId", undefined);
    const woundType = get(tally, "attributes.context.woundType", undefined);
    let subtype;
    if (tally.attributes.context) {
      const subtypeKey = subtypeKeysForSchema[woundType];
      subtype = get(tally, `attributes.context.${subtypeKey}`, undefined);
    }

    return {
      acquired,
      age,
      resolved,
      woundType,
      woundSubtype: subtype,
      locationIds: locationId ? [locationId] : undefined,
      locationsInclusive: false
    };
  }

  // ------------------- Render ------------------- //

  render() {
    const { tally } = this.props;
    let rowClassName = "";
    if (tally.tallyType == tallyTypes.total) {
      rowClassName = styles["total-row"];
    } else if (tally.tallyType == tallyTypes.etiology) {
      rowClassName = styles["wound-type-row"];
    }

    return (
      <tr className={rowClassName}>
        {this.renderTotalContextData()}
        {this.renderWoundTypeContextData()}
        {this.renderLocationContextData()}

        {this.renderWoundResident()}

        {this.renderNewPoa()}
        {this.renderNewIha()}
        {this.renderNewIhaw()}
        {this.renderNewPihaw()}
        {this.renderNewTotal()}

        {this.renderExistingPoa()}
        {this.renderExistingIha()}
        {this.renderExistingIhaw()}
        {this.renderExistingPihaw()}
        {this.renderExistingTotal()}

        {this.renderAcquiredUnknown()}

        {this.renderTotal()}

        {this.renderResolved()}
      </tr>
    );
  }
}
