import { adminActionTypes } from "src/action_types/section_action_types";
import uniq from "lodash/uniq";
import without from "lodash/without";

export function monitorAtHomeStatusFilterReducer(state = [], action) {
  switch (action.type) {
    case adminActionTypes.setMonitorAtHomeStatus: {
      return uniq([...state, action.payload]);
    }
    case adminActionTypes.deselectMonitorAtHomeStatus: {
      return without(state, action.payload);
    }
    default:
      return state;
  }
}
