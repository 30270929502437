import { createSelector } from "reselect";

// utils
import { wrapSelector } from "../../utils";

// context
import { createContext } from "../../create_context";

// request methods
import { requestMethods } from "../../request_methods";

/**
 * @param { Function } metaRootSelector
 * @returns { Function } selector creator that returns a selector for page count for a queryObject
 */
export function createPageCountSelectorCreator(
  resourceManager,
  globalConfig,
  metaRootSelector
) {
  /**
   * Create a selector creator for that returns the page count for a query and page limit
   * @param {Object|Function} queryObject - query object, or selector for a query object
   * @param {Object} page - the page limit
   * @returns {Function} selector for the page count
   */
  return function createPageCountSelector(queryObject) {
    const queryObjectSelector = wrapSelector(queryObject);
    return createSelector(
      queryObjectSelector,
      metaRootSelector,
      (queryObjectValue, rootMeta) => {
        const context = createContext(
          requestMethods.get,
          queryObjectValue,
          // NOTE: coupling pagination strategy with limit param to hardcoded object here
          {
            offset: 0,
            limit: queryObjectValue.pageConfig.limit
          }
        );

        const meta = rootMeta[context];
        if (!meta) {
          // return single page if we don't know how many there are
          return 1;
        }

        const recordCount = meta.meta.recordCount;
        return Math.ceil(recordCount / queryObjectValue.pageConfig.limit);
      }
    );
  };
}
