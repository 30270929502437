import { createSelector } from "reselect";

// Constants
import { eulaDisabledPartners } from "src/constants";

// Selectors
import { partnerNameSelector } from "../partner_name_selector";

/**
 * Memoized selector to get the Disabled/Enabled state
 * of the EULA Modal Button on Settings Page
 * When the EULA Modal Button is disabled it cannot
 * be opened and closed and will not be shown.
 */
export const eulaButtonEnabledSelector = createSelector(
  partnerNameSelector,
  partnerName => {
    if (eulaDisabledPartners.includes(partnerName)) {
      return false;
    }
    return true;
  }
);
