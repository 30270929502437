import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { withRouter } from "react-router-dom";

//Actions
import { postCsvExportAction } from "src/actions/data_actions";

// Components
import { AlertsSectionComponent } from "src/components/alerts_section_component";

// Selectors
import { alertsSectionTabsSelector } from "src/selectors/ui_config_selectors/alerts";
import {
  assessmentSummaryWoundCsvExportPostPayloadSelector,
  assessmentSummaryBradenCsvExportPostPayloadSelector,
  assessmentSummaryPURSCsvExportPostPayloadSelector,
  assessmentSummaryTotalSkinCsvExportPostPayloadSelector
} from "src/selectors/api_selectors/evaluation_list/csv_exports";
import {
  alertsEnabledSelector,
  evaluationListEnabledSelector,
  healingIndexDashboardEnabledSelector
} from "src/selectors/ui_config_selectors/global";

function mapStateToProps(state) {
  let postPayload;
  if (location.pathname === "/my-dashboard/evaluation-list/wound") {
    postPayload = assessmentSummaryWoundCsvExportPostPayloadSelector(state);
  } else if (location.pathname === "/my-dashboard/evaluation-list/braden") {
    postPayload = assessmentSummaryBradenCsvExportPostPayloadSelector(state);
  } else if (location.pathname === "/my-dashboard/evaluation-list/purs") {
    postPayload = assessmentSummaryPURSCsvExportPostPayloadSelector(state);
  } else if (location.pathname === "/my-dashboard/evaluation-list/total-skin") {
    postPayload = assessmentSummaryTotalSkinCsvExportPostPayloadSelector(state);
  }
  return {
    tabs: alertsSectionTabsSelector(state),
    csvPostPayload: postPayload,
    alertsTabEnabled: alertsEnabledSelector(state),
    evaluationListEnabled: evaluationListEnabledSelector(state),
    healingIndexDashboardEnabled: healingIndexDashboardEnabledSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendPostCsvExportAction(payload) {
      dispatch(postCsvExportAction({ payload }));
    }
  };
}

export const AlertsSectionContainer = flowRight(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AlertsSectionComponent);
