import { createSelector } from "reselect";

/**
 * Get the load state for a context string or context string selector
 * @param {Object|array|Function} queryObject - object, array of objects, or selector for a query object or array of query objects
 * @returns {Function} selector for context load state(s)
 */
export function createDataSelectorCreator(
  dataRootSelector,
  createMetaSelector
) {
  return function createDataSelector(queryObject) {
    return createSelector(
      dataRootSelector,
      createMetaSelector(queryObject),
      (data, meta) => {
        return meta.reduce((agg, m) => {
          if (m && m.ids) {
            const dataForMeta = m.ids.reduce((agg, id) => {
              if (data[id]) {
                agg.push(data[id]);
              }
              return agg;
            }, []);
            agg = agg.concat(dataForMeta);
          }
          return agg;
        }, []);
      }
    );
  };
}
