export const woundTypesV6MayoClinic = {
	//Wound Types
	abrasionOnly: "Abrasion",
	abscess: "Abscess",
	arterial: "Arterial Ulcer",
	avulsion: "Avulsion",
	blister: "Blister",
	burn: "Burn",
	cancerLesion: "Cancer Lesion",
	calciphylaxis: "Calciphylaxis",
	cellulitis: "Cellulitis",
	colostomy: "Colostomy",
	dermCondition: "Dermatologic Condition",
	diabetic: "Diabetic Ulcer",
	donorSite: "Donor site",
	extravasation: "Extravasation",
	fistula: "Fistula",
	friction: "Friction Injury",
	frostBite: "Frostbite",
	graphSite: "Graft Site",
	gunshot: "Gunshot",
	herpeticLesions: "Herpetic Lesions",
	hematoma: "Hematoma",
	hSuppurtiva: "Hidradenitis Suppurativa",
	illeostomy: "Illeostomy",
	incision: "Incision",
	iad: "Incontinence Associated Dermatitis",
	inflammatory: "Inflammatory Process",
	itd: "Intertriginous Dermatitis",
	laceration: "Laceration",
	medicalTape: "Medical Tape Injury",
	mixedVenousArterial: "Mixed Arterial & Venous",
	mole: "Mole",
	necrotizingFasciitis: "Necrotizing Fasciitis",
	neuropathic: "Neuropathic",
	lesion: "Open Lesion",
	otherMasd: "Other Moisture Associated Skin Damage",
	stoma: "Other Stoma",
	otherWound: "Other Wound",
	pilonidalSinus: "Pilonidal Sinus",
	pressureUlcer: "Pressure Injury",
	pyodermaGangrenosum: "Pyoderma Gangrenosum",
	radiation: "Radiation",
	shearing: "Shearing",
	skinTear: "Skin Tear",
	softTissueNecrosis: "Soft Tissue Necrosis",
	surgical: "Surgical",
	trauma: "Traumatic",
	urostomy: "Urostomy",
	vasculitis: "Vasculitis",
	venous: "Venous Ulcer",
	undiagnosed: "Undiagnosed",

	//Sub Types
	////Burn
	first: "Superficial (1st)",
	superficialSecond: "Superficial Partial, Deep Partial Thickness (2nd)",
	third: "Full Thickness (3rd)",
	fourth: "Fourth Degree",

	// Diabetic
	gradeZero: "Grade 0",
	gradeOne: "Grade 1",
	gradeTwo: "Grade 2",
	gradeThree: "Grade 3",
	gradeFour: "Grade 4",
	gradeFive: "Grade 5",

	//Dermatologic Condition
	ischemic: "Ischemic",
	neuroIschemic: "Neuro-ischemic",
	neuropathic: "Neuropathic",

	////Colostomy 
	ascending: "Ascending",
	descending: "Descending",
	end: "End",
	endLoop: "End loop",
	loop: "Loop",
	sigmoid: "Sigmoid",
	transverse: "Transverse",
	colOther: "Other",

	////Fistula
	colocutaneous: "Colocutaneous",
	enterocutaneous: "Enterocutaneous",

	////Illeostomy
	illeoContinent: "Continent",
	illeoEnd: "End",
	illeoEndLoop: "End loop",
	illeoLoop: "Loop",
	illeoOther: "Other",

	////Hidradenitis Suppurativa
	hurleyOne: "Hurley Stage I: Nodule or abscess formation without tracts or scars",
	hurleyTwo: "Hurley Stage I: Nodule or abscess formation without tracts or scars",
	hurleyThree: "Hurley Stage I: Nodule or abscess formation without tracts or scars",

	////Pressure Ulcer
	one: "Stage 1",
	two: "Stage 2",
	three: "Stage 3",
	four: "Stage 4",
	dti: "Deep Tissue Injury",
	medicalDevice: "Medical Device related",
	mucosalMembrane: "Mucosal Membrane",
	unstageable: "Unstageable",

	////Skin Tear
	type1None: "No skin loss",
	type2Partial: "Partial flap loss",
	type3Total: "Total flap loss",

	////Stoma
	cecostomy: "Cecostomy",
	esophagostomy: "Esophagostomy",
	jejunostomy: "Jejunostomy",
	mucousFistula: "Mucous Fistula",
	stomaOther: "Other",

	////Urostomy
	colonConduit: "Colon conduit",
	continent: "Continent",
	ilealConduit: "Ileal conduit",
	vesicostomy: "Vesicostomy",
	uroOther: "Other",

	////Applies to all notSet keys
	notSet: "Not Set"
};
