import { createSelector } from "reselect";

// Selectors
import { partnerNameSelector } from "../partner_name_selector";

export const woundIdLinkEnabledSelector = createSelector(
  partnerNameSelector,
  partnerName => {
    if (partnerName) {
      return false;
    }
    return true;
  }
);
