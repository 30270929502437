import React, { useState } from "react";
import { FlexBox, FlexItem, SelectComponent } from "src/components/ui_kit";
import { GridRow, GridColumn } from "src/components/grid";
import { push } from "react-router-redux";
import createFlashMessage from "app/flash_messages/create";
import { signOut } from "src/actions/session_actions";
import { connect } from "react-redux";

//Translations
import i18n from "src/translations";

//CSS
import styles from "./style.module.less";

//Components
import { ComplianceCliniciansListComponent } from "src/pages/compliance/components/compliance_clinicians_list_component.js";

function ComplianceCliniciansComponent({raiseErrorLoadingEvaluations}) {
  const [sortValue, setSortValue] = useState("-in_progress_count");

  const onSelectSortOption = value => {
    setSortValue(value);
  };

  const sortOptions = [
    {
      key: "alphabetical",
      value: "first_name",
      label: "Clinician Name"
    },
    {
      key: "in_progress_count",
      value: "-in_progress_count",
      label: "Most In Progress"
    },
    {
      key: "most_completed",
      value: "-completed_count",
      label: "Most Completed"
    }
  ];

  return (
    <div>
      <GridRow>
        <GridColumn xs="8">
          <h3>{i18n.t("headers.clinicians")}</h3>
        </GridColumn>
        <GridColumn xs="4" className={styles["align-right"]}>
          <div className={`${styles["margin-top-14px"]}`}>
            <div className={styles["float-right"]}>
              <SelectComponent
                value={sortValue}
                options={sortOptions}
                onChange={onSelectSortOption}
              />
            </div>
            <div
              className={`${styles["float-right"]} ${styles["margin-right"]}`}
            >
              <span>Sort By:</span>
            </div>
          </div>
        </GridColumn>
      </GridRow>
      <div className={styles["divisor"]} />
      <div className={styles["margin-bottom-40px"]}>
        <FlexBox vAlign="center">
          <FlexItem basis="220">
            <div
              className={`${styles["legend-box"]} ${
                styles["legend-completed"]
              } primary-background`}
            ></div>
            <p className={styles["legend-text"]}>
              {i18n.t("text.chartLegends.clinicianStatsCompleted")}
            </p>
          </FlexItem>
          <FlexItem basis="220">
            <div
              className={`${styles["legend-box"]} ${styles["legend-signed"]}`}
            ></div>
            <p className={styles["legend-text"]}>
              {i18n.t("text.chartLegends.clinicianStatsSigned")}
            </p>
          </FlexItem>
          <FlexItem basis="220">
            <div
              className={`${styles["legend-box"]} ${
                styles["legend-in-progress"]
              }`}
            ></div>
            <p className={styles["legend-text"]}>
              {i18n.t("text.chartLegends.clinicianStatsInProgress")}
            </p>
          </FlexItem>
        </FlexBox>
      </div>
      <ComplianceCliniciansListComponent raiseErrorLoadingEvaluations={raiseErrorLoadingEvaluations} sortValue={sortValue} />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    raiseErrorLoadingEvaluations: err => {
      console.error(err);
      if (err.response) {
        if (err.response.status == 401) {
          dispatch(
            createFlashMessage(
              "Your session has expired. Please sign in again.",
              "error"
            )
          );

          dispatch(signOut());
          dispatch(push("/sign_in"));
        } else {
          dispatch(
            createFlashMessage(
              i18n.t("interaction.errorMessages.userActions.requestCancelled"),
              "error"
            )
          );
        }
        console.error(err.response.data);
        console.log(err.response.status);
      }
    }
  };
}

export default connect(
  () => {},
  mapDispatchToProps
)(ComplianceCliniciansComponent);
