import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash.get";

// Components
import { GridRow, GridColumn } from "src/components/grid";
import BradenHistoryChart from "src/containers/trends/BradenHistoryChart";
import NortonHistoryChart from "src/containers/trends/NortonHistoryChart";

/**
 * Render a header view for a specific type of non wound assessment (ex. braden, norton, skin, purs)
 */
export class NonWoundAssessmentDetailsHeaderComponent extends React.PureComponent {
  static propTypes = {
    assessmentAnswer: PropTypes.object.isRequired,
    assessmentAnswerGroup: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    assessmentSchema: PropTypes.object.isRequired
  };

  /**
   * Legacy chart code
   * Render chart conditionally on the assessment title
   */
  renderChart() {
    const { assessmentSchema, patientTrend } = this.props;
    const tileName = get(
      { assessmentSchema },
      "assessmentSchema.attributes.schemaJson.localizationDictionary.base.tileName",
      ""
    );

    switch (tileName) {
      case "Braden":
        return <BradenHistoryChart data={patientTrend.bradenTrend} />;
      case "Norton Plus":
        return <NortonHistoryChart data={patientTrend.nortonPlusTrend} />;
    }

    return undefined;
  }

  render() {
    const { assessmentAnswer, user, assessmentSchema } = this.props;
    const firstName = get({ user }, "user.attributes.profile.firstName", null);
    const lastName = get({ user }, "user.attributes.profile.lastName", null);

    let fullName;

    if (!firstName && !lastName) {
      fullName = get({ user }, "user.attributes.username", "");
    } else if (firstName && !lastName) {
      fullName = firstName;
    } else if (lastName && !firstName) {
      fullName = lastName;
    } else {
      fullName = `${firstName} ${lastName}`;
    }

    const title = get(
      { assessmentSchema },
      "assessmentSchema.attributes.schemaJson.localizationDictionary.base.tileName",
      ""
    );

    const completedBy = `Completed By: ${fullName}`;

    const createdAt = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.createdAt",
      null
    );

    const lastAssessed = `Last Evaluated:  ${createdAt &&
      moment(createdAt).fromNow()}`;

    return (
      <GridRow>
        <GridColumn sm="8">
          <div>
            <h1>{title}</h1>
            <h3>{lastAssessed}</h3>
            <h3>{completedBy}</h3>
          </div>
        </GridColumn>
        <GridColumn sm="4">{this.renderChart()}</GridColumn>
      </GridRow>
    );
  }
}
