import { createSelector } from "reselect";

//Selectors
import {
  selectedWoundOptionsSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector,
  selectedDateSelector,
  selectedProgressFilterSelector,
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  selectedPayersIdsFilterSelector
} from "src/selectors/section_selectors/wounds";

//Payload Generator
import { apiV2AssessmentsPayloadGenerator } from "src/api/wound_assessments";

export const firstPagePayloadForSelectedProgressSelector = createSelector(
  selectedWoundOptionsSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector,
  selectedDateSelector,
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  selectedProgressFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  selectedPayersIdsFilterSelector,
  (
    selectedWoundOptions,
    selectedLocationIDs,
    acquiredFilter,
    selectedEndDate,
    hprScoreMinRangeFilter,
    hprScoreMaxRangeFilter,
    selectedProgressFilter,
    bwatScoreMinRangeFilter,
    bwatScoreMaxRangeFilter,
    selectedPayersIds
  ) => {
    const pageNumber = 0,
      pageLimit = 10;

    return apiV2AssessmentsPayloadGenerator(
      selectedWoundOptions,
      selectedLocationIDs,
      acquiredFilter,
      selectedEndDate,
      hprScoreMinRangeFilter,
      hprScoreMaxRangeFilter,
      bwatScoreMinRangeFilter,
      bwatScoreMaxRangeFilter,
      selectedProgressFilter,
      pageNumber,
      pageLimit,
      selectedPayersIds
    );
  }
);
