import { createResourceActions } from "src/redux_resources_v1";
// Error tracking
import { trackError } from "src/error_tracking";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

/**
 * Create a put data action creator
 * @param {Object} options
 * @param {string} options.resourceName resource name
 * @param {Object} options.api api object that responds to "patch" call
 * @param {function} options.onSuccess thunk action for success case
 * @param {function} options.onError thunk action for error case
 * @returns {Function} action creator for a thunk action to Patch the resource
 */
export function createPutDataAction({
  resourceName,
  api,
  onSuccess,
  onError,
  responseNormalizer
}) {
  const analyticsEventName = `API_PUT_${resourceName.toUpperCase()}`;
  const resourceActions = createResourceActions(resourceName);
  return ({ payload }) => {
    return dispatch => {
      dispatch(resourceActions.loadingPutAction(payload.id));
      startTimeTrack(analyticsEventName);

      return api
        .put({ payload })
        .then(response => {
          if (response.data && responseNormalizer) {
            const data = responseNormalizer(response.data);
            dispatch(
              resourceActions.populateAction({
                data
              })
            );
          }

          finishTimeTrack(analyticsEventName);
          if (onSuccess) {
            onSuccess(dispatch);
          }
        })
        .catch(err => {
          finishTimeTrack(analyticsEventName, {
            error: true,
            errorCode: err.response ? err.response.status : undefined
          });
          trackError(err);
          dispatch(resourceActions.loadErrorPutAction(payload.id));

          if (onError) {
            onError(dispatch, err);
          }

          return err;
        });
    };
  };
}
