import get from "lodash.get";

export function validatePassword(value, values) {
  const passwordMinLength = get(values, "passwordMinLength", 8);
  const passwordStrength = new RegExp(
    `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{${passwordMinLength},}$`
  );
  return value && !passwordStrength.test(value)
    ? `Password must be ${passwordMinLength} or more characters long, contain at least one capital, one lowercase, one number and one non-alphanumeric symbol (eg. Sw1ftmedic@l)`
    : "";
}
