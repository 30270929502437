import { createSelector } from "reselect";
import { allLocationsSelector } from "../all_locations_selector";
import { partnerLocationIdsSelector } from "../../../config_selectors/partner_location_ids_selector";
import { isEMCViewModeSelector } from "../../../session_selectors";

/**
 * Selector for locations to display data for to user
 */
export const locationsToDisplaySelector = createSelector(
  allLocationsSelector,
  partnerLocationIdsSelector,
  isEMCViewModeSelector,
  (allLocations, partnerLocationIds, isAdmin) => {
    // all locs - excluding provinces
    allLocations = allLocations.filter(loc => {
      return loc.depth != 0;
    });

    if (!isAdmin && partnerLocationIds.length) {
      return allLocations.filter(loc => {
        return partnerLocationIds.includes(loc.id);
      });
    } else {
      return allLocations;
    }
  }
);
