import { createSelector } from "reselect";

import { buildQueryString } from "src/utils/build_query_string";

export function createAllAssessmentsWithSchemasQuerySelector() {
  return createSelector(() => {
    return buildQueryString({
      include: "assessment_schema,child_assessments.assessment_schema"
    });
  });
}
