import get from "lodash.get";

import formatScore from "src/helpers/assessmentAnswers/formatScore";
import { getNumberTypeSubtypeStringForAssessmentAnswer } from "src/models/assessment_answers";
import { ageThisMoment } from "src/utils/timeUtils";
import {
  pressureWoundTypes,
  incompletePushScoreValues,
  incompleteBwatScoreValues
} from "src/constants";
import i18n from "src/translations";
import { formatBodyLocationString } from "src/utils/format_location_string";
import { createListStringFromArray } from "src/utils/format_string_list_from_array";

/**
 * Return pdfmake stack object of patient and wound summary information for
 * display at the top of the pdf body content.
 * @param {Object} obj
 * @param {Object} obj.assessmentAnswer - the assessmentAnswer
 * @param {Object} obj.revision - the revision
 * @return {object} pdf object
 */
export function getWoundHeader(pdfContentObject, options, isCoverPage) {
  const {
    series,
    revision,
    assessmentAnswer,
    payersForRevision
  } = pdfContentObject;

  const {
    woundNumbersEnabled,
    bwatScoreEnabled,
    encounterNamingConvention,
    payerDashboardEnabled,
    visitSequenceEnabled,
    schemaLocalizationDictionary,
    assessmentVersion,
    etiologyVerifiedQuestionEnabled,
    subtypeKeysForSchema
  } = options;

  const {
    overallProgress,
    inHouseAcquired,
    woundType,
    pushScore,
    location,
    sublocation,
    laterality,
    bwatScore
  } = assessmentAnswer.attributes.answersJson;

  const { encounterNumber } = assessmentAnswer.attributes;
  // Wound Age
  const woundAge = get(
    { assessmentAnswer },
    "assessmentAnswer.attributes.answersJson.woundAge",
    "unknown"
  );

  const visitFrequency = get(
    { assessmentAnswer },
    "assessmentAnswer.attributes.answersJson.visitFrequency",
    "N/A"
  );

  const physicianVerified = get(
    { assessmentAnswer },
    "assessmentAnswer.attributes.answersJson.physicianVerified",
    "N/A"
  );

  const seriesOriginAt = get(series, "attributes.originAt", null);

  const revisionCreatedAt = get(revision, "attributes.createdAt", null);

  let woundAgeFromOrigin = ageThisMoment(revisionCreatedAt, seriesOriginAt);

  if (["chronic", "unknown"].includes(woundAge)) {
    woundAgeFromOrigin = i18n.t(`data.woundAge.${woundAge}`);
  }

  const woundNumber = get(series, "attributes.patientWoundNumber");

  // AA values
  let woundTypeTitle = getNumberTypeSubtypeStringForAssessmentAnswer({
    assessmentAnswer,
    localizationDictionary: schemaLocalizationDictionary,
    subtypeKeysForSchema,
    ...(woundNumbersEnabled && { patientWoundNumber: woundNumber })
  });

  const progress = overallProgress
    ? i18n.t(`data.overallProgress.${assessmentVersion}.${overallProgress}`)
    : "Status Unknown";
  const acquiredString = inHouseAcquired
    ? `${i18n.t("glossary.acquired", { context: "capitalize" })}: ${i18n.t(
        `data.acquired.${assessmentVersion}.${inHouseAcquired}`
      )}`
    : `${i18n.t("glossary.acquired", { context: "capitalize" })}: ${i18n.t(
        "text.notSet"
      )}`;

  const bodyLocation = location
    ? formatBodyLocationString(
        {
          location,
          sublocation,
          lateralities: laterality
        },
        schemaLocalizationDictionary
      )
    : "Location not set";

  const pushScoreLabel = pressureWoundTypes.includes(woundType)
    ? {
        text: `PUSH Score: ${formatScore(
          incompletePushScoreValues.includes(pushScore) ? "INC" : pushScore
        )}`,
        style: "paragraph"
      }
    : null;

  const bwatScoreLabel = bwatScore
    ? {
        text: `BWAT Score: ${formatScore(
          incompleteBwatScoreValues.includes(bwatScore) ? "INC" : bwatScore
        )}`,
        style: "paragraph"
      }
    : null;

  const encounterLabel = encounterNumber
    ? {
        text: `${encounterNamingConvention}: ${encounterNumber}`,
        style: "paragraph"
      }
    : null;

  const payersForRevisionLabel = payersForRevision
    ? {
        text: `Payer(s): ${createListStringFromArray(payersForRevision)}`,
        style: "paragraph"
      }
    : null;

  const visitSequenceLabel = visitFrequency
    ? {
        text: `Current Wk Visit Freq: ${visitFrequency}`,
        style: "paragraph"
      }
    : null;

  const physicianVerifiedLabel = physicianVerified
    ? {
        text: `Etiology Verified by Physician: ${physicianVerified}`,
        style: "paragraph"
      }
    : null;

  let woundHeaderStackArray = [
    {
      text: woundTypeTitle,
      style: "h2"
    },
    {
      text: `Body Location: ${bodyLocation}`,
      style: "paragraph"
    },
    {
      text: `${progress} - ${woundAgeFromOrigin}`,
      style: "paragraph"
    },
    ...(physicianVerifiedLabel && etiologyVerifiedQuestionEnabled
      ? [physicianVerifiedLabel]
      : []),
    ...(payersForRevisionLabel && payerDashboardEnabled
      ? [payersForRevisionLabel]
      : []),
    ...(visitSequenceLabel && visitSequenceEnabled ? [visitSequenceLabel] : []),
    {
      text: acquiredString,
      style: "paragraph"
    },
    ...(!isCoverPage && pushScoreLabel && !bwatScoreEnabled
      ? [pushScoreLabel]
      : []),
    ...(!isCoverPage && bwatScoreLabel && bwatScoreEnabled
      ? [bwatScoreLabel]
      : []),
    ...(encounterLabel && encounterNamingConvention !== "None"
      ? [encounterLabel]
      : [])
  ];

  if (options.historyCauseEnabled) {
    let history = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.history",
      ""
    );

    if (history === "") {
      history = "Not Set";
    } else {
      history = i18n.t(`data.historyOrCause.${history}`);
    }

    woundHeaderStackArray.splice(2, 0, {
      text: `History/Cause: ${history}`,
      style: "paragraph"
    });
  }

  return {
    stack: woundHeaderStackArray,
    alignment: "left",
    margin: [0, 0, 0, 10]
  };
}
