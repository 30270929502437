import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash.get";
import { trackError } from "src/error_tracking";

import zip from "lodash.zip";
import zipobject from "lodash.zipobject";

// Model
import { getPatientQrCodeDataURL } from "src/models/patients";

//Constants
import { pressureWoundTypes } from "src/constants";

// Pdf
import { createRevisionPdf } from "src/pdfs";
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import { pdfTableStyle } from "src/pdfs";
pdfMake.vfs = vfsFonts.pdfMake.vfs;
pdfMake.tableLayouts = { pdfTableStyle };

// Utils
import {
  getImageDataUrlFromPath,
  getImageDataUrlFromLocalPath,
  renderChartToImageDataUrl
} from "src/utils/image_data_url";
import { getQueryParamsFromUrl } from "src/utils/get_query_params_from_url";

// Translations
import i18n from "src/translations";

// Style
import styles from "./style.module.less";

export default class RevisionWoundDetailsHeaderComponent extends React.PureComponent {
  static propTypes = {
    series: PropTypes.object.isRequired,
    revision: PropTypes.object.isRequired,
    assessmentAnswer: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    progressSchemas: PropTypes.array.isRequired,
    treatmentSchemas: PropTypes.array.isRequired,
    attributesSchemas: PropTypes.array.isRequired,
    describeSchemas: PropTypes.array.isRequired,
    revisionIsFirstInSeries: PropTypes.bool.isRequired,
    pdfExportEnabled: PropTypes.bool.isRequired,
    copyAssessmentToClipboardEnabled: PropTypes.bool.isRequired,
    stringifiedAssessmentJSON: PropTypes.string.isRequired,
    seriesTrend: PropTypes.object.isRequired,
    showPdfErrorView: PropTypes.func.isRequired,
    latestSignedEvent: PropTypes.array.isRequired,
    latestLockedEvent: PropTypes.array.isRequired,
    isAutodepth: PropTypes.bool.isRequired,
    woundNumbersEnabled: PropTypes.bool.isRequired,
    branding: PropTypes.string.isRequired,
    patientLastFacility: PropTypes.object,
    historyCauseEnabled: PropTypes.bool.isRequired,
    bwatScoreEnabled: PropTypes.bool,
    encounterNamingConvention: PropTypes.string.isRequired,
    payerDashboardEnabled: PropTypes.bool.isRequired,
    visitSequenceEnabled: PropTypes.bool.isRequired,
    payersForRevision: PropTypes.array.isRequired,
    brandedLogoPng: PropTypes.object.isRequired,
    assessmentVersion: PropTypes.string.isRequired,
    etiologyVerifiedQuestionEnabled: PropTypes.bool.isRequired
  };

  state = {
    pdfDisabled: false,
    copyDisabled: false
  };

  /**
   * TODO: refactor this
   * Get the closure chart image data url
   * @returns {Promise}
   */
  getClosureChartUrl() {
    const {
      seriesTrend: { closureTrend }
    } = this.props;

    const chartData = getChartObjectForPdf(closureTrend, "Percent Changed", {
      max: 100
    });
    return renderChartToImageDataUrl(chartData, 600, 450);
  }

  /**
   * TODO: refactor this
   * Get the push score chart image data url
   * @returns {Promise}
   */
  getPushChartUrl() {
    const {
      seriesTrend: { pushTrend }
    } = this.props;
    const chartData = getChartObjectForPdf(pushTrend, "PUSH", {
      min: 0,
      max: 17
    });

    return renderChartToImageDataUrl(chartData, 600, 450);
  }

  /**
   * Get the hpr chart image data url
   * @returns {Promise}
   */
  getHprChartUrl() {
    const {
      seriesTrend: { hprTrend }
    } = this.props;

    const chartData = getChartObjectForPdf(hprTrend, "HPR", {
      max: 30
    });

    return renderChartToImageDataUrl(chartData, 600, 450);
  }

  /**
   * Get the BWAT chart image data url
   * @returns {Promise}
   */
  getBwatChartUrl() {
    const {
      seriesTrend: { bwatTrend }
    } = this.props;

    const chartData = getChartObjectForPdf(bwatTrend, "BWAT", {
      max: 70
    });

    return renderChartToImageDataUrl(chartData, 600, 450);
  }

  /**
   * Handler for printing the pdf
   */
  handlePrintViewButtonClick = () => {
    const {
      frame,
      patient,
      revision,
      assessmentAnswer,
      patientType,
      branding,
      brandedLogoPng
    } = this.props;

    // image expiry values
    const pdfUrlExpiresAt = getQueryParamsFromUrl(
      get({ frame }, "frame.attributes.pdfUrl", "")
    ).Expires;

    if (pdfUrlExpiresAt && moment().isAfter(moment.unix(pdfUrlExpiresAt))) {
      this.props.showPdfTimeoutErrorMessage();
      return;
    }

    const woundType = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.woundType",
      ""
    );

    this.setState({ pdfDisabled: true });

    const hasImage = get({ frame }, "frame.attributes.hasImage", false),
      pdfUrl = get({ frame }, "frame.attributes.pdfUrl", ""),
      contentType = get({ frame }, "frame.attributes.contentType", ""),
      patientLastName = get({ patient }, "patient.attributes.lastName", ""),
      patientFirstName = get({ patient }, "patient.attributes.firstName", "");

    Promise.all([
      getImageDataUrlFromLocalPath(brandedLogoPng),
      hasImage ? getImageDataUrlFromPath(pdfUrl) : Promise.resolve(null),
      getPatientQrCodeDataURL(patient)
    ])
      .then(async ([logoDataUrl, woundImageUrl, qrcodeUrl]) => {
        const pdfContentObjects = [
          {
            user: this.props.user,
            series: this.props.series,
            patient: this.props.patient,
            patientLastFacility: this.props.patientLastFacility,
            revision: this.props.revision,
            payersForRevision: this.props.payersForRevision[
              this.props.revision.id
            ],
            assessmentAnswer: this.props.assessmentAnswer,
            schemas: {
              attributes: this.props.attributesSchemas,
              progress: this.props.progressSchemas,
              treatment: this.props.treatmentSchemas,
              describe: this.props.describeSchemas
            },
            latestSignedEvent: this.props.latestSignedEvent.length
              ? this.props.latestSignedEvent[0]
              : undefined,
            latestLockedEvent: this.props.latestLockedEvent.length
              ? this.props.latestLockedEvent[0]
              : undefined,
            isAutodepth: this.props.isAutodepth,
            revisionIsFirstInSeries: this.props.revisionIsFirstInSeries,
            images: {
              wound: woundImageUrl,
              qrCode: qrcodeUrl,
              closureChart:
                contentType == "declined" ? null : this.getClosureChartUrl(),
              pushChart: pressureWoundTypes.includes(woundType)
                ? this.getPushChartUrl()
                : null,
              hprChart: this.props.hprScoreEnabled
                ? this.getHprChartUrl()
                : null,
              bwatChart: this.props.bwatScoreEnabled
                ? this.getBwatChartUrl()
                : null,
              imageText:
                contentType == "declined"
                  ? patientType + " Declined Photo"
                  : "No Image"
            }
          }
        ];

        let globalContent = {
          reportName: "WOUND EVALUATION",
          logo: logoDataUrl
        };

        let schemaLocalizationDictionary = {};
        const assessmentId = get(assessmentAnswer, "attributes.assessmentId");
        schemaLocalizationDictionary[
          `${assessmentId}`
        ] = this.props.schemaLocalizationDictionary;

        return await createRevisionPdf(pdfContentObjects, globalContent, {
          branding: branding,
          woundNumbersEnabled: this.props.woundNumbersEnabled,
          bwatScoreEnabled: this.props.bwatScoreEnabled,
          encounterNamingConvention: this.props.encounterNamingConvention,
          payerDashboardEnabled: this.props.payerDashboardEnabled,
          visitSequenceEnabled: this.props.visitSequenceEnabled,
          historyCauseEnabled: this.props.historyCauseEnabled,
          hprScoreEnabled: this.props.hprScoreEnabled,
          schemaLocalizationDictionary,
          assessmentVersion: this.props.assessmentVersion,
          etiologyVerifiedQuestionEnabled: this.props
            .etiologyVerifiedQuestionEnabled,
          subtypeKeysForSchema: this.props.subtypeKeysForSchema
        });
      })
      .then(pdfDocDefinition => {
        const date = new moment(revision.attributes.createdAt).format(
          "YYYY-MM-DD--HH-mm"
        );

        pdfMake
          .createPdf(pdfDocDefinition)
          .download(
            `${patientLastName}_${patientFirstName}_wound_assessment_${date}.pdf`
          );
        this.setState({ pdfDisabled: false });
      })
      .catch(err => {
        this.setState({ pdfDisabled: false });
        this.props.showPdfErrorView();
        trackError(err, {
          extra: { error_context: "revision_pdf" }
        });
      });
  };

  /**
   * Render function of PDF Button
   */
  renderAssessmentPrintViewButton() {
    if (this.props.pdfExportEnabled && !this.props.isRevisionBeingEdited) {
      return (
        <button
          className={`${styles["assessment-button"]} dashboard-btn`}
          type="button"
          value="series_id"
          disabled={this.state.pdfDisabled}
          onClick={this.handlePrintViewButtonClick}
        >
          {i18n.t(`actions.downloadPDF`)}
        </button>
      );
    }
  }

  /**
   * Handler for copying assessment to clipboard
   */
  handleCopyAssessmentButtonClick = () => {
    navigator.clipboard.writeText(this.props.stringifiedAssessmentJSON);
  };

  /**
   * Render function of Copy Assessment Button
   */
  renderCopyAssessmentToClipboardButton() {
    if (
      this.props.copyAssessmentToClipboardEnabled &&
      !this.props.isRevisionBeingEdited
    ) {
      return (
        <button
          className={`${styles["assessment-button"]} dashboard-btn`}
          type="button"
          value="series_id"
          disabled={this.state.copyDisabled}
          onClick={this.handleCopyAssessmentButtonClick}
        >
          {i18n.t(`actions.copyEvaluation`)}
        </button>
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderAssessmentPrintViewButton()}
        {this.renderCopyAssessmentToClipboardButton()}
      </div>
    );
  }
}

/**
 * Get the chart.js definition for each chart type for pdf
 * TODO: Generating this definition should be moved to a selector when
 * the patient tab and its selectors are refactored
 * Selector should be specific to each chart (and potentially to pdf vs. render)
 */
function getChartObjectForPdf(
  valueDateModel,
  title,
  { beginAnywhere, noMax, max, noMin, min }
) {
  const { values, dates } = valueDateModel;

  const data = zip(dates.toArray(), values.toArray()).map(
    zipobject.bind(null, ["x", "y"])
  );

  return {
    type: "line",
    data: {
      datasets: [
        {
          label: "Treatments",
          fill: true,
          backgroundColor: "#e6efd5",
          strokeColor: "rgba(124,174,43,1)",
          pointColor: "rgba(124,174,43,1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(124,174,43,1)",
          data: data,
          pointRadius: 6,
          pointHoverRadius: 10,
          pointHitRadius: 30,
          pointBorderWidth: 2,
          borderColor: "rgba(124,174,43,1)"
        }
      ]
    },
    options: {
      animation: false,
      responsive: false,
      title: {
        display: true,
        text: title,
        fontSize: 40,
        padding: 10,
        fontColor: "#000000"
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              callback(label, index, labelsArray) {
                if (index === 0 || index === labelsArray.length - 1) {
                  return moment(label).format("MM/DD/YYYY");
                }
              },
              fontSize: 22,
              fontColor: "#000000",
              maxRotation: 0,
              padding: 20
            },
            gridLines: {
              color: "#000000"
            },
            time: {
              displayFormats: "MM/DD/YYYY",
              unit: "day",
              round: "day"
            },
            type: "time",
            display: true,
            scaleLabel: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              callback(label) {
                if (label.toFixed(0) == label) {
                  return label;
                }
              },
              beginAtZero: !beginAnywhere,
              max: noMax ? undefined : max,
              min: noMin ? undefined : min,
              fontSize: 22,
              fontColor: "#000000"
              // Can add step size (ie. stepSize:5 ticks to be 5 points appart)
            },
            gridLines: {
              color: "#000000"
            },
            display: true,
            scaleLabel: {
              display: false
            }
          }
        ]
      }
    }
  };
}
