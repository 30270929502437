import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyValues } from "src/models/policy";

//Constants
import { policyInternalNames } from "src/constants";

/**
 * Memoized selector to determine if evaluation list has non-wound assessments enabled for dashboard
 */

export const evaluationListNonWoundAssessmentsEnabledSelector = createSelector(
  createPolicyForInternalNameSelector(policyInternalNames.nonWoundSchemas),
  policy => {
    let enabledNonWoundAssessments = [];
    //Until most recent revisions can be brought to non-wound assessments
    let disableAll = [];
    if (!policy) return enabledNonWoundAssessments;
    if (
      policy.attributes.policyValue.includes(policyValues.nonWoundSchema.braden)
    ) {
      enabledNonWoundAssessments.push("Braden");
    }
    const totalSkinEnabled = policy.attributes.policyValue.some(policy =>
      policyValues.nonWoundSchema.fullBodySkin.includes(policy)
    );
    if (totalSkinEnabled) {
      enabledNonWoundAssessments.push("Total Skin");
    }
    if (
      policy.attributes.policyValue.includes(policyValues.nonWoundSchema.purs)
    ) {
      enabledNonWoundAssessments.push("PURS");
    }
    return disableAll ? disableAll : enabledNonWoundAssessments;
  }
);
