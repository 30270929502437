export const actions = {
  acknowledge: "Acknowledge",
  add: "Add",
  addAlert: "Add Alert",
  addPatient: "Add Patient",
  addRole: "Add Role",
  addUser: "Add User",
  cancel: "Cancel",
  copyEvaluation: "Copy Evaluation",
  createCSV: "Create CSV",
  createRole: "Create Role",
  deleteFilter: "Delete Filter",
  disable: "Disable",
  downloadPDF: "Download PDF",
  downloadSummary: "Download Summary",
  enable: "Enable",
  generateExport: "Generate Export",
  importPatients: "Import Patients",
  importUsers: "Import Users",
  loadMore: "Load More...",
  lock: "Lock",
  remove: "Remove",
  removeRole: "Remove Role",
  reopen: "Reopen",
  reset: "Reset",
  retry: "Retry",
  save: "Save",
  saveChanges: "Save Changes",
  saveFilter: "Save Filter",
  selectAll: "Select All",
  sign: "Sign",
  signAndLock: "Sign and Lock",
  signIn: "Sign In",
  unlock: "Unlock",
  viewEvaluation: "View Evaluation",
  yes: "Yes"
};
