import React from "react";
export class PatientsIconComponent extends React.Component {
  render() {
    return (
      <svg
        width="35"
        height="37"
        viewBox="0 0 35 37"
        xmlns="http://www.w3.org/2000/svg"
        {...this.props}
      >
        <path d="M24.318 24.72c5.015.68 8.37 3.637 9.027 4.71 1.245 1.98 1.14 6.593 1.14 6.593H.006s-.173-4.905 1.487-6.886c.934-.845 3.874-3.736 8.888-4.45 4.98-.683 3.46-5.88 3.632-5.815.173.098-.035-1.104-.035-1.104s-3.63-3.28-3.597-6.237c.07-2.987 0-4.514 0-4.514S10.968 0 16.087 0l2.87 1.3c4.6 0 5.36 5.716 5.36 5.716v4.937s-.587 4.19-3.457 5.717c0 0-.208 1.202-.035 1.105.173-.065-1.487 5.23 3.493 5.944z" />
      </svg>
    );
  }
}
