import { createSelector } from "reselect";
import get from "lodash.get";

//Selectors
import { patientsResourceSelectors } from "src/selectors/data_selectors";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

export function createLastFacilityQueryForPatientIdSelector({ patientId }) {
  return createSelector(
    patientsResourceSelectors.createDataForIdSelector(patientId),
    patient => {
      const lastFacilityId = get(
        patient,
        "attributes.lastFacilityId",
        undefined
      );

      if (lastFacilityId) {
        return buildQueryString({ filter: { id: lastFacilityId } });
      }

      return undefined;
    }
  );
}
