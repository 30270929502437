import { createSelector } from "reselect";

// Api
import { createGetPayload } from "src/api/assessment_summaries/payload_generators";
import {
  answersFilterSelector,
  selectedLocationIdsSelector,
  evaluationListTotalSkinSectionSelector
} from "src/selectors/section_selectors/evaluation_list/evaluation_list_total_skin_selector";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const totalSkinEvaluationPageLimitSelector = SingleValueReduxUtil.createSelectors(
  "totalSkinEvaluationPageLimit",
  evaluationListTotalSkinSectionSelector
);

export function createFirstPagePayloadSelector() {
  return createSelector(
    totalSkinEvaluationPageLimitSelector.fieldSelector,
    selectedLocationIdsSelector,
    answersFilterSelector,
    (pageLimit, selectedLocationIds, answers) => {
      if (!selectedLocationIds.length) return {};
      return createGetPayload({
        pageLimit,
        answers,
        patient_state: "admitted,new",
        selectedLocationIds,
        pageNumber: 0,
        sortEnabled: "created_at"
      });
    }
  );
}
