import { createSelector } from "reselect";
import { dataSelector } from "../../data_selector";

/**
 * Selector for the exports section of the data state tree
 */
export const exportsSelector = createSelector(
  dataSelector,
  data => data.exports
);
