import { medlineColors, defaultColors } from "src/constants/branding_colors";

// Translations
import i18n from "src/translations";

const LINE_TENSION = 0.075;

/**
 * Create a chart.js chart definition object for rendering a line chart showing
 * wound trends for each wound progress label (ex. new, stable)
 * @param {Object} chartData
 */
export function createWoundAssessmentProgressLineChart(
  data,
  branding,
  assessmentVersion
) {
  // Labels
  const labels = data.timeIntervals;
  // Branded colors
  const brandingColors =
    branding === "medline"
      ? medlineColors.woundsChart
      : defaultColors.woundsChart;

  // datasets
  const datasets = [
    {
      label: i18n.t(`data.overallProgress.${assessmentVersion}.new`),
      progressKey: "new",
      backgroundColor: brandingColors.new,
      fill: false,
      borderColor: brandingColors.new,
      pointBackgroundColor: brandingColors.new,
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data: data.newData,
      tension: LINE_TENSION
    },
    {
      label: i18n.t(`data.overallProgress.${assessmentVersion}.worse`),
      progressKey: "worse",
      backgroundColor: brandingColors.deteriorating,
      fill: false,
      borderColor: brandingColors.deteriorating,
      pointBackgroundColor: brandingColors.deteriorating,
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data: data.worseData,
      tension: LINE_TENSION
    },

    {
      label: i18n.t(`data.overallProgress.${assessmentVersion}.stalled`),
      progressKey: "stalled",
      backgroundColor: brandingColors.stalled,
      fill: false,
      borderColor: brandingColors.stalled,
      pointBackgroundColor: brandingColors.stalled,
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data: data.stalledData,
      tension: LINE_TENSION
    },

    {
      label: i18n.t(`data.overallProgress.${assessmentVersion}.stable`),
      progressKey: "stable",
      backgroundColor: brandingColors.stable,
      fill: false,
      borderColor: brandingColors.stable,
      pointBackgroundColor: brandingColors.stable,
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data: data.stableData,
      tension: LINE_TENSION
    },

    {
      label: i18n.t(`data.overallProgress.${assessmentVersion}.better`),
      progressKey: "better",
      backgroundColor: brandingColors.improving,
      fill: false,
      borderColor: brandingColors.improving,
      pointBackgroundColor: brandingColors.improving,
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data: data.betterData,
      tension: LINE_TENSION
    },

    {
      label: i18n.t(`data.overallProgress.${assessmentVersion}.monitoring`),
      progressKey: "monitoring",
      backgroundColor: "#808080",
      fill: false,
      borderColor: "#808080",
      pointBackgroundColor: "#808080",
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data: data.monitoringData,
      tension: LINE_TENSION
    },
    {
      label: i18n.t(`data.overallProgress.${assessmentVersion}.unknown`),
      progressKey: "unknown",
      backgroundColor: "#000000",
      fill: false,
      borderColor: "#000000",
      pointBackgroundColor: "#000000",
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data: data.unknownData,
      tension: LINE_TENSION
    }
  ];

  const options = {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "# of Wounds",
            fontColor: "#999999"
          },
          ticks: {
            beginAtZero: true,
            type: "linear",
            callback: value => {
              if (Number.isInteger(value)) {
                return value;
              } else {
                return null;
              }
            }
          }
        }
      ]
    },
    legend: {
      display: false
    },
    tooltips: {
      mode: "label"
    }
  };

  return {
    type: "line",
    data: {
      labels,
      datasets
    },
    options
  };
}
