export const woundTypesV3 = {
  //Wound Types
  abrasionOnly: "Abrasion",
  abrasion: "Abrasion/Bruise",
  arterial: "Arterial",
  blister: "Blister",
  bruise: "Bruise",
  burn: "Burn",
  cancerLesion: "Cancer Lesion",
  diabetic: "Diabetic",
  hSuppurtiva: "Hidradenitis Suppurativa",
  laceration: "Laceration",
  masd: "Moisture Associated Skin Damage (MASD)",
  mole: "Mole",
  lesion: "Open Lesion",
  pressureUlcer: "Pressure",
  rash: "Rash",
  skinTear: "Skin Tear",
  surgical: "Surgical",
  venous: "Venous",
  undiagnosed: "Undiagnosed",

  //Sub Types
  ////Skin Tear
  category1Linear: "Category I: Linear",
  category1Flap: "Category I: Flap",
  category2below25: "Category II: < 25% loss",
  category2above25: "Category II: > 25% loss",
  category3complete: "Category III: Complete loss",

  ////Burn
  first: "First Degree",
  second: "Second Degree",
  third: "Third Degree",

  ////Bruise
  bruiseType: "Colour",

  ////// (Bruise) Color
  red: "Red",
  bluePurple: "Blue/Purple",
  green: "Green",
  yellowBrown: "Yellow/Brown",

  ////Pressure Ulcer, Pressure Kennedy, Pressure Device Related
  one: "Stage 1",
  two: "Stage 2",
  three: "Stage 3",
  four: "Stage 4",
  dti: "Deep Tissue Injury",
  unstageable: "Unstageable",

  ////Pressure Ulcer, Pressure Device Related
  mucosalMembrane: "Mucosal Membrane",

  //////(Pressure Ulcer, Pressure Kennedy, Pressure Device Related) Unstageable
  necrosis: "Slough and/or eschar",
  dressing: "Non-removable device/dressing",

  ////MASD
  iad: "IAD",
  other: "Other",

  ////Surgical
  surgicalClosure: "Surgical Closure Method",
  surgicalOther: "Other",

  //////Surgical Closure Method
  closureSutures: "Sutures",
  closureStaples: "Staples",
  closureTape: "Tape",
  closureSteriStrips: "Steri-Strips",
  closureTissueAdhesives: "Tissue Adhesives",

  //////(Surgical) Other
  incisionApprox: "Incision Approximated",
  dehiscence: "Dehiscence",
  healingRidge: "Healing Ridge",

  ////Applies to all notSet keys
  notSet: "Not Set"
};
