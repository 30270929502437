import { connect } from "react-redux";

// Components
import { ButtonComponent } from "src/components/button_component";

// Selectors
import { createSignButtonVisibleSelector } from "src/selectors/ui_config_selectors/patients/create_sign_button_visible_selector";
import { createSignIsDisabledSelector } from "src/selectors/ui_config_selectors/patients/create_sign_is_disabled_selector";
import { createSignTooltipSelector } from "src/selectors/ui_config_selectors/patients/create_sign_tooltip_selector";

import { signAssessmentAnswerAction } from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  return {
    isVisible: createSignButtonVisibleSelector(ownProps)(state),
    disabled: createSignIsDisabledSelector(ownProps)(state),
    tooltip: createSignTooltipSelector(ownProps)(state)
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const payload = {
    id: ownProps.assessmentAnswer.id,
    data: {
      id: ownProps.assessmentAnswer.id
    }
  };
  if (!ownProps.onClick) {
    return {
      onClick: () => {
        return dispatch(signAssessmentAnswerAction({ payload }));
      }
    };
  }
};

export const SignButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonComponent);
