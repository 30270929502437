import React from "react";
import PropTypes from "prop-types";

import { finishTimeTrack } from "src/analytics";

/**
 * Will try to track events when all children finish rendering
 */
export class FinishRenderTimeTrackComponent extends React.PureComponent {
  static propTypes = {
    eventName: PropTypes.string.isRequired,
    error: PropTypes.bool
  };

  static defaultProps = {
    error: false
  };

  componentDidMount() {
    finishTimeTrack(
      this.props.eventName,
      this.props.error ? { error: true } : undefined
    );
  }

  componentDidUpdate() {
    finishTimeTrack(
      this.props.eventName,
      this.props.error ? { error: true } : undefined
    );
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
