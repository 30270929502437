import { createSelector } from "reselect";
import { policiesResourceSelectors } from "src/selectors/data_selectors/policies";
import get from "lodash.get";

export const ray1EnabledPolicySelector = createSelector(
  policiesResourceSelectors.dataSelector,
  policies => {
    if (!Object.keys(policies).length) {
      return null;
    }

    const ray1EnabledPolicy = Object.values(policies).find(policy => {
      return (
        policy.attributes.internalName == "ray_wound_surgical_workflow_enabled"
      );
    });

    return get(ray1EnabledPolicy, "attributes.policyValue", false);
  }
);
