import { dataActionTypes } from "src/action_types/data_action_types";

/**
 * Reducer for the Location data model in the data state tree
 * These correspond to api_v2_locations loaded from the api
 */
export function locationsReducer(state = {}, action) {
  switch (action.type) {
    case dataActionTypes.populateLocations:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
