import { createSelector } from "reselect";
import get from "lodash.get";
import {
  createPolicyForInternalNameSelector,
  createRolePermissionForPermissionTypeIdSelector
} from "src/selectors/data_selectors";

import {
  permissionTypeIds,
  permissionTypeAccess
} from "src/constants/permissions_type";

//Constants
import { policyInternalNames } from "src/constants";

export const qaNoteEnabledPolicySelector = createSelector(
  createPolicyForInternalNameSelector(policyInternalNames.qaNotesEnabled),
  policy => {
    return get(policy, "attributes.policyValue", false);
  }
);

export const qaNoteEnabledRoleSelector = createSelector(
  createRolePermissionForPermissionTypeIdSelector(
    permissionTypeIds.allow_add_edit_qa_notes
  ),
  permission => {
    return (
      get(permission, "permissionValue", "FALSE") == permissionTypeAccess.true
    );
  }
);
