import { createSelector } from "reselect";
import { frameConfigSelector } from "../frame_config_selector";

/**
 * Selector to get which fluoro frameType is currently displayed
 */
export const fluoroFrameTypeSelector = createSelector(
  frameConfigSelector,
  config => {
    return config.fluoroFrameType;
  }
);
