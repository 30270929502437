import { createSelector } from "reselect";

// Selectors
import { visibleEvaluationStatesPermissionSelector } from "src/selectors/config_selectors/visible_evaluation_states_permission";

// Payload generator
import { createGetPayload } from "src/api/revisions/payload_generators";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api query for the first page for revision comparison
 * @param {Object} props
 * @param {string} props.seriesId
 * @returns {function} selector
 */
export function createFirstPageRevisionComparisonQuerySelector({ seriesId }) {
  return createSelector(
    visibleEvaluationStatesPermissionSelector,
    assessmentStateFilters => {
      const { lockState } = assessmentStateFilters;
      return `${seriesId}/revisions${buildQueryString(
        createGetPayload({
          pageNumber: 0,
          lockState,
          strikeout_state: "open",
          deleted: "false",
          fields: "created_at,closure",
          sort: "created_at"
        })
      )}`;
    }
  );
}
