import { createSectionActionTypes } from "../../action_type_creators";

/**
 * Create a set of reducers for section
 * @param {string} section the section name eg. wounds, survey
 * @returns {Function} section redux reducer
 */
export function createSectionSelectedFilterIdReducer(section) {
  const actionTypes = createSectionActionTypes(section);

  return (state = "", action) => {
    switch (action.type) {
      case actionTypes.selectSavedFilter:
        return action.payload.id;
      case actionTypes.deselectSavedFilter:
        return "";
      default:
        return state;
    }
  };
}
