export const woundTypesV6LifeCare = {
  //Wound Types
  abrasionOnly: "Abrasion",
  abscess: "Abscess",
  arterial: "Arterial Ulcer",
  blister: "Blister",
  bruise: "Bruise",
  burn: "Burn",
  cancerLesion: "Cancer Lesion",
  diabetic: "Diabetic Ulcer",
  hematoma: "Hematoma",
  hSuppurtiva: "Hidradenitis Suppurativa",
  laceration: "Laceration",
  masd: "Moisture Associated Dermatitis",
  mole: "Mole",
  lesion: "Open Lesion",
  pressureUlcer: "Pressure Injury",
  pressureKennedy: "Pressure - Kennedy Terminal Ulcer",
  pressureDeviceRelated: "Pressure - Medical Device Related Pressure Injury",
  rash: "Rash",
  skinTear: "Skin Tear",
  surgical: "Surgical Incision",
  venous: "Venous Ulcer",
  neuropathic: "Neuropathic",
  radiation: "Radiation",
  traumatic: "Traumatic",
  undiagnosed: "Undiagnosed",
  otherWound: "Other",

  //Sub Types
  ////Skin Tear
  category1Linear: "Category I: Linear",
  category1Flap: "Category I: Flap",
  category2below25: "Category II: < 25% loss",
  category2above25: "Category II: > 25% loss",
  category3complete: "Category III: Complete loss",

  ////Burn
  first: "Superficial (1st degree)",
  superficialSecond: "Superficial Partial Thickness (2nd degree)",
  deepSecond: "Deep Partial Thickness (Deep 2nd degree)",
  third: "Third Degree",

  ////// (Bruise) Color
  red: "Red",
  bluePurple: "Blue/Purple",
  green: "Green",
  yellowBrown: "Yellow/Brown",

  ////Pressure Ulcer, Pressure Kennedy, Pressure Device Related
  one: "Stage 1",
  two: "Stage 2",
  three: "Stage 3",
  four: "Stage 4",
  dti: "Deep Tissue Injury",
  unstageable: "Unstageable",

  ////Pressure Ulcer, Pressure Device Related
  mucosalMembrane: "Mucosal Membrane",

  //////(Pressure Ulcer, Pressure Kennedy, Pressure Device Related) Unstageable
  necrosis: "Slough and/or eschar",
  dressing: "Non-removable device/dressing",

  ////MASD
  iad: "IAD",
  other: "Other",

  ////Surgical
  surgicalClosure: "Surgical Closure Method",
  surgicalOther: "Other",

  //////Surgical Closure Method
  closureApprox: "Approximated",
  closureSutures: "Surface Sutures",
  closureSteriStrips: "Steri-Strips",
  closureOther: "Other",
  closureUta: "Unable to Access (UTA)",
  closureDerma: "Dermabond",
  closureOpen: "Open to Air",
  closureNone: "None",

  //////(Surgical) Other
  incisionApprox: "Incision Approximated",
  dehiscence: "Dehiscence",
  healingRidge: "Healing Ridge",

  ////Applies to all notSet keys
  notSet: "Not Set"
};
