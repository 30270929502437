import PropTypes from "prop-types";
import React from "react";

// components
import { CheckboxList, CheckboxListItem } from "../checkbox_list";
import { CollapsibleContainerComponent } from "../collapsible_container_component";

export class SideBarAdminOrganizationFilterComponent extends React.Component {
  static propTypes = {
    facilities: PropTypes.object.isRequired,
    selectOrganizations: PropTypes.func.isRequired,
    deselectOrganizations: PropTypes.func.isRequired,
    selectFacilities: PropTypes.func.isRequired,
    deselectFacilities: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    organizations: PropTypes.array
  };

  static defaultProps = {
    disabled: false
  };

  isOrganizationChecked(organizationId) {
    return this.props.checkedOrganizationIds.includes(organizationId);
  }
  isFacilityChecked(facilityId) {
    return this.props.checkedFacilityIds.includes(facilityId);
  }

  // onselect event for the orgnization checkbox select
  onOrganizationSelected = orgId => {
    const {
      deselectOrganizations,
      selectOrganizations,
      facilities,
      selectFacilities,
      deselectFacilities
    } = this.props;

    const childFacilitiesIds = facilities
      ? Object.values(facilities)
          .filter(f => f.attributes.organizationId === orgId)
          .map(f => f.id)
      : [];

    if (this.isOrganizationChecked(orgId)) {
      deselectOrganizations([orgId]);

      // deselect children if they exist
      if (childFacilitiesIds) {
        deselectFacilities(childFacilitiesIds);
      }
    } else {
      selectOrganizations([orgId]);

      // select children if they exist
      if (childFacilitiesIds) {
        selectFacilities(childFacilitiesIds);
      }
    }
  };

  // onselect event for the facility checkbox select
  onFacilitySelected = facility => {
    const {
      facilities,
      selectFacilities,
      deselectFacilities,
      deselectOrganizations
    } = this.props;

    let facilitiesArray = Object.values(facilities)
      .filter(o => o.id == facility)
      .map(o => o.attributes.organizationId);

    if (this.isFacilityChecked(facility)) {
      deselectFacilities([facility]);
      deselectOrganizations([facilitiesArray[0]]);
    } else {
      selectFacilities([facility]);
    }
  };

  // ---------------------- Render ---------------------- //

  /**
   * Render a organization (root) checkbox and any nested facilities
   * @param {object} organization the organizations
   */
  renderCheckboxesForFacilities = organizations => {
    if (organizations.length) {
      return organizations.map(organization => {
        return (
          <CheckboxListItem
            key={organization.id}
            label={organization.attributes.name}
            value={organization.id}
            checked={this.isOrganizationChecked(organization.id)}
            onChange={this.onOrganizationSelected}
          >
            {this.renderChildCheckboxGroup(organization)}
          </CheckboxListItem>
        );
      });
    }
  };

  /**
   * Render the child checkboxes for the facilities according to organization Id
   * @param {object} organization the organizations
   */
  renderChildCheckboxGroup(organization) {
    const { facilities } = this.props;

    if (facilities) {
      let facilitiesArray = Object.values(facilities).filter(
        o => o.attributes.organizationId == organization.id
      );

      if (facilitiesArray.length) {
        return facilitiesArray.map(facility => {
          return (
            <CheckboxListItem
              checked={this.isFacilityChecked(facility.id)}
              onChange={this.onFacilitySelected}
              key={facility.id}
              label={facility.attributes.name}
              value={facility.id}
            />
          );
        });
      }
    }
    return null;
  }

  render() {
    const { organizations } = this.props;

    return (
      <CollapsibleContainerComponent label="Organizations">
        <CheckboxList disabled={this.props.disabled}>
          {this.renderCheckboxesForFacilities(organizations)}
        </CheckboxList>
      </CollapsibleContainerComponent>
    );
  }
}
