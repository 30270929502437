import { CancelToken } from "axios";

let cancelSources = {};

export function registerForCancellation(url) {
  // if there is an outstanding request then cancel it
  if (cancelSources[url]) {
    cancelSources[url].cancel("cancel");
  }

  // get a new source for cancellation
  let source = CancelToken.source();

  // register the source for cancelation
  cancelSources[url] = source;

  // return the cancel token to be used in the request
  return source.token;
}
