import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Components
import { SideBarSavedFiltersComponent } from "src/components/side_bar_saved_filters_component";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

// Selectors
import { savedFiltersResourceSelectors } from "src/selectors/data_selectors";
import { createFilteredSavedFilterDataForViewModeSelector } from "src/selectors/data_selectors/saved_filters/create_filtered_saved_filter_data_for_view_mode_selector";
import { createAllPagesSavedFiltersForSectionContextSelector } from "src/selectors/context_selectors/saved_filters";
import { createAllPagesSavedFiltersForSectionQuerySelector } from "src/selectors/query_selectors/saved_filters";

// Actions
import { loadSavedFiltersAction } from "src/actions/data_actions";

// UI Processors
import {
  createSectionSelectors,
  createSectionActions
} from "src/ui_processors";

/**
 * Create a SideBarSavedFiltersContainer for a section
 * @param {string} section
 * @returns {Class} Container
 */
export function createSideBarSavedFiltersContainer(section) {
  const sectionSelectors = createSectionSelectors(section);
  const sectionActions = createSectionActions(section);

  function mapStateToProps(state, ownProps) {
    const allFilters = savedFiltersResourceSelectors.createDataForContextSelector(
      createAllPagesSavedFiltersForSectionContextSelector(ownProps)
    )(state);

    return {
      filters: createFilteredSavedFilterDataForViewModeSelector(allFilters)(
        state
      ),
      selectedFilterId: sectionSelectors.selectedFilterIdSelector(state)
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      sendSelectFilterAction(filterId) {
        dispatch(sectionActions.selectSavedFilter(filterId));
      },
      sendDeselectFilterAction() {
        dispatch(sectionActions.deselectSavedFilter());
      }
    };
  }

  return flowRight(
    withResourceLoaderFactory({
      getResourceAction: loadSavedFiltersAction,
      resourceSelectors: savedFiltersResourceSelectors,
      createContextSelector: createAllPagesSavedFiltersForSectionContextSelector,
      createQuerySelector: createAllPagesSavedFiltersForSectionQuerySelector
    }),
    withLoadingComponentFactory({
      analyticsEventName: `RENDER_${section.toUpperCase()}_SAVED_FILTERS`
    }),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(SideBarSavedFiltersComponent);
}
