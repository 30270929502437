import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// HOCs
import { withResourceLoaderFactory } from "src/hoc/factories";

// Component
import { SideBarAdminOrganizationFilterComponent } from "src/components/side_bar_admin_organization_filter_component";

// Selectors
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";
import {
  checkedFacilityIdsSelector,
  checkedOrganizationIdsSelector,
  organizationsForSelectedPartnerIdSelector
} from "src/selectors/section_selectors/admin";
////Facilities
import {
  createAdminFacilitiesContextSelector,
  createAdminFacilitiesQuerySelector
} from "src/selectors/api_selectors/admin/facilities";

//Actions
import {
  selectOrganizationsAction,
  deselectOrganizationsAction,
  selectFacilitiesAction,
  deselectFacilitiesAction
} from "src/actions/section_actions/admin";

import { loadFacilitiesAction } from "src/actions/data_actions";

function mapStateToProps(state) {
  return {
    facilities: facilitiesResourceSelectors.dataSelector(state),
    checkedFacilityIds: checkedFacilityIdsSelector(state),
    checkedOrganizationIds: checkedOrganizationIdsSelector(state),
    organizations: organizationsForSelectedPartnerIdSelector(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    selectOrganizations: id => {
      dispatch(selectOrganizationsAction(id));
    },
    deselectOrganizations: id => {
      dispatch(deselectOrganizationsAction(id));
    },
    selectFacilities: id => {
      dispatch(selectFacilitiesAction(id));
    },
    deselectFacilities: id => {
      dispatch(deselectFacilitiesAction(id));
    }
  };
}
export const SideBarAdminOrganizationFilterContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadFacilitiesAction,
    resourceSelectors: facilitiesResourceSelectors,
    createContextSelector: createAdminFacilitiesContextSelector,
    createQuerySelector: createAdminFacilitiesQuerySelector
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SideBarAdminOrganizationFilterComponent);
