import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./style.module.less";

export class TabViewHeaderTitleComponent extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string
  };

  render() {
    const { title, titleSize } = this.props;
    return (
      <div className={`${styles["title-tab"]} ${styles[titleSize]}`}>
        {title}
      </div>
    );
  }
}
