import { createSelector } from "reselect";

import {
  assessmentsResourceSelectors,
  assessmentSchemasResourceSelectors
} from "src/selectors/data_selectors";

/**
 * Create a selector that gets the assessment schema given an assessment id
 * @param {string} assessmentId
 * @returns {Function} selector
 */
export function createAssessmentSchemaForAssessmentIdSelector(assessmentId) {
  return createSelector(
    assessmentsResourceSelectors.dataSelector,
    assessmentSchemasResourceSelectors.dataSelector,
    (assessments, assessmentSchemas) => {
      const assessment = assessments[assessmentId];
      if (assessment) {
        return Object.values(assessmentSchemas).find(schema => {
          return schema.attributes.assessmentId == assessment.id;
        });
      }

      return undefined;
    }
  );
}
