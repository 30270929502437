import { createSelector } from "reselect";
import {
  isMedlineSelector,
  partnerNameSelector
} from "src/selectors/config_selectors";

export const brandingSelector = createSelector(
  isMedlineSelector,
  partnerNameSelector,
  (isMedline, partnerName) => {
    if (partnerName) {
      return partnerName;
    }
    return isMedline ? "medline" : "default";
  }
);
