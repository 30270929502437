import { trackError } from "src/error_tracking";

// Api
import { resetPassword } from "src/api/reset_password";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";
import { create } from "src/actions/flashMessageActions";

/**
 * Action creator for setting loading state of reset password to "loading"
 * @return {Object} action
 */
function resetPasswordLoadingAction() {
  return {
    type: dataActionTypes.resetPasswordLoading
  };
}

/**
 * Action creator for setting ready state of reset password to "ready"
 * @return {Object} action
 */
function resetPasswordReadyAction() {
  return {
    type: dataActionTypes.resetPasswordReady
  };
}

/**
 * Send api call reset user password.
 * @param {Object} payload The payload
 */
export function resetPasswordAction(payload) {
  return dispatch => {
    // loading state
    dispatch(resetPasswordLoadingAction());
    return resetPassword(payload)
      .then(() => {
        // ready state
        dispatch(resetPasswordReadyAction());

        dispatch(
          create(
            "An email has been sent to you with reset password instructions",
            "success"
          )
        );
      })
      .catch(err => {
        if (err.message == "cancel") {
          return;
        }

        if (err.response && (err.response.status = 400))
          dispatch(
            create("There was a problem resetting your password", "error")
          );
        else
          dispatch(create("Sorry, there was an error reset password", "error"));
        dispatch(resetPasswordReadyAction());
        trackError(err);
      });
  };
}
