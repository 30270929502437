import { combineReducers } from "redux";

//Section Reducer Factory
import {
  createSectionFiltersReducer
  //   TO DO: Discuss possiblity of extending saved filters support from only section-based sections (eg. exports) to subsections (eg. exports -> exports name )
  //   createSectionSelectedFilterIdReducer
} from "src/ui_processors/global/reducer_creators";

// Constants
import { sections } from "src/constants";

// UI Processors
import SingleValue from "src/ui_processors/single_value";

export const exportsSectionReducers = combineReducers({
  exportName: SingleValue.createReducer("exportName", ""),
  filters: createSectionFiltersReducer(sections.exports)
  //   selectedFilterId: createSectionSelectedFilterIdReducer(sections.exports)
});
