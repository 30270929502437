import { createSelector } from "reselect";
import moment from "moment";

import { assessmentAnswersResourceSelectors } from "../assessment_answers_resource_selectors";

/**
 * Create a selector to get the most recent region from a given revision
 * @param {Object} revisionId
 * @returns {Function} selector
 */
export function createLatestAssessmentAnswerForRevisionSelector(revision) {
  return createSelector(
    assessmentAnswersResourceSelectors.dataSelector,
    assessmentAnswers => {
      if (!revision) {
        return undefined;
      }

      const sortedAssessmentAnswers = Object.values(assessmentAnswers)
        .filter(assessmentAnswer => {
          return (
            assessmentAnswer.attributes.resourceType == "Revision" &&
            assessmentAnswer.attributes.resourceId == revision.id
          );
        })
        .sort((a1, a2) => {
          return moment(a1.attributes.createdAt).isAfter(
            a2.attributes.createdAt
          )
            ? -1
            : 1;
        });

      return sortedAssessmentAnswers.length
        ? sortedAssessmentAnswers[0]
        : undefined;
    }
  );
}
