// @flow
import { camelizeKeys, camelize } from "humps";
function tagAllLocations(locations: Object[]) {
  let lastDepth = 0;
  let preparent;
  const parents = [];
  let currentAssessmentTypes;
  const allAssessmentTypes = new Set();
  locations.forEach(location => {
    if (location.depth > lastDepth) {
      parents.push(preparent);
    }
    if (location.depth < lastDepth) {
      parents.pop();
    }

    preparent = location;
    lastDepth = location.depth;
    if (
      location.riskAssessmentPolicies &&
      location.riskAssessmentPolicies.length > 0
    ) {
      currentAssessmentTypes = location.riskAssessmentPolicies.map(camelize);
    }
    location.assessmentTypes = new Set(currentAssessmentTypes || []);
    location.assessmentTypes.forEach(assessmentType => {
      allAssessmentTypes.add(assessmentType);
    });
    parents.forEach(parent => {
      (location.riskAssessmentPolicies || []).forEach(assessmentType => {
        parent.assessmentTypes.add(assessmentType);
      });
    });
  });
  return {
    taggedLocations: locations,
    allAssessmentTypes
  };
}

export default function separateLocations(locations: Object[]) {
  const { taggedLocations, allAssessmentTypes } = tagAllLocations(
    camelizeKeys(locations)
  );
  const prelocations = Array.from(allAssessmentTypes).reduce(
    (finalResult, assessmentType) => {
      finalResult[assessmentType] = [];
      return finalResult;
    },
    {}
  );
  return taggedLocations.reduce((finalResult, location) => {
    const { assessmentTypes, ...locationProps } = location;
    Array.from(assessmentTypes)
      .map(camelize)
      .forEach(assessmentType => {
        finalResult[assessmentType].push(locationProps);
      });
    return finalResult;
  }, prelocations);
}
