import React from "react";

export class PencilIconComponent extends React.Component {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.2093 68.862L8.06977 68.862L8.06977 52.7225L60.7923 0L76.9318 16.1395L24.2093 68.862Z"
          fill="white"
        />
      </svg>
    );
  }
}
