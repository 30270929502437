import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import get from "lodash.get";
import { policyInternalNames } from "src/constants";

export const dashboardActivityTimeoutSecondsSelector = createSelector(
  createPolicyForInternalNameSelector(
    policyInternalNames.dashboardActivityTimeoutSeconds
  ),
  dashboardActivityTimeoutSeconds =>
    get(dashboardActivityTimeoutSeconds, "attributes.policyValue", null)
);
