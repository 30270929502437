import get from "lodash.get";

/**
 * Map api request parameters to a context
 * @param {string} method the api request method
 * @param {Object} queryObject
 * @param {Object} queryObject.params
 * @param {Object} queryObject.pageConfig
 * @param {number} queryObject.pageConfig.limit
 * @param {Object} page
 * @param {number} page.number
 * @param {number} page.limit
 */
export function createContext(method, queryObject, page) {
  const sortedParamKeys = getSortedKeys(queryObject.params);
  const paramsString = sortedParamKeys.reduce((agg, key) => {
    const val = getSortedValue(get(queryObject.params, key));
    return agg.concat(`${key}:${val}&`);
  }, "");

  const limit = get(queryObject, "pageConfig.limit", "");

  let pageObjectString;
  if (page) {
    const sortedPageKeys = getSortedKeys(page);
    pageObjectString = sortedPageKeys.reduce((agg, key) => {
      const val = getSortedValue(get(page, key));
      return agg.concat(`${key}:${val}_`);
    }, "");
  }

  return `${method}_${queryObject.path}_${paramsString}_${limit}_${pageObjectString}`;
}

/**
 * Get sorted keys for an object - with support for nested objects
 * @param {Object} obj - object to get keys for
 * @param {string} parentKey - recursive key (key.nestedKey.nestierKey)
 * @param {array} keys - recursive keys to push to
 */
function getSortedKeys(obj, parentKey, keys = []) {
  Object.keys(obj)
    .sort()
    .forEach(key => {
      let fullKey = key;
      if (parentKey) {
        fullKey = `${parentKey}.${key}`;
      }

      const val = get(obj, key);

      if (val && typeof val == "object" && !Array.isArray(val)) {
        // object
        getSortedKeys(val, fullKey, keys);
      } else {
        keys.push(fullKey);
      }
    });

  return keys;
}

/**
 * Get a sorted string for a value
 * @param {array|string|number} value
 */
function getSortedValue(value) {
  if (Array.isArray(value)) {
    return value.sort().join();
  } else if (typeof value == "string") {
    return value
      .split(",")
      .sort()
      .join();
  } else {
    return value;
  }
}
