import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";

// Components
import { FormInputOptionComponent } from "../form_input_option_component";
import { GridRow, GridColumn } from "src/components/grid";

//Styles
import styles from "./style.module.less";

export class FormInputComponent extends React.Component {
  static propTypes = {
    componentType: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    multiselect: PropTypes.bool,
    disabled: PropTypes.bool,
    validate: PropTypes.array,
    normalize: PropTypes.func,
    viewOnly: PropTypes.bool,
    autoComplete: PropTypes.string,
    giveFocus: PropTypes.bool,
    useMultiLine: PropTypes.bool,
    // input options
    step: PropTypes.number,
    min: PropTypes.number,
    required: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    multiselect: false,
    giveFocus: false
  };

  constructor(props) {
    super(props);
    if (props.giveFocus) {
      this.inputRef = React.createRef();
    }
  }

  componentDidMount() {
    if (this.props.giveFocus && this.inputRef) {
      this.inputRef.current.focus();
    }
  }

  renderField = ({
    input,
    placeholder,
    type,
    className,
    children,
    disabled,
    autoComplete,
    meta: { touched, error, warning }
  }) => {
    const { multiselect, step, giveFocus, useMultiLine } = this.props;
    if (type == "select") {
      return (
        <div>
          <select
            {...input}
            disabled={disabled}
            className={className}
            multiple={multiselect}
            onBlur={event => event.preventDefault()}
          >
            {children}
          </select>
          {touched &&
            ((error && <span className={styles["error"]}>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      );
    } else if (useMultiLine) {
      return (
        <div>
          <textarea
            ref={giveFocus ? this.inputRef : undefined}
            {...input}
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            step={step}
            autoComplete={autoComplete}
          />
          {touched &&
            ((error && <span className={styles["error"]}>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      );
    } else
      return (
        <div>
          <input
            ref={giveFocus ? this.inputRef : undefined}
            {...input}
            className={`${className} ${styles[this.props.className]}`}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            step={step}
            autoComplete={autoComplete}
          />
          {touched &&
            ((error && <span className={styles["error"]}>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      );
  };

  renderEmptyDefault = () => {
    return (
      <FormInputOptionComponent
        value=""
        text={this.props.defaultOption || ""}
        key="empty-option"
      />
    );
  };

  renderViewOnlyField = () => {
    const { labelText, name, alignmentOverride } = this.props;
    const gridColumnLabelSize = alignmentOverride ? "3" : "2";
    const gridColumnValueSize = alignmentOverride ? "9" : "10";

    return (
      <GridRow>
        {labelText && (
          <GridColumn sm={gridColumnLabelSize}>
            <label className={styles["label-text"]}>{labelText}</label>
          </GridColumn>
        )}
        <GridColumn sm={gridColumnValueSize}>
          <Field
            className="form-control"
            name={name}
            component={this.renderViewValue}
          ></Field>
        </GridColumn>
      </GridRow>
    );
  };

  renderViewValue = ({ input }) => {
    return <p className={styles["view-only-text"]}>{input.value}</p>;
  };

  renderLabeledInput = () => {
    const { alignmentOverride } = this.props;
    const { alignmentPatientSearch } = this.props;
    const labelTextStyle = alignmentOverride
      ? "label-text-left-override"
      : "label-text";
    let gridColumnLabelSize = alignmentOverride ? "3" : "2";
    let gridColumnValueSize = alignmentOverride ? "9" : "10";
    if (alignmentPatientSearch) {
      (gridColumnLabelSize = "4"), (gridColumnValueSize = "8");
    }

    return (
      <GridRow className={styles["margin-bottom5px"]}>
        <GridColumn sm={gridColumnLabelSize}>
          <label className={styles[labelTextStyle]}>
            {this.props.labelText}{" "}
            {this.props.required && (
              <span className={styles["required-field"]}>*</span>
            )}
          </label>
        </GridColumn>
        <GridColumn sm={gridColumnValueSize}>
          {this.props.formattingLabel && (
            <label className={styles["formatting-label"]}>
              {this.props.formattingText}
            </label>
          )}
          <Field
            className={`form-control ${styles[this.props.className]}`}
            name={this.props.name}
            component={this.renderField}
            type={this.props.componentType}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            validate={this.props.validate ? this.props.validate : []}
            normalize={this.props.normalize}
            autoComplete={this.props.autoComplete}
          >
            {this.props.componentType === "select" &&
              this.props.emptyDefault === true &&
              this.renderEmptyDefault()}
            {this.props.children}
          </Field>
        </GridColumn>
      </GridRow>
    );
  };

  renderToggleSlider = () => {
    return (
      <Field
        className="form-control"
        name={this.props.name}
        component={this.renderToggleSliderField}
      />
    );
  };

  renderToggleSliderField = ({ input }) => {
    const toggleValue = !!input.value ? "true" : "false";
    return (
      <label className={styles["switch"]} toggle-value={toggleValue}>
        <input
          {...input}
          type="checkbox"
          value={input.value}
          checked={input.value}
        />
        <div className={styles["switch-btn"]} />
      </label>
    );
  };

  handleChange = event => {
    let value = event.target.value;
    if (value == "true" || value == "false") {
      value = event.target.value == "true";
    }
    this.props.handleBooleanEvent(event.target.name, value);
  };

  render() {
    if (this.props.viewOnly) {
      return this.renderViewOnlyField();
    } else if (this.props.labelText) {
      return this.renderLabeledInput();
    } else if (this.props.componentType === "toggle") {
      return this.renderToggleSlider();
    } else {
      return (
        <Field
          className="form-control"
          name={this.props.name}
          component={this.renderField}
          type={this.props.componentType}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          validate={this.props.validate}
          normalize={this.props.normalize}
          autoComplete={this.props.autoComplete}
        >
          {this.props.componentType === "select" &&
            this.props.emptyDefault === true &&
            this.renderEmptyDefault()}
          {this.props.children}
        </Field>
      );
    }
  }
}
