//Lodash
import { get, reduce } from "lodash";

import { formatAsObjectWithBooleanValues } from "src/utils/format_as_object_with_boolean_value";

export const getInitialValuesForAssessmentAnswer = assessmentAnswer => {
  const answers = get(assessmentAnswer, "attributes.answersJson", {});
  const initialValue = reduce(
    answers,
    (result, value, key) => {
      result[key] = formatAsObjectWithBooleanValues(value);
      return result;
    },
    {}
  );
  return initialValue;
};
