/**
 * Format cell using user object
 * @param {Object} cell the user object
 */
export function userFormatter(cell) {
  const { firstName, lastName, designation } = cell || {};
  let userString = `${firstName || ""} ${lastName || ""}`;
  if (designation) userString = `${userString}, ${designation}`;
  return userString;
}
