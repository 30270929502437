import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { replace } from "react-router-redux";

// Components
import { AppComponent } from "src/components/app_component";

// Selectors
import {
  sideBarEnabledSelector,
  partnerNameSelector
} from "src/selectors/config_selectors";
import {
  routeQueryParamsSelector,
  routePathnameSelector
} from "src/selectors/router_selectors";

import { downloadManagerOpenSelector } from "src/selectors/section_selectors/global";
import { dashboardActivityTimeoutSecondsSelector } from "../../selectors/config_selectors/dashboard_activity_timeout_seconds_selector";

// Actions
import { clearAccessToken } from "src/actions/session_actions";
import { clearApiHost } from "src/actions/session_actions";
import { pingApiAction } from "src/actions/session_actions";

function mapStateToProps(state) {
  return {
    showDownloadManager: downloadManagerOpenSelector(state),
    sideBarEnabled: sideBarEnabledSelector(state),
    query: routeQueryParamsSelector(state),
    pathname: routePathnameSelector(state),
    partnerName: partnerNameSelector(state),
    dashboardActivityTimeoutSeconds: dashboardActivityTimeoutSecondsSelector(
      state
    ),
    launchIntent: state.config.launchContext.intent
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendClearAccessTokenAction() {
      dispatch(clearAccessToken());
    },
    sendClearApiHostAction() {
      dispatch(clearApiHost());
    },
    sendGoToLoginAction() {
      dispatch(replace("/sign_in"));
    },
    sendPingApiAction(lastActivityTimestamp) {
      dispatch(pingApiAction(lastActivityTimestamp));
    }
  };
}

// TODO: add hoc's for loading startup items here
export const AppContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppComponent)
);
