import { createSelector } from "reselect";
import { assessmentAnswerGroupsResourceSelectors } from "../assessment_answer_groups_resource_selectors";

/**
 * Create a selector to find an Assessment Answer Group by id
 * @param {string} aagId
 * @returns {Function} selector
 */
export function createAssessmentAnswerGroupForIdSelector(aagId) {
  return createSelector(
    assessmentAnswerGroupsResourceSelectors.dataSelector,
    aags => {
      return aags[aagId];
    }
  );
}
