import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { sections } from "src/constants";

// Component
import { CheckboxFilterComponent } from "src/components/filters";

// Actions
import { createSectionActions } from "src/ui_processors/global/action_creators";

//HOC
import { withSchemaFilterConfigurationWrapper } from "src/hoc/with_schema_filter_configuration_wrapper";

// State
function mapStateToProps() {
  return {
    selectAllEnabled: true
  };
}

// Dispatch Actions
function mapDispatchToProps(dispatch) {
  return {
    selectCheckbox: filterObject => {
      dispatch(
        createSectionActions(sections.evaluationListWound).selectCheckbox(
          filterObject
        )
      );
    },
    deselectCheckbox: filterObject => {
      dispatch(
        createSectionActions(sections.evaluationListWound).deselectCheckbox(
          filterObject
        )
      );
    }
  };
}

export const SideBarWoundTypesFilterContainerEvaluationListWound = flowRight(
  withSchemaFilterConfigurationWrapper,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckboxFilterComponent);
