import PropTypes from "prop-types";
import React from "react";
import { track } from "src/analytics";

// components
import { CheckboxList, CheckboxListItem } from "../checkbox_list";
import { CollapsibleContainerComponent } from "../collapsible_container_component";

export class SideBarLocationFilterComponent extends React.Component {
  static propTypes = {
    locations: PropTypes.object.isRequired,
    checkedLocationIds: PropTypes.array.isRequired,
    selectLocations: PropTypes.func.isRequired,
    deselectLocations: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    disabled: false
  };

  /**
   * Handler for checkboxes - selects and deselects locations
   * @param {string} locId value from checkbox
   */
  onLocationSelected = locId => {
    const { deselectLocations, selectLocations, locations } = this.props;
    track("SELECT_LOCATION");

    if (this.isLocationChecked(locId)) {
      deselectLocations([locId]);

      // deselect children if they exist
      if (locations.children[locId]) {
        deselectLocations(locations.children[locId].map(loc => loc.id));
      }

      // deselect parents if they exist
      const location = locations.all.find(loc => {
        return loc.id == locId;
      });
      if (location.parent) {
        deselectLocations([location.parent.id]);
      }
    } else {
      selectLocations([locId]);

      // select children if they exist
      if (locations.children[locId]) {
        selectLocations(locations.children[locId].map(loc => loc.id));
      }
    }
  };

  /**
   * Handler for selecting a Unit group checkbox.
   * @param {String} parentLocationId the id of the parent locations (fac)
   */
  onUnitGroupSelected = parentLocationId => {
    const { locations, selectLocations, deselectLocations } = this.props;

    // Get child locations that are units
    const childIds = locations.children[parentLocationId]
      .filter(loc => {
        return loc.tags[0] == "Unit";
      })
      .map(loc => loc.id);

    if (this.isLocationGroupChecked(parentLocationId, "Unit")) {
      deselectLocations(childIds);
    } else {
      selectLocations(childIds);
    }
  };

  /**
   * Handler for selecting a Floor group checkbox.
   * @param {String} parentLocationId the id of the parent locations (fac)
   */
  onFloorGroupSelected = parentLocationId => {
    const { locations, selectLocations, deselectLocations } = this.props;

    // Get child locations that are units
    const childIds = locations.children[parentLocationId]
      .filter(loc => {
        return loc.tags[0] == "Floor";
      })
      .map(loc => loc.id);

    if (this.isLocationGroupChecked(parentLocationId, "Floor")) {
      deselectLocations(childIds);
    } else {
      selectLocations(childIds);
    }
  };

  /**
   * Checks if a location is selected given a location Id
   * @param {string} locationId location id
   * @return {boolean} true if location is selected
   */
  isLocationChecked(locationId) {
    return this.props.checkedLocationIds.includes(locationId);
  }

  /**
   * Check if a Unit or Floor group is checked for a parent facility.
   * It will be checked if any unit/floor under that facility is selected or if
   * the parent is checked
   * @param {String} parentLocationId the facility location id
   * @param {String} childFilterTag ex. Facility, FacilityUnit
   * @return {boolean} true if checked
   */
  isLocationGroupChecked(parentLocationId, childFilterTag) {
    const { locations, checkedLocationIds } = this.props;

    const children = locations.children[parentLocationId].filter(loc => {
      return loc.tags[0] == childFilterTag;
    });

    return children.some(child => {
      return checkedLocationIds.includes(child.id);
    });
  }

  // ---------------------- Render ---------------------- //

  /**
   * Render a facility (root) checkbox and any nested units and floors
   * @param {object} location the factility
   */
  renderCheckboxesForRootLocations = location => {
    return (
      <CheckboxListItem
        key={location.id}
        label={location.name}
        value={location.id}
        checked={this.isLocationChecked(location.id)}
        onChange={this.onLocationSelected}
      >
        {this.renderChildCheckboxGroup(location, "Unit", "Units")}
        {this.renderChildCheckboxGroup(location, "Floor", "Floors")}
      </CheckboxListItem>
    );
  };

  /**
   *
   */
  renderChildCheckboxGroup(parentLocation, childFilterTag, groupLabel) {
    const { locations } = this.props;

    if (locations.children[parentLocation.id]) {
      // Get child locations according to the group
      const childLocations = locations.children[parentLocation.id].filter(
        loc => {
          return loc.tags[0] == childFilterTag;
        }
      );

      if (childLocations.length) {
        // Get array of child items to nest in group label
        const childBoxes = childLocations.map(loc => {
          return (
            <CheckboxListItem
              key={loc.id}
              label={loc.name}
              value={loc.id}
              checked={this.isLocationChecked(loc.id)}
              onChange={this.onLocationSelected}
            />
          );
        });

        //
        const onChange =
          childFilterTag == "Unit"
            ? this.onUnitGroupSelected
            : this.onFloorGroupSelected;

        return (
          <CheckboxListItem
            key={groupLabel}
            label={groupLabel}
            value={parentLocation.id}
            checked={this.isLocationGroupChecked(
              parentLocation.id,
              childFilterTag
            )}
            onChange={onChange}
          >
            {childBoxes}
          </CheckboxListItem>
        );
      }
    }

    return null;
  }

  render() {
    const { locations } = this.props;

    return (
      <CollapsibleContainerComponent label="Locations">
        <CheckboxList disabled={this.props.disabled}>
          {locations.roots.map(this.renderCheckboxesForRootLocations)}
        </CheckboxList>
      </CollapsibleContainerComponent>
    );
  }
}
