import { createSelector } from "reselect";
import { policiesResourceSelectors } from "src/selectors/data_selectors/policies";
import { policies as policyConstants } from "src/constants";

const ASSESSMENT_STATE_DICT = {
  locked: "lockState",
  unlocked: "lockState",
  signed: "signState",
  unsigned: "signState",
  open: "strikeoutState",
  stricken: "strikeoutState"
};

export const visibleEvaluationStatesPermissionSelector = createSelector(
  policiesResourceSelectors.dataSelector,
  policies => {
    if (!Object.keys(policies).length) {
      return {};
    }

    const assessmentStateViewablePolicy = Object.values(policies).find(
      policy => {
        return (
          policy.attributes.internalName ==
          policyConstants.assessmentStateViewable
        );
      }
    );

    if (!assessmentStateViewablePolicy) return {};

    const policyVal = assessmentStateViewablePolicy.attributes.policyValue;
    return policyVal.reduce((states, policy) => {
      const stateName = ASSESSMENT_STATE_DICT[policy];
      if (states && states[stateName]) {
        states[stateName] = states[stateName] + "," + policy;
      } else {
        states[stateName] = policy;
      }
      return states;
    }, {});
  }
);
