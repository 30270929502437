import { connect } from "react-redux";

// Components
import { ButtonComponent } from "src/components/button_component";

// Selectors
import { createUnlockButtonVisibleSelector } from "src/selectors/ui_config_selectors/patients/create_unlock_button_visible_selector";
import { createUnlockIsDisabledSelector } from "src/selectors/ui_config_selectors/patients/create_unlock_is_disabled_selector";
import { createUnlockTooltipSelector } from "src/selectors/ui_config_selectors/patients/create_unlock_tooltip_selector";

import { unlockAssessmentAnswerAction } from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  return {
    isVisible: createUnlockButtonVisibleSelector(ownProps)(state),
    disabled: createUnlockIsDisabledSelector(ownProps)(state),
    tooltip: createUnlockTooltipSelector()(state)
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: () => {
      return dispatch(
        unlockAssessmentAnswerAction({ resource: ownProps.assessmentAnswer })
      );
    }
  };
};

export const UnlockButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonComponent);
