import normalize from "json-api-normalizer";
import { createLoadDataAction } from "../create_load_data_action";
import { resourceNames } from "src/constants";
import { trackError } from "src/error_tracking";
import get from "lodash.get";
import {
  sendNewAuditEvent,
  AUDIT_EVENT_TYPES,
  AUDIT_RESOURCE_TYPES
} from "src/helpers/audit";

import { camelizeKeys } from "humps";

// Tracking
import { startTimeTrack, finishTimeTrack, track } from "src/analytics";

// Translations
import i18n from "src/translations";

// Api
import {
  patientsApi,
  fetchPatients,
  fetchPatient,
  fetchPatientsByQueryString,
  createPatient,
  updatePatient
} from "src/api/patients";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Actions
import { studiesResourceActions } from "../";
import { create } from "src/actions/flashMessageActions";
import { createUserAction, updateUserAction } from "../users";
import { usersResourceActions } from "src/actions/data_actions/users";

export const patientsResourceActions = createResourceActions("patients");

export const loadPatientsAction = createLoadDataAction({
  resourceName: resourceNames.patients,
  includedResourceNames: [resourceNames.studies, resourceNames.payerPatients],
  api: patientsApi
});

/**
 * Action creator for loading patients
 * @return {Function} thunk action
 */
export function loadPatientsActionLegacy(contexts, filters) {
  return dispatch => {
    startTimeTrack("API_GET_PATIENTS");
    dispatch(patientsResourceActions.loadingForContextAction(contexts));
    return fetchPatients(filters)
      .then(response => {
        finishTimeTrack("API_GET_PATIENTS");
        const data = normalize(response.data);
        const patients = data.patients;
        const studies = data.studies;

        dispatch(
          studiesResourceActions.replaceAction({
            contexts,
            data: studies,
            ids: Object.keys(studies)
          })
        );

        dispatch(
          patientsResourceActions.replaceAction({
            contexts,
            data: patients,
            ids: Object.keys(patients),
            links: response.data.links,
            meta: response.data.meta
          })
        );
      })
      .catch(err => {
        // If the request was cancelled it means a new request started
        // don't show the loading view.
        if (err.message == "cancel") {
          return;
        }
        dispatch(patientsResourceActions.loadErrorForContextAction(contexts));
        trackError(err);
        finishTimeTrack("API_GET_PATIENTS", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
      });
  };
}

/**
 * Action creator that loads a single patient
 * @param {string} patientId
 * @returns {Function} thunk action
 */
export function loadPatientAction(patientId) {
  return dispatch => {
    startTimeTrack("API_GET_PATIENT");
    dispatch(patientsResourceActions.loadingForContextAction(patientId));
    return fetchPatient(patientId)
      .then(response => {
        finishTimeTrack("API_GET_PATIENT");
        const data = normalize(response.data);
        const patients = data.patients;
        const studies = data.studies;

        if (studies) {
          dispatch(
            studiesResourceActions.populateAction({
              data: studies
            })
          );
        }

        dispatch(
          patientsResourceActions.replaceAction({
            contexts: patientId,
            data: patients,
            ids: [patientId]
          })
        );
      })
      .catch(err => {
        // If the request was cancelled it means a new request started
        // don't show the loading view.
        if (err.message == "cancel") {
          return;
        }
        dispatch(patientsResourceActions.loadErrorForContextAction(patientId));
        trackError(err);
        finishTimeTrack("API_GET_PATIENT", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
      });
  };
}

/**
 * Action creator for create patients
 */
export function createPatientsAction(
  payload,
  isPatientUser,
  currentUserId,
  userPasswordMinLength
) {
  return dispatch => {
    dispatch(patientsResourceActions.loadingPostAction());
    return createPatient(payload)
      .then(response => {
        const patients = normalize(response.data).patients;
        dispatch(
          patientsResourceActions.populatePostAction({
            data: patients
          })
        );
        let patient = Object.values(patients)[0];
        let patientId = get(patient, "id", "");

        if (isPatientUser) {
          dispatch(
            createUserAction(
              payload,
              patientId,
              isPatientUser,
              currentUserId,
              userPasswordMinLength
            )
          );
        } else {
          dispatch(
            create(
              i18n.t(
                "interaction.successMessages.patientCreateOrUpdate.patientCreated"
              ),
              "success"
            )
          );
        }

        sendNewAuditEvent(
          AUDIT_EVENT_TYPES.CREATE,
          currentUserId,
          patientId,
          AUDIT_RESOURCE_TYPES.PATIENT
        );
      })
      .catch(err => {
        if (isPatientUser && err.response.status == 422) {
          const mixpanel_properties = {
            patientID: payload.data.id,
            userID: currentUserId
          };
          track("PATIENT_EMAIL_TAKEN", mixpanel_properties);
          dispatch(
            create(
              i18n.t("interaction.errorMessages.patientUser.patientEmailTaken"),
              "error"
            )
          );
        } else {
          dispatch(
            create(
              i18n.t(
                "interaction.errorMessages.patientCreateOrUpdate.patientCreateError"
              ),
              "error"
            )
          );
        }
        trackError(err);
        dispatch(patientsResourceActions.loadErrorPostAction());
      });
  };
}

/**
 * Action creator for update patients
 * @param {Object} payload The payload
 * @return {Object} thunk action
 */
export function updatePatientAction(
  payload,
  isPatientUser,
  patientEmailChange,
  currentUserId,
  userPasswordMinLength
) {
  return dispatch => {
    dispatch(patientsResourceActions.loadingPatchAction([payload.id]));
    return updatePatient(payload)
      .then(response => {
        const patients = normalize(response.data).patients;
        dispatch(
          patientsResourceActions.populatePatchAction({
            data: patients,
            ids: [patients.id]
          })
        );

        let patient = Object.values(patients)[0];
        let patientId = get(patient, "id", "");

        if (isPatientUser) {
          let patientUserId = get(patient, "attributes.patientUserId", "");
          if (patientUserId) {
            dispatch(
              updateUserAction(
                payload,
                patientId,
                isPatientUser,
                patientUserId,
                patientEmailChange,
                currentUserId,
                userPasswordMinLength
              )
            );
          } else {
            dispatch(
              createUserAction(
                payload,
                patientId,
                isPatientUser,
                currentUserId,
                userPasswordMinLength
              )
            );
          }
        } else {
          dispatch(
            create(
              i18n.t(
                "interaction.successMessages.patientCreateOrUpdate.patientUpdated"
              ),
              "success"
            )
          );
        }

        sendNewAuditEvent(
          AUDIT_EVENT_TYPES.UPDATE,
          currentUserId,
          patientId,
          AUDIT_RESOURCE_TYPES.PATIENT
        );
      })
      .catch(err => {
        if (isPatientUser && err.response.status == 422) {
          const mixpanel_properties = {
            patientID: payload.data.id,
            userID: currentUserId
          };
          track("PATIENT_EMAIL_TAKEN", mixpanel_properties);
          dispatch(
            create(
              i18n.t("interaction.errorMessages.patientUser.patientEmailTaken"),
              "error"
            )
          );
        } else {
          dispatch(
            create(
              i18n.t(
                "interaction.errorMessages.patientCreateOrUpdate.patientUpdateError"
              ),
              "error"
            )
          );
        }
        dispatch(patientsResourceActions.loadErrorPatchAction([payload.id]));
        trackError(err);
      });
  };
}

/**
 * Action creator for loading patients by query string
 * @param {string} queryString
 * @param {Object} payload for filtering the records
 * @return {Object} redux action
 */
export function loadPatientsByQueryStringAction(queryString, payload) {
  return dispatch => {
    startTimeTrack("API_GET_PATIENTS_BY_QUERY_STRING");
    dispatch(patientsResourceActions.loadingForContextAction([queryString]));
    dispatch(usersResourceActions.loadingPostAction());
    return fetchPatientsByQueryString(payload)
      .then(response => {
        finishTimeTrack("API_GET_PATIENTS_BY_QUERY_STRING");
        const patients = normalize(response.data).patients;
        const users = normalize(response.data).users;

        dispatch(
          patientsResourceActions.replaceAction({
            contexts: [queryString],
            data: patients,
            ids: patients ? Object.keys(patients) : [],
            links: response.data.links,
            meta: camelizeKeys(response.data.meta)
          })
        );
        dispatch(
          usersResourceActions.populatePostAction({
            data: users
          })
        );
        return response.data.data;
      })
      .catch(err => {
        // If the request was cancelled it means a new request started
        // don't show the loading view.
        if (err.message == "cancel") {
          return;
        }
        trackError(err);
        dispatch(
          patientsResourceActions.loadErrorForContextAction([queryString])
        );
        finishTimeTrack("API_GET_PATIENTS_BY_QUERY_STRING", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
      });
  };
}
