import { createSelector } from "reselect";
import { patientsSectionSelector } from "../patients_section_selector";

/**
 * Selector for the patient search string
 */
export const patientSearchStringSelector = createSelector(
  patientsSectionSelector,
  patientsSection => patientsSection.patientSearchString
);
