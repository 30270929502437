import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import classnames from "classnames";

// Translations
import i18n from "src/translations";

// css
import styles from "./style.module.less";

// Constants
import { sections } from "src/constants";

// Components
import { Glyphicon } from "../ui_kit";
import { CollapsibleContainerComponent } from "../collapsible_container_component";

// Containers
import { SideBarPatientsFilterContainer } from "src/containers/side_bar_patients_filter_container";
import {
  SideBarAlertsSavedFiltersContainer,
  SideBarEvaluationListWoundSavedFiltersContainer,
  SideBarEvaluationListBradenSavedFiltersContainer,
  SideBarEvaluationListPURSSavedFiltersContainer,
  SideBarEvaluationListTotalSkinSavedFiltersContainer,
  SideBarOutcomesSavedFiltersContainer,
  SideBarWoundsSavedFiltersContainer,
  SideBarRiskSavedFiltersContainer,
  SideBarSurveySavedFiltersContainer,
  SideBarPopulationSavedFiltersContainer,
  SideBarMonitoringSavedFiltersContainer,
  SideBarWoundRiskSavedFiltersContainer
} from "src/containers/side_bar_saved_filters_container";

import {
  SideBarComplianceHeaderContainer,
  SideBarOutcomesHeaderContainer,
  SideBarWoundsHeaderContainer,
  SideBarRiskHeaderContainer,
  SideBarSurveyHeaderContainer,
  SideBarPopulationHeaderContainer,
  SideBarMonitoringHeaderContainer,
  SideBarAlertsHeaderContainer,
  SideBarEvaluationListWoundHeaderContainer,
  SideBarEvaluationListBradenHeaderContainer,
  SideBarEvaluationListPURSHeaderContainer,
  SideBarEvaluationListTotalSkinHeaderContainer,
  SideBarWoundRiskHeaderContainer
} from "src/containers/side_bar_header_container";

import {
  SideBarEvaluationTypeFilterContainerAlerts,
  SideBarLocationsFilterContainerAlerts,
  SideBarLocationsFilterContainerMyDashboard,
  SideBarLocationsFilterContainerOutcomes,
  SideBarLocationsFilterContainerEvaluationListWound,
  SideBarLocationsFilterContainerEvaluationListBraden,
  SideBarLocationsFilterContainerEvaluationListPURS,
  SideBarLocationsFilterContainerEvaluationListTotalSkin,
  SideBarWoundTypesFilterContainerWounds,
  SideBarWoundTypesFilterContainerEvaluationListWound,
  SideBarWoundTypesFilterContainerWoundRisk,
  SideBarHprScoreFilterContainerWounds,
  SideBarBwatScoreFilterContainerWounds,
  SideBarAcquiredFilterContainerWounds,
  SideBarLocationsFilterContainerWounds,
  SideBarPayersFilterContainerWounds,
  SideBarLocationsFilterContainerRisk,
  SideBarWoundTypesFilterContainerSurvey,
  SideBarSurveyOptionsFilterContainer,
  SideBarLocationsFilterContainerSurvey,
  SideBarWoundTypesFilterContainerPopulation,
  SideBarLocationsFilterContainerPopulation,
  SideBarLocationsFilterContainerMonitoring,
  SideBarWoundProgressFilterContainerMonitoring,
  SideBarLocationsFilterContainerWoundRisk
} from "src/containers/side_bar_filter_containers";

import { SideBarLocationFilterContainerPatients } from "src/containers/side_bar_location_filter_container";
import { SideBarOptionsFilterContainerPatients } from "src/containers/side_bar_options_filter_contatiner";

import { SideBarAdminNavigationContainer } from "src/containers/side_bar_admin_navigation_container";

import ComplianceFilters from "src/pages/compliance/components/filters";

export class SideBarFiltersComponent extends React.Component {
  static propTypes = {
    partnerName: PropTypes.string.isRequired,
    adminTabEnabled: PropTypes.bool.isRequired,
    outcomesTabEnabled: PropTypes.bool.isRequired,
    monitoringTabEnabled: PropTypes.bool.isRequired,
    hprScoreEnabled: PropTypes.bool.isRequired,
    patientAppEnabled: PropTypes.bool.isRequired,
    payerDashboardEnabled: PropTypes.bool.isRequired,
    visitSequenceReportingEnabled: PropTypes.bool.isRequired,
    healingIndexDashboardEnabled: PropTypes.bool.isRequired
  };

  state = {
    showSecondaryFilter: false
  };

  // ----------------------- Secondary filter button ----------------------- //
  handleSecondaryFilterClick = () => {
    this.setState({
      showSecondaryFilter: !this.state.showSecondaryFilter
    });
  };

  renderSecondaryFilterButton = () => {
    return (
      <button
        className={`btn btn-default ${styles["secondary-filter-button"]}`}
        onClick={this.handleSecondaryFilterClick}
      >
        <Glyphicon
          glyph={
            this.state.showSecondaryFilter ? "circle-arrow-left" : "filter"
          }
        />
      </button>
    );
  };

  // ----------------------- Filter Header ----------------------- //
  renderDefaultFilterHeader() {
    return (
      <div>
        <h3>Filter</h3>
      </div>
    );
  }

  renderPatientFilterHeader = () => {
    return (
      <div>
        <h3>{this.state.showSecondaryFilter ? "Filter" : "Select Patient"}</h3>

        {this.renderSecondaryFilterButton()}
      </div>
    );
  };

  // ----------------------- Admin Filter Header ----------------------- //
  renderAdminDefaultFilterHeader = () => {
    return (
      <div>
        <h3>Admin</h3>
      </div>
    );
  };

  // ----------------------- Filters ----------------------- //
  getMyDashboardFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <SideBarLocationsFilterContainerMyDashboard />
        </li>
      </ul>
    );
  }

  getAlertFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarAlertsSavedFiltersContainer section={sections.alerts} />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarEvaluationTypeFilterContainerAlerts />
        </li>
        <li>
          <SideBarLocationsFilterContainerAlerts />
        </li>
      </ul>
    );
  }

  disabledComplianceFilters = () => {
    const {
      patientAppEnabled,
      payerDashboardEnabled,
      visitSequenceReportingEnabled,
      history
    } = this.props;

    let isCompliance = history.location.pathname === "/compliance/clinicians";
    let disabledFilterSections = [];
    if (!patientAppEnabled || isCompliance) {
      disabledFilterSections.push("patientEvaluations");
    }
    if (!payerDashboardEnabled || isCompliance) {
      disabledFilterSections.push("payers");
    }
    if (!visitSequenceReportingEnabled || isCompliance) {
      disabledFilterSections.push("visitFrequencies");
    }

    return disabledFilterSections;
  };

  getComplianceFilterComponent = () => {
    return (
      <>
        <ComplianceFilters
          disabledSections={this.disabledComplianceFilters()}
        />
      </>
    );
  };

  getEvaluationListWoundFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarEvaluationListWoundSavedFiltersContainer
              section={sections.evaluationListWound}
            />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarLocationsFilterContainerEvaluationListWound />
        </li>
        <li>
          <SideBarWoundTypesFilterContainerEvaluationListWound
            section={sections.evaluationListWound}
          />
        </li>
      </ul>
    );
  }

  getEvaluationListBradenFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarEvaluationListBradenSavedFiltersContainer
              section={sections.evaluationListBraden}
            />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarLocationsFilterContainerEvaluationListBraden />
        </li>
      </ul>
    );
  }

  getEvaluationListPURSFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarEvaluationListPURSSavedFiltersContainer
              section={sections.evaluationListPURS}
            />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarLocationsFilterContainerEvaluationListPURS />
        </li>
      </ul>
    );
  }

  getEvaluationListTotalSkinFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarEvaluationListTotalSkinSavedFiltersContainer
              section={sections.evaluationListTotalSkin}
            />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarLocationsFilterContainerEvaluationListTotalSkin />
        </li>
      </ul>
    );
  }

  getWoundRiskFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarWoundRiskSavedFiltersContainer
              section={sections.woundRisk}
            />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarLocationsFilterContainerWoundRisk />
        </li>
        <li>
          <SideBarWoundTypesFilterContainerWoundRisk
            section={sections.woundRisk}
          />
        </li>
      </ul>
    );
  }

  getMonitoringFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarMonitoringSavedFiltersContainer
              section={sections.monitoring}
            />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarLocationsFilterContainerMonitoring />
        </li>
        <li>
          <SideBarWoundProgressFilterContainerMonitoring />
        </li>
      </ul>
    );
  }

  getOutcomesFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarOutcomesSavedFiltersContainer section={sections.outcomes} />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarLocationsFilterContainerOutcomes />
        </li>
      </ul>
    );
  }

  getPatientFilterComponent = () => {
    let filter;
    if (this.state.showSecondaryFilter) {
      filter = (
        <ul className={styles["filter-component-list"]}>
          <li>
            <SideBarLocationFilterContainerPatients />
          </li>
          <li>
            <SideBarOptionsFilterContainerPatients />
          </li>
        </ul>
      );
    } else {
      filter = (
        <div>
          <SideBarPatientsFilterContainer />
        </div>
      );
    }

    return filter;
  };

  getWoundsFilterComponent = () => {
    const {
      hprScoreEnabled,
      bwatScoreEnabled,
      payerDashboardEnabled
    } = this.props;
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarWoundsSavedFiltersContainer section={sections.wounds} />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarWoundTypesFilterContainerWounds section={sections.wounds} />
        </li>
        {hprScoreEnabled && (
          <li>
            <CollapsibleContainerComponent label={i18n.t("headers.hprScore")}>
              <SideBarHprScoreFilterContainerWounds />
            </CollapsibleContainerComponent>
          </li>
        )}
        {bwatScoreEnabled && (
          <li>
            <CollapsibleContainerComponent label={i18n.t("headers.bwatScore")}>
              <SideBarBwatScoreFilterContainerWounds />
            </CollapsibleContainerComponent>
          </li>
        )}
        <li>
          <SideBarAcquiredFilterContainerWounds />
        </li>
        <li>
          <SideBarLocationsFilterContainerWounds />
        </li>
        {payerDashboardEnabled && (
          <li>
            <SideBarPayersFilterContainerWounds />
          </li>
        )}
      </ul>
    );
  };

  getRiskFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarRiskSavedFiltersContainer section={sections.risk} />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarLocationsFilterContainerRisk />
        </li>
      </ul>
    );
  }

  getSurveyFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarSurveySavedFiltersContainer section={sections.survey} />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarWoundTypesFilterContainerSurvey section={sections.survey} />
        </li>
        <li>
          <SideBarSurveyOptionsFilterContainer />
        </li>
        <li>
          <SideBarLocationsFilterContainerSurvey />
        </li>
      </ul>
    );
  }

  getSurveyDetailsFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarWoundTypesFilterContainerSurvey
              disabled={true}
              section={sections.survey}
            />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarSurveyOptionsFilterContainer disabled={true} />
        </li>
        <li>
          <SideBarLocationsFilterContainerSurvey disabled={true} />
        </li>
      </ul>
    );
  }

  getPopulationFilterComponent() {
    return (
      <ul className={styles["filter-component-list"]}>
        <li>
          <CollapsibleContainerComponent
            label={i18n.t("headers.mySavedFilters")}
          >
            <SideBarPopulationSavedFiltersContainer
              section={sections.population}
            />
          </CollapsibleContainerComponent>
        </li>
        <li>
          <SideBarWoundTypesFilterContainerPopulation
            section={sections.population}
          />
        </li>
        {/* following component is commented for the time but will be required later */}
        {/* <li>
          <PopulationSideBarAcquiredFilterContainer />
        </li> */}
        <li>
          <SideBarLocationsFilterContainerPopulation />
        </li>
      </ul>
    );
  }

  render() {
    let classnamesObject = {};
    classnamesObject[styles["sidebar-filters-container-pcc"]] =
      this.props.partnerName == "pcc" || this.props.partnerName == "pcc_wound";

    const classname = classnames(
      styles["sidebar-filters-container"],
      classnamesObject,
      "primary-background"
    );

    const {
      adminTabEnabled,
      outcomesTabEnabled,
      monitoringTabEnabled,
      myDashboardTabEnabled,
      alertsTabEnabled,
      evaluationListEnabled,
      healingIndexDashboardEnabled
    } = this.props;

    return (
      <div className={classname}>
        {/* Render Headers */}
        <Switch>
          <Route
            path="/compliance"
            component={SideBarComplianceHeaderContainer}
          />
          {outcomesTabEnabled && (
            <Route
              path="/outcomes"
              component={SideBarOutcomesHeaderContainer}
            />
          )}
          <Route path="/patients" render={this.renderPatientFilterHeader} />
          <Route path="/admin" render={this.renderAdminDefaultFilterHeader} />
          <Route path="/wounds" component={SideBarWoundsHeaderContainer} />
          <Route path="/risk" component={SideBarRiskHeaderContainer} />
          <Route
            exact
            path="/etiology/details"
            render={this.renderDefaultFilterHeader}
          />
          <Route path="/etiology" component={SideBarSurveyHeaderContainer} />
          <Route
            path="/population"
            component={SideBarPopulationHeaderContainer}
          />
          <Route path="/exports" />
          <Route
            path="/monitoring"
            component={SideBarMonitoringHeaderContainer}
          />
          {alertsTabEnabled && (
            //Replace wih "/my-dashboard/alerts" #theRealMyDashboard
            <Route
              path="/my-dashboard/alerts"
              component={SideBarAlertsHeaderContainer}
            />
          )}
          {evaluationListEnabled && (
            <Route
              path="/my-dashboard/evaluation-list/wound"
              component={SideBarEvaluationListWoundHeaderContainer}
            />
          )}
          {evaluationListEnabled && (
            <Route
              path="/my-dashboard/evaluation-list/braden"
              component={SideBarEvaluationListBradenHeaderContainer}
            />
          )}
          {evaluationListEnabled && (
            <Route
              path="/my-dashboard/evaluation-list/purs"
              component={SideBarEvaluationListPURSHeaderContainer}
            />
          )}
          {evaluationListEnabled && (
            <Route
              path="/my-dashboard/evaluation-list/total-skin"
              component={SideBarEvaluationListTotalSkinHeaderContainer}
            />
          )}
          {healingIndexDashboardEnabled && (
            <Route
              path="/my-dashboard/wound-risk"
              component={SideBarWoundRiskHeaderContainer}
            />
          )}
          <Route path="/" render={this.renderDefaultFilterHeader} />
        </Switch>

        {/* Render Filter Component */}
        <Switch>
          {myDashboardTabEnabled && (
            <Route
              path="/my-dashboard"
              render={this.getMyDashboardFilterComponent}
            />
          )}
          {alertsTabEnabled && (
            //Replace wih "/my-dashboard/alerts" #theRealMyDashboard
            <Route
              path="/my-dashboard/alerts"
              render={this.getAlertFilterComponent}
            />
          )}
          {evaluationListEnabled && (
            <Route
              path="/my-dashboard/evaluation-list/wound"
              render={this.getEvaluationListWoundFilterComponent}
            />
          )}
          {evaluationListEnabled && (
            <Route
              path="/my-dashboard/evaluation-list/braden"
              render={this.getEvaluationListBradenFilterComponent}
            />
          )}
          {evaluationListEnabled && (
            <Route
              path="/my-dashboard/evaluation-list/purs"
              render={this.getEvaluationListPURSFilterComponent}
            />
          )}
          {evaluationListEnabled && (
            <Route
              path="/my-dashboard/evaluation-list/total-skin"
              render={this.getEvaluationListTotalSkinFilterComponent}
            />
          )}
          {healingIndexDashboardEnabled && (
            <Route
              path="/my-dashboard/wound-risk"
              render={this.getWoundRiskFilterComponent}
            />
          )}
          <Route
            path="/compliance"
            render={this.getComplianceFilterComponent}
          />
          {monitoringTabEnabled && (
            <Route
              path="/monitoring"
              render={this.getMonitoringFilterComponent}
            />
          )}
          {outcomesTabEnabled && (
            <Route path="/outcomes" render={this.getOutcomesFilterComponent} />
          )}
          <Route
            path="/patients/:patientId?"
            render={this.getPatientFilterComponent}
          />
          <Route path="/wounds" render={this.getWoundsFilterComponent} />
          <Route path="/risk" render={this.getRiskFilterComponent} />
          <Route
            exact
            path="/etiology/details"
            render={this.getSurveyDetailsFilterComponent}
          />
          <Route path="/etiology" render={this.getSurveyFilterComponent} />
          <Route
            path="/population"
            render={this.getPopulationFilterComponent}
          />
          {adminTabEnabled && (
            <Route path="/admin" component={SideBarAdminNavigationContainer} />
          )}
        </Switch>
      </div>
    );
  }
}
