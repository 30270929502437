import { createSelector } from "reselect";

import { adminSectionSelector } from "../admin_section_selector";

/**
 * Selector for the Patient Name or MRN or Code Search String
 */
export const patientNameOrMRNOrCodeSearchStringSelector = createSelector(
  adminSectionSelector,
  section => {
    const { searchFilters } = section;
    if (searchFilters.length) {
      let nameMRNCodeSearchFilterObject = searchFilters.find(
        filter => filter.key === "patient_name_or_mrn_or_code"
      );
      if (nameMRNCodeSearchFilterObject) {
        return nameMRNCodeSearchFilterObject.value;
      }
    }
  }
);
