import { createSelector } from "reselect";
import { riskAssessmentTypesSelector } from "src/selectors/section_selectors/risk";

export const defaultAssessmentTypeSelector = createSelector(
  riskAssessmentTypesSelector,
  assessmentTypes => {
    if (!assessmentTypes.includes("braden")) {
      return "norton_plus";
    } else {
      return "braden";
    }
  }
);
