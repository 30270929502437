import { dataActionTypes } from "src/action_types/data_action_types";
import { loadStates } from "src/constants/load_states";
const defaultState = {
  loadState: loadStates.loading,
  data: {},
  links: {},
  meta: {}
};
/**
 * Reducer for the Partners data in the data state tree
 */
export function partnersReducer(state = defaultState, action) {
  switch (action.type) {
    case dataActionTypes.populatePartners:
      return {
        loadState: loadStates.loaded,
        data: { ...state.data, ...action.payload.data },
        links: action.payload.links,
        meta: action.payload.meta
      };
    case dataActionTypes.partnersLoadError:
      return { ...state, loadState: loadStates.loadError };
    default:
      return state;
  }
}
