/**
 * return selector if function, or wrap with function if Object
 * @param {Object|function} selector
 * @returns {function}
 */
export function wrapSelector(selector) {
  if (typeof selector == "function") {
    return selector;
  } else {
    return () => selector;
  }
}
