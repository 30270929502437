import { createSelector } from "reselect";
import { dataSelector } from "../../data_selector";

/**
 * Selector for the imports section of the data state tree
 */
export const importsSelector = createSelector(
  dataSelector,
  data => data.imports
);
