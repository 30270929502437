// @flow
import etiology from "./etiology";
import locations from "./locations";
import patients from "./patients";
import risks from "./risks";

import {
  ETIOLOGY,
  LOCATIONS,
  PATIENTS,
  RISKS
} from "src/reducers/propertyReducers/constants";

export default {
  [ETIOLOGY]: etiology,
  [LOCATIONS]: locations,
  [PATIENTS]: patients,
  [RISKS]: risks
};
