// Models
import {
  displayValueForArea,
  displayValueForLength,
  displayValueForPercentage
} from "src/models/revisions";

/**
 * Get table for pdf
 * @param {Object} revision
 * @return {Object} returns null if table is empty
 */
export function getDimensionsTable(revision, isAutodepth) {
  const {
    area,
    deltaArea,
    height,
    deltaHeight,
    width,
    deltaWidth,
    maxDepth,
    deltaDepth,
    maxUndermining,
    deltaUndermining,
    underminings,
    maxTunnelling,
    deltaTunnelling,
    tunnellings
  } = revision.attributes;

  const getDeltaCellStyle = value => {
    let styles = ["tableDataCell"];
    if (!value || value == 0) {
      return styles;
    }

    styles.push(value > 0 ? "redFont" : "greenFont");
    return styles;
  };

  let body = [];

  // Area row
  if (area || area == 0 || deltaArea || deltaArea == 0) {
    body.push([
      {
        text: "Area",
        style: "tableLabelCell"
      },
      {
        text: displayValueForArea(area),
        style: "tableDataCell"
      },
      {
        text: displayValueForPercentage(deltaArea),
        style: getDeltaCellStyle(deltaArea)
      }
    ]);
  }

  // Height
  if (height || height == 0 || deltaHeight || deltaHeight == 0) {
    body.push([
      {
        text: "Length",
        style: "tableLabelCell"
      },
      {
        text: displayValueForLength(height),
        style: "tableDataCell"
      },
      {
        text: displayValueForPercentage(deltaHeight),
        style: getDeltaCellStyle(deltaHeight)
      }
    ]);
  }

  // Width
  if (width || width == 0 || deltaWidth || deltaWidth == 0) {
    body.push([
      {
        text: "Width",
        style: "tableLabelCell"
      },
      {
        text: displayValueForLength(width),
        style: "tableDataCell"
      },
      {
        text: displayValueForPercentage(deltaWidth),
        style: getDeltaCellStyle(deltaWidth)
      }
    ]);
  }

  // Depth
  if (maxDepth || maxDepth == 0 || deltaDepth || deltaDepth == 0) {
    body.push([
      {
        text: isAutodepth ? "Max AutoDepth" : "Deepest Point",
        style: "tableLabelCell"
      },
      {
        text: displayValueForLength(maxDepth),
        style: "tableDataCell"
      },
      {
        text: displayValueForPercentage(deltaDepth),
        style: getDeltaCellStyle(deltaDepth)
      }
    ]);
  }

  if (
    maxUndermining ||
    maxUndermining == 0 ||
    deltaUndermining ||
    deltaUndermining == 0
  ) {
    body.push([
      {
        text: "Max Undermining",
        style: "tableLabelCell"
      },
      {
        text: displayValueForLength(maxUndermining),
        style: "tableDataCell"
      },
      {
        text: displayValueForPercentage(deltaUndermining),
        style: getDeltaCellStyle(deltaUndermining)
      }
    ]);
  }

  if (underminings && underminings.length) {
    body.push([
      {
        text: "Undermining",
        style: "tableLabelCell"
      },
      {
        text: underminings.join("\n"),
        style: "tableDataCell",
        colSpan: 2
      },
      {}
    ]);
  }

  if (
    maxTunnelling ||
    maxTunnelling == 0 ||
    deltaTunnelling ||
    deltaTunnelling == 0
  ) {
    body.push([
      {
        text: "Longest Tunnel",
        style: "tableLabelCell"
      },
      {
        text: displayValueForLength(maxTunnelling),
        style: "tableDataCell"
      },
      {
        text: displayValueForPercentage(deltaTunnelling),
        style: getDeltaCellStyle(deltaTunnelling)
      }
    ]);
  }

  if (tunnellings && tunnellings.length) {
    body.push([
      {
        text: "Tunnelling",
        style: "tableLabelCell"
      },
      {
        text: tunnellings.join("\n"),
        style: "tableDataCell",
        colSpan: 2
      },
      {}
    ]);
  }

  // don't show empty table
  if (!body.length) return null;

  // Add note at bottom of table
  body.push([
    {
      text: "*Negative percentage values indicate wound is getting smaller",
      colSpan: 3,
      style: "tableFootnote"
    },
    {},
    {}
  ]);

  // add header
  body.unshift([
    {
      text: "DIMENSIONS",
      colSpan: 3,
      style: "tableHeaderCell"
    },
    {},
    {}
  ]);

  // Construct table
  return [
    {
      table: {
        widths: ["45%", "30%", "*"],
        headerRows: 1,
        body
      },
      layout: "pdfTableStyle"
    }
  ];
}
