import { createSelector } from "reselect";

import { buildQueryString } from "src/utils/build_query_string";

//API
import { createRuleTemplatesGetPayload } from "src/api/rule_templates";

//Constants
import { apiPageLimits } from "src/constants";

export const createRuleTemplatesQuerySelector = ({ pageNumber, pageLimit }) => {
  return createSelector(() => {
    return buildQueryString(
      createRuleTemplatesGetPayload({
        pageNumber: pageNumber || 0,
        pageLimit: pageLimit || apiPageLimits.ruleTemplates
      })
    );
  });
};
