import get from "lodash.get";
import { createSelector } from "reselect";

//Selectors
import {
  patientsResourceSelectors,
  usersResourceSelectors
} from "src/selectors/data_selectors";

const getFullName = ({ firstName, middleName, lastName }) => {
  return `${firstName ? firstName : ""}${middleName ? ` ${middleName}` : ""}${
    lastName ? ` ${lastName}` : ""
  }`;
};

/**
 * Create a selector that takes an array of alerts and returns an array of alerts with
 * patient, user and frame data for each alert in the array
 * @param {array} alerts
 * @returns {Function} selector
 */
export function createAlertsIncludingPatientAndUserDetailsSelector(alerts) {
  return createSelector(
    patientsResourceSelectors.dataSelector,
    usersResourceSelectors.dataSelector,
    (patients, users) => {
      return alerts.map(alert => {
        //Patient
        const patient = patients[get(alert, "attributes.patientId", "")];
        if (patient) {
          const { firstName, middleName, lastName } = patient.attributes;
          alert.attributes.patientName = getFullName({
            firstName,
            middleName,
            lastName
          });
          alert.attributes.patientMrn = patient.attributes.mrn || "";
        }
        //Tripped By User
        const trippedByUser =
          users[get(alert, "attributes.trippedByUserId", "")];
        if (trippedByUser) {
          const { firstName, lastName } = trippedByUser.attributes.profile;
          alert.attributes.trippedByUserName = getFullName({
            firstName,
            lastName
          });
        }
        return alert;
      });
    }
  );
}
