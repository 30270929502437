import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import moment from "moment";

//PDF Definitions
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import { pdfTableStyle } from "src/pdfs";
pdfMake.vfs = vfsFonts.pdfMake.vfs;
pdfMake.tableLayouts = { pdfTableStyle };

//Components
import { ButtonComponent } from "src/components/button_component";

//Translations
import i18n from "src/translations";

//Utils
import { getQueryParamsFromUrl } from "src/utils/get_query_params_from_url";
import { trackError } from "src/error_tracking";

export class DownloadRevisionPDFButtonComponent extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    revisions: PropTypes.array.isRequired,
    patient: PropTypes.object.isRequired,
    frames: PropTypes.object.isRequired,
    generatePDFDefinition: PropTypes.func.isRequired,
    landscape: PropTypes.bool.isRequired
  };

  state = {
    pdfDisabled: false
  };

  /**
   * Handler for printing the pdf
   */
  handlePrintViewButtonClick = () => {
    const { revisions, patient, frames, landscape } = this.props,
      patientLastName = get({ patient }, "patient.attributes.lastName", ""),
      patientFirstName = get({ patient }, "patient.attributes.firstName", "");

    this.setState({ pdfDisabled: true });

    for (const frame in frames) {
      //Image Expiry
      let pdfUrlExpiresAt = getQueryParamsFromUrl(
        get(frame, "attributes.pdfUrl", "")
      ).Expires;

      if (pdfUrlExpiresAt && moment().isAfter(moment.unix(pdfUrlExpiresAt))) {
        this.props.showPdfTimeoutErrorMessage();
        return;
      }
    }

    let date = "";
    if (revisions.length == 1) {
      date =
        "_" +
        new moment(revisions[0].attributes.createdAt).format(
          "YYYY-MM-DD--HH-mm"
        );
    }

    let filename = "";
    if (landscape) {
      filename = `${patientLastName}_${patientFirstName}_wound_summary.pdf`;
    } else {
      filename = `${patientLastName}_${patientFirstName}_wound_assessment${date}.pdf`;
    }

    this.props
      .generatePDFDefinition()
      .then(pdfDefinitionObject => {
        pdfMake.createPdf(pdfDefinitionObject.pdfDefinition).download(filename);

        this.setState({ pdfDisabled: false });
      })
      .catch(err => {
        this.props.showPdfErrorView();
        trackError(err, {
          extra: { error_context: "revision_pdf" }
        });

        this.setState({ pdfDisabled: false });
      });

    this.setState({ pdfDisabled: false });
  };

  isSummaryFormat = () => {
    const { landscape, revisions } = this.props;
    return landscape && revisions && revisions.length > 0;
  };

  render() {
    const { pdfDisabled } = this.state;
    const { className } = this.props;
    let buttonText = this.isSummaryFormat()
      ? i18n.t("actions.downloadSummary")
      : i18n.t("actions.downloadPDF");
    return (
      <ButtonComponent
        onClick={this.handlePrintViewButtonClick}
        disabled={pdfDisabled}
        className={className}
      >
        {buttonText}
      </ButtonComponent>
    );
  }
}
