import { createSelector } from "reselect";
import moment from "moment";
import get from "lodash.get";

// Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";

/**
 * Create a selector that returns whether a revision was the first one in its series
 * @param {Object} revision - the revision
 */
export function createRevisionIsFirstInSeriesSelector(revision) {
  return createSelector(
    revisionsResourceSelectors.createDataForContextSelectorWithString(
      revision.attributes.seriesId
    ),
    revisionsResourceSelectors.createMetaContextSelectorWithString(
      revision.attributes.seriesId
    ),
    (revisions, meta) => {
      const recordCount = get(meta, "meta.recordCount", null);
      if (recordCount && recordCount <= revisions.length) {
        const sortedRevisions = revisions.sort((r1, r2) => {
          return moment(r1.attributes.createdAt).isAfter(
            r2.attributes.createdAt
          )
            ? -1
            : 1;
        });

        return sortedRevisions.length
          ? sortedRevisions[sortedRevisions.length - 1].id == revision.id
          : false;
      }

      return false;
    }
  );
}
