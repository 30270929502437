import React from "react";
import { connect } from "react-redux";
import i18n from "src/translations";

//Selectors
import { encounterNamingConventionSelector } from "src/selectors/ui_config_selectors/global/encounter_naming_convention_selector";

export const EncounterDisplayValueComponent = props => {
  const {
    tagName,
    tagStyle,
    encounterNumber,
    encounterStartDateString,
    encounterNamingConvention
  } = props;

  let CustomTag = tagName;

  if (encounterNamingConvention !== "None") {
    return (
      <CustomTag className={tagStyle}>
        {encounterNamingConvention + ": "}
        <>
          {encounterNumber ? (
            <>
              <span>{encounterNumber + " - "}</span>
              {i18n.t("data.encounters.startDateString") + ": "}
              <span>{encounterStartDateString}</span>
            </>
          ) : (
            <span>{i18n.t("data.encounters.unknown")}</span>
          )}
        </>
      </CustomTag>
    );
  } else return null;
};

const mapStateToProps = state => {
  return {
    encounterNamingConvention: encounterNamingConventionSelector(state)
  };
};

export default connect(mapStateToProps)(EncounterDisplayValueComponent);
