import { createSelector } from "reselect";
import { unitsResourceSelectors } from "../units_resource_selectors";
import { sortByName } from "src/utils/sort_by_name";

export const unitsSortedByNameSelector = createSelector(
  unitsResourceSelectors.dataSelector,
  units => {
    return sortByName(Object.values(units));
  }
);
