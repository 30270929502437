import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

/**
 * Memoized selector to enable copying of evaluations if policy exists
 */
export const copyAssessmentToClipboardEnabledSelector = createSelector(
  createPolicyForInternalNameSelector("enable_copy_assessment"),
  policy => {
    // only enable copy assessments if organization policy to enable copying of evaluation is found
    if (policy && policy.attributes.policyValue) {
      return true;
    } else {
      return false;
    }
  }
);
