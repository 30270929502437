import { actions } from "./actions";
import { data } from "./data";
import { entities } from "./entities";
import { glossary } from "./glossary";
import { headers } from "./headers";
import { interaction } from "./interaction";
import { text } from "./text";
import { validations } from "./validations";
export const en = {
  // Default name space
  translation: {
    actions,
    data,
    entities,
    glossary,
    headers,
    interaction,
    text,
    validations
  },
  // Glossary name space - words reused in other text
  glossary
};
