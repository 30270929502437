import axios from "axios";
import qs from "qs";

/**
 * Fetch partners data
 * @return {Promise} Promise with the response
 */
export function fetchPartners() {
  const query = {
    page: { limit: 1000 }
  };
  const url = `/api/v4/partners?${qs.stringify(query)}`;
  return axios.get(url);
}
