import { createSelector } from "reselect";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Selectors
import { createCurrentPagePayloadSelector } from "../create_current_page_payload_selector";

/**
 * create selector that gets context for the current page of patients
 */
export function createCurrentPageContextSelector() {
  return createSelector(
    createCurrentPagePayloadSelector(),
    currentPagePayload => buildQueryString(currentPagePayload)
  );
}
