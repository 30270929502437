import { createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { organizationsResourceSelectors } from "src/selectors/data_selectors/organizations";
import { selectedPartnerIdSelector } from "src/selectors/section_selectors/admin";
import { sortByName } from "src/utils/sort_by_name";

export function createUserFormPartnerOrganizationsOptionsSelector(formName) {
  return createSelector(
    getFormValues(formName),
    organizationsResourceSelectors.dataSelector,
    selectedPartnerIdSelector,
    (form, org, partnerId) => {
      let orgArray;

      if (form && form["partners"]) {
        orgArray = Object.values(org).filter(o => {
          return o.attributes.partnerId == form["partners"];
        });
      } else {
        orgArray =
          partnerId != ""
            ? Object.values(org).filter(
                o => o.attributes.partnerId == partnerId
              )
            : [];
      }

      return orgArray.length ? sortByName(orgArray) : [];
    }
  );
}
