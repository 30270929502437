import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// HOCs
// import { withFloorsLoader } from "src/hoc";
import { withResourceLoaderFactory } from "src/hoc/factories";

//Factory
import { withPatientsLoaderFactory } from "src/hoc";

//Components
import { AdminPatientsComponent } from "src/components/admin_patients_component";

//Selectors
import {
  patientsResourceSelectors,
  unitsResourceSelectors,
  floorsResourceSelectors
} from "src/selectors/data_selectors";
import { adminBulkImportPatientsEnabledSelector } from "src/selectors/config_selectors";
import {
  patientCsvExportEnabledSelector,
  patientCreateEnabledSelector
} from "src/selectors/ui_config_selectors/admin";
import {
  patientTableQuerySelector,
  patientTablePayloadSelector
} from "src/selectors/query_selectors/admin";
import { patientCsvExportPostPayloadSelector } from "src/selectors/api_selectors/admin/csv_exports";
////Units
import {
  createAdminUnitsContextSelector,
  createAdminUnitsQuerySelector
} from "src/selectors/api_selectors/admin/units";
////Floors
import {
  createAdminFloorsContextSelector,
  createAdminFloorsQuerySelector
} from "src/selectors/api_selectors/admin/floors";

// Actions
import { postCsvExportAction } from "src/actions/data_actions/csv_exports";
import { loadUnitsAction, loadFloorsAction } from "src/actions/data_actions";

function mapStateToProps(state) {
  const context = patientTableQuerySelector(state);
  return {
    patientsLoadState: patientsResourceSelectors.createContextLoadStateSelectorWithContextString(
      context
    )(state),
    bulkImportPatientsEnabled:
      adminBulkImportPatientsEnabledSelector(state) || false,
    createPatientsEnabled: patientCreateEnabledSelector(state),
    csvExportEnabled: patientCsvExportEnabledSelector(state),
    csvPostPayload: patientCsvExportPostPayloadSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendPostCsvExportAction: payload => {
      dispatch(postCsvExportAction({ payload }));
    }
  };
}

export const AdminPatientsContainer = flowRight(
  withPatientsLoaderFactory(
    patientTableQuerySelector,
    patientTablePayloadSelector
  ),
  withResourceLoaderFactory({
    getResourceAction: loadUnitsAction,
    resourceSelectors: unitsResourceSelectors,
    createContextSelector: createAdminUnitsContextSelector,
    createQuerySelector: createAdminUnitsQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadFloorsAction,
    resourceSelectors: floorsResourceSelectors,
    createContextSelector: createAdminFloorsContextSelector,
    createQuerySelector: createAdminFloorsQuerySelector
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AdminPatientsComponent);
