import { createSelector } from "reselect";

// Selectors
import { locationIdsToDisplaySelector } from "src/selectors/data_selectors/locations";

// Filter formatters
import resourceFilterFormatters from "src/api/resource_filter_formatters";

export function createSelectedLocationIdsForFiltersSelector({ filters }) {
  return createSelector(
    locationIdsToDisplaySelector,
    defaultIds => {
      let locationIds = resourceFilterFormatters.locationIdsFromFilters(
        filters
      );
      return locationIds && locationIds.length ? locationIds : defaultIds;
    }
  );
}
