import React from "react";
import PropTypes from "prop-types";

// Components
import { InputRangeComponent, TextLinkComponent } from "src/components/ui_kit";

// Styles
import styles from "./style.module.less";

export class SideBarRangeFilterComponent extends React.PureComponent {
  static propTypes = {
    minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minRangeAllowed: PropTypes.number.isRequired,
    maxRangeAllowed: PropTypes.number.isRequired,
    minChanged: PropTypes.func.isRequired,
    maxChanged: PropTypes.func.isRequired,
    resetRange: PropTypes.func.isRequired
  };

  onResetClicked = () => {
    this.props.resetRange();
  };

  render() {
    return (
      <div>
        <InputRangeComponent {...this.props} />
        <div className={styles["reset-button-container"]}>
          <TextLinkComponent
            theme={"light"}
            label="Reset"
            onClick={this.onResetClicked}
          />
        </div>
      </div>
    );
  }
}
