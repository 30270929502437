import { createSelector } from "reselect";
import { frameConfigSelector } from "../frame_config_selector";

/**
 * Selector to get which thermal frame (Delta or Absolute) is currently displayed
 */
export const thermalFrameSelector = createSelector(
  frameConfigSelector,
  config => {
    return config.isDeltaFrameActive;
  }
);
