import { createSelector } from "reselect";

// Selectors
import { woundTalliesResourceSelectors } from "src/selectors/data_selectors";
import { createWoundTalliesContextSelector } from "src/selectors/api_selectors/survey/wound_tallies/create_wound_tallies_context_selector";

export function createShowAcquiredUnknownSelector(tallyType) {
  return createSelector(
    woundTalliesResourceSelectors.createDataForContextSelector(
      createWoundTalliesContextSelector(tallyType)
    ),
    tallies => {
      return tallies.some(tally => {
        return (
          tally.attributes.existingAcquiredUnknownCount > 0 ||
          tally.attributes.newAcquiredUnknownCount > 0
        );
      });
    }
  );
}
