import apiFetch from "app/util/apiFetch";
import calculateAggregates from "./calculateAggregates";

export default function loadRiskClinicalAggregates(
  locationIds,
  startTime,
  endTime
) {
  return dispatch => {
    const payload = {
      location_ids: locationIds.join(),
      start_time: startTime,
      end_time: endTime
    };
    return dispatch(
      apiFetch(`/api/v2/risk_dashboard/braden/aggregates`, {
        method: "POST",
        body: payload,
        headers: {
          Accept: "application/json",
          "X-HTTP-Method-Override": "GET"
        }
      })
    ).then(({ aggregates }) => {
      dispatch({
        type: "LOCATION_CLINICAL_RISK_AGGREGATE",
        aggregates: calculateAggregates(aggregates)
      });
      return aggregates;
    });
  };
}
