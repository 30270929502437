// React
import PropTypes from "prop-types";

import React, { Component } from "react";

// Containers
import { PopulationTallyTableRowByEtiologyComponent } from "src/components/tables";

//Components
import { PopulationTallyTableHeaderByEtiologyComponent } from "../population_tally_table_header_by_etiology_component";

// css
import styles from "../style.module.less";

export class PopulationTallyTableByEtiologyComponent extends Component {
  static propTypes = {
    tallies: PropTypes.array.isRequired
  };

  /**
   * Get the body for the tally table (all tally rows)
   */
  renderBody() {
    const { tallies, schemaLocalizationDictionary } = this.props;
    return tallies.map((tally, index) => {
      return (
        <PopulationTallyTableRowByEtiologyComponent
          key={index}
          tally={tally}
          schemaLocalizationDictionary={schemaLocalizationDictionary}
        />
      );
    });
  }
  /**
   * Get the AveragePatientCensus based on the filter where
   * tallytype is totala dn display the value accordingly
   */
  renderAveragePatientCensus() {
    const { tallies } = this.props;
    return tallies.find(tallies => tallies.tallyType == "total").patientCensus;
  }

  render() {
    return (
      <div>
        <span className={`${styles["floatRight"]} ${styles["margin25px"]}`}>
          <strong> Average Patient Census: </strong>
          {this.renderAveragePatientCensus()}
        </span>

        <table className={`table ${styles["population-tally-table"]}`}>
          <thead>
            <PopulationTallyTableHeaderByEtiologyComponent />
          </thead>
          <tbody>{this.renderBody()}</tbody>
        </table>
      </div>
    );
  }
}
