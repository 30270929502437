import { createSelector } from "reselect";
import { organizationsResourceSelectors } from "../organizations_resource_selectors";
import { sortByName } from "src/utils/sort_by_name";

export const organizationsSortedByNameSelector = createSelector(
  organizationsResourceSelectors.dataSelector,
  organizations => {
    return sortByName(Object.values(organizations));
  }
);
