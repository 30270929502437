import get from "lodash.get";
import { createSelector } from "reselect";

//Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { usersResourceSelectors } from "src/selectors/data_selectors";

export function createDataForRevisionsWithUsers(currentPageContextSelector) {
  return createSelector(
    revisionsResourceSelectors.createDataForContextSelector(
      currentPageContextSelector
    ),
    usersResourceSelectors.dataSelector,
    (revisions, users) => {
      let revisionsDeepClone = JSON.parse(JSON.stringify(revisions));
      return revisionsDeepClone.map(revision => {
        let revisionUserId = get(revision, "attributes.userId", null);
        if (revisionUserId) {
          let user = users[revisionUserId],
            firstName = get(user, "attributes.profile.firstName", null),
            lastName = get(user, "attributes.profile.lastName", null),
            designation = get(user, "attributes.profile.designation", null);
          revision.attributes.createdBy = {};
          revision.attributes.createdBy.firstName = firstName
            ? firstName
            : null;
          revision.attributes.createdBy.lastName = lastName ? lastName : null;
          revision.attributes.createdBy.designation = designation
            ? designation
            : null;
        }
        return revision;
      });
    }
  );
}
