import { appThemes } from "src/constants";
import { configActionTypes } from "src/action_types/config_action_types";
import { appModes } from "src/constants";
import { setCookieValueForKey, getCookieValueForKey } from "src/client_storage";

const defaultState = {
  language: "en_us",
  mode: getCookieValueForKey("mode") || appModes.standard,
  name: getCookieValueForKey("pname") || null,
  partnerLocationIds: !!getCookieValueForKey("plocids")
    ? getCookieValueForKey("plocids").split(",")
    : [],
  theme: getCookieValueForKey("theme") || appThemes.swiftBlue,
  launchContext: {}
};

export function configReducer(state = defaultState, action) {
  switch (action.type) {
    case configActionTypes.setLanguage:
      return {
        ...state,
        language: action.payload
      };
    case configActionTypes.setMode:
      setCookieValueForKey("mode", action.payload);
      return {
        ...state,
        mode: action.payload
      };
    case configActionTypes.setPartnerLocationIds:
      setCookieValueForKey("plocids", action.payload.join());
      return {
        ...state,
        partnerLocationIds: action.payload
      };
    case configActionTypes.setPartnerName:
      setCookieValueForKey("pname", action.payload);
      return {
        ...state,
        partnerName: action.payload
      };
    case configActionTypes.setTheme:
      setCookieValueForKey("theme", action.payload);
      return {
        ...state,
        theme: action.payload
      };
    case configActionTypes.updateLaunchContext:
      return {
        ...state,
        launchContext: action.payload
      };
    default:
      return state;
  }
}
