import axios from "axios";
import qs from "qs";

import { apiFactory } from "../api_factory";

export const organizationsApi = apiFactory({
  path: "/api/v4/organizations"
});

/**
 * Fetch organizations and include the prganizations policies
 * @return {Promise} Promise with the response
 */
export function fetchOrganizations() {
  const query = {
    include: "policies,payers",
    page: { limit: 1000 }
  };
  const url = `/api/v4/organizations?${qs.stringify(query)}`;
  return axios.get(url);
}
