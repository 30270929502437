export const woundTypesV4Revera = {
  //Wound Types
  abrasionOnly: "Abrasion",
  abrasion: "Abrasion/Bruise",
  arterial: "Arterial",
  blister: "Blister",
  bruise: "Bruise",
  burn: "Burn",
  cancerLesion: "Cancer Lesion",
  diabetic: "Diabetic",
  hematoma: "Hematoma",
  hSuppurtiva: "Hidradenitis Suppurativa",
  laceration: "Laceration",
  masd: "Moisture Associated Skin Damage (MASD)",
  mole: "Mole",
  lesion: "Open Lesion",
  pressureUlcer: "Pressure",
  rash: "Rash",
  skinTear: "Skin Tear",
  surgical: "Surgical",
  venous: "Venous",
  undiagnosed: "Undiagnosed",

  //Sub Types
  ////Skin Tear
  type1None: "No Skin Loss",
  type2Partial: "Partial Flap Loss",
  type3Total: "Total Flap Loss",

  ////Burn
  first: "First Degree",
  second: "Second Degree",
  third: "Third Degree",

  ////Bruise
  bruiseType: "Colour",

  ////// (Bruise) Color
  red: "Red",
  bluePurple: "Blue/Purple",
  green: "Green",
  yellowBrown: "Yellow/Brown",

  ////Pressure Ulcer, Pressure Kennedy, Pressure Device Related
  one: "Stage 1",
  two: "Stage 2",
  three: "Stage 3",
  four: "Stage 4",
  dti: "Deep Tissue Injury",
  unstageable: "Unstageable",

  ////Pressure Ulcer, Pressure Device Related
  mucosalMembrane: "Mucosal Membrane",

  //////(Pressure Ulcer, Pressure Kennedy, Pressure Device Related) Unstageable
  necrosis: "Slough and/or eschar",
  dressing: "Non-removable device/dressing",

  ////MASD
  iad: "IAD",
  other: "Other",

  ////Surgical
  surgicalClosure: "Surgical Closure Method",
  surgicalOther: "Other",

  //////Surgical Closure Method
  closureSutures: "Sutures",
  closureStaples: "Staples",
  closureTape: "Tape",
  closureSteriStrips: "Steri-Strips",
  closureTissueAdhesives: "Tissue Adhesives",

  //////(Surgical) Other
  incisionApprox: "Incision Approximated",
  dehiscence: "Dehiscence",
  healingRidge: "Healing Ridge",

  ////Applies to all notSet keys
  notSet: "Not Set"
};
