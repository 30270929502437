import { createSelector } from "reselect";
import { partnerNameSelector } from "../partner_name_selector";

/**
 * Selector to get the population tab version for partner
 */

export const populationSectionVersionSelector = createSelector(
  partnerNameSelector,
  partnerName => {
    switch (partnerName) {
      case "pcc":
        return "1";
      default:
        return "1";
    }
  }
);
