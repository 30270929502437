// Action creators
// import { createPopulateActionCreator } from "./create_populate_action_creator";
import { createApiGetActionCreator } from "./create_api_get_action_creator";
import { createInvalidateActionCreator } from "./create_invalidate_action_creator";
import { createInvalidateAllActionCreator } from "./create_invalidate_all_action_creator";

import { createDecrementPageActionCreator } from "./create_decrement_page_action_creator";
import { createIncrementPageActionCreator } from "./create_increment_page_action_creator";
import { createSetPageActionCreator } from "./create_set_page_action_creator";

/**
 * Create action creators
 * @param {Object} resourceConfig
 * @param {Object} globalConfig
 */
export function createActionCreators(resourceConfig, globalConfig) {
  const resourceName = resourceConfig.resourceName;

  // Load state actions
  const invalidate = createInvalidateActionCreator(resourceName);
  const invalidateAll = createInvalidateAllActionCreator(resourceName);

  // Resource actions - expose if needed
  // const populate = createPopulateActionCreator(resourceName);

  // Api actions
  const get = createApiGetActionCreator(resourceConfig, globalConfig);

  // Page actions
  const incrementPage = createIncrementPageActionCreator(resourceName);
  const decrementPage = createDecrementPageActionCreator(resourceName);
  const setPage = createSetPageActionCreator(resourceName);

  return {
    invalidate,
    invalidateAll,
    get,
    incrementPage,
    decrementPage,
    setPage
  };
}
