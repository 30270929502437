import React from "react";
import PropTypes from "prop-types";

// Constants
import { exportReportOptions, exportStates } from "src/constants";

// Styles
import styles from "./style.module.less";

// Translations
import i18n from "src/translations";

// Components
import { ButtonComponent } from "src/components/button_component";
import { SelectComponent } from "src/components/ui_kit";

// Containers
import { ExportsSectionFiltersContainer } from "src/containers/exports_section_filters_container";

export class ExportsSectionComponent extends React.PureComponent {
  static propTypes = {
    exportName: PropTypes.string,
    exportType: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    sendCreateExportAction: PropTypes.func.isRequired,
    sendSetExportNameAction: PropTypes.func.isRequired
  };

  onCreateExportClicked = () => {
    const {
      exportName,
      exportType,
      filters,
      sendCreateExportAction
    } = this.props;
    sendCreateExportAction({ exportName, exportType, filters });
  };

  onSelectExportName = value => {
    this.props.sendSetExportNameAction(value);
  };

  generateExportDisabled = () => {
    return (
      !this.props.exportName ||
      Object.values(this.props.localExports).some(e => {
        return e.attributes.state == exportStates.processing;
      })
    );
  };

  render() {
    const { exportName } = this.props;

    const exportNameOptions = Object.keys(exportReportOptions).map(
      (key, index) => {
        return {
          key: index,
          value: key,
          label: i18n.t(`data.exportNames.${key}`)
        };
      }
    );

    exportNameOptions.unshift({
      key: "undefined",
      value: "",
      label: i18n.t(`data.exportNames.selectReport`)
    });

    return (
      <div>
        <nav className="navbar dashboard-control-navbar">
          <div className="navbar-header">
            <h1 className="navbar-text">Exports</h1>
          </div>
        </nav>
        <div className={styles["export-section-container"]}>
          <div className={styles["export-name-dropdown"]}>
            <SelectComponent
              value={exportName}
              options={exportNameOptions}
              onChange={this.onSelectExportName}
            />
          </div>
          <hr />
          <h3>Filters</h3>
          <ExportsSectionFiltersContainer />
          <ButtonComponent
            onClick={this.onCreateExportClicked}
            disabled={this.generateExportDisabled()}
          >
            {i18n.t("actions.generateExport")}
          </ButtonComponent>
        </div>
      </div>
    );
  }
}
