import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

// Constants
import { analyticsEventNames } from "src/constants/analytics_event_names";

// Components
import { AppConfigurationComponent } from "src/components/app_configuration_component";

// Selectors
import { routeQueryParamsSelector } from "src/selectors/router_selectors";
import { sessionIdSelector } from "src/selectors/session_selectors";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";
import { isIntegrationSelector } from "src/selectors/config_selectors/is_integration_selector";
import {
  assessmentsResourceSelectors,
  usersResourceSelectors,
  organizationsResourceSelectors
} from "src/selectors/data_selectors";
import { createAllAssessmentsWithSchemasQuerySelector } from "src/selectors/api_selectors/global/assessments";
import { createCurrentUserQuerySelector } from "src/selectors/api_selectors/global/users";
import { createAllOrganizationsQuerySelector } from "src/selectors/api_selectors/global/organizations";

// Actions
import {
  setModeAction,
  setThemeAction,
  setPartnerName
} from "src/actions/config_actions";
import { setSessionId } from "src/actions/session_actions";
// Actions
import {
  loadAllAssessmentsAction,
  loadCurrentUserAction,
  loadOrganizationsAction
} from "src/actions/data_actions";
import { createLocalizationDictionaryAction } from "src/actions/section_actions/global";
import { accessTokenSelector } from "src/selectors/session_selectors";

function mapStateToProps(state) {
  return {
    query: routeQueryParamsSelector(state),
    sessionId: sessionIdSelector(state),
    user: currentUserSelector(state),
    isIntegration: isIntegrationSelector(state),
    accessToken: accessTokenSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetModeAction(mode) {
      dispatch(setModeAction(mode));
    },
    sendSetThemeAction(theme) {
      dispatch(setThemeAction(theme));
    },
    sendSetPartnerNameAction(partnerName) {
      dispatch(setPartnerName(partnerName));
    },
    sendSetSessionId(sessionId) {
      dispatch(setSessionId(sessionId));
    }
  };
}

export const AppConfigurationContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadCurrentUserAction,
    resourceSelectors: usersResourceSelectors, //used for load state
    createContextSelector: createCurrentUserQuerySelector,
    createQuerySelector: createCurrentUserQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadOrganizationsAction,
    resourceSelectors: organizationsResourceSelectors,
    createContextSelector: createAllOrganizationsQuerySelector,
    createQuerySelector: createAllOrganizationsQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadAllAssessmentsAction,
    resourceSelectors: assessmentsResourceSelectors,
    createContextSelector: createAllAssessmentsWithSchemasQuerySelector,
    createQuerySelector: createAllAssessmentsWithSchemasQuerySelector,
    additionalActions: [createLocalizationDictionaryAction]
  }),
  withLoadingComponentFactory({
    analyticsEventName: analyticsEventNames.renderSectionInit
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AppConfigurationComponent);
