import { createSelector } from "reselect";
import { createLatestRegionForRevisionSelector } from "../create_latest_region_for_revision_selector";
import get from "lodash.get";

export function isLatestRegionForRevisionAutodepth(revision) {
  return createSelector(
    createLatestRegionForRevisionSelector(revision),
    region => {
      if (!revision || !region) {
        return false;
      }

      const points = get(region, "attributes.depth.points", null);
      if (!points || !points.length) {
        return false;
      }
      const isAutodepth =
        points.find(point => {
          return point.isComputed === true;
        }) != undefined;

      return isAutodepth;
    }
  );
}
