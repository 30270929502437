import { createSelector } from "reselect";
import {
  myDashboardTabEnabledSelector,
  alertsEnabledSelector,
  evaluationListEnabledSelector
} from "../";

import { searchPatientDashboardEnabledSelector } from "src/selectors/ui_config_selectors/global/search_patient_dashboard_enabled_selector";
import { reportingPermissionEnabledSelector } from "src/selectors/ui_config_selectors/global/reporting_permission_enabled_selector";

export const defaultNavigationPageSelector = createSelector(
  myDashboardTabEnabledSelector,
  alertsEnabledSelector,
  evaluationListEnabledSelector,
  searchPatientDashboardEnabledSelector,
  reportingPermissionEnabledSelector,
  (
    isDashboardEnabled,
    isAlertsEnabled,
    evaluationListEnabled,
    searchPatientDashboardEnabled,
    isReportingEnabled
  ) => {
    if (searchPatientDashboardEnabled) {
      return "/patient-search";
    } else if (!isReportingEnabled) {
      return "/patients";
    } else if (isAlertsEnabled) {
      return "/my-dashboard/alerts";
    } else if (evaluationListEnabled) {
      return "/my-dashboard/evaluation-list/wound";
    } else if (isDashboardEnabled) {
      return "/my-dashboard";
    } else {
      return "/compliance";
    }
  }
);
