import { createActionTypes } from "../create_action_types";

export function createActionCreators(fieldName) {
  const actionTypes = createActionTypes(fieldName);

  function setValueForKeyAtIndex(payload) {
    return {
      type: actionTypes.setValueForKeyAtIndex,
      payload
    };
  }

  function insertValueForKeyAtIndex(payload) {
    return {
      type: actionTypes.insertValueForKeyAtIndex,
      payload
    };
  }

  function appendValueForKey(payload) {
    return {
      type: actionTypes.appendValueForKey,
      payload
    };
  }

  function removeValueForKeyAtIndex(payload) {
    return {
      type: actionTypes.removeValueForKeyAtIndex,
      payload
    };
  }

  return {
    setValueForKeyAtIndex,
    insertValueForKeyAtIndex,
    appendValueForKey,
    removeValueForKeyAtIndex
  };
}
