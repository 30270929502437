import React from "react";
import { connect } from "react-redux";
import get from "lodash.get";
import encounterTextFormatter from "src/formatters/encounter_text_formatter/index";
import i18n from "src/translations";

//Constants
import { analyticsEventNames } from "src/constants";

//Analytics
import { track } from "src/analytics";

//Actions
import {
  signAssessmentAnswerAction,
  signAndLockAssessmentAnswersAction
} from "src/actions/data_actions";

// Styles
import styles from "./style.module.less";

//Selectors
import {
  isESignatureEnabledSelector,
  encounterFieldsShownInSelectionUISelector,
  encounterMandatorySelectionSelector
} from "src/selectors/ui_config_selectors/patients";

export const EncounterDropdownComponent = props => {
  const {
    encounters,
    selectedEncounterId,
    encounterNamingConvention,
    isESignatureEnabled,
    assessmentAnswerId,
    cancel,
    actionType,
    selectEncounter,
    dispatch,
    showModalForESignature,
    encounterSelectionMandatory,
    encounterFieldsShown
  } = props;

  const encounterSelectionMandatoryValue = encounterSelectionMandatory;

  if (
    Object.keys(encounters).length !== 0 ||
    !encounterSelectionMandatoryValue
  ) {
    return (
      <>
        <br />
        <h4>{i18n.t("data.encounters.evaluationDocumentedFor")}:</h4>
        <div className={styles["select-container"]}>
          <select
            className={styles["select"]}
            name="encounters"
            id="encounters"
            value={selectedEncounterId}
            onChange={e => selectEncounter(e.target.value)}
          >
            {Object.keys(encounters).length !== 1 && (
              <option value="">Please Select</option>
            )}

            {!encounterSelectionMandatoryValue && (
              <option value="none" key="no_encounter">
                No encounters
              </option>
            )}
            {encountersList(
              encounters,
              encounterNamingConvention,
              encounterFieldsShown
            )}
          </select>
        </div>
        {isESignatureEnabled && (
          <>
            <br />
            <h4>{i18n.t("data.encounters.continueQuestion")}?</h4>
          </>
        )}
        <div className={styles["button-container"]}>
          <button
            type="submit"
            className="btn dashboard-btn"
            disabled={selectedEncounterId === ""}
            onClick={() => {
              track(analyticsEventNames.confirmEncounter, {
                encountersAvailable:
                  Object.keys(encounters).length === 1 ? "One" : "Multiple",
                action: "Yes"
              });

              let selectedEncounterIdFinal = selectedEncounterId;

              if (selectedEncounterId === "none") {
                selectedEncounterIdFinal = null;
              }

              signOrSignAndLockAssessment(
                isESignatureEnabled,
                assessmentAnswerId,
                selectedEncounterIdFinal,
                cancel,
                showModalForESignature,
                actionType,
                dispatch
              );
            }}
          >
            {isESignatureEnabled ? i18n.t("data.encounters.yes") : actionType}
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => {
              track(analyticsEventNames.confirmEncounter, {
                encountersAvailable:
                  Object.keys(encounters).length === 1 ? "One" : "Multiple",
                action: "No"
              });
              cancel();
            }}
          >
            {i18n.t("data.encounters.cancel")}
          </button>
        </div>
      </>
    );
  } else {
    return (
      <div className="text-center">
        <p id="noEncountersFound">
          {i18n.t("data.encounters.noActiveEncounters", {
            encounterNamingConvention: encounterNamingConvention
          })}
        </p>
        <button
          type="submit"
          className="btn dashboard-btn"
          onClick={() => {
            track(analyticsEventNames.confirmEncounter, {
              encountersAvailable: "None",
              action: "No"
            });
            cancel();
          }}
        >
          {i18n.t("data.encounters.ok")}
        </button>
      </div>
    );
  }
};

const encountersList = (
  encounters,
  encounterNamingConvention,
  encounterFieldsShown
) => {
  return encounters.map(encounter => {
    const encounterId = get(encounter, "id", null);

    return encounterId ? (
      <option value={encounterId} key={encounterId}>
        {encounterTextFormatter(
          encounter,
          encounterNamingConvention,
          encounterFieldsShown
        )}
      </option>
    ) : null;
  });
};

const signOrSignAndLockAssessment = (
  isESignatureEnabled,
  assessmentAnswerId,
  selectedEncounterId,
  cancel,
  showModalForESignature,
  actionType,
  dispatch
) => {
  if (isESignatureEnabled) showModalForESignature(true);
  else {
    const payload = {
      id: assessmentAnswerId,
      data: {
        id: assessmentAnswerId,
        encounter_id: selectedEncounterId
      }
    };
    if (actionType === "Sign")
      dispatch(signAssessmentAnswerAction({ payload }));
    else dispatch(signAndLockAssessmentAnswersAction({ payload }));
    cancel();
  }
};

const mapStateToProps = state => {
  return {
    isESignatureEnabled: isESignatureEnabledSelector(state),
    encounterSelectionMandatory: encounterMandatorySelectionSelector(state),
    encounterFieldsShown: encounterFieldsShownInSelectionUISelector(state)
  };
};

export default connect(mapStateToProps)(EncounterDropdownComponent);
