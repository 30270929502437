import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";

export const isWoundsClinicalChartOptimizationEnabledSelector = createSelector(
  createPolicyForInternalNameSelector(
    policyInternalNames.assessmentSummaryOptimizationsEnabled
  ),
  assessmentSummaryOptimizationsEnabled => {
    return (
      assessmentSummaryOptimizationsEnabled &&
      assessmentSummaryOptimizationsEnabled.attributes.policyValue
    );
  }
);
