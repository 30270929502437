import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash.get";

//Constants
import { loadStates } from "src/constants";

// Selectors
import { csvExportsResourceSelectors } from "src/selectors/data_selectors";

// Actions
import { csvExportsResourceActions } from "src/actions/data_actions";

// Pusher
import Subscriptions from "src/subscriptions";

/**
 * HOC that is responsible for loading a csv export
 * @param {Class} WrappedComponent the component dependent on this data
 * @return {Class} Component wrapped by the loader component
 */
export function withCsvExportsSubscriber(WrappedComponent) {
  class CsvExportSubscriber extends React.Component {
    static propTypes = {
      csvExportId: PropTypes.string.isRequired,
      csvExport: PropTypes.object.isRequired,
      setLoadStateOfCsvExportToReload: PropTypes.func.isRequired
    };

    componentWillMount() {
      this.subscribeToCsvExportChanges();
    }

    componentWillUnmount() {
      this.unsubscribeFromCsvExportChanges();
    }

    onCsvExportChange = () => {
      const { setLoadStateOfCsvExportToReload, csvExportId } = this.props;
      setLoadStateOfCsvExportToReload(csvExportId);
    };

    /**
     * Subscribe to csv export channel to determine if url is ready
     * @param {object} csvExport
     */
    subscribeToCsvExportChanges() {
      const { csvExport } = this.props;
      const fileName = get(csvExport, "attributes.fileName", "");
      Subscriptions.subscribeToResourceChannelEvent({
        channelName: Subscriptions.resourceIdChannelNames.csv,
        resourceId: `${csvExport.id}--${fileName}`,
        eventName: Subscriptions.channelEvents.change,
        callback: this.onCsvExportChange
      });
    }

    /**
     * Unsubscribe to csv export channel
     * @param {object} csvExport
     */
    unsubscribeFromCsvExportChanges() {
      const { csvExport } = this.props;
      const fileName = get(csvExport, "attributes.fileName", "");
      Subscriptions.unsubscribeFromResourceChannelEvent({
        channelName: Subscriptions.resourceIdChannelNames.csv,
        resourceId: `${csvExport.id}--${fileName}`,
        eventName: Subscriptions.channelEvents.change,
        callback: this.onCsvExportChange
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  /**
   * Map the props needed for loading data
   */
  function mapStateToProps(state, ownProps) {
    const { csvExportId } = ownProps;
    return {
      csvExportId,
      csvExport: csvExportsResourceSelectors.createDataForIdSelector(
        csvExportId
      )(state)
    };
  }

  /**
   * Map dispatch to a props for loading data
   */
  function mapDispatchToProps(dispatch) {
    return {
      setLoadStateOfCsvExportToReload(csvExportId) {
        dispatch(
          csvExportsResourceActions.setLoadStateForContextAction(
            csvExportId,
            loadStates.reload
          )
        );
      }
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(CsvExportSubscriber);
}
