import React from "react";

export class AdminIconComponent extends React.Component {
  render() {
    return (
      <svg
        fill="#1A1A1A"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <path d="M13,86h12l5-4v-6h6v-6h6v-6h6l7.4-7.8c8.5,3.9,18.8,2.4,25.8-4.6c8.9-8.9,8.9-23.4,0-32.4c-8.9-8.9-23.4-8.9-32.4,0 c-6.6,6.6-8.3,16.3-5.1,24.5L13,75V86z M64.6,27.6c2.3-2.3,6-2.3,8.3,0s2.3,6,0,8.3s-6,2.3-8.3,0C62.3,33.6,62.3,29.9,64.6,27.6z" />
      </svg>
    );
  }
}
