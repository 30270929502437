export function phoneNormalizer(value) {
  if (!value) {
    return value;
  }

  if (value.includes("+1")) {
    value = value.substring(3);
  }

  let onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.length <= 3) {
    return "+1-" + onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `+1-${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `+1-${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
}
