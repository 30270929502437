import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { analyticsEventNames } from "src/constants";

// Actions
import {
  loadAssessmentAnswersAction,
  loadAssessmentsAction
} from "src/actions/data_actions";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc";

// Components
import { RevisionsListItemComponent } from "src/components/revisions_list_item_component";

// Selectors
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";
import {
  assessmentAnswersResourceSelectors,
  assessmentsResourceSelectors,
  createLatestAssessmentAnswerForRevisionSelector,
  createProgressTabSchemasForAssessmentAnswerSelector,
  createTreatmentTabSchemasForAssessmentAnswerSelector,
  createAttributesTabSchemasForAssessmentAnswerSelector,
  createDescriptionAssessmentSchemaForAssessmentAnswerSelector
} from "src/selectors/data_selectors";
import { createRevisionIsStruckOutSelector } from "src/selectors/data_selectors/revisions/create_revision_is_struck_out_selector";
import { createNestedAssessmentSchemasForAssessmentAnswerSelector } from "src/selectors/data_selectors";
import {
  showLockWoundRevisionButtonSelector,
  showSignWoundRevisionButtonSelector,
  isESignatureEnabledSelector,
  hasLockWoundRevisionsPermissionSelector,
  hasUnlockWoundRevisionsPermissionSelector,
  hasSignWoundRevisionPermissionSelector,
  hasAllowEditOtherUsersWorkPermissionSelector,
  hasReopenWoundRevisionsPermissionSelector
} from "src/selectors/ui_config_selectors/patients";
import {
  lockAssessmentAnswerAction,
  unlockAssessmentAnswerAction,
  signAssessmentAnswerAction,
  signAndLockAssessmentAnswersAction,
  reopenAssessmentAnswerAction
} from "src/actions/data_actions";
import { createLatestAssessmentAnswerForRevisionQuerySelector } from "src/selectors/api_selectors/global/assessment_answers/create_latest_assessment_answer_for_revision_query_selector";
import { createAllAssessmentsWithSchemasForRevisionQuerySelector } from "src/selectors/api_selectors/global/assessments";
import { createRevisionIsFirstInSeriesSelector } from "src/selectors/data_selectors/revisions/create_revision_is_first_in_series_selector";
import { encounterNamingConventionSelector } from "../../selectors/ui_config_selectors/global/encounter_naming_convention_selector";
import { createSignTooltipSelector } from "src/selectors/ui_config_selectors/patients/create_sign_tooltip_selector";
import { createSignAndLockTooltipSelector } from "src/selectors/ui_config_selectors/patients/create_sign_and_lock_tooltip_selector";

function mapStateToProps(state, ownProps) {
  const assessmentAnswer = createLatestAssessmentAnswerForRevisionSelector(
    ownProps.revision
  )(state);

  const progressSchemas = createProgressTabSchemasForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const treatmentSchemas = createTreatmentTabSchemasForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const attributesSchemas = createAttributesTabSchemasForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const descriptionSchemas = createDescriptionAssessmentSchemaForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const schemas = descriptionSchemas.concat(
    attributesSchemas,
    treatmentSchemas,
    progressSchemas
  );

  return {
    assessmentAnswer,
    isStruckOut: createRevisionIsStruckOutSelector(ownProps.revision)(state),
    putLoadState: assessmentAnswersResourceSelectors.putLoadStateSelector(
      state
    ),
    deleteLoadStateWithStatusById: assessmentAnswersResourceSelectors.deleteLoadStateWithStatusByIdSelector(
      state
    ),
    schemas,
    showLockWoundRevisionButton: showLockWoundRevisionButtonSelector(state),
    showSignWoundRevisionButton: showSignWoundRevisionButtonSelector(state),
    canLockWoundRevisions: hasLockWoundRevisionsPermissionSelector(state),
    canSignWoundRevisions: hasSignWoundRevisionPermissionSelector(state),
    canReopenWoundRevisions: hasReopenWoundRevisionsPermissionSelector(state),
    canUnlockWoundRevisions: hasUnlockWoundRevisionsPermissionSelector(state),
    canEditOtherUsersWork: hasAllowEditOtherUsersWorkPermissionSelector(state),
    isESignatureEnabled: isESignatureEnabledSelector(state),
    currentUser: currentUserSelector(state),
    nestedAssessmentSchemas: createNestedAssessmentSchemasForAssessmentAnswerSelector(
      assessmentAnswer
    )(state),
    revisionIsFirstInSeries: createRevisionIsFirstInSeriesSelector(
      ownProps.revision
    )(state),
    encounterNamingConvention: encounterNamingConventionSelector(state),
    signTooltipText: createSignTooltipSelector({
      ...ownProps,
      assessmentAnswer,
      schemas
    })(state),
    signAndLockTooltipText: createSignAndLockTooltipSelector({
      ...ownProps,
      assessmentAnswer,
      schemas
    })(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendLockAssessmentAnswerAction(payload) {
      return dispatch(lockAssessmentAnswerAction({ payload }));
    },
    sendUnlockAssessmentAnswerAction(assessmentAnswer) {
      return dispatch(
        unlockAssessmentAnswerAction({ resource: assessmentAnswer })
      );
    },
    sendSignAssessmentAnswerAction(payload) {
      return dispatch(signAssessmentAnswerAction({ payload }));
    },
    sendSignAndLockAssessmentAnswersAction(payload) {
      return dispatch(signAndLockAssessmentAnswersAction({ payload }));
    },
    sendReopenAssessmentAnswerAction(assessmentAnswer) {
      return dispatch(
        reopenAssessmentAnswerAction({ resource: assessmentAnswer })
      );
    }
  };
}

export const RevisionsListItemContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentAnswersAction,
    resourceSelectors: assessmentAnswersResourceSelectors,
    createQuerySelector: createLatestAssessmentAnswerForRevisionQuerySelector,
    createContextSelector: createLatestAssessmentAnswerForRevisionQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentsAction,
    resourceSelectors: assessmentsResourceSelectors,
    createQuerySelector: createAllAssessmentsWithSchemasForRevisionQuerySelector,
    createContextSelector: createAllAssessmentsWithSchemasForRevisionQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName:
      analyticsEventNames.renderRevisionAssessmentAnswersComponent
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(RevisionsListItemComponent);
