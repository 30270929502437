import { createSelector } from "reselect";

// Selectors
import { createFirstPageRevisionComparisonQuerySelector } from "..";

/**
 * create a selector to get the api context for the first page for revision comparison
 * @param {Object} props
 * @param {string} props.seriesId
 * @returns {function} selector
 */
export function createFirstPageRevisionComparisonContextSelector({ seriesId }) {
  return createSelector(
    createFirstPageRevisionComparisonQuerySelector({ seriesId }),
    query => {
      return query;
    }
  );
}
