import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { studiesResourceSelectors } from "../studies_resource_selectors";
import { createSeriesForRevisionSelector } from "../../series/create_series_for_revision_selector";

/**
 * create a selector with a revision that returns a study
 * @param {Object} props
 * @param {array} revision
 * @returns {Function} selector
 */

export function createStudyForRevisionSelector(revision) {
  return createSelector(
    studiesResourceSelectors.dataSelector,
    createSeriesForRevisionSelector(revision),
    (studies, series) => {
      const studyId = get(series, "attributes.studyId");
      const study = studies[studyId];
      return study;
    }
  );
}
