import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import moment from "moment";
import zip from "lodash.zip";
import zipObject from "lodash.zipobject";

// Components
import { GridRow, GridColumn } from "src/components/grid";
import { StudyBadgeComponent } from "src/components/study_badge_component";
import { Glyph } from "src/ui-kit";

//Containers
import { AssessmentAnswerGroupEventHistoryContainer } from "src/containers/event_history_container";

// Model
import { getScoreValue } from "src/models/assessment_answers";
import { getPatientQrCodeDataURL } from "src/models/patients";

// Styles
import styles from "./style.module.less";

// Utils
import {
  getImageDataUrlFromLocalPath,
  renderChartToImageDataUrl
} from "src/utils/image_data_url";

// Tracking
import { trackError } from "src/error_tracking";

// Pdf
import { createNonWoundAssessmentPdf } from "src/pdfs";
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import { pdfTableStyle } from "src/pdfs";
pdfMake.vfs = vfsFonts.pdfMake.vfs;
pdfMake.tableLayouts = { pdfTableStyle };

export class NonWoundAssessmentSummaryComponent extends React.PureComponent {
  static propTypes = {
    assessmentAnswer: PropTypes.object.isRequired,
    assessmentSchema: PropTypes.object.isRequired,
    assessmentAnswerGroup: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    pdfDownloadEnabled: PropTypes.bool.isRequired,
    patientLastFacility: PropTypes.object,
    branding: PropTypes.string.isRequired,
    brandedLogoPng: PropTypes.object.isRequired
  };

  state = {
    pdfDisabled: false
  };

  /**
   * TODO: refactor this
   * Get the closure chart image data url
   * @returns {Promise}
   */
  getClosureChartUrl() {
    const { assessmentSchema, patientTrend } = this.props;
    const tileName = get(
      { assessmentSchema },
      "assessmentSchema.attributes.schemaJson.localizationDictionary.base.tileName",
      ""
    );
    let chartData;
    if (tileName == "Braden") {
      chartData = getChartObjectForPdf(
        patientTrend.bradenTrend,
        "Braden History",
        {}
      );
      return renderChartToImageDataUrl(chartData, 600, 450);
    } else if (tileName == "Norton Plus") {
      chartData = getChartObjectForPdf(
        patientTrend.nortonPlusTrend,
        "Norton Plus History",
        {}
      );
      return renderChartToImageDataUrl(chartData, 600, 450);
    }
    return undefined;
  }

  /**
   * Handler for printing the pdf
   */
  handlePrintViewButtonClick = () => {
    const { assessmentAnswer, patient, branding, brandedLogoPng } = this.props;

    if (this.state.creatingPdf) {
      return;
    }

    this.setState({ pdfDisabled: true });

    const logo = brandedLogoPng;

    Promise.all([
      getImageDataUrlFromLocalPath(logo),
      getPatientQrCodeDataURL(patient)
    ])
      .then(([logoDataUrl, qrcodeUrl]) => {
        let images = {
          logo: logoDataUrl,
          qrCode: qrcodeUrl,
          bradenChart: this.getClosureChartUrl()
        };
        return createNonWoundAssessmentPdf(
          {
            ...this.props,
            latestSignedEvent: this.props.latestSignedEvent.length
              ? this.props.latestSignedEvent[0]
              : undefined,
            latestLockedEvent: this.props.latestLockedEvent.length
              ? this.props.latestLockedEvent[0]
              : undefined
          },
          images,
          { branding: branding }
        );
      })
      .then(pdfDocDefinition => {
        const date = new moment(assessmentAnswer.attributes.createdAt).format(
          "YYYY-MM-DD--HH-mm"
        );

        const assessmentType = get(
            assessmentAnswer,
            "attributes.answersJson.header.title",
            "non_wound_assessment"
          ),
          patientLastName = get({ patient }, "patient.attributes.lastName", ""),
          patientFirstName = get(
            { patient },
            "patient.attributes.firstName",
            ""
          );

        pdfMake
          .createPdf(pdfDocDefinition)
          .download(
            `${patientLastName}_${patientFirstName}_${assessmentType.replace(
              " ",
              "_"
            )}_${date}.pdf`
          );
        this.setState({ pdfDisabled: false });
      })
      .catch(err => {
        this.setState({ pdfDisabled: false });
        this.props.showPdfErrorView();
        trackError(err, {
          extra: { error_context: "non_wound_pdf" }
        });
      });
  };

  render() {
    const {
      assessmentAnswer,
      assessmentSchema,
      assessmentAnswerGroup,
      pdfDownloadEnabled
    } = this.props;
    const locked = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.lockState",
      ""
    );
    const isLocked = locked === "locked";

    const badgeTitle = get(
      { assessmentSchema },
      "assessmentSchema.attributes.schemaJson.localizationDictionary.base.tileName",
      ""
    );

    return (
      <GridRow>
        <GridColumn lg="8">
          <AssessmentAnswerGroupEventHistoryContainer
            assessmentAnswerGroupId={assessmentAnswerGroup.id}
          />
        </GridColumn>
        <GridColumn lg="4">
          <div className={styles["display-flex-column"]}>
            <div className={styles["display-flex-row"]}>
              <div className={styles["lock-icon-css"]}>
                {isLocked && <Glyph name="lock" />}
              </div>
              <div className={styles["non-wound-study-badge-container"]}>
                <StudyBadgeComponent
                  title={badgeTitle}
                  value={getScoreValue(assessmentAnswer, assessmentSchema)}
                />
              </div>
            </div>
            {pdfDownloadEnabled && (
              <div className={styles["float-right"]}>
                <button
                  className={`${styles["pdf-button"]} dashboard-btn`}
                  type="button"
                  value="event_id"
                  disabled={this.state.pdfDisabled}
                  onClick={this.handlePrintViewButtonClick}
                >
                  Download PDF
                </button>
              </div>
            )}
          </div>
        </GridColumn>
      </GridRow>
    );
  }
}
function getChartObjectForPdf(
  valueDateModel,
  title,
  { beginAnywhere, noMax, max }
) {
  const { values, dates } = valueDateModel;
  const data = zip(dates.toArray(), values.toArray()).map(
    zipObject.bind(null, ["x", "y"])
  );

  return {
    type: "line",
    data: {
      datasets: [
        {
          label: "Treatments",
          fill: true,
          backgroundColor: "#e6efd5",
          strokeColor: "rgba(124,174,43,1)",
          pointColor: "rgba(124,174,43,1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(124,174,43,1)",
          data: data,
          pointRadius: 6,
          pointHoverRadius: 10,
          pointHitRadius: 30,
          pointBorderWidth: 2,
          borderColor: "rgba(124,174,43,1)"
        }
      ]
    },
    options: {
      animation: false,
      responsive: false,
      title: {
        display: true,
        text: title,
        fontSize: 40,
        padding: 10,
        fontColor: "#000000"
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              callback(label, index, labelsArray) {
                if (index === 0 || index === labelsArray.length - 1) {
                  return moment(label).format("MM/DD/YYYY");
                }
              },
              fontSize: 22,
              fontColor: "#000000"
            },
            gridLines: {
              color: "#000000"
            },
            time: {
              displayFormats: "MM/DD/YYYY"
            },
            type: "time",
            display: true,
            scaleLabel: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: !beginAnywhere,
              max: noMax ? undefined : max,
              fontSize: 22,
              fontColor: "#000000"
            },
            gridLines: {
              color: "#000000"
            },
            display: true,
            scaleLabel: {
              display: false
            }
          }
        ]
      }
    }
  };
}
