// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { eventsApi } from "src/api/events";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

export const eventsResourceActions = createResourceActions(
  resourceNames.events,
  eventsApi
);

export const loadEventsAction = createLoadDataAction({
  resourceName: resourceNames.events,
  includedResourceNames: [resourceNames.users],
  api: eventsApi
});
