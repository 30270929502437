export const surveyTableTooltips = {
  type: "Wound types/subtypes",
  woundResidents: "Number of residents with one or more wounds",
  IHA:
    "Number of $t({{age}}) Internal ($t(data.acquired.default.IHA)) wounds in the selected date range",
  IHAw:
    "Number of $t({{age}}) Internal wounds that have worsened ($t(data.acquired.default.IHAw)) in the selected date range",
  POA:
    "Number of $t({{age}}) External ($t(data.acquired.default.POA)) wounds in the selected date range",
  PIHAw:
    "Number of $t({{age}}) External wounds that have worsened internally ($t(data.acquired.default.IHAw)) in the selected date range",
  totalAge: "Number of ${age} active wounds in the selected date range",
  acquiredNotSet: "Acquired field was not set in wound assessment",
  total: "Number of active wounds in the selected date range",
  totalResolved:
    "Number of wounds that have resolved in the selected date range"
};
