import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { sections } from "src/constants";

// Component
import { CheckboxFilterComponent } from "src/components/filters";

// Actions
import { createSectionActions } from "src/ui_processors/global/action_creators";

//HOC
import { withSchemaFilterConfigurationWrapper } from "src/hoc/with_schema_filter_configuration_wrapper";

// State
function mapStateToProps() {
  return {
    selectAllEnabled: false
  };
}

// Dispatch Actions
function mapDispatchToProps(dispatch) {
  return {
    selectCheckbox: filterObject => {
      dispatch(
        createSectionActions(sections.woundRisk).selectCheckbox(filterObject)
      );
    },
    deselectCheckbox: filterObject => {
      dispatch(
        createSectionActions(sections.woundRisk).deselectCheckbox(filterObject)
      );
    }
  };
}

export const SideBarWoundTypesFilterContainerWoundRisk = flowRight(
  withSchemaFilterConfigurationWrapper,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckboxFilterComponent);
