import apiFetch from "app/util/apiFetch";
import moment from "moment";
import groupby from "lodash.groupby";

import calculateAggregates from "./calculateAggregates";

function bradenLevelTotal(data) {
  let veryHighData = [];
  let highData = [];
  let mediumData = [];
  let lowData = [];
  let noData = [];
  let noneData = [];
  let timeIntervals = [];

  if (!data) {
    return {
      timeIntervals,
      veryHighData,
      highData,
      mediumData,
      lowData,
      noData,
      noneData
    };
  }

  let dataGroupedByTimeInterval = groupby(data, "time_interval_start");

  for (let timeInterval in dataGroupedByTimeInterval) {
    let dataPoints = dataGroupedByTimeInterval[timeInterval];

    let formattedTime = moment(timeInterval).format("MMM D");

    timeIntervals.push(formattedTime);

    let {
      veryHighTotal,
      highTotal,
      mediumTotal,
      lowTotal,
      noTotal,
      noneTotal
    } = calculateAggregates(dataPoints);

    veryHighData.push(veryHighTotal);
    highData.push(highTotal);
    mediumData.push(mediumTotal);
    lowData.push(lowTotal);
    noData.push(noTotal);
    noneData.push(noneTotal);
  }

  return {
    timeIntervals,
    veryHighData,
    highData,
    mediumData,
    lowData,
    noData,
    noneData
  };
}

export default function loadchartRiskAggregates(
  locationIds,
  startTime,
  endTime
) {
  return dispatch => {
    const payload = {
      location_ids: locationIds.join(),
      start_time: startTime,
      end_time: endTime,
      time_step: "week"
    };

    return dispatch(
      apiFetch(`/api/v2/risk_dashboard/braden/clinical_chart`, {
        method: "POST",
        body: payload,
        headers: {
          Accept: "application/json",
          "X-HTTP-Method-Override": "GET"
        }
      })
    ).then(({ chart }) => {
      let chartData = bradenLevelTotal(chart);
      dispatch({
        type: "LOCATION_AGGREGATE_CHART_RISK_LOAD",
        chartData
      });
      return chartData;
    });
  };
}
