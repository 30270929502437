import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

// Components
import { GridRow, GridColumn } from "src/components/grid";
import { PanelComponent, PanelBodyComponent } from "src/components/panel";
import { SchemaAnswersComponent } from "src/components/schema_answers_component";
import { RevisionMeasurementComponent } from "src/components/revision_measurement_component";
import { RevisionEventHistoryContainer } from "src/containers/event_history_container";
import { TabViewComponent } from "src/components/tab_view_component";
import DescribeComponent from "src/components/describe_component/index";

//Translations
import i18n from "src/translations";

//Constants
import { hprScoreKeys } from "src/constants";

const tabs = [
  {
    value: "measurement",
    title: `${i18n.t("headers.measurement")}`
  },
  {
    value: "attributes",
    title: `${i18n.t("headers.characteristics")}`
  },
  {
    value: "treatment",
    title: `${i18n.t("headers.treatment")}`
  },
  {
    value: "progress",
    title: `${i18n.t("headers.progress")}`
  },
  {
    value: "history",
    title: `${i18n.t("headers.history")}`
  },
  {
    value: "describe",
    title: `${i18n.t("headers.describe")}`
  }
];

export class RevisionAssessmentAnswersComponent extends React.PureComponent {
  static propTypes = {
    revision: PropTypes.object.isRequired,
    assessmentAnswer: PropTypes.object.isRequired,
    progressSchemas: PropTypes.array.isRequired,
    treatmentSchemas: PropTypes.array.isRequired,
    attributesSchemas: PropTypes.array.isRequired,
    describeSchemas: PropTypes.array.isRequired,
    revisionIsFirstInSeries: PropTypes.bool.isRequired,
    assessmentLoadState: PropTypes.string,
    assessmentsContextLoadState: PropTypes.object,
    hprScoreEnabled: PropTypes.bool.isRequired,
    revisionComparisonTabSelected: PropTypes.bool.isRequired,
    bulkSelectedTab: PropTypes.bool.isRequired,
    sendSelectBulkTabAction: PropTypes.func.isRequired
  };

  state = {
    selectedTab: "measurement"
  };

  onTabSelected = tabValue => {
    const {
      revisionComparisonTabSelected,
      sendSelectBulkTabAction
    } = this.props;
    if (revisionComparisonTabSelected) {
      sendSelectBulkTabAction(tabValue);
    } else {
      this.setState({
        selectedTab: tabValue
      });
    }
  };

  getSelectedTabValue = () => {
    const { revisionComparisonTabSelected, bulkSelectedTab } = this.props;
    return revisionComparisonTabSelected
      ? bulkSelectedTab
      : this.state.selectedTab;
  };

  /**
   * Map function to map schemas to SchemaAnswersComponent
   * @param {Object} schema
   */
  mapSchemasToComponents = schema => {
    const { assessmentAnswer, revisionIsFirstInSeries } = this.props;
    const translations = get(
      { schema },
      "schema.attributes.schemaJson.localizationDictionary.base",
      null
    );

    return (
      <PanelComponent
        key={schema.id}
        labelShow={translations && translations.title}
      >
        <PanelBodyComponent>
          <SchemaAnswersComponent
            assessmentAnswer={assessmentAnswer}
            assessmentSchema={schema}
            revisionIsFirstInSeries={revisionIsFirstInSeries}
          />
        </PanelBodyComponent>
      </PanelComponent>
    );
  };

  renderScore(key) {
    const { assessmentAnswer } = this.props;
    const answersJson = get(assessmentAnswer, "attributes.answersJson", {});
    return (
      <div key={key}>
        <GridRow>
          <GridColumn sm="5">
            <b>{`${i18n.t(`data.hprScoreLabels.${key}`)}`}</b>
          </GridColumn>
          <GridColumn sm="7">
            <span>{answersJson[key] || "--"}</span>
          </GridColumn>
        </GridRow>
        <hr />
      </div>
    );
  }

  render() {
    const {
      revision,
      progressSchemas,
      treatmentSchemas,
      attributesSchemas,
      isAutodepth,
      hprScoreEnabled,
      ray1Enabled
    } = this.props;

    let tabContent;
    const selectedTabValue = this.getSelectedTabValue();
    switch (selectedTabValue) {
      case "progress":
        tabContent = progressSchemas.map(this.mapSchemasToComponents);
        if (hprScoreEnabled) {
          tabContent.push(
            <PanelComponent
              key={"hprScoreTable"}
              labelShow={"Healing Progression Rate"}
            >
              <PanelBodyComponent>
                {Object.values(hprScoreKeys).map(key => this.renderScore(key))}
              </PanelBodyComponent>
            </PanelComponent>
          );
        }

        break;

      case "treatment":
        tabContent = treatmentSchemas.map(this.mapSchemasToComponents);

        break;

      case "measurement":
        tabContent = (
          <RevisionMeasurementComponent
            revision={revision}
            isAutodepth={isAutodepth}
            ray1Enabled={ray1Enabled}
          />
        );
        break;

      case "attributes":
        tabContent = attributesSchemas.map(this.mapSchemasToComponents);
        break;

      case "history":
        tabContent = (
          <PanelComponent labelShow="History" enableCollapsible>
            <PanelBodyComponent>
              <RevisionEventHistoryContainer revisionId={revision.id} />
            </PanelBodyComponent>
          </PanelComponent>
        );
        break;

      case "describe":
        tabContent = (
          <PanelComponent labelShow="Description" enableCollapsible>
            <PanelBodyComponent>
              <DescribeComponent revision={revision} />
            </PanelBodyComponent>
          </PanelComponent>
        );
    }

    return (
      <TabViewComponent
        tabHeaderType="title"
        tabs={tabs}
        selectedTabValue={selectedTabValue}
        selectTab={this.onTabSelected}
      >
        <div
          className="revision-info"
          style={{ height: "100%", maxHeight: "620px", overflowY: "scroll" }}
        >
          {tabContent}
        </div>
      </TabViewComponent>
    );
  }
}
