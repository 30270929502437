import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import patientDefaultAvatar from "src/assets/patientDefaultAvatar.png";
import styles from "./style.module.less";

PatientCardComponent.propTypes = {
  patient: PropTypes.object.isRequired,
  patientLinksEnabled: PropTypes.bool.isRequired,
  patientLinkTemplate: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired
};
/**
 * Component that renders a card displaying patient information
 */
export function PatientCardComponent({
  patient,
  patientLinksEnabled,
  patientLinkTemplate,
  partnerName
}) {
  function renderPatientName() {
    let patientName;
    if (patientLinksEnabled) {
      // Create link
      const link = patientLinkTemplate
        .replace("{admission_id}", patient.admissionId)
        .replace("{patient_id}", patient.id);

      // Set target
      if (partnerName === "pcc") {
        patientName = (
          <a href={link} target="_parent">
            {!patient.firstName && !patient.lastName
              ? "Anonymous"
              : `${patient.firstName} ${patient.lastName}`}
          </a>
        );
      } else {
        patientName = (
          <Link to={link}>
            {!patient.firstName && !patient.lastName
              ? "Anonymous"
              : `${patient.firstName} ${patient.lastName}`}
          </Link>
        );
      }
    } else {
      patientName = `${patient.firstName} ${patient.lastName}`;
    }

    return <h4 className={styles["media-heading"]}>{patientName}</h4>;
  }

  function renderPatientRoom() {
    return patient.facilityRoom && <p>Room: {patient.facilityRoom}</p>;
  }

  function renderPatientBed() {
    return patient.facilityRoomBed && <p>Bed: {patient.facilityRoomBed}</p>;
  }

  function renderPatientMrn() {
    return patient.mrn && <p>MRN: {patient.mrn}</p>;
  }

  function renderPatientAvatar() {
    if (patient.avatarImageUrl) {
      return (
        <div
          className={styles["avatar"]}
          style={{
            backgroundImage: `url('${patient.avatarImageUrl}')`
          }}
        />
      );
    } else {
      return (
        <div
          className={styles["avatar"]}
          style={{
            backgroundImage: `url('${patientDefaultAvatar}')`
          }}
        />
      );
    }
  }

  return (
    <div className={styles["patient-card"]}>
      <div className="media-left">{renderPatientAvatar()}</div>
      <div className="media-body">
        {renderPatientName()}
        {renderPatientMrn()}
        {renderPatientRoom()}
        {renderPatientBed()}
      </div>
    </div>
  );
}
