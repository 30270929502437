import axios from "axios";
import { registerForCancellation } from "../cancellation";

/**
 * Fetch Incidence Chart
 * @param {Object} payload the query parameters
 * start_time
 * end_time
 * wound_types
 * location_ids
 * @return {Promise} Promise with the response
 */
export function fetchIncidenceChartData(payload) {
  const location_ids = payload.location_ids.join();
  const wound_types = payload.wound_types.join();

  payload = {
    ...payload,
    wound_types,
    location_ids
  };

  const config = {
    cancelToken: registerForCancellation(`etiology/incidence_chart`),
    headers: {
      "X-HTTP-Method-Override": "GET"
    }
  };
  return axios.post("/api/v2/etiology/incidence_chart", payload, config);
}

/**
 * Fetch Prevalence Chart
 * @param {Object} payload the query parameters
 * start_time
 * end_time
 * wound_types
 * location_ids
 * @return {Promise} Promise with the response
 */
export function fetchPrevalenceChartData(payload) {
  const location_ids = payload.location_ids.join();
  const wound_types = payload.wound_types.join();

  payload = {
    ...payload,
    wound_types,
    location_ids
  };

  const config = {
    cancelToken: registerForCancellation(`etiology/prevalence_chart`),
    headers: {
      "X-HTTP-Method-Override": "GET"
    }
  };
  return axios.post("/api/v2/etiology/prevalence_chart", payload, config);
}
