import { createSelector } from "reselect";
import { dataSelector } from "src/selectors/data_selectors/data_selector";

/**
 * Selector for the currentUser object in the data tree in the store
 */
export const currentUserSelector = createSelector(
  dataSelector,
  data => data.currentUser
);
