import { createSelector } from "reselect";
import { locationsToDisplaySelector } from "src/selectors/data_selectors/locations";
import { selectedLocationIdsSelector } from "../selected_location_ids_selector";

/**
 * Locations selector for the risk tab - gets selected locations
 * (or all if none selected)
 */
export const selectedLocationsSelector = createSelector(
  locationsToDisplaySelector,
  selectedLocationIdsSelector,
  (locationsToDisplay, selectedLocationIds) => {
    if (selectedLocationIds.length) {
      return locationsToDisplay.filter(loc => {
        return selectedLocationIds.includes(loc.id);
      });
    }

    return locationsToDisplay;
  }
);
