import { combineReducers } from "redux";

//Section Reducer Factory
import {
  createSectionFiltersReducer,
  createSectionSelectedFilterIdReducer
} from "src/ui_processors/global/reducer_creators";

// Constants
import { sections } from "src/constants";

export const riskSectionReducers = combineReducers({
  filters: createSectionFiltersReducer(sections.risk),
  selectedFilterId: createSectionSelectedFilterIdReducer(sections.risk)
});
