import { createSelector } from "reselect";
import { seriesResourceSelectors } from "../series_resource_selectors";

/**
 * Create a selector to find a series by id
 * @param {string} seriesId
 * @returns {Function} selector
 */
export function createSeriesForIdSelector(seriesId) {
  return createSelector(
    seriesResourceSelectors.dataSelector,
    series => {
      return series[seriesId];
    }
  );
}
