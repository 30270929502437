// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { unitsApi } from "src/api/units";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

export const unitsResourceActions = createResourceActions(resourceNames.units);

export const loadUnitsAction = createLoadDataAction({
  resourceName: resourceNames.units,
  api: unitsApi
});
