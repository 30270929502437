import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Selectors
import {
  seriesResourceSelectors,
  createSeriesForIdSelector
} from "src/selectors/data_selectors";
import { visibleEvaluationStatesPermissionSelector } from "src/selectors/config_selectors/visible_evaluation_states_permission";

// Actions
import { loadSeriesForSeriesIdAction } from "src/actions/data_actions";

/**
 * HOC to load a single series
 * @param {Class} WrappedComponent
 * @returns {Class}
 */
export function withSeriesLoader(WrappedComponent) {
  class SeriesLoader extends React.Component {
    static propTypes = {
      seriesLoadState: PropTypes.string,
      series: PropTypes.object,
      match: PropTypes.object.isRequired,
      sendLoadSeriesForSeriesIdAction: PropTypes.func.isRequired,
      assessmentStateFilters: PropTypes.object
    };

    componentWillMount() {
      const seriesId = this.props.match.params.seriesId;
      const lockState = this.props.assessmentStateFilters.lockState;

      if (!this.props.series && !this.props.seriesLoadState) {
        this.props.sendLoadSeriesForSeriesIdAction(seriesId, lockState);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  /**
   * Map the props needed for loading data
   */
  function mapStateToProps(state, props) {
    const seriesId = props.match.params.seriesId;

    return {
      seriesLoadState: seriesResourceSelectors.createContextLoadStateSelectorWithContextString(
        seriesId
      )(state),
      series: createSeriesForIdSelector(seriesId)(state),
      assessmentStateFilters: visibleEvaluationStatesPermissionSelector(state)
    };
  }

  /**
   * Map dispatch to a props for loading data
   */
  function mapDispatchToProps(dispatch) {
    return {
      sendLoadSeriesForSeriesIdAction(seriesId, lockState) {
        dispatch(loadSeriesForSeriesIdAction(seriesId, lockState));
      }
    };
  }

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(SeriesLoader));
}
