// @flow
import { batchActions } from "redux-batched-actions";
import { normalize } from "./normalize";
import { sendNormalizedToStore } from "./sendNormalizedToStore";
import type { $singleSchema, $schema } from "../schemaUtils";
import schemas from "../..//schemas";
import propertySchemas from "../..//schemas/propertySchemas";

export const indexRespToStore = function(
  name: string,
  inputs: Array<Object>,
  baseSchema?: $singleSchema,
  schema?: $schema = schemas,
  options?: Object
) {
  return batchActions(
    inputs.map(input => respToStore(name, input, baseSchema, schema, options))
  );
};

export const respToStore = function(
  name: string,
  input: Object,
  baseSchema?: $singleSchema,
  schema?: $schema = schemas,
  options?: Object
) {
  return sendNormalizedToStore(
    normalize(input, name, {
      [name]: baseSchema || schema[name],
      ...schema
    }),
    options
  );
};

export const propertyRespToStore = function(
  propertyName: string,
  payload: Object,
  baseSchema?: $singleSchema,
  schema?: $schema,
  options?: Object
) {
  return respToStore(
    "properties",
    { ...payload, id: propertyName },
    baseSchema || propertySchemas[propertyName],
    schema,
    options
  );
};
