import mixpanel from "mixpanel-browser";
import pkg from "../../../package.json";

let constantEventParams = {
  appId: "dashboard",
  hostname: window.location.hostname,
  version: pkg.version
};

let timeTrackedEvents = {};

// List of events we should not send
const excludeEvents = {
  CHANGE_PAGE: true
};

const mixpanelToken = () => {
  return window.MIXPANEL_TOKEN || process.env.REACT_APP_MIXPANEL_TOKEN;
};

const mixpanelApiHost = () => {
  return (
    window.MIXPANEL_PROXY_HOSTNAME ||
    process.env.REACT_APP_MIXPANEL_PROXY_HOSTNAME
  );
};

const mixpanelEnabled = () => {
  return !(window.MIXPANEL_ENABLED && window.MIXPANEL_ENABLED === "disabled");
};

let isMixpanelInitialized = false;

const initializeMixpanel = () => {
  if (isMixpanelInitialized) return;

  if (mixpanelEnabled() && mixpanelToken()) {
    // initialize mixpanel
    mixpanel.init(mixpanelToken(), {
      opt_out_tracking_by_default: true,
      api_host: mixpanelApiHost()
    });

    // register fields to send with all events
    mixpanel.register(constantEventParams);

    isMixpanelInitialized = true;
  }
};

const mixpanelReady = () => {
  if (isMixpanelInitialized) {
    return mixpanelEnabled() && mixpanelToken();
  } else {
    initializeMixpanel();
  }
};

/**
 * Register values to send with each event
 * @param {Object} params the paramets to send with every event
 */
export function registerParamsForAllEvents(params) {
  if (mixpanelReady()) {
    constantEventParams = {
      ...constantEventParams,
      ...params
    };

    mixpanel.register(constantEventParams);
  }
}

/**
 * send information to analytics destination
 * @param  {string} name the event name
 * @param  {Object} payload the event payload
 * @param  {Function} callback the event callback
 */
export function track(name, payload, callback) {
  if (mixpanelReady() && !excludeEvents[name]) {
    return mixpanel.track(name, payload, callback);
  }
}

/**
 * Start tracking time for an event name
 * @param {string} name the event name
 */
export function startTimeTrack(name) {
  if (mixpanelReady() && !timeTrackedEvents[name]) {
    timeTrackedEvents[name] = true;

    // Register this event name to record the duration until the next track call
    mixpanel.time_event(name);
  }
}

/**
 * If the event 'name' is being time tracked then send a track event
 * This event will include duration.
 * @param  {string} name the event name
 * @param  {Object} payload the event payload
 * @param  {Function} callback the event callback
 */
export function finishTimeTrack(name, payload, callback) {
  if (mixpanelReady() && timeTrackedEvents[name]) {
    timeTrackedEvents[name] = false;
    mixpanel.track(name, payload, callback);
  }
}

/**
 * Initialize analytics with current user
 * @param  {Object} user
 */
export function userInit(user) {
  if (mixpanelReady()) {
    // Set the properties to send to mixpanel
    // * Note user model from session endpoint is different than the users endpoint. Cover both cases until session user model is deprecated
    const userID = user.id;
    const facilityID = user.attributes
      ? user.attributes.facilityId
      : user.facility_id;
    const organizationID = user.attributes
      ? user.attributes.organizationId
      : user.organization_id;
    const partnerID = user.attributes
      ? user.attributes.partnerId
      : user.partner_id;
    const signInCount = user.attributes
      ? user.attributes.signInCount
      : undefined;

    // Register user for mixpanel
    mixpanel.identify(userID);
    mixpanel.people.set({
      partnerID,
      organizationID,
      facilityID,
      signInCount
    });
    mixpanel.opt_in_tracking();

    // add facilityID to each event payload
    registerParamsForAllEvents({
      facilityID,
      organizationID: organizationID ? organizationID : undefined
    });
  }
}

/**
 * Reset analytics with current user
 */
export function resetMixpanel() {
  mixpanel.reset();
}
