export const surveyActionTypes = {
  selectLocations: "SURVEY_SELECT_LOCATIONS",
  deselectLocations: "SURVEY_DESELECT_LOCATIONS",

  selectWoundOptions: "SURVEY_WOUNDS_SELECT_OPTIONS",
  deselectWoundOptions: "SURVEY_WOUNDS_DESELECT_OPTIONS",

  setExcludeNewWounds: "SURVEY_SET_EXCLUDE_NEW_WOUNDS",

  setDateSelection: "SURVEY_SET_DATE_SELECTION",
  setRangeSelection: "SURVEY_SET_RANGE_SELECTION",
  setIncludeDischarged: "SURVEY_SET_INCLUDE_DISCHARGED"
};
