import { woundsActionTypes } from "src/action_types/section_action_types";

/**
 * Action creator used to update the selected date for wounds tab
 * @param {Moment} date the selected date
 * @return {Object} action creator
 */
export function selectDateAction(date) {
  return {
    type: woundsActionTypes.selectDate,
    payload: date
  };
}
