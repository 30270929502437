import PropTypes from "prop-types";
import React from "react";
import i18n from "src/translations";
// router
import { NavLink } from "react-router-dom";

import signIn from "./signIn";

import DefaultSignInComponent from "src/components/sign_in/default_sign_in_component";
import MedlineSignInComponent from "src/components/sign_in/medline_sign_in_component";

import removeFlashMessage from "app/flash_messages/remove";

import { push } from "react-router-redux";

import "./style.less";

export class SignInComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    branding: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
    organization: PropTypes.object,
    policies: PropTypes.object,
    //SignInComponent should be aware of organization and policies once they are loaded to check
    //for which page to land on upon sign in, and what tabs to show on the sidebar navigation list
    defaultNavigationPage: PropTypes.string.isRequired
  };

  componentDidUpdate() {
    const {
      dispatch,
      currentUser,
      organization,
      policies,
      defaultNavigationPage
    } = this.props;

    if (
      currentUser &&
      Object.keys(organization).length !== 0 &&
      Object.keys(policies).length !== 0
    ) {
      dispatch(removeFlashMessage("Signing in...", "info"));
      dispatch(
        removeFlashMessage(
          i18n.t(`interaction.errorMessages.loginError.invalidEntry`),
          "error"
        )
      );
      dispatch(push(defaultNavigationPage));
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    const { dispatch } = this.props;

    let formData = new window.FormData(event.target);

    dispatch(signIn(formData));
  };

  partnerSignIn = () => {
    const { branding } = this.props;
    if (branding != "medline") {
      return (
        <div>
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-sm-offset-4 text-center">
              <div className="hr-sect">or</div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-sm-offset-4 text-center">
              <NavLink
                to="/user-auth-code"
                className={`btn btn-default default`}
                activeClassName={`btn dashboard-btn activeClass`}
              >
                Sign In With Partner
              </NavLink>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  render = () => {
    const { branding } = this.props;

    const form = (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-sm-4 col-sm-offset-4">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="signInEmail"
                name="email"
                placeholder="Email"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-4 col-sm-offset-4">
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="signInPassword"
                name="password"
                placeholder="Password"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-4 col-sm-offset-4 text-center">
            <button className="btn btn-default">
              {i18n.t("actions.signIn")}
            </button>
          </div>
        </div>
        <div className="row margin-top10px">
          <div className="col-xs-12 col-sm-4 col-sm-offset-4 text-center">
            <NavLink className="nav-link" to="/reset-password">
              Forgot my password
            </NavLink>
          </div>
        </div>
        {this.partnerSignIn()}
      </form>
    );

    let brandedForm;

    switch (branding) {
      case "medline":
        brandedForm = <MedlineSignInComponent>{form}</MedlineSignInComponent>;
        break;
      case "default":
      default:
        brandedForm = <DefaultSignInComponent>{form}</DefaultSignInComponent>;
    }

    return brandedForm;
  };
}
