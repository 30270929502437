import get from "lodash.get";

import { acquiredValues } from "src/constants";
import i18n from "src/translations";

/**
 * Create a chart.js chart definition object for rendering a line chart showing
 * @param {Object} totalTally
 * @returns {Object} chart.js definition
 */
export function createWoundSurveyBarChart(totalTally) {
  const xLabels = acquiredValues.map(acq =>
    i18n.t(`data.acquired.default.${acq}`)
  );
  const newData = [
    get(totalTally, "attributes.newPoaCount", 0),
    get(totalTally, "attributes.newIhaCount", 0),
    get(totalTally, "attributes.newIhawCount", 0),
    get(totalTally, "attributes.newPihawCount", 0)
  ];
  const existingData = [
    get(totalTally, "attributes.existingPoaCount", 0),
    get(totalTally, "attributes.existingIhaCount", 0),
    get(totalTally, "attributes.existingIhawCount", 0),
    get(totalTally, "attributes.existingPihawCount", 0)
  ];
  // Labels
  const labels = xLabels;
  // datasets
  const datasets = [
    {
      label: "New",
      data: newData,
      backgroundColor: "#ba404a"
    },
    {
      label: "Existing",
      data: existingData,
      backgroundColor: "#9d9594"
    }
  ];

  const options = {
    responsive: false,
    legend: {
      position: "top"
    },
    scales: {
      xAxes: [
        {
          stacked: true
        }
      ],

      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback: value => {
              if (Math.floor(value) === value) {
                return value;
              }
            }
          },
          scaleLabel: {
            display: true,
            labelString: "Number of Wounds"
          }
        }
      ]
    }
  };

  return {
    type: "bar",
    data: {
      labels,
      datasets
    },
    options
  };
}
