import React from "react";
import { Link } from "react-router-dom";
import get from "lodash.get";

export function patientFirstNameFormatter(cell, row) {
  let firstName, patientId;
  if (row.type === "patients") {
    firstName = get(row, "attributes.firstName");
    patientId = row.id;
  } else {
    firstName = get(row, "attributes.patientFirstName");
    patientId = get(row, "attributes.patientId");
  }

  if (!firstName) return "";

  if (patientId) {
    return <Link to={`/patients/${patientId}`}>{firstName}</Link>;
  } else {
    return firstName;
  }
}
