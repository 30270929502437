// Mappings
import moment from "moment";
import i18n from "src/translations";

export function getBody(globalContent, images) {
  const { counts, progressValues, assessmentVersion } = globalContent;
  let row = progressValues.map(progressValue => {
    const count = counts[progressValue] || 0;
    let title = i18n.t(
      `data.overallProgress.${assessmentVersion}.${progressValue}`
    );
    if (progressValue !== "monitoring" && progressValue !== "unknown") {
      title += " Wounds";
    }
    return {
      stack: [
        {
          text: count,
          fontSize: 26,
          alignment: "center"
        },
        {
          text: title,
          fontSize: 12,
          alignment: "center"
        }
      ],
      border: [false, false, false, false],
      margin: [0, 20, 16, 0]
    };
  });

  let body = [
    {
      stack: [
        {
          image: images.woundsChart,
          width: 700,
          height: 250
        }
      ],
      margin: [50, 50, 0, 0]
    },
    {
      table: {
        headerRows: 1,
        widths: [740],
        body: [
          [
            {
              text: `ACTIVE WOUND NUMBERS AS OF ${moment(
                globalContent.selectedDate
              )
                .format("MMMM DD YYYY")
                .toUpperCase()}`,
              border: [false, false, false, true],
              bold: true,
              style: "subSectionHeader"
            }
          ]
        ]
      },
      marginLeft: 20,
      layout: {
        hLineColor: function() {
          return "#ddd";
        }
      }
    },
    {
      table: {
        headerRows: 1,
        body: [row]
      },
      marginLeft: 20
    }
  ];
  return [
    {
      stack: body
    }
  ];
}
