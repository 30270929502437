import { adminActionTypes } from "src/action_types/section_action_types";
import union from "lodash.union";
import without from "lodash.without";

export function checkedOrganizationIdsReducer(state = [], action) {
  switch (action.type) {
    case adminActionTypes.selectOrganizations:
      return union(state, action.payload);

    case adminActionTypes.deselectOrganizations:
      return without(state, ...action.payload);

    case adminActionTypes.selectedPartner:
      return [];
    default:
      return state;
  }
}
