import cloneDeep from "lodash.clonedeep";

// Action Types
import { createActionTypes } from "../../create_action_types";

// Utility function
import { mergeResources } from "./merge_resources";

/**
 * Create a reducer for the data piece of this resource
 * @param {string} name - the resource name
 */
export function createDataReducer(name) {
  const actionTypes = createActionTypes(name);

  return (state = {}, action) => {
    switch (action.type) {
      case actionTypes.populate:
        return mergeResources(state, action.payload.data);
      case actionTypes.removeById:
        const newState = cloneDeep(state);
        delete newState[action.payload.id];
        return newState;
      case actionTypes.invalidateAll:
        return {};
      default:
        return state;
    }
  };
}
