// @flow
import { Record, Map } from "immutable";
import LocationModel from "src/models/propertyModels/LocationModel";
import RiskModel from "src/models/propertyModels/RiskModel";
import { entityReducer } from "../../utils/reducerUtils";
import { LOCATIONS, PATIENTS, RISKS } from "./constants";

const mapOfPropertyModels = {
  [LOCATIONS]: new LocationModel(),
  [PATIENTS]: new Map(),
  [RISKS]: new RiskModel()
};

const DefaultData = Record(mapOfPropertyModels);

const modelGenerator = function(propertyModels, entity): Class<any> {
  return propertyModels[entity.id] || Map;
};

export const properties = entityReducer({
  modelGenerator: entity => modelGenerator(mapOfPropertyModels, entity),
  name: "properties",
  defaultStateConfig: {
    data: new DefaultData()
  }
});
