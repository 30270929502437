import { dataActionTypes } from "src/action_types/data_action_types";
import { fetchPopulationTally } from "src/api/population";
import { trackError } from "src/error_tracking";

/**
 * Action creator for setting loading state of tallies
 */
function populationTallyLoading() {
  return {
    type: dataActionTypes.populationTallyLoading
  };
}

/**
 * Action creator for setting loading state of the tallies
 */
function populationTallyLoadError() {
  return {
    type: dataActionTypes.populationTallyLoadError
  };
}

/**
 * Action creator for populating tallies in the store
 * @param {Array} tallies array of tallies
 * @return {Object} redux action
 */
function populateTallies(tallies) {
  return {
    type: dataActionTypes.populatePopulationTallies,
    payload: tallies
  };
}

/**
 * Action creator to fetch population tallies
 */
export function loadPopulationTalliesAction(payload) {
  return dispatch => {
    dispatch(populationTallyLoading());
    return fetchPopulationTally(payload)
      .then(response => {
        dispatch(populateTallies(response.data));
      })
      .catch(error => {
        // If the request was cancelled it means a new request started
        // don't show the loading view.
        if (error.message == "cancel") {
          return;
        }
        trackError(error);
        dispatch(populationTallyLoadError());
      });
  };
}
