import get from "lodash.get";

export const extractSubtypeKeysFromSchema = schema => {
  if (!schema || !Object.keys(schema).length) return {};

  const questions = get(schema, "attributes.schemaJson.questions", []);
  if (!Object.keys(questions).length) return {};

  const allWoundSubtypeQuestions = questions.filter(question => {
    return (
      question.visiblePredicate &&
      question.visiblePredicate.includes("woundType ==")
    );
  });

  const woundSubtypeKeysObject = allWoundSubtypeQuestions.reduce(
    (acc, question) => {
      const subtypeKey = question.name;
      const typeKey = question.visiblePredicate.split('"')[1];

      if (!typeKey) return acc;

      acc[typeKey] = acc[typeKey]
        ? [...acc[typeKey], subtypeKey]
        : [subtypeKey];

      return acc;
    },
    {}
  );

  return woundSubtypeKeysObject;
};

/*
ALGORITHM
1. Find all questions that qualify as subtype questions. (visiblePredicate has woundType == "someWoundEnumValue")
2. For each woundType that has subtype, assign it a string or an array (this may be changed later so it's only an array)
*/
