// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";

type $props = {
  className?: string,
  children?: any,
  centre?: boolean
};

export default class Text extends PureComponent {
  props: $props;
  render() {
    const { children, centre, className, ...props } = this.props;
    const klass = classnames(
      {
        "text-center": centre
      },
      className
    );
    return (
      <p className={klass} {...props}>
        {children}
      </p>
    );
  }
}
