import { createSelector } from "reselect";
import moment from "moment";

// Constants
import { apiPageLimits, attributesByResource } from "src/constants";

// Redux Resources
import { createGetPayload } from "src/redux_resources_v1";

// Selectors
import { createSelectedLocationIdsForFiltersSelector } from "../../../../section_selectors/global/create_selected_location_ids_for_filters_selector";

// Filter formatters
import resourceFilterFormatters from "src/api/resource_filter_formatters";

/**
 * Create revision GET payload with a filter json structure
 * @param {Object} props
 * @param {Object} props.filters - a filter blob applied to the revisions request
 */
export function createFirstPageRevisionPayloadForFiltersSelector({ filters }) {
  return createSelector(
    createSelectedLocationIdsForFiltersSelector({ filters }),
    locationIds => {
      // Get filter values
      const date = resourceFilterFormatters.filterValueForKeyFromFilters(
        filters,
        "date"
      );
      const lockState = resourceFilterFormatters.filterValueForKeyFromFilters(
        filters,
        "lockState"
      );
      const answers = resourceFilterFormatters.answersFilterFromFilters(
        filters
      );

      return createGetPayload({
        filter: {
          start_date: moment(date)
            .startOf("day")
            .toISOString(),
          end_date: moment(date)
            .endOf("day")
            .toISOString(),
          location_id: locationIds.join(),
          answers,
          lock_state: lockState,
          strikeout_state: "open"
        },
        page: {
          pageNumber: 0,
          limit: apiPageLimits.revisionsBulk
        },
        fields: {
          revisions:
            attributesByResource.revisions
              .allAttributesWithoutNestedAssessmentAnswers,
          series:
            attributesByResource.series.allAttributesWithoutNestedResources
        },
        include: ["series", "series.study", "series.study.patient"]
      });
    }
  );
}
