/**
 * Create action types
 * @param {string} fieldName the name of the field in the redux store
 */
export function createActionTypes(fieldName) {
  const allCapName = fieldName.toUpperCase();
  return {
    setValueForKeyAtIndex: `${allCapName}_SET_VALUE_FOR_KEY_AT_INDEX`,
    insertValueForKeyAtIndex: `${allCapName}_INSERT_VALUE_FOR_KEY_AT_INDEX`,
    appendValueForKey: `${allCapName}_APPEND_VALUE_FOR_KEY`,
    removeValueForKeyAtIndex: `${allCapName}_REMOVE_VALUE_FOR_KEY_AT_INDEX`
  };
}
