import {
	createSelector
} from "reselect";
import {
	assessmentVersionSelector
} from "src/selectors/config_selectors";

import {
	progressValues,
	progressValuesV5
} from "src/constants/progress_values";

export const progressValuesSelector = createSelector(
	assessmentVersionSelector,
	version => {
		const versions = [
			"v5",
			"v5Bwat",
			"v5Ashn",
			"v5LHC",
			"v6",
			"v6LifeCare",
			"v6MayoClinic",
			"ML1",
			"v7",
			"v8"
		];
		let values = versions.includes(version) ? progressValuesV5 : progressValues;
		return values;
	}
);
