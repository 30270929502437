import { createSelector } from "reselect";

import { isRoleProviderSelector } from "../../../config_selectors/is_role_provider_selector";
import { isRoleSupplierSelector } from "../../../config_selectors/is_role_supplier_selector";

/**
 * Memoized selector to determine if search patient dashboard is enabled
 */

export const searchPatientDashboardEnabledSelector = createSelector(
  isRoleProviderSelector,
  isRoleSupplierSelector,
  (isProvider, isSupplier) => isProvider || isSupplier
);
