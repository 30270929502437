import axios from "axios";
import { apiFactory } from "../api_factory";

export const assessmentsApi = apiFactory({
  path: "/api/v4/assessments"
});

/**
 * Fetch assessments for a set of assessmentIds with include for assessment_schema
 * @param {array} assessmentId
 * @return {Promise}
 */
export function fetchAssessmentWithAssessmentSchema(assessmentId) {
  const url = `/api/v4/assessments/${assessmentId}?include=assessment_schema`;
  return axios.get(url);
}

/**
 * Fetch assessments for a set of assessmentIds with include for assessment_schema
 * @param {array} assessmentId
 * @return {Promise}
 */
export function fetchAssessmentsWithAssessmentSchemas(assessmentIds) {
  const url = `/api/v4/assessments?filter[id]=${assessmentIds.join()}&include=assessment_schema`;
  return axios.get(url);
}
