import React from "react";
import PropTypes from "prop-types";

// Load states
import { loadStates } from "../load_states";

/**
 * HOC creator that facilitates easy use of loading/error UI with redux resources
 * @param {Object} components
 * @param {Object} components.LoadingComponent - loading component to display when loading
 * @param {Object} components.LoadingErrorComponent - load error component to display when an error occurs
 */
export function createLoadingManagerHoc({
  LoadingComponent,
  LoadingErrorComponent
}) {
  return function withLoadingManagerComponent(WrappedComponent) {
    return class LoadingManagerComponent extends React.PureComponent {
      static propTypes = {
        resourceLoadingObjects: PropTypes.array.isRequired,
        retry: PropTypes.func.isRequired
      };

      render() {
        const { resourceLoadingObjects } = this.props;

        const showError = Object.values(resourceLoadingObjects).some(loList => {
          return loList.some(lo => {
            return lo.loadState == loadStates.error;
          });
        });

        const showLoad = Object.values(resourceLoadingObjects).some(loList => {
          return loList.some(lo => {
            return lo.loadState != loadStates.loaded;
          });
        });

        if (showError) {
          return <LoadingErrorComponent {...this.props} />;
        } else if (showLoad) {
          return <LoadingComponent {...this.props} />;
        } else {
          return <WrappedComponent {...this.props} />;
        }
      }
    };
  };
}
