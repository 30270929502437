import { createActionTypes } from "../../create_action_types";

/**
 * Create action creator for "populating" a resource
 * @param {string} resourceName
 * @returns {function}
 */
export function createPopulateActionCreator(resourceName) {
  const actionTypes = createActionTypes(resourceName);
  return ({ data, links, meta, method, queryObject, page }) => {
    return {
      type: actionTypes.populate,
      payload: {
        data,
        links,
        meta,
        method,
        queryObject,
        page
      }
    };
  };
}
