import { createSelector } from "reselect";
import get from "lodash.get";

//Constants
import { allFacilitiesOptionId } from "src/constants";

//Selectors
import { customRolesResourceSelectors } from "src/selectors/data_selectors";
// import {
//   createRuleFormSelectedFacilitySelector,
//   ruleFormFacilitiesByOrganizationSelector,
//   ruleFormSelectedOrganizationSelector
// } from "src/selectors/section_selectors/admin";
import { createAlertRuleFormSelectedOrganizationSelector } from "../create_alert_rule_form_selected_organization_selector";
import { createAlertRuleFormSelectedFacilitySelector } from "../create_alert_rule_form_selected_facility_selector";
import { createAlertRuleFormFacilitiesByOrganizationSelector } from "../create_alert_rule_form_facilities_by_organization_selector";

export const createAlertRuleFormRoleOptionsSelector = formName =>
  createSelector(
    createAlertRuleFormSelectedOrganizationSelector(formName),
    createAlertRuleFormSelectedFacilitySelector(formName),
    createAlertRuleFormFacilitiesByOrganizationSelector(formName),
    customRolesResourceSelectors.dataSelector,
    (selectedOrganizationId, selectedFacilityId, facilities, roles) => {
      const rolesArray = Object.values(roles);
      let locationIds = [selectedOrganizationId];
      const defaultRoles = rolesArray.filter(role => {
        return role.type === "roles";
      });
      const customRoles = rolesArray.filter(role => {
        return role.type === "customRoles";
      });

      if (selectedFacilityId === allFacilitiesOptionId) {
        locationIds = locationIds.concat(
          facilities.map(facility => {
            return facility.id;
          })
        );
      } else {
        locationIds = locationIds.concat([selectedFacilityId]);
      }

      const customRolesByFacility = customRoles.filter(customRole => {
        const hasFacilityId = customRole.attributes.location.includes(
          "Facility"
        );
        const hasOrganizationId = customRole.attributes.location.includes(
          "Organization"
        );

        if (!hasFacilityId && !hasOrganizationId) return false;

        const locationId = customRole.attributes.location.filter(value => {
          return value !== "Facility" && value !== "Organization";
        });

        return locationIds.includes(locationId[0]);
      });

      const options = [...defaultRoles, ...customRolesByFacility].map(role => {
        return {
          id: role.id,
          text: get(role, "attributes.primaryName", "")
        };
      });

      return options;
    }
  );
