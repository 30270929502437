/**
 * Get graph images
 * @param {string} dataUrl the image url
 * @return {Object}
 */
export function getChartImage(dataUrl) {
  return {
    stack: [
      {
        image: dataUrl,
        width: 500,
        height: 350
      }
    ],

    margin: [50, 50, 0, 0]
  };
}
