import { adminActionTypes } from "src/action_types/section_action_types";

/**
 * Action to select organizations given array of org ids
 * @param {Array} orgIds array of org ids
 * @return {Object} Action
 */
export function selectOrganizationsAction(orgIds) {
  return {
    type: adminActionTypes.selectOrganizations,
    payload: orgIds
  };
}

/**
 * Action to deselect organizations given array of org ids
 * @param {Array} orgIds array of faclity ids
 * @return {Object} Action
 */
export function deselectOrganizationsAction(orgIds) {
  return {
    type: adminActionTypes.deselectOrganizations,
    payload: orgIds
  };
}
