//Reselect
import { createSelector } from "reselect";

//Selectors
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";
import { hasAllowEditOtherUsersWorkPermissionSelector } from "src/selectors/ui_config_selectors/patients";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";

//Lodash
import get from "lodash.get";

/**
 * selector to determine if edit wound assessments button is Enabled
 */
export function editWoundAssessmentsEnabledSelector(revision) {
  return createSelector(
    createPolicyForInternalNameSelector(
      policyInternalNames.woundAssessmentAccess
    ),
    hasAllowEditOtherUsersWorkPermissionSelector,
    currentUserSelector,
    (woundAccess, editOtherUsersWork, currentUser) => {
      return (
        woundAccess &&
        get(woundAccess, "attributes.policyValue", []).includes("edit") &&
        (get(revision, "attributes.userId", null) == currentUser.id ||
          editOtherUsersWork)
      );
    }
  );
}
