export const medlineColors = {
  populationChart: {
    incidence: "#ff5630",
    prevalence: "#6554c0"
  },
  surveyChart: {
    new: "#f491a3",
    existing: "#4c9aff"
  },
  woundsChart: {
    new: "#bf2600",
    deteriorating: "#ff991f",
    stalled: "#ffc400",
    stable: "#00c7e5",
    improving: "#00875a"
  },
  riskChart: {
    veryHigh: "#bf2600",
    high: "#ff991f",
    medium: "#ffc400",
    low: "#00c7e5",
    no: "#00875a",
    none: "#000000"
  }
};

export const defaultColors = {
  populationChart: {
    incidence: "#0700be",
    prevalence: "#db0a5b"
  },
  surveyChart: {
    new: "rgba(255, 99, 132, 0.2)",
    existing: "rgba(202, 182, 9, 0.2)"
  },
  woundsChart: {
    new: "#E9332C",
    deteriorating: "#EF8935",
    stalled: "#F4B63F",
    stable: "#00a38a",
    improving: "#59ad20"
  },
  riskChart: {
    veryHigh: "#E9332C",
    high: "#EF8935",
    medium: "#F4B63F",
    low: "#00a38a",
    no: "#59ad20",
    none: "#000000"
  }
};
