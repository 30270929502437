import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { withRouter } from "react-router-dom";

// Components
import { RevisionNavigationComponent } from "src/components/revision_navigation_component";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

// Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { createModalRevisionQuerySelector } from "src/selectors/api_selectors/global/revisions/create_modal_revision_query_selector";
import {
  assessmentAnswersResourceSelectors,
  assessmentsResourceSelectors,
  createProgressTabSchemasForAssessmentAnswerSelector,
  createTreatmentTabSchemasForAssessmentAnswerSelector,
  createAttributesTabSchemasForAssessmentAnswerSelector,
  createDescriptionAssessmentSchemaForAssessmentAnswerSelector
} from "src/selectors/data_selectors";
import { createLatestAssessmentAnswerForRevisionQuerySelector } from "src/selectors/api_selectors/global/assessment_answers/create_latest_assessment_answer_for_revision_query_selector";
import { createAllAssessmentsWithSchemasForRevisionQuerySelector } from "src/selectors/api_selectors/global/assessments";

import { localizationDictionaryForSeriesIdSelector } from "src/selectors/section_selectors/global";

import {
  editWoundAssessmentsEnabledSelector,
  editWoundAssessmentsVisibleSelector,
  isESignatureEnabledSelector
} from "src/selectors/ui_config_selectors/patients";

import { patientLinksEnabledSelector } from "src/selectors/config_selectors";

// Actions
import { loadRevisionsAction } from "src/actions/data_actions";
import { createSeriesForRevisionSelector } from "../../selectors/data_selectors/series/create_series_for_revision_selector";
import { createPatientForRevisionSelector } from "src/selectors/data_selectors/patients/create_patient_for_revision_selector";
import { createLatestAssessmentAnswerForRevisionSelector } from "src/selectors/data_selectors/assessment_answers/create_latest_assessment_answer_for_revision_selector";
import {
  loadAssessmentAnswersAction,
  loadAssessmentsAction
} from "src/actions/data_actions";
import SingleValueReduxUtil from "src/ui_processors/single_value";
import { patientsSectionSelector } from "src/selectors/section_selectors/patients";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";
import { encounterNamingConventionSelector } from "../../selectors/ui_config_selectors/global/encounter_naming_convention_selector";

const enableRevisionToBeEditedAction = SingleValueReduxUtil.createActionCreators(
  "revisionEnabledToBeEdited"
);

const enableRevisionToBeEditedSelector = SingleValueReduxUtil.createSelectors(
  "revisionEnabledToBeEdited",
  patientsSectionSelector
);

//Container that returns selectedRevision, and its respective series and patient to RevisionNavigationComponent

function mapStateToProps(state, ownProps) {
  const modalRevisionQuerySelector = createModalRevisionQuerySelector(ownProps)(
    state
  );
  const selectedRevision = revisionsResourceSelectors.createDataForContextSelector(
    modalRevisionQuerySelector
  )(state)[0];
  const assessmentAnswer = createLatestAssessmentAnswerForRevisionSelector(
    selectedRevision
  )(state);
  const revisionEditFormVisible =
    enableRevisionToBeEditedSelector.fieldSelector(state) !=
      selectedRevision.id &&
    editWoundAssessmentsVisibleSelector(assessmentAnswer)(state);
  const editAssessmentButtonEnabled = editWoundAssessmentsEnabledSelector(
    selectedRevision
  )(state);
  const series = createSeriesForRevisionSelector(selectedRevision)(state);
  const patient = createPatientForRevisionSelector(selectedRevision)(state);
  const isESignatureEnabled = isESignatureEnabledSelector(state);

  const progressSchemas = createProgressTabSchemasForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const treatmentSchemas = createTreatmentTabSchemasForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const attributesSchemas = createAttributesTabSchemasForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const descriptionSchemas = createDescriptionAssessmentSchemaForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const schemas = descriptionSchemas.concat(
    attributesSchemas,
    treatmentSchemas,
    progressSchemas
  );

  return {
    selectedRevision,
    schemas,
    series,
    patient,
    assessmentAnswer,
    isESignatureEnabled,
    isRevisionBeingEdited: enableRevisionToBeEditedSelector.fieldSelector(
      state
    ),
    editWoundAssessmentVisible: revisionEditFormVisible,
    editWoundAssessmentEnabled: editAssessmentButtonEnabled,
    schemaLocalizationDictionary: localizationDictionaryForSeriesIdSelector(
      series.id
    )(state),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state),
    patientLinksEnabled: patientLinksEnabledSelector(state),
    encounterNamingConvention: encounterNamingConventionSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendEnableRevisionToBeEditedAction(revisionId) {
      dispatch(enableRevisionToBeEditedAction.setValue(revisionId));
    }
  };
}

export const RevisionNavigationContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadRevisionsAction,
    resourceSelectors: revisionsResourceSelectors,
    createContextSelector: createModalRevisionQuerySelector,
    createQuerySelector: createModalRevisionQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentAnswersAction,
    resourceSelectors: assessmentAnswersResourceSelectors,
    createQuerySelector: createLatestAssessmentAnswerForRevisionQuerySelector,
    createContextSelector: createLatestAssessmentAnswerForRevisionQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentsAction,
    resourceSelectors: assessmentsResourceSelectors,
    createQuerySelector: createAllAssessmentsWithSchemasForRevisionQuerySelector,
    createContextSelector: createAllAssessmentsWithSchemasForRevisionQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: "RENDER_REVISION_NAVIGATION_CONTATINER"
  }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RevisionNavigationComponent);
