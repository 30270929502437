import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { getFormValues, clearFields } from "redux-form";
import get from "lodash.get";

//Constants
import {
  rulesEvaluationTypes,
  reduxFormNames,
  analyticsEventNames
} from "src/constants";

//HOC
import { withResourceLoaderFactory } from "src/hoc/factories";
import { withLoadingComponentFactory } from "src/hoc/factories";

//Components
import { RiskAlertRuleFormComponent } from "src/components/forms/risk_alert_rule_form_component";

//Action
import {
  postAlertAction,
  patchAlertAction,
  loadCustomRolesActionForRuleForm,
  loadRuleTemplatesAction
} from "src/actions/data_actions";

//Selectors
import {
  createAlertRuleFormInitialValuesSelector,
  createAlertRuleFormRuleParamsSelector,
  createAlertRuleTemplatesForEvaluationTypeSelector,
  createAlertRuleFormFacilitiesByOrganizationSelector,
  createAlertRuleFormRoleOptionsSelector,
  organizationsForSelectedPartnerIdSelector
} from "src/selectors/section_selectors/admin";

import {
  customRolesResourceSelectors,
  ruleTemplatesResourceSelector,
  rulesResourceSelectors
} from "src/selectors/data_selectors";

import { customRolesContextSelector } from "src/selectors/context_selectors/custom_roles";
import { customRolesQuerySelector } from "src/selectors/query_selectors/admin";

import { createRuleTemplatesQuerySelector } from "src/selectors/api_selectors/admin/rule_templates";

function mapStateToProps(state, ownProps) {
  const formValues = getFormValues(reduxFormNames.riskAlertRuleForm)(state);
  const templates = createAlertRuleTemplatesForEvaluationTypeSelector(
    rulesEvaluationTypes.risk
  )(state);

  return {
    evaluationType: rulesEvaluationTypes.risk,
    initialValues: createAlertRuleFormInitialValuesSelector({
      rule: ownProps.rule,
      evaluationType: rulesEvaluationTypes.risk
    })(state),
    formValues,
    createdAlertRuleParams: createAlertRuleFormRuleParamsSelector({
      formValues,
      templates
    })(state),
    templates,
    customRoles: createAlertRuleFormRoleOptionsSelector(
      reduxFormNames.riskAlertRuleForm
    )(state),
    rule: rulesResourceSelectors.createDataForIdSelector(
      get(ownProps, "rule.id")
    )(state),
    organizations: organizationsForSelectedPartnerIdSelector(state),
    facilities: createAlertRuleFormFacilitiesByOrganizationSelector(
      reduxFormNames.riskAlertRuleForm
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendPostAlertAction(payload) {
      return dispatch(postAlertAction({ payload }));
    },
    sendPatchAlertAction(payload) {
      return dispatch(patchAlertAction({ payload }));
    },
    clearFields(...fieldNames) {
      dispatch(
        clearFields(
          reduxFormNames.riskAlertRuleForm,
          false,
          false,
          ...fieldNames
        )
      );
    }
  };
}

export const RiskAlertRuleFormContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadRuleTemplatesAction,
    resourceSelectors: ruleTemplatesResourceSelector,
    createContextSelector: createRuleTemplatesQuerySelector,
    createQuerySelector: createRuleTemplatesQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadCustomRolesActionForRuleForm,
    resourceSelectors: customRolesResourceSelectors,
    createContextSelector: customRolesContextSelector,
    createQuerySelector: customRolesQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: analyticsEventNames.renderWoundAlertRuleForm
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RiskAlertRuleFormComponent);
