/**
 * Convert revision area to a display value, for a revision area value between 0 and 0.1, return < 0.1
 * @param {number} value
 * @returns {string} the display value
 */
export function displayValueForArea(value) {
  let displayValue = "--";
  if (value || value == 0) {
    displayValue = `${value} cm\u00B2`;
  }
  if (value < 0.1 && value > 0) {
    displayValue = "< 0.1 cm\u00B2";
  }
  return displayValue;
}
