import { createSelector } from "reselect";

// create context
import { createContext } from "../../create_context";

// utils
import { wrapSelector } from "../../utils";

/**
 * Get the load state for a context string or context string selector
 * @param {Object} resourceManager - the resource manager
 * @param {Object|array|Function} queryObject - object, array of objects, or selector for a query object or array of query objects
 * @param {Function} loadStateRootSelector - selector for the load state object
 * @param {Function} createPageCountSelector - selector creator for page count
 * @returns {Function} selector for context load state(s)
 */
export function createLoadStateSelectorCreator(
  resourceManager,
  globalConfig,
  loadStateRootSelector,
  createPageCountSelector,
  createPageSelector
) {
  return function createLoadStateSelector(method, queryObject) {
    const queryObjectSelector = wrapSelector(queryObject);
    const pageCountSelector = createPageCountSelector(queryObjectSelector);
    const pageSelector = createPageSelector(queryObjectSelector);

    return createSelector(
      queryObjectSelector,
      pageCountSelector,
      pageSelector,
      loadStateRootSelector,
      (queryObjectValue, pageCount, currentPage, loadState) => {
        const pages = globalConfig.paginationStrategy(
          queryObjectValue.pageConfig,
          pageCount,
          currentPage
        );
        return pages.map(p => {
          const context = createContext(method, queryObjectValue, p);
          return loadState[context];
        });
      }
    );
  };
}
