import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//Components
import { AlertsTableComponent } from "src/components/tables/alerts_table_component";

//HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

//Actions
import {
  userAlertsResourceActions,
  loadUserAlertsAction,
  resolveUserAlertAction
} from "src/actions/data_actions";
// import { setAlertsTablePageLimitAction } from "src/actions/section_actions/alerts";

//Selectors
import {
  userAlertsResourceSelectors,
  createAlertsIncludingPatientAndUserDetailsSelector
} from "src/selectors/data_selectors/user_alerts";
import {
  createFirstPageContextSelector,
  createCurrentPageContextSelector,
  createCurrentPagePayloadSelector
} from "src/selectors/api_selectors/alerts/user_alerts";
import { alertsSectionSelector } from "src/selectors/section_selectors/alerts";
import { createLocalizationDictionarySelectorForUserAlerts } from "src/selectors/section_selectors/global";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const alertsTablePageLimitSelector = SingleValueReduxUtil.createSelectors(
  "alertsTablePageLimit",
  alertsSectionSelector
);

const setAlertsTablePageLimitAction = SingleValueReduxUtil.createActionCreators(
  "alertsTablePageLimit"
);

function mapStateToProps(state) {
  const firstPageContextSelector = createFirstPageContextSelector();
  const alerts = userAlertsResourceSelectors.createDataForContextSelector(
    createCurrentPageContextSelector()
  )(state);

  return {
    schemaLocalizationDictionary: createLocalizationDictionarySelectorForUserAlerts(
      alerts
    )(state),
    alerts: createAlertsIncludingPatientAndUserDetailsSelector(alerts)(state),
    pageLimit: alertsTablePageLimitSelector.fieldSelector(state),
    pageCount:
      userAlertsResourceSelectors.createPageCountSelector(
        firstPageContextSelector,
        alertsTablePageLimitSelector.fieldSelector
      )(state) || 0,
    currentPage: userAlertsResourceSelectors.createCurrentPageForContextSelector(
      firstPageContextSelector
    )(state),
    firstPageContext: firstPageContextSelector(state),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetPageLimitAction: limit => {
      dispatch(setAlertsTablePageLimitAction.setValue(limit));
    },
    sendSetPageAction: (context, pageNumber) => {
      dispatch(
        userAlertsResourceActions.setPageForContextAction(context, pageNumber)
      );
    },
    sendResolveAlertAction: payload => {
      dispatch(resolveUserAlertAction({ payload }));
    }
  };
}

export const AlertsTableContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadUserAlertsAction,
    resourceSelectors: userAlertsResourceSelectors,
    createContextSelector: createCurrentPageContextSelector,
    createPayloadSelector: createCurrentPagePayloadSelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: `RENDER_USER_ALERTS_TABLE`
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AlertsTableComponent);
