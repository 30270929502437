import { createSelector } from "reselect";

import { orderedSelectedLocationsSelector } from "src/selectors/section_selectors/survey/ordered_selected_locations_selector";

/**
 * Selector to get context for the tallies we want to GET for selected location ids
 */
export const tallyContextsLocationSelector = createSelector(
  orderedSelectedLocationsSelector,
  locs => {
    return locs.map(loc => {
      return {
        locationId: loc.id
      };
    });
  }
);
