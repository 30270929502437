import { createSelector } from "reselect";
import { ruleTemplatesResourceSelector } from "src/selectors/data_selectors";
import get from "lodash.get";

export const createAlertRuleTemplatesForEvaluationTypeSelector = evaluationType => {
  return createSelector(
    ruleTemplatesResourceSelector.dataSelector,
    ruleTemplates => {
      const templatesByEvaluationType = Object.values(ruleTemplates).filter(
        template => {
          const ruleParams = get(template, "attributes.ruleParams", []);
          const ruleParam = ruleParams.find(param => {
            return param.name === "evaluation_type";
          });

          return ruleParam.value === evaluationType;
        }
      );
      return templatesByEvaluationType;
    }
  );
};
