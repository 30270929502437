import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { WoundAssessmentProgressChartContainer } from "src/containers/wound_assessment_progress_chart_container";
import { WoundsOptimizedChartContainer } from "src/containers/wounds_optimized_chart_container";

// Components
import { GridRow, GridColumn } from "src/components/grid";

// Translations
import i18n from "src/translations";

// Style
import styles from "./style.module.less";

export class WoundsChartTabComponent extends React.PureComponent {
  static propTypes = {
    progressValues: PropTypes.array.isRequired,
    selectedDate: PropTypes.object.isRequired,
    isWoundsClinicalChartOptimizationEnabled: PropTypes.bool.isRequired,
    assessmentVersion: PropTypes.string.isRequired
  };

  /**
   * Render the header for the page and legend for the graph
   */
  renderHeaderAndGraphLegend() {
    const { progressValues, assessmentVersion } = this.props;

    const legendList = progressValues.map((progress, index) => {
      const colorStyle = `badge-${progress}`;
      return (
        <li className="li-chart-legend" key={index}>
          <span className={`${styles["badge"]} ${colorStyle}`} />{" "}
          {i18n.t(`data.overallProgress.${assessmentVersion}.${progress}`)}
        </li>
      );
    });

    return (
      <GridRow className={styles["headline"]}>
        <GridColumn xs="4">
          <h3 className="section-title">
            Wounds - {moment(this.props.selectedDate).format("MMMM Do YYYY")}
          </h3>
        </GridColumn>
        <GridColumn xs="7">
          <ul className="list-unstyled chart-legend text-right">
            {legendList}
          </ul>
        </GridColumn>
        <GridColumn xs="1" />
      </GridRow>
    );
  }

  renderChart() {
    const { isWoundsClinicalChartOptimizationEnabled } = this.props;
    if (isWoundsClinicalChartOptimizationEnabled) {
      return <WoundsOptimizedChartContainer />;
    } else {
      return <WoundAssessmentProgressChartContainer />;
    }
  }

  render() {
    return (
      <div>
        {this.renderHeaderAndGraphLegend()}
        {this.renderChart()}
      </div>
    );
  }
}
