import { getHeaderV2 } from "../get_header_v2";
import { getFooter } from "../get_footer";
import { getBody } from "./get_body";
import { getMultiWoundBody } from "./get_multi_wound_body";
import { getPdfOptionsV2 } from "../get_pdf_options_v2";
import { createNoResultsPdf } from "../create_no_results_pdf";

/**
 * Generate a JSON object formatted to create a pdf for an assessment with pdfMake
 * @param {Object[]} pdfContentObjects - array of content objects
 * @param {Object} pdfContentObjects[].user - the user
 * @param {Object} pdfContentObjects[].patient - the patient
 * @param {Object} pdfContentObjects[].revision - the revision
 * @param {Object} pdfContentObjects[].assessmentAnswer - the most recent aa for the revision
 * @param {Object} pdfContentObjects[].schemas - schemas for the revision
 * @param {Object[]} pdfContentObjects[].schemas.progress - array of assessment schemas
 * @param {Object[]} pdfContentObjects.schemas.treatment - array of assessment schemas
 * @param {Object[]} pdfContentObjects.schemas.attributes - array of assessment schemas
 * @param {boolean} pdfContentObjects[].revisionIsFirstInSeries
 * @param {boolean} pdfContentObjects[].isAutodepth - flag to show whether a wound revision used autodepth to calculate a depth point
 *
 * @param {Object} pdfContentObjects[].images - image object specific to the revision
 * @param {string} pdfContentObjects[].images.qrCode - pdfContentObjects url of patient qr code
 * @param {string} pdfContentObjects[].images.wound - pdfContentObjects url of wound
 * @param {string} pdfContentObjects[].images.closureChart - pdfContentObjects url of closure chart
 * @param {string} pdfContentObjects[].images.pushChart - pdfContentObjects url of push chart
 *
 * @param {Object} globalImages
 * @param {string} globalImages.logo - pdfContentObjects url of swift (or branded) logo
 *
 * @param {Object} options - pdf generation options
 * @param {string} options.branding
 * @param {bool} options.woundNumbersEnabled
 *
 * @return {Object} json object representing the pdf
 */
export async function createRevisionPdf(
  pdfContentObjects,
  globalContent,
  options,
  landscape = false
) {
  if (!pdfContentObjects.length) {
    return createNoResultsPdf(globalContent, options);
  }

  let content = landscape
    ? getMultiWoundBody(pdfContentObjects, options)
    : await getBody(pdfContentObjects, options);

  return {
    header: getHeaderV2(globalContent, { ...options, landscape }),
    footer: getFooter(options.branding),
    content,
    ...getPdfOptionsV2({ ...options, applySideMargins: true, landscape })
  };
}
