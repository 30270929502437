import get from "lodash.get";
import i18n from "src/translations";

const encounterClinicianNameFormatter = (encounter, encounterFieldsShown) => {
  if (encounterFieldsShown.clinicianName) {
    const encounterClinicianName = get(
      encounter,
      "attributes.clinicianName",
      "N/A"
    );
    return `${i18n.t("data.encounters.clinicianNameString")}: ${
      encounterClinicianName ? encounterClinicianName : "N/A"
    }`;
  }
  return null;
};

export default encounterClinicianNameFormatter;
