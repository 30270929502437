export const visitSequenceConfiguration = {
  filterName: "visitFrequency",
  filterValues: [
    {
      key: "visitFrequency",
      value: "1",
      name: "1"
    },
    {
      key: "visitFrequency",
      value: "2",
      name: "2"
    },
    {
      key: "visitFrequency",
      value: "3",
      name: "3"
    },
    {
      key: "visitFrequency",
      value: "4",
      name: "4"
    },
    {
      key: "visitFrequency",
      value: "5",
      name: "5"
    },
    {
      key: "visitFrequency",
      value: "6",
      name: "6"
    },
    {
      key: "visitFrequency",
      value: "7",
      name: "7"
    }
  ]
};
