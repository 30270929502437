import { connect } from "react-redux";

import { brandingSelector } from "src/selectors/ui_config_selectors/global";
import { ResetPasswordComponent } from "src/components/reset_password";

function mapStateToProps(state) {
  return {
    branding: brandingSelector(state)
  };
}

export const ResetPasswordContainer = connect(mapStateToProps)(
  ResetPasswordComponent
);
