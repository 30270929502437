import React from "react";
import PropTypes from "prop-types";

//Constants
// import { localExportNames } from "src/constants";

// Containers
import {
  CsvExportDownloadManagerListItemContainer,
  LocalExportBulkRevisionsDownloadManagerListItemContainer
} from "src/containers/download_manager/download_manager_list_item_container";

// Styles
import styles from "./style.module.less";

export class DownloadManagerBodyComponent extends React.PureComponent {
  static propTypes = {
    fileExports: PropTypes.array.isRequired
  };

  render() {
    const { fileExports } = this.props;
    const downloadListItems = fileExports.map(fileExport => {
      if (fileExport.type === "csvExports") {
        return (
          <li key={fileExport.id}>
            <CsvExportDownloadManagerListItemContainer
              fileExport={fileExport}
              csvExportId={fileExport.id} // used in csv subscriber
            />
          </li>
        );
      } else {
        return (
          <li key={fileExport.id}>
            <LocalExportBulkRevisionsDownloadManagerListItemContainer
              fileExport={fileExport}
              filters={fileExport.attributes.filters} // used in resource loader selectors
              // TODO should this be filter blob or formatted filters for api - design in separate ticket
            />
          </li>
        );
      }
    });

    return (
      <div className={styles["download-manager-list-container"]}>
        <ul className={styles["download-manager-list"]}>{downloadListItems}</ul>
      </div>
    );
  }
}
