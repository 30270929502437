import { Action } from "src/utils/actionUtils";
import apiFetch from "app/util/apiFetch";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

class PatientActions extends Action {
  /**
   * This is for loading the braden history graph data
   */
  loadTrendsByPatient = id => {
    return dispatch => {
      // time track
      startTimeTrack("API_GET_PATIENT_TRENDS");

      return dispatch(
        apiFetch(`/pcc/v1/patients/${id}/trends`, {
          method: "GET"
        })
      )
        .then(({ data: trends }) => {
          // finish time track
          finishTimeTrack("API_GET_PATIENT_TRENDS");

          return dispatch(
            this.baseActions.getAdditionalEntityProperties(
              id,
              "patientTrends",
              trends
            )
          );
        })
        .catch(err => {
          finishTimeTrack("API_GET_PATIENT_TRENDS", {
            error: true,
            errorCode: err.response ? err.response.status : undefined
          });

          return dispatch(
            this.baseActions.getAdditionalEntityProperties(
              id,
              "patientTrends",
              err
            )
          );
        });
    };
  };
}

export default new PatientActions("patients");
