/**
 * Create the payload for GETing wounds clinical chart data
 * @param {Object} options
 * @param {Array} options.wounds
 * @param {string} options.inHouseAcquired
 * @param {Array} options.locations
 * @param {string} options.endTime
 */

export function createGetSingleCharDataPayload({
  wounds,
  inHouseAcquired,
  locations,
  endTime,
  hprScoreMinRangeFilter,
  hprScoreMaxRangeFilter,
  bwatScoreMinRangeFilter,
  bwatScoreMaxRangeFilter,
  payers
}) {
  return {
    filter: {
      ...(wounds && wounds.length && { wounds_filter: wounds }),
      ...(inHouseAcquired && { in_house_acquired_filter: inHouseAcquired }),
      ...(locations && { location_id: locations.join(",") }),
      ...(payers && { payer_ids: payers.join() }),
      ...(endTime && { end_time: endTime }),
      ...((hprScoreMinRangeFilter || hprScoreMinRangeFilter === 0) &&
        hprScoreMaxRangeFilter && {
          hpr_range_score: [hprScoreMinRangeFilter, hprScoreMaxRangeFilter]
        }),
      ...((bwatScoreMinRangeFilter || bwatScoreMinRangeFilter === 0) &&
        bwatScoreMaxRangeFilter && {
          bwat_range_score: `${bwatScoreMinRangeFilter},${bwatScoreMaxRangeFilter}`
        })
    }
  };
}
