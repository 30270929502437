import qs from "qs";
import isArray from "lodash.isarray";
import isEmpty from "lodash.isempty";
import cloneDeep from "lodash.clonedeep";
import isInteger from "lodash.isinteger";
import omit from "lodash.omit";
import includes from "lodash.includes";
import compact from "lodash.compact";

/**
 * Construct the query string based on query params
 * @param {object} queryParams Query parameters to build query string
 */
export function buildQueryString(queryParams) {
  const queryParamsCopy = cloneDeep(queryParams);
  const queryString = qs.stringify(getQueryObject(queryParamsCopy), {
    indices: false
  });
  return queryString ? `?${queryString}` : "";
}

/**
 * Create a valid query object based on query params
 * @param {object} queryParams Query parameters to build query object
 */
function getQueryObject(queryParams) {
  if (isEmpty(queryParams)) {
    return {};
  } else {
    let includeInQuery = [];
    let filter, page, include, sort, fields;
    for (let param in queryParams) {
      switch (param) {
        case "filter":
          filter = queryParams["filter"];
          if (!isObject(filter) || isEmpty(filter)) {
            break;
          } else {
            for (let key in filter) {
              if (typeof filter[key] == "object" && isEmpty(filter[key])) {
                filter = omit(filter, key);
              } else {
                includeInQuery.push("filter");
                if (isArray(filter[key])) {
                  filter[key] = compact(filter[key])
                    .sort()
                    .map(val => {
                      if (isObject(val)) {
                        return JSON.stringify(val);
                      } else {
                        return val;
                      }
                    })
                    .join();
                }
              }
            }
          }
        case "page":
          page = queryParams["page"];
          if (!isObject(page) || isEmpty(page)) {
            break;
          } else {
            for (let key in page) {
              const allowedPageKeys = ["limit", "offset"];
              if (!includes(allowedPageKeys, key)) {
                page = omit(page, key);
              } else {
                if (!isInteger(page[key])) {
                  page = omit(page, key);
                } else {
                  includeInQuery.push("page");
                }
              }
            }
          }
        case "include":
          include = queryParams["include"];
          if (!include) {
            break;
          } else {
            includeInQuery.push("include");
            if (isArray(include)) {
              include = include.join();
            }
          }
        case "sort":
          sort = queryParams["sort"];
          if (!sort) {
            break;
          } else {
            includeInQuery.push("sort");
            if (isArray(sort)) {
              sort = include.join();
            }
          }
        case "fields":
          fields = queryParams["fields"];
          if (!fields) {
            break;
          } else {
            includeInQuery.push("fields");
            if (isArray(fields)) {
              fields = include.join();
            }
          }
        default:
          break;
      }
    }

    if (!includeInQuery.length) {
      return {};
    } else {
      includeInQuery = arrangeQueryArray(includeInQuery);
      let query = {};
      includeInQuery.map(param => {
        switch (param) {
          case "filter":
            query["filter"] = filter;
          case "page":
            query["page"] = page;
          case "include":
            query["include"] = include;
          case "sort":
            query["sort"] = sort;
          case "fields":
            query["fields"] = fields;
          default:
            break;
        }
      });
      return query;
    }
  }
}

/**
 * Sort query params in the the order: filter, page, include
 * @param {array} queryParamsArray Array with queryParams
 */
function arrangeQueryArray(queryParamsArray) {
  const order = ["filter", "page", "include", "sort"];
  let orderedArray = [];
  order.map(item => {
    if (includes(queryParamsArray, item)) {
      orderedArray.push(item);
    }
  });
  return orderedArray;
}

/**
 * Determine if an argument is an object
 * @param {any} param Parameter to check
 */
function isObject(param) {
  if (Array.isArray(param)) {
    return false;
  } else {
    return param === Object(param);
  }
}
