import React from "react";
import PropTypes from "prop-types";

/**
 * Used to provide an adaptive font size.
 * This component should be used to wrap other components.
 * Children components should set font size using em units, to size relative to the font size
 * set by this component.
 *
 * Props:
 * baseWidth: widths below this will scale the font size down, widths above will scale font size up
 * baseFontSize: the font size displayed if width = baseWidth
 * scaleFactor: controls the speed at which font scales relative to width
 * minFontSize: the minimum font size (px)
 * maxFontSize: the max font size (px)
 */
export class AdaptiveFontSizeComponent extends React.PureComponent {
  static propTypes = {
    baseWidth: PropTypes.number,
    baseFontSize: PropTypes.number,
    scaleFactor: PropTypes.number,
    minFontSize: PropTypes.number,
    maxFontSize: PropTypes.number
  };

  static defaultProps = {
    baseWidth: 500,
    baseFontSize: 18,
    scaleFactor: 1,
    minFontSize: 12,
    maxFontSize: 24
  };

  state = {
    width: 0,
    fontSize: this.props.baseFontSize
  };

  // ref for the div that controls font size
  static fontSizeDivRef = null;

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateFontSize();
    window.addEventListener("resize", this.updateFontSize);
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateFontSize);
  }

  updateFontSize = () => {
    const {
      baseWidth,
      baseFontSize,
      scaleFactor,
      maxFontSize,
      minFontSize
    } = this.props;

    // Get scale
    const width = this.fontSizeDivRef.offsetWidth;

    // Don't update state needlessly
    if (width == this.state.width) {
      return;
    }

    // const widthDiff = baseWidth - width;
    let fontScale = width / baseWidth;
    fontScale =
      width > baseWidth ? fontScale * scaleFactor : fontScale / scaleFactor;

    // Get font size
    let fontSize = baseFontSize * fontScale;
    fontSize = Math.min(Math.max(fontSize, minFontSize), maxFontSize);

    // Set state
    this.setState({
      fontSize
    });
  };

  /**
   * Get the ref for the div
   */
  setDivRef = element => {
    // TODO: Update this to use React.createRef when we upgrade to react v16
    this.fontSizeDivRef = element;
  };

  render() {
    const style = {
      fontSize: this.state.fontSize
    };

    return (
      <div style={style} ref={this.setDivRef}>
        {this.props.children}
      </div>
    );
  }
}
