import React, { useState, useEffect } from "react";
import { ImageDeclinedContainer } from "src/containers/image_declined_container";
import PropTypes from "prop-types";
import get from "lodash.get";
import { setFluoroFrameTypeAction } from "src/actions/frame_actions";
import { fluoroFrameTypeSelector } from "src/selectors/frame_selectors";
import { connect } from "react-redux";
import i18n from "src/translations";
import { track } from "src/analytics";
import FluoroVisibleIcon from "src/components/fluoro_frame_component/fluoro-multi-enabled.svg";
import FluoroEnhancedIcon from "src/components/fluoro_frame_component/fluoro-enabled.svg";
import VisibleIcon from "src/components/fluoro_frame_component/fluoro-visible.svg";

function FluoroFrameComponent({
  contentType,
  fluoroFrames,
  fluoroFrameType,
  styles,
  trackingProperties,
  sendFluoroFrameChangeAction
}) {
  const [fluoroFrame, setFluoroFrame] = useState(
    fluoroFrames.find(frame => frame.frameType === fluoroFrameType)
  );
  const [imageUrl, setImageUrl] = useState(get(fluoroFrame, "imageUrl", null));
  const newFluoroTypes = {
    fluoVisible: "bacterial",
    bacterial: "fluorescent",
    fluorescent: "fluoVisible"
  };
  const fluoroFrameTypeTrackTitle = {
    fluoVisible: "Fluoro Visible View Toggled",
    bacterial: "Visible View Toggled",
    fluorescent: "Fluoro Enhanced View Toggled"
  };
  const fluoroFrameTypeTrackaDescription = {
    fluoVisible: "User has clicked on the Fluoro Visible toggle",
    bacterial: "User has clicked on the Visible Toggle",
    fluorescent: "User has clicked on the Fluoro Enhanced Toggle"
  };

  useEffect(() => {
    const newFrame = fluoroFrames.find(
      frame => frame.frameType === fluoroFrameType
    );
    setFluoroFrame(newFrame);
    setImageUrl(get(fluoroFrame, "imageUrl", null));
  }, [fluoroFrameType, fluoroFrames]);

  /**
   * Handle fluoro image toggle
   */
  function handleFluoroToggle() {
    track(fluoroFrameTypeTrackTitle[fluoroFrameType], {
      description: fluoroFrameTypeTrackaDescription[fluoroFrameType],
      ...trackingProperties
    });

    sendFluoroFrameChangeAction(newFluoroTypes[fluoroFrameType]);
  }

  return (
    <div style={{ height: "100%" }}>
      <div className={styles["frame-container"]}>
        <div className={styles["image-options-fluoro"]}>
          <div className={styles["image-icons"]}>
            <a className="btn" onClick={handleFluoroToggle}>
              {fluoroFrameType === "fluoVisible" ? (
                <img src={VisibleIcon} alt="Visible Icon" />
              ) : fluoroFrameType === "bacterial" ? (
                <img src={FluoroEnhancedIcon} alt="Fluoro Enhanced Icon" />
              ) : (
                <img src={FluoroVisibleIcon} alt="Fluoro Visible Icon" />
              )}
            </a>
          </div>
          <span className={styles["frame-label"]}>
            {fluoroFrameType === "fluoVisible"
              ? i18n.t("headers.visible")
              : fluoroFrameType === "bacterial"
              ? i18n.t("headers.fluoroEnhanced")
              : i18n.t("headers.fluoroVisible")}
          </span>
        </div>
        <div className={styles["canvas-container"]}>
          {contentType == "declined" ? (
            <ImageDeclinedContainer displayTextHorizontal={true} />
          ) : (
            <img className={styles["thermal-image-container"]} src={imageUrl} />
          )}
        </div>
      </div>
    </div>
  );
}

FluoroFrameComponent.propTypes = {
  fluoroFrames: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    fluoroFrameType: fluoroFrameTypeSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendFluoroFrameChangeAction: active => {
      dispatch(setFluoroFrameTypeAction(active));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FluoroFrameComponent);
