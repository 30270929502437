import { createSelector } from "reselect";
import { policiesResourceSelectors } from "../policies_resource_selectors";

/**
 * Selector creator for Policies
 * @param {string} internalName
 * @returns {Function} selector
 */
export function createPolicyForInternalNameSelector(internalName) {
  return createSelector(
    policiesResourceSelectors.dataSelector,
    policies => {
      return Object.values(policies).find(
        p => p.attributes.internalName == internalName
      );
    }
  );
}
