import { useContext } from "react";
import { ComplianceContext } from "src/pages/compliance/components/compliance_provider_component.js";
import complianceFilterSections from "src/pages/compliance/components/filters/sections";
import { track } from "src/analytics";

const default_mixpanel_props = (key, value) => {
  const { title: filterName } = complianceFilterSections.find(
    section => section.filterKey === key
  );

  return { filterName, key, value };
};

const useComplianceFilter = filterKey => {
  const { state, dispatch } = useContext(ComplianceContext);

  const enabledFilters = state.filters[filterKey];

  const disableFilters = filterIds => {
    dispatch({ type: "DISABLE_FILTERS", payload: { filterKey, filterIds } });
    track("UI_DESELECT_FILTER", default_mixpanel_props(filterKey, filterIds));
  };

  const enableFilters = filterIds => {
    dispatch({ type: "ENABLE_FILTERS", payload: { filterKey, filterIds } });
    track("UI_SELECT_FILTER", default_mixpanel_props(filterKey, filterIds));
  };

  const overrideFilters = filterIds => {
    dispatch({ type: "OVERRIDE_FILTERS", payload: { filterKey, filterIds } });
  };

  return { enabledFilters, enableFilters, disableFilters, overrideFilters };
};

export default useComplianceFilter;
