import { createSelector } from "reselect";
import { apiPageLimits } from "src/constants";

import { buildQueryString } from "src/utils/build_query_string";

export function createFirstPageAllFacilitiesQuerySelector() {
  return createSelector(() => {
    const queryObject = {
      page: {
        offset: 0,
        limit: apiPageLimits.facilities
      }
    };

    return buildQueryString(queryObject);
  });
}
