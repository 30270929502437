import { getPdfOptionsV2 } from "../get_pdf_options_v2";
import { getHeaderV2 } from "../get_header_v2";

export function createNoResultsPdf(globalContent, options) {
  return {
    header: getHeaderV2(globalContent, options),
    content: [
      {
        text: "No Results",
        marginTop: 200,
        alignment: "center"
      }
    ],
    ...getPdfOptionsV2({ ...options, applySideMargins: true })
  };
}
