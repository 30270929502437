import moment from "moment";

export const woundsDashboardPayloadGenerator = (
  selectedWoundOptions,
  selectedLocationIds,
  acquiredFilter,
  selectedEndDate,
  hprScoreMinRangeFilter,
  hprScoreMaxRangeFilter,
  bwatScoreMinRangeFilter,
  bwatScoreMaxRangeFilter,
  selectedPayersIds
) => {
  return {
    wounds_filter: selectedWoundOptions,
    location_ids: selectedLocationIds.join(),
    ...(selectedPayersIds &&
      selectedPayersIds.length && { payer_ids: selectedPayersIds.join() }),
    start_time: moment("1900-01-01").toISOString(),
    end_time: moment(selectedEndDate)
      .endOf("day")
      .toISOString(),
    in_house_acquired_filter: acquiredFilter,
    time_step: "week",
    ...((hprScoreMinRangeFilter || hprScoreMinRangeFilter === 0) &&
      hprScoreMaxRangeFilter && {
        hpr_range_score: [hprScoreMinRangeFilter, hprScoreMaxRangeFilter]
      }),
    ...((bwatScoreMinRangeFilter || bwatScoreMinRangeFilter === 0) &&
      bwatScoreMaxRangeFilter && {
        bwat_range_score: `${bwatScoreMinRangeFilter},${bwatScoreMaxRangeFilter}`
      })
  };
};
