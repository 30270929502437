import { get } from "lodash";
/**
 * Evaluates
 *
 * @param {Object} payload
 * @param {Object} payload.predicate
 * @param {Object} payload.assessmentAnswer,
 *
 * @returns {boolean}
 */
export function evaluatePredicate(payload) {
  const {
    predicate,
    assessmentAnswer,
    answersJson,
    revisionIsFirstInSeries = false
  } = payload;

  const answers =
    answersJson || get(assessmentAnswer, "attributes.answersJson", {});

  function evaluateNode(node) {
    switch (node.type) {
      case "Identifier":
        return answers[node.name];

      case "Literal":
        return node.value;

      case "LogicalExpression":
      case "BinaryExpression":
        return evaluateOperation(
          evaluateNode(node.left),
          evaluateNode(node.right),
          node.operator
        );

      case "CallExpression":
        switch (node.callee.name) {
          case "revisionIsFirstInSeries":
            return revisionIsFirstInSeries;
        }
    }
  }

  return evaluateNode(predicate);
}

function evaluateOperation(left, right, operator) {
  const noneEquivalents = ["", "none"];
  switch (operator) {
    case "in":
      if (right) {
        return right.includes(left);
      } else {
        return false;
      }
    case "==":
      return right === "none"
        ? !noneEquivalents.includes(left) && !!left
        : left == right;
    case "!=":
      return right === "none"
        ? !noneEquivalents.includes(left) && !!left
        : left != right;
    case "||":
      return left || right;
    case "&&":
      return left && right;
  }
}
