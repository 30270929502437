import { dataActionTypes } from "src/action_types";

const defaultState = {
  loadState: "ready"
};

export function populationTallyExportReducer(state = defaultState, action) {
  switch (action.type) {
    case dataActionTypes.populationDataTallyExportLoading:
      return { loadState: "loading" };
    case dataActionTypes.populationDataTallyExportLoadReady:
      return { loadState: "ready" };
    default:
      return state;
  }
}
