import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash.get";

//UI-Kit
import { Button } from "src/ui-kit";

//Constants
import { assessmentTypes } from "src/constants";

//Translations
import i18n from "src/translations";

// Styles
import styles from "./style.module.less";

// Utils
import { getWoundNumberTypeSubtypeString } from "src/utils/get_wound_number_type_subtype_string";

export class AcknowledgeAlertComponent extends React.Component {
  static propTypes = {
    alert: PropTypes.object.isRequired,
    sendResolveAlertAction: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    schemaLocalizationDictionary: PropTypes.object.isRequired,
    subtypeKeysForSchema: PropTypes.object.isRequired
  };

  onAcknowledgeAlert = () => {
    const { alert, sendResolveAlertAction, cancel } = this.props;
    sendResolveAlertAction({
      id: alert.id,
      data: { id: alert.id }
    }).then(() => cancel());
  };

  getWoundNumberTypeAndSubType = alert => {
    const { schemaLocalizationDictionary, subtypeKeysForSchema } = this.props,
      attributes = alert.attributes,
      woundNumber = get(attributes, "resource.patientWoundNumber"),
      assessmentId = get(attributes, "resource.assessmentId"),
      localizationDictionaryForAlert =
        schemaLocalizationDictionary[assessmentId];

    if (attributes.evaluationType != assessmentTypes.wound) {
      return "";
    }

    const woundNumberTypeSubtypeString = getWoundNumberTypeSubtypeString({
      answersJson: get(alert, "attributes.answersJson", {}),
      patientWoundNumber: woundNumber,
      localizationDictionary: localizationDictionaryForAlert,
      subtypeKeysForSchema
    });

    return woundNumberTypeSubtypeString;
  };

  render() {
    const { alert, cancel } = this.props;

    const alertDate = get(alert, "attributes.createdAt"),
      alertType = get(alert, "attributes.ruleTemplateDescription"),
      patientName = get(alert, "attributes.patientName", ""),
      evaluationType = get(alert, "attributes.evaluationType"),
      woundNumberTypeAndSubType = this.getWoundNumberTypeAndSubType(alert);

    return (
      <div>
        <h4>
          <b>
            {i18n.t("headers.acknowledge")}{" "}
            {evaluationType ? i18n.t(`headers.${evaluationType}`) : ""}{" "}
            {i18n.t("headers.alert")}{" "}
          </b>
        </h4>
        <hr />
        {woundNumberTypeAndSubType && (
          <div>
            <b>{woundNumberTypeAndSubType}</b>
            <br />
            <br />
          </div>
        )}
        <b>{i18n.t("text.alertLabels.alertDate")}: </b>
        {alertDate ? moment(alertDate).format("MMM D, YYYY") : "Not Set"}
        <br />
        <br />
        <b>{i18n.t("text.alertLabels.alertType")}: </b>
        {alertType ? alertType : "Not Set"}
        <br />
        <br />
        <b>{i18n.t("text.alertLabels.patientName")}: </b>
        {patientName ? patientName : "Not Set"}
        <br />
        <br />
        {i18n.t("interaction.alerts.acknowledgeAgreement")}
        <hr />

        <div className={styles["buttons-container"]}>
          <div className="pull-right">
            <Button
              className="btn dashboard-btn"
              onClick={this.onAcknowledgeAlert}
            >
              {i18n.t(`actions.yes`)}
            </Button>
            <Button className="btn btn-default" onClick={cancel}>
              {i18n.t(`actions.cancel`)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
