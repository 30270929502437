import { connect } from "react-redux";
import { PopulationLocationComponent } from "src/components/population_tallies_by_location_component";
import flowRight from "lodash.flowright";
import { withPopulationTalliesByLocationDataLoader } from "src/hoc";
// Selectors
import {
  selectedWoundTypesSelector,
  dateSelectionSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector
} from "src/selectors/section_selectors/population";
import { populationTalliesByLocationLoadStateSelector } from "src/selectors/data_selectors";

function mapStateToProps(state) {
  return {
    selectedLocationIds: selectedLocationIdsSelector(state),
    selectedWoundTypes: selectedWoundTypesSelector(state),
    dateSelection: dateSelectionSelector(state),
    acquiredFilter: acquiredFilterSelector(state),
    populationTalliesByLocationLoadState: populationTalliesByLocationLoadStateSelector(
      state
    )
  };
}

export const PopulationLocationContainer = flowRight(
  withPopulationTalliesByLocationDataLoader,
  connect(mapStateToProps)
)(PopulationLocationComponent);
