import React from "react";
import PropTypes from "prop-types";

// Containers
import {
  WoundProgressTallyByLocationTableRowContainer,
  WoundProgressTallyByLocationTableHeaderContainer
} from "src/containers/tables";

// Styles
import styles from "./style.module.less";

/**
 * Component to render a wound count table broken down by location
 */
export class WoundProgressTallyByLocationTableComponent extends React.PureComponent {
  static propTypes = {
    locations: PropTypes.array.isRequired,
    context: PropTypes.string.isRequired
  };

  /**
   * Render each row for the tally table
   */
  renderTableBody() {
    const { locations, context } = this.props;

    const rows = locations.map(loc => {
      return (
        <WoundProgressTallyByLocationTableRowContainer
          key={loc.id}
          location={loc}
          context={context}
        />
      );
    });

    return <tbody>{rows}</tbody>;
  }

  render() {
    //TODO: refactor the table and table-striped styles to an organized/localized style sheet.
    // ie. remove them from the src style sheet.
    return (
      <table
        className={`table table-striped ${styles["table-vertical-align"]}`}
      >
        <WoundProgressTallyByLocationTableHeaderContainer />
        {this.renderTableBody()}
      </table>
    );
  }
}
