import { createSelector } from "reselect";
// import { policies } from "src/constants";

// Selectors
// import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

/**
 * Selector to determine if wound numbers should be enabled
 */
export const woundNumbersEnabledSelector = createSelector(
  // createPolicyForInternalNameSelector(policies.enableWoundNumberView),
  // policy => !!policy && policy.attributes.policyValue
  () => true
);
