import { createSelector } from "reselect";

// Api
import { createFirstPageContextSelector } from "../create_first_page_context_selector";
import { createGetPayload } from "src/api/assessment_summaries/payload_generators";
import {
  selectedLocationIdsSelector,
  answersFilterSelector,
  woundRiskSectionSelector
} from "src/selectors/section_selectors/wound_risk/wound_risk_wound_selector";
import { assessmentSummariesResourceSelectors } from "src/selectors/data_selectors";
import { createArrayOfRevisionIdsSelector } from "src/selectors/data_selectors/wound_revisions";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const woundRiskPageLimitSelector = SingleValueReduxUtil.createSelectors(
  "woundRiskPageLimit",
  woundRiskSectionSelector
);

export function createCurrentPagePayloadSelector() {
  return createSelector(
    createArrayOfRevisionIdsSelector,
    assessmentSummariesResourceSelectors.createCurrentPageForContextSelector(
      createFirstPageContextSelector()
    ),
    woundRiskPageLimitSelector.fieldSelector,
    selectedLocationIdsSelector,
    answersFilterSelector,
    (
      woundRevisionIds,
      currentPage,
      pageLimit,
      selectedLocationIds,
      answers
    ) => {
      if (!selectedLocationIds.length) return {};
      return createGetPayload({
        woundRevisionIds,
        pageNumber: currentPage,
        pageLimit,
        answers,
        patient_state: "admitted,new",
        selectedLocationIds,
        sortEnabled: "created_at"
      });
    }
  );
}
