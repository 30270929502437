import {
	woundTypesDefault
} from "../wound_types/wound_types_default";
import {
	woundTypesV3
} from "../wound_types/wound_types_v3";
import {
	woundTypesV4
} from "../wound_types/wound_types_v4";
import {
	woundTypesV4Revera
} from "../wound_types/wound_types_v4_revera";
import {
	woundTypesV5
} from "../wound_types/wound_types_v5";
import {
	woundTypesV5Ashn
} from "../wound_types/wound_types_v5_ashn";
import {
	woundTypesV6
} from "../wound_types/wound_types_v6";
import {
	woundTypesV7
} from "../wound_types/wound_types_v7";
import {
	woundTypesV8
} from "../wound_types/wound_types_v8";
import {
	woundTypesML1
} from "../wound_types/wound_types_ML1";
import {
	woundTypesV6LifeCare
} from "../wound_types/wound_types_v6_lifecare";
import {
	woundTypesV6MayoClinic
} from "../wound_types/wound_types_v6_mayoclinic";
import { 
	woundTypesV5LHC 
} from "../wound_types/wound_types_v5_lhc";

const evaluationsTypes = {
	wound: "Wound",
	["braden,norton_plus,purs"]: "Risk",
	braden: "Braden",
	norton_plus: "Norton Plus",
	purs: "PURS",
	full_body_skin: "Total Skin",
	abpi: "ABPI",
	left_abpi: "Left ABPI",
	right_abpi: "Right ABPI",
	toe_pressure: "Toe Pressure",
	hemoglobin: "HbA1c"
};

export const evaluations = {
	types: {
		...evaluationsTypes
	},
	default: {
		...evaluationsTypes,
		...woundTypesDefault
	},
	v2: {
		...evaluationsTypes,
		...woundTypesDefault
	},
	v3: {
		...evaluationsTypes,
		...woundTypesV3
	},
	v4: {
		...evaluationsTypes,
		...woundTypesV4
	},
	v4Revera: {
		...evaluationsTypes,
		...woundTypesV4Revera
	},
	v5: {
		...evaluationsTypes,
		...woundTypesV5
	},
	v5Bwat: {
		...evaluationsTypes,
		...woundTypesV5
	},
	v5Ashn: {
		...evaluationsTypes,
		...woundTypesV5Ashn
	},
	v5LHC: {
		...evaluationsTypes,
		...woundTypesV5LHC
	},
	v6: {
		...evaluationsTypes,
		...woundTypesV6
	},
	v7: {
		...evaluationsTypes,
		...woundTypesV7
	},
	v8: {
		...evaluationsTypes,
		...woundTypesV8
	},
	ML1: {
		...evaluationsTypes,
		...woundTypesML1
	},
	v6LifeCare: {
		...evaluationsTypes,
		...woundTypesV6LifeCare
	},
	v6MayoClinic: {
		...evaluationsTypes,
		...woundTypesV6MayoClinic
	}
};
