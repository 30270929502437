import { createSelector } from "reselect";

// Selectors
import { createFirstPageEventsForRevisionIdContextSelector } from "src/selectors/context_selectors/events_for_revision_id";
import { eventsResourceSelectors } from "src/selectors/data_selectors";

// Payload generator
import { createEventsGetPayload } from "src/api/events/payload_generators";

// Constants
import { eventTypeFilters } from "src/constants";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api query for the current page - loading events with a revisionId
 * @param {Object} props
 * @param {string} props.revisionId
 * @returns {function} selector
 */
export function createCurrentPageEventsForRevisionIdQuerySelector({
  revisionId
}) {
  return createSelector(
    eventsResourceSelectors.createCurrentPageForContextSelector(
      createFirstPageEventsForRevisionIdContextSelector({ revisionId })
    ),
    pageNumber => {
      const queryParams = createEventsGetPayload({
        revisionId,
        pageNumber,
        eventType: eventTypeFilters.revision
      });
      return buildQueryString(queryParams);
    }
  );
}
