import { createSelector } from "reselect";
import { isPccSelector } from "src/selectors/config_selectors";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";

export const showSignWoundRevisionButtonSelector = createSelector(
  isPccSelector,
  createPolicyForInternalNameSelector(policyInternalNames.canSignAssessments),
  (isPcc, canSignAssessmentsPolicy) => {
    return (
      !isPcc &&
      !!(
        canSignAssessmentsPolicy &&
        canSignAssessmentsPolicy.attributes.policyValue
      )
    );
  }
);
