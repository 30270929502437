import { createSelector } from "reselect";
import { createGetCliniciansPayload } from "src/api/users/payload_generators";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

export function createFirstPageCliniciansContextSelector() {
  return createSelector(() => {
    const payloadObj = createGetCliniciansPayload({
      pageNumber: 0
    });
    const queryString = buildQueryString(payloadObj);
    return queryString;
  });
}
