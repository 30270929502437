import React from "react";
import PropTypes from "prop-types";
import { loadStates } from "src/constants/load_states";

//Containers
import { LegacyPopulationIncidencePrevalenceChartContainer } from "src/containers/legacy_population_incidence_prevalence_chart_container";

//Components
import { LoadingComponent } from "src/components/loading_component";
import { PanelBodyComponent } from "src/components/panel";
import { GridRow, GridColumn } from "src/components/grid";
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";

export class LegacyPopulationEtiologyComponent extends React.PureComponent {
  static propTypes = {
    populationPrevalenceChartLoadState: PropTypes.string.isRequired,
    populationIncidenceChartLoadState: PropTypes.string.isRequired
  };

  render() {
    const {
      populationPrevalenceChartLoadState,
      populationIncidenceChartLoadState
    } = this.props;

    if (
      populationIncidenceChartLoadState == loadStates.loading ||
      populationPrevalenceChartLoadState == loadStates.loading
    ) {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_POPULATION">
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else if (
      populationIncidenceChartLoadState == loadStates.error ||
      populationPrevalenceChartLoadState == loadStates.error
    ) {
      return (
        <FinishRenderTimeTrackComponent
          eventName="RENDER_POPULATION"
          error={true}
        >
          <LoadingComponent />
        </FinishRenderTimeTrackComponent>
      );
    } else {
      return (
        <div>
          <FinishRenderTimeTrackComponent eventName="RENDER_POPULATION">
            <PanelBodyComponent>
              <GridRow>
                <GridColumn xs="12">
                  <LegacyPopulationIncidencePrevalenceChartContainer />
                </GridColumn>
              </GridRow>
            </PanelBodyComponent>
          </FinishRenderTimeTrackComponent>
        </div>
      );
    }
  }
}
