import { connect } from "react-redux";
import { LegacyPopulationEtiologyComponent } from "src/components/legacy_population_tallies_by_etiology_component";
import { flowRight } from "lodash";
import { withLegacyPopulationChartDataLoader } from "src/hoc";

// Selectors
import {
  selectedWoundTypesSelector,
  dateSelectionSelector
} from "src/selectors/section_selectors/population";
import {
  populationIncidenceChartDataLoadStateSelector,
  populationPrevalenceChartDataLoadStateSelector
} from "src/selectors/data_selectors";

// Actions
import { setDateSelection } from "src/actions/section_actions/population";

function mapStateToProps(state) {
  return {
    selectedWoundTypes: selectedWoundTypesSelector(state),
    dateSelection: dateSelectionSelector(state),
    populationPrevalenceChartLoadState: populationPrevalenceChartDataLoadStateSelector(
      state
    ),
    populationIncidenceChartLoadState: populationIncidenceChartDataLoadStateSelector(
      state
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDateSelection(selection) {
      dispatch(setDateSelection(selection));
    }
  };
}

export const LegacyPopulationEtiologyContainer = flowRight(
  withLegacyPopulationChartDataLoader,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LegacyPopulationEtiologyComponent);
