import get from "lodash.get";

import { trackError } from "src/error_tracking";

import normalize from "json-api-normalizer";
import { camelizeKeys } from "humps";

// Constants
import { resourceNames } from "src/constants";

// Actions
import { createResourceActions } from "src/redux_resources_v1";
import { assessmentSchemasResourceActions } from "../assessment_schemas";
import { createLoadDataAction } from "../create_load_data_action";

// Api
import {
  assessmentsApi,
  fetchAssessmentWithAssessmentSchema,
  fetchAssessmentsWithAssessmentSchemas
} from "src/api/assessments";

// Tracking
import { startTimeTrack, finishTimeTrack } from "src/analytics";

export const loadAssessmentsAction = createLoadDataAction({
  resourceName: resourceNames.assessments,
  api: assessmentsApi,
  includedResourceNames: [resourceNames.assessmentSchemas],
  responseNormalizer: data => {
    // The camelize done in "normalize" breaks schema/answer mapping - have to do it with humps
    // /-/ => dont want to camelize uuids
    let normalizedData = normalize(data, {
      camelizeKeys: false
    });

    normalizedData = camelizeKeys(normalizedData, (key, convert, options) => {
      return /-/.test(key) ? key : convert(key, options);
    });

    return normalizedData;
  }
});

export const loadAllAssessmentsAction = createLoadDataAction({
  resourceName: resourceNames.assessments,
  api: assessmentsApi,
  includedResourceNames: [resourceNames.assessmentSchemas],
  responseNormalizer: data => {
    // The camelize done in "normalize" breaks schema/answer mapping - have to do it with humps
    // /-/ => dont want to camelize uuids
    let normalizedData = normalize(data, {
      camelizeKeys: false
    });

    normalizedData = camelizeKeys(normalizedData, (key, convert, options) => {
      return /-/.test(key) ? key : convert(key, options);
    });

    return normalizedData;
  },
  sendAdditionalActions: (dispatch, data, additionalActions) => {
    additionalActions.forEach(action => {
      dispatch(action(data));
    });
  }
});

const resourceActions = createResourceActions("assessments");

/**
 * Load Assessment and Assessment Schema for a set of assessment ids
 * @param {array} assessmentIds
 * @returns {Function} thunk action
 */
export function loadAssessmentWithSchemasAction(assessmentId) {
  return dispatch => {
    startTimeTrack("API_GET_ASSESSMENT_WITH_SCHEMAS");

    dispatch(resourceActions.loadingForContextAction(assessmentId));

    return fetchAssessmentWithAssessmentSchema(assessmentId)
      .then(response => {
        finishTimeTrack("API_GET_ASSESSMENT_WITH_SCHEMAS");

        // Check if nested assessments need to be loaded
        let needsNestedLoad = false;
        let components;
        const included = response.data.included;
        if (included && included.length) {
          const schema = included[0];
          components = get(schema, "attributes.schema_json.components");
          if (components && !Array.isArray(components)) {
            components = Object.values(components);
          }
          needsNestedLoad = !!components;
        }

        let loadAllAssessmentsPromise;
        if (needsNestedLoad) {
          loadAllAssessmentsPromise = Promise.all([
            response,
            fetchAssessmentsWithAssessmentSchemas(components)
          ]);
        } else {
          loadAllAssessmentsPromise = Promise.all([response]);
        }

        // Handle loading single assessment or assessment with nested assessments
        return loadAllAssessmentsPromise.then(responses => {
          // The camelize done in "normalize" breaks schema/answer mapping - have to do it with humps
          // /-/ => dont want to camelize uuids
          let normalizedData = responses.map(r => {
            return normalize(r.data, {
              camelizeKeys: false
            });
          });

          normalizedData = camelizeKeys(
            normalizedData,
            (key, convert, options) => {
              return /-/.test(key) ? key : convert(key, options);
            }
          );

          const assessments = normalizedData.reduce((agg, norm) => {
            return {
              ...agg,
              ...norm.assessments
            };
          }, {});

          const assessmentSchemas = normalizedData.reduce((agg, norm) => {
            return {
              ...agg,
              ...norm.assessmentSchemas
            };
          }, {});

          dispatch(
            assessmentSchemasResourceActions.populateAction({
              contexts: assessmentId,
              data: assessmentSchemas
            })
          );

          dispatch(
            resourceActions.populateAction({
              contexts: assessmentId,
              data: assessments
            })
          );
        });
      })
      .catch(err => {
        dispatch(resourceActions.loadErrorForContextAction(assessmentId));
        trackError(err);
        finishTimeTrack("API_GET_ASSESSMENT_WITH_SCHEMAS", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
      });
  };
}
