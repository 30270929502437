import get from "lodash.get";

/**
 * Generate a string to encode into a QR Code for patient access from the app
 * @param {Object} patient the patient
 * @returns {string}
 */
export function swiftQrCodeStringFromPatient(patient) {
  const shareToken = get(patient, "attributes.shareToken", "");
  return `https://swiftmedical.io/patients?share_token=${shareToken}`;
}
