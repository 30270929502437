import { createSelector } from "reselect";

/**
 * create a selector to get the api context for admin units
 * @returns {function} selector
 */
export function createAdminUnitsContextSelector() {
  return createSelector(
    () => {
      return "adminUnits";
    },
    context => {
      return context;
    }
  );
}
