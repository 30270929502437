import { woundsActionTypes } from "src/action_types/section_action_types";

/**
 * Action creator for setting selected wound list tab
 * @param {string} progressValue selected progress label (ex. new, stable)
 * @return {Object} Action
 */
export function setSelectedProgressFilterAction(progressValue) {
  return {
    type: woundsActionTypes.setSelectedProgressFilter,
    payload: progressValue
  };
}
