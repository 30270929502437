import { createSelector } from "reselect";

// Selectors
import { isSwiftAdminSelector } from "src/selectors/config_selectors/is_swift_admin_selector";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

// Constants
import { policyValues, policyInternalNames } from "src/constants";

/**
 * Selector to determine if patient create is enabled
 */
export const patientCreateEnabledSelector = createSelector(
  isSwiftAdminSelector,
  createPolicyForInternalNameSelector(policyInternalNames.patientAccess),
  (isSwiftAdmin, policy) => {
    return (
      isSwiftAdmin ||
      (policy && policy.attributes.policyValue.includes(policyValues.create))
    );
  }
);
