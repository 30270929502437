/**
 * Create the payload for GETing assessment summaries
 * @param {Object} options
 * @param {number} options.pageNumber
 */
export function createGetPayload({
  woundRevisionIds,
  pageNumber,
  pageLimit,
  selectedLocationIds,
  answers,
  patient_state,
  lock_state,
  sign_state,
  name_or_mrn_or_code,
  user_id,
  startAt,
  endAt,
  sortEnabled,
  with_patient_users,
  selectedPayersIds
}) {
  return {
    filter: {
      ...(lock_state && { lock_state }),
      ...(sign_state && { sign_state }),
      ...(patient_state && { patient_state }),
      ...(with_patient_users && {
        with_patient_users
      }),
      ...(selectedPayersIds &&
        selectedPayersIds.length && { payer_ids: selectedPayersIds.join() }),
      ...(woundRevisionIds &&
        woundRevisionIds.length && {
          resource_id: woundRevisionIds.join(),
          resource_type: "Revision"
        }),
      ...(selectedLocationIds &&
        selectedLocationIds.length && { location_id: selectedLocationIds }),
      ...(answers && answers.length && { answers }),
      ...(name_or_mrn_or_code && { name_or_mrn_or_code }),
      user_id,
      ...(startAt && {
        patient_admitted_and_created_between: `${startAt}, ${endAt}`
      })
    },
    ...(sortEnabled && { sort: sortEnabled }),
    page:
      pageNumber >= 0 && pageLimit
        ? {
            offset: pageNumber * pageLimit,
            limit: pageLimit
          }
        : undefined
  };
}
