import { dataActionTypes } from "src/action_types/data_action_types";
import { loadStates } from "src/constants/load_states";

const defaultState = {
  loadState: loadStates.loading,
  data: {
    timeIntervals: [],
    newData: [],
    worseData: [],
    stalledData: [],
    stableData: [],
    betterData: [],
    unknownData: []
  }
};

/**
 * Reducer for wound assessment progress chart data
 */
export function woundAssessmentProgressChartDataReducer(
  state = defaultState,
  action
) {
  switch (action.type) {
    case dataActionTypes.populateWoundAssessmentProgressChartData:
      return { loadState: loadStates.loaded, data: action.payload };

    case dataActionTypes.woundAssessmentProgressChartDataLoading:
      return { ...state, loadState: loadStates.loading };

    case dataActionTypes.woundAssessmentProgressChartDataLoadError:
      return { ...state, loadState: loadStates.error };

    default:
      return state;
  }
}
