import { combineReducers } from "redux";
import { checkedFacilityIdsReducer } from "./checked_facility_ids_reducer";
import { checkedOrganizationIdsReducer } from "./checked_organization_ids_reducer";
import { selectedPartnerReducer } from "./selected_partner_reducer";
import { selectedPatientTablePageNumberReducer } from "./selected_patient_table_page_number_reducer";
import { selectedPatientTablePageLimitReducer } from "./selected_patient_table_page_limit_reducer";
import { selectedUserTablePageNumberReducer } from "./selected_user_table_page_number_reducer";
import { selectedUserTablePageLimitReducer } from "./selected_user_table_page_limit_reducer";
import { admissionStatusFilterReducer } from "./admission_status_filter_reducer";
import { monitorAtHomeStatusFilterReducer } from "./monitor_at_home_status_filter_reducer";
import { rulesTabReducer } from "./rules_tab_reducer";
import { selectedWoundAlertRulesTablePageLimitReducer } from "./selected_wound_alert_rules_table_page_limit_reducer";
import { selectedWoundAlertRulesTablePageNumberReducer } from "./selected_wound_alert_rules_table_page_number_reducer";
import { selectedRiskAlertRulesTablePageLimitReducer } from "./selected_risk_alert_rules_table_page_limit_reducer";
import { selectedRiskAlertRulesTablePageNumberReducer } from "./selected_risk_alert_rules_table_page_number_reducer";

//Section Reducer Factory
import { createSectionSearchFiltersReducer } from "src/ui_processors/global/reducer_creators";

//Section UI Processor Reducers
import SingleValue from "src/ui_processors/single_value";

// Constants
import { sections } from "src/constants";

export const adminSectionReducers = combineReducers({
  checkedOrganizationIds: checkedOrganizationIdsReducer,
  checkedFacilityIds: checkedFacilityIdsReducer,
  selectedPartnerId: selectedPartnerReducer,
  selectedPatientTablePageNumber: selectedPatientTablePageNumberReducer,
  selectedPatientTablePageLimit: selectedPatientTablePageLimitReducer,
  selectedUserTablePageNumber: selectedUserTablePageNumberReducer,
  selectedUserTablePageLimit: selectedUserTablePageLimitReducer,
  admissionStatusFilter: admissionStatusFilterReducer,
  monitorAtHomeStatusFilter: monitorAtHomeStatusFilterReducer,
  rulesTab: rulesTabReducer,
  searchFilters: createSectionSearchFiltersReducer(sections.admin),
  selectedUserRoleId: SingleValue.createReducer("selectedUserRoleId", ""),
  selectedUserState: SingleValue.createReducer("selectedUserState", ""),
  selectedWoundAlertRulesTablePageNumber: selectedWoundAlertRulesTablePageNumberReducer,
  selectedWoundAlertRulesTablePageLimit: selectedWoundAlertRulesTablePageLimitReducer,
  selectedRiskAlertRulesTablePageNumber: selectedRiskAlertRulesTablePageNumberReducer,
  selectedRiskAlertRulesTablePageLimit: selectedRiskAlertRulesTablePageLimitReducer
});
