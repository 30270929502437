import { apiPageLimits } from "src/constants";

export function createEventsGetPayload({
  revisionId,
  assessmentAnswerGroupId,
  pageNumber,
  eventType
}) {
  return {
    filter: {
      revision_id: revisionId,
      assessment_answer_group_id: assessmentAnswerGroupId,
      event_type: eventType
    },
    page: {
      offset: pageNumber * apiPageLimits.events,
      limit: apiPageLimits.events
    }
  };
}

export function createLatestEventGetPayload({
  revisionId,
  assessmentAnswerGroupId,
  eventType
}) {
  return {
    // Note: filter orders matter as API runs them based on the order requested
    filter: {
      revision_id: revisionId,
      assessment_answer_group_id: assessmentAnswerGroupId,
      event_type: eventType,
      latest_event_by_revisions: true
    },
    page: {
      offset: 0
    }
  };
}
