// React
import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
//css
import styles from "../style.module.less";
export class PopulationTallyTableHeaderByLocationComponent extends Component {
  // ------------------- Facility Header ------------------- //

  getFacilityHeader() {
    return (
      <th className={styles["right-border"]}>
        <span>Facility</span>
      </th>
    );
  }

  // ------------------- Unit Header ------------------- //

  getUnitHeader() {
    return (
      <th className={styles["right-border"]}>
        <span>Unit</span>
      </th>
    );
  }
  // ------------------- AverageCensusinSelectedTime Header ------------------- //

  getAverageCensusinSelectedTimePeriodHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              Average number of patients in the population during the selected
              time period
            </Tooltip>
          }
        >
          <span>Average Census in Selected Time Period</span>
        </OverlayTrigger>
      </th>
    );
  }
  // ------------------- Floor Header ------------------- //

  getFloorHeader() {
    return (
      <th className={styles["right-border"]}>
        <span>Floor</span>
      </th>
    );
  }
  // ------------------- New Wound Patients Header ------------------- //

  getNewWoundPatientsHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">Number of patients with new wounds</Tooltip>
          }
        >
          <span>New Wound Patients</span>
        </OverlayTrigger>
      </th>
    );
  }

  // ------------------- Wound Patients Header ------------------- //

  getWoundPatientsHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">Number of patients with wounds</Tooltip>
          }
        >
          <span>Wound Patients</span>
        </OverlayTrigger>
      </th>
    );
  }

  // ------------------- Incidence Header ------------------- //

  getIncidenceHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              Percentage of patients with new wounds in the time period
            </Tooltip>
          }
        >
          <span>Incidence (%)</span>
        </OverlayTrigger>
      </th>
    );
  }

  // ------------------- Prevalence Patients Header ------------------- //

  getPrevalenceHeader() {
    return (
      <th className={styles["right-border"]}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              Percentage patients with wounds in the time period
            </Tooltip>
          }
        >
          <span>Prevalence (%)</span>
        </OverlayTrigger>
      </th>
    );
  }

  // ------------------- Render ------------------- //

  render() {
    return (
      <tr>
        {this.getFacilityHeader()}
        {this.getUnitHeader()}
        {this.getFloorHeader()}
        {this.getAverageCensusinSelectedTimePeriodHeader()}
        {this.getNewWoundPatientsHeader()}
        {this.getWoundPatientsHeader()}
        {this.getIncidenceHeader()}
        {this.getPrevalenceHeader()}
      </tr>
    );
  }
}
