import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { reduxForm } from "redux-form";
import { setUserAuthConfig } from "src/actions/session_actions";

import { UserAuthCodeComponent } from "src/components/user_auth_code_component";
import { create } from "src/actions/flashMessageActions";
import { defaultNavigationPageSelector } from "src/selectors/ui_config_selectors/global";
import { routeQueryParamsSelector } from "src/selectors/router_selectors";

function mapDispatchToProps(dispatch) {
  return {
    setAuthConfig: payload => {
      return dispatch(setUserAuthConfig(payload));
    },
    displayErrorMessage: () => {
      return dispatch(create("Incorrect AuthConfig", "error"));
    }
  };
}

function mapStateToProps(state) {
  return {
    defaultNavigationPage: defaultNavigationPageSelector(state),
    orgCode: routeQueryParamsSelector(state).orgCode
  };
}

export const UserAuthCodeContainer = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "userAuthCodeForm"
  })
)(UserAuthCodeComponent);
