import get from "lodash.get";

import { getSubtypeStringForAnswersJson } from "src/utils/get_wound_subtype_string";
import { getWoundNumberAndTypeString } from "src/utils/get_wound_number_and_type_string";

/**
 * Get the wound type string
 * @param {answersJson} obj
 * @param {patientWoundNumber} numberString
 * @param {includePressureUnstageable} Flag to include pressure unstageable
 * @param {localizationDictionary}
 * @param {subtypeKeysForSchema}
 * @returns {string}
 */
export const getWoundNumberTypeSubtypeString = ({
  answersJson,
  patientWoundNumber,
  includePressureUnstageable,
  localizationDictionary,
  subtypeKeysForSchema
}) => {
  let woundString = "";

  const woundTypeString = getWoundNumberAndTypeString({
    answersJson,
    patientWoundNumber,
    localizationDictionary
  });
  woundString += woundTypeString;

  const woundType = get(answersJson, "woundType", null);
  if (!woundType) return woundString;

  //Subtype
  const woundSubtypeString = getSubtypeStringForAnswersJson({
    woundType: woundType,
    answersJson,
    localizationDictionary,
    includePressureUnstageable,
    subtypeKeysForSchema
  });

  if (woundSubtypeString) {
    woundString += ` - ${woundSubtypeString}`;
  }

  return woundString;
};
