import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "react-bootstrap";

// Components
import { SavedFilterModalContentComponent } from "../saved_filter_modal_content_component";
import { CheckboxList, CheckboxListItem } from "../checkbox_list";

export class SideBarSavedFiltersComponent extends React.PureComponent {
  static propTypes = {
    filters: PropTypes.array,
    selectedFilterId: PropTypes.string,
    sendSelectFilterAction: PropTypes.func.isRequired,
    sendDeselectFilterAction: PropTypes.func.isRequired
  };

  state = {
    showEditFilterModal: false
  };

  filterSelected = filter => {
    const {
      selectedFilterId,
      sendSelectFilterAction,
      sendDeselectFilterAction
    } = this.props;
    if (selectedFilterId == filter.id) {
      sendDeselectFilterAction();
    } else {
      sendSelectFilterAction(filter);
    }
  };

  showEditFilter = () => {
    this.setState({
      showEditFilterModal: true
    });
  };

  hideEditFilter = () => {
    this.setState({
      showEditFilterModal: false
    });
  };

  render() {
    const { filters, selectedFilterId } = this.props;

    const content = filters.map(filter => {
      return (
        <CheckboxListItem
          key={filter.id}
          label={filter.attributes.title}
          value={filter}
          checked={selectedFilterId ? filter.id == selectedFilterId : false}
          onChange={this.filterSelected}
          rightIcon={filter.id == selectedFilterId ? "pencil" : undefined}
          onRightIconClicked={this.showEditFilter}
        />
      );
    });

    return (
      <div>
        <CheckboxList>{content}</CheckboxList>
        <Modal
          show={this.state.showEditFilterModal}
          onHide={this.showEditFilter}
          bsSize={"lg"}
        >
          <ModalBody>
            <SavedFilterModalContentComponent cancel={this.hideEditFilter} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
