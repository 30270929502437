import axios from "axios";
import { apiFactory } from "../api_factory";
import qs from "qs";
import { registerForCancellation } from "../cancellation";

export const patientsApi = apiFactory({ path: "/api/v4/patients" });

/**
 * Fetch list of patients from the server
 * TODO: pagination and search bar integration with api.
 * TODO: add organization and/or location filters.
 * @param {Object} filters the filters for facilities
 * @param {string} filters.facility_ids
 * @returns {Promise}
 */
export function fetchPatients(filters) {
  const facility_ids = filters.facility_ids
    ? filters.facility_ids.join()
    : undefined;

  const filter = {
    facility_id: facility_ids
  };

  const query = {
    filter: facility_ids ? filter : undefined,
    include: "studies"
  };

  const url = `/api/v4/patients?${qs.stringify(query)}`;

  const config = {
    cancelToken: registerForCancellation("patients")
  };

  return axios.get(url, config);
}

/**
 * create patient from the admin section
 * @param {Object} payload - responsible for sending the data to api.
 * @return {Promise}
 */
export function createPatient(payload) {
  let config = {
    headers: {
      "Content-Type": "application/vnd.api+json"
    }
  };
  return axios.post("/api/v4/patients", payload, config);
}

/**
 * update patient from the admin section
 * @param {Object} payload - responsible for sending the data to api.
 * @return {Promise}
 */
export function updatePatient(payload) {
  let config = {
    headers: {
      "Content-Type": "application/vnd.api+json"
    }
  };
  return axios.patch("/api/v4/patients/" + payload.data.id, payload, config);
}

/**
 * Fetch list of patients by query string
 * @param {payload} payload for filter the records
 * @return {Promise}
 */
export function fetchPatientsByQueryString(payload) {
  const config = {
    cancelToken: registerForCancellation("patients"),
    headers: {
      "X-HTTP-Method-Override": "GET",
      "Content-Type": "application/vnd.api+json"
    }
  };
  return axios.post("/api/v4/patients?include=patient_user", payload, config);
}

/**
 * Fetch patient
 * @param {patientId} patientId
 * @returns {Promise}
 */
export function fetchPatient(patientId) {
  const query = {
    include: "studies"
  };

  const url = `/api/v4/patients/${patientId}?${qs.stringify(query)}`;
  return axios.get(url);
}
