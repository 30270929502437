import { connect } from "react-redux";
import { WoundsSectionComponent } from "src/components/wounds_section_component";

// Selectors
import { selectedDateSelector } from "src/selectors/section_selectors/wounds";
import { woundsExportPdfEnabledSelector } from "src/selectors/ui_config_selectors/wounds";
import { selectedOrganizationSelector } from "src/selectors/data_selectors";
import { woundAssessmentProgressChartDefinitionSelector } from "src/selectors/chart_definition_selectors";
import { woundAssessmentsResourceSelectors } from "src/selectors/data_selectors";
import { assessmentsFirstPageContextAllProgressValuesSelector } from "src/selectors/context_selectors/wounds";
import { progressValuesWithoutHealedSelector } from "src/selectors/constant_selectors";
import { isWoundsClinicalChartOptimizationEnabledSelector } from "src/selectors/ui_config_selectors/wounds";
import {
  brandingSelector,
  brandedLogoPngSelector
} from "src/selectors/ui_config_selectors/global";

// Actions
import { selectDateAction } from "src/actions/section_actions/wounds";
import SingleValueReduxUtil from "src/ui_processors/single_value";

const selectedTabActions = SingleValueReduxUtil.createActionCreators(
  "selectedTab"
);

function mapStateToProps(state) {
  return {
    selectedDate: selectedDateSelector(state),
    woundsExportPdfEnabled: woundsExportPdfEnabledSelector(state),
    selectedOrganization: selectedOrganizationSelector(state),
    chartDefinition: woundAssessmentProgressChartDefinitionSelector(state),
    counts: woundAssessmentsResourceSelectors.createRecordCountSelector(
      assessmentsFirstPageContextAllProgressValuesSelector
    )(state),
    progressValues: progressValuesWithoutHealedSelector(state),
    isWoundsClinicalChartOptimizationEnabled: isWoundsClinicalChartOptimizationEnabledSelector(
      state
    ),
    branding: brandingSelector(state),
    brandedLogoPng: brandedLogoPngSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectDate(date) {
      dispatch(selectDateAction(date));
    },
    selectTab(tab) {
      dispatch(selectedTabActions.setValue(tab));
    }
  };
}

export const WoundsSectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WoundsSectionComponent);
