import { createSelector } from "reselect";
import moment from "moment";
import { createLatestFramesForRevisionsSelector } from "src/selectors/data_selectors/frames/create_latest_frames_for_revisions_selector";
import { regionsResourceSelectors } from "../regions_resource_selectors";

/**
 * Create a selector to get the most recent region from a given revision
 * @param {Object} revision
 * @returns {Function} selector
 */ export function createLatestRegionsForRevisionsSelector({ revisions }) {
  return createSelector(
    createLatestFramesForRevisionsSelector({ revisions }),
    regionsResourceSelectors.dataSelector,
    (framesByRevisionId, regions) => {
      const sortedRegions = Object.values(regions).sort((r1, r2) => {
        return moment(r1.attributes.createdAt).isAfter(r2.attributes.createdAt)
          ? -1
          : 1;
      });

      return Object.keys(framesByRevisionId).reduce((agg, revId) => {
        const frame = framesByRevisionId[revId];
        const filteredRegions = sortedRegions.filter(region => {
          return region.attributes.frameId == frame.id;
        });
        agg[revId] = filteredRegions.length ? filteredRegions[0] : undefined;
        return agg;
      }, {});
    }
  );
}
