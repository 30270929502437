import React from "react";
import PropTypes from "prop-types";
import i18n from "src/translations";
import { FieldArray } from "redux-form";

//Components
import { Button } from "src/ui-kit";
import { FlexBox, FlexItem } from "src/components/ui_kit";

//Styles
import styles from "./style.module.less";

/**
 * Redux Form Component that allows adding and removing multiple selections of options passed in as prop'
 * Each option in the options array should be an object with id and text (Use Selector if necessary)
 */

export class FormMultipleOptionsSelectionComponent extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired, //Store name of form field
    titleKey: PropTypes.string, //Title above
    labelKey: PropTypes.string, //Label to the left
    selectActionPlaceholderKey: PropTypes.string, //Selection placehoder text key
    addActionKey: PropTypes.string,
    removeActionKey: PropTypes.string,
    emptyDefault: PropTypes.bool,
    options: PropTypes.array.isRequired
  };

  state = {
    selectedOption: ""
  };

  componentDidMount() {
    const { emptyDefault, options } = this.props;
    if (options) {
      this.setState({
        selectedOption: emptyDefault ? "" : options[0].id
      });
    }
  }

  onSelect = e => {
    this.setState({
      selectedOption: e.target.value
    });
  };

  onAddOption = optionsToRender => {
    const { emptyDefault } = this.props;
    if (optionsToRender) {
      this.setState({
        selectedOption: emptyDefault ? "" : optionsToRender[0].id
      });
    }
  };

  renderSelectionDropdownAndSelectedOptions = ({
    fields,
    className,
    selectedOption
  }) => {
    const { emptyDefault, options, addActionKey, removeActionKey } = this.props;
    const selectedOptions = fields.getAll();
    const optionsToRender = options.filter(
      option => !selectedOptions.includes(option.id)
    );
    if (emptyDefault) {
      optionsToRender.unshift({ id: "", text: "" });
    }

    return (
      <div>
        <div className={styles["dropdown-wrapper"]}>
          <FlexBox spacing="small">
            <FlexItem>
              <select
                className={`${className} ${styles["select-option"]}`}
                onChange={this.onSelect}
                value={selectedOption}
              >
                {optionsToRender.map(option => (
                  <option
                    className={styles["select-option"]}
                    key={option.id}
                    value={option.id}
                  >
                    {option.text}
                  </option>
                ))}
              </select>
            </FlexItem>
            <FlexItem>
              <Button
                className={`btn btn-default ${styles["option-button"]}`}
                onClick={() => {
                  fields.push(selectedOption);
                  this.onAddOption(optionsToRender);
                }}
                type="button"
                disabled={selectedOption === ""}
              >
                {i18n.t(`actions.${addActionKey || "add"}`)}
              </Button>
            </FlexItem>
          </FlexBox>
        </div>

        {selectedOptions.map((optionId, index) => {
          let optionText = (
            options.find(option => option.id === optionId) || { text: "" }
          ).text;

          return (
            <div className={styles["selected-option-wrapper"]} key={index}>
              <FlexBox spacing="small">
                <FlexItem>
                  <p className={styles["selected-option"]}>{optionText}</p>
                </FlexItem>
                <FlexItem>
                  <Button
                    className={`btn btn-default ${styles["option-button"]}`}
                    onClick={() => fields.remove(index)}
                    type="button"
                  >
                    {i18n.t(`actions.${removeActionKey || "remove"}`)}
                  </Button>
                </FlexItem>
              </FlexBox>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <FieldArray
        className="form-control"
        name={this.props.name}
        selectedOption={this.state.selectedOption}
        component={this.renderSelectionDropdownAndSelectedOptions}
      />
    );
  }
}
