import { normalize as normalizr } from "normalizr";
import normalize from "json-api-normalizer";
import { camelizeKeys } from "humps";
import { trackError } from "src/error_tracking";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Api
import { fetchRevisionsForSeries } from "src/api/legacy_revisions";

// Model
import { mapMeasurementsToValues } from "src/models/revisions";

// Schema
import { revisionSchema } from "src/schema";

// Actions
import {
  framesResourceActions,
  regionsResourceActions,
  assessmentAnswersResourceActions,
  payerRevisionsResourceActions
} from "../";

// Constants
import { loadStates } from "src/constants/load_states";

// Tracking
import { startTimeTrack, finishTimeTrack } from "src/analytics";

export const revisionsResourceActions = createResourceActions("revisions");

/**
 * Action creator for loading revisions for a series
 * @param {string} seriesId
 * @param {Object} options
 * @param {boolean} options.loadMore
 * @param {boolean} options.refresh
 */
export function loadRevisionsForSeriesAction(seriesId, lockState, options) {
  return dispatch => {
    let loadState;
    if (!options) {
      loadState = loadStates.loading;
    } else if (options.loadMore) {
      loadState = loadStates.loadingMore;
    } else if (options.refresh) {
      loadState = loadStates.refreshing;
    } else {
      loadState = loadStates.loading;
    }
    startTimeTrack("API_GET_SERIES_REVISIONS");
    dispatch(
      revisionsResourceActions.setLoadStateForContextAction(seriesId, loadState)
    );

    return fetchRevisionsForSeries(
      seriesId,
      lockState,
      options && options.loadMore
    )
      .then(response => {
        finishTimeTrack("API_GET_SERIES_REVISIONS");

        let revisions = normalize(response.data).revisions;
        let payerRevisions = normalize(response.data).payerRevisions;
        revisions = Object.keys(revisions).reduce((agg, key) => {
          agg[key] = mapMeasurementsToValues(revisions[key]);
          return agg;
        }, {});

        const revisionsForNormalizr = camelizeKeys(response.data.data).map(
          r => {
            return { ...r.attributes, id: r.id };
          }
        );

        const entities = normalizr(revisionsForNormalizr, [revisionSchema])
          .entities;

        // parse json blobs on region
        if (entities.regions) {
          entities.regions = Object.keys(entities.regions).reduce((agg, id) => {
            let region = entities.regions[id];

            region.measurement = region.measurement
              ? JSON.parse(region.measurement)
              : {};

            region.undermining = region.undermining
              ? JSON.parse(region.undermining)
              : {};

            region.tunneling = region.tunneling
              ? JSON.parse(region.tunneling)
              : {};

            region.polygons = region.polygons
              ? JSON.parse(region.polygons)
              : {};

            region.depth = region.depth ? JSON.parse(region.depth) : {};

            agg[id] = region;
            return agg;
          }, {});
        }

        if (entities.regions) {
          dispatch(
            regionsResourceActions.populateAction({
              data: convertToJsonApi(entities.regions, "regions")
            })
          );
        }

        if (entities.frames) {
          dispatch(
            framesResourceActions.populateAction({
              data: convertToJsonApi(entities.frames, "frames")
            })
          );
        }

        if (entities.assessmentAnswers) {
          dispatch(
            assessmentAnswersResourceActions.populateAction({
              data: convertToJsonApi(
                entities.assessmentAnswers,
                "assessmentAnswers"
              )
            })
          );
        }

        if (options && options.loadMore) {
          dispatch(
            revisionsResourceActions.populateAction({
              contexts: seriesId,
              data: revisions,
              ids: Object.keys(revisions),
              links: response.data.links,
              meta: camelizeKeys(response.data.meta)
            })
          );
        } else {
          dispatch(
            revisionsResourceActions.replaceAction({
              contexts: seriesId,
              data: revisions,
              ids: Object.keys(revisions),
              links: response.data.links,
              meta: camelizeKeys(response.data.meta)
            })
          );
        }

        if (payerRevisions) {
          dispatch(
            payerRevisionsResourceActions.populatePostAction({
              data: payerRevisions
            })
          );
        }
      })
      .catch(err => {
        if (err.message == "cancel") return;
        dispatch(
          revisionsResourceActions.setLoadStateForContextAction(
            seriesId,
            loadStates.error
          )
        );
        dispatch(revisionsResourceActions.loadErrorForContextAction(seriesId));
        trackError(err);
        finishTimeTrack("API_GET_SERIES_REVISIONS", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
      });
  };
}

function convertToJsonApi(normalizedResource, type) {
  return Object.values(normalizedResource).reduce((acc, resource) => {
    acc[resource.id] = {
      id: resource.id,
      type,
      attributes: {
        ...resource
      }
    };

    delete acc[resource.id].attributes.id;

    return acc;
  }, {});
}
