import { createSelector } from "reselect";
import { isSwiftAdminSelector } from "../is_swift_admin_selector";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

/**
 * Memoized selector to determine if admin tab visisble or not,
 * returns false if partnerId of current user is null and user not swift admin or does not have permissions, true otherwise
 */

export const adminTabEnabledSelector = createSelector(
  currentUserSelector,
  isSwiftAdminSelector,
  createPolicyForInternalNameSelector("allow_admin_dashboard"),
  (currentUser, isSwiftAdmin, adminEnabledPolicy) => {
    if (isSwiftAdmin) {
      return true;
    } else if (!currentUser || !currentUser.attributes.partnerId) {
      return false;
    } else if (
      adminEnabledPolicy &&
      adminEnabledPolicy.attributes.policyValue
    ) {
      return true;
    }

    return false;
  }
);
