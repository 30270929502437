/**
 * Function to get the rounded measurement value to 2 dp
 * @param {number} measurement //the raw measurement value
 * @returns {string} the rounded measurement string
 */
export function roundMeasurementToTwoDecimalPlaces(measurement) {
  let roundedMeasurement = null;
  //Measurment has to be of type number, and cannot be 0
  if (measurement && typeof measurement === "number" && measurement > 0) {
    roundedMeasurement = measurement.toFixed(2);
  }
  return roundedMeasurement;
}
