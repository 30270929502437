import moment from "moment";
export const timeFormats = {
  BIRTHDAY: "MMM Do YYYY",
  ASSESSMENT_DATE: "MMM Do YYYY",
  HISTORY_DATE: "MMM Do YYYY"
};

export const timeDiffMinutes = function(updatedAt?: string) {
  return (Date.now() - Date.parse(updatedAt || undefined)) / 1000 / 60;
};

export const ageThisMoment = function(time1, time2) {
  // Customize locale
  moment.locale("en-age", {
    relativeTime: {
      future: "in %s",
      past: "%s old",
      s: "Minutes",
      m: "Minutes",
      mm: "%d minutes",
      h: "1 hour",
      hh: "%d hours",
      d: "1 day",
      dd: "%d days",
      M: "1 month",
      MM: "%d months",
      y: "1 year",
      yy: "%d years"
    }
  });
  // Generate age string
  let result = "Age Unknown";
  if (time1) {
    if (time2) {
      result = moment(time2).from(time1);
    } else {
      result = moment(time1).fromNow();
    }
  }
  // Reset locale back to EN
  moment.locale("en");
  return result;
};
