import React from "react";
import PropTypes from "prop-types";

// Components
import { ChartComponent } from "src/components/chart_component";
import { GridRow, GridColumn } from "src/components/grid";

/**
 * Render a chart.js chart showing population trends over time grouped by population progress
 */
export class PopulationIncidencePrevalenceChartComponent extends React.PureComponent {
  static propTypes = {
    chartDefinition: PropTypes.object.isRequired
  };
  render() {
    return (
      <div>
        <GridRow>
          <GridColumn xs="12">
            <div>
              <ChartComponent
                chartDefinition={this.props.chartDefinition}
                width={750}
                height={400}
              />
            </div>
          </GridColumn>
        </GridRow>
      </div>
    );
  }
}
