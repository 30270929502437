import {
	overallProgressDefault
} from "./overall_progress_default";
import {
	overallProgressV5Ashn
} from "./overall_progress_v5_ashn";

export const overallProgress = {
	default: overallProgressDefault,
	ML1: overallProgressDefault,
	v2: overallProgressDefault,
	v3: overallProgressDefault,
	v4: overallProgressDefault,
	v4Revera: overallProgressDefault,
	v5: overallProgressDefault,
	v5Bwat: overallProgressDefault,
	v5Ashn: overallProgressV5Ashn,
	v5LHC: overallProgressDefault,
	v6: overallProgressDefault,
	v7: overallProgressDefault,
	v8: overallProgressDefault,
	v6LifeCare: overallProgressDefault,
	v6MayoClinic: overallProgressDefault
};
