import { createHealingIndexListQueryForSeriesIdsSelector } from "src/selectors/api_selectors/global/healing_index/create_healing_index_list_query_for_series_ids_selector";
import { createSelector } from "reselect";

export function createHealingIndexListContextSelector() {
  return createSelector(
    createHealingIndexListQueryForSeriesIdsSelector(),
    queries => {
      return queries;
    }
  );
}
