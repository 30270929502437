import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

import { GridRow, GridColumn } from "src/components/grid";
import { Button, Breadcrumbs } from "src/ui-kit";

// Styles
import styles from "./style.module.less";

//Translations
import i18n from "src/translations";

import { ThermalAssessmentListComponent } from "src/components/thermal_assessment_list_component";

export class ThermalDetailsComponent extends React.PureComponent {
  static propTypes = {
    breadcrumbsEnabled: PropTypes.bool.isRequired,
    goBack: PropTypes.func.isRequired,
    patientId: PropTypes.string
  };

  renderControlNavbar() {
    const { patient } = this.props;
    const firstName = get({ patient }, "patient.attributes.firstName", ""),
      lastName = get({ patient }, "patient.attributes.lastName", "");

    return (
      <GridRow className="panel-headline">
        <GridColumn xs="1">
          <Button
            onClick={this.props.goBack}
            className={styles["margintop8px"]}
          >
            Back
          </Button>
        </GridColumn>
        <GridColumn xs="11">
          <h1 className={styles["margintop11px"]}>
            {firstName} {lastName}
          </h1>
        </GridColumn>
      </GridRow>
    );
  }

  renderBreadcrumbs() {
    const { patient } = this.props;
    const firstName = get({ patient }, "patient.attributes.firstName", ""),
      lastName = get({ patient }, "patient.attributes.lastName", "");

    return (
      <Breadcrumbs
        className={styles["breadcrumb-headline"]}
        items={[
          {
            text: `${firstName} ${lastName}`,
            onClick: this.props.goBack
          },
          {
            text: `Wound ${i18n.t("text.evaluation")}`,
            active: true
          }
        ]}
      />
    );
  }

  render() {
    const { breadcrumbsEnabled, patientId } = this.props;

    return (
      <div>
        {breadcrumbsEnabled
          ? this.renderBreadcrumbs()
          : this.renderControlNavbar()}
        <h1>Thermal</h1>
        <ThermalAssessmentListComponent patientId={patientId} />
      </div>
    );
  }
}
