import qs from "qs";
import { patientsApi } from "src/api/patients";

/**
 * Async validator
 * @param {Object} values redux form values
 * @returns {Promise}
 */
export function duplicatePatientAsyncValidator(values, dispatch, props) {
  return new Promise((resolve, reject) => {
    if (props.patient) {
      resolve();
    } else if (values.firstName && values.lastName && values.birthDate) {
      const query = qs.stringify({
        filter: {
          first_name: values.firstName,
          last_name: values.lastName,
          birth_at: values.birthDate,
          state: "new,admitted,discharged"
        },
        page: {
          limit: 1,
          offset: 0
        }
      });

      return patientsApi.get({ query: `?${query}` }).then(response => {
        if (response.data.data.length) {
          reject({
            birthDate:
              "A patient with this first name, last name and birth date already exists"
          });
        } else {
          resolve();
        }
      });
    } else {
      resolve();
    }
  }).catch();
}
