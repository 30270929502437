import { createSelector } from "reselect";
import { assessmentSummariesResourceSelectors } from "src/selectors/data_selectors";
import compact from "lodash.compact";

export const createArrayOfSeriesIdsFromAssessmentSummariesSelector = createSelector(
  assessmentSummariesResourceSelectors.dataSelector,
  assessmentSummaries => {
    const assessmentSummariesArray = Object.values(assessmentSummaries);
    return compact(
      assessmentSummariesArray.map(
        assessmentSummariesObj => assessmentSummariesObj.attributes.seriesId
      )
    );
  }
);
