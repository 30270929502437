import { getDetailsTable } from "../get_details_table";

/**
 * Get the content (body) portion of the Assessment PDF, Formatted for pdfMake
 * @param {Object} data
 * @param {Object} data.woundDetailsData - the talliesData
 
 * @return {Object} JSON representation of the pdf content
 */

export function getBody(data) {
  const { woundDetailsData } = data;
  const { options } = data;

  // construct pdf body
  let body = [];
  body.push(getDetailsTable(woundDetailsData, options));

  return [...body];
}
