// Api
import { facilitiesApi } from "src/api/facilities";

// Constants
import { resourceNames } from "src/constants";

// Actions
import { createLoadDataAction } from "../create_load_data_action";
import { createResourceActions } from "src/redux_resources_v1";

export const facilitiesResourceActions = createResourceActions("facilities");

export const loadFacilitiesAction = createLoadDataAction({
  resourceName: resourceNames.facilities,
  api: facilitiesApi
});
