import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";

export const hasAllowEditOtherUsersWorkPermissionSelector = createSelector(
  createPolicyForInternalNameSelector(
    policyInternalNames.allowEditOtherUsersWork
  ),
  allowEditOtherUsersWork => {
    return (
      allowEditOtherUsersWork && allowEditOtherUsersWork.attributes.policyValue
    );
  }
);
