import { createSelector } from "reselect";
import moment from "moment";

//Selectors
import { woundsClinicalChartResourceSelector } from "src/selectors/data_selectors";
import { createAllWeeksWoundsClinicalChartDataContextSelector } from "src/selectors/api_selectors/wounds/wounds_chart";

export const sortedWoundsClinicalChartDataSelector = createSelector(
  woundsClinicalChartResourceSelector.createDataForContextSelector(
    createAllWeeksWoundsClinicalChartDataContextSelector()
  ),
  woundsClinicalChartData => {
    const data = woundsClinicalChartData.map(dataPoint => dataPoint.attributes);
    const dataSortedByDate = data.sort((a, b) => {
      const dateA = a.endTime;
      const dateB = b.endTime;
      return moment(dateB).isBefore(dateA) ? 1 : -1;
    });

    return dataSortedByDate;
  }
);
