import { connect } from "react-redux";
import { isPristine, isSubmitting } from "redux-form";

// Constants
import { reduxFormNames } from "src/constants";

// Components
// import { SavedFilterFormComponent } from "src/components/forms/saved_filter_form_component";
import SavedFilterFormModal from "src/pages/shared/components/saved_filter_form_modal";

// Actions
import {
  postSavedFilterAction,
  patchSavedFilterAction,
  deleteSavedFilterAction
} from "src/actions/data_actions";

// Selectors
import { currentUserSelector } from "src/selectors/config_selectors";
import { savedFiltersResourceSelectors } from "src/selectors/data_selectors";
import { singleFaciltyViewModeSelector } from "src/selectors/ui_config_selectors/global";

// UI Processors
import {
  createSectionSelectors,
  createSectionActions
} from "src/ui_processors";

/**
 * Create a SavedFilterFormContainer for a section
 * @param {string} section
 * @returns {Class} Container
 */

export function createSavedFilterFormContainer(section) {
  const sectionSelectors = createSectionSelectors(section);
  const sectionActions = createSectionActions(section);
  const savedFilterFromRedux = state => {
    const selectedFilterId = sectionSelectors.selectedFilterIdSelector(state);
    return savedFiltersResourceSelectors.createDataForIdSelector(
      selectedFilterId
    )(state);
  };

  function mapStateToProps(state, ownProps) {
    return {
      savedFilter: ownProps.savedFilter || savedFilterFromRedux(state),
      currentFilter: sectionSelectors.filtersSelector(state),
      section,
      singleFacilityId: singleFaciltyViewModeSelector(state),
      currentUser: currentUserSelector(state),
      pristine: isPristine(reduxFormNames.savedFilterForm)(state),
      submitting: isSubmitting(reduxFormNames.savedFilterForm)(state)
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      sendPostSavedFilterAction(payload) {
        return dispatch(postSavedFilterAction({ payload }));
      },
      sendPatchSavedFilterAction(payload) {
        return dispatch(patchSavedFilterAction({ payload }));
      },
      sendDeleteSavedFilterAction(savedFilter) {
        return dispatch(deleteSavedFilterAction({ resource: savedFilter }));
      },
      sendDeselectFilterAction() {
        dispatch(sectionActions.deselectSavedFilter());
      }
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(SavedFilterFormModal);
}
