import { connect } from "react-redux";
import { get } from "lodash";
import { sections } from "src/constants";
import { evaluationsFilterConfigSelector } from "src/selectors/ui_config_selectors/compliance";
import { localizationDictionaryForAllSchemasSelector } from "src/selectors/section_selectors/global";
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";
import { createSectionActions } from "src/ui_processors/global/action_creators";
import LegacyFilterSection from "src/pages/compliance/components/filters/legacy_filter_section";
import {
  woundTypeKeyForAttribute,
  woundTypeKeyForSubtype,
  subtypeKeyValueForAttribute
} from "src/constants";

const sectionSelectors = createSectionSelectors(sections.compliance);
const sectionActions = createSectionActions(sections.compliance);

const getLegacyFilterValues = state => {
  const complianceFilters = get(state, "sections.compliance.filters", []);
  const complianceEvaluationsFilters = complianceFilters.find(
    f => f.key === "evaluations"
  );
  const filterValues = get(complianceEvaluationsFilters, "value", []);
  return filterValues.map(fv => {
    if (fv.key === "assessment_type") {
      return { [fv.key]: fv.value };
    } else {
      let woundOption = {
        assessment_type: "wound",
        [fv.key]: fv.value
      };
      if (woundTypeKeyForAttribute[fv.key]) {
        woundOption.woundType = woundTypeKeyForAttribute[fv.key];
      }
      if (woundTypeKeyForSubtype[fv.key]) {
        woundOption.woundType = woundTypeKeyForSubtype[fv.key];
      }
      if (subtypeKeyValueForAttribute[fv.key]) {
        const subtypeKey = subtypeKeyValueForAttribute[fv.key].key;
        const subtypeValue = subtypeKeyValueForAttribute[fv.key].value;
        woundOption[subtypeKey] = subtypeValue;
      }
      return woundOption;
    }
  });
};

function mapStateToProps(state) {
  return {
    legacyCheckedFilters: getLegacyFilterValues(state),
    selectAllEnabled: true,
    schemaLocalizationDictionary: localizationDictionaryForAllSchemasSelector(
      state
    ),
    filterConfig: evaluationsFilterConfigSelector(state),
    filterCheckedState: sectionSelectors.createFilterCheckedStateSelector(
      evaluationsFilterConfigSelector(state)
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectCheckbox: filterObject => {
      dispatch(sectionActions.selectCheckbox(filterObject));
    },
    deselectCheckbox: filterObject => {
      dispatch(sectionActions.deselectCheckbox(filterObject));
    }
  };
}

const LegacyEvaluationsFilterWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(LegacyFilterSection);

export default LegacyEvaluationsFilterWrapper;
