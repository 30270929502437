import { createSelector } from "reselect";

// Selectors
import { createAllPagesSavedFiltersForSectionQuerySelector } from "../../../query_selectors/saved_filters";

/**
 * create a selector to get the api context for the all pages for saved filters
 * @param {Object} props
 * @param {string} props.section
 * @returns {function} selector
 */
export function createAllPagesSavedFiltersForSectionContextSelector({
  section
}) {
  return createSelector(
    createAllPagesSavedFiltersForSectionQuerySelector({ section }),
    queries => {
      return queries;
    }
  );
}
