import { createActionTypes } from "../../create_action_types";

/**
 * Create action creator for setting the page for a context
 * @param {string} resourceName
 * @returns {function}
 */
export function createSetPageActionCreator(resourceName) {
  const actionTypes = createActionTypes(resourceName);
  return (queryObject, page) => {
    return {
      type: actionTypes.setPage,
      payload: {
        queryObject,
        page
      }
    };
  };
}
