import { populationActionTypes } from "src/action_types/section_action_types";

/**
 * Action creator for setting the acquired filter (inHouse, externally, or "")
 * @param {string} filterOption the filter option
 * @return {Object} Action
 */
export function setAcquiredFilterAction(filterOption) {
  return {
    type: populationActionTypes.setAcquiredFilter,
    payload: filterOption
  };
}

export function clearAcquiredFilterAction() {
  return {
    type: populationActionTypes.setAcquiredFilter,
    payload: ""
  };
}
