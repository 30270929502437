import { connect } from "react-redux";
import get from "lodash.get";

// Components
import { MyDashboardSectionComponent } from "src/components/my_dashboard_section_component";

// Selectors
// Possible for multiple tabs in my dashboard - first iteration only one view with iframe
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

function mapStateToProps(state) {
  const iframeUrlsPolicy = createPolicyForInternalNameSelector(
    "my_dashboard_iframe_urls"
  )(state);

  const iframeUrls = get(iframeUrlsPolicy, "attributes.policyValue", []);

  return {
    iframeUrls
  };
}

export const MyDashboardSectionContainer = connect(mapStateToProps)(
  MyDashboardSectionComponent
);
