import { createSelector } from "reselect";

// Selectors
import { isSwiftAdminSelector } from "src/selectors/config_selectors/is_swift_admin_selector";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

// Constants
import { policyValues, policyInternalNames } from "src/constants";

/**
 * Selector to determine if user delete is enabled
 */
export const userDeleteEnabledSelector = createSelector(
  isSwiftAdminSelector,
  createPolicyForInternalNameSelector(policyInternalNames.userAccess),
  (isSwiftAdmin, userAccessPolicy) => {
    return (
      isSwiftAdmin ||
      (userAccessPolicy &&
        userAccessPolicy.attributes.policyValue.includes(policyValues.delete))
    );
  }
);
