import React from "react";

export class ExportIconComponent extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 100 100"
          transform="matrix(.69 0 0 .69 15.5 15.5)"
        >
          <path d="M46,62.8a2,2,0,0,1-2-2v-33H29.16a2,2,0,0,1-1.56-3.25L48.44,2.75a2,2,0,0,1,3.12,0L72.4,24.55a2,2,0,0,1-1.56,3.25H56v33a2,2,0,0,1-2,2Z" />
          <path d="M86,50H70a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2H84a2,2,0,0,1,2,2v8a2,2,0,0,1-2,2H16a2,2,0,0,1-2-2V64a2,2,0,0,1,2-2H30a2,2,0,0,0,2-2V52a2,2,0,0,0-2-2H14A12,12,0,0,0,2,62V86A12,12,0,0,0,14,98H86A12,12,0,0,0,98,86V62A12,12,0,0,0,86,50Z" />
        </svg>
      </svg>
    );
  }
}
