import { normalize } from "normalizr";
import { trackError } from "src/error_tracking";

// Schema
import { v2AssessmentSchema } from "src/schema/v2_assessment";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";

// Api
import { fetchSurveyWoundDetails } from "src/api/survey";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

/**
 * Action creator for setting loading state of tallies
 */
function surveyWoundDetailsLoading() {
  return {
    type: dataActionTypes.surveyWoundDetailsLoading
  };
}

/**
 * Action creator for setting loading state of the tallies
 */
function surveyWoundDetailsLoadError() {
  return {
    type: dataActionTypes.surveyWoundDetailsLoadError
  };
}

/**
 * Action creator for populating tallies in the store
 * @param {Array} tallies array of tallies
 * @return {Object} redux action
 */
function populateSurveyWoundDetails(woundDetails) {
  return {
    type: dataActionTypes.populateSurveyWoundDetails,
    payload: woundDetails
  };
}

/**
 * Action creator to fetch survey tallies
 */
export function loadSurveyWoundDetails(payload) {
  return dispatch => {
    startTimeTrack("API_POST_LOAD_SURVEY_WOUND_DETAILS");
    dispatch(surveyWoundDetailsLoading());

    return fetchSurveyWoundDetails(payload)
      .then(response => {
        finishTimeTrack("API_POST_LOAD_SURVEY_WOUND_DETAILS");

        // Normalize V2 Assessments
        const wounds =
          normalize(response.data, [v2AssessmentSchema]).entities.assessments ||
          {};

        dispatch(populateSurveyWoundDetails(wounds));
      })
      .catch(err => {
        finishTimeTrack("API_POST_LOAD_SURVEY_WOUND_DETAILS", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
        trackError(err);
        dispatch(surveyWoundDetailsLoadError());
      });
  };
}
