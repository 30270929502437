export const formatApiResource = (type, obj, id) => {
  return {
    data: {
      ...(id && { id }),
      type,
      attributes: {
        ...obj
      }
    }
  };
};
