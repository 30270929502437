import { connect } from "react-redux";
import { isPristine, isSubmitting, getFormValues } from "redux-form";
import { RoleFormComponent } from "src/components/forms/role_form_component";

import {
  createCustomRoleAction,
  updateCustomRoleAction
} from "src/actions/data_actions/custom_roles";

import {
  permissionTypesResourceSelectors,
  rolesResourceSelectors
} from "src/selectors/data_selectors";

import {
  createRoleFormFacilityOptionsSelector,
  organizationsForSelectedPartnerIdSelector
} from "src/selectors/section_selectors/admin";

import { reduxFormNames } from "src/constants";

function mapStateToProps(state) {
  return {
    formName: reduxFormNames.roleCreateForm,
    permissionTypes: permissionTypesResourceSelectors.dataSelector(state),
    organizations: organizationsForSelectedPartnerIdSelector(state),
    facilities: createRoleFormFacilityOptionsSelector(
      reduxFormNames.roleCreateForm
    )(state),
    pristine: isPristine(reduxFormNames.roleCreateForm)(state),
    submitting: isSubmitting(reduxFormNames.roleCreateForm)(state),
    formValues: getFormValues(reduxFormNames.roleCreateForm)(state),
    createLoadState: rolesResourceSelectors.postLoadStateSelector(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sendCreateRoleAction(payload) {
      return dispatch(createCustomRoleAction(payload));
    },
    sendUpdateRoleAction(payload) {
      return dispatch(updateCustomRoleAction(payload));
    },
    resetForm() {
      return dispatch(reset(reduxFormNames.roleCreateForm));
    },
    changeField(fieldName, fieldValue) {
      dispatch(change(reduxFormNames.roleCreateForm, fieldName, fieldValue));
    },
    clearFields(...fieldNames) {
      dispatch(
        clearFields(reduxFormNames.roleCreateForm, false, false, ...fieldNames)
      );
    }
  };
}
export const CreateRoleFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleFormComponent);
