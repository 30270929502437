import { createSelector } from "reselect";
import { currentUserSelector } from "../current_user_selector";
import { securityRoleIds } from "src/constants/security_access";

/**
 * Memoized selector, returns true if user is a Swift Admin, false otherwise
 */

export const isSwiftAdminSelector = createSelector(
  currentUserSelector,
  currentUser => {
    if (currentUser && currentUser.relationships.roles.data) {
      return currentUser.relationships.roles.data
        .map(role => role.id)
        .includes(securityRoleIds.admin)
        ? true
        : false;
    }

    return false;
  }
);
