import React from "react";
import PropTypes from "prop-types";
// router
import { NavLink } from "react-router-dom";
import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormComponent } from "src/components/forms/form_component_legacy";
import { Button } from "src/ui-kit";
import { loadStates } from "src/constants/load_states";
// style
import styles from "./style.module.less";

import { reduxFormNames } from "src/constants";
import { required, validateEmail } from "src/validations";

export class ResetPasswordFormComponent extends React.Component {
  static propTypes = {
    resetPasswordLoadState: PropTypes.string.isRequired
  };

  /**
   * Event handler for the reset password
   */
  onResetPassword = values => {
    const { resetPasswordLoadState } = this.props;
    if (resetPasswordLoadState == loadStates.ready) {
      const payload = {
        email: values.resetEmail
      };
      this.props.sendResetPasswordAction(payload);
    }
  };
  render = () => {
    return (
      <FormComponent
        form={reduxFormNames.resetPasswordForm}
        onSubmit={this.onResetPassword}
      >
        <FormInputComponent
          name="resetEmail"
          component="input"
          componentType="email"
          placeholder="Email"
          validate={[required, validateEmail]}
        />

        <div className={`text-center ${styles["margin-top10px"]}`}>
          <Button className="btn-default" subtype="submit">
            Submit
          </Button>

          <NavLink className="btn btn-default" to="/sign_in">
            Cancel
          </NavLink>
        </div>
      </FormComponent>
    );
  };
}
