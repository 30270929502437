import { createSelector } from "reselect";

// Selectors
import { createFirstPageEventsForRevisionIdContextSelector } from "src/selectors/context_selectors/events_for_revision_id";
import { eventsResourceSelectors } from "src/selectors/data_selectors";

// Payload generator
import { createEventsGetPayload } from "src/api/events/payload_generators";

// Constants
import { apiPageLimits, eventTypeFilters } from "src/constants";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get all the queries (for all pages) for loading events with revision id
 * @param {Object} props
 * @param {string} props.revisionId
 * @returns {function} selector
 */
export function createAllPagesEventsForRevisionIdQuerySelector({ revisionId }) {
  return createSelector(
    eventsResourceSelectors.createPageCountSelector(
      createFirstPageEventsForRevisionIdContextSelector({ revisionId }),
      apiPageLimits.events
    ),
    pageCount => {
      if (!pageCount) {
        return [];
      }

      let queries = [];
      for (let i = 0; i < pageCount; i++) {
        const queryParams = createEventsGetPayload({
          revisionId,
          pageNumber: i,
          eventType: eventTypeFilters.revision
        });
        queries.push(buildQueryString(queryParams));
      }

      return queries;
    }
  );
}
