import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { createNestedAssessmentSchemasForAssessmentAnswerSelector } from "src/selectors/data_selectors";

/**
 * Create a selector that gets the schemas for a wound assessment (attributes tab)
 * @param {Object} assessmentAnswer
 * @returns {Function} selector
 */
export function createDescriptionAssessmentSchemaForAssessmentAnswerSelector(
  assessmentAnswer
) {
  return createSelector(
    createNestedAssessmentSchemasForAssessmentAnswerSelector(assessmentAnswer),
    assessmentSchemas => {
      const descriptionSchema = assessmentSchemas.find(
        schema =>
          get(schema, "attributes.schemaJson.name", "") === "description"
      );

      let descriptionSchemas = [];
      if (descriptionSchema) descriptionSchemas.push(descriptionSchema);

      return descriptionSchemas;
    }
  );
}
