import React from "react";
import { SurveyTallyTableContainer } from "src/containers/tables";

//Component
import { PanelComponent, PanelBodyComponent } from "src/components/panel";

//translation
import i18n from "src/translations";

// Constants
import { tallyTypes } from "src/constants";

export class SurveyLocationsComponent extends React.Component {
  render() {
    return (
      <PanelComponent
        labelShow={i18n.t("headers.woundNumbersTable")}
        labelHide={i18n.t("headers.woundNumbersTable")}
        enableCollapsible={true}
      >
        <PanelBodyComponent>
          <SurveyTallyTableContainer tallyType={tallyTypes.location} />
        </PanelBodyComponent>
      </PanelComponent>
    );
  }
}
