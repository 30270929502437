// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";

export type $props = {
  active?: boolean,
  className?: string,
  onClick?: Function,
  text: any
};

export default class BreadcrumbItem extends PureComponent {
  props: $props;
  render() {
    const { className, active, text, onClick } = this.props;
    const klass = classnames("breadcrumb-item", {
      active: active,
      [className || ""]: className
    });
    if (active) {
      return <li className={klass}>{text}</li>;
    }
    return (
      <li className={klass} onClick={onClick}>
        <a onClick={onClick}>{text}</a>
      </li>
    );
  }
}
