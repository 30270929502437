import { createSelector } from "reselect";
import { partnerNameSelector } from "../partner_name_selector";

/**
 * Selector to get the tabs to display on a per parnter basis
 */
export const sideBarNavigationItemsSelector = createSelector(
  partnerNameSelector,
  partnerName => {
    switch (partnerName) {
      case "pcc":
        return ["wounds", "risk", "survey", "population"];
      default:
        return [
          "compliance",
          "patients",
          "wounds",
          "risk",
          "survey",
          "population",
          "settings",
          "admin",
          "monitoring"
        ];
    }
  }
);
