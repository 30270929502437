import { createActionTypes } from "../../create_action_types";

/**
 * Create action creator for marking a single resource context invalid
 * @param {string} resourceName
 * @returns {function}
 */
export function createInvalidateActionCreator(resourceName) {
  const actionTypes = createActionTypes(resourceName);
  return (method, queryObject, page) => {
    return {
      type: actionTypes.invalidate,
      payload: {
        method,
        queryObject,
        page
      }
    };
  };
}
