/**
 * Map measurements from the server to values we can work with
 * @param {Object} revision
 * @return {Object} modified revision
 */
export function mapMeasurementsToValues(revision) {
  if (revision.attributes.area) {
    if (revision.attributes.area == "N/A") {
      revision.attributes.area = null;
    } else if (/^</.test(revision.attributes.area)) {
      // We map 0.5 to < 0.1 on our end - API shouldn't be sending display values
      revision.attributes.area = 0.05;
    } else {
      revision.attributes.area = parseFloat(revision.attributes.area);
    }
  }

  if (revision.attributes.deltaArea) {
    revision.attributes.deltaArea =
      revision.attributes.deltaArea && revision.attributes.deltaArea != "N/A"
        ? parseFloat(revision.attributes.deltaArea)
        : null;
  }

  if (revision.attributes.height) {
    if (revision.attributes.height == "N/A") {
      revision.attributes.height = null;
    } else if (/^</.test(revision.attributes.height)) {
      // We map 0.5 to < 0.1 on our end - API shouldn't be sending display values
      revision.attributes.height = 0.05;
    } else {
      revision.attributes.height = parseFloat(revision.attributes.height);
    }
  }

  if (revision.attributes.deltaHeight) {
    revision.attributes.deltaHeight =
      revision.attributes.deltaHeight &&
      revision.attributes.deltaHeight != "N/A"
        ? parseFloat(revision.attributes.deltaHeight)
        : null;
  }

  if (revision.attributes.width) {
    if (revision.attributes.width == "N/A") {
      revision.attributes.width = null;
    } else if (/^</.test(revision.attributes.width)) {
      // We map 0.5 to < 0.1 on our end - API shouldn't be sending display values
      revision.attributes.width = 0.05;
    } else {
      revision.attributes.width = parseFloat(revision.attributes.width);
    }
  }

  if (revision.attributes.deltaWidth) {
    revision.attributes.deltaWidth =
      revision.attributes.deltaWidth && revision.attributes.deltaWidth != "N/A"
        ? parseFloat(revision.attributes.deltaWidth)
        : null;
  }

  if (revision.attributes.maxDepth) {
    if (revision.attributes.maxDepth == "N/A") {
      revision.attributes.maxDepth = null;
    } else if (/^</.test(revision.attributes.maxDepth)) {
      // We map 0.5 to < 0.1 on our end - API shouldn't be sending display values
      revision.attributes.maxDepth = 0.05;
    } else {
      revision.attributes.maxDepth = parseFloat(revision.attributes.maxDepth);
    }
  }

  if (revision.attributes.deltaDepth) {
    if (revision.attributes.deltaDepth == "N/A") {
      revision.attributes.deltaDepth = null;
    } else if (/^</.test(revision.attributes.deltaDepth)) {
      // We map 0.5 to < 0.1 on our end - API shouldn't be sending display values
      revision.attributes.deltaDepth = 0.05;
    } else {
      revision.attributes.deltaDepth = parseFloat(
        revision.attributes.deltaDepth
      );
    }
  }

  if (revision.attributes.maxUndermining) {
    if (revision.attributes.maxUndermining == "N/A") {
      revision.attributes.maxUndermining = null;
    } else if (/^</.test(revision.attributes.maxUndermining)) {
      // We map 0.5 to < 0.1 on our end - API shouldn't be sending display values
      revision.attributes.maxUndermining = 0.05;
    } else {
      revision.attributes.maxUndermining = parseFloat(
        revision.attributes.maxUndermining
      );
    }
  }

  if (revision.attributes.deltaUndermining) {
    revision.attributes.deltaUndermining =
      revision.attributes.deltaUndermining &&
      revision.attributes.deltaUndermining != "N/A"
        ? parseFloat(revision.attributes.deltaUndermining)
        : null;
  }

  if (revision.attributes.maxTunnelling) {
    if (revision.attributes.maxTunnelling == "N/A") {
      revision.attributes.maxTunnelling = null;
    } else if (/^</.test(revision.attributes.maxTunnelling)) {
      // We map 0.5 to < 0.1 on our end - API shouldn't be sending display values
      revision.attributes.maxTunnelling = 0.05;
    } else {
      revision.attributes.maxTunnelling = parseFloat(
        revision.attributes.maxTunnelling
      );
    }
  }

  if (revision.attributes.deltaTunnelling) {
    revision.attributes.deltaTunnelling =
      revision.attributes.deltaTunnelling &&
      revision.attributes.deltaTunnelling != "N/A"
        ? parseFloat(revision.attributes.deltaTunnelling)
        : null;
  }

  return revision;
}
