import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import i18n from "src/translations";

// Styles
import styles from "./style.module.less";

const DISPLAYED_QA_LENGTH = 50;

export function qaValueFormatter(cell) {
  let value = "--";
  if (cell) {
    value =
      cell.length > DISPLAYED_QA_LENGTH
        ? `${cell.substring(0, DISPLAYED_QA_LENGTH)}...`
        : cell;
  }
  return value;
}

export function qaNoteFormatter(cell, row, rowIndex, formatExtraData) {
  let value = qaValueFormatter(cell);

  const tooltip = (
    <Tooltip id="qa-tooltip">
      <p className="bold-text">
        {i18n.t("data.qaNotes.updatedBy", {
          user: row.attributes.qaNoteAuthor,
          date: moment(row.attributes.qaNoteUpdatedAt).format("LL - LT")
        })}
      </p>
      <p className={styles["qa-note-tooltip-body"]}>{cell}</p>
    </Tooltip>
  );

  return (
    <>
      {cell ? (
        <OverlayTrigger placement="left" overlay={tooltip}>
          <div onClick={e => formatExtraData.eventHandler(e, row)}>
            <Link to={location.pathname}>{value}</Link>
          </div>
        </OverlayTrigger>
      ) : (
        <div onClick={e => formatExtraData.eventHandler(e, row)}>
          <Link to={location.pathname}>{value}</Link>
        </div>
      )}
    </>
  );
}
