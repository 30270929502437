import { createSelector } from "reselect";
import { customRolesQuerySelector } from "src/selectors/query_selectors/admin";

export function customRolesContextSelector() {
  return createSelector(
    customRolesQuerySelector(),
    query => {
      return query;
    }
  );
}
