import { addReducer } from "incremental-redux-reducers";

export default addReducer("flashMessages", function flashMessagesReducer(
  state = [],
  action
) {
  switch (action.type) {
    case "FLASH_MESSAGE_CREATE":
      let { id, flashMessage, messageType } = action;

      return [
        ...state,
        {
          id,
          flashMessage,
          messageType
        }
      ];
    case "FLASH_MESSAGE_REMOVE":
      return state.filter(message => {
        return !(
          message.messageType === action.messageType &&
          message.flashMessage === action.flashMessage
        );
      });
    default:
      return state;
  }
});
