//Reselect
import { createSelector } from "reselect";

//Selectors
import { isPccSelector } from "src/selectors/config_selectors";

//Lodash
import get from "lodash.get";

/**
 * selector to determine if edit wound assessments button is enabled
 */
export function editWoundAssessmentsVisibleSelector(assessmentAnswer) {
  return createSelector(
    isPccSelector,
    isPcc => {
      return (
        !isPcc &&
        (get(assessmentAnswer, "attributes.signState", null) == "unsigned" &&
          get(assessmentAnswer, "attributes.lockState", null) == "unlocked")
      );
    }
  );
}
