import get from "lodash.get";

// Models
import { evaluatePredicate } from "src/models/assessment_answers";

/**
 * Evaluates if the assessment answer has missing required fields
 *
 * @param {Array} schemas
 * @param {Object} assessmentAnswer,
 * @param {boolean} revisionIsFirstInSeries
 *
 * @returns {boolean}
 */

export function getMissingRequiredFields(
  schemas,
  assessmentAnswer,
  revisionIsFirstInSeries
) {
  let missingFields = [];

  schemas.forEach(schema => {
    const requiredQuestions = getRequiredQuestionsForSchema(
      schema,
      revisionIsFirstInSeries,
      assessmentAnswer
    );
    const missingRequiredFieldsForSchema = getMissingRequiredFieldsForSchema(
      schema,
      requiredQuestions,
      assessmentAnswer
    );
    missingFields = [...missingFields, ...missingRequiredFieldsForSchema];
  });

  return missingFields;
}

function getRequiredQuestionsForSchema(
  schema,
  revisionIsFirstInSeries,
  assessmentAnswer
) {
  const questions = get(schema, "attributes.schemaJson.questions", []);
  const requiredQuestions = questions.filter(question => {
    if (question.visiblePredicateJson) {
      return (
        !!question.required &&
        !!evaluatePredicate({
          assessmentAnswer,
          revisionIsFirstInSeries,
          predicate: question.visiblePredicateJson
        })
      );
    } else {
      return !!question.required;
    }
  });
  return requiredQuestions;
}

function getMissingRequiredFieldsForSchema(
  schema,
  requiredQuestions,
  assessmentAnswer
) {
  const translations = get(
    schema,
    "attributes.schemaJson.localizationDictionary.base",
    null
  );

  let missingFields = [];

  requiredQuestions.forEach(question => {
    const hasQuestionValue = get(
      assessmentAnswer,
      `attributes.answersJson.${question.name}`,
      false
    );
    const title = get(translations, `${question.name}.title`, question.name);
    if (!hasQuestionValue) {
      return missingFields.push(title);
    }
  });

  return missingFields;
}
