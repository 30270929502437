import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { sections } from "src/constants";

// Component
import { CheckboxFilterComponent } from "src/components/filters";

// UI Processors
import { createSectionActions } from "src/ui_processors/global/action_creators";

//HOC
import { withSchemaFilterConfigurationWrapper } from "src/hoc/with_schema_filter_configuration_wrapper";

const sectionActions = createSectionActions(sections.exports);

function mapStateToProps() {
  return {
    selectAllEnabled: true
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectCheckbox: filterObject => {
      dispatch(sectionActions.selectCheckbox(filterObject));
    },
    deselectCheckbox: filterObject => {
      dispatch(sectionActions.deselectCheckbox(filterObject));
    }
  };
}

export const WoundEvaluationsWoundTypesFilterContainer = flowRight(
  withSchemaFilterConfigurationWrapper,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckboxFilterComponent);
