import { globalActionTypes } from "src/action_types/section_action_types";

export function downloadManagerOpenReducer(state = false, action) {
  switch (action.type) {
    case globalActionTypes.closeDownloadManager:
      return false;
    case globalActionTypes.openDownloadManager:
      return true;
    default:
      return state;
  }
}
