import React, { PureComponent } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./style.module.less";

/**
 * Component for a drop down selector
 * options must contain - key, value, label
 */
export class SelectComponent extends PureComponent {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string
  };

  handleChange = event => this.props.onChange(event.target.value);

  render() {
    const { options, disabled, value, label } = this.props;
    return (
      <div className={styles["select-container"]}>
        {label && <label>{label}</label>}
        <select
          disabled={disabled}
          value={value}
          className="form-control"
          onChange={this.handleChange}
        >
          {options.map((option, index) => {
            return (
              <option
                key={index}
                value={option.value}
                disabled={option.disabled}
                selected={option.selected}
              >
                {option.label || option.value}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}
