import { createSelector } from "reselect";

// Selectors
import { createAllPagesRevisionContextsForFiltersSelector } from "../../revisions/create_all_pages_revision_contexts_for_filters_selector";
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Redux Resources
import { createGetPayload } from "src/redux_resources_v1";

/**
 * @param {Object} props
 * @param {Object} props.filters
 */
export function createLatestAssessmentAnswerQueriesForAllRevisionsForFiltersSelector({
  filters
}) {
  return createSelector(
    revisionsResourceSelectors.createDataForContextSelector(
      createAllPagesRevisionContextsForFiltersSelector({ filters })
    ),
    revisions => {
      return revisions.map(r => {
        return buildQueryString(
          createGetPayload({
            filter: {
              resource_id: r.id
            },
            page: {
              pageNumber: 0,
              limit: 1
            }
          })
        );
      });
    }
  );
}
