export const encounters = {
  evaluationDocumentedFor: "The evaluation was documented for",
  continueQuestion: "Do you want to continue",
  yes: "Yes",
  noActiveEncounters:
    "No active {{encounterNamingConvention}} found for this patient. Please contact support@swiftmedical.io to continue.",
  startDateString: "Start",
  endDateString: "End",
  cancel: "Cancel",
  ok: "Ok",
  unknown: "Unknown",
  confirmEncounterOrVisit: "Confirm {{encounterNamingConvention}}",
  clinicianNameString: "Clinician",
  locationString: "Location",
  disciplineString: "Discipline"
};
