import React, { useEffect } from "react";
import { connect } from "react-redux";
import { smartOnFhirLogin } from "src/actions/session_actions";
import { getContextPatientId } from "src/actions/config_actions";
import { LoadingComponent } from "src/components/loading_component";
import { launchIntents } from "src/constants";

import styles from "./style.module.less";

function EmbeddedDashboardComponent(props) {
  const { dispatch, launchError, launchIntent, sessionId } = props;

  useEffect(() => {
    dispatch(smartOnFhirLogin());
  }, []);

  useEffect(() => {
    if (!!sessionId && launchIntent === launchIntents.chart) {
      dispatch(getContextPatientId());
    }
  }, [launchIntent, sessionId]);

  if (!!launchError) return <LaunchError error={launchError} />;
  else return <LoadingComponent />;
}

function LaunchError({ error }) {
  return (
    <div className={styles["launch-error-container"]}>
      <div className={styles["error-message"]}>{error}</div>
      <div className={styles["error-cta"]}>Please try again</div>
    </div>
  );
}

const mapStateToProps = state => ({
  launchError: state.session.launchError,
  launchIntent: state.config.launchContext.intent,
  sessionId: state.session.sessionId
});

export default connect(mapStateToProps)(EmbeddedDashboardComponent);
