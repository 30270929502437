import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Glyphicon } from "src/components/ui_kit";

import { connect } from "react-redux";
import { mapReducersToProps } from "incremental-redux-reducers";

import Moment from "moment";
import patientDefaultAvatar from "src/assets/patientDefaultAvatar.png";

import "./columnComponentStyle.less";

import applicationReducer from "app/reducers/application";

import { accessTokenSelector } from "src/selectors/session_selectors";
import {
  partnerNameSelector,
  patientLinksEnabledSelector
} from "src/selectors/config_selectors";

class PatientColumnComponent extends React.Component {
  static propTypes = {
    patient: PropTypes.object,
    startDate: PropTypes.object,
    handleOpenClose: PropTypes.bool,
    partnerName: PropTypes.any,
    application: PropTypes.object.isRequired,
    accessToken: PropTypes.string.isRequired,
    patientLinksEnabled: PropTypes.bool.isRequired
  };

  state = {
    showInfo: false
  };

  handleInfoToggle = () => {
    this.setState({
      showInfo: !this.state.showInfo
    });
  };

  generatePatientName = patient => {
    let { patient_link_template } = this.props.application;
    let patientName;
    if (
      !patient.first_name &&
      !patient.firstName &&
      (!patient.last_name && !patient.lastName)
    )
      patientName = "Anonymous";
    else if (
      !patient.first_name &&
      !patient.firstName &&
      (patient.last_name || patient.lastName)
    )
      patientName = `(No First Name) ${patient.last_name || patient.lastName}`;
    else if (
      !patient.last_name &&
      !patient.last_name &&
      (patient.first_name || patient.first_name)
    )
      patientName = `${patient.first_name || patient.firstName} (No Last Name)`;
    else
      patientName = `${patient.first_name ||
        patient.firstName} ${patient.last_name || patient.lastName}`;

    let link = patient_link_template
      .replace("{admission_id}", patient.admission_id || patient.admissionId)
      .replace("{patient_id}", patient.id);

    if (this.props.partnerName === "pcc") {
      return (
        <a href={link} target="_parent">
          {patientName}
        </a>
      );
    } else if (!this.props.patientLinksEnabled) {
      return patientName;
    } else {
      return <Link to={link}>{patientName}</Link>;
    }
  };

  render = () => {
    let { patient, handleOpenClose } = this.props;

    let patientAvatar;
    let lastAssessed;
    let nextAssessment;

    if (patient.avatar_image_urls && patient.avatar_image_urls.small) {
      patientAvatar = (
        <div
          className="avatar"
          style={{
            backgroundImage: `url('${patient.avatar_image_urls.small}')`
          }}
        />
      );
    }

    if (patient.avatarImageUrls && patient.avatarImageUrls.small) {
      patientAvatar = (
        <div
          className="avatar"
          style={{
            backgroundImage: `url('${patient.avatarImageUrls.small}')`
          }}
        />
      );
    }
    if (!patientAvatar) {
      patientAvatar = (
        <div
          className="avatar"
          style={{
            backgroundImage: `url('${patientDefaultAvatar}')`
          }}
        />
      );
    }

    if (patient.first_completed_assessment_answer_created_at) {
      lastAssessed = patient.first_completed_assessment_answer_created_at;
    } else if (patient.firstCompletedAssessmentAnswerCreatedAt) {
      lastAssessed = patient.firstCompletedAssessmentAnswerCreatedAt;
    }

    if (lastAssessed) {
      let firstCompletedAssessmentAnswerCreatedAtMoment = new Moment(
        lastAssessed
      );
      let firstCompletedAssessmentAnswerCreatedAtString = firstCompletedAssessmentAnswerCreatedAtMoment.format(
        "MMM Do YYYY"
      );
      let timeSinceLastAssessed = firstCompletedAssessmentAnswerCreatedAtMoment.from();

      lastAssessed = (
        <p className="last-assessed">
          <b>Last Evaluated:</b>
          <br />
          {firstCompletedAssessmentAnswerCreatedAtString}
          <br />({timeSinceLastAssessed})
        </p>
      );
    }

    if (patient.next_assessment_at || patient.nextAssessmentAt) {
      let nextAssessmentMoment = new Moment(
        patient.next_assessment_at || patient.nextAssessmentAt
      );
      let nextAssessmentDateString = nextAssessmentMoment.format("MMM Do YYYY");
      let timeUntilNextAssessment = nextAssessmentMoment.from();

      nextAssessment = (
        <p className="next-assessed">
          <b>Next Due:</b>
          <br />
          {nextAssessmentDateString}
          <br />({timeUntilNextAssessment})
        </p>
      );
    }

    const room = patient.facility_room || patient.facilityRoom;
    const bed = patient.facility_room_bed || patient.facilityRoomBed;

    let patientContent = (
      <div className="patient-column-component">
        {patientAvatar}
        <div className="media-body">
          <h4 className="media-heading">
            {this.props.accessToken && this.generatePatientName(patient)}
          </h4>
          {room && <p>Room: {room}</p>}
          {bed && <p>Bed: {bed}</p>}
          {lastAssessed}
          {nextAssessment}
        </div>
      </div>
    );

    if (handleOpenClose) {
      return (
        <div>
          <p className="dropdown-toggle" onClick={this.handleInfoToggle}>
            Resident Details
            <Glyphicon
              glyph={this.state.showInfo ? "menu-down" : "menu-right"}
              className="arrow-placement"
            />
          </p>

          <Collapse in={this.state.showInfo}>{patientContent}</Collapse>
        </div>
      );
    } else {
      return patientContent;
    }
  };
}

function mapStateToProps(state) {
  const reducerProps = mapReducersToProps({
    application: applicationReducer
  });

  return {
    ...reducerProps(state),
    partnerName: partnerNameSelector(state),
    accessToken: accessTokenSelector(state),
    patientLinksEnabled: patientLinksEnabledSelector(state)
  };
}

export default connect(mapStateToProps)(PatientColumnComponent);
