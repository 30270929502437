import { createSelector } from "reselect";
import get from "lodash.get";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

export const reportingPermissionEnabledSelector = createSelector(
  createPolicyForInternalNameSelector("reporting"),
  policy => {
    return get(policy, "attributes.policyValue", true);
  }
);
