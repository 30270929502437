import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import get from "lodash.get";

// Components
import { FormComponent } from "../form_component";
import { FormTitleComponent } from "../form_title_component";
import { FormSubmitComponent } from "../form_submit_component";
import { FormCheckboxComponent } from "src/components/forms/form_checkbox_component";
import { FormDatePickerComponent } from "src/components/forms/form_date_picker_component";
import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormInputOptionComponent } from "src/components/forms/form_input_option_component";
import { GridRow, GridColumn } from "src/components/grid";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

// Constants
import { reduxFormFieldNames } from "src/constants";

// Validations
import {
  required,
  dateFormatMatch,
  dateCannotBeInFuture,
  validatePhoneNumber
} from "src/validations";

// Normalizers
import { phoneNormalizer } from "src/input_normalizers";

// Styles
import styles from "./style.module.less";

export class PatientFormComponent extends React.Component {
  static propTypes = {
    patient: PropTypes.object,
    initialValues: PropTypes.object.isRequired,
    partners: PropTypes.array.isRequired,
    organizations: PropTypes.array.isRequired,
    facilities: PropTypes.array.isRequired,
    units: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    asyncValidating: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    partnerDropDownEnabled: PropTypes.bool.isRequired,
    sendCreatePatientsAction: PropTypes.func.isRequired,
    sendUpdatePatientAction: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    clearFormFields: PropTypes.func.isRequired,
    patientAppEnabled: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.wrappedHandleSubmit = props.handleSubmit(this.handleSubmit);
  }

  componentWillReceiveProps(nextProps) {
    const { change, clearFormFields, patient } = this.props;

    // Don't change the location info after edit patient initialization
    const isEditForm = !!nextProps.patient;
    let patientUserId = get(patient, "attributes.patientUserId", "");
    if (patientUserId) {
      change(reduxFormFieldNames.allowHomeMonitor, true);
    }
    if (!isEditForm) {
      if (propsAreDifferent(nextProps, this.props, "partners") && !isEditForm) {
        if (nextProps.partners.length) {
          change(reduxFormFieldNames.partnerId, nextProps.partners[0]);
        } else {
          clearFormFields(reduxFormFieldNames.partnerId);
        }
      }
      if (propsAreDifferent(nextProps, this.props, "organizations")) {
        if (nextProps.organizations.length) {
          change(
            reduxFormFieldNames.organizationId,
            nextProps.organizations[0].id
          );
        } else {
          clearFormFields(reduxFormFieldNames.organizationId);
        }
      }
      if (propsAreDifferent(nextProps, this.props, "facilities")) {
        if (nextProps.facilities.length) {
          change(reduxFormFieldNames.facilityId, nextProps.facilities[0].id);
        } else {
          clearFormFields(reduxFormFieldNames.facilityId);
        }
      }
      if (propsAreDifferent(nextProps, this.props, "units")) {
        if (nextProps.units.length) {
          change(reduxFormFieldNames.unitId, nextProps.units[0].id);
        } else {
          clearFormFields(reduxFormFieldNames.unitId);
        }
      }
    }
  }

  /**
   * Event handler for the create patient
   */
  handleSubmit = values => {
    const {
      currentUser,
      patient,
      sendUpdatePatientAction,
      sendCreatePatientsAction,
      cancel
    } = this.props;
    const userId = currentUser.id;
    const phoneNumber = values.phoneNumber
      ? values.phoneNumber.replace(/[^\d]/g, "")
      : null;
    const payload = {
      data: {
        attributes: {
          partner_id: values[reduxFormFieldNames.partnerId]
            ? values[reduxFormFieldNames.partnerId]
            : currentUser.attributes.partnerId,
          organization_id: values[reduxFormFieldNames.organizationId],
          facility_id: values[reduxFormFieldNames.facilityId],
          facility_unit_id: values[reduxFormFieldNames.unitId],
          first_name: values.firstName,
          middle_name: values.middleName,
          last_name: values.lastName,
          gender: values.gender,
          birth_at: values.birthDate
            ? moment(values.birthDate).toISOString()
            : undefined,
          email: values.email,
          patient_email: values.patientEmail,
          address: values.address,
          phone_number: phoneNumber,
          health_card_number: values.healthCardNumber,
          mrn: values.mrn,
          user_id: userId
        },
        type: "patients",
        id: patient ? patient.id : undefined
      }
    };
    if (patient && patient.id) {
      const oldPatientEmail = get(patient, "attributes.patientEmail", "");
      const patientEmailChange =
        oldPatientEmail == values.patientEmail ? false : true;
      sendUpdatePatientAction(
        payload,
        values.allowHomeMonitor,
        patientEmailChange,
        currentUser.id
      ).then(() => cancel());
    } else {
      sendCreatePatientsAction(
        payload,
        values.allowHomeMonitor,
        currentUser.id
      ).then(() => cancel());
    }
  };

  render() {
    const {
      partners,
      organizations,
      units,
      facilities,
      asyncValidating,
      pristine,
      submitting,
      patient,
      partnerDropDownEnabled,
      cancel,
      formValues,
      patientAppEnabled
    } = this.props;
    let allowHomeMonitorStatus = get(formValues, "allowHomeMonitor", "");
    return (
      <div className={styles["style-buttons"]}>
        <FormTitleComponent>
          <h4>{patient ? "Edit Patient" : "Add Patient"}</h4>
        </FormTitleComponent>
        <FormComponent {...this.props} onSubmit={this.wrappedHandleSubmit}>
          {patientAppEnabled && (
            <FormCheckboxComponent
              labelView={true}
              component="input"
              componentType="checkbox"
              key="atHome"
              value="atHome"
              name={reduxFormFieldNames.allowHomeMonitor}
              labelText="Allow At Home Monitoring"
              patientRequired={allowHomeMonitorStatus}
            />
          )}
          {partnerDropDownEnabled && (
            <FormInputComponent
              name={reduxFormFieldNames.partnerId}
              component="input"
              componentType="select"
              labelText="Partner"
              disabled={patient ? true : false}
            >
              {partners.map(partner => {
                return (
                  <FormInputOptionComponent
                    key={partner.id}
                    text={partner.attributes.name}
                    value={partner.id}
                  />
                );
              })}
            </FormInputComponent>
          )}
          <FormInputComponent
            name={reduxFormFieldNames.organizationId}
            component="input"
            componentType="select"
            labelText="Organization"
            disabled={patient ? true : false}
            required={true}
            validate={[required]}
          >
            {organizations.map(organization => {
              return (
                <FormInputOptionComponent
                  key={organization.id}
                  text={organization.attributes.name}
                  value={organization.id}
                />
              );
            })}
          </FormInputComponent>
          <FormInputComponent
            name={reduxFormFieldNames.facilityId}
            component="input"
            componentType="select"
            labelText="Facility"
            disabled={patient ? true : false}
            required={true}
            validate={[required]}
          >
            {patient && (
              <FormInputOptionComponent
                key="notSet"
                text="Not Set"
                value={null}
              />
            )}
            {facilities.map(facility => {
              return (
                <FormInputOptionComponent
                  key={facility.id}
                  text={facility.attributes.name}
                  value={facility.id}
                />
              );
            })}
          </FormInputComponent>
          <FormInputComponent
            name={reduxFormFieldNames.unitId}
            component="input"
            componentType="select"
            labelText="Unit"
            disabled={patient ? true : false}
          >
            {patient && (
              <FormInputOptionComponent
                key="notSet"
                text="Not Set"
                value={null}
              />
            )}
            {units.map(unit => {
              return (
                <FormInputOptionComponent
                  key={unit.id}
                  text={unit.attributes.name}
                  value={unit.id}
                />
              );
            })}
          </FormInputComponent>

          <GridRow className={styles["margin-bottom15px"]}>
            <GridColumn sm="2">
              <label className={styles["label-text"]}>
                Name <span className={styles["required-field"]}>*</span>
              </label>
            </GridColumn>
            <GridColumn sm="10">
              <div className={`input-group ${styles["group-textbox"]}`}>
                <FormInputComponent
                  name="firstName"
                  component="input"
                  componentType="text"
                  placeholder="First Name"
                  className="form-control"
                  required={true}
                  validate={[required]}
                />
                <FormInputComponent
                  name="middleName"
                  component="input"
                  componentType="text"
                  placeholder="Middle Name"
                  className="form-control"
                />
                <FormInputComponent
                  name="lastName"
                  component="input"
                  componentType="text"
                  placeholder="Last Name"
                  className="form-control"
                  required={true}
                  validate={[required]}
                />
              </div>
            </GridColumn>
          </GridRow>
          <FormInputComponent
            name={reduxFormFieldNames.patientEmail}
            component="input"
            componentType="email"
            placeholder="Patient's Email"
            labelText="Patient's Email"
            required={allowHomeMonitorStatus}
            validate={allowHomeMonitorStatus && [required]}
          />
          <FormDatePickerComponent
            name="birthDate"
            component="input"
            componentType="date"
            placeholder="YYYY-MM-DD"
            labelText="Birth Date"
            maxDate={new Date()}
            required={true}
            validate={[required, dateFormatMatch, dateCannotBeInFuture]}
          />
          <FormInputComponent
            name="gender"
            component="input"
            componentType="select"
            labelText="Gender"
            required={true}
            validate={[required]}
          >
            <FormInputOptionComponent text="" value="" />
            <FormInputOptionComponent text="Male" value="male" />
            <FormInputOptionComponent text="Female" value="female" />
          </FormInputComponent>
          <FormInputComponent
            name="address"
            component="input"
            componentType="textarea"
            placeholder="Address"
            labelText="Address"
          />
          <FormInputComponent
            name="phoneNumber"
            component="input"
            componentType="text"
            placeholder="Patient's Mobile Phone"
            labelText="Patient's Mobile Phone"
            validate={
              allowHomeMonitorStatus
                ? [validatePhoneNumber, required]
                : [validatePhoneNumber]
            }
            normalize={phoneNormalizer}
            required={allowHomeMonitorStatus}
          />
          <FormInputComponent
            name="healthCardNumber"
            component="input"
            componentType="text"
            placeholder="Health Card Number"
            labelText="Health Card Number"
          />
          <FormInputComponent
            name="mrn"
            component="input"
            componentType="text"
            placeholder="MRN"
            labelText="MRN"
            required={true}
            validate={[required]}
          />
          <FormInputComponent
            name="email"
            component="input"
            componentType="email"
            placeholder="Physician's Email"
            labelText="Physician's Email"
          />
          <div className="pull-right">
            <FormSubmitComponent
              cancel={cancel}
              saveLabel="Save"
              cancelLabel="Cancel"
              asyncValidating={asyncValidating}
              submitting={submitting}
              pristine={pristine}
            />
          </div>
        </FormComponent>
      </div>
    );
  }
}
