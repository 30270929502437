import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

import { FormTitleComponent } from "src/components/forms/form_title_component";

import { Button } from "src/ui-kit";
import styles from "./style.module.less";
import i18n from "src/translations";

export class UserStateFormComponent extends React.Component {
  static propTypes = {
    sendUpdateUserAction: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  onSubmit = () => {
    const { sendUpdateUserAction, user, cancel } = this.props;
    const newState =
      user.attributes.state === "enabled" ? "disabled" : "enabled";

    const payload = {
      data: {
        id: user.id,
        type: "users",
        attributes: {
          state: newState
        }
      }
    };

    sendUpdateUserAction(payload).then(() => cancel());
  };

  render() {
    const { user, cancel } = this.props;

    const firstName = get(user, "attributes.profile.firstName", "");
    const lastName = get(user, "attributes.profile.lastName", "");
    const userName = firstName + " " + lastName;

    let action =
      user.attributes.state === "enabled"
        ? i18n.t(`actions.disable`)
        : i18n.t(`actions.enable`);

    action = action.charAt(0).toLowerCase() + action.slice(1);

    return (
      <div>
        <FormTitleComponent>
          <h4>{i18n.t(`headers.confirm`)}</h4>
        </FormTitleComponent>
        <p className={styles["align-text-center"]}>
          Are you sure you want to {action} access for {userName} ?
        </p>
        <div className={`${styles["padding-top-12px"]} text-center`}>
          <Button
            className="btn dashboard-btn"
            subtype="submit"
            onClick={this.onSubmit}
          >
            Yes
          </Button>
          <Button
            type="button"
            className="btn btn-default"
            subtype="button"
            onClick={cancel}
          >
            No
          </Button>
        </div>
      </div>
    );
  }
}
