import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// HOCs
import { withWoundAssessmentsLoaderFactory } from "src/hoc";

// Component
import { WoundAssessmentProgressListComponent } from "src/components/wound_assessment_progress_list_component";

// Selectors
import { woundAssessmentsResourceSelectors } from "src/selectors/data_selectors";
import { selectedProgressFilterSelector } from "src/selectors/section_selectors/wounds";

//Query Payload and Context Selectors
import {
  allPagesContextsForSelectedProgressSelector,
  createAssessmentsFirstPageContextSelectorWithProgress,
  assessmentsFirstPageContextAllProgressValuesSelector
} from "src/selectors/context_selectors/wounds";
import {
  allPagesPayloadsForSelectedProgressSelector,
  createAssessmentsFirstPagePayloadSelectorWithProgress
} from "src/selectors/query_selectors/wounds";

function mapStateToProps(state) {
  const selectedProgressFilter = selectedProgressFilterSelector(state);
  const allPagesPayloadsForSelectedProgress = allPagesPayloadsForSelectedProgressSelector(
    state
  );
  const allPagesContextsForSelectedProgress = allPagesContextsForSelectedProgressSelector(
    state
  );

  let nextPagePayload, nextPageContext;

  for (let context of allPagesContextsForSelectedProgress) {
    if (
      !woundAssessmentsResourceSelectors.createDataForContextSelector(context)(
        state
      ).length
    ) {
      let nextPageIndex = allPagesContextsForSelectedProgress.indexOf(context);
      nextPagePayload = allPagesPayloadsForSelectedProgress[nextPageIndex];
      nextPageContext = context;
      break;
    }
  }

  return {
    counts: woundAssessmentsResourceSelectors.createRecordCountSelector(
      assessmentsFirstPageContextAllProgressValuesSelector
    )(state),
    assessments: woundAssessmentsResourceSelectors.createDataForContextSelector(
      allPagesContextsForSelectedProgress
    )(state),
    nextPagePayload,
    nextPageContext,
    selectedProgressFilter
  };
}

export const WoundAssessmentProgressListContainer = flowRight(
  withWoundAssessmentsLoaderFactory(
    createAssessmentsFirstPageContextSelectorWithProgress,
    createAssessmentsFirstPagePayloadSelectorWithProgress
  ),
  connect(mapStateToProps)
)(WoundAssessmentProgressListComponent);
