import React from "react";

// TODO: This is a temp SVG. need to replace with official SVG.
export class MonitoringIconComponent extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 256.539 256.539"
        version="1.1"
        viewBox="0 0 256.539 256.539"
        xmlSpace="preserve"
      >
        <path d="M45.852 143.504h16.635c2.184 0 3.638-1.427 4.387-3.347 2.585-6.657 5.175-13.319 7.765-19.969 3.3 6.786 6.608 13.565 9.904 20.35 1.765 3.616 7.373 2.55 8.32-1.087 4.799-18.425 9.6-36.853 14.398-55.281 4.088 18.662 8.176 37.331 12.261 55.992 1.051 4.794 7.297 3.859 8.777 0a83438.29 83438.29 0 0013.538-35.411c3.758 11.662 7.505 23.321 11.263 34.985 1.158 3.585 6.368 4.712 8.323 1.086 2.523-4.682 5.052-9.373 7.565-14.061 1.671 4.464 3.357 8.933 5.022 13.4.722 1.925 2.225 3.342 4.388 3.342h42.686c5.87 0 5.87-9.1 0-9.1h-36.917c-1.604 0-2.55.224-3.108.498.046-.812-.162-2.204-1.016-4.484-1.979-5.278-3.96-10.552-5.931-15.828-1.3-3.453-6.297-4.837-8.322-1.087-2.417 4.487-4.835 8.98-7.247 13.467-3.98-12.364-7.962-24.729-11.943-37.089-1.452-4.51-7.19-4.146-8.779 0-4.24 11.09-8.485 22.186-12.723 33.278-4.364-19.9-8.727-39.801-13.083-59.699-.978-4.464-7.67-4.245-8.777 0a200334.22 200334.22 0 00-16.077 61.748l-9.064-18.621c-1.904-3.91-7.036-2.216-8.32 1.087-3.057 7.855-6.109 15.719-9.168 23.577-.597 1.549-1.039 2.503-1.371 3.153H45.837c-5.856.001-5.856 9.101.015 9.101z" />
        <path d="M0 22.364v169.163h91.985v34.83h69.414v-34.83h95.141V22.364H0zm230.54 143.163H25.999V48.363H230.54v117.164z" />
        <path d="M54.613 227.017H198.772V234.176H54.613z" />
      </svg>
    );
  }
}
