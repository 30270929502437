import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { DownloadManagerComponent } from "src/components/download_manager/download_manager_component";

// Selectors
import { showDownloadManagerBodySelector } from "src/selectors/section_selectors/global/show_download_manager_body_selector";

function mapStateToProps(state) {
  return {
    shouldShowBody: showDownloadManagerBodySelector(state)
  };
}

export const DownloadManagerContainer = withRouter(
  connect(mapStateToProps)(DownloadManagerComponent)
);
