import { createSelector } from "reselect";

// Selectors
import { createSingleRevisionQuerySelector } from "..";

/**
 * create a selector to get the api context for a single revision
 * @param {Object} props
 * @param {string} props.revisionId
 * @returns {function} selector
 */
export function createSingleRevisionContextSelector({ revisionId }) {
  return createSelector(
    createSingleRevisionQuerySelector({ revisionId }),
    queries => {
      return queries;
    }
  );
}
