import { createSelector } from "reselect";
import uniq from "lodash.uniq";

//Payload Generator
import { createLatestEventGetPayload } from "src/api/events/payload_generators";

// Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { createAllPagesRevisionContextsForFiltersSelector } from "../../revisions/create_all_pages_revision_contexts_for_filters_selector";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api query for the latest locked event with a revisions filter
 * @param {Object} props
 * @param {string} props.filters
 * @returns {function} selector
 */
export function createLatestSignedEventQueryByRevisionIdForRevisionsFilterSelector({
  filters
}) {
  return createSelector(
    revisionsResourceSelectors.createDataForContextSelector(
      createAllPagesRevisionContextsForFiltersSelector({ filters })
    ),
    revisions => {
      const ids = uniq(revisions.map(r => r.id));
      return ids.reduce((agg, id) => {
        agg[id] = buildQueryString(
          createLatestEventGetPayload({
            revisionId: id,
            eventType: "signed"
          })
        );
        return agg;
      }, {});
    }
  );
}
