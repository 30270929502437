import { createSelector } from "reselect";

//<-------- Note: Code commented out to enable revisions comparisons for all users------------->//

// Constants
// import { policies } from "src/constants";

// Selectors
// import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";

export const revisionComparisonEnabledSelector = createSelector(
  //   createPolicyForInternalNameSelector(policies.revisionComparisonViewEnabled),
  // policy => !!policy && policy.attributes.policyValue
  () => {
    return true;
  },
  enabled => enabled
);
