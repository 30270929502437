import { createSelector } from "reselect";

// Constants
import { appModes } from "src/constants";

// Selectors
import { modeSelector } from "../mode_selector";
import { partnerNameSelector } from "../partner_name_selector";

/**
 * Selector that determines if the patient name navbar is enabled on the patient view
 */
export const patientNameNavbarEnabledSelector = createSelector(
  partnerNameSelector,
  modeSelector,
  (partnerName, mode) => {
    if (partnerName == "pcc" || partnerName == "pcc_wound") {
      return false;
    } else if (mode == appModes.patientViewHospital) {
      return false;
    }

    return true;
  }
);
