import { createSelector } from "reselect";
import { locationsToDisplaySelector } from "src/selectors/data_selectors/locations";
import union from "lodash.union";
import { camelize } from "humps";

export const riskAssessmentTypesSelector = createSelector(
  locationsToDisplaySelector,
  locations => {
    const allAssessmentPolicies = locations.map(
      loc => loc.riskAssessmentPolicies
    );
    const assessmentPolicies = union(...allAssessmentPolicies).map(value => {
      return camelize(value);
    });

    // if policies are not set defaults to only braden
    return assessmentPolicies.length ? assessmentPolicies : ["braden"];
  }
);
