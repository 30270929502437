import { connect } from "react-redux";
//Lodash
import get from "lodash.get";

// Components
import { RevisionWoundDetailsComponent } from "src/components/revision_wound_details_component";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";
//
// Selectors
import { createLatestAssessmentAnswerForRevisionSelector } from "src/selectors/data_selectors";
import { createLastFacilityQueryForPatientIdSelector } from "src/selectors/api_selectors/global/patients";
import { patientsSectionSelector } from "src/selectors/section_selectors/patients";

// Selectors
import { qaNoteEnabledPolicySelector } from "src/selectors/ui_config_selectors/global";

const enableRevisionToBeEditedSelector = SingleValueReduxUtil.createSelectors(
  "revisionEnabledToBeEdited",
  patientsSectionSelector
);

const enableRevisionToBeEditedAction = SingleValueReduxUtil.createActionCreators(
  "revisionEnabledToBeEdited"
);

function mapStateToProps(state, ownProps) {
  const assessmentAnswer = createLatestAssessmentAnswerForRevisionSelector(
    ownProps.revision
  )(state);
  const patientId = get(assessmentAnswer, "attributes.patientId", "");
  const revision = ownProps.revision;
  const revisionEditFormVisible =
    enableRevisionToBeEditedSelector.fieldSelector(state) == revision.id;

  return {
    assessmentAnswer,
    patientId,
    lastFacilityQuery: createLastFacilityQueryForPatientIdSelector({
      patientId
    })(state),
    showEdit: revisionEditFormVisible,
    qaNoteAllowed: qaNoteEnabledPolicySelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cancel() {
      dispatch(enableRevisionToBeEditedAction.setValue(""));
    }
  };
}

export const RevisionWoundDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RevisionWoundDetailsComponent);
