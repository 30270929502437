import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

import i18n from "src/translations";

// Constants
import { loadStates } from "src/constants/load_states";

// Components
import { GridRow, GridColumn } from "src/components/grid";
import { LoadingComponent } from "src/components/loading_component";
import { TabViewComponent } from "src/components/tab_view_component";

// Containers
import { SeriesDetailsHeaderContainer } from "src/containers/series_details_header_container";
import { RevisionsListContainer } from "src/containers/revisions_list_container";
import { ComparisonViewRevisionsComparisonContainer } from "src/containers/revisions_comparison_container";
import { TreatmentNavViewRevisionsComparisonContainer } from "src/containers/revisions_comparison_container";
import { RevisionsTableContainer } from "src/containers/tables/revisions_table_container";

// Styles
import styles from "./style.module.less";

// Legacy
// import { Breadcrumbs, Button, Row, Column, Glyph } from "@sw/ui-kit";
import { Button, Breadcrumbs } from "src/ui-kit";
import SeriesAnalytics from "src/containers/series/AnalyticsContainer";
import ContextComponent from "src/components/context_component";

/**
 * The series details - header, graphs, and list of revisions
 */
export class SeriesDetailsComponent extends PureComponent {
  static propTypes = {
    patient: PropTypes.object,
    patientLoadState: PropTypes.string,
    series: PropTypes.object,
    seriesLoadState: PropTypes.string,
    assessmentAnswer: PropTypes.object,
    breadcrumbsEnabled: PropTypes.bool.isRequired,
    revisionComparisonEnabled: PropTypes.bool.isRequired,
    revisionsTableEnabled: PropTypes.bool.isRequired,
    revisionTreatmentNavigatorEnabled: PropTypes.bool.isRequired,
    revisionsContextEnabled: PropTypes.bool.isRequired,
    goBack: PropTypes.func.isRequired
  };

  state = {
    selectedTab: "list"
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * Render the top grey bar in the component
   * TODO: the navbar should be a single component that is router dependent
   */
  renderControlNavbar() {
    const { patient } = this.props;
    const firstName = get({ patient }, "patient.attributes.firstName", ""),
      lastName = get({ patient }, "patient.attributes.lastName", "");

    return (
      <GridRow className="panel-headline">
        <GridColumn xs="1">
          <Button
            onClick={this.props.goBack}
            className={`${styles["margintop8px"]} dashboard-btn`}
          >
            Back
          </Button>
        </GridColumn>
        <GridColumn xs="11">
          <h1 className={styles["margintop11px"]}>
            {firstName} {lastName}
          </h1>
        </GridColumn>
      </GridRow>
    );
  }

  renderBreadcrumbs() {
    const { patient } = this.props;

    const firstName = get({ patient }, "patient.attributes.firstName", ""),
      lastName = get({ patient }, "patient.attributes.lastName", "");

    return (
      <Breadcrumbs
        className={styles["breadcrumb-headline"]}
        items={[
          {
            text: `${firstName} ${lastName}`,
            onClick: this.props.goBack
          },
          {
            text: `"Wound ${i18n.t("text.evaluation")}"`,
            active: true
          }
        ]}
      />
    );
  }

  renderTabs(series) {
    const {
      revisionComparisonEnabled,
      revisionTreatmentNavigatorEnabled,
      revisionsTableEnabled,
      revisionsContextEnabled,
      patient
    } = this.props;
    let tabs = [];

    tabs.push({
      value: "list",
      title: `${i18n.t("headers.list")}`
    });

    if (revisionComparisonEnabled) {
      tabs.push({
        value: "comparison",
        title: `${i18n.t("headers.comparison")}`
      });
    }

    if (revisionsTableEnabled) {
      tabs.push({
        value: "table",
        title: `${i18n.t("headers.table")}`
      });
    }

    if (revisionTreatmentNavigatorEnabled) {
      tabs.push({
        value: "treatmentNavigator",
        title: `${i18n.t("headers.treatmentNavigator")}`
      });
    }

    if (revisionsContextEnabled) {
      tabs.push({
        value: "context",
        title: `${i18n.t("headers.context")}`
      });
    }

    let tabContent;
    switch (this.state.selectedTab) {
      case "list":
        tabContent = <RevisionsListContainer series={series} />;
        break;
      case "comparison":
        tabContent = (
          <ComparisonViewRevisionsComparisonContainer
            navigationType={"plusButton"}
            seriesId={series.id}
          />
        );
        break;
      case "treatmentNavigator":
        tabContent = (
          <TreatmentNavViewRevisionsComparisonContainer
            navigationType={"closureChart"}
            seriesId={series.id}
          />
        );
      case "table":
        tabContent = (
          <RevisionsTableContainer
            seriesId={series.id}
            patientId={patient.id}
          />
        );
        break;
      case "context":
        tabContent = (
          <ContextComponent
            seriesId={series.id}
            patientType={patient.attributes.visitClass}
          />
        );
        break;
    }

    return (
      <TabViewComponent
        tabHeaderType="title"
        tabs={tabs}
        selectedTabValue={this.state.selectedTab}
        selectTab={this.onSelectTab}
        sizeLarge={true}
        marginClass={"marginBottom20px"}
        horizontalSpread={true}
      >
        {tabContent}
      </TabViewComponent>
    );
  }

  onSelectTab = tab => {
    this.setState({
      selectedTab: tab
    });
  };

  render() {
    const {
      patient,
      patientLoadState,
      series,
      seriesLoadState,
      assessmentAnswer,
      breadcrumbsEnabled,
      revisionComparisonEnabled,
      revisionTreatmentNavigatorEnabled
    } = this.props;

    if (!patient && patientLoadState == loadStates.loading) {
      return <LoadingComponent />;
    } else if (seriesLoadState == loadStates.loading) {
      return <LoadingComponent />;
    } else if (!patient && patientLoadState == loadStates.error) {
      // TODO: handle patient load error
      return <LoadingComponent />;
    } else if (!series && seriesLoadState == loadStates.loading) {
      // TODO: handle series load error
      return <LoadingComponent />;
    }

    const woundType = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.woundType",
      ""
    );

    const seriesId = get(series, "id", null);

    return (
      <div>
        {breadcrumbsEnabled
          ? this.renderBreadcrumbs()
          : this.renderControlNavbar()}
        <GridRow className={styles["header-container"]}>
          <GridColumn sm="6">
            <SeriesDetailsHeaderContainer />
          </GridColumn>
          <GridColumn sm="6">
            {seriesId && (
              <SeriesAnalytics id={series.id} woundType={woundType} />
            )}
          </GridColumn>
        </GridRow>
        {revisionComparisonEnabled || revisionTreatmentNavigatorEnabled ? (
          this.renderTabs(series)
        ) : (
          <div>
            <hr />
            <RevisionsListContainer series={series} />
          </div>
        )}
      </div>
    );
  }
}
