import { connect } from "react-redux";

// Component
import { WoundProgressTallyByLocationTableComponent } from "src/components/tables";

// Selector
import { selectedLocationsSelector } from "src/selectors/section_selectors/wounds";
import { createWoundTalliesContextSelector } from "src/selectors/api_selectors/wounds/wound_tallies/create_wound_tallies_context_selector";

function mapStateToProps(state) {
  return {
    locations: selectedLocationsSelector(state),
    context: createWoundTalliesContextSelector()(state)
  };
}

export const WoundProgressTallyByLocationTableContainer = connect(
  mapStateToProps
)(WoundProgressTallyByLocationTableComponent);
