import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//Components
import { RevisionsTableComponent } from "src/components/tables/revisions_table_component";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

// Actions
import {
  revisionsResourceActions,
  loadWoundSeriesRevisionsAction
} from "src/actions/data_actions";

import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import {
  createFirstPageContextSelector,
  createCurrentPageContextSelector,
  createCurrentPageQuerySelector
} from "src/selectors/api_selectors/patients/revisions_table";
import {
  patientsSectionSelector,
  pdfExportEnabledSelector
} from "src/selectors/section_selectors/patients";

import { localizationDictionaryForSeriesIdSelector } from "src/selectors/section_selectors/global";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

import { createDataForRevisionsWithUsers } from "src/selectors/data_selectors/revisions/create_data_for_revisions_with_users";
import { assessmentVersionSelector } from "src/selectors/config_selectors";
import {
  bwatScoreEnabledSelector,
  woundSummaryPdfEnabledSelector,
  encounterNamingConventionSelector
} from "src/selectors/ui_config_selectors/global";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const revisionsTablePageLimitSelector = SingleValueReduxUtil.createSelectors(
  "revisionsTablePageLimit",
  patientsSectionSelector
);

const setRevisionsTablePageLimitActions = SingleValueReduxUtil.createActionCreators(
  "revisionsTablePageLimit"
);
const enableRevisionToBeEditedSelector = SingleValueReduxUtil.createSelectors(
  "revisionEnabledToBeEdited",
  patientsSectionSelector
);

function mapStateToProps(state, ownProps) {
  const { seriesId, patientId } = ownProps;
  const firstPageContextSelector = createFirstPageContextSelector({ seriesId });
  const currentPageContextSelector = createCurrentPageContextSelector({
    seriesId
  });
  const revisionBeingEdited = enableRevisionToBeEditedSelector.fieldSelector(
    state
  );

  return {
    patientId,
    revisions: revisionsResourceSelectors.createDataForContextSelector(
      currentPageContextSelector
    )(state),
    revisionsWithUserProfile: createDataForRevisionsWithUsers(
      currentPageContextSelector
    )(state),
    firstPageContext: firstPageContextSelector(state),
    pageCount:
      revisionsResourceSelectors.createPageCountSelector(
        currentPageContextSelector,
        revisionsTablePageLimitSelector.fieldSelector
      )(state) || 0,
    pageLimit: revisionsTablePageLimitSelector.fieldSelector(state),
    currentPage: revisionsResourceSelectors.createCurrentPageForContextSelector(
      firstPageContextSelector
    )(state),
    revisionBeingEdited: revisionBeingEdited,
    bwatScoreEnabled: bwatScoreEnabledSelector(state),
    pdfExportEnabled: pdfExportEnabledSelector(state),
    isWoundSummaryDownloadEnabled: woundSummaryPdfEnabledSelector(state),
    schemaLocalizationDictionary: localizationDictionaryForSeriesIdSelector(
      seriesId
    )(state),
    assessmentVersion: assessmentVersionSelector(state),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state),
    encounterNamingConvention: encounterNamingConventionSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetPageLimitAction: limit => {
      dispatch(setRevisionsTablePageLimitActions.setValue(limit));
    },
    sendSetPageAction: (context, pageNumber) => {
      dispatch(
        revisionsResourceActions.setPageForContextAction(context, pageNumber)
      );
    }
  };
}

export const RevisionsTableContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadWoundSeriesRevisionsAction,
    resourceSelectors: revisionsResourceSelectors,
    createContextSelector: createCurrentPageContextSelector,
    createQuerySelector: createCurrentPageQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: `RENDER_REVISIONS_TABLE_CONTAINER`
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RevisionsTableComponent);
