import { createSelector } from "reselect";

/**
 * create a selector to get the api query for the csv_export
 * @param {Object} props
 * @param {string} props.csvExportId
 * @returns {function} selector
 */
export function createCsvExportForIdQuerySelector({ csvExportId }) {
  return createSelector(() => {
    return csvExportId;
  });
}
