import { createSelector } from "reselect";
import { eventsResourceSelectors } from "..";
import { createLatestSignedEventForRevisionsQuerySelector } from "src/selectors/api_selectors/global/events/create_latest_signed_event_for_revisions_query_selector";

/**
 * Create a selector to get the latest signed event for a revision
 * @param {Object} props
 * @param {Object} props.revision
 * @returns {function} selector
 */
export function createLatestSignedEventForRevisionsSelector(revisions) {
  return createSelector(
    eventsResourceSelectors.createDataForContextSelector(
      createLatestSignedEventForRevisionsQuerySelector(revisions)
    ),
    eventsArray => {
      const deconstructedRevisions = revisions.revisions;

      return deconstructedRevisions.reduce((agg, r) => {
        let revisionEvent = eventsArray.filter(
          event => event.attributes.revisionId == r.id
        );

        agg[r.id] = revisionEvent.length ? revisionEvent[0] : undefined;
        return agg;
      }, {});
    }
  );
}
