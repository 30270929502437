import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import flowRight from "lodash.flowright";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc";

// Components
import { PatientDetailsComponent } from "src/components/patient_details_component";

// Actions
import { loadPatientsAction } from "src/actions/data_actions";

// Selectors
import { patientsResourceSelectors } from "src/selectors/data_selectors";

import { createPatientQueryForPatientIdSelector } from "src/selectors/api_selectors/global/patients";

import {
  createPatientForIdSelector,
  createStudyForPatientIdSelector
} from "src/selectors/data_selectors";
import {
  patientHeaderEnabledSelector,
  patientNameNavbarEnabledSelector
} from "src/selectors/config_selectors";
import { patientOverviewEnabledSelector } from "src/selectors/ui_config_selectors/patients/patient_overview";
import { alertsEnabledSelector } from "src/selectors/ui_config_selectors/global";

function mapStateToProps(state, ownProps) {
  const patientId = ownProps.match.params.patientId;

  return {
    patient: createPatientForIdSelector(patientId)(state),
    study: createStudyForPatientIdSelector(patientId)(state),
    patientHeaderEnabled: patientHeaderEnabledSelector(state),
    patientNameNavbarEnabled: patientNameNavbarEnabledSelector(state),
    patientOverviewEnabled: patientOverviewEnabledSelector(state),
    patientAlertsEnabled: alertsEnabledSelector(state)
  };
}

export const PatientDetailsContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadPatientsAction,
    resourceSelectors: patientsResourceSelectors,
    createQuerySelector: createPatientQueryForPatientIdSelector,
    createContextSelector: createPatientQueryForPatientIdSelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: "RENDER_PATIENT_DETAILS_COMPONENT"
  }),
  withRouter,
  connect(mapStateToProps)
)(PatientDetailsComponent);
