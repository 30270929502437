import { get } from "cookies-js";

/**
 * Get cookie value for the key
 * @param {string} key
 * @returns {string} the value
 */
export function getCookieValueForKey(key) {
  return get(key) || null;
}
