import { createResourceActions } from "src/redux_resources_v1";
import normalize from "json-api-normalizer";

// Error tracking
import { trackError } from "src/error_tracking";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

/**
 * Create a load data action creator
 * @param {Object} options
 * @param {string} options.resourceName resource name
 * @param {Object} options.api api object that responds to "post" call
 * @param {function} options.onSuccess thunk action for success case
 * @param {function} options.onError thunk action for error case
 * @returns {Function} action creator for a thunk action to POST the resource
 */
export function createPostDataAction({
  resourceName,
  api,
  responseNormalizer,
  onSuccess,
  onError
}) {
  const analyticsEventName = `API_POST_${resourceName.toUpperCase()}`;
  const resourceActions = createResourceActions(resourceName);
  return ({ payload }) => {
    return dispatch => {
      dispatch(resourceActions.loadingPostAction());
      startTimeTrack(analyticsEventName);

      return api
        .post({ payload })
        .then(response => {
          finishTimeTrack(analyticsEventName);

          // Normalize response
          let normalizedData;
          if (responseNormalizer) {
            normalizedData = responseNormalizer(response.data);
          } else {
            normalizedData = normalize(response.data);
          }

          // Update the resource
          dispatch(
            resourceActions.populatePostAction({
              data: normalizedData[resourceName]
            })
          );

          if (onSuccess) {
            onSuccess(dispatch);
          }
        })
        .catch(err => {
          finishTimeTrack(analyticsEventName, {
            error: true,
            errorCode: err.response ? err.response.status : undefined
          });
          trackError(err);
          dispatch(resourceActions.loadErrorPostAction());

          if (onError) {
            onError(dispatch, err);
          }
        });
    };
  };
}
