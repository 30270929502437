import { createSelector } from "reselect";
import { adminSectionSelector } from "../admin_section_selector";

/**
 * Selector to select the selected Patient Table Page Number
 */

export const selectedPatientTablePageNumberSelector = createSelector(
  adminSectionSelector,
  adminSection => {
    return adminSection.selectedPatientTablePageNumber;
  }
);
