import { createSelector } from "reselect";
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";

//Constants
import { sections } from "src/constants";

/**
 * Selector for if the user has closed/opened the download manager or not
 */
export const downloadManagerOpenSelector = createSelector(
  createSectionSelectors(sections.global).sectionSelector,
  section => {
    return section.downloadManagerOpen;
  }
);
