import { createSelector } from "reselect";
import { frameConfigSelector } from "../frame_config_selector";
/**
 * Selector to get whether or not annotations are showing on frames
 */
export const annotationSelector = createSelector(
  frameConfigSelector,
  config => {
    return config.showAnnotation;
  }
);
