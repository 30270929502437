import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//Components
import { EvaluationListWoundTableComponent } from "src/components/tables/evaluation_list_wound_table_component";

//HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

//Actions
import {
  loadAssessmentSummariesAction,
  loadWoundRevisionsAction,
  loadWoundSeriesAction
} from "src/actions/data_actions";

//Constants
import { analyticsEventNames } from "src/constants";

//Selectors
import { assessmentSummariesResourceSelectors } from "src/selectors/data_selectors";
import { woundRevisionsResourceSelector } from "src/selectors/data_selectors/wound_revisions";
import { createFirstPageContextSelector } from "src/selectors/api_selectors/evaluation_list/wound/create_first_page_context_selector";
import { createCurrentPageContextSelector } from "src/selectors/api_selectors/evaluation_list/wound/create_current_page_context_selector";
import { createCurrentPagePayloadSelector } from "src/selectors/api_selectors/evaluation_list/wound/create_current_page_payload_selector";
import { evaluationListWoundSectionSelector } from "src/selectors/section_selectors/evaluation_list/evaluation_list_wound_selector";
import { woundNumbersEnabledSelector } from "src/selectors/ui_config_selectors/global";
import { woundSeriesResourceSelector } from "src/selectors/data_selectors/wound_series";
import { createWoundSeriesQuerySelector } from "src/selectors/api_selectors/evaluation_list/wound_series/create_wound_series_query_selector";
import { createWoundSeriesContextSelector } from "src/selectors/api_selectors/evaluation_list/wound_series/create_wound_series_context_selector";
import { createAllPagesPayloadSelector } from "src/selectors/api_selectors/evaluation_list/wound_revisions/create_all_pages_payload_selector";
import { createAllPagesContextSelector } from "src/selectors/api_selectors/evaluation_list/wound_revisions/create_all_pages_context_selector";
import { createLocalizationDictionarySelectorForAssessmentSummaries } from "src/selectors/section_selectors/global";
import {
  assessmentVersionSelector,
  patientLinksEnabledSelector
} from "src/selectors/config_selectors";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";
import { assessmentSummariesResourceActions } from "../../../actions/data_actions";

const woundEvaluationPageLimitSelector = SingleValueReduxUtil.createSelectors(
  "woundEvaluationPageLimit",
  evaluationListWoundSectionSelector
);

const setWoundEvaluationPageLimitAction = SingleValueReduxUtil.createActionCreators(
  "woundEvaluationPageLimit"
);

function mapStateToProps(state) {
  const firstPageContextSelector = createFirstPageContextSelector();
  const assessmentSummaries = assessmentSummariesResourceSelectors.createDataForContextSelector(
    createCurrentPageContextSelector()
  )(state);
  return {
    assessmentSummaries,
    schemaLocalizationDictionary: createLocalizationDictionarySelectorForAssessmentSummaries(
      assessmentSummaries
    )(state),
    totalCount: assessmentSummariesResourceSelectors.createRecordCountSelector(
      createFirstPageContextSelector()
    )(state),
    pageLimit: woundEvaluationPageLimitSelector.fieldSelector(state),
    pageCount:
      assessmentSummariesResourceSelectors.createPageCountSelector(
        firstPageContextSelector,
        woundEvaluationPageLimitSelector.fieldSelector
      )(state) || 0,
    currentPage: assessmentSummariesResourceSelectors.createCurrentPageForContextSelector(
      firstPageContextSelector
    )(state),
    firstPageContext: firstPageContextSelector(state),
    woundNumbersEnabled: woundNumbersEnabledSelector(state),
    assessmentVersion: assessmentVersionSelector(state),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state),
    patientLinksEnabled: patientLinksEnabledSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetPageLimitAction: limit => {
      dispatch(setWoundEvaluationPageLimitAction.setValue(limit));
    },
    sendSetPageAction: (context, pageNumber) => {
      dispatch(
        assessmentSummariesResourceActions.setPageForContextAction(
          context,
          pageNumber
        )
      );
    }
  };
}

export const EvaluationListWoundTableContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadWoundSeriesAction,
    resourceSelectors: woundSeriesResourceSelector,
    createContextSelector: createWoundSeriesContextSelector,
    createQuerySelector: createWoundSeriesQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadWoundRevisionsAction,
    resourceSelectors: woundRevisionsResourceSelector,
    createContextSelector: createAllPagesContextSelector,
    createPayloadSelector: createAllPagesPayloadSelector,
    waitForParentLoaderToComplete: true
  }),
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentSummariesAction,
    resourceSelectors: assessmentSummariesResourceSelectors,
    createContextSelector: createCurrentPageContextSelector,
    createPayloadSelector: createCurrentPagePayloadSelector,
    waitForParentLoaderToComplete: true
  }),
  withLoadingComponentFactory({
    analyticsEventName: analyticsEventNames.renderUserEvaluationListWoundTable
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EvaluationListWoundTableComponent);
