import { createSelector } from "reselect";
import get from "lodash.get";
import {
  hasAllowEditOtherUsersWorkPermissionSelector,
  hasSignWoundRevisionPermissionSelector,
  hasLockWoundRevisionsPermissionSelector
} from "src/selectors/ui_config_selectors/patients";
import { createNestedAssessmentSchemasForAssessmentAnswerSelector } from "src/selectors/data_selectors";
import { createRevisionIsFirstInSeriesSelector } from "src/selectors/data_selectors/revisions/create_revision_is_first_in_series_selector";
import { assessmentAnswersResourceSelectors } from "src/selectors/data_selectors";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";
import { isMissingRequiredFields } from "src/models/assessment_answers";
import { containsRequiredFieldsForAssessmentAnswers } from "src/utils/required_fields";

export function createSignAndLockIsDisabledSelector({
  assessmentAnswer,
  revision,
  schemas
}) {
  return createSelector(
    hasAllowEditOtherUsersWorkPermissionSelector,
    createNestedAssessmentSchemasForAssessmentAnswerSelector(assessmentAnswer),
    createRevisionIsFirstInSeriesSelector(revision),
    hasLockWoundRevisionsPermissionSelector,
    hasSignWoundRevisionPermissionSelector,
    assessmentAnswersResourceSelectors.deleteLoadStateWithStatusByIdSelector,
    assessmentAnswersResourceSelectors.putLoadStateSelector,
    currentUserSelector,
    (
      canEditOtherUsersWork,
      nestedAssessmentSchemas,
      revisionIsFirstInSeries,
      canLockWoundRevisions,
      canSignWoundRevisions,
      putLoadState,
      deleteLoadStateWithStatusById,
      currentUser
    ) => {
      const assessmentAnswerAnswersJson =
        assessmentAnswer.attributes.answersJson;
      const revisionAuthorId = get(revision, "attributes.userId", null);
      const isRevisionAuthor =
        revisionAuthorId && revisionAuthorId === currentUser.id;
      const hasRequiredFields = !isMissingRequiredFields(
        nestedAssessmentSchemas,
        assessmentAnswer,
        revisionIsFirstInSeries
      );
      return (
        (!canEditOtherUsersWork && !isRevisionAuthor) ||
        !hasRequiredFields ||
        !containsRequiredFieldsForAssessmentAnswers(
          schemas,
          assessmentAnswerAnswersJson,
          revisionIsFirstInSeries
        ) ||
        !canLockWoundRevisions ||
        !canSignWoundRevisions ||
        putLoadState[assessmentAnswer.id] === "loading" ||
        deleteLoadStateWithStatusById[assessmentAnswer.id] === "loading" ||
        deleteLoadStateWithStatusById[assessmentAnswer.id] === "loaded"
      );
    }
  );
}
