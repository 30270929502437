import { connect } from "react-redux";

//Component
import { PatientOverviewTabContentComponent } from "src/components/patient_overview/patient_overview_tab_content_component";

//Selectors
import { patientsSectionSelector } from "src/selectors/section_selectors/patients";

//UI Processors
import SingleValue from "src/ui_processors/single_value";
const selectedTabSelectors = SingleValue.createSelectors(
  "patientOverviewSelectedTab",
  patientsSectionSelector
);

function mapStateToProps(state) {
  return {
    //TO DO: Content should be provided using a resource loader that takes selectedTab as an argument
    //Potentially, all different selected tabs will use similar api patterns and
    //data returned will follow the question/answer-schema/data pattern for quick feature completion
    content: selectedTabSelectors.fieldSelector(state)
  };
}

export const PatientOverviewTabContentContainer = connect(mapStateToProps)(
  PatientOverviewTabContentComponent
);
