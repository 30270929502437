import { createSelector } from "reselect";
import { createArrayOfSeriesIdsFromAssessmentSummariesSelector } from "src/selectors/api_selectors/global/healing_index/create_array_of_series_ids_from_assessment_summaries_selector";

export function createHealingIndexListQueryForSeriesIdsSelector() {
  return createSelector(
    createArrayOfSeriesIdsFromAssessmentSummariesSelector,
    series => {
      return `?filter[series_id]=${series.join()}`;
    }
  );
}
