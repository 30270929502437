import { createSelector } from "reselect";

// Constants
import { sections } from "src/constants";

// Selectors
import { createSectionSelectors } from "src/ui_processors";
const sectionSelectors = createSectionSelectors(sections.wounds);

/**
 * Get selected payers
 */
export const selectedPayersIdsFilterSelector = createSelector(
  sectionSelectors.filtersSelector,
  filters => {
    const payersFilter = filters.find(filter => filter.key == "payers");

    if (payersFilter && payersFilter.value.length) {
      return payersFilter.value.map(filt => filt.value);
    } else {
      return [];
    }
  }
);
