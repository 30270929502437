import { createSelector } from "reselect";

// Selectors
import { createCurrentPageQuerySelector } from "../create_current_page_query_selector";

/**
 * create selector that gets context for the current page of revisions table
 */
export function createCurrentPageContextSelector({ seriesId }) {
  return createSelector(
    createCurrentPageQuerySelector({ seriesId }),
    currentPageQuery => currentPageQuery
  );
}
