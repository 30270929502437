import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { get, omitBy, isEmpty } from "lodash";
import { FormComponent } from "src/components/forms/form_component_legacy";
import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormSubmitComponent } from "src/components/forms/form_submit_component";
import { reduxFormNames } from "src/constants";
import { createPostPayload } from "src/api/saved_filters/payload_generators";
import { required } from "src/validations";
import i18n from "src/translations";
import { ComplianceContext } from "src/pages/compliance/components/compliance_provider_component.js";
import { buildFilterPayloadObject } from "src/pages/shared/helpers/filterHelpers.js";
import styles from "./style.module.less";

function SavedFilterFormModal(props) {
  const {
    savedFilter,
    sendPatchSavedFilterAction,
    sendPostSavedFilterAction,
    currentFilter,
    cancel,
    onSubmit,
    section,
    singleFacilityId,
    currentUser,
    sendDeleteSavedFilterAction,
    sendDeselectFilterAction,
    submitting,
    pristine
  } = props;

  const [initialValues, setInitialValues] = useState({});
  const {
    state: { filters: contextFilters }
  } = useContext(ComplianceContext);

  useEffect(() => {
    if (savedFilter) {
      setInitialValues({ filterName: savedFilter.attributes.title });
    }
  }, [savedFilter]);

  const contextFiltersPayload = () => {
    const selectedContextFilters = omitBy(contextFilters, isEmpty);

    return Object.entries(selectedContextFilters).map(buildFilterPayloadObject);
  };

  const updateSavedFilter = ({ filterName }) => {
    const payload = createPostPayload({
      id: savedFilter.id,
      filterName,
      filters: [...currentFilter, ...contextFiltersPayload()],
      section: get(savedFilter, "attributes.parameters.section"),
      appliesToFacilityWithId: get(
        savedFilter,
        "attributes.parameters.appliesToFacilityWithId"
      )
    });

    return sendPatchSavedFilterAction(payload);
  };

  const createSavedFilter = ({ filterName }) => {
    const payload = createPostPayload({
      filterName,
      filters: [...currentFilter, ...contextFiltersPayload()],
      section,
      ...(singleFacilityId && { appliesToFacilityWithId: singleFacilityId }),
      userId: currentUser.id
    });

    return sendPostSavedFilterAction(payload);
  };

  const submitForm = formValues => {
    const submitAction = savedFilter ? updateSavedFilter : createSavedFilter;

    submitAction(formValues).then(onSubmit);
  };

  const onDelete = () => {
    if (!savedFilter) {
      return;
    }

    sendDeleteSavedFilterAction(savedFilter).then(() => {
      sendDeselectFilterAction();
      cancel();
    });
  };

  return (
    <FormComponent
      initialValues={initialValues}
      form={reduxFormNames.savedFilterForm}
      onSubmit={submitForm}
    >
      <div className={styles["title-input"]}>
        <FormInputComponent
          name="filterName"
          component="input"
          componentType="text"
          validate={[required]}
          placeholder={i18n.t("headers.filterName")}
          labelText={i18n.t("headers.filterName")}
        />
      </div>
      <div className={styles["submission-container"]}>
        <FormSubmitComponent
          cancel={cancel}
          onDelete={onDelete}
          saveLabel={i18n.t("actions.saveFilter")}
          deleteLabel={i18n.t("actions.deleteFilter")}
          cancelLabel={i18n.t("actions.cancel")}
          submitting={submitting}
          pristine={pristine}
        />
      </div>
    </FormComponent>
  );
}

SavedFilterFormModal.prototypes = {
  savedFilter: PropTypes.object,
  currentFilter: PropTypes.array,
  section: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  singleFacilityId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  sendPostSavedFilterAction: PropTypes.func.isRequired,
  sendPatchSavedFilterAction: PropTypes.func.isRequired,
  sendDeleteSavedFilterAction: PropTypes.func.isRequired,
  sendDeselectFilterAction: PropTypes.func.isRequired
};

export default SavedFilterFormModal;
