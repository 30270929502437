import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "react-bootstrap";
import { Glyphicon } from "src/components/ui_kit";

// Components
import { TableComponent } from "src/components/tables/table_component";
import { TableNavigatorComponent } from "src/components/tables/table_navigator_component";
import { TablePageLimitDropdownComponent } from "../table_page_limit_dropdown_component";
import { Button } from "src/ui-kit";

// Containers
import { EditUserFormContainer } from "src/containers/forms";
import { UserStateFormContainer } from "src/containers/forms";

// Styles
import styles from "./style.module.less";

//Translations
import i18n from "src/translations";

export class AdminUsersTableComponent extends React.PureComponent {
  static propTypes = {
    users: PropTypes.array.isRequired,
    organizations: PropTypes.object.isRequired,
    allFacilities: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
    customRoles: PropTypes.object.isRequired,
    userEditEnabled: PropTypes.bool.isRequired,
    userDeleteEnabled: PropTypes.bool.isRequired
  };

  state = {
    editUser: {},
    showUserEditModal: false,
    showUserStateModal: false
  };

  actionFormatter = (cell, row) => {
    const { userDeleteEnabled, userEditEnabled } = this.props;

    if (!userDeleteEnabled && !userEditEnabled) {
      return <p> -- </p>;
    }

    const statusActionText =
      row.attributes.state === "enabled"
        ? i18n.t(`actions.disable`)
        : i18n.t(`actions.enable`);

    return (
      <div className={styles["users-table-actions-container"]}>
        {userEditEnabled && (
          <Button
            type="button"
            data-toggle="modal"
            onClick={e => this.onClickEdit(e, row)}
            data-target="#modalUserEdit"
            className="btn-default"
            subtype="button"
          >
            <Glyphicon glyph="pencil" className="margin-right5px" />
            Edit
          </Button>
        )}
        {userDeleteEnabled && (
          <Button
            type="button"
            data-toggle="modal"
            onClick={e => this.onShowUserStateModal(e, row)}
            data-target="#modalUserStatus"
            className="btn-default"
            subtype="button"
          >
            {statusActionText}
          </Button>
        )}
      </div>
    );
  };

  onClickEdit = (e, row) => {
    this.setState({
      ...this.state,
      editUser: row,
      showUserEditModal: true
    });
  };

  onShowUserStateModal = (e, row) => {
    this.setState({
      ...this.state,
      editUser: row,
      showUserStateModal: true
    });
  };

  displayOrganizationsFormatter = (cell, row) => {
    if (
      row.relationships.organizations &&
      row.relationships.organizations.data &&
      row.relationships.organizations.data.length
    ) {
      return row.relationships.organizations.data.map(item => {
        return (
          <div key={item.id} className={styles["margin-bottom10px"]}>
            <span className={styles["margin-right5px"]}>
              {this.props.organizations[item.id]
                ? this.props.organizations[item.id].attributes.name
                : ""}
            </span>
          </div>
        );
      });
    }
  };

  displayFacilitiesFormatter = (cell, row) => {
    let facilities = row.relationships.facilities;
    if (facilities && facilities.data && facilities.data.length) {
      return facilities.data.map(item => {
        if (this.props.allFacilities[item.id])
          return (
            <div key={item.id} className={styles["margin-bottom10px"]}>
              <span className={styles["margin-right5px"]}>
                {this.props.allFacilities[item.id].attributes.name}
              </span>
            </div>
          );
      });
    }
  };

  displayUnitsFormatter = (cell, row) => {
    let units = row.relationships.facilityUnits;
    if (units && units.data && units.data.length) {
      return units.data.map(item => {
        if (this.props.allUnits[item.id])
          return (
            <div key={item.id} className={styles["margin-bottom10px"]}>
              <span className={styles["margin-right5px"]}>
                {this.props.allUnits[item.id].attributes.name}
              </span>
            </div>
          );
      });
    }
  };

  displayPartnersFormatter = (cell, row) => {
    let partnerId = row.attributes.partnerId;
    if (partnerId && this.props.partners[partnerId]) {
      return (
        <div key={row.id} className={styles["margin-bottom10px"]}>
          <span className={styles["margin-right5px"]}>
            {this.props.partners[partnerId].attributes.name}
          </span>
        </div>
      );
    }
  };

  displayRolesFormatter = (cell, row) => {
    const { roles, customRoles } = this.props;
    const rolesObject = { ...roles, ...customRoles };

    const rolesArray = row.relationships.roles;
    if (rolesArray && rolesArray.data && rolesArray.data.length) {
      return rolesArray.data.map((itemObj, index) => {
        if (rolesObject[itemObj.id])
          return (
            <div
              key={itemObj.id + index}
              className={styles["margin-bottom10px"]}
            >
              <span className={styles["margin-right5px"]}>
                {rolesObject[itemObj.id].attributes.primaryName}
              </span>
            </div>
          );
      });
    }
  };

  hideUserEditModal = () => {
    this.setState({ ...this.state, showUserEditModal: false });
  };

  hideUserStateModal = () => {
    this.setState({ ...this.state, showUserStateModal: false });
  };

  render() {
    const {
      users,
      selectedUserTablePageNumber,
      userTablePageCount,
      selectUserTablePage,
      selectedUserTablePageLimit,
      selectUserTablePageLimit
    } = this.props;

    let usersArray = Object.values(users);

    const columns = [
      {
        dataField: "attributes.profile.firstName",
        text: "First Name"
      },
      {
        dataField: "attributes.profile.lastName",
        text: "Last Name"
      },
      {
        dataField: "attributes.email",
        text: "Email"
      },
      {
        dataField: "attributes.partnerId",
        formatter: this.displayPartnersFormatter,
        text: "Partners"
      },
      {
        dataField: "attributes.organizationId",
        formatter: this.displayOrganizationsFormatter,
        text: "Organizations"
      },
      {
        dataField: "attributes.facilityId",
        formatter: this.displayFacilitiesFormatter,
        text: "Facilities"
      },
      {
        dataField: "attributes.units",
        formatter: this.displayUnitsFormatter,
        text: "Units"
      },
      {
        dataField: "attributes.role",
        text: "Role",
        formatter: this.displayRolesFormatter
      },
      {
        dataField: "attributes.state",
        text: "State",
        formatter: (cell, row) =>
          i18n.exists(`data.userStates.${row.attributes.state}`)
            ? i18n.t(`data.userStates.${row.attributes.state}`)
            : ""
      },
      {
        dataField: "hidden",
        formatter: this.actionFormatter,
        text: "Actions"
      }
    ];

    return (
      <div>
        <TableComponent data={usersArray} keyField="id" columns={columns} />
        <div className="pull-left">
          <TablePageLimitDropdownComponent
            selectPageLimit={selectUserTablePageLimit}
            selectedPageLimit={selectedUserTablePageLimit}
          />
        </div>
        <TableNavigatorComponent
          pageNumber={selectedUserTablePageNumber}
          pageCount={userTablePageCount}
          selectTablePage={selectUserTablePage}
        />
        <Modal
          show={this.state.showUserEditModal}
          onHide={this.hideUserEditModal}
          bsSize={"lg"}
        >
          <ModalBody>
            <EditUserFormContainer
              user={this.state.editUser}
              cancel={this.hideUserEditModal}
            />
          </ModalBody>
        </Modal>
        <Modal
          show={this.state.showUserStateModal}
          onHide={this.hideUserStateModal}
          bsSize={"lg"}
        >
          <ModalBody>
            <UserStateFormContainer
              user={this.state.editUser}
              cancel={this.hideUserStateModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
