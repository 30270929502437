import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// Styles
import styles from "./style.module.less";

export class ImageDeclinedComponent extends React.PureComponent {
  static propTypes = {
    patientType: PropTypes.string.isRequired,
    borderRadius: PropTypes.bool,
    displayTextHorizontal: PropTypes.bool
  };

  render() {
    const { displayTextHorizontal, patientType } = this.props;

    let borderClassName =
      styles[
        classnames({
          "thumbnail-border-radius": this.props.borderRadius
        })
      ];

    return (
      <div className={`${styles["image-container"]} ${borderClassName}`}>
        {!displayTextHorizontal ? (
          <div className={styles["innerdiv"]}>
            {patientType} <br />
            Declined
            <br /> Photo
          </div>
        ) : (
          <div className={styles["imageText"]}>
            {patientType} Declined Photo
          </div>
        )}
      </div>
    );
  }
}
