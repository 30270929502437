import { dataActionTypes } from "src/action_types/data_action_types";

import { loadStates } from "src/constants/load_states";

const defaultState = {
  loadState: loadStates.loading,
  data: []
};

/**
 * Reducer for population tally data
 */
export function populationTalliesByEtiologyReducer(
  state = defaultState,
  action
) {
  switch (action.type) {
    case dataActionTypes.populatePopulationTallies:
      return {
        data: action.payload,
        loadState: loadStates.loaded
      };
    case dataActionTypes.populationTallyLoading:
      return { ...state, loadState: loadStates.loading };

    case dataActionTypes.populationTallyLoadError:
      return { ...state, loadState: loadStates.error };

    default:
      return state;
  }
}
