export const sections = {
  myDashboard: "myDashboard",
  alerts: "alerts",
  evaluationListWound: "evaluationListWound",
  evaluationListBraden: "evaluationListBraden",
  evaluationListPURS: "evaluationListPURS",
  evaluationListTotalSkin: "evaluationListTotalSkin",
  compliance: "compliance",
  outcomes: "outcomes",
  patients: "patients",
  wounds: "wounds",
  risk: "risk",
  survey: "survey",
  population: "population",
  admin: "admin",
  global: "global",
  exports: "exports",
  monitoring: "monitoring",
  woundRisk: "woundRisk"
};
