import { otpCode } from "./otp_code";
import { patientUser } from "./patient_user";
import { userActions } from "./user_actions";
import { patientCreateOrUpdate } from "./patient_create_or_update";
import { qaNotes } from "./qa_notes";

export const successMessages = {
  otpCode,
  patientUser,
  userActions,
  patientCreateOrUpdate,
  qaNotes
};
