import apiFetch from "app/util/apiFetch";

import BRADEN_BUCKETS from "./bradenBuckets";
const [maxScore, minScore] = BRADEN_BUCKETS["No Risk"];

export default function loadPatientsForNoRisk(
  locationIds,
  startTime,
  endTime,
  nextCursor
) {
  return dispatch => {
    let payload, type;

    if (nextCursor) {
      payload = {
        location_ids: locationIds.join(),
        start_time: startTime,
        end_time: endTime,
        min_score: minScore,
        max_score: maxScore,
        next_cursor: nextCursor
      };
      type = "LOCATION_NORISK_PATIENT_LOAD_MORE";
    } else {
      payload = {
        location_ids: locationIds.join(),
        start_time: startTime,
        end_time: endTime,
        min_score: minScore,
        max_score: maxScore
      };
      type = "LOCATION_NORISK_PATIENT_LOAD";
    }

    return dispatch(
      apiFetch(`/api/v2/risk_dashboard/braden`, {
        method: "POST",
        body: payload,
        headers: {
          Accept: "application/json",
          "X-HTTP-Method-Override": "GET"
        }
      })
    ).then(({ patients, meta }) => {
      dispatch({
        type,
        patients,
        meta
      });

      return patients;
    });
  };
}
