// @flow
import { createAction } from "redux-actions";
import { retypeAction } from "redux-retype-actions";
import { decamelize } from "humps";
import {
  respToStore,
  indexRespToStore,
  propertyRespToStore
} from "../reducerUtils/respToStore";
import {
  entityActionNames,
  createEntity,
  updateEntity,
  removeEntity,
  deleteAtPath,
  getEntity
} from "./actions";
export class Action {
  constants: $$mapOf<string>;
  baseActions: {
    create: (ent: Object) => any,
    update: (ent: Object) => any,
    remove: (id: $$id) => any,
    get: (ent: Object) => any,
    index: (ents: Object[]) => any,
    getRelated: (id: $$id, relationshipName: string, ents: Object[]) => any,
    getAdditionalEntityProperties: (
      id: $$id,
      entityName: string,
      entity: Object
    ) => any
  };
  constructor(name: string, constants?: Object = {}) {
    const baseConstants = Object.keys(entityActionNames).reduce(
      (finalResult, key) => {
        const constant = entityActionNames[key](name);
        finalResult[constant] = constant;
        return finalResult;
      },
      {}
    );
    this.constants = Object.keys(constants).reduce((finalResult, constant) => {
      finalResult[constant] = constant;
      return finalResult;
    }, baseConstants);
    this.baseActions = {
      create: entity => createEntity(name, entity),
      update: entity => updateEntity(name, entity),
      remove: id => removeEntity(name, id),
      get: entity =>
        retypeAction(entityActionNames.get(name), respToStore(name, entity)),
      index: entities =>
        retypeAction(
          entityActionNames.index(name),
          indexRespToStore(name, entities)
        ),
      getRelated: (id, relationshipName, entities) => {
        if (entities instanceof Error) {
          return getEntity(name, entities);
        }
        return retypeAction(
          entityActionNames.getRelated(name),
          respToStore(name, { id, [relationshipName]: entities })
        );
      },
      addRelated: (id, relationshipName, entities) => {
        if (entities instanceof Error) {
          return getEntity(name, entities);
        }
        return retypeAction(
          entityActionNames.getRelated(name),
          respToStore(
            name,
            { id, [relationshipName]: entities },
            undefined,
            undefined,
            {
              relationships: {
                [name]: {
                  [relationshipName]: {
                    add: true
                  }
                }
              }
            }
          )
        );
      },
      getAdditionalEntityProperties: (id, entityName, entity) => {
        if (entity instanceof Error) {
          return getEntity(entityName, entity);
        }
        return retypeAction(
          entityActionNames.getAdditionalEntityProperties(name),
          respToStore(entityName, { id, ...entity })
        );
      }
    };
  }
  createAction(name: string, payload: Object) {
    return createAction(name)(payload);
  }
}

export class PropertyAction {
  constants: $$mapOf<string>;
  baseActions: {
    create: (ent: Object) => any,
    update: (ent: Object) => any,
    remove: () => any,
    get: (ent: Object) => any,
    getRelated: (relationshipName: string, ents: Object[]) => any
  };
  constructor(name: string, constants?: Object = {}) {
    const baseConstants = Object.keys(entityActionNames).reduce(
      (finalResult, key) => {
        const constant = entityActionNames[key](name);
        finalResult[constant] = constant;
        return finalResult;
      },
      {}
    );
    this.constants = Object.keys(constants).reduce((finalResult, constant) => {
      finalResult[constant] = constant;
      return finalResult;
    }, baseConstants);
    this.baseActions = {
      create: entity => createEntity("properties", { ...entity, id: name }),
      update: entity => updateEntity("properties", { ...entity, id: name }),
      remove: () => removeEntity("properties", name),
      deleteAtPath: (path: Array) =>
        deleteAtPath("properties", [name].concat(path)),
      get: entity =>
        retypeAction(
          `${entityActionNames.get("properties")}_${decamelize(
            name
          ).toUpperCase()}`,
          propertyRespToStore(name, entity)
        ),
      getRelated: (relationshipName, entities) => {
        if (entities instanceof Error) {
          return getEntity(name, entities);
        }
        return retypeAction(
          `${entityActionNames.getRelated("properties")}_${decamelize(
            name
          ).toUpperCase()}`,
          propertyRespToStore(name, { [relationshipName]: entities })
        );
      },
      addRelated: (relationshipName, entities) => {
        if (entities instanceof Error) {
          return getEntity(name, entities);
        }
        return retypeAction(
          `${entityActionNames.getRelated("properties")}_${decamelize(
            name
          ).toUpperCase()}`,
          propertyRespToStore(
            name,
            { [relationshipName]: entities },
            undefined,
            undefined,
            {
              relationships: {
                properties: {
                  [relationshipName]: {
                    add: true
                  }
                }
              }
            }
          )
        );
      }
    };
  }
  createAction(name: string, payload: Object) {
    return createAction(name)(payload);
  }
}
