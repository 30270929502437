import i18n from "src/translations";
import get from "lodash.get";

import { getWoundNumberTypeSubtypeString } from "src/utils/get_wound_number_type_subtype_string";

//Constants
import { assessmentTypes } from "src/constants";

//Formatters
import { woundEvaluationHyperlinkFormatter } from "../wound_evaluation_hyperlink_formatter";

/**
 * cell formatter to translate wound number, type and subtype
 * @param {object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.evaluationType the assessment type
 * @param {Object} row.attributes.resource.answersJson the answers json
 * @param {Object} row.attributes.resource.patientWoundNumber the patient wound number
 * @param {number} rowIndex
 * @param {Object} formatExtraData.localizationDictionary
 */
export const woundNumberTypeAndSubtypeFormatter = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => {
  const { localizationDictionary, subtypeKeysForSchema } = formatExtraData;
  const assessmentId = get(row, "attributes.resource.assessmentId");
  const localizationDictionaryForRow = localizationDictionary[assessmentId];
  const attributes = row.attributes;
  const woundNumber = get(attributes, "resource.patientWoundNumber");
  // Return N/A for non wound assessments
  if (attributes.evaluationType != assessmentTypes.wound) return "N/A";

  const woundType = get(attributes, "resource.answersJson.woundType");

  // Return not set if wound type is not set
  if (!woundType) {
    return woundEvaluationHyperlinkFormatter(
      `${woundNumber ? `${woundNumber} - ` : ""}${i18n.t("text.notSet")}`,
      row
    );
  }

  const woundNumberTypeSubtypeString = getWoundNumberTypeSubtypeString({
    answersJson: get(attributes, "resource.answersJson", {}),
    localizationDictionary: localizationDictionaryForRow,
    patientWoundNumber: woundNumber,
    subtypeKeysForSchema
  });

  return woundEvaluationHyperlinkFormatter(woundNumberTypeSubtypeString, row);
};
