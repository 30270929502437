import { woundEvaluations } from "./wound_evaluations";
import { otpCode } from "./otp_code";
import { userAlerts } from "./user_alerts";
import { loginError } from "./login_error";
import { patientUser } from "./patient_user";
import { userActions } from "./user_actions";
import { patientCreateOrUpdate } from "./patient_create_or_update";
import { patientSearch } from "./patient_search";
import { pdfErrors } from "./pdf_errors";
import { qaNotes } from "./qa_notes";

export const errorMessages = {
  loginError,
  woundEvaluations,
  userAlerts,
  otpCode,
  patientUser,
  userActions,
  patientCreateOrUpdate,
  patientSearch,
  pdfErrors,
  qaNotes
};
