import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import styles from "./style.module.less";

/**
 * React Component that is a text link and can be clicked to call a set of action(s) with there corresponding payload(s)
 * @param {Object} props
 * @param {string} props.label Link Label Text
 * @param {function} props.onClick Function to send actions on click
 */
export class TextLinkComponent extends PureComponent {
  static propTypes = {
    theme: PropTypes.oneOf(["light", "dark"]),
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  static defaultProps = {
    theme: "dark"
  };

  render() {
    const { theme, label, onClick } = this.props;

    const className = `theme-${theme}`;

    return (
      <a className={`${styles["link"]} ${styles[className]}`} onClick={onClick}>
        {label}
      </a>
    );
  }
}
