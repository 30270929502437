import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { sections } from "src/constants";

// Component
import { CheckboxFilterComponent } from "src/components/filters";

// Selectors
import { evaluationsFilterConfigSelector } from "src/selectors/ui_config_selectors/compliance";
import { localizationDictionaryForAllSchemasSelector } from "src/selectors/section_selectors/global";

// UI Processors
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";
import { createSectionActions } from "src/ui_processors/global/action_creators";

const sectionSelectors = createSectionSelectors(sections.compliance);
const sectionActions = createSectionActions(sections.compliance);

function mapStateToProps(state) {
  return {
    selectAllEnabled: true,
    schemaLocalizationDictionary: localizationDictionaryForAllSchemasSelector(
      state
    ),
    filterConfig: evaluationsFilterConfigSelector(state),
    filterCheckedState: sectionSelectors.createFilterCheckedStateSelector(
      evaluationsFilterConfigSelector(state)
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectCheckbox: filterObject => {
      dispatch(sectionActions.selectCheckbox(filterObject));
    },
    deselectCheckbox: filterObject => {
      dispatch(sectionActions.deselectCheckbox(filterObject));
    }
  };
}

export const SideBarEvaluationsFilterContainer = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckboxFilterComponent);
