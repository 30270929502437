import React from "react";
import PropTypes from "prop-types";

export class FormSubmitComponent extends React.PureComponent {
  static propTypes = {
    cancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    saveLabel: PropTypes.string.isRequired,
    deleteLabel: PropTypes.string,
    cancelLabel: PropTypes.string.isRequired,
    asyncValidating: PropTypes.bool,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool
  };

  render() {
    const {
      cancel,
      onDelete,
      saveLabel,
      deleteLabel,
      cancelLabel,
      asyncValidating,
      disable,
      pristine,
      submitting
    } = this.props;
    return (
      <div className="text-center">
        <button
          type="submit"
          disabled={asyncValidating || pristine || submitting || disable}
          className="btn dashboard-btn"
        >
          {saveLabel}
        </button>
        {onDelete && (
          <button
            disabled={submitting}
            type="button"
            className="btn btn-default"
            onClick={onDelete}
          >
            {deleteLabel}
          </button>
        )}
        <button
          disabled={submitting}
          type="button"
          data-dismiss="modal"
          className="btn btn-default"
          onClick={cancel}
        >
          {cancelLabel}
        </button>
      </div>
    );
  }
}
