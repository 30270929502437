import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash.get";

// Components
import { GridRow, GridColumn } from "src/components/grid";

// Models
import { evaluatePredicate } from "src/models/assessment_answers";

// Styles
import styles from "./style.module.less";

//Helpers
import { formatBodyLocationString } from "src/utils/format_location_string";

/**
 * Render answers for an assessment schema (static/not editible)
 */
export class SchemaAnswersComponent extends React.PureComponent {
  static propTypes = {
    assessmentAnswer: PropTypes.object.isRequired,
    assessmentSchema: PropTypes.object.isRequired,
    revisionIsFirstInSeries: PropTypes.bool
  };

  renderAnswerForQuestion(question) {
    const { assessmentAnswer, assessmentSchema } = this.props;
    const { name, type } = question;

    const answers = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson",
      null
    );

    const translations = get(
      { assessmentSchema },
      "assessmentSchema.attributes.schemaJson.localizationDictionary.base",
      null
    );

    if (!answers || (answers && !answers[name])) {
      return <div>Not Set</div>;
    } else {
      switch (type) {
        // Boolean value
        case 1:
          return <div>{answers[name] == "0" ? "Not Set" : "Yes"}</div>;

        // integer, string values
        case 2:
        case 4:
          return <div>{answers[name]}</div>;
        //float Values
        case 3:
          return (
            <div>
              {(Math.round(parseFloat(answers[name]) * 100) / 100).toString()}
            </div>
          );

        // date value
        case 5:
          return <div>{moment(answers[name]).format("YYYY MM DD")}</div>;

        // single select list (enumeration)
        case 6:
          if (translations && translations[`${name}.${answers[name]}`]) {
            if (name == "location") {
              let locationString = answers.location
                ? formatBodyLocationString(
                    {
                      location: answers.location,
                      sublocation: answers.sublocation,
                      lateralities: answers.laterality
                    },
                    translations
                  )
                : i18n.t("text.notSet");

              return <div>{locationString}</div>;
            }
            return (
              <div>
                {translations && translations[`${name}.${answers[name]}`]}
              </div>
            );
          } else {
            return <div>Not Set</div>;
          }

        // Multi select list (flags)
        case 7:
          if (answers[name].length > 0) {
            // Note - support is here for strings due to an android app error - this can be removed at some point
            const values =
              typeof answers[name] == "string"
                ? [answers[name]]
                : answers[name];

            const translatedList = values.map(value => {
              if (translations && translations[`${name}.${value}`]) {
                return {
                  value: value,
                  translatedValue:
                    translations && translations[`${name}.${value}`]
                };
              }
            });
            const sortedTranslatedList = translatedList.sort((a, b) => {
              if (a.translatedValue > b.translatedValue) return 1;
              else if (a.translatedValue < b.translatedValue) return -1;
              else return 0;
            });
            const list = sortedTranslatedList.map(item => {
              return (
                <li key={item.value}>
                  {item.translatedValue ? item.translatedValue : "Not Set"}
                </li>
              );
            });
            return <ul className={styles["answer-list"]}>{list}</ul>;
          } else {
            return <div>Not Set</div>;
          }
      }
    }
  }

  render() {
    const {
      assessmentAnswer,
      assessmentSchema,
      revisionIsFirstInSeries
    } = this.props;

    const schemaJson = get(assessmentSchema, "attributes.schemaJson", null);
    const schemaJsonQuestions = get(schemaJson, "questions", []);
    const translations = get(
      assessmentSchema,
      "attributes.schemaJson.localizationDictionary.base",
      null
    );

    const answersUI =
      schemaJson &&
      schemaJsonQuestions.map(question => {
        // Evaluate visible predicate
        if (
          question.visiblePredicateJson &&
          !evaluatePredicate({
            assessmentAnswer,
            revisionIsFirstInSeries,
            predicate: question.visiblePredicateJson
          })
        ) {
          return null;
        }

        const { name, type } = question;
        const label = translations[`${name}.title`];

        // don't show no value type
        if (type == 0) {
          return;
        }

        return (
          <div key={question.name}>
            <GridRow>
              <GridColumn sm="5">
                <b>{label}</b>
              </GridColumn>
              <GridColumn sm="7">
                {this.renderAnswerForQuestion(question)}
              </GridColumn>
            </GridRow>
            <hr />
          </div>
        );
      });

    // root schema
    return <div>{answersUI}</div>;
  }
}
