import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "react-bootstrap";
import { Glyphicon } from "src/components/ui_kit";
import styles from "./style.module.less";

//Custom checkbox component to use "indeterminate" for partial selections
import { Checkbox } from "../checkbox";

// Components
import { PencilIconComponent } from "../icon_components";

export class CheckboxListItem extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
    rightIcon: PropTypes.oneOf(["pencil"]),
    onRightIconClicked: PropTypes.func,
    disabled: PropTypes.bool,
    depth: PropTypes.number
  };

  static defaultProps = {
    disabled: false,
    depth: 0
  };

  state = {
    expanded: false
  };

  /**
   * Responsible for changing the expansion state of the container
   */
  handleExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  onChange = () => {
    this.props.onChange(this.props.value, this.props.checked);
  };

  getDepthStyle() {
    return {
      paddingLeft: `${this.props.depth * 20}px`
    };
  }

  renderCheckbox() {
    return (
      <div className={`${styles["checkbox"]} checkbox`}>
        <label>
          <Checkbox
            value={this.props.value}
            checked={this.props.checked}
            indeterminate={this.props.checked === "partial"}
            onChange={this.onChange}
            disabled={this.props.disabled}
          />
          {this.props.label}
        </label>
      </div>
    );
  }

  renderCheckboxWithExpansion() {
    return (
      <div className={`${styles["checkbox-expansion"]} checkbox`}>
        <label className={styles["checkbox-label"]}>
          <Checkbox
            value={this.props.value}
            checked={this.props.checked}
            onChange={this.onChange}
            disabled={this.props.disabled}
            indeterminate={this.props.checked === "partial"}
          />
          {this.props.label}
        </label>
        <Glyphicon
          onClick={this.handleExpand}
          glyph={this.state.expanded ? "menu-down" : "menu-right"}
          className="arrow-placement"
        />
      </div>
    );
  }

  renderRightIcon() {
    const { rightIcon, onRightIconClicked } = this.props;

    if (rightIcon) {
      return (
        <div
          className={styles["edit-filter-button"]}
          onClick={onRightIconClicked}
        >
          <PencilIconComponent />
        </div>
      );
    }
  }

  render() {
    const { disabled, depth } = this.props;

    // filter out null or undefined children
    const children = React.Children.toArray(this.props.children).filter(
      child => {
        return child != null && child != undefined;
      }
    );

    if (children.length) {
      // cascade disabled state
      // increase depth for nested checkbox items
      let childrenWithProps = children.map(child => {
        if (child) {
          return React.cloneElement(child, {
            disabled: disabled,
            depth: depth + 1
          });
        } else {
          return null;
        }
      });

      return (
        <li className={styles["list-item"]} style={this.getDepthStyle()}>
          {this.renderCheckboxWithExpansion()}
          {this.renderRightIcon()}
          <div>
            <Collapse in={this.state.expanded}>
              <ul>{childrenWithProps}</ul>
            </Collapse>
          </div>
        </li>
      );
    } else {
      return (
        <li className={styles["list-item"]} style={this.getDepthStyle()}>
          {this.renderCheckbox()}
          {this.renderRightIcon()}
        </li>
      );
    }
  }
}
