import PropTypes from "prop-types";
import React from "react";
import { ModalBody } from "react-bootstrap";
import i18n from "src/translations";
import { Glyphicon } from "src/components/ui_kit";
import { Link } from "react-router-dom";
import get from "lodash.get";

// Helpers
import { formatBodyLocationString } from "src/utils/format_location_string";

//Components
import { GridRow, GridColumn } from "src/components/grid";
import EncounterModalComponent from "../encounters/encounter_modal_component";

//Containers Import
import { RevisionWoundImageContainer } from "src/containers/revision_wound_image_container";
import { RevisionWoundImageHeaderContainer } from "src/containers/revision_wound_image_header_container";
import { RevisionWoundDetailsContainer } from "src/containers/revision_wound_details_container";
import { LockButtonContainer } from "src/containers/buttons/lock_button_container";
import { ReopenButtonContainer } from "src/containers/buttons/reopen_button_container";
import { SignAndLockButtonContainer } from "src/containers/buttons/sign_and_lock_button_container";
import { SignButtonContainer } from "src/containers/buttons/sign_button_container";
import { UnlockButtonContainer } from "src/containers/buttons/unlock_button_container";
import { SignAssessmentAnswerFormContainer } from "src/containers/forms/sign_assessment_answer_form_container";

// Styles
import styles from "./style.module.less";

// Model
import { getNumberTypeSubtypeStringForAssessmentAnswer } from "src/models/assessment_answers";

//Comments
//This component takes the revisions prop and the clickedRevision prop to show the
//selected revision and be able to navigate between the revisions via buttons

export class RevisionNavigationComponent extends React.Component {
  static propTypes = {
    selectedRevision: PropTypes.object.isRequired,
    series: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    assessmentAnswer: PropTypes.object.isRequired,
    schemas: PropTypes.array,
    isESignatureEnabled: PropTypes.bool.isRequired,
    patientLinksEnabled: PropTypes.bool.isRequired,
    encounterNamingConvention: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.setCurrentFrameTab = this.setCurrentFrameTab.bind(this);
  }

  state = {
    showESignature: false,
    actionSign: "actions.undefined",
    showEncounterView: false,
    currentFrameTab: ""
  };

  setCurrentFrameTab(newFrame) {
    this.setState({ currentFrameTab: newFrame });
  }

  signOrSignAndLockAction = actionType => {
    const {
      isESignatureEnabled,
      encounterNamingConvention,
      assessmentAnswer
    } = this.props;

    let encounterNumber = get(
      assessmentAnswer,
      "attributes.encounterNumber",
      null
    );
    if (encounterNamingConvention !== "None" && !encounterNumber) {
      return () => {
        this.setState({
          ...this.state,
          showEncounterView: true,
          actionSelected: `actions.${actionType}`
        });
      };
    } else {
      return isESignatureEnabled
        ? () => {
            this.setState({
              ...this.state,
              showESignature: true,
              actionSelected: `actions.${actionType}`
            });
          }
        : null;
    }
  };

  hideESignature = () => {
    this.setState({
      ...this.state,
      showESignature: false,
      actionSign: ""
    });
  };

  hideEncounterView = () => {
    this.setState({
      ...this.state,
      showEncounterView: false,
      actionSign: ""
    });
  };

  enableRevisionEdit = () => {
    this.props.sendEnableRevisionToBeEditedAction(
      this.props.selectedRevision.id
    );
  };

  renderEditAssessmentButton() {
    if (this.props.editWoundAssessmentVisible) {
      return (
        <button
          className="btn dashboard-btn"
          type="button"
          value="series_id"
          disabled={!this.props.editWoundAssessmentEnabled}
          onClick={this.enableRevisionEdit}
        >
          Edit
        </button>
      );
    }
  }

  renderAllActionButtons() {
    const { selectedRevision, assessmentAnswer, schemas } = this.props;

    if (!this.props.isRevisionBeingEdited) {
      return (
        <div className={styles["buttonContainer"]}>
          {this.renderEditAssessmentButton()}
          <SignButtonContainer
            onClick={this.signOrSignAndLockAction("sign")}
            assessmentAnswer={assessmentAnswer}
            revision={selectedRevision}
            schemas={schemas}
          >
            {i18n.t("actions.sign")}
          </SignButtonContainer>
          <ReopenButtonContainer
            assessmentAnswer={assessmentAnswer}
            revision={selectedRevision}
          >
            {i18n.t("actions.reopen")}
          </ReopenButtonContainer>
          <SignAndLockButtonContainer
            onClick={this.signOrSignAndLockAction("signAndLock")}
            assessmentAnswer={assessmentAnswer}
            revision={selectedRevision}
            schemas={schemas}
          >
            {i18n.t("actions.signAndLock")}
          </SignAndLockButtonContainer>
          <LockButtonContainer
            assessmentAnswer={assessmentAnswer}
            revision={selectedRevision}
          >
            {i18n.t("actions.lock")}
          </LockButtonContainer>
          <UnlockButtonContainer
            assessmentAnswer={assessmentAnswer}
            revision={selectedRevision}
          >
            {i18n.t("actions.unlock")}
          </UnlockButtonContainer>
        </div>
      );
    }
  }

  renderWoundLocationString = () => {
    const { assessmentAnswer, schemaLocalizationDictionary } = this.props;

    const location = get(
      assessmentAnswer,
      "attributes.answersJson.location",
      ""
    );

    const sublocation = get(
      assessmentAnswer,
      "attributes.answersJson.sublocation",
      ""
    );

    const lateralities = get(
      assessmentAnswer,
      "attributes.answersJson.laterality",
      []
    );

    const locationString = location
      ? formatBodyLocationString(
          {
            location,
            sublocation,
            lateralities
          },
          schemaLocalizationDictionary
        )
      : "Not Set";

    return <h2>{locationString}</h2>;
  };

  render() {
    const {
      selectedRevision,
      series,
      patient,
      assessmentAnswer,
      hide,
      hyperlinkHeadersEnabled,
      isRevisionBeingEdited,
      schemaLocalizationDictionary,
      subtypeKeysForSchema,
      patientLinksEnabled
    } = this.props;
    const {
      showESignature,
      showEncounterView,
      actionSelected,
      currentFrameTab
    } = this.state;
    let content;
    if (showEncounterView) {
      content = (
        <div className={styles["encounterModal"]}>
          <EncounterModalComponent
            cancel={this.hideEncounterView}
            assessmentAnswerId={assessmentAnswer.id}
            actionType={i18n.t(actionSelected)}
            patientId={get(assessmentAnswer, "attributes.patientId", null)}
            revisionCreatedAt={get(
              selectedRevision,
              "attributes.createdAt",
              null
            )}
          />
        </div>
      );
    } else if (showESignature && !showEncounterView) {
      content = (
        <div className={styles["esignatureForm"]}>
          <SignAssessmentAnswerFormContainer
            cancel={this.hideESignature}
            assessmentAnswerId={assessmentAnswer.id}
            actionType={i18n.t(actionSelected)}
          />
        </div>
      );
    } else {
      content = (
        <div>
          {this.renderAllActionButtons()}
          <div className={styles["detailStyle"]}>
            <RevisionWoundDetailsContainer revision={selectedRevision} />
          </div>
        </div>
      );
    }

    let mrnDisplay;
    let mrn = get(patient, "attributes.mrn", "");
    let firstName = get(patient, "attributes.firstName", "");
    let lastName = get(patient, "attributes.lastName", "");
    let patientWoundNumber = get(series, "attributes.patientWoundNumber", "");
    if (mrn) {
      mrnDisplay = " (" + mrn + ")";
    } else {
      mrnDisplay = "";
    }
    let header;
    if (hyperlinkHeadersEnabled && patientLinksEnabled) {
      header = (
        <div>
          <h1>
            <Link to={`/patients/${patient.id}`}>
              {firstName + " " + lastName + mrnDisplay}
            </Link>
          </h1>
          <h2>
            <Link to={`/patients/${patient.id}/series/${series.id}`}>
              {getNumberTypeSubtypeStringForAssessmentAnswer({
                assessmentAnswer: this.props.assessmentAnswer,
                localizationDictionary: schemaLocalizationDictionary,
                subtypeKeysForSchema,
                patientWoundNumber
              })}
            </Link>
          </h2>
        </div>
      );
    } else {
      header = (
        <div>
          <h1>{firstName + " " + lastName + mrnDisplay}</h1>
          <h2>
            {getNumberTypeSubtypeStringForAssessmentAnswer({
              assessmentAnswer: this.props.assessmentAnswer,
              localizationDictionary: schemaLocalizationDictionary,
              subtypeKeysForSchema,
              patientWoundNumber
            })}
          </h2>
        </div>
      );
    }

    return (
      <div className={styles["revisionNavigationBody"]}>
        <div className={styles["headerStyle"]}>
          {!isRevisionBeingEdited && (
            <div className={styles["exitStyle"]} onClick={hide}>
              <Glyphicon glyph="remove" />
            </div>
          )}
          {header}
          {this.renderWoundLocationString()}
        </div>
        <ModalBody style={{ height: "81%" }}>
          <GridRow className="modalBody">
            <GridColumn xs="4">
              <div className={styles["imageStyle"]}>
                <RevisionWoundImageHeaderContainer
                  revision={selectedRevision}
                />
                <RevisionWoundImageContainer
                  revision={selectedRevision}
                  isFromModal={true}
                  currentFrameTab={currentFrameTab}
                  setCurrentFrameTab={this.setCurrentFrameTab}
                />
              </div>
            </GridColumn>
            <GridColumn xs="8">{content}</GridColumn>
          </GridRow>
        </ModalBody>
      </div>
    );
  }
}
