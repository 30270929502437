import { connect } from "react-redux";
import { get } from "lodash";
import { sections } from "src/constants";
import { locationsFilterConfigSelector } from "src/selectors/ui_config_selectors/global";
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";
import { createSectionActions } from "src/ui_processors/global/action_creators";
import LegacyFilterSection from "src/pages/compliance/components/filters/legacy_filter_section";

const sectionSelectors = createSectionSelectors(sections.compliance);
const sectionActions = createSectionActions(sections.compliance);

const getLegacyFilterValues = state => {
  const complianceFilters = get(state, "sections.compliance.filters", []);
  const complianceLocationFilters = complianceFilters.find(
    lf => lf.key === "locations"
  );
  const filterValues = get(complianceLocationFilters, "value", []);
  return filterValues.map(fv => fv.value);
};

function mapStateToProps(state) {
  return {
    legacyCheckedFilters: getLegacyFilterValues(state),
    filterConfig: locationsFilterConfigSelector(state),
    filterCheckedState: sectionSelectors.createFilterCheckedStateSelector(
      locationsFilterConfigSelector(state)
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectCheckbox: filterObject => {
      dispatch(sectionActions.selectCheckbox(filterObject));
    },
    deselectCheckbox: filterObject => {
      dispatch(sectionActions.deselectCheckbox(filterObject));
    }
  };
}

const LegacyLocationsFilterWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(LegacyFilterSection);

export default LegacyLocationsFilterWrapper;
