import { trackError } from "src/error_tracking";

import Moment from "moment";
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";

// Api
import {
  fetchIncidenceChartData,
  fetchPrevalenceChartData
} from "src/api/legacy_population";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

/**
 * Action creator for setting the population load state to loading
 * @return {Object} redux action
 */
function populationIncidenceChartDataLoadingAction() {
  return {
    type: dataActionTypes.populationIncidenceChartDataLoading
  };
}

/**
 * Action creator for setting the population load state to error
 * @return {Object} redux action
 */
function populationIncidenceChartDataLoadErrorAction() {
  return {
    type: dataActionTypes.populationIncidenceChartDataLoadError
  };
}

/**
 * Action creator for populating the population chart data.
 * @param {Object} chartData the formatted chart data
 * @return {Object} redux action
 */
function populatePopulationIncidenceChartDataAction(chartData) {
  return {
    type: dataActionTypes.populatePopulationIncidenceChartData,
    payload: chartData
  };
}

/**
 * Action creator for loading the population chart data
 * @param {Object} queryParams the query params for filtering wounds
 */
export function loadPopulationIncidenceChartDataAction(queryParams) {
  return dispatch => {
    startTimeTrack("API_GET_POPULATION_INCIDENCE_CHART");

    dispatch(populationIncidenceChartDataLoadingAction());

    return fetchIncidenceChartData(queryParams)
      .then(response => {
        finishTimeTrack("API_GET_POPULATION_INCIDENCE_CHART");
        const chartData = calculateChartData(response.data.result);
        dispatch(populatePopulationIncidenceChartDataAction(chartData));
      })
      .catch(err => {
        if (err.message == "cancel") {
          return;
        }
        finishTimeTrack("API_GET_POPULATION_INCIDENCE_CHART", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
        trackError(err);
        dispatch(populationIncidenceChartDataLoadErrorAction());
      });
  };
}

/**
 * Action creator for setting the population chart load state to loading
 * @return {Object} redux action
 */
function populationPrevalenceChartDataLoadingAction() {
  return {
    type: dataActionTypes.populationPrevalenceChartDataLoading
  };
}

/**
 * Action creator for setting the population load state to error
 * @return {Object} redux action
 */
function populationPrevalenceChartDataLoadErrorAction() {
  return {
    type: dataActionTypes.populationPrevalenceChartDataLoadError
  };
}

/**
 * Action creator for populating the population chart data.
 * @param {Object} chartData the formatted chart data
 * @return {Object} redux action
 */
function populatePopulationPrevalenceChartDataAction(chartData) {
  return {
    type: dataActionTypes.populatePopulationPrevalenceChartData,
    payload: chartData
  };
}

/**
 * Action creator for loading the population chart data
 * @param {Object} queryParams the query params for filtering wounds
 */
export function loadPopulationPrevalenceChartDataAction(queryParams) {
  return dispatch => {
    startTimeTrack("API_GET_POPULATION_PREVALENCE_CHART");
    dispatch(populationPrevalenceChartDataLoadingAction());

    return fetchPrevalenceChartData(queryParams)
      .then(response => {
        finishTimeTrack("API_GET_POPULATION_PREVALENCE_CHART");
        const chartData = calculateChartData(response.data.result);
        dispatch(populatePopulationPrevalenceChartDataAction(chartData));
      })
      .catch(err => {
        if (err.message == "cancel") {
          return;
        }
        finishTimeTrack("API_GET_POPULATION_PREVALENCE_CHART", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
        trackError(err);
        dispatch(populationPrevalenceChartDataLoadErrorAction());
      });
  };
}
/**
 * Action creator for calculating the population chart data
 * @param {Object} result, result from api call and manipulate the data for calcualtion
 */
function calculateChartData(result) {
  result = result.filter(result => result.wound_type == "overall");
  const labels = uniq(
    result.map(point => new Moment(point.time_interval_start).format("MMM Do"))
  );
  const pointsGroupedByWoundTypes = groupBy(result, "wound_type");

  const datasets = Object.keys(pointsGroupedByWoundTypes).map(woundType => {
    // Get points for each wound type
    const points = pointsGroupedByWoundTypes[woundType];

    // Get the patient with wound count and patient count for each point
    const patientWithWoundCounts = points.map(
      point =>
        point.patients_with_wounds_count || point.patients_with_new_wounds_count
    );
    const patientCounts = points.map(point => point.patient_count);

    // Calculate incidencs/prevalence
    const data = points.map((point, index) => {
      return (
        Math.round(
          (patientWithWoundCounts[index] * 10000) / patientCounts[index]
        ) / 100
      );
    });

    // build dataset
    return {
      label: woundType == "null" ? "unknown" : woundType,
      data,
      patientWithWoundCounts,
      patientCounts
    };
  });

  return {
    labels,
    datasets
  };
}
