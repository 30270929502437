import { connect } from "react-redux";
import { isPristine, isSubmitting, change, clearFields } from "redux-form";

// Components
import { PatientAdtFormComponent } from "src/components/forms/patient_adt_form_component";

//selectors
import {
  patientsResourceSelectors,
  facilitiesResourceSelectors
} from "src/selectors/data_selectors";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";

import {
  createPatientAdtFormUnitOptionsSelector,
  patientsAdtLocationEnabledSelector
} from "src/selectors/section_selectors/admin";

//actions
import { createPatientADTAction } from "src/actions/data_actions";

import { reduxFormNames } from "src/constants";

function mapStateToProps(state) {
  return {
    facilities: facilitiesResourceSelectors.dataSelector(state),
    units: createPatientAdtFormUnitOptionsSelector(
      reduxFormNames.patientAdtForm
    )(state),
    formName: reduxFormNames.patientAdtForm,
    currentUser: currentUserSelector(state),
    patientsAdtLoadState: patientsResourceSelectors.createContextLoadStateSelectorWithContextString(
      "adminPatients"
    )(state),
    pristine: isPristine(reduxFormNames.patientAdtForm)(state),
    submitting: isSubmitting(reduxFormNames.patientAdtForm)(state),
    patientsAdtLocationEnabled: patientsAdtLocationEnabledSelector(
      reduxFormNames.patientAdtForm
    )(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    createPatientADT(payload) {
      return dispatch(createPatientADTAction(payload));
    },
    changeField(fieldName, fieldValue) {
      dispatch(change(reduxFormNames.patientAdtForm, fieldName, fieldValue));
    },
    clearFields(...fieldNames) {
      dispatch(
        clearFields(reduxFormNames.patientAdtForm, false, false, ...fieldNames)
      );
    }
  };
}
export const PatientAdtFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientAdtFormComponent);
