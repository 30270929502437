import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { GridRow, GridColumn } from "src/components/grid";
import { ImageComponent } from "src/components/image_component";
import { Glyph } from "src/ui-kit";

import { ImageDeclinedContainer } from "src/containers/image_declined_container";

// Model
import { getNumberTypeSubtypeStringForAssessmentAnswer } from "src/models/assessment_answers";

// Translations
import i18n from "src/translations";

// Utils
import { ageThisMoment } from "@sw/utils/timeUtils";
import { formatBodyLocationString } from "src/utils/format_location_string";

// Styles
import styles from "./style.module.less";

export class SeriesDetailsHeaderComponent extends React.PureComponent {
  static propTypes = {
    series: PropTypes.object.isRequired,
    frame: PropTypes.object.isRequired,
    assessmentAnswer: PropTypes.object.isRequired,
    woundNumbersEnabled: PropTypes.bool.isRequired,
    historyCauseEnabled: PropTypes.bool.isRequired,
    visitSequenceEnabled: PropTypes.bool.isRequired,
    assessmentVersion: PropTypes.string.isRequired
  };

  renderWoundLocation = () => {
    const { assessmentAnswer, schemaLocalizationDictionary } = this.props;

    const location = get(
      assessmentAnswer,
      "attributes.answersJson.location",
      ""
    );

    const sublocation = get(
      assessmentAnswer,
      "attributes.answersJson.sublocation",
      ""
    );

    const lateralities = get(
      assessmentAnswer,
      "attributes.answersJson.laterality",
      []
    );

    const locationString = location
      ? formatBodyLocationString(
          {
            location,
            sublocation,
            lateralities
          },
          schemaLocalizationDictionary
        )
      : "Not Set";

    return <h3>{locationString}</h3>;
  };

  render() {
    const {
      series,
      frame,
      assessmentAnswer,
      woundNumbersEnabled,
      historyCauseEnabled,
      visitSequenceEnabled,
      schemaLocalizationDictionary,
      assessmentVersion,
      subtypeKeysForSchema
    } = this.props;
    const woundNumber = series.attributes.patientWoundNumber;

    const woundProgressString = get(
      assessmentAnswer,
      "attributes.answersJson.overallProgress",
      null
    )
      ? i18n.t(
          `data.overallProgress.${assessmentVersion}.${assessmentAnswer.attributes.answersJson.overallProgress}`
        )
      : "Unknown";

    const acquiredVal = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.answersJson.inHouseAcquired",
        ""
      ),
      contentType = get({ frame }, "frame.attributes.contentType", ""),
      thumbnailUrl = get({ frame }, "frame.attributes.thumbnailUrl", ""),
      originAt = get({ series }, "series.attributes.originAt", ""),
      inHouseAcquired = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.answersJson.inHouseAcquired",
        ""
      );

    const woundAge = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.woundAge",
      "unknown"
    );

    let woundAgeFromOrigin = ageThisMoment(originAt);

    if (["chronic", "unknown"].includes(woundAge)) {
      woundAgeFromOrigin = i18n.t(`data.woundAge.${woundAge}`);
    }

    const acquiredString = acquiredVal
      ? `${i18n.t("glossary.acquired", { context: "capitalize" })}: ${i18n.t(
          `data.acquired.${assessmentVersion}.${acquiredVal}`
        )}`
      : `${i18n.t("glossary.acquired", { context: "capitalize" })}: ${i18n.t(
          "text.notSet"
        )}`;

    let history = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.history",
      ""
    );

    const visitFrequency = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.visitFrequency",
      "N/A"
    );

    if (history === "") {
      history = "Not Set";
    } else {
      history = i18n.t(`data.historyOrCause.${history}`);
    }
    return (
      <GridRow>
        <GridColumn xs="11">
          <div className="media">
            <div className="media-left">
              <div className={styles["wound-image-container"]}>
                {contentType == "declined" ? (
                  <ImageDeclinedContainer borderRadius={true} />
                ) : (
                  <ImageComponent imageUrl={thumbnailUrl} />
                )}
              </div>
            </div>
            <div className="media-body">
              <div className={styles["series-details-header-text-container"]}>
                <h2>
                  {getNumberTypeSubtypeStringForAssessmentAnswer({
                    assessmentAnswer: this.props.assessmentAnswer,
                    localizationDictionary: schemaLocalizationDictionary,
                    subtypeKeysForSchema,
                    ...(woundNumbersEnabled && {
                      patientWoundNumber: woundNumber
                    })
                  })}
                </h2>
                {this.renderWoundLocation()}
                {historyCauseEnabled && <h3>History/Cause: {history}</h3>}
                <br />
                <h2>
                  {`Status ${woundProgressString} - ${woundAgeFromOrigin}`}
                </h2>
                <h3>{acquiredString}</h3>
                {visitSequenceEnabled && (
                  <h3>{"Current Wk Visit Freq: " + visitFrequency}</h3>
                )}
              </div>
            </div>
          </div>
        </GridColumn>
        <GridColumn xs="1">
          {inHouseAcquired === "inHouse" && (
            <div
              className={`pull-right ${
                styles["series-details-header-text-container"]
              }`}
            >
              <h2>
                <Glyph name="home" />
              </h2>
            </div>
          )}
        </GridColumn>
      </GridRow>
    );
  }
}
