import { createSelector } from "reselect";
import { rolesResourceSelectors } from "../roles_resource_selectors";

/**
 * Selector creator for Roles
 * @param {string} permissionTypeId
 * @returns {Function} selector
 */
export function createRolePermissionForPermissionTypeIdSelector(
  permissionTypeId
) {
  return createSelector(
    rolesResourceSelectors.dataSelector,
    roles => {
      return Object.values(roles)[0].attributes.rolePermissionTypes.find(
        p => p.permissionTypeId == permissionTypeId
      );
    }
  );
}
