// @flow
import { decamelizeKeys } from "humps";
export default function constructQS(obj: Object) {
  let qsComponents = [];
  const decamelizedObject = decamelizeKeys(obj);
  Object.keys(decamelizedObject).forEach(key => {
    let value = decamelizedObject[key];
    qsComponents.push(
      `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    );
  });

  if (qsComponents.length) {
    return `?${qsComponents.join("&")}`;
  } else {
    return "";
  }
}
