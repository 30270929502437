import { createSelector } from "reselect";

// Api
import { createFirstPageContextSelector } from "../create_first_page_context_selector";
import { createGetPayload } from "src/api/assessment_summaries/payload_generators";
import {
  answersFilterSelector,
  selectedLocationIdsSelector,
  evaluationListTotalSkinSectionSelector
} from "src/selectors/section_selectors/evaluation_list/evaluation_list_total_skin_selector";
import { assessmentSummariesResourceSelectors } from "src/selectors/data_selectors";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const totalSkinEvaluationPageLimitSelector = SingleValueReduxUtil.createSelectors(
  "totalSkinEvaluationPageLimit",
  evaluationListTotalSkinSectionSelector
);

export function createCurrentPagePayloadSelector() {
  return createSelector(
    assessmentSummariesResourceSelectors.createCurrentPageForContextSelector(
      createFirstPageContextSelector()
    ),
    totalSkinEvaluationPageLimitSelector.fieldSelector,
    selectedLocationIdsSelector,
    answersFilterSelector,
    (currentPage, pageLimit, selectedLocationIds, answers) => {
      if (!selectedLocationIds.length) return {};
      return createGetPayload({
        pageNumber: currentPage,
        pageLimit,
        answers,
        patient_state: "admitted,new",
        selectedLocationIds,
        sortEnabled: "created_at"
      });
    }
  );
}
