import { trackError } from "src/error_tracking";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";

// Api
import { fetchWoundAssessmentProgressChartData } from "src/api/wound_assessments";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";
import { calculateWoundsProgressChartValues } from "src/utils/calculate_wounds_progress_chart_values";

/**
 * Action creator for setting the wound progress chart load state to loading
 * @return {Object} redux action
 */
function woundAssessmentProgressChartDataLoadingAction() {
  return {
    type: dataActionTypes.woundAssessmentProgressChartDataLoading
  };
}

/**
 * Action creator for setting the wound progress chart load state to error
 * @return {Object} redux action
 */
function woundAssessmentProgressChartDataLoadErrorAction() {
  return {
    type: dataActionTypes.woundAssessmentProgressChartDataLoadError
  };
}

/**
 * Action creator for populating the wound assessment progress chart data.
 * @param {Object} chartData the formatted chart data
 * @return {Object} redux action
 */
function populateWoundAssessmentProgressChartDataAction(chartData) {
  return {
    type: dataActionTypes.populateWoundAssessmentProgressChartData,
    payload: chartData
  };
}

/**
 * Action creator for loading the wound progress chart data
 * @param {Object} queryParams the query params for filtering wounds
 */
export function loadWoundAssessmentProgressChartDataAction(queryParams) {
  return dispatch => {
    startTimeTrack("API_GET_WOUNDS_DASHBOARD_CLINICAL_CHART");
    dispatch(woundAssessmentProgressChartDataLoadingAction());

    return fetchWoundAssessmentProgressChartData(queryParams)
      .then(response => {
        finishTimeTrack("API_GET_WOUNDS_DASHBOARD_CLINICAL_CHART");
        const chartData = calculateWoundsProgressChartValues(
          response.data.chart,
          "time_interval_start",
          "wound_overall_progress",
          "wound_overall_progress_count"
        );
        dispatch(populateWoundAssessmentProgressChartDataAction(chartData));
      })
      .catch(err => {
        if (err.message == "cancel") {
          return;
        }
        finishTimeTrack("API_GET_WOUNDS_DASHBOARD_CLINICAL_CHART", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
        trackError(err);
        dispatch(woundAssessmentProgressChartDataLoadErrorAction());
      });
  };
}
