import React from "react";
import MedlineLogoComponent from "src/components/medline_logo_component";
import { MedlineEmblemComponent } from "src/components/icon_components/medline_emblem_component";

export default class MedlineSignInComponent extends React.PureComponent {
  render() {
    return (
      <div className="sign-in-content">
        <div className="background">
          <MedlineEmblemComponent />
        </div>
        <div className="row foreground">
          <div className="col-xs-12 col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3 text-center">
            <div className="medline-logo">
              <MedlineLogoComponent />
              <p className="caption">NE1&#174; Wound Management System</p>
            </div>
          </div>
        </div>
        {this.props.children}
        <div className="footer">
          <p>Powered by Swift Medical</p>
        </div>
      </div>
    );
  }
}
