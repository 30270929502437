export const woundAssessmentsByProgressActions = {
  populateWoundAssessmentsByProgress: "POPULATE_WOUND_ASSESSMENTS_BY_PROGRESS",
  replaceWoundAssessmentsByProgress: "REPLACE_WOUND_ASSESSMENTS_BY_PROGRESS",
  woundAssessmentsByProgressLoading:
    "DATA_WOUND_ASSESSMENTS_BY_PROGRESS_LOADING",
  woundAssessmentsByProgressLoadingMore:
    "DATA_WOUND_ASSESSMENTS_BY_PROGRESS_LOADING_MORE",
  woundAssessmentsByProgressloadError:
    "DATA_WOUND_ASSESSMENTS_BY_PROGRESS_LOAD_ERROR",

  populateWoundAssessmentByProgressCounts:
    "POPULATE_WOUND_ASSESSMENT_COUNTS_BY_PROGRESS",
  woundAssessmentByProgressCountsLoading:
    "DATA_WOUND_ASSESSMENT_COUNTS_BY_PROGRESS_LOADING",
  woundAssessmentByProgressCountsLoadError:
    "DATA_WOUND_ASSESSMENT_COUNTS_BY_PROGRESS_LOAD_ERROR"
};
