import i18n from "src/translations";
import get from "lodash.get";

import { resourceNames } from "src/constants";

import { create } from "src/actions/flashMessageActions";
import { createPostDataAction } from "../create_post_data_action";
import { createLoadDataAction } from "../create_load_data_action";
import { enterOtpCodeApi, generateOtpCodeApi } from "src/api/otp_code";

export const enterOtpCodeAction = createPostDataAction({
  resourceName: resourceNames.otpCode,
  api: enterOtpCodeApi,
  onSuccess: dispatch => {
    window.location.reload(true);
    dispatch(
      create(i18n.t("interaction.successMessages.otpCode.validated"), "success")
    );
  },
  onError: (dispatch, error) => {
    const errorMessage = get(error, "response.data.error", null);

    if (errorMessage) {
      let errorKey = errorMessage === "Expired" ? "expired" : "incorrect";
      dispatch(
        create(i18n.t(`interaction.errorMessages.otpCode.${errorKey}`), "error")
      );
    } else {
      dispatch(
        create(
          i18n.t(
            "Sorry, there was an error verifying the code. Please try again"
          ),
          "error"
        )
      );
    }
  }
});

export const requestNewOtpCodeAction = createLoadDataAction({
  resourceName: resourceNames.otpCode,
  api: generateOtpCodeApi,
  onSuccess: dispatch => {
    dispatch(
      create(i18n.t("interaction.successMessages.otpCode.resent"), "success")
    );
  },
  onError: (dispatch, error) => {
    const errorCode = get(error, "response.status", "");
    if (errorCode === "") {
      dispatch(
        create(i18n.t("interaction.successMessages.otpCode.resent"), "success")
      );
    } else {
      dispatch(
        create(i18n.t("interaction.errorMessages.otpCode.resendError"), "error")
      );
    }
  }
});
