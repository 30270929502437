import { createSelector } from "reselect";

const getPatientLinkTemplate = state => {
  return state.application.patient_link_template;
};

export const patientLinkTemplateSelector = createSelector(
  [getPatientLinkTemplate],
  linkTemplate => {
    return linkTemplate;
  }
);
