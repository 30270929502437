// @flow
import groupBy from "lodash.groupby";
import moment from "moment";
import { bradenScores, nortonScores } from "./scores";

type $aggregates = Array<{ braden_score: number, braden_score_count: number }>;

export function calculateNortonAggregates(aggregates: $aggregates) {
  return _calculateAggregates(aggregates, nortonScores, "norton_plus");
}

export function calculateBradenAggregates(aggregates: $aggregates) {
  return _calculateAggregates(aggregates, bradenScores, "braden");
}

function _calculateAggregates(aggregates, scores, name) {
  const totals = Object.keys(scores).reduce((finalResult, riskName) => {
    finalResult[`${riskName}Total`] = 0;
    return finalResult;
  }, {});
  const scoreName = `${name}_score`;
  const scoreCountName = `${scoreName}_count`;
  aggregates.forEach(aggregate => {
    for (let bradenBucket in scores) {
      const { minScore: minValue, maxScore: maxValue } = scores[bradenBucket];
      if (
        maxValue !== null &&
        minValue !== null &&
        aggregate[scoreName] !== null &&
        aggregate[scoreName] <= maxValue &&
        aggregate[scoreName] >= minValue
      ) {
        totals[`${bradenBucket}Total`] += aggregate[scoreCountName];
      } else if (
        maxValue === null &&
        minValue === null &&
        aggregate[scoreName] === null
      ) {
        totals[`${bradenBucket}Total`] += aggregate[scoreCountName];
      }
    }
  });
  return totals;
}

export function calculateNortonCharts(data: any) {
  return _calculateCharts(data, nortonScores, "norton_plus");
}

export function calculateBradenCharts(data: any) {
  return _calculateCharts(data, bradenScores, "braden");
}

function _calculateCharts(data, scores, name) {
  const dataResults = Object.keys(scores).reduce(
    (finalResult, riskName) => {
      finalResult[`${riskName}Data`] = [];
      return finalResult;
    },
    {
      timeIntervals: []
    }
  );
  if (!data) {
    return dataResults;
  }
  const dataGroupedByTimeInterval = groupBy(data, "time_interval_start");

  for (let timeInterval in dataGroupedByTimeInterval) {
    const dataPoints = dataGroupedByTimeInterval[timeInterval];

    const formattedTime = moment(timeInterval).format("MMM D");

    dataResults.timeIntervals.push(formattedTime);

    const totals = _calculateAggregates(dataPoints, scores, name);

    Object.keys(totals).forEach(riskNameTotal => {
      const riskName = riskNameTotal.replace("Total", "");
      dataResults[`${riskName}Data`].push(totals[riskNameTotal]);
    });
  }
  return dataResults;
}
