export const woundTypesV7 = {
  //Wound Types
  abrasionOnly: "Abrasion",
  abscess: "Abscess",
  arterial: "Arterial",
  blister: "Blister",
  bruise: "Bruise",
  burn: "Burn",
  cancerLesion: "Cancer Lesion",
  diabetic: "Diabetic Foot",
  hematoma: "Hematoma",
  hSuppurtiva: "Hidradenitis Suppurativa",
  inflammatory: "Inflammatory",
  laceration: "Laceration",
  malignant: "Malignant",
  masd: "MASD",
  mixedVenousArterial: "Mixed-Venous/Arterial",
  mole: "Mole",
  lesion: "Open Lesion",
  pilonidalSinus: "Pilonidal Sinus",
  pressureUlcer: "Pressure",
  pressureKennedy: "Pressure - Kennedy Terminal Ulcer",
  pressureDeviceRelated: "Pressure - Medical Device Related Pressure Injury",
  rash: "Rash",
  skinTear: "Skin Tear",
  surgical: "Surgical",
  trauma: "Trauma",
  venous: "Venous",
  undiagnosed: "Undiagnosed",

  //Sub Types
  ////Skin Tear
  type1None: "No Skin Loss",
  type2Partial: "Partial Flap Loss",
  type3Total: "Total Flap Loss",

  ////Burn
  first: "First Degree",
  second: "Second Degree",
  third: "Third Degree",

  ////Bruise
  bruiseType: "Colour",

  ////// (Bruise) Color
  red: "Red",
  bluePurple: "Blue/Purple",
  green: "Green",
  yellowBrown: "Yellow/Brown",

  ////Pressure Ulcer, Pressure Kennedy, Pressure Device Related
  one: "Stage 1",
  two: "Stage 2",
  three: "Stage 3",
  four: "Stage 4",
  dti: "Deep Tissue Injury",
  unstageable: "Unstageable",

  ////Pressure Ulcer, Pressure Device Related
  mucosalMembrane: "Mucosal Membrane",

  //////(Pressure Ulcer, Pressure Kennedy, Pressure Device Related) Unstageable
  necrosis: "Slough and/or eschar",
  dressing: "Non-removable device/dressing",

  ////MASD
  iad: "IAD",
  other: "Other Moisture Associated Damage",

  ////Surgical
  surgicalClosure: "Surgical Closure Method",
  surgicalOther: "Other",

  //////Surgical Closure Method
  closureSutures: "Sutures",
  closureStaples: "Staples",
  closureTape: "Tape",
  closureSteriStrips: "Steri-Strips",
  closureTissueAdhesives: "Tissue Adhesives",
  closureOther: "Other",

  //////(Surgical) Other
  incisionApprox: "Incision Approximated",
  dehiscence: "Dehiscence",
  healingRidge: "Healing Ridge",

  ////Applies to all notSet keys
  notSet: "Not Set"
};
