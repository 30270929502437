import { createSelector } from "reselect";

// Selectors
import {
  selectedWoundOptionsSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector,
  selectedDateSelector,
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  selectedPayersIdsFilterSelector
} from "src/selectors/section_selectors/wounds";

import { createWeeklyDatesArray } from "src/utils/create_dates_by_week_array";
import { createGetSingleCharDataPayload } from "src/api/wounds_chart/payload_generators";

export function createAllWeeksWoundsClinicalChartDataPayloadSelector() {
  return createSelector(
    selectedWoundOptionsSelector,
    selectedLocationIdsSelector,
    acquiredFilterSelector,
    selectedDateSelector,
    hprScoreMinRangeFilterSelector,
    hprScoreMaxRangeFilterSelector,
    bwatScoreMinRangeFilterSelector,
    bwatScoreMaxRangeFilterSelector,
    selectedPayersIdsFilterSelector,
    (
      selectedWoundOptions,
      selectedLocationIds,
      acquiredFilter,
      selectedEndDate,
      hprScoreMinRangeFilter,
      hprScoreMaxRangeFilter,
      bwatScoreMinRangeFilter,
      bwatScoreMaxRangeFilter,
      selectedPayersIds
    ) => {
      const datesArray = createWeeklyDatesArray(16, selectedEndDate);
      const payloads = datesArray.map(date => {
        return createGetSingleCharDataPayload({
          endTime: date,
          locations: selectedLocationIds,
          payers: selectedPayersIds,
          inHouseAcquired: acquiredFilter,
          wounds: selectedWoundOptions,
          hprScoreMinRangeFilter,
          hprScoreMaxRangeFilter,
          bwatScoreMinRangeFilter,
          bwatScoreMaxRangeFilter
        });
      });

      return payloads;
    }
  );
}
