import { connect } from "react-redux";
import { AdminRolesTableComponent } from "src/components/tables";

import flowRight from "lodash.flowright";

//Selectors
import {
  rolesResourceSelectors,
  permissionTypesResourceSelectors,
  customRolesResourceSelectors
} from "src/selectors/data_selectors";
import { createAdminPermissionTypesContextSelector } from "src/selectors/api_selectors/admin/permission_types";
import { userCreateEnabledSelector } from "src/selectors/ui_config_selectors/admin";

//Actions
import { loadPermissionTypesAction } from "src/actions/data_actions";

//HOC
import { withResourceLoaderFactory } from "src/hoc/factories";

function mapStateToProps(state) {
  return {
    roles: rolesResourceSelectors.dataSelector(state),
    customRoles: customRolesResourceSelectors.dataSelector(state),
    permissionTypes: permissionTypesResourceSelectors.dataSelector(state),
    roleEditEnabled: userCreateEnabledSelector(state)
  };
}
export const AdminRolesTableContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadPermissionTypesAction,
    resourceSelectors: permissionTypesResourceSelectors,
    createContextSelector: createAdminPermissionTypesContextSelector
  }),
  connect(mapStateToProps)
)(AdminRolesTableComponent);
