import get from "lodash.get";
/**
 * Format date string for table cells
 * @param {string} cell get acquired status
 */
export function acquiredStatusFormatter(cell, row) {
  let acquiredStatus = get(row, "attributes.answersJson.inHouseAcquired", "");
  if (acquiredStatus === "inHouse") return "IHA";
  else if (acquiredStatus === "externally") return "POA";
  else return "Unknown";
}
