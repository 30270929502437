import React from "react";
import PropTypes from "prop-types";
import { NavLink, Route, Switch } from "react-router-dom";

//Containers
import { PopulationLocationContainer } from "src/containers/population_tallies_by_location_container";
import { PopulationEtiologyContainer } from "src/containers/population_tallies_by_etiology_container";

// Legacy Containers
import { LegacyPopulationEtiologyContainer } from "src/containers/legacy_population_tallies_by_etiology_container";

// Components
import { DatePickerRange, Button, Icon } from "src/ui-kit";

export class PopulationSectionComponent extends React.Component {
  static propTypes = {
    selectedWoundTypes: PropTypes.object.isRequired,
    dateSelection: PropTypes.object.isRequired,
    setDateSelection: PropTypes.func.isRequired
  };

  handleDatePickerChange = dateData => {
    // Using a bulk action to update the entire date state
    // TODO: These should be split up to individual actions,
    // the date range selector shouldn't be responsible for setting the dates
    // when the range filter changes (ex. month to date). Should be handled by
    // the reducer.
    this.props.setDateSelection(dateData);
  };

  /**
   * Event handler for the csv download
   */
  onDownloadCsvButtonClicked = () => {
    const {
      populationTallyExportLoadState,
      selectedLocationIds,
      dateSelection,
      selectedWoundTypes,
      acquiredFilter,
      routePathname
    } = this.props;
    let acquired;
    if (acquiredFilter)
      acquired = acquiredFilter == "externally" ? "POA" : "IHA";

    const tallyType =
      routePathname == "/population/location" ? "location" : "etiology";

    // TODO: Make this dependent on load state of locations instead of checking length
    if (
      populationTallyExportLoadState == "ready" &&
      selectedLocationIds.length
    ) {
      const payload = {
        startDate: dateSelection.startDate,
        endDate: dateSelection.endDate,
        woundTypes: selectedWoundTypes.woundTypes,
        locationIds: selectedLocationIds,
        acquired: acquired,
        tallyType: tallyType,
        format: "csv"
      };

      this.props.sendCreateAndDownloadCsvAction(payload);
    }
  };

  /**
   * TODO: Move this to a central component that renders bar according to Route Switch
   * Renders the control bar on the top of the page
   */
  renderControlNavbar() {
    return (
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">Incidence and Prevalence</h1>
        </div>

        <form className="navbar-form navbar-right">
          <div className="btn-group control-navbar-element" role="group">
            <NavLink
              to="/population"
              exact={true}
              className="btn btn-default"
              activeClassName="active"
            >
              Etiology
            </NavLink>
            <NavLink
              to="/population/location"
              className="btn btn-default"
              activeClassName="active"
            >
              Location
            </NavLink>
          </div>
          <span className="control-navbar-element">
            <DatePickerRange
              allowSameDayRange={true}
              startDate={this.props.dateSelection.startDate}
              endDate={this.props.dateSelection.endDate}
              unitRange={this.props.dateSelection.unitRange}
              update={this.handleDatePickerChange}
            />
          </span>
          <Button
            name="primary"
            className="control-navbar-element"
            type="button"
            onClick={this.onDownloadCsvButtonClicked}
          >
            <Icon name="download" />
          </Button>
        </form>
      </nav>
    );
  }

  renderPopulationContent() {
    return (
      <Switch>
        <Route
          exact
          path="/population"
          component={PopulationEtiologyContainer}
        />
        <Route
          path="/population/location"
          component={PopulationLocationContainer}
        />
      </Switch>
    );
  }

  //Legacy Function for Old Control Navbar
  renderLegacyControlNavbar() {
    return (
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">Incidence and Prevalence</h1>
        </div>

        <form className="navbar-form navbar-right">
          <span className="control-navbar-element">
            <DatePickerRange
              allowSameDayRange={true}
              startDate={this.props.dateSelection.startDate}
              endDate={this.props.dateSelection.endDate}
              unitRange={this.props.dateSelection.unitRange}
              update={this.handleDatePickerChange}
            />
          </span>
        </form>
      </nav>
    );
  }

  // Legacy Function for old pop tab
  renderLegacyPopulationContent() {
    return <LegacyPopulationEtiologyContainer />;
  }

  render() {
    const { populationSectionVersion } = this.props;

    switch (populationSectionVersion) {
      case "1":
        return (
          <div>
            {this.renderLegacyControlNavbar()}
            {this.renderLegacyPopulationContent()}
          </div>
        );
      default:
        return (
          <div>
            {this.renderControlNavbar()}
            {this.renderPopulationContent()}
          </div>
        );
    }
  }
}
