import { createSelector } from "reselect";

/**
 * create a selector to get the api query for all series trends for a revision
 * @param {Object} props
 * @param {Object} props.revision
 * @returns {function} selector
 */
export function createAllSeriesTrendsForRevisionQueryFilterSelector({
  revision
}) {
  return createSelector(
    () => revision.attributes.seriesId,
    id => `${id}/trends`
  );
}
