import { combineReducers } from "redux";
import { dateSelectionReducer } from "./date_selection_reducer";
import { acquiredFilterReducer } from "./acquired_filter_reducer";

//Section Reducer Factory
import {
  createSectionFiltersReducer,
  createSectionSelectedFilterIdReducer
} from "src/ui_processors/global/reducer_creators";

// Constants
import { sections } from "src/constants";

export const populationSectionReducers = combineReducers({
  dateSelection: dateSelectionReducer,
  acquiredFilter: acquiredFilterReducer,
  filters: createSectionFiltersReducer(sections.population),
  selectedFilterId: createSectionSelectedFilterIdReducer(sections.population)
});
