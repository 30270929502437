// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash.get";

import { createPatientForIdSelector } from "src/selectors/data_selectors";

// Components
import { QrCodeComponent } from "src/components/ui_kit";
import { CollapsibleContainerComponent } from "src/components/collapsible_container_component";

// Containers
import { SeriesListContainer } from "src/containers/series_list_container";
import { NonWoundAssessmentListContainer } from "src/containers/non_wound_assessment_list_container";
import { PatientOverviewContainer } from "src/containers/patient_overview";
import { AssessmentAnswerHistoryListContainer } from "src/containers/assessment_answer_history_list_container";
import { PatientAlertsContainer } from "src/containers/patient_alerts_container";

// Model
import { swiftQrCodeStringFromPatient } from "src/models/patients";

// Legacy Containers
import PatientAnalytics from "src/containers/patients/AnalyticsContainer";
import { Container, Row, Column, Button } from "@sw/ui-kit";
import Patient from "@sw/models/PatientModel";

// import { amedysisDemo } from "src/constants";
//Translations
import i18n from "src/translations";
import {
  sendNewAuditEvent,
  AUDIT_EVENT_TYPES,
  AUDIT_RESOURCE_TYPES
} from "src/helpers/audit";

const PatientMdsExportModalView = () => null;

type $ownProps = {
  id: string,
  isReporting: boolean,
  turnOffReporting: Function
};
type $stateProps = {
  hideSwiftCode?: boolean,
  mdsExport: Object,
  careTeam: Object,
  patient: Patient
};
type $dispatchProps = {
  closeModal: Function,
  openModal: Function
};
type $props = $ownProps & $stateProps & $dispatchProps;

export class PatientBody extends PureComponent {
  constructor(props) {
    super(props);

    sendNewAuditEvent(
      AUDIT_EVENT_TYPES.VIEW,
      props.userId,
      props.patient.id,
      AUDIT_RESOURCE_TYPES.PATIENT
    );
  }

  props: $props;
  render() {
    const {
      hideSwiftCode,
      patient,
      isReporting,
      mdsExport,
      turnOffReporting,
      id,
      showPatientOverview,
      showPatientAlerts
    } = this.props;
    let swiftCode = "";
    const shareToken = get({ patient }, "patient.attributes.shareToken", "");

    if (!hideSwiftCode) {
      swiftCode = (
        <Container>
          <Row className="patient__info">
            <Column size={4} className="text-center patient__info__text">
              Scan to Load
            </Column>
            <Column size={4}>
              <QrCodeComponent code={swiftQrCodeStringFromPatient(patient)} />
            </Column>
            <Column size={4} className="text-center patient__info__text">
              {shareToken}
            </Column>
          </Row>
        </Container>
      );
    }

    let reportStatus = "";
    let alertStyle = "alert alert-info hide";
    if (isReporting) {
      if (mdsExport.success) {
        alertStyle = "alert alert-success";
      } else if (mdsExport.errors) {
        alertStyle = "alert alert-danger";
      }

      if (mdsExport.close) {
        turnOffReporting(false);
      }

      reportStatus = <PatientMdsExportModalView />;
    }

    return (
      <Row>
        <div className={alertStyle}>{reportStatus}</div>
        <Column size={7} id="sm_col_left">
          {showPatientAlerts && (
            <div>
              <Row>
                <Column>
                  <PatientAlertsContainer patientId={patient.id} />
                </Column>
              </Row>
              <hr />
            </div>
          )}
          {/* The code block below has been commented out but not deleted, 
          in case we need to show alerts for a medicines demo */}
          {/* {amedysisDemo.patientAlertsEnabled && (
            <div>
              <Row>
                <Column>
                  <CollapsibleContainerComponent
                    label={`Alerts ( ${amedysisDemoPatientAlertCount} )`}
                    expanded={true}
                    labelSize="lg"
                  >
                    <PatientAlertsListContainer
                      alertCount={amedysisDemoPatientAlertCount}
                    />
                  </CollapsibleContainerComponent>
                </Column>
              </Row>
              <hr />
            </div>
          )} */}
          {showPatientOverview && (
            <div>
              <Row>
                <Column>
                  <CollapsibleContainerComponent
                    label={"Overview"}
                    labelSize="lg"
                    expanded={true}
                  >
                    <PatientOverviewContainer />
                  </CollapsibleContainerComponent>
                </Column>
              </Row>
              <hr />
            </div>
          )}
          <Row>
            <Column>
              <CollapsibleContainerComponent
                label={`${i18n.t("text.evaluation")}s`}
                labelSize="lg"
                expanded={true}
              >
                <NonWoundAssessmentListContainer />
              </CollapsibleContainerComponent>
            </Column>
          </Row>
          <hr />
          <Row>
            <Column>
              <CollapsibleContainerComponent
                label="Wounds"
                expanded={true}
                labelSize="lg"
              >
                <SeriesListContainer />
              </CollapsibleContainerComponent>
            </Column>
          </Row>
        </Column>
        <Column size={5} id="sm_col_right" className="secondary-background">
          {swiftCode}
          <Row>
            <Column>
              <h2>
                <span className="pull-right hidden">
                  <Button name="primary" circle children="+" />
                </span>
              </h2>
              <PatientAnalytics id={id} />
            </Column>
          </Row>
          <Row>
            <Column size={12}>
              <h2>
                History
                <span className="pull-right hidden">
                  <Button name="primary" circle children="+" />
                </span>
              </h2>
              <br />
              <AssessmentAnswerHistoryListContainer />
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

function mapStateToProps(state, props) {
  const userIds = Object.keys(get(state, "data.users.data", {}));
  return {
    patient: createPatientForIdSelector(props.match.params.patientId)(state),
    userId: userIds[0]
  };
}

export default withRouter(connect(mapStateToProps)(PatientBody));
