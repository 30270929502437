// @flow
import { combineReducers } from "redux";
import { patients, patientRelationships } from "./patientReducers";
import { properties, propertyRelationships } from "./propertyReducers";

import { locations, locationRelationships } from "./locationReducers";
import { trends } from "./trendReducers";
import { studies, studyRelationships } from "./studyReducers";
import {
  assessmentAnswers,
  assessmentAnswerRelationships
} from "./assessmentAnswerReducers";
import { series, seriesRelationships } from "./seriesReducers";
import { users, userRelationships } from "./userReducers";
import { seriesTrends, seriesTrendRelationships } from "./seriesTrendReducers";
import {
  patientTrends,
  patientTrendRelationships
} from "./patientTrendReducers";
import {
  locationRisks,
  locationRiskRelationships
} from "./locationRiskReducers";

// Data Reducers - state tree for data loaded from the api
import { dataReducers } from "./data_reducers";

// Resource reudcer - replacing data reducers
// This will be used as we transition to the new redux_resources module
import { resourcesReducers } from "./resources_reducers";

// Section Reducers - state tree for user selections and filters
import { sectionReducers } from "./section_reducers";

// Config reducers - state tree for application level configuration.
// (ex. partnerName, patientLinkTemplate)
import { configReducer } from "./config_reducers";

// Session - state tree for session info (ex. access token)
import { sessionReducer } from "./session_reducers";

// Frames - state tree for frame related configuration
import { framesReducer } from "./frames_reducers";

// react-router-redux
import { routerReducer } from "react-router-redux";
import { reducer as reducerForm } from "redux-form";

const entities = combineReducers({
  patients,
  properties,
  locations,
  trends,
  studies,
  assessmentAnswers,
  users,
  series,
  seriesTrends,
  patientTrends,
  locationRisks
});

const relationships = combineReducers({
  patients: patientRelationships,
  properties: propertyRelationships,
  locations: locationRelationships,
  studies: studyRelationships,
  assessmentAnswers: assessmentAnswerRelationships,
  users: userRelationships,
  series: seriesRelationships,
  seriesTrends: seriesTrendRelationships,
  patientTrends: patientTrendRelationships,
  risks: locationRiskRelationships
});

export default {
  entities,
  relationships,
  data: dataReducers,
  resources: resourcesReducers,
  sections: sectionReducers,
  config: configReducer,
  session: sessionReducer,
  routing: routerReducer,
  frames: framesReducer,
  form: reducerForm
};
