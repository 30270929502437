import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Helpers
import { propsAreDifferent } from "src/utils/props_are_different";

// Selectors
import {
  assessmentAnswersResourceSelectors,
  createStudyIdForPatientIdSelector
} from "src/selectors/data_selectors";
import { currentUserSelector } from "src/selectors/config_selectors";

// Actions
import { loadAssessmentAnswersForStudyAction } from "src/actions/data_actions";

// Subscriptions
// import Subscriptions from "src/subscriptions";

/**
 * HOC that is responsible for loading a list of patients
 * @param {Class} WrappedComponent the component dependent on this data
 * @return {Class} Component wrapped by the loader component
 */
export function withAssessmentAnswersForStudyLoader(WrappedComponent) {
  class AssessmentAnswersLoader extends React.Component {
    static propTypes = {
      loadState: PropTypes.string,
      studyId: PropTypes.string,
      currentUser: PropTypes.object,
      sendLoadAssessmentAnswersForStudyAction: PropTypes.func.isRequired,
      sendLoadMoreAssessmentAnswersForStudyAction: PropTypes.func.isRequired
    };

    componentDidMount() {
      if (
        !this.props.loadState &&
        this.props.studyId &&
        this.props.currentUser
      ) {
        this.props.sendLoadAssessmentAnswersForStudyAction(this.props.studyId);
      }

      // if (this.props.studyId) {
      //   this.subscribeToStudyChanges(this.props.studyId);
      // }
    }

    componentWillReceiveProps(nextProps) {
      if (
        !nextProps.loadState &&
        nextProps.currentUser &&
        propsAreDifferent(this.props, nextProps, "studyId", "currentUser")
      ) {
        this.props.sendLoadAssessmentAnswersForStudyAction(nextProps.studyId);
      }

      // if (nextProps.studyId) {
      //   this.subscribeToStudyChanges(this.props.studyId);
      // }
    }

    // /**
    //  * Subscribe for changes to study assessment answers
    //  * @param {string} studyId
    //  */
    // subscribeToStudyChanges(studyId) {
    //   Subscriptions.subscribeToResourceChannelEvent({
    //     channelName: Subscriptions.resourceIdChannelNames.studyAssessmentAnswer,
    //     resourceId: studyId,
    //     eventName: Subscriptions.channelEvents.change,
    //     callback: this.onResourceChange,
    //     callbackContext: {
    //       resourceId: studyId,
    //       loadResource: this.props.loadAssessmentAnswersForStudyAction
    //     }
    //   });
    // }

    // /**
    //  * Callback for reloading after pusher update
    //  */
    // onResourceChange() {
    //   this.loadResource(resourceId);
    // }

    /**
     * Function to load the next page
     */
    loadMoreAssessmentAnswers = () => {
      this.props.sendLoadMoreAssessmentAnswersForStudyAction(
        this.props.studyId
      );
    };

    render() {
      return (
        <WrappedComponent
          loadMoreAssessmentAnswers={this.loadMoreAssessmentAnswers}
          {...this.props}
        />
      );
    }
  }

  function mapStateToProps(state, props) {
    const patientId = props.match.params.patientId;
    const studyIdSelector = createStudyIdForPatientIdSelector(patientId);
    return {
      loadState: assessmentAnswersResourceSelectors.createContextLoadStateSelectorWithContextStringSelector(
        studyIdSelector
      )(state),
      studyId: studyIdSelector(state),
      currentUser: currentUserSelector(state)
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      sendLoadAssessmentAnswersForStudyAction(studyId) {
        dispatch(loadAssessmentAnswersForStudyAction(studyId));
      },
      sendLoadMoreAssessmentAnswersForStudyAction(studyId) {
        dispatch(loadAssessmentAnswersForStudyAction(studyId, true));
      }
    };
  }

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AssessmentAnswersLoader)
  );
}
