import { connect } from "react-redux";

import { fileExportsDataSelector } from "src/selectors/data_selectors";

import { DownloadManagerBodyComponent } from "src/components/download_manager/download_manager_body_component";

function mapStateToProps(state) {
  return {
    fileExports: fileExportsDataSelector(state)
  };
}

export const DownloadManagerBodyContainer = connect(mapStateToProps)(
  DownloadManagerBodyComponent
);
