import { tallyTypes } from "src/constants";

/**
 * Get the display string for a tally
 * @param {Object} tally
 * @returns {string}
 */
export function getWoundTypeStringForTally(
  tally,
  schemaLocalizationDictionary
) {
  if (tally.attributes.type == tallyTypes.total) return "Total";

  const { woundType } = tally.attributes.context;

  if (schemaLocalizationDictionary && woundType) {
    return woundType == "undiagnosed"
      ? "Undiagnosed"
      : schemaLocalizationDictionary[`woundType.${woundType}`];
  }

  return "";
}
