import { connect } from "react-redux";
import flowRight from "lodash.flowright";

//Constants
import { apiPageLimits } from "src/constants";

//Component
import { PatientAlertsComponent } from "src/components/patient_alerts_component";

//HOC
import {
  withResourceLoaderFactory
  //   withLoadingComponentFactory
} from "src/hoc/factories";

//Query Selectors
import {
  createFirstPageAlertsForPatientIdQuerySelector,
  createCurrentPageAlertsForPatientIdQuerySelector,
  createAllPagesAlertsForPatientIdQuerySelector
} from "src/selectors/api_selectors/patients/user_alerts";
//Data Selectors
import { createAlertsIncludingPatientAndUserDetailsSelector } from "src/selectors/data_selectors/user_alerts";
import { userAlertsResourceSelectors } from "src/selectors/data_selectors";

//Actions
import {
  userAlertsResourceActions,
  loadUserAlertsAction,
  resolveUserAlertAction
} from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  const firstPageContextSelector = createFirstPageAlertsForPatientIdQuerySelector(
    ownProps
  );
  const currentPageContextSelector = createCurrentPageAlertsForPatientIdQuerySelector(
    ownProps
  );
  const allPagesContextSelector = createAllPagesAlertsForPatientIdQuerySelector(
    ownProps
  );

  const alertsForPatient = userAlertsResourceSelectors.createDataForContextSelector(
    allPagesContextSelector
  )(state);

  return {
    patientAlerts: createAlertsIncludingPatientAndUserDetailsSelector(
      alertsForPatient
    )(state),
    loadState: userAlertsResourceSelectors.createLoadStateForContextSelector(
      currentPageContextSelector
    )(state),
    currentPage: userAlertsResourceSelectors.createCurrentPageForContextSelector(
      firstPageContextSelector
    )(state),
    recordCount: userAlertsResourceSelectors.createRecordCountSelector(
      firstPageContextSelector
    )(state),
    pageCount:
      userAlertsResourceSelectors.createPageCountSelector(
        firstPageContextSelector,
        apiPageLimits.patientAlerts
      )(state) || 0,
    firstPageContext: firstPageContextSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendIncrementPageAction: context => {
      dispatch(
        userAlertsResourceActions.incrementPageForContextAction(context)
      );
    },
    sendResolveAlertAction: payload => {
      dispatch(resolveUserAlertAction({ payload }));
    }
  };
}

export const PatientAlertsContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadUserAlertsAction,
    resourceSelectors: userAlertsResourceSelectors,
    createContextSelector: createCurrentPageAlertsForPatientIdQuerySelector,
    createQuerySelector: createCurrentPageAlertsForPatientIdQuerySelector
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PatientAlertsComponent);
