export default [
  {
    name: "assessmentAnswers",
    getVariableName(ent: Object) {
      switch (ent.assessmentType) {
        case "wound":
          return "woundAssessmentAnswers";
        case "co_morbidities":
        case "medications":
        case "devices":
        case "education":
          return "patientAssessments";
        default:
          return "otherAssessmentAnswers";
      }
    }
  },
  {
    name: "assessmentAnswers",
    alias: "woundAssessmentAnswers"
  },
  {
    name: "assessmentAnswers",
    alias: "otherAssessmentAnswers"
  },
  {
    name: "assessmentAnswers",
    alias: "patientAssessments"
  }
];
