import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

// Containers
import { AdminUsersContainer } from "src/containers/admin_users_container";
import { AdminPatientsContainer } from "src/containers/admin_patients_container";
import { AdminAlertRulesContainer } from "src/containers/admin_alert_rules_container";

// Components
import Select from "src/ui-kit/Select";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

export class AdminSectionComponent extends React.PureComponent {
  static propTypes = {
    partners: PropTypes.array.isRequired,
    partnerDropDownEnabled: PropTypes.bool.isRequired,
    sendSelectPartnerAction: PropTypes.func.isRequired
  };

  componentWillReceiveProps(nextProps) {
    if (propsAreDifferent(nextProps, this.props, "partners")) {
      if (nextProps.partners.length) {
        this.props.sendSelectPartnerAction(nextProps.partners[0].id);
      }
    }
  }

  onChangePartners = value => {
    this.props.sendSelectPartnerAction(value);
  };

  renderControlNavbar() {
    const { partners, partnerDropDownEnabled } = this.props;
    const partnersArray = partners.map(val => {
      return {
        label: val.attributes.name,
        value: val.id
      };
    });

    return (
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">Admin</h1>
        </div>
        {partnerDropDownEnabled && (
          <div className="navbar-form navbar-right">
            <div className="btn-group control-navbar-element" role="group">
              Partners
            </div>
            <span className="control-navbar-element">
              <Select
                onChange={this.onChangePartners}
                options={partnersArray}
              />
            </span>
          </div>
        )}
      </nav>
    );
  }

  renderUsersSection = () => {
    const { usersSectionEnabled } = this.props;
    if (usersSectionEnabled) {
      return <AdminUsersContainer />;
    } else {
      return <Redirect to="/admin" />;
    }
  };

  render() {
    return (
      <div>
        {this.renderControlNavbar()}
        <Switch>
          <Route exact path="/admin/users" render={this.renderUsersSection} />
          <Route path="/admin/patients" component={AdminPatientsContainer} />
          <Route path="/admin/alerts" component={AdminAlertRulesContainer} />
        </Switch>
      </div>
    );
  }
}
