import PropTypes from "prop-types";
import React from "react";
import { Collapse } from "react-bootstrap";
import { Glyphicon } from "src/components/ui_kit";

// css
import styles from "./style.module.less";

/**
 * React Component that will surround its children with a collapsible container
 */
export class CollapsibleContainerComponent extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    labelSize: PropTypes.oneOf(["lg", "md", "sm"]),
    expanded: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: props.expanded || false
    };
  }

  /**
   * Responsible for changing the expansion state of the container
   */
  handleExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  /**
   * TO DO: Render header function can potentially become its own component: CollapsibleContainerComponentHeaderComponent
   */
  renderHeader = () => {
    const { label, labelSize } = this.props;
    const labelContent = (
      <div className={`${styles["label-content"]} dropdown-toggle`}>
        {label}
        <Glyphicon
          glyph={this.state.expanded ? "menu-down" : "menu-right"}
          className="arrow-placement"
        />
      </div>
    );
    switch (labelSize) {
      case "lg":
        return <h3 onClick={this.handleExpand}>{labelContent}</h3>;

      case "md":
        return <h4 onClick={this.handleExpand}>{labelContent}</h4>;

      default:
        return <h5 onClick={this.handleExpand}>{labelContent}</h5>;
    }
  };

  render() {
    return (
      <div>
        {this.renderHeader()}
        <Collapse in={this.state.expanded}>
          <div>{this.props.children}</div>
        </Collapse>
      </div>
    );
  }
}
