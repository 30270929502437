import { createSelector } from "reselect";
import i18n from "src/translations";
import { hasReopenWoundRevisionsPermissionSelector } from "src/selectors/ui_config_selectors/patients";

export function createReopenTooltipSelector() {
  return createSelector(
    hasReopenWoundRevisionsPermissionSelector,
    canReopenWoundRevision => {
      const missingPermissionsText = i18n.t(
        "text.revisionButtonTooltips.missingPermissions"
      );
      return !canReopenWoundRevision ? missingPermissionsText : "";
    }
  );
}
