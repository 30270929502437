import React from "react";
import sections from "./sections";
import styles from "./../style.module.less";

function Filters({ disabledSections = [] }) {
  const isSectionEnabled = ({ filterKey }) =>
    !disabledSections.includes(filterKey);

  const enabledSections = sections.filter(isSectionEnabled);

  const renderFilterSection = section => {
    const { component: SectionComponent } = section;

    return (
      <li key={section.filterKey}>
        <SectionComponent {...section} />
      </li>
    );
  };

  return (
    <ul className={styles["filter-component-list"]}>
      {enabledSections.map(renderFilterSection)}
    </ul>
  );
}

export default Filters;
