// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import BreadcrumbItem from "./BreadcrumbItem";
import type { $props as $breadcrumbItem } from "./BreadcrumbItem";

type $props = {
  className?: string,
  items: Array<$breadcrumbItem>
};
export default class Breadcrumbs extends PureComponent {
  props: $props;
  render() {
    const { className, items } = this.props;
    const klass = classnames("breadcrumb", {
      [className || ""]: !!className
    });
    return (
      <ol className={klass}>
        {items.map(item => (
          <BreadcrumbItem key={item.text} {...item} />
        ))}
      </ol>
    );
  }
}
