import { createSelector } from "reselect";

// Api
import { createWoundTalliesGetPayload } from "src/api/wound_tallies/payload_generators";

// Selectors
import { answersFilterSelector } from "src/selectors/section_selectors/wounds/answers_filter_selector";
import { locationsTallyContextsSelector } from "src/selectors/section_selectors/wounds/locations_tally_contexts_selector";
import { dateSelectionSelector } from "src/selectors/section_selectors/wounds/date_selection_selector";
import { acquiredFilterSelector } from "src/selectors/section_selectors/wounds/acquired_filter_selector";
import { selectedOrganizationSelector } from "src/selectors/data_selectors";
import { orderedSelectedLocationsSelector } from "src/selectors/section_selectors/wounds/ordered_selected_locations_selector";
import {
  hprScoreMinRangeFilterSelector,
  hprScoreMaxRangeFilterSelector,
  bwatScoreMinRangeFilterSelector,
  bwatScoreMaxRangeFilterSelector,
  selectedPayersIdsFilterSelector
} from "src/selectors/section_selectors/wounds";

//Constants
import { acquiredPayloadFilters } from "src/constants/acquired_values";

/**
 * create selector to get payloads for loading tallies for a set of selected filters
 * @returns {Function} selector
 */
export function createWoundTalliesPayloadSelector() {
  return createSelector(
    answersFilterSelector,
    locationsTallyContextsSelector,
    orderedSelectedLocationsSelector,
    dateSelectionSelector,
    acquiredFilterSelector,
    selectedOrganizationSelector,
    hprScoreMinRangeFilterSelector,
    hprScoreMaxRangeFilterSelector,
    bwatScoreMinRangeFilterSelector,
    bwatScoreMaxRangeFilterSelector,
    selectedPayersIdsFilterSelector,
    (
      answers,
      tallyContexts,
      locations,
      dateSelection,
      acquired,
      organization,
      hprScoreMinRangeFilter,
      hprScoreMaxRangeFilter,
      bwatScoreMinRangeFilter,
      bwatScoreMaxRangeFilter,
      selectedPayersIds
    ) => {
      if (!locations.length || !organization) {
        return undefined;
      }

      return createWoundTalliesGetPayload({
        answers,
        tallyContexts,
        locationIds: locations.map(loc => loc.id),
        selectedPayersIds,
        dateSelection,
        acquired: acquiredPayloadFilters[acquired],
        organizationId: organization.id,
        ...((hprScoreMinRangeFilter || hprScoreMinRangeFilter === 0) &&
          hprScoreMaxRangeFilter && {
            hprRangeScore: `${hprScoreMinRangeFilter},${hprScoreMaxRangeFilter}`
          }),
        ...((bwatScoreMinRangeFilter || bwatScoreMinRangeFilter === 0) &&
          bwatScoreMaxRangeFilter && {
            bwatRangeScore: `${bwatScoreMinRangeFilter},${bwatScoreMaxRangeFilter}`
          })
      });
    }
  );
}
