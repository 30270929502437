import React from "react";

/**
 * Render a plus icon
 */
export class PlusIconComponent extends React.PureComponent {
  render() {
    return (
      <svg
        width="86"
        height="86"
        viewBox="0 0 86 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="43"
          cy="43"
          r="42.625"
          fill="#E5E5E5"
          stroke="#C4C4C4"
          strokeWidth="0.75"
        />
        <line
          x1="42.5"
          y1="19"
          x2="42.5"
          y2="66"
          stroke="#8E8E93"
          strokeWidth="5"
        />
        <line
          x1="19"
          y1="43.5"
          x2="66"
          y2="43.5"
          stroke="#8E8E93"
          strokeWidth="5"
        />
      </svg>
    );
  }
}
