import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import { reduxForm, getFormValues } from "redux-form";
import { reduxFormNames } from "src/constants";

import { currentUserSelector } from "src/selectors/config_selectors";
import {
  signAssessmentAnswerAction,
  signAndLockAssessmentAnswersAction
} from "src/actions/data_actions";

import { SignAssessmentAnswerFormComponent } from "src/components/forms/sign_assessment_answer_form_component";

function mapStateToProps(state) {
  return {
    currentUser: currentUserSelector(state),
    formValues: getFormValues(reduxFormNames.signAssessmentAnswerForm)(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSignAssessmentAnswerAction(payload) {
      return dispatch(signAssessmentAnswerAction({ payload }));
    },
    signAndLockAssessmentAnswersAction(payload) {
      return dispatch(signAndLockAssessmentAnswersAction({ payload }));
    }
  };
}

export const SignAssessmentAnswerFormContainer = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: reduxFormNames.signAssessmentAnswerForm
  })
)(SignAssessmentAnswerFormComponent);
