import { createSelector } from "reselect";
import { reduce } from "lodash";

// Selectors
import { createNestedAssessmentSchemasForAssessmentAnswerSelector } from "../create_nested_assessment_schemas_for_assessment_answer_selector";

export function createQuestionWithVisiblePredicateFromSchemaSelector(
  assessmentAnswer
) {
  return createSelector(
    createNestedAssessmentSchemasForAssessmentAnswerSelector(assessmentAnswer),
    schemas => {
      let questionPredicateObject = {};
      schemas.forEach(schema => {
        let result = questionWithVisiblePredicates(
          schema.attributes.schemaJson.questions
        );
        questionPredicateObject = { ...questionPredicateObject, ...result };
      });
      return questionPredicateObject;
    }
  );
}

function questionWithVisiblePredicates(questions) {
  return reduce(
    questions,
    function(hash, question) {
      const questionViewable =
        question.viewParameters &&
        question.viewParameters.control &&
        question.viewParameters.control != 0;

      if (question.visiblePredicateJson && questionViewable) {
        hash[question.name] = question.visiblePredicateJson;
      }
      return hash;
    },
    {}
  );
}
