import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// component
import { SideBarLocationFilterComponent } from "src/components/side_bar_location_filter_component";

// selectors
import { locationsHierarchySelector } from "src/selectors/data_selectors/locations";
import { checkedLocationIdsSelector } from "src/selectors/section_selectors/patients";
import { selectedLocationIdsSelector } from "src/selectors/section_selectors/patients";

// actions
import {
  selectLocations,
  deselectLocations
} from "src/actions/section_actions/patients";

function mapStateToProps(state) {
  return {
    locations: locationsHierarchySelector(state),
    checkedLocationIds: checkedLocationIdsSelector(state),
    selectedLocationIds: selectedLocationIdsSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectLocations: id => {
      dispatch(selectLocations(id));
    },
    deselectLocations: id => {
      dispatch(deselectLocations(id));
    }
  };
}

export const SideBarLocationFilterContainerPatients = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SideBarLocationFilterComponent);
