// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { permissionTypesApi } from "src/api/permissions_types";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

export const permissionTypesResourceActions = createResourceActions(
  resourceNames.permissionTypes
);

export const loadPermissionTypesAction = createLoadDataAction({
  resourceName: resourceNames.permissionTypes,
  api: permissionTypesApi
});
