import { combineReducers } from "redux";
import { selectedProgressFilterReducer } from "./selected_progress_filter_reducer";
import { selectedDateReducer } from "./selected_date_reducer";

//Section Reducer Factory
import {
  createSectionFiltersReducer,
  createSectionSelectedFilterIdReducer
} from "src/ui_processors/global/reducer_creators";

//Section UI Processor Reducers
import SingleValue from "src/ui_processors/single_value";

// Constants
import { sections } from "src/constants";

export const woundsSectionReducers = combineReducers({
  selectedProgressFilter: selectedProgressFilterReducer,
  selectedDate: selectedDateReducer,
  filters: createSectionFiltersReducer(sections.wounds),
  selectedFilterId: createSectionSelectedFilterIdReducer(sections.wounds),
  selectedTab: SingleValue.createReducer("selectedTab", "wounds")
});
