import { createSelector } from "reselect";
import { locationsToDisplaySelector } from "../locations_to_display_selector";

/**
 * Selector for location ids of locations to display data for to user
 */
export const locationIdsToDisplaySelector = createSelector(
  locationsToDisplaySelector,
  locations => {
    return locations.map(loc => loc.id);
  }
);
