import { combineReducers } from "redux";

//Section Reducer Factory
import {
  createSectionFiltersReducer,
  createSectionSelectedFilterIdReducer
} from "src/ui_processors/global/reducer_creators";

// Constants
import { sections } from "src/constants";

export const outcomesSectionReducers = combineReducers({
  filters: createSectionFiltersReducer(sections.outcomes),
  selectedFilterId: createSectionSelectedFilterIdReducer(sections.outcomes)
});
