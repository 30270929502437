import PropTypes from "prop-types";
import get from "lodash.get";

import React, { PureComponent } from "react";

// Constants
import { tallyTypes } from "src/constants";

// Containers
import { SurveyTallyTableRowContainer } from "src/containers/tables";
import { SurveyTallyTableHeaderContainer } from "src/containers/tables";

// Components
import { NoResultsTableRowComponent } from "../no_results_table_row_component";

// css
import styles from "../style.module.less";

export class SurveyTallyTableComponent extends PureComponent {
  static propTypes = {
    tallies: PropTypes.array.isRequired,
    tallyType: PropTypes.string.isRequired
  };

  renderHeader() {
    return <SurveyTallyTableHeaderContainer tallyType={this.props.tallyType} />;
  }

  /**
   * Get the body for the tally table (all tally rows)
   */
  renderBody() {
    const { tallies, tallyType } = this.props;

    if (tallies.length == 0) {
      const numberOfColumns = tallyType == tallyTypes.etiology ? 14 : 17;
      return <NoResultsTableRowComponent numberOfColumns={numberOfColumns} />;
    } else {
      return tallies
        .filter(tally => {
          const context = get(tally, "attributes.context", null);
          if (!context) return true;

          const hasUndefinedKeys = Object.keys(context).includes("undefined");
          return !hasUndefinedKeys;
        })
        .map((tally, index) => {
          return (
            <SurveyTallyTableRowContainer
              key={index}
              tally={tally}
              tallyType={tallyType}
            />
          );
        });
    }
  }

  render() {
    return (
      <div>
        <table className={`table ${styles["survey-tally-table"]}`}>
          <thead>{this.renderHeader()}</thead>
          <tbody>{this.renderBody()}</tbody>
        </table>
      </div>
    );
  }
}
