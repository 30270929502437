// @flow
import { Api } from "../../utils/serviceUtils";

const DEFAULT_OPTIONS = {
  includeAll: "false",
  includeLeaves: "true"
};

class OrganizationServices extends Api {
  loadLocations = (
    organizationID: $$id,
    options?: Object
  ): Promise<{ locations: Object[] }> => {
    if (!organizationID) {
      return Promise.reject("You must supply an organization ID.");
    }
    const query = { ...DEFAULT_OPTIONS, ...options };
    return this.get(`/${organizationID}/locations`, { query });
  };
}

export default new OrganizationServices("organizations", "/api/v2");
