import { connect } from "react-redux";

// Constants
import { sections } from "src/constants";
import { overallProgressConfiguration } from "src/constants/filters_configurations/monitoring";

// Component
import { CheckboxFilterComponent } from "src/components/filters";

// UI Processors
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";
import { createSectionActions } from "src/ui_processors/global/action_creators";

const sectionSelectors = createSectionSelectors(sections.monitoring);
const sectionActions = createSectionActions(sections.monitoring);

function mapStateToProps(state) {
  return {
    filterConfig: overallProgressConfiguration,
    filterCheckedState: sectionSelectors.createFilterCheckedStateSelector(
      overallProgressConfiguration
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectCheckbox: filterObject => {
      dispatch(sectionActions.selectCheckbox(filterObject));
    },
    deselectCheckbox: filterObject => {
      dispatch(sectionActions.deselectCheckbox(filterObject));
    }
  };
}

export const SideBarWoundProgressFilterContainerMonitoring = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckboxFilterComponent);
