import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";
import { keyBy } from "lodash";

const allowedFieldValues = [
  "clinicianName",
  "externalIdentifier",
  "startDate",
  "startTime",
  "endDate",
  "endTime",
  "location",
  "discipline"
];

export const encounterFieldsShownInSelectionUISelector = createSelector(
  createPolicyForInternalNameSelector(
    policyInternalNames.encounterFieldsShownInSelectionUI
  ),
  policy => {
    return policy
      ? keyBy(
          policy.attributes.policyValue
            .map(value => value.trim())
            .filter(value => {
              return allowedFieldValues.includes(value);
            })
        )
      : { externalIdentifier: "externalIdentifier", startDate: "startDate" };
  }
);
