import { connect } from "react-redux";

// Component
import { WoundProgressTallyByLocationTableRowComponent } from "src/components/tables";

// Selectors
import { createWoundAssessmentTotalsSeriesForLocationSelector } from "src/selectors/data_selectors";
import { createWoundAssessmentTallyForLocationAndContextSelector } from "src/selectors/section_selectors/wounds";
import { progressValuesSelector } from "src/selectors/constant_selectors";

function mapStateToProps(state, ownProps) {
  return {
    tally: createWoundAssessmentTallyForLocationAndContextSelector(
      ownProps.location,
      ownProps.context
    )(state),
    totalSeries: createWoundAssessmentTotalsSeriesForLocationSelector(
      ownProps.location
    )(state),
    progressValues: progressValuesSelector(state)
  };
}

export const WoundProgressTallyByLocationTableRowContainer = connect(
  mapStateToProps
)(WoundProgressTallyByLocationTableRowComponent);
