import React from "react";
import { Link } from "react-router-dom";
import get from "lodash.get";

export function patientFullNameFormatter(cell, row) {
  const patientId = get(row, "attributes.patientId");

  if (!cell) return "";

  if (patientId) {
    return <Link to={`/patients/${patientId}`}>{cell}</Link>;
  } else {
    return cell;
  }
}
