import { createSelector } from "reselect";

// Selectors
import { usersResourceSelectors } from "src/selectors/data_selectors";
import { createFirstPageCliniciansContextSelector } from "../create_first_page_clinicians_context_selector";

// Payload generator
import { createGetCliniciansPayload } from "src/api/users/payload_generators";

// Constants
import { apiPageLimits } from "src/constants";

/**
 * create a selector to get the api query for the first page for clinicians (users with has_created_assessment=true)
 * @param {Object} props
 * @param {string} props.section
 * @returns {function} selector
 */
export function createAllPagesCliniciansPayloadSelector() {
  return createSelector(
    usersResourceSelectors.createPageCountSelector(
      createFirstPageCliniciansContextSelector(),
      apiPageLimits.users
    ),
    pageCount => {
      let payloads = [
        createGetCliniciansPayload({
          pageNumber: 0
        })
      ];

      if (pageCount > 1) {
        for (let i = 1; i < pageCount; i++) {
          payloads.push(createGetCliniciansPayload({ pageNumber: i }));
        }
      }

      return payloads;
    }
  );
}
