import React from "react";
import PropTypes from "prop-types";

// Translations
import i18n from "src/translations";

// Components
import { CheckboxList, CheckboxListItem } from "../checkbox_list";
import { CollapsibleContainerComponent } from "../collapsible_container_component";

// Analytics
import { track } from "src/analytics";
export class SideBarAcquiredFilterComponent extends React.Component {
  static propTypes = {
    acquiredFilter: PropTypes.string.isRequired,
    sendSetAcquiredFilterAction: PropTypes.func.isRequired,
    sendClearAcquiredFilterAction: PropTypes.func.isRequired,
    assessmentVersion: PropTypes.string.isRequired
  };

  /**
   * Handler for checkboxes - sets the acquired filter
   * @param {string} value value for the filter
   */
  onFilterChanged = value => {
    if (this.isFilterChecked(value)) {
      this.props.sendClearAcquiredFilterAction();
      track("DESELECT_WOUNDS_DASHBOARD_ACQUIRED_FILTER", { acquired: value });
    } else {
      this.props.sendSetAcquiredFilterAction(value);
      track("SELECT_WOUNDS_DASHBOARD_ACQUIRED_FILTER", { acquired: value });
    }
  };

  /**
   * Checks if a filter for a given filter value is checked
   */
  isFilterChecked(value) {
    return this.props.acquiredFilter == value;
  }

  /**
   * Render the checkboxes
   */
  renderCheckboxes() {
    const { assessmentVersion } = this.props;
    return (
      <CheckboxList>
        <CheckboxListItem
          label={i18n.t(`data.acquired.${assessmentVersion}.inHouse`)}
          value="inHouse"
          checked={this.isFilterChecked("inHouse")}
          onChange={this.onFilterChanged}
        />
        <CheckboxListItem
          label={i18n.t(`data.acquired.${assessmentVersion}.externally`)}
          value="externally"
          checked={this.isFilterChecked("externally")}
          onChange={this.onFilterChanged}
        />
      </CheckboxList>
    );
  }

  render() {
    return (
      <CollapsibleContainerComponent label="Acquired">
        {this.renderCheckboxes()}
      </CollapsibleContainerComponent>
    );
  }
}
