import BRADEN_BUCKETS from "./bradenBuckets";

export default function calculateAggregates(aggregates) {
  let veryHighTotal = 0;
  let highTotal = 0;
  let mediumTotal = 0;
  let lowTotal = 0;
  let noTotal = 0;
  let noneTotal = 0;

  aggregates.forEach(aggregate => {
    for (let bradenBucket in BRADEN_BUCKETS) {
      let [maxValue, minValue] = BRADEN_BUCKETS[bradenBucket];

      if (
        maxValue !== null &&
        minValue !== null &&
        aggregate.braden_score !== null &&
        aggregate.braden_score <= maxValue &&
        aggregate.braden_score >= minValue
      ) {
        switch (bradenBucket) {
          case "Very High":
            veryHighTotal += aggregate.braden_score_count;
            break;
          case "High":
            highTotal += aggregate.braden_score_count;
            break;
          case "Medium":
            mediumTotal += aggregate.braden_score_count;
            break;
          case "Low":
            lowTotal += aggregate.braden_score_count;
            break;
          case "No Risk":
            noTotal += aggregate.braden_score_count;
        }
      } else if (
        maxValue === null &&
        minValue === null &&
        aggregate.braden_score === null
      ) {
        noneTotal += aggregate.braden_score_count;
      }
    }
  });

  return {
    veryHighTotal,
    highTotal,
    mediumTotal,
    lowTotal,
    noTotal,
    noneTotal
  };
}
