import { set } from "cookies-js";

// Default config
// - expires is undefined: cookie will be removed when user quits browser
// - secure (https only)
// TODO: look into another cookie library that supports same-site behaviour
const cookieConfig = {
  path: "/",
  secure: process.env.NODE_ENV == "development" ? false : true
};

/**
 * write value to a cookie with key
 * @param {string} key
 * @param {string} value
 */
export function setCookieValueForKey(key, value) {
  set(key, value, cookieConfig);
}
