import FiltersService from "src/pages/compliance/services/filters_service";

import LegacyEvaluationsFilter from "src/pages/compliance/components/filters/evaluations_filter_section";
import LegacyLocationsFilter from "src/pages/compliance/components/filters/locations_filter_section";
import FilterSection from "src/pages/compliance/components/filters/filter_section";
import SavedFiltersSection from "src/pages/compliance/components/filters/saved_filters_section";

const formatFilter = (label, value) => ({ label, value });

const sections = [
  {
    title: "My Saved Filters",
    filterKey: "saved",
    component: SavedFiltersSection,
    fetchData: async () => {
      // const data = await FiltersService.saved();
      // return data.map(({ attributes, id }) =>
      //   formatFilter(attributes.title, id)
      // );
    }
  },
  {
    title: "Evaluations",
    filterKey: "evaluations",
    component: LegacyEvaluationsFilter,
    fetchData: async () => {
      // const data = await FiltersService.evaluations();
      // return data.map(({ value }) => formatFilter(value, value));
    }
  },
  {
    title: "Locations",
    filterKey: "locations",
    component: LegacyLocationsFilter,
    fetchData: async () => {
      // const data = await FiltersService.locations(query.organizationId);
      // return data.map(({ name, id }) => formatFilter(name, id));
    }
  },
  {
    title: "Clinicians",
    filterKey: "clinicians",
    component: FilterSection,
    fetchData: async () => {
      const data = await FiltersService.clinicians();
      return data.map(({ attributes, id }) =>
        formatFilter(`${attributes.first_name} ${attributes.last_name}`, id)
      );
    }
  },
  {
    title: "Patient Evaluations",
    filterKey: "patientEvaluations",
    component: FilterSection,
    fetchData: async () => {
      const data = await FiltersService.patientEvaluations();
      return data.map(({ name, key }) => formatFilter(name, key));
    }
  },
  {
    title: "Payers",
    filterKey: "payers",
    component: FilterSection,
    fetchData: async query => {
      const data = await FiltersService.payers(query.organizationId);
      return data.map(({ attributes, id }) =>
        formatFilter(attributes.name, id)
      );
    }
  },
  {
    title: "Visit Frequency",
    filterKey: "visitFrequencies",
    component: FilterSection,
    fetchData: async () => {
      const data = await FiltersService.visitFrequencies();
      return data.map(({ name, value }) => formatFilter(name, value));
    }
  }
];

export default sections;
