import { combineReducers } from "redux";
import { surveyTallyExportReducer } from "./survey_tally_export_reducer";
import { surveyWoundDetailsExportReducer } from "./survey_wound_details_export_reducer";
import { populationTallyExportReducer } from "./population_tally_export_reducer";

export const exportsReducer = combineReducers({
  surveyTallyExport: surveyTallyExportReducer,
  surveyWoundDetailsExport: surveyWoundDetailsExportReducer,
  populationTallyExport: populationTallyExportReducer
});
