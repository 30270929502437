import { createSelector } from "reselect";
import { sections } from "src/constants";
import { woundTypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/wound_type_keys_for_all_schemas_selector";
import { createSectionSelectors } from "src/ui_processors";

const sectionSelectors = createSectionSelectors(sections.population);

export const selectedWoundTypesSelector = createSelector(
  sectionSelectors.filtersSelector,
  woundTypeKeysForAllSchemasSelector,
  (filters, woundTypesMap) => {
    const woundTypeFilters = filters.find(filter => filter.key == "woundTypes");
    if (woundTypeFilters) {
      return {
        woundTypes: woundTypeFilters.value.map(filter => filter.value)
      };
    } else {
      //None selected return all wound types
      return { woundTypes: woundTypesMap };
    }
  }
);
