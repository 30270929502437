import { adminActionTypes } from "src/action_types/section_action_types";

/**
 * Action to select user table page number
 * @param {number} pageNumber selected table page number
 * @return {Object} Action
 */

export function selectUserTablePageNumberAction(pageNumber) {
  return {
    type: adminActionTypes.selectUserTablePageNumber,
    payload: { pageNumber }
  };
}
