import axios from "axios";
import qs from "qs";

import { getNextPageQuery, registerPagination } from "../pagination";
import { registerForCancellation } from "../cancellation";

/**
 * Fetch series for a study id
 * TODO: pagination
 * @return {Promise}
 */
export function fetchStudySeries(studyId, loadMore = false) {
  const paginationKey = `api/v4/studies/${studyId}/series`;
  let url;

  if (!loadMore) {
    const query = {
      page: {
        limit: 5
      }
    };

    url = `/api/v4/studies/${studyId}/series?${qs.stringify(query)}`;
  } else {
    url = `/api/v4/studies/${studyId}/series${getNextPageQuery(paginationKey)}`;
  }

  const config = {
    cancelToken: registerForCancellation(url)
  };

  return axios
    .get(url, config)
    .then(response => {
      registerPagination(paginationKey, response);
      return response;
    })
    .catch(() => console.error("fetchStudySeries"));
}

/**
 * Fetch assessment answers for a study id
 * @return {Promise}
 */
export function fetchStudyNonWoundAssessmentAnswers(studyId) {
  // we need the first non wound aa of each type
  // currently no filter that supports this - therefore need to load every non_wound assessment for the study
  // pagination can't be turned off - use big limit to do this (max 1000)
  const pageLimit = 1000;

  const query = {
    page: {
      limit: pageLimit
    },
    filter: {
      assessment_type: "non_wound"
    },
    include: "user,assessment_answer_group"
  };

  const url = `/api/v4/studies/${studyId}/assessment-answers?${qs.stringify(
    query
  )}`;
  return axios.get(url);
}

export function fetchStudyAssessmentAnswers(studyId, loadMore = false) {
  const paginationKey = `api/v4/studies/${studyId}/assessment-answers`;
  let url = `/api/v4/studies/${studyId}/assessment-answers`;
  if (!loadMore) {
    const query = {
      page: {
        limit: 10
      },
      include: "user,assessment_answer_group"
    };
    url = url + `?${qs.stringify(query)}`;
  } else {
    url = url + getNextPageQuery(paginationKey);
  }

  return axios.get(url).then(response => {
    registerPagination(paginationKey, response);
    return response;
  });
}
