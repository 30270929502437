// Error tracking
import { trackError } from "src/error_tracking";

/**
 * handle tracking of api errors
 * @param {Object} error
 * @param {Object} details
 */
export function onApiActionError(error, details) {
  trackError(error, {
    requestMethod: details.requestMethod,
    resourceName: details.resourceName
  });
}
