import { createSelector } from "reselect";
import { populationIncidencePrevalenceChartDataDataSelector } from "src/selectors/data_selectors";
import { timeStepFilterSelector } from "src/selectors/section_selectors/population";
import { createPopulationLineChart } from "src/charts";

export const populationIncidencePrevalenceChartDefinitionSelector = createSelector(
  populationIncidencePrevalenceChartDataDataSelector,
  timeStepFilterSelector,
  (incidencePrevalenceChartData, timeStepFilter) => {
    return createPopulationLineChart(
      incidencePrevalenceChartData,
      timeStepFilter
    );
  }
);
