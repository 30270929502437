import { combineReducers } from "redux";

import { createResourceReducer } from "src/redux_resources_v1";

import { assessmentAnswerGroupsReducer } from "./assessment_answer_groups_reducer";
import { exportsReducer } from "./exports_reducer";
import { locationsReducer } from "./locations_reducer";
import { organizationsResourceReducer } from "./organizations_reducer";
import { policiesResourceReducer } from "./policies_reducer";
import { revisionsReducer } from "./revisions_reducer";
import { surveyWoundDetailsReducer } from "./survey_wound_details_reducer";
import { woundAssessmentsResourceReducer } from "./wound_assessments_reducer";
import { woundAssessmentProgressChartDataReducer } from "./wound_assessment_progress_chart_data_reducer";
import { woundAssessmentTotalsTimeSeriesByLocationReducer } from "./wound_assessment_totals_time_series_by_location_reducer";
import { populationTalliesByEtiologyReducer } from "./population_tally_by_etiology_reducer";
import { populationIncidenceReducer } from "./legacy_population_incidence_chart_reducer";
import { populationPrevalenceReducer } from "./legacy_population_prevalence_chart_reducer";
import { populationIncidencePrevalenceReducer } from "./population_incidence_prevalence_chart_reducer";
import { populationTalliesByLocationReducer } from "./population_tally_by_location_reducer";
import { rolesResourceReducer } from "./roles_reducer";
import { customRolesResourceReducer } from "./custom_roles_reducer";
import { permissionTypesResourceReducer } from "./permission_types_reducer";
import { importsReducer } from "./imports_reducer";
import { resetPasswordReducer } from "./reset_password_reducer";
import { changePasswordReducer } from "./change_password_reducer";
import { partnersReducer } from "./partners_reducer";
import { unitsResourceReducer } from "./units_reducer";
import { floorsResourceReducer } from "./floors_reducer";
import { currentUserReducer } from "./current_user_reducer";
import { patientsAdtResourceReducer } from "./patients_adt_reducer";

import { resourceNames } from "src/constants/resource_names";

const resourceReducers = Object.values(resourceNames).reduce((agg, name) => {
  agg[name] = createResourceReducer(name);
  return agg;
}, {});

export const dataReducers = combineReducers({
  assessmentAnswerGroups: assessmentAnswerGroupsReducer,
  exports: exportsReducer,
  floors: floorsResourceReducer,
  locations: locationsReducer,
  organizations: organizationsResourceReducer,
  policies: policiesResourceReducer,
  revisions: revisionsReducer,
  surveyWoundDetails: surveyWoundDetailsReducer,
  woundAssessments: woundAssessmentsResourceReducer,
  woundAssessmentProgressChartData: woundAssessmentProgressChartDataReducer,
  woundAssessmentTotalsTimeSeriesByLocation: woundAssessmentTotalsTimeSeriesByLocationReducer,
  populationTalliesByEtiology: populationTalliesByEtiologyReducer,
  populationIncidenceChartData: populationIncidenceReducer,
  populationPrevalenceChartData: populationPrevalenceReducer,
  populationIncidencePrevalenceChartData: populationIncidencePrevalenceReducer,
  populationTalliesByLocation: populationTalliesByLocationReducer,
  roles: rolesResourceReducer,
  customRoles: customRolesResourceReducer,
  permissionTypes: permissionTypesResourceReducer,
  imports: importsReducer,
  resetPassword: resetPasswordReducer,
  changePassword: changePasswordReducer,
  partners: partnersReducer,
  resetPassword: resetPasswordReducer,
  units: unitsResourceReducer,
  currentUser: currentUserReducer,
  patientsAdt: patientsAdtResourceReducer,
  ...resourceReducers
});
