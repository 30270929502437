import { addReducer } from "incremental-redux-reducers";

const DEFAULT_STATE = {
  patient_link_template: "/patients/{patient_id}"
};

export default addReducer("application", reducer);

export function reducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case "SET_PATIENT_LINK_TEMPLATE":
      return {
        patient_link_template: action.template
      };
    default:
      return state;
  }
}
