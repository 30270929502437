// @flow
import { Map, Record, List, fromJS } from "immutable";
import { convertInput } from "src/utils/modelUtils";

export const recordInput = {
  admissionId: "",
  age: "",
  assessmentCreatedAt: "",
  assessmentFrequency: "",
  avatarColours: new List(),
  avatarUrl: "",
  avatarImageUrls: "",
  birthAt: "",
  bradenRisk: "",
  bradenScore: 0,
  createdAt: "",
  currentUser: new Map(),
  facilityId: "",
  facilityRoom: "",
  facilityRoomBed: "",
  firstName: "",
  fullName: "",
  gender: "",
  id: "",
  lastName: "",
  lengthOfStay: 0,
  middleName: "",
  mrn: "",
  nextAssessmentAt: "",
  organizationId: "",
  qrcodeUrl: "",
  reversedFullName: "",
  shareToken: "",
  updatedAt: "",
  userPatients: new List(),
  worstWoundStatus: "",
  assessmentAnswerGroupCreatedAt: "",
  firstCompletedAssessmentAnswerCreatedAt: ""
};

export default class PatientModel extends Record(recordInput) {
  id: $$id;
  fullName: string;
  static isLoggedIn = patient => {
    return !!patient.currentUser.get("userId");
  };
  constructor(opts: Object = {}) {
    const nextOpts = convertInput(
      {
        avatarColours: List,
        userPatients: List,
        currentUser: fromJS
      },
      opts
    );
    if (!nextOpts.fullName) {
      nextOpts.fullName = `${nextOpts.firstName || ""} ${nextOpts.lastName ||
        ""}`;
    }
    super(nextOpts);
  }
}
