// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";

type $props = {
  responsive?: boolean,
  className?: string,
  src?: string
};

export default class Image extends PureComponent {
  props: $props;
  render() {
    const { responsive, className, ...otherProps } = this.props;
    const klass = classnames({
      ["img-responsive"]: !!responsive,
      [className || ""]: !!className
    });
    return <img className={klass} {...otherProps} />;
  }
}
