import { adminActionTypes } from "src/action_types/section_action_types";

export function selectedPatientTablePageNumberReducer(state = 0, action) {
  switch (action.type) {
    case adminActionTypes.selectPatientTablePageNumber:
      return action.payload;
    case adminActionTypes.selectOrganizations:
      return 0;
    case adminActionTypes.deselectOrganizations:
      return 0;
    case adminActionTypes.selectFacilities:
      return 0;
    case adminActionTypes.deselectFacilities:
      return 0;
    case adminActionTypes.selectedPartner:
      return 0;
    case adminActionTypes.selectPatientTablePageLimit:
      return 0;
    default:
      return state;
  }
}
