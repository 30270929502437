import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";
import { GridRow, GridColumn } from "src/components/grid";

import styles from "./style.module.less";
export class FormCheckboxComponent extends React.Component {
  static propTypes = {
    component: PropTypes.string.isRequired,
    componentType: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    checked: PropTypes.bool,
    labelView: PropTypes.bool,
    validate: PropTypes.array,
    required: PropTypes.bool
  };

  renderLabelView = () => {
    const {
      labelText,
      required,
      name,
      component,
      componentType,
      value,
      patientRequired
    } = this.props;
    return (
      <GridRow className={styles["margin-bottom5px"]}>
        <GridColumn sm={2}>
          <label className={styles["label-text"]}>
            {labelText}{" "}
            {required && <span className={styles["required-field"]}>*</span>}
          </label>
        </GridColumn>
        <GridColumn sm={10}>
          <Field
            name={name}
            component={component}
            type={componentType}
            value={value}
          />
          {patientRequired && (
            <span className={styles["error"]}>
              Please fill out all of the mandatory (
              <span style={{ color: "red" }}>*</span>) fields below
            </span>
          )}
        </GridColumn>
      </GridRow>
    );
  };

  render() {
    const {
      labelView,
      labelText,
      name,
      component,
      componentType,
      checked,
      value
    } = this.props;
    if (labelView) return this.renderLabelView();
    else {
      return (
        <div className={`checkbox ${styles["margin-bottom5px"]}`}>
          <label>
            <Field
              name={name}
              component={component}
              type={componentType}
              value={value}
              checked={checked}
            />
            {labelText}
          </label>
        </div>
      );
    }
  }
}
