import get from "lodash.get";
import i18n from "src/translations";

const encounterLocationFormatter = (encounter, encounterFieldsShown) => {
  if (encounterFieldsShown.location) {
    const encounterLocation =
      get(encounter, "attributes.location", null) || "N/A";
    return `${i18n.t("data.encounters.locationString")}: ${encounterLocation}`;
  }
  return null;
};

export default encounterLocationFormatter;
