import { push } from "react-router-redux";
import { trackError } from "src/error_tracking";

// Api
import { changePassword } from "src/api/change_password";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";
import { create } from "src/actions/flashMessageActions";

/**
 * Action creator for setting loading state of change password to "loading"
 * @return {Object} action
 */
function changePasswordLoadingAction() {
  return {
    type: dataActionTypes.changePasswordLoading
  };
}

/**
 * Action creator for setting ready state of change password to 'ready'
 * @return {Object} action
 */
function changePasswordReadyAction() {
  return {
    type: dataActionTypes.changePasswordReady
  };
}

/**
 * Send api call change user password.
 * @param {Object} payload The payload
 */
export function changePasswordAction(payload) {
  return dispatch => {
    // loading state
    dispatch(changePasswordLoadingAction());
    return changePassword(payload)
      .then(result => {
        // ready state
        dispatch(changePasswordReadyAction());

        if (
          result &&
          result.status == 200 &&
          result.data &&
          result.data.errors
        ) {
          dispatch(create(result.data.errors, "error"));
        } else {
          dispatch(create("Password updated successfully", "success"));
          dispatch(push(`account`));
        }
      })
      .catch(err => {
        if (err.response && err.response.status == 422) {
          const errorMessages = err.response.data.errors.join(", ");
          dispatch(create(errorMessages, "error"));
        } else
          dispatch(
            create("Sorry, there was an error changing your password", "error")
          );
        dispatch(changePasswordReadyAction());
        trackError(err);
      });
  };
}
