import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { signOut } from "src/actions/session_actions";

//Components
import { Button } from "src/ui-kit";

//Constants
import { staticPages } from "src/constants";

//Styles
import "./style.less";

//Selectors
import {
  eulaButtonEnabledSelector,
  privacyPolicyButtonEnabledSelector
} from "src/selectors/config_selectors";

//Router
import { NavLink } from "react-router-dom";

class AccountComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func
  };

  handleSignOut = event => {
    event.preventDefault();

    this.props.dispatch(signOut());
  };

  onClickPrivacyPolicy = () => {
    window.open(staticPages.baseURL + staticPages.privacyPolicy, "_blank");
  };

  onClickEULA = () => {
    window.open(staticPages.baseURL + staticPages.eula, "_blank");
  };

  render = () => {
    const { privacyPolicyEnabled, eulaEnabled } = this.props;
    return (
      <div className="account-view">
        <div className="row panel-headline">
          <div className="col-xs-12">
            <h1>Settings</h1>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <NavLink to="/change-password">
            <p>Change Password</p>
          </NavLink>
          {eulaEnabled && (
            <div>
              <Button
                subtype="link"
                onClick={this.onClickEULA}
                analytics={{ type: "BUTTON_CLICKED_EULA" }}
              >
                <p>End User License Agreement</p>
              </Button>
            </div>
          )}
          {privacyPolicyEnabled && (
            <div>
              <Button
                subtype="link"
                onClick={this.onClickPrivacyPolicy}
                analytics={{ type: "BUTTON_CLICKED_PRIVACY_POLICY" }}
              >
                <p>Privacy Policy</p>
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    privacyPolicyEnabled: privacyPolicyButtonEnabledSelector(state),
    eulaEnabled: eulaButtonEnabledSelector(state)
  };
}

export default connect(mapStateToProps)(AccountComponent);
