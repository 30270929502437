import get from "lodash.get";
import { allowedSubtypes } from "src/constants"; //for DAS-3088, this will need to be modified.

export const createQuestionHierarchyFromSchema = schema => {
  if (!schema || !Object.keys(schema).length) return [];

  const questions = get(schema, "attributes.schemaJson.questions", []);
  let hierarchyObject = {};
  const woundTypeObject = questions.find(
    question => question.name === "woundType"
  );

  if (!woundTypeObject) return [];

  woundTypeObject.enumConstants.forEach(value => {
    let questionObject = {
      key: "woundType",
      value
    };
    const children = getChildrenForQuestion("woundType", value, questions);
    if (children && value !== "surgical") {
      questionObject.children = children;
    }

    hierarchyObject[value] = questionObject;
  });

  if (Object.values(hierarchyObject).length) {
    hierarchyObject["undiagnosed"] = {
      key: "woundType",
      value: "undiagnosed"
    };
  }

  return Object.values(hierarchyObject);
};

const getChildrenForQuestion = (type, value, questions) => {
  const questionsWithTypeAndValue = questions.filter(question => {
    return (
      question.visiblePredicate != null &&
      question.visiblePredicate.includes(type) &&
      question.visiblePredicate.includes(value) &&
      question.enumConstants
    );
  });

  let children = [];
  if (type === "woundType" && !allowedSubtypes.includes(value)) {
    //This helps differentiate wound attributes from subtypes, if it's not a subtype, don't add children.
    return null;
  }

  if (questionsWithTypeAndValue.length > 1) {
    children = questionsWithTypeAndValue.reduce((acc, question) => {
      const listOfOptions = getListOfOptions(question, questions);
      const obj = {
        // key: question.name,
        value: question.name,
        children: listOfOptions
      };

      return [...acc, obj];
    }, []);
  } else if (questionsWithTypeAndValue.length === 1) {
    children = getListOfOptions(questionsWithTypeAndValue[0], questions);
  }

  return children.length ? children : null;
};

const getListOfOptions = (question, questions) => {
  const listOfOptions = question.enumConstants.map(option => {
    const children = getChildrenForQuestion(question.name, option, questions);

    return {
      key: question.name,
      value: option,
      ...(children && children.length && { children })
    };
  });

  if (listOfOptions.length > 0) {
    listOfOptions.push({ key: question.name, value: "notSet" });
  }

  return listOfOptions;
};

/*
ALGORITHM
V2
- 1. Create object with all wound types
    - for each enum in wound types, append {key, value} pairs to object.
- 2. For each woundType question, find all questions where visible predicate has "woundType==""
    - Append children to key/value pair where visible is predicate to find parent question. (find parent with key "pressureUlcer" and append children: one object for each enum constant)
    - For each child, find all questions where visible predicate has "child.name == ..."
        - Append children to key/value pair where visible is predicate to find parent question.
        Find all questions where visible predicate has "woundType==""
*/

/**
  dictionary:
   "surgicalClosure.closureStaples": "Staples",
      "surgicalClosure.closureSteriStrips": "Steri-Strips",
      "surgicalClosure.closureSutures": "Sutures",
      "surgicalClosure.closureTape": "Tape",
      "surgicalClosure.closureTissueAdhesives": "Tissue Adhesives",
      "surgicalClosure.closureOther": "Other",
      "surgicalClosure.title": "Closure Method",
      "surgicalOther.dehiscence": "Dehiscence",
      "surgicalOther.dehiscence.help": "Partial or complete separation of the outer layers of the joined incision",
      "surgicalOther.healingRidge": "Healing Ridge",
      "surgicalOther.healingRidge.help": "Induration beneath the skin under the suture line",
      "surgicalOther.incisionApprox": "Incision Approximated",
      "surgicalOther.title": "Other",
      "surgicalStapleCount.title": "# of Staples",
      "surgicalSutureCount.title": "# of Sutures",


  questions:
      {
      "enumConstants": [
        "closureSutures",
        "closureStaples",
        "closureTape",
        "closureSteriStrips",
        "closureTissueAdhesives",
        "closureOther"
      ],
      "name": "surgicalClosure",
      "required": false,
      "type": 7,
      "groupInheritable": 1,
      "visiblePredicate": "woundType == \"surgical\"",
      "viewParameters": {
        "control": 600
      }
    },

        {
      "enumConstants": [
        "incisionApprox",
        "dehiscence",
        "healingRidge"
      ],
      "name": "surgicalOther",
      "required": false,
      "type": 7,
      "groupInheritable": 1,
      "visiblePredicate": "woundType == \"surgical\"",
      "viewParameters": {
        "control": 600
      }
    }
  */
