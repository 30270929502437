import get from "lodash.get";
import { ruleParameterOperators } from "src/constants";
/**
 * cell formatter for alert rules applies to
 * @param {Object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.visibleRuleParams the description of the alert rule
 */
export function alertRulesRangeFormatter(cell, row) {
  const ruleParams = get(row, "attributes.ruleParams", []);
  const visibleRuleParams = ruleParams.filter(param => param.hidden === false);
  const minValueParam = visibleRuleParams.find(
    param => param.operator === ruleParameterOperators.greaterOrEqual
  );
  const maxValueParam = visibleRuleParams.find(
    param => param.operator === ruleParameterOperators.lessOrEqual
  );

  if (minValueParam && maxValueParam) {
    const minVal = minValueParam.value;
    const maxVal = maxValueParam.value;
    if (minVal && maxVal) {
      return `${minVal} to ${maxVal}`;
    }
  }
}
