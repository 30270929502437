import axios from "axios";
import qs from "qs";

export function fetchLocationsForOrganization(orgId) {
  const query = {
    include_all: true,
    inlude_leaves: true
  };

  const url = `/api/v2/organizations/${orgId}/locations?${qs.stringify(query)}`;
  return axios.get(url);
}
