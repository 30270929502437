import { createSelector } from "reselect";

// Selectors
import { woundTalliesResourceSelectors } from "src/selectors/data_selectors";
import { createWoundTalliesContextSelector } from "src/selectors/api_selectors/survey/wound_tallies/create_wound_tallies_context_selector";

// Constants
import { tallyTypes } from "src/constants";

/**
 * Selector to provide tally data aggregated by wound type
 */
export const talliesByWoundTypeSelector = createSelector(
  woundTalliesResourceSelectors.createDataForContextSelector(
    createWoundTalliesContextSelector(tallyTypes.etiology)
  ),
  tallies => {
    return tallies;
  }
);
