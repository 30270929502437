import PropTypes from "prop-types";
import React from "react";
import { track } from "src/analytics";

// components
import { CheckboxList, CheckboxListItem } from "../checkbox_list";
import { CollapsibleContainerComponent } from "../collapsible_container_component";

export class SideBarOptionsFilterComponent extends React.Component {
  static propTypes = {
    locations: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    dischargedOptionSelected: PropTypes.bool.isRequired,
    sendDischargedOptionSelectedAction: PropTypes.func.isRequired
  };

  static defaultProps = {
    disabled: false
  };

  onDischargedSelected = () => {
    const {
      dischargedOptionSelected,
      sendDischargedOptionSelectedAction
    } = this.props;
    track("SELECT_DISHCHARGE_OPTION_FOR_PATIENT");

    if (dischargedOptionSelected) {
      sendDischargedOptionSelectedAction(false);
    } else {
      sendDischargedOptionSelectedAction(true);
    }
  };

  render() {
    const { dischargedOptionSelected, disabled } = this.props;

    return (
      <CollapsibleContainerComponent label="Options">
        <CheckboxList disabled={disabled}>
          <CheckboxListItem
            key="isDischarged"
            label="Include Discharged"
            value="isDischarged"
            checked={dischargedOptionSelected}
            onChange={this.onDischargedSelected}
          ></CheckboxListItem>
        </CheckboxList>
      </CollapsibleContainerComponent>
    );
  }
}
