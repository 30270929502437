import { createSelector } from "reselect";
import { createSectionSelectors } from "src/ui_processors/global/selector_creators";

//Constants
import { sections } from "src/constants";

/**
 * Memoized selector to return if side bar should be shown or hidden
 */
export const showSidebarSelector = createSelector(
  createSectionSelectors(sections.global).sectionSelector,
  section => {
    return section.showSidebar;
  }
);
