import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { propsAreDifferent } from "src/utils/props_are_different";

// Actions
import { loadWoundAssessmentTotalsTimeSeriesByLocationAction } from "src/actions/data_actions";

// Selectors
import { selectedLocationIdsSelector } from "src/selectors/section_selectors/wounds";
import { administrativeChartPayloadSelector } from "src/selectors/query_selectors/wounds";

/**
 * HOC that is responsible for loading wound total count time series by location
 * @param {Class} WrappedComponent the component dependent on this data
 * @return {Class} Component wrapped by the loader component
 */
export function withWoundAssessmentTotalsTimeSeriesByLocationLoader(
  WrappedComponent
) {
  class WoundAssessmentTotalsTimeSeriesByLocationLoader extends React.Component {
    static propTypes = {
      payload: PropTypes.object.isRequired,
      selectedLocationIds: PropTypes.array.isRequired,
      sendLoadWoundTotalsSeriesByLocationAction: PropTypes.func.isRequired
    };

    componentWillMount() {
      // TODO: Keep track of location loading state and use that here
      if (this.props.selectedLocationIds.length) {
        this.loadWoundTotalsSeries(this.props);
      }
    }

    componentWillReceiveProps(nextProps) {
      if (
        propsAreDifferent(this.props, nextProps, "payload") &&
        nextProps.selectedLocationIds.length
      ) {
        this.loadWoundTotalsSeries(nextProps);
      }
    }

    loadWoundTotalsSeries(props) {
      const {
        payload,
        selectedLocationIds,
        sendLoadWoundTotalsSeriesByLocationAction
      } = props;
      sendLoadWoundTotalsSeriesByLocationAction(payload, selectedLocationIds);
    }

    render() {
      return <WrappedComponent />;
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WoundAssessmentTotalsTimeSeriesByLocationLoader);
}

/**
 * Map the props needed for loading data.
 */
function mapStateToProps(state) {
  return {
    payload: administrativeChartPayloadSelector(state),
    selectedLocationIds: selectedLocationIdsSelector(state)
  };
}

/**
 * Map dispatch to a props for loading data
 */
function mapDispatchToProps(dispatch) {
  return {
    sendLoadWoundTotalsSeriesByLocationAction(payload, selectedLocationIds) {
      dispatch(
        loadWoundAssessmentTotalsTimeSeriesByLocationAction(
          payload,
          selectedLocationIds
        )
      );
    }
  };
}
