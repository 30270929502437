import React from "react";
import PropTypes from "prop-types";

import { Glyphicon } from "src/components/ui_kit";

//CSS
import styles from "./style.module.less";

export class DownloadManagerHeaderComponent extends React.Component {
  static propTypes = {
    showBody: PropTypes.bool.isRequired,
    closeDownloadManagerAction: PropTypes.func.isRequired,
    toggleExpandCollapseDownloadManagerBody: PropTypes.func.isRequired
  };

  onToggleExpandCollapseBody = () => {
    this.props.toggleExpandCollapseDownloadManagerBody();
  };

  onCloseDownloadManager = () => {
    this.props.closeDownloadManagerAction();
  };

  render() {
    return (
      <div className={styles["container"]}>
        <div className={styles["title"]}>
          <p>Exports</p>
        </div>
        <div className={styles["action-buttons"]}>
          <div
            className={`${styles["icon-button"]}`}
            onClick={this.onToggleExpandCollapseBody}
          >
            <Glyphicon
              glyph={this.props.showBody ? "menu-down" : "menu-up"}
              className="panel-arrow"
            />
          </div>
          <div
            className={styles["icon-button"]}
            onClick={this.onCloseDownloadManager}
          >
            <Glyphicon glyph="remove" />
          </div>
        </div>
      </div>
    );
  }
}
