// TODO:
// time tracking/analytics/error hooks
// Add support for resource specific pagination strategies (not globably configured ones)

// TODO:
// Remove dependence on thunk middleware (used by the api actions)
// Remove dependency on humps

import { loadStates } from "./load_states";
import { createActionCreators } from "./create_action_creators";
import { createReducer } from "./create_reducer";
import { createSelectors } from "./create_selectors";
import { createWithResourceHoc } from "./create_with_resource_hoc";
import paginationStrategies from "./pagination_strategies";
import { createLoadingManagerHoc } from "./create_loading_manager_hoc";

/**
 * reduxStoreRootName
 * - root page in the store to put data
 *
 * paginationStrategy
 * - function to transform page inputs to page objects sent to the api
 *
 * normalizer
 * - function responsible for normalizing api response
 * - overrideable per resource
 * - required
 *
 * onApiActionError
 * - function called when an API request fails in an action
 * - overrideable per resource
 * - passed the error, resourceName, requestMethod, queryObject, page
 */
let globalConfig = {
  reduxStoreRootName: "resources",
  paginationStrategy: paginationStrategies.offset,
  normalizer: undefined,
  onApiActionError: undefined
};

/**
 * Configure global options for ReduxResources
 * @param {Object} config - config uration object
 * @param {function} config.normalizer - api response normalizer
 */
function configure(config) {
  globalConfig = {
    ...globalConfig,
    ...config
  };
}

class ResourceManager {
  /**
   * Resource Manager Constructor
   * @param {Object} resourceConfig
   * @param {class} resourceConfig.ResourceClass - class loaded resources will be assigned to
   * @param {string} resourceConfig.resourceName - the resource name
   * @param {array} resourceConfig.includedResourceNames - possible resource names included using json-api "include"
   * @param {Object} resourceConfig.api - api object - must respond to get/post/patch/delete
   * @param {function} resourceConfig.api.get
   * @param {function} resourceConfig.api.post
   * @param {function} resourceConfig.api.patch
   * @param {function} resourceConfig.api.delete
   */
  constructor(resourceConfig) {
    this.ResourceClass = resourceConfig.ResourceClass;
    this.resourceName = resourceConfig.resourceName;
    this.includedResourceNames = resourceConfig.includedResourceNames;
    this.api = resourceConfig.api;
    this.normalizer = resourceConfig.normalizer;

    // Actions
    this.actions = createActionCreators(this, globalConfig);

    // Reducer
    this.reducer = createReducer(this.resourceName);

    // selectors
    this.selectors = createSelectors(this, globalConfig);

    // HOC's
    this.withResource = createWithResourceHoc(this, globalConfig);
  }
}

export default {
  ResourceManager,
  configure,
  createLoadingManagerHoc,
  loadStates
};
