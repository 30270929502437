import { createSelector } from "reselect";

//Constants
import { allFacilitiesOptionId, rulesEvaluationTypes } from "src/constants";

//Utils
import { buildQueryString } from "src/utils/build_query_string";
import SingleValueReduxUtil from "src/ui_processors/single_value";

//Payload Generator
import { createRulesGetPayload } from "src/api/rules";

//Selectors
import {
  adminRulesSelectedFacilityIdSelector,
  adminRulesSelectedOrganizationIdSelector,
  adminSectionSelector
} from "src/selectors/section_selectors/admin";
const adminRiskRulesTablePageNumberSelector = SingleValueReduxUtil.createSelectors(
  "selectedRiskAlertRulesTablePageNumber",
  adminSectionSelector
).fieldSelector;
const adminRiskRulesTablePageLimitSelector = SingleValueReduxUtil.createSelectors(
  "selectedRiskAlertRulesTablePageLimit",
  adminSectionSelector
).fieldSelector;

export const createRiskAlertRulesQuerySelector = () => {
  return createSelector(
    adminRulesSelectedOrganizationIdSelector,
    adminRulesSelectedFacilityIdSelector,
    adminRiskRulesTablePageNumberSelector,
    adminRiskRulesTablePageLimitSelector,
    (organizationId, facilityId, pageNumber, pageLimit) => {
      if (!organizationId && !facilityId) {
        return "";
      }

      const locationId =
        facilityId == allFacilitiesOptionId ? organizationId : facilityId;
      const locationType =
        facilityId == allFacilitiesOptionId ? "Organization" : "Facility";

      return buildQueryString(
        createRulesGetPayload({
          locationId,
          locationType,
          pageNumber,
          pageLimit,
          evaluationType: rulesEvaluationTypes.risk
        })
      );
    }
  );
};
