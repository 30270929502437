import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./style.module.less";

/**
 * Class that renders an image - handles image loading animation as well
 */
export class ImageComponent extends React.PureComponent {
  static propTypes = {
    imageUrl: PropTypes.string
  };

  state = {
    imageLoading: true
  };

  componentDidMount() {
    let img = new Image();
    img.src = this.props.imageUrl;
    img.onload = () => {
      this.setState({
        imageLoading: false
      });
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.imageUrl) {
      const previousUrl = this.props.imageUrl.split("?")[0];
      const newUrl = nextProps.imageUrl.split("?")[0];

      if (previousUrl !== newUrl) {
        this.setState({
          imageLoading: true
        });

        let img = new Image();
        img.src = nextProps.imageUrl;
        img.onload = () => {
          this.setState({
            imageLoading: false
          });
        };
      }
    }
  }

  render() {
    const { imageUrl } = this.props;
    if (!imageUrl) {
      // default image
      return (
        <div
          className={`${styles["image-container"]} ${styles["no-image-css"]}`}
        />
      );
    } else if (this.state.imageLoading) {
      return (
        <div className={styles["image-container"]}>
          <div className={styles["spin-box"]} />
        </div>
      );
    }

    return <img className={styles["image-container"]} src={imageUrl} />;
  }
}
