// @flow
import React, { PureComponent } from "react";

type $props = {
  options: Array<{ label?: string, value: any }>,
  value: string | number,
  onChange: Function,
  disabled?: boolean
};

export default class Select extends PureComponent {
  props: $props;
  handleChange = (event: Object) => this.props.onChange(event.target.value);
  render() {
    const { options, value, disabled } = this.props;
    return (
      <select
        disabled={disabled}
        className="form-control"
        onChange={this.handleChange}
        value={value}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label || option.value}
          </option>
        ))}
      </select>
    );
  }
}
