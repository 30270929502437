import Moment from "moment";

/**
 * @param weekCount The number of weeks we want to generate dates for
 * @returns an array of date strings in ISO format that point to the end of each week.
 */
export function createWeeklyDatesArray(weekCount, endTime) {
  if (!endTime) return [];

  let datesArray = [...Array(weekCount).keys()].map(i => {
    const selectedDateObject = new Moment(endTime);
    const calculatedEndDate = selectedDateObject.subtract(i, "weeks");
    const dateString = calculatedEndDate.endOf("day").toISOString();
    return dateString;
  });
  return datesArray;
}
