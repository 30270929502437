import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "react-bootstrap";

//Containers
import { AdminAlertRulesFiltersContainer } from "src/containers/admin_alert_rules_filters_container";

import {
  AdminWoundAlertRulesTableContainer,
  AdminRiskAlertRulesTableContainer
} from "src/containers/tables";

import { WoundAlertRuleFormContainer } from "src/containers/forms/wound_alert_rule_form_container";
import { RiskAlertRuleFormContainer } from "src/containers/forms/risk_alert_rule_form_container";

//Components
import {
  PanelHeaderRightComponent,
  PanelBodyComponent,
  PanelComponent
} from "src/components/panel";
import { ButtonComponent } from "src/components/button_component";

//Translations
import i18n from "src/translations";

export class AdminAlertRulesComponent extends React.PureComponent {
  static propTypes = {
    ruleTemplatesLoadState: PropTypes.string
  };

  state = {
    showCreateWoundAlertModal: false,
    showCreateRiskAlertModal: false
  };

  onClickAddWoundAlert = () => {
    this.setState({ ...this.state, showCreateWoundAlertModal: true });
  };

  onClickAddRiskAlert = () => {
    this.setState({ ...this.state, showCreateRiskAlertModal: true });
  };

  onHideModal = () => {
    this.setState({
      ...this.state,
      showCreateWoundAlertModal: false,
      showCreateRiskAlertModal: false
    });
  };

  render() {
    return (
      <div>
        <AdminAlertRulesFiltersContainer />
        {/* Wound Alerts Panel */}
        <PanelComponent
          labelShow={i18n.t(`headers.woundAlerts`)}
          enableCollapsible={true}
        >
          <PanelHeaderRightComponent>
            <ButtonComponent onClick={this.onClickAddWoundAlert}>
              {i18n.t(`actions.addAlert`)}
            </ButtonComponent>
          </PanelHeaderRightComponent>
          <PanelBodyComponent>
            <AdminWoundAlertRulesTableContainer />
          </PanelBodyComponent>
        </PanelComponent>

        {/* Add New Wound Alert Modal */}
        <Modal
          show={this.state.showCreateWoundAlertModal}
          onHide={this.onHideModal}
          bsSize={"lg"}
        >
          <ModalBody>
            <WoundAlertRuleFormContainer cancel={this.onHideModal} />
          </ModalBody>
        </Modal>

        {/* Risk Alerts Panel */}
        <PanelComponent labelShow={"Risk Alerts"} enableCollapsible={true}>
          <PanelHeaderRightComponent>
            <ButtonComponent onClick={this.onClickAddRiskAlert}>
              {i18n.t(`actions.addAlert`)}
            </ButtonComponent>
          </PanelHeaderRightComponent>
          <PanelBodyComponent>
            <AdminRiskAlertRulesTableContainer />
          </PanelBodyComponent>
        </PanelComponent>

        {/* Add New Risk Alert Modal */}
        <Modal
          show={this.state.showCreateRiskAlertModal}
          onHide={this.onHideModal}
          bsSize={"lg"}
        >
          <ModalBody>
            <RiskAlertRuleFormContainer cancel={this.onHideModal} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
