/**
 * Function to get max undermining or tunnelling with direction from array
 * @param {array} arrayOfValuesWithDirections
 * @param {number} maxValue
 * @returns {string} max value with direction string
 */
export function getMaxValueWithDirectionForTunnellingAndUndermining(
  arrayOfValuesWithDirections,
  maxValue
) {
  if (
    arrayOfValuesWithDirections &&
    arrayOfValuesWithDirections.length &&
    maxValue
  ) {
    //Get first string value of the tunnellings or underminings array
    //This is to account for the less than 0.1 measurement case
    let maxValueWithDirectionString = arrayOfValuesWithDirections[0];
    //Find the index of the string value for the max measurement value if present
    const maxIndex = arrayOfValuesWithDirections.findIndex(valueWithDirection =>
      //Need to change data type to number to round to 1 dp,
      //and then convert back to string for the string value index finding
      valueWithDirection.includes(
        Number(maxValue)
          .toFixed(1)
          .toString()
      )
    );
    // If a max value string is found
    if (maxIndex >= 0) {
      maxValueWithDirectionString = arrayOfValuesWithDirections[maxIndex];
    }
    return maxValueWithDirectionString;
  }
}
