import { connect } from "react-redux";
import { ResetPasswordFormComponent } from "src/components/forms/reset_password_form_component";

//selectors
import { resetPasswordLoadStateSelector } from "src/selectors/data_selectors";
// Actions
import { resetPasswordAction } from "src/actions/data_actions/reset_password";

function mapStateToProps(state) {
  return {
    resetPasswordLoadState: resetPasswordLoadStateSelector(state)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sendResetPasswordAction(payload) {
      dispatch(resetPasswordAction(payload));
    }
  };
}
export const ResetPasswordFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordFormComponent);
