import { addReducer } from "incremental-redux-reducers";

const DEFAULT_STATE = {
  timeIntervals: [],
  veryHighData: [],
  highData: [],
  mediumData: [],
  lowData: [],
  noData: [],
  noneData: []
};

export default addReducer(
  "locationChartRisk",
  function locationChartRiskReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
      case "LOCATION_AGGREGATE_CHART_RISK_LOAD":
        let { chartData } = action;

        return {
          ...state,
          ...chartData
        };
      default:
        return state;
    }
  }
);
