import { createSelector } from "reselect";
import { organizationsResourceSelectors } from "../organizations_resource_selectors";
import { selectedOrganizationIdSelector } from "src/selectors/session_selectors";
/**
 * Org table should only have one entry for now
 */
export const selectedOrganizationSelector = createSelector(
  organizationsResourceSelectors.dataSelector,
  selectedOrganizationIdSelector,
  (data, selectedOrganizationId) => {
    const orgs = Object.values(data);

    if (selectedOrganizationId) {
      return data ? data[selectedOrganizationId] : null;
    } else {
      return orgs.length ? orgs[0] : null;
    }
  }
);
