import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";

import { AssessmentCardComponent } from "src/components/cards";

import {
  seriesLinkEnabledSelector,
  assessmentVersionSelector,
  patientLinksEnabledSelector
} from "src/selectors/config_selectors";

import {
  woundNumbersEnabledSelector,
  hprScoreEnabledSelector,
  bwatScoreEnabledSelector
} from "src/selectors/ui_config_selectors/global";

// Selectors
import { seriesResourceSelectors } from "src/selectors/data_selectors";
import { schemaLocalizationDictionarySelector } from "src/selectors/section_selectors/global";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";
import { createDefaultFrameForSeriesIdSelector } from "src/selectors/data_selectors";

// Actions
import { loadSeriesForSeriesIdAction } from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  const seriesId = get(ownProps, "assessment.attributes.seriesId");
  const thumbnailUrl = get(
    ownProps,
    "assessment.attributes.frameImageUrls.thumb"
  );
  const frame_content_type = get(
    ownProps,
    "assessment.attributes.frameContentType"
  );

  return {
    seriesLinkEnabled: seriesLinkEnabledSelector(state),
    woundNumbersEnabled: woundNumbersEnabledSelector(state),
    hprScoreEnabled: hprScoreEnabledSelector(state),
    bwatScoreEnabled: bwatScoreEnabledSelector(state),
    assessmentVersion: assessmentVersionSelector(state),
    schemaLocalizationDictionary: schemaLocalizationDictionarySelector(state),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state),
    patientLinksEnabled: patientLinksEnabledSelector(state),
    seriesId,
    seriesLoadState: seriesResourceSelectors.createContextLoadStateSelectorWithContextString(
      seriesId
    )(state),
    frame: createDefaultFrameForSeriesIdSelector(seriesId)(state),
    thumbnailUrl,
    frame_content_type
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendLoadSeriesForSeriesIdAction(seriesId, lockState) {
      dispatch(loadSeriesForSeriesIdAction(seriesId, lockState));
    }
  };
}

export const AssessmentCardContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssessmentCardComponent)
);
