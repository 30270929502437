import { createSelector } from "reselect";

// Api
import { createWoundTalliesGetPayload } from "src/api/wound_tallies/payload_generators";

// Selectors
import { answersFilterSelector } from "src/selectors/section_selectors/survey/answers_filter_selector";
import { tallyContextsEtiologySelector } from "src/selectors/section_selectors/survey/tally_contexts_etiology_selector";
import { tallyContextsLocationSelector } from "src/selectors/section_selectors/survey/tally_contexts_location_selector";
import { dateSelectionSelector } from "src/selectors/section_selectors/survey/date_selection_selector";
import { includeDischargedSelector } from "../../../../section_selectors/survey/include_discharged_selector";
import { excludeNewWoundsSelector } from "src/selectors/section_selectors/survey/exclude_new_wounds_selector";
import { selectedOrganizationSelector } from "src/selectors/data_selectors";
import { orderedSelectedLocationsSelector } from "src/selectors/section_selectors/survey/ordered_selected_locations_selector";

// Constants
import { tallyTypes } from "src/constants";

/**
 * create selector to get payloads for loading tallies for each wound type
 * @param {string} tallyType - etiology or location
 * @returns {Function} selector
 */
export function createWoundTalliesPayloadSelector(tallyType) {
  return createSelector(
    answersFilterSelector,
    tallyContextsEtiologySelector,
    tallyContextsLocationSelector,
    orderedSelectedLocationsSelector,
    dateSelectionSelector,
    includeDischargedSelector,
    excludeNewWoundsSelector,
    selectedOrganizationSelector,
    (
      answers,
      tallyContextsEtiology,
      tallyContextsLocation,
      locations,
      dateSelection,
      includeDischarged,
      excludeNewHealed,
      organization
    ) => {
      if (!locations.length || !organization) {
        return undefined;
      }

      return createWoundTalliesGetPayload({
        answers,
        tallyContexts:
          tallyType == tallyTypes.location
            ? tallyContextsLocation
            : tallyContextsEtiology,
        locationIds: locations.map(loc => loc.id),
        dateSelection,
        includeDischarged,
        excludeNewHealed,
        organizationId: organization.id
      });
    }
  );
}
