import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import DatePicker from "react-datepicker";
import Select from "src/ui-kit/Select";

// Analytics
import { track } from "src/analytics";

export class DateRangeFilterComponent extends React.Component {
  static propTypes = {
    analytics: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    setDate: PropTypes.func.isRequired,
    unitRange: PropTypes.string,
    allowSameDayRange: PropTypes.bool
  };

  componentDidMount = () => {
    const { startDate, endDate } = this.props;
    if (!startDate) {
      this.handleStartDateRangeChange(
        Moment()
          .subtract(7, "d")
          .startOf("day")
      );
    }
    if (!endDate) {
      this.handleEndDateRangeChange(Moment().endOf("day"));
    }
  };

  handleAnalytics = (name, value) => {
    track(this.props.analytics || "DATE_RANGE_FILTER_COMPONENT", {
      name,
      value
    });
  };

  handleStartDateRangeChange = startDate => {
    if (startDate) {
      this.handleAnalytics("Change Start Date", startDate.toISOString());
      this.props.setDate({ key: "startDate", value: startDate });
    }
  };

  handleEndDateRangeChange = endDate => {
    if (endDate) {
      this.handleAnalytics("Change End Date", endDate.toISOString());
      this.props.setDate({ key: "endDate", value: endDate });
    }
  };

  handleDateRangeChangeUnit = value => {
    this.handleAnalytics("changeUnit", value);
    switch (value) {
      // Month presets
      case "monthToDate":
        this.props.setDate({
          startDate: Moment().startOf("month"),
          endDate: Moment().endOf("day"),
          unitRange: value
        });
        break;
      case "pastMonth":
        this.props.setDate({
          startDate: Moment()
            .subtract(1, "months")
            .startOf("month"),
          endDate: Moment()
            .subtract(1, "months")
            .endOf("month"),
          unitRange: value
        });
        break;
      case "past90Days":
        // NOTE: This is inclusive of the current day
        this.props.setDate({
          startDate: Moment()
            .subtract(89, "days")
            .startOf("day"),
          endDate: Moment().endOf("day"),
          unitRange: value
        });
        break;

      // Week presets
      case "weekToDate":
        // NOTE: Start of week considered to be Mon at 12am
        this.props.setDate({
          startDate: Moment().startOf("isoWeek"),
          endDate: Moment().endOf("day"),
          unitRange: value
        });
        break;
      case "pastWeek":
        // NOTE: Start of week considered to be Mon at 12am
        this.props.setDate({
          startDate: Moment()
            .startOf("isoWeek")
            .subtract(1, "week"),
          endDate: Moment()
            .endOf("isoWeek")
            .subtract(1, "week"),
          unitRange: value
        });
        break;
      case "past7Days":
        // NOTE: This is inclusive of the current day
        this.props.setDate({
          startDate: Moment()
            .subtract(6, "days")
            .startOf("day"),
          endDate: Moment().endOf("day"),
          unitRange: value
        });
        break;

      case "pastQuarter":
        this.props.setDate({
          startDate: Moment()
            .subtract(1, "quarter")
            .startOf("quarter"),
          endDate: Moment()
            .subtract(1, "quarter")
            .endOf("quarter"),
          unitRange: value
        });
        break;
      case "quarterToDate":
        this.props.setDate({
          startDate: Moment().startOf("quarter"),
          endDate: Moment().endOf("day"),
          unitRange: value
        });
        break;
      case "custom":
        this.props.setDate({
          startDate: this.props.startDate,
          endDate: this.props.endDate,
          unitRange: value
        });
        break;

      default:
        this.props.update({
          unitRange: value
        });
    }
  };

  isBeforeEndDate = date => {
    const { allowSameDayRange } = this.props;
    if (allowSameDayRange) {
      return new Moment(date).isSameOrBefore(this.props.endDate);
    } else {
      return new Moment(date).isBefore(this.props.endDate);
    }
  };

  isAfterStartDate = date => {
    const { allowSameDayRange } = this.props;
    if (allowSameDayRange) {
      return new Moment(date).isSameOrAfter(this.props.startDate);
    } else {
      return new Moment(date).isAfter(this.props.startDate);
    }
  };

  render() {
    const { startDate, endDate, unitRange } = this.props,
      format = "MMMM D, YYYY";

    return (
      <div>
        <span className="date-picker-range-first-element">
          <Select
            onChange={this.handleDateRangeChangeUnit}
            value={unitRange}
            options={[
              { value: "monthToDate", label: "Month to Date" },
              { value: "pastMonth", label: "Past Month" },
              { value: "past90Days", label: "Past 90 Days" },

              { value: "weekToDate", label: "Week to Date" },
              { value: "pastWeek", label: "Past Week" },
              { value: "past7Days", label: "Past 7 Days" },

              { value: "pastQuarter", label: "Past Quarter" },
              { value: "quarterToDate", label: "Quarter to Date" },

              { value: "custom", label: "Custom" }
            ]}
          />
        </span>
        <span className="date-picker-range-element">
          <DatePicker
            filterDate={this.isBeforeEndDate}
            dateFormat={format}
            selected={startDate}
            onChange={this.handleStartDateRangeChange}
            className="form-control"
          />
        </span>
        <span className="date-picker-range-element">to</span>
        <span className="date-picker-range-last-element">
          <DatePicker
            filterDate={this.isAfterStartDate}
            dateFormat={format}
            selected={endDate}
            onChange={this.handleEndDateRangeChange}
            className="form-control"
          />
        </span>
      </div>
    );
  }
}
