import { createSelector } from "reselect";
import { framesResourceSelectors } from "../frames_resource_selectors";
import { createLatestRevisionForSeriesIdSelector } from "src/selectors/data_selectors/revisions/create_latest_revision_for_series_id_selector";
import { DEFAULT_FRAME_TYPES } from "../constants";

/**
 * Creates a selector using seriesId that returns a frame that matches the frame types you are looking for
 * @param {String} seriesId
 * @return {Function} selector
 */

export function createDefaultFrameForSeriesIdSelector(seriesId) {
  return createFrameForSeriesIdSelector(seriesId, DEFAULT_FRAME_TYPES);
}

export function createFrameForSeriesIdSelector(seriesId, types) {
  return createSelector(
    createLatestRevisionForSeriesIdSelector(seriesId),
    framesResourceSelectors.dataSelector,
    (revision, frames) => {
      if (!seriesId || !revision) {
        return null;
      }

      // Get all the frames for this revision
      const revisionFrames = Object.values(frames).filter(
        frame => frame.attributes.revisionId == revision.id
      );

      let currentFrame;

      //Get the frame that mathes the type

      for (let i = 0; i < types.length; i++) {
        currentFrame = revisionFrames.filter(
          frame => frame.attributes.frameType == types[i]
        )[0];

        if (currentFrame) break;
      }

      return currentFrame ? currentFrame : revisionFrames[0];
    }
  );
}
