import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

// Components
import { PatientListContainer } from "src/containers/patient_list_container";

// css
import styles from "./style.module.less";

export class SideBarPatientsFilterComponent extends PureComponent {
  static propTypes = {
    sendSetPatientSearchStringAction: PropTypes.func.isRequired
  };

  /**
   * Debounced handler for the search string change
   */
  handleSearchChangeDebounce = debounce(event => {
    let { value } = event.target;
    this.props.sendSetPatientSearchStringAction(value);
  }, 500);

  /**
   * Update search string in the redux store
   */
  handleSearchChange = event => {
    event.persist();
    this.handleSearchChangeDebounce(event);
  };

  /**
   * Render the search bar
   */
  renderSearchBar() {
    return (
      <div className={`form-group ${styles["search-bar"]}`}>
        <input
          type="search"
          className="form-control"
          placeholder="Search"
          onChange={this.handleSearchChange}
          defaultValue={this.props.searchString}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={styles["patient-filter-container"]}>
        {this.renderSearchBar()}
        <PatientListContainer />
      </div>
    );
  }
}
