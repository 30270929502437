import moment from "moment";
import get from "lodash.get";

import { ageStringFromPatient } from "src/models/patients";

import { medlineCopy, defaultCopy } from "src/constants/branding_copy";

import { getWoundHeader } from "../../create_revision_pdf/get_wound_header";

export function getCoverPage(pdfContent, options) {
  const { patient, patientLastFacility, assessmentAnswer } = pdfContent;
  const {
    branding,
    schemaLocalizationDictionary,
    subtypeKeysForSchema
  } = options;
  const qrCode = pdfContent.images.qrCode;

  const {
    firstName,
    lastName,
    gender,
    birthAt,
    shareToken,
    mrn
  } = patient.attributes;

  const age = ageStringFromPatient(birthAt);
  const dob = birthAt
    ? `DOB: ${new moment(birthAt).utc().format("MMM D, YYYY")}`
    : "DOB: Unknown";

  const brandedCodeLabel =
    branding === "medline" ? medlineCopy.codeLabel : defaultCopy.codeLabel;

  const assessmentId = get(assessmentAnswer, "attributes.assessmentId");
  const schemaLocalizationDictionaryForRevision =
    schemaLocalizationDictionary[assessmentId];

  return {
    stack: [
      {
        columns: [
          {
            stack: [
              {
                image: qrCode,
                width: 100
              }
            ],
            width: "auto"
          },
          {
            stack: [
              {
                text: `${firstName} ${lastName}`,
                style: "h2"
              },
              {
                text: `${age}, ${gender ? gender : "Gender Unknown"}`
              },
              {
                text: dob
              },
              {
                text: `MRN: ${mrn ? mrn : "N/A"}`
              },
              {
                text: brandedCodeLabel,
                bold: true,
                margin: [0, 0, 0, 0]
              },
              {
                text: shareToken,
                bold: true
              }
            ],
            width: 190
          },
          {
            ...(patientLastFacility && {
              stack: getPDFAddressStack(getAddressObject(patientLastFacility)),
              width: 220
            })
          },
          {
            stack: [
              getWoundHeader(
                pdfContent,
                {
                  ...options,
                  schemaLocalizationDictionary: schemaLocalizationDictionaryForRevision,
                  subtypeKeysForSchema
                },
                true
              )
            ]
          }
        ],
        columnGap: 20
      },
      //horizontal line
      {
        canvas: [
          {
            type: "line",
            color: "#a79f9e",
            x1: 0,
            y1: 5,
            x2: 750,
            y2: 5,
            lineWidth: 1
          }
        ]
      },
      {
        stack: [
          {
            text:
              "Note: this document is a summary of the wound evaluations. Refer to the medical record for the full evaluation",
            fontSize: 10,
            margin: [10, 20, 0, 15]
          }
        ]
      }
    ],
    // Left, top, right, bottom
    margin: [0, 20, 0, 15]
  };
}

const getAddressObject = facility => {
  if (!facility) return;

  const addressFields = [
    "name",
    "address1",
    "address2",
    "city",
    "provinceCode",
    "postalCode",
    "phone",
    "fax",
    "email"
  ];

  const address = addressFields.reduce((agg, field) => {
    agg[field] = get(facility, `attributes.${field}`, "");
    agg[field] = agg[field] === null ? "" : agg[field];
    return agg;
  }, {});

  return address;
};

const getPDFAddressStack = address => {
  let stack = [];
  const facilityName = address.name;
  const {
    address1,
    address2,
    city,
    provinceCode,
    postalCode,
    phone,
    fax,
    email
  } = address;

  if (facilityName) {
    stack.push({
      text: `${facilityName} ${fax ? `| Fax: ${fax}` : ""}`,
      style: "h3"
    });
  }

  if (address1) {
    stack.push({
      text: `${address1}${address2 ? `, ${address2}` : ""} `
    });
  }

  if (city) {
    stack.push({
      text: `${city}, ${provinceCode} ${postalCode}`
    });
  }

  if (phone) {
    stack.push({
      text: `Phone: ${phone}`
    });
  }

  if (email) {
    stack.push({
      text: `Email: ${email}`
    });
  }

  return stack;
};
