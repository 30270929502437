import { createSelector } from "reselect";
import { dataSelector } from "../../data_selector";

/**
 * Selector for the tallies object in the data tree in the store
 */
export const surveyWoundDetailsSelector = createSelector(
  dataSelector,
  data => data.surveyWoundDetails
);
