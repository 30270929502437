import { getHeaderV2 } from "../get_header_v2";
import { getFooterV2 } from "../get_footer_v2";
import { getPdfOptionsV2 } from "../get_pdf_options_v2";
import { getBody } from "./get_body";

export function createSurveyDetailsPdf(
  globalContent,
  options,
  footerData,
  woundDetailsData
) {
  return {
    header: getHeaderV2(globalContent, { ...options, landscape: true }),
    footer: getFooterV2(footerData),
    content: getBody({
      woundDetailsData,
      options
    }),
    ...getPdfOptionsV2({ landscape: true })
  };
}
