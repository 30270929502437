import { createSelector } from "reselect";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api query for a single revision
 * @param {Object} props
 * @param {string} props.revisionId
 * @returns {function} selector
 */
export function createModalRevisionQuerySelector({ revisionId }) {
  return createSelector(() => {
    return buildQueryString({
      filter: {
        id: revisionId
      },
      include: [
        "series",
        "series.study",
        "series.study.patient",
        "payer_revisions"
      ]
    });
  });
}
