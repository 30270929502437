// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { floorsApi } from "src/api/floors";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

export const floorsResourceActions = createResourceActions(
  resourceNames.floors
);

export const loadFloorsAction = createLoadDataAction({
  resourceName: resourceNames.floors,
  api: floorsApi
});
