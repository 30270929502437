import get from "lodash.get";
import { createSelector } from "reselect";

//Selectors
import {
  patientsResourceSelectors,
  usersResourceSelectors
} from "src/selectors/data_selectors";

export function createDataForPatientUsersandPatients(context) {
  return createSelector(
    patientsResourceSelectors.createDataForContextSelectorWithString(context),
    usersResourceSelectors.dataSelector,
    (patients, users) => {
      let patientsDeepClone = JSON.parse(JSON.stringify(patients));
      return patientsDeepClone.map(patient => {
        let patientUserId = get(patient, "attributes.patientUserId", "");
        if (patientUserId) {
          let user = users[patientUserId],
            status = get(user, "attributes.status", null);
          patient.attributes.monitorAtHomeStatus = status || null;
        }
        return patient;
      });
    }
  );
}
