export const allowedSubtypes = [
  "bruise",
  "burn",
  "colostomy",
  "dermCondition",		
  "diabetic",
  "fistula",
  "illeostomy",		
  "masd",
  "pressureDeviceRelated",
  "pressureKennedy",
  "pressureUlcer",
  "skinGraft",
  "skinTear",
  "stoma",	
  "surgical",
  "hSuppurtiva",
  "urostomy",
  "softTissueNecrosis"
];
