/**
 * Get location ids from a filters array
 * @param {Array} filters - the filters
 * @returns {Array} - location ids
 */
export function locationIdsFromFilters(filters) {
  const locationFilter = filters.find(filter => filter.key == "locations");

  if (locationFilter && locationFilter.value.length) {
    return locationFilter.value.map(filt => filt.value);
  } else {
    return [];
  }
}
