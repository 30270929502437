import { dataActionTypes } from "src/action_types/data_action_types";
import { fetchPopulationTally } from "src/api/population";
import { trackError } from "src/error_tracking";

/**
 * Action creator for setting loading state of tallies
 */
function populationTallyByLocationLoading() {
  return {
    type: dataActionTypes.populationTallyByLocationLoading
  };
}

/**
 * Action creator for setting loading state of the tallies
 */
function populationTallyByLocationLoadError() {
  return {
    type: dataActionTypes.populationTallyByLocationLoadError
  };
}

/**
 * Action creator for populating tallies in the store
 * @param {Array} tallies array of tallies
 * @return {Object} redux action
 */
function populateTalliesByLocation(tallies) {
  return {
    type: dataActionTypes.populatePopulationTalliesByLocation,
    payload: tallies
  };
}

/**
 * Action creator to fetch population tallies
 */
export function loadPopulationTalliesByLocationAction(payload) {
  return dispatch => {
    dispatch(populationTallyByLocationLoading());
    return fetchPopulationTally(payload)
      .then(response => {
        dispatch(populateTalliesByLocation(response.data));
      })
      .catch(error => {
        // If the request was cancelled it means a new request started
        // don't show the loading view.
        if (error.message == "cancel") {
          return;
        }
        trackError(error);
        dispatch(populationTallyByLocationLoadError());
      });
  };
}
