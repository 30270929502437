import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "react-datepicker";

const format = "MMMM D, YYYY";

export class DatePickerComponent extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    if (!props.selected) {
      props.onChange(moment().toISOString());
    }
  }

  handleDateChange = date => {
    if (date) {
      this.props.onChange(date.toISOString());
    }
  };

  render() {
    return (
      <DatePicker
        dateFormat={format}
        {...this.props}
        onChange={this.handleDateChange}
        className="form-control"
      />
    );
  }
}
