import moment from "moment";

/**
 * Get age string from patient
 * @param {string} date
 * @returns {string} the age dislay string
 */
export function ageStringFromPatient(birthAt) {
  const birthDate = birthAt;
  let ageString = "Age Unknown";
  if (birthDate) {
    let diffDuration = moment.duration(moment().diff(moment(birthDate)));
    const ageConst = Math.trunc(
      moment().diff(moment(birthDate), "months", true)
    );

    switch (true) {
      case ageConst < 1:
        ageString = `${diffDuration.weeks()} week(s) old`;
        break;
      case ageConst >= 1 && ageConst < 12:
        ageString = `${diffDuration.months()} month(s) old`;
        break;
      case ageConst == 12:
        ageString = `1 yr old`;
        break;
      case ageConst > 12 && ageConst < 24:
        ageString = `${diffDuration.years()} yr (${ageConst} months) old`;
        break;
      default:
        ageString = `${diffDuration.years()} yrs old`;
    }
  }
  return ageString;
}
