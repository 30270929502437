import { createSelector } from "reselect";

//Constants
import { apiPageLimits } from "src/constants";

//Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * Create a selector to get the api query for the first page - loading user alerts with a patientId
 * @param {Object} props
 * @param {string} props.patientId
 * @returns {function} selector
 */
export function createFirstPageAlertsForPatientIdQuerySelector({ patientId }) {
  return createSelector(() => {
    return buildQueryString({
      filter: {
        patient_id: patientId,
        non_resolved_only: "true"
      },
      include: "patient,tripped_by_user",
      page: {
        offset: 0,
        limit: apiPageLimits.patientAlerts
      },
      sort: "-created_at"
    });
  });
}
