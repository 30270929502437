import fetch from "isomorphic-fetch";
import get from "lodash.get";
import { accessTokenSelector } from "src/selectors/session_selectors";
import i18n from "src/translations";

import { push } from "react-router-redux";

import createFlashMessage from "app/flash_messages/create";

import { signOut } from "src/actions/session_actions";
import { getQuery } from "src/selectors/router_selectors";
import { apiHostSelector } from "src/selectors/session_selectors";

import { storeCurrentUserEmailAction } from "src/actions/section_actions/otp_verification";
import { expireCookieValueForKey } from "src/client_storage";

const defaultHeaders = {
  Accept: "application/json"
};

const defaultOptions = {
  credentials: "include",
  mode: "cors"
};

const expireSession = async dispatch => {
  dispatch(
    createFlashMessage(
      "Your session has expired. Please sign in again.",
      "error"
    )
  );

  dispatch(signOut());
  dispatch(push("/sign_in"));
};

const isAbsoluteUrl = url => {
  return Boolean(url.match(/https?:\/\//));
};

const apiFetch = (url, options) => {
  return async (dispatch, getState) => {
    try {
      const { body, noToken } = options;
      const state = getState();

      const urlWithApiHost = isAbsoluteUrl(url)
        ? url
        : `${apiHostSelector(state) || ""}${url}`;

      const optionsWithDefaults = {
        ...options,
        ...defaultOptions,
        headers: { ...options.headers, ...defaultHeaders }
      };

      if (body && body.constructor.name != "FormData") {
        optionsWithDefaults.headers["Content-Type"] = "application/json";
        optionsWithDefaults.body = JSON.stringify(body);
      }

      if (!noToken) {
        const accessToken =
          accessTokenSelector(state) || getQuery("access_token")(state);
        optionsWithDefaults.headers.Authorization = `Bearer ${accessToken}`;
      }

      const response = await fetch(urlWithApiHost, optionsWithDefaults);
      if (response.status === 202) {
        dispatch(
          storeCurrentUserEmailAction.setValue(
            get(response, "data.user_email", null)
          )
        );
        dispatch(push("/verification"));
      } else if (response.status === 401) {
        expireCookieValueForKey("accessToken");
        let responsePromise = response.json();
        responsePromise.then(errorResponse => {
          let errors = errorResponse.errors;
          if (
            errors &&
            errors[0].message !=
              i18n.t(`interaction.errorMessages.loginError.invalidEntry`)
          ) {
            expireSession(dispatch);
          } else {
            dispatch(createFlashMessage(errors[0].message, "error"));
          }
        });
      } else if (
        response &&
        response.status &&
        response.url &&
        response.url.includes("auth.swiftmedical.io") &&
        (response.status.toString().startsWith("4") ||
          response.status.toString().startsWith("5"))
      ) {
        dispatch(
          createFlashMessage(
            i18n.t(`interaction.errorMessages.loginError.genericError`),
            "error"
          )
        );
      }
      return response.json();
    } catch (error) {
      console.error({ error });
    }
  };
};

export default apiFetch;
