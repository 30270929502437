import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

//Components
import { GridRow, GridColumn } from "src/components/grid";

//Styles
import styles from "./style.module.less";

export class FormRangeDropdownsComponent extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    domain: PropTypes.array.isRequired,
    min: PropTypes.string,
    max: PropTypes.string,
    labelKey: PropTypes.string,
    minLabelKey: PropTypes.string,
    maxLabelKey: PropTypes.string,
    minOperator: PropTypes.string,
    maxOperator: PropTypes.string,
    validate: PropTypes.array
  };

  state = {
    max: "",
    min: ""
  };

  componentDidMount() {
    const { min, max } = this.props;
    this.setState({
      min: min ? min : "",
      max: max ? max : ""
    });
  }

  onSelectMin = e => {
    this.setState({
      min: e.target.value
    });
  };

  onSelectMax = e => {
    this.setState({
      max: e.target.value
    });
  };

  renderMinField = ({ input, className, min }) => {
    const { domain } = this.props;
    const { name } = input;

    return (
      <div>
        <select
          {...input}
          className={className}
          value={min}
          onChange={this.onSelectMin}
        >
          {domain.map(val => (
            <option
              className={styles["select-option"]}
              key={`${name}-${val}`}
              value={val}
            >
              {val}
            </option>
          ))}
        </select>
      </div>
    );
  };

  renderMaxField = ({ input, className, max }) => {
    const { domain } = this.props;
    const { name } = input;

    return (
      <div>
        <select
          {...input}
          className={className}
          value={max}
          onChange={this.onSelectMax}
        >
          {domain.map(val => (
            <option
              className={styles["select-option"]}
              key={`${name}-${val}`}
              value={val}
            >
              {val}
            </option>
          ))}
        </select>
      </div>
    );
  };

  render() {
    const {
      name,
      labelText,
      minLabelText,
      maxLabelText,
      minOperator,
      maxOperator,
      validate
    } = this.props;

    const { min, max } = this.state;

    return (
      <GridRow className={styles["margin-bottom5px"]}>
        <GridColumn sm="2">
          <label className={styles["label-text"]}>{labelText}</label>
        </GridColumn>
        <GridColumn sm="5">
          {/* Minimum */}
          {minLabelText && <p>{minLabelText}</p>}
          <Field
            className="form-control"
            name={`${name}-${minOperator}`}
            min={min}
            component={this.renderMinField}
            validate={validate}
          />
        </GridColumn>
        <GridColumn sm="5">
          {/* Maximum */}
          {maxLabelText && <p>{maxLabelText}</p>}
          <Field
            className="form-control"
            name={`${name}-${maxOperator}`}
            max={max}
            component={this.renderMaxField}
            validate={validate}
          />
        </GridColumn>
      </GridRow>
    );
  }
}
