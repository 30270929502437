// @flow
import { generateSchema } from "src/utils/schemaUtils";
import { recordInput } from "src/models/PatientModel";
import assessmentAnswerSchemas from "src/models/AssessmentAnswerModel/relationshipSchemaUtils";
export default generateSchema(
  [
    {
      name: "wounds"
    },
    {
      name: "studies"
    },
    ...assessmentAnswerSchemas
  ],
  { recordInput }
);
