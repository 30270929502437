import { createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { unitsResourceSelectors } from "src/selectors/data_selectors";
import { sortByName } from "src/utils/sort_by_name";

export function createPatientAdtFormUnitOptionsSelector(formName) {
  return createSelector(
    getFormValues(formName),
    unitsResourceSelectors.dataSelector,
    (form, units) => {
      if (form && form["facility"]) {
        const filteredUnits = Object.values(units).filter(
          o => o.attributes.facilityId == form["facility"]
        );

        return sortByName(filteredUnits);
      }

      return [];
    }
  );
}
