import { connect } from "react-redux";

//Component
import { PatientOverviewComponent } from "src/components/patient_overview";

//Selectors
import { patientOverviewTabsToRenderSelector } from "src/selectors/ui_config_selectors/patients/patient_overview";
import { patientsSectionSelector } from "src/selectors/section_selectors/patients";

//UI Processors
import SingleValue from "src/ui_processors/single_value";
const selectedTabSelectors = SingleValue.createSelectors(
  "patientOverviewSelectedTab",
  patientsSectionSelector
);
const selectedTabActions = SingleValue.createActionCreators(
  "patientOverviewSelectedTab"
);

function mapStateToProps(state) {
  return {
    tabs: patientOverviewTabsToRenderSelector(state),
    selectedTab: selectedTabSelectors.fieldSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetTabValueAction(value) {
      dispatch(selectedTabActions.setValue(value));
    }
  };
}

export const PatientOverviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientOverviewComponent);
