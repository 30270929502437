import { woundsActionTypes } from "src/action_types/section_action_types";
import moment from "moment";

const defaultState = moment().endOf("day");

export function selectedDateReducer(state = defaultState, action) {
  switch (action.type) {
    case woundsActionTypes.selectDate:
      return action.payload;
    default:
      return state;
  }
}
