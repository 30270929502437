import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { Modal } from "react-bootstrap";

//Translations
import i18n from "src/translations";

//Constants
import { analyticsEventNames } from "src/constants";

//Formatters
import { cellFormatters } from "../cell_formatters";

//Components
import { TableComponent } from "../table_component";
import { TableNavigatorComponent } from "src/components/tables/table_navigator_component";
import { TablePageLimitDropdownComponent } from "../table_page_limit_dropdown_component";

// Containers
import { RevisionNavigationContainer } from "src/containers/revision_navigation_container";

// Styles
import styles from "./style.module.less";

import { track } from "src/analytics";

/**
 * Table component for user alerts
 */
export class EvaluationListWoundTableComponent extends React.PureComponent {
  static propTypes = {
    assessmentSummaries: PropTypes.array.isRequired,
    pageLimit: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    firstPageContext: PropTypes.string.isRequired,
    sendSetPageLimitAction: PropTypes.func.isRequired,
    sendSetPageAction: PropTypes.func.isRequired,
    totalCount: PropTypes.array.isRequired,
    woundNumbersEnabled: PropTypes.bool.isRequired,
    assessmentVersion: PropTypes.string.isRequired,
    patientLinksEnabled: PropTypes.bool.isRequired
  };

  state = {
    selectedRevisionId: undefined
  };

  setPage = pageNumber => {
    const { firstPageContext, sendSetPageAction } = this.props;
    sendSetPageAction(firstPageContext, pageNumber);
  };

  onClickWoundLink = (e, row, columnName) => {
    let dashboardTab;
    const tabPathname = window.location.pathname;
    if (tabPathname.includes("my-dashboard/evaluation-list")) {
      dashboardTab = "Evaluation List";
    } else if (tabPathname.includes("my-dashboard/alerts")) {
      dashboardTab = "Alerts";
    } else if (tabPathname.includes("my-dashboard/wound-risk")) {
      dashboardTab = "Wound Risk";
    }

    track(analyticsEventNames.onClickTableLinkToRevisionModal, {
      columnName,
      dashboardTab
    });
    this.setState({
      ...this.state,
      selectedRevisionId: get(row, "attributes.revisionId", "")
    });
  };

  hideRevisionImageModal = () => {
    if (this.props.revisionBeingEdited != this.state.selectedRevisionId) {
      this.setState({
        ...this.state,
        selectedRevisionId: false
      });
    }
  };

  render() {
    const {
      assessmentSummaries,
      pageLimit,
      pageCount,
      currentPage,
      sendSetPageLimitAction,
      woundNumbersEnabled,
      schemaLocalizationDictionary,
      assessmentVersion,
      subtypeKeysForSchema,
      patientLinksEnabled
    } = this.props;

    const columns = [
      {
        text: i18n.t("headers.patientName"),
        dataField: "firstName",
        formatter: cellFormatters.patientFirstNameLastNameFormatter,
        formatExtraData: {
          patientLinksEnabled: patientLinksEnabled
        }
      },
      {
        text: i18n.t("headers.mrn"),
        dataField: "attributes.patientMrn",
        formatter: cellFormatters.mrnFormatter
      },
      {
        text: i18n.t("headers.location"),
        dataField: "attributes.facilityName",
        formatter: cellFormatters.patientLocationNamesFormatter
      },
      {
        text: i18n.t("headers.woundNumberTypeSubtype"),
        dataField: "attributes.patientWoundNumber",
        formatter: cellFormatters.woundInfoFormatter,
        formatExtraData: {
          localizationDictionary: schemaLocalizationDictionary,
          subtypeKeysForSchema,
          patientLinksEnabled: patientLinksEnabled,
          eventHandler: this.onClickWoundLink
        },
        hidden: !woundNumbersEnabled
      },
      {
        text: i18n.t("headers.bodyLocation"),
        dataField: "bodyLocation",
        formatter: cellFormatters.bodyLocationFormatter,
        formatExtraData: {
          localizationDictionary: schemaLocalizationDictionary,
          patientLinksEnabled: patientLinksEnabled,
          eventHandler: this.onClickWoundLink
        }
      },
      {
        text: i18n.t("headers.woundRisk"),
        dataField: "attributes.healingIndexMean",
        formatter: cellFormatters.woundRiskFormatter,
        hidden: location.pathname !== "/my-dashboard/wound-risk"
      },
      {
        text: i18n.t("headers.progress"),
        dataField: "progress",
        formatter: cellFormatters.woundProgressFormatter,
        formatExtraData: {
          eventHandler: this.onClickWoundLink,
          columnName: "progress",
          assessmentVersion: assessmentVersion,
          patientLinksEnabled: patientLinksEnabled
        }
      },
      {
        text: i18n.t("Acquired Status"),
        dataField: "attributes.answersJson.inHouseAcquired",
        formatter: cellFormatters.acquiredStatusFormatter
      },
      {
        text: i18n.t("Last Evaluation Date"),
        dataField: "attributes.createdAt",
        formatter: cellFormatters.lastEvaluationDateFormatter,
        formatExtraData: { eventHandler: this.onClickWoundLink }
      },
      {
        text: i18n.t("# of Days since Last Evaluation"),
        dataField: "attributes.createdAt",
        formatter: cellFormatters.daysElapsedFormatter
      }
    ];
    const woundCount = this.props.totalCount ? this.props.totalCount : 0;
    return (
      <div>
        <div className={styles["total-font"]}>
          {woundCount + " Total"}
          <hr />
        </div>
        <TableComponent
          data={assessmentSummaries}
          keyField="id"
          columns={columns}
        />
        <div className="pull-left">
          <TablePageLimitDropdownComponent
            selectPageLimit={sendSetPageLimitAction}
            selectedPageLimit={pageLimit}
          />
        </div>
        <TableNavigatorComponent
          pageNumber={currentPage}
          pageCount={pageCount}
          selectTablePage={this.setPage}
        />
        <Modal
          show={this.state.selectedRevisionId}
          onHide={this.hideRevisionImageModal}
          bsSize={"lg"}
        >
          {this.state.selectedRevisionId && (
            <RevisionNavigationContainer
              revisionId={this.state.selectedRevisionId}
              revision={{ id: this.state.selectedRevisionId }}
              hide={this.hideRevisionImageModal}
              hyperlinkHeadersEnabled={true}
            />
          )}
        </Modal>
      </div>
    );
  }
}
