//Redux
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import { reduxFormNames } from "src/constants";

//Lodash
import flowRight from "lodash.flowright";

//Component
import { WoundAssessmentEditFormComponent } from "src/components/forms/wound_assessment_edit_form_component";

//Actions
import { createAssessmentAnswerAction } from "src/actions/data_actions";

//InitialValues
import { getInitialValuesForAssessmentAnswer } from "./get_initial_values_for_assessment_answer";

// Selectors
import {
  createProgressTabSchemasForAssessmentAnswerSelector,
  createTreatmentTabSchemasForAssessmentAnswerSelector,
  createAttributesTabSchemasForAssessmentAnswerSelector,
  createDescriptionAssessmentSchemaForAssessmentAnswerSelector,
  createQuestionWithVisiblePredicateFromSchemaSelector
} from "src/selectors/data_selectors";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";
import { createRevisionIsFirstInSeriesSelector } from "src/selectors/data_selectors/revisions/create_revision_is_first_in_series_selector";

function mapStateToProps(state, ownProps) {
  const { assessmentAnswer } = ownProps;
  const formValues = getFormValues(reduxFormNames.schemaAnswersForm)(state);
  const initialValues = getInitialValuesForAssessmentAnswer(assessmentAnswer);
  const progressSchemas = createProgressTabSchemasForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const treatmentSchemas = createTreatmentTabSchemasForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const attributesSchemas = createAttributesTabSchemasForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const descriptionSchemas = createDescriptionAssessmentSchemaForAssessmentAnswerSelector(
    assessmentAnswer
  )(state);
  const schemas = descriptionSchemas.concat(
    attributesSchemas,
    treatmentSchemas,
    progressSchemas
  );
  const questionWithVisiblePredicate = createQuestionWithVisiblePredicateFromSchemaSelector(
    assessmentAnswer
  )(state);
  return {
    initialValues: initialValues,
    formValues: formValues,
    schemas: schemas,
    questionWithVisiblePredicate: questionWithVisiblePredicate,
    currentUser: currentUserSelector(state),
    revisionIsFirstInSeries: createRevisionIsFirstInSeriesSelector(
      ownProps.revision
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendCreateAssessmentAnswerAction(payload) {
      return dispatch(createAssessmentAnswerAction({ payload }));
    }
  };
}

export const WoundAssessmentEditFormContainer = flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: reduxFormNames.schemaAnswersForm,
    destroyOnUnmount: true,
    touchOnBlur: false
  })
)(WoundAssessmentEditFormComponent);
