import { connect } from "react-redux";

// Components
import { WoundsChartTabComponent } from "src/components/wounds_chart_tab_component";
import { progressValuesWithoutHealedSelector } from "src/selectors/constant_selectors";
import { selectedDateSelector } from "src/selectors/section_selectors/wounds";
import { isWoundsClinicalChartOptimizationEnabledSelector } from "src/selectors/ui_config_selectors/wounds";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

function mapStateToProps(state) {
  const progressValues = progressValuesWithoutHealedSelector(state);
  return {
    progressValues,
    assessmentVersion: assessmentVersionSelector(state),
    selectedDate: selectedDateSelector(state),
    isWoundsClinicalChartOptimizationEnabled: isWoundsClinicalChartOptimizationEnabledSelector(
      state
    )
  };
}

export const WoundsChartTabContainer = connect(mapStateToProps)(
  WoundsChartTabComponent
);
