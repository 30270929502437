// @flow
import { generateSchema } from "src/utils/schemaUtils";
import { recordInput } from "src/models/SeriesModel";
export default generateSchema(
  [
    {
      name: "assessmentAnswers"
    },
    {
      name: "seriesTrends"
    }
  ],
  {
    recordInput
  }
);
