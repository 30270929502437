import { apiPageLimits } from "src/constants";

/**
 * Create the payload for GETing saved filters
 * @param {Object} options
 * @param {string} options.section
 * @param {number} options.pageNumber
 */
export function createGetPayload({ section, pageNumber }) {
  return {
    filter: {
      dashboard: section
    },
    sort: "title",
    page: {
      offset: pageNumber * apiPageLimits.savedFilters,
      limit: apiPageLimits.savedFilters
    }
  };
}
