import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { assessmentsResourceSelectors } from "../../assessments/assessments_resource_selectors";
import { assessmentSchemasResourceSelectors } from "../assessment_schemas_resource_selectors";
import { createLatestAssessmentSchemasForRevisionsSelector } from "../create_latest_assessment_schemas_for_revisions_selector";

// Constants
import { woundSchemaTabValues } from "src/constants";

/**
 * Create a selector that gets the schemas for each revision - by tab.
 * @param {Object} props
 * @param {array} props.revisions - revisions
 * @returns {Function} selector
 */
export function createSchemaByTabForRevisionsSelector({ revisions }) {
  return createSelector(
    assessmentsResourceSelectors.dataSelector,
    assessmentSchemasResourceSelectors.dataSelector,
    createLatestAssessmentSchemasForRevisionsSelector({ revisions }),
    (assessments, allSchemas, schemasByRevision) => {
      return Object.keys(schemasByRevision).reduce((agg, revId) => {
        const rootSchema = schemasByRevision[revId];
        const components = get(
          rootSchema,
          "attributes.schemaJson.components",
          {}
        );

        // Get schemas for each tab
        const schemasByTab = Object.keys(woundSchemaTabValues).reduce(
          (agg, tab) => {
            const schemaTypes = woundSchemaTabValues[tab];

            // Get schema for each schema type in the tab
            const schemas = schemaTypes.reduce((agg, schemaType) => {
              const assessmentId = components[schemaType];
              const assessment = assessments[assessmentId];
              if (assessment) {
                const schemaId = get(
                  assessment,
                  "relationships.assessmentSchema.data.id",
                  ""
                );
                const schema = allSchemas[schemaId];
                if (schema) agg.push(allSchemas[schemaId]);
              }
              return agg;
            }, []);

            agg[tab] = schemas;
            return agg;
          },
          {}
        );

        agg[revId] = schemasByTab;
        return agg;
      }, {});
    }
  );
}
