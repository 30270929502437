import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import i18n from "src/translations";
import { Modal, ModalBody } from "react-bootstrap";

// Components
import { GridRow, GridColumn } from "src/components/grid";
import EncounterModalComponent from "src/components/encounters/encounter_modal_component.js";
import {
  PanelComponent,
  PanelBodyComponent,
  PanelHeaderRightComponent
} from "src/components/panel";
import { ButtonComponent } from "src/components/button_component";

// Containers
import { RevisionWoundImageContainer } from "src/containers/revision_wound_image_container";
import { RevisionWoundImageHeaderContainer } from "src/containers/revision_wound_image_header_container";
import { RevisionWoundDetailsContainer } from "src/containers/revision_wound_details_container";
import { SignAssessmentAnswerFormContainer } from "src/containers/forms/sign_assessment_answer_form_container";

//Selectors
import { containsRequiredFieldsForAssessmentAnswers } from "src/utils/required_fields";

export class RevisionsListItemComponent extends React.PureComponent {
  static propTypes = {
    revision: PropTypes.object.isRequired,
    isStruckOut: PropTypes.bool.isRequired,
    sendLockAssessmentAnswerAction: PropTypes.func.isRequired,
    sendUnlockAssessmentAnswerAction: PropTypes.func.isRequired,
    putLoadState: PropTypes.object.isRequired,
    deleteLoadStateWithStatusById: PropTypes.object.isRequired,
    isESignatureEnabled: PropTypes.bool.isRequired,
    sendSignAssessmentAnswerAction: PropTypes.func.isRequired,
    sendSignAndLockAssessmentAnswersAction: PropTypes.func.isRequired,
    sendReopenAssessmentAnswerAction: PropTypes.func.isRequired,
    encounterNamingConvention: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.setCurrentFrameTab = this.setCurrentFrameTab.bind(this);
  }

  state = {
    showESignature: false,
    showEncounterView: false,
    action: null,
    currentFrameTab: ""
  };

  setCurrentFrameTab(newFrame) {
    this.setState({ currentFrameTab: newFrame });
  }

  onClickLock = () => {
    const {
      revision,
      sendLockAssessmentAnswerAction,
      sendUnlockAssessmentAnswerAction
    } = this.props;

    const lockState = get(
      revision,
      "attributes.assessmentAnswers[0].lockState",
      null
    );

    const assessmentAnswerId = get(
      revision,
      "attributes.assessmentAnswers[0].id",
      null
    );

    if (!lockState || !assessmentAnswerId) {
      return;
    } else if (lockState === "locked") {
      sendUnlockAssessmentAnswerAction({
        id: assessmentAnswerId,
        data: {
          id: assessmentAnswerId
        }
      });
    } else {
      sendLockAssessmentAnswerAction({
        id: assessmentAnswerId,
        data: {
          id: assessmentAnswerId
        }
      });
    }
  };

  onClickSignButton = () => {
    const {
      isESignatureEnabled,
      sendSignAssessmentAnswerAction,
      revision,
      encounterNamingConvention,
      assessmentAnswer
    } = this.props;
    const assessmentAnswerId = get(
      revision,
      "attributes.assessmentAnswers[0].id",
      null
    );
    let encounterNumber = get(
      assessmentAnswer,
      "attributes.encounterNumber",
      null
    );

    if (encounterNamingConvention !== "None" && !encounterNumber) {
      this.setState({
        ...this.state,
        showEncounterView: true,
        action: "sign"
      });
    } else if (isESignatureEnabled) {
      this.setState({
        ...this.state,
        showESignature: true,
        action: "sign"
      });
    } else {
      sendSignAssessmentAnswerAction({
        id: assessmentAnswerId,
        data: {
          id: assessmentAnswerId
        }
      });
    }
  };

  onClickSignAndLockButton = () => {
    const {
      sendSignAndLockAssessmentAnswersAction,
      revision,
      isESignatureEnabled,
      encounterNamingConvention,
      assessmentAnswer
    } = this.props;

    const assessmentAnswerId = get(
      revision,
      "attributes.assessmentAnswers[0].id",
      null
    );
    let encounterNumber = get(
      assessmentAnswer,
      "attributes.encounterNumber",
      null
    );

    if (encounterNamingConvention !== "None" && !encounterNumber) {
      this.setState({
        ...this.state,
        showEncounterView: true,
        action: "signAndLock"
      });
    } else if (isESignatureEnabled) {
      this.setState({
        ...this.state,
        showESignature: true,
        action: "signAndLock"
      });
    } else {
      sendSignAndLockAssessmentAnswersAction({
        id: assessmentAnswerId,
        data: {
          id: assessmentAnswerId
        }
      });
    }
  };

  onClickReopenButton = () => {
    const { sendReopenAssessmentAnswerAction, revision } = this.props;
    const assessmentAnswerId = get(
      revision,
      "attributes.assessmentAnswers[0].id",
      null
    );

    sendReopenAssessmentAnswerAction({
      id: assessmentAnswerId,
      data: {
        id: assessmentAnswerId
      }
    });
  };

  hideESignature = () => {
    this.setState({
      ...this.state,
      showESignature: false,
      action: ""
    });
  };

  hideEncounterView = () => {
    this.setState({
      ...this.state,
      showEncounterView: false,
      action: ""
    });
  };

  hideModal = () => {
    this.setState({
      ...this.state,
      showESignature: false,
      showEncounterView: false,
      action: ""
    });
  };

  modalPopUp = () => {
    const { showEncounterView, action } = this.state;
    const { assessmentAnswer, revision } = this.props;
    if (showEncounterView) {
      return (
        <EncounterModalComponent
          cancel={this.hideEncounterView}
          assessmentAnswerId={assessmentAnswer.id}
          actionType={i18n.t(`actions.${action}`)}
          patientId={get(assessmentAnswer, "attributes.patientId", null)}
          revisionCreatedAt={get(revision, "attributes.createdAt", null)}
        />
      );
    } else {
      return (
        <SignAssessmentAnswerFormContainer
          cancel={this.hideESignature}
          assessmentAnswerId={assessmentAnswer.id}
          actionType={i18n.t(`actions.${action}`)}
        />
      );
    }
  };

  getActionButtons = () => {
    const {
      canLockWoundRevisions,
      canSignWoundRevisions,
      canReopenWoundRevisions,
      canUnlockWoundRevisions,
      canEditOtherUsersWork,
      putLoadState,
      deleteLoadStateWithStatusById,
      currentUser,
      showLockWoundRevisionButton,
      showSignWoundRevisionButton,
      assessmentAnswer,
      revision,
      revisionIsFirstInSeries,
      schemas,
      signTooltipText,
      signAndLockTooltipText
    } = this.props;

    if (!revision || !assessmentAnswer) return [];

    const assessmentAnswerAnswersJson = assessmentAnswer.attributes.answersJson;
    const revisionAuthorId = get(revision, "attributes.userId", null);
    const isRevisionAuthor =
      revisionAuthorId && revisionAuthorId === currentUser.id;
    const lockState = get(assessmentAnswer, "attributes.lockState", null);
    const signState = get(assessmentAnswer, "attributes.signState", null);

    const missingPermissionsText = i18n.t(
      "text.revisionButtonTooltips.missingPermissions"
    );

    const signObj = {
      isVisible:
        signState === "unsigned" &&
        lockState === "unlocked" &&
        showSignWoundRevisionButton,
      onClick: this.onClickSignButton,
      isDisabled:
        (!canEditOtherUsersWork && !isRevisionAuthor) ||
        !containsRequiredFieldsForAssessmentAnswers(
          schemas,
          assessmentAnswerAnswersJson,
          revisionIsFirstInSeries
        ) ||
        !canSignWoundRevisions ||
        putLoadState[assessmentAnswer.id] === "loading",
      text: i18n.t("actions.sign"),
      tooltipText: signTooltipText
    };

    const lockObj = {
      isVisible:
        signState === "signed" &&
        lockState === "unlocked" &&
        showLockWoundRevisionButton,
      onClick: this.onClickLock,
      isDisabled:
        (!canEditOtherUsersWork && !isRevisionAuthor) ||
        !canLockWoundRevisions ||
        putLoadState[assessmentAnswer.id] === "loading",
      text: i18n.t("actions.lock"),
      tooltipText: !canLockWoundRevisions ? missingPermissionsText : null
    };

    const unlockObj = {
      isVisible:
        signState === "signed" &&
        lockState === "locked" &&
        showLockWoundRevisionButton,
      onClick: this.onClickLock,
      isDisabled:
        (!canEditOtherUsersWork && !isRevisionAuthor) ||
        !canUnlockWoundRevisions ||
        deleteLoadStateWithStatusById[assessmentAnswer.id] === "loading" ||
        deleteLoadStateWithStatusById[assessmentAnswer.id] === "loaded",
      text: i18n.t("actions.unlock"),
      tooltipText: !canLockWoundRevisions ? missingPermissionsText : null
    };

    const signAndLockObj = {
      isVisible: signState === "unsigned" && lockState === "unlocked",
      onClick: this.onClickSignAndLockButton,
      isDisabled:
        (!canEditOtherUsersWork && !isRevisionAuthor) ||
        !containsRequiredFieldsForAssessmentAnswers(
          schemas,
          assessmentAnswerAnswersJson,
          revisionIsFirstInSeries
        ) ||
        !canLockWoundRevisions ||
        !canSignWoundRevisions ||
        putLoadState[assessmentAnswer.id] === "loading" ||
        deleteLoadStateWithStatusById[assessmentAnswer.id] === "loading" ||
        deleteLoadStateWithStatusById[assessmentAnswer.id] === "loaded",
      text: i18n.t("actions.signAndLock"),
      tooltipText: signAndLockTooltipText
    };

    const reopenObj = {
      isVisible:
        signState === "signed" &&
        lockState === "unlocked" &&
        showSignWoundRevisionButton,
      onClick: this.onClickReopenButton,
      isDisabled:
        (!canEditOtherUsersWork && !isRevisionAuthor) ||
        !canReopenWoundRevisions ||
        deleteLoadStateWithStatusById[assessmentAnswer.id] === "loading" ||
        deleteLoadStateWithStatusById[assessmentAnswer.id] === "loaded",
      text: i18n.t("actions.reopen"),
      tooltipText: !canSignWoundRevisions ? missingPermissionsText : null
    };

    return [signObj, reopenObj, signAndLockObj, lockObj, unlockObj];
  };

  render() {
    const {
      revision,
      isStruckOut,
      showLockWoundRevisionButton,
      showSignWoundRevisionButton
    } = this.props;

    if (isStruckOut) {
      return null;
    }

    let content = (
      <GridRow>
        <GridColumn lg="6" xl="7">
          <RevisionWoundImageHeaderContainer revision={revision} />
          <RevisionWoundImageContainer
            revision={revision}
            currentFrameTab={this.props.currentFrameTab}
            setCurrentFrameTab={this.setCurrentFrameTab}
            isFromList={true}
          />
        </GridColumn>
        <GridColumn lg="6" xl="5">
          <RevisionWoundDetailsContainer revision={revision} />
        </GridColumn>
      </GridRow>
    );
    const actions = this.getActionButtons();

    if (!showLockWoundRevisionButton && !showSignWoundRevisionButton) {
      return <li>{content}</li>;
    }

    return (
      <li>
        <PanelComponent>
          <PanelHeaderRightComponent labelShow={false}>
            {actions.map(action => {
              if (action.isVisible) {
                return (
                  <ButtonComponent
                    onClick={action.onClick}
                    disabled={action.isDisabled}
                    tooltip={action.tooltipText}
                  >
                    {action.text}
                  </ButtonComponent>
                );
              }
            })}
          </PanelHeaderRightComponent>
          <PanelBodyComponent>{content}</PanelBodyComponent>
        </PanelComponent>
        <Modal
          show={this.state.showESignature || this.state.showEncounterView}
          onHide={this.hideModal}
        >
          <ModalBody>{this.modalPopUp()}</ModalBody>
        </Modal>
      </li>
    );
  }
}
