import get from "lodash.get";
import moment from "moment";
import i18n from "src/translations";

const encounterStartDateFormatter = (
  encounter,
  encounterFieldsShown,
  isFloatingVisit
) => {
  if (encounterFieldsShown.startDate) {
    const encounterStartDate = get(encounter, "attributes.startAt", null);

    const formatString =
      encounterFieldsShown.startTime && !isFloatingVisit
        ? "DD MMM YYYY, h:mm a"
        : "DD MMM YYYY";
    const formattedStartDate = encounterStartDate
      ? moment(encounterStartDate).format(formatString)
      : "N/A";

    return `${i18n.t(
      "data.encounters.startDateString"
    )}: ${formattedStartDate}`;
  }
  return null;
};

export default encounterStartDateFormatter;
