import React from "react";
import { Link } from "react-router-dom";
import get from "lodash.get";

export function mrnFormatter(cell, row) {
  const mrn = cell;
  let patientId;
  if (row.type === "patients") {
    patientId = row.id;
  } else {
    patientId = get(row, "attributes.patientId");
  }

  if (!mrn) return "";

  if (patientId) {
    return <Link to={`/patients/${patientId}`}>{mrn}</Link>;
  } else {
    return mrn;
  }
}
