// @flow
import { Record } from "immutable";

export const recordInput = {
  address1: "",
  address2: "",
  city: "",
  country: "",
  depth: 0,
  id: "",
  leaf_node: false,
  name: "",
  organizaton: {},
  parent: "",
  phone_number: "",
  postal_code: "",
  provice: "",
  tags: []
};
export default class LocationModel extends Record(recordInput) {}
