/**
 * Create the payload for GETing a single revision
 * @param {Object} options
 * @param {string} options.revisionId
 */
export function createGetPayload({ revisionId }) {
  return {
    filter: {
      id: revisionId
    }
  };
}
