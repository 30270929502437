import { acquired } from "./acquired";
import { admissionStatus } from "./admission_status";
import { evaluationStatus } from "./evaluation_status";
import { evaluations } from "./evaluations";
import { evaluationType } from "./evaluation_type";
import { eventTypes } from "./event_types";
import { exportNames } from "./export_names";
import { fileExportStates } from "./file_export_states";
import { historyOrCause } from "./history_or_cause";
import { hprScoreLabels } from "./hpr_score_labels";
import { lockedState } from "./locked_state";
import { monitorAtHomeStatus } from "./monitor_at_home_status";
import { overallProgress } from "./overall_progress";
import { qaNotes } from "./qa_notes";
import { ruleTemplateNamesByName } from "./rule_template_names_by_name";
import { signedState } from "./signed_state";
import { surveyOptions } from "./survey_options";
import { userStates } from "./user_states";
import { woundAge } from "./wound_age";
import { woundTypes } from "./wound_types";
import { rulesEvaluationTypes } from "./rules_evaluation_types";
import { encounters } from "./encounters";

export const data = {
  acquired,
  admissionStatus,
  evaluationStatus,
  evaluations,
  evaluationType,
  eventTypes,
  exportNames,
  fileExportStates,
  historyOrCause,
  hprScoreLabels,
  lockedState,
  monitorAtHomeStatus,
  overallProgress,
  qaNotes,
  ruleTemplateNamesByName,
  signedState,
  surveyOptions,
  userStates,
  woundAge,
  woundTypes,
  rulesEvaluationTypes,
  encounters
};
