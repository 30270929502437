import { connect } from "react-redux";
import flowRight from "lodash.flowright";

import { WoundsWoundTabPDFButtonComponent } from "src/components/wounds_wound_tab_pdf_button_component";

// Selectors
import { selectedDateSelector } from "src/selectors/section_selectors/wounds";
import { selectedOrganizationSelector } from "src/selectors/data_selectors";
import { woundAssessmentProgressChartDefinitionSelector } from "src/selectors/chart_definition_selectors";
import { woundAssessmentsResourceSelectors } from "src/selectors/data_selectors";
import { progressValuesWithoutHealedSelector } from "src/selectors/constant_selectors";
import {
  brandingSelector,
  brandedLogoPngSelector
} from "src/selectors/ui_config_selectors/global";

// HOCs
import { woundAssessmentProgressChartDataLoadStateSelector } from "src/selectors/data_selectors";

//Query Payload and Context Selectors
import { createAssessmentsFirstPageContextSelectorWithProgress } from "src/selectors/context_selectors/wounds";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

function mapStateToProps(state) {
  const progressValues = progressValuesWithoutHealedSelector(state);
  const firstPageContextsLoadStates = {};
  progressValues.forEach(progress => {
    const context = createAssessmentsFirstPageContextSelectorWithProgress(
      progress
    )(state);
    firstPageContextsLoadStates[
      progress
    ] = woundAssessmentsResourceSelectors.createLoadStateForContextSelector(
      context
    )(state);
  });

  const woundAssessmentProgressChartLoadState = woundAssessmentProgressChartDataLoadStateSelector(
    state
  );

  return {
    selectedDate: selectedDateSelector(state),
    selectedOrganization: selectedOrganizationSelector(state),
    chartDefinition: woundAssessmentProgressChartDefinitionSelector(state),
    progressValues: progressValuesWithoutHealedSelector(state),
    chartLoadStates: [woundAssessmentProgressChartLoadState],
    branding: brandingSelector(state),
    brandedLogoPng: brandedLogoPngSelector(state),
    assessmentVersion: assessmentVersionSelector(state)
  };
}

export const WoundsWoundTabPdfButtonContainer = flowRight(
  connect(mapStateToProps)
)(WoundsWoundTabPDFButtonComponent);
