import PropTypes from "prop-types";
import React from "react";
import BradenRiskContainer from "src/containers/risk/Braden";
import NortonRiskContainer from "src/containers/risk/NortonPlus";

export class RiskDefaultComponent extends React.Component {
  static propTypes = {
    defaultAssessmentType: PropTypes.string.isRequired
  };

  render() {
    let riskComponent;
    if (this.props.defaultAssessmentType == "norton_plus") {
      riskComponent = <NortonRiskContainer />;
    } else {
      riskComponent = <BradenRiskContainer />;
    }

    return <div>{riskComponent}</div>;
  }
}
