import React from "react";
import get from "lodash.get";

export function assessmentLockFormatter(cell, row, rowIndex, formatExtraData) {
  const userId = get(row, "attributes.userId", "");
  const currentUser = get(formatExtraData, "currentUser", "");
  const canEditOtherUsersWork = get(
    formatExtraData,
    "canEditOtherUsersWork",
    ""
  );
  let checkBoxDisabled = null;
  if (canEditOtherUsersWork) checkBoxDisabled = false;
  else if (userId == currentUser.id) checkBoxDisabled = false;
  else checkBoxDisabled = true;
  return (
    <input
      type="checkbox"
      value={row}
      checked={formatExtraData.checkedAssessmentSummaries.includes(row)}
      onChange={e =>
        formatExtraData.eventHandler(e, row, formatExtraData.columnName)
      }
      disabled={checkBoxDisabled}
      ref={el => (this.el = el)}
    />
  );
}
