import React, { PureComponent } from "react";
import { Chart } from "@sw/ui-kit";

export default class BwatChart extends PureComponent {
  render() {
    if (this.props.data._map.size != 0) {
      this.props.data._map._root.entries[1][1]._tail.array.forEach(
        (element, index) => {
          if (element == -1)
            this.props.data._map._root.entries[1][1]._tail.array[index] = "INC";
        }
      );
    }
    return <Chart data={this.props.data} beginAnywhere min={0} max={70} />;
  }
}
