import { createSelector } from "reselect";

// Selectors
import { createAllPagesCliniciansPayloadSelector } from "../create_all_pages_clinicians_payload_selector";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api context for the all pages for clinicians (users with has_created_assessment=true)
 * @param {Object} props
 * @param {string} props.section
 * @returns {function} selector
 */
export function createAllPagesCliniciansContextSelector() {
  return createSelector(
    createAllPagesCliniciansPayloadSelector(),
    payloads => {
      return payloads.map(buildQueryString);
    }
  );
}
