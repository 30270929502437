import { camelizeKeys } from "humps";

import { createResourceActions } from "src/redux_resources_v1";
// Error tracking
import { trackError } from "src/error_tracking";
// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

export function createSignAndLockAssessmentAnswerAction({
  resourceName,
  signApi,
  lockApi,
  onSuccess,
  onError,
  responseNormalizer
}) {
  const analyticsEventName = `API_PUT_${resourceName.toUpperCase()}`;
  const resourceActions = createResourceActions(resourceName);
  return ({ payload }) => {
    return dispatch => {
      dispatch(resourceActions.loadingPutAction(payload.id));
      startTimeTrack(analyticsEventName);
      return signApi
        .put({ payload })
        .then(response => {
          finishTimeTrack(analyticsEventName);
          let data, normalizedData;
          // Populate assessment answer
          if (response.data && responseNormalizer) {
            normalizedData = responseNormalizer(response.data);
          } else {
            data = camelizeKeys(response.data, (key, convert, options) => {
              return /-/.test(key) ? key : convert(key, options);
            });
            normalizedData = {
              type: resourceName,
              id: data.id,
              attributes: {
                ...data
              }
            };
          }
          dispatch(
            resourceActions.populateAction({
              data: normalizedData
            })
          );

          const lockPayload = {
            id: response.data.id,
            data: {
              id: response.data.id
            }
          };
          startTimeTrack(analyticsEventName);
          dispatch(resourceActions.loadingPutAction(payload.id));
          lockApi
            .put({ payload: lockPayload })
            .then(response => {
              let data, normalizedData;
              // Populate assessment answer
              if (response.data && responseNormalizer) {
                normalizedData = responseNormalizer(response.data);
              } else {
                data = camelizeKeys(response.data, (key, convert, options) => {
                  return /-/.test(key) ? key : convert(key, options);
                });
                normalizedData = {
                  type: resourceName,
                  id: data.id,
                  attributes: {
                    ...data
                  }
                };
              }

              dispatch(
                resourceActions.populateAction({
                  data: normalizedData
                })
              );

              finishTimeTrack(analyticsEventName);
              if (onSuccess) {
                onSuccess(dispatch);
              }
            })
            .catch(err => {
              finishTimeTrack(analyticsEventName, {
                error: true,
                errorCode: err.response ? err.response.status : undefined
              });
              trackError(err);
              dispatch(resourceActions.loadErrorPutAction(payload.id));
              if (onError) {
                onError(dispatch, err, "lock");
              }
            });
        })
        .catch(err => {
          finishTimeTrack(analyticsEventName, {
            error: true,
            errorCode: err.response ? err.response.status : undefined
          });
          dispatch(resourceActions.loadErrorPutAction(payload.id));
          trackError(err);

          if (onError) {
            onError(dispatch, err, "sign");
          }

          return err;
        });
    };
  };
}
