// @flow
import { generateSchema } from "src/utils/schemaUtils";
// $FlowFixMe
export default generateSchema(
  [
    {
      name: "patients",
      alias: "highPatientsNorton"
    },
    {
      name: "patients",
      alias: "mediumPatientsNorton"
    },
    {
      name: "patients",
      alias: "lowPatientsNorton"
    },
    {
      name: "patients",
      alias: "veryHighPatientsBraden"
    },
    {
      name: "patients",
      alias: "highPatientsBraden"
    },
    {
      name: "patients",
      alias: "mediumPatientsBraden"
    },
    {
      name: "patients",
      alias: "lowPatientsBraden"
    },
    {
      name: "patients",
      alias: "noPatientsBraden"
    },
    {
      name: "patients",
      alias: "nonePatientsBraden"
    },
    {
      name: "locations",
      alias: "nortonPlusLocations"
    },
    {
      name: "locations",
      alias: "bradenLocations"
    }
  ],
  {}
);
