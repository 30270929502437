import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";

// Selectors - legacy
import patientTrendSelectors from "@sw/selectors/patientTrendSelectors";

// Actions - legacy
import patientActions from "@sw/actions/patientActions";

export function withPatientTrendsLoader(WrappedComponent) {
  class PatientTrendsLoader extends React.PureComponent {
    componentDidMount() {
      this.loadTrends(this.props.match.params.patientId);
    }

    componentWillReceiveProps(nextProps) {
      if (
        nextProps.match.params &&
        nextProps.match.params.patientId !== this.props.match.params.patientId
      ) {
        this.loadTrends(nextProps.match.params.patientId);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }

    loadTrends(patientId) {
      this.props.loadPatientTrends(patientId);
    }
  }

  const mapStateToProps = (state, ownProps) => {
    const idSelector = () => ownProps.match.params.patientId;
    return createStructuredSelector({
      patientTrend: patientTrendSelectors.findEntity(idSelector)
    });
  };

  const mapDispatchToProps = dispatch => {
    return {
      loadPatientTrends(patientId) {
        dispatch(patientActions.loadTrendsByPatient(patientId));
      }
    };
  };

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(PatientTrendsLoader)
  );
}
