import React from "react";
export class InHouseAcquiredIconComponent extends React.PureComponent {
  render() {
    return (
      <svg
        width="73"
        height="68"
        viewBox="0 0 73 68"
        xmlns="http://www.w3.org/2000/svg"
        {...this.props}
      >
        <path d="M44.593 67.954V54.368c0-1.386-.523-1.906-1.916-1.906h-12.39c-1.343.002-1.9.54-1.903 1.873-.007 4.255-.002 8.51-.003 12.764v.857H12.755c-1.295-.002-1.816-.52-1.817-1.8 0-10.325.003-20.648-.01-30.972 0-.442.126-.738.466-1.034 8.226-7.17 16.444-14.35 24.664-21.526.135-.12.28-.226.464-.372.72.626 1.42 1.237 2.122 1.85 7.653 6.683 15.303 13.37 22.963 20.044.337.293.47.585.47 1.03-.013 10.284-.01 20.568-.01 30.85 0 1.44-.494 1.927-1.944 1.927h-15.53z" />
        <path d="M3.876 35.888l-3.85-4.283c.182-.116.37-.203.52-.334A44379.998 44379.998 0 0 0 35.808.47c.56-.493.81-.504 1.37-.012 11.717 10.25 23.443 20.488 35.166 30.728.168.147.332.297.552.495l-3.77 4.21-32.62-28.496-32.63 28.493M19.996 4.593c0 1.735.005 3.404-.01 5.073 0 .138-.106.307-.215.405a3443.683 3443.683 0 0 1-8.552 7.625c-.054.048-.13.072-.245.134V4.592h9.023z" />
      </svg>
    );
  }
}
