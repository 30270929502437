import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import flowRight from "lodash.flowright";

// HOC
import { withSeriesForStudyLoader } from "src/hoc";

// Components
import { SeriesListComponent } from "src/components/series_list_component";

// Selectors
import {
  seriesResourceSelectors,
  createStudyIdForPatientIdSelector
} from "src/selectors/data_selectors";

function mapStateToProps(state, ownProps) {
  const patientId = ownProps.match.params.patientId;
  const studyIdSelector = createStudyIdForPatientIdSelector(patientId);

  return {
    series: seriesResourceSelectors.createDataForContextSelector(
      studyIdSelector
    )(state),
    seriesMeta: seriesResourceSelectors.createMetaContextSelectorWithSelector(
      studyIdSelector
    )(state),
    seriesLoadState: seriesResourceSelectors.createContextLoadStateSelectorWithContextStringSelector(
      studyIdSelector
    )(state)
  };
}

export const SeriesListContainer = flowRight(
  withSeriesForStudyLoader,
  withRouter,
  connect(mapStateToProps)
)(SeriesListComponent);
