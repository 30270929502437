import React from "react";
import PropTypes from "prop-types";

// Components
import { ChartComponent } from "src/components/chart_component";
import { GridRow, GridColumn } from "src/components/grid";
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";
import { LoadingComponent } from "src/components/loading_component";

import { analyticsEventNames } from "src/constants";
import { loadStates as _loadStates } from "src/constants/load_states";

/**
 * Render a chart.js chart showing wound trends over time grouped by wound progress
 */
export class WoundAssessmentProgressChartComponent extends React.PureComponent {
  static propTypes = {
    chartDefinition: PropTypes.object.isRequired,
    woundAssessmentProgressChartLoadState: PropTypes.string.isRequired
  };

  render() {
    const { woundAssessmentProgressChartLoadState } = this.props;
    if (!woundAssessmentProgressChartLoadState) {
      return (
        <StartRenderTimeTrackComponent
          eventName={analyticsEventNames.renderWoundsChart}
        >
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else if (woundAssessmentProgressChartLoadState == _loadStates.loading) {
      return (
        <StartRenderTimeTrackComponent
          eventName={analyticsEventNames.renderWoundsChart}
        >
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else if (woundAssessmentProgressChartLoadState == _loadStates.error) {
      return (
        <FinishRenderTimeTrackComponent
          eventName={analyticsEventNames.renderWoundsChart}
          error={true}
        >
          <LoadingComponent />;
        </FinishRenderTimeTrackComponent>
      );
    } else {
      return (
        <FinishRenderTimeTrackComponent
          eventName={analyticsEventNames.renderWoundsChart}
        >
          <GridRow className="chart-container">
            <GridColumn xs="12">
              <div className="line-chart">
                <ChartComponent
                  chartDefinition={this.props.chartDefinition}
                  width={750}
                  height={300}
                />
              </div>
            </GridColumn>
          </GridRow>
        </FinishRenderTimeTrackComponent>
      );
    }
  }
}
