import { createSelector } from "reselect";

//Selectors
import {
  selectedFacilityIdsSelector,
  selectedPatientTablePageNumberSelector,
  selectedPatientTablePageLimitSelector,
  admissionStatusFilterSelector,
  monitorAtHomeStatusFilterSelector,
  patientNameOrMRNOrCodeSearchStringSelector
} from "../../../section_selectors/admin";

/**
 * Selector to return the query string to query patients for selected facilities and patient table page number
 * TO-DO Add page limit in the future
 */

export const patientTablePayloadSelector = createSelector(
  selectedFacilityIdsSelector,
  selectedPatientTablePageNumberSelector,
  selectedPatientTablePageLimitSelector,
  admissionStatusFilterSelector,
  monitorAtHomeStatusFilterSelector,
  patientNameOrMRNOrCodeSearchStringSelector,
  (
    facility_id,
    page_number,
    page_limit,
    admissionStatusFilter,
    monitorAtHomeStatusFilter,
    name_or_mrn_or_code
  ) => {
    const queryParams = {
      filter: {
        ...(facility_id && { facility_id: facility_id.join() }),
        state:
          admissionStatusFilter.length > 0
            ? admissionStatusFilter.join()
            : "new,admitted,discharged",
        patient_user_status:
          monitorAtHomeStatusFilter.length > 0
            ? monitorAtHomeStatusFilter.join()
            : "not_invited,invited,locked,accepted",
        ...(name_or_mrn_or_code && { name_or_mrn_or_code })
      },
      page: {
        offset: page_number * page_limit,
        limit: page_limit
      }
    };

    return queryParams;
  }
);
