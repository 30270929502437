import { medlineColors, defaultColors } from "src/constants/branding_colors";

const LINE_TENSION = 0.075;

/**
 * Create a chart.js chart definition object for rendering a line chart showing
 * @param {Array} incidenceData
 * @param {Array} prevalenceData
 */
export function legacyCreatePopulationLineChart(
  incidenceData,
  prevalenceData,
  branding
) {
  // Labels
  let labels = [];
  if (prevalenceData) {
    labels = Object.assign([], incidenceData.labels, prevalenceData.labels);
  }

  const brandingColors =
    branding === "medline"
      ? medlineColors.populationChart
      : defaultColors.populationChart;
  // datasets
  const datasets = [
    {
      label: "Incidence",
      backgroundColor: "rgba(151,187,205,0)",
      borderColor: brandingColors.incidence,
      pointBackgroundColor: brandingColors.incidence,
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data: incidenceData.datasets[0].data,
      tension: LINE_TENSION,
      patientWithWoundCounts: incidenceData.datasets[0].patientWithWoundCounts,
      patientCounts: incidenceData.datasets[0].patientCounts
    },
    {
      label: "Prevalence",
      backgroundColor: "rgba(151,187,205,0)",
      borderColor: brandingColors.prevalence,
      pointBackgroundColor: brandingColors.prevalence,
      pointStrokeColor: "#fff",
      pointBorderColor: "#fff",
      data:
        prevalenceData && prevalenceData.datasets
          ? prevalenceData.datasets[0].data
          : [],
      tension: LINE_TENSION,
      patientWithWoundCounts:
        prevalenceData && prevalenceData.datasets
          ? prevalenceData.datasets[0].patientWithWoundCounts
          : 0,
      patientCounts:
        prevalenceData && prevalenceData.datasets
          ? prevalenceData.datasets[0].patientCounts
          : 0
    }
  ];
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            type: "linear",
            callback: value => {
              if (Number.isInteger(value)) {
                return `${value}%`;
              } else {
                return null;
              }
            }
          }
        }
      ]
    },
    legend: {
      display: true
    },
    tooltips: {
      mode: "single",
      callbacks: {
        label: (item: Object, data: Object) => {
          let dataset = data.datasets[item.datasetIndex];
          let woundCount = dataset.patientWithWoundCounts[item.index];
          let patientCount = dataset.patientCounts[item.index];
          return [
            `${woundCount} ÷ ${patientCount} = ${item.yLabel}% `,
            `${dataset.label}`
          ];
        }
      }
    }
  };

  return {
    type: "line",
    data: {
      labels,
      datasets
    },
    options
  };
}
