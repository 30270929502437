import { apiFactory } from "../api_factory";

export const assessmentAnswersApi = apiFactory({
  path: `/api/v4/assessment-answers/`
});

export const assessmentAnswersLockApi = apiFactory({
  path: `/api/v4/assessment-answers/`,
  urlExtension: "lock",
  disableErrorInterceptor: true
});

export const assessmentAnswersSignApi = apiFactory({
  path: `/api/v4/assessment-answers/`,
  urlExtension: "sign",
  disableErrorInterceptor: true
});
