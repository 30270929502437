import React from "react";
import PropTypes from "prop-types";

// Containers
import {
  RevisionWoundDetailsHeaderContainer,
  RevisionWoundDetailsHeaderWithPatientLastFacilityContainer
} from "src/containers/revision_wound_details_header_container";
import { RevisionAssessmentAnswersContainer } from "src/containers/revision_assessment_answers_container";
import { WoundAssessmentEditFormContainer } from "src/containers/forms/wound_assessment_edit_form_container";
import QaNoteInput from "src/components/qa_note_input";

export class RevisionWoundDetailsComponent extends React.PureComponent {
  static propTypes = {
    revision: PropTypes.object.isRequired,
    assessmentAnswer: PropTypes.object.isRequired,
    lastFacilityQuery: PropTypes.string,
    patientId: PropTypes.string,
    qaNoteAllowed: PropTypes.bool
  };

  // TO DO: Should just render RevisionWoundDetailsHeaderContainer once lastFacilityId implemented and two containers are not needed #lastFacilityIdDelay
  renderRevisionWoundDetailsHeaderContainer = () => {
    const {
      lastFacilityQuery,
      revision,
      assessmentAnswer,
      patientId
    } = this.props;

    if (lastFacilityQuery) {
      return (
        <RevisionWoundDetailsHeaderWithPatientLastFacilityContainer
          revision={revision}
          revisionId={revision.id}
          assessmentAnswer={assessmentAnswer}
          patientId={patientId}
        />
      );
    } else {
      return (
        <RevisionWoundDetailsHeaderContainer
          revision={revision}
          revisionId={revision.id}
          assessmentAnswer={assessmentAnswer}
          patientId={patientId}
        />
      );
    }
  };

  renderEditForm = () => {
    const { assessmentAnswer, revision, cancel } = this.props;
    return (
      <WoundAssessmentEditFormContainer
        assessmentAnswer={assessmentAnswer}
        revision={revision}
        cancel={cancel}
      />
    );
  };

  renderAssessmentAnswerDetails = () => {
    const { assessmentAnswer, revision } = this.props;
    return (
      <RevisionAssessmentAnswersContainer
        revision={revision}
        assessmentAnswer={assessmentAnswer}
      />
    );
  };

  renderContent = () => {
    const { showEdit } = this.props;

    if (showEdit) {
      return this.renderEditForm();
    } else {
      return this.renderAssessmentAnswerDetails();
    }
  };

  renderQaNote = () => {
    const { assessmentAnswer, qaNoteAllowed } = this.props;
    return qaNoteAllowed ? (
      <QaNoteInput
        assessmentAnswer={{
          ...assessmentAnswer.attributes,
          id: assessmentAnswer.attributes.id || assessmentAnswer.id
        }}
      />
    ) : null;
  };

  render() {
    return (
      <div>
        {this.renderRevisionWoundDetailsHeaderContainer()}
        {this.renderQaNote()}
        {this.renderContent()}
      </div>
    );
  }
}
