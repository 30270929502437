import get from "lodash.get";
import i18n from "src/translations";

const attributesMap = {
  closureStaples: "surgicalStapleCount",
  closureSutures: "surgicalSutureCount"
};

export const getSubtypeStringForAnswersJson = ({
  woundType,
  answersJson,
  localizationDictionary,
  includePressureUnstageable,
  subtypeKeysForSchema
}) => {
  const subtypesForType = subtypeKeysForSchema[woundType];

  if (!subtypesForType) return null;

  let completeSubtypeString;

  const subtypeKeysInAnswers = subtypesForType.filter(subtype => {
    return answersJson[subtype] != null;
  });

  if (subtypeKeysInAnswers.length < 1) return i18n.t("text.notSet");

  // For each wound type question
  const subtypeValues = subtypeKeysInAnswers.map(subtypeKey => {
    const subtypeValue = get(answersJson, `${subtypeKey}`);
    let stringValForSubtypeKey;
    const subtypeHasMultipleValues = Array.isArray(subtypeValue);

    if (subtypeHasMultipleValues) {
      const vals = subtypeValue.map(val => {
        return getStringForIndividualSubtypeValue({
          subtypeValue: val,
          localizationDictionary,
          subtypeKey,
          answersJson
        });
      });
      stringValForSubtypeKey = vals.join(", ");
    } else {
      stringValForSubtypeKey = getStringForIndividualSubtypeValue({
        subtypeValue,
        subtypeKey,
        localizationDictionary,
        answersJson
      });
    }

    return stringValForSubtypeKey;
  });

  completeSubtypeString = subtypeValues.join(", ");

  if (includePressureUnstageable && subtypesForType.length === 1) {
    const pressureUnstageableString = getPressureUnstageableCauseSubtype({
      answersJson,
      woundSubtype: get(answersJson, `${subtypesForType[0]}`),
      woundType: woundType,
      localizationDictionary
    });
    return pressureUnstageableString
      ? completeSubtypeString + ` (${pressureUnstageableString})`
      : completeSubtypeString;
  }

  return completeSubtypeString;
};

const getStringForIndividualSubtypeValue = ({
  subtypeValue,
  subtypeKey,
  localizationDictionary,
  answersJson
}) => {
  if (!subtypeValue || subtypeValue === "notSet") return i18n.t("text.notSet");

  let stringVal =
    subtypeValue && localizationDictionary
      ? localizationDictionary[`${subtypeKey}.${subtypeValue}`]
      : i18n.t("text.notSet");

  const attributeKey = attributesMap[subtypeValue];

  if (!attributeKey) return stringVal;

  const attributeValue = get(answersJson, attributesMap[subtypeValue]);
  if (attributeValue) {
    stringVal += ` (${attributeValue})`;
  }

  return stringVal;
};

const getPressureUnstageableCauseSubtype = ({
  answersJson,
  woundSubtype,
  woundType,
  localizationDictionary
}) => {
  // Pressure unstageable cause subtype
  let pressureUnstageableCause, pressureUnstageableCauseString;
  if (woundSubtype === "unstageable") {
    switch (woundType) {
      case "pressureUlcer":
        pressureUnstageableCause = get(
          answersJson,
          "pressureUlcerUnstageableCause",
          ""
        );
        break;
      case "pressureKennedy":
        pressureUnstageableCause = get(
          answersJson,
          "pressureKennedyUnstageableCause",
          ""
        );
        break;
      case "pressureDeviceRelated":
        pressureUnstageableCause = get(
          answersJson,
          "pressureDeviceRelatedUnstageableCause",
          ""
        );
        break;
    }
  }

  if (pressureUnstageableCause) {
    pressureUnstageableCauseString = localizationDictionary
      ? localizationDictionary[
          `pressureUlcerUnstageableCause.${pressureUnstageableCause}`
        ]
      : i18n.t("text.notSet");
  }

  return pressureUnstageableCauseString;
};

// Context
// wound types can have multiple subtype questions
// subtypes can have multiple attribute values

// Example:
// woundType surgical
// Subtype questions:
//    surgicalClosure: enumConstants:["closureSutures", "closureStaples"]
//    surgicalOther: enumConstants:["descidence"]

//while a different wound can look like the following
// woundType bruise
// bruiseType: enumConstants["Red", "Purple"]

//surgical
//  surgicalClosure ->
//     closureStaples
//          surgicalStapleCount (attribute)
//     closureSutures
//          surgicalSutureCount (attribute)
//  surgicalOther
