import { createSectionActionTypes } from "../../action_type_creators";
import get from "lodash.get";
import cloneDeep from "lodash.clonedeep";

/**
 * Create a set of reducers for section
 * @param {string} section the section name eg. wounds, survey
 * @returns {Function} section redux reducer
 */
export function createSectionFiltersReducer(section) {
  const actionTypes = createSectionActionTypes(section);

  return (state = [], action) => {
    switch (action.type) {
      case actionTypes.selectSavedFilter:
        const filters = get(
          action.payload,
          "attributes.parameters.filters",
          []
        );
        return cloneDeep(filters);
      case actionTypes.deselectSavedFilter:
        return [];
      case actionTypes.selectCheckbox:
      case actionTypes.setSingleValue:
      case actionTypes.selectDate: {
        let filters = state;
        // ----------- Wound Types and Locations Filter ---------- //
        if (
          [
            "woundTypes",
            "locations",
            "evaluations",
            "clinicianOptions",
            "patientEvaluations",
            "payers",
            "visitFrequency"
          ].includes(action.payload.filterName)
        ) {
          let compoundFilter = state.find(
            filter => filter.key === action.payload.filterName
          );

          //Compound Filter is not present in store (first selection in compound filter)
          if (!compoundFilter) {
            filters = filters.concat({
              type: "Compound",
              operator: "OR",
              key: action.payload.filterName,
              value: getConfiguredCompoundObjectsArray(action.payload)
            });
            //Compound Filter present
          } else {
            filters = replaceObjectInArray(
              filters,
              "key",
              action.payload.filterName,
              addFilterObjectToCompoundFilter(compoundFilter, action.payload)
            );
          }
          // ----------- Default Filter ---------- //
        } else {
          let filter = state.find(filter => filter.key === action.payload.key);

          let filterObject = {
            type: "Literal",
            key: action.payload.key,
            value: action.payload.value
          };

          //Filter is not present in store (first selection in acquired filter)
          if (!filter) {
            filters = filters.concat(filterObject);
            //Filter present, selecting another filter value
          } else {
            filters = replaceObjectInArray(
              filters,
              "key",
              action.payload.key,
              filterObject
            );
          }
        }

        return filters;
      }

      case actionTypes.deselectCheckbox: {
        let filters = state;
        // ----------- Wound Types and Locations Filter ---------- //
        if (
          [
            "woundTypes",
            "locations",
            "evaluations",
            "clinicianOptions",
            "patientEvaluations",
            "payers",
            "visitFrequency"
          ].includes(action.payload.filterName)
        ) {
          if (action.payload.key === "selectAll") {
            filters = filters.filter(
              obj => obj.key !== action.payload.filterName
            );
          } else {
            let compoundFilter = filters.find(
                filter => filter.key === action.payload.filterName
              ),
              compoundFilterValueArray = compoundFilter.value;

            compoundFilterValueArray = removeFilterObjectFromCompoundFilterValueArray(
              compoundFilterValueArray,
              action.payload
            );

            compoundFilter.value = compoundFilterValueArray;

            if (compoundFilter.value.length > 0) {
              filters = replaceObjectInArray(
                filters,
                "key",
                action.payload.filterName,
                compoundFilter
              );
            } else {
              filters = filters.filter(
                obj => obj.key !== action.payload.filterName
              );
            }
          }
          // ----------- Default Filter ---------- //
        } else {
          filters = filters.filter(obj => obj.key !== action.payload.key);
        }

        return filters;
      }

      default:
        return state;
    }
  };
}

// ------ Helper Functions ----- //
/**
 * Function to find an object in an array using a key-value pair and return a copy
 * @param {array} array the array in which the required object is present
 * @param {string} objectKey the key in the object to search object with
 * @param {string} objectValue the value in the key-value pair in the object to search object with
 * @returns {object} required object if found
 */
const getObjectFromArray = (array, objectKey, objectValue) => {
  let objectInArray = array.find(
    obj => obj.key === objectKey && obj.value === objectValue
  );
  if (objectInArray) {
    return cloneDeep(objectInArray);
  }
};

/**
 * Function to find an object in an array using a key-value pair,
 * replace the object with desired object and return a new array
 * @param {array} array the array in which the required object to replaced is present
 * @param {string} objectKey the key in the object to search object with
 * @param {string} objectValue the value in the key-value pair in the object to search object with
 * @param {object} replacementObject the object to return the original object with
 * @returns {array} array with object replaced
 */
const replaceObjectInArray = (
  array,
  objectKey,
  objectValue,
  replacementObject
) => {
  let objectIndexInArray = array.findIndex(
    obj => obj[objectKey] === objectValue
  );
  if (objectIndexInArray > -1) {
    let arrayCopy = cloneDeep(array);
    arrayCopy[objectIndexInArray] = replacementObject;
    return arrayCopy;
  }
};

/**
 * Function to create a compound filter object using the config filter value received from component
 * @param {object} configFilterObject the config object
 * @returns {array} the store compatible compound filter objects array
 */
export const getConfiguredCompoundObjectsArray = configFilterObject => {
  return [configFilterObject].reduce((agg, filterObject) => {
    if (filterObject.children) {
      filterObject.children.map(child => {
        const configuredChildrenObjectsArray = getConfiguredCompoundObjectsArray(
          child
        );
        agg = agg.concat(configuredChildrenObjectsArray);
      });
    }

    if (configFilterObject.key && configFilterObject.value) {
      agg.push({
        type: "Literal",
        key: filterObject.key,
        value: filterObject.value
      });
    }
    return agg;
  }, []);
};

/**
 * Function to remove a filter object using the config filter value received from component
 * @param {array} compoundFilterValueArray the compound filter value array
 * @param {object} filterObject the config filter object
 * @param {string} hierarchy optional "parent" parameter
 * @returns {array} the store compatible compound filter objects array with
 */
export const removeFilterObjectFromCompoundFilterValueArray = (
  compoundFilterValueArray,
  configFilterObject,
  hierarchy
) => {
  return [configFilterObject].reduce((agg, filterObject) => {
    //Recursively call function if filterObject has children so all children are removed from store when parent is removed
    if (hierarchy !== "parent" && filterObject.children) {
      filterObject.children.map(child => {
        agg = removeFilterObjectFromCompoundFilterValueArray(agg, child);
      });
    }

    //If filterObject has a parent, and parent is selected, remove parent from store when child is removed
    if (filterObject.parent) {
      agg = removeFilterObjectFromCompoundFilterValueArray(
        agg,
        filterObject.parent,
        "parent"
      );
    }

    const filterObjectIndex = agg.findIndex(
      storeFilterObject =>
        storeFilterObject.key === filterObject.key &&
        storeFilterObject.value === filterObject.value
    );

    if (filterObjectIndex > -1) {
      agg.splice(filterObjectIndex, 1);
    }

    return agg;
  }, cloneDeep(compoundFilterValueArray));
};

/**
 * Function that sets up store configured compound filter(s)
 * using the existing store filter array and selected the filter object,
 * and returns a new copy of the filters with new selections added
 * @param {array} compoundFilter the store compound filter
 * @param {object} filterObject the filter object to be inserted into store filter
 * @returns {array} new filters array with selected filters set up
 */
const addFilterObjectToCompoundFilter = (compoundFilter, filterObject) => {
  let compoundFilterValueArray = compoundFilter.value;
  let storeFilterObject = getObjectFromArray(
    compoundFilterValueArray,
    filterObject.key,
    filterObject.value
  );

  //Filter object not in store (case always expected, safeguard against duplicate filter object)
  if (!storeFilterObject) {
    const configuredCompoundObjectArray = getConfiguredCompoundObjectsArray(
      filterObject
    );

    configuredCompoundObjectArray.forEach(obj => {
      const objectIndexInStore = compoundFilterValueArray.findIndex(
        filter => filter.key === obj.key && filter.value === obj.value
      );
      if (objectIndexInStore === -1) {
        compoundFilterValueArray.push(obj);
      }
    });

    if (allChildrenOfParentSelected(filterObject, compoundFilterValueArray)) {
      const { parent } = filterObject;
      const { key, value } = parent;
      if (key && value) {
        compoundFilterValueArray = compoundFilterValueArray.concat(
          getConfiguredCompoundObjectsArray({
            key,
            value
          })
        );
      }
    }

    compoundFilter.value = compoundFilterValueArray;

    return compoundFilter;
  }
};

/**
 * Function that returns if all children of a filter object's parent is selected
 * using the updated compound store filter array and selected the filter object
 * @param {object} filterObject the filter object that is inserted into store filter
 * @param {array} updatedCompoundFilterValueArray the compound filter array that has been updated with new fitlerObject
 * @returns {boolean} whether all children of a filterObjects's parent are selected
 */
const allChildrenOfParentSelected = (
  filterObject,
  updatedCompoundFilterValueArray
) => {
  if (filterObject.parent) {
    const { children } = filterObject.parent;
    return children.every(child =>
      updatedCompoundFilterValueArray.find(
        filter => filter.key === child.key && filter.value === child.value
      )
    );
  }
};
