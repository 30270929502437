import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router-dom";
import { flowRight } from "lodash";

// Hoc
import {
  withPatientLoader,
  withNonWoundAssessmentAnswersAndSchemasForStudyLoader
} from "src/hoc";

// Components
import { NonWoundAssessmentDetailsComponent } from "src/components/non_wound_assessment_details_component";

// Selectors
import {
  createStudyForPatientIdSelector,
  assessmentAnswersResourceSelectors,
  assessmentsResourceSelectors,
  createSortedAssessmentAnswersForAssessmentIdAndPatientIdSelector,
  createAssessmentSchemaForAssessmentIdSelector
} from "src/selectors/data_selectors";
import { patientBreadcrumbsEnabledSelector } from "src/selectors/config_selectors";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";

import { createLastFacilityQueryForPatientIdSelector } from "src/selectors/api_selectors/global/patients";

function mapStateToProps(state, props) {
  const patientId = props.match.params.patientId;
  return {
    // patient: createPatientForIdSelector(props.match.params.patientId),
    study: createStudyForPatientIdSelector(props.match.params.patientId)(state),
    assessmentAnswers: createSortedAssessmentAnswersForAssessmentIdAndPatientIdSelector(
      props.match.params.assessmentId,
      props.match.params.patientId
    )(state),
    assessmentSchema: createAssessmentSchemaForAssessmentIdSelector(
      props.match.params.assessmentId
    )(state),
    assessmentAnswersContextLoadState: assessmentAnswersResourceSelectors.contextLoadStateSelector(
      state
    ),
    assessmentsContextLoadState: assessmentsResourceSelectors.contextLoadStateSelector(
      state
    ),
    breadcrumbsEnabled: patientBreadcrumbsEnabledSelector(state),
    user: currentUserSelector(state),
    lastFacilityQuery: createLastFacilityQueryForPatientIdSelector({
      patientId
    })(state),
    patientId
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    goBack() {
      dispatch(push(`/patients/${ownProps.match.params.patientId}`));
    }
  };
}

export const NonWoundAssessmentDetailsContainer = flowRight(
  withRouter,
  withPatientLoader,
  withNonWoundAssessmentAnswersAndSchemasForStudyLoader,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NonWoundAssessmentDetailsComponent);
