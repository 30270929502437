import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import i18n from "src/translations";
import "bootstrap/dist/css/bootstrap.min.css";
import { get } from "lodash";
import { v1 } from "uuid";

import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";

import ThermalFrameComponent from "src/components/thermal_frame_component";
import StandardFrameComponent from "src/components/standard_frame_component";
import FluoroFrameComponent from "src/components/fluoro_frame_component";
import {
  STANDARD_FRAME_TYPES,
  THERMAL_FRAME_TYPES,
  FLUORO_FRAME_TYPES
} from "src/selectors/data_selectors/frames/constants";
import { createLatestRegionForRevisionHasMeasurementSelector } from "src/selectors/data_selectors/regions/create_latest_region_for_revision_has_measurement_selector/index";
import styles from "src/components/revision_wound_image_component/style.module.less";
import { createLatestRegionForRevisionSelector } from "src/selectors/data_selectors/regions/create_latest_region_for_revision_selector/index";
import { createDefaultFrameForSeriesIdSelector } from "src/selectors/data_selectors";

const IMAGE_HEIGHT = 480;
const IMAGE_WIDTH = 360;
const LOAD_COUNT = 5;

export function ContextComponent(props) {
  const [hasStandardFrames, setHasStandardFrames] = useState(false);
  const [hasThermalFrames, setHasThermalFrames] = useState(false);
  const [hasFluoroFrames, setHasFluoroFrames] = useState(false);

  const [currentSortedRevisions, setCurrentSortedRevisions] = useState(null);

  useEffect(() => {
    setCurrentSortedRevisions(
      getSortedRevisions(
        props,
        hasStandardFrames,
        setHasStandardFrames,
        hasThermalFrames,
        setHasThermalFrames,
        hasFluoroFrames,
        setHasFluoroFrames
      )
    );
  }, [hasStandardFrames, hasThermalFrames, hasFluoroFrames]);

  if (!currentSortedRevisions) {
    return <div></div>;
  }

  const tableColumns = getTableColumns(
    hasStandardFrames,
    hasThermalFrames,
    hasFluoroFrames
  );

  return [
    <BootstrapTable
      columns={tableColumns}
      keyField="id"
      data={getTableBody(currentSortedRevisions, props)}
      striped
      hover
      bordered={false}
      rowClasses={styles["context-table-row"]}
      pagination={paginationFactory({ sizePerPage: LOAD_COUNT })}
    />
  ];
}

function getSortedRevisions(
  props,
  hasStandardFrames,
  setHasStandardFrames,
  hasThermalFrames,
  setHasThermalFrames,
  hasFluoroFrames,
  setHasFluoroFrames
) {
  const { revisions } = props;
  const sortedRevisions = [];

  revisions.forEach(currentRevision => {
    let standardFrames;
    let thermalFrames;
    let fluoroFrames;

    standardFrames = currentRevision.attributes.frames.filter(frame =>
      STANDARD_FRAME_TYPES.includes(frame.frameType)
    );

    if (standardFrames && !hasStandardFrames && standardFrames.length > 0) {
      setHasStandardFrames(true);
    }

    thermalFrames = currentRevision.attributes.frames.filter(frame =>
      Object.values(THERMAL_FRAME_TYPES).includes(frame.frameType)
    );

    if (thermalFrames && !hasThermalFrames && thermalFrames.length > 0) {
      setHasThermalFrames(true);
    }

    fluoroFrames = currentRevision.attributes.frames.filter(frame =>
      Object.values(FLUORO_FRAME_TYPES).includes(frame.frameType)
    );

    if (fluoroFrames && !hasFluoroFrames && fluoroFrames.length > 0) {
      setHasFluoroFrames(true);
    }

    let revisionDate = new Date(currentRevision.attributes.createdAt);

    if (
      standardFrames.length > 0 ||
      thermalFrames.length > 0 ||
      fluoroFrames.length > 0
    ) {
      sortedRevisions.push({
        ...currentRevision,
        createdAt: revisionDate,
        createdAtFormatted: `${revisionDate.toDateString()}`,
        standardFrames,
        thermalFrames,
        fluoroFrames,
        assessmentAnswer: get(
          currentRevision,
          "attributes.assessmentAnswers[0]",
          null
        )
      });
    }
  });

  return sortedRevisions;
}

function getTableColumns(standardFrames, thermalFrames, flouroFrames) {
  const tableColumns = [];

  if (standardFrames) {
    tableColumns.push({
      dataField: "standard",
      text: i18n.t("headers.standard")
    });
  }

  if (thermalFrames) {
    tableColumns.push({
      dataField: "thermal",
      text: i18n.t("headers.thermal")
    });
  }

  if (flouroFrames) {
    tableColumns.push({
      dataField: "fluoro",
      text: i18n.t("headers.fluoro")
    });
  }

  return tableColumns;
}

function getTableBody(currentSortedRevisions, props) {
  const tableBody = [];
  const { frame, patientType } = props;

  for (let index in currentSortedRevisions) {
    const thisRevision = currentSortedRevisions[index];
    const hasStandardFrames =
      thisRevision.standardFrames && thisRevision.standardFrames.length > 0;
    const hasThermalFrames =
      thisRevision.thermalFrames && thisRevision.thermalFrames.length > 0;
    const hasFluoroFrames =
      thisRevision.fluoroFrames && thisRevision.fluoroFrames.length > 0;
    const row = { id: thisRevision.id };

    let contentType = get({ frame }, "frame.attributes.contentType", "");
    contentType = !contentType
      ? get({ frame }, "frame.contentType", "")
      : contentType;

    let imageHeight = get(frame, "attributes.imageHeight", null);
    let imageWidth = get(frame, "attributes.imageWidth", null);

    if (!imageHeight || !imageWidth) {
      imageHeight = IMAGE_HEIGHT;
      imageWidth = IMAGE_WIDTH;
    }
    if (hasStandardFrames || hasThermalFrames) {
      row["standard"] = (
        <div
          key={v1()}
          className={hasStandardFrames ? "context-heading-standard" : ""}
          style={{ textAlign: "center" }}
        >
          <h3 style={{ height: "40px" }} className="revision-row-created-at">
            {thisRevision.createdAtFormatted}
          </h3>
          {hasStandardFrames ? (
            <>
              <StandardFrameComponent
                key={v1()}
                contentType={contentType}
                hasMeasurement={thisRevision.hasMeasurement}
                patientType={patientType}
                imageUrl={get(thisRevision, "standardFrames[0].imageUrl", null)}
                styles={styles}
                imageHeight={imageHeight}
                imageWidth={imageWidth}
                region={thisRevision.region}
              />
              <br />
              <strong>
                <h4>
                  {`${i18n.t("headers.surfaceArea")}: ${(
                    get(thisRevision.standardFrames[0], "regions[0].area", 0) /
                    100
                  ).toFixed(2)} cm`}
                  <sup>2</sup>
                </h4>
              </strong>
            </>
          ) : (
            <></>
          )}
        </div>
      );
    }

    if (thisRevision.thermalFrames && thisRevision.thermalFrames.length > 0) {
      const deltaFrame = thisRevision.thermalFrames.find(
        frame => THERMAL_FRAME_TYPES.DELTA === frame.frameType
      );

      const standardFrame = thisRevision.standardFrames[0];

      row["thermal"] = (
        <div
          key={v1()}
          style={{ marginTop: "70px", textAlign: "center" }}
          className="context-heading-thermal"
        >
          <ThermalFrameComponent
            key={v1()}
            contentType={contentType}
            deltaFrame={deltaFrame}
            absoluteFrame={
              thisRevision.thermalFrames.find(
                frame => THERMAL_FRAME_TYPES.THERMAL === frame.frameType
              ) || null
            }
            styles={styles}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            region={thisRevision.region}
            trackingProperties={{
              pageName: "Context Tab",
              patientID:
                thisRevision.assessmentAnswer &&
                thisRevision.assessmentAnswer.patientId,
              aagID:
                thisRevision.assessmentAnswer &&
                thisRevision.assessmentAnswer.assessmentAnswerGroupId,
              frameID: frame.id
            }}
          />
          <br />
          <strong>
            <h4>
              {`${i18n.t("headers.woundBedDelta")}: ${get(
                standardFrame,
                "imageMetadata.measurements.woundBedDelta",
                ""
              )} C`}
            </h4>
          </strong>
        </div>
      );
    }

    if (thisRevision.fluoroFrames && thisRevision.fluoroFrames.length > 0) {
      row["fluoro"] = (
        <div
          key={v1()}
          className={hasFluoroFrames ? "context-heading-fluoro" : ""}
          style={{ marginTop: "70px", textAlign: "center" }}
        >
          <>
            <FluoroFrameComponent
              key={v1()}
              contentType={contentType}
              fluoroFrames={get(thisRevision, "fluoroFrames", [])}
              styles={styles}
              imageHeight={imageHeight}
              imageWidth={imageWidth}
              trackingProperties={{
                pageName: "Context Tab",
                patientID:
                  thisRevision.assessmentAnswer &&
                  thisRevision.assessmentAnswer.patientId,
                aagID:
                  thisRevision.assessmentAnswer &&
                  thisRevision.assessmentAnswer.assessmentAnswerGroupId,
                frameID: frame.id
              }}
            />
          </>
        </div>
      );
    }

    tableBody.push(row);
  }

  return tableBody;
}

function mapStateToProps(state, ownProps) {
  const { seriesId } = ownProps;
  const revisions = revisionsResourceSelectors.createDataForContextSelectorWithString(
    seriesId
  )(state);

  for (let i = 0; i < revisions.length; i++) {
    revisions[
      i
    ].hasMeasurement = createLatestRegionForRevisionHasMeasurementSelector(
      revisions[i]
    )(state);

    revisions[i].region = createLatestRegionForRevisionSelector(revisions[i])(
      state
    );
  }

  return {
    revisions,
    frame: createDefaultFrameForSeriesIdSelector(seriesId)(state)
  };
}

export default connect(mapStateToProps)(ContextComponent);
