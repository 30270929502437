import { dataActionTypes } from "src/action_types/data_action_types";

const defaultState = {
  loadState: "loading",
  data: []
};

/**
 * Reducer for population data
 */
export function populationIncidencePrevalenceReducer(
  state = defaultState,
  action
) {
  switch (action.type) {
    case dataActionTypes.populatePopulationIncidencePrevalenceChartData:
      return {
        data: action.payload,
        loadState: "loaded"
      };

    case dataActionTypes.populationIncidencePrevalenceChartDataLoading:
      return { ...state, loadState: "loading" };

    case dataActionTypes.populationIncidencePrevalenceChartDataLoadError:
      return { ...state, loadState: "error" };

    default:
      return state;
  }
}
