import axios from "axios";

/**
 * upload users CSV file
 * @return {Promise} Promise with the response
 */
export function uploadCsv(payload, partnerId, csvType) {
  const config = {
    headers: {
      "X-Partner-ID": partnerId,
      "Content-Type": "multipart/form-data"
    }
  };
  payload.set("csv_type", csvType);
  return axios.post("/api/v1/csv_uploads", payload, config);
}
