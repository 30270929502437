import { populationActionTypes } from "src/action_types/section_action_types";

/**
 * Action creator used to update the entire date selection object
 * startDate, endDate, selectionRange
 * @param {Object} selection the new dateSelection state
 * @return {Object} action creator
 */
export function setDateSelection(selection) {
  return {
    type: populationActionTypes.setDateSelection,
    payload: selection
  };
}
