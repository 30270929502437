import { trackError } from "src/error_tracking";

// Api
import { createPopulationTallyCsv } from "src/api/population";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";

/**
 * Action creator for setting loading state of population tally export to "loading"
 * @return {Object} action
 */
function populationTallyExportLoading() {
  return {
    type: dataActionTypes.populationDataTallyExportLoading
  };
}

/**
 * Action creator for setting loading state of population tally export to "ready"
 * @return {Object} action
 */
function populationTallyExportReady() {
  return {
    type: dataActionTypes.populationDataTallyExportLoadReady
  };
}

/**
 * Send api call to generate a population tally csv, initiate download upon success.
 * @param {Object} payload The payload
 */
export function createAndDownloadPopulationTallyCsv(payload) {
  return dispatch => {
    // loading state
    dispatch(populationTallyExportLoading());
    return createPopulationTallyCsv(payload)
      .then(response => {
        // download result
        window.location.assign(response.data.url);
        // ready state
        dispatch(populationTallyExportReady());
      })
      .catch(err => {
        if (err.message == "cancel") {
          return;
        }
        trackError(err);
        dispatch(populationTallyExportReady());
      });
  };
}
