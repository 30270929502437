import { connect } from "react-redux";

import { ImageDeclinedComponent } from "src/components/image_declined_component";
import { patientTypeSelector } from "src/selectors/config_selectors";

function mapStateToProps(state) {
  return {
    patientType: patientTypeSelector(state)
  };
}

export const ImageDeclinedContainer = connect(mapStateToProps)(
  ImageDeclinedComponent
);
