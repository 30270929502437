import { createSelector } from "reselect";
import get from "lodash.get";

import { healingIndexResourceSelector } from "src/selectors/data_selectors/healing_index";
import { createHealingIndexContextSelector } from "src/selectors/api_selectors/global/healing_index/create_healing_index_context_selector";
/**
 * Create a selector that gets a series for a revision id
 * @param {string} revisionId
 * @returns {Function} selector
 */
export function createRiskCategoryForSeriesIdSelector(ownProps) {
  return createSelector(
    healingIndexResourceSelector.createDataForContextSelector(
      createHealingIndexContextSelector(ownProps)
    ),
    healingIndices => {
      const healingIndicesArray = Object.values(healingIndices);
      let healingIndexRiskCategory = get(
        healingIndicesArray[0],
        "attributes.riskCategory",
        ""
      );
      return healingIndexRiskCategory ? ~~healingIndexRiskCategory : "average";
    }
  );
}
