import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { createNestedAssessmentSchemasForAssessmentAnswerSelector } from "src/selectors/data_selectors";

/**
 * Create a selector that gets the schemas for a wound assessment (treatment tab)
 * @param {Object} assessmentAnswer
 * @returns {Function} selector
 */
export function createTreatmentTabSchemasForAssessmentAnswerSelector(
  assessmentAnswer
) {
  return createSelector(
    createNestedAssessmentSchemasForAssessmentAnswerSelector(assessmentAnswer),
    assessmentSchemas => {
      const treatmentSchema = assessmentSchemas.find(
        schema => get(schema, "attributes.schemaJson.name", "") === "treatment"
      );
      let treatmentTabSchemas = [];
      if (treatmentSchema) treatmentTabSchemas.push(treatmentSchema);

      return treatmentTabSchemas;
    }
  );
}
