import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.less";

//patient default avatar
import patientDefaultAvatar from "src/assets/patientDefaultAvatar.png";

export class SideBarPatientsFilterRowComponent extends PureComponent {
  static propTypes = {
    patient: PropTypes.object.isRequired
  };

  render() {
    const { patient } = this.props;
    let avatarUrl;
    if (patient.attributes.avatarUrl.search(/missing.png/) < 0) {
      avatarUrl = patient.attributes.avatarUrl;
    } else {
      avatarUrl = patientDefaultAvatar;
    }
    return (
      <Link
        to={`/patients/${patient.id}`}
        className={`media ${styles["patient-media"]}`}
      >
        <div className={`media-left ${styles["patient-media-left"]}`}>
          <div
            className={`${styles["avatar"]} media-object`}
            style={{
              backgroundImage: `url('${avatarUrl}')`
            }}
          />
        </div>
        <div className={`media-body ${styles["patient-media-body"]}`}>
          <h4 className={styles["media-heading"]}>
            {!patient.attributes.firstName && !patient.attributes.lastName
              ? "Anonymous"
              : `${patient.attributes.firstName} ${patient.attributes.lastName}`}
          </h4>
        </div>
      </Link>
    );
  }
}
