import React, { PureComponent } from "react";
import PropTypes from "prop-types";
let timer;
export class SessionTimeoutComponent extends PureComponent {
  static propTypes = {
    onTimeout: PropTypes.func.isRequired,
    timeOut: PropTypes.number.isRequired
  };

  componentDidMount() {
    this.updateTime();
    window.addEventListener("click", this.updateTime);
    window.addEventListener("mousemove", this.updateTime);
    window.addEventListener("keypress", this.updateTime);
    window.addEventListener("scroll", this.updateTime);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.updateTime);
    window.removeEventListener("mousemove", this.updateTime);
    window.removeEventListener("keypress", this.updateTime);
    window.removeEventListener("scroll", this.updateTime);
  }

  updateTime = () => {
    clearTimeout(timer);
    timer = setTimeout(this.props.onTimeout, this.props.timeOut);
  };

  render() {
    return <div />;
  }
}
