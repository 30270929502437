import normalize from "json-api-normalizer";

import { createResourceActions } from "src/redux_resources_v1";
const policiesResourceActions = createResourceActions("policies");

/**
 * Action creator for populating policies in the data tree of the store
 * @param {Object} policies
 * @return {Object} redux action
 */
export function populatePolicies(contexts, responseData) {
  const policies = normalize(responseData.data).policies;

  return policiesResourceActions.populateAction({
    contexts,
    data: policies,
    ids: Object.keys(policies),
    links: responseData.data.links,
    meta: responseData.data.meta
  });
}
