import get from "lodash.get";
import { createSelector } from "reselect";

import {
  payersResourceSelector,
  payerRevisionsResourceSelector
} from "src/selectors/data_selectors";

/**
 * Create a selector that gets payers for an array of revisions
 * @param {Object} props
 * @param {array} props.revisions - the revisions to get payers for
 * @returns array of payers per revision (check test)
 */

export function createPayersForRevisionsSelector({ revisions }) {
  return createSelector(
    payersResourceSelector.dataSelector,
    payerRevisionsResourceSelector.dataSelector,
    (payers, payerRevisions) => {
      const payerRevisionsArray = Object.values(payerRevisions);
      return revisions.reduce((agg, r) => {
        agg[r.id] = [];
        payerRevisionsArray.map(payerRevision => {
          let payerRevisionId = get(payerRevision, "attributes.revisionId", "");
          if (r.id === payerRevisionId) {
            agg[r.id].push(
              payers[payerRevision.attributes.payerId].attributes.name
            );
          }
        });
        if (agg[r.id].length === 0) agg[r.id].push("N/A");
        return agg;
      }, {});
    }
  );
}
