import React from "react";
import PropTypes from "prop-types";

// Components
import { AdaptiveFontSizeComponent } from "src/components/adaptive_font_size_component";

// Styles
import styles from "./style.module.less";

/**
 * Renders a badge with a title, numeric value and caption if provided
 */
export class StudyBadgeComponent extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    bottomText: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
  };

  render() {
    const { title, value, bottomText, width, height } = this.props;

    const style = {
      width: width,
      height: height
    };

    return (
      <div
        className={`${styles["study-badge"]} badge-background`}
        style={style}
      >
        <AdaptiveFontSizeComponent baseWidth={70} scaleFactor={1}>
          <div className={styles["badge-title"]}>{title}</div>
          <div className={styles["badge-value"]}>{value}</div>
          <div className={styles["badge-bottom-text"]}>{bottomText}</div>
        </AdaptiveFontSizeComponent>
      </div>
    );
  }
}
