import { createSelector } from "reselect";
import { isSwiftAdminSelector } from "../is_swift_admin_selector";

/**
 * Memoized selector to determine if user is allowed to view partners dropdown in admin dashboard,
 * returns true if user is a Swift Admin, false otherwise
 */

export const adminPartnerDropDownEnabledSelector = createSelector(
  isSwiftAdminSelector,
  isSwiftAdmin => isSwiftAdmin
);
