/**
 * Create action types for a resource name
 * @param {string} resourceName - the resource name (ex. series)
 */
export function createActionTypes(resourceName) {
  const uppercaseName = resourceName.toUpperCase();

  return {
    // load state actions
    setLoadState: `${uppercaseName}_RESOURCE_SET_LOAD_STATE_FOR_CONTEXT_REDUX_RESOURCES`,

    // data actions
    populate: `${uppercaseName}_RESOURCE_POPULATE_REDUX_RESOURCES`,
    remove: `${uppercaseName}_RESOURCE_REMOVE_REDUX_RESOURCES`,
    invalidate: `${uppercaseName}_RESOURCE_INVALIDATE_REDUX_RESOURCES`,
    invalidateAll: `${uppercaseName}_RESOURCE_INVALIDATE_ALL_REDUX_RESOURCES`,

    // pagination actions
    incrementPage: `${uppercaseName}_RESOURCE_INCREMENT_PAGE_REDUX_RESOURCES`,
    decrementPage: `${uppercaseName}_RESOURCE_DECREMENT_PAGE_REDUX_RESOURCES`,
    setPage: `${uppercaseName}_RESOURCE_SET_PAGE_REDUX_RESOURCES`
  };
}
