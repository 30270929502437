import { createSelector } from "reselect";
import { talliesByWoundTypeSelector } from "../tallies_by_wound_type_selector";

/**
 * Selector to provide total tally
 */
export const totalTallySelector = createSelector(
  talliesByWoundTypeSelector,
  tallies => {
    return tallies[0] || {};
  }
);
