import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import get from "lodash.get";

// Components
import { StudyBadgeComponent } from "src/components/study_badge_component";
import { GridColumn, GridRow } from "src/components/grid";
import { FixedAspectRatioComponent } from "src/components/fixed_aspect_ratio_component";
import { ImageComponent } from "src/components/image_component";
import { ImageDeclinedContainer } from "src/containers/image_declined_container";

// Mapping
import { formatBodyLocationString } from "src/utils/format_location_string";

// Utils
import { ageThisMoment } from "@sw/utils/timeUtils";

// Model
import { getNumberTypeSubtypeStringForAssessmentAnswer } from "src/models/assessment_answers";
import { displayValueForUnitless } from "src/models/revisions";

// Styles
import styles from "./style.module.less";

//Translations
import i18n from "src/translations";

/**
 * Renders a summary view for a series (wound)
 */
export class SeriesListItemComponent extends PureComponent {
  static propTypes = {
    series: PropTypes.object.isRequired,
    revision: PropTypes.object,
    frame: PropTypes.object,
    assessmentAnswer: PropTypes.object,
    woundNumbersEnabled: PropTypes.bool.isRequired,
    schemaLocalizationDictionary: PropTypes.object.isRequired,
    assessmentVersion: PropTypes.string.isRequired
  };
  /**
   * Get the wound location string
   * @returns {string}
   */

  getWoundLocationString() {
    const { assessmentAnswer, schemaLocalizationDictionary } = this.props;
    const location = get(
      assessmentAnswer,
      "attributes.answersJson.location",
      ""
    );

    const sublocation = get(
      assessmentAnswer,
      "attributes.answersJson.sublocation",
      ""
    );

    const lateralities = get(
      assessmentAnswer,
      "attributes.answersJson.laterality",
      []
    );

    const locationString = location
      ? formatBodyLocationString(
          {
            location,
            sublocation,
            lateralities
          },
          schemaLocalizationDictionary
        )
      : "Not Set";

    return locationString;
  }

  /**
   * Get the progress string
   * @returns {string}
   */
  getProgressString() {
    const { assessmentAnswer, assessmentVersion } = this.props;
    const overallProgress = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.overallProgress",
      ""
    )
      ? i18n.t(
          `data.overallProgress.${assessmentVersion}.${assessmentAnswer.attributes.answersJson.overallProgress}`
        )
      : "Unknown";
    return `Status ${overallProgress}`;
  }

  /**
   * Get the wound age string
   * @returns {string}
   */
  getWoundAgeString() {
    const { series, assessmentAnswer } = this.props;

    const woundAge = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.woundAge",
      "unknown"
    );

    const seriesOriginAt = get(series, "attributes.originAt", null);

    let woundAgeFromOrigin = ageThisMoment(seriesOriginAt);

    if (["chronic", "unknown"].includes(woundAge)) {
      woundAgeFromOrigin = i18n.t(`data.woundAge.${woundAge}`);
    }

    return woundAgeFromOrigin;
  }

  /**
   * Get the last assessed string
   * @returns {string}
   */
  getLastAssessedString() {
    const { revision } = this.props;
    const createdAt = get({ revision }, "revision.attributes.createdAt", null);
    if (createdAt) {
      return moment(createdAt).format("MMM DD, YYYY");
    }
    return "";
  }

  render() {
    const {
      series,
      revision,
      frame,
      match,
      woundNumbersEnabled,
      schemaLocalizationDictionary,
      subtypeKeysForSchema
    } = this.props;
    const woundNumber = series.attributes.patientWoundNumber;

    if (!revision) {
      return null;
    }
    const contentType = get({ frame }, "frame.attributes.contentType", null),
      thumbnailUrl = get({ frame }, "frame.attributes.thumbnailUrl", ""),
      closure = get({ revision }, "revision.attributes.closure", "");

    return (
      <div>
        <Link to={`${match.url}/series/${series.id}`}>
          <GridRow className="assessment">
            <GridColumn xs="6" sm="8">
              <GridRow>
                <GridColumn sm="4">
                  <FixedAspectRatioComponent maxWidth={100} minWidth={50}>
                    {contentType == "declined" ? (
                      <ImageDeclinedContainer borderRadius={true} />
                    ) : (
                      <ImageComponent imageUrl={thumbnailUrl} />
                    )}
                  </FixedAspectRatioComponent>
                </GridColumn>
                <GridColumn sm="8">
                  <div className={styles["series-info-container"]}>
                    <div className={styles["series-info-wound-type"]}>
                      {getNumberTypeSubtypeStringForAssessmentAnswer({
                        assessmentAnswer: this.props.assessmentAnswer,
                        localizationDictionary: schemaLocalizationDictionary,
                        subtypeKeysForSchema,
                        ...(woundNumbersEnabled && {
                          patientWoundNumber: woundNumber
                        })
                      })}
                    </div>
                    <div className={styles["series-info"]}>
                      {this.getWoundLocationString()}
                    </div>
                    <br />
                    <div className={styles["series-info"]}>
                      <strong>{this.getProgressString()}</strong> -{" "}
                      {this.getWoundAgeString()}
                    </div>
                    <div className={styles["series-info"]}>
                      <strong>Last Evaluated On:</strong>
                      {` ${this.getLastAssessedString()}`}
                    </div>
                  </div>
                </GridColumn>
              </GridRow>
            </GridColumn>
            <GridColumn xs="6" sm="4">
              <FixedAspectRatioComponent maxWidth={100} minWidth={50}>
                <StudyBadgeComponent
                  title="% Changed"
                  value={
                    contentType == "declined"
                      ? "--"
                      : displayValueForUnitless(closure)
                  }
                />
              </FixedAspectRatioComponent>
            </GridColumn>
          </GridRow>
        </Link>
        <hr />
      </div>
    );
  }
}
