import { createSelector } from "reselect";
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";
import { organizationsForSelectedPartnerIdSelector } from "../organizations_for_selected_partnerid_selector";

/**
 * Facilities selector for the admin section
 * used to get the facilities according to organizations
 */
export const selectFacilitiesByAllOrganizationsSelector = createSelector(
  facilitiesResourceSelectors.createDataForContextSelectorWithString(
    "adminFacilities"
  ),
  organizationsForSelectedPartnerIdSelector,
  (facilities, organizations) => {
    const organizationIds = Object.values(organizations).map(org => org.id);
    return Object.values(facilities).filter(fac => {
      return organizationIds.includes(fac.attributes.organizationId);
    });
  }
);
