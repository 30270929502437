export const woundAssessmentTotalsTimeSeriesByLocationActions = {
  populateWoundAssessmentTotalsTimeSeriesByLocation:
    "POPULATE_WOUND_ASSESSMENT_TOTALS_TIME_SERIES_BY_LOCATION_DATA",
  woundAssessmentTotalsTimeSeriesByLocationLoading:
    "DATA_WOUND_ASSESSMENT_TOTALS_TIME_SERIES_BY_LOCATION_LOADING",
  woundAssessmentTotalsTimeSeriesByLocationLoaded:
    "DATA_WOUND_ASSESSMENT_TOTALS_TIME_SERIES_BY_LOCATION_LOADED",
  woundAssessmentTotalsTimeSeriesByLocationLoadError:
    "DATA_WOUND_ASSESSMENT_TOTALS_TIME_SERIES_BY_LOCATION_LOAD_ERROR"
};
