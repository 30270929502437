import { createSelector } from "reselect";
import cloneDeep from "lodash.clonedeep";

// Selectors
import { assessmentVersionSelector } from "src/selectors/config_selectors";
import { woundTypesConfigurationFilterForSchemaPolicySelector } from "src/selectors/ui_config_selectors/global/wound_type_filter_configuration_selector";

//Configuration
import { evaluationsConfigurationDefault } from "src/constants/filters_configurations/compliance";
import { evaluationsConfigurationV7 } from "src/constants/filters_configurations/compliance";

export const evaluationsFilterConfigSelector = createSelector(
  woundTypesConfigurationFilterForSchemaPolicySelector,
  assessmentVersionSelector,
  (woundTypesFilterConfiguration, assessmentVersion) => {
    let evaluationsConfig;
    if (assessmentVersion == "v7" || assessmentVersion == "v8") {
      evaluationsConfig = cloneDeep(evaluationsConfigurationV7);
    } else {
      evaluationsConfig = cloneDeep(evaluationsConfigurationDefault);
    }

    let woundTypesFilterConfig = cloneDeep(woundTypesFilterConfiguration);

    const { filterValues } = evaluationsConfigurationDefault;
    const woundFilterIndex = filterValues.findIndex(
      filter => filter.value === "wound"
    );

    if (woundFilterIndex > -1) {
      evaluationsConfig.assessmentVersion = assessmentVersion;
      evaluationsConfig.filterValues[woundFilterIndex].children =
        woundTypesFilterConfig.filterValues;
    }

    return evaluationsConfig;
  }
);
