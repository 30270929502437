import { connect } from "react-redux";

// Components
import { ButtonComponent } from "src/components/button_component";

// Selectors
import { createReopenButtonVisibleSelector } from "src/selectors/ui_config_selectors/patients/create_reopen_button_visible_selector";
import { createReopenIsDisabledSelector } from "src/selectors/ui_config_selectors/patients/create_reopen_is_disabled_selector";
import { createReopenTooltipSelector } from "src/selectors/ui_config_selectors/patients/create_reopen_tooltip_selector";

import { reopenAssessmentAnswerAction } from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  return {
    isVisible: createReopenButtonVisibleSelector(ownProps)(state),
    disabled: createReopenIsDisabledSelector(ownProps)(state),
    tooltip: createReopenTooltipSelector()(state)
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: () => {
      return dispatch(
        reopenAssessmentAnswerAction({ resource: ownProps.assessmentAnswer })
      );
    }
  };
};

export const ReopenButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonComponent);
