import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./style.module.less";

/**
 * Wrapper around flexbox css api
 * defaults:
 * flex-direction - row
 * flex-wrap - wrap
 * flex-justify - flex-start
 * align-items - flex-start
 */
export class FlexBox extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,

    // Control for flexbox
    column: PropTypes.bool,
    nowrap: PropTypes.bool,
    vAlign: PropTypes.oneOf(["top", "center", "bottom"]),
    hAlign: PropTypes.oneOf(["left", "center", "right"]),

    // Child properties
    spacing: PropTypes.oneOf(["none", "small", "large"])
  };

  static defaultProps = {
    vAlign: "top",
    hAlign: "left",
    spacing: "none"
  };

  getStyles() {
    const { column, nowrap, vAlign, hAlign } = this.props;

    function getAlignment(align) {
      switch (align) {
        case "top":
        case "left":
          return "flex-start";
        case "center":
          return "center";
        case "bottom":
        case "right":
          return "flex-end";
      }
    }

    return {
      // some browsers don't set these by default on flex
      minWidth: 0,
      minHeight: 0,

      // flex properties
      display: "flex",
      flexDirection: column ? "column" : "row",
      flexWrap: nowrap ? "nowrap" : "wrap",
      justifyContent: getAlignment(column ? vAlign : hAlign),
      alignItems: getAlignment(column ? hAlign : vAlign)
    };
  }

  render() {
    const { spacing, column } = this.props;
    const className = classnames(this.props.className, {
      [styles["spacing-small-row"]]: spacing == "small" && !column,
      [styles["spacing-large-row"]]: spacing == "large" && !column,
      [styles["spacing-small-column"]]: spacing == "small" && column,
      [styles["spacing-large-column"]]: spacing == "large" && column
    });

    return (
      <div className={className} style={this.getStyles()}>
        {this.props.children}
      </div>
    );
  }
}
