import { createSelector } from "reselect";

export function createSelectors(fieldName, rootSelector) {
  let fieldSelector;
  if (rootSelector) {
    fieldSelector = createSelector(
      rootSelector,
      root => {
        return root["dateRangeValue"][fieldName];
      }
    );
  } else {
    fieldSelector = state => state[fieldName];
  }

  return {
    fieldSelector
  };
}
