import { createSelector } from "reselect";
import get from "lodash.get";

export const createAlertRuleFormRuleParamsSelector = ({
  formValues,
  templates
}) => {
  return createSelector(() => {
    if (formValues) {
      let ruleParams;
      const template = templates.find(
        template => template.id === formValues.ruleTemplateId
      );
      ruleParams = get(template, "attributes.ruleParams", []);

      if (ruleParams) {
        //Visible and Hidden Rule Params
        const hiddenRuleParams = ruleParams.filter(field => {
          return field.hidden === true;
        });
        const visibleRuleParams = ruleParams.filter(field => {
          return field.hidden === false;
        });

        return {
          hiddenRuleParams,
          visibleRuleParams
        };
      }
    }
  });
};
