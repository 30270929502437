// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";

type $props = {
  className?: string,
  children?: any
};

export default class Container extends PureComponent {
  props: $props;
  render() {
    const { className, children } = this.props;
    const klass = classnames("container-fluid", {
      [className || ""]: !!className
    });
    return <div className={klass}>{children}</div>;
  }
}
