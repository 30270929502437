export function createRulesGetPayload({
  locationId,
  locationType,
  pageNumber,
  pageLimit,
  evaluationType
}) {
  return {
    filter: {
      location_id: locationId,
      location_type: locationType,
      evaluation_type: evaluationType
    },
    page: {
      offset: pageNumber * pageLimit,
      limit: pageLimit
    },
    sort: "-created_at"
  };
}
