import { createSelector } from "reselect";

//Constants
import { apiPageLimits } from "src/constants";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get the api query for admin  facilities
 * @returns {function} selector
 */
export function createAdminFacilitiesQuerySelector() {
  return createSelector(
    () => {
      return apiPageLimits.adminFacilities;
    },
    pageLimit => {
      const query = {
        page: { limit: pageLimit }
      };
      return buildQueryString(query);
    }
  );
}
