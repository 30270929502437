import React from "react";
import PropTypes from "prop-types";

// Translations
import i18n from "src/translations";

// Styles
import styles from "./style.module.less";
import get from "lodash.get";

export class SavedFilterVisualizationComponent extends React.PureComponent {
  static propTypes = {
    filterUiConfigs: PropTypes.array.isRequired,
    filterCheckedStates: PropTypes.array.isRequired,
    schemaLocalizationDictionary: PropTypes.object
  };

  isWoundTypeFilter = filterConfig => {
    if (filterConfig.key === "woundType") return true;

    if (get(filterConfig, "parent.key") === "woundType") return true;

    const hasWoundTypeParent = filterConfig => {
      const parent = filterConfig.parent;
      if (!parent) return false;

      if (parent.key === "woundType") return true;

      return hasWoundTypeParent(parent);
    };
    return hasWoundTypeParent(filterConfig);
  };

  renderFilterLabel = (filterConfig, config) => {
    const { schemaLocalizationDictionary } = this.props;
    const isWoundTypeFilter = this.isWoundTypeFilter(filterConfig);

    // Get label translation
    let filterLabel;
    if (filterConfig.name) {
      filterLabel = filterConfig.name;
    } else if (isWoundTypeFilter && schemaLocalizationDictionary) {
      filterLabel =
        schemaLocalizationDictionary[
          `${filterConfig.key}.${filterConfig.value}`
        ];
      filterLabel = filterLabel || i18n.t(`text.${filterConfig.value}`);
    } else if (config.assessmentVersion && filterConfig.value != "wound") {
      filterLabel = i18n.t(
        `data.${config.filterName}.${config.assessmentVersion}.${filterConfig.value}`
      );
    } else if (filterConfig.value == "wound") {
      filterLabel = i18n.t(`data.evaluations.types.wound`);
    }

    if (!filterLabel) {
      filterLabel = i18n.t(`data.${config.filterName}.${filterConfig.value}`);
    }

    return filterLabel;
  };

  render() {
    const { filterUiConfigs, filterCheckedStates } = this.props;
    const filterConfigList = filterUiConfigs.map((config, index) => {
      const checkedStates = filterCheckedStates[index];
      // Reduce function to render list of selected filters for a config
      const renderFilterValuesList = (agg, filterConfig) => {
        if (filterConfig.key) {
          if (
            checkedStates[filterConfig.key][filterConfig.value] == "true" ||
            checkedStates[filterConfig.key][filterConfig.value] == "partial"
          ) {
            const filterLabel = this.renderFilterLabel(filterConfig, config);
            agg.push(
              <li key={`${filterConfig.key} - ${filterConfig.value}`}>
                {filterLabel}
              </li>
            );
          }
        } else {
          if (
            checkedStates[filterConfig.value][filterConfig.value] == "true" ||
            checkedStates[filterConfig.value][filterConfig.value] == "partial"
          ) {
            const filterLabel = this.renderFilterLabel(filterConfig, config);
            agg.push(
              <li key={`${filterConfig.value} - ${filterConfig.value}`}>
                {filterLabel}
              </li>
            );
          }
        }

        if (filterConfig.children) {
          agg.push(
            <li
              key={`parent - ${filterConfig.key || filterConfig.value} - ${
                filterConfig.value
              }`}
            >
              <ul className={styles["indented-list"]}>
                {filterConfig.children.reduce(renderFilterValuesList, [])}
              </ul>
            </li>
          );
        }

        return agg;
      };

      return (
        <li key={config.filterName}>
          <h4>{i18n.t(`headers.${config.filterName}`)}</h4>
          <ul className={styles["unindented-list"]}>
            {config.filterValues.reduce(renderFilterValuesList, [])}
          </ul>
        </li>
      );
    });

    return (
      <div>
        <h3>{i18n.t("headers.selectedFilters")}</h3>
        <ul className={styles["unindented-list"]}>{filterConfigList}</ul>
      </div>
    );
  }
}
