import { createSelector } from "reselect";
import moment from "moment";

// Constants
import { apiPageLimits, attributesByResource } from "src/constants";

// Redux Resources
import { createGetPayload } from "src/redux_resources_v1";

// Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { createFirstPageRevisionContextForFiltersSelector } from "../create_first_page_revision_context_for_filters_selector";
import { createFirstPageRevisionPayloadForFiltersSelector } from "../create_first_page_revision_payload_for_filters_selector";
import { createSelectedLocationIdsForFiltersSelector } from "../../../../section_selectors/global/create_selected_location_ids_for_filters_selector";

// Filter formatters
import resourceFilterFormatters from "src/api/resource_filter_formatters";

export function createAllPagesRevisionPayloadsForFiltersSelector({ filters }) {
  return createSelector(
    createFirstPageRevisionPayloadForFiltersSelector({ filters }),
    revisionsResourceSelectors.createPageCountSelector(
      createFirstPageRevisionContextForFiltersSelector({ filters }),
      apiPageLimits.revisionsBulk
    ),
    createSelectedLocationIdsForFiltersSelector({ filters }),
    (firstPagePayload, pageCount, locationIds) => {
      let payloads = [firstPagePayload];

      // Get filter values
      const date = resourceFilterFormatters.filterValueForKeyFromFilters(
        filters,
        "date"
      );
      const lockState = resourceFilterFormatters.filterValueForKeyFromFilters(
        filters,
        "lockState"
      );
      const answers = resourceFilterFormatters.answersFilterFromFilters(
        filters
      );

      if (pageCount > 1) {
        for (let i = 1; i < pageCount; i++) {
          payloads.push(
            createGetPayload({
              filter: {
                start_date: moment(date)
                  .startOf("day")
                  .toISOString(),
                end_date: moment(date)
                  .endOf("day")
                  .toISOString(),
                location_id: locationIds.join(),
                answers,
                lock_state: lockState,
                strikeout_state: "open"
              },
              page: {
                pageNumber: i,
                limit: apiPageLimits.revisionsBulk
              },
              fields: {
                revisions:
                  attributesByResource.revisions
                    .allAttributesWithoutNestedAssessmentAnswers,
                series:
                  attributesByResource.series
                    .allAttributesWithoutNestedResources
              },
              include: ["series", "series.study", "series.study.patient"]
            })
          );
        }
      }

      return payloads;
    }
  );
}
