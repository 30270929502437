import { createSectionActionTypes } from "../../action_type_creators";

import cloneDeep from "lodash.clonedeep";

/**
 * Create a set of reducers for section search filters
 * @param {string} section the section name eg. wounds, survey
 * @returns {Function} section redux reducer
 */
export function createSectionSearchFiltersReducer(section) {
  const actionTypes = createSectionActionTypes(section);

  return (state = [], action) => {
    switch (action.type) {
      case actionTypes.setSearchString:
        let searchFilters = state;

        const { key, value } = action.payload;

        const searchFilterObject = {
          type: "Literal",
          key,
          value
        };

        const filterIndex = searchFilters.findIndex(
          filter => filter.key === key
        );

        if (filterIndex !== -1) {
          searchFilters[filterIndex] = searchFilterObject;
        } else {
          searchFilters = searchFilters.concat(searchFilterObject);
        }

        return cloneDeep(searchFilters);

      default:
        return state;
    }
  };
}
