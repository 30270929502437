export const globalActionTypes = {
  //Download Manager UI Actions
  closeDownloadManager: "GLOBAL_CLOSE_DOWNLOAD_MANAGER",
  openDownloadManager: "GLOBAL_OPEN_DOWNLOAD_MANAGER",
  toggleExpandCollapseDownloadManagerBody:
    "TOGGLE_EXPAND_COLLAPSE_DOWNLOAD_MANAGER_BODY",
  toggleExpandSidebar: "TOGGLE_EXPAND_SIDEBAR",
  //Downloadable schemas
  createSchemaLocalizationDictionary: "CREATE_SCHEMA_LOCALIZATION_DICTIONARY"
};
