// @flow
import React, { PureComponent } from "react";
import { Chart } from "@sw/ui-kit";
import DateValueModel from "@sw/models/TrendModel/DateValueModel";
type $props = {
  data: DateValueModel
};

const userCallback = function(tick) {
  if ([0, 9, 12, 14, 18, 23].indexOf(tick) === -1) {
    return null;
  }
  return tick;
};

export default class BradenHistoryChart extends PureComponent {
  props: $props;
  render() {
    return (
      <Chart
        data={this.props.data}
        userCallback={userCallback}
        min={0}
        max={23}
        fixedStepSize={1}
      />
    );
  }
}
