import axios from "axios";

import { formatApiResource } from "src/utils/api_utils";
import createFlashMessage from "app/flash_messages/create";
import { camelCaseObjects } from "src/utils/camel_case_objects";
import { updateAssessmentAnswerQaNote } from "src/actions/data_actions";
// Translations
import i18n from "src/translations";

//Constants
import { analyticsEventNames } from "src/constants";

// Analytics
import { track } from "src/analytics";

export const saveQaNote = (note, assessmentAnswer) => async dispatch => {
  try {
    let updateType = !!assessmentAnswer.qaNoteId ? "Save-Edit" : "Save-Create"
    let evaluationState = "In progress";
    if(assessmentAnswer.lockState == 'unlocked' && assessmentAnswer.signState == "signed"){
      evaluationState = 'Signed'
    }
    if(assessmentAnswer.lockState == 'locked' && assessmentAnswer.signState == "signed"){
      evaluationState = 'Completed'
    }
    const mixpanel_properties = {
      updateType: updateType,
      characterCount: note.length,
      evaluationState: evaluationState
    };
    track(analyticsEventNames.updatedQANote, mixpanel_properties);
    const response = await (!!assessmentAnswer.qaNoteId
      ? updateQaNote(note, assessmentAnswer)
      : createQaNote(note, assessmentAnswer));
    const formattedResponse = camelCaseObjects(response.data);

    const newAssessmentAnswer = {
      id: assessmentAnswer.id,
      attributes: {
        ...assessmentAnswer,
        qaNoteValue: formattedResponse.data.attributes.value,
        qaNoteId: formattedResponse.data.id,
        qaNoteAuthor: formattedResponse.data.attributes.noteAuthor,
        qaNoteUpdatedAt: formattedResponse.data.attributes.updatedAt
      }
    };
    dispatch(
      updateAssessmentAnswerQaNote({
        [assessmentAnswer.id]: { ...newAssessmentAnswer }
      })
    );

    dispatch(
      createFlashMessage(
        i18n.t("interaction.successMessages.qaNotes.qaNoteUpdateSuccess"),
        "success"
      )
    );
  } catch (error) {
    dispatch(
      createFlashMessage(
        i18n.t("interaction.errorMessages.qaNotes.qaNoteUpdateError"),
        "error"
      )
    );
  }
};

const updateQaNote = async (note, assessmentAnswer) => {
  const { qaNoteId: id } = assessmentAnswer;
  return await axios.put(
    `/api/v4/qa-notes/${id}`,
    formatApiResource("qa_notes", { value: note }, id),
    {
      headers: {
        "Content-Type": "application/vnd.api+json"
      }
    }
  );
};

const createQaNote = async (note, assessmentAnswer) => {
  const qaNote = {
    value: note,
    assessment_answer_group_id: assessmentAnswer.assessmentAnswerGroupId
  };

  return await axios.post(
    `/api/v4/qa-notes`,
    formatApiResource("qa_notes", qaNote),
    {
      headers: {
        "Content-Type": "application/vnd.api+json"
      }
    }
  );
};
