import moment from "moment";
import get from "lodash.get";
import zip from "lodash.zip";
import zipobject from "lodash.zipobject";

//Constants
import { seriesTrendsKeys } from "src/constants";

//Utils
import { renderChartToImageDataUrl } from "src/utils/image_data_url";

//Translations
import i18n from "src/translations";

//Util function that creates and returns the chart image URL using seriesTrends and trendType to be used by pdfMake
export const getChartUrlForPdf = (seriesTrends, trendType, { min, max }) => {
  const seriesTrendKey = seriesTrendsKeys[trendType];
  const trend = get(seriesTrends, `attributes.${seriesTrendKey}`, {});
  const chartData = getChartObjectForPdf(
    trend,
    i18n.t(`text.seriesTrendsChartsPDFTitles.${trendType}`),
    {
      ...((min === 0 && { min }) || (min && { min })),
      ...(max && { max })
    }
  );

  return renderChartToImageDataUrl(chartData, 600, 450);
};

//Get the chart.js definition for each chart type for pdf
const getChartObjectForPdf = (
  valueDateModel,
  title,
  { beginAnywhere, noMax, max, noMin, min }
) => {
  const { values, dates } = valueDateModel;

  const data = zip(dates, values).map(zipobject.bind(null, ["x", "y"]));

  return {
    type: "line",
    data: {
      datasets: [
        {
          label: "Treatments",
          fill: true,
          backgroundColor: "#e6efd5",
          strokeColor: "rgba(124,174,43,1)",
          pointColor: "rgba(124,174,43,1)",
          pointStrokeColor: "#fff",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(124,174,43,1)",
          data: data,
          pointRadius: 6,
          pointHoverRadius: 10,
          pointHitRadius: 30,
          pointBorderWidth: 2,
          borderColor: "rgba(124,174,43,1)"
        }
      ]
    },
    options: {
      animation: false,
      responsive: false,
      title: {
        display: true,
        text: title,
        fontSize: 40,
        padding: 10,
        fontColor: "#000000"
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              callback(label, index, labelsArray) {
                if (index === 0 || index === labelsArray.length - 1) {
                  return moment(label).format("MM/DD/YYYY");
                }
              },
              fontSize: 22,
              fontColor: "#000000",
              maxRotation: 0,
              padding: 20
            },
            gridLines: {
              color: "#000000"
            },
            time: {
              displayFormats: "MM/DD/YYYY",
              unit: "day",
              round: "day"
            },
            type: "time",
            display: true,
            scaleLabel: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              callback(label) {
                if (label.toFixed(0) == label) {
                  return label;
                }
              },
              beginAtZero: !beginAnywhere,
              max: noMax ? undefined : max,
              min: noMin ? undefined : min,
              fontSize: 22,
              fontColor: "#000000"
            },
            gridLines: {
              color: "#000000"
            },
            display: true,
            scaleLabel: {
              display: false
            }
          }
        ]
      }
    }
  };
};
