import { createSelector } from "reselect";

import {
  customRolesResourceSelectors,
  rolesResourceSelectors
} from "src/selectors/data_selectors";

/**
 * Selector to get all the roles and custom roles and return an array of options for admin users table role filter
 */

export const userRolesSelector = createSelector(
  rolesResourceSelectors.dataSelector,
  customRolesResourceSelectors.dataSelector,
  (roles, customRoles) => {
    //Empty Role Option as first option
    let allRoles = [
      {
        value: "",
        label: "-- Select Role --"
      }
    ];
    //Concat Roles To Array
    allRoles = allRoles.concat(
      Object.values(roles).map(role => getRoleObject(role))
    );
    //Concat Custom Roles To Array
    allRoles = allRoles.concat(
      Object.values(customRoles).map(role => getRoleObject(role))
    );

    return allRoles;
  }
);

const getRoleObject = role => {
  return {
    value: role.id,
    label: role.attributes.primaryName
  };
};
