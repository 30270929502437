import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";

export const hasUnlockWoundRevisionsPermissionSelector = createSelector(
  createPolicyForInternalNameSelector(
    policyInternalNames.canUnlockFromDashboard
  ),
  canUnlockFromDashboard => {
    return (
      !!canUnlockFromDashboard && canUnlockFromDashboard.attributes.policyValue
    );
  }
);
