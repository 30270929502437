import { tallyTypes, woundTypeKeyForSubtype } from "src/constants";

/**
 * Determine if the tally display string should be indented
 * @param {Object} tally
 * @returns {boolean}
 */
export function shouldIndentContextString(tally) {
  if (tally.attributes.type == tallyTypes.total) return false;

  const { woundType } = tally.attributes.context;

  if (woundType) {
    const subtypeKey = Object.keys(woundTypeKeyForSubtype).find(key => {
      return !!tally.attributes.context[key];
    });

    if (subtypeKey) {
      return true;
    } else {
      return false;
    }
  }

  return false;
}
