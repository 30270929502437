import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { patientsResourceSelectors } from "../patients_resource_selectors";
import { createStudiesForRevisionsSelector } from "../../studies/create_studies_for_revisions_selector";

/**
 * create a selector with an array of revisions that returns patients by revision id
 * @param {Object} props
 * @param {array} revisions
 * @returns {Function} selector
 */
export function createPatientsForRevisionsSelector({ revisions }) {
  return createSelector(
    patientsResourceSelectors.dataSelector,
    createStudiesForRevisionsSelector({ revisions }),
    (patients, studiesByRevisionId) => {
      return Object.keys(studiesByRevisionId).reduce((agg, revId) => {
        const study = studiesByRevisionId[revId];
        const patientId = get(study, "attributes.patientId");
        agg[revId] = patients[patientId];
        return agg;
      }, {});
    }
  );
}
