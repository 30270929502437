import { dataActionTypes } from "src/action_types/data_action_types";
import { loadStates } from "src/constants/load_states";

const defaultState = {
  loadState: loadStates.loading,
  data: {}
};

/**
 * Reducer for survey tally data
 */
export function surveyWoundDetailsReducer(state = defaultState, action) {
  switch (action.type) {
    case dataActionTypes.populateSurveyWoundDetails:
      return {
        data: action.payload,
        loadState: "loaded"
      };

    case dataActionTypes.surveyWoundDetailsLoading:
      return { ...state, loadState: loadStates.loading };

    case dataActionTypes.surveyWoundDetailsLoadError:
      return { ...state, loadState: loadStates.error };

    default:
      return state;
  }
}
