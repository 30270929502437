import { createSelector } from "reselect";
import moment from "moment";
import sortedUniqBy from "lodash.sorteduniqby";

import { createStudyForPatientIdSelector } from "src/selectors/data_selectors";
import { assessmentAnswersResourceSelectors } from "../assessment_answers_resource_selectors";

/**
 * create selector for the most recent non wound assessment answers of each assessment type for a patient id
 * @param {string} patientId
 * @returns {Function} selector
 */
export function createNonWoundAssessmentAnswersForPatientIdSelector(patientId) {
  return createSelector(
    createStudyForPatientIdSelector(patientId),
    assessmentAnswersResourceSelectors.dataSelector,
    (study, assessmentAnswers) => {
      if (!study) {
        return [];
      }
      let filteredAssessmentAnswers = Object.values(assessmentAnswers)
        .filter(aa => {
          return (
            aa.attributes.resourceId == study.id &&
            aa.attributes.resourceType == "Study" &&
            aa.attributes.completed
          );
        })
        .sort((aa1, aa2) => {
          return moment(aa1.attributes.createdAt).isAfter(
            aa2.attributes.createdAt
          )
            ? -1
            : 1;
        });

      // Don't show struck out assessment answer groups
      const struckOutGroupIds = filteredAssessmentAnswers.reduce((agg, aa) => {
        if (aa.attributes.strikeoutState == "stricken") {
          agg.push(aa.attributes.assessmentAnswerGroupId);
        }
        return agg;
      }, []);

      filteredAssessmentAnswers = filteredAssessmentAnswers.filter(aa => {
        return !struckOutGroupIds.includes(
          aa.attributes.assessmentAnswerGroupId
        );
      });

      return sortedUniqBy(
        filteredAssessmentAnswers,
        aa => aa.attributes.assessmentAnswerGroupId
      ).reduce((acc, aa1) => {
        const ids = acc.map(aa => aa.attributes.assessmentId);
        if (!ids.includes(aa1.attributes.assessmentId)) {
          acc.push(aa1);
        }
        return acc;
      }, []);
    }
  );
}
