import { createSelector } from "reselect";
import get from "lodash.get";

import { usersResourceSelectors } from "src/selectors/data_selectors";
import { createAllPagesCliniciansContextSelector } from "src/selectors/api_selectors/global/users";

export const clinicianFilterConfigSelector = createSelector(
  usersResourceSelectors.createDataForContextSelector(
    createAllPagesCliniciansContextSelector()
  ),
  clinicians => {
    let arr = [];
    Object.values(clinicians).forEach(clinician => {
      if (
        !clinician.attributes.profile ||
        (!clinician.attributes.profile.firstName &&
          !clinician.attributes.profile.lastName)
      )
        return;
      arr.push({
        key: "user_id",
        value: clinician.id,
        name:
          get(clinician, "attributes.profile.firstName", "") +
          " " +
          get(clinician, "attributes.profile.lastName", "")
      });
    });

    return {
      filterName: "clinicianOptions",
      filterValues: arr
    };
  }
);
