import { connect } from "react-redux";
import flowRight from "lodash.flowright";

import { WoundsWoundTabPDFButtonComponent } from "src/components/wounds_wound_tab_pdf_button_component";

// Selectors
import { selectedDateSelector } from "src/selectors/section_selectors/wounds";
import { selectedOrganizationSelector } from "src/selectors/data_selectors";
import { woundAssessmentsResourceSelectors } from "src/selectors/data_selectors";
import { assessmentsFirstPageContextAllProgressValuesSelector } from "src/selectors/context_selectors/wounds";
import { progressValuesWithoutHealedSelector } from "src/selectors/constant_selectors";
import { createAssessmentsFirstPageContextSelectorWithProgress } from "src/selectors/context_selectors/wounds";

import { woundsSectionSelector } from "src/selectors/section_selectors/wounds";
import { woundsClinicalChartResourceSelector } from "src/selectors/data_selectors";
import { createAllWeeksWoundsClinicalChartDataContextSelector } from "src/selectors/api_selectors/wounds/wounds_chart";
import { woundsClinicalChartDefinitionSelector } from "src/selectors/chart_definition_selectors";
import {
  brandingSelector,
  brandedLogoPngSelector
} from "src/selectors/ui_config_selectors/global";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

import SingleValueReduxUtil from "src/ui_processors/single_value";

const selectedTabSelectors = SingleValueReduxUtil.createSelectors(
  "selectedTab",
  woundsSectionSelector
);

function mapStateToProps(state) {
  const allWeeksContextSelector = createAllWeeksWoundsClinicalChartDataContextSelector(
    state
  );

  const progressValues = progressValuesWithoutHealedSelector(state);
  const firstPageContextsLoadStates = {};
  progressValues.forEach(progress => {
    const context = createAssessmentsFirstPageContextSelectorWithProgress(
      progress
    )(state);
    firstPageContextsLoadStates[
      progress
    ] = woundAssessmentsResourceSelectors.createLoadStateForContextSelector(
      context
    )(state);
  });

  return {
    selectedDate: selectedDateSelector(state),
    selectedOrganization: selectedOrganizationSelector(state),
    chartDefinition: woundsClinicalChartDefinitionSelector(state),
    counts: woundAssessmentsResourceSelectors.createRecordCountSelector(
      assessmentsFirstPageContextAllProgressValuesSelector
    )(state),
    progressValues,
    disabled: selectedTabSelectors.fieldSelector(state) !== "chart", //TODO: create an enabled/disabled selector
    chartLoadStates: woundsClinicalChartResourceSelector.createLoadStateForContextSelector(
      allWeeksContextSelector
    )(state),
    tabsLoadStates: firstPageContextsLoadStates,
    branding: brandingSelector(state),
    brandedLogoPng: brandedLogoPngSelector(state),
    assessmentVersion: assessmentVersionSelector(state)
  };
}

export const WoundsWoundTabOptimizedPdfButtonContainer = flowRight(
  connect(mapStateToProps)
)(WoundsWoundTabPDFButtonComponent);
