import { alertLabels } from "./alert_labels";
import { filters } from "./filters";
import { refreshPage } from "./refresh_page";
import { searchBarPlaceholders } from "./search_bar_placeholders";
import { surveyChartTooltips } from "./survey_chart_tooltips";
import { surveyPDFChartAcquiredTooltips } from "./survey_PDF_chart_acquired_tooltips";
import { surveyTableTooltips } from "./survey_table_tooltips";
import { seriesTrendsChartsPDFTitles } from "./series_trends_charts_PDF_tltes";
import { descriptions } from "./descriptions";
import { revisionButtonTooltips } from "./revision_button_tooltips";
import { alertRuleFormLabels } from "./alert_rule_form_labels";
import { evaluationListLabels } from "./evaluation_list";
import { chartLegends } from "./chart_legends";
import { healXMessages } from "./heal_x_messages";

export const text = {
  alertLabels,
  filters,
  searchBarPlaceholders,
  refreshPage,
  surveyChartTooltips,
  surveyPDFChartAcquiredTooltips,
  surveyTableTooltips,
  seriesTrendsChartsPDFTitles,
  alertRuleFormLabels,
  notSet: "Not Set",
  descriptions,
  evaluationListLabels,
  revisionButtonTooltips,
  chartLegends,
  evaluationListLabels,
  healXMessages,
  undiagnosed: "Undiagnosed",
  evaluation: "Evaluation"
};
