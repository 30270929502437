// router
import { NavLink } from "react-router-dom";

// React
import React from "react";

//styles
import styles from "./style.module.less";
import { SideBarAdminOrganizationFilterContainer } from "src/containers/side_bar_admin_organization_filter_container";
import { SideBarAdmissionStatusFilterContainer } from "src/containers/side_bar_adminssion_status_filter_container";
import { SideBarMonitorAtHomeStatusFilterContainer } from "src/containers/side_bar_monitor_at_home_status_filter_container";
export class SideBarAdminNavigationComponent extends React.Component {
  render() {
    const {
      usersSectionEnabled,
      admissionFilterEnabled,
      organizationFilterEnabled,
      alertsSectionEnabled,
      patientsSectionEnabled,
      monitorAtHomeStatusFilterEnabled
    } = this.props;
    return (
      <div>
        <ul className={styles["filter-component-list"]}>
          {usersSectionEnabled && (
            <li>
              <NavLink to="/admin/users">Users, Roles and Permissions</NavLink>
            </li>
          )}
          {alertsSectionEnabled && (
            <li>
              <NavLink to="/admin/alerts">Alerts</NavLink>
            </li>
          )}
          {patientsSectionEnabled && (
            <li>
              <NavLink to="/admin/patients">Patients</NavLink>
            </li>
          )}
          {/* <li>
            <NavLink to="/admin/messaging">Alerts and Messaging</NavLink>
          </li>
          <li>
            <NavLink to="/admin/workflows">Workflows</NavLink>
          </li>
          <li>
            <NavLink to="/admin/assessments">Assessments</NavLink>
          </li>
          <li>
            <NavLink to="/admin/careplans">Care Plans</NavLink>
          </li>
          <li>
            <NavLink to="/admin/orders">Orders</NavLink>
          </li>
          <li>
            <NavLink to="admin/notes">Notes</NavLink>
          </li> */}
        </ul>
        {organizationFilterEnabled && (
          <div className={`${styles["margin-top-bottom"]}`}>
            <SideBarAdminOrganizationFilterContainer />
          </div>
        )}

        {admissionFilterEnabled && (
          <div className={`${styles["margin-top-bottom"]}`}>
            <SideBarAdmissionStatusFilterContainer />
          </div>
        )}

        {monitorAtHomeStatusFilterEnabled && (
          <div className={`${styles["margin-top-bottom"]}`}>
            <SideBarMonitorAtHomeStatusFilterContainer />
          </div>
        )}
      </div>
    );
  }
}
