import { patientsActionTypes } from "src/action_types/section_action_types";

/**
 * Action to select locations given array of ids
 * @param {Array} locIds array of location ids
 * @return {Object} Action
 */
export function selectLocations(locIds) {
  return {
    type: patientsActionTypes.selectLocations,
    payload: locIds
  };
}

/**
 * Action to deselect locations given array of ids
 * @param {Array} locIds array of location ids
 * @return {Object} Action
 */
export function deselectLocations(locIds) {
  return {
    type: patientsActionTypes.deselectLocations,
    payload: locIds
  };
}

/**
 * Action creator to set the search string for the patient filter
 * @param {String} searchString the search string
 * @return {Object} redux action
 */
export function setPatientSearchStringAction(searchString) {
  return {
    type: patientsActionTypes.setPatientSearchString,
    payload: searchString
  };
}

export function enableRevisionToBeEditedAction(revisionId) {
  return {
    type: patientsActionTypes.enableRevisionToBeEdited,
    payload: revisionId
  };
}
