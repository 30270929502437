import { createSelector } from "reselect";
import { usersResourceSelectors } from "../users_resource_selectors";

/**
 * Create a selector to find a user by id
 * @param {string} userId
 * @returns {Function} selector
 */
export function createUserForIdSelector(userId) {
  return createSelector(
    usersResourceSelectors.dataSelector,
    users => {
      return users[userId];
    }
  );
}
