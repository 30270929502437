export const progressValues = [
  "new",
  "worse",
  "stalled",
  "stable",
  "better",
  "healed",
  "unknown"
];

export const progressValuesV5 = [
  "new",
  "worse",
  "stalled",
  "stable",
  "better",
  "monitoring",
  "healed",
  "unknown"
];
