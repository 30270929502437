import React from "react";
import PropTypes from "prop-types";

// Constants
import { loadStates } from "src/constants";

// Components
import { LoadingComponent } from "src/components/loading_component";
import { LoadingErrorComponent } from "src/components/loading_error_component";
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";

class EmptyComponent extends React.PureComponent {
  render() {
    return null;
  }
}

/**
 * HOC factory that creates a HOC that handles loading
 * @param {Object} options
 * @param {string} options.analyticsEventName
 * @param {bool} options.showProgressBar
 * @param {bool} options.performRetriesSynchronously
 */
export function withLoadingComponentFactory({
  analyticsEventName,
  showProgressBar,
  performRetriesSynchronously
}) {
  return function withLoadingComponent(WrappedComponent) {
    return class LoadingManagerComponent extends React.PureComponent {
      static propTypes = {
        loadingObjectsByContext: PropTypes.object.isRequired
      };

      /**
       * Handler for clicking retry - this will retry for every failed context
       */
      onRetryClicked = () => {
        const { loadingObjectsByContext } = this.props;
        const errorContexts = this.getErrorContexts();

        if (performRetriesSynchronously) {
          errorContexts.reduce((p, context) => {
            return p.then(() => {
              return loadingObjectsByContext[context].retry(
                loadingObjectsByContext[context]
              );
            });
          }, Promise.resolve());
        } else {
          errorContexts.forEach(context => {
            loadingObjectsByContext[context].retry(
              loadingObjectsByContext[context]
            );
          });
        }
      };

      /**
       * Get all the contexts for which an error occurred
       * @returns {array}
       */
      getErrorContexts() {
        const { loadingObjectsByContext } = this.props;
        return Object.keys(loadingObjectsByContext).filter(context => {
          return loadingObjectsByContext[context].loadState == loadStates.error;
        });
      }

      getProgress() {
        const contextCount = Object.keys(this.props.loadingObjectsByContext)
          .length;
        const loadedContextCount = Object.values(
          this.props.loadingObjectsByContext
        ).filter(loadingObject => {
          return loadingObject.loadState == loadStates.loaded;
        }).length;

        if (!loadedContextCount || !contextCount) return 0;

        return loadedContextCount / contextCount;
      }

      render() {
        const { loadingObjectsByContext } = this.props;

        // Loading state
        if (
          !loadingObjectsByContext ||
          !Object.keys(loadingObjectsByContext).length ||
          Object.values(loadingObjectsByContext).some(
            loadingObject =>
              !loadingObject.loadState ||
              loadingObject.loadState == loadStates.loading
          )
        ) {
          return (
            <StartRenderTimeTrackComponent eventName={analyticsEventName}>
              <LoadingComponent
                showProgress={showProgressBar}
                progress={showProgressBar ? this.getProgress() : undefined}
              />
            </StartRenderTimeTrackComponent>
          );
        }

        // Error View
        const errorContexts = this.getErrorContexts();

        if (errorContexts.length) {
          return (
            <FinishRenderTimeTrackComponent
              eventName={analyticsEventName}
              error={true}
            >
              <LoadingErrorComponent retry={this.onRetryClicked} />
            </FinishRenderTimeTrackComponent>
          );
        }

        // Success View
        const child = WrappedComponent ? (
          <WrappedComponent {...this.props} />
        ) : (
          <EmptyComponent />
        );
        return (
          <FinishRenderTimeTrackComponent eventName={analyticsEventName}>
            {child}
          </FinishRenderTimeTrackComponent>
        );
      }
    };
  };
}
