import React from "react";
import PropTypes from "prop-types";

// Components
import Select from "src/ui-kit/Select";

/**
 * React Component that will be used to
 * select the records per page or select all records
 */
export class TablePageLimitDropdownComponent extends React.Component {
  static propTypes = {
    selectedPageLimit: PropTypes.number.isRequired,
    selectPageLimit: PropTypes.func.isRequired
  };

  onChangePageLimit = value => {
    this.props.selectPageLimit(parseInt(value));
  };

  render() {
    const { selectedPageLimit } = this.props;

    const options = [
      {
        label: "25",
        value: 25
      },
      {
        label: "50",
        value: 50
      },
      {
        label: "100",
        value: 100
      }
    ];

    return (
      <div>
        <Select
          value={selectedPageLimit}
          onChange={this.onChangePageLimit}
          options={options}
        />
      </div>
    );
  }
}
