import { createResourceActions } from "src/redux_resources_v1";
import normalize from "json-api-normalizer";
import get from "lodash.get";

// Error tracking
import { trackError } from "src/error_tracking";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";
import { camelizeKeys } from "humps";

/**
 * Create a load data action creator
 * @param {Object} options
 * @param {string} options.resourceName resource name
 * @param {array} options.includedResourceNames resources names of "included" resources
 * @param {Object} options.api api object that responds to "get" call
 * @param {Function} options.responseNormalizer function to override normalizing the response
 * @param {string} options.analyticsEvent override the default event name for analytics
 * @param {Function} options.onSuccess callback function to run when request is successfull (optional)
 * @param {Function} options.onError callback function to run when request fails (optional)
 * @returns {Function} action creator for a thunk action to load the resource
 */
export function createLoadDataAction({
  resourceName,
  includedResourceNames,
  api,
  responseNormalizer,
  onSuccess,
  onError,
  sendAdditionalActions
}) {
  return ({ context, query, payload, additionalActions }) => {
    const analyticsEventName = `API_GET_${resourceName.toUpperCase()}`;
    const resourceActions = createResourceActions(resourceName);

    return dispatch => {
      dispatch(resourceActions.loadingForContextAction(context));
      startTimeTrack(analyticsEventName);

      return api
        .get({ query, payload })
        .then(response => {
          finishTimeTrack(analyticsEventName);

          let normalizedData;
          if (responseNormalizer) {
            normalizedData = responseNormalizer(response.data, { query });
          } else {
            normalizedData = normalize(response.data);
          }

          if (includedResourceNames) {
            includedResourceNames.forEach(includedResourceName => {
              if (normalizedData[includedResourceName]) {
                const includedResourceActions = createResourceActions(
                  includedResourceName
                );
                dispatch(
                  includedResourceActions.populateAction({
                    data: normalizedData[includedResourceName]
                  })
                );
              }
            });
          }

          if (normalizedData[resourceName]) {
            dispatch(
              resourceActions.populateAction({
                contexts: context,
                data: normalizedData[resourceName],
                ids: Object.keys(normalizedData[resourceName]),
                links: response.data.links,
                meta: camelizeKeys(response.data.meta)
              })
            );
          } else {
            dispatch(
              resourceActions.populateAction({
                contexts: context,
                data: [],
                ids: [],
                links: response.data.links,
                meta: camelizeKeys(response.data.meta)
              })
            );
          }

          if (sendAdditionalActions && additionalActions.length > 0) {
            sendAdditionalActions(dispatch, normalizedData, additionalActions);
          }

          if (onSuccess) {
            onSuccess(dispatch);
          }

          return response;
        })
        .catch(err => {
          dispatch(resourceActions.loadErrorForContextAction(context));
          trackError(err);
          finishTimeTrack(analyticsEventName, {
            error: true,
            errorCode: get(err, "response", "")
              ? get(err, "response.status", "")
              : undefined
          });
          if (onError) {
            onError(dispatch, err);
          }

          return err;
        });
    };
  };
}
