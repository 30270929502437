export function createListStringFromArray(array) {
  if (!array.length) return "";

  let listText = "";
  if (array.length > 1) {
    listText = array
      .slice(0, array.length - 1)
      .join(", ")
      .concat(` and ${array[array.length - 1]}`);
  } else {
    listText = array[0];
  }
  return listText;
}
