// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Translations
import i18n from "src/translations";

// Api
import { usersApi, singleUserApi } from "src/api/users";

// Actions
import { createLoadDataAction } from "../create_load_data_action";

// Analytics
import { track } from "src/analytics";

export const usersResourceActions = createResourceActions(resourceNames.users);

export const loadUsersAction = createLoadDataAction({
  resourceName: resourceNames.users,
  api: usersApi
});

export const loadCurrentUserAction = createLoadDataAction({
  resourceName: resourceNames.users,
  api: singleUserApi,
  includedResourceNames: ["policies", "roles"]
});

//Functions
import { createUserPayloadFromPatientPayload } from "src/utils/create_user_payload_from_patient_payload";

/* ------------ Legacy -------------- */
import normalize from "json-api-normalizer";
import { camelizeKeys } from "humps";

// Tracking
import { trackError } from "src/error_tracking";
import { startTimeTrack, finishTimeTrack } from "src/analytics";

//API
import {
  fetchUsersByQueryString,
  fetchUser,
  createUser,
  updateUser,
  sendWelcomeMessage
} from "src/api/users";

import { populatePolicies } from "src/actions/data_actions/policies";

import { create } from "src/actions/flashMessageActions";
import { dataActionTypes } from "src/action_types/data_action_types";

/**
 * Action creator for loading users by query string
 * @param {string} queryString
 * @param {Object} payload for filtering the records
 * @return {Object} redux action
 */
export function loadUsersByQueryStringAction(queryString, payload) {
  return dispatch => {
    startTimeTrack("API_GET_USERS_BY_QUERY_STRING");
    dispatch(usersResourceActions.loadingForContextAction([queryString]));
    return fetchUsersByQueryString(payload)
      .then(response => {
        finishTimeTrack("API_GET_USERS_BY_QUERY_STRING");
        let users = normalize(response.data).users;
        dispatch(
          usersResourceActions.replaceAction({
            contexts: [queryString],
            data: users,
            ids: users ? Object.keys(users) : [],
            links: response.data.links,
            meta: camelizeKeys(response.data.meta)
          })
        );
      })
      .catch(err => {
        // If the request was cancelled it means a new request started
        // don't show the loading view.
        if (err.message == "cancel") {
          return;
        }
        trackError(err);
        dispatch(usersResourceActions.loadErrorForContextAction(queryString));
        finishTimeTrack("API_GET_USERS_BY_QUERY_STRING", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
      });
  };
}

/**
 * Action creator for populate the current user in the store
 * @param {Object} currentUser object of current user
 * @return {Object} redux action
 */
function populateCurrentUser(currentUser) {
  return {
    type: dataActionTypes.populateCurrentUserData,
    payload: currentUser
  };
}

/**
 * Action creator to fetch current user data
 * @param {Object} contexts
 * @return {Object} redux action
 */
export function loadUserAction(userId) {
  return dispatch => {
    startTimeTrack("API_GET_USER");
    fetchUser(userId)
      .then(response => {
        finishTimeTrack("API_GET_USER");
        const normalizedData = normalize(response.data);
        const users = normalizedData.users;
        const policies = normalizedData.policies;

        //load the cusrrent user
        dispatch(populateCurrentUser(users[userId]));

        if (policies) dispatch(populatePolicies(["currentUser"], response));
      })

      .catch(error => {
        // If the request was cancelled it means a new request started
        // don't show the loading view.
        if (error.message == "cancel") {
          return;
        }
        finishTimeTrack("API_GET_ROLES", { error: true });
        trackError(error);
        dispatch(
          usersResourceActions.loadErrorForContextAction(["currentUser"])
        );
      });
  };
}

/**
 * Action creator for create user
 * @param {Object} payload
 * @return {Object} redux action
 */
export function createUserAction(
  payload,
  patientId,
  isPatientUser,
  currentUserId,
  userPasswordMinLength
) {
  return dispatch => {
    dispatch(usersResourceActions.loadingPostAction());
    if (isPatientUser) {
      payload = createUserPayloadFromPatientPayload(
        payload,
        patientId,
        userPasswordMinLength
      );
    }
    return createUser(payload)
      .then(response => {
        const users = normalize(response.data).users;
        dispatch(
          usersResourceActions.populatePostAction({
            data: users
          })
        );
        if (isPatientUser) {
          dispatch(
            create(
              i18n.t("interaction.successMessages.patientUser.patientCreated"),
              "success"
            )
          );
          const user = Object.values(users)[0];
          const userId = user.id;
          const patientId = user.attributes.ownPatientId;
          dispatch(
            createWelcomeMessage(userId, patientId, false, currentUserId)
          );
        } else {
          dispatch(
            create(
              i18n.t("interaction.successMessages.userActions.userCreated"),
              "success"
            )
          );
        }
      })
      .catch(err => {
        if (isPatientUser) {
          dispatch(
            create(
              i18n.t("interaction.errorMessages.patientUser.patientUserError"),
              "error"
            )
          );
        } else if (err.response.status == 422) {
          dispatch(
            create(
              i18n.t("interaction.errorMessages.userActions.emailTaken"),
              "error"
            )
          );
        } else {
          dispatch(
            create(
              i18n.t("interaction.errorMessages.userActions.userCreateError"),
              "error"
            )
          );
        }
        trackError(err);
        dispatch(usersResourceActions.loadErrorPostAction());
      });
  };
}

/**
 * Action creator for update user
 * @param {Object} payload
 * @return {Object} thunk action
 */
export function updateUserAction(
  payload,
  patientId,
  isPatientUser,
  patientUserId,
  patientEmailChange,
  currentUserId,
  userPasswordMinLength
) {
  return dispatch => {
    if (isPatientUser) {
      payload = createUserPayloadFromPatientPayload(
        payload,
        patientId,
        userPasswordMinLength,
        patientUserId
      );
    }
    dispatch(usersResourceActions.loadingPatchAction([payload.id]));
    return updateUser(payload)
      .then(response => {
        const users = normalize(response.data).users;
        dispatch(
          usersResourceActions.populatePatchAction({
            data: {},
            //Sending empty user data to populatePatch, to prevent change in store. API does not return all required relationships on patch requests.
            ids: [users.id]
          })
        );
        if (isPatientUser) {
          if (patientEmailChange) {
            const user = Object.values(users)[0];
            const userId = user.id;
            const patientId = user.attributes.ownPatientId;
            dispatch(
              createWelcomeMessage(userId, patientId, false, currentUserId)
            );
          }
          dispatch(
            create(
              i18n.t("interaction.successMessages.patientUser.patientUpdated"),
              "success"
            )
          );
        } else {
          dispatch(
            create(
              i18n.t("interaction.successMessages.userActions.userUpdated"),
              "success"
            )
          );
        }
      })
      .catch(err => {
        if (isPatientUser) {
          dispatch(
            create(
              i18n.t(
                "interaction.errorMessages.patientUser.patientUserUpdateError"
              ),
              "error"
            )
          );
        } else if (err.response.status == 422) {
          dispatch(
            create(
              i18n.t("interaction.errorMessages.userActions.emailTaken"),
              "error"
            )
          );
        } else {
          dispatch(
            create(
              i18n.t("interaction.errorMessages.userActions.userUpdateError"),
              "error"
            )
          );
        }
        trackError(err);
        dispatch(usersResourceActions.loadErrorPatchAction([payload.id]));
      });
  };
}

export function createWelcomeMessage(
  userId,
  patientId,
  isReSend,
  currentUserId
) {
  return dispatch => {
    sendWelcomeMessage(userId)
      .then(() => {
        dispatch(
          create(
            i18n.t(
              "interaction.successMessages.patientUser.welcomeMessageSent"
            ),
            "success"
          )
        );
        const mixpanel_properties = {
          patientUserID: userId,
          patientID: patientId,
          userID: currentUserId
        };
        if (isReSend) track("RESEND_PATIENT_INVITATION", mixpanel_properties);
        else track("PATIENT_INVITATION_SENT", mixpanel_properties);
        return;
      })
      .catch(() => {
        dispatch(
          create(
            i18n.t(
              "interaction.errorMessages.patientUser.welcomeMessageFailed"
            ),
            "error"
          )
        );
      });
  };
}
