import { tallyTypes } from "src/constants";
import { getSubtypeStringForAnswersJson } from "src/utils/get_wound_subtype_string";
import get from "lodash.get";
import i18n from "src/translations";

/**
 * Get the display string for a tally
 * @param {Object} tally
 * @returns {string}
 */
export function getContextStringForTally(
  tally,
  schemaLocalizationDictionary,
  subtypeKeysForSchema
) {
  if (tally.attributes.type == tallyTypes.total) return "Total";

  const { woundType } = tally.attributes.context;

  if (!woundType) return "";

  const subtypeKey = Object.values(subtypeKeysForSchema).find(value => {
    return !!tally.attributes.context[value];
  });

  if (subtypeKey) {
    return (
      getSubtypeStringForAnswersJson({
        woundType,
        answersJson: get(tally, "attributes.context", {}),
        localizationDictionary: schemaLocalizationDictionary,
        subtypeKeysForSchema
      }) || i18n.t("text.notSet")
    );
  } else {
    return woundType == "undiagnosed"
      ? "Undiagnosed"
      : schemaLocalizationDictionary[`woundType.${woundType}`];
  }
}
