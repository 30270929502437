import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash.get";

// Components
import { GridRow, GridColumn } from "src/components/grid";

// Model
import { userFullName } from "src/models/users";

// Utils
import { capitalizeFirstLetter } from "src/utils/capitalize_first_letter";

//Translations
import i18n from "src/translations";

export class AssessmentAnswerHistoryListItemComponent extends React.PureComponent {
  static propTypes = {
    assessmentAnswer: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  render() {
    const { assessmentAnswer, user } = this.props;

    const headerTitle = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.answersJson.header.title",
        ""
      ),
      headerType = get(
        { assessmentAnswer },
        "assessmentAnswer.attributes.answersJson.header.type",
        ""
      );

    let assessmentTitle = headerTitle
      ? headerTitle.replace("Assessment", i18n.t("text.evaluation"))
      : `${capitalizeFirstLetter(headerType)} ${i18n.t("text.evaluation")}`;

    const assessmentAnswerCreatedAt = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.createdAt",
      null
    );

    let createdAt = "";
    if (assessmentAnswerCreatedAt) {
      createdAt = moment(assessmentAnswerCreatedAt).fromNow();
    }
    const completedBy = userFullName(user);

    return (
      <GridRow>
        <GridColumn xs="6">{`Updated ${assessmentTitle}`}</GridColumn>
        <GridColumn xs="3">{completedBy}</GridColumn>
        <GridColumn xs="3">{createdAt}</GridColumn>
      </GridRow>
    );
  }
}
