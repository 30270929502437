import { createAverageHealingIndexQueryForRiskCategorySelector } from "src/selectors/api_selectors/global/healing_index/create_average_healing_index_query_for_risk_category_selector";
import { createSelector } from "reselect";

export function createAverageHealingIndexContextSelector(ownProps) {
  return createSelector(
    createAverageHealingIndexQueryForRiskCategorySelector(ownProps),
    queries => {
      return queries;
    }
  );
}
