import get from "lodash.get";
/**
 * cell formatter for alert rules applies to
 * @param {Object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.appliesTo
 */
export function alertRulesAppliesToFormatter(cell, row) {
  const rolesString = get(row, "attributes.roles", [])
    .map(role => role.primaryName)
    .join(", ");
  return rolesString;
}
