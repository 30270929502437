import { connect } from "react-redux";
import { PopulationSectionComponent } from "src/components/population_section_component";

// Selectors
import {
  selectedWoundTypesSelector,
  dateSelectionSelector,
  selectedLocationIdsSelector,
  acquiredFilterSelector
} from "src/selectors/section_selectors/population";
import { populationTallyExportLoadStateSelector } from "src/selectors/data_selectors";
import { populationSectionVersionSelector } from "src/selectors/config_selectors";

//Legacy Selectors
import {
  populationIncidenceChartDataLoadStateSelector,
  populationPrevalenceChartDataLoadStateSelector
} from "src/selectors/data_selectors";

// Actions
import { setDateSelection } from "src/actions/section_actions/population";
import { createAndDownloadPopulationTallyCsv } from "src/actions/data_actions/export";

import { routePathnameSelector } from "src/selectors/router_selectors";
function mapStateToProps(state) {
  return {
    selectedLocationIds: selectedLocationIdsSelector(state),
    selectedWoundTypes: selectedWoundTypesSelector(state),
    dateSelection: dateSelectionSelector(state),
    acquiredFilter: acquiredFilterSelector(state),
    populationTallyExportLoadState: populationTallyExportLoadStateSelector(
      state
    ),
    populationPrevalenceChartLoadState: populationPrevalenceChartDataLoadStateSelector(
      state
    ),
    populationIncidenceChartLoadState: populationIncidenceChartDataLoadStateSelector(
      state
    ),
    routePathname: routePathnameSelector(state),
    populationSectionVersion: populationSectionVersionSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDateSelection(selection) {
      dispatch(setDateSelection(selection));
    },
    sendCreateAndDownloadCsvAction(payload) {
      dispatch(createAndDownloadPopulationTallyCsv(payload));
    }
  };
}
export const PopulationSectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PopulationSectionComponent);
