export function renderHealingIndexMean(
  healingIndexMean,
  healingIndexCategoryList
) {
  if (!healingIndexMean) {
    return "N/A";
  }

  let roundHealingIndexMean = "";

  if (!healingIndexCategoryList && healingIndexMean) {
    roundHealingIndexMean = `, ${Math.floor(
      Number(healingIndexMean.toFixed(2)) * 100
    )}`;
  }

  switch (true) {
    case healingIndexMean >= 0 && healingIndexMean < 0.2:
      return `Very Low` + roundHealingIndexMean;
    case healingIndexMean >= 0.2 && healingIndexMean < 0.4:
      return `Low` + roundHealingIndexMean;
    case healingIndexMean >= 0.4 && healingIndexMean < 0.6:
      return `Medium` + roundHealingIndexMean;
    case healingIndexMean >= 0.6 && healingIndexMean < 0.8:
      return `High` + roundHealingIndexMean;
    case healingIndexMean >= 0.8 && healingIndexMean <= 1.0:
      return `Very High` + roundHealingIndexMean;
    default:
      return "N/A";
  }
}
