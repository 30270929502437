import React, { useEffect } from "react";
import { CheckboxFilterComponent } from "src/components/filters";
import useComplianceFilter from "src/pages/compliance/hooks/useComplianceFilter";

const LegacyFilterSection = props => {
  const { overrideFilters, enabledFilters } = useComplianceFilter(
    props.filterKey
  );
  const { legacyCheckedFilters } = props;

  const filtersChanged = () =>
    JSON.stringify(legacyCheckedFilters) !== JSON.stringify(enabledFilters);

  useEffect(() => {
    if (filtersChanged()) {
      overrideFilters(legacyCheckedFilters);
    }
  }, [legacyCheckedFilters]);

  return (
    <CheckboxFilterComponent
      selectAllEnabled={props.selectAllEnabled}
      schemaLocalizationDictionary={props.schemaLocalizationDictionary}
      filterConfig={props.filterConfig}
      filterCheckedState={props.filterCheckedState}
      selectCheckbox={props.selectCheckbox}
      deselectCheckbox={props.deselectCheckbox}
    />
  );
};

export default LegacyFilterSection;
