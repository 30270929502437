import normalize from "json-api-normalizer";

// Constants
import { resourceNames } from "src/constants";

import { woundRevisionsApi } from "src/api/wound_revisions";

import { createLoadDataAction } from "src/actions/data_actions/create_load_data_action";

// Model
import { mapMeasurementsToValues } from "src/models/revisions";

export const loadWoundRevisionsAction = createLoadDataAction({
  resourceName: resourceNames.woundRevisions,
  api: woundRevisionsApi,
  responseNormalizer: response => {
    // Normalize woundRevisions
    const normalized = normalize(response);
    let woundRevisions = normalized.woundRevisions || {};
    woundRevisions = Object.values(woundRevisions).reduce(
      (agg, woundRevisions) => {
        agg[woundRevisions.id] = mapMeasurementsToValues(woundRevisions);
        return agg;
      },
      {}
    );

    return {
      [resourceNames.woundRevisions]: woundRevisions
    };
  }
});
