import React from "react";
import PropTypes from "prop-types";

// css
import styles from "./style.module.less";

export class Glyphicon extends React.PureComponent {
  static propTypes = {
    glyph: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string
  };

  render() {
    const { glyph, className, size } = this.props;
    const glyphiconClass = `glyphicon glyphicon-${glyph}`;
    const fontSize = size ? size : "16px";
    return (
      <div
        className={`${styles[className]} ${styles[`icon-wrapper`]}`}
        onClick={this.props.onClick}
      >
        <span
          className={`${glyphiconClass}`}
          style={{ fontSize: `${fontSize}` }}
        />
      </div>
    );
  }
}
