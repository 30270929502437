import get from "lodash.get";

export function userFullName(user) {
  let fullName = "";
  let firstName = get(user, "attributes.profile.firstName");
  let lastName = get(user, "attributes.profile.lastName");

  if (firstName) fullName += firstName;

  if (firstName && lastName) fullName += " ";

  if (lastName) fullName += lastName;

  return fullName;
}
