import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import flowRight from "lodash.flowright";

// HOC
import { withNonWoundAssessmentAnswersAndSchemasForStudyLoader } from "src/hoc";

// Components
import { NonWoundAssessmentListComponent } from "src/components/non_wound_assessment_list_component";

// Selectors
import {
  createNonWoundAssessmentAnswersForPatientIdSelector,
  assessmentAnswersResourceSelectors,
  assessmentsResourceSelectors
} from "src/selectors/data_selectors";
import { janssenDashboardEnabledSelector } from "src/selectors/ui_config_selectors/global";
import { createNonWoundAssessmentAnswersLoaderContextSelector } from "src/selectors/section_selectors/patients";

function mapStateToProps(state, ownProps) {
  const patientId = ownProps.match.params.patientId;
  const contextSelector = createNonWoundAssessmentAnswersLoaderContextSelector(
    patientId
  );

  return {
    assessmentAnswers: createNonWoundAssessmentAnswersForPatientIdSelector(
      ownProps.match.params.patientId
    )(state),
    assessmentAnswersLoadState: assessmentAnswersResourceSelectors.createContextLoadStateSelectorWithContextStringSelector(
      contextSelector
    )(state),
    assessmentsContextLoadState: assessmentsResourceSelectors.contextLoadStateSelector(
      state
    ),
    janssenDashboardEnabled: janssenDashboardEnabledSelector(state)
  };
}

export const NonWoundAssessmentListContainer = flowRight(
  withNonWoundAssessmentAnswersAndSchemasForStudyLoader,
  withRouter,
  connect(mapStateToProps)
)(NonWoundAssessmentListComponent);
