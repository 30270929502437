import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// HOC
import { withRevisionsLoader } from "src/hoc";

// Components
import { RevisionsListComponent } from "src/components/revisions_list_component";

// Selectors
import { seriesResourceSelectors } from "src/selectors/data_selectors";
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";

function mapStateToProps(state, ownProps) {
  const seriesId = ownProps.series.id;

  return {
    revisions: revisionsResourceSelectors.createDataForContextSelectorWithString(
      seriesId
    )(state),
    revisionsMeta: revisionsResourceSelectors.createMetaContextSelectorWithString(
      seriesId
    )(state),
    revisionsLoadState: revisionsResourceSelectors.createContextLoadStateSelectorWithContextString(
      seriesId
    )(state),
    seriesLoadState: seriesResourceSelectors.createContextLoadStateSelectorWithContextString(
      seriesId
    )(state)
  };
}

export const RevisionsListContainer = withRevisionsLoader(
  withRouter(connect(mapStateToProps)(RevisionsListComponent))
);
