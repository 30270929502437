import { createSelector } from "reselect";
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";
import { ruleFormSelectedOrganizationSelector } from "../rule_form_selected_organization_selector";
import get from "lodash.get";
import { allFacilitiesOptionId } from "src/constants";

export function ruleFormFacilitiesByOrganizationSelector(formName) {
  return createSelector(
    facilitiesResourceSelectors.dataSelector,
    ruleFormSelectedOrganizationSelector(formName),
    (facilities, organizationId) => {
      if (!facilities) {
        return [];
      }

      const facilitiesByOrganization = Object.values(facilities)
        .filter(facility => {
          return (
            get(facility, "attributes.organizationId", "") === organizationId
          );
        })
        .map(facility => {
          return {
            id: facility.id,
            text: get(facility, "attributes.name", "")
          };
        });

      return facilitiesByOrganization.length
        ? [
            {
              id: allFacilitiesOptionId,
              text: "All Facilities"
            }
          ].concat(facilitiesByOrganization)
        : facilitiesByOrganization;
    }
  );
}
