import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import flowRight from "lodash.flowright";

// Component
import { SurveyComponent } from "src/components/survey_component";

// constants
import { tallyTypes } from "src/constants";

// Selectors
import { createWoundTalliesPayloadSelector } from "src/selectors/api_selectors/survey/wound_tallies";
import {
  selectedLocationIdsSelector,
  dateSelectionSelector,
  excludeNewWoundsSelector,
  includeDischargedSelector,
  talliesByWoundTypeSelector,
  talliesByLocationSelector
} from "src/selectors/section_selectors/survey";
import { surveyTallyExportLoadStateSelector } from "src/selectors/data_selectors/exports/survey_tally_export";
import { selectedOrganizationSelector } from "src/selectors/data_selectors";
import { languageSelector } from "src/selectors/config_selectors";
import {
  brandingSelector,
  brandedLogoPngSelector
} from "src/selectors/ui_config_selectors/global";

import { surveyEtiologyChartDefinitionSelector } from "src/selectors/chart_definition_selectors";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

// Actions
import { createAndDownloadSurveyTallyCsv } from "src/actions/data_actions/export";
import { setDateSelection } from "src/actions/section_actions/survey";
import { create } from "src/actions/flashMessageActions";

// HOC
import { withSchemaLocalizationDictionaryWrapper } from "src/hoc/with_schema_localization_dictionary_wrapper";

/**
 * Connected container responsible for loading data
 */
class SurveyContainerLoader extends React.Component {
  static propTypes = {
    locationIdsToDisplay: PropTypes.array.isRequired,
    selectedLocationIds: PropTypes.array.isRequired,
    dateSelection: PropTypes.object.isRequired,
    routePathname: PropTypes.string.isRequired,
    talliesLoadState: PropTypes.object.isRequired,
    surveyTallyExportLoadState: PropTypes.string.isRequired,
    setDateSelection: PropTypes.func.isRequired,
    sendCreateAndDownloadCsvAction: PropTypes.func.isRequired
  };

  /**
   * Create a payload and call action to create and download a csv export
   */
  createAndDownloadCsv = () => {
    const { surveyPayload } = this.props;

    this.props.sendCreateAndDownloadCsvAction({
      filter: {
        ...surveyPayload.filter,
        exportFormat: "csv"
      }
    });
  };

  render() {
    return (
      <SurveyComponent
        createAndDownloadCsv={this.createAndDownloadCsv}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const tallyType =
    ownProps.location.pathname == "/etiology"
      ? tallyTypes.etiology
      : tallyTypes.location;

  return {
    selectedLocationIds: selectedLocationIdsSelector(state),
    dateSelection: dateSelectionSelector(state),
    excludeNewHealed: excludeNewWoundsSelector(state),
    surveyTallyExportLoadState: surveyTallyExportLoadStateSelector(state),
    includeDischarged: includeDischargedSelector(state),
    language: languageSelector(state),
    tallies:
      tallyType == tallyTypes.etiology
        ? talliesByWoundTypeSelector(state)
        : talliesByLocationSelector(state),
    selectedOrganization: selectedOrganizationSelector(state),
    chartDefinition: surveyEtiologyChartDefinitionSelector(state),
    surveyPayload: createWoundTalliesPayloadSelector(tallyType)(state),
    branding: brandingSelector(state),
    brandedLogoPng: brandedLogoPngSelector(state),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDateSelection(selection) {
      dispatch(setDateSelection(selection));
    },

    sendCreateAndDownloadCsvAction(payload) {
      dispatch(createAndDownloadSurveyTallyCsv(payload));
    },
    showPdfErrorView() {
      dispatch(
        create("Sorry, there was an error downloading your export", "error")
      );
    }
  };
}

export const SurveyContainer = withRouter(
  flowRight(
    withSchemaLocalizationDictionaryWrapper,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(SurveyContainerLoader)
);
