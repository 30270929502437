import React from "react";

export class Checkbox extends React.Component {
  componentDidMount() {
    this.el.indeterminate = this.props.indeterminate;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.el.indeterminate = this.props.indeterminate;
    }
  }

  render() {
    return (
      <input
        type="checkbox"
        value={this.props.value}
        checked={this.props.checked}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
        ref={el => (this.el = el)}
      />
    );
  }
}

Checkbox.defaultProps = {
  indeterminate: false
};
