// @flow
import { relationshipTypes } from "../schemaUtils";
import blacklist from "blacklist";
import type { $schema } from "../schemaUtils";
import { camelizeKeys } from "humps";
import get from "lodash.get";
export type $normalizeResponse = {
  entities: $$mapOf<$$numberMapOf<any>>,
  relationships: $$mapOf<$$mapOf<$$numberMapOf<$$id | $$id[]>>>
};

export const normalize = function(
  input: Object,
  entityName: string,
  schema: $schema
): $normalizeResponse {
  const entities = {};
  const relationships = {};
  _normalizeRecursive(
    camelizeKeys(input),
    entityName,
    schema,
    entities,
    relationships
  );
  return { entities, relationships };
};

const _normalizeRecursive = function(
  input,
  entityName,
  schema,
  entities,
  relationships
) {
  const { modifier, relationshipSchemas, propertyName } = schema[
    entityName
  ] || { modifier: ent => ent, relationshipSchemas: [], propertyName: "" };
  const usedRelationships = [];
  const inputId = propertyName || input.id;
  relationshipSchemas.forEach(relationshipSchema => {
    const { getVariableName } = relationshipSchema;
    const variableIds = {};
    const relationshipName =
      relationshipSchema.relationshipName ||
      relationshipSchema.alias ||
      relationshipSchema.name;
    const relation = get(input, relationshipName);

    if (relation) {
      usedRelationships.push(relationshipName);
      if (
        !relationshipSchema.type ||
        relationshipSchema.type === relationshipTypes.MANY
      ) {
        let relationshipIds = [];
        relation.forEach(relatedEntity => {
          if (typeof relatedEntity === "number") {
            relationshipIds.push(relatedEntity);
          } else {
            _normalizeRecursive(
              relatedEntity,
              relationshipSchema.name,
              schema,
              entities,
              relationships
            );
            if (getVariableName) {
              const variableName = getVariableName(relatedEntity);
              if (!variableIds[variableName]) {
                variableIds[variableName] = [];
              }
              variableIds[variableName].push(relatedEntity.id);
            }
            relationshipIds.push(relatedEntity.id);
          }
        });
        if (getVariableName) {
          Object.keys(variableIds).forEach(variableRelationshipName => {
            _addToRelationships(
              relationships,
              entityName,
              variableRelationshipName,
              inputId,
              variableIds[variableRelationshipName]
            );
          });
        }
        _addToRelationships(
          relationships,
          entityName,
          relationshipName,
          inputId,
          relationshipIds
        );
      } else {
        let relationshipId;
        if (typeof relation === "number") {
          relationshipId = relation;
        } else {
          relationshipId = relation.id;
          _normalizeRecursive(
            relation,
            relationshipSchema.name,
            schema,
            entities,
            relationships
          );
        }
        _addToRelationships(
          relationships,
          entityName,
          relationshipName,
          inputId,
          relationshipId
        );
      }
    }
  });
  _addToEntities(
    entities,
    entityName,
    usedRelationships,
    input,
    modifier,
    propertyName
  );
};

const _addToRelationships = function(
  relationships,
  entityName,
  relationshipName,
  entityId,
  values
) {
  if (!relationships[entityName]) {
    relationships[entityName] = {};
  }
  if (!relationships[entityName][relationshipName]) {
    relationships[entityName][relationshipName] = {};
  }
  relationships[entityName][relationshipName][entityId] = values;
};

export const _removeUsedSplitRelationship = (
  entity: Object,
  splitRelationship: Array<string>
): Object => {
  const nextRelationship = splitRelationship.shift();
  return Object.keys(entity).reduce((finalResult, key) => {
    if (key !== nextRelationship) {
      finalResult[key] = entity[key];
    } else {
      if (splitRelationship.length) {
        finalResult[key] = _removeUsedSplitRelationship(
          entity[key],
          splitRelationship
        );
      }
    }
    return finalResult;
  }, {});
};

const _addToEntities = function(
  entities,
  entityName,
  usedRelationships,
  entity,
  modifier,
  propertyName
) {
  if (!entities[entityName]) {
    entities[entityName] = {};
  }
  let nextEntity = entity;
  if (usedRelationships.length) {
    nextEntity = usedRelationships.reduce((finalResult, usedRelationship) => {
      const splitRelationshipNames = usedRelationship.split(".");
      if (splitRelationshipNames.length > 1) {
        // $FlowFixMe
        return _removeUsedSplitRelationship(
          finalResult,
          splitRelationshipNames
        );
      }
      return blacklist(finalResult, usedRelationship);
    }, entity);
  }
  // $FlowFixMe
  if (Object.keys(nextEntity).length > 1) {
    entities[entityName][propertyName || entity.id] = modifier(nextEntity);
  }
};
