import { populationActionTypes } from "src/action_types/section_action_types";

export function acquiredFilterReducer(state = "", action) {
  switch (action.type) {
    case populationActionTypes.setAcquiredFilter:
      return action.payload;
    default:
      return state;
  }
}
