import React from "react";
import { PopulationTallyTableByLocationContainer } from "src/containers/tables";
import PropTypes from "prop-types";
import { LoadingComponent } from "src/components/loading_component";
import { PanelComponent, PanelBodyComponent } from "src/components/panel";

import { loadStates } from "src/constants/load_states";
export class PopulationLocationComponent extends React.PureComponent {
  static propTypes = {
    populationTalliesByLocationLoadState: PropTypes.string.isRequired
  };

  renderPoulationLocation() {
    const { populationTalliesByLocationLoadState } = this.props;

    if (populationTalliesByLocationLoadState != loadStates.loaded) {
      return <LoadingComponent />;
    } else {
      return (
        <PanelComponent
          labelHide=""
          enableCollapsible={false}
          labelShow="Location Table"
        >
          <PanelBodyComponent>
            <PopulationTallyTableByLocationContainer />
          </PanelBodyComponent>
        </PanelComponent>
      );
    }
  }
  render() {
    return <div>{this.renderPoulationLocation()}</div>;
  }
}
