import { connect } from "react-redux";
import { SideBarMonitorAtHomeStatusFilterComponent } from "src/components/side_bar_monitor_at_home_status_filter_component";

// selectors
import { monitorAtHomeStatusFilterSelector } from "src/selectors/section_selectors/admin";

// actions
import {
  setMonitorAtHomeStatusFilterAction,
  deselectMonitorAtHomeStatusFilterAction
} from "src/actions/section_actions/admin";

function mapStateToProps(state) {
  return {
    monitorAtHomeStatusFilter: monitorAtHomeStatusFilterSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSetMonitorAtHomeStatusFilterAction: value => {
      dispatch(setMonitorAtHomeStatusFilterAction(value));
    },
    sendDeselectMonitorAtHomeStatusFilterAction: value => {
      dispatch(deselectMonitorAtHomeStatusFilterAction(value));
    }
  };
}

export const SideBarMonitorAtHomeStatusFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBarMonitorAtHomeStatusFilterComponent);
