import { getChartImage } from "../get_chart_image";

export function getFilteredCharts({
  closureChart,
  pushChart,
  hprChart,
  bwatChart
}) {
  let chartsArray = [];
  if (closureChart && pushChart && hprChart) {
    chartsArray.push({
      columns: [getChartImage(closureChart), getChartImage(pushChart)],
      style: "columnBody",
      alignment: "center"
    });
    chartsArray.push({
      columns: [getChartImage(hprChart), [{}]],
      style: "columnBody",
      alignment: "center",
      marginTop: 20,
      marginBottom: 20
    });
  } else if (closureChart && bwatChart) {
    chartsArray.push({
      columns: [getChartImage(closureChart), getChartImage(bwatChart)],
      style: "columnBody",
      alignment: "center"
    });
  } else if (closureChart && pushChart) {
    chartsArray.push({
      columns: [getChartImage(closureChart), getChartImage(pushChart)],
      style: "columnBody",
      alignment: "center"
    });
  } else if (closureChart && hprChart) {
    chartsArray.push({
      columns: [getChartImage(closureChart), getChartImage(hprChart)],
      style: "columnBody",
      alignment: "center"
    });
  } else if (closureChart) {
    chartsArray.push({
      columns: [getChartImage(closureChart), [{}]],
      style: "columnBody",
      alignment: "center"
    });
  } else {
    chartsArray.push({
      columns: [{}],
      style: "columnBody",
      alignment: "center"
    });
  }

  return chartsArray;
}
