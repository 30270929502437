// @flow
import React, { PureComponent } from "react";
import { Chart } from "@sw/ui-kit";
import DateValueModel from "@sw/models/TrendModel/DateValueModel";

type $props = {
  data: DateValueModel
};

export default class PUSHChart extends PureComponent {
  props: $props;
  render() {
    if (this.props.data._map.size != 0) {
      this.props.data._map._root.entries[1][1]._tail.array.forEach(
        (element, index) => {
          if (element == -1)
            this.props.data._map._root.entries[1][1]._tail.array[index] = "INC";
        }
      );
    }
    return (
      <Chart data={this.props.data} max={17} min={0} noDecimalYAxis={true} />
    );
  }
}
