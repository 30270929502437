import { apiPageLimits } from "src/constants";

export function createRuleTemplatesGetPayload({ pageNumber, pageLimit }) {
  return {
    page: {
      offset: pageNumber * apiPageLimits.ruleTemplates,
      limit: pageLimit
    }
  };
}
