export const analyticsEventNames = {
  downloadEvaluationListCSV: "DOWNLOAD_EVALUATION_LIST_CSV",
  downloadSurveyLineListCsvClicked: "DOWNLOAD_SURVEY_LINE_LIST_CSV_CLICKED",
  downloadSurveyLineListPdfClicked: "DOWNLOAD_SURVEY_LINE_LIST_PDF_CLICKED",
  onClickTableLinkToRevisionModal: "ON_CLICK_TABLE_LINK_TO_REVISION_MODAL",
  renderAdminAlertsTab: "RENDER_ADMIN_ALERTS_TAB",
  renderAdminRiskAlertRulesTable: "RENDER_ADMIN_RISK_ALERT_RULES_TABLE",
  renderAdminWoundAlertRulesTable: "RENDER_ADMIN_WOUND_ALERT_RULES_TABLE",
  renderOptimizedWoundsChart: "RENDER_OPTIMIZED_WOUNDS_CHART",
  renderRevisionAssessmentAnswersComponent:
    "RENDER_REVISION_ASSESSMENT_ANSWERS_VIEW",
  renderRevisionPDFButton: "RENDER_REVISION_PDF_BUTTON",
  renderRiskAlertRuleForm: "RENDER_RISK_ALERT_RULE_FORM",
  renderSavedFilters: "RENDER_SAVED_FILTERS",
  renderUserEvaluationListWoundTable: "RENDER_USER_EVALUATION_LIST_WOUND_TABLE",
  renderUserEvaluationListBradenTable:
    "RENDER_USER_EVALUATION_LIST_BRADEN_TABLE",
  renderUserEvaluationListPURSTable: "RENDER_USER_EVALUATION_LIST_PURS_TABLE",
  renderUserEvaluationListTotalSkinTable:
    "RENDER_USER_EVALUATION_LIST_TOTAL_SKIN_TABLE",
  renderWoundAlertRuleForm: "RENDER_WOUND_ALERT_RULE_FORM",
  renderWoundsChart: "RENDER_WOUNDS_CHART",
  renderWoundsDashboardLocationsTable:
    "RENDER_WOUNDS_DASHBOARD_LOCATIONS_TABLE",
  renderWoundRiskTable: "RENDER_WOUND_RISK_TABLE",
  renderWoundsWoundTabsList: "RENDER_WOUNDS_WOUND_TABS_LIST",
  riskChartPanel: "RISK_CHART_PANEL",
  renderSectionInit: "RENDER_SECTION_INIT",
  surveyLineListExportMenuCLicked: "SURVEY_LINE_LIST_EXPORT_MENU_CLICKED",
  woundsProgressChartPanel: "WOUNDS_PROGRESS_CHART_PANEL",
  updatedQANote: "UPDATED_QA_NOTE",
  confirmEncounter: "CONFIRM_ENCOUNTER"
};
