import axios from "axios";
import qs from "qs";

import { getNextPageQuery, registerPagination } from "../pagination";
import { registerForCancellation } from "../cancellation";

/**
 * Fetch assessments for a set of assessmentIds with include for assessment_schema
 * @param {array} assessmentId
 * @return {Promise}
 */
export function fetchRevisionsForSeries(seriesId, lockState, loadMore = false) {
  const paginationKey = `api/v4/wound-series/${seriesId}/revisions`;
  let url;
  if (!loadMore) {
    const query = {
      filter: {
        ...(lockState && { lock_state: lockState })
      },
      include: "payer_revisions",
      page: {
        limit: 10
      },
      sort: "-created_at"
    };

    url = `/api/v4/wound-series/${seriesId}/revisions?${qs.stringify(query)}`;
  } else {
    url = `/api/v4/wound-series/${seriesId}/revisions${getNextPageQuery(
      paginationKey
    )}`;
  }

  const config = {
    cancelToken: registerForCancellation(url)
  };

  return axios.get(url, config).then(response => {
    registerPagination(paginationKey, response);
    return response;
  });
}

/**
 * Fetch the first revision for a series
 * @returns {Promise}
 */
export function fetchFirstRevisionForSeries(seriesId, lockState, onlyFirst) {
  const query = {
    filter: {
      strikeout_state: "open",
      deleted: "false",
      ...(lockState && { lock_state: lockState })
    },
    sort: "-created_at",
    page: {
      limit: onlyFirst ? 1 : 100
    }
  };

  const url = `/api/v4/wound-series/${seriesId}/revisions?${qs.stringify(
    query
  )}`;

  return axios.get(url);
}
