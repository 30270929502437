import axios from "axios";
import { registerForCancellation } from "../cancellation";

/**
 * Fetch population tally
 * @param {Object} payload the query parameters
 * startDate
 * endDate
 * woundTypes
 * locationIds
 * acquired
 * @return {Promise} Promise with the response
 */
export function fetchPopulationTally(payload) {
  const url = "/api/v3/survey/population-tallies";
  const config = {
    cancelToken: registerForCancellation(url)
  };
  return axios.post(url, payload, config);
}

/**
 * Post request to Incidence Prevalence Chart Data
 * @param {Object} payload
 * startDate
 * endDate
 * woundTypes
 * locationIds
 * tallyType
 * format
 * acquired
 * @return {Promise} Promise with the response
 */
export function fetchIncidencePrevalenceChartData(payload) {
  const url = "/api/v3/survey/incidence-prevalence-time-series";
  const config = {
    cancelToken: registerForCancellation(url)
  };
  return axios.post(url, payload, config);
}

/**
 * Post request to generate and download a csv
 * @param {Object} payload
 * startDate
 * endDate
 * woundTypes
 * locationIds
 * tallyType
 * format
 * acquired
 * @return {Promise} Promise with the response
 */
export function createPopulationTallyCsv(payload) {
  const url = "/api/v3/survey/population-tallies-export";
  return axios.post(url, payload);
}
