import { createSelector } from "reselect";

// Selectors
import { createFirstPageEventsForAssessmentAnswerGroupIdContextSelector } from "src/selectors/context_selectors/events_for_assessment_answer_group_id";
import { eventsResourceSelectors } from "src/selectors/data_selectors";

// Payload generator
import { createEventsGetPayload } from "src/api/events/payload_generators";

// Constants
import { apiPageLimits, eventTypeFilters } from "src/constants";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * create a selector to get all the queries (for all pages) for loading events with assessment answer group id
 * @param {Object} props
 * @param {string} props.assessmentAnswerGroupId
 * @returns {function} selector
 */
export function createAllPagesEventsForAssessmentAnswerGroupIdQuerySelector({
  assessmentAnswerGroupId
}) {
  return createSelector(
    eventsResourceSelectors.createPageCountSelector(
      createFirstPageEventsForAssessmentAnswerGroupIdContextSelector({
        assessmentAnswerGroupId
      }),
      apiPageLimits.events
    ),
    pageCount => {
      if (!pageCount) {
        return [];
      }

      let queries = [];
      for (let i = 0; i < pageCount; i++) {
        const queryParams = createEventsGetPayload({
          assessmentAnswerGroupId,
          pageNumber: i,
          eventType: eventTypeFilters.assessmentAnswerGroup
        });
        queries.push(buildQueryString(queryParams));
      }

      return queries;
    }
  );
}
