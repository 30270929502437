import { dataActionTypes } from "src/action_types";
import { loadStates } from "src/constants/load_states";

const defaultState = {
  loadState: loadStates.ready
};

export function csvImportReducer(state = defaultState, action) {
  switch (action.type) {
    case dataActionTypes.csvImportLoading:
      return { loadState: loadStates.loading };
    case dataActionTypes.csvImportReady:
      return { loadState: loadStates.ready };
    default:
      return state;
  }
}
