import { createSelector } from "reselect";
import get from "lodash.get";
import {
  hasAllowEditOtherUsersWorkPermissionSelector,
  hasLockWoundRevisionsPermissionSelector
} from "src/selectors/ui_config_selectors/patients";
import { assessmentAnswersResourceSelectors } from "src/selectors/data_selectors";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";

export function createLockIsDisabledSelector({ assessmentAnswer, revision }) {
  return createSelector(
    hasAllowEditOtherUsersWorkPermissionSelector,
    hasLockWoundRevisionsPermissionSelector,
    currentUserSelector,
    assessmentAnswersResourceSelectors.putLoadStateSelector,
    (
      canEditOtherUsersWork,
      canLockWoundRevisions,
      currentUser,
      putLoadState
    ) => {
      const revisionAuthorId = get(revision, "attributes.userId", null);
      const isRevisionAuthor =
        revisionAuthorId && revisionAuthorId === currentUser.id;
      return (
        (!canEditOtherUsersWork && !isRevisionAuthor) ||
        !canLockWoundRevisions ||
        putLoadState[assessmentAnswer.id] === "loading"
      );
    }
  );
}
