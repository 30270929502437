import React from "react";

//style
import styles from "./style.module.less";
export class FormTitleComponent extends React.Component {
  render() {
    return (
      <div className={`text-center ${styles["margin-bottom20px"]}`}>
        {this.props.children}
      </div>
    );
  }
}
