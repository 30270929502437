import { createSelector } from "reselect";
import {
  THERMAL_FRAME_TYPES,
  FLUORO_FRAME_TYPES
} from "src/selectors/data_selectors/frames/constants";
import _ from "lodash";

export function revisionContextEnabledSelector(revisions) {
  return createSelector(
    () => {
      const revisionIds = Object.keys(revisions);

      for (let i = 0; i < revisionIds.length; i++) {
        const revision = revisions[revisionIds[i]];

        if (!revision) {
          return false;
        }

        const frames = _.get(revision, "attributes.frames");
        if (
          frames.find(
            frame =>
              Object.values(THERMAL_FRAME_TYPES).includes(frame.frameType) ||
              Object.values(FLUORO_FRAME_TYPES).includes(frame.frameType)
          )
        ) {
          return true;
        }
      }

      return false;
    },
    enabled => enabled
  );
}
