import { createSelector } from "reselect";
import i18n from "src/translations";
import { hasSignWoundRevisionPermissionSelector } from "src/selectors/ui_config_selectors/patients";
import { createNestedAssessmentSchemasForAssessmentAnswerSelector } from "src/selectors/data_selectors";
import { createRevisionIsFirstInSeriesSelector } from "src/selectors/data_selectors/revisions/create_revision_is_first_in_series_selector";
import { getMissingRequiredFields } from "src/models/assessment_answers";
import { createListStringFromArray } from "src/utils/format_string_list_from_array";
import { containsRequiredFieldsForAssessmentAnswers } from "src/utils/required_fields";

export function createSignTooltipSelector({
  assessmentAnswer,
  revision,
  schemas
}) {
  return createSelector(
    hasSignWoundRevisionPermissionSelector,
    createNestedAssessmentSchemasForAssessmentAnswerSelector(assessmentAnswer),
    createRevisionIsFirstInSeriesSelector(revision),
    (
      canSignWoundRevisions,
      nestedAssessmentSchemas,
      revisionIsFirstInSeries
    ) => {
      let signTooltipText = [];
      const assessmentAnswerAnswersJson =
        assessmentAnswer.attributes.answersJson;
      const missingFields = getMissingRequiredFields(
        nestedAssessmentSchemas,
        assessmentAnswer,
        revisionIsFirstInSeries
      );

      if (
        !containsRequiredFieldsForAssessmentAnswers(
          schemas,
          assessmentAnswerAnswersJson,
          revisionIsFirstInSeries
        )
      ) {
        const requiredPermissionsText = i18n.t(
          "text.revisionButtonTooltips.requiredPermissionsTextToSign"
        );
        signTooltipText.push(requiredPermissionsText);
      }
      if (!canSignWoundRevisions) {
        const missingPermissionsText = i18n.t(
          "text.revisionButtonTooltips.missingPermissions"
        );
        signTooltipText.push(missingPermissionsText);
      }
      if (missingFields.length > 0) {
        let missingRequiredFieldsText = i18n.t(
          "text.revisionButtonTooltips.missingRequiredInformation"
        );
        missingRequiredFieldsText += createListStringFromArray(missingFields);
        signTooltipText.push(missingRequiredFieldsText);
      }
      return signTooltipText.length > 0 ? signTooltipText.join(" ") : "";
    }
  );
}
