import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Components
import { RevisionsComparisonComponent } from "src/components/revisions_comparison_component";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc/factories";

// UI Processors
import valueArraysByKey from "src/ui_processors/value_arrays_by_key";

// Selectors
import { patientsSectionSelector } from "src/selectors/section_selectors/patients";
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { createAllPageRevisionComparisonContextSelector } from "src/selectors/api_selectors/patients/revision_comparison";
import { createAllPageRevisionComparisonQuerySelector } from "src/selectors/api_selectors/patients/revision_comparison";
import { healingIndexDashboardEnabledSelector } from "src/selectors/ui_config_selectors/global";
// Actions
import { loadRevisionsAction } from "src/actions/data_actions";

const valueArraysByKeyActions = valueArraysByKey.createActionCreators(
  "treatmentRevisionsToDisplayBySeries"
);
const valueArraysByKeySelectors = valueArraysByKey.createSelectors(
  "treatmentRevisionsToDisplayBySeries",
  patientsSectionSelector
);

function mapStateToProps(state, ownProps) {
  const { seriesId } = ownProps;

  return {
    revisions: revisionsResourceSelectors.createDataForContextSelector(
      createAllPageRevisionComparisonContextSelector(ownProps)
    )(state),
    revisionsToCompareForSeries: valueArraysByKeySelectors.createValuesForKeySelector(
      seriesId
    )(state),
    healingIndexDashboardEnabled: healingIndexDashboardEnabledSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSelectRevisionAction: revisionObject => {
      dispatch(
        valueArraysByKeyActions.setValueForKeyAtIndex({
          value: revisionObject.revisionId,
          key: revisionObject.seriesId,
          index: revisionObject.index
        })
      );
    },
    sendInsertRevisionAction: revisionObject => {
      dispatch(
        valueArraysByKeyActions.insertValueForKeyAtIndex({
          value: revisionObject.revisionId,
          key: revisionObject.seriesId,
          index: revisionObject.index
        })
      );
    },
    sendAddRevisionItemAction: ({ revisionId, seriesId }) => {
      dispatch(
        valueArraysByKeyActions.appendValueForKey({
          value: revisionId,
          key: seriesId
        })
      );
    },
    sendRemoveRevisionItemAction: revisionItemSeriesIdAndIndex => {
      dispatch(
        valueArraysByKeyActions.removeValueForKeyAtIndex({
          key: revisionItemSeriesIdAndIndex.seriesId,
          index: revisionItemSeriesIdAndIndex.index
        })
      );
    }
  };
}

export const TreatmentNavViewRevisionsComparisonContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadRevisionsAction,
    resourceSelectors: revisionsResourceSelectors,
    createContextSelector: createAllPageRevisionComparisonContextSelector,
    createQuerySelector: createAllPageRevisionComparisonQuerySelector
  }),
  withLoadingComponentFactory({
    analyticsEventName: `RENDER_TREATMENT_NAVIGATOR`
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RevisionsComparisonComponent);
