import normalize from "json-api-normalizer";
import { camelizeKeys } from "humps";

//Tracking
import { startTimeTrack, finishTimeTrack } from "src/analytics";
import { trackError } from "src/error_tracking";

//API
import { fetchWoundAssessments } from "src/api/wound_assessments";

//Redux Resources
import { createResourceActions } from "src/redux_resources_v1";
const woundAssessmentsResourceActions = createResourceActions(
  "woundAssessments"
);

/**
 * Action creator for loading wound assessments
 * @return {Function} thunk action
 */
export function loadWoundAssessmentsAction(payload, context) {
  return dispatch => {
    const progressFilter = payload.filter.wound_overall_progress.toUpperCase();
    startTimeTrack(`API_GET_WOUND_ASSESSMENTS_FOR_${progressFilter}_WOUNDS`);
    dispatch(woundAssessmentsResourceActions.loadingForContextAction(context));
    return fetchWoundAssessments(payload)
      .then(response => {
        finishTimeTrack(
          `API_GET_WOUND_ASSESSMENTS_FOR_${progressFilter}_WOUNDS`
        );
        const data = normalize(response.data);
        const assessments = data.apiV2Assessments ? data.apiV2Assessments : {};
        dispatch(
          woundAssessmentsResourceActions.populateAction({
            contexts: context,
            data: assessments,
            ids: Object.keys(assessments),
            links: response.data.links,
            meta: response.data.meta ? camelizeKeys(response.data.meta) : {}
          })
        );
      })
      .catch(error => {
        //If the request was cancelled it means a new request started
        //don't show the loading view;
        if (error.message == "cancel") {
          return;
        }
        trackError(error);
        dispatch(
          woundAssessmentsResourceActions.loadErrorForContextAction(context)
        );
        finishTimeTrack(
          `API_GET_WOUND_ASSESSMENTS_FOR_${progressFilter}_WOUNDS`,
          {
            error: true,
            errorCode: error.response ? error.response.status : undefined
          }
        );
      });
  };
}
