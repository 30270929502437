/* eslint-disable */
export default function(regionCanvas, args) {
  var additiveColor = "#32FF32";
  var subtractiveColor = "#FFFFFF";

  var regionColour = "rgba( 255, 255, 255, 0.7 )";
  var islandColour = "rgba( 255, 255, 255, 1.0 )";
  var undermineColour = "rgba( 0, 128, 242, 0.5 )";
  var tunnelColour = "rgba(255, 255, 0, 0.8)";
  var depthColour = "rgba(255, 0, 0, 0.8)";
  var depthColourBlue = "rgba(0, 0, 255, 1)";
  var widthColour = "rgba(0,255,255,1.0)";
  var lengthColour = "rgba(0,0,255,1.0)";

  var _widget = this;
  var _enabled = true;
  _widget.last = null;
  _widget.history = [];
  _widget.color = "#32CD32";
  _widget.fillColor = "#32CD32";
  _widget.additive = true;
  _widget.strokeSize = 15;
  _widget.strokes = [];
  _widget.firstTouch = null;
  _widget.originalImgSize = [0, 0];
  _widget.viewImgSize = [0, 0];
  _widget.canvasSize = [];
  _widget.loadedRegionData = {};
  _widget.loadedUnderminingData = {};
  _widget.loadedTunnellingData = {};
  _widget.loadedDepthData = {};
  _widget.diameterWidthArray = [];
  _widget.widthRatio = 1;
  _widget.heightRatio = 1;
  _widget.regionOfInterest = [];

  // Vector of histories

  function getPosition(_e) {
    var pX = 0;
    var pY = 0;
    if (_e.offsetParent) {
      while (true) {
        pY += _e.offsetTop;
        pX += _e.offsetLeft;
        if (_e.offsetParent === null) {
          break;
        }
        _e = _e.offsetParent;
      }
    } else if (_e.y) {
      pY += _e.y;
      pX += _e.x;
    }
    return { x: pX, y: pY };
  }

  this.setMode = function(_additive) {
    if (_additive === true) _widget.setAdd();
    else _widget.setSub();
  };

  this.setAdd = function() {
    this.setColor(additiveColor);
    _widget.additive = true;
    var compOp = _widget.ctx.globalCompositeOperation;
    _widget.ctx.globalCompositeOperation = "source-over";
  };

  this.setSub = function() {
    this.setColor(subtractiveColor);
    _widget.additive = false;
    _widget.ctx.globalCompositeOperation = "destination-out";
  };

  this.isAdding = function() {
    return _widget.additive;
  };

  function isAdding() {
    return _widget.additive;
  }

  this.setColor = function(_color) {
    _widget.ctx.strokeStyle = _color;
    _widget.color = _color;
    _widget.ctx.fillStyle = _color;
  };

  this.setStrokeSize = function(_size) {
    _widget.ctx.lineWidth =
      _size * (_widget.viewImgSize[0] / _widget.originalImgSize[0]);
    _widget.strokeSize =
      _size * (_widget.viewImgSize[0] / _widget.originalImgSize[0]);
  };

  this.reset = function() {
    _widget.clear();
  };

  this.clear = function() {
    _widget.ctx.beginPath();
    _widget.ctx.clearRect(0, 0, _widget.width, _widget.height);
    _widget.ctx.closePath();
    _widget.history = [];
    _widget.strokes = [];
    _widget.last = null;
    _widget.firstTouch = null;
    _widget.drawing = false;
    _widget.refreshPosition();
  };

  this.flushStrokes = function() {
    _widget.history.push(_widget.strokes);
    _widget.strokes = [];
  };

  this.isFirstMove = function() {
    return (
      _widget.strokes.length === 0 &&
      _widget.last === null &&
      _widget.firstTouch !== null
    );
  };

  function startDrawing(e) {
    _widget.pos = getPosition(_widget.canvas);
    _widget.drawing = true;
    _widget.strokes = [];
    _widget.firstTouch = null;
    var _next;
    var _current;
    if (e.targetTouches && e.targetTouches.item(0) !== null) {
      var te = e.targetTouches.item(0);
      _current = {
        x:
          (te.pageX - _widget.pos.x - 1) *
          (_widget.originalImgSize[0] / _widget.viewImgSize[0]),
        y:
          (te.pageY - _widget.pos.y) *
          (_widget.originalImgSize[1] / _widget.viewImgSize[1])
      };
      _next = {
        x:
          (te.pageX - _widget.pos.x + 1) *
          (_widget.originalImgSize[0] / _widget.viewImgSize[0]),
        y:
          (te.pageY - _widget.pos.y) *
          (_widget.originalImgSize[1] / _widget.viewImgSize[1])
      };
    } else {
      _current = {
        x:
          (e.pageX - _widget.pos.x - 1) *
          (_widget.originalImgSize[0] / _widget.viewImgSize[0]),
        y:
          (e.pageY - _widget.pos.y) *
          (_widget.originalImgSize[1] / _widget.viewImgSize[1])
      };
      _next = _current = {
        x:
          (e.pageX - _widget.pos.x + 1) *
          (_widget.originalImgSize[0] / _widget.viewImgSize[0]),
        y:
          (e.pageY - _widget.pos.y) *
          (_widget.originalImgSize[1] / _widget.viewImgSize[1])
      };
    }
    _widget.firstTouch = { current: _current, next: _next };
    // console.log("------------------ NEW RUN ----------------");
    // console.log(_widget.strokes);
    // console.log("FirstTouch current: " + _widget.firstTouch.current.x + ", " + _widget.firstTouch.current.y );
    // console.log("FirstTouch next: " + _widget.firstTouch.next.x + ", " + _widget.firstTouch.next.y );
    e.preventDefault();
  }

  function stopDrawing(e) {
    // if we are just a dot
    if (_widget.strokes.length === 0 && _widget.firstTouch !== null)
      _widget.plotLine(_widget.firstTouch.current, _widget.firstTouch.next);

    _widget.plotLine(_widget.last, _widget.firstTouch.current);

    _widget.history.push(_widget.strokes);
    _widget.fillPoly();

    // console.log(_widget.strokes);
    // console.log("Last point on stop: " + _widget.last.x + ", " + _widget.last.y );
    // console.log("Start of first stroke: " + _widget.strokes[0].s.x + ", " + _widget.strokes[0].s.y );
    // console.log("End of last stroke: " + _widget.strokes[_widget.strokes.length - 1].e.x + ", " + _widget.strokes[_widget.strokes.length - 1].e.y );

    _widget.firstTouch = null;
    _widget.drawing = false;
    _widget.last = null;
    e.preventDefault();
  }

  function move(e) {
    if (_widget.drawing) {
      var te = e.targetTouches.item(0);
      var _current = {
        x:
          (te.pageX - _widget.pos.x - 1) *
          (_widget.originalImgSize[0] / _widget.viewImgSize[0]),
        y:
          (te.pageY - _widget.pos.y) *
          (_widget.originalImgSize[1] / _widget.viewImgSize[1])
      };

      // Draw first plot
      if (_widget.isFirstMove())
        _widget.plotLine(_widget.firstTouch.current, _current);

      if (_widget.last !== null) _widget.plotLine(_widget.last, _current);

      _widget.last = _current;
    }
    e.preventDefault();
  }

  function moveMouse(e) {
    if (_widget.drawing) {
      var _current = {
        x:
          (e.pageX - _widget.pos.x) *
          (_widget.originalImgSize[0] / _widget.viewImgSize[0]),
        y:
          (e.pageY - _widget.pos.y) *
          (_widget.originalImgSize[1] / _widget.viewImgSize[1])
      };

      // Draw first plot
      if (_widget.isFirstMove())
        _widget.plotLine(_widget.firstTouch.current, _current);

      if (_widget.last !== null) _widget.plotLine(_widget.last, _current);

      _widget.last = _current;
    }
    e.preventDefault();
  }

  this.plotLine = function(
    start,
    end,
    _color,
    _size,
    _additive,
    dontStrokePoly
  ) {
    if (!dontStrokePoly) {
      var dontStrokePoly = false;
    }

    // Overrides for use when loading data
    if (typeof _color === "undefined") _color = _widget.color;

    _widget.ctx.strokeStyle = _color;

    if (typeof _size === "undefined")
      _size =
        _widget.strokeSize *
        (_widget.viewImgSize[0] / _widget.originalImgSize[0]);

    _widget.setStrokeSize(_size);
    // _widget.ctx.lineWidth = _size * (_widget.viewImgSize[0]/_widget.originalImgSize[0]);

    if (typeof _additive === "undefined") _additive = _widget.additive;

    _widget.ctx.globalCompositeOperation = _additive
      ? "source-over"
      : "destination-out";

    // Perform draw
    _widget.ctx.beginPath();
    _widget.ctx.moveTo(
      start.x * (_widget.viewImgSize[0] / _widget.originalImgSize[0]),
      start.y * (_widget.viewImgSize[1] / _widget.originalImgSize[1])
    );
    _widget.ctx.lineTo(
      end.x * (_widget.viewImgSize[0] / _widget.originalImgSize[0]),
      end.y * (_widget.viewImgSize[1] / _widget.originalImgSize[1])
    );
    if (!dontStrokePoly) {
      _widget.ctx.stroke();
    }
    _widget.ctx.closePath();

    // Store stroke
    var _item = { s: start, e: end, c: _color, sz: _size, a: _additive };
    _widget.strokes.push(_item);

    // _widget.restoreOverrides( _color, _size, _additive );
  };

  this.drawCircle = function(x, y, radius, text, isReferencePoint = false) {
    _widget.ctx.strokeStyle = "#ffffff";
    _widget.ctx.lineWidth = 15;
    _widget.ctx.font = "60px Helvetica";
    _widget.ctx.fillStyle = "rgba(0, 0, 0, 0.4)";

    _widget.ctx.beginPath();
    _widget.ctx.arc(x, y, radius * 45, 0, 2 * Math.PI);
    _widget.ctx.fill();
    _widget.ctx.stroke();
    _widget.ctx.closePath();

    if (isReferencePoint) {
      _widget.ctx.beginPath();
      _widget.ctx.arc(x, y, radius * 55, 0, 2 * Math.PI);
      _widget.ctx.stroke();
    }

    _widget.ctx.fillStyle = "#ffffff";
    _widget.ctx.textAlign = "center";
    _widget.ctx.textBaseline = "middle";
    _widget.ctx.fillText(text, x, y);
  };

  this.fillPoly = function(_color, _size, _additive) {
    // Overrides for use when loading data
    if (typeof _color === "undefined") _color = _widget.color;

    _widget.ctx.fillStyle = _color;

    if (typeof _size === "undefined")
      _size =
        _widget.strokeSize *
        (_widget.viewImgSize[0] / _widget.originalImgSize[0]);

    // _widget.ctx.lineWidth = _size * (_widget.viewImgSize[0]/_widget.originalImgSize[0]);
    _widget.setStrokeSize(_size);

    if (typeof _additive === "undefined") _additive = _widget.additive;

    _widget.ctx.globalCompositeOperation = _additive
      ? "source-over"
      : "destination-out";

    // Perform fill
    _widget.ctx.beginPath();
    _widget.ctx.moveTo(
      _widget.strokes[0].s.x *
        (_widget.viewImgSize[0] / _widget.originalImgSize[0]),
      _widget.strokes[0].s.y *
        (_widget.viewImgSize[1] / _widget.originalImgSize[1])
    );
    for (var i = 0; i < _widget.strokes.length; i++)
      _widget.ctx.lineTo(
        _widget.strokes[i].e.x *
          (_widget.viewImgSize[0] / _widget.originalImgSize[0]),
        _widget.strokes[i].e.y *
          (_widget.viewImgSize[1] / _widget.originalImgSize[1])
      );
    _widget.ctx.lineTo(
      _widget.strokes[0].s.x *
        (_widget.viewImgSize[0] / _widget.originalImgSize[0]),
      _widget.strokes[0].s.y *
        (_widget.viewImgSize[1] / _widget.originalImgSize[1])
    );
    _widget.ctx.fill();

    // _widget.restoreOverrides( _color, _size, _additive );
  };

  this.restoreOverrides = function(_color, _size, _additive) {
    // Restore to user selections in case overrides were used
    _widget.ctx.strokeStyle = _widget.color;
    _widget.ctx.fillStyle = _widget.color;
    _widget.ctx.lineWidth = _widget.strokeSize;
    _widget.ctx.globalCompositeOperation = _widget.additive
      ? "source-over"
      : "destination-out";
  };

  function gobbler(e) {
    e.preventDefault();
  }

  this.getData = function() {
    // Generate data needed for regis
    var history = _widget.history;
    var _polygons = [];

    // Create array of points for each polygon
    for (var i = 0; i < history.length; i += 1) {
      var _polygon = [];
      var _colour = history[i][0].c;
      var _diameter = history[i][0].sz;
      var _additive = history[i][0].a;
      var _points = [];

      for (var j = 0; j < history[i].length; j++)
        _points.push([history[i][j].s.x, history[i][j].s.y]);

      var typeStr = _additive ? "ADD" : "SUBTRACT";
      _polygons.push({
        polydata: { type: typeStr, colour: _colour, lineDiameter: _diameter },
        points: _points
      });
    }

    return _polygons;
  };

  this.loadData = function(
    originalImgSize,
    regionStruct,
    underminingStruct,
    tunnellingStruct,
    depthStruct,
    diameterWidthArray
  ) {
    _widget.originalImgSize = originalImgSize;
    _widget.loadedRegionData = regionStruct;
    _widget.loadedUnderminingData = underminingStruct;
    _widget.loadedTunnellingData = tunnellingStruct;
    _widget.loadedDepthData = depthStruct;
    _widget.loadedDiameterData = diameterWidthArray;
    this.reloadData();
  };

  this.reloadData = function() {
    _widget.clear();
    if (_widget.loadedRegionData) {
      _widget.appendRegion(_widget.loadedRegionData);
    }
    if (_widget.loadedUnderminingData) {
      _widget.appendUndermining(_widget.loadedUnderminingData);
    }
    if (_widget.loadedTunnellingData) {
      _widget.appendTunnelling(_widget.loadedTunnellingData);
    }
    if (_widget.loadedDepthData) {
      _widget.appendDepth(_widget.loadedDepthData);
    }
    if (_widget.loadedDiameterData) {
      _widget.appendDimensionArrows(_widget.loadedDiameterData);
    }
  };

  this.loadTransformedData = function(
    originalImgSize,
    regionStruct,
    diameterWidthArray,
    widthRatio,
    heightRatio,
    regionOfInterest
  ) {
    _widget.originalImgSize = originalImgSize;
    _widget.loadedRegionData = regionStruct;
    _widget.loadedDiameterData = diameterWidthArray;
    _widget.widthRatio = widthRatio;
    _widget.heightRatio = heightRatio;
    _widget.regionOfInterest = regionOfInterest;
    this.reloadTransformedData();
  };

  this.reloadTransformedData = function() {
    _widget.clear();
    if (_widget.loadedRegionData) {
      _widget.appendTransformedRegion(_widget.loadedRegionData);
    }
    if (_widget.loadedDiameterData && _widget.regionOfInterest.length > 0) {
      _widget.appendTransformedDimensionArrows(_widget.loadedDiameterData);
    }
  };

  this.appendRegion = function(data) {
    // Generate history to load
    var _history = [];
    var _polygons = data;

    // Create history of strokes
    for (var i = 0; i < _polygons.length; i += 1) {
      var _additive = _polygons[i].polydata.type == "ADD";
      // var _colour   = _polygons[i].polydata.colour;
      var _colour = regionColour;
      if (!_additive) {
        _colour = islandColour;
      }

      var _diameter = 5; //_polygons[i].polydata.lineDiameter;
      var _points = _polygons[i].points;
      var _strokes = [];

      for (var j = 0; j < _points.length; j++) {
        var _start = [];
        var _end = [];

        if (j == _points.length - 1) {
          _start = { x: _points[j][0], y: _points[j][1] };
          _end = { x: _points[0][0], y: _points[0][1] };
        } else {
          _start = { x: _points[j][0], y: _points[j][1] };
          _end = { x: _points[j + 1][0], y: _points[j + 1][1] };
        }

        _strokes.push({
          s: _start,
          e: _end,
          c: _colour,
          sz: _diameter,
          a: _additive
        });
      }

      _history.push(_strokes);
    }

    this.redraw(_history, false);
  };

  this.appendTransformedRegion = function(data) {
    // Generate history to load
    var _history = [];
    var _polygons = data;

    // Create history of strokes
    for (var i = 0; i < _polygons.length; i += 1) {
      var _additive = _polygons[i].polydata.type == "ADD";
      var _colour = regionColour;
      if (!_additive) {
        _colour = islandColour;
      }

      var _diameter = 5;
      var _strokes = this.calculateTransformedStrokes(
        _polygons[i].points,
        _colour,
        _additive,
        _diameter
      );

      _history.push(_strokes);
    }

    this.redraw(_history, false);
  };

  this.appendUndermining = function(data) {
    // Generate history to load
    var _history = [];
    var _undermines = data;

    // Create history of strokes
    for (var i = 0; i < _undermines.length; i += 1) {
      var _additive = true;
      var _colour = undermineColour;
      var _diameter = 0;
      var _points = _undermines[i].renderPoints;
      var _strokes = [];

      for (var j = 0; j < _points.length; j++) {
        var _start = [];
        var _end = [];

        if (j == _points.length - 1) {
          _start = { x: _points[j][0], y: _points[j][1] };
          _end = { x: _points[0][0], y: _points[0][1] };
        } else {
          _start = { x: _points[j][0], y: _points[j][1] };
          _end = { x: _points[j + 1][0], y: _points[j + 1][1] };
        }

        _strokes.push({
          s: _start,
          e: _end,
          c: _colour,
          sz: _diameter,
          a: _additive
        });
      }

      _history.push(_strokes);
    }

    this.redraw(_history, true);
  };

  this.appendTunnelling = function(data) {
    var _history = [];
    var _tunnels = data;

    for (var i = 0; i < _tunnels.length; i++) {
      var _additive = true;
      var _colour = tunnelColour;
      var _diameter = 5;
      var _points = _tunnels[i].renderPoints;
      var _strokes = [];

      for (var j = 0; j < _points.length; j++) {
        var _start = [];
        var _end = [];

        if (j == _points.length - 1) {
          _start = { x: _points[j][0], y: _points[j][1] };
          _end = { x: _points[0][0], y: _points[0][1] };
        } else {
          _start = { x: _points[j][0], y: _points[j][1] };
          _end = { x: _points[j + 1][0], y: _points[j + 1][1] };
        }

        _strokes.push({
          s: _start,
          e: _end,
          c: _colour,
          sz: _diameter,
          a: _additive
        });
      }

      _history.push(_strokes);
    }

    this.redraw(_history, false);
  };

  this.appendDimensionArrows = function(data) {
    var _history = [];
    var _arrowPoints = data;

    for (var i = 0; i < _arrowPoints.length; i++) {
      var _additive = true;
      if (i == 0) {
        var _colour = lengthColour;
      } else {
        var _colour = widthColour;
      }
      var _diameter = 3;
      var _points = _arrowPoints[i];
      var _strokes = [];

      for (var j = 0; j < _points.length; j++) {
        var _start = [];
        var _end = [];

        if (j == _points.length - 1) {
          _start = { x: _points[j][0], y: _points[j][1] };
          _end = { x: _points[0][0], y: _points[0][1] };
        } else {
          _start = { x: _points[j][0], y: _points[j][1] };
          _end = { x: _points[j + 1][0], y: _points[j + 1][1] };
        }

        _strokes.push({
          s: _start,
          e: _end,
          c: _colour,
          sz: _diameter,
          a: _additive
        });
      }

      _history.push(_strokes);
    }

    this.redraw(_history, false);
  };

  this.appendTransformedDimensionArrows = function(data) {
    var _history = [];
    var _arrowPoints = data;

    for (var i = 0; i < _arrowPoints.length; i++) {
      var _additive = true;
      if (i == 0) {
        var _colour = lengthColour;
      } else {
        var _colour = widthColour;
      }
      var _diameter = 3;
      var _strokes = this.calculateTransformedStrokes(
        _arrowPoints[i],
        _colour,
        _additive,
        _diameter
      );

      _history.push(_strokes);
    }

    this.redraw(_history, false);
  };

  this.calculateTransformedStrokes = function(
    _points,
    _colour,
    _additive,
    _diameter
  ) {
    var _strokes = [];
    var _roi = _widget.regionOfInterest;
    var _widthRatio = _widget.widthRatio;
    var _heightRatio = _widget.heightRatio;
    for (var i = 0; i < _points.length; i++) {
      var _start = [];
      var _end = [];

      if (i == _points.length - 1) {
        _start = {
          x: (_points[i][0] - _roi[0]) * _widthRatio,
          y: (_points[i][1] - _roi[1]) * _heightRatio
        };
        _end = {
          x: (_points[0][0] - _roi[0]) * _widthRatio,
          y: (_points[0][1] - _roi[1]) * _heightRatio
        };
      } else {
        _start = {
          x: (_points[i][0] - _roi[0]) * _widthRatio,
          y: (_points[i][1] - _roi[1]) * _heightRatio
        };
        _end = {
          x: (_points[i + 1][0] - _roi[0]) * _widthRatio,
          y: (_points[i + 1][1] - _roi[1]) * _heightRatio
        };
      }

      _strokes.push({
        s: _start,
        e: _end,
        c: _colour,
        sz: _diameter,
        a: _additive
      });
    }
    return _strokes;
  };

  this.appendDepth = function(data) {
    var _history = [];
    var _points = data;

    for (var j = 0; j < _points.length; j++) {
      var _additive = true;
      var _colour = _points[j].isComputed ? depthColourBlue : depthColour;
      var _diameter = 30;
      //50 * (_widget.originalImgSize[0] / _widget.viewImgSize[0]);
      var _strokes = [];

      var _start = [];
      var _end = [];

      _start = { x: _points[j].pt[0], y: _points[j].pt[1] };
      _end = { x: _points[j].pt[0] + 1, y: _points[j].pt[1] };

      _strokes.push({
        s: _start,
        e: _end,
        c: _colour,
        sz: _diameter,
        a: _additive
      });

      _history.push(_strokes);
    }

    this.redraw(_history, false);
  };

  this.redraw = function(history, withFill) {
    // Redraw history
    var _history = history;
    _widget.ctx.beginPath();
    _widget.ctx.lineCap = "round";
    for (var i = 0; i < _history.length; i += 1) {
      if (withFill == true) {
        for (var j = 0; j < _history[i].length; j++) {
          _widget.plotLine(
            _history[i][j].s,
            _history[i][j].e,
            _history[i][j].c,
            _history[i][j].sz,
            _history[i][j].a,
            true
          );
        }

        if (_history[i].length) {
          _widget.fillPoly(
            _history[i][0].c,
            _history[i][0].sz,
            _history[i][0].a
          );
        }
      } else {
        for (var j = 0; j < _history[i].length; j++) {
          _widget.plotLine(
            _history[i][j].s,
            _history[i][j].e,
            _history[i][j].c,
            _history[i][j].sz,
            _history[i][j].a
          );
        }
      }
      _widget.flushStrokes();
    }
  };

  this.refreshPosition = function() {
    _widget.pos = getPosition(_widget.canvas);
  };

  this.resizeImageCanvas = function() {
    _widget.canvas.width = document.getElementById(_widget.canvas.id).width;
    _widget.canvas.height = document.getElementById(_widget.canvas.id).height;
    _widget.height = _widget.canvas.height;
    _widget.width = _widget.canvas.width;
    _widget.ctx.lineCap = "round";
    _widget.viewImgSize = [_widget.canvas.width, _widget.canvas.height];
    this.reloadData();
  };

  function init() {
    if (typeof args.enabled === "boolean") {
      _enabled = args.enabled;
    }

    _widget.canvas = regionCanvas;
    if (typeof args.height === "number") {
      _widget.height = args.height;
    } else {
      _widget.height = _widget.canvas.height;
    }
    if (typeof args.width === "number") {
      _widget.width = args.width;
    } else {
      _widget.width = _widget.canvas.width;
    }

    _widget.canvas.style.cursor = "crosshair";
    _widget.ctx = _widget.canvas.getContext("2d");
    _widget.ctx.lineCap = "round";
    _widget.viewImgSize = [_widget.width, _widget.height];

    if (_enabled === true) {
      // _widget.canvas.addEventListener( "touchmove", move, false );
      // _widget.canvas.addEventListener( "touchstart", startDrawing, false );
      // document.body.addEventListener( "gesturestart", gobbler, true );
      // _widget.canvas.addEventListener( "touchend", stopDrawing, false );
      // _widget.canvas.addEventListener( "mousemove", moveMouse, false );
      // _widget.canvas.addEventListener( "mousedown", startDrawing, false );
      // _widget.canvas.addEventListener( "click", stopDrawing, false );
      // window.addEventListener( "resize", resizeImageCanvas, false );
    }
    _widget.setColor(_widget.color);
    _widget.ctx.lineWidth =
      _widget.strokeSize *
      (_widget.viewImgSize[0] / _widget.originalImgSize[0]);
  }

  init();
}
