import { createSelector } from "reselect";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

// Selectors
import { createAllPagesRevisionPayloadsForFiltersSelector } from "../create_all_pages_revision_payloads_for_filters_selector";

export function createAllPagesRevisionContextsForFiltersSelector({ filters }) {
  return createSelector(
    createAllPagesRevisionPayloadsForFiltersSelector({ filters }),
    payloads => {
      const contexts = payloads.map(payload => buildQueryString(payload));
      return contexts;
    }
  );
}
