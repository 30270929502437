import { createSelector } from "reselect";
import get from "lodash.get";
import { isPccSelector } from "src/selectors/config_selectors";

export function createLockButtonVisibleSelector({ assessmentAnswer }) {
  return createSelector(
    isPccSelector,

    isPcc => {
      const lockState = get(assessmentAnswer, "attributes.lockState", null);
      const signState = get(assessmentAnswer, "attributes.signState", null);
      return signState === "signed" && lockState === "unlocked" && !isPcc;
    }
  );
}
