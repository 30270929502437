export const eventTypeFilters = {
  revision: [
    "create",
    "update",
    "delete",
    "signed",
    "lock",
    "unsigned",
    "unlock",
    "strikeout"
  ],
  assessmentAnswerGroup: [
    "create",
    "update",
    "delete",
    "signed",
    "lock",
    "unsigned",
    "unlock",
    "strikeout"
  ],
  revisionPDF: ["signed", "lock"]
};
