import { reduce } from "lodash";
import moment from "moment";
import { evaluatePredicate } from "src/models/assessment_answers";

export const formatAnswersJson = ({
  formValues,
  questionWithVisiblePredicate,
  revisionIsFirstInSeries
}) => {
  // Keeping a copy so that formState does not change when preparing the payload.
  const formValuesCopy = {};
  // Iterate through the whole formValuesCopy and prepare it in the format that is desired for evaludatePredicate.
  for (const [key, value] of Object.entries(formValues)) {
    formatValues({key, value, result:formValuesCopy, formValues });
  }
  // We will be using formValuesCopy to evaluate the predicate, because it has all the nessasary parametes present.
  return reduce(
    formValues,
    function(result, value, key) {
      const predicate = questionWithVisiblePredicate[key];
      const deleteKeyValue = predicate
        ? !evaluatePredicate({
            answersJson: formValuesCopy,
            predicate,
            revisionIsFirstInSeries,
          })
        : false;
      const resultWithFormValues = { key, value, result, formValues };

      deleteKeyValue ? (delete result[key], delete formValuesCopy[key]) : formatValues(resultWithFormValues);
      return result;
    },
    {}
  );
};

const formatValues = ({ key, value, result, formValues }) => {
  if (key != "header" && typeof value === "object" && value !== null) {
    result[key] = [];
    Object.keys(value).forEach(k => {
      if (formValues[key][k]) {
        result[key].push(k);
      }
    });
  } else {
    if (key == "woundDate") {
      result[key] = moment.utc(value).toISOString();
    } else {
      result[key] = value;
    }
  }
  return result;
};
