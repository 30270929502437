import { dataActionTypes } from "src/action_types/data_action_types";

import { loadStates } from "src/constants/load_states";

const defaultState = {
  loadState: loadStates.loading,
  data: null
};

/**
 * Reducer for current user data
 */
export function currentUserReducer(state = defaultState, action) {
  switch (action.type) {
    case dataActionTypes.populateCurrentUserData: {
      return {
        data: action.payload,
        loadState: loadStates.loaded
      };
    }
    default:
      return state;
  }
}
