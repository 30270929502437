import React from "react";
export default class SwiftLogoComponent extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.28 97">
        <title>SSAW Logo Stacked - White</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              d="M212.28,14.05A14,14,0,1,1,198.23,0,14,14,0,0,1,212.28,14.05Zm-13.64-1.41,3.94-3.93a1.41,1.41,0,0,0-2-2l-8.73,8.73h6l-3.93,3.94a1.4,1.4,0,0,0,2,2l8.73-8.74Z"
              fill="#fff"
            />
            <path
              d="M57.91,19a2.13,2.13,0,0,0-.8-1.76A9.94,9.94,0,0,0,54.3,16,27.62,27.62,0,0,1,51,14.68q-4.14-2-4.14-5.6a5,5,0,0,1,1-3.13,6.76,6.76,0,0,1,2.92-2.11,11.45,11.45,0,0,1,4.25-.75,9.87,9.87,0,0,1,4.12.82A6.53,6.53,0,0,1,62,6.25a6.11,6.11,0,0,1,1,3.46H57.92a2.55,2.55,0,0,0-.8-2A3.1,3.1,0,0,0,55,7a3.47,3.47,0,0,0-2.17.61,1.79,1.79,0,0,0,.08,3A11.21,11.21,0,0,0,56,12a18.41,18.41,0,0,1,3.63,1.53A6.1,6.1,0,0,1,63.05,19a5.21,5.21,0,0,1-2.11,4.4A9.41,9.41,0,0,1,55.15,25a11.48,11.48,0,0,1-4.69-.93,7,7,0,0,1-3.16-2.55,6.6,6.6,0,0,1-1.07-3.72h5.16a3.22,3.22,0,0,0,.89,2.52,4.14,4.14,0,0,0,2.87.82,3.38,3.38,0,0,0,2-.55A1.83,1.83,0,0,0,57.91,19Z"
              fill="#fff"
            />
            <path
              d="M88.81,16.76,91.26,3.38h5.09L91.83,24.71H86.5L83.64,12.55l-2.8,12.16H75.52L71,3.38h5.11l2.45,13.38L81.47,3.38h4.37Z"
              fill="#fff"
            />
            <path d="M112.09,24.71H107V3.38h5.13Z" fill="#fff" />
            <path
              d="M138.16,16.22h-8.29v8.49h-5.15V3.38H139v4h-9.12v4.91h8.29Z"
              fill="#fff"
            />
            <path
              d="M167.17,7.35h-6.4V24.71h-5.14V7.35h-6.28v-4h17.82Z"
              fill="#fff"
            />
            <path
              d="M13.48,55.47a3.46,3.46,0,0,0-1.25-2.81,13.15,13.15,0,0,0-4.55-2,16.09,16.09,0,0,1-4.84-2,4.92,4.92,0,0,1-.15-8.05,8.27,8.27,0,0,1,5.15-1.53,8.52,8.52,0,0,1,3.8.82,6.29,6.29,0,0,1,2.6,2.28,6.07,6.07,0,0,1,.92,3.27H13.34a4.47,4.47,0,0,0-1.49-3.52,5.84,5.84,0,0,0-4-1.33,6.33,6.33,0,0,0-3.9,1.1,3.39,3.39,0,0,0-1.45,2.85,3.24,3.24,0,0,0,1.3,2.66A12.3,12.3,0,0,0,8,49a19.72,19.72,0,0,1,4.35,1.62,6.3,6.3,0,0,1,2.23,2,5.09,5.09,0,0,1,.74,2.77,4.82,4.82,0,0,1-2,4A8.54,8.54,0,0,1,8,61a10.5,10.5,0,0,1-4.14-.8A6.37,6.37,0,0,1,1,57.94a5.81,5.81,0,0,1-1-3.32H1.8a4.25,4.25,0,0,0,1.69,3.56A7.1,7.1,0,0,0,8,59.48a6.67,6.67,0,0,0,4-1.1A3.44,3.44,0,0,0,13.48,55.47Z"
              fill="#fff"
            />
            <path
              d="M30,50.12l-3,3v7.63H25.14V39.38H27v11.5l10.92-11.5h2.27l-8.93,9.49,9.62,11.84H38.64Z"
              fill="#fff"
            />
            <path d="M52.24,60.71h-1.8V39.38h1.8Z" fill="#fff" />
            <path
              d="M79.87,60.71h-1.8L65.81,42.46V60.71H64V39.38h1.82L78.09,57.65V39.38h1.78Z"
              fill="#fff"
            />
            <path
              d="M110.64,54.72H101l-2.2,6H96.87l8.07-21.33h1.72l8.07,21.33h-1.88Zm-9.12-1.52h8.55l-4.28-11.62Z"
              fill="#fff"
            />
            <path
              d="M139.71,60.71h-1.8L125.65,42.46V60.71h-1.82V39.38h1.82l12.27,18.27V39.38h1.79Z"
              fill="#fff"
            />
            <path
              d="M151.1,60.71V39.38h6a9.27,9.27,0,0,1,4.71,1.2A8.24,8.24,0,0,1,165,44a10.69,10.69,0,0,1,1.16,5.06v2A10.82,10.82,0,0,1,165,56.1a8.25,8.25,0,0,1-3.26,3.41,9.56,9.56,0,0,1-4.83,1.2Zm1.8-19.8V59.19h4a7,7,0,0,0,5.4-2.24,8.64,8.64,0,0,0,2-6.05V49a8.44,8.44,0,0,0-2-5.9,6.86,6.86,0,0,0-5.29-2.22Z"
              fill="#fff"
            />
            <path
              d="M43,91l.66,3.31.81-3.16L49,75.38h1.63L55.24,91.1,56,94.29l.7-3.34,3.86-15.57h1.84L57,96.71H55.24L50.41,80l-.55-2.11L49.34,80l-5,16.68H42.66L37.21,75.38h1.84Z"
              fill="#fff"
            />
            <path
              d="M87.64,87.2a12.26,12.26,0,0,1-1,5.17,7.75,7.75,0,0,1-2.91,3.43A8,8,0,0,1,79.34,97a7.57,7.57,0,0,1-6-2.67A10.7,10.7,0,0,1,71,87.12v-2.2a12.16,12.16,0,0,1,1-5.16,8.16,8.16,0,0,1,11.62-3.48,7.83,7.83,0,0,1,2.91,3.35,12.15,12.15,0,0,1,1.07,5Zm-1.81-2.31a9.46,9.46,0,0,0-1.74-6,6.27,6.27,0,0,0-9.51,0A9.49,9.49,0,0,0,72.81,85V87.2a9.46,9.46,0,0,0,1.76,6,6.27,6.27,0,0,0,9.55,0,9.72,9.72,0,0,0,1.71-6.11Z"
              fill="#fff"
            />
            <path
              d="M112.84,75.38V89.94a7.4,7.4,0,0,1-1,3.75,6.24,6.24,0,0,1-2.65,2.46,8.59,8.59,0,0,1-3.91.85,7.75,7.75,0,0,1-5.4-1.83,6.71,6.71,0,0,1-2.1-5.1V75.38h1.78V89.81A5.52,5.52,0,0,0,101.15,94a6.58,6.58,0,0,0,8.34,0A5.56,5.56,0,0,0,111,89.83V75.38Z"
              fill="#fff"
            />
            <path
              d="M139.71,96.71h-1.8L125.65,78.46V96.71h-1.82V75.38h1.82l12.28,18.27V75.38h1.78Z"
              fill="#fff"
            />
            <path
              d="M151.1,96.71V75.38h6a9.27,9.27,0,0,1,4.71,1.2A8.18,8.18,0,0,1,165,80a10.7,10.7,0,0,1,1.17,5.06v2A10.83,10.83,0,0,1,165,92.1a8.25,8.25,0,0,1-3.26,3.41,9.56,9.56,0,0,1-4.83,1.2Zm1.8-19.8V95.19h4a7,7,0,0,0,5.4-2.24,8.64,8.64,0,0,0,2-6V85a8.44,8.44,0,0,0-2-5.9,6.86,6.86,0,0,0-5.29-2.22Z"
              fill="#fff"
            />
            <path
              d="M171.15,76.46h-.55v1.6h-.24v-1.6h-.55v-.24h1.34Zm1.75.17-.52,1.43h-.18l-.52-1.45v1.45h-.25V76.22h.31l.55,1.47.54-1.47h.31v1.84h-.24Z"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    );
  }
}
