// React
import PropTypes from "prop-types";

import React from "react";

// Router
import { NavLink } from "react-router-dom";

// Icon Components
import { Glyphicon } from "src/components/ui_kit";

import {
  SwiftIconComponent,
  MedlineIconComponent,
  ComplianceIconComponent,
  MyDashboardIconComponent,
  OutcomesIconComponent,
  PatientsIconComponent,
  WoundsIconComponent,
  RiskIconComponent,
  SurveyIconComponent,
  PopulationIconComponent,
  AdminIconComponent,
  SettingsIconComponent,
  ExportIconComponent,
  MonitoringIconComponent,
  LogoutIconComponent
} from "src/components/icon_components";

// Styles
import styles from "./style.module.less";

// Translations
import i18n from "src/translations";

/**
 * React Component to display navigation links
 */
export class SideBarTabNavigationComponent extends React.Component {
  static propTypes = {
    sideBarNavigationItems: PropTypes.array.isRequired,
    myDashboardTabEnabled: PropTypes.bool.isRequired,
    alertsTabEnabled: PropTypes.bool.isRequired,
    evaluationListEnabled: PropTypes.bool.isRequired,
    exportsTabEnabled: PropTypes.bool.isRequired,
    outcomesTabEnabled: PropTypes.bool.isRequired,
    searchPatientDashboardEnabled: PropTypes.bool.isRequired,
    logoutTabEnabled: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    healingIndexDashboardEnabled: PropTypes.bool.isRequired,
    authWorkflow: PropTypes.string.isRequired
  };

  handleSignOut = event => {
    event.preventDefault();
    this.props.signOut();
  };

  renderIcon() {
    const { isMedline } = this.props;
    if (isMedline)
      return (
        <li>
          <div className={styles["medline-logo"]}>
            <MedlineIconComponent />
            <div>Medline NE1</div>
          </div>
        </li>
      );
    return (
      <li>
        <div className={styles["swift-logo"]}>
          <SwiftIconComponent />
        </div>
      </li>
    );
  }

  renderComplianceLink() {
    if (this.props.sideBarNavigationItems.includes("compliance")) {
      return (
        <li>
          <NavLink to="/compliance" activeClassName={styles["active-nav-link"]}>
            <ComplianceIconComponent />
            <br />
            {i18n.t(`headers.compliance`)}
          </NavLink>
        </li>
      );
    }
  }

  /* renderAlertsLink will eventually be replaced by renderMyDashboardLink #theRealMyDashboard */
  renderAlertsLink() {
    const { alertsTabEnabled, evaluationListEnabled } = this.props;
    let myDashboardLink;
    if (alertsTabEnabled) {
      myDashboardLink = "/my-dashboard/alerts";
    } else if (evaluationListEnabled) {
      myDashboardLink = "/my-dashboard/evaluation-list/wound";
    } else {
      myDashboardLink = "/my-dashboard/wound-risk";
    }
    return (
      <li>
        <NavLink
          // Change to "/my-dashboard/alerts" #theRealMyDashboard
          to={myDashboardLink}
          isActive={(match, location) => {
            if (
              location.pathname.includes("/my-dashboard/alerts") ||
              location.pathname.includes(
                "/my-dashboard/evaluation-list/wound"
              ) ||
              location.pathname.includes("/my-dashboard/wound-risk")
            ) {
              return true;
            }
            return false;
          }}
          activeClassName={styles["active-nav-link"]}
        >
          <MyDashboardIconComponent />
          <br />
          {i18n.t(`headers.myDashboard`)}
        </NavLink>
      </li>
    );
  }

  /* Merge renderAlertsLink stuff here when #theRealMyDashboard is on it's way */
  renderMyDashboardLink() {
    return (
      <li>
        <NavLink to="/my-dashboard" activeClassName={styles["active-nav-link"]}>
          <MyDashboardIconComponent />
          <br />
          {i18n.t(`headers.myDashboard`)}
        </NavLink>
      </li>
    );
  }

  renderOutcomesLink() {
    return (
      <li>
        <NavLink to="/outcomes" activeClassName={styles["active-nav-link"]}>
          <OutcomesIconComponent />
          <br />
          Outcomes
        </NavLink>
      </li>
    );
  }

  renderPatientLink() {
    if (this.props.sideBarNavigationItems.includes("patients")) {
      return (
        <li>
          <NavLink to="/patients" activeClassName={styles["active-nav-link"]}>
            <PatientsIconComponent />
            <br />
            Patients
          </NavLink>
        </li>
      );
    }
  }

  renderPatientSearchLink() {
    return (
      <li>
        <NavLink
          to="/patient-search"
          activeClassName={styles["active-nav-link"]}
        >
          <Glyphicon glyph="search" size="30px" />
          <br />
          Search
        </NavLink>
      </li>
    );
  }

  renderWoundsLink() {
    if (this.props.sideBarNavigationItems.includes("wounds")) {
      return (
        <li>
          <NavLink to="/wounds" activeClassName={styles["active-nav-link"]}>
            <WoundsIconComponent />
            <br />
            Wounds
          </NavLink>
        </li>
      );
    }
  }

  renderRiskLink() {
    if (this.props.sideBarNavigationItems.includes("risk")) {
      return (
        <li>
          <NavLink to="/risk" activeClassName={styles["active-nav-link"]}>
            <RiskIconComponent />
            <br />
            Risk
          </NavLink>
        </li>
      );
    }
  }

  renderSurveyLink() {
    if (this.props.sideBarNavigationItems.includes("survey")) {
      return (
        <li>
          <NavLink to="/etiology" activeClassName={styles["active-nav-link"]}>
            <SurveyIconComponent />
            <br />
            Survey
          </NavLink>
        </li>
      );
    }
  }

  renderPopulationLink() {
    if (this.props.sideBarNavigationItems.includes("population")) {
      return (
        <li>
          <NavLink
            to="/population"
            className={styles["paddingleft1px"]}
            activeClassName={styles["active-nav-link"]}
          >
            <PopulationIconComponent />
            <br />
            Population
          </NavLink>
        </li>
      );
    }
  }

  renderSettingsLink() {
    if (this.props.sideBarNavigationItems.includes("settings")) {
      return (
        <li>
          <NavLink to="/account" activeClassName={styles["active-nav-link"]}>
            <SettingsIconComponent />
            <br />
            Settings
          </NavLink>
        </li>
      );
    }
  }
  renderAdminLink() {
    if (this.props.sideBarNavigationItems.includes("admin")) {
      return (
        <li>
          <NavLink
            isActive={(match, location) => {
              return location.pathname.startsWith("/admin");
            }}
            to={this.props.adminDefaultView}
            activeClassName={styles["active-nav-link"]}
          >
            <AdminIconComponent />
            Admin
          </NavLink>
        </li>
      );
    }
  }

  renderMonitoringLink() {
    if (this.props.sideBarNavigationItems.includes("monitoring")) {
      return (
        <li>
          <NavLink
            isActive={(match, location) => {
              return location.pathname.startsWith("/monitoring");
            }}
            to={"/monitoring"}
            activeClassName={styles["active-nav-link"]}
          >
            <MonitoringIconComponent />
            Monitoring
          </NavLink>
        </li>
      );
    }
  }

  renderExportsLink() {
    return (
      <li>
        <NavLink to="/exports" activeClassName={styles["active-nav-link"]}>
          <ExportIconComponent />
          Exports
        </NavLink>
      </li>
    );
  }

  renderLogOutLink() {
    if (this.props.sideBarNavigationItems.includes("settings")) {
      return (
        <li>
          <a href="#" onClick={this.handleSignOut}>
            <LogoutIconComponent />
            Logout
          </a>
        </li>
      );
    }
  }

  isEmbeddedAuthWorkflow() {
    return this.props.authWorkflow === "embedded";
  }

  render() {
    const {
      myDashboardTabEnabled,
      alertsTabEnabled,
      evaluationListEnabled,
      outcomesTabEnabled,
      adminTabEnabled,
      exportsTabEnabled,
      monitoringTabEnabled,
      searchPatientDashboardEnabled,
      logoutTabEnabled,
      healingIndexDashboardEnabled,
      isReportingEnabled
    } = this.props;
    return (
      <div
        className={`${styles["sidebar-nav-menu"]} sidebar-nav-menu-branding`}
      >
        <ul className={`nav nav-stacked ${styles["logo-nav"]}`}>
          {this.renderIcon()}
        </ul>
        {!searchPatientDashboardEnabled &&
          isReportingEnabled &&
          !this.isEmbeddedAuthWorkflow() && (
            <ul className={`nav nav-stacked main-nav`}>
              {this.renderComplianceLink()}
              {(alertsTabEnabled ||
                evaluationListEnabled ||
                healingIndexDashboardEnabled) &&
                this.renderAlertsLink()}
              {myDashboardTabEnabled && this.renderMyDashboardLink()}
              {this.renderPatientLink()}
              {this.renderWoundsLink()}
              {this.renderRiskLink()}
              {this.renderSurveyLink()}
              {outcomesTabEnabled && this.renderOutcomesLink()}
              {this.renderPopulationLink()}
              {adminTabEnabled && this.renderAdminLink()}
              {exportsTabEnabled && this.renderExportsLink()}
              {monitoringTabEnabled && this.renderMonitoringLink()}
            </ul>
          )}

        {this.isEmbeddedAuthWorkflow() && (
          <ul className={`nav nav-stacked main-nav`}>
            {this.renderComplianceLink()}
            {this.renderWoundsLink()}
            {this.renderRiskLink()}
            {this.renderSurveyLink()}
            {this.renderPopulationLink()}
          </ul>
        )}

        {!isReportingEnabled && !this.isEmbeddedAuthWorkflow() && (
          <ul className={`nav nav-stacked main-nav`}>
            {this.renderPatientLink()}
            {adminTabEnabled && this.renderAdminLink()}
            {exportsTabEnabled && this.renderExportsLink()}
          </ul>
        )}

        {searchPatientDashboardEnabled && !this.isEmbeddedAuthWorkflow() && (
          <ul className={`nav nav-stacked main-nav`}>
            {this.renderPatientSearchLink()}
          </ul>
        )}

        {!this.isEmbeddedAuthWorkflow() && (
          <ul className={`nav nav-stacked ${styles["bottom-nav"]}`}>
            {this.renderSettingsLink()}
            {logoutTabEnabled && this.renderLogOutLink()}
          </ul>
        )}
      </div>
    );
  }
}
