import { connect } from "react-redux";

// Component
import { PatientCardComponent } from "src/components/cards";

// Selectors
import {
  patientLinksEnabledSelector,
  partnerNameSelector
} from "src/selectors/config_selectors";
import { patientLinkTemplateSelector } from "src/selectors/application_state_selectors";

function mapStateToProps(state) {
  return {
    patientLinksEnabled: patientLinksEnabledSelector(state),
    partnerName: partnerNameSelector(state),
    patientLinkTemplate: patientLinkTemplateSelector(state)
  };
}

export const PatientCardContainer = connect(mapStateToProps)(
  PatientCardComponent
);
