import { createSelector } from "reselect";

// Selectors
import { createAllPagesEventsForAssessmentAnswerGroupIdQuerySelector } from "src/selectors/query_selectors/events_for_assessment_answer_group_id";

/**
 * create a selector to get all the contexts for loading events with assessment answer group id
 * @param {Object} props
 * @param {string} props.assessmentAnswerGroupId
 * @returns {function} selector
 */
export function createAllPagesEventsForAssessmentAnswerGroupIdContextSelector({
  assessmentAnswerGroupId
}) {
  return createSelector(
    createAllPagesEventsForAssessmentAnswerGroupIdQuerySelector({
      assessmentAnswerGroupId
    }),
    queries => {
      return queries;
    }
  );
}
