import React from "react";
import FontAwesome from "react-fontawesome";

// TODO: migrate components to use the new Loading Component in src/components, and remove this
export default () => {
  return (
    <div className="loading-state">
      <p className="text-center">
        <FontAwesome name="spinner" size="2x" spin />
        <br />
        <br />
        Loading ...
      </p>
    </div>
  );
};
