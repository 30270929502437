// @flow
export const bradenScores = {
  veryHigh: {
    minScore: 0,
    maxScore: 9
  },
  high: {
    minScore: 10,
    maxScore: 12
  },
  medium: {
    minScore: 13,
    maxScore: 14
  },
  low: {
    minScore: 15,
    maxScore: 18
  },
  no: {
    minScore: 19,
    maxScore: 23
  },
  none: {
    minScore: null,
    maxScore: null
  }
};
export const nortonScores = {
  high: {
    minScore: -3,
    maxScore: 10
  },
  medium: {
    minScore: 11,
    maxScore: 15
  },
  low: {
    minScore: 16,
    maxScore: 20
  }
};
