import { createSelector } from "reselect";
import { partnerNameSelector } from "src/selectors/config_selectors";

/**
 * Sign in view is disabled if it is an EMR integration
 */
export const signInViewIsDisabledSelector = createSelector(
  partnerNameSelector,
  partnerName => {
    return partnerName.length > 1;
  }
);
