import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

// Constants
import { loadStates } from "src/constants/load_states";

// Containers
import { SeriesListItemContainer } from "src/containers/series_list_item_container";

// Components
import { Button } from "src/ui-kit";
import { LoadingComponent } from "src/components/loading_component";

// Styles
import styles from "./style.module.less";

//Analytics
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";

/**
 * Renders a list of series (wounds)
 */
export class SeriesListComponent extends PureComponent {
  static propTypes = {
    series: PropTypes.array.isRequired,
    seriesMeta: PropTypes.object,
    seriesLoadState: PropTypes.string,
    match: PropTypes.object.isRequired,
    loadMoreSeries: PropTypes.func.isRequired
  };

  render() {
    const { series, seriesMeta, seriesLoadState, loadMoreSeries } = this.props;

    if (!seriesLoadState || seriesLoadState == loadStates.loading) {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_PATIENTS_SERIES_LIST">
          <LoadingComponent height="50px" />
        </StartRenderTimeTrackComponent>
      );
    }

    if (seriesLoadState == loadStates.error) {
      return (
        <FinishRenderTimeTrackComponent
          eventName="RENDER_PATIENTS_SERIES_LIST"
          error={true}
        >
          <LoadingComponent height="50px" />
        </FinishRenderTimeTrackComponent>
      );
    }

    const seriesList =
      series &&
      series.map(s => {
        return <SeriesListItemContainer key={s.id} series={s} />;
      });

    const recordCount = get(
      { seriesMeta },
      "seriesMeta.meta.recordCount",
      null
    );

    const showLoadMore =
      series &&
      recordCount > series.length &&
      seriesLoadState != loadStates.loadingMore;

    return (
      <div className="assessments assessments--wound">
        <FinishRenderTimeTrackComponent eventName="RENDER_PATIENTS_SERIES_LIST">
          {seriesList}
        </FinishRenderTimeTrackComponent>
        {showLoadMore && (
          <div className={styles["load-more-container"]}>
            <Button className="dashboard-btn" onClick={loadMoreSeries}>
              Load More...
            </Button>
          </div>
        )}
        {seriesLoadState == loadStates.loadingMore && (
          <LoadingComponent height="50px" />
        )}
      </div>
    );
  }
}
