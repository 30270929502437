import axios from "axios";
import qs from "qs";
import {
  visitSequenceConfiguration,
  patientEvaluationsConfiguration
} from "src/constants/filters_configurations/compliance";

const saved = async () => {
  const filters = { filter: { dashboard: "compliance" }, sort: "title" };
  const queryString = qs.stringify(filters);
  const response = await axios.get(`api/v4/saved-filters?${queryString}`);
  const { data: savedFilters } = response.data;

  return savedFilters;
};

const evaluations = async () => {
  // current algorithm:
  // 1. get all org policies
  // 2. find wound policy
  //  policy.internal_name == 'wound_schema'
  // 3. find out which assessment version to use
  //  src/selectors/config_selectors/assessment_version_selector/index.js
  // 4. define which evaluations config to use based on assessment version
  //  src/selectors/ui_config_selectors/compliance/evaluations_config_selector/index.js
  //  if v7 or v8: evaluationsConfigurationV7
  //  else: use evaluationsConfigurationDefault
  //  this defines the top level filter options under evaluations
  // 5. find wound property on config object (step 4) and add assessmentVersion (step 3) and children to it
  //  children list is defined by woundTypesConfigurationFilterForSchemaPolicySelector
  //  src/selectors/ui_config_selectors/global/wound_type_filter_configuration_selector/index.js
  //  this defines the filter options under wound
  //  for children:
  //  5.1. get assessmentSchemas (?)
  //  5.2. get woundSchemaPolicy (already available from step 2)
  //  5.3. get schemaLocalizationDictionary (?) -> used for sorting filter options
  //  5.4. find main schema
  //  5.5 find secondary schemas
  //  5.6 create hierarchy by calling createQuestionHierarchyFromMultipleSchemas(mainSchema, secondarySchemas)
  //  5.7 sort alphabetically if there is a schemaLocalizationDictionary
  //  5.8 maybe format result and return it in a way that filter section can read
};

const locations = async () => {
  // const filters = { include_all: true, include_leaves: true };
  // const queryString = qs.stringify(filters);
  // const response = await axios.get(
  //   `api/v2/organizations/${organizationId}/locations?${queryString}`
  // );
  // const { locations } = response.data;
  // return locations;
};

const payers = async organizationId => {
  const queryString = qs.stringify({ page: { limit: 1000 } });
  const response = await axios.get(
    `api/v4/organizations/${organizationId}/payers?${queryString}`
  );
  const { data: payers } = response.data;

  return payers;
};

const clinicians = async () => {
  const filters = {
    filter: { has_created_assessment: true, patient_users: false },
    sort: "first_name,last_name",
    page: { limit: 1000 }
  };
  const queryString = qs.stringify(filters);
  const response = await axios.get(`api/v4/users?${queryString}`);
  const { data: users } = response.data;

  return users;
};

const visitFrequencies = async () => visitSequenceConfiguration.filterValues;

const patientEvaluations = async () =>
  patientEvaluationsConfiguration.filterValues;

const FiltersService = {
  saved,
  evaluations,
  locations,
  payers,
  clinicians,
  visitFrequencies,
  patientEvaluations
};

export default FiltersService;
