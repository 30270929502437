import { createSelector } from "reselect";

// Selectors
import { createFirstPageContextSelector } from "../create_first_page_context_selector";
import { patientsResourceSelectors } from "src/selectors/data_selectors";

// Constants
import { apiPageLimits } from "src/constants";

// Utils
import {
  patientSearchStringSelector,
  selectedLocationIdsSelector,
  patientsSectionSelector
} from "src/selectors/section_selectors/patients";

import SingleValueReduxUtil from "src/ui_processors/single_value";

const dischargedOptionSelectedSelectors = SingleValueReduxUtil.createSelectors(
  "dischargedOptionSelected",
  patientsSectionSelector
);

/**
 * create a selector to get all payloads for all pages for patients
 */
export function createAllPagesPayloadSelector() {
  return createSelector(
    patientsResourceSelectors.createPageCountSelector(
      createFirstPageContextSelector(),
      apiPageLimits.patients
    ),
    patientSearchStringSelector,
    selectedLocationIdsSelector,
    dischargedOptionSelectedSelectors.fieldSelector,
    (pageCount, searchString, locationIds, dischargedOptionSelected) => {
      let payloads = [];
      for (let i = 0; i < pageCount; i++) {
        const payload = {
          filter: {
            name_or_mrn_or_code: searchString ? searchString : undefined,
            location_id: locationIds ? locationIds.join() : undefined,
            state: dischargedOptionSelected
              ? "admitted,new,discharged"
              : "admitted,new"
          },
          page: {
            offset: i * apiPageLimits.patients,
            limit: apiPageLimits.patients
          },
          include: "studies"
        };

        payloads.push(payload);
      }

      return payloads;
    }
  );
}
