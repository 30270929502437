import { createSelector } from "reselect";
import { currentUserSelector } from "../current_user_selector";
import { securityRoleIds } from "src/constants/security_access";

/**
 * Memoized selector, returns true if user is a Swift Admin, false otherwise
 */

export const isRoleSupplierSelector = createSelector(
  currentUserSelector,
  currentUser => {
    if (currentUser && currentUser.relationships.roles.data) {
      const userRoles = currentUser.relationships.roles.data.map(
        role => role.id
      );
      const supplierRoles = [securityRoleIds.supplier];
      return userRoles.some(userRole => supplierRoles.includes(userRole));
    }
    return false;
  }
);
