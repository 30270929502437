import React from "react";
import pickby from "lodash.pickby";
import get from "lodash.get";
import PropTypes from "prop-types";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormCheckboxComponent } from "src/components/forms/form_checkbox_component";
import { FormInputOptionComponent } from "src/components/forms/form_input_option_component";
import { FormComponent } from "src/components/forms/form_component_legacy";
import { FormTitleComponent } from "src/components/forms/form_title_component";
import { GridRow, GridColumn } from "src/components/grid";

import styles from "./style.module.less";
import { Button } from "src/ui-kit";

import { reduxFormNames } from "src/constants";
import { validatePassword, required, validateEmail } from "src/validations";

export class UserFormComponent extends React.Component {
  static propTypes = {
    userPasswordMinLength: PropTypes.number.isRequired
  };

  state = {
    initialValues: {}
  };
  constructor(props) {
    super(props);
    const {
      formName,
      user,
      organizations,
      selectedPartnerId,
      roles,
      userPasswordMinLength
    } = props;

    let initialUserObject = {};
    if (formName == reduxFormNames.userEditForm) {
      initialUserObject = this.getInitialValuesForUser(user);
    } else {
      initialUserObject = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        partners: selectedPartnerId,
        organization: { [organizations[0].id]: true },
        facility: {},
        units: {},
        roles: roles ? Object.keys(props.roles)[0] : null,
        passwordMinLength: userPasswordMinLength || null
      };
    }
    this.state = {
      initialValues: initialUserObject
    };
  }

  /**
   * Get initial form values for a user
   * @param {Object} user
   * @return {Object} initial values
   */
  getInitialValuesForUser(user) {
    const facilitiesObject =
      user &&
      user.relationships &&
      user.relationships.facilities &&
      user.relationships.facilities.data
        ? user.relationships.facilities.data.reduce((agg, current) => {
            agg[current.id] = true;
            return agg;
          }, {})
        : {};

    const organizationsObject =
      user &&
      user.relationships &&
      user.relationships.organizations &&
      user.relationships.organizations.data
        ? user.relationships.organizations.data.reduce((agg, current) => {
            agg[current.id] = true;
            return agg;
          }, {})
        : {};

    const unitsObject =
      user &&
      user.relationships &&
      user.relationships.facilityUnits &&
      user.relationships.facilityUnits.data
        ? user.relationships.facilityUnits.data.reduce((agg, current) => {
            agg[current.id] = true;
            return agg;
          }, {})
        : {};

    const defaultFacility = get(
      { user },
      "user.attributes.profile.defaultFacilityId",
      ""
    );

    return {
      firstName: user.attributes.profile
        ? user.attributes.profile.firstName
        : "",
      lastName: user.attributes.profile ? user.attributes.profile.lastName : "",
      designation: user.attributes.profile
        ? user.attributes.profile.designation
        : "",
      email: user.attributes.email,
      password: user.attributes.password,
      partners: user.attributes.partnerId,
      organization: organizationsObject,
      facility: facilitiesObject,
      units: unitsObject,
      id: user.id,
      roles:
        user.relationships.roles &&
        user.relationships.roles.data &&
        user.relationships.roles.data.length
          ? Object.values(user.relationships.roles.data)[0].id
          : null,
      defaultFacility: defaultFacility,
      passwordMinLength: this.props.userPasswordMinLength
    };
  }

  componentWillReceiveProps(nextProps) {
    const { formName, changeField, clearFields } = this.props;

    if (formName != reduxFormNames.userEditForm) {
      if (propsAreDifferent(nextProps, this.props, "partners")) {
        if (nextProps.partners.length) {
          changeField("partners", nextProps.partners[0]);
        } else {
          clearFields("partners");
        }
      }
    }

    if (propsAreDifferent(nextProps, this.props, "facilities")) {
      if (nextProps.facilities.length) {
        changeField(
          "facility",
          nextProps.facilities.reduce((agg, current) => {
            agg[current.id] =
              nextProps.formValues && nextProps.formValues["facility"]
                ? Object.keys(
                    pickby(nextProps.formValues["facility"])
                  ).includes(current.id)
                : false;
            return agg;
          }, {})
        );
      } else clearFields("facility");
    }

    if (propsAreDifferent(nextProps, this.props, "units")) {
      if (nextProps.units.length) {
        changeField(
          "units",
          nextProps.units.reduce((agg, current) => {
            agg[current.id] =
              nextProps.formValues && nextProps.formValues["units"]
                ? Object.keys(pickby(nextProps.formValues["units"])).includes(
                    current.id
                  )
                : false;
            return agg;
          }, {})
        );
      } else clearFields("units");
    }

    if (propsAreDifferent(nextProps, this.props, "user")) {
      this.setState({
        initialValues: this.getInitialValuesForUser(nextProps.user)
      });
    }

    if (
      this.props.createLoadState === "loading" &&
      nextProps.createLoadState === "ready"
    ) {
      this.props.clearFields(
        "firstName",
        "password",
        "lastName",
        "roles",
        "email"
      );
    }
  }

  saveUser = () => {
    const {
      formValues,
      user,
      currentUser,
      sendUpdateUserAction,
      sendCreateUserAction,
      cancel
    } = this.props;
    const values = formValues;
    const payload = {
      data: {
        attributes: {
          partner_id: values.partners
            ? values.partners
            : currentUser.attributes.partnerId,
          email: values.email,
          password: values.password,
          own_patient_id: get(user, "user.attributes.ownPatientId", null),
          profile: {
            first_name: values.firstName,
            last_name: values.lastName,
            designation: values.designation,
            default_facility_id: values.defaultFacility,
            birth_at: get(user, "user.attributes.profile.birthAt", null),
            birth_at_verified_at: get(
              user,
              "user.attributes.profile.birthAtVerifiedAt",
              null
            ),
            phone_number: get(
              user,
              "user.attributes.profile.phoneNumber",
              null
            ),
            id: get(user, "user.attributes.profile.id", null),
            user_id: get(user, "user.attributes.profile.userId", null),
            title: get(user, "user.attributes.profile.title", null),
            share_token: get(user, "user.attributes.profile.shareToken", null),
            created_at: get(user, "user.attributes.profile.createdAt", null),
            updated_at: get(user, "user.attributes.profile.updatedAt", null)
          }
        },
        relationships: {
          roles: {
            data: [{ type: "roles", id: values.roles }]
          },
          organizations: {
            data: Object.keys(pickby(values.organization)).map(item => {
              return {
                type: "organizations",
                id: item
              };
            })
          },
          facilities: {
            data: Object.keys(pickby(values.facility)).map(item => {
              return {
                type: "facilities",
                id: item
              };
            })
          },
          facility_units: {
            data: Object.keys(pickby(values.units)).map(item => {
              return {
                type: "facility_units",
                id: item
              };
            })
          }
        },
        type: "users",
        id: values.id
      }
    };

    const action = values.id ? sendUpdateUserAction : sendCreateUserAction;
    action(payload).then(() => cancel());
  };

  render() {
    const {
      formName,
      partners,
      organizations,
      facilities,
      units,
      pristine,
      submitting,
      roles,
      customRoles,
      user,
      partnerDropDownEnabled,
      defaultFacilities
    } = this.props;

    const rolesData = { ...roles, ...customRoles };

    return (
      <div className={styles["style-buttons"]}>
        <FormTitleComponent>
          <h4>{user && user.id ? "Update User" : "Add User"}</h4>
        </FormTitleComponent>

        <FormComponent
          initialValues={this.state.initialValues}
          form={formName}
          onSubmit={this.saveUser}
        >
          <GridRow className="row">
            <GridColumn sm="2">
              <label className={styles["label-text"]}>Name</label>
            </GridColumn>
            <GridColumn sm="10">
              <div className={`input-group ${styles["group-textbox"]}`}>
                <FormInputComponent
                  name="firstName"
                  component="input"
                  componentType="text"
                  placeholder="First Name"
                  className="form-control"
                  validate={[required]}
                />

                <FormInputComponent
                  name="lastName"
                  component="input"
                  componentType="text"
                  placeholder="Last Name"
                  className="form-control"
                  validate={[required]}
                />
              </div>
            </GridColumn>
          </GridRow>
          <FormInputComponent
            name="email"
            component="input"
            componentType="text"
            placeholder="Email"
            labelText="Email"
            validate={[required, validateEmail]}
          />
          <FormInputComponent
            className="form-control"
            name="password"
            component="password"
            componentType="password"
            placeholder="Password"
            labelText="Password"
            autoComplete="new-password"
            validate={
              formName == reduxFormNames.userEditForm
                ? [validatePassword]
                : [required, validatePassword]
            }
          />
          <FormInputComponent
            className="form-control"
            name="designation"
            component="input"
            componentType="text"
            placeholder="Designation"
            labelText="Designation"
          />
          {partnerDropDownEnabled && (
            <FormInputComponent
              name="partners"
              component="input"
              componentType="select"
              labelText="Partner"
            >
              {partners.map(itemSet => {
                return (
                  itemSet.attributes &&
                  itemSet.attributes.name && (
                    <FormInputOptionComponent
                      key={itemSet.id}
                      text={itemSet.attributes.name}
                      value={itemSet.id}
                    />
                  )
                );
              })}
            </FormInputComponent>
          )}
          <GridRow className={`row ${styles["margin-bottom15px"]}`}>
            <GridColumn sm="6">
              <label>Organization</label>
              {Object.values(organizations).map(itemSet => {
                return (
                  <FormCheckboxComponent
                    key={itemSet.id}
                    name={`organization.${itemSet.id}`}
                    component="input"
                    componentType="checkbox"
                    value={itemSet.id}
                    labelText={itemSet.attributes.name}
                    checked={
                      this.props.formValues &&
                      this.props.formValues["organization"] &&
                      Object.keys(
                        pickby(this.props.formValues["organization"])
                      ).includes(itemSet.id)
                        ? true
                        : false
                    }
                  />
                );
              })}
            </GridColumn>
          </GridRow>
          <GridRow className={`row ${styles["margin-bottom15px"]}  `}>
            <GridColumn sm="6">
              <label>Facilities</label>
            </GridColumn>
            <GridColumn sm="6">
              <label>Units</label>
            </GridColumn>
          </GridRow>
          {facilities.map(itemSet => {
            return (
              <div
                className={`${styles["borderBottom1px"]} ${
                  styles["margin-bottom25px"]
                } `}
                key={itemSet.id}
              >
                <GridRow className={`${styles["margin-bottom15px"]} `}>
                  <GridColumn sm="6">
                    <FormCheckboxComponent
                      name={`facility.${itemSet.id}`}
                      component="input"
                      componentType="checkbox"
                      value={itemSet.id}
                      labelText={itemSet.attributes.name}
                      checked={
                        this.props.formValues &&
                        this.props.formValues["facility"] &&
                        Object.keys(
                          pickby(this.props.formValues["facility"])
                        ).includes(itemSet.id)
                          ? true
                          : false
                      }
                    />
                  </GridColumn>
                  <GridColumn sm="6">
                    {units
                      ? units
                          .filter(o => o.attributes.facilityId == itemSet.id)
                          .map(itemSetUnits => {
                            return (
                              <FormCheckboxComponent
                                key={itemSetUnits.id}
                                name={`units.${itemSetUnits.id}`}
                                component="input"
                                componentType="checkbox"
                                value={itemSetUnits.id}
                                labelText={itemSetUnits.attributes.name}
                                checked={
                                  this.props.formValues &&
                                  this.props.formValues["units"] &&
                                  Object.keys(
                                    pickby(this.props.formValues["units"])
                                  ).includes(itemSetUnits.id)
                                    ? true
                                    : false
                                }
                              />
                            );
                          })
                      : ""}
                  </GridColumn>
                </GridRow>
              </div>
            );
          })}
          <FormInputComponent
            name="defaultFacility"
            component="input"
            componentType="select"
            labelText="Default Facility"
            validate={[required]}
          >
            <FormInputOptionComponent
              key="empty"
              text={
                defaultFacilities.length === 0
                  ? "-- Select at least one facility above to assign --"
                  : "-- Select Default Facility --"
              }
              value=""
            />
            {defaultFacilities.map(itemSet => {
              return (
                itemSet.attributes &&
                itemSet.attributes.name && (
                  <FormInputOptionComponent
                    key={itemSet.id}
                    text={itemSet.attributes.name}
                    value={itemSet.id}
                  />
                )
              );
            })}
          </FormInputComponent>

          <FormInputComponent
            name="roles"
            component="input"
            componentType="select"
            labelText="Role"
            validate={[required]}
          >
            {Object.values(rolesData).map(itemSet => {
              return (
                itemSet.attributes &&
                itemSet.attributes.primaryName && (
                  <FormInputOptionComponent
                    key={itemSet.id}
                    text={itemSet.attributes.primaryName}
                    value={itemSet.id}
                  />
                )
              );
            })}
          </FormInputComponent>
          <div className="pull-right">
            <Button
              className="dashboard-btn"
              disabled={pristine || submitting}
              subtype="submit"
            >
              Save
            </Button>
            <Button
              className="btn-default"
              disabled={submitting}
              subtype="button"
              onClick={this.props.cancel}
            >
              Cancel
            </Button>
          </div>
        </FormComponent>
      </div>
    );
  }
}
