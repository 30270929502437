import axios from "axios";
import { registerForCancellation } from "../cancellation";

/**
 * Fetch survey tally
 * @param {Object} payload the query parameters
 * startDate
 * endDate
 * woundTypes
 * locationIds
 * @return {Promise} Promise with the response
 */
export function fetchSurveyTally(payload) {
  const url = "/api/v3/survey/tally";

  const config = {
    cancelToken: registerForCancellation(url)
  };

  return axios.post(url, payload, config);
}

/**
 * Fetch wound details
 * @param {Object} payload
 * @return {Promise} Promise with the response
 */
export function fetchSurveyWoundDetails(payload) {
  const url = "/api/v3/survey/details";
  const config = {
    cancelToken: registerForCancellation(url)
  };

  return axios.post(url, payload, config);
}

/**
 * Post request to generate a csv for wound details
 * @param {Object} payload
 * @return {Promise} Promise with the response
 */
export function createWoundDetailsCsv(payload) {
  const url = "/api/v3/survey/details/csv";
  return axios.post(url, payload);
}
