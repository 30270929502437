import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { propsAreDifferent } from "src/utils/props_are_different";

// Selectors
import { clinicalChartPayloadSelector } from "src/selectors/query_selectors/wounds";

// Actions
import { loadWoundAssessmentProgressChartDataAction } from "src/actions/data_actions";

/**
 * HOC that is responsible for loading wound assessment progress chart data
 * @param {Class} WrappedComponent the component dependent on this data
 * @return {Class} Component wrapped by the loader component
 */
export function withWoundAssessmentsProgressChartDataLoader(WrappedComponent) {
  class WoundAssessmentProgressChartDataLoader extends React.Component {
    static propTypes = {
      payload: PropTypes.object.isRequired,
      sendLoadChartDataAction: PropTypes.func.isRequired
    };

    componentWillMount() {
      // TODO: Keep track of location loading state and use that
      if (this.props.payload.location_ids.length) {
        this.loadChartData(this.props);
      }
    }

    componentWillReceiveProps(nextProps) {
      if (
        propsAreDifferent(this.props, nextProps, "payload") &&
        nextProps.payload.location_ids.length
      ) {
        this.loadChartData(nextProps);
      }
    }

    loadChartData(props) {
      const { sendLoadChartDataAction, payload } = props;
      sendLoadChartDataAction(payload);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WoundAssessmentProgressChartDataLoader);
}

/**
 * Map the props needed for loading data
 */
function mapStateToProps(state) {
  return {
    payload: clinicalChartPayloadSelector(state)
  };
}

/**
 * Map dispatch to a props for loading data
 */
function mapDispatchToProps(dispatch) {
  return {
    sendLoadChartDataAction(payload) {
      dispatch(loadWoundAssessmentProgressChartDataAction(payload));
    }
  };
}
