export const woundsActionTypes = {
  selectLocations: "WOUNDS_SELECT_LOCATIONS",
  deselectLocations: "WOUNDS_DESELECT_LOCATIONS",

  selectWoundOptions: "WOUNDS_SELECT_OPTIONS",
  deselectWoundOptions: "WOUNDS_DESELECT_OPTIONS",

  setAcquiredFilter: "WOUNDS_SET_ACQUIRED_FILTER",

  selectDate: "WOUNDS_SELECT_DATE",

  setSelectedProgressFilter: "WOUNDS_SELECT_WOUND_ASSESSMENT_LIST_TAB"
};
