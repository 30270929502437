// @flow
export type $relationshipSchema = $Exact<{
  name: string,
  alias?: string,
  type?: number,
  getVariableName?: (ent: Object) => string
}>;

export type $singleSchema = {
  propertyName: string,
  modifier: Function,
  relationshipSchemas: Array<$relationshipSchema>
};

export type $schema = {
  [key: string]: $singleSchema
};

type $options = {
  recordInput: Object,
  modifier?: (ent: Object) => Object
};
const onlyKeys = function(onlyObject: Object, fullObject: Object): Object {
  return Object.keys(onlyObject).reduce((finalResult, key) => {
    const fullObjectResult = fullObject[key];
    if (fullObjectResult !== undefined) {
      finalResult[key] = fullObjectResult;
    }
    return finalResult;
  }, {});
};

export const generateSchema = function(
  relationshipSchemas: Array<$relationshipSchema>,
  options: $options
): $singleSchema {
  const premodifier = ent => ent;
  let propertyName = "";
  const possibleModifiers = {
    // $FlowFixMe
    modifier: ent => options.modifier(ent),
    recordInput: ent => {
      return onlyKeys(options.recordInput, ent);
    }
  };
  const modifier = Object.keys(possibleModifiers).reduce((finalResult, key) => {
    if (options[key]) {
      return ent => possibleModifiers[key](finalResult(ent));
    }
    return finalResult;
  }, premodifier);

  if (options.propertyModel) {
    propertyName = options.propertyModel;
  }
  // $FlowFixMe
  return {
    modifier,
    relationshipSchemas,
    propertyName
  };
};

export const relationshipTypes = {
  ONE: 1,
  MANY: 2
};
