import { createSelector } from "reselect";

// create context
import { createContext } from "../../create_context";

// request methods
import { requestMethods } from "../../request_methods";

// utils
import { wrapSelector } from "../../utils";

/**
 * Get the meta for a specific query object
 * @param {Object|array|Function} queryObject - object, array of objects, or selector for a query object or array of query objects
 * @returns {Function} selector for context load state(s)
 */
export function createMetaSelectorCreator(
  resourceManager,
  globalConfig,
  metaRootSelector,
  createPageCountSelector,
  createCurrentPageSelector
) {
  return function createMetaSelector(queryObject) {
    const queryObjectSelector = wrapSelector(queryObject);
    const pageCountSelector = createPageCountSelector(queryObject);
    const pageSelector = createCurrentPageSelector(queryObjectSelector);

    return createSelector(
      queryObjectSelector,
      pageCountSelector,
      pageSelector,
      metaRootSelector,
      (queryObjectValue, pageCount, currentPage, meta) => {
        const pages = globalConfig.paginationStrategy(
          queryObjectValue.pageConfig,
          pageCount,
          currentPage
        );
        return pages.map(p => {
          const context = createContext(
            requestMethods.get,
            queryObjectValue,
            p
          );
          return meta[context];
        });
      }
    );
  };
}
