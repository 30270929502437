import { createSelector } from "reselect";
import { locationsToDisplaySelector } from "../locations_to_display_selector";

/**
 * Selector that structures locations
 */
export const locationsHierarchySelector = createSelector(
  locationsToDisplaySelector,
  locations => {
    let locHierarchy = {};

    // Get roots (facilities - provinces aren't displayed as selections)
    locHierarchy.roots = locations
      .filter(loc => {
        return loc.depth == 1;
      })
      .sort(locationComparator);

    // Recursively find all children
    locHierarchy.children = {};

    const addChildren = parentLocation => {
      if (parentLocation.leafNode) {
        return;
      }

      let children = locations
        .filter(loc => {
          return loc.parent && loc.parent.id == parentLocation.id;
        })
        .sort(locationComparator);

      if (children.length) {
        locHierarchy.children[parentLocation.id] = children;
      }

      children.forEach(addChildren);
    };

    locHierarchy.roots.forEach(addChildren);

    // all locs - excluding provinces - in sorted order (fac -> unit -> floor)
    locHierarchy.all = locHierarchy.roots.reduce((agg, root) => {
      agg.push(root);
      if (locHierarchy.children[root.id]) {
        agg = agg.concat(locHierarchy.children[root.id]);
      }

      return agg;
    }, []);

    return locHierarchy;
  }
);

function locationComparator(loc1, loc2) {
  return loc1.name < loc2.name ? -1 : 1;
}
