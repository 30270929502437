/**
 * Create action types
 * @param {string} fieldName the name of the field in the redux store
 */
export function createActionTypes(fieldName) {
  const allCapName = fieldName.toUpperCase();
  return {
    setValue: `${allCapName}_SET_VALUE`,
    toggleValue: `${allCapName}_TOGGLE_VALUE`
  };
}
