// @flow
import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { Sparklines, SparklinesLine } from "react-sparklines";

import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";
import { View, Text, Row, Column, Table, Header } from "@sw/ui-kit";

export default class AdminComponent extends React.Component {
  static propTypes = {
    locations: PropTypes.arrayOf(PropTypes.object),
    locationAggregateRisk: PropTypes.object,
    loaded: PropTypes.any,
    locationSparkAggregateRisk: PropTypes.object,
    startDate: PropTypes.any
  };
  getTotal = (aggregateRisk: Object, bucketName: string) => {
    const { buckets } = this.props;
    return Object.keys(buckets).reduce((finalResult, bucket) => {
      finalResult[bucket] = aggregateRisk[`${bucket}Total`];
      return finalResult;
    }, {})[bucketName];
  };
  getFormattedLocations = () => {
    const {
      buckets,
      locations,
      locationAggregateRisk,
      locationSparkAggregateRisk
    } = this.props;
    return locations.map((location, i) => {
      let aggregateRisk = locationAggregateRisk[location.id];
      let riskForBuckets = [];
      let totalMetric = 0;
      for (let bucketName in buckets) {
        const bucketAggregate = this.getTotal(aggregateRisk, bucketName);
        totalMetric += bucketAggregate;
        riskForBuckets.push(
          <td className="text-center" key={`${bucketName}-${i}`}>
            {bucketAggregate}
          </td>
        );
      }
      let sparkline =
        typeof locationSparkAggregateRisk[location.id] !== "undefined"
          ? locationSparkAggregateRisk[location.id].map(item => {
              return (
                item.average_braden_score ||
                item.averageBradenScore ||
                item.averageNortonPlusScore
              );
            })
          : [];

      let positionStyle = {
        left: location.parent !== null ? location.depth * 17 + "px" : "0px"
      };

      return (
        <tr key={i}>
          <td
            style={positionStyle}
            className={
              location.parent !== null ? "location-indented" : "location"
            }
          >
            {location.name}
          </td>
          <td>
            <Sparklines data={sparkline} width={120} height={45} margin={0}>
              <SparklinesLine color="blue" />
            </Sparklines>
          </td>
          {riskForBuckets}
          <td className="alert alert-info text-center in-house">
            {totalMetric}
          </td>
          {/*
          <td><button className='btn dashboard-btn center-block'>View</button></td>
            */}
        </tr>
      );
    });
  };
  render = () => {
    if (!this.props.loaded) {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_RISK_LOCATIONS">
          <View className="loading-state">
            <Text centre className="text-center">
              <FontAwesome name="spinner" size="2x" spin />
              <br />
              <br />
              Loading ...{" "}
            </Text>
          </View>
        </StartRenderTimeTrackComponent>
      );
    }
    const { buckets, riskName } = this.props;

    const riskLevelHeadings = Object.values(buckets).map(bucket => {
      return (
        <th className="text-center" key={bucket.label}>
          {bucket.label}
        </th>
      );
    });
    const formattedLocations = this.getFormattedLocations();
    return (
      <FinishRenderTimeTrackComponent eventName="RENDER_RISK_LOCATIONS">
        <View>
          <Row className="headline">
            <Column>
              <Text className="section-title">
                <Header size={"sm"}>{`${riskName ||
                  "Braden"} Risk - ${this.props.startDate.format(
                  "MMMM Do YYYY"
                )}`}</Header>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Table striped>
                <thead>
                  <tr>
                    <th className="location">Location</th>
                    <th>Four Week Trend</th>
                    {riskLevelHeadings}
                    <th className="text-center alert alert-info in-house">
                      Total
                    </th>
                    {/*
                  <th className='text-center'>Clinical view</th>
                    */}
                  </tr>
                </thead>
                <tbody>{formattedLocations}</tbody>
              </Table>
            </Column>
          </Row>
        </View>
      </FinishRenderTimeTrackComponent>
    );
  };
}
