// @flow
import { generateSchema } from "src/utils/schemaUtils";
import { recordInput } from "src/models/StudyModel";
import assessmentAnswerSchemas from "src/models/AssessmentAnswerModel/relationshipSchemaUtils";
export default generateSchema(
  [
    {
      name: "series"
    },
    ...assessmentAnswerSchemas,
    {
      alias: "typeRestrictedAssessmentAnswers",
      name: "assessmentAnswers"
    }
  ],
  { recordInput }
);
