import { createSelector } from "reselect";
import { createPolicyForInternalNameSelector } from "src/selectors/data_selectors";
import { policyInternalNames } from "src/constants";

export const isESignatureEnabledSelector = createSelector(
  createPolicyForInternalNameSelector(policyInternalNames.eSignatureMode),
  isESignatureEnabled => {
    return (
      isESignatureEnabled &&
      isESignatureEnabled.attributes.policyValue === "partner"
    );
  }
);
