import { connect } from "react-redux";

// Component
import { PopulationIncidencePrevalenceChartComponent } from "src/components/population_incidence_prevalence_chart_component";

// Selectors
import { populationIncidencePrevalenceChartDefinitionSelector } from "src/selectors/chart_definition_selectors";

function mapStateToProps(state) {
  return {
    chartDefinition: populationIncidencePrevalenceChartDefinitionSelector(state)
  };
}

export const PopulationIncidencePrevalenceChartContainer = connect(
  mapStateToProps
)(PopulationIncidencePrevalenceChartComponent);
