import {
	createSelector
} from "reselect";
import {
	policiesResourceSelectors
} from "src/selectors/data_selectors/policies";
import {
	policyValues
} from "src/models/policy";

/**
 * Determines if V3 assessments should be enabled, according to the organization
 * wound schema policy
 */
export const assessmentVersionSelector = createSelector(
	policiesResourceSelectors.dataSelector,
	policies => {
		if (!Object.keys(policies).length) {
			return "v2";
		}

		// get all policies for this org
		const orgPolicies = Object.values(policies);

		// get wound schema policy
		const woundSchemaPolicy = orgPolicies.find(policy => {
			return policy.attributes.internalName == "wound_schema";
		});

		if (woundSchemaPolicy) {
			const policyValue = woundSchemaPolicy.attributes.policyValue;
			if (policyValues.woundSchema.v3.includes(policyValue)) {
				return "v3";
			} else if (policyValues.woundSchema.v4.includes(policyValue)) {
				return "v4";
			} else if (policyValues.woundSchema.v4Revera.includes(policyValue)) {
				return "v4Revera";
			} else if (policyValues.woundSchema.v5.includes(policyValue)) {
				return "v5";
			} else if (policyValues.woundSchema.v6.includes(policyValue)) {
				return "v6";
			} else if (policyValues.woundSchema.v7.includes(policyValue)) {
				return "v7";
			} else if (policyValues.woundSchema.v8.includes(policyValue)) {
				return "v8";
			} else if (policyValues.woundSchema.ML1.includes(policyValue)) {
				return "ML1";
			} else if (policyValues.woundSchema.v5Bwat.includes(policyValue)) {
				return "v5Bwat";
			} else if (policyValues.woundSchema.v5Ashn.includes(policyValue)) {
				return "v5Ashn";
			} else if (policyValues.woundSchema.v5LHC.includes(policyValue)) {
					return "v5LHC";
			} else if (policyValues.woundSchema.v6LifeCare.includes(policyValue)) {
				return "v6LifeCare";
			} else if (policyValues.woundSchema.v6MayoClinic.includes(policyValue)) {
				return "v6MayoClinic";
			}
		}

		return "v2";
	}
);
