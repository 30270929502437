import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import i18n from "src/translations";

// Components
import { GridRow, GridColumn } from "src/components/grid";
import { PanelComponent, PanelBodyComponent } from "src/components/panel";

// Model
import {
  displayValueForArea,
  displayValueForLength,
  displayValueForPercentage,
  displayValueForUnitless,
  colorForMeasurementChange
} from "src/models/revisions";

// Styles
import styles from "./style.module.less";

export class RevisionMeasurementComponent extends React.PureComponent {
  static propTypes = {
    revision: PropTypes.object.isRequired,
    isAutodepth: PropTypes.bool.isRequired
  };

  renderMeasurement(title, displayValue, percentChange) {
    const percentageStyle = {
      backgroundColor: colorForMeasurementChange(percentChange)
    };

    return (
      <div>
        <GridRow>
          <GridColumn sm="6">
            <b>{title}</b>
          </GridColumn>
          <GridColumn sm="3">
            <span>{displayValue}</span>
          </GridColumn>
          <GridColumn sm="3">
            <span className={styles["percentage-text"]} style={percentageStyle}>
              {displayValueForPercentage(percentChange)}
            </span>
          </GridColumn>
        </GridRow>
        <hr />
      </div>
    );
  }

  render() {
    let { revision, isAutodepth, ray1Enabled } = this.props;

    const underminingsList = get(revision, "attributes.underminings", []);
    const underminings = underminingsList
      ? underminingsList.map((undermining, index) => {
          return <div key={index}>{undermining}</div>;
        })
      : [];

    const tunnellingsList = get(revision, "attributes.tunnellings", []);
    const tunnellings = tunnellingsList
      ? tunnellingsList.map((tunnelling, index) => {
          return <div key={index}>{tunnelling}</div>;
        })
      : [];

    const originalFrame = get(revision, "attributes.frames", []).find(
      val => val.frameType === "original"
    );

    const area = get({ revision }, "revision.attributes.area", null),
      deltaArea = get({ revision }, "revision.attributes.deltaArea", null),
      height = get({ revision }, "revision.attributes.height", null),
      deltaHeight = get({ revision }, "revision.attributes.deltaHeight", null),
      width = get({ revision }, "revision.attributes.width", null),
      deltaWidth = get({ revision }, "revision.attributes.deltaWidth", null),
      maxDepth = get({ revision }, "revision.attributes.maxDepth", null),
      deltaDepth = get({ revision }, "revision.attributes.deltaDepth", null),
      woundBedDelta = get(
        originalFrame,
        "imageMetadata.measurements.woundBedDelta",
        null
      ),
      maxUndermining = get(
        { revision },
        "revision.attributes.maxUndermining",
        null
      ),
      deltaUndermining = get(
        { revision },
        "revision.attributes.deltaUndermining",
        null
      ),
      maxTunnelling = get(
        { revision },
        "revision.attributes.maxTunnelling",
        null
      ),
      deltaTunnelling = get(
        { revision },
        "revision.attributes.deltaTunnelling",
        null
      );

    return (
      <div>
        <PanelComponent labelShow="Measurements">
          <PanelBodyComponent>
            {this.renderMeasurement(
              "Area",
              displayValueForArea(area),
              deltaArea
            )}
            {this.renderMeasurement(
              "Length",
              displayValueForLength(height),
              deltaHeight
            )}
            {this.renderMeasurement(
              "Width",
              displayValueForLength(width),
              deltaWidth
            )}
            {this.renderMeasurement(
              isAutodepth ? "Max AutoDepth" : "Deepest Point",
              displayValueForLength(maxDepth),
              deltaDepth
            )}
            {ray1Enabled
              ? this.renderMeasurement(
                  i18n.t("headers.woundBedDelta"),
                  displayValueForUnitless(woundBedDelta),
                  null
                )
              : null}
          </PanelBodyComponent>
        </PanelComponent>
        <PanelComponent labelShow="Undermining" enableCollapsible>
          <PanelBodyComponent>
            {this.renderMeasurement(
              "Max Undermining",
              displayValueForLength(maxUndermining),
              deltaUndermining
            )}
            {underminings}
          </PanelBodyComponent>
        </PanelComponent>
        <PanelComponent labelShow="Tunnelling" enableCollapsible>
          <PanelBodyComponent>
            {this.renderMeasurement(
              "Longest Tunnel",
              displayValueForLength(maxTunnelling),
              deltaTunnelling
            )}
            {tunnellings}
          </PanelBodyComponent>
        </PanelComponent>
      </div>
    );
  }
}
