import { getHeader } from "../get_header";
import { getFooter } from "../get_footer";
import { getBody } from "./get_body";
import { getPdfOptions } from "../get_pdf_options";

/**
 * Generate a JSON object formatted to create a pdf for an non wound assessment with pdfMake
 * @param {Object} data
 * @param {Object} data.user - the user
 * @param {Object} data.patient - the patient
 * @param {Object} data.assessmentAnswer - the most recent aa for the revision
 * @param {Object[]} data.assessmentSchema - array of assessment schemas
 * @param {Object} images
 * @param {string} images.logo - data url of swift logo
 * @param {string} images.qrCode - data url of patient qr code
 * @param {string} images.bradenChart - data url of braden chart
 *
 * @return {Object} json object representing the pdf
 */
export function createNonWoundAssessmentPdf(data, images, options) {
  const evaluatedOn = data.assessmentAnswerGroup.attributes.createdAt;
  return {
    header: getHeader(
      data.patient,
      data.patientLastFacility,
      evaluatedOn,
      images.logo,
      images.qrCode,
      options
    ),
    footer: getFooter(),
    content: getBody(data, images),
    ...getPdfOptions()
  };
}
