// React
import React, { Component } from "react";
import classnames from "classnames";
// Css
import styles from "../style.module.less";

export class PopulationTallyTableRowByLocationComponent extends Component {
  renderFacilityContextData() {
    const { tally } = this.props;
    if (tally.tallyType == "total") {
      return (
        <td className={`${styles["left-align"]} ${styles["right-border"]}`}>
          All Locations
        </td>
      );
    }
    return <td className={styles["right-border"]}>{tally.facilityName}</td>;
  }
  renderUnitContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.unitName}</td>;
  }
  renderFloorContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.floorName}</td>;
  }
  renderAverageCensusinSelectedTimePeriodContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.patientCensus}</td>;
  }
  renderNewWoundPatientsContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.newWoundPatients}</td>;
  }

  renderWoundPatientsContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.woundPatients}</td>;
  }

  renderIncidenceContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.incidence}</td>;
  }

  renderPrevalenceContextData() {
    const { tally } = this.props;
    return <td className={styles["right-border"]}>{tally.prevalence}</td>;
  }

  // ------------------- Render ------------------- //

  render() {
    const { tally } = this.props;
    let rowClassName =
      styles[
        classnames({
          "total-row": tally.tallyType == "total",
          "location-type-row": tally.tallyType == "location"
        })
      ];

    return (
      <tr className={rowClassName}>
        {this.renderFacilityContextData()}
        {this.renderUnitContextData()}
        {this.renderFloorContextData()}
        {this.renderAverageCensusinSelectedTimePeriodContextData()}
        {this.renderNewWoundPatientsContextData()}
        {this.renderWoundPatientsContextData()}
        {this.renderIncidenceContextData()}
        {this.renderPrevalenceContextData()}
      </tr>
    );
  }
}
