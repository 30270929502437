import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Container, Column, Row } from "@sw/ui-kit";
import BradenHistoryChart from "src/containers/trends/BradenHistoryChart";
import NortonHistoryChart from "src/containers/trends/NortonHistoryChart";
import TrendModel from "@sw/models/TrendModel";
import patientTrendSelectors from "@sw/selectors/patientTrendSelectors";

import { riskAssessmentTypesSelector } from "src/selectors/section_selectors/risk";

// HOC
import { withPatientTrendsLoader } from "src/hoc";

type $stateProps = {
  patientTrends: TrendModel
};

type $ownProps = {
  id: $$id
};

type $props = $ownProps & $stateProps;

const assessmentTypes = {
  braden: {
    title: "Braden History",
    Chart: BradenHistoryChart,
    trendName: "bradenTrend"
  },
  nortonPlus: {
    title: "Norton Plus History",
    Chart: NortonHistoryChart,
    trendName: "nortonPlusTrend"
  }
};

export class PatientAnalytics extends PureComponent {
  props: $props;
  render() {
    const { riskAssessmentTypes } = this.props;
    return (
      <Container className="analytics">
        {riskAssessmentTypes.map(type => {
          if (!assessmentTypes[type]) return;
          const { title, trendName, Chart } = assessmentTypes[type];
          return (
            <Row key={type} fluid>
              <Column centre>
                <div className="bdsm-chart-title">{title}</div>
                <div>
                  <Chart data={this.props.patientTrend[trendName]} />
                </div>
              </Column>
            </Row>
          );
        })}
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const idSelector = () => ownProps.match.params.patientId;
  const structuredSelectors = createStructuredSelector({
    patientTrend: patientTrendSelectors.findEntity(idSelector),
    riskAssessmentTypes: riskAssessmentTypesSelector
  });
  return {
    ...structuredSelectors(state)
  };
}

export default withPatientTrendsLoader(
  connect(mapStateToProps)(PatientAnalytics)
);
