import { createSelector } from "reselect";

//Selectors
import { createLatestLockedEventForAssessmentGroupIdQuerySelector } from "src/selectors/query_selectors/events_for_assessment_answer_group_id";

/**
 * create a selector to get the context for the latest locked event with a assessmentAnswerGroupId
 * @param {Object} props
 * @param {string} props.assessmentAnswerGroupId
 * @returns {function} selector
 */

export function createLatestLockedEventForAssessmentAnswerGroupIdContextSelector({
  assessmentAnswerGroupId
}) {
  return createSelector(
    createLatestLockedEventForAssessmentGroupIdQuerySelector({
      assessmentAnswerGroupId
    }),
    query => {
      return query;
    }
  );
}
