import { createSelector } from "reselect";
import { locationsHierarchySelector } from "src/selectors/data_selectors/locations";
import { selectedLocationIdsSelector } from "../selected_location_ids_selector";

/**
 * Locations selector for the wounds tab - gets selected locations
 * (or all if none selected)
 */
export const selectedLocationsSelector = createSelector(
  locationsHierarchySelector,
  selectedLocationIdsSelector,
  (locationsHeirarchy, selectedLocationIds) => {
    if (selectedLocationIds.length) {
      return locationsHeirarchy.all.filter(location =>
        selectedLocationIds.includes(location.id)
      );
    } else {
      return locationsHeirarchy.all;
    }
  }
);
