import { createSelector } from "reselect";
import uniq from "lodash.uniq";

// Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { createAllPagesRevisionContextsForFiltersSelector } from "../../revisions/create_all_pages_revision_contexts_for_filters_selector";

export function createAllSeriesTrendsQueriesForRevisionsFilterSelector({
  filters
}) {
  return createSelector(
    revisionsResourceSelectors.createDataForContextSelector(
      createAllPagesRevisionContextsForFiltersSelector({ filters })
    ),
    revisions => {
      const seriesIds = uniq(revisions.map(r => r.attributes.seriesId));
      return seriesIds.map(id => `${id}/trends`);
    }
  );
}
