import { createSelector } from "reselect";

// Selector Creators
import { createDataRootSelector } from "./create_data_root_selector";
import { createLoadStateRootSelector } from "./create_load_state_root_selector";
import { createMetaRootSelector } from "./create_meta_root_selector";
import { createCurrentPageSelectorCreator } from "./create_current_page_selector_creator";
import { createPagesRootSelector } from "./create_pages_root_selector";

import { createDataSelectorCreator } from "./create_data_selector_creator";
import { createLoadStateSelectorCreator } from "./create_load_state_selector_creator";
import { createMetaSelectorCreator } from "./create_meta_selector_creator";

import { createPageCountSelectorCreator } from "./create_page_count_selector_creator";

export function createSelectors(resourceManager, globalConfig) {
  // Root and resource
  const rootSelector = state => state[globalConfig.reduxStoreRootName];
  const resourceSelector = createSelector(
    rootSelector,
    root => root[resourceManager.resourceName]
  );

  // SECTION ROOT SELECTORS
  const dataRootSelector = createDataRootSelector(resourceSelector);
  const loadStateRootSelector = createLoadStateRootSelector(resourceSelector);
  const metaRootSelector = createMetaRootSelector(resourceSelector);
  // const pageRootSelector = createPagesRootSelector(resourceSelector);

  // SPECIFIC SELECTOR
  // Page Count
  const createPageCountSelector = createPageCountSelectorCreator(
    resourceManager,
    globalConfig,
    metaRootSelector
  );

  // page selectors
  const pagesRootSelector = createPagesRootSelector(resourceSelector);
  const createCurrentPageSelector = createCurrentPageSelectorCreator(
    pagesRootSelector
  );

  // Meta
  const createMetaSelector = createMetaSelectorCreator(
    resourceManager,
    globalConfig,
    metaRootSelector,
    createPageCountSelector,
    createCurrentPageSelector
  );

  // Data
  const createDataSelector = createDataSelectorCreator(
    dataRootSelector,
    createMetaSelector
  );

  // Load State
  const createLoadStateSelector = createLoadStateSelectorCreator(
    resourceManager,
    globalConfig,
    loadStateRootSelector,
    createPageCountSelector,
    createCurrentPageSelector
  );

  return {
    dataRoot: dataRootSelector,
    loadStateRoot: loadStateRootSelector,
    metaRoot: metaRootSelector,
    pagesRoot: pagesRootSelector,

    createDataSelector,
    createLoadStateSelector,
    createMetaSelector,
    createPageCountSelector,
    createCurrentPageSelector
  };
}
