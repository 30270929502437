import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// Components
import { GridRow, GridColumn } from "src/components/grid";

// Legacy Containers/Components
import PatientBody from "src/containers/patients/ShowContainer/Body";
import Header from "src/containers/patients/ShowContainer/Header";

// Legacy styles
import "app/patients/@patientID/style.less";

/**
 * Component to display the patient medical chart
 */
export class PatientDetailsComponent extends React.Component {
  static propTypes = {
    patient: PropTypes.object,
    study: PropTypes.object,
    patientHeaderEnabled: PropTypes.bool.isRequired,
    patientNameNavbarEnabled: PropTypes.bool.isRequired,
    patientOverviewEnabled: PropTypes.bool.isRequired,
    patientAlertsEnabled: PropTypes.bool.isRequired
  };

  /**
   * Render the top grey bar in the component
   * TODO: the navbar should be a single component that is router dependent
   */
  renderControlNavbar() {
    const { patient, patientNameNavbarEnabled } = this.props;

    if (patientNameNavbarEnabled) {
      return (
        <GridRow className="panel-headline">
          <GridColumn xs="12">
            <h1>
              {patient.attributes.firstName} {patient.attributes.lastName}
            </h1>
          </GridColumn>
        </GridRow>
      );
    }
  }

  render() {
    const {
      study,
      patientHeaderEnabled,
      patientOverviewEnabled,
      patientAlertsEnabled
    } = this.props;

    // If theres no header then this is used as a standalone component and needs
    // a bootstrap container class. ie. in the integration.
    const klass = classnames({
      container: !patientHeaderEnabled
    });

    return (
      <div className={klass}>
        <div className="patients-show">
          {this.renderControlNavbar()}
          {patientHeaderEnabled ? (
            <Header />
          ) : (
            <div style={{ display: "block", width: "100%", height: "50px" }} />
          )}
          {study && (
            <PatientBody
              hideSwiftCode={patientHeaderEnabled}
              showPatientOverview={patientOverviewEnabled}
              showPatientAlerts={patientAlertsEnabled}
            />
          )}
        </div>
      </div>
    );
  }
}
