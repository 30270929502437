import { createSelector } from "reselect";

// Api
import { createGetPayload } from "src/api/assessment_summaries/payload_generators";
import {
  selectedLocationIdsSelector,
  answersFilterSelector,
  woundRiskSectionSelector
} from "src/selectors/section_selectors/wound_risk/wound_risk_wound_selector";
import { createArrayOfRevisionIdsSelector } from "src/selectors/data_selectors/wound_revisions";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const woundRiskPageLimitSelector = SingleValueReduxUtil.createSelectors(
  "woundRiskPageLimit",
  woundRiskSectionSelector
);

export function createFirstPagePayloadSelector() {
  return createSelector(
    createArrayOfRevisionIdsSelector,
    woundRiskPageLimitSelector.fieldSelector,
    selectedLocationIdsSelector,
    answersFilterSelector,
    (woundRevisionIds, pageLimit, selectedLocationIds, answers) => {
      if (!selectedLocationIds.length) return {};
      return createGetPayload({
        woundRevisionIds,
        pageLimit,
        selectedLocationIds,
        answers,
        patient_state: "admitted,new",
        pageNumber: 0,
        sortEnabled: "created_at"
      });
    }
  );
}
