import React from "react";
import PropTypes from "prop-types";

//Translations
import i18n from "src/translations";

//UI Kit
import { Glyphicon } from "src/components/ui_kit";
import { Modal, ModalBody } from "react-bootstrap";
import { Button } from "src/ui-kit";

//Component
import { TableComponent } from "../table_component";
import { TableNavigatorComponent } from "../table_navigator_component";
import { TablePageLimitDropdownComponent } from "../table_page_limit_dropdown_component";

//Container
import { RiskAlertRuleFormContainer } from "src/containers/forms/risk_alert_rule_form_container";

//Formatters
import { cellFormatters } from "../cell_formatters";

export class AdminRiskAlertRulesTableComponent extends React.PureComponent {
  static propTypes = {
    context: PropTypes.string.isRequired,
    rules: PropTypes.array.isRequired,
    pageLimit: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    sendSetPageLimitAction: PropTypes.func.isRequired,
    sendSetPageAction: PropTypes.func.isRequired
  };

  state = {
    showEditAlertRuleModal: false,
    alertRuleInModal: {}
  };

  setPage = pageNumber => {
    const { sendSetPageAction } = this.props;
    sendSetPageAction(pageNumber);
  };

  onHideEditAlertRuleModal = () => {
    this.setState({
      ...this.state,
      showEditAlertRuleModal: false
    });
  };

  onClickEditAlertRule = (e, row) => {
    this.setState({
      ...this.state,
      alertRuleInModal: row,
      showEditAlertRuleModal: true
    });
  };

  //Button Cell Formatter
  editButtonFormatter = (cell, row) => {
    return (
      <Button
        type="button"
        onClick={e => this.onClickEditAlertRule(e, row)}
        className="btn-default"
        subtype="button"
      >
        <Glyphicon glyph="pencil" className="margin-right5px" />
        Edit
      </Button>
    );
  };

  render() {
    const {
      rules,
      pageLimit,
      pageCount,
      currentPage,
      sendSetPageLimitAction
    } = this.props;

    const columns = [
      {
        dataField: "description",
        text: i18n.t(`headers.description`),
        formatter: cellFormatters.alertRulesDescriptionFormatter
      },
      {
        dataField: "range",
        text: i18n.t(`headers.range`),
        formatter: cellFormatters.alertRulesRangeFormatter
      },
      {
        dataField: "appliesTo",
        text: i18n.t(`headers.appliesTo`),
        formatter: cellFormatters.alertRulesAppliesToFormatter
      },
      {
        dataField: "id",
        text: "",
        formatter: this.editButtonFormatter
      }
    ];

    return (
      <div>
        <TableComponent data={rules} keyField="id" columns={columns} />
        <div className="pull-left">
          <TablePageLimitDropdownComponent
            selectPageLimit={sendSetPageLimitAction}
            selectedPageLimit={pageLimit}
          />
        </div>
        <TableNavigatorComponent
          pageNumber={currentPage}
          pageCount={pageCount}
          selectTablePage={this.setPage}
        />
        <Modal
          show={this.state.showEditAlertRuleModal}
          onHide={this.onHideEditAlertRuleModal}
          bsSize={"large"}
        >
          <ModalBody>
            <RiskAlertRuleFormContainer
              rule={this.state.alertRuleInModal}
              cancel={this.onHideEditAlertRuleModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
