import { connect } from "react-redux";
import { UserStateFormComponent } from "src/components/forms/user_state_form_component";
import { updateUserAction } from "src/actions/data_actions";

function mapDispatchToProps(dispatch) {
  return {
    sendUpdateUserAction(payload) {
      return dispatch(updateUserAction(payload));
    }
  };
}

export const UserStateFormContainer = connect(
  null,
  mapDispatchToProps
)(UserStateFormComponent);
