import { adminActionTypes } from "src/action_types/section_action_types";

/**
 * Action creator for setting the monitor at home status filter
 * @param {string} filterOption the filter option
 * @return {Object} Action
 */
export function setMonitorAtHomeStatusFilterAction(filterOption) {
  return {
    type: adminActionTypes.setMonitorAtHomeStatus,
    payload: filterOption
  };
}

export function deselectMonitorAtHomeStatusFilterAction(filterOption) {
  return {
    type: adminActionTypes.deselectMonitorAtHomeStatus,
    payload: filterOption
  };
}
