// @flow
import React, { PureComponent } from "react";
import Column from "../../Grid/Column";
import Row from "../../Grid/Row";

export default class MenuPanel extends PureComponent {
  render() {
    return (
      <Row className="panel-headline">
        <Column>
          <ul className="list-inline dashboard-toggle pull-right">
            {this.props.children}
          </ul>
          <h1>{this.props.title}</h1>
        </Column>
      </Row>
    );
  }
}
