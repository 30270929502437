// @flow
import { generateSchema, relationshipTypes } from "src/utils/schemaUtils";
import { recordInput } from "src/models/AssessmentAnswerModel";
export default generateSchema(
  [
    {
      name: "users",
      alias: "user",
      type: relationshipTypes.ONE
    }
  ],
  {
    recordInput,
    modifier: ent => {
      if (ent.answersJson && typeof ent.answersJson === "object") {
        return {
          ...ent,
          answersJson: JSON.stringify(ent.answersJson)
        };
      }
      return ent;
    }
  }
);
