import React from "react";
import { ChangePasswordFormContainer } from "src/containers/change_password_form_container";
import styles from "./style.module.less";

/**
 * Following component is used for change password form load
 * data.
  
 */
export class ChangePasswordComponent extends React.PureComponent {
  render() {
    return (
      <div className={styles["margin-top10px"]}>
        <h3 className={styles["margin-bottom30px"]}>Change Password</h3>
        <ChangePasswordFormContainer />
      </div>
    );
  }
}
