export const createDictionaryFromMultipleDictionaries = (
  primaryAssessmentId,
  localizationDictionary
) => {
  const primaryLocalizationDictionary =
    localizationDictionary[primaryAssessmentId];

  let dictionaryClone = JSON.parse(JSON.stringify(localizationDictionary));
  delete dictionaryClone[primaryAssessmentId];

  const secondaryLocalizationDictionaries = Object.values(dictionaryClone);

  const mergedDictionaries = mergeSecondaryDictionariesToPrimaryDictionary(
    primaryLocalizationDictionary,
    secondaryLocalizationDictionaries
  );

  return mergedDictionaries;
};

const mergeSecondaryDictionariesToPrimaryDictionary = (
  primary,
  secondaries
) => {
  const mergedSecondaryDictionaries = secondaries.reduce((acc, elem) => {
    return {
      ...acc,
      ...elem
    };
  }, {});

  //Merge primary second so it overrides any duplicate keys
  const mergedDictionaries = {
    ...mergedSecondaryDictionaries,
    ...primary
  };

  return mergedDictionaries;
};
