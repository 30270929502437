// @flow
import { decamelize } from "humps";
import { createAction } from "redux-actions";

const generateActionName = name => decamelize(name).toUpperCase();

export const entityActionNames: $$mapOf<(name: string) => string> = {
  create: name => `CREATE_${generateActionName(name)}`,
  update: name => `UPDATE_${generateActionName(name)}`,
  remove: name => `REMOVE_${generateActionName(name)}`,
  deleteAtPath: name => `DELETE_AT_PATH_${generateActionName(name)}`,
  get: name => `GET_${generateActionName(name)}`,
  index: name => `INDEX_${generateActionName(name)}`,
  getRelated: name => `GET_RELATED_TO_${generateActionName(name)}`,
  getAdditionalEntityProperties: name =>
    `GET_ADDITIONAL_ENTITY_PROPERTIES_FOR_${generateActionName(name)}`
};

export const propertyActionNames: $$mapOf<(name: string) => string> = {
  create: name => `CREATE_PROPERTY_${generateActionName(name)}`
};

export const relationshipActionNames: $$mapOf<(name: string) => string> = {
  create: name => `CREATE_RELATIONSHIP_${generateActionName(name)}`,
  add: name => `ADD_RELATIONSHIP_${generateActionName(name)}`
};

export const createEntity = function(entityName: string, entity: Object) {
  if (entity instanceof Error) {
    return createAction(entityActionNames.create(entityName))(entity);
  }
  return createAction(entityActionNames.create(entityName))({ entity });
};

export const updateEntity = function(entityName: string, entity: Object) {
  if (entity instanceof Error) {
    return createAction(entityActionNames.update(entityName))(entity);
  }
  return createAction(entityActionNames.update(entityName))({ entity });
};

export const removeEntity = function(
  entityName: string,
  entityId: string | number
) {
  if (entityId instanceof Error) {
    return createAction(entityActionNames.remove(entityName))(entityId);
  }
  return createAction(entityActionNames.remove(entityName))({ entityId });
};

/**
 * Used to delete a piece of an entity in the store
 */
export const deleteAtPath = function(entityName: string, path: Array<string>) {
  if (path instanceof Error) {
    return createAction(entityActionNames.deleteAtPath(entityName))(path);
  }
  return createAction(entityActionNames.deleteAtPath(entityName))({ path });
};

export const getEntity = function(entityName: string, entity: Object) {
  if (entity instanceof Error) {
    return createAction(entityActionNames.get(entityName))(entity);
  }
  return createAction(entityActionNames.get(entityName))({ entity });
};

export const indexEntity = function(
  entityName: string,
  entities: Error | Object[]
) {
  if (entities instanceof Error) {
    return createAction(entityActionNames.index(entityName))(entities);
  }
  return createAction(entityActionNames.index(entityName))({ entities });
};

export const createRelationship = function(
  entityName: string,
  relationshipName: string,
  entityId: $$id,
  value: $$id | $$id[]
) {
  // $FlowFixMe
  if (value instanceof Error) {
    return createAction(relationshipActionNames.create(entityName))(value);
  }
  return createAction(relationshipActionNames.create(entityName))({
    relationshipName,
    entityId,
    value
  });
};

export const addRelationship = function(
  entityName: string,
  relationshipName: string,
  entityId: $$id,
  value: $$id | $$id[]
) {
  // $FlowFixMe
  if (value instanceof Error) {
    return createAction(relationshipActionNames.add(entityName))(value);
  }
  return createAction(relationshipActionNames.add(entityName))({
    relationshipName,
    entityId,
    value
  });
};
