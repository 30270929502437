import { createSelector } from "reselect";

import { seriesResourceSelectors } from "src/selectors/data_selectors";

/**
 * Create a selector that gets a series for a revision id
 * @param {string} revisionId
 * @returns {Function} selector
 */
export function createSeriesForRevisionSelector(revision) {
  return createSelector(
    seriesResourceSelectors.dataSelector,
    series => {
      return series[revision.attributes.seriesId];
    }
  );
}
