// @flow
import React, { PureComponent } from "react";

type $props = {
  className?: string,
  children?: any
};

export default class View extends PureComponent {
  props: $props;
  render() {
    const { children, ...props } = this.props;
    return <div {...props}>{children}</div>;
  }
}
