import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";

import history from "./src/history";
import store from "./src/store";
import RootComponent from "./RootComponent";

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <RootComponent />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
