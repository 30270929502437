import React, { PureComponent } from "react";
import classnames from "classnames";
type $props = {
  left?: boolean,
  right?: boolean,
  centre?: boolean
};

export default class Align extends PureComponent {
  props: $props;
  render() {
    const { className, left, right, centre, children } = this.props;
    const klass = classnames(
      {
        ["pull-left"]: left,
        ["pull-right"]: right,
        ["text-center"]: centre
      },
      className
    );
    return <div className={klass}>{children}</div>;
  }
}
