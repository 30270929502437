// @flow
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { mapReducersToProps } from "incremental-redux-reducers";

import { Button } from "@sw/ui-kit";
import riskActions from "@sw/actions/propertyActions/risks";
import riskSelectors from "@sw/selectors/propertySelectors/risks";
import {
  selectedLocationIdsSelector,
  selectedLocationsSelector
} from "src/selectors/section_selectors/risk";
import { selectedOrganizationSelector } from "src/selectors/data_selectors/organizations";
import { brandingSelector } from "src/selectors/ui_config_selectors/global";

//Selectors
import { riskAssessmentTypesSelector } from "src/selectors/section_selectors/risk";

import loadLocationsAndRiskAggregates from "./loadLocationsAndRiskAggregates";
import patientHighRiskReducer from "./patientHighRiskReducer";
import patientVeryHighRiskReducer from "./patientVeryHighRiskReducer";
import patientMediumRiskReducer from "./patientMediumRiskReducer";
import patientLowRiskReducer from "./patientLowRiskReducer";
import patientNoRiskReducer from "./patientNoRiskReducer";
import patientNoneRiskReducer from "./patientNoneRiskReducer";
import loadPatientsForHighRisk from "./loadPatientsHighRisk";
import loadPatientsForLowRisk from "./loadPatientsLowRisk";
import loadPatientsForVeryHighRisk from "./loadPatientsVeryHighRisk";
import loadPatientsForMediumRisk from "./loadPatientsMediumRisk";
import loadPatientsForNoRisk from "./loadPatientsNoRisk";
import loadPatientsForNoneRisk from "./loadPatientsNoneRisk";

import locationAggregateRiskReducer from "./locationAggregateRiskReducer";
import loadLocationRiskSpark from "./loadLocationRiskSpark";
import aggregateSparkReducer from "./aggregateSparkReducer";
import loadClinicalChart from "./loadClinicalChart";
import loadRiskClinicalAggregates from "./loadRiskClinicalAggregates";
import clinicalChartReducer from "./clinicalChartReducer";
import clinicalAggregateReducer from "./clinicalAggregateReducer";
import DatePicker from "react-datepicker";
import Moment from "moment";
import AdminComponent from "./adminComponent";
import ClinicalComponent from "./clinicalComponent";
import ProgressChartComponent from "../Braden/progressChartComponent";

import isEqual from "lodash.isequal";

import { startTimeTrack, finishTimeTrack } from "src/analytics";
import { trackError } from "src/error_tracking";

// Styles
import "./style.less";
import styles from "./style.module.less";

type $dispatchProps = {
  loadPatientsForVeryHighRisk: Function,
  loadPatientsForHighRisk: Function,
  loadPatientsForMediumRisk: Function,
  loadPatientsForLowRisk: Function,
  loadPatientsForNoRisk: Function,
  loadPatientsForNoneRisk: Function,
  goToNortonPlus: Function,
  dispatch: $$dispatch
};

class RiskComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentOrganization: PropTypes.any,
    selectedLocationIds: PropTypes.array.isRequired,
    selectedLocations: PropTypes.array.isRequired,
    locationAggregateRisk: PropTypes.object,
    locationPatientHighRisk: PropTypes.object,
    locationPatientVeryHighRisk: PropTypes.object,
    locationPatientMediumRisk: PropTypes.object,
    locationPatientLowRisk: PropTypes.object,
    locationPatientNoRisk: PropTypes.object,
    locationPatientNoneRisk: PropTypes.object,
    locationSparkAggregateRisk: PropTypes.object,
    locationChartRisk: PropTypes.object,
    clinicalRiskAggregate: PropTypes.any,
    riskAssessmentTypes: PropTypes.array
  };

  state = {
    view: "clinical",
    clinicalLoaded: false,
    adminLoaded: false,
    startDate: Moment()
  };

  componentDidMount = () => {
    if (this.props.selectedLocationIds.length) {
      this.fetchData(this.props.selectedLocationIds, this.state.startDate);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (
      !isEqual(this.props.selectedLocationIds, nextProps.selectedLocationIds)
    ) {
      this.setState({ clinicalLoaded: false, adminLoaded: false });
      if (nextProps.selectedLocationIds.length) {
        this.fetchData(nextProps.selectedLocationIds, this.state.startDate);
      }
      this.handleView();
    }
  };

  fetchData = (locationIds, startDate) => {
    const {
      dispatch,
      loadPatientsForVeryHighRisk,
      loadPatientsForHighRisk,
      loadPatientsForMediumRisk,
      loadPatientsForLowRisk,
      loadPatientsForNoRisk,
      loadPatientsForNoneRisk
    } = this.props;

    let startTime = new Moment("1900-01-01").toJSON();
    let endTime = new Moment(startDate).endOf("day").toJSON();
    let adminChartTime = new Moment(startDate).subtract(4, "w").toJSON();
    let clinicalChartTime = new Moment(startDate)
      .subtract(16, "weeks")
      .toJSON();

    if (this.state.view === "admin") {
      this.setState({ adminLoaded: false });

      startTimeTrack("API_GET_RISK_LOCATION_DATA");
      Promise.all([
        dispatch(
          loadLocationsAndRiskAggregates(locationIds, startTime, endTime)
        ),
        dispatch(loadLocationRiskSpark(locationIds, adminChartTime, endTime))
      ])
        .then(() => {
          finishTimeTrack("API_GET_RISK_LOCATION_DATA");
          this.setState({ adminLoaded: true });
        })
        .catch(err => {
          finishTimeTrack("API_GET_RISK_LOCATION_DATA", {
            error: true,
            errorCode: err.response ? err.response.status : undefined
          });
          trackError(err);
        });
    } else {
      this.setState({ clinicalLoaded: false });
      startTimeTrack("API_GET_RISK_RESIDENTS_DATA");
      Promise.all([
        dispatch(loadRiskClinicalAggregates(locationIds, startTime, endTime)),
        dispatch(loadClinicalChart(locationIds, clinicalChartTime, endTime)),
        loadPatientsForVeryHighRisk(locationIds, startTime, endTime),
        loadPatientsForHighRisk(locationIds, startTime, endTime),
        loadPatientsForMediumRisk(locationIds, startTime, endTime),
        loadPatientsForLowRisk(locationIds, startTime, endTime),
        loadPatientsForNoRisk(locationIds, startTime, endTime),
        loadPatientsForNoneRisk(locationIds, startTime, endTime)
      ])
        .then(() => {
          finishTimeTrack("API_GET_RISK_RESIDENTS_DATA");
          this.setState({ clinicalLoaded: true });
        })
        .catch(err => {
          finishTimeTrack("API_GET_RISK_RESIDENTS_DATA", {
            error: true,
            errorCode: err.response ? err.response.status : undefined
          });
          trackError(err);
        });
    }
  };

  loadLocationsForOrganization = organization => {
    this.props
      .dispatch(riskActions.loadLocations(organization.id, "braden"))
      .then(() => {
        this.fetchData(this.props.selectedLocationIds, this.state.startDate);
      });
  };

  handleDatePicker = date => {
    this.setState({
      startDate: date
    });
    this.fetchData(this.props.selectedLocationIds, date);
  };

  handleStateView = event => {
    this.setState(
      {
        view: event.target.value
      },
      () => {
        this.fetchData(this.props.selectedLocationIds, this.state.startDate);
      }
    );
  };

  handleView = () => {
    const {
      locationPatientLowRisk,
      locationPatientMediumRisk,
      locationPatientHighRisk,
      locationPatientVeryHighRisk,
      locationPatientNoRisk,
      locationPatientNoneRisk,
      loadPatientsForVeryHighRisk,
      loadPatientsForHighRisk,
      loadPatientsForMediumRisk,
      loadPatientsForLowRisk,
      loadPatientsForNoRisk,
      loadPatientsForNoneRisk
    } = this.props;

    const tabs = {
      veryHigh: {
        label: "Very High",
        load: loadPatientsForVeryHighRisk
      },
      high: {
        label: "High",
        load: loadPatientsForHighRisk
      },
      medium: {
        label: "Medium",
        load: loadPatientsForMediumRisk
      },
      low: {
        label: "Low",
        load: loadPatientsForLowRisk
      },
      no: {
        label: "No Risk",
        load: loadPatientsForNoRisk
      },
      none: {
        label: "None",
        load: loadPatientsForNoneRisk
      }
    };
    if (this.state.view === "admin") {
      return (
        <AdminComponent
          buckets={tabs}
          loaded={this.state.adminLoaded}
          locations={this.props.selectedLocations}
          locationAggregateRisk={this.props.locationAggregateRisk}
          locationSparkAggregateRisk={this.props.locationSparkAggregateRisk}
          startDate={new Moment(this.state.startDate)}
        />
      );
    } else if (this.state.view === "clinical") {
      return (
        <ClinicalComponent
          dispatch={this.props.dispatch}
          locationIds={this.props.selectedLocationIds}
          chartData={this.props.locationChartRisk}
          aggregateData={this.props.clinicalRiskAggregate}
          loaded={this.state.clinicalLoaded}
          startDate={new Moment(this.state.startDate)}
          tabs={tabs}
          patients={{
            veryHigh: locationPatientVeryHighRisk,
            high: locationPatientHighRisk,
            medium: locationPatientMediumRisk,
            low: locationPatientLowRisk,
            no: locationPatientNoRisk,
            none: locationPatientNoneRisk
          }}
          branding={this.props.branding}
        />
      );
    } else {
      return (
        <ProgressChartComponent
          dispatch={this.props.dispatch}
          locationIds={this.props.selectedLocationIds}
          chartData={this.props.locationChartRisk}
          aggregateData={this.props.clinicalRiskAggregate}
          loaded={this.state.clinicalLoaded}
          startDate={new Moment(this.state.startDate)}
          tabs={tabs}
          patients={{
            veryHigh: locationPatientVeryHighRisk,
            high: locationPatientHighRisk,
            medium: locationPatientMediumRisk,
            low: locationPatientLowRisk,
            no: locationPatientNoRisk,
            none: locationPatientNoneRisk
          }}
          branding={this.props.branding}
        />
      );
    }
  };

  toggleClass = value => {
    if (this.state.view === value) {
      return `active btn btn-default ${
        styles["active-button-default-no-margins"]
      }`;
    } else {
      return `btn btn-default ${styles["button-default-no-margins"]}`;
    }
  };

  renderControlNavbar() {
    const { goToNortonPlus, riskAssessmentTypes } = this.props;
    const assessmentTypeButtons = {
      nortonPlus: {
        onClick: goToNortonPlus,
        children: "Norton Plus",
        disabled: false,
        className: `btn btn-default ${styles["button-default-no-margins"]}`
      },
      braden: {
        className: `active btn btn-default ${
          styles["active-button-default-no-margins"]
        }`,
        children: "Braden",
        disabled: true
      }
    };

    return (
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">Risk Status</h1>
        </div>

        <form className="navbar-form navbar-right">
          <div className="btn-group control-navbar-element" role="group">
            {riskAssessmentTypes.length > 1 &&
              riskAssessmentTypes.map(assessmentType => {
                const buttonConfig = assessmentTypeButtons[assessmentType];
                return (
                  <Button
                    key={assessmentType}
                    name="default"
                    {...buttonConfig}
                  />
                );
              })}
          </div>

          <div className="btn-group control-navbar-element" role="group">
            <button
              type="button"
              value="clinical"
              onClick={this.handleStateView}
              className={this.toggleClass("clinical")}
            >
              Residents
            </button>
            <button
              type="button"
              value="progress"
              onClick={this.handleStateView}
              className={this.toggleClass("progress")}
            >
              Chart
            </button>
            <button
              type="button"
              value="admin"
              onClick={this.handleStateView}
              className={this.toggleClass("admin")}
            >
              Locations
            </button>
          </div>

          <span className="control-navbar-element">
            <DatePicker
              dateFormat="MMMM D, YYYY"
              selected={this.state.startDate}
              onChange={this.handleDatePicker}
              className="form-control"
            />
          </span>
        </form>
      </nav>
    );
  }

  render = () => {
    let renderDashboard = this.handleView();
    return (
      <div className="risk-dashboard">
        {this.renderControlNavbar()}
        {renderDashboard}
      </div>
    );
  };
}

export function mapDispatchToProps(dispatch: $$dispatch): $dispatchProps {
  return {
    dispatch,
    loadPatientsForVeryHighRisk(locationIds, startTime, endTime, cursor) {
      return dispatch(
        loadPatientsForVeryHighRisk(locationIds, startTime, endTime, cursor)
      );
    },
    loadPatientsForHighRisk(locationIds, startTime, endTime, cursor) {
      return dispatch(
        loadPatientsForHighRisk(locationIds, startTime, endTime, cursor)
      );
    },
    loadPatientsForMediumRisk(locationIds, startTime, endTime, cursor) {
      return dispatch(
        loadPatientsForMediumRisk(locationIds, startTime, endTime, cursor)
      );
    },
    loadPatientsForLowRisk(locationIds, startTime, endTime, cursor) {
      return dispatch(
        loadPatientsForLowRisk(locationIds, startTime, endTime, cursor)
      );
    },
    loadPatientsForNoRisk(locationIds, startTime, endTime, cursor) {
      return dispatch(
        loadPatientsForNoRisk(locationIds, startTime, endTime, cursor)
      );
    },
    loadPatientsForNoneRisk(locationIds, startTime, endTime, cursor) {
      return dispatch(
        loadPatientsForNoneRisk(locationIds, startTime, endTime, cursor)
      );
    },
    goToNortonPlus() {
      dispatch(riskActions.goTo("norton_plus"));
    },
    goToBraden() {
      return null;
    }
  };
}

function mapStateToProps(state) {
  const structuredSelectors = createStructuredSelector({
    risk: riskSelectors.findProperty()
  });

  const reducerProps = mapReducersToProps({
    locationAggregateRisk: locationAggregateRiskReducer,
    locationPatientLowRisk: patientLowRiskReducer,
    locationPatientMediumRisk: patientMediumRiskReducer,
    locationPatientHighRisk: patientHighRiskReducer,
    locationPatientVeryHighRisk: patientVeryHighRiskReducer,
    locationPatientNoRisk: patientNoRiskReducer,
    locationPatientNoneRisk: patientNoneRiskReducer,
    locationSparkAggregateRisk: aggregateSparkReducer,
    locationChartRisk: clinicalChartReducer,
    clinicalRiskAggregate: clinicalAggregateReducer
  });

  return {
    ...structuredSelectors(state),
    ...reducerProps(state),
    riskAssessmentTypes: riskAssessmentTypesSelector(state),
    selectedLocationIds: selectedLocationIdsSelector(state),
    selectedLocations: selectedLocationsSelector(state),
    currentOrganization: selectedOrganizationSelector(state),
    branding: brandingSelector(state)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskComponent);
