import { dataActionTypes } from "src/action_types/data_action_types";
import { loadStates } from "src/constants/load_states";

const defaultState = {
  loadState: loadStates.loading,
  data: {}
};

/**
 * Reducer for wound assessment total count time series grouped by locationId
 */
export function woundAssessmentTotalsTimeSeriesByLocationReducer(
  state = defaultState,
  action
) {
  switch (action.type) {
    case dataActionTypes.populateWoundAssessmentTotalsTimeSeriesByLocation:
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      };

    case dataActionTypes.woundAssessmentTotalsTimeSeriesByLocationLoading:
      return {
        ...state,
        loadState: loadStates.loading
      };

    case dataActionTypes.woundAssessmentTotalsTimeSeriesByLocationLoaded:
      return {
        ...state,
        loadState: loadStates.loaded
      };

    case dataActionTypes.woundAssessmentTotalsTimeSeriesByLocationLoadError:
      return {
        ...state,
        loadState: loadStates.error
      };

    default:
      return state;
  }
}
