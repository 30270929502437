import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

//CSS
import styles from "./style.module.less";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

export class SearchBarComponent extends PureComponent {
  static propTypes = {
    searchBarLabel: PropTypes.string,
    searchKey: PropTypes.string.isRequired,
    setSearchString: PropTypes.func.isRequired,
    placeHolder: PropTypes.string,
    onInputChange: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      value: ""
    };

    this.props.setSearchString({
      key: this.props.searchKey,
      value: ""
    });
  }

  componentDidUpdate(oldProps) {
    if (
      propsAreDifferent(oldProps, this.props, "searchString") &&
      !this.props.searchString
    ) {
      this.setState({
        value: this.props.searchString
      });
    }
  }

  /**
   * Update search string in the redux store
   */
  handleSearchChange = event => {
    this.setState({
      value: event.target.value
    });
    event.persist();
    this.handleSearchChangeDebounce(event);
  };

  /**
   * Debounced handler for the search string change
   */
  handleSearchChangeDebounce = debounce(event => {
    const { value } = event.target;
    const { setSearchString, onInputChange } = this.props;
    if (onInputChange) {
      onInputChange();
    }
    setSearchString({
      key: this.props.searchKey,
      value
    });
  }, 500);

  render() {
    const { searchBarLabel, placeHolder } = this.props;
    return (
      <div className={`${styles["search-bar"]}`}>
        {searchBarLabel && <label>{searchBarLabel}</label>}
        <input
          type="search"
          className="form-control"
          placeholder={placeHolder}
          onChange={this.handleSearchChange}
          value={this.state.value}
        />
      </div>
    );
  }
}
