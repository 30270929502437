// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import "./style.less";

export default class SidebarSection extends PureComponent {
  render() {
    const { style = {}, className } = this.props;
    return (
      <div
        style={style}
        className={classnames("ui-sidebar-section", className)}
      >
        <h4>{this.props.title}</h4>
        {this.props.children}
      </div>
    );
  }
}
