import React from "react";

export class MedlineEmblemComponent extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="-40 -60 400 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M277.8 478.52C277.33 478.52 276.85 478.48 276.37 478.4L276.12 478.36C239.43 472.16 206.05 453.03 182.17 424.51C158.56 396.31 145.56 360.63 145.56 324.05C145.56 315.3 146.31 306.45 147.78 297.75C150.41 282.22 155.29 267.29 162.31 253.35C164.4 249.19 169.47 247.52 173.63 249.61C177.79 251.7 179.46 256.77 177.37 260.93C171.11 273.36 166.75 286.69 164.41 300.55C163.1 308.32 162.43 316.23 162.43 324.04C162.43 356.67 174.04 388.5 195.11 413.67C216.41 439.12 246.2 456.19 278.97 461.73L279.23 461.77C283.82 462.56 286.9 466.92 286.12 471.51C285.4 475.62 281.83 478.52 277.8 478.52Z"
          fill="#F7CFD8"
        />
        <path
          d="M310.86 480.34C306.42 480.34 302.71 476.88 302.45 472.39C302.18 467.74 305.74 463.76 310.39 463.49C341.47 461.71 371.52 449.3 394.98 428.54C418.79 407.48 434.82 378.58 440.13 347.18C441.44 339.41 442.11 331.51 442.11 323.69C442.11 307.01 439.14 290.62 433.29 274.98C431.66 270.62 433.87 265.76 438.23 264.13C442.59 262.49 447.45 264.71 449.08 269.07C455.64 286.61 458.97 304.98 458.97 323.69C458.97 332.45 458.22 341.3 456.75 349.99C450.8 385.19 432.83 417.57 406.14 441.17C379.85 464.42 346.18 478.33 311.34 480.32C311.19 480.33 311.02 480.34 310.86 480.34Z"
          fill="#F7CFD8"
        />
        <path
          d="M426.21 250.92C423.47 250.92 420.77 249.58 419.15 247.12C397.87 214.73 363.76 192.45 325.58 186C317.81 184.69 309.91 184.02 302.09 184.02C260.4 184.02 221.17 202.53 194.46 234.79C191.49 238.38 186.18 238.88 182.59 235.91C179 232.94 178.5 227.62 181.47 224.04C211.4 187.89 255.36 167.16 302.09 167.16C310.84 167.16 319.69 167.91 328.39 169.38C371.18 176.62 409.4 201.58 433.25 237.87C435.81 241.76 434.73 246.99 430.83 249.55C429.4 250.48 427.8 250.92 426.21 250.92Z"
          fill="#F7CFD8"
        />
        <path
          d="M301.89 512.67C259.7 512.67 219 498.62 185.37 472.09C145.78 440.87 120.71 396.09 114.8 346.02C102.58 242.65 176.75 148.61 280.12 136.39C330.2 130.47 379.58 144.41 419.17 175.63C458.76 206.85 483.83 251.63 489.74 301.71C495.66 351.79 481.72 401.17 450.49 440.76C419.27 480.35 374.49 505.42 324.42 511.33C316.89 512.23 309.36 512.67 301.89 512.67ZM302.61 151.93C295.8 151.93 288.96 152.33 282.09 153.14C236.49 158.53 195.71 181.35 167.28 217.41C138.85 253.47 126.15 298.44 131.54 344.04C136.93 389.64 159.75 430.42 195.81 458.85C231.87 487.29 276.84 499.98 322.44 494.59C368.04 489.2 408.82 466.38 437.25 430.32C465.68 394.26 478.38 349.29 472.99 303.69C467.6 258.09 444.78 217.31 408.72 188.88C378.09 164.72 341.03 151.93 302.61 151.93Z"
          fill="#F591A3"
        />
        <path
          d="M112.77 399.51C103.14 375.32 98.2599 349.82 98.2599 323.73C98.2599 277.54 113.42 233.9 142.09 197.55C178.78 151.03 233.79 122.8 293.02 120.08C297.51 119.87 301.01 116.19 301.06 111.74C301.07 111.6 301.06 111.46 301.06 111.31C301.06 111.29 301.06 111.28 301.06 111.27C301.06 111.2 301.05 111.14 301.04 111.08C300.96 109.08 300.21 107.11 298.72 105.56L276.42 82.4399C273.19 79.0899 267.85 78.9899 264.5 82.2299C261.15 85.4599 261.05 90.7999 264.29 94.1499L274.53 104.77C217.25 112.03 164.89 141.43 128.86 187.11C97.7999 226.46 81.3999 273.7 81.3999 323.72C81.3999 351.96 86.6799 379.56 97.1099 405.74C98.4299 409.04 101.59 411.06 104.95 411.06C105.99 411.06 107.04 410.87 108.07 410.46C112.39 408.74 114.5 403.83 112.77 399.51Z"
          fill="#F16278"
        />
        <path
          d="M474.4 448.6C470.73 445.73 465.43 446.38 462.56 450.05C462.52 450.1 462.48 450.14 462.45 450.19C423.52 499.56 365.08 527.87 302.13 527.87C255.94 527.87 212.3 512.72 175.95 484.04C157.92 469.82 142.57 452.9 130.33 433.74C129.84 432.97 129.22 432.34 128.55 431.79C127.61 430.9 126.45 430.21 125.12 429.82C120.65 428.53 115.97 431.09 114.67 435.57L105.71 466.42C104.41 470.89 106.98 475.57 111.46 476.87C112.25 477.1 113.04 477.21 113.82 477.21C117.48 477.21 120.84 474.81 121.91 471.13L126 457C137.34 471.99 150.56 485.51 165.5 497.29C204.86 528.33 252.11 544.74 302.13 544.74C370.28 544.74 433.54 514.08 475.69 460.64L475.85 460.44C478.72 456.76 478.07 451.46 474.4 448.6Z"
          fill="#F16278"
        />
        <path
          d="M530.36 412.17C529.24 407.65 524.66 404.9 520.15 406.01L505.86 409.55C517.19 382.6 523.15 353.36 523.15 324C523.15 255.85 492.5 192.59 439.05 150.43C408.52 126.35 372.15 110.72 333.87 105.22C329.26 104.57 324.99 107.76 324.32 112.37C323.66 116.98 326.86 121.25 331.47 121.92C366.81 126.99 400.4 141.43 428.6 163.67C477.97 202.6 506.28 261.04 506.28 323.99C506.28 356.53 498.39 388.92 483.46 417.67C482.61 419.3 482.37 421.08 482.62 422.77C482.66 423.16 482.72 423.55 482.81 423.94C483.76 427.78 487.2 430.34 490.99 430.34C491.66 430.34 492.34 430.26 493.03 430.09L524.21 422.36C528.72 421.26 531.48 416.69 530.36 412.17Z"
          fill="#F16278"
        />
        <g>
          <path
            opacity="0.2"
            d="M196.09 95.6001C192.35 97.3501 188.67 99.1801 185.04 101.11C180.37 104.04 176.01 107.05 171.74 110.01C164.7 114.89 157.86 119.61 150.26 123.35C145.4 127.05 140.68 130.93 136.1 134.97C153.03 131.45 163.24 122.32 173.97 112.71C180.5 106.87 187.33 100.76 196.09 95.6001Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M335.83 138.31C178.91 84.2701 203.91 171.1 103.59 169.66C102.64 170.88 101.7 172.1 100.78 173.34C137.37 175.34 145.48 175.44 175.96 151.75C232.79 107.38 289.21 125.94 343.78 143.9C421.99 169.63 432.49 145.78 494.71 168.76C497.39 169.75 500.05 170.73 502.82 171.67C502.22 170.88 501.61 170.09 501 169.3C500.55 169.11 500.1 168.92 499.65 168.73C428.41 138.45 422.17 168.04 335.83 138.31Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M270.66 74.0401C293.36 74.9201 321.45 78.47 356.71 85.77C376.45 89.85 387.34 90.81 398.19 90.95C368.65 78.77 336.29 72.0301 302.35 72.0401C291.62 72.0501 281.04 72.7301 270.66 74.0401Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M501.53 469.16C465.79 477.52 460.19 429.48 415.13 412.5C359.44 392.93 353.05 416.97 304.89 422.99C252 430.05 174.94 411.62 77.5901 435.79C80.3801 441.36 83.3701 446.81 86.5501 452.14C87.4601 451.91 88.3501 451.7 89.2701 451.46C96.6401 449.66 104.8 447.14 113.44 444.48C230.63 408.33 257.7 433.19 308.12 425.2C347.18 419.29 397.32 383.5 445.76 441.46C458.65 456.89 476.32 478.01 494.69 477.76C497.06 477.73 499.39 477.32 501.69 476.55C504.58 472.8 507.37 468.96 510.05 465.04C507.69 466.58 505.1 467.91 502.18 468.97C502.17 468.97 501.83 469.11 501.53 469.16Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M513.24 448.43C506.18 454.64 499.37 457.4 492.45 456.85C472.09 455.24 457.65 424.62 443.66 415.31C433.61 408.1 421.7 402.61 406.14 398C349.24 381.84 354.81 417.8 238.21 412.43C156.63 408.67 140.71 410.35 105.53 413.3C93.6 414.3 79.32 415.25 69.76 418.58C71.55 422.96 73.46 427.27 75.48 431.52C237.84 395.59 259.89 436.83 336.06 409.76C453.02 368.19 453.49 466.65 493.42 465.43C503.33 465.13 513.04 457.64 523.1 442.53C524.04 441.1 524.97 439.76 525.9 438.47C527.42 435.53 528.87 432.55 530.27 429.55C529.9 430.02 529.52 430.5 529.14 430.99C524.87 436.45 520.01 442.65 513.24 448.43Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M500.3 435.4C500.08 435.48 499.77 435.56 499.45 435.57C481.09 438.14 470.24 419.67 456.52 405.95C450.54 400 445.93 396.26 420.7 388.76C351.19 368.37 372.36 381.89 282.52 394.29C119.07 417.93 137 366.08 64 403.07C65.12 406.43 66.32 409.76 67.58 413.06C126.36 393.97 148.45 409.82 221.2 406.38C245 405.25 246.18 405.2 261.75 405.04C357.35 404.04 343.7 376.18 407.71 392.18C472.9 408.04 468.86 450.93 494.86 449.7C504.92 449.23 513.36 439.15 522.3 428.48C527.17 422.67 532.16 416.72 537.57 412.25C538.13 410.76 538.68 409.26 539.22 407.76C535.41 410.28 531.47 413.46 527.23 417.45C517.44 426.72 510.89 432.55 500.3 435.4Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M468.71 392.12C443.01 372.99 435.77 380.8 395.17 367.48C315.77 341.43 243.65 408.13 159.24 382.64C122.29 371.48 101.39 365.46 59.8 389.13C60.55 391.89 61.33 394.64 62.17 397.36C98.67 380.92 123.64 383.71 152.33 389.83C204.63 400.99 224.49 390.2 280.62 383.9C351.24 375.97 356.6 365.78 394.93 375.52C442.89 387.15 449.09 385.77 469.64 409.38C479.29 420.46 488.41 430.94 500.42 427.65C508.67 425.39 514.08 419.92 519.8 414.12C525.81 408.03 532.25 401.52 542.67 397.42C543.02 396.29 543.35 395.16 543.68 394.02C531.92 396.39 524.27 399.46 518.09 401.95C497.32 410.33 487.94 406.43 468.71 392.12Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M447.07 469.21C396.54 413.26 381.06 419.29 326.3 438.1C265.66 459.35 260.21 422.11 144.93 459.96C130.68 464.64 116.24 469.38 100.59 473.17C103.26 476.76 106.02 480.29 108.88 483.73C111.76 482.98 114.65 482.14 117.56 481.18C129.3 477.39 139.69 472.11 150.69 466.52C248.11 416.99 261.76 470.22 340.33 438.35C420.89 405.67 427.61 487.93 479.7 501.56C481.68 499.59 483.62 497.59 485.53 495.56C475.53 493.4 464.98 487.1 454.03 476.7C452.41 475.12 449.94 472.38 447.07 469.21Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M395.07 349.96C361.88 341.22 328.66 341.61 256.74 365.49C223.44 376.55 202.88 383.6 154.16 365.06C103.38 346.07 88.37 356.3 58.45 376.69C57.98 377.01 57.47 377.35 57 377.68C57.38 379.4 57.78 381.12 58.19 382.83C96.82 359.81 120.99 365.31 156 374.95C182.21 382.16 199.52 386.93 267.27 371.94C359.88 352.02 359.88 352.02 394.4 360.25C479.65 380.58 445.37 370.8 483 394.03C497.64 403.07 503.6 400.6 515.48 395.69C522.76 392.68 531.87 388.91 545.84 386.24C546.12 385.17 546.37 384.09 546.63 383.02C534.77 382.27 518.33 395.18 494.26 389.71C488.28 388.35 484.03 384.04 472.89 376.73C448.45 360.68 424.15 357.62 395.07 349.96Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M388.86 178.43C331.48 171.59 269.58 128.74 192.8 180.22C143.5 213.27 136.28 197.32 86.36 194.94C85.74 195.99 85.11 197.03 84.5 198.09C96.75 198.51 108.58 201.54 120.01 204.47C138.48 209.21 145.01 209.71 145.01 209.71C160.22 211.37 182.46 199.58 195.93 190.62C289.24 128.47 341.34 180.72 409.69 181.3C484.42 181.91 478.73 199.25 516.51 213.91C521.54 215.82 526.23 217.2 530.76 218.25C528.9 214.21 526.92 210.22 524.85 206.3C521.96 205.56 519.01 204.79 515.96 203.94C445.82 182.35 508.39 185.49 388.86 178.43Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M448.96 454.15C401.94 402.05 371.14 412.19 338.53 422.94C252.8 451.18 273.12 406.8 90.2001 455C89.6501 455.16 89.1201 455.29 88.5701 455.44C91.4301 460.06 94.4201 464.58 97.5601 468.99C113.51 465.25 125.89 459.76 138.14 454.33C233.73 411.93 248.65 454.19 338.15 427.52C398.56 409.47 410.83 420.04 453.07 467.71C462.11 477.56 476.81 488.11 490.81 489.74C492.74 487.55 494.65 485.34 496.51 483.08C496.46 483.08 496.42 483.09 496.37 483.09C482.74 483.73 467.24 474.26 448.96 454.15Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M406.14 196.05C350.57 192.65 288.19 146.61 209.12 207C171.7 234.87 154.29 229.23 127.94 220.7C120.09 218.16 111.2 215.28 100.79 213.06C91.2502 211.04 83.5402 210.76 77.1902 211.79C76.6002 212.98 76.0102 214.17 75.4402 215.37C78.0502 214.63 80.8902 214.18 83.9802 214.07C99.2102 213.29 115.73 220.36 131.69 227.21C166.69 242.21 175.07 244.67 216.21 215.84C300.81 156.55 326.84 187.17 424.54 204.13C492.77 215.78 478.07 242.54 528.71 243.97C533.41 244.11 537.34 243.92 540.85 243.72C540.16 241.65 539.44 239.59 538.7 237.54C490.84 232.59 472.29 200.09 406.14 196.05Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M424.92 223.79C405.01 217.43 399.43 218.24 367.83 210.48C304.57 194.67 282.73 195.36 233.87 237.76C160.84 301.12 115.32 225.87 81.8101 237.76C73.7601 240.62 66.7701 248.59 60.4601 262.11C59.0701 265.1 57.8201 268.02 56.6001 270.88C55.5501 275.82 54.6401 280.81 53.8801 285.85C54.6801 284.34 55.4901 282.78 56.3301 281.16C57.2801 279.33 58.2701 277.42 59.3201 275.44C79.8701 236.37 100.8 246.79 128.22 257.8C246.25 305.18 239.75 190.04 338.8 208.81C524.88 245.66 449.73 300.14 523.86 271.5C533.74 267.66 541.39 266.66 547.53 267.57C547.15 265.89 546.75 264.23 546.33 262.56C540.5 262.85 534.75 264.68 528.86 266.56C476.88 283.15 491.04 245.38 424.92 223.79Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M441.48 171.61C385.3 169.02 399.13 177.59 330.86 155.34C307.72 147.47 274.06 142.15 248.99 142.41C187.49 142.96 189.01 178.22 152.93 188.58C137.04 193.35 117.68 191.49 98.97 189.69C96.02 189.41 93.06 189.12 90.1 188.86C89.82 189.3 89.55 189.74 89.28 190.18C97.16 190.9 106.18 192.13 116.79 193.71C157.24 199.44 171.33 188.96 187.65 176.83C235.53 141.24 293.99 145.31 348.1 163.52C417.37 186.83 417.7 166.01 466.53 178.5C487.65 184.54 485.66 189.26 520.87 198.99C519.51 196.61 518.11 194.25 516.68 191.92C515.46 191.66 514.21 191.39 512.92 191.11C498.35 187.78 490.34 184.18 483.27 181C473 176.38 464.9 172.73 441.48 171.61Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M438.54 248.03C414.87 234.96 343.55 212.36 313.41 216.01C274.56 220.6 251.58 256.33 224.77 271.8C160.75 303.59 115.28 243.99 84.3101 254.1C73.5101 257.62 68.2901 268.71 62.2301 281.55C59.2901 287.78 56.1601 294.39 52.1301 300.59C51.9501 302.62 51.7901 304.66 51.6501 306.71C54.5301 302.72 57.2101 298.56 59.8201 294.5C71.8601 275.75 83.2301 258.03 109.32 265.84C127.25 271.33 190.17 309.22 234.16 277.64C265.18 255.51 286.95 233.1 315.37 227.17C353.33 219.08 410.68 244 429.58 254.29C486.13 285 469.84 305.85 509.72 288.35C517.08 285.12 524.7 281.78 532.07 281.23C539.65 280.66 545.99 283.18 551.4 288.87C550.83 284.69 550.15 280.54 549.37 276.43C542.9 272.95 537.01 275.17 527.48 278.8C471.78 299.96 489.6 276.76 438.54 248.03Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M530.85 349.93C503.97 317.9 491.91 353.76 457.82 336.13C450.05 332.11 431.52 329.42 394.26 305.48C347.19 275.43 322.77 275.69 261.58 318.83C214.01 352.37 194.74 351.42 134.46 329.2C100.92 316.83 97.41 315.54 64.73 345.73L62.99 347.34C59.81 350.28 56.56 353.27 53.23 356.18C53.34 357.02 53.46 357.85 53.58 358.69C54.51 358.05 55.45 357.39 56.34 356.77C93.93 330.65 105.27 322.76 159.48 341.79C206.67 358.51 222.76 350.05 254.8 333.18C404.34 254.48 361.57 343.44 480.52 344.8C497.01 344.99 511.24 345.15 526.84 356.54C533.81 361.62 538.97 366.22 549.77 368.26C549.96 367.23 550.12 366.19 550.3 365.15C540.76 361.73 535.75 355.78 530.85 349.93Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M501.45 322.16C476.01 332.12 472.09 329.45 460.46 321.51C447.86 312.92 450.22 315.4 423.17 299.34C379.62 274.26 344.36 248.05 292.44 279.35C243.84 308.49 226.28 353.91 135.55 313.95C94.99 295.54 87.34 305.37 74.67 321.66C68.98 328.98 62.58 337.21 52 344.63C52.1 345.83 52.2 347.02 52.32 348.21C54.58 346.31 56.92 344.3 59.46 342.12L60.62 341.12C96.24 310.21 104.34 312.69 131.2 320.9C230.87 351.37 232.35 325.19 288.66 295.78C345.41 265.57 370.76 280.62 402.86 299.68C475.67 342.9 474.61 335.36 498.65 329.84C528.31 323.48 529.86 341.86 545.32 354.84C547.52 356.76 550.24 358.04 550.27 358.06C550.63 358.19 550.96 358.29 551.31 358.41C551.59 356.41 551.84 354.41 552.08 352.39C547.49 349.55 543.34 345.54 539.68 340.01C524.75 317.19 524.54 313.09 501.45 322.16Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M514.95 293.93C465.46 313.43 483.42 290.03 426.36 260.32C229.33 157.66 296.81 360.36 120.15 276.27C86.5001 260.37 77.8301 275.31 65.8301 295.98C61.6701 303.15 57.1701 310.87 51.1601 317.85C51.1301 319.35 51.1101 320.84 51.1001 322.35C54.8701 318.28 58.3701 313.89 61.8601 309.52C62.8101 308.33 63.7501 307.15 64.6901 305.98C106.98 253.91 107.42 297.29 191.49 305.38C238.5 309.76 238.77 285.96 288.46 255.5C322.32 234.6 351.04 235.48 402.25 259C498.84 304.39 452.29 320.38 511.87 299.66C537.63 290.59 545.33 305.01 552.77 318.95C553.08 319.54 553.4 320.13 553.72 320.72C553.66 314.9 553.41 309.12 552.96 303.39C551.34 300.87 549.55 298.28 547.48 295.67C538.66 284.59 529.22 288.31 514.95 293.93Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M409.62 186.43C338.88 185.87 283.39 137.55 200.93 193.07C136.12 236.7 130.66 201.93 81.1901 204.04C81.0801 204.25 80.9701 204.46 80.8601 204.66C116.64 202.32 152.53 236.23 205.79 202.34C303.12 140.4 349.13 188.01 406.89 190.94C468.37 194.06 486.59 216.23 509.53 225.79C521.6 230.73 529.96 232.33 536.92 232.77C535.65 229.5 534.32 226.26 532.92 223.05C481.15 210.74 485.45 188.45 409.62 186.43Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M304.75 519C279.5 528.68 258.35 511.7 235.23 506.79C206.56 500.78 185.77 512.39 161.69 525.84C160.16 526.69 158.61 527.56 157.05 528.42C158.25 529.27 159.47 530.11 160.69 530.94C188.38 518.57 209.99 511.47 238.32 519.9C287.65 534.38 282.97 540.35 324.48 518.86C359.08 500.99 372.65 509.98 405.44 531.71C410.89 535.33 416.89 539.3 423.63 543.59C425.74 542.43 427.82 541.23 429.89 540.01C426.43 537.4 422.96 534.69 419.44 531.94C350.27 477.86 341.49 505.13 304.75 519Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M421.52 115.24C385.28 118.55 372.45 116.74 331.06 104.4C231.14 74.6501 205.22 93.2601 177.16 117.66C164.19 128.94 151.94 139.59 129.8 142.19C129.21 142.27 128.64 142.32 128.06 142.38C126.72 143.67 125.4 144.97 124.09 146.28C147.3 144.07 160.51 133.12 174.35 121.65C257.3 52.9001 331.99 128.3 411.38 124.7C420.99 124.27 432.96 123.72 453.27 124.81C454.53 124.91 455.74 125.03 456.95 125.15C451.82 121.14 446.52 117.34 441.07 113.73C439.61 113.8 438.13 113.87 436.59 113.98C431.16 114.36 426.26 114.81 421.52 115.24Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M242.35 548.97C222.7 545.86 202.66 543.72 187.41 546.9C192.13 549.33 196.94 551.61 201.82 553.75C212.91 553.01 224.28 553.52 236.2 556C345.06 577.39 320.96 532.88 391.37 558.5C395.92 556.78 400.41 554.93 404.83 552.96C327.16 518.57 360.1 565.44 242.35 548.97Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M238.49 563.84C233.98 563.35 229.29 562.85 224.4 562.34C248.99 570.36 275.24 574.72 302.51 574.71C315.51 574.71 328.29 573.71 340.76 571.81C346.08 569.74 352.04 568.34 359.41 568.23C367.73 566.3 375.88 563.95 383.86 561.22C334.35 545.86 350.09 575.92 238.49 563.84Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M225.07 102.71C198.12 109.89 185.35 120.32 174.09 129.52C160.92 140.28 149.33 149.75 119.53 151.02C117.98 152.66 116.46 154.33 114.96 156.01C146.33 155.84 160.27 144.01 174.89 131.58C194.97 114.52 215.73 96.88 282.95 107.71C309.52 111.76 326.71 118.21 341.87 123.9C407.21 148.41 419.53 130.53 475.59 141.21C472.84 138.59 470.02 136.04 467.15 133.54C459.61 132.46 450.59 131.64 439.46 131.25C429.02 130.89 420.62 130.91 413.21 130.92C332.93 131.05 294.78 87.74 225.07 102.71Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M224.64 84.33C222.41 85.06 220.19 85.81 217.99 86.6C232.24 82.91 250.62 81.68 275.7 84.86C339.03 93 353.32 117.19 418.75 109.88C422.88 109.42 427.5 108.9 432.66 108.38C425.01 103.73 417.08 99.5 408.91 95.68C408.01 95.67 407.1 95.66 406.21 95.65C388.76 95.47 372.27 95.31 328.57 87.04C280.26 78.01 248.15 78.85 224.64 84.33Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M327.51 525.01C284.78 544.97 266.52 537.86 248.86 530.97C231.17 524.08 212.88 516.97 168.89 536.32C171.8 538.15 174.75 539.93 177.73 541.64C197.79 536.14 218.03 533.45 240.14 538.3C347.98 560.91 324 491.13 410.5 550.34C413.11 549.1 415.69 547.81 418.25 546.48C417.39 545.89 416.53 545.31 415.68 544.73C411.53 541.89 407.61 539.21 403.77 536.72C359.02 507.53 344.58 516.96 327.51 525.01Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M513.75 305.54C461.02 320.9 495.43 318.25 423.93 280.08C304.45 214.26 295.49 260.53 234.6 305.39C179.36 343.25 115.74 273.22 88.4 289.64C88.35 289.67 81.38 294.11 69.21 309.58C68.05 311.07 66.9 312.58 65.75 314.08C61.04 320.24 56.39 326.31 51.24 331.63C51.3 333.51 51.38 335.4 51.48 337.27C58.25 331.79 63.35 325.84 68.13 320.26C82.47 303.53 93.79 290.32 138.01 308.01C223.96 341.13 241.21 302.65 292.73 270.38C333.16 245.06 363.58 258.95 402.04 278.58C479.17 317.66 463.78 330.42 496.62 316.8C509.7 311.37 524.53 305.22 538.25 324.86C540.13 327.61 541.74 330.16 543.29 332.64C546.56 337.85 549.31 342.22 552.8 345.27C553.12 341.52 553.37 337.74 553.52 333.94C549.86 329.71 547.2 324.98 544.69 320.48C536.43 305.67 531.5 300.38 513.75 305.54Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M391.19 329C360.05 307.78 336.66 295.46 262.59 339.93C219.75 365.65 201.97 365.87 155.81 347.49C133.51 338.61 112.44 330.22 82.6301 346.11C72.3201 351.83 63.2701 358.53 54.5701 365.09C54.8601 366.82 55.1601 368.54 55.4901 370.26C90.2801 348.7 108.47 342.04 156.58 358.35C200.74 373.34 212.77 375.82 251.08 356.58C352.66 305.55 359.79 334.87 423.9 351.64C450.43 358.58 475.75 356.59 493.43 360.39C520.08 366.12 534.26 375.88 548.08 376.76C548.21 376.16 548.35 375.56 548.47 374.96C536.31 372.87 530.45 368.37 524.71 363.97C488.96 336.54 454.92 372.44 391.19 329Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M439.7 479.84C388.11 427.69 372.34 432.71 343.41 443.91C259.8 476.26 258.68 424.27 150.96 473.82C138.41 479.59 126.24 485.18 113.45 489.06C115.91 491.86 118.42 494.61 121 497.3C132.74 492.5 143.23 486.22 153.53 480.04C247.41 423.71 258.69 481.5 333.05 452.07C381.66 432.74 391.03 442.43 438.06 488.61C450.69 501.14 459.51 508.57 467.56 512.87C470.1 510.66 472.59 508.39 475.04 506.08C460.46 500.86 449.15 489.42 439.7 479.84Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M336.43 457.41C252.05 488.8 249.64 431.2 156.48 485.94C146.73 491.67 136.81 497.49 125.84 502.2C127.65 503.98 129.48 505.74 131.34 507.47C139.36 503.29 146.35 498.46 153.75 493.35C162.42 487.37 171.38 481.18 183.24 474.99C240.35 444.97 267.78 491.28 327.29 465.25C373.22 444.89 382.85 455.14 432.53 503.5C440.91 511.54 448.62 517.86 455.75 522.52C458.24 520.6 460.69 518.65 463.1 516.64C452.29 509.82 441.49 499.47 430.31 488.73C380.35 440.74 368.9 445.33 336.43 457.41Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M334.06 472.7C254.98 501.75 237.73 452.57 186.58 481.2C178.55 485.72 171.31 491.07 163.63 496.74C155.66 502.63 147.53 508.62 138.33 513.76C139.06 514.39 139.81 515.01 140.55 515.64C147.34 511.8 153.68 507.75 159.85 503.82C249.03 446.84 254.36 514.73 324.08 481.86C371.86 459.57 380.72 476.33 429.41 518.79C434.98 523.66 439.94 527.59 444.42 530.79C446.52 529.35 448.59 527.88 450.65 526.37C443.92 521.31 437.67 515.44 431.79 509.9C375.62 457.05 364.93 461.36 334.06 472.7Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M333.99 147.14C212.14 107.68 189.64 154.1 156.84 171.34C140.46 179.7 118.56 179.91 96.5702 179.17C95.8602 180.19 95.1502 181.2 94.4502 182.23C95.5702 182.3 96.6802 182.37 97.8002 182.44C120.02 183.81 143 185.23 159.58 177.8C198.82 159.34 205.89 106.47 359.38 158.77C413.54 176.71 438.03 152.88 488 175.7C494.42 178.63 501.54 181.88 512.04 184.69C511.17 183.38 510.3 182.09 509.41 180.8C504.75 179.44 500.64 177.91 496.42 176.33C432.75 152.47 416.64 173.58 333.99 147.14Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M192.31 125.9C185.96 129.91 180.72 133.77 175.65 137.51C158.64 150.04 145.78 159.51 111.2 160.29C110.21 161.45 109.22 162.61 108.25 163.79C136.97 164.82 147.84 161.12 178.2 137.52C213.84 109.63 267.86 107.91 338.75 132.42C419 160.04 436.93 135.77 492.45 158.87C490.39 156.49 488.27 154.16 486.12 151.85C427.15 132.62 406.27 152.43 334.51 127.82C307.03 118.74 239.29 95.0701 192.31 125.9Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M366.27 197.98C310.71 182.45 276.95 178.93 221.51 223.92C154.89 277.52 132.41 218.52 84.2901 220.66C79.3101 220.86 75.0801 222.15 71.4201 224.25C68.4201 231.23 65.7301 238.38 63.3601 245.67C100.69 194.94 150.83 292.58 218.7 242.35C255.51 214.26 281.61 189.92 325.52 194.45C338.75 196.58 424.01 216.75 426.88 217.66C497.47 240.34 477.21 277.31 529.54 259.14C534.66 257.36 539.52 255.69 544.29 254.79C543.77 252.97 543.23 251.15 542.68 249.35C542.11 249.35 541.54 249.34 540.95 249.34C537.36 249.32 533.3 249.3 528.51 249.01C503 247.43 494.3 241.61 483.28 234.24C445.15 208.72 424.63 214.18 366.27 197.98Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M324.01 491.13C249.72 524.93 241.91 454.64 166.57 508.76C160.77 512.92 154.65 517.31 147.78 521.5C148.18 521.82 148.6 522.12 149 522.44C149.67 522.08 150.34 521.71 151.01 521.35C179.96 505.58 207.31 490.69 250.9 504.78C300.32 520.78 298.88 512.66 331.29 495.95C368.56 477.17 386.42 504.56 434.44 537.27C436.18 536.2 437.9 535.1 439.61 533.99C433.34 529.13 426.97 523.41 419.75 516.93C374.04 475.89 357.62 475.84 324.01 491.13Z"
            fill="white"
          />
        </g>
      </svg>
    );
  }
}
