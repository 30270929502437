import { withRouter } from "react-router-dom";
import flowRight from "lodash.flowright";

// HOC
import {
  withResourceLoaderFactory,
  withLoadingComponentFactory
} from "src/hoc";

// Constants
import { tallyTypes } from "src/constants";

// Component
import { SurveyEtiologyComponent } from "src/components/survey_etiology_component";

// Actions
import { loadWoundTalliesAction } from "src/actions/data_actions/wound_tallies";

// Selectors
import { woundTalliesResourceSelectors } from "src/selectors/data_selectors/wound_tallies";
import {
  createWoundTalliesPayloadSelector,
  createWoundTalliesContextSelector
} from "src/selectors/api_selectors/survey/wound_tallies";

export const SurveyEtiologyContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadWoundTalliesAction,
    resourceSelectors: woundTalliesResourceSelectors,
    createContextSelector: () =>
      createWoundTalliesContextSelector(tallyTypes.etiology),
    createPayloadSelector: () =>
      createWoundTalliesPayloadSelector(tallyTypes.etiology)
  }),
  withLoadingComponentFactory({
    analyticsEventName: "RENDER_SURVEY"
  }),
  withRouter
)(SurveyEtiologyComponent);
