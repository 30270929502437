import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import get from "lodash.get";

//Components
import { TableComponent } from "../table_component";
import { TableNavigatorComponent } from "../table_navigator_component";
import { TablePageLimitDropdownComponent } from "../table_page_limit_dropdown_component";
import {
  LockedIconComponent,
  UnlockedIconComponent
} from "src/components/icon_components";

//Containers
import { ImageDeclinedContainer } from "src/containers/image_declined_container";
import { DownloadRevisionPDFButtonContainer } from "src/containers/buttons/download_revision_pdf_button_container/index.js";
import { RevisionNavigationContainer } from "src/containers/revision_navigation_container";

// Formatters
import { cellFormatters } from "./cell_formatters";
import { userFormatter } from "src/components/tables/cell_formatters/user_formatter";

// Styles
import styles from "./style.module.less";

// Analytics
import { track } from "src/analytics";

// Translations
import i18n from "src/translations";
import { STANDARD_FRAME_TYPES } from "src/selectors/data_selectors/frames/constants.js";
export class RevisionsTableComponent extends React.PureComponent {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
    revisions: PropTypes.array.isRequired,
    revisionsWithUserProfile: PropTypes.array.isRequired,
    pageLimit: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    firstPageContext: PropTypes.string.isRequired,
    sendSetPageLimitAction: PropTypes.func.isRequired,
    sendSetPageAction: PropTypes.func.isRequired,
    bwatScoreEnabled: PropTypes.bool,
    pdfExportEnabled: PropTypes.bool.isRequired,
    isWoundSummaryDownloadEnabled: PropTypes.bool.isRequired,
    assessmentVersion: PropTypes.string.isRequired,
    subtypeKeysForSchema: PropTypes.object.isRequired,
    encounterNamingConvention: PropTypes.string.isRequired
  };

  state = {
    showRevisionImageModal: false,
    revisionInModal: {}
  };

  setPage = pageNumber => {
    const { firstPageContext, sendSetPageAction } = this.props;
    sendSetPageAction(firstPageContext, pageNumber);
  };

  hideRevisionImageModal = () => {
    const isCurrentRevsionNotEdited =
      this.props.revisionBeingEdited != this.state.revisionInModal.id;
    if (isCurrentRevsionNotEdited) {
      this.setState({
        ...this.state,
        showRevisionImageModal: false
      });
    }
  };

  handleAnalytics = () => {
    track("RENDER_REVISIONS_TABLE_REVISION_IMAGE_MODAL");
  };

  onClickRevisionImage = (e, row) => {
    this.handleAnalytics();
    this.setState({
      ...this.state,
      revisionInModal: row,
      showRevisionImageModal: true
    });
  };

  //Buttons Cell Formatters
  revisionThumbnailFormatter = (cell, row) => {
    const frames = get(row, "attributes.frames", []);
    const frame =
      frames.find(val => STANDARD_FRAME_TYPES.includes(val.frameType)) || {};
    const contentType = get(frame, "contentType", "");
    if (contentType == "declined") {
      return (
        <div
          className={`${styles["assessment-image"]}`}
          onClick={e => this.onClickRevisionImage(e, row)}
        >
          <ImageDeclinedContainer />
        </div>
      );
    } else if (frame.imageUrl && frame.thumbnailUrl) {
      return (
        <div
          className={styles["wound-image"]}
          style={{
            backgroundImage: `url('${frame.thumbnailUrl}')`
          }}
          onClick={e => this.onClickRevisionImage(e, row)}
        />
      );
    } else {
      return (
        <div
          className={styles["assessment-image"]}
          onClick={e => this.onClickRevisionImage(e, row)}
        />
      );
    }
  };

  downloadPDFButtonFormatter = (cell, row) => {
    const { patientId, pdfExportEnabled } = this.props;
    if (!pdfExportEnabled) return "";
    if (pdfExportEnabled) {
      return (
        <div className={styles["pdf-button-container"]}>
          <DownloadRevisionPDFButtonContainer
            revision={row}
            revisions={[row]}
            patientId={patientId}
          />
        </div>
      );
    }
  };

  lockedStateFormatter = (cell, row) => {
    if (
      get(row, "attributes.assessmentAnswers[0].lockState", "") === "locked"
    ) {
      return (
        <div className={styles["icon-wrapper"]}>
          <LockedIconComponent />
        </div>
      );
    } else {
      return (
        <div className={styles["icon-wrapper"]}>
          <UnlockedIconComponent />
        </div>
      );
    }
  };

  render() {
    const {
      revisionsWithUserProfile,
      pageLimit,
      pageCount,
      currentPage,
      sendSetPageLimitAction,
      bwatScoreEnabled,
      revisions,
      patientId,
      pdfExportEnabled,
      isWoundSummaryDownloadEnabled,
      schemaLocalizationDictionary,
      assessmentVersion,
      subtypeKeysForSchema,
      encounterNamingConvention
    } = this.props;

    const headerStyle = {
      verticalAlign: "top"
    };

    const columns = [
      {
        dataField: "attributes.frames[0].thumbnailUrl",
        text: "",
        formatter: this.revisionThumbnailFormatter
      },
      {
        dataField: "Download PDF",
        text: "",
        formatter: this.downloadPDFButtonFormatter
      },
      {
        dataField: "attributes.createdAt",
        text: "Evaluation Date",
        formatter: cellFormatters.evaluationDateFormatter,
        headerStyle
      },
      {
        dataField: "attributes.assessmentAnswers[0].attributes.encounterNumber",
        text: encounterNamingConvention,
        formatter: (cell, row) =>
          cellFormatters.encounterFormatter(
            cell,
            row,
            encounterNamingConvention
          ),
        headerStyle,
        hidden: encounterNamingConvention === "None"
      },
      {
        dataField:
          "attributes.assessmentAnswers.answersJson[0].overallProgress",
        text: "Progress",
        formatter: (cell, row) =>
          cellFormatters.progressFormatter(cell, row, assessmentVersion),
        headerStyle
      },
      {
        dataField: "attributes.assessmentAnswers.answersJson[0].woundType",
        text: "Type",
        formatter: (cell, row) =>
          cellFormatters.woundTypeFormatter(
            cell,
            row,
            schemaLocalizationDictionary
          ),
        headerStyle
      },
      {
        dataField: "attributes.assessmentAnswers.answersJson[0].subType",
        text: "Subtype",
        formatter: (cell, row) =>
          cellFormatters.subTypeFormatter(
            cell,
            row,
            schemaLocalizationDictionary,
            subtypeKeysForSchema
          ),
        headerStyle
      },
      {
        dataField: "attributes.area",
        text: "Surface Area",
        formatter: cellFormatters.areaFormatter,
        headerStyle
      },
      {
        dataField: "attributes.height",
        text: "Length",
        formatter: cellFormatters.lengthFormatter,
        headerStyle
      },
      {
        dataField: "attributes.width",
        text: "Width",
        formatter: cellFormatters.widthFormatter,
        headerStyle
      },
      {
        dataField: "attributes.maxDepth",
        text: "Depth",
        formatter: cellFormatters.depthFormatter,
        headerStyle
      },
      {
        dataField: bwatScoreEnabled
          ? "attributes.assessmentAnswers.answersJson[0].bwatScore"
          : "attributes.assessmentAnswers.answersJson[0].pushScore",
        text: bwatScoreEnabled ? "BWAT Score" : "PUSH Score",
        formatter: bwatScoreEnabled
          ? cellFormatters.bwatScoreFormatter
          : cellFormatters.pushScoreFormatter,
        headerStyle
      },
      {
        dataField: "attributes.assessmentAnswers.answersJson[0].cleaned",
        text: "Cleansing Solution",
        formatter: (cell, row) =>
          cellFormatters.cleansingSolutionFormatter(
            cell,
            row,
            schemaLocalizationDictionary
          ),
        headerStyle
      },
      {
        dataField:
          "attributes.assessmentAnswers.answersJson[0].primaryDressing",
        text: "Primary Dressing",
        formatter: (cell, row) =>
          cellFormatters.primaryDressingFormatter(
            cell,
            row,
            schemaLocalizationDictionary
          ),
        headerStyle
      },
      {
        text: i18n.t("headers.createdBy"),
        dataField: "attributes.createdBy",
        formatter: userFormatter
      },
      {
        dataField: "attributes.assessmentAnswers.lockState",
        text: "",
        formatter: this.lockedStateFormatter
      }
    ];

    return (
      <div>
        {isWoundSummaryDownloadEnabled && pdfExportEnabled && (
          <div
            className={styles["pdf-button-container"]}
            style={{ width: "100%", display: "inline-block" }}
          >
            <DownloadRevisionPDFButtonContainer
              revision={revisions[0]}
              revisions={revisions}
              patientId={patientId}
              landscape={true}
              className={"float-right"}
            />
          </div>
        )}
        <TableComponent
          data={revisionsWithUserProfile}
          keyField="id"
          columns={columns}
          rowClasses={styles["revision-table-row"]}
        />
        <div className="pull-left">
          <TablePageLimitDropdownComponent
            selectPageLimit={sendSetPageLimitAction}
            selectedPageLimit={pageLimit}
          />
        </div>
        <TableNavigatorComponent
          pageNumber={currentPage}
          pageCount={pageCount}
          selectTablePage={this.setPage}
        />
        <Modal
          className={styles["modalStyle"]}
          show={this.state.showRevisionImageModal}
          onHide={this.hideRevisionImageModal}
          bsSize={"lg"}
        >
          <RevisionNavigationContainer
            revisionId={this.state.revisionInModal.id}
            revision={{ id: this.state.revisionInModal.id }}
            hide={this.hideRevisionImageModal}
          />
        </Modal>
      </div>
    );
  }
}
