import React from "react";
import PropTypes from "prop-types";
import { Button } from "src/ui-kit";
import { Modal, ModalBody } from "react-bootstrap";
import { Glyphicon } from "src/components/ui_kit";

//components
import { TableComponent } from "src/components/tables/table_component";
import { EditRoleFormContainer } from "src/containers/forms";
import { ViewRoleFormContainer } from "src/containers/forms/role_form_container";

import { modalModes } from "src/constants";

export class AdminRolesTableComponent extends React.PureComponent {
  static propTypes = {
    roles: PropTypes.object.isRequired,
    customRoles: PropTypes.object.isRequired,
    permissionTypes: PropTypes.object.isRequired,
    roleEditEnabled: PropTypes.bool.isRequired
  };
  state = {
    role: {},
    modal: {
      show: false,
      mode: ""
    }
  };

  actionButtonsFormatter = (cell, row) => {
    return (
      <div>
        <Button
          type="button"
          data-toggle="modal"
          onClick={e => this.onShowModal(e, row, modalModes.view)}
          data-target="#modalRoleDetails"
          className="btn-default"
          subtype="button"
        >
          Details
        </Button>
        {row.type == "customRoles" && this.props.roleEditEnabled && (
          <Button
            type="button"
            data-toggle="modal"
            onClick={e => this.onShowModal(e, row, modalModes.edit)}
            data-target="#modalRoleEdit"
            className="btn-default"
            subtype="button"
          >
            <Glyphicon glyph="pencil" className="margin-right5px" />
            Edit
          </Button>
        )}
      </div>
    );
  };

  onShowModal = (e, row, mode) => {
    this.setState({
      ...this.state,
      role: row,
      modal: {
        show: true,
        mode
      }
    });
  };

  onHideModal = (e, row) => {
    this.setState({
      ...this.state,
      role: row,
      modal: {
        show: false,
        mode: ""
      }
    });
  };

  render() {
    const { roles, customRoles } = this.props;

    const rolesObject = { ...roles, ...customRoles };
    const rolesData = Object.values(rolesObject);

    const columns = [
      {
        dataField: "attributes.primaryName",
        text: "Role Name",
        headerStyle: () => {
          return {
            width: "80%"
          };
        }
      },
      {
        dataField: "hidden",
        formatter: this.actionButtonsFormatter,
        text: "Action"
      }
    ];

    return (
      <div>
        <TableComponent data={rolesData} keyField="id" columns={columns} />
        <Modal
          show={this.state.modal.show}
          onHide={this.onHideModal}
          bsSize={"lg"}
        >
          <ModalBody>
            {this.state.modal.mode === modalModes.view && (
              <ViewRoleFormContainer
                role={this.state.role}
                mode={this.state.modal.mode}
              />
            )}
            {this.state.modal.mode === modalModes.edit && (
              <EditRoleFormContainer
                role={this.state.role}
                cancel={this.onHideModal}
                mode={this.state.modal.mode}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
