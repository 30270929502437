import { createSelector } from "reselect";
import get from "lodash.get";

//Constants
import {
  allFacilitiesOptionId,
  locationTypes,
  ruleParameterOperators
} from "src/constants";

//Selectors
import {
  adminRulesSelectedOrganizationIdSelector,
  adminRulesSelectedFacilityIdSelector
} from "src/selectors/section_selectors/admin";

import { facilitiesResourceSelectors } from "src/selectors/data_selectors";

export const createAlertRuleFormInitialValuesSelector = ({
  rule,
  evaluationType
}) => {
  return createSelector(
    adminRulesSelectedOrganizationIdSelector,
    adminRulesSelectedFacilityIdSelector,
    facilitiesResourceSelectors.dataSelector,
    (organizationId, facilityId, allFacilities) => {
      //Initial Values of Alert Rule to be Edited
      if (rule && rule.attributes) {
        let organizationId, facilityId;
        const {
          locationId,
          locationType,
          name,
          ruleTemplateId,
          roleIds,
          ruleParams
        } = rule.attributes;

        //Location Type and Location ID
        if (locationType === locationTypes.organization) {
          facilityId = allFacilitiesOptionId;
          organizationId = locationId;
        } else if (locationType === locationTypes.facility) {
          facilityId = locationId;
          organizationId = get(
            allFacilities,
            `${facilityId}.attributes.organizationId`,
            ""
          );
        }

        //Visible and Hidden Rule Params
        const hiddenRuleParams = ruleParams.filter(field => {
          return field.hidden === true;
        });
        const visibleRuleParams = ruleParams.filter(field => {
          return field.hidden === false;
        });
        const minValueParam = visibleRuleParams.find(
          param => param.operator === ruleParameterOperators.greaterOrEqual
        );
        const maxValueParam = visibleRuleParams.find(
          param => param.operator === ruleParameterOperators.lessOrEqual
        );
        let min, max;
        if (minValueParam && maxValueParam) {
          min = {
            [`${minValueParam.name}-${minValueParam.operator}`]: minValueParam.value
          };
          max = {
            [`${maxValueParam.name}-${maxValueParam.operator}`]: maxValueParam.value
          };
        }

        return {
          evaluationType,
          id: rule.id,
          organizationId,
          facilityId,
          name,
          ruleTemplateId,
          roleIds,
          hiddenRuleParams,
          visibleRuleParams,
          ...min,
          ...max
        };

        //New Alert Rule
      } else {
        return {
          evaluationType,
          organizationId,
          facilityId,
          roleIds: []
        };
      }
    }
  );
};
