import get from "lodash.get";
import i18n from "src/translations";

/**
 * Get the wound type string
 * @param {answersJson} obj
 * @param {localizationDictionary}
 * @returns {string}
 */
export const getWoundNumberAndTypeString = ({
  answersJson,
  patientWoundNumber,
  localizationDictionary
}) => {
  const woundType = get(answersJson, "woundType", null);

  if (!woundType && !patientWoundNumber) return i18n.t("text.undiagnosed");

  const numberString =
    patientWoundNumber == "N/A"
      ? `${patientWoundNumber}`
      : `#${patientWoundNumber}`;

  if (!woundType && patientWoundNumber) {
    return `${numberString} - ${i18n.t("text.undiagnosed")}`;
  }

  let woundTypeString;
  if (!localizationDictionary) woundTypeString = woundType;
  else
    woundTypeString =
      localizationDictionary[`woundType.${woundType}`] || woundType;

  return patientWoundNumber && numberString
    ? `${numberString} - ${woundTypeString}`
    : woundTypeString;
};
