import uuid from "uuid/v1";
import moment from "moment";
import clonedeep from "lodash.clonedeep";

// Constants
import {
  resourceNames,
  exportStates,
  exportReportOptions
} from "src/constants";

// Actions
import { openDownloadManagerAction } from "src/actions/section_actions/global";
import { createResourceActions } from "src/redux_resources_v1";
import { createRemoveResourceByIdAction } from "../create_remove_resource_by_id_action";

export const localExportsResourceActions = createResourceActions(
  resourceNames.localExports
);

export function createLocalExportAction({ exportName, exportType, filters }) {
  let fileName;
  switch (exportName) {
    case exportReportOptions.woundEvaluations:
      const dateFilter = filters.find(f => f.key == "date");
      const date = dateFilter ? moment(dateFilter.value) : moment();
      fileName = `wound_evaluations-${date.format("MMM-DD-YYYY")}`;
  }

  return dispatch => {
    const localExport = {
      type: resourceNames.localExports,
      id: uuid(),
      attributes: {
        exportName,
        exportType,
        filters: clonedeep(filters),
        createdAt: moment().toISOString(),
        state: exportStates.processing,
        fileName: `${fileName}`
      }
    };

    dispatch(
      localExportsResourceActions.populateAction({
        contexts: localExport.id,
        data: {
          [localExport.id]: localExport
        },
        ids: [localExport.id]
      })
    );

    dispatch(openDownloadManagerAction());
  };
}

export function markLocalExportCompletedAction(localExport) {
  return dispatch => {
    dispatch(
      localExportsResourceActions.populateAction({
        contexts: localExport.id,
        data: {
          [localExport.id]: {
            ...localExport,
            attributes: {
              ...localExport.attributes,
              state: exportStates.completed
            }
          }
        },
        ids: [localExport.id]
      })
    );
  };
}

export function markLocalExportFailedAction(localExport) {
  return dispatch => {
    dispatch(
      localExportsResourceActions.populateAction({
        contexts: localExport.id,
        data: {
          [localExport.id]: {
            ...localExport,
            attributes: {
              ...localExport.attributes,
              state: exportStates.failed
            }
          }
        },
        ids: [localExport.id]
      })
    );
  };
}

export const removeLocalExportAction = createRemoveResourceByIdAction({
  resourceName: resourceNames.localExports
});
