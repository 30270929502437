import React from "react";
import PropTypes from "prop-types";

//CSS
import styles from "./style.module.less";

/**
 * React Component that will be used to
 * navigate pages for table that support pagination
 */

export class TableNavigatorComponent extends React.Component {
  static propTypes = {
    pageNumber: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    selectTablePage: PropTypes.func.isRequired
  };

  selectTablePage = pageNumber => {
    this.props.selectTablePage(pageNumber);
  };

  //A max of three page numbers should be shown between arrows starting from selected page.
  getPageButtons = (pageNumber, pageCount) => {
    let buttons = [0];
    if (pageCount > 3) {
      let fromPage, toPage;
      if (pageNumber === pageCount - 1) {
        fromPage = pageNumber - 2;
        toPage = pageNumber;
      } else if (pageNumber + 1 === pageCount - 1) {
        fromPage = pageNumber - 1;
        toPage = pageNumber + 1;
      } else {
        fromPage = pageNumber;
        toPage = pageNumber + 2;
      }
      buttons = [fromPage, fromPage + 1, toPage];
    } else if (pageCount === 3) {
      buttons = [0, 1, 2];
    } else if (pageCount === 2) {
      buttons = [0, 1];
    }
    return buttons;
  };

  render() {
    const { pageNumber, pageCount } = this.props;
    const pageButtons = this.getPageButtons(pageNumber, pageCount);
    return (
      <div className="pull-right">
        <FirstPage selectTablePage={this.selectTablePage} />
        <PreviousPage
          pageNumber={pageNumber}
          selectTablePage={this.selectTablePage}
        />
        {pageButtons.length &&
          pageButtons.map((pageNumber, index) => {
            return (
              <PageButton
                key={index}
                pageNumber={pageNumber}
                selectTablePage={this.selectTablePage}
              />
            );
          })}
        <NextPage
          pageNumber={pageNumber}
          pageCount={pageCount}
          selectTablePage={this.selectTablePage}
        />
        <LastPage
          pageCount={pageCount}
          selectTablePage={this.selectTablePage}
        />
      </div>
    );
  }
}

const FirstPage = ({ ...props }) => {
  const { selectTablePage } = props;
  const arrows = "<<";
  const page = 0;
  return (
    <button
      className={`btn btn-default ${styles["table-navigator-button"]}`}
      onClick={() => selectTablePage(page)}
    >
      {arrows}
    </button>
  );
};

const PreviousPage = ({ ...props }) => {
  const { pageNumber, selectTablePage } = props;
  const arrow = "<";
  const page = pageNumber > 0 ? pageNumber - 1 : 0;
  return (
    <button
      className={`btn btn-default ${styles["table-navigator-button"]}`}
      onClick={() => selectTablePage(page)}
    >
      {arrow}
    </button>
  );
};

const PageButton = ({ ...props }) => {
  const { pageNumber, selectTablePage } = props;
  const showPage = pageNumber + 1;
  return (
    <button
      className={`btn btn-default ${styles["table-navigator-button"]}`}
      onClick={() => selectTablePage(pageNumber)}
    >
      {showPage}
    </button>
  );
};

const NextPage = ({ ...props }) => {
  const { pageNumber, pageCount, selectTablePage } = props;
  const arrow = ">";
  const page = pageNumber === pageCount - 1 ? pageCount - 1 : pageNumber + 1;
  return (
    <button
      className={`btn btn-default ${styles["table-navigator-button"]}`}
      onClick={() => selectTablePage(page)}
    >
      {arrow}
    </button>
  );
};

const LastPage = ({ ...props }) => {
  const { pageCount, selectTablePage } = props;
  const arrows = ">>";
  return (
    <button
      className={`btn btn-default ${styles["table-navigator-button"]}`}
      onClick={() => selectTablePage(pageCount - 1)}
    >
      {arrows}
    </button>
  );
};
