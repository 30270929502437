import { createSelector } from "reselect";
import { surveyWoundDetailsExportSelector } from "../survey_wound_details_export_selector";

/**
 * Load state selector for the survey wound details export functionality
 */
export const surveyWoundDetailsExportLoadStateSelector = createSelector(
  surveyWoundDetailsExportSelector,
  surveyWoundDetailsExport => surveyWoundDetailsExport.loadState
);
