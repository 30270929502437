import { createSelector } from "reselect";

//Payloads Selector
import { allPagesPayloadsForSelectedProgressSelector } from "src/selectors/query_selectors/wounds/all_pages_payloads_for_selected_progress_selector";

//Utils
import { buildQueryString } from "src/utils/build_query_string";

export const allPagesContextsForSelectedProgressSelector = createSelector(
  allPagesPayloadsForSelectedProgressSelector,
  allPagesPayloadsForSelectedProgress =>
    allPagesPayloadsForSelectedProgress.map(payload =>
      buildQueryString(payload)
    )
);
