import { createSelector } from "reselect";

// Selectors
import { partnerNameSelector } from "../partner_name_selector";

/**
 * Selector that determines if the wound type navbar is enabled
 */
export const seriesLinkEnabledSelector = createSelector(
  partnerNameSelector,
  partnerName => {
    if (partnerName == "pcc" || partnerName == "pcc_wound") {
      return false;
    }

    return true;
  }
);
