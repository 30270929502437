import { admissionStatusFormatter } from "./admission_status_formatter";
import { assessmentLockFormatter } from "./assessment_lock_formatter";
import { assessmentLockStatusFormatter } from "./assessment_lock_status_formatter";
import { assessmentTypeFormatter } from "./assessment_type_formatter";
import { bodyLocationFormatter } from "./body_location_formatter";
import { dateFormatter } from "./date_formatter";
import { dateAndTimeFormatter } from "./date_and_time_formatter";
import { lockedFormatter } from "./locked_formatter";
import { patientFirstNameFormatter } from "./patient_first_name_formatter";
import { patientFullNameFormatter } from "./patient_full_name_formatter";
import { patientLastNameFormatter } from "./patient_last_name_formatter";
import { patientLocationNamesFormatter } from "./patient_location_names_formatter";
import { woundNumberTypeAndSubtypeFormatter } from "./wound_number_type_and_subtype_formatter";
import { patientFirstNameLastNameFormatter } from "./patient_first_name_last_name_formatter";
import { signedFormatter } from "./signed_formatter";
import { userFormatter } from "./user_formatter";
import { woundProgressFormatter } from "./wound_progress_formatter";
import { woundThumbnailFormatter } from "./wound_thumbnail_formatter";
import { woundEvaluationHyperlinkFormatter } from "./wound_evaluation_hyperlink_formatter";
import { woundInfoFormatter } from "./wound_info_formatter";
import { mrnFormatter } from "./mrn_formatter";
import { alertRulesDescriptionFormatter } from "./alert_rules_description_formatter";
import { alertRulesAppliesToFormatter } from "./alert_rules_applies_to_formatter";
import { alertRulesRangeFormatter } from "./alert_rules_range_formatter";
import { alertTypeFormatter } from "./alert_type_formatter";
import { daysElapsedFormatter } from "./days_elapsed_formatter";
import { lastEvaluationDateFormatter } from "./last_evaluation_date_formatter";
import { acquiredStatusFormatter } from "./acquired_status_formatter";
import { bradenScoreFormatter } from "./braden_score_formatter";
import { pursScoreFormatter } from "./purs_score_formatter";
import { payerFormatter } from "./payer_formatter";
import { visitFrequencyFormatter } from "./visit_frequency_formatter";
import { woundRiskFormatter } from "./wound_risk_formatter";
import { qaNoteFormatter } from "./qa_note_formatter";

export const cellFormatters = {
  admissionStatusFormatter,
  assessmentLockFormatter,
  assessmentLockStatusFormatter,
  assessmentTypeFormatter,
  bodyLocationFormatter,
  dateFormatter,
  dateAndTimeFormatter,
  lockedFormatter,
  patientFirstNameFormatter,
  patientFullNameFormatter,
  patientLastNameFormatter,
  patientLocationNamesFormatter,
  woundNumberTypeAndSubtypeFormatter,
  patientFirstNameLastNameFormatter,
  signedFormatter,
  userFormatter,
  woundProgressFormatter,
  woundThumbnailFormatter,
  woundEvaluationHyperlinkFormatter,
  woundInfoFormatter,
  mrnFormatter,
  alertRulesDescriptionFormatter,
  alertRulesAppliesToFormatter,
  alertRulesRangeFormatter,
  alertTypeFormatter,
  daysElapsedFormatter,
  lastEvaluationDateFormatter,
  acquiredStatusFormatter,
  bradenScoreFormatter,
  pursScoreFormatter,
  payerFormatter,
  visitFrequencyFormatter,
  woundRiskFormatter,
  qaNoteFormatter
};
