import { createSelector } from "reselect";

// Selectors
import { progressValuesSelector } from "src/selectors/constant_selectors";
import { assessmentsFirstPagePayloadAllProgressValuesSelector } from "src/selectors/query_selectors/wounds";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

export const assessmentsFirstPageContextAllProgressValuesSelector = createSelector(
  progressValuesSelector,
  assessmentsFirstPagePayloadAllProgressValuesSelector,
  (progressValues, payloads) => {
    return progressValues.reduce((agg, progress) => {
      agg[progress] = buildQueryString(payloads[progress]);
      return agg;
    }, {});
  }
);
