import { createSelector } from "reselect";

import { brandingSelector } from "../branding_selector";
import swiftLogo from "src/assets/logos/swift_skin_and_wound_logo.png";
import pccLogo from "src/assets/logos/point_click_care_logo.png";
import medlineLogo from "src/assets/logos/medline_logo.png";

export const brandedLogoPngSelector = createSelector(
  brandingSelector,
  branding => {
    switch (branding) {
      case "pcc":
      case "pcc_wound":
        return pccLogo;
      case "medline":
        return medlineLogo;
      default:
        return swiftLogo;
    }
  }
);
