// @flow
import { generateSchema, relationshipTypes } from "src/utils/schemaUtils";
import { recordInput } from "src/models/LocationModel";
export default generateSchema(
  [
    {
      name: "locations",
      alias: "parent",
      type: relationshipTypes.ONE
    },
    {
      name: "locations",
      alias: "sublocations"
    }
  ],
  { recordInput }
);
