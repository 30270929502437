import { createResourceSelectors } from "src/redux_resources_v1";
import { resourceNames } from "src/constants";

export const rulesResourceSelectors = createResourceSelectors(
  resourceNames.rules
);

export const ruleTemplatesResourceSelector = createResourceSelectors(
  resourceNames.ruleTemplates
);
