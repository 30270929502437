export const sessionActionTypes = {
  loggedIn: "LOGGED_IN",
  setAccessToken: "SESSION_SET_ACCESS_TOKEN",
  clearAccessToken: "SESSION_CLEAR_ACCESS_TOKEN",
  selectOrganizationId: "SELECT_ORGANIZATION_ID",
  setSessionId: "SESSION_SET_ID",
  clearSessionId: "SESSION_CLEAR_ID",
  clearApiHost: "SESSION_CLEAR_API_HOST",
  setUserAuthConfig: "SESSION_SET_USER_AUTH_CONFIG",
  launchFailed: "LAUNCH_FAILED",
  setAuthWorkflow: "SESSION_SET_AUTH_WORKFLOW"
};
