import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ActionsloadRevisionsForSeriesAction
import { loadRevisionsForSeriesAction } from "src/actions/data_actions";

// Selectors
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { visibleEvaluationStatesPermissionSelector } from "src/selectors/config_selectors/visible_evaluation_states_permission";

// Subscription
import Subscriptions from "src/subscriptions";

/**
 * HOC for loading revisions for a series (provided in props)
 * @param {Class} WrappedComponent
 * @returns {Class} React component
 */
export function withRevisionsLoader(WrappedComponent) {
  class RevisionsLoader extends React.PureComponent {
    static propTypes = {
      series: PropTypes.object.isRequired,
      loadState: PropTypes.string,
      assessmentStateFilters: PropTypes.object
    };

    componentDidMount() {
      if (!this.props.loadState) {
        this.props.sendLoadRevisionsForSeriesAction(
          this.props.series.id,
          this.props.assessmentStateFilters.lockState
        );
      }

      this.subscribeToSeriesChanges(
        this.props.series.id,
        this.props.assessmentStateFilters.lockState
      );
    }

    componentWillUnmount() {
      this.unsubscribeFromSeriesChanges(this.props.series.id);
    }

    /**
     * Subscribe to changes for each series
     * @param {array} series
     */
    subscribeToSeriesChanges(seriesId, lockState) {
      Subscriptions.subscribeToResourceChannelEvent({
        channelName:
          Subscriptions.resourceIdChannelNames.seriesAssessmentAnswer,
        resourceId: seriesId,
        eventName: Subscriptions.channelEvents.change,
        callback: this.onResourceChange,
        callbackContext: {
          resourceId: seriesId,
          lockState: lockState,
          loadResource: this.props.sendLoadRevisionsForSeriesAction
        }
      });
    }

    /**
     * Unsubscribe from changes to individual series
     * @param {array} series
     */
    unsubscribeFromSeriesChanges(seriesId) {
      Subscriptions.unsubscribeFromResourceChannelEvent({
        channelName:
          Subscriptions.resourceIdChannelNames.seriesAssessmentAnswer,
        resourceId: seriesId,
        eventName: Subscriptions.channelEvents.change,
        callback: this.onResourceChange
      });
    }

    onResourceChange() {
      this.loadResource(this.resourceId, this.lockState);
    }

    /**
     * Load more revisions for this series
     */
    loadMoreRevisions = () => {
      this.props.sendLoadMoreRevisionsAction(
        this.props.series.id,
        this.props.assessmentStateFilters.lockState
      );
    };

    render() {
      return (
        <WrappedComponent
          loadMoreRevisions={this.loadMoreRevisions}
          {...this.props}
        />
      );
    }
  }

  function mapStateToProps(state, ownProps) {
    const seriesId = ownProps.series.id;
    return {
      loadState: revisionsResourceSelectors.createContextLoadStateSelectorWithContextString(
        seriesId
      )(state),
      assessmentStateFilters: visibleEvaluationStatesPermissionSelector(state)
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      sendLoadRevisionsForSeriesAction(seriesId, lockState) {
        dispatch(loadRevisionsForSeriesAction(seriesId, lockState));
      },
      sendLoadMoreRevisionsAction(seriesId, lockState) {
        dispatch(
          loadRevisionsForSeriesAction(seriesId, lockState, { loadMore: true })
        );
      },
      refreshRevisions(seriesId, lockState) {
        dispatch(
          loadRevisionsForSeriesAction(seriesId, lockState, { refresh: true })
        );
      }
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(RevisionsLoader);
}
