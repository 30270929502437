import React, { PureComponent } from "react";

const sizeOptions = {
  sm: "sm",
  md: "md",
  lg: "lg"
};

export default class Header extends PureComponent {
  render() {
    const { children, size, ...props } = this.props;
    if (size === sizeOptions.sm) {
      return <h3 {...props} children={children} />;
    }
    if (size === sizeOptions.md) {
      <h2 {...props} children={children} />;
    }
    return <h1 {...props} children={children} />;
  }
}
