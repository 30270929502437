import { normalize as normalizr } from "normalizr";
import normalize from "json-api-normalizer";
import { trackError } from "src/error_tracking";
import get from "lodash.get";

import { camelizeKeys } from "humps";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Schema
import { seriesSchema } from "src/schema";

// Api
import { fetchStudySeries } from "src/api/studies";
import { fetchSeries } from "src/api/series";
import { fetchFirstRevisionForSeries } from "src/api/legacy_revisions";

// Actions
import {
  framesResourceActions,
  regionsResourceActions,
  revisionsResourceActions,
  assessmentAnswersResourceActions
} from "../";

// Model
import { mapMeasurementsToValues } from "src/models/revisions";

// Constants
import { loadStates } from "src/constants/load_states";

// Tracking
import { startTimeTrack, finishTimeTrack } from "src/analytics";

const seriesResourceActions = createResourceActions("series");

/**
 * Action creator for loading series for a study from the api
 * @param {string} studyId
 * @param {Object} options
 * @param {boolean} options.loadMore
 * @param {boolean} options.refresh
 */
export function loadSeriesForStudyAction(studyId, lockState, options) {
  return dispatch => {
    let loadState;
    if (!options) {
      loadState = loadStates.loading;
    } else if (options.loadMore) {
      loadState = loadStates.loadingMore;
    } else if (options.refresh) {
      loadState = loadStates.refreshing;
    } else {
      loadState = loadStates.loading;
    }
    startTimeTrack("API_GET_STUDY_SERIES");
    dispatch(
      seriesResourceActions.setLoadStateForContextAction(studyId, loadState)
    );

    return fetchStudySeries(studyId, options && options.loadMore)
      .then(response => {
        // NOTE: The api response with a jsonapi response for series, but of its
        // relations are nested in the attributes field instead of "included" as per
        // the jsonapi spec. To get around this we manually add the id of the series
        // to the attributes field and then use Normalizr to normalize everything.
        // TODO: when the api updates to jsonapi format we should use that.
        const series = camelizeKeys(response.data.data).map(s => {
          return { ...s.attributes, id: s.id };
        });
        finishTimeTrack("API_GET_STUDY_SERIES");
        // Load first revision for each series we loaded
        // TODO: get this added as an include field for series (not supported on api yet)
        const revisionPromises = series.map(s => {
          return fetchFirstRevisionForSeries(s.id, lockState, true);
        });

        return Promise.all(revisionPromises)
          .then(revisonResponses => {
            //Filter out revision responses that do not have data eg. all revisions
            //have been struck out for series and there are no first revision for series
            let filteredRevisionResponses = revisonResponses.filter(
              revisionResponse => {
                const revisionData = get(revisionResponse, "data.data", []);
                return revisionData.length > 0;
              }
            );
            return filteredRevisionResponses;
          })
          .then(revisionResponses => {
            const entities = normalizr(series, [seriesSchema]).entities;

            // parse json blobs on region
            if (entities.regions) {
              entities.regions = Object.keys(entities.regions).reduce(
                (agg, id) => {
                  let region = entities.regions[id];

                  region.measurement = region.measurement
                    ? JSON.parse(region.measurement)
                    : {};

                  region.undermining = region.undermining
                    ? JSON.parse(region.undermining)
                    : {};

                  region.tunneling = region.tunneling
                    ? JSON.parse(region.tunneling)
                    : {};

                  region.polygons = region.polygons
                    ? JSON.parse(region.polygons)
                    : {};

                  region.depth = region.depth ? JSON.parse(region.depth) : {};

                  agg[id] = region;
                  return agg;
                },
                {}
              );
            }

            if (entities.regions) {
              dispatch(
                regionsResourceActions.populateAction({
                  contexts: `studies/${studyId}/series`,
                  data: convertToJsonApi(entities.regions, "regions"),
                  ids: Object.keys(entities.regions)
                })
              );
            }

            if (entities.frames) {
              dispatch(
                framesResourceActions.populateAction({
                  contexts: `studies/${studyId}/series`,
                  data: convertToJsonApi(entities.frames, "frames"),
                  ids: Object.keys(entities.frames)
                })
              );
            }

            if (entities.assessmentAnswers) {
              dispatch(
                assessmentAnswersResourceActions.populateAction({
                  contexts: `studies/${studyId}/series`,
                  data: convertToJsonApi(
                    entities.assessmentAnswers,
                    "assessmentAnswers"
                  ),
                  ids: Object.keys(entities.assessmentAnswers)
                })
              );
            }

            // Store all revisions in the store
            revisionResponses.forEach(revisionResponse => {
              let revisions = normalize(revisionResponse.data).revisions;
              revisions = Object.keys(revisions).reduce((agg, key) => {
                agg[key] = mapMeasurementsToValues(revisions[key]);
                return agg;
              }, {});

              dispatch(
                revisionsResourceActions.populateAction({
                  contexts: "first_in_series",
                  data: revisions,
                  ids: Object.keys(revisions)
                })
              );
            });

            const jsonApiSeries = entities.series
              ? convertToJsonApi(entities.series, "series")
              : {};

            if (options && options.refresh) {
              dispatch(
                seriesResourceActions.replaceAction({
                  contexts: studyId,
                  data: jsonApiSeries,
                  ids: Object.keys(jsonApiSeries),
                  links: response.data.links,
                  meta: camelizeKeys(response.data.meta)
                })
              );
            } else {
              dispatch(
                seriesResourceActions.populateAction({
                  contexts: studyId,
                  data: jsonApiSeries,
                  ids: Object.keys(jsonApiSeries),
                  links: response.data.links,
                  meta: camelizeKeys(response.data.meta)
                })
              );
            }
          });
      })
      .catch(err => {
        if (err.message == "cancel") return;

        dispatch(seriesResourceActions.loadErrorForContextAction(studyId));
        trackError(err);
        finishTimeTrack("API_GET_STUDY_SERIES", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
      });
  };
}

/**
 * Action creator to load a single series (and nested data)
 * @param {string} seriesId - the series id
 * @returns {Function} Thunk action
 */
export function loadSeriesForSeriesIdAction(seriesId, lockState) {
  return dispatch => {
    startTimeTrack("API_GET_SERIES");
    dispatch(seriesResourceActions.loadingForContextAction(seriesId));

    return fetchSeries(seriesId)
      .then(response => {
        finishTimeTrack("API_GET_SERIES");
        // NOTE: The api response with a jsonapi response for series, but of its
        // relations are nested in the attributes field instead of "included" as per
        // the jsonapi spec. To get around this we manually add the id of the series
        // to the attributes field and then use Normalizr to normalize everything.
        // TODO: when the api updates to jsonapi format we should use that.
        let series = camelizeKeys(response.data.data);
        series.attributes = { ...series.attributes, id: series.id };

        // Load first revision for series
        // TODO: get this added as an include field for series (not supported on api yet)
        startTimeTrack("API_GET_SERIES_FIRST_REVISION");
        return fetchFirstRevisionForSeries(series.id, lockState, true).then(
          revisionResponse => {
            finishTimeTrack("API_GET_SERIES_FIRST_REVISION");
            // Store all revisions in the store
            let revisions = normalize(revisionResponse.data).revisions;
            revisions = Object.keys(revisions).reduce((agg, key) => {
              agg[key] = mapMeasurementsToValues(revisions[key]);
              return agg;
            }, {});

            dispatch(
              revisionsResourceActions.populateAction({
                contexts: "first_in_series",
                data: revisions,
                ids: Object.keys(revisions)
              })
            );

            const entities = normalizr(series, [seriesSchema]).entities;

            // parse json blobs on region
            if (entities.regions) {
              entities.regions = Object.keys(entities.regions).reduce(
                (agg, id) => {
                  let region = entities.regions[id];

                  region.measurement = region.measurement
                    ? JSON.parse(region.measurement)
                    : {};

                  region.undermining = region.undermining
                    ? JSON.parse(region.undermining)
                    : {};

                  region.tunneling = region.tunneling
                    ? JSON.parse(region.tunneling)
                    : {};

                  region.polygons = region.polygons
                    ? JSON.parse(region.polygons)
                    : {};

                  region.depth = region.depth ? JSON.parse(region.depth) : {};

                  agg[id] = region;
                  return agg;
                },
                {}
              );

              dispatch(
                regionsResourceActions.populateAction({
                  data: convertToJsonApi(entities.regions, "regions")
                })
              );
            }

            if (entities.frames) {
              dispatch(
                framesResourceActions.populateAction({
                  data: convertToJsonApi(entities.frames, "frames")
                })
              );
            }

            if (entities.assessmentAnswers) {
              dispatch(
                assessmentAnswersResourceActions.populateAction({
                  data: convertToJsonApi(
                    entities.assessmentAnswers,
                    "assessmentAnswers"
                  )
                })
              );
            }

            const jsonApiSeries = convertToJsonApi(entities.series, "series");
            dispatch(
              seriesResourceActions.populateAction({
                contexts: seriesId,
                data: jsonApiSeries,
                ids: Object.keys(series),
                links: response.data.links,
                meta: camelizeKeys(response.data.meta)
              })
            );
          }
        );
      })
      .catch(err => {
        trackError(err);
        dispatch(seriesResourceActions.loadErrorForContextAction(seriesId));
        finishTimeTrack("API_GET_SERIES", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
      });
  };
}

function convertToJsonApi(normalizedResource, type) {
  return Object.values(normalizedResource).reduce((acc, resource) => {
    acc[resource.id] = {
      id: resource.id,
      type,
      attributes: {
        ...resource
      }
    };

    delete acc[resource.id].attributes.id;

    return acc;
  }, {});
}
