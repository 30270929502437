import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { assessmentsResourceSelectors } from "../assessments_resource_selectors";
import { createLatestAssessmentAnswerForRevisionSelector } from "src/selectors/data_selectors";

/**
 * Create a selector that gets the assessment for the latest assessmentAnswer for a revision
 * @param {Object} revision
 * @returns {Function} selector
 */
export function createLatestAssessmentForRevisionSelector(revision) {
  return createSelector(
    createLatestAssessmentAnswerForRevisionSelector(revision),
    assessmentsResourceSelectors.dataSelector,
    (assessmentAnswer, assessments) => {
      const assessmentId = get(
        assessmentAnswer,
        "attributes.assessmentId",
        null
      );
      return assessments[assessmentId];
    }
  );
}
