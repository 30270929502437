import {
  disableFilters,
  enableFilters
} from "src/pages/compliance/helpers/filterHelpers";

export function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "DISABLE_FILTERS":
      const filtersAfterDisabling = {
        ...state.filters,
        [payload.filterKey]: disableFilters(
          state.filters[payload.filterKey],
          payload.filterIds
        )
      };
      return { ...state, filters: filtersAfterDisabling };
    case "ENABLE_FILTERS":
      const filtersAfterEnabling = {
        ...state.filters,
        [payload.filterKey]: enableFilters(
          state.filters[payload.filterKey],
          payload.filterIds
        )
      };
      return { ...state, filters: filtersAfterEnabling };
    case "OVERRIDE_FILTERS":
      const newFilters = {
        ...state.filters,
        [payload.filterKey]: payload.filterIds
      };
      return { ...state, filters: newFilters };
    case "SET_ORGANIZATIONS":
      const { organizations } = payload;
      return { ...state, organizations };
    case "selectLoc":
      return { ...state, selectedLocationId: payload.id };
    case "selectPageLimit":
      return { ...state, pageLimit: payload.pageLimit };
    case "selectPageNumber":
      return { ...state, pageNumber: action.payload.pageNumber };
    case "enterPatientOrMrnValue":
      return { ...state, nameOrMrnOrCode: action.payload.nameOrMrnOrCode };
    case "selectTab":
      return { ...state, selectedTab: action.payload.selectedTab };
    case "selectUnitDateRange":
      return {
        ...state,
        unitRange: payload.unitRange,
        startDate: payload.startDate,
        endDate: payload.endDate
      };
    case "selectStartDate":
      return {
        ...state,
        unitRange: "custom",
        startDate: payload.startDate
      };
    case "selectEndDate":
      return { ...state, unitRange: "custom", endDate: payload.endDate };
    case "enterPatientOrMrnValue":
      return { ...state, nameOrMrnOrCode: payload.nameOrMrnOrCode };
    case "selectTab":
      return { ...state, selectedTab: payload.selectedTab };
    case "setCheckedAssessmentSummaries":
      return {
        ...state,
        checkedAssessmentSummaries: payload.checkedAssessmentSummaries
      };
    case "setSchemaLocalizationDictionary":
      return {
        ...state,
        schemaLocalizationDictionary: payload.schemaLocalizationDictionary
      };
    default:
      state;
  }
}
