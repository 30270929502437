import React from "react";
import i18n from "src/translations";
import createFlashMessage from "app/flash_messages/create";
import fetch from "isomorphic-fetch";
import get from "lodash.get";
import { push } from "react-router-redux";
import { Redirect } from "react-router";

import SwiftLogoComponent from "src/components/logo_component";
import { GridRow, GridColumn } from "src/components/grid";
import { FormInputComponent } from "src/components/forms/form_input_component";
import { FormComponent } from "src/components/forms/form_component_legacy";
import { getCookieValueForKey } from "src/client_storage";
import { ssoMode } from "src/constants";

import styles from "./style.module.less";

export class UserAuthCodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.wrappedHandleSubmit = props.handleSubmit(this.submitForm);
    this.state = { loginPopOpen: true, fetchedUserAuth: false };
    this.formRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.orgCode && !this.state.fetchedUserAuth) {
      this.setState(prev => ({ ...prev, fetchedUserAuth: true }));
      this.formRef.current.submit();
    }
    const { dispatch } = this.props;
    if (!this.state.loginPopOpen && getCookieValueForKey("accessToken")) {
      dispatch(push(this.props.defaultNavigationPage));
      window.location.reload();
    } else if (!this.state.loginPopOpen) {
      dispatch(
        createFlashMessage("Unable to Sign in, Please try again", "error")
      );
      this.setState({ loginPopOpen: true });
    }
  }

  submitForm = async formValue => {
    const { dispatch } = this.props;
    const authConfigResponse = await this.fetchUserAuth(formValue);
    const respStatus = get(authConfigResponse, "status", null);
    if (respStatus == 200) {
      const respBody = await authConfigResponse.json();
      this.props.setAuthConfig(respBody);
      const url = respBody.launchUrl;
      const params = new URLSearchParams(url);
      const ssomode = params.get("ssomode");
      localStorage.setItem("ssomode", ssomode);
      if (ssomode === ssoMode.redirect) {
        this.launchRedirect(url);
      } else {
        await this.launchPopUp(url);
        this.setState({ loginPopOpen: false });
      }
    } else {
      let errMsg = "Error requesting Auth Configs";
      if (authConfigResponse) {
        errMsg = get(await authConfigResponse.json(), "message");
      }
      dispatch(createFlashMessage(errMsg, "error"));
    }
  };

  launchPopUp = url => {
    const POPUP_WIDTH = 500;
    const POPUP_HEIGHT = 526;
    const left = window.screen.width / 2 - POPUP_WIDTH / 2,
      top = window.screen.height / 2 - POPUP_HEIGHT / 2;
    const popUpWindow = window.open(
      url,
      "Partner Login",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        POPUP_WIDTH +
        ", height=" +
        POPUP_HEIGHT +
        ", top=" +
        top +
        ", left=" +
        left
    );

    return new Promise(resolve => {
      const checkWindow = loginWindow => {
        // If window is still open check again later
        if (!loginWindow.closed) {
          setTimeout(() => checkWindow(loginWindow), 100);
          return;
        }

        resolve("logged In");
      };

      checkWindow(popUpWindow);
    });
  };

  launchRedirect = url => {
    window.open(url, "_self");
  };

  fetchUserAuth = async formValue => {
    try {
      const authHost = window.AUTH_HOST || process.env.REACT_APP_AUTH_HOST;
      const authConfigCodePath = "/auth-config-by-code";
      const authConfigUrl = `${authHost}${authConfigCodePath}?user_auth_code=${formValue.userAuthCode}`;
      const asyncAuthConfigResponse = await fetch(authConfigUrl, {
        method: "GET"
      });
      return asyncAuthConfigResponse;
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    if (getCookieValueForKey("accessToken")) {
      return <Redirect to={this.props.defaultNavigationPage} />;
    }

    return (
      <div className={styles["min-height"]}>
        <div className={`text-center ${styles["margin-bottom10px"]}`}>
          <div className={styles["logo"]}>
            <SwiftLogoComponent />
          </div>
        </div>
        <GridRow className={styles["margin-bottom10px"]}>
          <GridColumn sm="4" className="col-sm-offset-4">
            <FormComponent
              ref={this.formRef}
              form="userAuthCodeForm"
              initialValues={{ userAuthCode: this.props.orgCode }}
              onSubmit={this.wrappedHandleSubmit}
            >
              <FormInputComponent
                name="userAuthCode"
                component="input"
                placeholder="Enter Org Code here"
              />
              <div className="text-center margin-top-10px">
                <button className="btn btn-default" type="submit">
                  {i18n.t("actions.signIn")}
                </button>
              </div>
            </FormComponent>
          </GridColumn>
        </GridRow>
        <br />
      </div>
    );
  }
}
