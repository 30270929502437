import { createSelector } from "reselect";

// Constants
import {
  sections,
  woundTypeKeyForAttribute,
  woundTypeKeyForSubtype,
  subtypeKeyValueForAttribute
} from "src/constants";

// Selectors
import { woundTypesConfigurationFilterForSchemaPolicySelector } from "src/selectors/ui_config_selectors/global/wound_type_filter_configuration_selector";
import { createSectionSelectors } from "src/ui_processors";
const sectionSelectors = createSectionSelectors(
  sections.evaluationListTotalSkin
);

/**
 * Answers filter
 */
export const answersFilterSelector = createSelector(
  woundTypesConfigurationFilterForSchemaPolicySelector,
  sectionSelectors.filtersSelector,
  (woundTypesConfig, filters) => {
    const woundTypesFilter = filters.find(filter => filter.key == "woundTypes");

    if (woundTypesFilter && woundTypesFilter.value.length) {
      return woundTypesFilter.value.map(filter => {
        let woundOption = {
          [filter.key]: filter.value
        };

        // add wound type for attributes
        if (woundTypeKeyForAttribute[filter.key]) {
          woundOption.woundType = woundTypeKeyForAttribute[filter.key];
        }

        // add wound type for subtypes
        if (woundTypeKeyForSubtype[filter.key]) {
          woundOption.woundType = woundTypeKeyForSubtype[filter.key];
        }

        // Add "subtypes" for attributes of subtypes
        if (subtypeKeyValueForAttribute[filter.key]) {
          const subtypeKey = subtypeKeyValueForAttribute[filter.key].key;
          const subtypeValue = subtypeKeyValueForAttribute[filter.key].value;
          woundOption[subtypeKey] = subtypeValue;
        }
        return woundOption;
      });
    } else {
      return woundTypesConfig.filterValues.map(configFilterObject => {
        return {
          assessment_type: "full_body_skin",
          [configFilterObject.key]: configFilterObject.value
        };
      });
    }
  }
);
