import React from "react";
import PropTypes from "prop-types";

// styles
import loadCardStyles from "./style.module.less";
import cardStyles from "../style.module.less";

export class LoadMoreCardComponent extends React.PureComponent {
  static propTypes = {
    remainingCount: PropTypes.number.isRequired,
    handleLoadMore: PropTypes.func.isRequired
  };

  render() {
    const { remainingCount, handleLoadMore } = this.props;

    return (
      <div
        className={`${cardStyles["card"]} ${cardStyles["card-border"]} ${
          loadCardStyles["load-more-card"]
        }`}
      >
        <button
          className={`btn btn-block btn-link ${
            loadCardStyles["view-more-button"]
          }`}
          type="button"
          onClick={handleLoadMore}
        >
          <span className={loadCardStyles["view-more-plus-label"]}>
            +{remainingCount}
          </span>
          View more
        </button>
      </div>
    );
  }
}
