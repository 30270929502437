import { createSelector } from "reselect";

// Constants
import { sections } from "src/constants";

// Selectors
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";
import { woundTypesConfigurationFilterForSchemaPolicySelector } from "src/selectors/ui_config_selectors/global/wound_type_filter_configuration_selector";
import { localizationDictionaryForAllSchemasSelector } from "src/selectors/section_selectors/global/schema_localization_dictionary_selectors";

import { createSectionSelectors } from "src/ui_processors";
const sectionSelectors = createSectionSelectors(sections.survey);

/**
 * Selected wound types for the Survey tab
 */
export const selectedWoundTypesSelector = createSelector(
  sectionSelectors.filtersSelector,
  subtypeKeysForAllSchemasSelector,
  woundTypesConfigurationFilterForSchemaPolicySelector,
  localizationDictionaryForAllSchemasSelector,
  (
    filters,
    subtypeKeysForSchema,
    filterConfig,
    schemaLocalizationDictionary
  ) => {
    let selectedWoundTypes = [];

    // Find the compound filter for wound types
    const woundTypeFilters = filters.find(filter => filter.key == "woundTypes");
    if (!woundTypeFilters) {
      // none selected defaults to all
      const woundFilterHierarchy = filterConfig.filterValues;
      const woundTypes = woundFilterHierarchy.map(woundFilterObject => {
        return {
          woundType: woundFilterObject.value,
          ...(woundFilterObject.children && {
            subWoundType: woundFilterObject.children.map(subtypeObject => {
              return subtypeObject.value;
            })
          })
        };
      });
      selectedWoundTypes = [...woundTypes, { woundType: "undiagnosed" }];
    } else {
      // Add wound types to the selected wound types
      selectedWoundTypes = woundTypeFilters.value
        .filter(filter => filter.key == "woundType")
        .map(filter => {
          return { woundType: filter.value };
        });

      // Add subtypes and missing wound types for those subtypes
      Object.keys(subtypeKeysForSchema).forEach(woundTypeKey => {
        const subTypeFilters = woundTypeFilters.value
          .filter(filter => filter.key == subtypeKeysForSchema[woundTypeKey])
          .sort(function(valueA, valueB) {
            if (valueA.value == "notSet") {
              return 1;
            } else if (valueB.value == "notSet") {
              return -1;
            }
            return (
              Object.keys(subtypeKeysForSchema[woundTypeKey]).indexOf(
                valueA.value
              ) -
              Object.keys(subtypeKeysForSchema[woundTypeKey]).indexOf(
                valueB.value
              )
            );
          });

        if (subTypeFilters.length) {
          let selectedWoundType = selectedWoundTypes.find(
            wt => wt.woundType == woundTypeKey
          );

          if (selectedWoundType) {
            selectedWoundType.subWoundType = subTypeFilters.map(
              filter => filter.value
            );
          } else {
            selectedWoundTypes.push({
              woundType: woundTypeKey,
              subWoundType: subTypeFilters.map(filter => filter.value)
            });
          }
        }
      });
    }

    // Sort wound types
    const sortedVal = selectedWoundTypes.sort((valueA, valueB) => {
      const sortedValueA =
        valueA.woundType &&
        schemaLocalizationDictionary[`woundType.${valueA.woundType}`]
          ? schemaLocalizationDictionary[
              `woundType.${valueA.woundType}`
            ].toLowerCase()
          : "undiagnosed";

      const sortedValueB =
        valueB.woundType &&
        schemaLocalizationDictionary[`woundType.${valueB.woundType}`]
          ? schemaLocalizationDictionary[
              `woundType.${valueB.woundType}`
            ].toLowerCase()
          : "undiagnosed";

      if (sortedValueA == "undiagnosed") return 1;
      else if (sortedValueB == "undiagnosed") return -1;
      else if (sortedValueA < sortedValueB) return -1;
      else if (sortedValueA > sortedValueB) return 1;
      else return 0;
    });

    return sortedVal;
  }
);
