// @flow
import { Record } from "immutable";
import { convertInput } from "src/utils/modelUtils";
import GenericRiskModel from "./GenericModel";

export const recordInput = {
  id: "",
  braden: new GenericRiskModel(),
  nortonPlus: new GenericRiskModel()
};

export default class RiskModel extends Record(recordInput) {
  constructor(opts: Object = {}) {
    super(
      convertInput(
        {
          braden: ent => new GenericRiskModel(ent),
          nortonPlus: ent => new GenericRiskModel(ent)
        },
        opts
      )
    );
  }
}
