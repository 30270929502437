import { createSelector } from "reselect";

//Selectors
import { calculateWoundsProgressChartValues } from "src/utils/calculate_wounds_progress_chart_values";
import { brandingSelector } from "src/selectors/ui_config_selectors/global";
import { createWoundAssessmentProgressLineChart } from "src/charts";
import { sortedWoundsClinicalChartDataSelector } from "../sorted_wounds_clinical_chart_data";
import { assessmentVersionSelector } from "src/selectors/config_selectors";

export const woundsClinicalChartDefinitionSelector = createSelector(
  sortedWoundsClinicalChartDataSelector,
  brandingSelector,
  assessmentVersionSelector,
  (sortedWoundsClinicalData, brandingStyle, assessmentVersion) => {
    const chartData = calculateWoundsProgressChartValues(
      sortedWoundsClinicalData,
      "endTime",
      "woundOverallProgress",
      "woundOverallProgressCount"
    );

    return createWoundAssessmentProgressLineChart(
      chartData,
      brandingStyle,
      assessmentVersion
    );
  }
);
