import { connect } from "react-redux";
import { initialize } from "redux-form";
import flowRight from "lodash.flowright";

//HOCs
import { withResourceLoaderFactory } from "src/hoc/factories";

//Components
import { AdminUsersComponent } from "src/components/admin_users_component";

//Selectors
import {
  usersResourceSelectors,
  rolesResourceSelectors,
  customRolesResourceSelectors,
  permissionTypesResourceSelectors,
  unitsResourceSelectors,
  facilitiesResourceSelectors
} from "src/selectors/data_selectors";
import { adminBulkImportUsersEnabledSelector } from "src/selectors/config_selectors";
import { organizationsResourceSelectors } from "src/selectors/data_selectors/organizations";
import {
  usersCsvExportEnabledSelector,
  userCreateEnabledSelector
} from "src/selectors/ui_config_selectors/admin";

import { usersCsvExportPostPayloadSelector } from "src/selectors/api_selectors/admin/csv_exports";

////Users
import { createUsersTableContextSelector } from "src/selectors/api_selectors/admin/users/create_users_table_context_selector";
import { createUsersTablePayloadSelector } from "src/selectors/api_selectors/admin/users/create_users_table_payload_selector";
////Custom Roles
import { createAdminUsersCustomRolesContextSelector } from "src/selectors/api_selectors/admin/custom_roles";
////Roles
import { createAdminUsersRolesContextSelector } from "src/selectors/api_selectors/admin/roles";
////Permission Types
import { createAdminPermissionTypesContextSelector } from "src/selectors/api_selectors/admin/permission_types";
////Facilities
import {
  createAdminFacilitiesContextSelector,
  createAdminFacilitiesQuerySelector
} from "src/selectors/api_selectors/admin/facilities";
////Units
import {
  createAdminUnitsContextSelector,
  createAdminUnitsQuerySelector
} from "src/selectors/api_selectors/admin/units";

//Actions
import { postCsvExportAction } from "src/actions/data_actions/csv_exports";
import {
  loadCustomRolesAction,
  loadRolesAction,
  loadUsersAction,
  loadPermissionTypesAction,
  loadUnitsAction,
  loadFacilitiesAction
} from "src/actions/data_actions";

function mapStateToProps(state) {
  const context = createUsersTableContextSelector()(state);
  return {
    usersLoadState: usersResourceSelectors.createContextLoadStateSelectorWithContextString(
      context
    )(state),
    rolesLoadState: rolesResourceSelectors.createContextLoadStateSelectorWithContextString(
      "adminUsersRoles"
    )(state),
    customRolesLoadState: customRolesResourceSelectors.createContextLoadStateSelectorWithContextString(
      "adminUsersCustomRoles"
    )(state),
    roles: rolesResourceSelectors.dataSelector(state),
    customRoles: customRolesResourceSelectors.dataSelector(state),
    users: usersResourceSelectors.dataSelector(state),

    bulkImportUsersEnabled: adminBulkImportUsersEnabledSelector(state),
    permissionTypes: permissionTypesResourceSelectors.dataSelector(state),
    permissionLoadState: permissionTypesResourceSelectors.createContextLoadStateSelectorWithContextString(
      "adminPermissionTypes"
    )(state),
    organizations: organizationsResourceSelectors.dataSelector(state),
    usersCsvExportEnabled: usersCsvExportEnabledSelector(state),
    usersCsvPostPayload: usersCsvExportPostPayloadSelector(state),
    userCreateEnabled: userCreateEnabledSelector(state),
    roleCreateEnabled: userCreateEnabledSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetForm: (formName, initializeObject) => {
      dispatch(initialize(formName, initializeObject));
    },
    sendPostUsersCsvExportAction: payload => {
      dispatch(postCsvExportAction({ payload }));
    }
  };
}

export const AdminUsersContainer = flowRight(
  withResourceLoaderFactory({
    getResourceAction: loadUsersAction,
    resourceSelectors: usersResourceSelectors,
    createContextSelector: createUsersTableContextSelector,
    createPayloadSelector: createUsersTablePayloadSelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadRolesAction,
    resourceSelectors: rolesResourceSelectors,
    createContextSelector: createAdminUsersRolesContextSelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadCustomRolesAction,
    resourceSelectors: customRolesResourceSelectors,
    createContextSelector: createAdminUsersCustomRolesContextSelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadUnitsAction,
    resourceSelectors: unitsResourceSelectors,
    createContextSelector: createAdminUnitsContextSelector,
    createQuerySelector: createAdminUnitsQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadFacilitiesAction,
    resourceSelectors: facilitiesResourceSelectors,
    createContextSelector: createAdminFacilitiesContextSelector,
    createQuerySelector: createAdminFacilitiesQuerySelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadPermissionTypesAction,
    resourceSelectors: permissionTypesResourceSelectors,
    createContextSelector: createAdminPermissionTypesContextSelector
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AdminUsersComponent);
