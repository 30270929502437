// React
import { connect } from "react-redux";

// selectors
import {
  talliesByWoundTypeSelector,
  talliesByLocationSelector,
  excludeNewWoundsSelector,
  createShowAcquiredUnknownSelector
} from "src/selectors/section_selectors/survey";

// Components
import { SurveyTallyTableComponent } from "src/components/tables";

// Constants
import { tallyTypes } from "src/constants";

// -------------- Prop & Component Connection -------------- //
function mapStateToProps(state, ownProps) {
  const commonProps = {
    excludeNewWounds: excludeNewWoundsSelector(state),
    showAcquiredUnknown: createShowAcquiredUnknownSelector(ownProps.tallyType)(
      state
    )
  };

  if (ownProps.tallyType == tallyTypes.etiology) {
    return {
      tallies: talliesByWoundTypeSelector(state),
      ...commonProps
    };
  } else {
    return {
      tallies: talliesByLocationSelector(state),
      ...commonProps
    };
  }
}

export const SurveyTallyTableContainer = connect(mapStateToProps)(
  SurveyTallyTableComponent
);
