export const overallProgressConfiguration = {
  filterName: "overallProgress",
  filterValues: [
    {
      key: "new",
      value: "new"
    },
    {
      key: "better",
      value: "better"
    },
    {
      key: "stable",
      value: "stable"
    },
    {
      key: "stalled",
      value: "stalled"
    },
    {
      key: "worse",
      value: "worse"
    },
    {
      key: "healed",
      value: "healed"
    },
    {
      key: "monitoring",
      value: "monitoring"
    },
    {
      key: "unknown",
      value: "unknown"
    }
  ]
};
