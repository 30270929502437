import { dataActionTypes } from "src/action_types/data_action_types";

/**
 * Reducer for the policy model in the data state tree
 */
export function policiesReducer(state = {}, action) {
  switch (action.type) {
    case dataActionTypes.populatePolicies:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
import { createResourceReducer } from "src/redux_resources_v1";

export const policiesResourceReducer = createResourceReducer("policies");
