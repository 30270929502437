export const populationActionTypes = {
  selectLocations: "POPULATION_SELECT_LOCATIONS",
  deselectLocations: "POPULATION_DESELECT_LOCATIONS",

  selectWoundOptions: "POPULATION_WOUNDS_SELECT_OPTIONS",
  deselectWoundOptions: "POPULATION_WOUNDS_DESELECT_OPTIONS",

  setDateSelection: "POPULATION_SET_DATE_SELECTION",
  setRangeSelection: "POPULATION_SET_RANGE_SELECTION",
  setAcquiredFilter: "POPULATION_SET_ACQUIRED_FILTER"
};
