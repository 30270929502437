import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import seriesActions from "@sw/actions/seriesActions";
import seriesTrendSelectors from "@sw/selectors/seriesTrendSelectors";
import { Container, Row, Column } from "@sw/ui-kit";

import ClosureChart from "src/containers/trends/ClosureChart";
import HealingPerformanceChart from "src/containers/trends/HealingPerformanceChart";
import PUSHChart from "src/containers/trends/PUSHChart";
import StageTrendChart from "src/containers/trends/StageTrendChart";
import HprChart from "src/containers/trends/HprChart";
import BwatChart from "src/containers/trends/BwatChart";

// Selectors
import {
  hprScoreEnabledSelector,
  bwatScoreEnabledSelector
} from "src/selectors/ui_config_selectors/global";

// Components
import { GridRow, GridColumn } from "src/components/grid";

/**
 * Component that renders the charts on the right side of the SeriesDetailsComponent
 */
export class SeriesAnalytics extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    seriesTrend: PropTypes.object.isRequired,
    loadTrends: PropTypes.func.isRequired
  };

  componentWillMount() {
    const { loadTrends, id } = this.props;
    loadTrends(id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.props.loadTrends(nextProps.id);
    }
  }

  render() {
    const {
      woundType,
      seriesTrend,
      hprScoreEnabled,
      bwatScoreEnabled
    } = this.props;
    const { closureTrend, hprTrend, bwatTrend } = seriesTrend;
    const isPressureUlcer =
      woundType === "pressureUlcer" ||
      woundType === "pressureKennedy" ||
      woundType === "pressureDeviceRelated";

    if (isPressureUlcer) {
      return this.renderPressureUlcer();
    } else if (hprScoreEnabled) {
      return (
        <GridRow>
          <GridColumn xs="6">
            <div className="bdsm-chart-title">% Changed</div>
            <ClosureChart data={closureTrend} />
          </GridColumn>
          <GridColumn xs="6">
            <div className="bdsm-chart-title">HPR</div>
            <HprChart data={hprTrend} />
          </GridColumn>
        </GridRow>
      );
    } else if (bwatScoreEnabled) {
      return (
        <GridRow>
          <GridColumn xs="6">
            <div className="bdsm-chart-title">% Changed</div>
            <ClosureChart data={closureTrend} />
          </GridColumn>
          <GridColumn xs="6">
            <div className="bdsm-chart-title">BWAT</div>
            <BwatChart data={bwatTrend} />
          </GridColumn>
        </GridRow>
      );
    } else {
      return (
        <GridRow>
          <GridColumn xs="12">
            <div className="bdsm-chart-title">% Changed</div>
            <ClosureChart data={closureTrend} />
          </GridColumn>
        </GridRow>
      );
    }
  }

  renderPressureUlcer = () => {
    const { seriesTrend, hprScoreEnabled, bwatScoreEnabled } = this.props;
    const {
      closureTrend,
      pushTrend,
      stageTrend,
      healingPerformance,
      hprTrend,
      bwatTrend
    } = seriesTrend;

    let hpChart;
    if (hprScoreEnabled) {
      hpChart = (
        <Column size={6} centre>
          <div className="bdsm-chart-title">HPR</div>
          <div>
            <HprChart data={hprTrend} />
          </div>
        </Column>
      );
    } else if (bwatScoreEnabled) {
      hpChart = (
        <Column size={6} centre>
          <div className="bdsm-chart-title">BWAT</div>
          <div>
            <BwatChart data={bwatTrend} />
          </div>
        </Column>
      );
    } else {
      hpChart = (
        <Column size={6} centre>
          <div className="bdsm-chart-title">Healing Perf</div>
          <div>
            <HealingPerformanceChart data={healingPerformance} />
          </div>
        </Column>
      );
    }

    return (
      <Container className="analytics">
        <Row>
          <Column centre size={6}>
            <div className="bdsm-chart-title">% Changed</div>
            <div>
              <ClosureChart data={closureTrend} />
            </div>
          </Column>
          {hpChart}
        </Row>

        <Row>
          {!bwatScoreEnabled && (
            <Column centre size={6}>
              <div className="bdsm-chart-title">PUSH</div>
              <PUSHChart data={pushTrend} />
            </Column>
          )}

          <Column centre size={6}>
            <div className="bdsm-chart-title">Stage</div>
            <StageTrendChart data={stageTrend} />
          </Column>
        </Row>
      </Container>
    );
  };
}

function mapStateToProps(state, ownProps) {
  const idSelector = () => ownProps.id;
  const structuredSelectors = createStructuredSelector({
    seriesTrend: seriesTrendSelectors.findEntity(idSelector)
  });

  return {
    ...structuredSelectors(state),
    hprScoreEnabled: hprScoreEnabledSelector(state),
    bwatScoreEnabled: bwatScoreEnabledSelector(state)
  };
}

const mapDispatchToProps = function(dispatch) {
  return {
    loadTrends(id) {
      dispatch(seriesActions.loadTrendsBySeries(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SeriesAnalytics);
