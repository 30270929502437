import { createSelector } from "reselect";
import { adminSectionSelector } from "../admin_section_selector";

/**
 * Selector to select the selected Patient Table Page Limit
 */

export const selectedPatientTablePageLimitSelector = createSelector(
  adminSectionSelector,
  adminSection => {
    return adminSection.selectedPatientTablePageLimit;
  }
);
