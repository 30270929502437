import React from "react";
import { Link } from "react-router-dom";
import get from "lodash.get";
import { woundEvaluationHyperlinkFormatter } from "../wound_evaluation_hyperlink_formatter";

// Translations
import i18n from "src/translations";

// Constants
import { assessmentTypes } from "src/constants";

/**
 * cell formatter for wound progress
 * @param {object} row
 * @param {Object} row.attributes
 * @param {string} row.attributes.type the assessment type
 * @param {Object} row.attributes.answersJson the answers json
 */
export function woundProgressFormatter(cell, row, rowIndex, formatExtraData) {
  const { assessmentVersion } = formatExtraData;
  let progress;
  if (get(row, "attributes.type") == assessmentTypes.wound) {
    progress = get(row, "attributes.answersJson.overallProgress", "unknown");
    progress = i18n.t(`data.overallProgress.${assessmentVersion}.${progress}`);
    return (
      <div onClick={e => formatExtraData.eventHandler(e, row)}>
        <Link to={location.pathname}>{progress}</Link>
      </div>
    );
  }

  return woundEvaluationHyperlinkFormatter("N/A", row, formatExtraData);
}
