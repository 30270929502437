import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import i18n from "src/translations";

// Constants
import { resourceNames, loadStates, exportStates } from "src/constants";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

// Tracking
import { trackError } from "src/error_tracking";

// Styles
import styles from "./style.module.less";

export class DownloadManagerListItemComponent extends React.PureComponent {
  static propTypes = {
    fileExport: PropTypes.object.isRequired,
    removeItem: PropTypes.func.isRequired,

    // Local Export specific
    loadingObjectsByContext: PropTypes.object,
    markLocalExportCompleted: PropTypes.func,
    markLocalExportFailed: PropTypes.func,
    sendGenerateFileAndDownloadAction: PropTypes.func,
    showDownloadError: PropTypes.func
  };

  state = {
    downloading: false
  };

  constructor(props) {
    super(props);

    if (props.fileExport.type == resourceNames.localExports) {
      if (
        Object.values(props.loadingObjectsByContext).every(
          loadingObject => loadingObject.loadState == loadStates.loaded
        )
      ) {
        props.markLocalExportCompleted(props.fileExport);
      } else if (
        Object.values(props.loadingObjectsByContext).some(
          loadingObject => loadingObject.loadState == loadStates.error
        )
      ) {
        // TODO:
        // if errors out here - network requests for the required data failed
        // need a retry workflow with an icon to retry
        // use the loadingObject retry functions
        props.markLocalExportFailed(props.fileExport);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.fileExport.type == resourceNames.localExports &&
      propsAreDifferent(prevProps, this.props, "loadingObjectsByContext")
    ) {
      if (
        Object.values(this.props.loadingObjectsByContext).every(
          loadingObject => loadingObject.loadState == loadStates.loaded
        )
      ) {
        this.props.markLocalExportCompleted(this.props.fileExport);
      } else if (
        Object.values(this.props.loadingObjectsByContext).some(
          loadingObject => loadingObject.loadState == loadStates.error
        )
      ) {
        // TODO:
        // if errors out here - network requests for the required data failed
        // need a retry workflow with an icon to retry
        // use the loadingObject retry functions
        this.props.markLocalExportFailed(this.props.fileExport);
      } else if (
        Object.values(this.props.loadingObjectsByContext).some(
          loadingObject =>
            loadingObject.loadState == undefined && loadingObject.context == ""
        )
      ) {
        // covers the case where there are no evaluations for filter options
        // ex: date, evaluation status, location, etc
        this.props.markLocalExportFailed(this.props.fileExport);
      }
    }
  }

  onDownloadClicked = () => {
    if (this.state.downloading) return;

    this.setState({
      downloading: true
    });

    this.props
      .generateFileDefinition()
      .then(fileDefinition => {
        this.props.sendDownloadAction(fileDefinition, this.props.fileExport);
        this.setState({
          downloading: false
        });
      })
      .catch(err => {
        // track file generation error
        // if it errors out here then there was a problem creating the pdf definition
        trackError(err, {
          extra: { error_context: "bulk_revision_pdf" }
        });

        // send action to display error
        this.props.showDownloadError();

        this.setState({
          downloading: false
        });
      });
  };

  onRemove = () => {
    const { fileExport, removeItem } = this.props;
    removeItem(fileExport.id);
  };

  renderDownloadButton = () => {
    const { fileExport } = this.props;

    const fileExportType = get(fileExport, "type", "");
    const fileUrl = get(fileExport, "attributes.url", "");

    switch (fileExportType) {
      case resourceNames.localExports:
        const className = this.state.downloading
          ? styles["download-icon-disabled"]
          : styles["download-icon"];
        return (
          <span
            className={`fa fa-download ${className}`}
            onClick={this.onDownloadClicked}
          />
        );

      default:
        return (
          <a href={fileUrl} download>
            <span className={`fa fa-download ${styles["download-icon"]}`} />
          </a>
        );
    }
  };

  render() {
    const { fileExport } = this.props;

    const fileState = get(fileExport, "attributes.state", "");
    const isFileReady = fileState === exportStates.completed;
    const trucateLength = isFileReady ? styles["truncate-wide"] : "";
    const stylizeInProgress = isFileReady ? "" : styles["in-progress"];

    return (
      <div className={`${styles["container"]} ${stylizeInProgress}`}>
        <div
          className={`${styles["filename"]}`}
          data-toggle="tooltip"
          data-placement="top"
          title={get(fileExport, "attributes.fileName", "")}
        >
          <p className={`${styles["truncate-filename"]} ${trucateLength}`}>
            {get(fileExport, "attributes.fileName", "")}
          </p>
        </div>
        <div className={`${styles["text-align-right"]}`}>
          {isFileReady && this.renderDownloadButton()}
          {!isFileReady && (
            <p>
              {" "}
              {fileState
                ? i18n.t(`data.fileExportStates.${fileState}`)
                : ""}{" "}
            </p>
          )}
        </div>
      </div>
    );
  }
}
