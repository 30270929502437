import { createSelector } from "reselect";
import { isSwiftAdminSelector } from "../is_swift_admin_selector";

/**
 * Memoized selector to determine the default view for admin and other users,
 * returns true if user is a Swift Admin, false otherwise
 */

export const adminDefaultViewSelector = createSelector(
  isSwiftAdminSelector,
  isSwiftAdmin => {
    return isSwiftAdmin ? "/admin/users" : "/admin/patients";
  }
);
