import { createSelector } from "reselect";

// Selectors
import { createCsvExportForIdQuerySelector } from "../../../query_selectors/csv_exports";

/**
 * create a selector to get the context for a csv_export
 * @param {Object} props
 * @param {string} props.csvExportId
 * @returns {function} selector
 */
export function createCsvExportForIContextSelector({ csvExportId }) {
  return createSelector(
    createCsvExportForIdQuerySelector({
      csvExportId
    }),
    query => {
      return query;
    }
  );
}
