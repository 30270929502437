import moment from "moment";
import get from "lodash.get";

import { ageStringFromPatient } from "src/models/patients";
import { medlineCopy, defaultCopy } from "src/constants/branding_copy";

/**
 * Get the header portion of the Non Wound Assessment PDF, Formatted for PDF Make
 * @param {Object} patient - the patient
 * @param {string} logoDataUrl - data url for swift logo
 * @param {string} qrCodeUrl - data url for patient qr code
 * @return {Object} JSON representation of the pdf header
 */
export function getHeader(
  patient,
  patientLastFacility,
  evaluatedOn,
  logoDataUrl,
  qrCodeUrl,
  options
) {
  const {
    firstName,
    lastName,
    gender,
    birthAt,
    shareToken
  } = patient.attributes;
  const { branding } = options;
  const age = ageStringFromPatient(birthAt);
  const dob = birthAt
    ? `DOB: ${new moment(birthAt).utc().format("MMM D, YYYY")}`
    : "DOB: Unknown";

  const assessedOn = new moment(evaluatedOn).format("MMM D, YYYY - HH:mm");

  const mrn = patient.mrn ? [{ text: `MRN: ${patient.mrn}` }] : [];
  const brandedCodeLabel =
    branding === "medline" ? medlineCopy.codeLabel : defaultCopy.codeLabel;

  return {
    stack: [
      {
        image: logoDataUrl,
        width: 150
      },
      {
        text: `Evaluated on ${assessedOn}`,
        style: "h1"
      },
      [
        {
          columns: [
            {
              stack: [
                {
                  image: qrCodeUrl,
                  width: 100
                }
              ],
              width: "auto"
            },
            {
              stack: [
                {
                  text: `${firstName} ${lastName}`,
                  style: "h2"
                },
                {
                  text: `${age}, ${gender ? gender : "Gender Unknown"}`
                },
                {
                  text: dob
                },
                ...mrn,
                {
                  text: brandedCodeLabel,
                  bold: true,
                  margin: [0, 10, 0, 0]
                },
                {
                  text: shareToken,
                  bold: true
                }
              ],
              width: 190
            },
            {
              ...(patientLastFacility && {
                stack: getPDFAddressStack(
                  getAddressObject(patientLastFacility)
                ),
                width: 220
              })
            }
          ],
          columnGap: 20
        }
      ],
      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 5,
            x2: 592,
            y2: 5,
            lineWidth: 1
          }
        ]
      },
      {
        canvas: [
          {
            type: "line",
            x1: 306,
            y1: -500,
            x2: 306,
            y2: 0,
            lineWidth: 1
          }
        ]
      }
    ],
    margin: 10
  };
}

const getAddressObject = facility => {
  if (!facility) return;

  const addressFields = [
    "name",
    "address1",
    "address2",
    "city",
    "provinceCode",
    "postalCode",
    "phone",
    "fax",
    "email"
  ];

  const address = addressFields.reduce((agg, field) => {
    agg[field] = get(facility, `attributes.${field}`, "");
    agg[field] = agg[field] === null ? "" : agg[field];
    return agg;
  }, {});

  return address;
};

const getPDFAddressStack = address => {
  let stack = [];
  const facilityName = address.name;
  const {
    address1,
    address2,
    city,
    provinceCode,
    postalCode,
    phone,
    fax,
    email
  } = address;

  if (facilityName) {
    stack.push({
      text: `${facilityName} ${fax ? `| Fax: ${fax}` : ""}`,
      style: "h3"
    });
  }

  if (address1) {
    stack.push({
      text: `${address1}${address2 ? `, ${address2}` : ""} `
    });
  }

  if (city) {
    stack.push({
      text: `${city}, ${provinceCode} ${postalCode}`
    });
  }

  if (phone) {
    stack.push({
      text: `Phone: ${phone}`
    });
  }

  if (email) {
    stack.push({
      text: `Email: ${email}`
    });
  }

  return stack;
};
