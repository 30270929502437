import { createSelector } from "reselect";

//Selectors
import { createLatestLockedEventForRevisionIdQuerySelector } from "src/selectors/query_selectors/events_for_revision_id";

/**
 * create a selector to get the context for the latest locked event with a revisionId
 * @param {Object} props
 * @param {string} props.revisionId
 * @returns {function} selector
 */

export function createLatestLockedEventForRevisionIdContextSelector({
  revisionId
}) {
  return createSelector(
    createLatestLockedEventForRevisionIdQuerySelector({ revisionId }),
    query => {
      return query;
    }
  );
}
