import { connect } from "react-redux";

// Component
import { AdminPatientTableComponent } from "src/components/tables";

//Selectors
import {
  patientsResourceSelectors,
  createDataForPatientUsersandPatients
} from "src/selectors/data_selectors";
import {
  selectedPatientTablePageNumberSelector,
  selectedPatientTablePageLimitSelector
} from "src/selectors/section_selectors/admin";
import {
  patientEditEnabledSelector,
  patientAdtEnabledSelector
} from "src/selectors/ui_config_selectors/admin";
import { patientTableQuerySelector } from "src/selectors/query_selectors/admin";
import { patientAppDashboardEnabledSelector } from "src/selectors/ui_config_selectors/global";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";

//Actions
import {
  selectPatientTablePageNumberAction,
  selectPatientTablePageLimitAction
} from "src/actions/section_actions/admin";
import { createWelcomeMessage } from "src/actions/data_actions";

function mapStateToProps(state) {
  const context = patientTableQuerySelector(state);
  return {
    patientsAndUsers: createDataForPatientUsersandPatients(context)(state),
    selectedPatientTablePageNumber: selectedPatientTablePageNumberSelector(
      state
    ),
    selectedPatientTablePageLimit: selectedPatientTablePageLimitSelector(state),
    patientTablePageCount:
      patientsResourceSelectors.createPageCountSelector(
        context,
        selectedPatientTablePageLimitSelector
      )(state) || 0,
    patientTableRecordCount:
      patientsResourceSelectors.createRecordCountSelector(context)(state) || 0,
    editPatientEnabled: patientEditEnabledSelector(state),
    adtEnabled: patientAdtEnabledSelector(state),
    patientAppEnabled: patientAppDashboardEnabledSelector(state),
    currentUser: currentUserSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectPatientTablePage(pageNumber) {
      dispatch(selectPatientTablePageNumberAction(pageNumber));
    },
    selectPatientTablePageLimit(pageLimit) {
      dispatch(selectPatientTablePageLimitAction(pageLimit));
    },
    resendWelcomeMessage(patientUserId, patientId, cuurentUserId) {
      return dispatch(
        createWelcomeMessage(patientUserId, patientId, true, cuurentUserId)
      );
    }
  };
}

export const AdminPatientTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPatientTableComponent);
