import { createSelector } from "reselect";
import { configSelector } from "../config_selector";

/**
 * Memoized selector for the partner location ids
 */
export const partnerLocationIdsSelector = createSelector(
  configSelector,
  config => {
    return config.partnerLocationIds;
  }
);
