import { connect } from "react-redux";

//Selectors
import { createLocalizationDictionarySelectorForUserAlerts } from "src/selectors/section_selectors/global";
import { subtypeKeysForAllSchemasSelector } from "src/selectors/section_selectors/global/subtype_keys_for_all_schemas_selector";

//Component
import { AcknowledgeAlertComponent } from "src/components/acknowledge_alert_component";

//Actions
import { resolveUserAlertAction } from "src/actions/data_actions";

function mapStateToProps(state, ownProps) {
  return {
    alert: ownProps.alert,
    schemaLocalizationDictionary: createLocalizationDictionarySelectorForUserAlerts(
      [ownProps.alert]
    )(state),
    subtypeKeysForSchema: subtypeKeysForAllSchemasSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendResolveAlertAction: payload => {
      return dispatch(resolveUserAlertAction({ payload }));
    }
  };
}

export const AcknowledgeAlertContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcknowledgeAlertComponent);
