// @flow
import { Record, Map, fromJS, List } from "immutable";
import AnswerModel from "./AnswerModel";
import { convertInput } from "src/utils/modelUtils";

export const recordInput = {
  analysis: new Map(),
  answers: new AnswerModel(),
  answersJson: "",
  user: new Map(),
  revisionId: "",
  forms: new Map(),
  assessmentAnswerGroupId: "",
  assessmentId: "",
  assessmentType: "",
  assessmentVersion: 0,
  completed: false,
  createdAt: "",
  frequency: "",
  frequencyValue: 0,
  history: List(),
  id: "",
  imageUrl: "",
  imageWidth: 0,
  imageHeight: 0,
  lockState: "",
  name: "",
  overallProgress: "Unknown",
  patientId: "",
  pressureUlcerStage: "",
  pressureUlcerUnstageableCause: "",
  resource: new Map(),
  resourceId: "",
  resourceType: "",
  score: "",
  seriesId: "",
  studyId: "",
  title: "",
  updatedAt: "",
  userId: "",
  woundClosure: 0,
  woundGeography: "Location Not Set",
  woundOriginAt: "",
  woundType: "Undiagnosed",
  lastAssessedAt: ""
};

const getFinalPressureUlcerName = function(
  assessmentAnswer: AssessmentAnswerModel
) {
  const {
    woundType,
    pressureUlcerStage,
    pressureUlcerUnstageableCause
  } = assessmentAnswer;
  const {
    pressureUlcerStage: displayPressureUlcerStage
  } = assessmentAnswer.answers;
  if (pressureUlcerStage === "unstageable") {
    let stageCause = "";
    if (pressureUlcerUnstageableCause === "dressing") {
      stageCause = " (DR)";
    } else if (pressureUlcerUnstageableCause === "dti") {
      stageCause = " (DTI)";
    } else if (pressureUlcerUnstageableCause === "necrosis") {
      stageCause = " (NEC)";
    }
    return `${woundType} - ${displayPressureUlcerStage}${stageCause}`;
  } else if (pressureUlcerStage === "unknown") {
    return `${woundType} - ${displayPressureUlcerStage}`;
  } else {
    return `${woundType} - Stage ${displayPressureUlcerStage}`;
  }
};

const getFinalWoundTypeName = function(
  assessmentAnswer: AssessmentAnswerModel
) {
  const { woundType } = assessmentAnswer;
  const { burnDegree, skinTearCategory, masdType } = assessmentAnswer.answers;

  // the values in the model are pre-mapped to a regular case version of the api key
  // Add special exception for skin tear types
  // TODO: Update this to using the mapping on refactor
  let skinTearCategoryDisplay = skinTearCategory;

  if (skinTearCategory == "Type 1 None") {
    skinTearCategoryDisplay = "No skin loss";
  } else if (skinTearCategory == "Type 2 Partial") {
    skinTearCategoryDisplay = "Partial flap loss";
  } else if (skinTearCategory == "Type 3 Total") {
    skinTearCategoryDisplay = "Total flap loss";
  }

  switch (woundType) {
    case "Pressure Ulcer":
      return getFinalPressureUlcerName(assessmentAnswer);
    case "Burn":
      return burnDegree ? `${woundType} - ${burnDegree}` : woundType;
    case "Skin Tear":
      return skinTearCategory
        ? `${woundType} - ${skinTearCategoryDisplay}`
        : woundType;
    case "Moisture Associated Skin Damage (MASD)":
      if (assessmentAnswer.assessmentVersion >= 3) {
        return masdType ? `${woundType} - ${masdType}` : woundType;
      } else {
        return woundType;
      }

    default:
      return woundType;
  }
};

export default class AssessmentAnswerModel extends Record(recordInput) {
  static getFinalWoundTypeName = getFinalWoundTypeName;
  static constants = {
    WOUND_ASSESSMENT_DEFAULT_IMAGE_URL:
      "https://s3.amazonaws.com/bdsm-dev-east/assets/wound-camera-icon.png"
  };
  constructor(opts: Object = {}) {
    const nextOpts = convertInput(
      {
        answers: answers => new AnswerModel(answers),
        user: Map,
        forms: (forms = {}) => fromJS(forms),
        history: (history = []) => fromJS(history),
        resource: (resource = {}) => fromJS(resource),
        analysis: (analysis = {}) => fromJS(analysis)
      },
      opts
    );
    super(nextOpts);
  }
}
