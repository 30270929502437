// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

// Constants
import { resourceNames } from "src/constants";

// Api
import { savedFiltersApi } from "src/api/saved_filters";

// Actions
import { createLoadDataAction } from "../create_load_data_action";
import { createPostDataAction } from "../create_post_data_action";
import { createPatchDataAction } from "../create_patch_data_action";
import { createDeleteDataAction } from "../create_delete_data_action";
import { create } from "src/actions/flashMessageActions";

export const savedFiltersResourceActions = createResourceActions(
  resourceNames.savedFilters,
  savedFiltersApi
);

export const loadSavedFiltersAction = createLoadDataAction({
  resourceName: resourceNames.savedFilters,
  api: savedFiltersApi
});

export const postSavedFilterAction = createPostDataAction({
  resourceName: resourceNames.savedFilters,
  api: savedFiltersApi,
  onSuccess: dispatch => {
    dispatch(create("Filter Created, Please Refresh to View", "success"));
  },
  onError: dispatch => {
    dispatch(create("Sorry, there was an error creating the filter", "error"));
  }
});

export const patchSavedFilterAction = createPatchDataAction({
  resourceName: resourceNames.savedFilters,
  api: savedFiltersApi,
  onSuccess: dispatch => {
    dispatch(create("Filter Updated", "success"));
  },
  onError: dispatch => {
    dispatch(create("Sorry, there was an updating the filter", "error"));
  }
});

export const deleteSavedFilterAction = createDeleteDataAction({
  resourceName: resourceNames.savedFilters,
  api: savedFiltersApi,
  onSuccess: dispatch => {
    dispatch(create("Filter Deleted", "success"));
  },
  onError: dispatch => {
    dispatch(create("Sorry, there was an error deleting the filter", "error"));
  }
});
