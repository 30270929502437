export const legacyPopulationChartDataActions = {
  populatePopulationIncidenceChartData:
    "POPULATE_POPULATION_INCIDENCE_CHART_DATA",
  populationIncidenceChartDataLoading:
    "DATA_POPULATION_INCIDENCE_CHART_LOADING",
  populationIncidenceChartDataLoadError:
    "DATA_POPULATION_INCIDENCE_CHART_LOAD_ERROR",
  populatePopulationPrevalenceChartData:
    "POPULATE_POPULATION_PREVALENCE_CHART_DATA",
  populationPrevalenceChartDataLoading:
    "DATA_POPULATION_PREVALENCE_CHART_LOADING",
  populationPrevalenceChartDataLoadError:
    "DATA_POPULATION_PREVALENCE_CHART_LOAD_ERROR"
};
