import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { trackError } from "src/error_tracking";
import moment from "moment";

// Translations
import i18n from "src/translations";

// Moment
import Moment from "moment";

// Lodash
import capitalize from "lodash.capitalize";

// Ui kit
import { MenuPanel, Button, Icon } from "@sw/ui-kit";
import LoadingComponent from "app/loading/component";

// Analytics
import { track as pretrack } from "src/analytics";

// Constants
import { loadStates } from "src/constants/load_states";

import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";

// Utils
import { getImageDataUrlFromLocalPath } from "src/utils/image_data_url";
import { createListStringFromArray } from "src/utils/format_string_list_from_array";
import { sortByNameArray } from "src/utils/sort_by_name";
// Styles
import styles from "./style.module.less";
import pdf from "src/components/survey_component/pdf.png"; // TODO: this should be an svg component
import csv from "src/components/survey_component/csv.png"; // TODO: this should be an svg component

import pdfMake from "pdfmake/build/pdfmake";
import { createSurveyDetailsPdf } from "src/pdfs";

//Constants
import { analyticsEventNames } from "../../constants/analytics_event_names";

const track = (name, payload) => pretrack(`SURVEY_LINELIST:${name}`, payload);

const generateButton = type => ({ onClick, ...props }) => {
  return (
    <button
      type="button"
      onClick={() => {
        track(type);
        onClick();
      }}
      {...props}
      className="-btn"
    >
      {props.children}
    </button>
  );
};

const NextButton = generateButton("NEXT");
const PreviousButton = generateButton("PREVIOUS");
// --------------  IE11 Hack for React-Table -------------- //
if (window.navigator.userAgent.indexOf("Trident") > 0) {
  var node = document.createElement("style");
  node.innerHTML = ".ReactTable { display: block !important}";
  document.body.appendChild(node);
}

// --------------  Date format for react table title -------------- //
const dateFormat = "MMM Do, YYYY";
const dateTimeFormat = "MMM D, YYYY - HH:mm";

// --------------  Spacer Element for Spans -------------- //
const spacer = <span style={{ display: "inline-block", width: "2px" }} />;

export class SurveyDetailsComponent extends Component {
  static propTypes = {
    woundDetailsLoadState: PropTypes.string.isRequired,
    woundDetailsExportLoadState: PropTypes.string.isRequired,
    woundDetails: PropTypes.array.isRequired,
    woundNumbersEnabled: PropTypes.bool.isRequired,
    patientLinksEnabled: PropTypes.bool.isRequired,
    partnerName: PropTypes.string.isRequired,
    patientLinkTemplate: PropTypes.string.isRequired,
    routeQueryParams: PropTypes.object.isRequired,
    selectedLocationIds: PropTypes.array.isRequired,
    dateSelection: PropTypes.object.isRequired,
    excludeNewHealed: PropTypes.bool.isRequired,
    hprScoreEnabled: PropTypes.bool.isRequired,
    bwatScoreEnabled: PropTypes.bool.isRequired,
    treatmentColumnEnabled: PropTypes.bool.isRequired,
    historyCauseEnabled: PropTypes.bool.isRequired,
    visitSequenceEnabled: PropTypes.bool.isRequired,
    loadDetails: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    createAndDownloadCsv: PropTypes.func.isRequired,
    assessmentVersion: PropTypes.string.isRequired,
    selectedOrganization: PropTypes.object.isRequired,
    branding: PropTypes.string,
    brandedLogoPng: PropTypes.object.isRequired,
    schemaLocalizationDictionary: PropTypes.object.isRequired
  };

  /**
   * Event handler for the csv download
   */
  onDownloadCsvButtonClicked = () => {
    const { woundDetailsExportLoadState, selectedLocationIds } = this.props;
    track(analyticsEventNames.downloadSurveyLineListCsvClicked);
    // TODO: Make this dependent on load state of locations instead of checking length
    if (woundDetailsExportLoadState == "ready" && selectedLocationIds.length) {
      this.props.createAndDownloadCsv();
    }
  };

  onDownloadPdfButtonClicked = () => {
    const { selectedOrganization, brandedLogoPng } = this.props;
    const { startDate, endDate } = this.props.dateSelection;
    const dateFormat = "MMM DD, YYYY";
    const dateRangeText = `${startDate.format(
      dateFormat
    )} \u2013 ${endDate.format(dateFormat)}`;

    track(analyticsEventNames.downloadSurveyLineListPdfClicked);
    Promise.all([getImageDataUrlFromLocalPath(brandedLogoPng)])
      .then(([logoDataUrl]) => {
        const globalContent = {
          logo: logoDataUrl,
          reportName: i18n.t("headers.lineList", {
            context: "uppercase"
          }),
          contextText: dateRangeText,
          organization: selectedOrganization
        };
        const options = {
          branding: this.props.branding,
          woundNumbersEnabled: this.props.woundNumbersEnabled,
          fillWhite: true
        };
        const date = new moment().format("YYYY-MMM-DD--HH-mm").toUpperCase();
        let dataSorted =
          JSON.parse(
            JSON.stringify(this.tableInstance.getResolvedState().sortedData)
          ) || {};
        pdfMake
          .createPdf(
            createSurveyDetailsPdf(
              globalContent,
              options,
              {
                footerText: i18n.t("headers.lineList"),
                dateRangeText: dateRangeText,
                fillWhite: true
              },
              dataSorted
            )
          )
          .download(`wound_details_report_${date}.pdf`);
      })

      .catch(err => {
        this.props.showPdfErrorView();
        trackError(err, {
          extra: { error_context: "line_list_report_pdf" }
        });
      });
  };

  toolTipEntering = e => {
    track(analyticsEventNames.surveyLineListExportMenuCLicked);
    e.children[0].style.borderBottomColor = "#f5f5f5";

    e.children[1].style.backgroundColor = "#f5f5f5";
    e.children[1].style.float = "left";
  };

  // TODO: this should be consistent with how its done on other tabs,
  // the top control bar should be its own component that follows the router
  // pattern used by the sidebar
  renderMenuPanel() {
    return (
      <MenuPanel title="Survey">
        <li>
          <Button onClick={this.props.goBack} className="btn-default">
            <Icon name="left-arrow" />
            Back
          </Button>
        </li>
        <li>
          <OverlayTrigger
            placement="bottom"
            trigger="click"
            onEntering={this.toolTipEntering}
            overlay={
              <Tooltip id="popover-contained">
                <ul className={styles["download-icon-list"]}>
                  <li>
                    <div className={styles["textLeft"]}>
                      <img src={csv} width={32} height={32} />
                      <a onClick={this.onDownloadCsvButtonClicked}>
                        Export to CSV
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className={styles["textLeft"]}>
                      <img src={pdf} width={32} height={32} />
                      <a onClick={this.onDownloadPdfButtonClicked}>
                        Export to PDF
                      </a>
                    </div>
                  </li>
                </ul>
              </Tooltip>
            }
          >
            <Button
              name="primary"
              className="control-navbar-element dashboard-btn"
              subtype="button"
            >
              <Icon name="download" />
            </Button>
          </OverlayTrigger>
        </li>
      </MenuPanel>
    );
  }

  /**
   * Render the header for the details table
   */
  renderReactTableHeader() {
    const { acquired, age, resolved } = this.props.routeQueryParams;
    const { assessmentVersion } = this.props;
    const { startDate, endDate } = this.props.dateSelection;

    let startDateMoment = new Moment(startDate);
    let startDateFormatted = startDateMoment.format(dateFormat);
    let endDateMoment = new Moment(endDate);
    let endDateFormatted = endDateMoment.format(dateFormat);

    // Title
    let title = `Resident Line List: ${startDateFormatted} - ${endDateFormatted}`;

    // Subtitle
    let subTitle;

    if (resolved == "true") {
      subTitle = "All Resolved Wounds";
    } else {
      const ageString = capitalize(age);
      let acquiredString;
      if (acquired == "unknown") {
        acquiredString = '"Acquired Not Set"';
      } else if (acquired == "total" || acquired == "allSet") {
        acquiredString = "";
      } else {
        acquiredString = i18n.t(
          `data.acquired.${assessmentVersion}.${acquired}`
        );
      }

      subTitle = `${ageString} ${acquiredString} Wounds`;
    }

    return (
      <h3>
        {title}
        <br />
        {subTitle}
      </h3>
    );
  }

  // --------------  Accessors & Cells for Details React Table -------------- //

  getSurfaceAreaCell(cellProps) {
    const { surfaceArea } = cellProps.original;

    let surfaceAreaValue = surfaceArea;
    if (surfaceAreaValue != "--") {
      surfaceAreaValue = (
        <span>
          {surfaceAreaValue} cm<sup>2</sup>
        </span>
      );
    }

    return (
      <span>
        {surfaceAreaValue}
        {spacer}
      </span>
    );
  }

  getDimensionsCell(cellProps) {
    const { lXwXd } = cellProps.original;
    let { l, w, d } = lXwXd;

    //Add units to dimension measurements if they have a value
    l = l === "--" ? l : l + " cm";
    w = w === "--" ? w : w + " cm";
    d = d === "--" ? d : d + " cm";

    return (
      <span>
        <div>L: {l}</div>
        <div>W: {w}</div>
        <div>D: {d}</div>
      </span>
    );
  }

  /**
   * Used to provide render logic for the patient name cell in the React Table
   */
  getPatientNameCell = cellProps => {
    const { name, patientId, patientAdmissionId } = cellProps.original;
    const { patientLinksEnabled, patientLinkTemplate } = this.props;

    if (patientLinksEnabled) {
      let link = patientLinkTemplate
        .replace("{admission_id}", patientAdmissionId)
        .replace("{patient_id}", patientId);

      let patientName;
      if (this.props.partnerName === "pcc") {
        patientName = (
          <a href={link} target="_parent">
            {name}
          </a>
        );
      } else {
        patientName = <Link to={link}>{name}</Link>;
      }
      return patientName;
    } else {
      return <div>{name}</div>;
    }
  };

  getAdmissionStatus = cellProps => {
    const { dischargedAt } = cellProps.original;
    const { dateSelection } = this.props;

    const dischargeDate = new Moment(dischargedAt);
    const endDate = new Moment(dateSelection.endDate);

    if (!dischargedAt || dischargeDate.isAfter(endDate)) {
      return "Current";
    }

    return "Discharged";
  };

  /**
   * Used to provide render logic for the wound id cell in the React Table
   */
  linkWoundIdCell = cellProps => {
    const { patientId, seriesId } = cellProps.original;
    const { woundIdLinkEnabled } = this.props;
    let link = `/patients/${patientId}/series/${seriesId}`;
    if (woundIdLinkEnabled) {
      return <Link to={link}>{seriesId}</Link>;
    } else {
      return <p> {seriesId}</p>;
    }
  };

  primaryDressingAccessor = wound => {
    const { data } = wound;
    const { schemaLocalizationDictionary, partnerName } = this.props;

    /* Fix for the issue when the key primaryDressing changed in schema v7 to treatmentGeneric
     * We shouldn't maintain two diff keys
     * Remove when migrate to v8
     */
    const labelAccessor =
      data.header.version === 7 && partnerName === "pcc"
        ? "treatmentGeneric"
        : "primaryDressing";

    if (
      data &&
      data[labelAccessor] &&
      typeof data[labelAccessor] === "string"
    ) {
      return schemaLocalizationDictionary[
        `${labelAccessor}.${data[labelAccessor]}`
      ];
    }

    return data && data[labelAccessor] && data[labelAccessor].length
      ? createListStringFromArray(
          sortByNameArray(
            data[labelAccessor].map(pd => {
              return schemaLocalizationDictionary[`${labelAccessor}.${pd}`];
            })
          )
        )
      : "--";
  };

  cleansingSolutionAccessor = wound => {
    const { data } = wound;
    const { schemaLocalizationDictionary } = this.props;

    if (data && data.cleaned && typeof data.cleaned == "string") {
      return schemaLocalizationDictionary[`cleaned.${data.primaryDressing}`];
    }

    return data && data.cleaned && data.cleaned.length
      ? createListStringFromArray(
          sortByNameArray(
            data.cleaned.map(clean => {
              return schemaLocalizationDictionary[`cleaned.${clean}`];
            })
          )
        )
      : "--";
  };

  createDataAccessor = (key, defaultVal) => {
    return wound => {
      const { data } = wound;
      return data[key] || defaultVal;
    };
  };

  worstTissueTypeAccessor = this.createDataAccessor(
    "worstTissueTypeScore",
    "--"
  );
  surfaceAreaValueAccessor = this.createDataAccessor(
    "surfaceAreaValueScore",
    "--"
  );
  intactValueAccessor = this.createDataAccessor("intactValueScore", "--");
  hprScoreAccessor = this.createDataAccessor("hprScore", "--");
  bwatScoreAccessor = this.createDataAccessor("bwatScore", "INC");
  visitFrequencyAccessor = this.createDataAccessor("visitFrequency", "N/A");

  historyCauseAccessor = wound => {
    const { data } = wound;
    return data.history ? i18n.t(`data.historyOrCause.${data.history}`) : "--";
  };

  getReactTableColumns() {
    const dateSortMethod = (date1, date2) => {
      const parseFormat = "MMM D, YYYY - HH:mm";
      const moment1 = new Moment(date1, parseFormat);
      const moment2 = new Moment(date2, parseFormat);
      return moment1.isAfter(moment2) ? 1 : -1;
    };
    const dateTimeSortMethod = (date1, date2, desc) => {
      const parseFormat = dateTimeFormat;
      const moment1 = new Moment(date1, parseFormat);
      const moment2 = new Moment(date2, parseFormat);

      if (
        (!desc && moment1.isValid() === false) ||
        (desc && moment2.isValid() === false)
      ) {
        return 1;
      }

      return moment1.isAfter(moment2) ? 1 : -1;
    };
    const surfaceAreaSortMethod = (a, b) => {
      // force empty to the bottom
      a = a == "--" ? -Infinity : parseFloat(a);
      b = b == "--" ? -Infinity : parseFloat(b);

      return a > b ? 1 : -1;
    };

    const dimensionsSortMethod = (a, b) => {
      // force empty length to the bottom
      const l1 = a.l == "--" ? -Infinity : parseInt(a.l.match(/\d+/)[0]);
      const l2 = b.l == "--" ? -Infinity : parseInt(b.l.match(/\d+/)[0]);

      return l1 > l2 ? 1 : -1;
    };

    const pushScoreSortMethod = (a, b) => {
      // force empty to the bottom
      if (a == "N/A") {
        a = -200;
      } else if (a == "INC") {
        a = -100;
      }

      if (b == "N/A") {
        b = -200;
      } else if (b == "INC") {
        b = -100;
      }

      return a > b ? 1 : -1;
    };

    return [
      {
        Header: "Name",
        accessor: "name",
        id: "name",
        Cell: this.getPatientNameCell
      },
      {
        Header: "Facility",
        accessor: "facilityName"
      },
      {
        Header: "Unit",
        accessor: "unitName"
      },
      {
        Header: "Floor",
        accessor: "floorName"
      },
      {
        Header: "Last Evaluated in Selected Time Period",
        accessor: data =>
          Moment(data.lastAssessed).format("MMM D, YYYY - HH:mm"),
        id: "lastAssessed",
        sortMethod: dateSortMethod
      },
      {
        Header: "Wound Identified",
        accessor: data => Moment(data.identified).format("MMM D, YYYY - HH:mm"),
        id: "woundIdentified",
        sortMethod: dateSortMethod
      },
      {
        Header: "Current Wk Visit Freq",
        accessor: this.visitFrequencyAccessor,
        id: "visitFrequency",
        show: this.props.visitSequenceEnabled
      },
      {
        Header: "Wound #",
        accessor: "patientWoundNumber",
        show: this.props.woundNumbersEnabled
      },
      {
        Header: "Type",
        accessor: "type"
      },
      {
        Header: "Subtype",
        accessor: "subtype"
      },
      {
        Header: "Body Location",
        accessor: "bodyLocation"
      },
      {
        Header: "Surface Area",
        accessor: "surfaceArea",
        id: "surfaceArea",
        Cell: this.getSurfaceAreaCell,
        sortMethod: surfaceAreaSortMethod
      },
      {
        Header: "PUSH Score",
        accessor: "pushScore",
        sortMethod: pushScoreSortMethod,
        show: !this.props.bwatScoreEnabled
      },
      {
        Header: "BWAT Score",
        accessor: this.bwatScoreAccessor,
        id: "bwat",
        show: this.props.bwatScoreEnabled
      },
      {
        Header: "Worst Tissue Type (WTT)",
        accessor: this.worstTissueTypeAccessor,
        id: "wtt",
        width: 200,
        style: {
          whiteSpace: "pre-line"
        },
        show: this.props.hprScoreEnabled
      },
      {
        Header: "Surface Area Value (SAV)",
        accessor: this.surfaceAreaValueAccessor,
        id: "sav",
        show: this.props.hprScoreEnabled
      },
      {
        Header: "Intact Value (IV)",
        accessor: this.intactValueAccessor,
        id: "iv",
        show: this.props.hprScoreEnabled
      },
      {
        Header: "Total HPR Score",
        accessor: this.hprScoreAccessor,
        id: "hpr",
        show: this.props.hprScoreEnabled
      },
      {
        Header: "Measurements",
        accessor: "lXwXd",
        id: "dimensions",
        Cell: this.getDimensionsCell,
        sortMethod: dimensionsSortMethod
      },
      {
        Header: "Acquired",
        accessor: "acquired"
      },
      {
        Header: "Cleansing Solution",
        id: "cleansingSolution",
        accessor: this.cleansingSolutionAccessor,
        width: 200,
        style: {
          whiteSpace: "pre-line"
        },
        show: !this.props.treatmentColumnEnabled
      },
      {
        Header: "Primary Dressing",
        id: "primaryDressing",
        accessor: this.primaryDressingAccessor,
        width: 200,
        style: {
          whiteSpace: "pre-line"
        },
        show: !this.props.treatmentColumnEnabled
      },
      {
        Header: "History / Cause",
        accessor: this.historyCauseAccessor,
        id: "history/cause",
        show: this.props.historyCauseEnabled
      },
      {
        Header: "Progress",
        accessor: "progress"
      },
      {
        Header: "Admission Status",
        accessor: "dischargedAt",
        id: "admissionStatus",
        Cell: this.getAdmissionStatus
      },
      {
        Header: "Admitted Date",
        accessor: data => Moment(data.admittedAt).format(dateTimeFormat),
        id: "admittedDate",
        sortMethod: dateTimeSortMethod
      },
      {
        Header: "Discharged Date",
        accessor: data =>
          data.dischargedAt == null
            ? "--"
            : Moment(data.dischargedAt).format(dateTimeFormat),
        id: "dischargedDate",
        sortMethod: dateTimeSortMethod
      },
      {
        Header: "Wound Id",
        accessor: "seriesId",
        id: "seriesId",
        Cell: this.linkWoundIdCell
      }
    ];
  }

  render() {
    const { woundDetailsLoadState, woundDetails } = this.props;

    // TODO: implement error loading view
    if (woundDetailsLoadState == loadStates.loading) {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_SURVEY_DETAILS">
          <LoadingComponent />
        </StartRenderTimeTrackComponent>
      );
    } else if (woundDetailsLoadState == loadStates.error) {
      return (
        <FinishRenderTimeTrackComponent
          eventName="RENDER_SURVEY_DETAILS"
          error={true}
        >
          <LoadingComponent />
        </FinishRenderTimeTrackComponent>
      );
    }

    // Default render component
    return (
      <FinishRenderTimeTrackComponent eventName="RENDER_SURVEY_DETAILS">
        <div>
          {this.renderMenuPanel()}
          {this.renderReactTableHeader()}
          <ReactTable
            columns={this.getReactTableColumns()}
            data={woundDetails}
            NextComponent={NextButton}
            PreviousComponent={PreviousButton}
            ref={el => (this.tableInstance = el)}
            getTheadThProps={(state, rowInfo, column, instance) => {
              return {
                onClick: () => {
                  track("SORT", { column: column.Header });
                  instance.sortColumn(column);
                },
                style: {
                  whiteSpace: "normal"
                }
              };
            }}
          />
        </div>
      </FinishRenderTimeTrackComponent>
    );
  }
}
