import React from "react";
import PropTypes from "prop-types";

//Translations
import i18n from "src/translations";

//Formatters
import { cellFormatters } from "../cell_formatters";

//Components
import { TableComponent } from "../table_component";
import { TableNavigatorComponent } from "src/components/tables/table_navigator_component";
import { TablePageLimitDropdownComponent } from "../table_page_limit_dropdown_component";

// Styles
import styles from "./style.module.less";

/**
 * Table component for user alerts
 */
export class EvaluationListPURSTableComponent extends React.PureComponent {
  static propTypes = {
    assessmentSummaries: PropTypes.array.isRequired,
    pageLimit: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    firstPageContext: PropTypes.string.isRequired,
    sendSetPageLimitAction: PropTypes.func.isRequired,
    sendSetPageAction: PropTypes.func.isRequired,
    totalCount: PropTypes.array.isRequired,
    patientLinksEnabled: PropTypes.bool.isRequired
  };

  setPage = pageNumber => {
    const { firstPageContext, sendSetPageAction } = this.props;
    sendSetPageAction(firstPageContext, pageNumber);
  };

  render() {
    const {
      assessmentSummaries,
      pageLimit,
      pageCount,
      currentPage,
      sendSetPageLimitAction,
      patientLinksEnabled
    } = this.props;

    const columns = [
      {
        text: i18n.t("headers.patientName"),
        dataField: "firstName",
        formatter: cellFormatters.patientFirstNameLastNameFormatter,
        formatExtraData: {
          patientLinksEnabled: patientLinksEnabled
        }
      },
      {
        text: i18n.t("headers.mrn"),
        dataField: "attributes.patientMrn",
        formatter: cellFormatters.mrnFormatter
      },
      {
        text: i18n.t("headers.location"),
        dataField: "attributes.facilityName",
        formatter: cellFormatters.patientLocationNamesFormatter
      },
      {
        text: i18n.t("Last PURS Score"),
        dataField: "attributes.answersJson.pursScore",
        formatter: cellFormatters.pursScoreFormatter
      },
      {
        text: i18n.t("Last Evaluation Date"),
        dataField: "attributes.createdAt",
        formatter: cellFormatters.lastEvaluationDateFormatter
      },
      {
        text: i18n.t("# of Days since Last Evaluation"),
        dataField: "attributes.createdAt",
        formatter: cellFormatters.daysElapsedFormatter
      }
    ];
    const pursCount = this.props.totalCount ? this.props.totalCount : 0;
    return (
      <div>
        <div className={styles["total-font"]}>
          {pursCount + " Total"}
          <hr />
        </div>
        <TableComponent
          data={assessmentSummaries}
          keyField="id"
          columns={columns}
        />
        <div className="pull-left">
          <TablePageLimitDropdownComponent
            selectPageLimit={sendSetPageLimitAction}
            selectedPageLimit={pageLimit}
          />
        </div>
        <TableNavigatorComponent
          pageNumber={currentPage}
          pageCount={pageCount}
          selectTablePage={this.setPage}
        />
      </div>
    );
  }
}
