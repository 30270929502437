// TODO: add support for Boolean fields
export const surveyOptionsConfiguration = {
  filterName: "surveyOptions",
  filterValues: [
    {
      key: "excludeNewWounds",
      value: "excludeNewWounds"
    },
    {
      key: "includeDischarged",
      value: "includeDischarged"
    }
  ]
};
