import { createSelector } from "reselect";
import { decamelize } from "humps";

// Constants
import { exportNames, exportTypes } from "src/constants";

// Selectors
import {
  selectedLocationIdsSelector,
  answersFilterSelector
} from "src/selectors/section_selectors/evaluation_list/evaluation_list_braden_selector";
import { currentUserSelector } from "src/selectors/config_selectors/current_user_selector";

// Api
import { createPostPayload } from "src/api/csv_exports/payload_generators";

// Utils
import { getUTCOffsetString } from "src/utils/get_UTC_offset_string/index.js";

export const assessmentSummaryBradenCsvExportPostPayloadSelector = createSelector(
  currentUserSelector,
  selectedLocationIdsSelector,
  answersFilterSelector,
  (currentUser, selectedLocationIds, answers) => {
    return createPostPayload({
      type: exportTypes.csvExports,
      workerType: decamelize(exportNames.evaluationListBraden),
      userId: currentUser ? currentUser.id : "",
      userOffset: getUTCOffsetString(),
      parameters: {
        filter: {
          location_id: selectedLocationIds.length
            ? selectedLocationIds
            : undefined,
          answers: answers && answers.length ? answers : undefined,
          patient_state: "admitted,new"
        },
        sort: "created_at"
      }
    });
  }
);
