import { createSelector } from "reselect";
import { reduxFormNames } from "src/constants";
import { customRolesResourceSelectors } from "src/selectors/data_selectors";

import { allFacilitiesOptionId } from "src/constants";

import {
  ruleFormSelectedFacilitySelector,
  ruleFormFacilitiesByOrganizationSelector,
  ruleFormSelectedOrganizationSelector
} from "src/selectors/section_selectors/admin";

export const ruleFormCustomRolesByFacilitiesSelector = createSelector(
  ruleFormSelectedFacilitySelector(reduxFormNames.ruleCreateForm),
  ruleFormSelectedOrganizationSelector(reduxFormNames.ruleCreateForm),
  customRolesResourceSelectors.dataSelector,
  ruleFormFacilitiesByOrganizationSelector(reduxFormNames.ruleCreateForm),
  (selectedFacilityId, selectedOrganizationId, roles, facilities) => {
    const rolesArray = Object.values(roles);
    let locationIds = [selectedOrganizationId];
    const defaultRoles = rolesArray.filter(role => {
      return role.type === "roles";
    });
    const customRoles = rolesArray.filter(role => {
      return role.type === "customRoles";
    });

    if (selectedFacilityId === allFacilitiesOptionId) {
      locationIds = locationIds.concat(
        facilities.map(facility => {
          return facility.id;
        })
      );
    } else {
      locationIds = locationIds.concat([selectedFacilityId]);
    }

    const customRolesByFacility = customRoles.filter(customRole => {
      const hasFacilityId = customRole.attributes.location.includes("Facility");
      const hasOrganizationId = customRole.attributes.location.includes(
        "Organization"
      );

      if (!hasFacilityId && !hasOrganizationId) return false;

      const locationId = customRole.attributes.location.filter(value => {
        return value !== "Facility" && value !== "Organization";
      });

      return locationIds.includes(locationId[0]);
    });

    return [...defaultRoles, ...customRolesByFacility];
  }
);
