import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Component
import { WoundAssessmentProgressChartComponent } from "src/components/wound_assessment_progress_chart_component";

// HOCs
import { withWoundAssessmentsProgressChartDataLoader } from "src/hoc";

// Selectors
import { woundAssessmentProgressChartDefinitionSelector } from "src/selectors/chart_definition_selectors";
import { woundAssessmentProgressChartDataLoadStateSelector } from "src/selectors/data_selectors";
import { progressValuesWithoutHealedSelector } from "src/selectors/constant_selectors";

function mapStateToProps(state) {
  return {
    chartDefinition: woundAssessmentProgressChartDefinitionSelector(state),
    woundAssessmentProgressChartLoadState: woundAssessmentProgressChartDataLoadStateSelector(
      state
    ),
    progressValues: progressValuesWithoutHealedSelector(state)
  };
}

export const WoundAssessmentProgressChartContainer = flowRight(
  withWoundAssessmentsProgressChartDataLoader,
  connect(mapStateToProps)
)(WoundAssessmentProgressChartComponent);
