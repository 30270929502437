import { createSelector } from "reselect";
import moment from "moment";
import { woundsSectionSelector } from "../wounds_section_selector";

export const dateSelectionSelector = createSelector(
  woundsSectionSelector,
  wounds => {
    return {
      startDate: moment("2010-01-01"),
      endDate: wounds.selectedDate
    };
  }
);
