import { createSelector } from "reselect";

import { createLatestAssessmentAnswerForRevisionSelector } from "src/selectors/data_selectors";

/**
 * Create a selector to check if a revision is struck out
 * @param {Object} revision
 * @returns {Function} selector
 */
export function createRevisionIsStruckOutSelector(revision) {
  return createSelector(
    createLatestAssessmentAnswerForRevisionSelector(revision),
    latestAssessmentAnswer => {
      if (
        !latestAssessmentAnswer ||
        latestAssessmentAnswer.attributes.strikeoutState == "stricken"
      ) {
        return true;
      }

      return false;
    }
  );
}
