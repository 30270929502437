import { connect } from "react-redux";
import { RiskDefaultComponent } from "src/components/risk_default_component";
import { defaultAssessmentTypeSelector } from "src/selectors/section_selectors/risk";

function mapStateToProps(state) {
  return {
    defaultAssessmentType: defaultAssessmentTypeSelector(state)
  };
}

export const RiskDefaultContainer = connect(mapStateToProps)(
  RiskDefaultComponent
);
