import moment from "moment";

export function dateFormatMatch(value) {
  // _i is the input used when create the moment object

  if (
    moment(value).isValid() &&
    /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/.test(
      moment(value)._i
    )
  ) {
    return "";
  } else if (
    value &&
    !/^(((\d{4}-((0[13578]-|1[02]-)(0[1-9]|[12]\d|3[01])|(0[13456789]-|1[012]-)(0[1-9]|[12]\d|30)|02-(0[1-9]|1\d|2[0-8])))|((([02468][048]|[13579][26])00|\d{2}([13579][26]|0[48]|[2468][048])))-02-29)){0,10}$/.test(
      value
    )
  )
    return "Please enter a valid date in the YYYY-MM-DD format";
}
