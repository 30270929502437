// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Selector
import { showSidebarSelector } from "src/selectors/section_selectors/global";
import { partnerNameSelector } from "src/selectors/config_selectors";
import {
  monitoringTabEnabledSelector,
  searchPatientDashboardEnabledSelector
} from "src/selectors/ui_config_selectors/global";

// actions
import { toggleExpandSidebarAction } from "src/actions/section_actions/global";

// Component
import { SideBarComponent } from "src/components/side_bar_component";

function mapStateToProps(state) {
  return {
    showSidebar: showSidebarSelector(state),
    monitoringTabEnabled: monitoringTabEnabledSelector(state),
    partnerName: partnerNameSelector(state),
    searchPatientDashboardEnabled: searchPatientDashboardEnabledSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleExpandSidebar: () => {
      dispatch(toggleExpandSidebarAction());
    }
  };
}

export const SideBarContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SideBarComponent)
);
