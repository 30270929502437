// Constants
import { resourceNames } from "src/constants";

//API
import { csvExportsApi } from "src/api/csv_exports";

// Actions
import { create } from "src/actions/flashMessageActions";
import { openDownloadManagerAction } from "src/actions/section_actions/global";
import { createResourceActions } from "src/redux_resources_v1";
import { createPostDataAction } from "../create_post_data_action";
import { createLoadDataAction } from "../create_load_data_action";
import { createRemoveResourceByIdAction } from "../create_remove_resource_by_id_action";

export const csvExportsResourceActions = createResourceActions(
  resourceNames.csvExports
);

export const postCsvExportAction = createPostDataAction({
  resourceName: resourceNames.csvExports,
  api: csvExportsApi,
  onSuccess: dispatch => {
    dispatch(openDownloadManagerAction());
  },
  onError: dispatch => {
    dispatch(create("Sorry, there was an error creating your report", "error"));
  }
});

export const loadCsvExportAction = createLoadDataAction({
  resourceName: resourceNames.csvExports,
  api: csvExportsApi
});

export const removeCsvExportAction = createRemoveResourceByIdAction({
  resourceName: resourceNames.csvExports
});
