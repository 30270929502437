// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import get from "lodash.get";

// Components
import { QrCodeComponent } from "src/components/ui_kit";

import { timeFormats } from "@sw/utils/timeUtils";
import { Row, Column } from "@sw/ui-kit";
import PatientModel from "@sw/models/PatientModel";

import { createPatientForIdSelector } from "src/selectors/data_selectors";
import { brandingSelector } from "src/selectors/ui_config_selectors/global";
import { accessTokenSelector } from "src/selectors/session_selectors";
import { loadPayersFromPatientId } from "src/selectors/data_selectors/payers/load_payers_from_patient_id";
import { payerDashboardEnabledSelector } from "src/selectors/ui_config_selectors/global";
import { medlineCopy, defaultCopy } from "src/constants/branding_copy";
import { createListStringFromArray } from "src/utils/format_string_list_from_array";

// Model
import {
  ageStringFromPatient,
  swiftQrCodeStringFromPatient
} from "src/models/patients";

//patient default avatar
import patientDefaultAvatar from "src/assets/patientDefaultAvatar.png";

type $ownProps = {
  id: $$id
};

type $stateProps = {
  patient: PatientModel,
  accessToken: string,
  payerDashboardEnabled: Boolean,
  payersForPatient: Array
};

type $dispatchProps = {
  goToTimeline: Function
};

type $props = $ownProps & $stateProps & $dispatchProps;

class PatientHeader extends PureComponent {
  props: $props;
  auxPatientData() {
    return <div />;
  }
  render() {
    const {
      patient,
      accessToken,
      branding,
      payerDashboardEnabled,
      payersForPatient
    } = this.props;

    const firstName = get({ patient }, "patient.attributes.firstName", null),
      lastName = get({ patient }, "patient.attributes.lastName", null),
      birthAt = get({ patient }, "patient.attributes.birthAt", null),
      shareToken = get({ patient }, "patient.attributes.shareToken", null),
      gender = get({ patient }, "patient.attributes.gender", null),
      mrn = get({ patient }, "patient.attributes.mrn", null),
      avatarUrl = get({ patient }, "patient.attributes.avatarUrl", null),
      aux = get({ patient }, "patient.attributes.aux", null);

    const birthDate = birthAt
      ? moment(birthAt)
          .utc()
          .format(timeFormats.BIRTHDAY)
      : "Unknown";

    // Background, Foreground
    // Green
    // ["#266659", "#7fbcaa"],
    // ["#a0cebc", "#d1e2d3"],
    // ["#35c4af", "#baead6"],
    // ["#00563f", "#00b28c"],
    // # Blue
    // ["#00a0c4", "#6bc9db"],
    // ["#008cb2", "#d1d8d8"],
    // ["#0091c9", "#a5dde2"],
    // # Yellow
    // ["#d3bf11", "#f2ed9e"],
    // # Red
    // ["#d81e05", "#ed9e84"],
    // ["#d12d33", "#ffd3aa"],
    // # Orange
    // ["#fc7f3f", "#f9c6aa"]

    let avatarStyles = {
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundImage: `url('${patientDefaultAvatar}')`
    };
    let avatarHtml = (
      <div className="img-responsive">
        <i className="glyphicon" style={avatarStyles} />
      </div>
    );

    let headerDetails = payerDashboardEnabled ? (
      <div className="pull-left">
        DOB: {birthDate}
        <br />
        MRN: {mrn || "N/A"}
        <br />
        {"Current Payer(s): " + createListStringFromArray(payersForPatient)}
      </div>
    ) : (
      <div className="pull-left">
        DOB: {birthDate}
        <br />
        MRN: {mrn || "N/A"}
      </div>
    );

    if (avatarUrl && avatarUrl.search(/missing.png/) < 0) {
      avatarStyles.backgroundImage = `url('${avatarUrl}')`;
      avatarHtml = (
        <div className="image-responsive">
          <i className="glyphicon" style={avatarStyles} />
        </div>
      );
    }
    let patientDataHtml = "";
    if (accessToken) {
      patientDataHtml = (
        <Row fluid>
          <Column className="patient__name">
            <div className="pull-left">
              <div>
                {firstName || ""} {lastName || ""}
              </div>
              <div className="patient__age">
                {ageStringFromPatient(birthAt) + ", "}
                {!gender ? "Gender Unknown" : gender}
              </div>
            </div>
          </Column>
          <Column className="patient__birth-at">{headerDetails}</Column>
        </Row>
      );
    } else {
      patientDataHtml = (
        <Row fluid>
          <Column className="patient__name blurred-text">
            <div className="pull-left">
              <div>Anonymous Patient</div>
              <div className="patient__age blurred-text">00, Gender</div>
            </div>
            <div className="pull-right" />
          </Column>
          <Column className="patient__birth-at blurred-text">
            <div className="pull-left">
              DOB: month 0, 2015
              <br />
              MRN: 0000000000
            </div>
          </Column>
        </Row>
      );
    }

    return (
      <Row>
        <Column size={7} className="patient-summary__left">
          <div className="patient__avatar pull-left">{avatarHtml}</div>
          <div className="patient__data container-fluid">{patientDataHtml}</div>
          <hr />
          <div>{this.auxPatientData(aux)}</div>
        </Column>
        <Column
          size={5}
          className="patient-summary__right secondary-background"
        >
          <Row>
            <Column size={7}>
              <Row className="text-center" style={{ marginTop: "8px" }}>
                <Column>
                  {branding === "medline"
                    ? medlineCopy.codeLabel
                    : defaultCopy.codeLabel}
                </Column>
                <Column className="patient__share-token">{shareToken}</Column>
                <Column>Scan to Load</Column>
              </Row>
            </Column>
            <Column size={5}>
              <Row>
                <Column className="text-center">
                  <QrCodeComponent
                    code={swiftQrCodeStringFromPatient(patient)}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const patientId = ownProps.match.params.patientId;
  return {
    patient: createPatientForIdSelector(patientId)(state),
    payersForPatient: loadPayersFromPatientId(patientId)(state),
    accessToken: accessTokenSelector(state),
    branding: brandingSelector(state),
    payerDashboardEnabled: payerDashboardEnabledSelector(state)
  };
}

export default withRouter(connect(mapStateToProps)(PatientHeader));
