import moment from "moment";
import get from "lodash.get";

import formatScore from "src/helpers/assessmentAnswers/formatScore";
import { incompletePushScoreValues } from "src/constants";

import { getFilteredCharts } from "../get_filtered_charts";
import { getWoundImage } from "../get_wound_image";
import { getDimensionsTable } from "../get_dimensions_table";
import { getCoverPage } from "../../common_components/get_landscape_cover_page";
import { incompleteBwatScoreValues } from "../../../constants/incomplete_bwat_score_values";
import { createListStringFromArray } from "src/utils/format_string_list_from_array";

export function getMultiWoundBody(pdfContentObjects, options) {
  let woundPDFBody = [];

  woundPDFBody.push(getCoverPage(pdfContentObjects[0], options));

  let { images } = pdfContentObjects[0];

  let { closureChart, pushChart, bwatChart, hprChart } = images;

  // Graphs
  const charts = getFilteredCharts({
    closureChart,
    pushChart,
    bwatChart,
    hprChart
  });

  woundPDFBody.push(...charts);

  let woundColumns = [];

  pdfContentObjects.forEach(content => {
    const {
      // resources
      revision,
      assessmentAnswer,
      schemas,
      payersForRevision,
      // flags
      isAutodepth
    } = content;

    let { images } = content;

    const { wound, imageText } = images;

    const evaluatedOn = new moment(revision.attributes.createdAt).format(
      "MMM D, YYYY - HH:mm"
    );
    const setOverallProgress = get(
      assessmentAnswer,
      "attributes.answersJson.overallProgress",
      null
    );
    const dimensionsTable = getDimensionsTable(revision, isAutodepth);
    const progressSchemas = schemas.progress;
    let overallProgress = "Not Set";

    const bwatScore = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.bwatScore",
      null
    );

    const pushScore = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.pushScore",
      null
    );

    const visitFrequency = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.visitFrequency",
      "N/A"
    );

    const physicianVerified = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.physicianVerified",
      "N/A"
    );

    const encounterNumber = get(assessmentAnswer, "attributes.encounterNumber");

    let scoreText = "";
    if (pushScore && !options.bwatScoreEnabled) {
      scoreText = `PUSH Score: ${formatScore(
        incompletePushScoreValues.includes(pushScore) ? "INC" : pushScore
      )}`;
    } else if (bwatScore) {
      scoreText = `BWAT Score: ${formatScore(
        incompleteBwatScoreValues.includes(bwatScore) ? "INC" : bwatScore
      )}`;
    }

    progressSchemas.forEach(progressSchema => {
      if (
        progressSchema.attributes.schemaJson.name === "notes" &&
        setOverallProgress
      ) {
        overallProgress =
          progressSchema.attributes.schemaJson.localizationDictionary.base[
            `overallProgress.${setOverallProgress}`
          ];
      }
    });

    let woundColumnsStack = [];

    if (encounterNumber) {
      woundColumnsStack.push({
        text: `Encounter: ${encounterNumber}`,
        fontSize: 10
      });
    }

    woundColumnsStack.push({
      text: `Evaluated on ${evaluatedOn}`,
      fontSize: 10
    });

    if (setOverallProgress) {
      woundColumnsStack.push({
        text: `Progress: ${overallProgress}`,
        fontSize: 10
      });
    } else {
      woundColumnsStack.push({
        text: "",
        marginTop: 11
      });
    }

    if (physicianVerified && options.etiologyVerifiedQuestionEnabled) {
      woundColumnsStack.push({
        text: `Etiology Verified by Physician ${physicianVerified}`,
        fontSize: 10
      });
    }

    if (payersForRevision && options.payerDashboardEnabled) {
      woundColumnsStack.push({
        text: `Payer(s): ${createListStringFromArray(payersForRevision)}`,
        fontSize: 10
      });
    }

    if (visitFrequency && options.visitSequenceEnabled) {
      woundColumnsStack.push({
        text: `Current Wk Visit Freq: ${visitFrequency}`,
        fontSize: 10
      });
    }

    if (scoreText) {
      woundColumnsStack.push({
        text: scoreText,
        fontSize: 10
      });
    } else {
      woundColumnsStack.push({
        text: "",
        marginTop: 11
      });
    }

    woundColumns.push({
      stack: [
        getWoundImage(wound, imageText, "left", 200),
        {
          stack: woundColumnsStack,
          marginTop: 5,
          marginBottom: 5
        },
        ...(dimensionsTable ? [dimensionsTable] : [])
      ],
      width: 225
    });
  });

  const woundColumnsPages = new Array(Math.ceil(woundColumns.length / 3))
    .fill()
    .map(() => woundColumns.splice(0, 3));

  woundColumnsPages.forEach(woundColumnsPage => {
    woundPDFBody.push({
      columns: woundColumnsPage,
      style: "columnBody",
      margin: [25, 5, 0, 0],
      pageBreak: "before"
    });
  });

  return woundPDFBody;
}
