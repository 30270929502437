import { trackError } from "src/error_tracking";

// Api
import { createPatientADT } from "src/api/patients_adt";

// Redux Resources
import { createResourceActions } from "src/redux_resources_v1";

import { create } from "src/actions/flashMessageActions";

// Translations
import i18n from "src/translations";

const resourceActions = createResourceActions("patientAdt");
const patientsResourceActions = createResourceActions("patients");

/**
 * Action creator for ADT patients
 * @param {Object} payload The payload
 * @return {Object} redux action
 */
export function createPatientADTAction(payload) {
  return dispatch => {
    dispatch(resourceActions.loadingPostAction());
    return createPatientADT(payload)
      .then(() => {
        let state = "";
        if (payload.adt_event.event_type == "A01") {
          state = "admitted";
        } else if (payload.adt_event.event_type == "A02") {
          state = "admitted";
        } else if (payload.adt_event.event_type == "A03") {
          state = "discharged";
        }
        payload.patient.attributes.state = state;
        payload.patient.attributes.facilityId = payload.adt_event.facility_id;
        payload.patient.attributes.facilityUnitId =
          payload.adt_event.facility_unit_id;

        dispatch(
          patientsResourceActions.populatePatchAction({
            data: {
              [payload.patient.id]: {
                id: payload.patient.id,
                type: payload.patient.type,
                attributes: payload.patient.attributes,
                links: payload.patient.links,
                relationships: payload.patient.relationships
              },
              ids: [payload.patient.id]
            }
          })
        );
        dispatch(create(i18n.t("interaction.patientADT.sucess"), "success"));
      })
      .catch(err => {
        let errorMessage;
        if (payload.adt_event.event_type == "A01") {
          errorMessage = i18n.t("interaction.patientADT.error", {
            context: "admit"
          });
        } else if (payload.adt_event.event_type == "A02") {
          errorMessage = i18n.t("interaction.patientADT.error", {
            context: "transfer"
          });
        } else if (payload.adt_event.event_type == "A03") {
          errorMessage = i18n.t("interaction.patientADT.error", {
            context: "discharge"
          });
        }
        dispatch(create(errorMessage, "error"));
        dispatch(resourceActions.loadErrorPostAction());
        trackError(err);
      });
  };
}
