import { frameActionTypes } from "src/action_types/frame_action_types";

/**
 * Action creator to set if annotations are on or off for frames
 * @param {boolean} annotation
 */
export function setShowAnnotationAction(annotation) {
  return {
    type: frameActionTypes.setShowAnnotation,
    payload: annotation
  };
}

/**
 * Action creator to set if delta thermal frame is active
 * @param {boolean} isDeltaFrameActive
 */
export function setIsDeltaFrameActiveAction(isDeltaFrameActive) {
  return {
    type: frameActionTypes.setIsDeltaFrameActive,
    payload: isDeltaFrameActive
  };
}

/**
 * Action creator to set if fluoro frame is active
 * @param {boolean} isFluoroFrameActive
 */
export function setFluoroFrameTypeAction(frameType) {
  return {
    type: frameActionTypes.setFluoroFrameType,
    payload: frameType
  };
}
