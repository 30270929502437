import { createSelector } from "reselect";
import moment from "moment";

// Constants
import { reduxFormFieldNames } from "src/constants";

// Selectors
import {
  partnersArraySortSelector,
  organizationsSortedByNameSelector,
  facilitiesSortedByNameSelector,
  unitsSortedByNameSelector
} from "src/selectors/data_selectors";

export function createPatientFormInitialValuesSelector(patient) {
  return createSelector(
    partnersArraySortSelector,
    organizationsSortedByNameSelector,
    facilitiesSortedByNameSelector,
    unitsSortedByNameSelector,
    (partners, organizations, facilities, units) => {
      if (patient) {
        return {
          [reduxFormFieldNames.partnerId]: patient.attributes.partnerId,
          [reduxFormFieldNames.organizationId]:
            patient.attributes.organizationId,
          [reduxFormFieldNames.facilityId]: patient.attributes.facilityId,
          [reduxFormFieldNames.unitId]: patient.attributes.facilityUnitId,
          firstName: patient.attributes.firstName,
          middleName: patient.attributes.middleName,
          lastName: patient.attributes.lastName,
          gender: patient.attributes.gender,
          birthDate: patient.attributes.birthAt
            ? moment(patient.attributes.birthAt)
                .utcOffset(0)
                .format("YYYY-MM-DD")
            : null,
          email: patient.attributes.email,
          patientEmail: patient.attributes.patientEmail,
          address: patient.attributes.address,
          phoneNumber: patient.attributes.phoneNumber,
          healthCardNumber: patient.attributes.healthCardNumber,
          mrn: patient.attributes.mrn,
          userId: patient.attributes.userId,
          id: patient.id
        };
      } else {
        const partnerId = partners.length ? partners[0].id : undefined;

        let orgId;
        if (partnerId) {
          const orgsForPartner = organizations.filter(
            o => o.attributes.partnerId == partnerId
          );
          if (orgsForPartner.length) {
            orgId = orgsForPartner[0].id;
          }
        }

        let facId;
        if (orgId) {
          const facForOrg = facilities.filter(
            f => f.attributes.organizationId == orgId
          );
          if (facForOrg.length) {
            facId = facForOrg[0].id;
          }
        }

        let unitId;
        if (facId) {
          const unitsForFac = units.filter(
            u => u.attributes.facilityId == facId
          );
          if (unitsForFac.length) {
            unitId = unitsForFac[0].id;
          }
        }

        return {
          [reduxFormFieldNames.partnerId]: partnerId,
          [reduxFormFieldNames.organizationId]: orgId,
          [reduxFormFieldNames.facilityId]: facId,
          [reduxFormFieldNames.unitId]: unitId
        };
      }
    }
  );
}
