import { connect } from "react-redux";

import { showDownloadManagerBodySelector } from "src/selectors/section_selectors/global";
import { DownloadManagerHeaderComponent } from "src/components/download_manager/download_manager_header_component";
import {
  toggleExpandCollapseDownloadManagerBodyAction,
  closeDownloadManagerAction
} from "src/actions/section_actions/global";

function mapStateToProps(state) {
  return {
    showBody: showDownloadManagerBodySelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeDownloadManagerAction: () => {
      dispatch(closeDownloadManagerAction());
    },
    toggleExpandCollapseDownloadManagerBody: () => {
      dispatch(toggleExpandCollapseDownloadManagerBodyAction());
    }
  };
}

export const DownloadManagerHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadManagerHeaderComponent);
