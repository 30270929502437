import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

// Selectors
import { facilitiesResourceSelectors } from "src/selectors/data_selectors";

// Constants
import { reduxFormFieldNames } from "src/constants";

// Utils
import { sortByName } from "src/utils/sort_by_name";

export function createFacilityOptionsSelector(formName) {
  return createSelector(
    getFormValues(formName),
    facilitiesResourceSelectors.dataSelector,
    (values, facilities) => {
      if (values && values[reduxFormFieldNames.organizationId]) {
        const filterFacilities = Object.values(facilities).filter(
          o =>
            o.attributes.organizationId ==
            values[reduxFormFieldNames.organizationId]
        );

        return sortByName(filterFacilities);
      }

      return [];
    }
  );
}
