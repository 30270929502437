import { connect } from "react-redux";
import flowRight from "lodash.flowright";
import get from "lodash.get";
import moment from "moment";
import JSZip from "jszip";

//HOC
import { withResourceLoaderFactory } from "src/hoc";

//Component
import { DownloadManagerListItemComponent } from "src/components/download_manager/download_manager_list_item_component";

//Selectors
import {
  assessmentAnswersResourceSelectors,
  assessmentsResourceSelectors,
  eventsResourceSelectors,
  facilitiesResourceSelectors
} from "src/selectors/data_selectors";
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";
import { seriesTrendsResourceSelectors } from "src/selectors/data_selectors/series_trends";
import { createBulkRevisionPdfDefinitionForFiltersSelector } from "src/selectors/pdf_definition_selectors/create_bulk_revision_pdf_definition_for_filters_selector";

// api selectors
import { createAllPagesRevisionPayloadsForFiltersSelector } from "src/selectors/api_selectors/global/revisions/create_all_pages_revision_payloads_for_filters_selector";
import { createAllPagesRevisionContextsForFiltersSelector } from "src/selectors/api_selectors/global/revisions/create_all_pages_revision_contexts_for_filters_selector";
import { createLatestAssessmentAnswerQueriesForAllRevisionsForFiltersSelector } from "src/selectors/api_selectors/global/assessment_answers/create_latest_assessment_answer_queries_for_all_revisions_for_filters_selector";
import { createAllAssessmentsWithSchemaQueriesForRevisionFiltersSelector } from "src/selectors/api_selectors/global/assessments/create_all_assessments_with_schema_queries_for_revision_filters_selector";
import { createLatestSignedEventQueryForRevisionsFilterSelector } from "src/selectors/api_selectors/global/events/create_latest_signed_event_query_for_revisions_filter_selector";
import { createLatestLockedEventQueryForRevisionsFilterSelector } from "src/selectors/api_selectors/global/events/create_latest_locked_event_query_for_revisions_filter_selector";
import { createAllSeriesTrendsQueriesForRevisionsFilterSelector } from "src/selectors/api_selectors/global/series_trends";
import { createAllLastFacilitiesQueryForRevisionsSelector } from "src/selectors/api_selectors/global/facilities/create_all_last_facilities_query_for_revisions_selector";

//Action
import {
  loadRevisionsAction,
  loadAssessmentAnswersAction,
  loadAssessmentsAction,
  loadSeriesTrendsAction,
  loadEventsAction,
  loadFacilitiesAction
} from "src/actions/data_actions";
import {
  markLocalExportCompletedAction,
  markLocalExportFailedAction,
  removeLocalExportAction
} from "src/actions/data_actions/local_exports";
import { create } from "src/actions/flashMessageActions";

// PDF
import pdfMake from "pdfmake/build/pdfmake";

function mapStateToProps(state, ownProps) {
  const revisions = revisionsResourceSelectors.createDataForContextSelector(
    createAllPagesRevisionContextsForFiltersSelector({
      filters: ownProps.filters
    })
  )(state);
  const fileGenerationFunction = createBulkRevisionPdfDefinitionForFiltersSelector(
    {
      revisions,
      filters: ownProps.filters
    }
  )(state);

  return {
    generateFileDefinition: async () => {
      return fileGenerationFunction().then(async pdfContentAndDefinitions => {
        let zip = new JSZip();

        if (!pdfContentAndDefinitions.length) {
          zip.folder("no results");
          return zip.generateAsync({ type: "blob" });
        }

        const filters = get(ownProps, "fileExport.attributes.filters", []);
        const date = filters.find(filt => filt.key == "date");

        const folderName = date
          ? moment(date.value).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");

        // Wrapper promises
        let promises = [];

        //resolve all promises
        const pdfData = await Promise.all(pdfContentAndDefinitions);

        // Add each pdf to zip file
        pdfData.forEach(pdfObject => {
          // wrap getBlob in a promise
          const wrapperPromise = new Promise(resolve => {
            pdfMake.createPdf(pdfObject.pdfDefinition).getBlob(pdfBlob => {
              // get values for file name
              const mrn = get(pdfObject, "content.patient.attributes.mrn", "");
              const firstName = get(
                pdfObject,
                "content.patient.attributes.firstName",
                ""
              );
              const lastName = get(
                pdfObject,
                "content.patient.attributes.lastName",
                ""
              );
              const woundNumber = get(
                pdfObject,
                "content.series.attributes.patientWoundNumber",
                ""
              );
              let createdAt = get(
                pdfObject,
                "content.revision.attributes.createdAt",
                ""
              );
              if (createdAt)
                createdAt = moment(createdAt).format("MMM DD YYYY");

              // Build file name
              let fileName = "";
              if (mrn) {
                fileName = `${mrn} - `;
              } else {
                if (firstName) fileName = `${firstName} `;
                if (lastName) fileName = `${fileName}${lastName} - `;
              }
              if (woundNumber) fileName = `${fileName}Wound #${woundNumber} - `;
              if (createdAt) fileName = `${fileName}${createdAt}`;

              // Add file to ZIP
              let pathName = `${folderName}/${fileName}.pdf`;
              let num = 1;
              while (!!zip.files[pathName]) {
                pathName = `${folderName}/${fileName} (${num}).pdf`;
                num++;
              }

              zip.file(pathName, pdfBlob);
              resolve();
            });
          });

          promises.push(wrapperPromise);
        });

        return Promise.all(promises).then(() => {
          return zip.generateAsync({ type: "blob" });
        });
      });
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeItem: id => {
      dispatch(removeLocalExportAction({ id }));
    },
    markLocalExportCompleted(fileExport) {
      dispatch(markLocalExportCompletedAction(fileExport));
    },
    markLocalExportFailed(fileExport) {
      dispatch(markLocalExportFailedAction(fileExport));
    },
    sendDownloadAction(zip, fileExport) {
      const fileName = `${fileExport.attributes.fileName}.zip`;
      saveAs(zip, fileName);
    },
    showDownloadError() {
      dispatch(
        create("Sorry, there was an error downloading your export", "error")
      );
    }
  };
}

export const LocalExportBulkRevisionsDownloadManagerListItemContainer = flowRight(
  // Revisions
  withResourceLoaderFactory({
    getResourceAction: loadRevisionsAction,
    resourceSelectors: revisionsResourceSelectors,
    createContextSelector: createAllPagesRevisionContextsForFiltersSelector,
    createPayloadSelector: createAllPagesRevisionPayloadsForFiltersSelector
  }),
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentAnswersAction,
    resourceSelectors: assessmentAnswersResourceSelectors,
    createContextSelector: createLatestAssessmentAnswerQueriesForAllRevisionsForFiltersSelector,
    createQuerySelector: createLatestAssessmentAnswerQueriesForAllRevisionsForFiltersSelector,
    waitForParentLoaderToComplete: true
  }),
  // Assessment and schemas for all assessment answers
  withResourceLoaderFactory({
    getResourceAction: loadAssessmentsAction,
    resourceSelectors: assessmentsResourceSelectors,
    createContextSelector: createAllAssessmentsWithSchemaQueriesForRevisionFiltersSelector,
    createQuerySelector: createAllAssessmentsWithSchemaQueriesForRevisionFiltersSelector,
    waitForParentLoaderToComplete: true
  }),
  // Events - latest signed for each revision
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestSignedEventQueryForRevisionsFilterSelector,
    createQuerySelector: createLatestSignedEventQueryForRevisionsFilterSelector,
    waitForParentLoaderToComplete: true
  }),
  // Events - latest locked for each revision
  withResourceLoaderFactory({
    getResourceAction: loadEventsAction,
    resourceSelectors: eventsResourceSelectors,
    createContextSelector: createLatestLockedEventQueryForRevisionsFilterSelector,
    createQuerySelector: createLatestLockedEventQueryForRevisionsFilterSelector,
    waitForParentLoaderToComplete: true
  }),
  // Series trends - for closure and push graphs
  withResourceLoaderFactory({
    getResourceAction: loadSeriesTrendsAction,
    resourceSelectors: seriesTrendsResourceSelectors,
    createContextSelector: createAllSeriesTrendsQueriesForRevisionsFilterSelector,
    createQuerySelector: createAllSeriesTrendsQueriesForRevisionsFilterSelector,
    waitForParentLoaderToComplete: true
  }),
  withResourceLoaderFactory({
    getResourceAction: loadFacilitiesAction,
    resourceSelectors: facilitiesResourceSelectors,
    createQuerySelector: createAllLastFacilitiesQueryForRevisionsSelector,
    createContextSelector: createAllLastFacilitiesQueryForRevisionsSelector,
    waitForParentLoaderToComplete: true
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DownloadManagerListItemComponent);
