import { createLocalizationDictionary } from "src/utils/downloadable_schemas";
import { globalActionTypes } from "src/action_types/section_action_types";

export function schemaLocalizationDictionaryReducer(state = {}, action) {
  switch (action.type) {
    case globalActionTypes.createSchemaLocalizationDictionary:
      return createLocalizationDictionary(action.payload);
    default:
      return state;
  }
}
