import { connect } from "react-redux";
import { push } from "react-router-redux";
import flowright from "lodash.flowright";

// HOC
import { withSeriesLoader, withPatientLoader } from "src/hoc";

// Components
import { SeriesDetailsComponent } from "src/components/series_details_component";

// Selectors
import {
  patientsResourceSelectors,
  seriesResourceSelectors,
  createSeriesForIdSelector,
  createLatestAssessmentAnswerForSeriesIdSelector,
  createPatientForIdSelector
} from "src/selectors/data_selectors";
import { revisionsResourceSelectors } from "src/selectors/data_selectors/revisions/revisions_resource_selectors";

import { patientBreadcrumbsEnabledSelector } from "src/selectors/config_selectors";
import {
  revisionComparisonEnabledSelector,
  revisionTreatmentNavigatorEnabledSelector,
  revisionsTableEnabledSelector,
  revisionContextEnabledSelector
} from "src/selectors/ui_config_selectors/patients";

function mapStateToProps(state, ownProps) {
  const { patientId, seriesId } = ownProps.match.params;
  const assessmentAnswer = createLatestAssessmentAnswerForSeriesIdSelector(
    ownProps.match.params.seriesId
  )(state);

  const revisions = revisionsResourceSelectors.createDataForContextSelectorWithString(
    seriesId
  )(state);

  const revisionsContextEnabled = revisionContextEnabledSelector(revisions)(
    state
  );

  return {
    patient: createPatientForIdSelector(patientId)(state),
    patientLoadState: patientsResourceSelectors.createContextLoadStateSelectorWithContextString(
      patientId
    )(state),
    series: createSeriesForIdSelector(seriesId)(state),
    seriesLoadState: seriesResourceSelectors.createContextLoadStateSelectorWithContextString(
      seriesId
    )(state),
    assessmentAnswer,
    breadcrumbsEnabled: patientBreadcrumbsEnabledSelector(state),
    revisionComparisonEnabled: revisionComparisonEnabledSelector(state),
    revisionsContextEnabled,
    revisionsTableEnabled: revisionsTableEnabledSelector(state),
    revisionTreatmentNavigatorEnabled: revisionTreatmentNavigatorEnabledSelector(
      state
    )
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    goBack() {
      dispatch(push(`/patients/${ownProps.match.params.patientId}`));
    }
  };
}

export const SeriesDetailsContainer = flowright(
  withPatientLoader,
  withSeriesLoader,
  connect(mapStateToProps, mapDispatchToProps)
)(SeriesDetailsComponent);
