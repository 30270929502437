import { styles } from "../../pdf_styles";
import { margins } from "../../margins";

import { getWoundDetailsColumns } from "src/pdfs/create_survey_details_pdf/get_wound_details_columns";

/**
 * Get table for pdf
 * @param {Object} woundDetails
 * @return {Object} returns null if table is empty
 */
export function getDetailsTable(woundDetails, options) {
  let body = [];
  const woundDetailsData = Object.values(woundDetails);

  // Left, top, right, bottom
  // page margins built into the table (no margin applied to the page)
  const cellMargin = [4, 4, 4, 4];
  const leftCellMargin = [margins.defaultPageMargin, 4, 4, 4];
  const rightCellMargin = [4, 4, margins.defaultPageMargin, 4];
  for (let i = 0; i < woundDetailsData.length; i++) {
    let columnsData =
      getWoundDetailsColumns(woundDetailsData[i], options) || {};
    const cellValues = (textValue, alignment, margin) => {
      return {
        text: textValue,
        style: styles.tallyTableContentCellBlackAndWhite,
        alignment: alignment,
        margin: margin || cellMargin
      };
    };
    body.push([
      cellValues(columnsData.name, "left", leftCellMargin),
      cellValues(columnsData.unit, "left"),
      cellValues(columnsData.floor, "left"),
      cellValues(columnsData.lastEvaluatedTimePeriod, "left"),
      cellValues(columnsData.woundInfo, "left"),
      cellValues(columnsData.acquired, "left"),
      cellValues(columnsData.progress, "left"),
      cellValues(columnsData.bodyLocation, "left"),
      cellValues(columnsData.surfaceArea, "left"),
      cellValues(columnsData.measurements, "left"),
      cellValues(columnsData.cleansingSolution, "left"),
      cellValues(columnsData.primaryDressing, "left", rightCellMargin)
    ]);
  }

  const { woundNumbersEnabled } = options;

  const headerCells = [
    {
      text: "Name",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: leftCellMargin
    },
    {
      text: "Unit",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    },
    {
      text: "Floor",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    },
    {
      text: `Last Evaluated in\nSelected Time Period`,
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    },
    {
      text: woundNumbersEnabled
        ? "Wound #,\nWound Type, Subtype"
        : "Wound Type, Subtype",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    },
    {
      text: "Acquired",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    },
    {
      text: "Progress",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    },
    {
      text: "Body\nLocation",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    },
    {
      text: "Surface\nArea",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    },
    {
      text: "Measurements",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    },
    {
      text: "Cleansing\nSolution",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    },
    {
      text: "Primary\nDressing",
      style: styles.tallyTableHeaderCellBlackAndWhite,
      alignment: "center",
      margin: cellMargin
    }
  ];

  // don't show empty table
  if (!body.length) return null;

  // Construct table
  return {
    table: {
      headerRows: 1,
      // Total width should add up to page size (792)
      // Note - this ignores table border width (negligible)
      widths: [84, 40, 40, 82, 117, 42, 42, 92, 42, 57, 77, 77],
      body: [headerCells, ...body]
    },
    layout: styles.tallyTable
  };
}
