import { connect } from "react-redux";

// Components
import { ExportsSectionFiltersComponent } from "src/components/exports_section_filters_component";

// Selectors
import { exportsSectionSelector } from "src/selectors/section_selectors/exports";

// UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";
const exportNameSelectors = SingleValueReduxUtil.createSelectors(
  "exportName",
  exportsSectionSelector
);

function mapStateToProps(state) {
  return {
    exportName: exportNameSelectors.fieldSelector(state)
  };
}

export const ExportsSectionFiltersContainer = connect(mapStateToProps)(
  ExportsSectionFiltersComponent
);
