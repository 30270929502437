import { trackError } from "src/error_tracking";

// Api
import { createWoundDetailsCsv } from "src/api/survey";

// Action types
import { dataActionTypes } from "src/action_types/data_action_types";

// Actions
import { create } from "src/actions/flashMessageActions";

// Analytics
import { startTimeTrack, finishTimeTrack } from "src/analytics";

/**
 * Action creator for setting loading state of survey wound details export to "loading"
 * @return {Object} action
 */
function surveyWoundDetailsExportLoading() {
  return {
    type: dataActionTypes.surveyWoundDetailsExportLoading
  };
}

/**
 * Action creator for setting loading state of survey wound details export to "ready"
 * @return {Object} action
 */
function surveyWoundDetailsExportReady() {
  return {
    type: dataActionTypes.surveyWoundDetailsExportLoadReady
  };
}

/**
 * Send api call to generate wound details csv, initiate download upon success.
 * @param {Object} payload The payload
 */
export function createAndDownloadSurveyWoundDetailsCsv(payload) {
  return dispatch => {
    // loading state
    dispatch(surveyWoundDetailsExportLoading());

    startTimeTrack("API_POST_CREATE_SURVEY_DETAILS_CSV");

    return createWoundDetailsCsv(payload)
      .then(response => {
        finishTimeTrack("API_POST_CREATE_SURVEY_DETAILS_CSV");

        // download result
        window.location.assign(response.data.url);
        // ready state
        dispatch(surveyWoundDetailsExportReady());
      })
      .catch(err => {
        finishTimeTrack("API_POST_CREATE_SURVEY_DETAILS_CSV", {
          error: true,
          errorCode: err.response ? err.response.status : undefined
        });
        trackError(err);

        // TODO: Make error message contextual to what actually happened
        dispatch(
          create("Sorry, there was an error downloading your export", "error")
        );
        dispatch(surveyWoundDetailsExportReady());
      });
  };
}
