import get from "lodash.get";
import { createSelector } from "reselect";
import { createCurrentPageContextSelector } from "src/selectors/api_selectors/wound_risk/wound/create_current_page_context_selector";

//Selectors
import {
  assessmentSummariesResourceSelectors,
  healingIndexResourceSelector
} from "src/selectors/data_selectors";

export function createDataForAssessmentSummariesAndHealingIndex() {
  return createSelector(
    assessmentSummariesResourceSelectors.createDataForContextSelector(
      createCurrentPageContextSelector()
    ),
    healingIndexResourceSelector.dataSelector,
    (assessmentSummaries, healingIndices) => {
      let assessmentSummariesDeepClone = JSON.parse(
        JSON.stringify(assessmentSummaries)
      );

      return assessmentSummariesDeepClone.map(assessmentSummary => {
        const resourceId = get(assessmentSummary, "attributes.resourceId");
        const resourceType = get(assessmentSummary, "attributes.resourceType");
        const healingIndicesArray = Object.values(healingIndices);
        const healingIndexMean = healingIndicesArray.find(healingIndex => {
          const healingIndexRevisionId = get(
            healingIndex,
            "attributes.revisionId",
            ""
          );
          return (
            resourceId === healingIndexRevisionId && resourceType === "Revision"
          );
        });

        if (healingIndexMean) {
          assessmentSummary.attributes.healingIndexMean = get(
            healingIndexMean,
            "attributes.mean",
            ""
          );
        }
        return assessmentSummary;
      });
    }
  );
}
