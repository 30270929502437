import React from "react";
import get from "lodash.get";
import PropTypes from "prop-types";
import moment from "moment";

import { woundProgress } from "src/mappings";
import { ageThisMoment } from "src/utils/timeUtils";
import {
  incompletePushScoreValues,
  incompleteBwatScoreValues
} from "src/constants";
import {
  displayValueForArea,
  displayValueForUnitless
} from "src/models/revisions";

import { GridColumn, GridRow } from "src/components/grid";
import { StudyBadgeComponent } from "src/components/study_badge_component";
import {
  LockedIconComponent,
  UnlockedIconComponent,
  SignedIconComponent
} from "src/components/icon_components";
import EncounterDisplayValueComponent from "src/components/encounters/encounter_display_value_component.js";

// Translations
import i18n from "src/translations";

import { createListStringFromArray } from "src/utils/format_string_list_from_array";

// Style
import styles from "./style.module.less";

import { renderHealingIndexMean } from "src/utils/woundRiskUtils";

export class RevisionSummaryFooterComponent extends React.PureComponent {
  static propTypes = {
    bwatScoreEnabled: PropTypes.bool,
    payerDashboardEnabled: PropTypes.bool.isRequired,
    payersForRevision: PropTypes.array.isRequired,
    healingIndexDashboardEnabled: PropTypes.bool,
    healingIndex: PropTypes.object,
    assessmentVersion: PropTypes.string.isRequired,
    visitSequenceEnabled: PropTypes.bool.isRequired,
    etiologyVerifiedQuestionEnabled: PropTypes.bool.isRequired
  };

  render() {
    const {
      revision,
      assessmentAnswer,
      frame,
      series,
      bwatScoreEnabled,
      payerDashboardEnabled,
      payersForRevision,
      visitSequenceEnabled,
      healingIndexDashboardEnabled,
      healingIndex,
      assessmentVersion,
      etiologyVerifiedQuestionEnabled
    } = this.props;

    const overallProgress = get(
      assessmentAnswer,
      "attributes.answersJson.overallProgress",
      null
    )
      ? i18n.t(
          `data.overallProgress.${assessmentVersion}.${assessmentAnswer.attributes.answersJson.overallProgress}`
        )
      : woundProgress.unknown;

    let woundAgeFromOrigin = ageThisMoment(
      get(revision, "attributes.createdAt", null),
      get(series, "attributes.originAt", null)
    );

    const woundAge = get(
      { assessmentAnswer },
      "assessmentAnswer.attributes.answersJson.woundAge",
      "unknown"
    );

    if (["chronic", "unknown"].includes(woundAge)) {
      woundAgeFromOrigin = i18n.t(`data.woundAge.${woundAge}`);
    }

    const area = displayValueForArea(
      get({ revision }, "revision.attributes.area", null)
    );

    const contentType = get(frame, "attributes.contentType", "");

    const closure = displayValueForUnitless(
      get(revision, "attributes.closure", null)
    );

    const woundType = get(
      assessmentAnswer,
      "attributes.answersJson.woundType",
      ""
    );

    const pushScore = get(
      assessmentAnswer,
      "attributes.answersJson.pushScore",
      null
    );

    const bwatScore = get(
      assessmentAnswer,
      "attributes.answersJson.bwatScore",
      null
    );

    const lockState = get(assessmentAnswer, "attributes.lockState", "");

    const signState = get(assessmentAnswer, "attributes.signState", "");

    const payers = createListStringFromArray(payersForRevision[revision.id]);

    const visitFrequency = get(
      assessmentAnswer,
      "attributes.answersJson.visitFrequency",
      "N/A"
    );

    const physicianVerified = get(
      assessmentAnswer,
      "attributes.answersJson.physicianVerified",
      "N/A"
    );

    const healingIndexMean = get(
      healingIndex.slice(-1)[0],
      "attributes.mean",
      ""
    );
    const healingIndexCategoryList = false;

    const encounterNumber = get(
      assessmentAnswer,
      "attributes.encounterNumber",
      null
    );
    const encounterStartAt = get(
      assessmentAnswer,
      "attributes.encounterStartAt",
      null
    );
    let encounterStartDateString = encounterStartAt
      ? moment(encounterStartAt).format("MMM DD, YYYY")
      : "N/A";

    return (
      <div className={`${styles["container"]}`}>
        <GridRow>
          <GridColumn md="6">
            <p
              className={`${styles["title"]}  ${styles["reduced-line-height"]}`}
            >
              {area}
            </p>
            <p
              className={`${styles["subtitle"]} ${styles["reduced-line-height"]}`}
            >
              {overallProgress + "-"} <span>{woundAgeFromOrigin}</span>
              {etiologyVerifiedQuestionEnabled && (
                <p
                  className={`${styles["subtitle"]} ${styles["reduced-line-height"]}`}
                >
                  {"Etiology Verified by Physician: "}{" "}
                  <span>{physicianVerified}</span>
                </p>
              )}
              {payerDashboardEnabled && (
                <p
                  className={`${styles["subtitle"]} ${styles["reduced-line-height"]}`}
                >
                  {"Payer(s): "} <span>{payers}</span>
                </p>
              )}
              {visitSequenceEnabled && (
                <p
                  className={`${styles["subtitle"]} ${styles["reduced-line-height"]}`}
                >
                  {"Current Wk Visit Freq: "} <span>{visitFrequency}</span>
                </p>
              )}
              {healingIndexDashboardEnabled && woundType == "pressureUlcer" && (
                <p
                  className={`${styles["subtitle"]} ${styles["reduced-line-height"]}`}
                >
                  {"Healing Risk Indicator (HRI) - "}{" "}
                  <span>
                    {renderHealingIndexMean(
                      healingIndexMean,
                      healingIndexCategoryList
                    )}
                  </span>
                </p>
              )}
              <EncounterDisplayValueComponent
                tagName="p"
                tagStyle={`${styles["subtitle"]} ${styles["reduced-line-height"]}`}
                encounterNumber={encounterNumber}
                encounterStartDateString={encounterStartDateString}
              />
            </p>
          </GridColumn>
          <GridColumn md="6">
            <div>
              {bwatScoreEnabled ? (
                <div className={styles["badge"]}>
                  <StudyBadgeComponent
                    title="BWAT"
                    value={
                      incompleteBwatScoreValues.includes(bwatScore)
                        ? "INC"
                        : bwatScore
                    }
                    width="66px"
                    height="66px"
                  />
                </div>
              ) : (
                (woundType == "pressureUlcer" ||
                  woundType == "pressureKennedy" ||
                  woundType == "pressureDeviceRelated") &&
                !bwatScoreEnabled && (
                  <div className={styles["badge"]}>
                    <StudyBadgeComponent
                      title="PUSH"
                      value={
                        incompletePushScoreValues.includes(pushScore)
                          ? "INC"
                          : pushScore
                      }
                      width="66px"
                      height="66px"
                    />
                  </div>
                )
              )}
              <div className={styles["badge"]}>
                <StudyBadgeComponent
                  title="% Changed"
                  value={
                    contentType == "declined"
                      ? "--"
                      : displayValueForUnitless(closure)
                  }
                  width="66px"
                  height="66px"
                />
              </div>
              <div className={`${styles["icon-small"]} pull-right`}>
                {lockState == "locked" && <LockedIconComponent />}
                {lockState != "locked" && <UnlockedIconComponent />}
              </div>
              {signState === "signed" && (
                <div className={`${styles["icon-small"]} pull-right`}>
                  <SignedIconComponent />
                </div>
              )}
            </div>
          </GridColumn>
        </GridRow>
      </div>
    );
  }
}
