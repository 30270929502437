import { adminActionTypes } from "src/action_types/section_action_types";

/**
 * Action to select partner id
 * @param {Array} partnerid of selected partner
 * @return {Object} Action
 */
export function selectedPartnerIdAction(partnerId) {
  return {
    type: adminActionTypes.selectedPartner,
    payload: partnerId
  };
}
