import { createSelector } from "reselect";
import { framesResourceSelectors } from "../frames_resource_selectors";
import { DEFAULT_FRAME_TYPES } from "../constants";
import get from "lodash.get";

/**
 * Create a selector to get the most recent frames for a set of revisions
 * @param {Object} props
 * @param {array} props.revisions
 * @return {Function} selector
 */

export function createDefaultFrameForRevisionSelector(revision) {
  let revisionFrames = get(revision, "attributes.frames", []).filter(
    frame => frame.revisionId == revision.id
  );

  try {
    return createSelector(framesResourceSelectors.dataSelector, () => {
      // Get all the frames for this revision
      let currentFrame;

      //Get the frame that mathes the type
      for (let i = 0; i < DEFAULT_FRAME_TYPES.length; i++) {
        currentFrame = revisionFrames.find(
          frame => frame.frameType == DEFAULT_FRAME_TYPES[i]
        );

        if (currentFrame) break;
      }

      if (get(currentFrame, "revisionId", null)) {
        currentFrame = {
          attributes: currentFrame,
          type: "frames",
          id: currentFrame.revisionId
        };
      }

      return currentFrame ? currentFrame : revisionFrames[0];
    });
  } catch (err) {
    console.error(err);
  }
}
