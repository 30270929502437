import { adminActionTypes } from "src/action_types/section_action_types";
import { apiPageLimits } from "src/constants";

export function selectedRiskAlertRulesTablePageLimitReducer(
  state = apiPageLimits.riskAlertRules,
  action
) {
  switch (action.type) {
    case adminActionTypes.selectRiskAlertRulesTablePageLimit:
      return action.payload;
    case adminActionTypes.selectRulesFacility:
      return apiPageLimits.riskAlertRules;
    case adminActionTypes.selectRulesOrganization:
      return apiPageLimits.riskAlertRules;
    default:
      return state;
  }
}
