import cloneDeep from "lodash.clonedeep";

import { actionTypes } from "../../action_types";
import get from "lodash.get";

export function revisionsComparisonReducer(state = {}, action) {
  let revisionsToCompare = cloneDeep(state);
  switch (action.type) {
    //Select a revision in a revision item
    case actionTypes.selectRevision: {
      const { seriesId, revisionId, index } = get(action, "payload", {});
      revisionsToCompare[seriesId][index] = revisionId;
      return revisionsToCompare;
    }

    //Add an empty revision item without any revision selected for a given series
    case actionTypes.addRevisionItem: {
      const seriesId = action.payload;
      //If seriesId key is not in revisionsToCompare object, initiate array
      //with one empty revision item denoted by empty string
      if (!revisionsToCompare[seriesId]) {
        revisionsToCompare[seriesId] = [""];
        //Otherwise, push empty revision item at end of array
      } else {
        revisionsToCompare[seriesId].push("");
      }
      return revisionsToCompare;
    }

    //Remove a revision item
    case actionTypes.removeRevisionItem: {
      const { seriesId, index } = get(action, "payload", {});
      revisionsToCompare[seriesId].splice(index, 1);
      return revisionsToCompare;
    }

    default:
      return state;
  }
}
