import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash.get";
import { Modal, ModalBody } from "react-bootstrap";
import { Link } from "react-router-dom";

//Constants
import { loadStates, assessmentTypes } from "src/constants";

//Translations
import i18n from "src/translations";

//Styles
import styles from "./style.module.less";

//Containers
import { AcknowledgeAlertContainer } from "src/containers/acknowledge_alert_container";

//Components
import { LoadingComponent } from "src/components/loading_component";
import { Button } from "src/ui-kit";
import { CollapsibleContainerComponent } from "src/components/collapsible_container_component";
import { RedDotIconComponent } from "src/components/icon_components";
import { GridRow, GridColumn } from "src/components/grid";

//Analytics
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";

export class PatientAlertsComponent extends React.Component {
  static propTypes = {
    patientAlerts: PropTypes.array.isRequired,
    loadState: PropTypes.string,
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    recordCount: PropTypes.number,
    firstPageContext: PropTypes.string.isRequired,
    sendIncrementPageAction: PropTypes.func.isRequired,
    sendResolveAlertAction: PropTypes.func.isRequired
  };

  state = {
    showAcknowledgeAlertModal: false,
    alertInModal: {}
  };

  acknowledgeAlert = alertId => {
    this.props.sendResolveAlertAction({ id: alertId, data: { id: alertId } });
  };

  onClickAcknowledgeLink = alert => {
    this.setState({
      ...this.state,
      alertInModal: alert,
      showAcknowledgeAlertModal: true
    });
  };

  hideAcknowledgeAlertModal = () => {
    this.setState({
      ...this.state,
      showAcknowledgeAlertModal: false
    });
  };

  loadMoreClicked = () => {
    this.props.sendIncrementPageAction(this.props.firstPageContext);
  };

  renderAlerts = () => {
    const { loadState, currentPage, patientAlerts } = this.props;
    if (currentPage == 0 && (!loadState || loadState == loadStates.loading)) {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_PATIENT_ALERTS">
          <LoadingComponent height="20px" />
        </StartRenderTimeTrackComponent>
      );
    } else if (loadState == loadStates.error) {
      return (
        <FinishRenderTimeTrackComponent
          eventName="RENDER_PATIENTS_ALERTS"
          error={true}
        >
          <LoadingComponent height="20px" />
        </FinishRenderTimeTrackComponent>
      );
    } else {
      if (patientAlerts.length) {
        return patientAlerts.map((alert, index) => {
          const alertDate = get(alert, "attributes.createdAt"),
            woundNumber = get(alert, "attributes.resource.patientWoundNumber"),
            alertDescription = get(
              alert,
              "attributes.ruleTemplateDescription",
              "Not Set"
            ),
            evaluationType = get(alert, "attributes.evaluationType"),
            patientId = get(alert, "attributes.patientId"),
            seriesId = get(alert, "attributes.resource.seriesId"),
            assessmentId = get(alert, "attributes.resource.assessmentId");

          return (
            <div key={index}>
              <GridRow>
                <GridColumn xs="7">
                  <span>
                    <RedDotIconComponent />

                    <b>
                      {alertDate
                        ? ` ${moment(alertDate).format("MMM D, YYYY")}: `
                        : " "}
                    </b>
                    {evaluationType === assessmentTypes.wound && woundNumber
                      ? `Wound # ${woundNumber} - ${alertDescription}`
                      : `${alertDescription}`}
                  </span>
                </GridColumn>
                <GridColumn xs="5">
                  <div className="pull-right">
                    <a
                      className={styles["action-link"]}
                      onClick={() => this.onClickAcknowledgeLink(alert)}
                    >
                      {i18n.t("actions.acknowledge")}
                    </a>
                    {evaluationType === "wound" && (
                      <Link
                        to={`/patients/${patientId}/series/${seriesId}`}
                        className={styles["action-link"]}
                      >
                        {i18n.t("actions.viewEvaluation")}
                      </Link>
                    )}
                    {evaluationType === "risk" && (
                      <Link
                        to={`/patients/${patientId}/assessments/${assessmentId}`}
                        className={styles["action-link"]}
                      >
                        {i18n.t("actions.viewEvaluation")}
                      </Link>
                    )}
                  </div>
                </GridColumn>
              </GridRow>
            </div>
          );
        });
      } else {
        return <p>{i18n.t("interaction.alerts.noAlertsForPatient")}</p>;
      }
    }
  };

  render() {
    const { recordCount, loadState, currentPage, pageCount } = this.props;
    const showNextPageButton =
      loadState != loadStates.loading &&
      loadState != loadStates.error &&
      currentPage < pageCount - 1 &&
      pageCount > 1;

    const showLoadingMore = loadState == loadStates.loading;

    return (
      <CollapsibleContainerComponent
        label={`Alerts ${recordCount ? `(${recordCount})` : ""}`}
        expanded={true}
        labelSize="lg"
      >
        {this.renderAlerts()}
        {showNextPageButton && (
          <div className={styles["load-more-container"]}>
            <Button className="dashboard-btn" onClick={this.loadMoreClicked}>
              {i18n.t("actions.loadMore")}
            </Button>
          </div>
        )}
        {showLoadingMore && <LoadingComponent height="50px" />}
        <Modal
          show={this.state.showAcknowledgeAlertModal}
          onHide={this.hideAcknowledgeAlertModal}
        >
          <ModalBody>
            <AcknowledgeAlertContainer
              alert={this.state.alertInModal}
              cancel={this.hideAcknowledgeAlertModal}
            />
          </ModalBody>
        </Modal>
      </CollapsibleContainerComponent>
    );
  }
}
