// @flow
import React, { PureComponent } from "react";
import "./style.less";

export default class Checkbox extends PureComponent {
  handleChange = (e: Object) => {
    return this.props.onChange(e.target.checked);
  };
  render() {
    return (
      <span>
        <input
          className="filter-checkbox"
          disabled={this.props.disabled}
          type="checkbox"
          onChange={this.handleChange}
          checked={this.props.checked}
        />
        {this.props.label}
      </span>
    );
  }
}
