import { connect } from "react-redux";
import React from "react";

// Selectors
import { localizationDictionaryForAllSchemasSelector } from "src/selectors/section_selectors/global";

export function withSchemaLocalizationDictionaryWrapper(WrappedComponent) {
  class SchemaLocalizationDictionaryWrapper extends React.PureComponent {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      schemaLocalizationDictionary: localizationDictionaryForAllSchemasSelector(
        state
      )
    };
  }

  return connect(mapStateToProps)(SchemaLocalizationDictionaryWrapper);
}
