// @flow
import React from "react";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import ChartComponent from "app/chart/component";
import FontAwesome from "react-fontawesome";
// import _ from 'lodash'
import Moment from "moment";
import { Row, Column, View, Badge, UL, ULItem } from "@sw/ui-kit";

//Components
import {
  StartRenderTimeTrackComponent,
  FinishRenderTimeTrackComponent
} from "src/components/analytics";
import { medlineColors, defaultColors } from "src/constants/branding_colors";
const LINE_TENSION = 0.075;

const options = {
  scales: {
    yAxes: [
      {
        // stacked: true
        scaleLabel: {
          display: true,
          labelString: "# of Residents",
          fontColor: "#999999"
        },
        ticks: {
          beginAtZero: true,
          type: "linear",
          callback: value => {
            if (Number.isInteger(value)) {
              return value;
            } else {
              return null;
            }
          }
        }
      }
    ],
    xAxes: [
      {
        // stacked: true
        ticks: {
          callback: (value, index, values) => {
            const { length } = values;
            if (index === 0) {
              return value;
            }

            if (length - 1 === index) {
              return value;
            }
            const stepSize = Math.round(length / 15);
            if (index % stepSize === 0) {
              return value;
            }
          }
        }
      }
    ]
  },
  legend: {
    display: false
  },
  tooltips: {
    mode: "label"
  }
};

export default class ProgressChartComponent extends React.Component {
  selectTab: Object;
  loadNext: Object;
  static propTypes = {
    chartData: PropTypes.any,
    aggregateData: PropTypes.any,
    startDate: PropTypes.any,
    locationIds: PropTypes.any,
    loaded: PropTypes.any,
    branding: PropTypes.string
  };

  state = {
    selectedTab: "veryHigh",
    chartData: null,
    contentPanelRef: null,
    contentPanelWidth: 0
  };
  constructor(props: Object) {
    super(props);
    const { tabs } = props;
    this.selectTab = Object.keys(tabs).reduce((finalResult, tab) => {
      finalResult[tab] = event => {
        event.preventDefault();
        this.setState({
          selectedTab: tab
        });
      };
      return finalResult;
    }, {});

    const startTime = new Moment("1900-01-01").toJSON();
    const endTime = new Moment(this.props.startDate).add(1, "d").toJSON();
    this.loadNext = Object.keys(tabs).reduce((finalResult, tab) => {
      finalResult[tab] = () => {
        const nextCursor = this.props.patients[tab].meta.next_cursor;
        if (typeof nextCursor !== "undefined") {
          this.props.tabs[tab].load(
            this.props.locationIds,
            startTime,
            endTime,
            nextCursor
          );
        }
      };
      return finalResult;
    }, {});
    this.state.selectedTab = props.startingRisk || "veryHigh";
    // $FlowFixMe
    this.state.chartData = this.generateChartData(props);
  }

  componentWillMount = () => {
    window.addEventListener("resize", this.resizeContentPanel);
  };

  componentWillReceiveProps = (nextProps: Object) => {
    if (
      this.props.chartData !== nextProps.chartData &&
      this.props.startDate !== nextProps.startDate
    ) {
      // $FlowFixMe
      this.setState({
        chartData: this.generateChartData(nextProps)
      });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeContentPanel);
  };

  assignContentPanelRef = (component: any) => {
    const element = findDOMNode(component);
    this.setState(
      {
        contentPanelRef: element
      },
      this.resizeContentPanel
    );
  };

  resizeContentPanel = () => {
    let { contentPanelRef } = this.state;

    if (contentPanelRef) {
      this.setState({
        contentPanelWidth: contentPanelRef.offsetWidth
      });
    }
  };

  getOffset = () => {
    const tabNames = Object.keys(this.props.tabs);
    const result = tabNames.reduce((finalResult, tab, i) => {
      finalResult[tab] = i;
      return finalResult;
    }, {})[this.state.selectedTab];
    return result;
  };

  generateChartData = (props: Object) => {
    const { tabs, chartData, branding } = props;
    const brandingColors =
      branding === "medline"
        ? medlineColors.riskChart
        : defaultColors.riskChart;
    const colourSchemes = {
      veryHigh: {
        backgroundColor: "rgba(220,220,220,0)",
        borderColor: brandingColors.veryHigh,
        pointBackgroundColor: brandingColors.veryHigh
      },
      high: {
        backgroundColor: "rgba(151,187,205,0)",
        borderColor: brandingColors.high,
        pointBackgroundColor: brandingColors.high
      },
      medium: {
        backgroundColor: "rgba(151,187,205,0)",
        borderColor: brandingColors.medium,
        pointBackgroundColor: brandingColors.medium
      },
      low: {
        backgroundColor: "rgba(151,187,205,0)",
        borderColor: brandingColors.low,
        pointBackgroundColor: brandingColors.low
      },
      no: {
        backgroundColor: "rgba(151,187,205,0)",
        borderColor: brandingColors.no,
        pointBackgroundColor: brandingColors.no
      },
      none: {
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "#000000",
        pointBackgroundColor: "#000000"
      }
    };
    const datasets = Object.keys(tabs).map(tab => {
      return {
        label: tabs[tab].label,
        pointStrokeColor: "#fff",
        pointBorderColor: "#fff",
        pointHighlightStroke: "rgba(151,187,205,1)",
        data: chartData[`${tab}Data`],
        tension: LINE_TENSION,
        ...colourSchemes[tab]
      };
    });
    return {
      labels: chartData.timeIntervals,
      datasets
    };
  };

  render = () => {
    if (!this.props.loaded) {
      return (
        <StartRenderTimeTrackComponent eventName="RENDER_RISK_RESIDENTS">
          <div className="loading-state">
            <p className="text-center">
              <FontAwesome name="spinner" size="2x" spin />
              <br />
              <br />
              Loading ...{" "}
            </p>
          </div>
        </StartRenderTimeTrackComponent>
      );
    }
    const { tabs, riskName, branding } = this.props;
    const tabNames = Object.keys(tabs);
    const brandingColors =
      branding === "medline"
        ? medlineColors.riskChart
        : defaultColors.riskChart;
    return (
      <FinishRenderTimeTrackComponent eventName="RENDER_RISK_RESIDENTS">
        <Row className="headline">
          <Column size={4}>
            <h3>{`${riskName || "Braden"} Risk - ${this.props.startDate.format(
              "MMMM Do YYYY"
            )}`}</h3>
          </Column>
          <Column size={7}>
            <UL className="list-unstyled chart-legend text-right">
              {tabNames.map((tab, i) => (
                <ULItem className="li-chart-legend" key={i}>
                  <Badge style={{ background: brandingColors[tab] }} />{" "}
                  {tabs[tab].label}
                </ULItem>
              ))}
            </UL>
          </Column>
          <Column size={1} />
        </Row>
        <Row className="chart-container">
          <Column>
            <View className="line-chart">
              <ChartComponent
                type="line"
                data={this.state.chartData}
                options={options}
                width={750}
                height={300}
              />
            </View>
          </Column>
        </Row>
      </FinishRenderTimeTrackComponent>
    );
  };
}
