import { formatValues } from "src/components/forms/schema_answers_form_component/format_values/index.js";
import { evaluatePredicate } from "src/models/assessment_answers";
import { get } from "lodash";

export function containsRequiredFieldsForAssessmentAnswers(
  schemas,
  assessmentAnswerAnswersJson,
  revisionIsFirstInSeries
) {
  const answersJson = assessmentAnswerAnswersJson;
  const formattedValues = formatValues(answersJson);
  let allRequiredHaveValues = true;

  const evaluateQuestion = (formattedValues, predicate) => {
    return evaluatePredicate({
      answersJson: formattedValues,
      revisionIsFirstInSeries,
      predicate: predicate
    });
  };

  schemas.map(assessmentSchema => {
    const schemaJsonQuestions = get(
      assessmentSchema,
      "attributes.schemaJson.questions",
      []
    );

    for (const index in schemaJsonQuestions) {
      const question = schemaJsonQuestions[index];
      const visiblePredicate = question.visiblePredicateJson;
      const enabledPredicate = question.enabledPredicateJson;

      let visibleQuestion = visiblePredicate
        ? evaluateQuestion(formattedValues, visiblePredicate)
        : true;
      let enabledQuestion = enabledPredicate
        ? evaluateQuestion(formattedValues, enabledPredicate)
        : true;

      if (
        visibleQuestion &&
        enabledQuestion &&
        isRequiredForSignAndLock(question)
      ) {
        if (!answersJson[question["name"]]) {
          answersJson[question["name"]];
          allRequiredHaveValues = false;
        }
      }
    }
  });

  return allRequiredHaveValues;
}

function isRequiredForSignAndLock(question) {
  if (
    question["required"] ||
    (question["requiredFor"] &&
      (question["requiredFor"].includes("sign") ||
        question["requiredFor"].includes("lock")))
  ) {
    return true;
  }

  return false;
}
