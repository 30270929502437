import { connect } from "react-redux";

// Constants
import { sections, bwatFilterRange } from "src/constants";

// Component
import { SideBarRangeFilterComponent } from "src/components/side_bar_range_filter_component";

// Selectors
import { bwatScoreMaxRangeFilterSelector } from "src/selectors/section_selectors/wounds/bwat_score_max_range_filter_selector";
import { bwatScoreMinRangeFilterSelector } from "src/selectors/section_selectors/wounds/bwat_score_min_range_filter_selector";

// Actions
import { createSectionActions } from "src/ui_processors/global/action_creators";

// normalizers
import {
  createMinValueOfRangeNormalizer,
  createMaxValueOfRangeNormalizer
} from "src/input_normalizers";
const minValueOfRangeNormalizer = createMinValueOfRangeNormalizer(
  bwatFilterRange.minValue
);
const maxValueOfRangeNormalizer = createMaxValueOfRangeNormalizer(
  bwatFilterRange.maxValue
);

function mapStateToProps(state) {
  return {
    minValue: bwatScoreMinRangeFilterSelector(state),
    maxValue: bwatScoreMaxRangeFilterSelector(state),
    minRangeAllowed: bwatFilterRange.minValue,
    maxRangeAllowed: bwatFilterRange.maxValue,
    minValueOfRangeNormalizer,
    maxValueOfRangeNormalizer
  };
}
const sectionActions = createSectionActions(sections.wounds);

// Dispatch Actions
function mapDispatchToProps(dispatch) {
  return {
    minChanged: minValue => {
      dispatch(
        sectionActions.setSingleValue({
          key: "minBwatScore",
          value: minValue
        })
      );
    },
    maxChanged: maxValue => {
      dispatch(
        sectionActions.setSingleValue({
          key: "maxBwatScore",
          value: maxValue
        })
      );
    },
    resetRange: () => {
      dispatch(
        sectionActions.setSingleValue({
          key: "minBwatScore",
          value: ""
        })
      );
      dispatch(
        sectionActions.setSingleValue({
          key: "maxBwatScore",
          value: ""
        })
      );
    }
  };
}

export const SideBarBwatScoreFilterContainerWounds = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBarRangeFilterComponent);
