import { adminActionTypes } from "src/action_types/section_action_types";

/**
 * Action to select patient table page number
 * @param {number} pageNumber selected table page number
 * @return {Object} Action
 */

export function selectPatientTablePageNumberAction(pageNumber) {
  return {
    type: adminActionTypes.selectPatientTablePageNumber,
    payload: pageNumber
  };
}
