import { connect } from "react-redux";

//Translations
import i18n from "src/translations";

//Components
import { SelectComponent } from "src/components/ui_kit";

//Selectors
import {
  adminSectionSelector,
  userStatesSelector
} from "src/selectors/section_selectors/admin";

//UI Processors
import SingleValueReduxUtil from "src/ui_processors/single_value";

const selectUsersStateFilterSelector = SingleValueReduxUtil.createSelectors(
  "selectedUserState",
  adminSectionSelector
);

const selectUsersStateFilterActions = SingleValueReduxUtil.createActionCreators(
  "selectedUserState"
);

function mapStateToProps(state) {
  return {
    options: userStatesSelector(state),
    label: i18n.t(`headers.state`),
    value: selectUsersStateFilterSelector.fieldSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange(value) {
      dispatch(selectUsersStateFilterActions.setValue(value));
    }
  };
}

export const AdminUserStateSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectComponent);
