import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Utils
import { propsAreDifferent } from "src/utils/props_are_different";

// Contants
import { loadStates } from "src/constants";

/**
 * Creates a HOC that fetches the resource associated with the supplied values
 * @param {Object} payload
 * @param {Object} payload.getResourceAction Action to fetch the resource
 * @param {Object} payload.resourceSelectors Selectors for the redux resource
 * @param {function} payload.createContextSelector Function to create a selector for the context to load
 * @param {function} payload.querySelector Selector for the query to send with api request (not needed with payloadSelector)
 * @param {function} payload.payloadSelector Selector for the payload to send with api request (not needed with querySelector)
 * @param {bool} payload.performSynchronously
 * @param {bool} payload.waitForParentLoaderToComplete if true, loader will wait for any parent loaders to finish loading before it starts
 * @return {function} Higher Order Component function wrapped by the loader component
 */
export function withResourceLoaderFactory({
  getResourceAction,
  resourceSelectors,
  createContextSelector,
  createQuerySelector,
  createPayloadSelector,
  performSynchronously = false,
  waitForParentLoaderToComplete = false,
  refreshOnMount = false,
  additionalActions = []
}) {
  return function withResourceLoader(WrappedComponent) {
    class ResourceLoader extends React.PureComponent {
      static propTypes = {
        context: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        contextLoadState: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array
        ]),
        query: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        payload: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        loadingObjectsByContext: PropTypes.object,
        sendLoadResourceAction: PropTypes.func.isRequired
      };

      constructor(props) {
        super(props);
        if (refreshOnMount) {
          this.state = {
            refreshedContexts: {}
          };
        } else {
          this.state = {};
        }

        this.loadData(props, this.state);
      }

      componentDidUpdate(prevProps) {
        if (
          propsAreDifferent(
            prevProps,
            this.props,
            "context",
            "contextLoadState"
          )
        ) {
          this.loadData(this.props, this.state);
        }
      }

      loadData = props => {
        const {
          context,
          query,
          payload,
          contextLoadState,
          sendLoadResourceAction,
          addditionalActions
        } = props;

        const { refreshedContexts } = this.state;

        if (!context) return;

        if (Array.isArray(context)) {
          if (performSynchronously) {
            context.reduce((p, c, index) => {
              if (
                !contextLoadState[index] ||
                contextLoadState[index] === "reload"
              ) {
                return p.then(() => {
                  return sendLoadResourceAction({
                    context: c,
                    query: query ? query[index] : undefined,
                    payload: payload ? payload[index] : undefined,
                    additionalActions
                  });
                });
              }

              return p;
            }, Promise.resolve());
          } else {
            context.forEach((c, index) => {
              if (
                !contextLoadState[index] ||
                contextLoadState[index] === "reload" ||
                (refreshOnMount && !refreshedContexts[c])
              ) {
                sendLoadResourceAction({
                  context: c,
                  query: query ? query[index] : undefined,
                  payload: payload ? payload[index] : undefined,
                  addditionalActions
                });

                if (refreshOnMount) {
                  this.setState({
                    refreshedContexts: {
                      ...refreshedContexts,
                      [context]: true
                    }
                  });
                }
              }
            });
          }
        } else if (
          !contextLoadState ||
          contextLoadState === "reload" ||
          (refreshOnMount && !refreshedContexts[context])
        ) {
          sendLoadResourceAction({
            context,
            query,
            payload,
            additionalActions
          });

          if (refreshOnMount) {
            this.setState({
              refreshedContexts: {
                ...refreshedContexts,
                [context]: true
              }
            });
          }
        }
      };

      /**
       * Send a query using a destructured loading object
       * @param {Object} loadingObject
       * @param {String} loadingObject.context
       * @param {String} loadingObject.query
       * @param {Object} loadingObject.payload
       */
      retry = ({ context, query, payload, additionalActions }) => {
        const { sendLoadResourceAction } = this.props;
        return sendLoadResourceAction({
          context,
          query,
          payload,
          additionalActions
        });
      };

      /**
       * For the current context(s) - create loading objects that can be passed down to dependent components
       * @returns {Object} loading objects by context
       */
      getLoadingObject() {
        let {
          loadingObjectsByContext,
          context,
          contextLoadState,
          query,
          payload
        } = this.props;

        let newLoadingObjects;
        if (Array.isArray(context)) {
          newLoadingObjects = context.reduce((agg, contextString, index) => {
            agg[contextString] = {
              context: contextString,
              loadState: contextLoadState[index],
              retry: this.retry,
              query: query ? query[index] : undefined,
              payload: payload ? payload[index] : undefined
            };

            return agg;
          }, {});
        } else {
          newLoadingObjects = {
            [context]: {
              context,
              loadState: contextLoadState,
              retry: this.retry,
              query,
              payload
            }
          };
        }

        return {
          ...loadingObjectsByContext,
          ...newLoadingObjects
        };
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            loadingObjectsByContext={this.getLoadingObject()}
          />
        );
      }
    }

    function mapStateToProps(state, props) {
      // If we are waiting for all the parent loaders to complete then we'll
      // return undefined props if everything has not loaded - and rely on
      // React to not re-render the loader component
      // NOTE
      // this is done to:
      // - somewhat improved lag issues when loading a lot of data
      // - act as a mock queue for api requests to lessen the api load
      // The real solution will be to speed up the selectors that generate the queries and implment real queue functionality
      if (
        waitForParentLoaderToComplete &&
        props.loadingObjectsByContext &&
        Object.values(props.loadingObjectsByContext).some(
          loadingObject => loadingObject.loadState != loadStates.loaded
        )
      ) {
        return {
          context: undefined,
          contextLoadState: undefined,
          query: undefined,
          payload: undefined
        };
      }

      const contextSelector = createContextSelector(props);

      return {
        context: contextSelector(state),
        contextLoadState: resourceSelectors.createLoadStateForContextSelector(
          contextSelector
        )(state),
        query: createQuerySelector
          ? createQuerySelector(props)(state)
          : undefined,
        payload: createPayloadSelector
          ? createPayloadSelector(props)(state)
          : undefined,
        additionalActions
      };
    }

    function mapDispatchToProps(dispatch) {
      return {
        sendLoadResourceAction(payload) {
          return dispatch(getResourceAction(payload));
        }
      };
    }

    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(ResourceLoader);
  };
}
