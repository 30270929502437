import React from "react";
import PropTypes from "prop-types";

//Translations
import i18n from "src/translations";

//Components
import { TabViewComponent } from "src/components/tab_view_component";

//Containers
import { PatientOverviewTabContentContainer } from "src/containers/patient_overview/patient_overview_tab_content_container";

export class PatientOverviewComponent extends React.Component {
  static propTypes = {
    tabs: PropTypes.array.isRequired,
    selectedTab: PropTypes.string.isRequired,
    sendSetTabValueAction: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { tabs, selectedTab } = this.props;

    if (!selectedTab && tabs.length) {
      this.onSelectTab(tabs[0]);
    }
  }

  onSelectTab = tab => {
    this.props.sendSetTabValueAction(tab);
  };

  render() {
    const { selectedTab } = this.props;
    const tabs = this.props.tabs.map(tab => {
      return {
        value: tab,
        title: `${i18n.t(`headers.${tab}`)}`
      };
    });

    return (
      <TabViewComponent
        tabHeaderType="title"
        tabs={tabs}
        selectedTabValue={selectedTab}
        selectTab={this.onSelectTab}
        horizontalSpread={true}
      >
        <PatientOverviewTabContentContainer />
      </TabViewComponent>
    );
  }
}
