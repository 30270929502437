import { createActionTypes } from "../create_action_types";

export function createActionCreators(fieldName) {
  const actionTypes = createActionTypes(fieldName);

  function setValue(value) {
    return {
      type: actionTypes.setValue,
      payload: value
    };
  }

  function toggleValue() {
    return {
      type: actionTypes.toggleValue
    };
  }

  return {
    setValue,
    toggleValue
  };
}
