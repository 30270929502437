import { combineReducers } from "redux";
import { dateSelectionReducer } from "./date_selection_reducer";

//Section Reducer Factory
import {
  createSectionFiltersReducer,
  createSectionSelectedFilterIdReducer
} from "src/ui_processors/global/reducer_creators";

// Constants
import { sections } from "src/constants";

export const surveySectionReducers = combineReducers({
  dateSelection: dateSelectionReducer,
  filters: createSectionFiltersReducer(sections.survey),
  selectedFilterId: createSectionSelectedFilterIdReducer(sections.survey)
});
