import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { studiesResourceSelectors } from "../studies_resource_selectors";
import { createSeriesForRevisionsSelector } from "../../series/create_series_for_revisions_selector";

/**
 * create a selector with an array of revisions that returns studies by revision id
 * @param {Object} props
 * @param {array} revisions
 * @returns {Function} selector
 */
export function createStudiesForRevisionsSelector({ revisions }) {
  return createSelector(
    studiesResourceSelectors.dataSelector,
    createSeriesForRevisionsSelector({ revisions }),
    (studies, seriesByRevisionId) => {
      return Object.keys(seriesByRevisionId).reduce((agg, revId) => {
        const series = seriesByRevisionId[revId];
        const studyId = get(series, "attributes.studyId");
        agg[revId] = studies[studyId];
        return agg;
      }, {});
    }
  );
}
