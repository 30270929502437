import { createSelector } from "reselect";

// Constants
import { appModes } from "src/constants";

// Selectors
import { modeSelector } from "../mode_selector";
import { partnerNameSelector } from "../partner_name_selector";

/**
 * Memoized selector to get the Disabled/Enabled state of the side bar.
 * When the side bar is disabled it can not be opened and closed and will not
 * be shown.
 */
export const sideBarEnabledSelector = createSelector(
  partnerNameSelector,
  modeSelector,
  (partnerName, mode) => {
    if (partnerName == "pcc_wound" || mode == appModes.patientViewHospital) {
      return false;
    }

    return true;
  }
);
