import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { patientsResourceSelectors } from "../patients_resource_selectors";
import { createStudyForRevisionSelector } from "../../studies/create_study_for_revision_selector";

/**
 * create a selector with a revision that returns a patient
 * @param {Object} props
 * @param {array} revision
 * @returns {Function} selector
 */

export function createPatientForRevisionSelector(revision) {
  return createSelector(
    patientsResourceSelectors.dataSelector,
    createStudyForRevisionSelector(revision),
    (patients, study) => {
      const patientId = get(study, "attributes.patientId");
      const patient = patients[patientId];
      return patient;
    }
  );
}
