import { connect } from "react-redux";
import flowRight from "lodash.flowright";

// Constants
import { sections } from "src/constants";
import { surveyOptionsConfiguration } from "src/constants/filters_configurations/survey";

// Components
import { SavedFilterVisualizationComponent } from "src/components/saved_filter_visualization_component";

// Selectors
import { woundTypesConfigurationFilterForSchemaPolicySelector } from "src/selectors/ui_config_selectors/global/wound_type_filter_configuration_selector";
import { locationsFilterConfigSelector } from "src/selectors/ui_config_selectors/global";

// UI Processors
import { createSectionSelectors } from "src/ui_processors";

// HOC
import { withSchemaLocalizationDictionaryWrapper } from "src/hoc/with_schema_localization_dictionary_wrapper";

const sectionSelectors = createSectionSelectors(sections.survey);

function mapStateToProps(state) {
  // wound types
  const woundTypesFilterConfig = woundTypesConfigurationFilterForSchemaPolicySelector(
    state
  );
  const woundTypesFilterCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    woundTypesFilterConfig
  )(state);

  // Survey options
  const surveyOptionsFilterCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    surveyOptionsConfiguration
  )(state);

  // Locations
  const locationConfig = locationsFilterConfigSelector(state);
  const locationCheckedState = sectionSelectors.createFilterCheckedStateSelector(
    locationConfig
  )(state);

  return {
    filterUiConfigs: [
      woundTypesFilterConfig,
      surveyOptionsConfiguration,
      locationConfig
    ],
    filterCheckedStates: [
      woundTypesFilterCheckedState,
      surveyOptionsFilterCheckedState,
      locationCheckedState
    ]
  };
}

export const SurveySavedFilterVisualizationContainer = flowRight(
  withSchemaLocalizationDictionaryWrapper,
  connect(mapStateToProps)
)(SavedFilterVisualizationComponent);
