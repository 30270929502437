export const subtypeKeyValueForAttribute = {
  pressureUlcerUnstageableCause: {
    key: "pressureUlcerStage",
    value: "unstageable"
  },
  pressureKennedyUnstageableCause: {
    key: "pressureKennedyStage",
    value: "unstageable"
  },
  pressureDeviceRelatedUnstageableCause: {
    key: "pressureDeviceRelatedStage",
    value: "unstageable"
  }
};
