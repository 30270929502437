import { createActionTypes } from "../create_action_types";

export function createActionCreators(fieldName) {
  const actionTypes = createActionTypes(fieldName);

  function setValuesForKeys(payload) {
    return {
      type: actionTypes.setValuesForKeys,
      payload
    };
  }

  return {
    setValuesForKeys
  };
}
