import { createSelector } from "reselect";
import get from "lodash.get";

//Selectors
import {
  healingIndexResourceSelector,
  averageHealingIndexResourceSelector
} from "src/selectors/data_selectors";
import { createHealingIndexContextSelector } from "src/selectors/api_selectors/global/healing_index/create_healing_index_context_selector";
import { createAverageHealingIndexContextSelector } from "src/selectors/api_selectors/global/healing_index/create_average_healing_index_context_selector";

export function healingRiskChartDefinitionSelector(ownProps) {
  return createSelector(
    healingIndexResourceSelector.createDataForContextSelector(
      createHealingIndexContextSelector(ownProps)
    ),
    averageHealingIndexResourceSelector.createDataForContextSelector(
      createAverageHealingIndexContextSelector(ownProps)
    ),
    (healingRisk, averageHealingRisk) => {
      return getChartDefinition(healingRisk, averageHealingRisk);
    }
  );
}

const getChartDefinition = (healingIndex, averageHealingIndex) => {
  const averageData = averageHealingIndex[Object.keys(averageHealingIndex)[0]];

  var riskPlots = [];
  var riskPlot = {};

  var riskPlotsLower = [];
  var riskPlotLower = {};

  var riskPlotsUpper = [];
  var riskPlotUpper = {};

  var averageRiskPlots = [];
  var averageRiskPlot = {};
  var revisionId = get(healingIndex.slice(-1)[0], "attributes.revisionId", "");

  healingIndex.forEach(item => {
    if (item.attributes.mean !== null) {
      riskPlot = {
        x: item.attributes.deltaDayRevision,
        y: Math.floor(Number(item.attributes.mean.toFixed(2)) * 100)
      };
    } else {
      riskPlot = {
        x: item.attributes.deltaDayRevision,
        y: null
      };
    }

    if (item.attributes.lower !== null) {
      riskPlotLower = {
        x: item.attributes.deltaDayRevision,
        y: Math.floor(Number(item.attributes.lower.toFixed(2)) * 100)
      };
    } else {
      riskPlotLower = {
        x: item.attributes.deltaDayRevision,
        y: null
      };
    }

    if (item.attributes.upper !== null) {
      riskPlotUpper = {
        x: item.attributes.deltaDayRevision,
        y: Math.floor(Number(item.attributes.upper.toFixed(2)) * 100)
      };
    } else {
      riskPlotUpper = {
        x: item.attributes.deltaDayRevision,
        y: null
      };
    }

    if (revisionId === item.attributes.revisionId) {
      riskPlots.pop();
      riskPlots.push(riskPlot);
      riskPlotsLower.pop();
      riskPlotsLower.push(riskPlotLower);
      riskPlotsUpper.pop();
      riskPlotsUpper.push(riskPlotUpper);
    } else {
      revisionId = item.attributes.revisionId;
      riskPlots.push(riskPlot);
      riskPlotsLower.push(riskPlotLower);
      riskPlotsUpper.push(riskPlotUpper);
    }
  });

  var latestRevisionTime = get(riskPlots.slice(-1)[0], "x", "");
  var xAxisLimit =
    latestRevisionTime + 30 > 357 ? 357 : latestRevisionTime + 30;

  if (typeof averageData !== "undefined" && "attributes" in averageData) {
    averageData.attributes.averagePayload.forEach(item => {
      averageRiskPlot = {
        x: item.day,
        y: Math.floor(Number(item.mean.toFixed(2)) * 100)
      };
      averageRiskPlots.push(averageRiskPlot);
    });
  }

  var maxUpper = Math.max.apply(
    Math,
    riskPlotsUpper.map(function(o) {
      return o.y;
    })
  );
  var maxAverage = Math.max.apply(
    Math,
    averageRiskPlots.map(function(o) {
      return o.y;
    })
  );
  var maximums = [maxUpper, maxAverage];
  var maximum =
    Math.max.apply(
      Math,
      maximums.map(function(o) {
        return o;
      })
    ) + 20;
  var limits = [maximum, 100];
  var yAxisLimit = Math.min.apply(
    Math,
    limits.map(function(o) {
      return o;
    })
  );

  var maxLinePlot = [];
  var maxLinePlots = [];
  averageRiskPlots.forEach(item => {
    maxLinePlot = { x: item.x, y: 100 };
    maxLinePlots.push(maxLinePlot);
  });

  const chartDefinition = {
    type: "line",
    data: {
      datasets: [
        {
          label: "Risk Index",
          data: riskPlots,
          backgroundColor: "Black",
          borderColor: "Black",
          fill: false
        },
        {
          label: "Upper Bound Risk Index",
          data: riskPlotsUpper,
          backgroundColor: "rgb(0,0,0,0.2)",
          borderColor: "transparent",
          pointRadius: 0,
          fill: 0
        },
        {
          label: "Lower Bound Risk Index",
          data: riskPlotsLower,
          backgroundColor: "rgb(0,0,0,0.2)",
          borderColor: "transparent",
          pointRadius: 0,
          fill: 0
        },
        {
          label: "Average Risk Index",
          data: averageRiskPlots,
          backgroundColor: "rgba(0, 0, 100, 0.3)",
          borderColor: "rgba(0, 100, 0, 0.9)",
          pointRadius: 0
        },
        {
          label: "Max Line",
          data: maxLinePlots,
          backgroundColor: "rgba(100,0,0,0.3)",
          borderColor: "transparent",
          pointRadius: 0,
          fill: "-1"
        }
      ]
    },
    options: {
      scales: {
        xAxes: [
          {
            type: "linear",
            position: "bottom",
            scaleLabel: {
              display: true,
              labelString: "Number Of Days Since First Assessment"
            },
            ticks: {
              suggestedMin: 0,
              max: parseInt(xAxisLimit)
            }
          }
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Healing Risk Indicator (HRI)"
            },
            ticks: {
              suggestedMin: 0,
              max: Number(yAxisLimit.toFixed(2)), // set this adaptively based on max upper limit
              callback: function(value) {
                switch (true) {
                  case value === 20:
                    return "Very Low " + value;
                  case value === 40:
                    return "Low " + value;
                  case value === 60:
                    return "Medium " + value;
                  case value === 80:
                    return "High " + value;
                  case value === 100:
                    return "Very High " + value;
                  default:
                    return value;
                }
              }
            }
          }
        ]
      },
      legend: { display: false }
    }
  };

  return chartDefinition;
};
