import { createSelector } from "reselect";
import get from "lodash.get";

// Selectors
import { createNestedAssessmentSchemasForAssessmentAnswerSelector } from "src/selectors/data_selectors";

/**
 * Create a selector that gets the schemas for a wound assessment (progress tab)
 * @param {Object} assessmentAnswer
 * @returns {Function} selector
 */
export function createProgressTabSchemasForAssessmentAnswerSelector(
  assessmentAnswer
) {
  return createSelector(
    createNestedAssessmentSchemasForAssessmentAnswerSelector(assessmentAnswer),
    assessmentSchemas => {
      const ordersSchema = assessmentSchemas.find(
        schema => get(schema, "attributes.schemaJson.name", "") === "orders"
      );
      const notesSchema = assessmentSchemas.find(
        schema => get(schema, "attributes.schemaJson.name", "") === "notes"
      );

      let progressTabSchemas = [];
      if (ordersSchema) progressTabSchemas.push(ordersSchema);
      if (notesSchema) progressTabSchemas.push(notesSchema);

      return progressTabSchemas;
    }
  );
}
