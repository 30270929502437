import { createSelector } from "reselect";

//Payload Generator
import { createLatestEventGetPayload } from "src/api/events/payload_generators";

// Utils
import { buildQueryString } from "src/utils/build_query_string";

/**
 * Create a selector to get the api query for the latest signed event for a revision
 * @param {Object} props
 * @param {Object} props.revision
 * @returns {function} selector
 */
export function createLatestSignedEventForRevisionsQuerySelector({
  revisions
}) {
  return createSelector(() => {
    let revisionIds = revisions.map(r => r.id);
    return buildQueryString(
      createLatestEventGetPayload({
        revisionId: revisionIds,
        eventType: "signed"
      })
    );
  });
}
