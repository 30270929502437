import React from "react";
import PropTypes from "prop-types";
import { NavLink, Route, Switch } from "react-router-dom";
import DatePicker from "react-datepicker";

// containers
import { WoundAssessmentProgressTabViewContainer } from "src/containers/wound_assessment_progress_tab_view_container";
import { WoundsChartTabContainer } from "src/containers/wounds_chart_tab_container";
import { WoundsLocationTabContainer } from "src/containers/wounds_location_tab_container";
import { WoundsWoundTabPdfButtonContainer } from "src/containers/wounds_wound_tab_pdf_button_container";
import { WoundsWoundTabOptimizedPdfButtonContainer } from "src/containers/wounds_wound_tab_optimized_pdf_button_container";

// Constants
import { analyticsEventNames } from "src/constants";

export class WoundsSectionComponent extends React.PureComponent {
  static propTypes = {
    selectedDate: PropTypes.object.isRequired,
    selectDate: PropTypes.func.isRequired,
    woundsExportPdfEnabled: PropTypes.bool.isRequired,
    selectTab: PropTypes.func.isRequired,
    isWoundsClinicalChartOptimizationEnabled: PropTypes.bool.isRequired
  };

  /**
   * handle user selecting a new date
   * @param {String} date
   */
  onDatePickerChange = date => {
    this.props.selectDate(date);
  };

  onClickWoundsTab = () => {
    this.props.selectTab("wounds");
  };

  onClickChartTab = () => {
    analyticsEventNames.woundsProgressChartPanel;
    this.props.selectTab("chart");
  };

  onClickLocationsTab = () => {
    this.props.selectTab("locations");
  };

  renderPDFButton = () => {
    const { isWoundsClinicalChartOptimizationEnabled } = this.props;
    if (isWoundsClinicalChartOptimizationEnabled) {
      return <WoundsWoundTabOptimizedPdfButtonContainer />;
    } else {
      return <WoundsWoundTabPdfButtonContainer />;
    }
  };

  /**
   * TODO: Move this to a central component that renders bar according to Route Switch
   * Renders the control bar on the top of the page
   */
  renderControlNavbar() {
    const { woundsExportPdfEnabled, location } = this.props;
    return (
      <nav className="navbar dashboard-control-navbar">
        <div className="navbar-header">
          <h1 className="navbar-text">Wound Status</h1>
        </div>

        <form className="navbar-form navbar-right">
          <div className="btn-group control-navbar-element" role="group">
            <NavLink
              to="/wounds"
              exact={true}
              className="btn btn-default"
              activeClassName="active"
              onClick={() => this.onClickWoundsTab()}
            >
              Wounds
            </NavLink>
            <NavLink
              to="/wounds/chart"
              exact={true}
              className="btn btn-default"
              activeClassName="active"
              onClick={() => this.onClickChartTab()}
            >
              Chart
            </NavLink>
            <NavLink
              to="/wounds/locations"
              className="btn btn-default"
              activeClassName="active"
              onClick={() => this.onClickLocationsTab()}
            >
              Locations
            </NavLink>
          </div>

          <span className="control-navbar-element">
            <DatePicker
              dateFormat="MMMM D, YYYY"
              selected={this.props.selectedDate}
              onChange={this.onDatePickerChange}
              className="form-control"
            />
          </span>
          {woundsExportPdfEnabled &&
            location.pathname === "/wounds/chart" &&
            this.renderPDFButton()}
        </form>
      </nav>
    );
  }

  /**
   * Render the content of the wound dashboard - ie. wounds vs. locations
   */
  renderWoundsContent() {
    return (
      <Switch>
        <Route
          exact
          path="/wounds/locations"
          component={WoundsLocationTabContainer}
        />
        <Route exact path="/wounds/chart" component={WoundsChartTabContainer} />
        <Route
          path="/wounds"
          component={WoundAssessmentProgressTabViewContainer}
        />
      </Switch>
    );
  }

  render() {
    return (
      <div>
        {this.renderControlNavbar()}
        {this.renderWoundsContent()}
      </div>
    );
  }
}
