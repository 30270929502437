import React from "react";
export class PopulationIconComponent extends React.Component {
  render() {
    return (
      <svg
        width="300"
        height="248"
        viewBox="0 0 300 248"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Page-1" fill="none" fillRule="evenodd">
          <g
            id="Custom-Preset-3"
            transform="translate(0 -25)"
            fillRule="nonzero"
            fill="#000"
          >
            <g id="Population-Dashboard-Icon" transform="translate(-4 25)">
              <path
                d="M0.2,249.6 C0.2,235 0.1,221.4 0.3,207.7 C0.3,206.5 1.8,205.1 3,204.3 C39.7,181.2 76.5,158.1 113.3,135 C120,130.8 126.7,131.4 133.6,134.3 C153.1,142.4 172.6,150.2 192.1,158.4 C196.2,160.1 199.5,159.9 203.2,157.7 C215.7,150.3 228.4,143.2 240.9,135.9 C242.5,135 244.1,133.9 245.9,132.7 C242.4,126.3 239.2,120.3 235.2,113 C260.2,114.8 283.9,116.6 308.6,118.4 C297.4,140.6 286.7,162 275.4,184.5 C271.3,177.5 267.9,171.6 264.3,165.3 C261.8,166.6 259.5,167.8 257.3,169 C241.8,177.9 226.3,186.6 211,195.7 C203.7,200 196.7,200.5 188.9,197.1 C170.2,189.1 151.1,181.8 132.4,173.8 C127.4,171.7 123.6,172 119,174.9 C82,198.5 44.8,221.9 7.6,245.3 C5.5,246.7 3.2,247.8 0.2,249.6 Z"
                id="Shape"
              />
              <path
                d="M157,106.4 C157.4,103.1 157.7,99.9 158.3,96.6 C159.2,91.1 161.9,86.6 166.5,83.3 C179.2,74.3 198.1,74.6 210.1,84.8 C213.2,87.5 215.8,92 216.6,96 C218.8,107.2 220.2,118.5 221.4,129.8 C221.6,131.7 220.1,134.5 218.5,135.6 C212.7,139.4 206.8,143 200.5,145.8 C197.9,146.9 194,146.6 191.2,145.6 C174.6,139.1 158,132.6 141.8,125.4 C127.5,119 114.7,120.3 101.6,128.8 C76.7,145.1 51.4,160.7 26.2,176.6 C24.2,177.9 22.1,179 19,180.8 C19.8,174.4 20.3,169.2 21,164 C22.9,149.3 25.4,134.6 26.8,119.8 C28.4,103.2 39.1,97.9 51.1,95.8 C60.8,94.1 70.3,95.9 78.1,102.1 C81.7,104.9 84.1,109.3 86.8,112.6 C88.3,101.9 90,90.2 91.5,78.4 C94.2,57.5 110.5,46.8 131.2,52.6 C142.9,55.9 150.7,62.1 151.7,75.4 C152.3,84.7 154.2,93.9 155.6,103.1 C155.8,104.2 156.1,105.3 156.4,106.5 C156.6,106.6 156.8,106.5 157,106.4 Z"
                id="Shape"
              />
              <path
                d="M33.4,67.7 C33.2,55 43.7,43.9 56.2,43.7 C69.1,43.4 80.4,54.1 80.7,66.8 C81,80.5 70.6,91.1 56.7,91.3 C44.2,91.4 33.6,80.8 33.4,67.7 Z"
                id="Shape"
              />
              <path
                d="M145.4,23.4 C145.4,36.9 135.1,47.1 121.4,47.2 C108.6,47.2 98,36.4 98,23.2 C98,10.5 108.7,0 121.6,0 C134.9,0 145.4,10.3 145.4,23.4 Z"
                id="Shape"
              />
              <path
                d="M164.2,48.6 C164.5,35.1 174.9,24.9 188.1,25.2 C201,25.5 211.6,36.8 211.2,49.7 C210.7,62.8 200.1,73 187.3,72.7 C173.9,72.4 163.9,61.9 164.2,48.6 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
