import moment from "moment";
import { createSelector } from "reselect";
import { revisionsResourceSelectors } from "../revisions_resource_selectors";
import { assessmentAnswersResourceSelectors } from "src/selectors/data_selectors";

/**
 * Create a selector that returns all the revisions for a series.
 * Sorted by created at.
 * Stricken revisions filtered out.
 * @param {string} seriesId
 * @returns {Function} selector
 */
export function createRevisionsForSeriesIdSelector(seriesId) {
  return createSelector(
    revisionsResourceSelectors.dataSelector,
    assessmentAnswersResourceSelectors.dataSelector,
    (revisions, assessmentAnswers) => {
      return Object.values(revisions)
        .filter(revision => {
          return revision.attributes.seriesId == seriesId;
        })
        .sort((r1, r2) => {
          return moment(r1.attributes.createdAt).isAfter(
            r2.attributes.createdAt
          )
            ? -1
            : 1;
        })
        .filter(revision => {
          const sortedAA = Object.values(assessmentAnswers)
            .filter(assessmentAnswer => {
              return (
                assessmentAnswer.attributes.resourceType == "Revision" &&
                assessmentAnswer.attributes.resourceId == revision.id
              );
            })
            .sort((a1, a2) => {
              return moment(a1.attributes.createdAt).isAfter(
                a2.attributes.createdAt
              )
                ? -1
                : 1;
            });

          return (
            sortedAA.length &&
            sortedAA[0].attributes.strikeoutState != "stricken"
          );
        });
    }
  );
}
