import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import { replace } from "react-router-redux";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { mapReducersToProps } from "incremental-redux-reducers";
import get from "lodash.get";
import jwtDecode from "jwt-decode";
import i18n from "src/translations";

// containers
import { AppContainer } from "src/containers/app_container";
import { SignInContainer } from "src/containers/account";
import { ResetPasswordContainer } from "src/containers/account";
import { OtpVerificationContainer } from "src/containers/otp_verification_container";
import { UserAuthCodeContainer } from "src/containers/user_auth_code_container";
import { AppConfigurationContainer } from "src/containers/app_configuration_container";
import { OauthCallBackContainer } from "src/containers/oauth_callback_container";
import EmbeddedDashboardComponent from "src/components/embedded_dashboard_component/embedded_dashboard_component";

// components
import FlashMessagesComponent from "./flash_messages/component";
import { RefreshComponent } from "src/components/refresh_component";

// New selectors
import {
  sideBarEnabledSelector,
  partnerNameSelector
} from "src/selectors/config_selectors";
import {
  signInViewIsDisabledSelector,
  brandingSelector
} from "src/selectors/ui_config_selectors/global";
import {
  accessTokenSelector,
  apiHostSelector,
  userIdSelector,
  viewModeSelector,
  selectedOrganizationIdSelector
} from "src/selectors/session_selectors";
import { routeQueryParamsUserIdSelector } from "src/selectors/router_selectors";
import {
  routeQueryParamsSelector,
  routePathnameSelector
} from "src/selectors/router_selectors";
import { selectedOrganizationSelector } from "src/selectors/data_selectors/organizations";

// legacy Selector/Reducers
import applicationReducer from "./reducers/application";

// new actions
import { loadLocationsForOrganization } from "src/actions/data_actions/locations";
import {
  setPartnerLocationIds,
  setPartnerName
} from "src/actions/config_actions";
import {
  setAccessToken,
  populateSelectedOrganizationId
} from "src/actions/session_actions";
import { loadUserAction } from "src/actions/data_actions";
import { loadOrganizationsActionLegacy } from "src/actions/data_actions";

// legacy actions
import createFlashMessage from "./flash_messages/create";
import { setPatientLinkTemplate } from "./actions/application";

// api config
import { configureApi } from "src/api/config";

// Analtyics
import { registerParamsForAllEvents } from "src/analytics";

class RootComponent extends React.Component {
  static propTypes = {
    children: PropTypes.element,
    routing: PropTypes.object,
    accessToken: PropTypes.any,
    partnerName: PropTypes.any,
    dispatch: PropTypes.func,
    query: PropTypes.object,
    pathname: PropTypes.string,
    signInViewIsDisabled: PropTypes.bool,
    branding: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.setTitleBarContent(props);
  }

  componentWillMount = () => {
    //Check for access_token in query on initial load;
    const { query } = this.props;
    let userId = null;
    if (query && query.access_token) {
      userId = jwtDecode(query.access_token).user_id || null;
    }
    configureApi(query.access_token, userId);

    // Refresh organization table on refresh
    if (this.props.accessToken) {
      this.configureSentryForUser(this.props);

      const orgId = this.props.selectedOrganizationId;
      if (orgId) {
        this.props.dispatch(loadLocationsForOrganization(orgId));
      }
      //TODO:remove this for init refactor
      if (this.props.userId) {
        this.props.dispatch(loadUserAction(this.props.userId));
        this.props.dispatch(loadOrganizationsActionLegacy(["organizations"]));
      }
    }
  };

  componentDidMount = () => {
    let { dispatch, query, pathname } = this.props;

    if (query && query.partner) {
      // Register partner name to be sent with all mixpanel events
      registerParamsForAllEvents({
        partner: query.partner
      });

      dispatch(setPartnerName(query.partner));
    }

    if (query && query.patient_url) {
      let cleansedPatientUrl = query.patient_url.replace(/\n/g, "");
      dispatch(setPatientLinkTemplate(window.atob(cleansedPatientUrl)));
    }

    if (query && query.access_token) {
      let organizationID;

      if (query.organization_id) {
        organizationID = query.organization_id;
        dispatch(populateSelectedOrganizationId(organizationID));
        this.props.dispatch(loadLocationsForOrganization(organizationID));
      }

      // Register organization id to be sent with all analytics events
      registerParamsForAllEvents({ organizationID });

      dispatch(setAccessToken(query.access_token));
      const userId = jwtDecode(query.access_token).user_id;

      dispatch(loadUserAction(userId));
      dispatch(loadOrganizationsActionLegacy(["organizations"]));

      // Set the partner location ids
      if (query.location_ids) {
        let locationIdArray = query.location_ids.split(",");
        dispatch(setPartnerLocationIds(locationIdArray));
      }

      dispatch(replace(pathname));
    } else {
      if (
        !this.props.accessToken &&
        (!pathname.startsWith("/sign_in") &&
          !pathname.startsWith("/reset-password") &&
          !pathname.startsWith("/user-auth-code")) &&
        !pathname.startsWith("/oauth-callback") &&
        !pathname.startsWith("/embedded-dashboard")
      ) {
        if (pathname !== "/") {
          dispatch(createFlashMessage("Please sign in to continue", "info"));
        }
        dispatch(replace("/sign_in"));
      }
    }

    this.loadBrandingStyle(this.props);

    if (this.props.partnerName) {
      this.loadAdditionalPartnerLogic(this.props.partnerName);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.partnerName && nextProps.partnerName) {
      this.loadAdditionalPartnerLogic(nextProps.partnerName);
      this.loadBrandingStyle(nextProps);
    }
    const nextOrgId = get(nextProps, "currentOrganization.id");
    if (get(this.props, "currentOrganization.id") !== nextOrgId) {
      this.props.dispatch(loadLocationsForOrganization(nextOrgId));
    }

    // Configure Sentry and analytics
    if (
      get(this.props, "currentOrganization.id") !== nextOrgId ||
      this.props.userId != nextProps.userId ||
      this.props.viewMode != nextProps.viewMode
    ) {
      // register org id and view mode to be sent with every analytics event
      registerParamsForAllEvents({
        organizationID: nextOrgId ? nextOrgId : undefined,
        viewMode: nextProps.viewMode
      });
      if (nextProps.accessToken) {
        this.configureSentryForUser(nextProps);
      }
    }
  };

  configureSentryForUser(props) {
    const { userId, currentOrganization, viewMode } = props;

    Sentry.configureScope(scope => {
      scope.setUser({
        id: userId
      });

      scope.setExtra(
        "org_id",
        currentOrganization ? currentOrganization.id : ""
      );

      scope.setExtra("view_mode", viewMode);
    });
  }

  setTitleBarContent(props) {
    const favicon = document.getElementById("favicon");
    if (props.branding === "medline") {
      favicon.setAttribute("href", "./medline-favicon.png");
      document.title = i18n.t("headers.medlinePageTitle", "");
    } else {
      document.title = i18n.t("headers.swiftMedical", "");
    }
  }

  loadBrandingStyle(props) {
    switch (props.branding) {
      case "pcc":
      case "pcc_wound":
        import("iframe-resizer/js/iframeResizer.contentWindow").then();
        import("./style.pcc.less").then();
        break;
      case "medline":
        import("./style.medline.less").then();
    }
  }

  loadAdditionalPartnerLogic = partner => {
    switch (partner) {
      case "pcc":
      case "pcc_wound":
        window.setInterval(() => {
          window.parent.postMessage("touchPCCSession", "*");
        }, 2 * 60 * 1000); // 2 minutes
    }
  };

  /**
   * Render the sign in view
   */
  renderSignIn = () => {
    if (this.props.signInViewIsDisabled) {
      return <RefreshComponent />;
    } else {
      return (
        <div className="sign-in-view">
          <FlashMessagesComponent />
          <div id="container" className="container">
            <SignInContainer />
          </div>
        </div>
      );
    }
  };

  /**
   * Render the reset password view
   */
  renderResetPassword = () => {
    return (
      <div className="sign-in-view">
        <FlashMessagesComponent />
        <div id="container" className="container">
          <ResetPasswordContainer />
        </div>
      </div>
    );
  };
  /**
   * Render the app body (sidebar and body of app)
   */
  renderSectionContent = () => {
    if (!this.props.userId && !this.props.userIdQueryParam) return <div></div>;
    return (
      <AppConfigurationContainer>
        <AppContainer />
      </AppConfigurationContainer>
    );
  };

  renderOTPVerification = () => {
    return (
      <div className="sign-in-view">
        <FlashMessagesComponent />
        <div id="container" className="container">
          <OtpVerificationContainer />
        </div>
      </div>
    );
  };

  renderUserAuthCode = () => {
    return (
      <div className="sign-in-view">
        <FlashMessagesComponent />
        <div id="container" className="container">
          <UserAuthCodeContainer />
        </div>
      </div>
    );
  };
  renderOauthCallBack = () => {
    return (
      <div className="sign-in-view">
        <FlashMessagesComponent />
        <div id="container" className="container">
          <OauthCallBackContainer />
        </div>
      </div>
    );
  };

  renderEmbeddedDashboard = () => {
    return (
      <div className="sign-in-view">
        <FlashMessagesComponent />
        <div id="container" className="container">
          <EmbeddedDashboardComponent />
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        <Switch>
          <Route exact path="/sign_in" render={this.renderSignIn} />
          <Route
            exact
            path="/reset-password"
            render={this.renderResetPassword}
          />
          <Route
            exact
            path="/user-auth-code"
            render={this.renderUserAuthCode}
          />
          <Route
            exact
            path="/verification"
            render={this.renderOTPVerification}
          />
          <Route
            exact
            path="/oauth-callback"
            render={this.renderOauthCallBack}
          />
          <Route
            exact
            path="/embedded-dashboard"
            render={this.renderEmbeddedDashboard}
          />
          <Route path="/" render={this.renderSectionContent} />
        </Switch>
      </div>
    );
  };
}

const mapStateToProps = state => {
  const reducerProps = mapReducersToProps({
    application: applicationReducer
  });

  return {
    ...reducerProps(state),
    accessToken: accessTokenSelector(state),
    apiHost: apiHostSelector(state),
    userId: userIdSelector(state),
    userIdQueryParam: routeQueryParamsUserIdSelector(state),
    viewMode: viewModeSelector(state),
    partnerName: partnerNameSelector(state),
    sideBarEnabled: sideBarEnabledSelector(state),
    query: routeQueryParamsSelector(state),
    pathname: routePathnameSelector(state),
    currentOrganization: selectedOrganizationSelector(state),
    selectedOrganizationId: selectedOrganizationIdSelector(state),
    signInViewIsDisabled: signInViewIsDisabledSelector(state),
    branding: brandingSelector(state)
  };
};

export default withRouter(connect(mapStateToProps)(RootComponent));
